import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import {
  Circle,
  CircleLabel,
  CirclesContainer,
  CircleContainer,
} from './styles';
import { CustomFormControlLabel } from '../../../KeywordTrends/components/Header/styled';
import { CustomCheckbox } from '../../../../../../../../shared/checkbox/styles';
import CustomCheckboxImage from '../../../../../../../../shared/images/CustomCheckbox';
import CheckedIcon from '../../../../../../../../shared/images/CheckedIcon';
import { useSharedLinks } from '../../../../../../../../hooks';
import { COMPETITORS_COLORS } from '../../../../../../../../shared/layout/PrivateLayout/constant';
import { getSharedLinkToken } from '../../../../../../../../utils/localStorage';
import { useAppSelector } from '../../../../../../../../store';

const GraphLabels = () => {
  const { onGetSharedProjectPerformance, onSetShowCompetitorsForGraph } =
    useSharedLinks();

  const showCompetitors = useAppSelector(
    state => state.sharedLinks.projectGraphs.projectPerformance.showCompetitors
  );
  const activePeriodProjectPerformance = useAppSelector(
    state => state.sharedLinks.projectGraphs.activePeriodProjectPerformance
  );
  const projectSharedDeviceType = useAppSelector(
    state => state.sharedLinks.projectSharedDeviceType
  );
  const projectShared = useAppSelector(
    state => state.sharedLinks.projectShared
  );

  const handleChange = (id: number, checked: boolean) => {
    const sharedLinkToken = getSharedLinkToken();
    let newShowCompetitors: number[];

    if (checked) {
      newShowCompetitors = [...showCompetitors, id];
      onSetShowCompetitorsForGraph([...showCompetitors, id]);
    } else {
      newShowCompetitors = showCompetitors.filter(item => item !== id);
      onSetShowCompetitorsForGraph(showCompetitors.filter(item => item !== id));
    }

    if (
      projectShared?.id &&
      projectSharedDeviceType &&
      sharedLinkToken &&
      projectShared
    ) {
      onGetSharedProjectPerformance({
        link: sharedLinkToken,
        projectId: projectShared.id,
        period: activePeriodProjectPerformance.value,
        competitorIds: newShowCompetitors.map(item => item.toString()),
        deviceType: projectSharedDeviceType,
      });
    }
  };

  const isBaseSearchEngine = useMemo(() => {
    return (
      projectShared &&
      projectShared.searchEngine.name !== 'Google My Business' &&
      projectShared.searchEngine.name !== 'Google Maps'
    );
  }, [projectShared?.searchEngine?.name]);

  return (
    <Grid>
      <CirclesContainer>
        <CircleContainer>
          <Circle backgroundColor={'#1A73E9'} />
          <CircleLabel>Daily Average Position</CircleLabel>
        </CircleContainer>

        <CircleContainer>
          <Circle backgroundColor={'#FA32CE'} />
          <CircleLabel>Notes</CircleLabel>
        </CircleContainer>
      </CirclesContainer>

      {projectShared?.competitors?.length ? (
        <Grid>
          {projectShared?.competitors.map(competitor => {
            const label = isBaseSearchEngine
              ? competitor.domainName
              : `${competitor.businessName}${
                  competitor?.url ? ` (${competitor?.url})` : ''
                }`;
            return (
              <Grid key={competitor.id}>
                <CustomFormControlLabel
                  control={
                    <CustomCheckbox
                      checked={
                        !!showCompetitors.find(item => item === competitor.id)
                      }
                      onChange={(event, checked) => {
                        handleChange(competitor.id, checked);
                      }}
                      icon={<CustomCheckboxImage />}
                      checkedIcon={
                        <CheckedIcon
                          checkedColor={
                            COMPETITORS_COLORS[
                              competitor.id % COMPETITORS_COLORS.length
                            ]
                          }
                        />
                      }
                    />
                  }
                  label={label}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : null}
    </Grid>
  );
};

export default GraphLabels;
