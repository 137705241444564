import React from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router';
import ROUTES from '../../routes/constants';

import {
  getRefreshWithLocalStorage,
  getRefreshWithSessionStorage,
} from '../../utils/localStorage';

const LoginLayout = () => {
  const navigate = useNavigate();

  const refreshWithLocalStorage = getRefreshWithLocalStorage();
  const refreshWithSessionStorage = getRefreshWithSessionStorage();

  if (refreshWithLocalStorage || refreshWithSessionStorage) {
    navigate(ROUTES.projects);
  }

  return (
    <>
      {!(refreshWithLocalStorage || refreshWithSessionStorage) ? (
        <Outlet />
      ) : null}
    </>
  );
};

export default LoginLayout;
