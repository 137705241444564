import React from 'react';

interface Props {
  stroke?: string;
}

const LightningIcon: React.FC<Props> = ({ stroke = 'white' }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='21'
    height='20'
    viewBox='0 0 21 20'
    fill='none'
  >
    <path
      d='M11.3331 1.6665L3.91094 10.573C3.62026 10.9219 3.47492 11.0963 3.4727 11.2436C3.47077 11.3716 3.52783 11.4934 3.62744 11.5739C3.74201 11.6665 3.96904 11.6665 4.42308 11.6665H10.4997L9.66639 18.3332L17.0885 9.42663C17.3792 9.07782 17.5245 8.90341 17.5267 8.75612C17.5287 8.62807 17.4716 8.50625 17.372 8.42576C17.2574 8.33317 17.0304 8.33317 16.5764 8.33317H10.4997L11.3331 1.6665Z'
      stroke={stroke}
      strokeWidth='1.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default LightningIcon;
