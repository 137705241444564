import React, { useCallback, useEffect, useState } from 'react';
import {
  debounce,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { PopupTableSettings } from './components';
import SearchIcon from '../../../../../../../../shared/images/SearchIcon';
import { useAuth, useProjects } from '../../../../../../../../hooks';
import ActionsMenu from '../ActionsMenu/ActionsMenu';
import PopupTags from './components/PopupTags/PopupTags';

import { CountLabel } from './styles';
import { OutlinedInputCustom } from '../../../../../../../../shared/inputs/styled';
import { useAppSelector } from '../../../../../../../../store';

const Header = () => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');
  const extraTooSmallScreen = useMediaQuery('(max-width:550px)');
  const seScreen = useMediaQuery('(max-width:400px)');

  const { onSetSearchProjects, onSetRowSelectionModel } = useProjects();

  const search = useAppSelector(state => state.projects.tables.projects.search);
  const totalItems = useAppSelector(
    state => state.projects.projects?.meta?.totalItems
  );

  const rowSelectionModel = useAppSelector(
    state => state.projects.tables.projects.rowSelectionModel
  );
  const isShowTableFilters = useAppSelector(
    state => state.projects.isShowTableFilters
  );

  const myAccount = useAppSelector(state => state.auth.myAccount);

  const accountLimit = useAppSelector(state => state.auth.accountLimit);

  const [localSearch, setLocalSearch] = useState(search);

  const [showSearchMobile, setShowSearchMobile] = useState(false);

  const debounceSearch = useCallback(
    debounce(value => {
      onSetSearchProjects(value);
    }, 500),
    [onSetSearchProjects]
  );

  useEffect(() => {
    if (!search) {
      setLocalSearch('');
    }
  }, [search]);

  const handleSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLocalSearch(event.target.value);
    debounceSearch(event.target.value);
  };

  const handleClearSearch = () => {
    setLocalSearch('');
    debounceSearch('');
  };

  const onClearSelected = () => {
    onSetRowSelectionModel([]);
  };

  return (
    <Grid
      container
      item
      justifyContent='space-between'
      alignItems='center'
      flexDirection='column'
      sx={{
        padding: !extraSmallScreen
          ? '32px 24px 24px 24px'
          : '8px 24px 24px 24px',
      }}
    >
      {isShowTableFilters && extraSmallScreen ? (
        <Grid
          item
          container
          alignItems='center'
          sx={{ marginBottom: '16px', gap: '6px' }}
        >
          <Grid item>
            <PopupTags />
          </Grid>
          <Grid item>
            <PopupTableSettings />
          </Grid>
        </Grid>
      ) : null}

      <Grid
        container
        item
        justifyContent='space-between'
        sx={{
          gap:
            extraTooSmallScreen && rowSelectionModel.length && showSearchMobile
              ? '8px'
              : !extraSmallScreen
              ? '8px'
              : !seScreen
              ? '0'
              : '8px',
          flexDirection:
            extraTooSmallScreen && rowSelectionModel.length && showSearchMobile
              ? 'column-reverse'
              : !seScreen
              ? 'row'
              : !showSearchMobile
              ? 'row'
              : 'column-reverse',
          alignItems:
            extraTooSmallScreen && rowSelectionModel.length && showSearchMobile
              ? 'flex-start'
              : !seScreen
              ? 'center'
              : !showSearchMobile
              ? 'center'
              : 'flex-start',
        }}
      >
        <Grid item>
          {rowSelectionModel.length ? (
            <Grid container item>
              <Grid container item alignItems='center'>
                <Grid item>
                  <IconButton
                    onClick={onClearSelected}
                    sx={{ padding: '0 !important', marginRight: '8px' }}
                  >
                    <CloseIcon fontSize='small' />
                  </IconButton>
                </Grid>
                <Grid item sx={{ paddingRight: '16px' }}>
                  <Typography
                    fontSize='14px'
                    lineHeight='14px'
                    color='#101828'
                    fontWeight='500'
                    alignItems='center'
                  >
                    {`${rowSelectionModel.length} Selected`}
                  </Typography>
                </Grid>
                <Grid item>
                  <ActionsMenu />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              item
              alignItems='center'
              display={
                !seScreen &&
                extraSmallScreen &&
                showSearchMobile &&
                !rowSelectionModel.length
                  ? 'none'
                  : 'flex'
              }
            >
              <Grid item marginRight='8px'>
                <Typography
                  variant='h6'
                  fontWeight='600'
                  color='#101828'
                  fontSize='18px'
                  lineHeight='28px'
                >
                  All Projects
                </Typography>
              </Grid>
              <Grid item>
                <CountLabel>
                  {accountLimit?.accountLimitsUsed?.numberOfProjects || 0}
                </CountLabel>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid
          sx={{
            gap: '8px',
            width:
              extraTooSmallScreen &&
              rowSelectionModel.length &&
              showSearchMobile
                ? '100%'
                : extraSmallScreen &&
                  showSearchMobile &&
                  !rowSelectionModel.length
                ? '100%'
                : 'initial',
            maxWidth:
              extraTooSmallScreen &&
              rowSelectionModel.length &&
              showSearchMobile
                ? 'initial'
                : seScreen
                ? 'initial'
                : extraSmallScreen &&
                  showSearchMobile &&
                  rowSelectionModel.length
                ? '50%'
                : 'initial',
          }}
          item
        >
          <Grid
            item
            container
            sx={{
              gap: '8px',
            }}
          >
            <Grid
              item
              xs={extraSmallScreen && showSearchMobile ? 12 : undefined}
            >
              <OutlinedInputCustom
                sx={{
                  width: extraSmallScreen && !showSearchMobile ? '44px' : null,
                }}
                $padding={'7.94px 14px 7.94px 0'}
                fullWidth
                size='small'
                type='text'
                id='searchProject'
                name='searchProject'
                placeholder='Search project'
                value={localSearch}
                onChange={handleSearch}
                onFocus={() => {
                  extraSmallScreen && setShowSearchMobile(true);
                }}
                startAdornment={
                  <InputAdornment position='start' disablePointerEvents>
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  extraSmallScreen && showSearchMobile ? (
                    <>
                      <Typography
                        fontSize='14px'
                        fontWeight='600'
                        lineHeight='20px'
                        color='#126FE9'
                        sx={{ cursor: 'pointer', paddingRight: '4px' }}
                        onClick={handleClearSearch}
                      >
                        Clear
                      </Typography>
                      <CloseIcon
                        fontSize='small'
                        sx={{ color: '#101828', cursor: 'pointer' }}
                        onClick={() =>
                          extraSmallScreen && setShowSearchMobile(false)
                        }
                      />
                    </>
                  ) : null
                }
              />
            </Grid>

            {!extraSmallScreen ? (
              <>
                <Grid item sx={{ height: '100%' }}>
                  <PopupTags />
                </Grid>
                <Grid item>
                  <PopupTableSettings />
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
