import React from 'react';

const PackageIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        d='M11 6.26664L5 2.80664'
        stroke='#667085'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14 10.666V5.33262C13.9998 5.0988 13.938 4.86916 13.821 4.66673C13.704 4.46429 13.5358 4.29619 13.3333 4.17928L8.66667 1.51262C8.46397 1.39559 8.23405 1.33398 8 1.33398C7.76595 1.33398 7.53603 1.39559 7.33333 1.51262L2.66667 4.17928C2.46418 4.29619 2.29599 4.46429 2.17897 4.66673C2.06196 4.86916 2.00024 5.0988 2 5.33262V10.666C2.00024 10.8998 2.06196 11.1294 2.17897 11.3318C2.29599 11.5343 2.46418 11.7024 2.66667 11.8193L7.33333 14.486C7.53603 14.603 7.76595 14.6646 8 14.6646C8.23405 14.6646 8.46397 14.603 8.66667 14.486L13.3333 11.8193C13.5358 11.7024 13.704 11.5343 13.821 11.3318C13.938 11.1294 13.9998 10.8998 14 10.666Z'
        stroke='#667085'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.17969 4.64062L7.99969 8.00729L13.8197 4.64062'
        stroke='#667085'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 14.72V8'
        stroke='#667085'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default PackageIcon;
