import React from 'react';

const ExpandMoreFolderIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.3955 5.22871C11.7107 4.9138 11.4877 4.375 11.0421 4.375H2.95634C2.51099 4.375 2.28786 4.91335 2.60263 5.22839L6.64188 9.27129C6.83705 9.46664 7.15363 9.46678 7.34898 9.27161L11.3955 5.22871Z'
        fill='#667085'
      />
    </svg>
  );
};

export default ExpandMoreFolderIcon;
