import React, { useEffect, useMemo } from 'react';
import {
  Autocomplete,
  Fade,
  FormHelperText,
  Grid,
  InputLabel,
  Modal,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useEmailReports, useProjects } from '../../../../../hooks';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DEFAULT_GET_USERS_PARAMS } from '../../../../auth/components/Settings/components/Team/constants';
import {
  ModalContainer,
  ModalWrapper,
} from '../../../../projects/components/Projects/components/ProjectRedactorModal/styles';
import { CloseDeletePopoverOne } from '../../../../auth/components/Settings/components/Account/styles';
import { CustomFormHelperText } from '../../../../../shared/components';
import { StyledAutocompleteLi } from '../../../../auth/components/Settings/components/Team/components/styles';
import CloseIcon from '@mui/icons-material/Close';
import SelectIcon from '../../../../../shared/components/SelectIcon';
import { ButtonsWrapper } from '../../../../auth/components/Settings/components/Team/components/customTableStyles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../shared/buttons/styles';
import { EmailReportFrequency } from '../../../types';
import { TimesGroup } from '../../../../projects/components';
import { EditProjectReportProps } from '../types';
import { useAppSelector } from '../../../../../store';
import {
  ModalAutocompleteStylesProps,
  ModalOutlinedInput,
} from '../../../../../shared/modal/styled';
import GlobeIcon from '../../../../../shared/images/GlobeIcon';
import { capitalizeFirstLetter } from '../../../../../utils';

/**
 * Component for editing a project report.
 * @component
 * @param {EditProjectReportProps} props - Props for the EditProjectReport component.
 * @returns {JSX.Element} The rendered EditProjectReport component.
 */
const EditProjectReport: React.FC<EditProjectReportProps> = ({
  onClose,
  open,
  id,
}) => {
  {
    const extraSmallScreen = useMediaQuery('(max-width:600px)');

    const { onGetProjectsListForInvite } = useProjects();

    const projectsListForInvite = useAppSelector(
      state => state.projects.projectsListForInvite
    );

    const currentAccount = useAppSelector(state => state.auth.currentAccount);
    const {
      onEditEmailReport,
      onGetEmailReportFull,
      onGetEmailReports,
      onGetEmailReportFrequency,
      onGetReportDeliveryTimes,
      onGetReportTypes,
      emailReportFrequency,
      reportDeliveryTimes,
      reportTypes,
      emailReportFull,
      tables: {
        scheduledEmailReports: { paginationModel, search, sortModel },
      },
    } = useEmailReports();

    const formik = useFormik({
      initialValues: {
        projectId: null as {
          id: number;
          projectName: string;
          favicon?: string;
        } | null,
        recipients: '',
        frequency: null as EmailReportFrequency | null,
        reportType: null as EmailReportFrequency | null,
        reportDeliveryTime: null as EmailReportFrequency | null,
        message: '',
      },
      validationSchema: Yup.object().shape({
        projectId: Yup.object().required('Project is required.'),
        recipients: Yup.string()
          .required('Recipient is required.')
          .email('Invalid email address.'),
        frequency: Yup.object().required('Frequency is required.'),
        reportType: Yup.object().required('Report Type is required.'),
        reportDeliveryTime: Yup.object().required(
          'Report Delivery Time is required.'
        ),
      }),
      onSubmit: (
        values,
        { setStatus, setSubmitting, setErrors, resetForm }
      ) => {
        if (
          currentAccount?.accountId &&
          values?.projectId?.id &&
          values?.frequency?.name &&
          values?.reportType?.name &&
          values?.reportDeliveryTime?.name
        ) {
          const requestData = {
            emailReportId: id,
            accountId: currentAccount.accountId,
            projectId: values.projectId.id,
            recipients: [values.recipients],
            frequency: values?.frequency?.name,
            reportType: values?.reportType?.name,
            reportDeliveryTimeId: values?.reportDeliveryTime?.id,
            successFn: () => {
              onGetEmailReports({
                id: currentAccount.accountId,
                page: paginationModel.page,
                limit: paginationModel.pageSize,
                search: search || DEFAULT_GET_USERS_PARAMS.search,
                sortBy: sortModel[0]?.field,
                sortOrder: sortModel[0]?.sort,
              });
              resetForm();
              onClose();
            },
            errorFn: (error: any) => {
              setStatus({ success: false });
              setSubmitting(false);
              setErrors(error);
            },
          };

          onEditEmailReport({ ...requestData });
        }
      },
    });

    const timesGroupItems = useMemo(() => {
      return reportTypes.map(period => ({
        label: period.name.toUpperCase(),
        value: period.name,
        isActive: period.id === formik.values.reportType?.id,
        onChange: () => formik.setFieldValue('reportType', period),
      }));
    }, [formik.values, formik.setFieldValue, reportTypes]);

    const handleClose = () => {
      onClose();
      formik.resetForm();
    };

    useEffect(() => {
      if (open && currentAccount?.accountId) {
        onGetEmailReportFull({
          accountId: currentAccount.accountId,
          emailReportId: id,
        });
      }
    }, [currentAccount.accountId, id, onGetEmailReportFull, open]);

    useEffect(() => {
      if (open && currentAccount?.accountId) {
        onGetProjectsListForInvite({
          accountId: currentAccount.accountId,
          search: '',
        });
        !emailReportFrequency.length && onGetEmailReportFrequency();
        !reportDeliveryTimes.length &&
          onGetReportDeliveryTimes(currentAccount.accountId);
        !reportTypes.length && onGetReportTypes();
      }
    }, [
      currentAccount?.accountId,
      onGetEmailReportFrequency,
      onGetProjectsListForInvite,
      onGetReportDeliveryTimes,
      onGetReportTypes,
      open,
    ]);

    useEffect(() => {
      if (emailReportFull?.id) {
        formik.setFieldValue('projectId', emailReportFull.project);
        formik.setFieldValue('recipients', emailReportFull.recipients[0].email);
        formik.setFieldValue('frequency', emailReportFull.frequency);
        formik.setFieldValue(
          'reportDeliveryTime',
          emailReportFull.deliveryTime
        );
        formik.setFieldValue('reportType', emailReportFull.format);
      }
    }, [emailReportFull?.id]);

    return (
      <>
        <Modal
          aria-labelledby='AddNoteModal'
          aria-describedby='EditNoteModal'
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <form onSubmit={formik.handleSubmit}>
              <ModalWrapper>
                <ModalContainer container xs={12} sx={{ gap: '8px' }}>
                  <Grid xs={12}>
                    <Grid item xs={12} sx={{ position: 'relative' }}>
                      <CloseDeletePopoverOne onClick={handleClose}>
                        <CloseIcon fontSize='small' />
                      </CloseDeletePopoverOne>
                      <Typography
                        fontSize='18px'
                        fontWeight='600'
                        lineHeight='28px'
                        color='#101828'
                      >
                        Edit Project Report
                      </Typography>
                    </Grid>
                    <Grid paddingTop={'16px'}>
                      <InputLabel shrink htmlFor='projectId'>
                        Project*
                      </InputLabel>
                      <Autocomplete
                        sx={ModalAutocompleteStylesProps}
                        style={{ margin: '0 0 6px 0' }}
                        fullWidth
                        size={'small'}
                        id='projectId'
                        limitTags={2}
                        options={projectsListForInvite.items}
                        getOptionLabel={option => option.projectName}
                        renderOption={(props, option) => {
                          return (
                            <StyledAutocompleteLi {...props} key={option.id}>
                              <Grid container gap={'8px'}>
                                <Grid
                                  item
                                  display={'flex'}
                                  alignItems={'center'}
                                >
                                  {option?.favicon ? (
                                    <img
                                      src={option.favicon}
                                      width={16}
                                      height={16}
                                      alt={'Company Logo URL'}
                                    />
                                  ) : (
                                    <GlobeIcon />
                                  )}
                                </Grid>
                                <Grid item>{option.projectName}</Grid>
                              </Grid>
                            </StyledAutocompleteLi>
                          );
                        }}
                        value={formik.values.projectId}
                        onChange={(_, value) => {
                          formik.setFieldValue('projectId', value || null);
                        }}
                        onBlur={formik.handleBlur}
                        popupIcon={<SelectIcon />}
                        renderInput={params => (
                          <TextField
                            {...params}
                            placeholder='Select project'
                            name='projectId'
                            error={
                              !!(
                                formik.touched.projectId &&
                                formik.errors.projectId
                              )
                            }
                          />
                        )}
                        PaperComponent={paperProps => {
                          const { children, ...restPaperProps } = paperProps;
                          return <Paper {...restPaperProps}>{children}</Paper>;
                        }}
                      />
                      {formik.touched.projectId && formik.errors?.projectId && (
                        <FormHelperText error id='projectId'>
                          <CustomFormHelperText
                            error={formik.errors.projectId}
                          />
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid paddingTop={'16px'}>
                      <InputLabel shrink htmlFor='recipients'>
                        Recipient*
                      </InputLabel>
                      <ModalOutlinedInput
                        fullWidth
                        size={'small'}
                        type='text'
                        id='recipients'
                        name='recipients'
                        placeholder='example@example.com'
                        value={formik.values.recipients}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.touched.recipients &&
                            formik.errors.recipients
                          )
                        }
                      />
                      {formik.touched.recipients &&
                        formik.errors.recipients && (
                          <FormHelperText error id='recipients'>
                            <CustomFormHelperText
                              error={formik.errors.recipients}
                            />
                          </FormHelperText>
                        )}
                    </Grid>

                    <Grid paddingTop={'16px'}>
                      <InputLabel shrink htmlFor='frequency'>
                        Frequency*
                      </InputLabel>
                      <Autocomplete
                        sx={ModalAutocompleteStylesProps}
                        style={{ margin: '0 0 6px 0' }}
                        fullWidth
                        size={'small'}
                        id='frequency'
                        limitTags={2}
                        options={emailReportFrequency}
                        getOptionLabel={option => option.name}
                        renderOption={(props, option) => {
                          return (
                            <StyledAutocompleteLi {...props} key={option.id}>
                              {capitalizeFirstLetter(option.name)}
                            </StyledAutocompleteLi>
                          );
                        }}
                        value={formik.values.frequency}
                        onChange={(_, value) => {
                          formik.setFieldValue('frequency', value || null);
                        }}
                        onBlur={formik.handleBlur}
                        popupIcon={<SelectIcon />}
                        renderInput={params => (
                          <TextField
                            {...params}
                            placeholder='Select frequency'
                            name='frequency'
                            error={
                              !!(
                                formik.touched.frequency &&
                                formik.errors.frequency
                              )
                            }
                            inputProps={{
                              ...params.inputProps,
                              value: formik?.values?.frequency?.name
                                ? capitalizeFirstLetter(
                                    formik.values.frequency.name
                                  )
                                : null,
                            }}
                          />
                        )}
                        PaperComponent={paperProps => {
                          const { children, ...restPaperProps } = paperProps;
                          return <Paper {...restPaperProps}>{children}</Paper>;
                        }}
                      />
                      {formik.touched.frequency && formik.errors?.frequency && (
                        <FormHelperText error id='frequency'>
                          <CustomFormHelperText
                            error={formik.errors.frequency}
                          />
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid paddingTop={'16px'}>
                      <InputLabel shrink htmlFor='reportType'>
                        Report Type*
                      </InputLabel>
                      <TimesGroup items={timesGroupItems} />
                      {formik.touched.reportType &&
                        formik.errors?.reportType && (
                          <FormHelperText error id='reportType'>
                            <CustomFormHelperText
                              error={formik.errors.reportType}
                            />
                          </FormHelperText>
                        )}
                    </Grid>

                    <Grid paddingTop={'16px'}>
                      <InputLabel shrink htmlFor='reportDeliveryTime'>
                        Report Delivery Time*
                      </InputLabel>
                      <Autocomplete
                        sx={ModalAutocompleteStylesProps}
                        style={{ margin: '0 0 6px 0' }}
                        fullWidth
                        size={'small'}
                        id='reportDeliveryTime'
                        limitTags={2}
                        options={reportDeliveryTimes}
                        getOptionLabel={option => option.name}
                        renderOption={(props, option) => {
                          return (
                            <StyledAutocompleteLi {...props} key={option.id}>
                              {`At around ${option.name}`}
                            </StyledAutocompleteLi>
                          );
                        }}
                        value={formik.values.reportDeliveryTime}
                        onChange={(_, value) => {
                          formik.setFieldValue(
                            'reportDeliveryTime',
                            value || null
                          );
                        }}
                        onBlur={formik.handleBlur}
                        popupIcon={<SelectIcon />}
                        renderInput={params => (
                          <TextField
                            {...params}
                            placeholder='Select report delivery time'
                            name='reportDeliveryTime'
                            error={
                              !!(
                                formik.touched.reportDeliveryTime &&
                                formik.errors.reportDeliveryTime
                              )
                            }
                          />
                        )}
                        PaperComponent={paperProps => {
                          const { children, ...restPaperProps } = paperProps;
                          return <Paper {...restPaperProps}>{children}</Paper>;
                        }}
                      />
                      {formik.touched.reportDeliveryTime &&
                        formik.errors?.reportDeliveryTime && (
                          <FormHelperText error id='reportDeliveryTime'>
                            <CustomFormHelperText
                              error={formik.errors.reportDeliveryTime}
                            />
                          </FormHelperText>
                        )}
                    </Grid>
                    <Grid>
                      {
                        <FormHelperText
                          error={!!formik.errors.message}
                          id='message'
                        >
                          {formik.errors.message && (
                            <CustomFormHelperText
                              error={formik.errors.message}
                            />
                          )}
                        </FormHelperText>
                      }
                    </Grid>
                    <ButtonsWrapper
                      container
                      xs={12}
                      sx={{ paddingTop: '32px' }}
                    >
                      <Grid item xs={extraSmallScreen && 12}>
                        <GrayButtonSmall
                          fullWidth={extraSmallScreen}
                          size='small'
                          outline
                          onClick={handleClose}
                        >
                          Cancel
                        </GrayButtonSmall>
                      </Grid>
                      <Grid item xs={extraSmallScreen && 12}>
                        <PrimaryButtonSmall
                          fullWidth={extraSmallScreen}
                          variant='contained'
                          size='small'
                          type='submit'
                          disabled={formik.isSubmitting || !formik.isValid}
                          onClick={() => formik.handleSubmit()}
                        >
                          Save Changes
                        </PrimaryButtonSmall>
                      </Grid>
                    </ButtonsWrapper>
                  </Grid>
                </ModalContainer>
              </ModalWrapper>
            </form>
          </Fade>
        </Modal>
      </>
    );
  }
};

export default EditProjectReport;
