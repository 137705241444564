import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { Header, InvoiceTable } from './components';
import { useAuth } from '../../../../../../../../hooks';
import { useAppSelector } from '../../../../../../../../store';

/**
 * Invoice component that displays the header and invoice table, and handles fetching invoices for the current account.
 *
 * @component
 * @returns {JSX.Element} Rendered Invoice component.
 */
const Invoice = () => {
  const { onGetInvoices } = useAuth();

  const sortModel = useAppSelector(
    state => state.auth.tables.invoice.sortModel
  );
  const paginationModel = useAppSelector(
    state => state.auth.tables.invoice.paginationModel
  );
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  useEffect(() => {
    if (currentAccount) {
      onGetInvoices({
        id: currentAccount.accountId,
        page: paginationModel.page,
        limit: paginationModel.pageSize,
        sortBy: sortModel[0]?.field,
        sortOrder: sortModel[0]?.sort,
      });
    }
  }, [
    currentAccount,
    onGetInvoices,
    paginationModel.page,
    paginationModel.pageSize,
    sortModel,
  ]);

  return (
    <Grid xs={12}>
      <Header />
      <InvoiceTable />
    </Grid>
  );
};

export default Invoice;
