import { socket } from '../../constants/socketConfig';
import {
  GetBillingResponse,
  IBillingCard,
  IFolderTree,
  IUpdateBalanceCount,
  User,
} from './types';

/**
 * Unsubscribes all socket events.
 */
const offAllSockets = () => {
  socket.off();
};

/**
 * Sets up a socket listener for the addition of email reports.
 *
 * @param {number} accountId - The account ID.
 * @param {Function} successFn - Callback function to handle the updated balance count.
 */
const updateEmailReportsBalanceCountSocket = (
  accountId: number,
  successFn: (value: IUpdateBalanceCount) => void
) => {
  socket.off(`accounts/${accountId}/addition-of-email-reports`);
  socket.on(
    `accounts/${accountId}/addition-of-email-reports`,
    (value: IUpdateBalanceCount) => {
      successFn(value);
    }
  );
};

/**
 * Sets up a socket listener for the addition of triggers.
 *
 * @param {number} accountId - The account ID.
 * @param {Function} successFn - Callback function to handle the updated balance count.
 */
const updateTriggersBalanceCountSocket = (
  accountId: number,
  successFn: (value: IUpdateBalanceCount) => void
) => {
  socket.off(`accounts/${accountId}/addition-of-triggers`);
  socket.on(
    `accounts/${accountId}/addition-of-triggers`,
    (value: IUpdateBalanceCount) => {
      successFn(value);
    }
  );
};

/**
 * Sets up a socket listener for the addition of users.
 *
 * @param {number} accountId - The account ID.
 * @param {Function} successFn - Callback function to handle the updated balance count.
 */
const updateUsersBalanceCountSocket = (
  accountId: number,
  successFn: (value: IUpdateBalanceCount) => void
) => {
  socket.off(`accounts/${accountId}/addition-of-users`);
  socket.on(
    `accounts/${accountId}/addition-of-users`,
    (value: IUpdateBalanceCount) => {
      successFn(value);
    }
  );
};

/**
 * Sets up a socket listener for the addition of competitors.
 *
 * @param {number} accountId - The account ID.
 * @param {Function} successFn - Callback function to handle the updated balance count.
 */
const updateCompetitorsBalanceCountSocket = (
  accountId: number,
  successFn: (value: IUpdateBalanceCount) => void
) => {
  socket.off(`accounts/${accountId}/addition-of-competitors`);
  socket.on(
    `accounts/${accountId}/addition-of-competitors`,
    (value: IUpdateBalanceCount) => {
      successFn(value);
    }
  );
};

/**
 * Sets up a socket listener for the addition of notes.
 *
 * @param {number} accountId - The account ID.
 * @param {Function} successFn - Callback function to handle the updated balance count.
 */
const updateNotesBalanceCountSocket = (
  accountId: number,
  successFn: (value: IUpdateBalanceCount) => void
) => {
  socket.off(`accounts/${accountId}/addition-of-notes`);
  socket.on(
    `accounts/${accountId}/addition-of-notes`,
    (value: IUpdateBalanceCount) => {
      successFn(value);
    }
  );
};

/**
 * Sets up a socket listener for the addition of daily keywords.
 *
 * @param {number} accountId - The account ID.
 * @param {Function} successFn - Callback function to handle the updated balance count.
 */
const updateDailyKeywordsBalanceCountSocket = (
  accountId: number,
  successFn: (value: IUpdateBalanceCount) => void
) => {
  socket.off(`accounts/${accountId}/addition-of-keywords`);
  socket.on(
    `accounts/${accountId}/addition-of-keywords`,
    (value: IUpdateBalanceCount) => {
      successFn(value);
    }
  );
};

/**
 * Sets up a socket listener for the monthly keyword update.
 *
 * @param {number} accountId - The account ID.
 * @param {Function} successFn - Callback function to handle the updated balance count.
 */
const updateMonthlyKeywordsBalanceCountSocket = (
  accountId: number,
  successFn: (value: IUpdateBalanceCount) => void
) => {
  socket.off(`accounts/${accountId}/monthly-keyword-update`);
  socket.on(
    `accounts/${accountId}/monthly-keyword-update`,
    (value: IUpdateBalanceCount) => {
      successFn(value);
    }
  );
};

/**
 * Sets up a socket listener for the addition of shared links.
 *
 * @param {number} accountId - The account ID.
 * @param {Function} successFn - Callback function to handle the updated balance count.
 */
const updateSharedLinksBalanceCountSocket = (
  accountId: number,
  successFn: (value: IUpdateBalanceCount) => void
) => {
  socket.off(`accounts/${accountId}/addition-of-shared-links`);
  socket.on(
    `accounts/${accountId}/addition-of-shared-links`,
    (value: IUpdateBalanceCount) => {
      successFn(value);
    }
  );
};

/**
 * Sets up a socket listener for the number of account projects.
 *
 * @param {number} accountId - The account ID.
 * @param {Function} successFn - Callback function to handle the updated balance count.
 */
const updateProjectsBalanceCountSocket = (
  accountId: number,
  successFn: (value: IUpdateBalanceCount) => void
) => {
  socket.off(`accounts/${accountId}/number-of-account-projects`);
  socket.on(
    `accounts/${accountId}/number-of-account-projects`,
    (value: IUpdateBalanceCount) => {
      successFn(value);
    }
  );
};

/**
 * Sets up a socket listener for the addition of invitations.
 *
 * @param {number} accountId - The account ID.
 * @param {Function} successFn - Callback function to handle the updated balance count.
 */
const updateInvitationsBalanceCountSocket = (
  accountId: number,
  successFn: (value: IUpdateBalanceCount) => void
) => {
  socket.off(`accounts/${accountId}/addition-of-invitations`);
  socket.on(
    `accounts/${accountId}/addition-of-invitations`,
    (value: IUpdateBalanceCount) => {
      successFn(value);
    }
  );
};

/**
 * Sets up a socket listener for the update of payment method.
 *
 * @param {number} accountId - The account ID.
 * @param {Function} successFn - Callback function to handle the updated billing card.
 */
const updatePaymentMethodSocket = (
  accountId: number,
  successFn: (card: IBillingCard | null) => void
) => {
  socket.off(`accounts/${accountId}/update-payment-method`);
  socket.on(
    `accounts/${accountId}/update-payment-method`,
    (data: { card: IBillingCard | null }) => {
      successFn(data.card);
    }
  );
};

/**
 * Sets up a socket listener for updated account limits.
 *
 * @param {number} accountId - The account ID.
 * @param {Function} successFn - Callback function to handle the update limits status.
 */
const updatedAllAccountLimitsSocket = (
  accountId: number,
  successFn: (updateLimits: boolean) => void
) => {
  socket.off(`accounts/${accountId}/updated-all-account-limits`);
  socket.on(
    `accounts/${accountId}/updated-all-account-limits`,
    (data: { updateLimits: boolean }) => {
      successFn(data.updateLimits);
    }
  );
};

/**
 * Sets up a socket listener for updated user data.
 *
 * @param {number} userId - The user ID.
 * @param {Function} successFn - Callback function to handle the user data.
 */
const updatedUserSocket = (userId: number, successFn: (data: User) => void) => {
  socket.off(`users/${userId}/user-response`);
  socket.on(`users/${userId}/user-response`, (data: User) => {
    successFn(data);
  });
};

/**
 * Sets up a socket listener for subscription cancellation updates.
 *
 * @param {number} accountId - The account ID.
 * @param {Function} successFn - Callback function to handle the billing response.
 */
const checkCancelSubscription = (
  accountId: number,
  successFn: (card: GetBillingResponse) => void
) => {
  socket.off(`accounts/${accountId}/update-billing`);
  socket.on(
    `accounts/${accountId}/update-billing`,
    (data: GetBillingResponse) => {
      successFn(data);
    }
  );
};

/**
 * Interface for folder tree socket data.
 */
interface IFolderTreeSocket {
  folderTree: IFolderTree;
}

/**
 * Sets up a socket listener for the folder tree of a user.
 *
 * @param {number} accountId - The account ID.
 * @param {number} userId - The user ID.
 * @param {Function} successFn - Callback function to handle the folder tree data.
 */
const setFolderTreeSocket = (
  accountId: number,
  userId: number,
  successFn: (data: IFolderTree) => void
) => {
  socket.off(`accounts/${accountId}/users/${userId}/folder-tree`);
  socket.on(
    `accounts/${accountId}/users/${userId}/folder-tree`,
    (data: IFolderTreeSocket) => {
      successFn(data.folderTree);
    }
  );
};

export {
  offAllSockets,
  updateEmailReportsBalanceCountSocket,
  updateTriggersBalanceCountSocket,
  updateUsersBalanceCountSocket,
  updateCompetitorsBalanceCountSocket,
  updateNotesBalanceCountSocket,
  updateDailyKeywordsBalanceCountSocket,
  updateMonthlyKeywordsBalanceCountSocket,
  updateSharedLinksBalanceCountSocket,
  updateProjectsBalanceCountSocket,
  updateInvitationsBalanceCountSocket,
  updatePaymentMethodSocket,
  updatedAllAccountLimitsSocket,
  updatedUserSocket,
  checkCancelSubscription,
  setFolderTreeSocket,
};
