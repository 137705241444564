import { styled } from '@mui/system';
import { Grid } from '@mui/material';

const LineStyles = styled(Grid)`
  margin: 4px 0;
`;

const LabelStyles = styled(Grid)`
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const ValueStyles = styled(Grid)<{ $color?: string }>`
  color: ${props => (props.$color ? props.$color : '#101828')};
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

export { LabelStyles, ValueStyles, LineStyles };
