import { AxiosResponse } from 'axios';
import api from '../../api';
import {
  IAlertInfo,
  BulkDeleteKeywordsFromTriggerParams,
  BulkDeleteTriggersParams,
  GetAlertInfoParams,
  GetAlertsParams,
  GetTriggerInfoParams,
  GetTriggerKeywordsParams,
  GetTriggersParams,
  IAlertByKeywordsResponse,
  IAlertByProjectResponse,
  IAlertsTriggersResponse,
  ICreateTriggerParams,
  IGetKeywordsByProjectParams,
  IGetTriggerKeywordsResponse,
  IGetTriggersByKeywordsResponse,
  IGetTriggersByProjectsResponse,
  IKeywordsByProject,
  ITriggerInfo,
  UpdateKeywordTriggerAPIParams,
  UpdateProjectTriggerAPIParams,
  GetAlertKeywordsParams,
  IGetAlertKeywordsResponse,
  ISetViewAlertParams,
  IGetProjectsByAlertsParams,
  IGetProjectsByAlertsResponse,
  ICreateTriggerResponse,
} from './types/types';

/**
 * Fetches keywords by project for a given account.
 *
 * @param {IGetKeywordsByProjectParams} params - The parameters for fetching keywords by project.
 * @returns {Promise<AxiosResponse<IKeywordsByProject>>} The keywords by project response.
 */
const getKeywordsByProjectAPI = async (
  params: IGetKeywordsByProjectParams
): Promise<AxiosResponse<IKeywordsByProject>> => {
  return api.get<IKeywordsByProject>(
    `/accounts/${params.id}/projects/${params.projectId}/keywords`,
    { params: { page: params.page, limit: params.limit } }
  );
};

/**
 * Fetches alert trigger types.
 *
 * @returns {Promise<AxiosResponse<IAlertsTriggersResponse>>} The alert trigger types response.
 */
const getAlertsTriggersTypesAPI = async (): Promise<
  AxiosResponse<IAlertsTriggersResponse>
> => {
  return api.get<IAlertsTriggersResponse>(`/trigger-rules`);
};

/**
 * Creates a trigger for a given account.
 *
 * @param {ICreateTriggerParams} params - The parameters for creating a trigger.
 * @returns {Promise<AxiosResponse<ICreateTriggerResponse>>} The create trigger response.
 */
const createTriggerAPI = async (
  params: ICreateTriggerParams
): Promise<AxiosResponse<ICreateTriggerResponse>> => {
  return api.post<ICreateTriggerResponse>(`/accounts/${params.id}/triggers`, {
    ...params.data,
  });
};

/**
 * Fetches triggers by projects for a given account.
 *
 * @param {GetTriggersParams} params - The parameters for fetching triggers by projects.
 * @returns {Promise<AxiosResponse<IGetTriggersByProjectsResponse>>} The triggers by projects response.
 */
const getTriggersByProjectsAPI = async (
  params: GetTriggersParams
): Promise<AxiosResponse<IGetTriggersByProjectsResponse>> => {
  return api.get<IGetTriggersByProjectsResponse>(
    `/accounts/${params.id}/triggers/by-projects`,
    {
      params: {
        page: params.page + 1,
        limit: params.limit,
        sortBy: params.sortBy,
        sortOrder: params?.sortOrder ? params.sortOrder.toUpperCase() : null,
      },
    }
  );
};

/**
 * Fetches triggers by keywords for a given account.
 *
 * @param {GetTriggersParams} params - The parameters for fetching triggers by keywords.
 * @returns {Promise<AxiosResponse<IGetTriggersByKeywordsResponse>>} The triggers by keywords response.
 */
const getTriggersByKeywordsAPI = async (
  params: GetTriggersParams
): Promise<AxiosResponse<IGetTriggersByKeywordsResponse>> => {
  return api.get<IGetTriggersByKeywordsResponse>(
    `/accounts/${params.id}/triggers/by-keywords`,
    {
      params: {
        page: params.page + 1,
        limit: params.limit,
        sortBy: params.sortBy,
        sortOrder: params?.sortOrder ? params.sortOrder.toUpperCase() : null,
      },
    }
  );
};

/**
 * Bulk deletes triggers by projects for a given account.
 *
 * @param {BulkDeleteTriggersParams} params - The parameters for bulk deleting triggers by projects.
 * @returns {Promise<AxiosResponse<null>>} The bulk delete response.
 */
const bulkDeleteTriggersByProjectsAPI = async (
  params: BulkDeleteTriggersParams
): Promise<AxiosResponse<null>> => {
  return api.post<null>(`/accounts/${params.id}/triggers/delete-by-projects`, {
    ids: params.ids,
  });
};

/**
 * Bulk deletes triggers by keywords for a given account.
 *
 * @param {BulkDeleteTriggersParams} params - The parameters for bulk deleting triggers by keywords.
 * @returns {Promise<AxiosResponse<null>>} The bulk delete response.
 */
const bulkDeleteTriggersByKeywordsAPI = async (
  params: BulkDeleteTriggersParams
): Promise<AxiosResponse<null>> => {
  return api.post<null>(`/accounts/${params.id}/triggers/delete-by-keywords`, {
    ids: params.ids,
  });
};

/**
 * Fetches keywords for a given trigger.
 *
 * @param {GetTriggerKeywordsParams} params - The parameters for fetching trigger keywords.
 * @returns {Promise<AxiosResponse<IGetTriggerKeywordsResponse>>} The trigger keywords response.
 */
const getTriggerKeywordsAPI = async (
  params: GetTriggerKeywordsParams
): Promise<AxiosResponse<IGetTriggerKeywordsResponse>> => {
  return api.get<IGetTriggerKeywordsResponse>(
    `/accounts/${params.id}/triggers/${params.triggerId}/keywords`,
    {
      params: {
        page: params.page + 1,
        limit: params.limit,
        sortBy: params.sortBy,
        sortOrder: params?.sortOrder ? params.sortOrder.toUpperCase() : null,
      },
    }
  );
};

/**
 * Fetches trigger information for a given trigger.
 *
 * @param {GetTriggerInfoParams} params - The parameters for fetching trigger information.
 * @returns {Promise<AxiosResponse<ITriggerInfo>>} The trigger information response.
 */
const getTriggerInfoAPI = async (
  params: GetTriggerInfoParams
): Promise<AxiosResponse<ITriggerInfo>> => {
  return api.get<ITriggerInfo>(
    `/accounts/${params.id}/triggers/${params.triggerId}`
  );
};

/**
 * Bulk deletes keywords from a trigger.
 *
 * @param {BulkDeleteKeywordsFromTriggerParams} params - The parameters for bulk deleting keywords from a trigger.
 * @returns {Promise<AxiosResponse<null>>} The bulk delete response.
 */
const bulkDeleteKeywordsFromTrigger = async (
  params: BulkDeleteKeywordsFromTriggerParams
): Promise<AxiosResponse<null>> => {
  return api.post<null>(
    `/accounts/${params.id}/triggers/remove-keywords-from-trigger`,
    { triggerKeywordIds: params.triggerKeywordIds }
  );
};

/**
 * Updates a project trigger for a given account.
 *
 * @param {UpdateProjectTriggerAPIParams} params - The parameters for updating a project trigger.
 * @returns {Promise<AxiosResponse<null>>} The update project trigger response.
 */
const updateProjectTriggerAPI = async (
  params: UpdateProjectTriggerAPIParams
): Promise<AxiosResponse<null>> => {
  return api.patch<null>(
    `/accounts/${params.id}/triggers/${params.triggerId}/update-project-trigger`,
    { ...params.data }
  );
};

/**
 * Updates a keyword trigger for a given account.
 *
 * @param {UpdateKeywordTriggerAPIParams} params - The parameters for updating a keyword trigger.
 * @returns {Promise<AxiosResponse<null>>} The update keyword trigger response.
 */
const updateKeywordTriggerAPI = async (
  params: UpdateKeywordTriggerAPIParams
): Promise<AxiosResponse<null>> => {
  return api.patch<null>(
    `/accounts/${params.id}/triggers/${params.triggerId}/update-keyword-trigger`,
    { ...params.data }
  );
};

/**
 * Fetches alerts by project for a given account.
 *
 * @param {GetAlertsParams} params - The parameters for fetching alerts by project.
 * @returns {Promise<AxiosResponse<IAlertByProjectResponse>>} The alerts by project response.
 */
const getAlertsByProjectAPI = async (
  params: GetAlertsParams
): Promise<AxiosResponse<IAlertByProjectResponse>> => {
  return api.get<IAlertByProjectResponse>(
    `/accounts/${params.id}/alerts-by-project`,
    {
      params: {
        page: params.page + 1,
        projectIds:
          params.projectIds[0] !== '0' ? params.projectIds : undefined,
        limit: params.limit,
        search: params.search,
        sortBy: params.sortBy,
        sortOrder: params?.sortOrder ? params.sortOrder.toUpperCase() : null,
      },
    }
  );
};

/**
 * Fetches alerts by keywords for a given account.
 *
 * @param {GetAlertsParams} params - The parameters for fetching alerts by keywords.
 * @returns {Promise<AxiosResponse<IAlertByKeywordsResponse>>} The alerts by keywords response.
 */
const getAlertsByKeywordsAPI = async (
  params: GetAlertsParams
): Promise<AxiosResponse<IAlertByKeywordsResponse>> => {
  return api.get<IAlertByKeywordsResponse>(
    `/accounts/${params.id}/alerts-by-keywords`,
    {
      params: {
        page: params.page + 1,
        projectIds:
          params.projectIds[0] !== '0' ? params.projectIds : undefined,
        limit: params.limit,
        search: params.search,
        sortBy: params.sortBy,
        sortOrder: params?.sortOrder ? params.sortOrder.toUpperCase() : null,
      },
    }
  );
};

/**
 * Retrieves alert information by account and alert ID.
 *
 * @param {GetAlertInfoParams} params - Parameters including account ID and alert ID.
 * @returns {Promise<AxiosResponse<IAlertInfo>>} - A promise containing the alert information response.
 */
const getAlertInfoAPI = async (
  params: GetAlertInfoParams
): Promise<AxiosResponse<IAlertInfo>> => {
  return api.get<IAlertInfo>(`/accounts/${params.id}/alerts/${params.alertId}`);
};

/**
 * Retrieves keywords associated with a specific alert.
 *
 * @param {GetAlertKeywordsParams} params - Parameters including account ID, alert ID, page, and limit.
 * @returns {Promise<AxiosResponse<IGetAlertKeywordsResponse>>} - A promise containing the response with alert keywords.
 */
const getAlertKeywordsAPI = async (
  params: GetAlertKeywordsParams
): Promise<AxiosResponse<IGetAlertKeywordsResponse>> => {
  return api.get<IGetAlertKeywordsResponse>(
    `/accounts/${params.id}/alerts/${params.alertId}/keywords`,
    {
      params: {
        page: params.page + 1,
        limit: params.limit,
        sortBy: params.sortBy,
        sortOrder: params?.sortOrder ? params.sortOrder.toUpperCase() : null,
      },
    }
  );
};

/**
 * Sets an alert as viewed by project.
 *
 * @param {ISetViewAlertParams} params - Parameters including account ID and alert ID.
 * @returns {Promise<AxiosResponse<null>>} - A promise indicating the success or failure of the operation.
 */
const setViewAlertByProjectAPI = async (
  params: ISetViewAlertParams
): Promise<AxiosResponse<null>> => {
  return api.post<null>(
    `/accounts/${params.id}/alerts/${params.alertId}/view-alert-by-project`
  );
};

/**
 * Sets an alert as viewed by keywords.
 *
 * @param {ISetViewAlertParams} params - Parameters including account ID and alert ID.
 * @returns {Promise<AxiosResponse<null>>} - A promise indicating the success or failure of the operation.
 */
const setViewAlertByKeywordsAPI = async (
  params: ISetViewAlertParams
): Promise<AxiosResponse<null>> => {
  return api.post<null>(
    `/accounts/${params.id}/alerts/${params.alertId}/view-alert-by-keywords`
  );
};

/**
 * Retrieves projects associated with alerts.
 *
 * @param {IGetProjectsByAlertsParams} params - Parameters including account ID and selection type.
 * @returns {Promise<AxiosResponse<IGetProjectsByAlertsResponse>>} - A promise containing the response with projects associated with alerts.
 */
const getProjectsByAlertsAPI = async (
  params: IGetProjectsByAlertsParams
): Promise<AxiosResponse<IGetProjectsByAlertsResponse>> => {
  return api.get<IGetProjectsByAlertsResponse>(
    `/accounts/${params.id}/alerts/projects?type=${params.selectBy}`
  );
};

export {
  getKeywordsByProjectAPI,
  getAlertsTriggersTypesAPI,
  createTriggerAPI,
  getTriggersByProjectsAPI,
  getTriggersByKeywordsAPI,
  bulkDeleteTriggersByProjectsAPI,
  bulkDeleteTriggersByKeywordsAPI,
  getTriggerKeywordsAPI,
  getTriggerInfoAPI,
  bulkDeleteKeywordsFromTrigger,
  updateProjectTriggerAPI,
  updateKeywordTriggerAPI,
  getAlertsByProjectAPI,
  getAlertsByKeywordsAPI,
  getAlertInfoAPI,
  getAlertKeywordsAPI,
  setViewAlertByProjectAPI,
  setViewAlertByKeywordsAPI,
  getProjectsByAlertsAPI,
};
