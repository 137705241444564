import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

import background from '../../../../../../../../shared/images/backgroundPatternDecorative.svg';
import { StyledProps } from 'styled-components';
import { getPositionTableHeader } from '../../../../../../../../utils';

const CustomDataGrid = styled(DataGridPro)<{
  isSelectedRow: boolean;
  $isSharedTable?: boolean;
  $openTariffPlanBanner: boolean;
}>`
  --unstable_DataGrid-radius: 0px;

  max-width: 100%;
  min-height: 390px;

  border: 1px solid #dedede;
  border-right-width: 0;
  border-left-width: 0;
  background: #fff;

  .MuiDataGrid-virtualScroller,
  .MuiDataGrid-overlayWrapperInner {
    min-height: 390px;
  }

  .MuiDataGrid-columnHeaders {
    line-height: initial !important;
    position: sticky;
    top: ${props =>
      getPositionTableHeader(
        props.$openTariffPlanBanner,
        props.$isSharedTable
      )};
    z-index: 5;

    @media (max-width: 599px) {
      top: ${props => `${props.$isSharedTable ? '0' : '57px'}`};
    }
  }

  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  .MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
  }

  .MuiDataGrid-columnHeaders {
    background-color: #fafafa;
  }

  .MuiDataGrid-columnSeparator {
    opacity: 0 !important;
  }

  .MuiDataGrid-row {
    cursor: ${props => props.isSelectedRow && 'pointer'};
  }

  .MuiDataGrid-row:hover {
    background-color: #f6f6f6 !important;
  }

  .MuiDataGrid-row.Mui-selected {
    background-color: #e8f1fd;
  }

  .MuiDataGrid-footerContainer {
    display: none;
  }

  .MuiDataGrid-main {
    z-index: 10;
    overflow: visible;
  }

  .MuiDataGrid-row--lastVisible {
    .MuiDataGrid-cell {
      border-bottom-color: #dedede !important;
    }
  }
`;

const StyledCustomNoRowsOverlay = styled(Grid)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${background});
  background-position: top;
  background-repeat: no-repeat;
`;

const EmptyState = styled(Grid)`
  max-width: 365px;
  margin-top: 40px;
  padding: 0 16px;
`;

const Content = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface PinActionColumnProps {
  colIndex: number;
}

const PinNameColumn = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width:900px) {
    .MuiDataGrid-cell[data-field='name'] {
      position: sticky;
      left: 50px;
      background-color: white;
      border-right: 1px solid #dedede;
    }

    .MuiDataGrid-cell[data-field='__check__'] {
      position: sticky;
      left: 0;
      background-color: white;
    }

    .Mui-selected .MuiDataGrid-cell[data-field='name'],
    .Mui-selected .MuiDataGrid-cell[data-field='__check__'] {
      background-color: #e8f1fd;
    }

    .MuiDataGrid-row:hover .MuiDataGrid-cell[data-field='name'],
    .MuiDataGrid-row:hover .MuiDataGrid-cell[data-field='__check__'] {
      background-color: #f6f6f6 !important;
    }

    .MuiDataGrid-columnHeader[data-field='name'] {
      position: sticky;
      left: 50px;
    }

    .MuiDataGrid-columnHeader[data-field='__check__'] {
      position: sticky;
      left: 0;
    }
  },
  
`;

const PinActionColumn = styled(Grid)<StyledProps<PinActionColumnProps>>(
  ({ colIndex }) => `
    .MuiDataGrid-cell[aria-colindex="${colIndex}"] {
      position: sticky;
      right: 0;
      background-color: white;
      padding: 0;
      
      > div {
        border-left: 1px solid #DEDEDE;
        padding: 0 5px 0 4px;
      }
    }
    
    .MuiDataGrid-columnHeader[aria-colindex="${colIndex}"] {
      padding: 0;
    }
    
    .Mui-selected .MuiDataGrid-cell[aria-colindex="${colIndex}"] {
      background-color: #e8f1fd;
    }
    
    .MuiDataGrid-row:hover .MuiDataGrid-cell[aria-colindex="${colIndex}"] {
      background-color: #f6f6f6 !important;
    }
  `
);

const PinRefreshColumn = styled(Grid)<StyledProps<PinActionColumnProps>>(
  ({ colIndex }) => `
    .MuiDataGrid-cell[aria-colindex="${colIndex}"] {
      position: sticky;
      right: 50px;
      background-color: white;
      padding: 0;
      border-left: 1px solid #DEDEDE;
    }
    
    .MuiDataGrid-columnHeader[aria-colindex="${colIndex}"] {
      padding: 0;
    }
    
    .Mui-selected .MuiDataGrid-cell[aria-colindex="${colIndex}"] {
      background-color: #e8f1fd;
    }
    
    .MuiDataGrid-row:hover .MuiDataGrid-cell[aria-colindex="${colIndex}"] {
      background-color: #f6f6f6 !important;
    }
  `
);

const PinActionColumnForKeywordRankingsTable = styled(Grid)<
  StyledProps<PinActionColumnProps>
>(
  ({ colIndex }) => `
    .MuiDataGrid-cell[aria-colindex="${colIndex}"] {
      position: sticky;
      right: 0;
      background-color: white;
      padding: 0;
    }
    
    .MuiDataGrid-columnHeader[aria-colindex="${colIndex}"] {
      padding: 0;
    }
    
    .Mui-selected .MuiDataGrid-cell[aria-colindex="${colIndex}"] {
      background-color: #e8f1fd;
    }
    .MuiDataGrid-row:hover .MuiDataGrid-cell[aria-colindex="${colIndex}"] {
      background-color: #f6f6f6 !important;
    }
  `
);

const PinRefreshColumnForKeywordRankingsTable = styled(Grid)<
  StyledProps<PinActionColumnProps>
>(
  ({ colIndex }) => `
    .MuiDataGrid-cell[aria-colindex="${colIndex}"] {
      position: sticky;
      right: 50px;
      background-color: white;
      padding: 0;
      border-left: 1px solid #DEDEDE;
    }
    
    .MuiDataGrid-columnHeader[aria-colindex="${colIndex}"] {
      padding: 0;
    }
    
    .Mui-selected .MuiDataGrid-cell[aria-colindex="${colIndex}"] {
      background-color: #e8f1fd;
    }
    .MuiDataGrid-row:hover .MuiDataGrid-cell[aria-colindex="${colIndex}"] {
      background-color: #f6f6f6 !important;
    }
  `
);

export {
  PinNameColumn,
  CustomDataGrid,
  StyledCustomNoRowsOverlay,
  EmptyState,
  Content,
  PinActionColumn,
  PinRefreshColumn,
  PinActionColumnForKeywordRankingsTable,
  PinRefreshColumnForKeywordRankingsTable,
};
