import {
  useSharedLinks,
  useSharedLinkContentsListTable,
} from '../../../../../../hooks';
import { Grid } from '@mui/material';
import { CustomDataGrid } from '../../../../../projects/components/Projects/components/TableProjects/components/Table/styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import ROUTES from '../../../../../../routes/constants';
import { TableNoData } from '../../../../../../shared/components';
import { TableSkeleton } from '../../../../../../shared/graphSkeletons';
import { encryptData } from '../../../../../../utils/cryptoJs';
import { useAppSelector } from '../../../../../../store';
import { GridRowParams } from '@mui/x-data-grid';
import {
  setTableColumns,
  TABLES_STORAGE_KEYS,
} from '../../../../../projects/tablesColumnStase';

const Table = () => {
  const navigate = useNavigate();

  const { columns, columnsSkeleton } = useSharedLinkContentsListTable();

  const { onSetSortModelSharedProjects } = useSharedLinks();

  const columnVisibilityModel = useAppSelector(
    state =>
      state.sharedLinks.tables.sharedLinkContentsList.columnVisibilityModel
  );
  const rowSelectionModel = useAppSelector(
    state => state.sharedLinks.tables.sharedLinkContentsList.rowSelectionModel
  );
  const sharedLinkContentsList = useAppSelector(
    state => state.sharedLinks.sharedLinkContentsList
  );
  const pageSize = useAppSelector(
    state =>
      state.sharedLinks.tables.sharedLinkContentsList.paginationModel.pageSize
  );
  const openTariffPlanBanner = useAppSelector(
    state => state.auth.openTariffPlanBanner
  );

  const rows = useMemo(
    () =>
      sharedLinkContentsList?.items?.length ? sharedLinkContentsList.items : [],
    [sharedLinkContentsList?.items]
  );

  const [itemCount, setItemCount] = useState(
    sharedLinkContentsList?.meta?.itemCount || pageSize
  );

  const arrayOfObjects = useMemo(
    () =>
      Array.from({ length: itemCount }, (_, index) => ({
        id: index + 1,
      })),
    [itemCount]
  );

  const renderRows = useMemo(() => {
    return !sharedLinkContentsList?.items ? arrayOfObjects : rows;
  }, [arrayOfObjects, sharedLinkContentsList?.items, rows]);

  const renderColumns = useMemo(() => {
    return !sharedLinkContentsList?.items ? columnsSkeleton : columns;
  }, [columns, columnsSkeleton, sharedLinkContentsList?.items]);

  useEffect(() => {
    if (sharedLinkContentsList?.meta?.itemCount) {
      setItemCount(sharedLinkContentsList.meta.itemCount);
    }
  }, [sharedLinkContentsList?.meta?.itemCount]);

  const handleRowClick = useCallback(
    (params: GridRowParams<any>) => {
      if (sharedLinkContentsList?.items)
        navigate(
          ROUTES.dynamic.sharedProject(encryptData(params.id.toString()))
        );
    },
    [navigate, sharedLinkContentsList?.items]
  );

  useEffect(() => {
    setTableColumns(
      TABLES_STORAGE_KEYS.sharedWithYouPage,
      columnVisibilityModel
    );
  }, [columnVisibilityModel]);

  return (
    <Grid item xs={12}>
      <CustomDataGrid
        autoHeight
        isSelectedRow
        columnHeaderHeight={40}
        rowHeight={40}
        rows={renderRows}
        rowSelectionModel={rowSelectionModel}
        columns={renderColumns}
        onSortModelChange={onSetSortModelSharedProjects}
        onRowClick={handleRowClick}
        components={{
          NoRowsOverlay: !sharedLinkContentsList ? TableSkeleton : TableNoData,
        }}
        columnVisibilityModel={columnVisibilityModel}
        keepNonExistentRowsSelected
        hideFooterPagination
        disableRowSelectionOnClick
        disableColumnMenu
        $isSharedTable={true}
        $openTariffPlanBanner={openTariffPlanBanner}
      />
    </Grid>
  );
};

export default Table;
