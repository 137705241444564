import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const ImprovedVsDeclinedContainer = styled(Grid)`
  ${props => props.theme.breakpoints.down('lg')} {
    padding: 24px;
  }

  ${props => props.theme.breakpoints.down('md')} {
    padding: 16px;
  }

  padding: 24px;
`;

export { ImprovedVsDeclinedContainer };
