import React from 'react';
import { Outlet } from 'react-router-dom';
import KeywordsLimitExceededModal from '../components/KeywordsLimitExceededModal/KeywordsLimitExceededModal';
import CannotAddExcessKeywordsModal from '../components/CannotAddExcessKeywordsModal/CannotAddExcessKeywordsModal';
import KeywordUpdateScheduledModal from '../components/KeywordUpdateScheduledModal/KeywordUpdateScheduledModal';
import CannotRefreshExcessKeywordsModal from '../components/CannotRefreshExcessKeywordsModal/CannotRefreshExcessKeywordsModal';
import YouveReachedYourRefreshLimitForTodayModal from '../components/YouveReachedYourRefreshLimitForTodayModal/YouveReachedYourRefreshLimitForTodayModal';
import YourSubscriptionHasExpiredModal from '../components/FreeTialSubscriptionEnded/YourSubscriptionHasExpiredModal';
import YourFreeTrialHasExpiredModal from '../components/FreeTialSubscriptionEnded/YourFreeTrialHasExpiredModal';
import ActionRequiredCancelSubscriptionModal from '../components/ActionRequiredCancelSubscriptionModal/ActionRequiredCancelSubscriptionModal';
import NoticeAccountDeletionCancelledModal from '../components/NoticeAccountDeletionCancelledModal/NoticeAccountDeletionCancelledModal';
import TriggersLimitExceededModal from '../components/TriggersLimitExceededModal/TriggersLimitExceededModal';

const AccountLimitsLayout = () => {
  return (
    <>
      <Outlet />
      <NoticeAccountDeletionCancelledModal />
      <ActionRequiredCancelSubscriptionModal />
      <YourFreeTrialHasExpiredModal />
      <YourSubscriptionHasExpiredModal />
      <YouveReachedYourRefreshLimitForTodayModal />
      <CannotRefreshExcessKeywordsModal />
      <KeywordUpdateScheduledModal />
      <CannotAddExcessKeywordsModal />
      <KeywordsLimitExceededModal />
      <TriggersLimitExceededModal />
    </>
  );
};

export default AccountLimitsLayout;
