import React from 'react';

import { CustomFormHelperTextProps } from './types';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { FormHelperText, Typography } from '@mui/material';

import { FormHelperTextErrorWrapper } from './styles';

const CustomFormHelperText = ({ error }: CustomFormHelperTextProps) => {
  return (
    <FormHelperTextErrorWrapper>
      <ErrorOutlineIcon fontSize='small' />
      <Typography
        paddingLeft={'5px'}
        variant='body2'
        sx={{ margin: '0px', fontSize: '122px' }}
      >
        <FormHelperText error>{error}</FormHelperText>
      </Typography>
    </FormHelperTextErrorWrapper>
  );
};

export default CustomFormHelperText;
