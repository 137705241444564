import { FormHelperText, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import SerpNestLogo from '../../../../shared/images/SerpNestLogo';
import ROUTES from '../../../../routes/constants';

import { GrayButton } from '../../../../shared/buttons/styles';
import { Paper } from '../../../../shared/paper/styles';
import { LogoContainer, SingUpLabel, Wrapper } from './styles';
import { useAuth } from '../../../../hooks';
import { CustomFormHelperText } from '../../../../shared/components';
import CustomHelperText from '../ForgetPasswordStep3/components/CustomHelperText';
import { useAppSelector } from '../../../../store';

/**
 * Component representing the second step of the forget password process.
 *
 * @component
 * @returns {JSX.Element} The ForgetPasswordStep2 component.
 */
const ForgetPasswordStep2 = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { onResendingForgotPasswordLetterThunkEmail } = useAuth();

  const isLoading = useAppSelector(state => state.auth.isLoading);

  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const pathSnippets = pathname.split('/');
  const email = pathSnippets[2];

  /**
   * Redirects the user to the login page.
   */
  const onBack = () => {
    navigate(ROUTES.login);
  };

  /**
   * Handles resending the forgot password email.
   */
  const onResendEmail = () => {
    setSuccess(false);
    setError(false);
    onResendingForgotPasswordLetterThunkEmail({
      email,
      successFn: setSuccess,
      errorFn: setError,
      errorNavigate: () => {
        navigate(ROUTES.forgotPasswordContactSupport);
      },
    });
  };

  return (
    <Wrapper>
      <Grid container>
        <Grid xs={1} lg={4} md={2} />
        <Grid xs={10} lg={4} md={8}>
          <Paper
            sx={{
              p: 4,
            }}
          >
            <LogoContainer>
              <SerpNestLogo />
            </LogoContainer>
            <SingUpLabel variant='h5'>Check your email</SingUpLabel>
            <Typography sx={{ mt: 1 }} variant='body2'>
              Please check the email address
            </Typography>
            <Typography variant='body2' sx={{ mb: 2 }}>
              <b>{email}</b> for instructions to reset your password.
            </Typography>
            {success && (
              <FormHelperText id='success'>
                <CustomHelperText
                  text={
                    'Email has been resent. Please check your inbox or spam folder'
                  }
                />
              </FormHelperText>
            )}
            {error && (
              <FormHelperText error id='error'>
                <CustomFormHelperText
                  error={
                    "We have already sent you an email. Please check your inbox or spam folder. If the email hasn't arrived, please try again later."
                  }
                />
              </FormHelperText>
            )}
            <GrayButton
              size='large'
              outline
              fullWidth
              variant='text'
              sx={{
                mt: 2,
              }}
              disabled={isLoading}
              onClick={onResendEmail}
            >
              Resend email
            </GrayButton>
            <GrayButton
              size='large'
              fullWidth
              variant='text'
              sx={{
                mt: 2,
              }}
              startIcon={<ArrowBackIcon />}
              onClick={onBack}
            >
              Back to log in
            </GrayButton>
          </Paper>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default ForgetPasswordStep2;
