import React, { useState } from 'react';
import { RefreshAllKeywordsActionProps } from './types';
import {
  ListItemTypography,
  StyledListItem,
  StyledListItemButton,
} from '../styles';
import RefreshIcon from '../../../../../../../../../shared/images/RefreshIcon';
import RefreshAllKeywordsModal from '../../../../../../modals/RefreshAllKeywordsModal/RefreshAllKeywordsModal';
import { useProjects } from '../../../../../../../../../hooks';
import useAccountLimits from '../../../../../../../../auth/hooks/useAccountLimits';
import { useAppSelector } from '../../../../../../../../../store';

const RefreshAllKeywordsAction: React.FC<RefreshAllKeywordsActionProps> = ({
  projectId,
  keywordCount,
  popupClose,
}) => {
  const { onSetRowSelectionModel, onSetRefreshAllKeywordsModal } =
    useProjects();
  const { onCheckSubscriptionExpiration } = useAccountLimits();

  const open = useAppSelector(
    state => state.projects.modals.refreshAllKeywords
  );

  const handleOpenModal = () => {
    const isExpiration = onCheckSubscriptionExpiration();
    if (!isExpiration) {
      onSetRefreshAllKeywordsModal(true);
    }
  };
  const handleCloseModal = () => {
    onSetRowSelectionModel([]);
    onSetRefreshAllKeywordsModal(false);
    popupClose();
  };

  return (
    <>
      <StyledListItem onClick={handleOpenModal}>
        <StyledListItemButton>
          <RefreshIcon />
          <ListItemTypography marginLeft='8px'>
            Refresh All Keywords
          </ListItemTypography>
        </StyledListItemButton>
      </StyledListItem>
      <RefreshAllKeywordsModal
        onClose={handleCloseModal}
        open={open}
        projectIds={[projectId]}
        keywordCount={keywordCount}
      />
    </>
  );
};

export default RefreshAllKeywordsAction;
