import React, { useMemo } from 'react';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { Popover } from '../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import { PrimaryButtonCustom } from '../../../../../../../shared/buttons/styles';
import DownIcon from '../../../../../../../shared/images/DownIcon';
import ListActions from './ListActions/ListActions';
import { useParams } from 'react-router';
import { useProjects } from '../../../../../../../hooks';
import { decryptData } from '../../../../../../../utils/cryptoJs';
import { useAppSelector } from '../../../../../../../store';

const ProjectOptions = () => {
  const { id } = useParams();

  const { onGetDeviceTypes } = useProjects();

  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );

  const deviceTypes = useAppSelector(state => state.projects.deviceTypes);

  const decryptId = useMemo(() => {
    if (id) {
      return decryptData(id);
    }
  }, [id]);

  if (!decryptId || !projectExpanded) {
    return null;
  }

  return (
    <PopupState variant='popover'>
      {popupState => (
        <>
          <PrimaryButtonCustom
            variant='contained'
            size='small'
            $padding='8px 16px'
            endIcon={<DownIcon />}
            {...bindTrigger(popupState)}
            onClick={event => {
              !deviceTypes.length && onGetDeviceTypes();
              bindTrigger(popupState).onClick(event);
            }}
          >
            Options
          </PrimaryButtonCustom>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <ListActions
              projectId={Number(decryptId)}
              keywordCount={projectExpanded.keywordCount}
              disableExportCSV={projectExpanded.isUpdated}
              popupClose={popupState.close}
            />
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export default ProjectOptions;
