import { Fade, Grid, Modal, Typography, useMediaQuery } from '@mui/material';
import React from 'react';

import { OverrideFrequencyForMultipleProjectsProps } from './types';

import { ModalContainer, ModalWrapper } from '../styles';
import { CloseDeletePopoverOne } from '../../../../../../auth/components/Settings/components/Account/styles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../../../shared/buttons/styles';
import { ButtonsWrapper } from '../../../../../../auth/components/Settings/components/Team/components/customTableStyles';
import CloseIcon from '@mui/icons-material/Close';

const OverrideFrequencyForMultipleProjects: React.FC<
  OverrideFrequencyForMultipleProjectsProps
> = ({ open, handleClose, handleSubmit }) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  return (
    <Modal
      aria-labelledby='add-keywords-modal-title'
      aria-describedby='add-keywords-description'
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <ModalWrapper>
          <ModalContainer container xs={12} sx={{ gap: '8px' }}>
            <Grid xs={12}>
              <Grid item xs={12} sx={{ position: 'relative' }}>
                <CloseDeletePopoverOne onClick={handleClose}>
                  <CloseIcon fontSize='small' />
                </CloseDeletePopoverOne>
                <Typography
                  fontSize='18px'
                  fontWeight='600'
                  lineHeight='28px'
                  color='#101828'
                >
                  Override frequency for multiple projects?
                </Typography>
              </Grid>
              <Grid item xs={12} paddingTop='28px'>
                <Typography
                  fontSize='14px'
                  fontWeight='400'
                  lineHeight='24px'
                  color='#475467'
                >
                  You have selected multiple projects. Please note that setting
                  a new frequency here will override the current frequency
                  settings for all selected projects.
                  <br />
                  <br />
                  Are you sure you want to proceed?
                </Typography>
              </Grid>
              <ButtonsWrapper
                container
                xs={12}
                sx={{ paddingTop: '24px !important' }}
              >
                <Grid item xs={extraSmallScreen && 12}>
                  <GrayButtonSmall
                    fullWidth={extraSmallScreen}
                    size='small'
                    outline
                    onClick={handleClose}
                  >
                    Cancel
                  </GrayButtonSmall>
                </Grid>
                <Grid item xs={extraSmallScreen && 12}>
                  <PrimaryButtonSmall
                    fullWidth={extraSmallScreen}
                    variant='contained'
                    size='small'
                    type='submit'
                    onClick={handleSubmit}
                  >
                    Proceed
                  </PrimaryButtonSmall>
                </Grid>
              </ButtonsWrapper>
            </Grid>
          </ModalContainer>
        </ModalWrapper>
      </Fade>
    </Modal>
  );
};

export default OverrideFrequencyForMultipleProjects;
