import { styled } from '@mui/system';
import { NoBorderButton } from '../../../../buttons/styles';

const StyledNoBorderButton = styled(NoBorderButton)`
  padding: 8px 16px !important;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #101828;
  border-radius: 0 !important;
`;

export { StyledNoBorderButton };
