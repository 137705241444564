import React from 'react';

const InfoIcon: React.FC = () => (
  <svg
    width='2'
    height='8'
    viewBox='0 0 2 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_2689_181281)'>
      <path
        d='M0 3H2V8H0V3ZM1 0C1.55 0 2 0.45 2 1C2 1.55 1.55 2 1 2C0.45 2 0 1.55 0 1C0 0.45 0.45 0 1 0Z'
        fill='#98A2B3'
      />
    </g>
    <defs>
      <clipPath id='clip0_2689_181281'>
        <rect width='2' height='8' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export default InfoIcon;
