import React, { useState } from 'react';

import SmallTrashIcon from '../../../../../../../../../shared/images/SmallTrashIcom';
import { DeleteProjectsActionProps } from './types';
import { useAuth, useProjects } from '../../../../../../../../../hooks';

import { ListItemTypography, StyledListItem } from '../styles';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import CustomToast from '../../../../../../../../../shared/CustomToast/CustomToast';
import { closeAndUndoSnackbarAction } from '../../../../../../../../../utils/snackbar';
import { StyledListItemButton } from '../../EllipsisMenu/styles';
import DeleteProjectModal from '../../../../../../modals/DeleteProjectModal/DeleteProjectModal';
import { useAppSelector } from '../../../../../../../../../store';

const DeleteProjectsAction: React.FC<DeleteProjectsActionProps> = ({
  popupClose,
}) => {
  const { onGetMyAccount, onGetAccountLimit } = useAuth();
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const {
    onSetDeleteElementsProjectsTable,
    onGetProjects,
    onBulkDeleteProjects,
    onGetAccountTags,
    onSetRowSelectionModel,
    onGetProjectsListForInvite,
  } = useProjects();

  const selectedTags = useAppSelector(state => state.projects.selectedTags);
  const search = useAppSelector(state => state.projects.tables.projects.search);
  const paginationModel = useAppSelector(
    state => state.projects.tables.projects.paginationModel
  );
  const sortModel = useAppSelector(
    state => state.projects.tables.projects.sortModel
  );
  const columnVisibilityModel = useAppSelector(
    state => state.projects.tables.projects.columnVisibilityModel
  );
  const frequencyFilter = useAppSelector(
    state => state.projects.tables.projects.frequencyFilter
  );
  const rowSelectionModel = useAppSelector(
    state => state.projects.tables.projects.rowSelectionModel
  );

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleHardDelete = () => {
    onBulkDeleteProjects({
      accountId: currentAccount.accountId,
      projectIds: rowSelectionModel,
      successFn: () => {
        onGetAccountLimit({ id: currentAccount.accountId });
        onGetProjectsListForInvite({
          accountId: currentAccount.accountId,
          search: '',
        });
        onSetRowSelectionModel([]);
        onGetAccountTags({ accountId: currentAccount.accountId });
        onGetProjects({
          accountId: currentAccount.accountId,
          params: {
            frequencyName: frequencyFilter,
            page: paginationModel.page,
            limit: paginationModel.pageSize,
            tagIds: selectedTags.map(item => item.id).join(),
            search: search,
            sortBy: sortModel[0]?.field,
            sortOrder: sortModel[0]?.sort,
            dailyAverage: columnVisibilityModel.dailyAverage,
          },
        });
        onGetMyAccount(currentAccount.accountId);
        onSetDeleteElementsProjectsTable([]);
      },
    });
  };

  const handleDeleteProject = () => {
    onSetDeleteElementsProjectsTable(rowSelectionModel);

    popupClose();
    let undoDelete = false;
    const onUndo = () => {
      undoDelete = true;
      onSetDeleteElementsProjectsTable([]);
    };
    enqueueSnackbar(
      <CustomToast
        message='Projects Removed'
        submessage={`${rowSelectionModel.length} projects have been successfully removed.`}
      />,
      {
        action: (snackbarId: SnackbarKey) =>
          closeAndUndoSnackbarAction(snackbarId, onUndo),
        variant: 'error',
      }
    );
    setTimeout(() => {
      if (!undoDelete) {
        handleHardDelete();
      }
    }, 5000);
  };

  return (
    <>
      <StyledListItem onClick={handleOpenModal}>
        <StyledListItemButton>
          <SmallTrashIcon />
          <ListItemTypography marginLeft='8px' sx={{ color: '#B42318' }}>
            Delete Projects
          </ListItemTypography>
        </StyledListItemButton>
      </StyledListItem>
      <DeleteProjectModal
        isMultiple={true}
        onClose={handleCloseModal}
        onDelete={handleDeleteProject}
        open={openModal}
        count={rowSelectionModel.length}
      />
    </>
  );
};

export default DeleteProjectsAction;
