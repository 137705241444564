import React from 'react';

const PDFIcon = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3.0998 1.6C3.0998 0.88203 3.68183 0.3 4.3998 0.3H10.7998C10.8483 0.3 10.8948 0.319264 10.9291 0.353553L15.2463 4.67071C15.2805 4.705 15.2998 4.75151 15.2998 4.8V14.4C15.2998 15.118 14.7178 15.7 13.9998 15.7H4.39981C3.68184 15.7 3.0998 15.118 3.0998 14.4V1.6Z'
      fill='white'
      stroke='#D0D5DD'
      strokeWidth='0.6'
    />
    <path
      d='M10.7998 0.199951V3.19995C10.7998 4.08361 11.5161 4.79995 12.3998 4.79995H15.3998'
      stroke='#D0D5DD'
      strokeWidth='0.6'
    />
    <rect
      x='0.399902'
      y='7.1001'
      width='10.4'
      height='6.6'
      rx='0.8'
      fill='#D92D20'
    />
    <path
      d='M1.93302 11.9001V8.99106H3.08074C3.30139 8.99106 3.48936 9.0332 3.64466 9.11748C3.79997 9.20081 3.91834 9.31681 3.99978 9.46549C4.08216 9.61321 4.12336 9.78367 4.12336 9.97685C4.12336 10.17 4.08169 10.3405 3.99836 10.4882C3.91502 10.6359 3.79429 10.751 3.63614 10.8334C3.47894 10.9158 3.2886 10.957 3.06512 10.957H2.33358V10.4641H2.96569C3.08406 10.4641 3.1816 10.4437 3.2583 10.403C3.33595 10.3613 3.39372 10.304 3.4316 10.2311C3.47042 10.1572 3.48983 10.0725 3.48983 9.97685C3.48983 9.88026 3.47042 9.79598 3.4316 9.72401C3.39372 9.65109 3.33595 9.59475 3.2583 9.55498C3.18065 9.51426 3.08216 9.4939 2.96285 9.4939H2.54807V11.9001H1.93302ZM5.55411 11.9001H4.52286V8.99106H5.56263C5.85525 8.99106 6.10714 9.04929 6.31832 9.16577C6.52949 9.2813 6.69189 9.44749 6.80553 9.66435C6.92011 9.88121 6.97741 10.1407 6.97741 10.4428C6.97741 10.7458 6.92011 11.0062 6.80553 11.224C6.69189 11.4418 6.52854 11.609 6.31547 11.7254C6.10335 11.8419 5.84957 11.9001 5.55411 11.9001ZM5.13792 11.3732H5.52854C5.71036 11.3732 5.8633 11.341 5.98735 11.2766C6.11235 11.2112 6.2061 11.1104 6.2686 10.974C6.33205 10.8367 6.36377 10.6596 6.36377 10.4428C6.36377 10.2278 6.33205 10.0521 6.2686 9.91577C6.2061 9.77941 6.11282 9.67903 5.98877 9.61463C5.86472 9.55024 5.71178 9.51804 5.52996 9.51804H5.13792V11.3732ZM7.43302 11.9001V8.99106H9.35915V9.49816H8.04807V10.1913H9.23131V10.6984H8.04807V11.9001H7.43302Z'
      fill='white'
    />
  </svg>
);

export default PDFIcon;
