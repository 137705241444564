import React, { useMemo } from 'react';
import { List, ListItemButton } from '@mui/material';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { GrayButtonCustom } from '../../../../../../../../shared/buttons/styles';
import SettingsIcon from '../../../../../../../../shared/images/SettingsIcon';
import { Popover } from '../../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import {
  ListItemTypography,
  MainContainer,
  PopupLabel,
  ResetDefaultButton,
  StyledListItem,
} from '../../../../../../../projects/components/Projects/components/TableProjects/components/Header/components/PopupTableSettings/styles';
import { CustomCheckbox } from '../../../../../../../../shared/checkbox/styles';
import CustomCheckboxImage from '../../../../../../../../shared/images/CustomCheckbox';
import CheckedIcon from '../../../../../../../../shared/images/CheckedIcon';
import { renderListItemTitle, renderListItemTitleByKeywords } from './utils';
import useAlerts from '../../../../../../hooks/useAlerts';
import {
  IColumnVisibilityKeyAlertsByKeywords,
  IColumnVisibilityKeyAlertsByProject,
} from '../../../../../../types/types';

interface PopupTableSettingsProps {
  selectBy: 'by Project' | 'by Keywords';
}

/**
 * Component for managing the table settings popover.
 * Allows the user to show or hide columns in the table.
 */
const PopupTableSettings: React.FC<PopupTableSettingsProps> = ({
  selectBy,
}) => {
  const {
    onSetColumnVisibilityModelEmailAlerts,
    onResetColumnVisibilityModelAlerts,
    onResetColumnVisibilityModelAlertsByKeywords,
    onSetColumnVisibilityModelAlertsByKeyword,
    tables: {
      alerts: {
        columnVisibilityModelByProject,
        columnVisibilityModelByKeywords,
      },
    },
  } = useAlerts();

  const handleResetColumnVisibilityModel = () => {
    selectBy === 'by Keywords'
      ? onResetColumnVisibilityModelAlertsByKeywords()
      : onResetColumnVisibilityModelAlerts();
  };

  const ListColumns = useMemo(() => {
    if (selectBy === 'by Keywords') {
      return (
        <List>
          {Object.keys(columnVisibilityModelByKeywords).map(item => {
            const columnKey = item as IColumnVisibilityKeyAlertsByKeywords;
            return (
              <StyledListItem
                key={item}
                disablePadding
                checkBackgroundColor={
                  columnVisibilityModelByKeywords[columnKey]
                    ? '#EEF3FF'
                    : undefined
                }
              >
                <ListItemButton
                  onClick={() =>
                    onSetColumnVisibilityModelAlertsByKeyword(
                      columnKey,
                      !columnVisibilityModelByKeywords[columnKey]
                    )
                  }
                >
                  <CustomCheckbox
                    checked={columnVisibilityModelByKeywords[columnKey]}
                    icon={<CustomCheckboxImage />}
                    checkedIcon={<CheckedIcon />}
                    disableFocusRipple
                  />

                  <ListItemTypography>
                    {renderListItemTitleByKeywords(columnKey)}
                  </ListItemTypography>
                </ListItemButton>
              </StyledListItem>
            );
          })}
        </List>
      );
    }
    return (
      <List>
        {Object.keys(columnVisibilityModelByProject).map(item => {
          const columnKey = item as IColumnVisibilityKeyAlertsByProject;
          return (
            <StyledListItem
              key={item}
              disablePadding
              checkBackgroundColor={
                columnVisibilityModelByProject[columnKey]
                  ? '#EEF3FF'
                  : undefined
              }
            >
              <ListItemButton
                onClick={() =>
                  onSetColumnVisibilityModelEmailAlerts(
                    columnKey,
                    !columnVisibilityModelByProject[columnKey]
                  )
                }
              >
                <CustomCheckbox
                  checked={columnVisibilityModelByProject[columnKey]}
                  icon={<CustomCheckboxImage />}
                  checkedIcon={<CheckedIcon />}
                  disableFocusRipple
                />

                <ListItemTypography>
                  {renderListItemTitle(columnKey)}
                </ListItemTypography>
              </ListItemButton>
            </StyledListItem>
          );
        })}
      </List>
    );
  }, [
    columnVisibilityModelByKeywords,
    columnVisibilityModelByProject,
    onSetColumnVisibilityModelAlertsByKeyword,
    onSetColumnVisibilityModelEmailAlerts,
    selectBy,
  ]);

  return (
    <>
      <PopupState variant='popover'>
        {popupState => (
          <>
            <GrayButtonCustom
              $padding='7px 12px'
              size='small'
              outline
              startIcon={<SettingsIcon stroke='#344054' />}
              {...bindTrigger(popupState)}
            >
              Table Settings
            </GrayButtonCustom>

            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MainContainer>
                <PopupLabel>Show or Hide Columns</PopupLabel>
                <ResetDefaultButton onClick={handleResetColumnVisibilityModel}>
                  Reset to default
                </ResetDefaultButton>
                {ListColumns}
              </MainContainer>
            </Popover>
          </>
        )}
      </PopupState>
    </>
  );
};

export default PopupTableSettings;
