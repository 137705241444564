import React, { useEffect, useMemo } from 'react';
import { Fade, Grid, Modal, Typography, useMediaQuery } from '@mui/material';
import { useSharedLinks } from '../../../../../hooks';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  ModalContainer,
  ModalWrapper,
} from '../../../../projects/components/Projects/components/ProjectRedactorModal/styles';
import { CloseDeletePopoverOne } from '../../../../auth/components/Settings/components/Account/styles';
import { ButtonsWrapper } from '../../../../auth/components/Settings/components/Team/components/customTableStyles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../shared/buttons/styles';
import { CustomSwitch } from '../../../../../shared/Switch/styles';
import { CustomSwitchContainer } from './styles';
import { EditSharedLinkModalProps } from '../EditSharedLinkModal/types';
import { getSharedLinkToken } from '../../../../../utils/localStorage';
import { useParams } from 'react-router';
import { checkObjectProperties } from '../../../../projects/utils';
import { DEFAULT_KEYWORD_POSITIONS_MODEL } from '../../../../projects/constants';
import { decryptData } from '../../../../../utils/cryptoJs';
import { useAppSelector } from '../../../../../store';

const SharedLinkReportTableColumnsModal: React.FC<EditSharedLinkModalProps> = ({
  linkId,
  onClose,
  open,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const { projectId } = useParams();

  const decryptId = useMemo(() => {
    if (projectId) {
      return decryptData(projectId);
    }
  }, [projectId]);

  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const {
    onGetSharedProjectKeywordRankings,
    onEditSharedLink,
    ongGetSharedLinkSettings,
  } = useSharedLinks();

  const projectSharedDeviceType = useAppSelector(
    state => state.sharedLinks.projectSharedDeviceType
  );
  const keywordPositionModel = useAppSelector(
    state =>
      state.sharedLinks.projectGraphs.keywordRankings.table.keywordPositionModel
  );
  const sortModel = useAppSelector(
    state => state.sharedLinks.projectGraphs.keywordRankings.table.sortModel
  );
  const search = useAppSelector(
    state => state.sharedLinks.projectGraphs.keywordRankings.table.search
  );
  const paginationModel = useAppSelector(
    state =>
      state.sharedLinks.projectGraphs.keywordRankings.table.paginationModel
  );
  const sharedLinkSettings = useAppSelector(
    state => state.sharedLinks.sharedLinkSettings
  );
  const selectedTags = useAppSelector(
    state => state.sharedLinks.projectGraphs.keywordRankings.table.selectedTags
  );
  const projectShared = useAppSelector(
    state => state.sharedLinks.projectShared
  );

  const formik = useFormik({
    initialValues: {
      position: false,
      oneDayChange: false,
      sevenDayChange: false,
      thirtyDayChange: false,
      startingRank: false,
      bestRank: false,
      lifeTimeChange: false,
      volume: false,
      url: false,
      updated: false,
      message: '',
    },
    validationSchema: Yup.object().shape({
      projects: Yup.array().min(1, 'Project is required.'),
    }),
    onSubmit: (values, { setStatus, setSubmitting, setErrors, resetForm }) => {
      const sharedLinkToken = getSharedLinkToken();
      if (currentAccount?.accountId) {
        const requestData = {
          accountId: currentAccount.accountId,
          linkId: linkId,
          position: values.position,
          oneDayChange: values.oneDayChange,
          sevenDayChange: values.sevenDayChange,
          thirtyDayChange: values.thirtyDayChange,
          startingRank: values.startingRank,
          bestRank: values.bestRank,
          lifeTimeChange: values.lifeTimeChange,
          volume: values.volume,
          url: values.url,
          updated: values.updated,
          successFn: () => {
            resetForm();
            handleClose();
            if (sharedLinkToken && projectSharedDeviceType) {
              ongGetSharedLinkSettings({
                link: sharedLinkToken,
              });
              onGetSharedProjectKeywordRankings({
                link: sharedLinkToken,
                projectId: Number(decryptId),
                params: {
                  page: paginationModel.page,
                  limit: paginationModel.pageSize,
                  search: search,
                  sortBy: sortModel[0]?.field,
                  sortOrder: sortModel[0]?.sort,
                },
                filters: checkObjectProperties(
                  DEFAULT_KEYWORD_POSITIONS_MODEL,
                  keywordPositionModel
                ),
                deviceType: projectSharedDeviceType,
                tagIds: selectedTags.map(item => item.id),
              });
            }
          },
          errorFn: (error: any) => {
            setStatus({ success: false });
            setSubmitting(false);
            setErrors(error);
          },
        };

        onEditSharedLink({ ...requestData });
      }
    },
  });

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  useEffect(() => {
    if (sharedLinkSettings && open) {
      formik.setFieldValue('position', sharedLinkSettings.settings.position);
      formik.setFieldValue(
        'oneDayChange',
        sharedLinkSettings.settings.oneDayChange
      );
      formik.setFieldValue(
        'sevenDayChange',
        sharedLinkSettings.settings.sevenDayChange
      );
      formik.setFieldValue(
        'thirtyDayChange',
        sharedLinkSettings.settings.thirtyDayChange
      );
      formik.setFieldValue(
        'startingRank',
        sharedLinkSettings.settings.startingRank
      );
      formik.setFieldValue('bestRank', sharedLinkSettings.settings.bestRank);
      formik.setFieldValue(
        'lifeTimeChange',
        sharedLinkSettings.settings.lifeTimeChange
      );
      formik.setFieldValue('volume', sharedLinkSettings.settings.volume);
      formik.setFieldValue('url', sharedLinkSettings.settings.url);
      formik.setFieldValue('updated', sharedLinkSettings.settings.updated);
    }
  }, [sharedLinkSettings, open]);

  const isRenderVolumeAndURL = useMemo(
    () =>
      projectShared?.searchEngine?.name === 'Google' ||
      projectShared?.searchEngine?.name === 'Bing',
    [projectShared?.searchEngine?.name]
  );

  return (
    <>
      <Modal
        aria-labelledby='AddNoteModal'
        aria-describedby='EditNoteModal'
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <form onSubmit={formik.handleSubmit}>
            <ModalWrapper>
              <ModalContainer container xs={12} sx={{ gap: '8px' }}>
                <Grid xs={12}>
                  <Grid item xs={12} sx={{ position: 'relative' }}>
                    <CloseDeletePopoverOne onClick={handleClose} />
                    <Typography
                      fontSize='18px'
                      fontWeight='600'
                      lineHeight='28px'
                      color='#101828'
                    >
                      Shared Link report table columns
                    </Typography>
                  </Grid>

                  <Grid>
                    <Typography
                      fontSize='14px'
                      fontWeight='400'
                      lineHeight='20px'
                      color='#475467'
                    >
                      Show or hide columns in Shared Link page.
                    </Typography>
                  </Grid>

                  <CustomSwitchContainer>
                    <Grid marginRight={'12px'}>Position</Grid>
                    <Grid>
                      <CustomSwitch
                        checked={formik.values.position}
                        onChange={(e, checked) =>
                          formik.setFieldValue('position', checked)
                        }
                      />
                    </Grid>
                  </CustomSwitchContainer>

                  <CustomSwitchContainer>
                    <Grid marginRight={'12px'}>1 Day</Grid>
                    <Grid>
                      <CustomSwitch
                        checked={formik.values.oneDayChange}
                        onChange={(e, checked) =>
                          formik.setFieldValue('oneDayChange', checked)
                        }
                      />
                    </Grid>
                  </CustomSwitchContainer>

                  <CustomSwitchContainer>
                    <Grid marginRight={'12px'}>7 Day</Grid>
                    <Grid>
                      <CustomSwitch
                        checked={formik.values.sevenDayChange}
                        onChange={(e, checked) =>
                          formik.setFieldValue('sevenDayChange', checked)
                        }
                      />
                    </Grid>
                  </CustomSwitchContainer>

                  <CustomSwitchContainer>
                    <Grid marginRight={'12px'}>30 Day</Grid>
                    <Grid>
                      <CustomSwitch
                        checked={formik.values.thirtyDayChange}
                        onChange={(e, checked) =>
                          formik.setFieldValue('thirtyDayChange', checked)
                        }
                      />
                    </Grid>
                  </CustomSwitchContainer>

                  <CustomSwitchContainer>
                    <Grid marginRight={'12px'}>Life</Grid>
                    <Grid>
                      <CustomSwitch
                        checked={formik.values.lifeTimeChange}
                        onChange={(e, checked) =>
                          formik.setFieldValue('lifeTimeChange', checked)
                        }
                      />
                    </Grid>
                  </CustomSwitchContainer>

                  <CustomSwitchContainer>
                    <Grid marginRight={'12px'}>Start</Grid>
                    <Grid>
                      <CustomSwitch
                        checked={formik.values.startingRank}
                        onChange={(e, checked) =>
                          formik.setFieldValue('startingRank', checked)
                        }
                      />
                    </Grid>
                  </CustomSwitchContainer>

                  <CustomSwitchContainer>
                    <Grid marginRight={'12px'}>Best</Grid>
                    <Grid>
                      <CustomSwitch
                        checked={formik.values.bestRank}
                        onChange={(e, checked) =>
                          formik.setFieldValue('bestRank', checked)
                        }
                      />
                    </Grid>
                  </CustomSwitchContainer>

                  {isRenderVolumeAndURL && (
                    <>
                      <CustomSwitchContainer>
                        <Grid marginRight={'12px'}>Volume</Grid>
                        <Grid>
                          <CustomSwitch
                            checked={formik.values.volume}
                            onChange={(e, checked) =>
                              formik.setFieldValue('volume', checked)
                            }
                          />
                        </Grid>
                      </CustomSwitchContainer>
                      <CustomSwitchContainer>
                        <Grid marginRight={'12px'}>URL</Grid>
                        <Grid>
                          <CustomSwitch
                            checked={formik.values.url}
                            onChange={(e, checked) =>
                              formik.setFieldValue('url', checked)
                            }
                          />
                        </Grid>
                      </CustomSwitchContainer>
                    </>
                  )}

                  <CustomSwitchContainer>
                    <Grid marginRight={'12px'}>Updated</Grid>
                    <Grid>
                      <CustomSwitch
                        checked={formik.values.updated}
                        onChange={(e, checked) =>
                          formik.setFieldValue('updated', checked)
                        }
                      />
                    </Grid>
                  </CustomSwitchContainer>

                  <ButtonsWrapper container xs={12} sx={{ paddingTop: '32px' }}>
                    <Grid item xs={extraSmallScreen && 12}>
                      <GrayButtonSmall
                        fullWidth={extraSmallScreen}
                        size='small'
                        outline
                        onClick={handleClose}
                      >
                        Cancel
                      </GrayButtonSmall>
                    </Grid>
                    <Grid item xs={extraSmallScreen && 12}>
                      <PrimaryButtonSmall
                        fullWidth={extraSmallScreen}
                        variant='contained'
                        size='small'
                        type='submit'
                        disabled={formik.isSubmitting || !formik.isValid}
                        onClick={() => formik.handleSubmit()}
                      >
                        Get Share Link
                      </PrimaryButtonSmall>
                    </Grid>
                  </ButtonsWrapper>
                </Grid>
              </ModalContainer>
            </ModalWrapper>
          </form>
        </Fade>
      </Modal>
    </>
  );
};

export default SharedLinkReportTableColumnsModal;
