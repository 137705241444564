import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { TooltipProps } from 'recharts';

import {
  StyledITooltipContainerHead,
  StyledStatus,
  StyledStatusLabel,
  StyledStatusValue,
} from '../../../../../Overview/styles';
import dayjs from 'dayjs';
import { COMPETITORS_COLORS } from '../../../../../../../../../../shared/layout/PrivateLayout/constant';
import { useAppSelector } from '../../../../../../../../../../store';
import { StyledITooltipContainer } from '../../../../../../../../../projects/components/ProjectExpanded/components/ImprovedVsDeclined/components/Graph/styled';

const Competitor: React.FC<{ name: string; value: string }> = ({
  name,
  value,
}) => {
  const projectShared = useAppSelector(
    state => state.sharedLinks.projectShared
  );

  const isBaseSearchEngine = useMemo(() => {
    return (
      projectShared &&
      projectShared.searchEngine.name !== 'Google My Business' &&
      projectShared.searchEngine.name !== 'Google Maps'
    );
  }, [projectShared?.searchEngine?.name]);

  const competitor = useMemo(() => {
    return (
      projectShared &&
      projectShared?.competitors?.length &&
      projectShared.competitors.find(
        item => Number(name.replace(/^competitor/, '')) === item.id
      )
    );
  }, [name, projectShared]);

  const label = useMemo(() => {
    if (competitor) {
      return isBaseSearchEngine
        ? competitor.domainName
        : `${competitor.businessName}${
            competitor?.url ? ` (${competitor?.url})` : ''
          }`;
    }
    return '';
  }, [competitor, isBaseSearchEngine]);

  if (!competitor) {
    return null;
  }

  return (
    <Grid container item xs={12}>
      <Grid container item xs={10} alignItems={'center'} wrap={'nowrap'}>
        <StyledStatus
          item
          $backgroundColor={
            COMPETITORS_COLORS[competitor.id % COMPETITORS_COLORS.length]
          }
        />
        <StyledStatusLabel item>{label}</StyledStatusLabel>
      </Grid>

      <Grid xs={2} container item justifyContent={'flex-end'}>
        <StyledStatusValue item>
          {Number(value) === 101 ? '> 100' : Math.round(Number(value))}
        </StyledStatusValue>
      </Grid>
    </Grid>
  );
};

const PositionHistory = ({
  active,
  label,
  payload,
}: TooltipProps<number, string>) => {
  const competitors = payload?.filter(item => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return item?.dataKey?.slice(0, 10) === 'competitor';
  });

  if (active) {
    return (
      <StyledITooltipContainer item>
        <StyledITooltipContainerHead item>
          {dayjs(label).format('MMMM DD, YYYY')}
        </StyledITooltipContainerHead>
        {payload?.length && (
          <>
            {payload[0] && (
              <Grid container item xs={12}>
                <Grid container item xs={8} alignItems={'center'}>
                  <StyledStatus item $backgroundColor={'#074AE0'} />
                  <StyledStatusLabel item>Position:</StyledStatusLabel>
                </Grid>
                <Grid xs={4} container item justifyContent={'flex-end'}>
                  <StyledStatusValue item>
                    {payload[0].value === 101 ? '> 100' : payload[0].value}
                  </StyledStatusValue>
                </Grid>
              </Grid>
            )}
          </>
        )}

        {competitors?.length
          ? competitors.map(item => (
              <Competitor
                key={item.name}
                name={item.name || ''}
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                value={item.payload[item.name]}
              />
            ))
          : null}
      </StyledITooltipContainer>
    );
  }

  return null;
};

export { PositionHistory };
