import { Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { SelectProject, Search } from './components';
import { SelectProjectProvider } from './components/SelectProject/context/SelectProjectProvider';
import { TopSearchbarExpandProps } from '../layout/PrivateLayout/types';

const TopSearchbarExpand: React.FC<TopSearchbarExpandProps> = ({
  isFreeTrial,
}) => {
  const extraSmallScreen = useMediaQuery('(min-width:600px)');

  return (
    <Grid container spacing={2}>
      {extraSmallScreen && (
        <Grid item>
          <SelectProjectProvider>
            <SelectProject />
          </SelectProjectProvider>
        </Grid>
      )}
      <Grid item>
        <Search isFreeTrial={isFreeTrial} />
      </Grid>
    </Grid>
  );
};

export default TopSearchbarExpand;
