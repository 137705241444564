import React from 'react';
import { KeywordsActionProps } from '../../../../../../../../projects/components/ProjectExpanded/components/KeywordRankings/components/KeywordRankingsTable/components/ActionsMenu/components/types';
import { useParams } from 'react-router';
import { useNotes, useProjects } from '../../../../../../../../../hooks';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import CustomToast from '../../../../../../../../../shared/CustomToast/CustomToast';
import { closeAndUndoSnackbarAction } from '../../../../../../../../../utils/snackbar';
import {
  ListItemTypography,
  StyledListItem,
} from '../../../../../../../../projects/components/ProjectExpanded/components/KeywordRankings/components/KeywordRankingsTable/components/ActionsMenu/styles';
import SmallTrashIcon from '../../../../../../../../../shared/images/SmallTrashIcom';
import { DEFAULT_GET_USERS_PARAMS } from '../../../../../../../../auth/components/Settings/components/Team/constants';
import { StyledListItemButton } from '../../../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/styles';
import { useAppSelector } from '../../../../../../../../../store';

const DeleteProjectNotesAction: React.FC<KeywordsActionProps> = ({
  popupClose,
}) => {
  const { id } = useParams();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );

  const deviceType = useAppSelector(
    state => state.projects.projectExpandedGraphs.deviceType
  );
  const activePeriodProjectPerformance = useAppSelector(
    state => state.projects.projectExpandedGraphs.activePeriodProjectPerformance
  );

  const {
    onSetDeleteElementsProjectNotesTable,
    onBulkDeleteNotesNotes,
    onGetProjectNotes,
    tables: {
      projectNotes: { paginationModel, search, sortModel, rowSelectionModel },
    },
  } = useNotes();

  const { onGetProjectPerformance } = useProjects();

  const handleGetProjectPerformance = () => {
    if (projectExpanded?.id && currentAccount?.accountId && deviceType) {
      onGetProjectPerformance({
        id: currentAccount.accountId,
        projectId: projectExpanded.id,
        period: activePeriodProjectPerformance.value,
        competitorIds: projectExpanded.competitors.map(item =>
          item.id.toString()
        ),
        deviceType: deviceType,
      });
    }
  };

  const handleHardDelete = () => {
    if (id) {
      onBulkDeleteNotesNotes({
        id: currentAccount.accountId,
        noteIds: rowSelectionModel as number[],
        successFn: () => {
          handleGetProjectPerformance();
          if (currentAccount?.accountId && projectExpanded?.id) {
            onGetProjectNotes({
              id: currentAccount.accountId,
              projectId: projectExpanded.id as number,
              page: paginationModel.page,
              limit: paginationModel.pageSize,
              search: search || DEFAULT_GET_USERS_PARAMS.search,
              sortBy: sortModel[0]?.field,
              sortOrder: sortModel[0]?.sort,
            });
          }
          onSetDeleteElementsProjectNotesTable([]);
        },
      });
    }
  };

  const handleDeleteOneKeywords = () => {
    onSetDeleteElementsProjectNotesTable(rowSelectionModel);

    popupClose();
    let undoDelete = false;
    const onUndo = () => {
      undoDelete = true;
      onSetDeleteElementsProjectNotesTable([]);
    };
    enqueueSnackbar(
      <CustomToast
        message='Notes Removed'
        submessage={`Notes has been successfully removed.`}
      />,
      {
        action: (snackbarId: SnackbarKey) =>
          closeAndUndoSnackbarAction(snackbarId, onUndo),
        variant: 'error',
      }
    );
    setTimeout(() => {
      !undoDelete && handleHardDelete();
    }, 5000);
  };

  return (
    <StyledListItem onClick={handleDeleteOneKeywords}>
      <StyledListItemButton>
        <SmallTrashIcon />
        <ListItemTypography marginLeft='8px' sx={{ color: '#B42318' }}>
          Delete Notes
        </ListItemTypography>
      </StyledListItemButton>
    </StyledListItem>
  );
};

export default DeleteProjectNotesAction;
