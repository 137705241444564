import React from 'react';

const CloseBannerIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
  >
    <path
      d='M15 5L5 15M5 5L15 15'
      stroke='#2A7DEB'
      strokeWidth='1.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default CloseBannerIcon;
