import { Outlet } from 'react-router';
import React from 'react';

const SharedLinksRoutes = () => {
  return (
    <div>
      <Outlet />
    </div>
  );
};
export default SharedLinksRoutes;
