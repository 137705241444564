import { Box, Grid, Typography, Skeleton } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import SerpNestLogo from '../../../../shared/images/SerpNestLogo';
import VerifyEmailSuccessfulImage from '../../../../shared/images/VerifyEmailSuccessfu';
import { useAuth } from '../../../../hooks';
import ROUTES from '../../../../routes/constants';

import { PrimaryButton } from '../../../../shared/buttons/styles';
import {
  LogoContainer,
  PaperContainer,
  Wrapper,
} from '../VerifyEmailSuccessful/styles';
import { useAppSelector } from '../../../../store';

/**
 * Component representing a successful email change page.
 *
 * @component
 * @returns {JSX.Element} The ChangeEmailSuccessful component.
 */
const ChangeEmailSuccessful = () => {
  const navigate = useNavigate();

  const { emailConfirmationToken } = useParams();

  const searchParams = new URLSearchParams(useLocation().search);
  const newEmailEncoded = searchParams.get('newEmail');
  const newEmail = newEmailEncoded
    ? decodeURIComponent(newEmailEncoded.replace(/ /g, '+'))
    : '';

  const { onEmailChangeConfirmation, onLogout } = useAuth();

  const isLoadingEmailChangeConfirmation = useAppSelector(
    state => state.auth.isLoading
  );

  /**
   * Redirects the user to the login page.
   */
  const onRedirect = () => {
    navigate(ROUTES.login);
  };
  useEffect(() => {
    if (emailConfirmationToken && newEmail)
      onEmailChangeConfirmation({
        emailConfirmationToken,
        newEmail,
        errorFn: onRedirect,
        successFn: onLogout,
      });
  }, []);

  return (
    <Wrapper>
      <Grid container>
        <Grid xs={1} lg={3} md={2} />
        <Grid xs={10} lg={6} md={8}>
          <Box>
            <LogoContainer>
              <SerpNestLogo />
            </LogoContainer>
            <PaperContainer>
              {isLoadingEmailChangeConfirmation ? (
                <Skeleton width='179px' height='160px' />
              ) : (
                <VerifyEmailSuccessfulImage />
              )}

              {isLoadingEmailChangeConfirmation ? (
                <Skeleton width='70%' height='30px' />
              ) : (
                <Typography variant='h4' fontWeight='700' sx={{ mt: 3, mb: 3 }}>
                  Email changed successful!
                </Typography>
              )}

              {isLoadingEmailChangeConfirmation ? (
                <Skeleton width='60%' height='20px' />
              ) : (
                <Typography variant='body1' sx={{ mb: 3 }}>
                  Your email address has been successfully changed
                </Typography>
              )}

              {isLoadingEmailChangeConfirmation ? (
                <Skeleton width='158px' height='56px' />
              ) : (
                <PrimaryButton
                  size='large'
                  variant='contained'
                  onClick={onRedirect}
                >
                  Get Started
                </PrimaryButton>
              )}
            </PaperContainer>
          </Box>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default ChangeEmailSuccessful;
