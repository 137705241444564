import React from 'react';
import ExportCSV from './components/ExportCSV';
import { PaginationContainer } from '../../../../../../../projects/components/Projects/components/TableProjects/components/Pagination/styles';
import Grid from '@mui/material/Grid';
import Table from './components/Table';
import Pagination from './components/Pagination';

const KeywordPositionsInfo = () => {
  return (
    <Grid xs={12} paddingTop={'27px'}>
      <ExportCSV />
      <Table />
      <PaginationContainer xs={12}>
        <Pagination />
      </PaginationContainer>
    </Grid>
  );
};

export default KeywordPositionsInfo;
