import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import TreeView from '@mui/lab/TreeView';
import { treeItemClasses } from '@mui/lab';
import TreeItem from '@mui/lab/TreeItem';

const StyledFoldersDrawer = styled(Grid)`
  padding: 32px 16px 16px 16px;
`;

const StyledFoldersDrawerTitle = styled(Grid)`
  color: #808080;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 8px 7px;
`;

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    borderRadius: '8px',
  },
}));

const StyledTreeView = styled(TreeView)<{ $color?: string }>`
  .MuiTreeItem-content {
    width: calc(100% - 24px);
    min-width: 150px;
    padding: 6px 12px;
    border-radius: 8px;
  }

  .MuiTreeItem-content .MuiTreeItem-label {
    color: ${props => props.$color || '#101828'};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .MuiTreeItem-content.Mui-selected {
    background-color: #e8f1fd;
    color: #126fe9 !important;
  }

  .MuiTreeItem-content.Mui-selected.Mui-focused {
    background-color: #e8f1fd;
  }

  .MuiTreeItem-content.Mui-focused {
    background-color: #fff;
  }

  .MuiTreeItem-content:hover {
    background-color: #f6f6f6;
  }

  .MuiTreeItem-content.Mui-selected:hover {
    background-color: #f6f6f6;
  }
`;

export {
  StyledFoldersDrawer,
  StyledFoldersDrawerTitle,
  StyledTreeView,
  StyledTreeItemRoot,
};
