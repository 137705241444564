import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import React, { useMemo } from 'react';
import { CanceledPlanCard, PaymentMethod, StarterPlan } from './components';
import { StyledPaymentAndPlan } from './styles';
import { useAppSelector } from '../../../../../../../../store';
import DeactivatedPlanCard from './components/DeactivatedPlanCard/DeactivatedPlanCard';

/**
 * The PaymentAndPlan component is responsible for rendering the current state of the user's account plan,
 * including the type of plan, the current payment method, and the actions related to managing these.
 *
 * This component conditionally displays different plan cards based on the account's billing status
 * (e.g., activated, canceled, deactivated). It also shows the payment method section if the plan
 * is not deactivated.
 *
 * @component
 * @returns {React.ReactElement | null} The rendered component or null if billing information is not available.
 */
const PaymentAndPlan = () => {
  const billing = useAppSelector(state => state.auth.payment.billing);

  // Check if the account plan is activated or updated
  const isAccountPlanActivated = useMemo(
    () =>
      billing?.status?.name === 'activated' ||
      billing?.status?.name === 'updated',
    [billing?.status?.name]
  );

  // Check if the account plan is canceled
  const isAccountPlanCanceled = useMemo(
    () => billing?.status?.name === 'canceled',
    [billing?.status?.name]
  );

  // Check if the account plan is deactivated
  const isAccountPlanDeactivated = useMemo(
    () => billing?.status?.name === 'deactivated',
    [billing?.status?.name]
  );

  if (!billing) {
    return null;
  }

  return (
    <Box>
      <StyledPaymentAndPlan container spacing={2}>
        <Grid item xs={12} lg={6}>
          {isAccountPlanCanceled && <CanceledPlanCard />}
          {isAccountPlanActivated && <StarterPlan />}
          {isAccountPlanDeactivated && <DeactivatedPlanCard />}
        </Grid>
        {!isAccountPlanDeactivated && (
          <Grid item xs={12} lg={6}>
            <PaymentMethod />
          </Grid>
        )}
      </StyledPaymentAndPlan>
    </Box>
  );
};

export default PaymentAndPlan;
