import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

/**
 * Converts a date string from one timezone to another.
 *
 * @param {string} dateStr - The date string to convert, formatted as 'MMM D, h:mm A'.
 * @param {string} fromTz - The timezone of the input date string (e.g., 'America/New_York').
 * @param {string} toTz - The target timezone for the conversion (e.g., 'Europe/London').
 * @returns {string} The converted date string formatted as 'MMM D, h:mm A'.
 *
 * @example
 * const convertedDate = convertToTimezone('Oct 11, 3:00 PM', 'America/New_York', 'Europe/London');
 * console.log(convertedDate); // Outputs the date in the target timezone
 */
const convertToTimezone = (dateStr: string, fromTz: string, toTz: string) => {
  const localTz = getLocalUtc();
  const toggleTimezone = toggleTimezoneSign(localTz);

  const formatDate = dayjs(dateStr, 'MMM D, h:mm A').format(
    'YYYY-MM-DDTHH:mm:ssZ'
  );
  const date = dayjs.tz(formatDate.toString(), toggleTimezone);
  const convertedDate = date.tz(toTz);

  return convertedDate.format('MMM D, h:mm A');
};

/**
 * Retrieves the local UTC offset of the current date and time.
 *
 * @returns {string} The current local UTC offset formatted as 'Z' (e.g., '+00:00').
 *
 * @example
 * const localUtc = getLocalUtc();
 * console.log(localUtc); // Outputs the local UTC offset
 */
const getLocalUtc = () => {
  return dayjs().format('Z');
};

/**
 * Toggles the sign of a given timezone offset.
 *
 * @param {string} timezone - The timezone offset to toggle, formatted as '+00:00' or '-00:00'.
 * @returns {string} The timezone offset with the sign toggled.
 *
 * @throws {Error} Throws an error if the timezone format is invalid.
 *
 * @example
 * const toggledTimezone = toggleTimezoneSign('+05:00');
 * console.log(toggledTimezone); // Outputs '-05:00'
 */
const toggleTimezoneSign = (timezone: string): string => {
  const regex = /^[+-]\d{2}:\d{2}$/;
  if (!regex.test(timezone)) {
    throw new Error('Invalid timezone format');
  }

  if (timezone === '+00:00') {
    return '+00:00';
  }

  const sign = timezone.charAt(0);
  const newSign = sign === '+' ? '-' : '+';
  return `${newSign}${timezone.slice(1)}`;
};

export { convertToTimezone, getLocalUtc };
