import React from 'react';
import {
  StyledCanceledPlanCard,
  StyledExpireDate,
  StyledHead,
} from '../CanceledPlanCard/styles';
import { Grid } from '@mui/material';
import { PrimaryButtonCustom } from '../../../../../../../../../../shared/buttons/styles';
import ROUTES from '../../../../../../../../../../routes/constants';
import { useNavigate } from 'react-router';

/**
 * DeactivatedPlanCard component to display details of a deactivated subscription plan
 * and provide an option for the user to upgrade their plan.
 *
 * @returns {JSX.Element} A component displaying the deactivated plan information and option to upgrade.
 */
const DeactivatedPlanCard = () => {
  const navigate = useNavigate();

  /**
   * Handle navigation to the upgrade plan page.
   */
  const handleUpgradePlan = () => {
    navigate(ROUTES.upgradePlan);
  };

  return (
    <StyledCanceledPlanCard xs={12}>
      <StyledHead>Your subscription has ended.</StyledHead>

      <StyledExpireDate>Renew to regain full access.</StyledExpireDate>

      <Grid padding={'24px 0'}>
        <PrimaryButtonCustom
          variant='contained'
          size='small'
          $padding='12px 16px'
          onClick={handleUpgradePlan}
        >
          Upgrade Plan
        </PrimaryButtonCustom>
      </Grid>
    </StyledCanceledPlanCard>
  );
};

export default DeactivatedPlanCard;
