import axios, { HeadersDefaults } from 'axios';

import { API_URL } from '../constants/config';
import {
  clearStorage,
  getAccessWithLocalStorage,
  getAccessWithSessionStorage,
  getRefreshWithLocalStorage,
  getRefreshWithSessionStorage,
  getSharedLinkAccessToken,
  getSharedLinkToken,
  saveAccessInLocalStorage,
  saveAccessInSessionStorage,
  saveRefreshInLocalStorage,
  saveRefreshInSessionStorage,
  STORAGE_KEYS,
} from '../utils/localStorage';
import { LoginResponse } from '../features/auth/types';
import ROUTES from '../routes/constants';

const api = axios.create({
  withCredentials: false,
  baseURL: API_URL,
});

interface CommonHeaderProperties extends HeadersDefaults {
  Authorization?: string;
}

/**
 * Intercepts requests to attach authorization tokens if available.
 * - Uses localStorage and sessionStorage tokens depending on what's available.
 * - Adds the Authorization header for shared links or regular authenticated requests.
 *
 * @param {AxiosRequestConfig} config - The Axios request configuration.
 * @returns {AxiosRequestConfig} The modified request configuration with Authorization header.
 */
api.interceptors.request.use(config => {
  if (config?.url?.includes('/actuator/gateway/routes')) {
    return Promise.reject(new Error('Request /actuator/gateway/routes'));
  }

  const token = getAccessWithLocalStorage();
  const sessionToken = getAccessWithSessionStorage();
  const sharedLinkAccessToken = getSharedLinkAccessToken();

  const isSharedLink = config.url?.split('/').find(item => item === 'shared');

  if (isSharedLink) {
    config.headers.Authorization = `Bearer ${sharedLinkAccessToken}`;
  } else {
    if (token || sessionToken) {
      (config.headers as unknown as CommonHeaderProperties)[
        'Authorization'
      ] = `Bearer ${token || sessionToken}`;
    }
  }

  return config;
});

/**
 * Intercepts responses to handle common HTTP errors (401, 403, 404).
 * - Handles 401 Unauthorized by attempting a token refresh.
 * - Redirects the user based on status code and context (shared link or regular access).
 *
 * @param {AxiosResponse} config - The Axios response configuration.
 * @returns {AxiosResponse} The response configuration without modification.
 * @throws {Error} Throws the error for further handling in case of failure.
 */
api.interceptors.response.use(
  config => {
    return config;
  },
  async error => {
    const originalRequest = error.config;
    const isSharedLink = originalRequest.url
      .split('/')
      .find((item: string) => item === 'shared');

    if (error.response.status === 401 && isSharedLink) {
      const sharedLinkToken = getSharedLinkToken();

      if (sharedLinkToken) {
        window.location.href = `${
          window.location.origin
        }${ROUTES.dynamic.sharedLinkLogin(sharedLinkToken)}`;
      }

      return;
    }

    if (isSharedLink) {
      if (error.response.status === 403 || error.response.status === 404) {
        window.location.href = `${window.location.origin}${ROUTES.sharedLinkUnavailable}`;
        return;
      }
    } else {
      if (error.response.status === 403 || error.response.status === 404) {
        if (error?.response?.config?.url) {
          const splitUrl = error.response.config.url.split('/');
          if (splitUrl[splitUrl.length - 1] === 'keyword-rankings') {
            return;
          }
        }
        window.history.pushState(
          {},
          '',
          `${window.location.origin}${ROUTES.notFound}`
        );
        window.dispatchEvent(new PopStateEvent('popstate'));

        return;
      }
    }

    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      const refreshWithLocalStorage = getRefreshWithLocalStorage();
      const refreshWithSessionStorage = getRefreshWithSessionStorage();

      originalRequest._isRetry = true;
      try {
        const refresh = refreshWithLocalStorage
          ? getRefreshWithLocalStorage()
          : getRefreshWithSessionStorage();

        const response = await axios.post<LoginResponse>(
          `${API_URL}/auth/refresh-token`,
          { refreshToken: refresh }
        );
        const { refreshToken, accessToken } = response.data;

        if (refreshWithLocalStorage && !refreshWithSessionStorage) {
          saveAccessInLocalStorage(accessToken);
          saveRefreshInLocalStorage(refreshToken);
        }
        if (!refreshWithLocalStorage && refreshWithSessionStorage) {
          saveAccessInSessionStorage(accessToken);
          saveRefreshInSessionStorage(refreshToken);
        }

        return api.request(originalRequest);
      } catch (e) {
        clearStorage(STORAGE_KEYS.access);
        clearStorage(STORAGE_KEYS.refresh);
        clearStorage(STORAGE_KEYS.currentAccount);

        if (!isSharedLink) {
          window.location.href = `${window.location.origin}${ROUTES.login}`;
        }
      }
    }
    throw error;
  }
);

export default api;
