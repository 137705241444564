import { useTriggersTable } from '../../../../../../../../../../hooks';
import useAlerts from '../../../../../../../../hooks/useAlerts';
import { Box, Grid } from '@mui/material';
import { CustomDataGrid } from '../../../../../../../../../auth/components/Settings/components/Team/components/customTableStyles';
import CheckboxWrapper from '../../../../../../../../../auth/components/Settings/components/Team/components/CheckboxWrapper';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TableNoData } from '../../../../../../../../../../shared/components';
import { arraysAreEqual } from '../../../../../../../../../../utils';
import { useAppSelector } from '../../../../../../../../../../store';

interface TableProps {
  selectBy: 'by Project' | 'by Keywords';
}

const Table: React.FC<TableProps> = ({ selectBy }) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const isAuth = useAppSelector(state => state.auth.isAuth);

  const {
    triggersByProjectColumns,
    triggersByKeywordsColumns,
    triggersByProjectColumnsSkeleton,
    triggersByKeywordsColumnsSkeleton,
  } = useTriggersTable();
  const {
    onSetRowSelectionModelTriggers,
    onSetSortModelTriggers,
    triggers: { byProjects, byKeywords },
    tables: { triggers },
  } = useAlerts();

  const openTariffPlanBanner = useAppSelector(
    state => state.auth.openTariffPlanBanner
  );

  const [itemCount, setItemCount] = useState(
    selectBy === 'by Project'
      ? byProjects?.meta?.itemCount || triggers.paginationModel.pageSize
      : byKeywords?.meta?.itemCount || triggers.paginationModel.pageSize
  );

  const isViewOnly = useMemo(
    () => !isAuth || (isAuth && currentAccount?.role?.name === 'ViewOnly'),
    [currentAccount?.role?.name, isAuth]
  );

  const isRowSelectable = useCallback(() => !isViewOnly, [isViewOnly]);

  const arrayOfObjects = useMemo(
    () => Array.from({ length: itemCount }, (_, index) => ({ id: index + 1 })),
    [itemCount]
  );

  const renderColumns = useMemo(() => {
    return selectBy === 'by Project'
      ? !byProjects
        ? triggersByProjectColumnsSkeleton
        : triggersByProjectColumns
      : !byKeywords
      ? triggersByKeywordsColumnsSkeleton
      : triggersByKeywordsColumns;
  }, [
    selectBy,
    byProjects,
    triggersByProjectColumnsSkeleton,
    triggersByProjectColumns,
    byKeywords,
    triggersByKeywordsColumnsSkeleton,
    triggersByKeywordsColumns,
  ]);

  const rows = useMemo(() => {
    if (selectBy === 'by Project') {
      const arr1 = [...(byProjects?.items?.length ? byProjects.items : [])];
      const arr2 = [...triggers.deleteElements];

      return arr1.filter(item => !arr2.includes(item.id));
    } else {
      const arr1 = [...(byKeywords?.items?.length ? byKeywords.items : [])];
      const arr2 = [...triggers.deleteElements];

      return arr1.filter(item => !arr2.includes(item.id));
    }
  }, [byKeywords?.items, byProjects?.items, selectBy, triggers.deleteElements]);

  const renderRows = useMemo(() => {
    return selectBy === 'by Project'
      ? !byProjects
        ? arrayOfObjects
        : rows
      : !byKeywords
      ? arrayOfObjects
      : rows;
  }, [arrayOfObjects, byKeywords, byProjects, rows, selectBy]);

  useEffect(() => {
    const filtered = rows
      ?.filter(obj => triggers.rowSelectionModel.includes(obj.id))
      ?.map(item => item.id)
      .filter(item => !triggers.deleteElements.includes(item));

    if (filtered && !arraysAreEqual(triggers.rowSelectionModel, filtered)) {
      onSetRowSelectionModelTriggers(filtered);
    }
  }, [
    onSetRowSelectionModelTriggers,
    rows,
    triggers.deleteElements,
    triggers.rowSelectionModel,
  ]);

  useEffect(() => {
    if (selectBy === 'by Project' && byProjects?.meta?.itemCount) {
      setItemCount(byProjects.meta.itemCount);
    } else {
      if (byKeywords?.meta?.itemCount) {
        setItemCount(byKeywords.meta.itemCount);
      }
    }
  }, [byKeywords?.meta?.itemCount, byProjects?.meta?.itemCount, selectBy]);

  const NoRowsOverlay =
    selectBy === 'by Project'
      ? !byProjects?.items
        ? Box
        : TableNoData
      : !byKeywords?.items
      ? Box
      : TableNoData;

  const pinnedColumns = useMemo(
    () => ({
      right: !isViewOnly ? ['actions'] : [],
    }),
    [isViewOnly]
  );

  return (
    <Grid xs={12}>
      <CustomDataGrid
        pinnedColumns={pinnedColumns}
        columnHeaderHeight={40}
        rowHeight={40}
        rows={renderRows}
        rowSelectionModel={triggers.rowSelectionModel}
        onRowSelectionModelChange={onSetRowSelectionModelTriggers}
        columns={renderColumns}
        onSortModelChange={onSetSortModelTriggers}
        isRowSelectable={isRowSelectable}
        components={{
          BaseCheckbox: CheckboxWrapper,
          NoRowsOverlay: NoRowsOverlay,
        }}
        keepNonExistentRowsSelected
        hideFooterPagination
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnMenu
        columnBuffer={100}
        columnThreshold={100}
        $isModalTable={true}
        $openTariffPlanBanner={openTariffPlanBanner}
      />
    </Grid>
  );
};

export default Table;
