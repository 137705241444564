import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StyledStatusLabel = styled(Grid)`
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export { StyledStatusLabel };
