import React from 'react';

const WelcomeProjectIcon = () => {
  return (
    <svg
      width='176'
      height='202'
      viewBox='0 0 176 202'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_980_40335)'>
        <path
          d='M84.4613 189.073L84.237 184.03C84.2136 184.018 84.1903 184.005 84.1669 183.993C81.7634 182.732 79.0088 185.015 79.7765 187.618C80.4988 190.068 80.9841 192.709 82.3063 194.853C83.0875 196.098 84.1389 197.152 85.3826 197.936C86.6263 198.719 88.0305 199.213 89.491 199.38L92.5446 201.25C92.9636 198.914 92.8712 196.515 92.2738 194.218C91.6764 191.922 90.5882 189.782 89.0842 187.946C88.2302 186.925 87.251 186.015 86.1696 185.238C85.4383 187.166 84.4613 189.073 84.4613 189.073Z'
          fill='#F2F2F2'
        />
        <path
          d='M57.1218 137.891C51.2429 131.828 42.7235 128.351 33.7481 128.351L33.4019 128.352C27.49 128.354 21.6836 126.786 16.5766 123.808L16.0451 123.498L16.4732 123.057C18.7904 120.66 20.7254 117.92 22.2101 114.935C21.1556 115.918 19.8979 116.657 18.526 117.101C17.154 117.544 15.7014 117.68 14.271 117.5C12.7609 117.32 11.3111 116.8 10.0304 115.98C8.7497 115.159 7.6714 114.06 6.87635 112.763C3.73935 107.673 0 100.451 0 94.9494C0.00488322 90.2989 0.978603 85.7005 2.8591 81.4472C4.73959 77.1939 7.4856 73.379 10.9221 70.2458C13.0977 68.2841 14.8355 65.8859 16.0221 63.2076C17.2087 60.5292 17.8175 57.6309 17.8088 54.7015L17.807 54.2846C17.8065 50.4278 18.2778 46.5855 19.2104 42.8432L19.3052 42.4653L19.694 42.4789C22.2013 42.5642 24.7099 42.354 27.168 41.8527C24.8623 41.5699 22.6067 40.97 20.4651 40.0699L20.0468 39.9007L20.1887 39.4721C29.974 33.4135 52.0621 36.5509 64.8827 7.21821C75.2785 -16.5666 111.958 28.332 111.958 54.2846C111.958 56.9327 109.337 60.4322 111.299 62.1825C132.508 81.1033 117.874 93.2531 113.261 105.479C112.166 108.379 114.539 111.564 114.539 114.702C114.539 115.234 114.526 115.784 114.499 116.336L114.458 117.183L113.751 116.713C112.352 115.797 111.055 114.733 109.883 113.54C111.099 116.339 111.673 119.375 111.56 122.425C111.448 125.474 110.654 128.46 109.236 131.162C107.013 135.357 104.863 138.36 102.845 140.088C96.3801 145.62 88.0462 148.474 79.5473 148.065C71.0484 147.657 63.0264 144.017 57.1218 137.891Z'
          fill='#E6E6E6'
        />
        <path
          d='M92.6282 201.001C92.7728 201 92.9124 200.948 93.0215 200.853C93.1306 200.759 93.2018 200.628 93.2222 200.485C93.2477 200.305 95.7475 182.277 93.5024 158.316C91.4291 136.188 84.7708 104.315 64.8251 78.1386C64.7773 78.0758 64.7176 78.0229 64.6494 77.9831C64.5812 77.9434 64.5058 77.9174 64.4276 77.9068C64.3494 77.8962 64.2698 77.9011 64.1935 77.9213C64.1171 77.9414 64.0455 77.9764 63.9827 78.0243C63.9199 78.0721 63.8672 78.1319 63.8275 78.2002C63.7878 78.2684 63.762 78.3438 63.7514 78.4221C63.7409 78.5003 63.7459 78.5799 63.7662 78.6562C63.7864 78.7325 63.8215 78.8041 63.8695 78.8668C83.6429 104.817 90.2475 136.456 92.3062 158.428C94.5382 182.249 92.0581 200.137 92.0328 200.315C92.0207 200.4 92.0271 200.487 92.0515 200.57C92.0759 200.652 92.1178 200.729 92.1742 200.794C92.2307 200.859 92.3004 200.911 92.3788 200.947C92.4571 200.982 92.5422 201.001 92.6282 201.001Z'
          fill='#3F3D56'
        />
        <path
          d='M76.3528 115.062C76.4797 115.062 76.6034 115.022 76.7061 114.947C76.8088 114.873 76.8852 114.768 76.9245 114.647C76.9638 114.526 76.9638 114.396 76.9247 114.276C76.8855 114.155 76.8091 114.05 76.7065 113.975C76.6341 113.923 69.3374 108.665 58.4289 104.671C48.3448 100.979 33.0105 97.6322 17.2241 102.657C17.1488 102.68 17.079 102.719 17.0186 102.77C16.9582 102.82 16.9084 102.883 16.872 102.953C16.8357 103.023 16.8136 103.099 16.8069 103.178C16.8002 103.257 16.809 103.336 16.833 103.411C16.8569 103.487 16.8955 103.556 16.9464 103.617C16.9973 103.677 17.0597 103.727 17.1298 103.763C17.2 103.799 17.2766 103.821 17.3553 103.828C17.434 103.834 17.5132 103.825 17.5884 103.801C33.0514 98.8802 48.1077 102.172 58.0158 105.799C68.7686 109.736 75.9291 114.895 76.0001 114.947C76.1026 115.022 76.2261 115.062 76.3528 115.062Z'
          fill='#3F3D56'
        />
        <path
          d='M103.846 46.5873C103.855 46.4608 103.824 46.3345 103.757 46.2267C103.69 46.1189 103.59 46.0351 103.473 45.9872C103.355 45.9394 103.226 45.93 103.103 45.9603C102.979 45.9907 102.869 46.0593 102.787 46.1563C102.729 46.2248 96.9601 53.1239 92.1908 63.7164C87.7823 73.5084 83.3398 88.5619 87.2142 104.669C87.2514 104.824 87.3487 104.958 87.4845 105.041C87.6204 105.124 87.7837 105.15 87.9386 105.113C88.0935 105.075 88.2272 104.978 88.3104 104.842C88.3936 104.706 88.4194 104.543 88.3821 104.388C84.5875 88.611 88.9548 73.8308 93.2864 64.2096C97.9876 53.7683 103.649 46.998 103.706 46.9308C103.787 46.834 103.836 46.7137 103.846 46.5873Z'
          fill='#3F3D56'
        />
        <path
          d='M103.033 77.5614C103.539 81.6505 106.373 85.022 110.493 85.022C112.472 85.022 114.37 84.236 115.769 82.8369C117.168 81.4377 117.954 79.5401 117.954 77.5614C117.954 73.441 114.596 70.4817 110.493 70.1008C106.14 69.6966 102.382 72.3115 103.033 77.5614Z'
          fill='#2F2E41'
        />
        <path
          d='M113.948 197.715L116.789 197.715L118.14 186.756L113.947 186.757L113.948 197.715Z'
          fill='#FFB6B6'
        />
        <path
          d='M113.848 200.679L122.584 200.679V200.568C122.584 199.666 122.226 198.801 121.588 198.164C120.951 197.526 120.086 197.168 119.184 197.168H119.184L117.588 195.957L114.61 197.168L113.848 197.168L113.848 200.679Z'
          fill='#2F2E41'
        />
        <path
          d='M102.757 197.715L105.598 197.715L106.95 186.756L102.757 186.757L102.757 197.715Z'
          fill='#FFB6B6'
        />
        <path
          d='M102.657 200.679L111.394 200.679V200.568C111.394 199.666 111.036 198.801 110.398 198.164C109.76 197.526 108.895 197.168 107.993 197.168H107.993L106.397 195.957L103.42 197.168L102.657 197.168L102.657 200.679Z'
          fill='#2F2E41'
        />
        <path
          d='M123.725 129.079L125.734 135.966L123.368 165.133C123.368 165.133 124.586 166.956 123.725 166.956C122.864 166.956 121.761 167.361 122.456 168.45C123.151 169.539 120.774 173.449 120.774 173.449L117.412 194.216L113.129 194.153C113.129 194.153 110.951 192.94 112.173 191.713C113.395 190.486 114.256 190.486 113.108 189.338C111.96 188.19 111.96 188.536 111.96 187.646C111.96 186.756 110.81 175.877 113.538 171.703L112.716 154.689L110.78 171.378L106.795 193.642L102.669 193.608C102.669 193.608 100.762 191.771 100.766 190.411C100.769 189.051 101.944 188.938 101.07 188.134C100.196 187.33 100.196 174.973 102.778 168.104C102.778 168.104 104.239 164.266 102.775 162.951C101.311 161.636 102.773 160.132 102.773 160.132C102.773 160.132 105.092 159.293 102.771 156.878C100.45 154.463 102.758 137.38 102.758 137.38C102.758 137.38 100.221 130.485 102.791 127.773C105.361 125.062 123.725 129.079 123.725 129.079Z'
          fill='#2F2E41'
        />
        <path
          d='M87.9496 140.944L91.5333 132.284L94.3569 133.852L92.6517 142.663C92.8225 143.221 92.8342 143.815 92.6855 144.38C92.5368 144.944 92.2336 145.456 91.81 145.858C91.3864 146.259 90.8592 146.535 90.2876 146.653C89.716 146.771 89.1228 146.728 88.5745 146.528C88.0263 146.327 87.5448 145.978 87.1842 145.519C86.8235 145.06 86.5981 144.51 86.5331 143.93C86.4682 143.349 86.5664 142.763 86.8166 142.235C87.0668 141.708 87.4592 141.261 87.9496 140.944Z'
          fill='#FFB6B6'
        />
        <path
          d='M148.087 72.4585L142.634 80.0819L140.239 77.9143L143.896 69.7197C143.856 69.1373 143.98 68.5554 144.253 68.0394C144.525 67.5233 144.936 67.0935 145.44 66.7984C145.944 66.5032 146.52 66.3544 147.103 66.3686C147.687 66.3828 148.255 66.5595 148.743 66.8789C149.232 67.1982 149.622 67.6475 149.869 68.1762C150.116 68.705 150.211 69.2922 150.143 69.8719C150.075 70.4516 149.846 71.0008 149.483 71.4577C149.12 71.9147 148.636 72.2612 148.087 72.4585Z'
          fill='#FFB6B6'
        />
        <path
          d='M124.267 129.785C127.804 135.271 110.025 142.233 103.204 139.438C102.009 138.949 97.9003 137.332 100.709 135.091C103.517 132.85 100.383 132.535 100.309 131.076C100.26 130.1 101.015 127.961 100.227 128.052C99.4389 128.144 98.5167 126.795 100.233 126.215C101.949 125.634 100.251 125.105 100.266 124.554C100.43 118.541 105.044 108.976 105.044 108.976L96.7306 130.227C96.7306 130.227 96.792 133.903 95.7319 132.78C94.6718 131.656 94.7489 135.293 94.7489 135.293C94.7489 135.293 93.1726 136.393 94.079 137.005C94.9855 137.617 93.1031 139.5 93.1031 139.5L88.7109 139.186C88.7109 139.186 88.7797 135.923 89.5296 135.201C90.2796 134.48 89.9112 133.345 89.9112 133.345C89.9112 133.345 91.8638 131.616 90.5547 130.213C88.8712 128.408 95.0094 97.9225 97.1277 98.2244C97.4272 96.7669 98.1238 95.4206 99.1407 94.3342C100.158 93.2479 101.455 92.4639 102.89 92.0689L107.633 90.7628L108.476 87.4206H115.156L116.319 89.6167L121.397 91.9084C122.354 90.5587 123.069 89.1365 126.986 88.6755C126.986 88.6755 137.541 79.0722 137.025 78.3224C136.51 77.5726 138.146 75.817 138.146 75.817C138.146 75.817 139.543 75.3597 138.755 75.1156C137.966 74.8714 139.457 74.3069 139.457 74.3069C139.457 74.3069 140.837 73.9442 140.186 73.4666C139.535 72.989 142.359 72.6753 142.359 72.6753L146.022 75.6644L145.282 76.9199C145.282 76.9199 145.733 78.6295 145.144 78.0054C144.555 77.3812 144.444 79.1925 144.444 79.1925C144.444 79.1925 144.143 81.1759 142.359 81.7734C140.574 82.371 139.221 88.048 139.221 88.048C135.013 93.5193 130.047 97.7874 124.162 100.597C126.456 103.23 124.267 129.785 124.267 129.785Z'
          fill='#126FE9'
        />
        <path
          d='M111.318 85.4649C114.972 85.4649 117.934 82.503 117.934 78.8492C117.934 75.1954 114.972 72.2334 111.318 72.2334C107.664 72.2334 104.702 75.1954 104.702 78.8492C104.702 82.503 107.664 85.4649 111.318 85.4649Z'
          fill='#FFB6B6'
        />
        <path
          d='M104.467 79.5699C104.676 81.0075 105.902 83.0133 105.615 83.0133C105.328 83.0133 103.288 77.0869 105.328 76.7005C106.808 76.4202 107.138 76.666 108.522 76.0663L108.326 76.25C109.242 76.9157 110.516 76.5053 111.56 76.0721C112.608 75.6416 113.879 75.2313 114.797 75.8941C115.374 76.3102 115.618 77.0333 115.976 77.6474C116.335 78.2643 116.998 78.8468 117.681 78.6575C118.229 78.5054 118.545 77.8913 118.542 77.3231C118.539 76.755 117.799 76.2145 118.048 75.7048C118.787 74.1941 118.123 73.1645 116.513 72.4537C115.928 72.3647 115.342 72.2738 114.757 72.181C115.064 71.7562 115.436 71.3824 115.859 71.0734C115.68 70.6149 115.371 70.2189 114.969 69.9343C114.502 69.6559 113.922 69.676 113.38 69.7047C112.169 69.7678 110.959 69.8309 109.75 69.8941C108.854 69.94 107.928 69.9945 107.127 70.4048C106.128 70.9185 105.474 71.9199 104.998 72.9386C103.93 75.2284 104.1 77.0706 104.467 79.5699Z'
          fill='#2F2E41'
        />
        <path
          d='M76.9462 201.424L142.011 201.512C142.102 201.512 142.189 201.476 142.253 201.412C142.317 201.348 142.353 201.261 142.353 201.171C142.353 201.08 142.317 200.993 142.253 200.929C142.189 200.865 142.102 200.829 142.011 200.829L76.9462 200.741C76.8555 200.741 76.7686 200.777 76.7045 200.841C76.6405 200.905 76.6045 200.992 76.6045 201.082C76.6045 201.173 76.6405 201.26 76.7045 201.324C76.7686 201.388 76.8555 201.424 76.9462 201.424Z'
          fill='#CACACA'
        />
        <path
          d='M53.413 171.641C53.533 170.817 52.6695 170.008 51.4844 169.836C50.2994 169.664 49.2414 170.192 49.1215 171.017C49.0015 171.841 49.865 172.649 51.0501 172.821C52.2352 172.994 53.2931 172.465 53.413 171.641Z'
          fill='#E6E6E6'
        />
        <path
          d='M67.5224 170.265C67.6424 169.44 66.7789 168.632 65.5938 168.46C64.4087 168.287 63.3508 168.816 63.2309 169.64C63.1109 170.465 63.9744 171.273 65.1594 171.445C66.3445 171.617 67.4025 171.089 67.5224 170.265Z'
          fill='#3F3D56'
        />
        <path
          d='M49.5497 160.218C49.6697 159.394 48.8062 158.586 47.6211 158.413C46.4361 158.241 45.3781 158.769 45.2582 159.594C45.1383 160.418 46.0017 161.226 47.1868 161.399C48.3719 161.571 49.4298 161.043 49.5497 160.218Z'
          fill='#FF6584'
        />
        <path
          d='M43.4189 155.54C43.4247 154.783 43.0025 154.166 42.476 154.162C41.9495 154.158 41.518 154.768 41.5123 155.525C41.5066 156.282 41.9288 156.899 42.4553 156.903C42.9818 156.907 43.4132 156.296 43.4189 155.54Z'
          fill='#E6E6E6'
        />
        <path
          d='M41.3388 146.824C41.3446 146.067 40.9224 145.451 40.3959 145.447C39.8694 145.443 39.438 146.053 39.4322 146.81C39.4265 147.567 39.8487 148.183 40.3752 148.187C40.9017 148.191 41.3331 147.581 41.3388 146.824Z'
          fill='#3F3D56'
        />
        <path
          d='M57.5078 162.162C57.5135 161.405 57.0913 160.789 56.5649 160.785C56.0384 160.781 55.6069 161.391 55.6012 162.148C55.5955 162.905 56.0176 163.521 56.5441 163.525C57.0706 163.529 57.5021 162.919 57.5078 162.162Z'
          fill='#E6E6E6'
        />
        <path
          d='M50.6894 165.567C50.6951 164.81 50.273 164.193 49.7465 164.189C49.22 164.185 48.7885 164.796 48.7828 165.552C48.7771 166.309 49.1993 166.926 49.7257 166.93C50.2522 166.934 50.6837 166.324 50.6894 165.567Z'
          fill='#FF6584'
        />
        <path
          d='M60.1806 171.859C60.1864 171.102 59.7642 170.485 59.2377 170.481C58.7112 170.477 58.2798 171.088 58.274 171.844C58.2683 172.601 58.6905 173.218 59.217 173.222C59.7435 173.226 60.1749 172.616 60.1806 171.859Z'
          fill='#3F3D56'
        />
        <path
          d='M161.88 127.451C162 126.627 161.136 125.819 159.951 125.646C158.766 125.474 157.708 126.002 157.588 126.827C157.468 127.651 158.332 128.459 159.517 128.632C160.702 128.804 161.76 128.275 161.88 127.451Z'
          fill='#E6E6E6'
        />
        <path
          d='M175.989 126.075C176.109 125.25 175.246 124.442 174.061 124.27C172.876 124.097 171.818 124.626 171.698 125.45C171.578 126.275 172.441 127.083 173.626 127.255C174.811 127.427 175.869 126.899 175.989 126.075Z'
          fill='#3F3D56'
        />
        <path
          d='M158.016 116.029C158.136 115.204 157.272 114.396 156.087 114.224C154.902 114.051 153.844 114.58 153.724 115.404C153.604 116.229 154.468 117.037 155.653 117.209C156.838 117.382 157.896 116.853 158.016 116.029Z'
          fill='#FF6584'
        />
        <path
          d='M151.885 111.35C151.89 110.593 151.468 109.976 150.942 109.972C150.415 109.968 149.984 110.578 149.978 111.335C149.972 112.092 150.395 112.709 150.921 112.713C151.448 112.717 151.879 112.106 151.885 111.35Z'
          fill='#E6E6E6'
        />
        <path
          d='M149.806 102.634C149.811 101.877 149.389 101.261 148.863 101.257C148.336 101.253 147.905 101.863 147.899 102.62C147.893 103.377 148.315 103.993 148.842 103.997C149.368 104.001 149.8 103.391 149.806 102.634Z'
          fill='#3F3D56'
        />
        <path
          d='M165.974 117.972C165.979 117.215 165.557 116.599 165.031 116.595C164.504 116.591 164.073 117.201 164.067 117.958C164.061 118.715 164.483 119.331 165.01 119.335C165.536 119.339 165.968 118.729 165.974 117.972Z'
          fill='#E6E6E6'
        />
        <path
          d='M159.155 121.377C159.161 120.62 158.739 120.003 158.212 119.999C157.686 119.995 157.254 120.606 157.249 121.363C157.243 122.119 157.665 122.736 158.192 122.74C158.718 122.744 159.15 122.134 159.155 121.377Z'
          fill='#FF6584'
        />
        <path
          d='M168.646 127.669C168.652 126.912 168.23 126.295 167.704 126.291C167.177 126.287 166.746 126.898 166.74 127.655C166.734 128.411 167.156 129.028 167.683 129.032C168.209 129.036 168.641 128.426 168.646 127.669Z'
          fill='#3F3D56'
        />
        <path
          d='M109.368 45.6717C109.488 44.8473 108.625 44.0392 107.44 43.8668C106.254 43.6944 105.197 44.2229 105.077 45.0473C104.957 45.8717 105.82 46.6798 107.005 46.8522C108.19 47.0247 109.248 46.4961 109.368 45.6717Z'
          fill='white'
        />
        <path
          d='M123.477 44.2948C123.597 43.4704 122.734 42.6623 121.549 42.4899C120.364 42.3175 119.306 42.846 119.186 43.6704C119.066 44.4948 119.929 45.3029 121.115 45.4753C122.3 45.6477 123.358 45.1192 123.477 44.2948Z'
          fill='#3F3D56'
        />
        <path
          d='M105.505 34.2484C105.625 33.424 104.761 32.6159 103.576 32.4435C102.391 32.2711 101.333 32.7996 101.213 33.624C101.093 34.4484 101.957 35.2565 103.142 35.4289C104.327 35.6013 105.385 35.0728 105.505 34.2484Z'
          fill='#FF6584'
        />
        <path
          d='M99.374 29.5693C99.3797 28.8125 98.9575 28.1957 98.4311 28.1918C97.9046 28.1878 97.4731 28.7981 97.4674 29.5549C97.4617 30.3118 97.8838 30.9285 98.4103 30.9325C98.9368 30.9365 99.3683 30.3262 99.374 29.5693Z'
          fill='#E6E6E6'
        />
        <path
          d='M97.2939 20.8545C97.2996 20.0977 96.8775 19.4809 96.351 19.4769C95.8245 19.4729 95.393 20.0832 95.3873 20.8401C95.3816 21.5969 95.8038 22.2137 96.3302 22.2177C96.8567 22.2216 97.2882 21.6113 97.2939 20.8545Z'
          fill='#3F3D56'
        />
        <path
          d='M113.462 36.1924C113.468 35.4356 113.045 34.8188 112.519 34.8148C111.992 34.8108 111.561 35.4211 111.555 36.178C111.55 36.9348 111.972 37.5516 112.498 37.5555C113.025 37.5595 113.456 36.9492 113.462 36.1924Z'
          fill='#E6E6E6'
        />
        <path
          d='M106.644 39.5967C106.65 38.8399 106.228 38.2231 105.702 38.2191C105.175 38.2151 104.744 38.8254 104.738 39.5823C104.732 40.3391 105.154 40.9559 105.681 40.9598C106.207 40.9638 106.639 40.3535 106.644 39.5967Z'
          fill='#FF6584'
        />
        <path
          d='M116.135 45.8887C116.14 45.1318 115.718 44.5151 115.192 44.5111C114.665 44.5071 114.234 45.1174 114.228 45.8743C114.222 46.6311 114.645 47.2479 115.171 47.2518C115.698 47.2558 116.129 46.6455 116.135 45.8887Z'
          fill='#3F3D56'
        />
      </g>
      <defs>
        <clipPath id='clip0_980_40335'>
          <rect
            width='176'
            height='201.024'
            fill='white'
            transform='translate(0 0.487793)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default WelcomeProjectIcon;
