import { styled } from '@mui/system';
import { Paper as MaterialPaper } from '@mui/material';

const Paper = styled(MaterialPaper)`
  border: 1px solid #ededed;
  border-radius: 8px;
  box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.06),
    0px 12px 16px -4px rgba(0, 0, 0, 0.1);
  overflow: clip;
`;

export { Paper };
