import React, { useEffect, useState } from 'react';
import EditPencilIcon from '../../../../../../../../../../shared/images/EditPencilIcon';
import {
  ListItemTypography,
  StyledListItem,
  StyledListItemButton,
} from '../../../../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/styles';
import { ListActionsProps } from './types';
import EditTrigger from '../../../../../../../Alerts/components/Header/components/EditTrigger/EditTrigger';
import { useAuth } from '../../../../../../../../../../hooks';
import useAlerts from '../../../../../../../../hooks/useAlerts';
import { useAppSelector } from '../../../../../../../../../../store';

const EditAction: React.FC<ListActionsProps> = ({
  id,
  selectBy,
  popupClose,
}) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const { onGetTriggerInfo } = useAlerts();

  const [isOpenEditModal, setOpenEditModal] = useState(false);

  const onOpen = () => {
    setOpenEditModal(true);
  };

  const onClose = () => {
    popupClose();
    setOpenEditModal(false);
  };

  useEffect(() => {
    if (currentAccount?.accountId) {
      onGetTriggerInfo({ id: currentAccount.accountId, triggerId: id });
    }
  }, [currentAccount?.accountId, id, onGetTriggerInfo]);

  return (
    <>
      <StyledListItem onClick={onOpen}>
        <StyledListItemButton>
          <EditPencilIcon />
          <ListItemTypography marginLeft='8px'>Edit Trigger</ListItemTypography>
        </StyledListItemButton>
      </StyledListItem>
      <EditTrigger
        handleClose={onClose}
        id={id}
        open={isOpenEditModal}
        type={selectBy}
      />
    </>
  );
};

export default EditAction;
