import React, { useCallback, useMemo, useState } from 'react';
import {
  debounce,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { StyledHead, StyledHeader } from './styles';
import SearchIcon from '../../../../../../../../../../shared/images/SearchIcon';

import {
  GrayButtonSmallForSelect,
  PrimaryButtonCustom,
  PrimaryButtonSmall,
} from '../../../../../../../../../../shared/buttons/styles';
import PlusIcon from '../../../../../../../../../../shared/images/PlusIcon';
import PopupTags from '../../../../../../../../../folders/components/Folders/components/TableFolders/components/Header/components/PopupTags/PopupTags';
import AddKeywordsModal from '../../../../../../../Projects/components/ProjectRedactorModal/AddKeywords/AddKeywordsModal';
import useProjects from '../../../../../../../../hooks/useProjects';
import { PopupTableSettings } from '../';
import PopupTablePosition from '../PopupPositions/PopupTablePosition';
import FilterIcon from '../../../../../../../../../../shared/images/FilterIcon';
import CloseIcon from '@mui/icons-material/Close';
import ActionsMenu from '../ActionsMenu/ActionsMenu';
import { CountLabel } from '../../../../../../../Projects/components/TableProjects/components/Header/styles';
import { checkObjectProperties } from '../../../../../../../../utils';
import { DEFAULT_KEYWORD_POSITIONS_MODEL } from '../../../../../../../../constants';
import RefreshAllKeywordsButton from './components/RefreshAllKeywordsButton';
import { OutlinedInputCustom } from '../../../../../../../../../../shared/inputs/styled';
import { useAppSelector } from '../../../../../../../../../../store';
import PopupKeywordTags from './components/PopupKeywordTags/PopupKeywordTags';
import useAccountLimits from '../../../../../../../../../auth/hooks/useAccountLimits';

const Header = () => {
  const smallScreen = useMediaQuery('(max-width:1000px)');
  const extraSmallScreen = useMediaQuery('(max-width:680px)');
  const extraTooSmallScreen = useMediaQuery('(max-width:550px)');
  const seScreen = useMediaQuery('(max-width:400px)');
  const [showSearchMobile, setShowSearchMobile] = useState(false);

  const [isOpenAddKeywordsModal, setIsOpenAddKeywordsModal] =
    React.useState(false);

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const {
    onSetShowTableFilters,
    onGetKeywordRankings,
    onGetDeviceTypes,
    onGetCurrentProject,
    onSetSearchKeywordRankings,
    onSetRowSelectionKeywordsModel,
  } = useProjects();
  const { onCheckSubscriptionExpiration } = useAccountLimits();

  const isShowTableFilters = useAppSelector(
    state => state.projects.isShowTableFilters
  );
  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );
  const deviceType = useAppSelector(
    state => state.projects.projectExpandedGraphs.deviceType
  );
  const keywordPositionModel = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table
        .keywordPositionModel
  );
  const rowSelectionModel = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table
        .rowSelectionModel
  );
  const paginationModel = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table.paginationModel
  );
  const sortModel = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table.sortModel
  );
  const search = useAppSelector(
    state => state.projects.projectExpandedGraphs.keywordRankings.table.search
  );
  const keywordRanking = useAppSelector(
    state => state.projects.projectExpandedGraphs.keywordRankings.keywordRanking
  );
  const keywordSelectedTags = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table.selectedTags
  );

  const memoKeywordSelectedTags = useMemo(
    () => keywordSelectedTags.map(item => item.id),
    [keywordSelectedTags]
  );

  const isViewOnly =
    !currentAccount?.accountId || currentAccount?.role?.name === 'ViewOnly';

  const handleShowFilters = () => onSetShowTableFilters(!isShowTableFilters);

  const [localSearch, setLocalSearch] = useState(search);

  const [isOpenMiddleSearch, setIsOpenMiddleSearch] = useState(false);

  const debounceSearch = useCallback(
    debounce(value => {
      onSetSearchKeywordRankings(value);
    }, 500),
    [onSetSearchKeywordRankings]
  );

  const handleSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLocalSearch(event.target.value);
    debounceSearch(event.target.value);
  };

  const handleClearSearch = () => {
    setLocalSearch('');
    debounceSearch('');
  };

  const successFn = () => {
    if (projectExpanded?.id && deviceType) {
      onGetKeywordRankings({
        accountId: currentAccount.accountId,
        projectId: projectExpanded.id,
        params: {
          page: paginationModel.page,
          limit: paginationModel.pageSize,
          search: search,
          sortBy: sortModel[0]?.field,
          sortOrder: sortModel[0]?.sort,
        },
        filters: checkObjectProperties(
          DEFAULT_KEYWORD_POSITIONS_MODEL,
          keywordPositionModel
        ),
        deviceType: deviceType,
        tagIds: memoKeywordSelectedTags,
      });
    }
  };

  const handleCloseAddKeywordsModal = () => {
    setIsOpenAddKeywordsModal(false);
    successFn();
  };

  const handleOpenAddKeywordsModal = () => {
    if (projectExpanded?.id && currentAccount?.accountId && deviceType) {
      const isExpiration = onCheckSubscriptionExpiration();
      if (!isExpiration) {
        !deviceType.length && onGetDeviceTypes();
        onGetCurrentProject({
          accountId: currentAccount.accountId,
          projectId: projectExpanded.id,
          tags: true,
          notes: true,
          successFn: () => {
            setIsOpenAddKeywordsModal(true);
          },
        });
      }
    }
  };

  const onClearSelected = () => {
    onSetRowSelectionKeywordsModel([]);
  };

  return (
    <>
      <StyledHeader container xs={12}>
        <Grid
          container
          item
          xs={12}
          justifyContent={'space-between'}
          alignItems={'center'}
          flexWrap={'wrap'}
          gap='8px'
          marginBottom={
            !extraSmallScreen ? '42px' : isShowTableFilters ? '13px' : '26px'
          }
        >
          <Grid item lg={2}>
            <StyledHead>Keyword Rankings</StyledHead>
          </Grid>

          <Grid
            item
            spacing={2}
            lg={9}
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              gap: '8px',
            }}
          >
            {!extraSmallScreen ? (
              <Grid item>
                <OutlinedInputCustom
                  sx={{
                    width:
                      smallScreen && !showSearchMobile
                        ? isOpenMiddleSearch
                          ? null
                          : '44px'
                        : null,
                  }}
                  onClick={() => setIsOpenMiddleSearch(true)}
                  onBlur={() => setIsOpenMiddleSearch(false)}
                  $padding={'7.94px 14px 7.94px 0'}
                  size='small'
                  type='text'
                  id='searchKeyword'
                  name='searchKeyword'
                  placeholder='Search keyword'
                  value={localSearch}
                  onChange={handleSearch}
                  startAdornment={
                    <InputAdornment position='start' disablePointerEvents>
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </Grid>
            ) : (
              <Grid item>
                <GrayButtonSmallForSelect
                  isSelected={isShowTableFilters}
                  sx={{
                    padding: '5px 12px !important',
                    minWidth: 'fit-content',
                    height: '36px',
                  }}
                  size='small'
                  outline
                  startIcon={
                    !seScreen ? (
                      <FilterIcon
                        color={isShowTableFilters ? '#0E59BA' : '#344054'}
                      />
                    ) : null
                  }
                  onClick={handleShowFilters}
                >
                  {!seScreen ? (
                    'Filters'
                  ) : (
                    <FilterIcon
                      color={isShowTableFilters ? '#0E59BA' : '#344054'}
                    />
                  )}
                </GrayButtonSmallForSelect>
              </Grid>
            )}

            {!extraSmallScreen && !isViewOnly ? (
              <RefreshAllKeywordsButton />
            ) : null}

            {extraSmallScreen && !isViewOnly ? (
              <Grid item>
                <PrimaryButtonSmall
                  sx={{
                    minWidth: extraSmallScreen ? '40px !important' : null,
                    height: '36px',
                  }}
                  variant='contained'
                  size='small'
                  onClick={handleOpenAddKeywordsModal}
                >
                  <PlusIcon />
                </PrimaryButtonSmall>
              </Grid>
            ) : null}

            <Grid item>
              <PopupTableSettings />
            </Grid>
          </Grid>
        </Grid>

        {extraSmallScreen && isShowTableFilters ? (
          <Grid
            item
            sx={{
              display: 'flex',
              gap: '8px',
              flexDirection: 'row',
              mb: '8px',
            }}
          >
            <Grid>
              <PopupTablePosition />
            </Grid>
            <Grid>
              <PopupTags />
            </Grid>
          </Grid>
        ) : null}

        <Grid
          container
          item
          xs={12}
          justifyContent={'space-between'}
          alignItems={'center'}
          flexWrap={'wrap'}
          gap='8px'
        >
          {rowSelectionModel.length ? (
            <Grid item>
              <Grid container item alignItems='center'>
                <Grid item>
                  <IconButton
                    onClick={onClearSelected}
                    sx={{ padding: '0 !important', marginRight: '8px' }}
                  >
                    <CloseIcon fontSize='small' />
                  </IconButton>
                </Grid>
                <Grid item sx={{ paddingRight: '16px' }}>
                  <Typography
                    fontSize='14px'
                    lineHeight='14px'
                    color='#101828'
                    fontWeight='500'
                    alignItems='center'
                  >
                    {`${rowSelectionModel.length} Selected`}
                  </Typography>
                </Grid>
                <Grid item>
                  <ActionsMenu />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item display={'flex'} alignItems={'center'} lg={2}>
              <Grid marginRight={'8px'}>
                <Typography
                  variant='h6'
                  fontWeight='600'
                  color='#101828'
                  fontSize='18px'
                  lineHeight='28px'
                >
                  All Keywords
                </Typography>
              </Grid>
              <Grid>
                <CountLabel>{keywordRanking?.meta?.totalItems || 0}</CountLabel>
              </Grid>
            </Grid>
          )}
          <Grid
            spacing={2}
            lg={9}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '8px',
              width:
                extraTooSmallScreen && showSearchMobile ? '100%' : 'initial',
            }}
          >
            {!extraSmallScreen ? (
              <>
                <Grid>
                  <PopupTablePosition />
                </Grid>
                <Grid>
                  <PopupKeywordTags />
                </Grid>
                {!isViewOnly && (
                  <Grid>
                    <PrimaryButtonCustom
                      $padding='8px 12px'
                      variant='contained'
                      size='small'
                      sx={{ gap: '4px' }}
                      onClick={handleOpenAddKeywordsModal}
                    >
                      <PlusIcon />
                      Add Keywords
                    </PrimaryButtonCustom>
                  </Grid>
                )}
              </>
            ) : (
              <Grid
                item
                xs={extraSmallScreen && showSearchMobile ? 12 : undefined}
              >
                <OutlinedInputCustom
                  sx={{
                    width:
                      extraSmallScreen && !showSearchMobile ? '44px' : null,
                  }}
                  $padding={'7.94px 14px 7.94px 0'}
                  fullWidth
                  size='small'
                  type='text'
                  id='searchKeyword'
                  name='searchKeyword'
                  placeholder='Search Keyword'
                  value={localSearch}
                  onChange={handleSearch}
                  onFocus={() => {
                    extraSmallScreen && setShowSearchMobile(true);
                  }}
                  startAdornment={
                    <InputAdornment position='start' disablePointerEvents>
                      <SearchIcon />
                    </InputAdornment>
                  }
                  endAdornment={
                    extraSmallScreen && showSearchMobile ? (
                      <>
                        <Typography
                          fontSize='14px'
                          fontWeight='600'
                          lineHeight='20px'
                          color='#126FE9'
                          sx={{ cursor: 'pointer', paddingRight: '4px' }}
                          onClick={handleClearSearch}
                        >
                          Clear
                        </Typography>
                        <CloseIcon
                          fontSize='small'
                          sx={{ color: '#101828', cursor: 'pointer' }}
                          onClick={() =>
                            extraSmallScreen && setShowSearchMobile(false)
                          }
                        />
                      </>
                    ) : null
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </StyledHeader>
      {isOpenAddKeywordsModal ? (
        <AddKeywordsModal
          open={isOpenAddKeywordsModal}
          handleClose={handleCloseAddKeywordsModal}
        />
      ) : null}
    </>
  );
};

export default Header;
