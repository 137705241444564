import { useState, useEffect } from 'react';

/**
 * A custom hook that manages local storage with a specified key.
 *
 * @template T
 * @param {string} key - The key under which the value is stored in local storage.
 * @param {T} initialValue - The initial value to be used if no value is found in local storage.
 * @returns {[T, (value: T) => void]} An array containing the current value from local storage and a function to update it.
 */
const useLocalStorage = <T>(key: string, initialValue: T) => {
  // State to hold the value from local storage
  const [value, setValue] = useState<T>(() => {
    // Attempt to retrieve the item from local storage
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue; // Parse and return the item, or return the initial value
  });

  // Effect to update local storage whenever the value changes
  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value)); // Store the new value in local storage
  }, [key, value]);

  // Effect to handle storage changes from other tabs/windows
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === key) {
        setValue(
          event.newValue !== null ? JSON.parse(event.newValue) : initialValue
        ); // Update state if the item is changed in another tab/window
      }
    };

    // Add event listener for storage events
    window.addEventListener('storage', handleStorageChange);

    return () => {
      // Cleanup the event listener on component unmount
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key, initialValue]);

  return [value, setValue] as const; // Return the value and the setter function as a tuple
};

export default useLocalStorage;
