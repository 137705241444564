import React from 'react';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { KeywordsActionProps } from '../../../../../../sharedLinks/components/sharedProject/components/KeywordRankings/components/KeywordRankingsTable/components/ActionsMenu/components/types';
import {
  ListItemTypography,
  StyledListItem,
} from '../../../../../../projects/components/ProjectExpanded/components/KeywordRankings/components/KeywordRankingsTable/components/ActionsMenu/styles';
import SmallTrashIcon from '../../../../../../../shared/images/SmallTrashIcom';
import { useAuth, useEmailReports } from '../../../../../../../hooks';
import { closeAndUndoSnackbarAction } from '../../../../../../../utils/snackbar';
import CustomToast from '../../../../../../../shared/CustomToast/CustomToast';
import { DEFAULT_GET_USERS_PARAMS } from '../../../../../../auth/components/Settings/components/Team/constants';
import { StyledListItemButton } from '../../../../../../../shared/buttons/styles';
import { useAppSelector } from '../../../../../../../store';

const DeleteAction: React.FC<KeywordsActionProps> = ({ popupClose }) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const {
    onSetDeleteElementsScheduledEmailReportsTable,
    onBulkDeleteEmailReports,
    onGetEmailReports,
    onSetRowSelectionModelEmailReports,
    tables: {
      scheduledEmailReports: {
        paginationModel,
        search,
        sortModel,
        rowSelectionModel,
      },
    },
  } = useEmailReports();

  const handleHardDelete = () => {
    if (currentAccount?.accountId) {
      onBulkDeleteEmailReports({
        accountId: currentAccount.accountId,
        emailReportIds: rowSelectionModel as number[],
        successFn: () => {
          if (currentAccount?.accountId) {
            onSetRowSelectionModelEmailReports([]);
            onGetEmailReports({
              id: currentAccount.accountId,
              page: paginationModel.page,
              limit: paginationModel.pageSize,
              search: search || DEFAULT_GET_USERS_PARAMS.search,
              sortBy: sortModel[0]?.field,
              sortOrder: sortModel[0]?.sort,
            });
            onSetDeleteElementsScheduledEmailReportsTable([]);
          }
        },
      });
    }
  };

  const handleDeleteOneKeywords = () => {
    onSetDeleteElementsScheduledEmailReportsTable(rowSelectionModel);
    popupClose();
    let undoDelete = false;
    const onUndo = () => {
      undoDelete = true;
      onSetDeleteElementsScheduledEmailReportsTable([]);
    };
    enqueueSnackbar(
      <CustomToast
        message='Email Reports Removed'
        submessage={`Email Reports has been successfully removed.`}
      />,
      {
        action: (snackbarId: SnackbarKey) =>
          closeAndUndoSnackbarAction(snackbarId, onUndo),
        variant: 'error',
      }
    );
    setTimeout(() => {
      if (!undoDelete) {
        handleHardDelete();
      }
    }, 5000);
  };

  return (
    <StyledListItem>
      <StyledListItemButton onClick={handleDeleteOneKeywords}>
        <SmallTrashIcon />
        <ListItemTypography marginLeft='8px' sx={{ color: '#B42318' }}>
          Delete Email Reports
        </ListItemTypography>
      </StyledListItemButton>
    </StyledListItem>
  );
};

export default DeleteAction;
