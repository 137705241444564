import React from 'react';
import Grid from '@mui/material/Grid';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { StylesActionRequiredToast } from './styles';
import { useAppSelector } from '../../../../../../../../store';

const ActionRequiredToast = () => {
  const actionRequired = useAppSelector(
    state => state.auth.myAccount.actionRequired
  );

  if (!actionRequired) {
    return null;
  }

  return (
    <StylesActionRequiredToast container gap={2}>
      <ErrorOutlineIcon fontSize='medium' color='error' />
      <Grid item>
        Action Required: To complete the email change process, click the
        confirmation link in your old email, then the verification link in your
        new email.
      </Grid>
    </StylesActionRequiredToast>
  );
};

export default ActionRequiredToast;
