import React, { useState } from 'react';
import {
  Divider,
  FormControlLabel,
  Grid,
  InputBase,
  InputLabel,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { AccountTag, AddTagsProps } from '../../../../types';

import { AddTagsPopover } from './styles';
import { ButtonsWrapper } from '../../../../../auth/components/Settings/components/Team/components/customTableStyles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../../shared/buttons/styles';
import { SearchContainer } from '../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import { SmallContainerCheckbox } from '../../../../../../shared/checkbox/styles';

import SearchIcon from '../../../../../../shared/images/SearchIcon';
import PlusIcon from '../../../../../../shared/images/PlusIcon';
import CustomCheckboxImage from '../../../../../../shared/images/CustomCheckbox';
import CheckedIcon from '../../../../../../shared/images/CheckedIcon';

const AddTagsPopup: React.FC<AddTagsProps> = ({
  closePopover,
  tags,
  tagIds,
  listTags,
  setFieldValue,
  refTagsInput,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const [searchString, setSearchString] = useState('');

  const [createdTags, setCreatedTags] = useState<string[]>(tags);
  const [checkedCreatedTags, setCheckedCreatedTags] = useState<string[]>(tags);

  const [checkedAccountTags, setCheckedAccountTags] =
    useState<AccountTag[]>(tagIds);

  const handleSelectCreatedTag = (item: string, checked: boolean) => {
    setCheckedCreatedTags([...checkedCreatedTags, item]);
    if (!checked) {
      setCheckedCreatedTags(
        checkedCreatedTags.filter(filterItem => filterItem !== item)
      );
    }
  };

  const handleSelectAccountTag = (tag: AccountTag, checked: boolean) => {
    setCheckedAccountTags([...checkedAccountTags, tag]);
    if (!checked) {
      setCheckedAccountTags(
        checkedAccountTags.filter(item => item.id !== tag.id)
      );
    }
  };

  const handleConfirm = () => {
    setFieldValue('tags', checkedCreatedTags);
    setFieldValue('tagIds', checkedAccountTags);
    closePopover();
  };

  return (
    <AddTagsPopover sx={{ width: refTagsInput?.current?.clientWidth || 512 }}>
      <SearchContainer sx={{ padding: '0 16px', gap: '0' }}>
        <InputLabel htmlFor='searchTag'>
          <SearchIcon />
        </InputLabel>
        <InputBase
          fullWidth
          id='searchTag'
          placeholder='Search tag'
          value={searchString}
          onChange={e => setSearchString(e.target.value as string)}
        />
      </SearchContainer>
      <Divider
        orientation='horizontal'
        flexItem
        sx={{
          margin: '8px 16px',
          color: '#EDEDED',
        }}
      />

      {searchString &&
      !createdTags.includes(searchString) &&
      !listTags.some(x => x.name === searchString) ? (
        <Grid
          sx={{
            display: 'flex',
            gap: '8px',
            padding: '8px 16px',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            setCreatedTags(prevState => prevState.concat(searchString));
            setCheckedCreatedTags(prevState => prevState.concat(searchString));
            setSearchString('');
          }}
        >
          <PlusIcon stroke={'#667085'} />
          <Typography
            component='span'
            sx={{ color: '#475467', fontSize: '14px', fontWeight: '600' }}
          >
            Create tag “{searchString}”
          </Typography>
        </Grid>
      ) : null}

      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer',
        }}
      >
        {createdTags.length ? (
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '8px',
              cursor: 'pointer',
            }}
          >
            {createdTags.map(item => (
              <FormControlLabel
                key={item}
                label={item}
                sx={{
                  marginLeft: '6px',
                  gap: '8px',
                  color: '#101828',
                  fontSize: '14px',
                  padding: '8px',
                  backgroundColor: checkedCreatedTags.includes(item)
                    ? '#EEF3FF'
                    : 'initial',
                  borderRadius: '8px',
                  ':hover': {
                    backgroundColor: '#F6F6F6',
                    borderRadius: '8px',
                  },
                }}
                control={
                  <SmallContainerCheckbox
                    onChange={() =>
                      handleSelectCreatedTag(
                        item,
                        !checkedCreatedTags.includes(item)
                      )
                    }
                    checked={checkedCreatedTags.includes(item)}
                    icon={<CustomCheckboxImage />}
                    checkedIcon={<CheckedIcon />}
                  />
                }
              />
            ))}
          </Grid>
        ) : null}
        {listTags.length ? (
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '8px',
              cursor: 'pointer',
            }}
          >
            {listTags.map(item => (
              <FormControlLabel
                key={item.id}
                label={item.name}
                sx={{
                  marginLeft: '6px',
                  gap: '8px',
                  color: '#101828',
                  fontSize: '14px',
                  padding: '8px',
                  backgroundColor: checkedAccountTags.some(
                    x => x.id === item.id
                  )
                    ? '#EEF3FF'
                    : 'initial',
                  borderRadius: '8px',
                  ':hover': {
                    backgroundColor: '#F6F6F6',
                    borderRadius: '8px',
                  },
                }}
                control={
                  <SmallContainerCheckbox
                    onChange={() =>
                      handleSelectAccountTag(
                        item,
                        !checkedAccountTags.some(x => x.id === item.id)
                      )
                    }
                    checked={checkedAccountTags.some(x => x.id === item.id)}
                    icon={<CustomCheckboxImage />}
                    checkedIcon={<CheckedIcon />}
                  />
                }
              />
            ))}
          </Grid>
        ) : null}
      </Grid>

      <ButtonsWrapper
        container
        xs={12}
        sx={{ padding: '8px 16px 0 !important' }}
      >
        <Grid item xs={extraSmallScreen && 12}>
          <PrimaryButtonSmall
            fullWidth={extraSmallScreen}
            variant='contained'
            size='small'
            // disabled={formik.isSubmitting || !formik.isValid}
            onClick={handleConfirm}
          >
            Apply
          </PrimaryButtonSmall>
        </Grid>
        <Grid item xs={extraSmallScreen && 12}>
          <GrayButtonSmall
            fullWidth={extraSmallScreen}
            size='small'
            outline
            onClick={closePopover}
          >
            Cancel
          </GrayButtonSmall>
        </Grid>
      </ButtonsWrapper>
    </AddTagsPopover>
  );
};

export default AddTagsPopup;
