import { styled } from '@mui/system';
import { OutlinedInput } from '@mui/material';

const OutlinedInputCustom = styled(OutlinedInput)<{ $padding?: string }>`
  .MuiOutlinedInput-input {
    padding: ${props => props.$padding};
  }
`;

export { OutlinedInputCustom };
