import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
  getGoogleKeepMeLoggedInLocalStorage,
  saveAccessInLocalStorage,
  saveGoogleAuthLoader,
  saveRefreshInLocalStorage,
} from '../../../utils/localStorage';
import { useAuth } from '../../../hooks';

/**
 * Component for handling Google Authentication and saving tokens.
 * @returns {React.ReactElement} A component that manages authentication.
 */
const AuthGoogle = () => {
  const searchParams = new URLSearchParams(useLocation().search);
  const accessToken = searchParams.get('accessToken');
  const refreshToken = searchParams.get('refreshToken');

  const navigate = useNavigate();

  const { checkAuth } = useAuth();

  const googleKeepMeLoggedIn = getGoogleKeepMeLoggedInLocalStorage();

  useEffect(() => {
    if (accessToken && refreshToken) {
      saveAccessInLocalStorage(accessToken);
      saveRefreshInLocalStorage(refreshToken);
    }
    saveGoogleAuthLoader('1');
    window.close();
  }, [navigate, googleKeepMeLoggedIn, accessToken, refreshToken, checkAuth]);
  return <div />;
};

export default AuthGoogle;
