import { Box, debounce, Grid } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';

import { CustomTable, InviteUserModal } from './components';
import CustomTableHeaderTolls from './components/CustomTableHeaderTolls';
import { useAuth } from '../../../../../../hooks';
import CustomTablePagination from './components/CustomTablePagination';
import { DEFAULT_GET_USERS_PARAMS } from './constants';
import { useAppSelector } from '../../../../../../store';
import useAccountLimits from '../../../../hooks/useAccountLimits';

const Team = () => {
  const { onGetUsers, onSetSortModelTeam, onSetSearchKeywordUsersTeam } =
    useAuth();
  const { onCheckSubscriptionExpiration } = useAccountLimits();

  const teamsDeleteElements = useAppSelector(
    state => state.auth.teamsDeleteElements
  );
  const users = useAppSelector(state => state.auth.users);
  const sortModelTeam = useAppSelector(
    state => state.auth.sorting.sortModelTeam
  );
  const searchKeywordUsersTeam = useAppSelector(
    state => state.auth.search.searchKeywordUsersTeam
  );
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: users?.meta?.itemsPerPage || DEFAULT_GET_USERS_PARAMS.limit,
    page:
      users?.meta?.currentPage - 1 >= 0
        ? users?.meta?.currentPage - 1
        : DEFAULT_GET_USERS_PARAMS.page - 1,
  });

  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  const [searchKeyword, setSearchKeyword] = useState('');

  const [openInviteUserModal, setOpenInviteUser] = React.useState(false);

  const rows = useMemo(() => {
    const arr1 = [
      ...(users?.items?.length
        ? users.items.map(user => ({
            id: user.id,
            name: user.name,
            email: user.email,
            role: user.role.name,
            dateAdded: user.dateAdded,
            dateAddedFullFormat: user.dateAddedFullFormat,
            lastActivity: user.lastActivity,
            lastActivityFullFormat: user.lastActivityFullFormat,
            registered: user.registered,
          }))
        : []),
    ];
    const arr2 = [...teamsDeleteElements];

    return arr1.filter(item => !arr2.includes(item.email));
  }, [teamsDeleteElements, users?.items]);

  const onRowSelectionModelChange = (
    rowSelectionModel: GridRowSelectionModel
  ) => {
    setRowSelectionModel(rowSelectionModel);
  };

  const handleOpenInviteUserModal = () => {
    const isExpiration = onCheckSubscriptionExpiration();
    if (!isExpiration) {
      setOpenInviteUser(true);
    }
  };
  const handleCloseInviteUserModal = () => {
    setOpenInviteUser(false);
  };

  const verify = useCallback(
    debounce(value => {
      if (currentAccount?.accountId) {
        onGetUsers({
          accountId: currentAccount.accountId,
          search: value,
          page: paginationModel.page + 1,
          limit: paginationModel.pageSize,
          sortBy: sortModelTeam[0]?.field,
          sortOrder: sortModelTeam[0]?.sort,
        });
      }
    }, 500),
    [currentAccount.accountId, paginationModel]
  );

  const onChangeSearchKeyword = (text: string) => {
    verify(text);
    setSearchKeyword(text);
    onSetSearchKeywordUsersTeam({ text });
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPaginationModel(prevState => ({
      ...prevState,
      page: newPage,
    }));
    onGetUsers({
      accountId: currentAccount.accountId,
      search: searchKeyword,
      page: newPage + 1,
      limit: paginationModel.pageSize,
      sortBy: sortModelTeam[0]?.field,
      sortOrder: sortModelTeam[0]?.sort,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const page = 0;
    const pageSize = parseInt(event.target.value, 10);
    setPaginationModel(() => ({
      pageSize: pageSize,
      page: page,
    }));
    onGetUsers({
      accountId: currentAccount.accountId,
      search: searchKeyword,
      page: page + 1,
      limit: pageSize,
      sortBy: sortModelTeam[0]?.field,
      sortOrder: sortModelTeam[0]?.sort,
    });
  };

  useEffect(() => {
    if (currentAccount.accountId) {
      setRowSelectionModel([]);
    }
  }, [currentAccount?.accountId]);

  useEffect(() => {
    if (currentAccount?.accountId) {
      onGetUsers({
        accountId: currentAccount.accountId,
        page: users?.meta?.currentPage || DEFAULT_GET_USERS_PARAMS.page,
        limit: users?.meta?.itemsPerPage || DEFAULT_GET_USERS_PARAMS.limit,
        search: searchKeywordUsersTeam || DEFAULT_GET_USERS_PARAMS.search,
        sortBy: sortModelTeam[0]?.field,
        sortOrder: sortModelTeam[0]?.sort,
      });
    }
  }, [sortModelTeam, currentAccount?.accountId]);

  return (
    <>
      <Box>
        <Grid container>
          <CustomTableHeaderTolls
            onRowSelectionModelChange={onRowSelectionModelChange}
            rowSelectionModel={rowSelectionModel}
            paginationModel={paginationModel}
            searchKeyword={searchKeyword}
            handleOpenInviteUserModal={handleOpenInviteUserModal}
            onChangeSearchKeyword={onChangeSearchKeyword}
          />
          <Grid item xs={12} paddingTop={3} paddingBottom={3} width='100%'>
            <CustomTable
              onRowSelectionModelChange={onRowSelectionModelChange}
              paginationModel={paginationModel}
              rows={rows}
              sortModel={sortModelTeam}
              rowSelectionModel={rowSelectionModel}
              setPaginationModel={setPaginationModel}
              onSortModelChange={onSetSortModelTeam}
            />
          </Grid>
          <Grid item xs={12} display='flex' justifyContent='flex-end'>
            <CustomTablePagination
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              page={paginationModel.page}
              rowsPerPage={paginationModel.pageSize}
              count={users?.meta?.totalItems || 0}
            />
          </Grid>
        </Grid>
      </Box>
      <InviteUserModal
        open={openInviteUserModal}
        handleClose={handleCloseInviteUserModal}
      />
    </>
  );
};

export default Team;
