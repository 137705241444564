import { styled } from '@mui/system';
import { Link, Typography } from '@mui/material';

const FooterWrapper = styled('div')`
  width: 100%;
  height: 12vh;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;

  ${props => props.theme.breakpoints.down('sm')} {
    height: 20vh;
  }

  // ${props => props.theme.breakpoints.down('xs')} {
  //   justify-content: flex-start;
  //   align-items: flex-start;
  // }
`;

const FooterContainer = styled('div')`
  width: 50%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;

  ${props => props.theme.breakpoints.down('lg')} {
    width: 85%;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column-reverse;
    align-items: center;
    border-top: 1px solid rgba(102, 112, 133, 0.3);
    padding-top: 35px;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    align-items: flex-start;
  }
`;

const FooterMobileContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;

  > div:last-child {
    padding-right: 50px;
  }
`;

const FooterItems = styled('div')`
  display: flex;
  flex-wrap: wrap;
  ${props => props.theme.breakpoints.down('sm')} {
    justify-content: space-around;
    padding-top: 10px;
  }
`;

const FooterItem = styled(Link)`
  padding-right: 16px;

  display: block;
  text-transform: none;
  text-decoration: none;
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;

  ${props => props.theme.breakpoints.down('sm')} {
    justify-content: space-around;
    padding-bottom: 15px;
  }

  :last-child {
    padding-right: 0;
  }
`;

const FooterMobileLabel = styled(Typography)`
  ${props => props.theme.breakpoints.up('xs')} {
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

export {
  FooterWrapper,
  FooterContainer,
  FooterMobileContainer,
  FooterItems,
  FooterItem,
  FooterMobileLabel,
};
