import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { CustomTooltip } from '../../../../../../shared/tooltip/styles';
import * as Tooltips from './Tooltips';
import {
  ButtonTooltipContainer,
  SearchEngine,
  SearchEngineContainer,
} from './styles';
import HelperIcon from '../../../../../../shared/images/HelperIcon';
import { FormikProps } from 'formik';
import { IDeviceType, NewProjectValues } from '../../../../types';
import { ISearchEngine } from '../../../../../auth/types';
import { getSearchEngineIcon } from '../../../../utils';
import {
  SEARCH_LOCATION_CHINA,
  SEARCH_LOCATION_GOOGLE,
} from '../../../../../../constants/options';
import NotificationSelectSearchEngine from '../../../NotificationSelectSearchEngine/NotificationSelectSearchEngine';
import { useProjects } from '../../../../../../hooks';
import { useAppSelector } from '../../../../../../store';

interface SearchEnginesListProps {
  showTooltip: boolean;
  openTooltip: number | undefined;
  onOpenTooltip: (tooltipIndex?: number) => void;
  onClickTooltip: (tooltipIndex?: number) => void;
  formik: FormikProps<NewProjectValues>;
  searchEngines: ISearchEngine[];
  deviceTypes: IDeviceType[];
}

const SearchEnginesList: React.FC<SearchEnginesListProps> = ({
  formik,
  searchEngines,
  deviceTypes,
  showTooltip,
  openTooltip,
  onOpenTooltip,
  onClickTooltip,
}) => {
  const searchLocations = useAppSelector(
    state => state.projects.searchLocations
  );

  const { onSetSearchLocationsMeta } = useProjects();

  const isRenderNotificationSelectSearchEngine = useMemo(
    () =>
      formik.values.searchEngine === 'YouTube' ||
      formik.values.searchEngine === 'Google My Business',
    [formik.values.searchEngine]
  );

  const handleClick = (item: ISearchEngine) => {
    formik.setFieldValue('searchEngine', item.name);
    if (
      item.name === 'YouTube' ||
      item.name === 'Google My Business' ||
      item.name === 'Google Maps'
    ) {
      const desktopDeviceType = deviceTypes.find(
        device => device.name === 'Desktop'
      );
      if (desktopDeviceType) {
        formik.setFieldValue('deviceType', desktopDeviceType);
      }
    }
    if (item.name === 'Baidu') {
      formik.setFieldValue('location', SEARCH_LOCATION_CHINA);
    }
    if (item.name !== 'Baidu') {
      formik.setFieldValue('location', SEARCH_LOCATION_GOOGLE);
    }
    if (
      formik.values.searchEngine === 'Google' ||
      formik.values.searchEngine === 'Google My Business' ||
      formik.values.searchEngine === 'Google Maps'
    ) {
      onSetSearchLocationsMeta({
        ...searchLocations.meta,
        search: 'United State',
      });
    }
  };

  return (
    <Grid item xs={12}>
      <Grid container fontWeight='500' alignItems='center' fontSize='14px'>
        <Grid item>Search Engine</Grid>
        <Grid item>
          <CustomTooltip
            title={<Tooltips.SearchEngine />}
            placement='top-start'
            arrow
            open={showTooltip && openTooltip === 2}
            onOpen={() => onOpenTooltip(2)}
            onClose={() => onClickTooltip(2)}
            onClick={() => onClickTooltip(2)}
          >
            <ButtonTooltipContainer onClick={() => onClickTooltip(2)}>
              <HelperIcon />
            </ButtonTooltipContainer>
          </CustomTooltip>
        </Grid>
        <SearchEngine>
          {searchEngines.map(item => (
            <SearchEngineContainer
              key={item.id}
              isSelected={formik.values.searchEngine === item.name}
              onClick={() => handleClick(item)}
            >
              {getSearchEngineIcon(item.name)}
              <Typography>{item.name}</Typography>
            </SearchEngineContainer>
          ))}
        </SearchEngine>
      </Grid>
      {isRenderNotificationSelectSearchEngine ? (
        <NotificationSelectSearchEngine
          searchEngine={formik.values.searchEngine}
        />
      ) : null}
    </Grid>
  );
};

export default SearchEnginesList;
