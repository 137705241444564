import {
  Box,
  Grid,
  InputLabel,
  Typography,
  OutlinedInput,
  FormHelperText,
  InputAdornment,
  IconButton,
} from '@mui/material';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { CustomFormHelperText } from '../../../../shared/components';
import SerpNestLogo from '../../../../shared/images/SerpNestLogo';
import ROUTES from '../../../../routes/constants';
import { useAuth } from '../../../../hooks';

import { GrayButton, PrimaryButton } from '../../../../shared/buttons/styles';
import { Paper } from '../../../../shared/paper/styles';
import { LogoContainer, SingUpLabel, Wrapper } from './styles';
import { PasswordStateLabel } from '../RegisterForm/styles';

/**
 * Component representing the reset password page.
 *
 * @component
 * @returns {JSX.Element} The ResetPassword component.
 */
const ResetPassword = () => {
  const { onResetPassword } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setConfirmPassword] = useState<boolean>(false);

  const pathSnippets = pathname.split('/');
  const passwordResetConfirmationToken = pathSnippets[2];

  /**
   * Toggles the visibility of the new password field.
   */
  const handleClickShowNewPassword = () => setShowNewPassword(show => !show);

  /**
   * Toggles the visibility of the confirm password field.
   */
  const handleClickConfirmPassword = () => setConfirmPassword(show => !show);

  /**
   * Prevents default action on mouse down for the password visibility icon.
   *
   * @param {React.MouseEvent<HTMLButtonElement>} event - The mouse down event.
   */
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_password: '',
      message: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .max(255)
        .required('New password is required.')
        .oneOf([Yup.ref('confirm_password')], 'Passwords must match'),
      confirm_password: Yup.string()
        .max(255)
        .required('Confirm password is required.')
        .oneOf([Yup.ref('password')], 'Passwords must match'),
    }),
    onSubmit: (values, { setErrors, setStatus, setSubmitting }) => {
      onResetPassword({
        passwordResetConfirmationToken,
        password: values.password,
        successFn: () => {
          navigate(ROUTES.login);
        },
        errorFn: (error: any) => {
          setStatus({ success: false });
          setSubmitting(false);
          setErrors(error);
        },
      });
    },
  });

  /**
   * Redirects the user to the login page.
   */
  const onBack = () => {
    navigate(ROUTES.login);
  };

  return (
    <Wrapper>
      <Grid container>
        <Grid xs={1} lg={4} md={2} />
        <Grid xs={10} lg={4} md={8}>
          <Paper
            sx={{
              p: 4,
            }}
          >
            <LogoContainer>
              <SerpNestLogo />
            </LogoContainer>
            <SingUpLabel variant='h5'>Reset Password</SingUpLabel>
            <Typography sx={{ mt: 1 }} variant='body2'>
              Enter a strong password that is at least 8 characters long.
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel shrink htmlFor='password'>
                      Enter new password
                    </InputLabel>
                    <OutlinedInput
                      fullWidth
                      id='password'
                      type={showNewPassword ? 'text' : 'password'}
                      value={formik.values.password}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        !!(formik.touched.password && formik.errors.password)
                      }
                      endAdornment={
                        <InputAdornment
                          position='end'
                          onClick={handleClickShowNewPassword}
                          sx={{ cursor: 'pointer' }}
                        >
                          <IconButton
                            aria-label='toggle password visibility'
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {showNewPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                          <PasswordStateLabel>
                            {showNewPassword ? <b>Hide</b> : <b>Show</b>}
                          </PasswordStateLabel>
                        </InputAdornment>
                      }
                      placeholder='Password'
                      name='password'
                    />
                    <FormHelperText>
                      <Typography variant='body2' color='#666666'>
                        Minimum 8 characters with at least one number.
                      </Typography>
                    </FormHelperText>
                    {formik.touched.password && formik.errors.password && (
                      <FormHelperText error id='password'>
                        <CustomFormHelperText error={formik.errors.password} />
                      </FormHelperText>
                    )}
                    {
                      <FormHelperText
                        error={!!formik.errors.message}
                        id='message'
                      >
                        {formik.errors.message && (
                          <CustomFormHelperText error={formik.errors.message} />
                        )}
                      </FormHelperText>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel shrink htmlFor='confirm_password'>
                      Confirm password
                    </InputLabel>
                    <OutlinedInput
                      fullWidth
                      type={showConfirmPassword ? 'text' : 'password'}
                      id='confirm_password'
                      name='confirm_password'
                      placeholder='Password'
                      value={formik.values.confirm_password}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        !!(
                          formik.touched.confirm_password &&
                          formik.errors.confirm_password
                        )
                      }
                      endAdornment={
                        <InputAdornment
                          position='end'
                          onClick={handleClickConfirmPassword}
                          sx={{ cursor: 'pointer' }}
                        >
                          <IconButton
                            aria-label='toggle password visibility'
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                          <PasswordStateLabel>
                            {showConfirmPassword ? <b>Hide</b> : <b>Show</b>}
                          </PasswordStateLabel>
                        </InputAdornment>
                      }
                    />
                    {formik.touched.confirm_password &&
                      formik.errors.confirm_password && (
                        <FormHelperText error id='confirm_password'>
                          <CustomFormHelperText
                            error={formik.errors.confirm_password}
                          />
                        </FormHelperText>
                      )}
                  </Grid>
                </Grid>
                <PrimaryButton
                  size='large'
                  type='submit'
                  fullWidth
                  variant='contained'
                  color={'primary'}
                  sx={{
                    mt: 3,
                  }}
                  disabled={
                    formik.isSubmitting || !(formik.isValid && formik.dirty)
                  }
                >
                  Reset Password
                </PrimaryButton>
                <GrayButton
                  size='large'
                  fullWidth
                  variant='text'
                  sx={{
                    mt: 2,
                  }}
                  startIcon={<ArrowBackIcon />}
                  onClick={onBack}
                >
                  Back to log in
                </GrayButton>
              </Box>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default ResetPassword;
