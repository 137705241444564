import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React from 'react';
import DownIcon from '../../../../../../../../../../shared/images/DownIcon';
import { GrayButtonCustom } from '../../../../../../../../../../shared/buttons/styles';
import { Popover } from '../../../../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import { MainContainer, StyledLinearLogSwitch } from './styled';
import { CustomCheckbox } from '../../../../../../../../../../shared/checkbox/styles';
import CustomCheckboxImage from '../../../../../../../../../../shared/images/CustomCheckbox';
import CheckedIcon from '../../../../../../../../../../shared/images/CheckedIcon';
import { CustomFormControlLabel } from '../../../../../../../../../auth/components/Login/styles';
import { Divider, Grid } from '@mui/material';
import { CustomSwitch } from '../../../../../../../../../../shared/Switch/styles';
import { useSharedLinks } from '../../../../../../../../../../hooks';
import { useAppSelector } from '../../../../../../../../../../store';

const GraphOptions = () => {
  const {
    onSetProjectPerformanceIsLinearGraph,
    onSetProjectPerformanceIsShowNotes,
  } = useSharedLinks();

  const isLinearGraph = useAppSelector(
    state => state.sharedLinks.projectGraphs.projectPerformance.isLinearGraph
  );
  const isShowNotes = useAppSelector(
    state => state.sharedLinks.projectGraphs.projectPerformance.isShowNotes
  );

  const handleSetProjectPerformanceIsShowNotes = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    onSetProjectPerformanceIsShowNotes(checked);
  };

  const handleSetProjectPerformanceIsLinearGraph = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    onSetProjectPerformanceIsLinearGraph(checked);
  };

  return (
    <>
      <PopupState variant='popover'>
        {popupState => (
          <>
            <GrayButtonCustom
              $padding='7px 12px'
              size='small'
              outline
              endIcon={<DownIcon stroke='#344054' />}
              {...bindTrigger(popupState)}
            >
              Graph Options
            </GrayButtonCustom>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MainContainer>
                <Grid margin={'8px 0'}>
                  <CustomFormControlLabel
                    control={
                      <CustomCheckbox
                        checked={isShowNotes}
                        onChange={handleSetProjectPerformanceIsShowNotes}
                        icon={<CustomCheckboxImage />}
                        checkedIcon={<CheckedIcon />}
                      />
                    }
                    label='Show notes'
                  />
                </Grid>
                <Divider
                  orientation='horizontal'
                  flexItem
                  sx={{
                    color: '#EDEDED',
                  }}
                />
                <StyledLinearLogSwitch margin={'16px 0'}>
                  <Grid>Log</Grid>
                  <Grid>
                    <CustomSwitch
                      checked={isLinearGraph}
                      onChange={handleSetProjectPerformanceIsLinearGraph}
                    />
                  </Grid>
                  <Grid>Linear</Grid>
                </StyledLinearLogSwitch>
              </MainContainer>
            </Popover>
          </>
        )}
      </PopupState>
    </>
  );
};

export default GraphOptions;
