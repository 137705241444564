import { useNotes } from '../../../../../../../hooks';
import { Stack } from '@mui/material';
import React from 'react';

const CustomNoRowsOverlay = () => {
  const {
    tables: {
      projectNotes: { search },
    },
  } = useNotes();
  return (
    <Stack
      height='100%'
      alignItems='center'
      justifyContent='center'
      textAlign='center'
    >
      No note found under search term “{search}”.
    </Stack>
  );
};

export default CustomNoRowsOverlay;
