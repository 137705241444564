import { useCallback } from 'react';

import { useAppDispatch } from '../../../store';

import {
  cancelAccountDeletionThunk,
  changeAccountBrandingThunk,
  changeAccountSettingsThunk,
  changeEmailThunk,
  checkAuthThunk,
  clearRecentlyViewedThunk,
  confirmEmailThunk,
  deleteAccountThunk,
  deleteInvitationsTeamThunk,
  deleteOneUserTeamThunk,
  editUserTeamThunk,
  emailChangeConfirmationThunk,
  forgotPasswordThunk,
  getAccountLimitThunk,
  getAddressGeolocationThunk,
  getBillingThunk,
  getCheckoutSettingsThunk,
  getCountriesThunk,
  getInvitationTeamFullInfoThunk,
  getInvoicePDFThunk,
  getInvoicesThunk,
  getMyAccountThunk,
  getRolesThunk,
  getSearchEnginesThunk,
  getTariffPlansThunk,
  getTimezonesThunk,
  getUsersThunk,
  getUserTeamFullInfoThunk,
  globalSearchThunk,
  loginThunk,
  necessaryRemovalThunk,
  passwordConfirmationThunk,
  removeAccessAccountThunk,
  removeAccessesAccountsThunk,
  requestNewKeyThunk,
  resendingForgotPasswordLetterThunk,
  resendingMailConfirmationLetterThunk,
  resetPasswordThunk,
  sendInvitationThunk,
  setAsDefaultAccountThunk,
  signUpThunk,
  usersBulkDeleteTeamThunk,
  userSearchesThunk,
} from '../redux/thunk';
import {
  CancelAccountDeletionParams,
  ChangeAccountBrandingParams,
  ChangeAccountSettingsThunkParams,
  ConfirmEmailParams,
  DeleteAccountParams,
  DeleteOneUserTeamParams,
  EditUserTeamParams,
  ForgetParams,
  GetUsersParams,
  LoginParams,
  PasswordConfirmationParams,
  ResendingForgotPasswordLetterThunkParams,
  ResetParams,
  sendInvitationParams,
  SetSearchKeywordUsersTeamParams,
  UsersBulkDeleteParams,
  SetAsDefaultAccountParams,
  UserSignUpData,
  SelectAccountParams,
  RemoveAccessAccountParams,
  RemoveAccessesAccountsParams,
  GlobalSearchParams,
  ChangeEmailParams,
  GetTInvoicesParams,
  GetInvoicePDFParams,
  GetBillingParams,
  UserSearchesParams,
  ClearRecentlyViewedParams,
  DeleteInvitationsTeamParams,
  EmailChangeConfirmationParams,
  GetAccountLimitParams,
  NecessaryRemovalParams,
  NecessaryRemovalResponse,
  IBillingCard,
  User,
  IGetAddressGeolocationActionParams,
  GetBillingResponse,
  GetUserTeamFullInfoParams,
  IFolderTree,
} from '../types';
import {
  clearStorage,
  STORAGE_KEYS,
  setSessionStorage,
} from '../../../utils/localStorage';
import {
  visitPreferredTariffPlan,
  removeToken,
  selectCurrentAccount,
} from '../redux/slice';
import {
  checkCancelSubscriptionAction,
  resetPayment,
  seOpenSiteBar,
  setAccountBillingCard,
  setActionRequiredBeforeDowngrading,
  setDeleteElementsTeamsTable,
  setFolderTreeAction,
  setInvoicesPageSize,
  setInvoicesPaginationPage,
  setOpenTariffPlanBanner,
  setSearchKeywordUsersTeam,
  setSortModelInvoices,
  setSortModelTeam,
  updatedUserAction,
} from '../redux/actions';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';

export default function useAuth() {
  const dispatch = useAppDispatch();

  const onSignUp = useCallback(
    (param: UserSignUpData) => {
      dispatch(signUpThunk(param));
    },
    [dispatch]
  );

  const onLogin = useCallback(
    (params: LoginParams) => {
      dispatch(loginThunk(params));
    },
    [dispatch]
  );

  const onLogout = useCallback(() => {
    clearStorage(STORAGE_KEYS.access);
    clearStorage(STORAGE_KEYS.refresh);
    clearStorage(STORAGE_KEYS.currentAccount);
    dispatch(removeToken());
  }, [dispatch]);

  const onForgotPassword = useCallback(
    (data: ForgetParams) => {
      dispatch(forgotPasswordThunk(data));
    },
    [dispatch]
  );

  const onResetPassword = useCallback(
    (data: ResetParams) => {
      dispatch(resetPasswordThunk(data));
    },
    [dispatch]
  );

  const checkAuth = useCallback(
    (token?: string | null) => {
      dispatch(checkAuthThunk(token));
    },
    [dispatch]
  );

  const onConfirmEmail = useCallback(
    (emailConfirmationToken: ConfirmEmailParams) => {
      dispatch(confirmEmailThunk(emailConfirmationToken));
    },
    [dispatch]
  );

  const onResendingForgotPasswordLetterThunkEmail = useCallback(
    (data: ResendingForgotPasswordLetterThunkParams) => {
      dispatch(resendingForgotPasswordLetterThunk(data));
    },
    [dispatch]
  );

  const onResendingMailConfirmationLetterThunkEmail = useCallback(
    (data: ResendingForgotPasswordLetterThunkParams) => {
      dispatch(resendingMailConfirmationLetterThunk(data));
    },
    [dispatch]
  );

  const onGetMyAccount = useCallback(
    (id: number) => {
      dispatch(getMyAccountThunk(id));
    },
    [dispatch]
  );

  const onGetCountries = useCallback(() => {
    dispatch(getCountriesThunk());
  }, [dispatch]);

  const onGetTimezones = useCallback(() => {
    dispatch(getTimezonesThunk());
  }, [dispatch]);

  const onDeleteAccount = useCallback(
    (params: DeleteAccountParams) => {
      dispatch(deleteAccountThunk(params));
    },
    [dispatch]
  );

  const onChangeAccountSettings = useCallback(
    (data: ChangeAccountSettingsThunkParams) => {
      dispatch(changeAccountSettingsThunk(data));
    },
    [dispatch]
  );

  const onPasswordConfirmation = useCallback(
    (params: PasswordConfirmationParams) => {
      dispatch(passwordConfirmationThunk(params));
    },
    [dispatch]
  );

  const onChangeAccountBranding = useCallback(
    (params: ChangeAccountBrandingParams) => {
      dispatch(changeAccountBrandingThunk(params));
    },
    [dispatch]
  );

  const onCancelAccountDeletionBranding = useCallback(
    (params: CancelAccountDeletionParams) => {
      dispatch(cancelAccountDeletionThunk(params));
    },
    [dispatch]
  );

  const onGetUsers = useCallback(
    (params: GetUsersParams) => {
      dispatch(getUsersThunk(params));
    },
    [dispatch]
  );

  const onGetRoles = useCallback(() => {
    dispatch(getRolesThunk());
  }, [dispatch]);

  const onSendInvitation = useCallback(
    (params: sendInvitationParams) => {
      dispatch(sendInvitationThunk(params));
    },
    [dispatch]
  );

  const onDeleteInvitationsTeam = useCallback(
    (params: DeleteInvitationsTeamParams) => {
      dispatch(deleteInvitationsTeamThunk(params));
    },
    [dispatch]
  );

  const onEditUserTeam = useCallback(
    (params: EditUserTeamParams) => {
      dispatch(editUserTeamThunk(params));
    },
    [dispatch]
  );

  const onSetSearchKeywordUsersTeam = useCallback(
    (params: SetSearchKeywordUsersTeamParams) => {
      dispatch(setSearchKeywordUsersTeam(params.text));
    },
    [dispatch]
  );

  const onDeleteOneUserTeam = useCallback(
    (params: DeleteOneUserTeamParams) => {
      dispatch(deleteOneUserTeamThunk(params));
    },
    [dispatch]
  );

  const onUsersBulkDeleteTeam = useCallback(
    (params: UsersBulkDeleteParams) => {
      dispatch(usersBulkDeleteTeamThunk(params));
    },
    [dispatch]
  );

  const onSetSortModelTeam = useCallback(
    (sortModel: GridSortModel) => {
      dispatch(setSortModelTeam(sortModel));
    },
    [dispatch]
  );

  const onSetAsDefaultAccount = useCallback(
    (params: SetAsDefaultAccountParams) => {
      dispatch(setAsDefaultAccountThunk(params));
    },
    [dispatch]
  );

  const onSelectAccount = useCallback(
    (params: SelectAccountParams) => {
      setSessionStorage(
        STORAGE_KEYS.currentAccount,
        JSON.stringify(params.account)
      );
      dispatch(selectCurrentAccount(params));
      params.successFn();
    },
    [dispatch]
  );

  const onRemoveAccessAccount = useCallback(
    (params: RemoveAccessAccountParams) => {
      dispatch(removeAccessAccountThunk(params));
    },
    [dispatch]
  );

  const onRemoveAccessesAccounts = useCallback(
    (params: RemoveAccessesAccountsParams) => {
      dispatch(removeAccessesAccountsThunk(params));
    },
    [dispatch]
  );

  const onGlobalSearch = useCallback(
    (params: GlobalSearchParams) => {
      dispatch(globalSearchThunk(params));
    },
    [dispatch]
  );

  const onRequestNewKey = useCallback(
    (params: { id: number; successFn: () => void }) => {
      dispatch(requestNewKeyThunk(params));
    },
    [dispatch]
  );

  const onGetTariffPlans = useCallback(
    (params: { type: string }) => {
      dispatch(getTariffPlansThunk(params));
    },
    [dispatch]
  );

  const onChangeEmail = useCallback(
    (params: ChangeEmailParams) => {
      dispatch(changeEmailThunk(params));
    },
    [dispatch]
  );

  const onGetInvoices = useCallback(
    (params: GetTInvoicesParams) => {
      dispatch(getInvoicesThunk(params));
    },
    [dispatch]
  );

  const onGetInvoicePDF = useCallback(
    (params: GetInvoicePDFParams) => {
      dispatch(getInvoicePDFThunk(params));
    },
    [dispatch]
  );

  const onSetInvoicesPageSize = useCallback(
    (pageSize: number) => {
      dispatch(setInvoicesPageSize(pageSize));
    },
    [dispatch]
  );

  const onSetInvoicesPaginationPage = useCallback(
    (page: number) => {
      dispatch(setInvoicesPaginationPage(page));
    },
    [dispatch]
  );

  const onSetSortModelInvoices = useCallback(
    (sortModel: GridSortModel) => {
      dispatch(setSortModelInvoices(sortModel));
    },
    [dispatch]
  );

  const onGetBilling = useCallback(
    (params: GetBillingParams) => {
      dispatch(getBillingThunk(params));
    },
    [dispatch]
  );

  const onVisitPreferredTariffPlan = useCallback(() => {
    dispatch(visitPreferredTariffPlan());
  }, [dispatch]);

  const onUserSearches = useCallback(
    (params: UserSearchesParams) => {
      dispatch(userSearchesThunk(params));
    },
    [dispatch]
  );

  const onSeOpenSiteBar = useCallback(
    (isOpen: boolean) => {
      dispatch(seOpenSiteBar(isOpen));
    },
    [dispatch]
  );

  const onClearRecentlyViewed = useCallback(
    (params: ClearRecentlyViewedParams) => {
      dispatch(clearRecentlyViewedThunk(params));
    },
    [dispatch]
  );

  const onSetDeleteElementsTeamsTable = useCallback(
    (deleteElements: GridRowSelectionModel) => {
      dispatch(setDeleteElementsTeamsTable(deleteElements));
    },
    [dispatch]
  );

  const onEmailChangeConfirmation = useCallback(
    (params: EmailChangeConfirmationParams) => {
      dispatch(emailChangeConfirmationThunk(params));
    },
    [dispatch]
  );

  const onGetAccountLimit = useCallback(
    (params: GetAccountLimitParams) => {
      dispatch(getAccountLimitThunk(params));
    },
    [dispatch]
  );

  const onSetOpenTariffPlanBanner = useCallback(
    (open: boolean) => {
      dispatch(setOpenTariffPlanBanner(open));
    },
    [dispatch]
  );

  const onResetPayment = useCallback(() => {
    dispatch(resetPayment());
  }, [dispatch]);

  const onNecessaryRemoval = useCallback(
    (params: NecessaryRemovalParams) => {
      dispatch(necessaryRemovalThunk(params));
    },
    [dispatch]
  );

  const onSetActionRequiredBeforeDowngrading = useCallback(
    (params: NecessaryRemovalResponse) => {
      dispatch(setActionRequiredBeforeDowngrading(params));
    },
    [dispatch]
  );

  const onSetAccountBillingCard = useCallback(
    (params: IBillingCard | null) => {
      dispatch(setAccountBillingCard(params));
    },
    [dispatch]
  );

  const onGetCheckoutSettings = useCallback(() => {
    dispatch(getCheckoutSettingsThunk());
  }, [dispatch]);

  const onUpdatedUser = useCallback(
    (user: User) => {
      dispatch(updatedUserAction(user));
    },
    [dispatch]
  );

  const onGetAddressGeolocation = useCallback(
    (params: IGetAddressGeolocationActionParams) => {
      dispatch(getAddressGeolocationThunk(params));
    },
    [dispatch]
  );

  const onCheckCancelSubscriptionAction = useCallback(
    (billing: GetBillingResponse) => {
      dispatch(checkCancelSubscriptionAction(billing));
    },
    [dispatch]
  );

  const onGetSearchEngines = useCallback(() => {
    dispatch(getSearchEnginesThunk());
  }, [dispatch]);

  const onGetUserTeamFullInfo = useCallback(
    (params: GetUserTeamFullInfoParams) => {
      dispatch(getUserTeamFullInfoThunk(params));
    },
    [dispatch]
  );

  const onGetInvitationTeamFullInfo = useCallback(
    (params: GetUserTeamFullInfoParams) => {
      dispatch(getInvitationTeamFullInfoThunk(params));
    },
    [dispatch]
  );

  const onSetFolderTree = useCallback(
    (params: IFolderTree) => {
      dispatch(setFolderTreeAction(params));
    },
    [dispatch]
  );

  return {
    onSignUp,
    onLogin,
    onForgotPassword,
    onResetPassword,
    checkAuth,
    onConfirmEmail,
    onLogout,
    onResendingForgotPasswordLetterThunkEmail,
    onResendingMailConfirmationLetterThunkEmail,
    onGetMyAccount,
    onGetCountries,
    onGetTimezones,
    onDeleteAccount,
    onChangeAccountSettings,
    onPasswordConfirmation,
    onChangeAccountBranding,
    onCancelAccountDeletionBranding,
    onGetUsers,
    onGetRoles,
    onSendInvitation,
    onEditUserTeam,
    onSetSearchKeywordUsersTeam,
    onDeleteOneUserTeam,
    onUsersBulkDeleteTeam,
    onSetSortModelTeam,
    onSetAsDefaultAccount,
    onSelectAccount,
    onRemoveAccessAccount,
    onRemoveAccessesAccounts,
    onGlobalSearch,
    onRequestNewKey,
    onGetTariffPlans,
    onChangeEmail,
    onGetInvoices,
    onGetInvoicePDF,
    onSetInvoicesPageSize,
    onSetInvoicesPaginationPage,
    onSetSortModelInvoices,
    onGetBilling,
    onVisitPreferredTariffPlan,
    onUserSearches,
    onSeOpenSiteBar,
    onClearRecentlyViewed,
    onSetDeleteElementsTeamsTable,
    onDeleteInvitationsTeam,
    onEmailChangeConfirmation,
    onGetAccountLimit,
    onSetOpenTariffPlanBanner,
    onResetPayment,
    onNecessaryRemoval,
    onSetActionRequiredBeforeDowngrading,
    onSetAccountBillingCard,
    onGetCheckoutSettings,
    onUpdatedUser,
    onGetAddressGeolocation,
    onCheckCancelSubscriptionAction,
    onGetSearchEngines,
    onGetUserTeamFullInfo,
    onGetInvitationTeamFullInfo,
    onSetFolderTree,
  };
}
