import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import ROUTES from '../routes/constants';
import NotFoundBlock from '../shared/components/NotFoundBlock/NotFoundBlock';

const NotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== ROUTES.notFound) {
      navigate(ROUTES.notFound);
    }
  }, [location.pathname, navigate]);

  return <NotFoundBlock />;
};

export default NotFound;
