import { styled } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';

const StyledNoNotes = styled(Grid)`
  width: 100%;
  height: 333px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledHead = styled(Box)`
  color: #020202;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.5px;
`;

const StyledLabel = styled(Box)`
  color: #808191;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-bottom: 13px;
`;

export { StyledNoNotes, StyledHead, StyledLabel };
