import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { Header, SharedLinksTable } from './components';
import { DEFAULT_GET_USERS_PARAMS } from '../../../auth/components/Settings/components/Team/constants';
import { useAuth, useSharedLinks } from '../../../../hooks';
import { useAppSelector } from '../../../../store';

const SharedLinksPage = () => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const { onGetSharedLinks, onSetRowSelectionModelSharedLinks } =
    useSharedLinks();

  const sortModel = useAppSelector(
    state => state.sharedLinks.tables.sharedLinksPage.sortModel
  );
  const search = useAppSelector(
    state => state.sharedLinks.tables.sharedLinksPage.search
  );
  const paginationModel = useAppSelector(
    state => state.sharedLinks.tables.sharedLinksPage.paginationModel
  );

  useEffect(() => {
    if (currentAccount?.accountId) {
      onGetSharedLinks({
        id: currentAccount.accountId,
        page: paginationModel.page,
        limit: paginationModel.pageSize,
        search: search || DEFAULT_GET_USERS_PARAMS.search,
        sortBy: sortModel[0]?.field,
        sortOrder: sortModel[0]?.sort,
      });
    }
  }, [
    currentAccount.accountId,
    onGetSharedLinks,
    paginationModel.page,
    paginationModel.pageSize,
    search,
    sortModel,
  ]);

  useEffect(() => {
    return () => onSetRowSelectionModelSharedLinks([]);
  }, [onSetRowSelectionModelSharedLinks]);

  return (
    <Grid xs={12}>
      <Header />
      <SharedLinksTable />
    </Grid>
  );
};

export default SharedLinksPage;
