import React from 'react';
import { StyledTablePagination } from '../../../../../../../../auth/components/Settings/components/Team/components/customTableStyles';
import useSharedKeywordRankHistory from '../../../../../../../hooks/useKeywordRankHistory';

const Pagination = () => {
  const {
    onSetKeywordRankHistoryPaginationPage,
    onSetKeywordRankHistoryPaginationPageSize,
    keywordPositionsInfo,
    tableState: { paginationModel },
  } = useSharedKeywordRankHistory();

  const handleSetPaginationPageSize = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onSetKeywordRankHistoryPaginationPageSize(Number(e.target.value));
  };

  const handleSetPaginationPage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    onSetKeywordRankHistoryPaginationPage(Number(page));
  };

  return (
    <StyledTablePagination
      labelRowsPerPage='Show rows:'
      count={keywordPositionsInfo?.meta?.totalItems || 0}
      page={paginationModel.page}
      rowsPerPageOptions={[10, 25, 50, 100]}
      onPageChange={handleSetPaginationPage}
      rowsPerPage={paginationModel.pageSize}
      onRowsPerPageChange={handleSetPaginationPageSize}
    />
  );
};

export default Pagination;
