import React, { useEffect, useMemo, useState } from 'react';
import {
  useSharedLinks,
  useSharedKeywordRankingsTable,
} from '../../../../../../../../../../hooks';
import { Box, Grid } from '@mui/material';
import { CustomDataGrid } from './styles';
import CheckboxWrapper from '../../../../../../../../../auth/components/Settings/components/Team/components/CheckboxWrapper';
import CustomNoRowsOverlay from './CustomNoRowsOverlay';
import { GridRowParams } from '@mui/x-data-grid';
import { KeywordRanking } from '../../../../../../../../../projects/types';
import { getSharedLinkToken } from '../../../../../../../../../../utils/localStorage';
import { TableNoData } from '../../../../../../../../../../shared/components';
import TableSkeleton from '../../../../../../../../../../shared/graphSkeletons/TableSkeleton';
import KeywordsAnalyticsSharedModal from '../../../../../../../modals/KeywordsAnalyticsSharedModal/KeywordsAnalyticsSharedModal';
import { useAppSelector } from '../../../../../../../../../../store';
import {
  setTableColumns,
  TABLES_STORAGE_KEYS,
} from '../../../../../../../../../projects/tablesColumnStase';

const Table = () => {
  const {
    onCloseKeywordPositionHistory,
    onGetKeywordPositionHistory,
    onSetSortModelKeywordRankings,
    onSetRowSelectionKeywordsModel,
  } = useSharedLinks();

  const projectShared = useAppSelector(
    state => state.sharedLinks.projectShared
  );
  const activePeriodKeywordTrends = useAppSelector(
    state =>
      state.sharedLinks.projectGraphs.keywordRankings.activePeriodKeywordTrends
  );
  const keywordRanking = useAppSelector(
    state => state.sharedLinks.projectGraphs.keywordRankings.keywordRanking
  );
  const rowSelectionModel = useAppSelector(
    state =>
      state.sharedLinks.projectGraphs.keywordRankings.table.rowSelectionModel
  );
  const columnVisibilityModel = useAppSelector(
    state =>
      state.sharedLinks.projectGraphs.keywordRankings.table
        .columnVisibilityModel
  );
  const search = useAppSelector(
    state => state.sharedLinks.projectGraphs.keywordRankings.table.search
  );
  const pageSize = useAppSelector(
    state =>
      state.sharedLinks.projectGraphs.keywordRankings.table.paginationModel
        .pageSize
  );
  const openTariffPlanBanner = useAppSelector(
    state => state.auth.openTariffPlanBanner
  );

  const { columns, columnsSkeleton } = useSharedKeywordRankingsTable();

  const [itemCount, setItemCount] = useState(
    keywordRanking?.meta?.itemCount || pageSize
  );

  const [openModal, setOpenModal] = useState(false);
  const [activeKeywordId, setActiveKeywordId] = useState<number | null>(null);
  const [activeKeywordName, setActiveKeywordName] = useState<string | null>(
    null
  );

  const arrayOfObjects = useMemo(
    () => Array.from({ length: itemCount }, (_, index) => ({ id: index + 1 })),
    [itemCount]
  );

  const rows: KeywordRanking[] = useMemo(
    () => (keywordRanking?.items?.length ? keywordRanking.items : []),
    [keywordRanking.items]
  );

  const renderRows = useMemo(() => {
    return !keywordRanking?.items ? arrayOfObjects : rows;
  }, [arrayOfObjects, keywordRanking?.items, rows]);

  const renderColumns = useMemo(() => {
    return !keywordRanking?.items ? columnsSkeleton : columns;
  }, [columns, columnsSkeleton, keywordRanking?.items]);

  const onClose = () => {
    if (activeKeywordId) {
      onCloseKeywordPositionHistory({ id: activeKeywordId });
      setOpenModal(false);
      setActiveKeywordId(null);
      setActiveKeywordName(null);
    }
  };

  const handleRowClick = (params: GridRowParams<any>) => {
    if (keywordRanking?.items) {
      const sharedLinkToken = getSharedLinkToken();
      if (sharedLinkToken && params.row.id && projectShared) {
        setOpenModal(true);
        setActiveKeywordId(params.row.id);
        setActiveKeywordName(params.row.name);
        onGetKeywordPositionHistory({
          keywordId: Number(params.row.id),
          link: sharedLinkToken,
          period: activePeriodKeywordTrends.value,
          competitorIds: projectShared.competitors.map(item => item.id),
        });
      }
    }
  };

  useEffect(() => {
    if (keywordRanking?.meta?.itemCount) {
      setItemCount(keywordRanking.meta.itemCount);
    }
  }, [keywordRanking?.meta?.itemCount]);

  const filterColumnVisibilityModel = useMemo(() => {
    if (
      projectShared?.searchEngine?.name !== 'Google' &&
      projectShared?.searchEngine?.name !== 'Bing'
    ) {
      return { ...columnVisibilityModel, cpc: false, searchValue: false };
    }
    return columnVisibilityModel;
  }, [projectShared?.searchEngine?.name, columnVisibilityModel]);

  useEffect(() => {
    setTableColumns(
      TABLES_STORAGE_KEYS.sharedKeywordRankings,
      columnVisibilityModel
    );
  }, [columnVisibilityModel]);

  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          initialState={{
            pinnedColumns: {
              right: ['actions'],
            },
          }}
          onRowClick={handleRowClick}
          columnHeaderHeight={40}
          rowHeight={40}
          rows={renderRows}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={onSetRowSelectionKeywordsModel}
          columns={renderColumns}
          onSortModelChange={onSetSortModelKeywordRankings}
          columnVisibilityModel={filterColumnVisibilityModel}
          disableVirtualization
          components={{
            BaseCheckbox: CheckboxWrapper,
            NoRowsOverlay: !keywordRanking?.items
              ? TableSkeleton
              : search && !keywordRanking?.items?.length
              ? CustomNoRowsOverlay
              : !keywordRanking?.items?.length
              ? TableNoData
              : Box,
          }}
          getRowId={row => row.id}
          keepNonExistentRowsSelected
          hideFooterPagination
          disableRowSelectionOnClick
          disableColumnMenu
          columnBuffer={100}
          columnThreshold={100}
          $isSharedTable={true}
          $isSharedKeywordRankingsTable={true}
          $openTariffPlanBanner={openTariffPlanBanner}
        />
      </Grid>
      {activeKeywordId && activeKeywordName && (
        <KeywordsAnalyticsSharedModal
          id={activeKeywordId}
          name={activeKeywordName}
          onClose={onClose}
          open={openModal}
        />
      )}
    </>
  );
};

export default Table;
