import React from 'react';
import { ProjectActionProps } from './types';

import TagIcon from '../../../../../../../../../shared/images/TagIcon';
import { EditProjectTagsModal } from '../../../../ProjectRedactorModal';

import {
  ListItemTypography,
  StyledListItem,
  StyledListItemButton,
} from '../styles';
import useProjects from '../../../../../../../hooks/useProjects';
import { useAppSelector } from '../../../../../../../../../store';
import { useFolders } from '../../../../../../../../../hooks';

const EditProjectTagsAction: React.FC<ProjectActionProps> = ({
  projectId,
  popupClose,
}) => {
  const [isOpenEditProjectModal, setIsOpenEditProjectModal] =
    React.useState(false);

  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const { onGetCurrentProject, onSetRowSelectionModel } = useProjects();
  const { onSetRowSelectionModel: onSetRowSelectionModelFolders } =
    useFolders();

  const handleOpenEditProjectModal = () => {
    onGetCurrentProject({
      accountId: currentAccount.accountId,
      projectId: projectId,
      tags: true,
      notes: true,
      successFn: () => {
        setIsOpenEditProjectModal(true);
      },
    });
  };

  const handleCloseEditProjectModal = () => {
    onSetRowSelectionModel([]);
    onSetRowSelectionModelFolders([]);
    popupClose();
    setIsOpenEditProjectModal(false);
  };

  return (
    <>
      <StyledListItem onClick={handleOpenEditProjectModal}>
        <StyledListItemButton>
          <TagIcon />
          <ListItemTypography marginLeft='8px'>Edit Tags</ListItemTypography>
        </StyledListItemButton>
      </StyledListItem>
      {isOpenEditProjectModal ? (
        <EditProjectTagsModal
          open={isOpenEditProjectModal}
          handleClose={handleCloseEditProjectModal}
        />
      ) : null}
    </>
  );
};

export default EditProjectTagsAction;
