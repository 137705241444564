import React from 'react';
import { Grid } from '@mui/material';
import DownIcon from '../images/DownIcon';

const SimpleSelectIcon = () => (
  <Grid
    sx={{
      display: 'inherit',
      paddingRight: '12px',
      pointerEvents: 'none !important',
      position: 'absolute',
      right: '0',
    }}
  >
    <DownIcon stroke='#344054' />
  </Grid>
);

export default SimpleSelectIcon;
