import React, { useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  Fade,
  FormHelperText,
  Grid,
  InputLabel,
  Modal,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import useAlerts from '../../../../../../hooks/useAlerts';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import {
  IAlertsTrigger,
  IKeywordByProject,
} from '../../../../../../types/types';
import * as Yup from 'yup';
import {
  ModalContainer,
  ModalWrapper,
} from '../../../../../../../projects/components/Projects/components/ProjectRedactorModal/styles';
import { CloseDeletePopoverOne } from '../../../../../../../auth/components/Settings/components/Account/styles';
import CloseIcon from '@mui/icons-material/Close';
import {
  StylesAddAnotherRecipientButton,
  StylesLabelModal,
} from '../AddNewAlert/styles';
import SelectIcon from '../../../../../../../../shared/components/SelectIcon';
import { CustomFormHelperText } from '../../../../../../../../shared/components';
import { StyledAutocompleteLi } from '../../../../../../../auth/components/Settings/components/Team/components/styles';
import ProjectsIcon from '../../../../../../../../shared/images/ProjectsIcon';
import CheckboxWrapper from '../../../../../../../auth/components/Settings/components/Team/components/CheckboxWrapper';
import { getDeviceTypeIcon } from '../../../../../../../projects/utils';
import { CustomCheckbox } from '../../../../../../../../shared/checkbox/styles';
import CustomCheckboxImage from '../../../../../../../../shared/images/CustomCheckbox';
import CheckedIcon from '../../../../../../../../shared/images/CheckedIcon';
import PlusIcon from '../../../../../../../../shared/images/PlusIcon';
import { ButtonsWrapper } from '../../../../../../../auth/components/Settings/components/Team/components/customTableStyles';
import {
  GrayButton36Small,
  PrimaryButton36Small,
} from '../../../../../../../../shared/buttons/styles';
import AutocompletePaper from '../../../../../../../../shared/AutocompletePaper';
import { useAppSelector } from '../../../../../../../../store';
import {
  ModalMultipleAutocompleteStylesProps,
  ModalAutocompleteStylesProps,
  ModalOutlinedInput,
} from '../../../../../../../../shared/modal/styled';
import { validateThreshold } from '../../../../../../../../utils/validation';

interface EditTriggerProps {
  open: boolean;
  handleClose: () => void;
  id: number;
  type: 'by Project' | 'by Keywords';
}

/**
 * EditTrigger component is used to edit an existing trigger alert. It allows selecting a project, keywords, setting a trigger rule, and adding email recipients for the alert.
 */
const EditTrigger: React.FC<EditTriggerProps> = ({
  handleClose,
  open,
  type,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const projectsListForInvite = useAppSelector(
    state => state.projects.projectsListForInvite
  );

  const {
    onGetKeywordsByProject,
    onGetAlertsTriggersTypes,
    onGetTriggerKeywords,
    onUpdateProjectTrigger,
    onUpdateKeywordTrigger,
    alertsTriggers,
    triggerInfo,
    onGetTriggersByProjects,
    onGetTriggersByKeywords,
    tables: { triggerKeywords, triggers },
  } = useAlerts();
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const initialValues = {
    triggerRule: undefined as IAlertsTrigger | undefined,
    threshold: '',
    project: undefined as { id: number; projectName: string } | undefined,
    keywordIds: [] as IKeywordByProject[],
    emails: [
      {
        email: '',
        subscribed: true,
      },
    ] as {
      email: string;
      subscribed: true;
    }[],
    message: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      project: Yup.object().required('Project is required.'),
      triggerRule: Yup.object().required('Trigger is required.'),
      threshold: Yup.string().required('Threshold is required.'),
      emails: Yup.array()
        .of(
          Yup.object().shape({
            email: Yup.string()
              .email('Invalid email address.')
              .test(
                'email-validation',
                'Email Address is required.',
                function (value) {
                  if (value && value.trim() !== '') {
                    return Yup.string().email().isValidSync(value);
                  }
                  return true;
                }
              ),
          })
        )
        .test(
          'at-least-one-email',
          'At least one email address is required.',
          function (emails) {
            if (!emails) return false;
            return emails.some(
              email => email.email && email.email.trim() !== ''
            );
          }
        ),
    }),
    onSubmit: (values, { setStatus, setSubmitting, setErrors, resetForm }) => {
      const thresholdError = validateThreshold(values.threshold);
      if (thresholdError) {
        setStatus({ success: false });
        setSubmitting(false);
        setErrors({ threshold: thresholdError });
        return;
      }
      if (
        currentAccount?.accountId &&
        triggerInfo &&
        values?.project &&
        values?.triggerRule?.name
      ) {
        const emails = values.emails.filter(item => item?.email);
        if (type === 'by Project') {
          onUpdateProjectTrigger({
            id: currentAccount.accountId,
            triggerId: triggerInfo.id,
            data: {
              triggerRule: values.triggerRule.name,
              emails: emails.length ? emails : undefined,
              threshold: Number(values.threshold),
            },
            successFn: () => {
              handleCloseModal();
              onGetTriggersByProjects({
                id: currentAccount.accountId,
                page: triggers.paginationModel.page,
                limit: triggers.paginationModel.pageSize,
                sortBy: triggers.sortModel[0]?.field,
                sortOrder: triggers.sortModel[0]?.sort,
              });
            },
            errorFn: (error: any) => {
              setStatus({ success: false });
              setSubmitting(false);
              setErrors(error);
            },
          });
        }
        if (type === 'by Keywords') {
          onUpdateKeywordTrigger({
            id: currentAccount.accountId,
            triggerId: triggerInfo.id,
            data: {
              triggerRule: values.triggerRule.name,
              keywordIds: values.keywordIds.length
                ? values.keywordIds.map(item => item.id)
                : [],
              emails: emails.length ? emails : undefined,
              threshold: Number(values.threshold),
            },
            successFn: () => {
              resetForm();
              handleClose();
              onGetTriggersByKeywords({
                id: currentAccount.accountId,
                page: triggers.paginationModel.page,
                limit: triggers.paginationModel.pageSize,
                sortBy: triggers.sortModel[0]?.field,
                sortOrder: triggers.sortModel[0]?.sort,
              });
            },
            errorFn: (error: any) => {
              setStatus({ success: false });
              setSubmitting(false);
              setErrors(error);
            },
          });
        }
      }
    },
  });

  const [keywordsOptions, setKeywordsOptions] = useState<IKeywordByProject[]>(
    []
  );

  const [isLastScrollData, setIsLastScrollData] = useState(false);

  const [keywordsByProjectPage, setKeywordsByProjectPage] = useState(1);

  const validateForm = useCallback(
    (values: typeof initialValues) => {
      formik.validateForm(values);
    },
    [formik]
  );

  const handleScroll = (event: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const listboxNode = event.currentTarget;
    if (
      formik.values.project &&
      listboxNode.scrollTop + listboxNode.clientHeight - 0.5 ===
        listboxNode.scrollHeight
    ) {
      if (!isLastScrollData) {
        const nextPage = keywordsByProjectPage + 1;
        setKeywordsByProjectPage(nextPage);
        onGetKeywordsByProject({
          id: currentAccount.accountId,
          projectId: formik.values.project.id,
          page: nextPage,
          limit: 20,
          successFn: (keywords: IKeywordByProject[]) => {
            if (!keywords.length) {
              setIsLastScrollData(true);
              return;
            } else {
              setKeywordsOptions([...keywordsOptions, ...keywords]);
            }
          },
        });
      }
    }
  };

  const handleCloseModal = () => {
    setKeywordsOptions([]);
    setIsLastScrollData(false);
    setKeywordsByProjectPage(1);
    handleClose();
    formik.resetForm();
  };

  useEffect(() => {
    if (triggerInfo) {
      formik.setFieldValue('project', triggerInfo.project);
      formik.setFieldValue('triggerRule', triggerInfo.rule);
      formik.setFieldValue('threshold', triggerInfo.threshold);
      formik.setFieldValue('emails', triggerInfo.recipients);
    }
  }, [triggerInfo]);

  useEffect(() => {
    if (!alertsTriggers.length && open) {
      onGetAlertsTriggersTypes();
    }
  }, [alertsTriggers, onGetAlertsTriggersTypes, open]);

  useEffect(() => {
    if (currentAccount?.accountId && triggerInfo?.id) {
      onGetTriggerKeywords({
        id: currentAccount.accountId,
        triggerId: triggerInfo.id,
        page: 0,
        limit: 20,
        sortBy: triggerKeywords.sortModel[0]?.field,
        sortOrder: triggerKeywords.sortModel[0]?.sort,
        successFn: keywords => {
          formik.setFieldValue('keywordIds', keywords);
        },
      });
    }
  }, [
    currentAccount?.accountId,
    onGetTriggerKeywords,
    triggerKeywords.sortModel,
    triggerInfo?.id,
  ]);

  useEffect(() => {
    if (formik.values.project && currentAccount?.accountId) {
      onGetKeywordsByProject({
        id: currentAccount.accountId,
        projectId: formik.values.project.id,
        page: 1,
        limit: 20,
        successFn: (keywords: IKeywordByProject[]) => {
          setKeywordsOptions([...keywords]);
        },
      });
    }
  }, [currentAccount.accountId, formik.values.project, onGetKeywordsByProject]);

  useEffect(() => {
    if (open) {
      validateForm(formik.values);
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby='AddNoteModal'
      aria-describedby='EditNoteModal'
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <form onSubmit={formik.handleSubmit}>
          <FormikProvider value={formik}>
            <ModalWrapper>
              <ModalContainer container xs={12} sx={{ gap: '8px' }}>
                <Grid xs={12}>
                  <Grid item xs={12} sx={{ position: 'relative' }}>
                    <CloseDeletePopoverOne onClick={handleCloseModal}>
                      <CloseIcon fontSize='small' />
                    </CloseDeletePopoverOne>
                    <Typography
                      fontSize='18px'
                      fontWeight='600'
                      lineHeight='28px'
                      color='#101828'
                    >
                      {`Edit a Trigger for the ${
                        type === 'by Project' ? 'Project' : 'Keywords'
                      }`}
                    </Typography>
                  </Grid>
                  <StylesLabelModal sx={{ paddingBottom: '32px' }}>
                    Select the project and keywords for this alert.
                  </StylesLabelModal>
                  <Grid
                    container
                    xs={12}
                    spacing={2}
                    paddingBottom={'16px'}
                    sx={{ marginLeft: '0' }}
                  >
                    <Grid item xs={9} sx={{ paddingLeft: '0 !important' }}>
                      <Autocomplete
                        sx={ModalAutocompleteStylesProps}
                        fullWidth
                        size={'small'}
                        id='triggerRule'
                        limitTags={2}
                        options={alertsTriggers}
                        getOptionLabel={option => option.name}
                        value={formik.values.triggerRule}
                        onChange={(_, value) => {
                          formik.setFieldValue(
                            'triggerRule',
                            value || undefined
                          );
                        }}
                        onBlur={formik.handleBlur}
                        popupIcon={<SelectIcon />}
                        renderInput={params => (
                          <TextField
                            {...params}
                            placeholder='Changes by more than'
                            name='changesByMoreThan'
                            error={
                              !!(
                                formik.touched.triggerRule &&
                                formik.errors.triggerRule
                              )
                            }
                          />
                        )}
                        PaperComponent={paperProps => {
                          const { children, ...restPaperProps } = paperProps;
                          return <Paper {...restPaperProps}>{children}</Paper>;
                        }}
                      />
                      {formik.touched.triggerRule &&
                        formik.errors?.triggerRule && (
                          <FormHelperText error id='triggerRule'>
                            <CustomFormHelperText
                              error={formik.errors.triggerRule.toString()}
                            />
                          </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={3} sx={{ paddingLeft: '8px !important' }}>
                      <ModalOutlinedInput
                        fullWidth
                        id='threshold'
                        size='small'
                        type='text'
                        name={`threshold`}
                        value={formik.values.threshold}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.touched.threshold && formik.errors?.threshold
                          )
                        }
                      />
                      {formik.touched.threshold && formik.errors?.threshold && (
                        <FormHelperText error id='threshold'>
                          <CustomFormHelperText
                            error={formik.errors?.threshold}
                          />
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid paddingBottom={'16px'}>
                    <Autocomplete
                      sx={ModalAutocompleteStylesProps}
                      disabled
                      fullWidth
                      size={'small'}
                      id='project'
                      limitTags={2}
                      options={projectsListForInvite.items}
                      getOptionLabel={option => option.projectName}
                      renderOption={(props, option) => {
                        return (
                          <StyledAutocompleteLi {...props} key={option.id}>
                            <ProjectsIcon />
                            <Grid marginLeft={'8px'}>{option.projectName}</Grid>
                          </StyledAutocompleteLi>
                        );
                      }}
                      value={formik.values.project}
                      onChange={(_, value) => {
                        formik.setFieldValue('project', value || undefined);
                      }}
                      onBlur={formik.handleBlur}
                      popupIcon={<SelectIcon />}
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder='Select project'
                          name='project'
                          error={
                            !!(formik.touched.project && formik.errors.project)
                          }
                        />
                      )}
                      PaperComponent={paperProps => {
                        const { children, ...restPaperProps } = paperProps;
                        return <Paper {...restPaperProps}>{children}</Paper>;
                      }}
                    />
                    {formik.touched.project && formik.errors?.project && (
                      <FormHelperText error id='project'>
                        <CustomFormHelperText
                          error={formik.errors.project.toString()}
                        />
                      </FormHelperText>
                    )}
                  </Grid>

                  {type !== 'by Project' ? (
                    <Grid paddingBottom={'16px'}>
                      <Autocomplete
                        sx={ModalMultipleAutocompleteStylesProps}
                        disabled={!formik.values.project}
                        multiple
                        disableCloseOnSelect
                        fullWidth
                        size={'small'}
                        id='keywordIds'
                        limitTags={2}
                        options={keywordsOptions}
                        getOptionLabel={option => option.name}
                        ListboxProps={{
                          onScroll: handleScroll,
                        }}
                        isOptionEqualToValue={option =>
                          formik.values.keywordIds.some(
                            selectedOption => selectedOption.id === option.id
                          )
                        }
                        renderOption={(props, option, { selected }) => {
                          return (
                            <StyledAutocompleteLi {...props} key={option.id}>
                              <CheckboxWrapper checked={selected} />
                              {getDeviceTypeIcon(option.deviceType.name)}
                              <Grid marginLeft={'8px'}>{option.name}</Grid>
                            </StyledAutocompleteLi>
                          );
                        }}
                        value={formik.values.keywordIds}
                        onChange={(_, value) => {
                          formik.setFieldValue(
                            'keywordIds',
                            value || undefined
                          );
                        }}
                        onBlur={formik.handleBlur}
                        popupIcon={<SelectIcon />}
                        renderInput={params => (
                          <TextField
                            {...params}
                            placeholder='Select keywords'
                            name='keywordIds'
                            error={
                              !!(
                                formik.touched.keywordIds &&
                                formik.errors.keywordIds
                              )
                            }
                          />
                        )}
                        PaperComponent={AutocompletePaper}
                      />
                      {formik.touched.keywordIds &&
                        formik.errors?.keywordIds && (
                          <FormHelperText error id='keywords'>
                            <CustomFormHelperText
                              error={formik.errors.keywordIds.toString()}
                            />
                          </FormHelperText>
                        )}
                    </Grid>
                  ) : null}

                  <FieldArray
                    name='emails'
                    render={arrayHelpers => {
                      return (
                        <Grid>
                          <Grid container gap={2}>
                            <Grid item xs={7}>
                              <InputLabel shrink>Email Address</InputLabel>
                            </Grid>
                            <Grid item xs={4}>
                              <InputLabel shrink>Alert via email</InputLabel>
                            </Grid>
                          </Grid>
                          {formik.values.emails.map((recipient, index) => {
                            const error =
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              formik.errors.emails?.[index]?.email as string;
                            return (
                              <Grid
                                key={index}
                                container
                                gap={2}
                                paddingBottom={'8px'}
                              >
                                <Grid item xs={7}>
                                  <ModalOutlinedInput
                                    fullWidth
                                    size='small'
                                    type='email'
                                    name={`emails[${index}].email`}
                                    placeholder={'email@example.com'}
                                    value={formik.values.emails[index].email}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={
                                      formik.touched.emails?.[index]?.email &&
                                      !!error
                                    }
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    helperText={
                                      formik.touched.emails?.[index]?.email &&
                                      error
                                    }
                                  />
                                  {formik.touched.emails?.[index]?.email &&
                                    error && (
                                      <Grid item xs={12}>
                                        <FormHelperText error>
                                          <CustomFormHelperText error={error} />
                                        </FormHelperText>
                                      </Grid>
                                    )}
                                </Grid>
                                <Grid item xs={4}>
                                  <CustomCheckbox
                                    icon={<CustomCheckboxImage />}
                                    checkedIcon={<CheckedIcon />}
                                    checked={
                                      formik.values.emails[index].subscribed
                                    }
                                    onChange={event =>
                                      formik.setFieldValue(
                                        `emails[${index}].subscribed`,
                                        event.target.checked
                                      )
                                    }
                                    sx={{ padding: '6px 8px' }}
                                  />
                                </Grid>
                              </Grid>
                            );
                          })}
                          {formik.touched.emails &&
                            formik.errors?.emails &&
                            typeof formik.errors?.emails === 'string' && (
                              <Grid paddingBottom={'16px'}>
                                <FormHelperText error id='triggerRule'>
                                  <CustomFormHelperText
                                    error={formik.errors.emails.toString()}
                                  />
                                </FormHelperText>
                              </Grid>
                            )}

                          <StylesAddAnotherRecipientButton
                            onClick={() =>
                              arrayHelpers.push({ email: '', subscribed: true })
                            }
                          >
                            <PlusIcon stroke={'#1A73E9'} />
                            Add another recipient
                          </StylesAddAnotherRecipientButton>

                          <FormHelperText
                            error={!!formik.errors.message}
                            id='message'
                          >
                            {formik.errors.message && (
                              <CustomFormHelperText
                                error={formik.errors.message}
                              />
                            )}
                          </FormHelperText>
                        </Grid>
                      );
                    }}
                  />

                  <ButtonsWrapper container xs={12} sx={{ paddingTop: '32px' }}>
                    <Grid item xs={extraSmallScreen && 12}>
                      <GrayButton36Small
                        fullWidth={extraSmallScreen}
                        size='small'
                        outline
                        onClick={handleCloseModal}
                      >
                        Cancel
                      </GrayButton36Small>
                    </Grid>
                    <Grid item xs={extraSmallScreen && 12}>
                      <PrimaryButton36Small
                        fullWidth={extraSmallScreen}
                        variant='contained'
                        size='small'
                        type='submit'
                        disabled={formik.isSubmitting || !formik.isValid}
                        onClick={() => formik.handleSubmit()}
                      >
                        Save
                      </PrimaryButton36Small>
                    </Grid>
                  </ButtonsWrapper>
                </Grid>
              </ModalContainer>
            </ModalWrapper>
          </FormikProvider>
        </form>
      </Fade>
    </Modal>
  );
};

export default EditTrigger;
