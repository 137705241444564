import React, { useEffect, useMemo, useState } from 'react';
import { KeywordProps } from './types';
import RefreshIcon from '../../../../../../../../../../../../shared/images/RefreshIcon';
import { StyledRefreshKeywordAction } from './styles';
import { CustomTooltip } from '../../../../../../../../../../../../shared/tooltip/styles';
import TextTooltip from '../../../../../../../../../../../../shared/components/TextTooltip';
import ChildrenTooltip from '../../../../../../../../../../../../shared/components/ChildrenTooltip';
import ManualRefreshKeywordsModal from '../../../../../../../../../modals/ManualRefreshKeywordsModal/ManualRefreshKeywordsModal';
import { prepareAgainHoursTooltip } from '../../../../../../../../utils';
import useAccountLimits from '../../../../../../../../../../../auth/hooks/useAccountLimits';
import { useAppSelector } from '../../../../../../../../../../../../store';
import { useProjects } from '../../../../../../../../../../../../hooks';

const popperProps = {
  sx: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: '#fff',
      color: '#344054',
      boxShadow:
        '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
      border: '1px solid #EDEDED',
    },
    '.MuiTooltip-arrow::before': {
      backgroundColor: '#fff',
      border: '1px solid #EDEDED',
    },
  },
};

const RefreshKeywordAction: React.FC<KeywordProps> = ({ keyword }) => {
  const { onCheckSubscriptionExpiration } = useAccountLimits();
  const { onSetRefreshKeywordsModal } = useProjects();

  const openRefreshKeywords = useAppSelector(
    state => state.projects.modals.refreshKeywords
  );

  const [open, setOpen] = useState(false);
  const [remainingTime, setRemainingTime] = useState('');

  useEffect(() => {
    if (!keyword.updateAllowed && keyword.updated) {
      const hoursTooltip = prepareAgainHoursTooltip(keyword.updated);
      setRemainingTime(hoursTooltip);
    }
  }, [keyword]);

  const handleOpenModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    event.preventDefault();
    if (keyword.updateAllowed && !keyword.positionUpdate) {
      const isExpiration = onCheckSubscriptionExpiration();
      if (!isExpiration) {
        setOpen(true);
        onSetRefreshKeywordsModal(true);
      }
    }
  };
  const handleCloseModal = () => {
    setOpen(false);
    onSetRefreshKeywordsModal(false);
  };

  const CustomTooltipTitle = useMemo(() => {
    if (keyword.updateAllowed && !keyword.positionUpdate) {
      return <TextTooltip text={'Refresh Keyword'} />;
    }
    if (keyword.positionUpdate) {
      return <TextTooltip text={'Updating position...'} />;
    }
    if (!keyword.updateAllowed) {
      return (
        <ChildrenTooltip>
          <div>
            Manual update for this keyword already used today. Available again
            in <b>{remainingTime}.</b>
          </div>
        </ChildrenTooltip>
      );
    }
    return '';
  }, [keyword.positionUpdate, keyword.updateAllowed, remainingTime]);

  const strokeRefreshIcon = useMemo(() => {
    if (keyword.updateAllowed && !keyword.positionUpdate) {
      return '#667085';
    }
    if (keyword.positionUpdate) {
      return '#667085';
    }
    if (!keyword.updateAllowed) {
      return '#D0D5DD';
    }
    return '#667085';
  }, [keyword.positionUpdate, keyword.updateAllowed]);

  return (
    <>
      <CustomTooltip
        title={CustomTooltipTitle}
        placement='top-start'
        arrow
        PopperProps={popperProps}
      >
        <StyledRefreshKeywordAction
          onClick={handleOpenModal}
          className={keyword.positionUpdate ? 'positionUpdate' : ''}
        >
          <RefreshIcon stroke={strokeRefreshIcon} />
        </StyledRefreshKeywordAction>
      </CustomTooltip>
      {open && (
        <ManualRefreshKeywordsModal
          onClose={handleCloseModal}
          open={openRefreshKeywords}
          keywordIds={[keyword.id]}
        />
      )}
    </>
  );
};

export default RefreshKeywordAction;
