import {
  Competitors,
  IBusinessCompetitors,
  NewProjectValues,
} from '../../../../../../types';
import React, { useMemo, useState } from 'react';
import {
  Divider,
  FormControlLabel,
  Grid,
  InputBase,
  InputLabel,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { AddTagsPopover } from '../../styles';
import { SearchContainer } from '../../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import SearchIcon from '../../../../../../../../shared/images/SearchIcon';
import PlusIcon from '../../../../../../../../shared/images/PlusIcon';
import { SmallContainerCheckbox } from '../../../../../../../../shared/checkbox/styles';
import CustomCheckboxImage from '../../../../../../../../shared/images/CustomCheckbox';
import CheckedIcon from '../../../../../../../../shared/images/CheckedIcon';
import { ButtonsWrapper } from '../../../../../../../auth/components/Settings/components/Team/components/customTableStyles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../../../../shared/buttons/styles';
import { FormikProps } from 'formik';
import AddCompetitorsModal from './AddCompetitorsModal';
import { useAppSelector } from '../../../../../../../../store';

interface AddCompetitorsPopupProps {
  closePopover: any;
  formik: FormikProps<NewProjectValues>;
  isNew: boolean;
}

const AddCompetitorsPopup: React.FC<AddCompetitorsPopupProps> = ({
  closePopover,
  formik,
  isNew,
}) => {
  const currentProject = useAppSelector(state => state.projects.currentProject);

  const businessCompetitors = useMemo(
    () => formik.values.businessCompetitors,
    [formik.values.businessCompetitors]
  );

  const competitorIds = useMemo(
    () => formik.values.competitorIds,
    [formik.values.competitorIds]
  );

  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const [searchString, setSearchString] = useState('');

  const [createdCompetitors, setCreatedCompetitors] =
    useState<IBusinessCompetitors[]>(businessCompetitors);
  const [checkedCreatedCompetitors, setCheckedCreatedCompetitors] =
    useState<IBusinessCompetitors[]>(businessCompetitors);

  const [checkedCompetitors, setCheckedCompetitors] =
    useState<Competitors[]>(competitorIds);

  const [isAddCompetitorsModal, setIsAddCompetitorsModal] = useState(false);

  const searchEngine = useMemo(
    () => formik.values.searchEngine,
    [formik.values.searchEngine]
  );

  const handleSelectCreatedCompetitor = (
    item: IBusinessCompetitors,
    checked: boolean
  ) => {
    setCheckedCreatedCompetitors([...checkedCreatedCompetitors, item]);
    if (!checked) {
      setCheckedCreatedCompetitors(
        checkedCreatedCompetitors.filter(filterItem => filterItem !== item)
      );
    }
  };

  const handleConfirm = () => {
    formik.setFieldValue('businessCompetitors', checkedCreatedCompetitors);
    formik.setFieldValue('competitorIds', checkedCompetitors);
    closePopover();
  };

  const handleAddCompetitor = (competitor: IBusinessCompetitors) => {
    setCreatedCompetitors(prevState => prevState.concat(competitor));
    setCheckedCreatedCompetitors(prevState => prevState.concat(competitor));
  };

  const handleSelectCompetitors = (
    competitors: Competitors,
    checked: boolean
  ) => {
    setCheckedCompetitors([...checkedCompetitors, competitors]);
    if (!checked) {
      setCheckedCompetitors(
        checkedCompetitors.filter(item => item.id !== competitors.id)
      );
    }
  };

  return (
    <>
      <AddTagsPopover sx={{ width: 512 }}>
        <SearchContainer sx={{ padding: '0 16px', gap: '0' }}>
          <InputLabel htmlFor='searchCompetitor'>
            <SearchIcon />
          </InputLabel>
          <InputBase
            fullWidth
            id='searchCompetitor'
            placeholder='Enter competitor'
            value={searchString}
            onChange={e => setSearchString(e.target.value as string)}
          />
        </SearchContainer>
        <Divider
          orientation='horizontal'
          flexItem
          sx={{
            margin: '8px 16px',
            color: '#EDEDED',
          }}
        />

        <Grid
          sx={{
            display: 'flex',
            gap: '8px',
            padding: '8px 16px',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => setIsAddCompetitorsModal(true)}
        >
          <PlusIcon stroke={'#667085'} />
          <Typography
            component='span'
            sx={{ color: '#475467', fontSize: '14px', fontWeight: '600' }}
          >
            {`Create competitor ${searchString ? `"${searchString}"` : ''}`}
          </Typography>
        </Grid>

        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer',
          }}
        >
          {createdCompetitors.length ? (
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '8px',
                cursor: 'pointer',
              }}
            >
              {createdCompetitors.map(item => (
                <FormControlLabel
                  key={item.competitorBusinessName}
                  label={item.competitorBusinessName}
                  sx={{
                    marginLeft: '6px',
                    gap: '8px',
                    color: '#101828',
                    fontSize: '14px',
                    padding: '8px',
                    backgroundColor: checkedCreatedCompetitors.includes(item)
                      ? '#EEF3FF'
                      : 'initial',
                    borderRadius: '8px',
                    ':hover': {
                      backgroundColor: '#F6F6F6',
                      borderRadius: '8px',
                    },
                  }}
                  control={
                    <SmallContainerCheckbox
                      onChange={() =>
                        handleSelectCreatedCompetitor(
                          item,
                          !checkedCreatedCompetitors.includes(item)
                        )
                      }
                      checked={checkedCreatedCompetitors.includes(item)}
                      icon={<CustomCheckboxImage />}
                      checkedIcon={<CheckedIcon />}
                    />
                  }
                />
              ))}
            </Grid>
          ) : null}
          {currentProject?.competitors?.length && !isNew ? (
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '8px',
                cursor: 'pointer',
              }}
            >
              {currentProject.competitors.map(item => (
                <FormControlLabel
                  key={item.id}
                  label={item.businessName}
                  sx={{
                    marginLeft: '6px',
                    gap: '8px',
                    color: '#101828',
                    fontSize: '14px',
                    padding: '8px',
                    backgroundColor: checkedCompetitors.some(
                      x => x.id === item.id
                    )
                      ? '#EEF3FF'
                      : 'initial',
                    borderRadius: '8px',
                    ':hover': {
                      backgroundColor: '#F6F6F6',
                      borderRadius: '8px',
                    },
                  }}
                  control={
                    <SmallContainerCheckbox
                      onChange={() =>
                        handleSelectCompetitors(
                          item,
                          !checkedCompetitors.some(x => x.id === item.id)
                        )
                      }
                      checked={checkedCompetitors.some(x => x.id === item.id)}
                      icon={<CustomCheckboxImage />}
                      checkedIcon={<CheckedIcon />}
                    />
                  }
                />
              ))}
            </Grid>
          ) : null}
        </Grid>

        <ButtonsWrapper
          container
          xs={12}
          sx={{ padding: '8px 16px 0 !important' }}
        >
          <Grid item xs={extraSmallScreen && 12}>
            <PrimaryButtonSmall
              fullWidth={extraSmallScreen}
              variant='contained'
              size='small'
              onClick={handleConfirm}
            >
              Apply
            </PrimaryButtonSmall>
          </Grid>
          <Grid item xs={extraSmallScreen && 12}>
            <GrayButtonSmall
              fullWidth={extraSmallScreen}
              size='small'
              outline
              onClick={closePopover}
            >
              Cancel
            </GrayButtonSmall>
          </Grid>
        </ButtonsWrapper>
      </AddTagsPopover>
      {isAddCompetitorsModal && (
        <AddCompetitorsModal
          searchEngine={searchEngine}
          searchString={searchString}
          open={isAddCompetitorsModal}
          onClose={() => setIsAddCompetitorsModal(false)}
          onAdd={handleAddCompetitor}
        />
      )}
    </>
  );
};

export default AddCompetitorsPopup;
