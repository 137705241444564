import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const ChartContainer = styled(Grid)`
  width: 100%;
  height: 311px;
  border: 1px solid #ededed;
  border-radius: 8px;
  background: white;
`;

const StyledITooltipContainer = styled(Grid)`
  max-width: 1000px;
  min-width: 230px;
  padding: 16px;
  box-shadow: 0px 12px 16px -4px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #ededed;
`;

export { ChartContainer, StyledITooltipContainer };
