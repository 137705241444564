import React from 'react';
import {
  Fade,
  Grid,
  InputLabel,
  Modal,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { writeText } from 'clipboard-polyfill';
import {
  ModalContainer,
  ModalWrapper,
} from '../../../../projects/components/Projects/components/ProjectRedactorModal/styles';
import { CloseDeletePopoverOne } from '../../../../auth/components/Settings/components/Account/styles';
import { ButtonsWrapper } from '../../../../auth/components/Settings/components/Team/components/customTableStyles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../shared/buttons/styles';
import { YourSharedLinkIsReadyModalProps } from './types';
import CopyIcon from '../../../../../shared/images/CopyIcon';
import { ModalOutlinedInput } from '../../../../../shared/modal/styled';

const YourSharedLinkIsReadyModal: React.FC<YourSharedLinkIsReadyModalProps> = ({
  onClose,
  open,
  link,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const handleClose = () => {
    onClose();
  };

  const handleCopy = () => {
    writeText(link);
  };

  return (
    <Modal
      aria-labelledby='AddNoteModal'
      aria-describedby='EditNoteModal'
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <ModalWrapper>
          <ModalContainer container xs={12} sx={{ gap: '8px' }}>
            <Grid xs={12}>
              <Grid item xs={12} sx={{ position: 'relative' }}>
                <CloseDeletePopoverOne onClick={handleClose} />
                <Typography
                  fontSize='18px'
                  fontWeight='600'
                  lineHeight='28px'
                  color='#101828'
                >
                  Your Shared Link is Ready!
                </Typography>
              </Grid>

              <Grid>
                <Typography
                  fontSize='14px'
                  fontWeight='400'
                  lineHeight='20px'
                  color='#475467'
                >
                  Select the project to add to this Shared Link.
                </Typography>
              </Grid>

              <Grid marginTop={'24px'} marginBottom={'12px'}>
                <div>
                  <InputLabel shrink htmlFor='link'>
                    Set a password for the Shared Link
                  </InputLabel>
                </div>
                <ModalOutlinedInput
                  fullWidth
                  size={'small'}
                  id='link'
                  type='text'
                  value={link}
                  name='link'
                />
              </Grid>

              <ButtonsWrapper container xs={12} sx={{ paddingTop: '32px' }}>
                <Grid item xs={extraSmallScreen && 12}>
                  <GrayButtonSmall
                    fullWidth={extraSmallScreen}
                    size='small'
                    outline
                    onClick={handleCopy}
                    startIcon={<CopyIcon />}
                  >
                    Copy link
                  </GrayButtonSmall>
                </Grid>
                <Grid item xs={extraSmallScreen && 12}>
                  <PrimaryButtonSmall
                    fullWidth={extraSmallScreen}
                    variant='contained'
                    size='small'
                    type='submit'
                    onClick={handleClose}
                  >
                    Done
                  </PrimaryButtonSmall>
                </Grid>
              </ButtonsWrapper>
            </Grid>
          </ModalContainer>
        </ModalWrapper>
      </Fade>
    </Modal>
  );
};

export default YourSharedLinkIsReadyModal;
