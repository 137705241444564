import React, { useCallback, useEffect, useState } from 'react';
import { SearchPopoverProps } from './types';
import { debounce, Grid, IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '../../../images/SearchIcon';
import FilterIcon from '../../../images/FilterIcon';
import { StyledOutlinedInput } from './styles';
import { FilterBy, SearchResult } from './components';
import { useAuth } from '../../../../hooks';
import CloseIcon from '../../../images/CloseIcon';
import { useAppSelector } from '../../../../store';

const SearchPopoverMobile: React.FC<SearchPopoverProps> = ({ onClose }) => {
  const { onGlobalSearch } = useAuth();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const [value, setValue] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [openFilter, seOpenFilter] = useState(false);

  const handleSetFilter = () => {
    seOpenFilter(!openFilter);
  };

  const debounceSearch = useCallback(
    debounce(value => {
      if (currentAccount) {
        onGlobalSearch({
          accountId: currentAccount.accountId,
          search: value,
          sortBy: filterBy,
        });
      }
    }, 500),
    [onGlobalSearch, currentAccount, filterBy]
  );

  const handleSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setValue(event.target.value);
    if (!(event.target.value.length < 4)) {
      debounceSearch(event.target.value);
    }
  };

  const handleFilterBy = (value: string) => {
    setFilterBy(value);
  };

  useEffect(() => {
    if (currentAccount && value.length >= 4) {
      onGlobalSearch({
        accountId: currentAccount.accountId,
        search: value,
        sortBy: filterBy,
      });
    }
  }, [currentAccount, filterBy]);

  return (
    <Grid>
      <Grid xs={12}>
        <StyledOutlinedInput
          autoFocus
          fullWidth
          id='search'
          name='search'
          placeholder='Search...'
          size={'small'}
          value={value}
          onChange={handleSearch}
          startAdornment={
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          }
          endAdornment={
            <>
              <InputAdornment position='end'>
                {value ? (
                  <IconButton
                    onClick={() => {
                      setValue('');
                      debounceSearch('');
                    }}
                  >
                    <CloseIcon width={'20'} height={'20'} />
                  </IconButton>
                ) : null}
                <IconButton onClick={handleSetFilter}>
                  <FilterIcon color={openFilter ? '#126FE9' : '#344054'} />
                </IconButton>
              </InputAdornment>
            </>
          }
        />
      </Grid>
      <FilterBy
        filterBy={filterBy}
        openFilter={openFilter}
        handleFilterBy={handleFilterBy}
      />
      <SearchResult onClose={onClose} value={value} filterBy={filterBy} />
    </Grid>
  );
};

export default SearchPopoverMobile;
