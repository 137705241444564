import React from 'react';
import { Grid } from '@mui/material';

import { ProgressProps } from './types';
import { StyledProgress, Title, StyledLinearProgress } from './styles';

export const normalise = (value = 0, maxValue = 0) => {
  const result = (value * 100) / maxValue;
  return result > 100 ? 100 : result;
};

export const normaliseLimits = (currentNumber = 0, defaultValue = 0) => {
  const result = ((defaultValue - currentNumber) / defaultValue) * 100;
  return result > 100 ? 100 : result;
};

export const getUsed = (defaultValue = 0, currentNumber = 0) => {
  const result = defaultValue - currentNumber;
  return result < 0 ? defaultValue : result;
};

const Progress: React.FC<ProgressProps> = ({ title, maxValue, value }) => {
  return (
    <StyledProgress item xs={12}>
      <Grid container justifyContent='space-between'>
        <Title item>{title}</Title>
        <Title>
          {value}/{maxValue}
        </Title>
      </Grid>
      <StyledLinearProgress
        variant='determinate'
        value={normalise(value, maxValue)}
      />
    </StyledProgress>
  );
};

export default Progress;
