import React from 'react';

const KeywordRankingsTableArrowIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='16'
      viewBox='0 0 24 16'
      fill='none'
    >
      <path
        d='M23.4059 1.06543L23.3989 1.07333L23.3923 1.08151L12.451 14.5423L12.4446 14.5503L12.4384 14.5585C12.2589 14.7988 11.838 14.7988 11.6585 14.5585L11.6515 14.5492L11.6441 14.5402L0.581743 1.08588C0.373051 0.809781 0.586813 0.5 0.822584 0.5H23.2111C23.2992 0.5 23.408 0.56068 23.4675 0.691347C23.5202 0.80713 23.5155 0.941616 23.4059 1.06543Z'
        fill='white'
        stroke='#DEDEDE'
      />
    </svg>
  );
};

export default KeywordRankingsTableArrowIcon;
