import React, { useState } from 'react';
import { StyledOverview } from '../../../../../projects/components/ProjectExpanded/components/Overview/styles';
import { StyledNoNotes } from './styles';
import PlusIcon from '../../../../../../shared/images/PlusIcon';
import AddNoteModal from '../../../../modals/AddNoteModal/AddNoteModal';
import useAccountLimits from '../../../../../auth/hooks/useAccountLimits';
import EmptyTable from '../../../../../../shared/components/EmptyTable/EmptyTable';
import { useProjects } from '../../../../../../hooks';
import { useAppSelector } from '../../../../../../store';

const NoNotes = () => {
  const { onCheckSubscriptionExpiration } = useAccountLimits();
  const { onGetProjectPerformance } = useProjects();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );
  const activePeriodProjectPerformance = useAppSelector(
    state => state.projects.projectExpandedGraphs.activePeriodProjectPerformance
  );
  const deviceType = useAppSelector(
    state => state.projects.projectExpandedGraphs.deviceType
  );

  const [isOpenNoteModal, setOpenNoteModal] = useState(false);

  const onDone = () => {
    if (currentAccount?.accountId && projectExpanded?.id && deviceType)
      onGetProjectPerformance({
        id: currentAccount.accountId,
        projectId: projectExpanded.id,
        period: activePeriodProjectPerformance.value,
        competitorIds: projectExpanded.competitors.map(item =>
          item.id.toString()
        ),
        deviceType: deviceType,
      });
  };

  const onOpenNoteModal = () => {
    const isExpiration = onCheckSubscriptionExpiration();
    if (!isExpiration) {
      setOpenNoteModal(true);
    }
  };

  const onCloseNoteModal = () => {
    setOpenNoteModal(false);
  };

  return (
    <StyledOverview>
      <span
        style={{
          color: '#101828',
          fontSize: '18px',
          fontWeight: '700',
        }}
      >
        Notes
      </span>
      <StyledNoNotes>
        <EmptyTable
          buttonText={'Add Note'}
          handleButton={onOpenNoteModal}
          supportingText={'Start by clicking the ‘Add Note’ button.'}
          text={'You don’t have any notes'}
          startIcon={<PlusIcon />}
        />
      </StyledNoNotes>
      <AddNoteModal
        open={isOpenNoteModal}
        onClose={onCloseNoteModal}
        onDone={onDone}
      />
    </StyledOverview>
  );
};

export default NoNotes;
