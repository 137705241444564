import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';

import {
  useAuth,
  useFolders,
  useProjects,
} from '../../../../../../../../hooks';
import { LIST_DOMAINS } from '../../../../../../../../constants/options';
import {
  CheckFrequency,
  Competitors,
  Domains,
  GoogleDomain,
  IBusinessCompetitors,
  IDeviceType,
  Language,
  SearchLocation,
} from '../../../../../../../projects/types';
import { AccountTag } from '../../../../../../types';
import { closeSnackbarAction } from '../../../../../../../../utils/snackbar';
import CustomToast from '../../../../../../../../shared/CustomToast/CustomToast';
import ProjectModalContainer from '../../../../../../../projects/components/Projects/components/ProjectRedactorModal/ProjectModalContainer';
import { CloseDeletePopoverOne } from '../../../../../../../auth/components/Settings/components/Account/styles';
import { NewProjectModalProps } from './types';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from '../../../../../../../../store';
import useAccountLimits from '../../../../../../../auth/hooks/useAccountLimits';
import { useNavigate } from 'react-router';
import ROUTES from '../../../../../../../../routes/constants';
import CompetitorsLimitExceededModal from '../../../../../../../../shared/components/CompetitorsLimitExceededModal/CompetitorsLimitExceededModal';
import TagsPreProjectExceededModal from '../../../../../../../../shared/components/TagsPreProjectExceededModal/TagsPreProjectExceededModal';
import DailyRefreshesLimitExceededModal from '../../../../../../../../shared/components/DailyRefreshesLimitExceededModal/DailyRefreshesLimitExceededModal';
import { determineProjectTypeAndAction } from '../../../../../../../projects/createProjectUtils';
import { getValidationSchemaForNewProject } from '../../../../../../../projects/projectValidation';

const NewProjectModal: React.FC<NewProjectModalProps> = ({
  open,
  folderId,
  handleClose,
}) => {
  const navigate = useNavigate();

  const { onGetMyAccount, onGetAccountLimit } = useAuth();

  const {
    onNewProject,
    onNewProjectForBaidu,
    onNewProjectForGoogleMaps,
    onNewProjectForBing,
    onNewProjectForYahoo,
    onNewProjectForGoogleLocal,
    onNewProjectForYoutube,
    onGetAccountTags,
    onGetProjectsListForInvite,
  } = useProjects();

  const {
    isRootView,
    onGetRootFolders,
    onGetFoldersById,
    currentFolder,
    tables: {
      rootFolder: {
        paginationModel,
        search,
        sortModel,
        rootFolderColumnVisibilityModel,
        childrenFolderColumnVisibilityModel,
      },
    },
  } = useFolders();

  const {
    onCheckKeywordLimit,
    onCheckCompetitorsLimit,
    onCloseCompetitorsLimitExceededModal,
    onCloseTagsPerProjectLimitExceededModal,
    onCheckTagsPerProjectLimit,
    onCloseRefreshesLimitLimitExceededModal,
    onCheckCannotAddExcessKeywords,
    onOpenKeywordUpdateScheduledModal,
  } = useAccountLimits();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const selectedTags = useAppSelector(state => state.projects.selectedTags);
  const checkFrequency = useAppSelector(state => state.projects.checkFrequency);
  const languages = useAppSelector(state => state.projects.languages);
  const deviceTypes = useAppSelector(state => state.projects.deviceTypes);
  const dailyRefreshesLimitExceededOpen = useAppSelector(
    state => state.auth.accountLimitErrors.dailyRefreshesLimitExceeded.open
  );
  const competitorsLimitExceededOpen = useAppSelector(
    state => state.auth.accountLimitErrors.competitorsLimitExceeded.open
  );
  const tagsPreProjectLimitExceededOpen = useAppSelector(
    state => state.auth.accountLimitErrors.tagsPerProjectLimitExceeded.open
  );

  const [isOpenAdvancedSettings, setIsOpenAdvancedSettings] = useState(false);
  const onToggleAdvancedSettings = () => {
    setIsOpenAdvancedSettings(!isOpenAdvancedSettings);
  };

  const [localSearchEngine, setLocalSearchEngine] = useState('Google');

  const onClose = () => {
    handleClose();
    formik.resetForm();
  };

  const columnVisibilityModel = useMemo(
    () =>
      isRootView
        ? rootFolderColumnVisibilityModel
        : childrenFolderColumnVisibilityModel,
    [
      childrenFolderColumnVisibilityModel,
      isRootView,
      rootFolderColumnVisibilityModel,
    ]
  );

  const formik = useFormik({
    initialValues: {
      projectName: '',
      url: '',
      projectUrlType: LIST_DOMAINS[0] as Domains,
      keywords: [''] as string[],
      searchEngine: 'Google',
      regionId: {
        countryName: 'United States',
        id: 51,
        name: 'google.com',
      } as GoogleDomain,
      location: {
        id: 229,
        locationName: 'United States',
      } as SearchLocation | null,
      deviceType: deviceTypes[0] as IDeviceType,
      languageId: languages.find(x => x.name === 'English') as Language,
      checkFrequency: checkFrequency[0] as CheckFrequency,
      competitorIds: [] as Competitors[],
      competitors: [] as string[],
      tagIds: [] as AccountTag[],
      tags: [] as string[],
      note: '',
      message: '',
      folder: null as { id: number; name: string } | null,
      businessName: '',
      businessUrl: '',
      businessCompetitors: [] as IBusinessCompetitors[],
      competitorsVideoUrl: [] as string[],
      videoUrl: '',
    },
    enableReinitialize: true,
    validationSchema: getValidationSchemaForNewProject(
      isOpenAdvancedSettings,
      localSearchEngine
    ),
    onSubmit: (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
      const filterKeywordsLength = values.keywords.filter(item =>
        /\S/.test(item)
      ).length;
      const isDesktopAndMobile = values.deviceType.name === 'DesktopAndMobile';
      const googleMyBusinessOrYouTube =
        values.searchEngine === 'Google My Business' ||
        values.searchEngine === 'YouTube';

      const checkKeywordLimit = onCheckKeywordLimit();
      let checkCannotAddExcessKeywords;
      if (!checkKeywordLimit) {
        checkCannotAddExcessKeywords = onCheckCannotAddExcessKeywords(
          filterKeywordsLength,
          isDesktopAndMobile,
          googleMyBusinessOrYouTube,
          values.searchEngine || ''
        );
      }
      const competitorsError = onCheckCompetitorsLimit(
        values.competitors.filter(item => /\S/.test(item)).length
      );
      const tagsPerProjectError = onCheckTagsPerProjectLimit(
        values.tags.filter(item => /\S/.test(item)).length +
          values.tagIds.length
      );
      if (
        !checkKeywordLimit &&
        !checkCannotAddExcessKeywords &&
        !competitorsError &&
        !tagsPerProjectError
      ) {
        const createNewProjectAction = determineProjectTypeAndAction(
          values.searchEngine,
          {
            google: onNewProject,
            googleMyBusiness: onNewProjectForGoogleLocal,
            googleMaps: onNewProjectForGoogleMaps,
            bing: onNewProjectForBing,
            yahoo: onNewProjectForYahoo,
            baidu: onNewProjectForBaidu,
            youTube: onNewProjectForYoutube,
          }
        );

        if (createNewProjectAction) {
          createNewProjectAction({
            accountId: currentAccount.accountId,
            folderId: folderId,
            data: values,
            successFn: keywordUpdateWasSkipped => {
              if (keywordUpdateWasSkipped) {
                onOpenKeywordUpdateScheduledModal();
              }
              resetForm();
              handleClose();
              onGetAccountLimit({ id: currentAccount.accountId });
              onGetAccountTags({ accountId: currentAccount.accountId });
              onGetProjectsListForInvite({
                accountId: currentAccount.accountId,
                search: '',
              });
              isRootView
                ? onGetRootFolders({
                    accountId: currentAccount.accountId,
                    params: {
                      page: paginationModel.page,
                      limit: paginationModel.pageSize,
                      search: search,
                      tagIds: selectedTags.map(item => item.id).join(),
                      sortBy: sortModel[0].field,
                      sortOrder: sortModel[0].sort,
                    },
                  })
                : onGetFoldersById({
                    accountId: currentAccount.accountId,
                    folderId: currentFolder.id,
                    params: {
                      page: paginationModel.page,
                      limit: paginationModel.pageSize,
                      search: search,
                      tagIds: selectedTags.map(item => item.id).join(),
                      dailyAverage: columnVisibilityModel.dailyAverage,
                    },
                  });
              onGetMyAccount(currentAccount.accountId);
              enqueueSnackbar(
                <CustomToast
                  message='Success'
                  submessage='New project created successfully.'
                />,
                { action: closeSnackbarAction }
              );
            },
            errorFn: (error: any) => {
              setStatus({ success: false });
              setSubmitting(false);
              setErrors(error);
            },
          });
        }
      } else {
        setStatus({ success: false });
        setSubmitting(false);
      }
    },
  });

  const handleCloseRefreshesLimitExceededModal = () => {
    onCloseRefreshesLimitLimitExceededModal();
    formik.resetForm();
  };

  const handleDailyRefreshesUpgradeAccount = () => {
    onCloseRefreshesLimitLimitExceededModal();
    navigate(ROUTES.upgradePlan);
  };

  const handleCloseCompetitorsLimitExceededModal = () => {
    onCloseCompetitorsLimitExceededModal();
    formik.resetForm();
  };

  const handleCompetitorsUpgradeAccount = () => {
    onCloseCompetitorsLimitExceededModal();
    navigate(ROUTES.upgradePlan);
  };

  const handleCloseTagsPerProjectLimitExceededModal = () => {
    onCloseTagsPerProjectLimitExceededModal();
    formik.resetForm();
  };

  const handleTagsPerProjectUpgradeAccount = () => {
    onCloseTagsPerProjectLimitExceededModal();
    navigate(ROUTES.upgradePlan);
  };

  useEffect(() => {
    setLocalSearchEngine(formik.values.searchEngine);
  }, [formik.values.searchEngine]);

  return (
    <>
      <ProjectModalContainer
        formik={formik}
        open={open}
        handleClose={handleClose}
        isOpenAdvancedSettings={isOpenAdvancedSettings}
        onToggleAdvancedSettings={onToggleAdvancedSettings}
        isNew={true}
      >
        <Grid item xs={12} sx={{ position: 'relative' }}>
          <CloseDeletePopoverOne onClick={onClose}>
            <CloseIcon fontSize='small' />
          </CloseDeletePopoverOne>
          <Typography
            fontSize='18px'
            fontWeight='600'
            lineHeight='28px'
            color='#101828'
          >
            New Project
          </Typography>
          <Typography fontSize='14px' lineHeight='20px' color='#475467'>
            Enter details to add a new project.
          </Typography>
        </Grid>
      </ProjectModalContainer>
      <DailyRefreshesLimitExceededModal
        onClose={handleCloseRefreshesLimitExceededModal}
        onUpgradeAccount={handleDailyRefreshesUpgradeAccount}
        open={dailyRefreshesLimitExceededOpen}
      />
      <CompetitorsLimitExceededModal
        onClose={handleCloseCompetitorsLimitExceededModal}
        onUpgradeAccount={handleCompetitorsUpgradeAccount}
        open={competitorsLimitExceededOpen}
      />
      <TagsPreProjectExceededModal
        onClose={handleCloseTagsPerProjectLimitExceededModal}
        onUpgradeAccount={handleTagsPerProjectUpgradeAccount}
        open={tagsPreProjectLimitExceededOpen}
      />
    </>
  );
};

export default NewProjectModal;
