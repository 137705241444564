import React, { useCallback, useEffect, useMemo } from 'react';
import { Autocomplete, FormHelperText, Grid, TextField } from '@mui/material';
import { CustomTooltip } from '../../../../../../../../shared/tooltip/styles';
import * as Tooltips from '../../Tooltips';
import { ButtonTooltipContainer } from '../../styles';
import HelperIcon from '../../../../../../../../shared/images/HelperIcon';
import { ModalAutocompleteStylesProps } from '../../../../../../../../shared/modal/styled';
import SelectIcon from '../../../../../../../../shared/components/SelectIcon';
import { CustomFormHelperText } from '../../../../../../../../shared/components';
import { useAppSelector } from '../../../../../../../../store';
import { FormikProps } from 'formik';
import { NewProjectValues } from '../../../../../../types';
import useProjects from '../../../../../../hooks/useProjects';

interface SearchLocationChinaProps {
  isNew: boolean;
  formik: FormikProps<NewProjectValues>;
}

const SearchLocation: React.FC<SearchLocationChinaProps> = ({ formik }) => {
  const {
    onGetSearchLocations,
    onGetLocationsBing,
    onGetLocationsYoutube,
    onGetLocationsYahoo,
    onGetLocationsBaidu,
    onSetSearchLocationsMeta,
    onSetLocationsBingMeta,
    onSetLocationsYoutubeMeta,
    onSetLocationsYahooMeta,
    onSetLocationsBaiduMeta,
  } = useProjects();

  const searchLocations = useAppSelector(
    state => state.projects.searchLocations
  );
  const locationsBing = useAppSelector(state => state.projects.locationsBing);
  const locationsYoutube = useAppSelector(
    state => state.projects.locationsYoutube
  );
  const locationsYahoo = useAppSelector(state => state.projects.locationsYahoo);
  const locationsBaidu = useAppSelector(state => state.projects.locationsBaidu);

  const defaultLocations = useMemo(() => {
    switch (formik.values.searchEngine) {
      case 'Bing':
        return locationsBing;
      case 'YouTube':
        return locationsYoutube;
      case 'Yahoo':
        return locationsYahoo;
      case 'Baidu':
        return locationsBaidu;
      default:
        return searchLocations;
    }
  }, [
    formik?.values?.searchEngine,
    locationsBaidu,
    locationsBing,
    locationsYahoo,
    locationsYoutube,
    searchLocations,
  ]);

  const onGetLocationsAction = useCallback(() => {
    switch (formik.values.searchEngine) {
      case 'Bing':
        return onGetLocationsBing;
      case 'YouTube':
        return onGetLocationsYoutube;
      case 'Yahoo':
        return onGetLocationsYahoo;
      case 'Baidu':
        return onGetLocationsBaidu;
      default:
        return onGetSearchLocations;
    }
  }, [
    formik?.values?.searchEngine,
    onGetLocationsBaidu,
    onGetLocationsBing,
    onGetLocationsYahoo,
    onGetLocationsYoutube,
    onGetSearchLocations,
  ]);

  const onSetLocationsMetaAction = useCallback(() => {
    switch (formik.values.searchEngine) {
      case 'Bing':
        return onSetLocationsBingMeta;
      case 'YouTube':
        return onSetLocationsYoutubeMeta;
      case 'Yahoo':
        return onSetLocationsYahooMeta;
      case 'Baidu':
        return onSetLocationsBaiduMeta;
      default:
        return onSetSearchLocationsMeta;
    }
  }, [
    formik?.values?.searchEngine,
    onSetLocationsYahooMeta,
    onSetLocationsBaiduMeta,
    onSetLocationsBingMeta,
    onSetLocationsYoutubeMeta,
    onSetSearchLocationsMeta,
  ]);

  useEffect(() => {
    const action = onGetLocationsAction();
    action({
      search: defaultLocations.meta.search,
      page: defaultLocations.meta.page,
      limit: defaultLocations.meta.limit,
    });
  }, [
    onGetLocationsAction,
    defaultLocations.meta.search,
    defaultLocations.meta.page,
    defaultLocations.meta.limit,
    formik.values.searchEngine,
  ]);

  return (
    <Grid item xs={12}>
      <Grid container fontWeight='500' alignItems='center' fontSize='14px'>
        <Grid item>Search Location</Grid>
        <Grid item>
          <CustomTooltip
            title={<Tooltips.SearchLocation />}
            placement='top-start'
            arrow
          >
            <ButtonTooltipContainer>
              <HelperIcon />
            </ButtonTooltipContainer>
          </CustomTooltip>
        </Grid>
        <Autocomplete
          sx={ModalAutocompleteStylesProps}
          fullWidth
          size={'small'}
          id='location'
          options={defaultLocations.items}
          getOptionLabel={option => option.locationName}
          value={formik.values.location}
          onChange={(_, value) =>
            formik.setFieldValue('location', value || null)
          }
          onBlur={formik.handleBlur}
          popupIcon={<SelectIcon />}
          renderInput={params => (
            <TextField
              {...params}
              onChange={event => {
                const action = onSetLocationsMetaAction();
                action({
                  ...defaultLocations.meta,
                  search: event.target.value,
                });
              }}
              placeholder='Enter city, region, or country'
              name='location'
              error={!!(formik.touched.location && formik.errors?.location)}
            />
          )}
        />
        {formik.touched.location && formik.errors.location && (
          <FormHelperText error id='location'>
            <CustomFormHelperText error={formik.errors.location} />
          </FormHelperText>
        )}
      </Grid>
    </Grid>
  );
};

export default SearchLocation;
