import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Fade,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useProjects, useSharedLinks } from '../../../../../hooks';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DEFAULT_GET_USERS_PARAMS } from '../../../../auth/components/Settings/components/Team/constants';
import {
  ModalContainer,
  ModalWrapper,
} from '../../../../projects/components/Projects/components/ProjectRedactorModal/styles';
import { CloseDeletePopoverOne } from '../../../../auth/components/Settings/components/Account/styles';
import { CustomSwitchContainer } from '../AddSharedLinkModal/styles';
import { CustomSwitch } from '../../../../../shared/Switch/styles';
import { CustomFormHelperText } from '../../../../../shared/components';
import { filter } from '../../../../auth/components/Settings/components/Team/utils';
import {
  StyledAutocompleteLi,
  StyledChip,
} from '../../../../auth/components/Settings/components/Team/components/styles';
import CheckboxWrapper from '../../../../auth/components/Settings/components/Team/components/CheckboxWrapper';
import CloseIcon from '../../../../../shared/images/CloseIcon';
import SelectIcon from '../../../../../shared/components/SelectIcon';
import { ButtonsWrapper } from '../../../../auth/components/Settings/components/Team/components/customTableStyles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../shared/buttons/styles';
import { EditSharedLinkModalProps } from './types';
import CopyIcon from '../../../../../shared/images/CopyIcon';
import { writeText } from 'clipboard-polyfill';
import AutocompletePaper from '../../../../../shared/AutocompletePaper';
import { useAppSelector } from '../../../../../store';
import {
  ModalAutocompleteStylesProps,
  ModalMultipleAutocompleteStylesProps,
  ModalOutlinedInput,
} from '../../../../../shared/modal/styled';
import GlobeIcon from '../../../../../shared/images/GlobeIcon';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { PasswordStateLabel } from '../../../../auth/components/RegisterForm/styles';

const EditSharedLinkModal: React.FC<EditSharedLinkModalProps> = ({
  linkId,
  onClose,
  open,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const { onGetProjectsListForInvite } = useProjects();

  const projectsListForInvite = useAppSelector(
    state => state.projects.projectsListForInvite
  );

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const { onGetSharedLinks, onEditSharedLink, onGetSharedLinkFull } =
    useSharedLinks();

  const sortModel = useAppSelector(
    state => state.sharedLinks.tables.sharedLinksPage.sortModel
  );
  const search = useAppSelector(
    state => state.sharedLinks.tables.sharedLinksPage.search
  );
  const paginationModel = useAppSelector(
    state => state.sharedLinks.tables.sharedLinksPage.paginationModel
  );
  const sharedLinkFullInfo = useAppSelector(
    state => state.sharedLinks.sharedLinkFullInfo
  );

  const formik = useFormik({
    initialValues: {
      projects: [] as { id: number; projectName: string; favicon?: string }[],
      enableSharing: true,
      requiredPassword: true,
      password: '',
      message: '',
    },
    validationSchema: Yup.object().shape({
      projects: Yup.array().min(1, 'Project is required.'),
    }),
    onSubmit: (values, { setStatus, setSubmitting, setErrors, resetForm }) => {
      if (currentAccount?.accountId) {
        const requestData = {
          accountId: currentAccount.accountId,
          linkId: linkId,
          enableSharing: values.enableSharing,
          requiredPassword: values.requiredPassword,
          password: values.requiredPassword ? values.password : undefined,
          projectIds: values.projects.map(item => item.id),
          successFn: () => {
            resetForm();
            handleClose();
            onGetSharedLinks({
              id: currentAccount.accountId,
              page: paginationModel.page,
              limit: paginationModel.pageSize,
              search: search || DEFAULT_GET_USERS_PARAMS.search,
              sortBy: sortModel[0]?.field,
              sortOrder: sortModel[0]?.sort,
            });
          },
          errorFn: (error: any) => {
            setStatus({ success: false });
            setSubmitting(false);
            setErrors(error);
          },
        };
        onEditSharedLink({ ...requestData });
      }
    },
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  const handleCopy = () => {
    writeText(sharedLinkFullInfo?.link || '');
  };

  useEffect(() => {
    if (open && currentAccount?.accountId) {
      onGetProjectsListForInvite({
        accountId: currentAccount.accountId,
        search: '',
      });
      onGetSharedLinkFull({
        accountId: currentAccount.accountId,
        linkId: linkId,
      });
    }
  }, [
    currentAccount.accountId,
    linkId,
    onGetProjectsListForInvite,
    onGetSharedLinkFull,
    open,
  ]);

  useEffect(() => {
    if (sharedLinkFullInfo) {
      formik.setFieldValue('projects', sharedLinkFullInfo.projects);
      formik.setFieldValue('enableSharing', sharedLinkFullInfo.enableSharing);
      formik.setFieldValue(
        'requiredPassword',
        sharedLinkFullInfo.requirePassword
      );
    }
  }, [sharedLinkFullInfo]);

  return (
    <>
      <Modal
        aria-labelledby='AddNoteModal'
        aria-describedby='EditNoteModal'
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <form onSubmit={formik.handleSubmit}>
            <ModalWrapper>
              <ModalContainer container xs={12} sx={{ gap: '8px' }}>
                <Grid xs={12}>
                  <Grid item xs={12} sx={{ position: 'relative' }}>
                    <CloseDeletePopoverOne onClick={handleClose} />
                    <Typography
                      fontSize='18px'
                      fontWeight='600'
                      lineHeight='28px'
                      color='#101828'
                    >
                      Edit Shared Link
                    </Typography>
                  </Grid>

                  <Grid>
                    <Typography
                      fontSize='14px'
                      fontWeight='400'
                      lineHeight='20px'
                      color='#475467'
                    >
                      Anyone with the link will be able view this project.
                    </Typography>
                  </Grid>

                  <CustomSwitchContainer>
                    <Grid marginRight={'12px'}>Enable Sharing</Grid>
                    <Grid>
                      <CustomSwitch
                        checked={formik.values.enableSharing}
                        onChange={(e, checked) =>
                          formik.setFieldValue('enableSharing', checked)
                        }
                      />
                    </Grid>
                  </CustomSwitchContainer>

                  <CustomSwitchContainer marginBottom={'24px'}>
                    <Grid marginRight={'12px'}>Require Password</Grid>
                    <Grid>
                      <CustomSwitch
                        checked={formik.values.requiredPassword}
                        onChange={(e, checked) =>
                          formik.setFieldValue('requiredPassword', checked)
                        }
                      />
                    </Grid>
                  </CustomSwitchContainer>

                  {formik.values.requiredPassword ? (
                    <Grid marginBottom={'12px'}>
                      <div>
                        <InputLabel shrink htmlFor='password'>
                          Set a password for the Shared Link
                        </InputLabel>
                      </div>
                      <ModalOutlinedInput
                        fullWidth
                        size={'small'}
                        id='password'
                        type={showPassword ? 'text' : 'password'}
                        value={formik.values.password}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={
                          !!(formik.touched.password && formik.errors.password)
                        }
                        placeholder='Password that you’ll provide to the viewers'
                        name='password'
                        autoComplete='new-password'
                        endAdornment={
                          <InputAdornment
                            position='end'
                            onClick={handleClickShowPassword}
                            sx={{ cursor: 'pointer' }}
                          >
                            <IconButton
                              aria-label='toggle password visibility'
                              onMouseDown={handleMouseDownPassword}
                              edge='end'
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                            <PasswordStateLabel>
                              {showPassword ? <b>Hide</b> : <b>Show</b>}
                            </PasswordStateLabel>
                          </InputAdornment>
                        }
                      />
                      {
                        <FormHelperText
                          error={
                            !!(
                              formik.touched.password && formik.errors.password
                            )
                          }
                          id='password'
                        >
                          {formik.touched.password &&
                            formik.errors.password && (
                              <CustomFormHelperText
                                error={formik.errors.password}
                              />
                            )}
                        </FormHelperText>
                      }
                    </Grid>
                  ) : null}

                  <Grid>
                    <Autocomplete
                      sx={
                        formik.values.projects.length
                          ? ModalMultipleAutocompleteStylesProps
                          : ModalAutocompleteStylesProps
                      }
                      style={{ margin: '0 0 6px 0' }}
                      multiple
                      disableCloseOnSelect
                      fullWidth
                      size={'small'}
                      id='projects'
                      limitTags={2}
                      options={projectsListForInvite.items}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        return [
                          { id: 0, projectName: 'Select all' },
                          ...filtered,
                        ];
                      }}
                      getOptionLabel={option => option.projectName}
                      renderOption={(props, option, { selected }) => {
                        const isSelected =
                          option.id === 0
                            ? projectsListForInvite.items.length ===
                              formik.values.projects.length
                            : !!formik.values.projects.find(
                                item => item.id == option.id
                              );
                        return (
                          <StyledAutocompleteLi {...props} key={option.id}>
                            <Grid container>
                              <CheckboxWrapper checked={isSelected} />
                              <Grid
                                item
                                display={'flex'}
                                alignItems={'center'}
                                marginRight={'8px'}
                              >
                                {option?.favicon ? (
                                  <img
                                    src={option.favicon}
                                    width={16}
                                    height={16}
                                    alt={'Company Logo URL'}
                                  />
                                ) : (
                                  <GlobeIcon />
                                )}
                              </Grid>
                              <Grid item>{option.projectName}</Grid>
                            </Grid>
                          </StyledAutocompleteLi>
                        );
                      }}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => {
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <StyledChip
                              label={option.projectName}
                              deleteIcon={
                                <IconButton>
                                  <CloseIcon
                                    width='12'
                                    height='12'
                                    {...getTagProps({ index })}
                                  />
                                </IconButton>
                              }
                              {...getTagProps({ index })}
                            />
                          );
                        })
                      }
                      value={formik.values.projects}
                      onChange={(_, value) => {
                        if (
                          value.find(
                            option => option.projectName === 'Select all'
                          )
                        ) {
                          if (
                            projectsListForInvite.items.length !==
                            formik.values.projects.length
                          ) {
                            formik.setFieldValue(
                              'projects',
                              projectsListForInvite.items
                            );
                          } else {
                            formik.setFieldValue('projects', []);
                          }
                        } else {
                          formik.setFieldValue('projects', value || []);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      popupIcon={<SelectIcon />}
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder='Select project to give access'
                          name='projects'
                          error={
                            !!(
                              formik.touched.projects && formik.errors.projects
                            )
                          }
                        />
                      )}
                      PaperComponent={AutocompletePaper}
                    />
                    {formik.touched.projects && formik.errors?.projects && (
                      <FormHelperText error id='projects'>
                        <CustomFormHelperText
                          error={formik.errors.projects.toString()}
                        />
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid marginTop={'24px'} marginBottom={'12px'}>
                    <div>
                      <InputLabel shrink htmlFor='link'>
                        Share link
                      </InputLabel>
                    </div>
                    <ModalOutlinedInput
                      fullWidth
                      size={'small'}
                      id='link'
                      value={sharedLinkFullInfo?.link || ''}
                      name='link'
                      endAdornment={
                        <IconButton onClick={handleCopy}>
                          <CopyIcon />
                        </IconButton>
                      }
                    />
                  </Grid>

                  <Grid>
                    {
                      <FormHelperText
                        error={!!formik.errors.message}
                        id='message'
                      >
                        {formik.errors.message && (
                          <CustomFormHelperText error={formik.errors.message} />
                        )}
                      </FormHelperText>
                    }
                  </Grid>
                  <ButtonsWrapper container xs={12} sx={{ paddingTop: '32px' }}>
                    <Grid item xs={extraSmallScreen && 12}>
                      <GrayButtonSmall
                        fullWidth={extraSmallScreen}
                        size='small'
                        outline
                        onClick={handleClose}
                      >
                        Cancel
                      </GrayButtonSmall>
                    </Grid>
                    <Grid item xs={extraSmallScreen && 12}>
                      <PrimaryButtonSmall
                        fullWidth={extraSmallScreen}
                        variant='contained'
                        size='small'
                        type='submit'
                        disabled={formik.isSubmitting || !formik.isValid}
                        onClick={() => formik.handleSubmit()}
                      >
                        Done
                      </PrimaryButtonSmall>
                    </Grid>
                  </ButtonsWrapper>
                </Grid>
              </ModalContainer>
            </ModalWrapper>
          </form>
        </Fade>
      </Modal>
    </>
  );
};

export default EditSharedLinkModal;
