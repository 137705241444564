import React from 'react';

import { StyledTablePagination, PaginationContainer } from './styles';
import { useProjects } from '../../../../../../../../../../../../hooks';
import { useAppSelector } from '../../../../../../../../../../../../store';
import { PaginationProps } from '../../../../../types';

const Pagination: React.FC<PaginationProps> = ({
  handleResizeFromPaginate,
}) => {
  const { onSetKeywordPaginationPageSize, onSetKeywordPaginationPage } =
    useProjects();

  const keywordRanking = useAppSelector(
    state => state.projects.projectExpandedGraphs.keywordRankings.keywordRanking
  );
  const paginationModel = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table.paginationModel
  );

  const handleSetPaginationPageSize = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onSetKeywordPaginationPageSize(Number(e.target.value));
    handleResizeFromPaginate();
  };

  const handleSetPaginationPage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    onSetKeywordPaginationPage(Number(page));
    handleResizeFromPaginate();
  };

  return (
    <PaginationContainer xs={12}>
      <StyledTablePagination
        labelRowsPerPage='Show rows:'
        count={keywordRanking?.meta?.totalItems || 0}
        page={paginationModel.page}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onPageChange={handleSetPaginationPage}
        rowsPerPage={paginationModel.pageSize}
        onRowsPerPageChange={handleSetPaginationPageSize}
      />
    </PaginationContainer>
  );
};

export default Pagination;
