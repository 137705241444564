import { isNumber } from 'lodash';

const HOURS_IN_DAY = 24;

/**
 * Normalizes a value as a percentage of a maximum value for linear progress overview.
 * @param value - The current value to be normalized.
 * @param maxValue - The maximum value.
 * @returns The normalized value as a percentage.
 */
const normaliseLinearProgressOverview = (value: number, maxValue: number) =>
  (value * 100) / maxValue;

/**
 * Prepares a tooltip string indicating the remaining hours based on the provided updated string.
 * @param updated - A string containing the time value and unit (e.g., "5 hrs").
 * @returns A string indicating the number of hours until the next update.
 */
const prepareAgainHoursTooltip = (updated: string): string => {
  const [val, unit] = updated.split(' ');
  const hour =
    !unit?.includes('hr') || !isNumber(+val)
      ? HOURS_IN_DAY
      : HOURS_IN_DAY - +val;

  return hour !== 1 ? `${hour} hours` : `1 hour`;
};

export { normaliseLinearProgressOverview, prepareAgainHoursTooltip };
