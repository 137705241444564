import { createSlice } from '@reduxjs/toolkit';
import {
  EmailReportFrequency,
  EmailReportsResponse,
  IEmailReportFull,
} from '../types';
import {
  getEmailReportFrequencyThunk,
  getEmailReportFullThunk,
  getEmailReportsThunk,
  getReportDeliveryTimesThunk,
  getReportTypesThunk,
} from './thunk';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import {
  resetColumnVisibilityModelEmailReports,
  resetEmailReportsState,
  setColumnVisibilityModelEmailReports,
  setDeleteElementsScheduledEmailReportsTable,
  setPaginationPageEmailReports,
  setPaginationPageSizeEmailReports,
  setRowSelectionModelEmailReports,
  setSearchEmailReports,
  setSortModelEmailReports,
} from './actions';
import { DEFAULT_EMAIL_REPORTS_COLUMN_VISIBILITY_MODEL } from '../constants';
import { getPage } from '../../../utils';
import {
  initializeTableColumns,
  TABLES_STORAGE_KEYS,
} from '../../projects/tablesColumnStase';

const initialState = {
  isLoading: false,
  emailReports: null as EmailReportsResponse | null,
  emailReportFull: null as IEmailReportFull | null,
  emailReportFrequency: [] as EmailReportFrequency[],
  reportDeliveryTimes: [] as EmailReportFrequency[],
  reportTypes: [] as EmailReportFrequency[],
  tables: {
    scheduledEmailReports: {
      search: '',
      sortModel: [{ field: 'project', sort: 'desc' }] as GridSortModel,
      paginationModel: {
        pageSize: 10,
        page: 0,
      },
      rowSelectionModel: [] as GridRowSelectionModel,
      columnVisibilityModel: initializeTableColumns(
        TABLES_STORAGE_KEYS.scheduledEmailReportsPage,
        DEFAULT_EMAIL_REPORTS_COLUMN_VISIBILITY_MODEL
      ),
      deleteElements: [] as GridRowSelectionModel,
    },
  },
};

export const emailReportsSlice = createSlice({
  name: 'emailReports',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getEmailReportsThunk.pending, state => {
      state.emailReports = null;
    });
    builder.addCase(getEmailReportsThunk.fulfilled, (state, { payload }) => {
      state.emailReports = payload;
      state.tables.scheduledEmailReports.paginationModel.page = getPage(
        state.tables.scheduledEmailReports.paginationModel.page,
        payload.meta.currentPage,
        payload.meta.totalPages
      );
    });

    builder.addCase(setSearchEmailReports, (state, { payload }) => {
      state.tables.scheduledEmailReports.search = payload;
    });

    builder.addCase(setRowSelectionModelEmailReports, (state, { payload }) => {
      state.tables.scheduledEmailReports.rowSelectionModel = payload;
    });

    builder.addCase(
      setColumnVisibilityModelEmailReports,
      (state, { payload }) => {
        state.tables.scheduledEmailReports.columnVisibilityModel[payload.key] =
          payload.value;
      }
    );

    builder.addCase(resetColumnVisibilityModelEmailReports, state => {
      state.tables.scheduledEmailReports.columnVisibilityModel =
        DEFAULT_EMAIL_REPORTS_COLUMN_VISIBILITY_MODEL;
    });

    builder.addCase(setSortModelEmailReports, (state, { payload }) => {
      state.tables.scheduledEmailReports.sortModel = payload;
    });

    builder.addCase(setPaginationPageSizeEmailReports, (state, { payload }) => {
      state.tables.scheduledEmailReports.paginationModel.pageSize = payload;
    });

    builder.addCase(setPaginationPageEmailReports, (state, { payload }) => {
      state.tables.scheduledEmailReports.paginationModel.page = payload;
    });

    builder.addCase(
      getEmailReportFrequencyThunk.fulfilled,
      (state, { payload }) => {
        state.emailReportFrequency = payload.items;
      }
    );

    builder.addCase(
      getReportDeliveryTimesThunk.fulfilled,
      (state, { payload }) => {
        state.reportDeliveryTimes = payload.items;
      }
    );

    builder.addCase(getReportTypesThunk.fulfilled, (state, { payload }) => {
      state.reportTypes = payload.items;
    });

    builder.addCase(getEmailReportFullThunk.pending, state => {
      state.emailReportFull = null;
    });
    builder.addCase(getEmailReportFullThunk.fulfilled, (state, { payload }) => {
      state.emailReportFull = payload;
    });

    builder.addCase(resetEmailReportsState, state => {
      return (state = initialState);
    });

    builder.addCase(
      setDeleteElementsScheduledEmailReportsTable,
      (state, { payload }) => {
        state.tables.scheduledEmailReports.deleteElements =
          payload.deleteElements;
      }
    );
  },
});

export default emailReportsSlice.reducer;
