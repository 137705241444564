import React, { useEffect, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import useProjects from '../../../../hooks/useProjects';

import PlusIcon from '../../../../../../shared/images/PlusIcon';
import FilterIcon from '../../../../../../shared/images/FilterIcon';
import { NewProjectModal } from '../ProjectRedactorModal';

import {
  PrimaryButtonSmall,
  GrayButtonSmallForSelect,
} from '../../../../../../shared/buttons/styles';
import { useAppSelector } from '../../../../../../store';
import useAccountLimits from '../../../../../auth/hooks/useAccountLimits';

const Header = () => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');
  const seScreen = useMediaQuery('(max-width:400px)');

  const { onCheckSubscriptionExpiration } = useAccountLimits();
  const { onSetShowTableFilters, onSetNewProjectModal } = useProjects();

  const isShowTableFilters = useAppSelector(
    state => state.projects.isShowTableFilters
  );

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const isAuth = useAppSelector(state => state.auth.isAuth);
  const folderTree = useAppSelector(state => state.auth.myAccount.folderTree);
  const openNewProject = useAppSelector(
    state => state.projects.modals.newProject
  );

  const isAddon = useMemo(
    () => !isAuth || (isAuth && currentAccount?.role?.name === 'Addon'),
    [currentAccount?.role?.name, isAuth]
  );
  const isViewOnly = useMemo(
    () => !isAuth || (isAuth && currentAccount?.role?.name === 'ViewOnly'),
    [currentAccount?.role?.name, isAuth]
  );

  const [isOpenNewProjectModal, setIsOpenNewProjectModal] =
    React.useState(false);

  const handleOpenNewProjectModal = () => {
    const isExpiration = onCheckSubscriptionExpiration();
    if (!isExpiration) {
      onSetNewProjectModal(true);
      setIsOpenNewProjectModal(true);
    }
  };
  const handleCloseNewProjectModal = () => {
    onSetNewProjectModal(false);
    setIsOpenNewProjectModal(false);
  };

  const handleShowFilters = () => onSetShowTableFilters(!isShowTableFilters);

  useEffect(() => {
    if (!openNewProject && isOpenNewProjectModal) {
      setIsOpenNewProjectModal(false);
    }
  }, [openNewProject]);

  return (
    <Grid
      container
      item
      justifyContent='space-between'
      alignItems='center'
      padding='0 24px'
    >
      <Grid item>
        <Typography
          sx={{ pt: 1, pb: 2 }}
          fontWeight='600'
          color='#101828'
          fontSize='28px'
        >
          Projects
        </Typography>
      </Grid>

      <Grid item>
        <Grid container item>
          {/*<FrequencyFilter />*/}

          {!extraSmallScreen ? null : (
            <Grid
              item
              sx={{
                marginRight: '12px',
              }}
            >
              <GrayButtonSmallForSelect
                isSelected={isShowTableFilters}
                sx={{
                  padding: `${
                    seScreen ? '7px 12px !important' : '5px 12px !important'
                  }`,
                  minWidth: 'fit-content',
                }}
                size='small'
                outline
                startIcon={
                  !seScreen ? (
                    <FilterIcon
                      color={isShowTableFilters ? '#0E59BA' : '#344054'}
                    />
                  ) : null
                }
                onClick={handleShowFilters}
              >
                {!seScreen ? (
                  'Filters'
                ) : (
                  <FilterIcon
                    color={isShowTableFilters ? '#0E59BA' : '#344054'}
                  />
                )}
              </GrayButtonSmallForSelect>
            </Grid>
          )}

          {!isAddon && !isViewOnly && (
            <Grid item>
              <PrimaryButtonSmall
                disabled={!folderTree}
                sx={{
                  padding: extraSmallScreen
                    ? '10px 12px !important'
                    : '6px 12px !important',
                  minWidth: extraSmallScreen ? '40px !important' : null,
                }}
                variant='contained'
                size='small'
                startIcon={!extraSmallScreen ? <PlusIcon /> : null}
                onClick={handleOpenNewProjectModal}
              >
                {!extraSmallScreen ? 'Add New Project' : <PlusIcon />}
              </PrimaryButtonSmall>
            </Grid>
          )}
        </Grid>
      </Grid>
      {isOpenNewProjectModal && (
        <NewProjectModal
          open={openNewProject}
          handleClose={handleCloseNewProjectModal}
        />
      )}
    </Grid>
  );
};

export default Header;
