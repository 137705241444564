import React from 'react';
import { ProjectActionProps } from './types';
import useProjects from '../../../../../../../hooks/useProjects';

import {
  ListItemTypography,
  StyledListItem,
  StyledListItemButton,
} from '../styles';

import PlusIcon from '../../../../../../../../../shared/images/PlusIcon';
import { AddKeywordsModal } from '../../../../ProjectRedactorModal/AddKeywords';
import { useAppSelector } from '../../../../../../../../../store';
import useAccountLimits from '../../../../../../../../auth/hooks/useAccountLimits';

const AddKeywordsAction: React.FC<ProjectActionProps> = ({
  projectId,
  popupClose,
}) => {
  const [isOpenAddKeywordsModal, setIsOpenAddKeywordsModal] =
    React.useState(false);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const { onGetCurrentProject, onSetRowSelectionModel } = useProjects();
  const { onCheckSubscriptionExpiration } = useAccountLimits();

  const handleOpenAddKeywordsModal = () => {
    const isExpiration = onCheckSubscriptionExpiration();
    if (!isExpiration) {
      onGetCurrentProject({
        accountId: currentAccount.accountId,
        projectId: projectId,
        tags: true,
        notes: true,
        successFn: () => {
          setIsOpenAddKeywordsModal(true);
        },
      });
    }
  };

  const handleCloseAddKeywordsModal = () => {
    onSetRowSelectionModel([]);
    popupClose();
    setIsOpenAddKeywordsModal(false);
  };

  return (
    <>
      <StyledListItem onClick={handleOpenAddKeywordsModal}>
        <StyledListItemButton>
          <PlusIcon stroke='#667085' />
          <ListItemTypography marginLeft='8px'>Add Keywords</ListItemTypography>
        </StyledListItemButton>
      </StyledListItem>
      {isOpenAddKeywordsModal ? (
        <AddKeywordsModal
          open={isOpenAddKeywordsModal}
          handleClose={handleCloseAddKeywordsModal}
        />
      ) : null}
    </>
  );
};

export default AddKeywordsAction;
