import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StyledAccountContainer = styled(Grid)`
  background-color: white;
  border: 1px solid #e4e4e4;
  box-shadow: 0 3px 2px -1px rgba(0, 0, 0, 0.02),
    0px 1px 1px -1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 0 24px;
`;

const Title = styled(Grid)`
  color: #101828;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  padding: 16px 0;
`;

export { StyledAccountContainer, Title };
