import React, { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { useMediaQuery, Skeleton } from '@mui/material';
import { useNavigate } from 'react-router';
import Avatar from '@mui/material/Avatar';
import AccountMenu from './AccountMenu';
import SwitchAccess from './SwitchAccess';

import ROUTES from '../../../../../routes/constants';
import { useAuth } from '../../../../../hooks';
import { avatarStyles, myAccountAvatarStyles } from '../../utils';
import { PopoverSx } from '../../constant';

import { MyAccountContainer, Popover } from './styles';
import useApp from '../../../../../hooks/useApp';
import { useAppSelector } from '../../../../../store';

const MyAccount = () => {
  const { onLogout } = useAuth();
  const isAuth = useAppSelector(state => state.auth.isAuth);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const { onResetApp } = useApp();
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width:600px)');

  const [isSwitchAccess, setSsSwitchAccess] = useState(false);

  const onClickLogout = () => {
    onLogout();
    onResetApp();
    navigate(ROUTES.login);
  };

  if (!isAuth) {
    return <Skeleton height='20px' width='80px' />;
  }

  const openSwitchAccess = () => setSsSwitchAccess(true);
  const closeSwitchAccess = () => setSsSwitchAccess(false);

  const stylesAccountAvatar = currentAccount.isMyAccount
    ? {
        ...myAccountAvatarStyles(
          `${currentAccount.owner.ownerUserFirstName}`,
          `${currentAccount.owner.ownerUserLastName}`,
          currentAccount.accountId,
          '32px',
          '16px'
        ),
      }
    : {
        ...avatarStyles(
          `${currentAccount.owner.ownerUserFirstName}`,
          `${currentAccount.owner.ownerUserLastName}`,
          currentAccount.accountId,
          '32px',
          '16px'
        ),
      };

  return (
    <PopupState variant='popover' popupId='my-account'>
      {popupState => (
        <>
          <MyAccountContainer {...bindTrigger(popupState)}>
            {matches ? <MoreVertIcon /> : <Avatar {...stylesAccountAvatar} />}
          </MyAccountContainer>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={PopoverSx}
          >
            {isSwitchAccess ? (
              <SwitchAccess
                closeSwitchAccess={closeSwitchAccess}
                isOpen={popupState.isOpen}
              />
            ) : (
              <AccountMenu
                onClickLogout={onClickLogout}
                openSwitchAccess={openSwitchAccess}
                myAccountClosePopup={popupState.close}
              />
            )}
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export default MyAccount;
