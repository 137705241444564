import React, { useEffect, useMemo, useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import * as Tooltips from '../../projects/components/ProjectExpanded/components/Toltips';
import InfoIcon from '../../../shared/images/InfoIcon';

import { CustomTooltip } from '../../../shared/tooltip/styles';
import TextTooltip from '../../../shared/components/TextTooltip';
import EllipsisMenu from '../components/sharedProject/components/KeywordRankings/components/KeywordRankingsTable/components/EllipsisMenu/EllipsisMenu';
import { findLongestString, getColumnWidth } from '../../../utils/table';
import {
  MemoRenderCellBest,
  MemoRenderCellCPC,
  MemoRenderCellD1,
  MemoRenderCellD30,
  MemoRenderCellD7,
  MemoRenderCellFirst,
  MemoRenderCellLife,
  MemoRenderCellPosition,
  MemoRenderCellSearchValue,
  MemoRenderCellUpdated,
  MemoRenderCellURL,
} from '../../projects/components/ProjectExpanded/components/KeywordRankings/components/KeywordRankingsTable/components/Table/components/RenderCells/useRenderCells';
import { customSortComparator } from '../../emailReports/hooks/useScheduledEmailReportsTable';
import { useAppSelector } from '../../../store';
import { Skeleton } from '@mui/material';
import { TableHeaderStyles } from '../../../shared/stylesTable';
import { getLocalUtc } from '../../../utils/date';
import Grid from '@mui/material/Grid';
import { getDeviceTypeIcon } from '../../projects/utils';
import Box from '@mui/material/Box';

export default function useSharedKeywordRankingsTable() {
  const keywordRanking = useAppSelector(
    state => state.sharedLinks.projectGraphs.keywordRankings.keywordRanking
  );

  const [showUpdatedFullFormat, setShowUpdatedFullFormat] =
    useState<boolean>(false);

  const [nameWidth, setNameWidth] = useState<number>(130);
  const [urlWidth, setUrlWidth] = useState<number>(130);
  const [updatedWidth, setUpdatedWidth] = useState<number>(120);

  useEffect(() => {
    if (keywordRanking?.items) {
      setNameWidth(
        getColumnWidth(
          findLongestString(keywordRanking?.items?.map(item => item.name)) || ''
        )
      );
      setUrlWidth(
        getColumnWidth(
          findLongestString(keywordRanking?.items?.map(item => item.url)) || ''
        )
      );
      setUpdatedWidth(
        getColumnWidth(
          findLongestString(
            keywordRanking?.items?.map(item =>
              showUpdatedFullFormat
                ? item?.updatedFullFormat || '-'
                : item?.updated || '-'
            )
          ) || ''
        )
      );
    }
  }, [keywordRanking?.items, showUpdatedFullFormat]);

  const tableWidth = useMemo(
    () => ({
      name: keywordRanking?.items
        ? getColumnWidth(
            findLongestString(keywordRanking?.items?.map(item => item.name)) ||
              ''
          )
        : nameWidth,
      url: keywordRanking?.items
        ? getColumnWidth(
            findLongestString(keywordRanking?.items?.map(item => item.url)) ||
              ''
          )
        : urlWidth,
      updated: keywordRanking?.items
        ? getColumnWidth(
            findLongestString(
              keywordRanking?.items?.map(item =>
                showUpdatedFullFormat
                  ? item?.updatedFullFormat || '-'
                  : item?.updated || '-'
              )
            ) || ''
          )
        : updatedWidth,
    }),
    [
      keywordRanking?.items,
      showUpdatedFullFormat,
      nameWidth,
      urlWidth,
      updatedWidth,
    ]
  );

  const popperProps = {
    sx: {
      '& .MuiTooltip-tooltip': {
        backgroundColor: '#fff',
        color: '#344054',
        boxShadow:
          '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
        border: '1px solid #EDEDED',
      },
      '.MuiTooltip-arrow::before': {
        backgroundColor: '#fff',
        border: '1px solid #EDEDED',
      },
    },
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        sortable: true,
        headerName: 'Keyword',
        maxWidth: 425,
        minWidth: 130,
        width: tableWidth.name,
        sortComparator: customSortComparator,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.name} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              <Grid display={'flex'} alignItems={'center'}>
                {getDeviceTypeIcon(params.row.deviceType.name)}
                <Box display='flex' alignItems='center'>
                  <Box marginLeft='8px'> {params.row.name}</Box>
                </Box>
              </Grid>
            </CustomTooltip>
          );
        },
      },
      {
        field: 'position',
        headerName: 'Position',
        sortable: true,
        maxWidth: 110,
        minWidth: 110,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Position />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Position '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellPosition row={params.row} />
        ),
      },
      {
        field: 'd1',
        headerName: '1d',
        sortable: true,
        maxWidth: 80,
        minWidth: 80,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip title={<Tooltips.D1 />} placement='top-start' arrow>
              <TableHeaderStyles>
                {'1d '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellD1 row={params.row} />
        ),
      },
      {
        field: 'd7',
        headerName: '7d',
        sortable: true,
        maxWidth: 80,
        minWidth: 80,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip title={<Tooltips.D7 />} placement='top-start' arrow>
              <TableHeaderStyles>
                {'7d '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellD7 row={params.row} />
        ),
      },
      {
        field: 'd30',
        headerName: '30d',
        sortable: true,
        maxWidth: 85,
        minWidth: 85,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip title={<Tooltips.D30 />} placement='top-start' arrow>
              <TableHeaderStyles>
                {'30d '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellD30 row={params.row} />
        ),
      },
      {
        field: 'life',
        headerName: 'Life',
        sortable: true,
        maxWidth: 85,
        minWidth: 85,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Life />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Life '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellLife row={params.row} />
        ),
      },
      {
        field: 'best',
        headerName: 'Best',
        sortable: true,
        maxWidth: 85,
        minWidth: 85,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Best />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Best '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellBest row={params.row} />
        ),
      },
      {
        field: 'first',
        headerName: 'start',
        sortable: true,
        maxWidth: 90,
        minWidth: 90,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Start />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Start '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellFirst row={params.row} />
        ),
      },
      {
        field: 'searchValue',
        headerName: 'Search Value',
        sortable: true,
        maxWidth: 110,
        minWidth: 110,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Volume />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Volume '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellSearchValue row={params.row} />
        ),
      },
      {
        field: 'cpc',
        headerName: 'L-Vol',
        sortable: true,
        maxWidth: 100,
        minWidth: 100,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip title={<Tooltips.CPC />} placement='top-start' arrow>
              <TableHeaderStyles>
                {'CPC '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellCPC row={params.row} />
        ),
      },
      {
        field: 'url',
        headerName: 'URL',
        sortable: true,
        maxWidth: 300,
        minWidth: 90,
        width: tableWidth.url,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip title={<Tooltips.URL />} placement='top-start' arrow>
              <TableHeaderStyles>
                {'URL '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellURL row={params.row} />
        ),
      },
      {
        field: 'updated',
        headerName: 'Updated',
        sortable: true,
        maxWidth: 150,
        minWidth: 120,
        width: tableWidth.updated,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Updated />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Updated '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellUpdated
            row={params.row}
            showUpdatedFullFormat={showUpdatedFullFormat}
            utc={getLocalUtc()}
            handleSetShowUpdatedFullFormat={value =>
              setShowUpdatedFullFormat(value)
            }
          />
        ),
      },
      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 50,
        align: 'center',
        renderCell: (params: GridRenderCellParams) => (
          <div>
            <EllipsisMenu keyword={params.row} />
          </div>
        ),
      },
    ],
    [tableWidth, showUpdatedFullFormat]
  );

  const columnsSkeleton: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        sortable: true,
        headerName: 'Keyword',
        maxWidth: 425,
        minWidth: 130,
        width: tableWidth.name,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'position',
        headerName: 'Position',
        sortable: true,
        maxWidth: 110,
        minWidth: 110,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'd1',
        headerName: '1d',
        sortable: true,
        maxWidth: 80,
        minWidth: 80,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'd7',
        headerName: '7d',
        sortable: true,
        maxWidth: 80,
        minWidth: 80,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'd30',
        headerName: '30d',
        sortable: true,
        maxWidth: 85,
        minWidth: 85,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'life',
        headerName: 'Life',
        sortable: true,
        maxWidth: 85,
        minWidth: 85,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'best',
        headerName: 'Best',
        sortable: true,
        maxWidth: 85,
        minWidth: 85,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'first',
        headerName: 'start',
        sortable: true,
        maxWidth: 90,
        minWidth: 90,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'searchValue',
        headerName: 'Search Value',
        sortable: true,
        maxWidth: 110,
        minWidth: 110,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'cpc',
        headerName: 'L-Vol',
        sortable: true,
        maxWidth: 100,
        minWidth: 100,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'url',
        headerName: 'URL',
        sortable: true,
        maxWidth: 300,
        minWidth: 90,
        width: tableWidth.url,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'updated',
        headerName: 'Updated',
        sortable: true,
        maxWidth: 150,
        minWidth: 120,
        width: tableWidth.updated,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 50,
        align: 'center',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
    ],
    [tableWidth]
  );

  return {
    columns,
    columnsSkeleton,
  };
}
