import React, { useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../../../../../../../../../../../hooks';
import useInvoiceTable from '../../../../../../../../../../hooks/useInvoiceTable';
import { TableNoData } from '../../../../../../../../../../../../shared/components';
import { useAppSelector } from '../../../../../../../../../../../../store';
import { CustomDataGridInvoice } from './styles';

/**
 * Table component that displays a list of invoices in a data grid.
 */
const Table = () => {
  const { onSetSortModelInvoices } = useAuth();

  const pageSize = useAppSelector(
    state => state.auth.tables.invoice.paginationModel.pageSize
  );
  const sortModel = useAppSelector(
    state => state.auth.tables.invoice.sortModel
  );
  const invoicesState = useAppSelector(
    state => state.auth.payment.invoicesState
  );
  const openTariffPlanBanner = useAppSelector(
    state => state.auth.openTariffPlanBanner
  );

  const { columns, columnsSkeleton } = useInvoiceTable();

  const [itemCount, setItemCount] = useState(
    invoicesState?.meta?.itemCount || pageSize
  );

  const arrayOfObjects = useMemo(
    () => Array.from({ length: itemCount }, (_, index) => ({ id: index + 1 })),
    [itemCount]
  );

  const rows = useMemo(
    () => invoicesState?.items || [],
    [invoicesState?.items]
  );

  const renderRows = useMemo(() => {
    return !invoicesState?.items ? arrayOfObjects : rows;
  }, [arrayOfObjects, invoicesState?.items, rows]);

  const renderColumns = useMemo(() => {
    return !invoicesState?.items ? columnsSkeleton : columns;
  }, [columns, columnsSkeleton, invoicesState?.items]);

  useEffect(() => {
    if (invoicesState?.meta?.itemCount) {
      setItemCount(invoicesState.meta.itemCount);
    }
  }, [invoicesState?.meta?.itemCount]);

  return (
    <>
      <CustomDataGridInvoice
        autoHeight
        columnHeaderHeight={40}
        rowHeight={40}
        rows={renderRows}
        columns={renderColumns}
        keepNonExistentRowsSelected
        hideFooterPagination
        disableRowSelectionOnClick
        disableColumnMenu
        sortModel={sortModel}
        onSortModelChange={onSetSortModelInvoices}
        components={{
          NoRowsOverlay: TableNoData,
        }}
        $openTariffPlanBanner={openTariffPlanBanner}
      />
    </>
  );
};

export default Table;
