import { styled } from '@mui/material/styles';
import { ListItem, Typography } from '@mui/material';

const PositionBlock = styled(ListItem)`
  flex-direction: column;
  width: fit-content;
  gap: 24px 0;
  padding: 0;

  & .MuiListItem-root {
    padding: 0 16px 0 0;
    justify-content: flex-end;
  }
`;

const URLBlock = styled(PositionBlock)`
  width: 100%;
  overflow: hidden;

  & .MuiListItem-root {
    padding: 0;
    justify-content: flex-start;
  }

  .MuiTypography-root {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const HeaderTypography = styled(Typography)`
  width: 100%;
  gap: 16px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
`;

const ListItemTypography = styled(Typography)<{ keywordPosition: boolean }>`
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: ${props => (props.keywordPosition ? `700` : '400')};
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export { HeaderTypography, URLBlock, PositionBlock, ListItemTypography };
