import React, { useEffect, useMemo } from 'react';

import { Header, StyledSearchResultContainer } from './styles';

import { SearchResultList, Pagination } from './components';
import { SearchResultProps } from './types';
import { useProjects } from '../../../../../../../../hooks';
import { Grid } from '@mui/material';
import { useAppSelector } from '../../../../../../../../store';

const SearchResult: React.FC<SearchResultProps> = ({ id }) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const { onGetKeywordSearchResults } = useProjects();

  const keywordsAnalytics = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.keywordsAnalytics
  );

  const keywordAnalytic = useMemo(
    () => keywordsAnalytics.find(item => item.id === id),
    [id, keywordsAnalytics]
  );

  const paginationData = useMemo(() => {
    return {
      page: keywordAnalytic?.searchResult?.meta.currentPage || 0,
      totalItems: keywordAnalytic?.searchResult?.meta.totalItems || 0,
    };
  }, [
    keywordAnalytic?.searchResult?.meta?.currentPage,
    keywordAnalytic?.searchResult?.meta?.totalItems,
  ]);

  useEffect(() => {
    if (keywordAnalytic && !keywordAnalytic?.searchResult && currentAccount) {
      onGetKeywordSearchResults({
        accountId: currentAccount.accountId,
        keywordId: id,
        limit: 10,
      });
    }
  }, [keywordAnalytic?.id]);

  return (
    <StyledSearchResultContainer width={'100%'} paddingTop={'27px'}>
      <Header>Search Results</Header>
      <Grid xs={12}>
        <SearchResultList searchResult={keywordAnalytic?.searchResult} />
        <Pagination paginationData={paginationData} id={id} />
      </Grid>
    </StyledSearchResultContainer>
  );
};

export default SearchResult;
