import React, { useMemo } from 'react';
import { FormikProps } from 'formik';
import { NewProjectValues } from '../../../../../../types';
import { FormHelperText, Grid, Typography } from '@mui/material';
import { CustomTooltip } from '../../../../../../../../shared/tooltip/styles';
import * as Tooltips from '../../Tooltips';
import {
  AddedTag,
  AddTagsContainer,
  AddTagsInput,
  ButtonTooltipContainer,
} from '../../styles';
import HelperIcon from '../../../../../../../../shared/images/HelperIcon';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import CloseIcon from '../../../../../../../../shared/images/CloseIcon';
import { Popover } from '../../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import AddCompetitorsPopup from './AddCompetitorsPopup';
import { CustomFormHelperText } from '../../../../../../../../shared/components';

interface VideoURLCompetitorsProps {
  formik: FormikProps<NewProjectValues>;
  isNew: boolean;
}

const VideoURLCompetitors: React.FC<VideoURLCompetitorsProps> = ({
  formik,
  isNew,
}) => {
  const competitorsVideoUrl = useMemo(
    () => formik.values.competitorsVideoUrl,
    [formik.values.competitorsVideoUrl]
  );

  const competitorIds = useMemo(
    () => formik.values.competitorIds,
    [formik.values.competitorIds]
  );

  const isRender = useMemo(
    () => formik.values.searchEngine === 'YouTube',
    [formik.values.searchEngine]
  );

  if (!isRender) {
    return null;
  }

  return (
    <Grid item xs={12} paddingTop='8px'>
      <Grid container fontWeight='500' alignItems='center' fontSize='14px'>
        <Grid item>Competitor’s Video URL (Optional)</Grid>
        <Grid item>
          <CustomTooltip
            title={<Tooltips.CompetitorsVideoURL />}
            placement='top-start'
            arrow
          >
            <ButtonTooltipContainer>
              <HelperIcon />
            </ButtonTooltipContainer>
          </CustomTooltip>
        </Grid>

        <PopupState variant='popover' popupId='add-tag-setting'>
          {popupState => (
            <>
              <AddTagsInput {...bindTrigger(popupState)}>
                {competitorsVideoUrl.length
                  ? competitorsVideoUrl.map(item => (
                      <AddedTag key={item}>
                        <Typography component='span'>{item}</Typography>
                        <Typography
                          component='p'
                          sx={{ fontSize: '0' }}
                          onClick={event => {
                            event.stopPropagation();
                            event.preventDefault();
                            formik.setFieldValue(
                              'competitorsVideoUrl',
                              competitorsVideoUrl.filter(
                                filterItem => filterItem !== item
                              )
                            );
                          }}
                        >
                          <CloseIcon width='12' height='12' />
                        </Typography>
                      </AddedTag>
                    ))
                  : null}

                {competitorIds.length
                  ? competitorIds.map(item => (
                      <AddedTag key={item.id}>
                        <Typography component='span'>
                          {item.domainName}
                        </Typography>
                        <Typography
                          component='p'
                          sx={{ fontSize: '0' }}
                          onClick={event => {
                            event.stopPropagation();
                            event.preventDefault();
                            formik.setFieldValue(
                              'competitorIds',
                              competitorIds.filter(
                                filterItem => filterItem.id !== item.id
                              )
                            );
                          }}
                        >
                          <CloseIcon width='12' height='12' />
                        </Typography>
                      </AddedTag>
                    ))
                  : null}

                <AddTagsContainer>
                  <Typography component='span'>Add competitors</Typography>
                </AddTagsContainer>
              </AddTagsInput>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                sx={{
                  boxShadow:
                    '0px 12px 16px -4px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.06)',
                  borderRadius: '8px',
                  marginTop: '7px',
                }}
              >
                <AddCompetitorsPopup
                  closePopover={popupState.close}
                  formik={formik}
                  isNew={isNew}
                />
              </Popover>
            </>
          )}
        </PopupState>
        <>
          {formik.errors?.competitorsVideoUrl && (
            <FormHelperText error id='competitors'>
              <CustomFormHelperText
                error={formik.errors.competitorsVideoUrl.toString()}
              />
            </FormHelperText>
          )}
        </>
      </Grid>
    </Grid>
  );
};

export default VideoURLCompetitors;
