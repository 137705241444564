import React from 'react';

const CardIcon = () => {
  return (
    <svg
      width='58'
      height='41'
      viewBox='0 0 58 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.630435 4.19532C0.630435 2.33436 2.13904 0.825747 4 0.825747H54C55.861 0.825747 57.3696 2.33435 57.3696 4.19531V36.5431C57.3696 38.4041 55.861 39.9127 54 39.9127H4C2.13904 39.9127 0.630435 38.4041 0.630435 36.5431V4.19532Z'
        fill='white'
      />
      <path
        d='M0.630435 4.19532C0.630435 2.33436 2.13904 0.825747 4 0.825747H54C55.861 0.825747 57.3696 2.33435 57.3696 4.19531V36.5431C57.3696 38.4041 55.861 39.9127 54 39.9127H4C2.13904 39.9127 0.630435 38.4041 0.630435 36.5431V4.19532Z'
        stroke='#EAECF0'
        strokeWidth='1.26087'
      />
      <path
        d='M41.2228 17.5564H16.7783M16.7783 15.3564L16.7783 24.6453C16.7783 26.0143 16.7783 26.6988 17.0447 27.2217C17.2791 27.6817 17.6531 28.0556 18.113 28.29C18.6359 28.5564 19.3204 28.5564 20.6894 28.5564L37.3117 28.5564C38.6807 28.5564 39.3652 28.5564 39.8881 28.29C40.348 28.0556 40.722 27.6817 40.9563 27.2217C41.2228 26.6988 41.2228 26.0143 41.2228 24.6453V15.3564C41.2228 13.9874 41.2228 13.3029 40.9563 12.78C40.722 12.3201 40.348 11.9461 39.8881 11.7117C39.3652 11.4453 38.6807 11.4453 37.3117 11.4453L20.6894 11.4453C19.3204 11.4453 18.6359 11.4453 18.113 11.7117C17.6531 11.9461 17.2791 12.3201 17.0447 12.78C16.7783 13.3029 16.7783 13.9874 16.7783 15.3564Z'
        stroke='#475467'
        strokeWidth='2.44444'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CardIcon;
