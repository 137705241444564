import React, { useMemo, useState } from 'react';
import {
  useAuth,
  useFolders,
  useProjects,
} from '../../../../../../../../../hooks';
import {
  ListItemTypography,
  StyledListItem,
  StyledListItemButton,
} from '../../../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/styles';
import FolderIcon from '../../../../../../../../../shared/images/FolderIcon';
import MoveTo from '../../../../../../../../projects/components/MoveTo/MoveTo';
import { MoveToFolderActionProps } from './types';
import { useAppSelector } from '../../../../../../../../../store';

const MoveToFolderAction: React.FC<MoveToFolderActionProps> = ({
  currentItemId,
  itemType,
  popupClose,
}) => {
  const { onGetMyAccount } = useAuth();

  const myAccount = useAppSelector(state => state.auth.myAccount);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const { onMoveFoldersToFolder, onMoveProjectsToFolder, onGetProjects } =
    useProjects();

  const selectedTags = useAppSelector(state => state.projects.selectedTags);
  const projects = useAppSelector(state => state.projects.tables.projects);

  const {
    currentFolder,
    onGetFoldersById,
    onGetRootFolders,
    onGetAvailableFolders,
    isRootView,
    tables: { rootFolder },
    onSetRowSelectionModel,
  } = useFolders();

  const columnVisibilityModel = useMemo(
    () =>
      isRootView
        ? rootFolder.rootFolderColumnVisibilityModel
        : rootFolder.childrenFolderColumnVisibilityModel,
    [
      rootFolder.childrenFolderColumnVisibilityModel,
      isRootView,
      rootFolder.rootFolderColumnVisibilityModel,
    ]
  );

  const [isOpenMoveToFolder, setIsOpenMoveToFolder] = useState(false);
  const [folderErrorMessage, setFolderErrorMessage] = useState('');

  const handleOpenMoveToFolder = () => {
    setIsOpenMoveToFolder(true);
  };
  const handleCloseMoveToFolder = () => {
    onSetRowSelectionModel([]);
    setIsOpenMoveToFolder(false);
    popupClose();
  };

  const handleMoveFolder = (folderId: number) => {
    if (currentAccount?.accountId)
      onMoveFoldersToFolder({
        id: currentAccount.accountId,
        folderId: currentItemId,
        destinationFolderId: folderId,
        successFn: () => {
          isRootView
            ? onGetRootFolders({
                accountId: currentAccount.accountId,
                params: {
                  page: rootFolder.paginationModel.page,
                  limit: rootFolder.paginationModel.pageSize,
                  search: rootFolder.search,
                  tagIds: selectedTags.map(item => item.id).join(),
                  sortBy: rootFolder.sortModel[0].field,
                  sortOrder: rootFolder.sortModel[0].sort,
                },
              })
            : onGetFoldersById({
                accountId: currentAccount.accountId,
                folderId: currentFolder.id,
                params: {
                  page: rootFolder.paginationModel.page,
                  limit: rootFolder.paginationModel.pageSize,
                  search: rootFolder.search,
                  tagIds: selectedTags.map(item => item.id).join(),
                  dailyAverage: columnVisibilityModel.dailyAverage,
                },
              });
          onGetMyAccount(currentAccount.accountId);
          onGetAvailableFolders({ accountId: myAccount.id });
          setFolderErrorMessage('');
          handleCloseMoveToFolder();
        },
        errorFn: setFolderErrorMessage,
      });
  };

  const handleMoveProject = (folderId: number) => {
    if (currentAccount?.accountId)
      onMoveProjectsToFolder({
        id: currentAccount.accountId,
        folderId: folderId,
        projectIds: [currentItemId],
        successFn: () => {
          onGetProjects({
            accountId: currentAccount.accountId,
            params: {
              frequencyName: projects.frequencyFilter,
              page: projects.paginationModel.page,
              limit: projects.paginationModel.pageSize,
              tagIds: selectedTags.map(item => item.id).join(),
              search: projects.search,
              sortBy: projects.sortModel[0]?.field,
              sortOrder: projects.sortModel[0]?.sort,
              dailyAverage: columnVisibilityModel.dailyAverage,
            },
          });
          onGetMyAccount(currentAccount.accountId);
        },
      });
  };

  return (
    <>
      <StyledListItem onClick={handleOpenMoveToFolder}>
        <StyledListItemButton>
          <FolderIcon />
          <ListItemTypography marginLeft='8px'>
            Move to Folder
          </ListItemTypography>
        </StyledListItemButton>
      </StyledListItem>
      {isOpenMoveToFolder && (
        <MoveTo
          isOpen={isOpenMoveToFolder}
          handleClose={handleCloseMoveToFolder}
          handleMove={
            itemType === 'project' ? handleMoveProject : handleMoveFolder
          }
          type={itemType === 'project' ? 'moveProject' : 'moveFolder'}
          folderErrorMessage={folderErrorMessage}
        />
      )}
    </>
  );
};

export default MoveToFolderAction;
