/**
 * Validates that a given threshold value does not exceed 100.
 *
 * @param {string} value - The threshold value to be validated, expected as a string.
 * @returns {string | undefined} - Returns an error message if the threshold exceeds 100; otherwise, returns `undefined`.
 */
function validateThreshold(value: string): string | undefined {
  if (Number(value) > 100) {
    return 'Threshold must not be greater than 100.';
  }
  return undefined;
}

export { validateThreshold };
