import React from 'react';
import { Grid, Skeleton } from '@mui/material';

const LineGraphSkeleton = () => {
  return (
    <Grid
      container
      alignItems={'center'}
      flexWrap={'nowrap'}
      width={'100%'}
      height={'100%'}
      padding={'15px'}
      overflow={'hidden'}
      position={'relative'}
    >
      <Grid
        width={'auto'}
        container
        item
        alignItems={'center'}
        flexWrap={'nowrap'}
        position={'relative'}
        gap={12}
      >
        <Grid item style={{ transform: 'rotate(45deg)' }}>
          <Skeleton width='3px' height='250px' />
        </Grid>
        <Grid item style={{ transform: 'rotate(-45deg)' }}>
          <Skeleton width='3px' height='250px' />
        </Grid>
      </Grid>
      <Grid
        width={'auto'}
        container
        item
        alignItems={'center'}
        flexWrap={'nowrap'}
        position={'relative'}
        gap={12}
      >
        <Grid
          item
          style={{
            transform: 'rotate(45deg)',
            position: 'relative',
            left: '109px',
          }}
        >
          <Skeleton width='3px' height='250px' />
        </Grid>
        <Grid
          item
          style={{
            transform: 'rotate(-45deg)',
            position: 'relative',
            left: '109px',
          }}
        >
          <Skeleton width='3px' height='250px' />
        </Grid>
      </Grid>
      <Grid
        width={'auto'}
        container
        item
        alignItems={'center'}
        flexWrap={'nowrap'}
        position={'relative'}
        gap={12}
      >
        <Grid
          item
          style={{
            transform: 'rotate(45deg)',
            position: 'relative',
            left: '218px',
          }}
        >
          <Skeleton width='3px' height='250px' />
        </Grid>
        <Grid
          item
          style={{
            transform: 'rotate(-45deg)',
            position: 'relative',
            left: '218px',
          }}
        >
          <Skeleton width='3px' height='250px' />
        </Grid>
      </Grid>
      <Grid
        width={'auto'}
        container
        item
        alignItems={'center'}
        flexWrap={'nowrap'}
        position={'relative'}
        gap={12}
      >
        <Grid
          item
          style={{
            transform: 'rotate(45deg)',
            position: 'relative',
            left: '326px',
          }}
        >
          <Skeleton width='3px' height='250px' />
        </Grid>
        <Grid
          item
          style={{
            transform: 'rotate(-45deg)',
            position: 'relative',
            left: '326px',
          }}
        >
          <Skeleton width='3px' height='250px' />
        </Grid>
      </Grid>
      <Grid
        width={'auto'}
        container
        item
        alignItems={'center'}
        flexWrap={'nowrap'}
        position={'relative'}
        gap={12}
      >
        <Grid
          item
          style={{
            transform: 'rotate(45deg)',
            position: 'relative',
            left: '434px',
          }}
        >
          <Skeleton width='3px' height='250px' />
        </Grid>
        <Grid
          item
          style={{
            transform: 'rotate(-45deg)',
            position: 'relative',
            left: '434px',
          }}
        >
          <Skeleton width='3px' height='250px' />
        </Grid>
      </Grid>
      <Grid
        width={'auto'}
        container
        item
        alignItems={'center'}
        flexWrap={'nowrap'}
        position={'relative'}
        gap={12}
      >
        <Grid
          item
          style={{
            transform: 'rotate(45deg)',
            position: 'relative',
            left: '542px',
          }}
        >
          <Skeleton width='3px' height='250px' />
        </Grid>
        <Grid
          item
          style={{
            transform: 'rotate(-45deg)',
            position: 'relative',
            left: '542px',
          }}
        >
          <Skeleton width='3px' height='250px' />
        </Grid>
      </Grid>
      <Grid
        width={'auto'}
        container
        item
        alignItems={'center'}
        flexWrap={'nowrap'}
        position={'relative'}
        gap={12}
      >
        <Grid
          item
          style={{
            transform: 'rotate(45deg)',
            position: 'relative',
            left: '650px',
          }}
        >
          <Skeleton width='3px' height='250px' />
        </Grid>
        <Grid
          item
          style={{
            transform: 'rotate(-45deg)',
            position: 'relative',
            left: '650px',
          }}
        >
          <Skeleton width='3px' height='250px' />
        </Grid>
      </Grid>
      <Grid
        width={'auto'}
        container
        item
        alignItems={'center'}
        flexWrap={'nowrap'}
        position={'relative'}
        gap={12}
      >
        <Grid
          item
          style={{
            transform: 'rotate(45deg)',
            position: 'relative',
            left: '758px',
          }}
        >
          <Skeleton width='3px' height='250px' />
        </Grid>
        <Grid
          item
          style={{
            transform: 'rotate(-45deg)',
            position: 'relative',
            left: '758px',
          }}
        >
          <Skeleton width='3px' height='250px' />
        </Grid>
      </Grid>
      <Grid
        width={'auto'}
        container
        item
        alignItems={'center'}
        flexWrap={'nowrap'}
        position={'relative'}
        gap={12}
      >
        <Grid
          item
          style={{
            transform: 'rotate(45deg)',
            position: 'relative',
            left: '866px',
          }}
        >
          <Skeleton width='3px' height='250px' />
        </Grid>
        <Grid
          item
          style={{
            transform: 'rotate(-45deg)',
            position: 'relative',
            left: '866px',
          }}
        >
          <Skeleton width='3px' height='250px' />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LineGraphSkeleton;
