import React from 'react';

import ClockIcon from '../../../../../../../../../shared/images/ClockIcon';
import { DeleteProjectActionProps } from './types';

import {
  ListItemTypography,
  StyledListItem,
  StyledListItemButton,
} from '../styles';
import useProjects from '../../../../../../../hooks/useProjects';
import EditFrequencyModal from '../../../../ProjectRedactorModal/EditFrequencyModal/EditFrequencyModal';
import { useFormik } from 'formik';
import { CheckFrequency } from '../../../../../../../types';
import { useAppSelector } from '../../../../../../../../../store';
import { useFolders } from '../../../../../../../../../hooks';

const EditFrequencyAction: React.FC<DeleteProjectActionProps> = ({
  projectId,
  popupClose,
}) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const {
    onGetCurrentProject,
    onChangeFrequencyForProjects,
    onGetProjects,
    onSetRowSelectionModel,
  } = useProjects();

  const { onSetRowSelectionModel: onSetRowSelectionModelFolders } =
    useFolders();

  const columnVisibilityModel = useAppSelector(
    state => state.projects.tables.projects.columnVisibilityModel
  );
  const frequencyFilter = useAppSelector(
    state => state.projects.tables.projects.frequencyFilter
  );
  const sortModel = useAppSelector(
    state => state.projects.tables.projects.sortModel
  );
  const search = useAppSelector(state => state.projects.tables.projects.search);
  const paginationModel = useAppSelector(
    state => state.projects.tables.projects.paginationModel
  );
  const currentProject = useAppSelector(state => state.projects.currentProject);
  const selectedTags = useAppSelector(state => state.projects.selectedTags);

  const [isOpenEditFrequencyModal, setIsOpenEditFrequencyModal] =
    React.useState(false);

  const formik = useFormik({
    initialValues: {
      checkFrequency: currentProject?.checkFrequency as CheckFrequency,
    },
    enableReinitialize: true,
    onSubmit: values => {
      onChangeFrequencyForProjects({
        accountId: currentAccount.accountId,
        projectIds: [projectId],
        frequency: values.checkFrequency.name,
        successFn: () => {
          onSetRowSelectionModel([]);
          onSetRowSelectionModelFolders([]);
          popupClose();
          onGetProjects({
            accountId: currentAccount.accountId,
            params: {
              frequencyName: frequencyFilter,
              page: paginationModel.page,
              limit: paginationModel.pageSize,
              tagIds: selectedTags.map(item => item.id).join(),
              search: search,
              sortBy: sortModel[0].field,
              sortOrder: sortModel[0].sort,
              dailyAverage: columnVisibilityModel.dailyAverage,
            },
          });
        },
      });
    },
  });

  const onClick = () => {
    onGetCurrentProject({
      accountId: currentAccount.accountId,
      projectId: projectId,
      tags: true,
      notes: true,
      successFn: () => {
        setIsOpenEditFrequencyModal(true);
      },
    });
  };

  const handleClose = () => {
    formik.resetForm();
    onSetRowSelectionModel([]);
    onSetRowSelectionModelFolders([]);
    setIsOpenEditFrequencyModal(false);
    popupClose();
  };

  const handleSubmitEditFrequencyModal = () => {
    formik.handleSubmit();
  };

  return (
    <>
      <StyledListItem>
        <StyledListItemButton onClick={onClick}>
          <ClockIcon />
          <ListItemTypography marginLeft='8px'>
            Edit Frequency
          </ListItemTypography>
        </StyledListItemButton>
      </StyledListItem>
      {isOpenEditFrequencyModal ? (
        <EditFrequencyModal
          isMultiple={false}
          open={isOpenEditFrequencyModal}
          handleClose={handleClose}
          formik={formik}
          handleSubmit={handleSubmitEditFrequencyModal}
        />
      ) : null}
    </>
  );
};

export default EditFrequencyAction;
