import {
  Autocomplete,
  Fade,
  FormHelperText,
  Grid,
  InputLabel,
  Modal,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect } from 'react';
import {
  ModalContainer,
  ModalWrapper,
} from '../../../projects/components/Projects/components/ProjectRedactorModal/styles';
import { AddNoteModalProps } from './types';
import { CloseDeletePopoverOne } from '../../../auth/components/Settings/components/Account/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomFormHelperText } from '../../../../shared/components';
import { useNotes, useProjects } from '../../../../hooks';
import { StyledAutocompleteLi } from '../../../auth/components/Settings/components/Team/components/styles';
import SelectIcon from '../../../../shared/components/SelectIcon';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../shared/buttons/styles';
import { ButtonsWrapper } from '../../../auth/components/Settings/components/Team/components/customTableStyles';
import { enqueueSnackbar } from 'notistack';
import CustomToast from '../../../../shared/CustomToast/CustomToast';
import { closeSnackbarAction } from '../../../../utils/snackbar';
import { DEFAULT_GET_USERS_PARAMS } from '../../../auth/components/Settings/components/Team/constants';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from '../../../../store';
import { ModalAutocompleteStylesProps } from '../../../../shared/modal/styled';
import { useNavigate } from 'react-router';
import useAccountLimits from '../../../auth/hooks/useAccountLimits';
import ROUTES from '../../../../routes/constants';
import NotesLimitExceededModal from '../../../../shared/components/NotesLimitExceededModal/NotesLimitExceededModal';
import GlobeIcon from '../../../../shared/images/GlobeIcon';

const AddNoteModal: React.FC<AddNoteModalProps> = ({
  onClose,
  onDone,
  open,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();

  const { onGetProjectsListForInvite } = useProjects();

  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );
  const projectsListForInvite = useAppSelector(
    state => state.projects.projectsListForInvite
  );
  const notesLimitExceededOpen = useAppSelector(
    state => state.auth.accountLimitErrors.notesLimitExceeded.open
  );

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const {
    onCreateNote,
    onGetProjectNotes,
    tables: {
      projectNotes: { sortModel, search, paginationModel },
    },
  } = useNotes();

  const { onCloseNotesLimitExceededModal, onCheckNotesLimit } =
    useAccountLimits();

  const formik = useFormik({
    initialValues: {
      note: '',
      text: '',
      project: undefined as
        | { id: number; projectName: string; favicon?: string }
        | undefined,
      message: '',
    },
    validationSchema: Yup.object().shape({
      note: Yup.string()
        .max(2500, 'Note must be at most 2,500 characters')
        .required('Note is required.')
        .trim()
        .matches(/\S/, 'Note should not contain only spaces.'),
      project: Yup.object().required('project is required.'),
    }),
    onSubmit: (values, { setStatus, setSubmitting, setErrors, resetForm }) => {
      const notesError = onCheckNotesLimit(1);
      if (
        currentAccount?.accountId &&
        values?.project?.id &&
        projectExpanded?.id &&
        !notesError
      ) {
        const requestData = {
          id: currentAccount.accountId,
          projectId: values.project.id,
          text: values.note,
          successFn: () => {
            onDone && onDone();
            resetForm();
            handleClose();
            onGetProjectNotes({
              id: currentAccount.accountId,
              projectId: projectExpanded.id as number,
              page: paginationModel.page,
              limit: paginationModel.pageSize,
              search: search || DEFAULT_GET_USERS_PARAMS.search,
              sortBy: sortModel[0]?.field,
              sortOrder: sortModel[0]?.sort,
            });
            enqueueSnackbar(
              <CustomToast
                message='Success'
                submessage='New Note created successfully.'
              />,
              { action: closeSnackbarAction }
            );
          },
          errorFn: (error: any) => {
            setStatus({ success: false });
            setSubmitting(false);
            setErrors(error);
          },
        };

        onCreateNote({ ...requestData });
      }
    },
  });

  const handleCloseNotesLimitExceededModal = () => {
    onCloseNotesLimitExceededModal();
    formik.resetForm();
  };

  const handleUpgradeAccount = () => {
    onCloseNotesLimitExceededModal();
    navigate(ROUTES.upgradePlan);
  };

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  useEffect(() => {
    if (open && currentAccount?.accountId) {
      onGetProjectsListForInvite({
        accountId: currentAccount.accountId,
        search: '',
      });
    }
  }, [currentAccount.accountId, onGetProjectsListForInvite, open]);

  return (
    <>
      <Modal
        aria-labelledby='AddNoteModal'
        aria-describedby='EditNoteModal'
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <form onSubmit={formik.handleSubmit}>
            <ModalWrapper>
              <ModalContainer container xs={12} sx={{ gap: '8px' }}>
                <Grid xs={12}>
                  <Grid item xs={12} sx={{ position: 'relative' }}>
                    <CloseDeletePopoverOne onClick={handleClose}>
                      <CloseIcon fontSize='small' />
                    </CloseDeletePopoverOne>
                    <Typography
                      fontSize='18px'
                      fontWeight='600'
                      lineHeight='28px'
                      color='#101828'
                    >
                      Add Note
                    </Typography>
                  </Grid>
                  <Grid xs={12} padding={'20px 0'}>
                    <InputLabel shrink htmlFor='note'>
                      Note
                    </InputLabel>
                    <OutlinedInput
                      multiline
                      rows={4}
                      fullWidth
                      id='note'
                      size='small'
                      type='text'
                      name={`note`}
                      placeholder='Enter a description...'
                      value={formik.values.note}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={!!(formik.touched.note && formik.errors?.note)}
                    />
                    {formik.touched.note && formik.errors?.note && (
                      <FormHelperText error id='note'>
                        <CustomFormHelperText error={formik.errors?.note} />
                      </FormHelperText>
                    )}
                    {formik.touched.text && formik.errors?.text && (
                      <FormHelperText error id='text'>
                        <CustomFormHelperText error={formik.errors?.text} />
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid>
                    <InputLabel shrink htmlFor='project'>
                      Assign to project
                    </InputLabel>
                    <Autocomplete
                      sx={ModalAutocompleteStylesProps}
                      style={{ marginBottom: '6px' }}
                      fullWidth
                      size={'small'}
                      id='project'
                      limitTags={2}
                      options={projectsListForInvite.items}
                      getOptionLabel={option => option.projectName}
                      renderOption={(props, option) => {
                        return (
                          <StyledAutocompleteLi {...props} key={option.id}>
                            <Grid container gap={'8px'}>
                              <Grid item display={'flex'} alignItems={'center'}>
                                {option?.favicon ? (
                                  <img
                                    src={option.favicon}
                                    width={16}
                                    height={16}
                                    alt={'Company Logo URL'}
                                  />
                                ) : (
                                  <GlobeIcon />
                                )}
                              </Grid>
                              <Grid item>{option.projectName}</Grid>
                            </Grid>
                          </StyledAutocompleteLi>
                        );
                      }}
                      value={formik.values.project}
                      onChange={(_, value) => {
                        formik.setFieldValue('project', value || undefined);
                      }}
                      onBlur={formik.handleBlur}
                      popupIcon={<SelectIcon />}
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder='Select project to assign this note'
                          name='project'
                          error={
                            !!(formik.touched.project && formik.errors.project)
                          }
                        />
                      )}
                      PaperComponent={paperProps => {
                        const { children, ...restPaperProps } = paperProps;
                        return <Paper {...restPaperProps}>{children}</Paper>;
                      }}
                    />
                    {formik.touched.project && formik.errors?.project && (
                      <FormHelperText error id='project'>
                        <CustomFormHelperText
                          error={formik.errors.project.toString()}
                        />
                      </FormHelperText>
                    )}
                    {formik.errors.message && (
                      <Grid item xs={12}>
                        <FormHelperText
                          error={!!formik.errors.message}
                          id='message'
                        >
                          {formik.errors.message && (
                            <CustomFormHelperText
                              error={formik.errors.message}
                            />
                          )}
                        </FormHelperText>
                      </Grid>
                    )}
                  </Grid>
                  <ButtonsWrapper container xs={12} sx={{ paddingTop: '32px' }}>
                    <Grid item xs={extraSmallScreen && 12}>
                      <GrayButtonSmall
                        fullWidth={extraSmallScreen}
                        size='small'
                        outline
                        onClick={handleClose}
                      >
                        Cancel
                      </GrayButtonSmall>
                    </Grid>
                    <Grid item xs={extraSmallScreen && 12}>
                      <PrimaryButtonSmall
                        fullWidth={extraSmallScreen}
                        variant='contained'
                        size='small'
                        type='submit'
                        disabled={formik.isSubmitting || !formik.isValid}
                        onClick={() => formik.handleSubmit()}
                      >
                        Done
                      </PrimaryButtonSmall>
                    </Grid>
                  </ButtonsWrapper>
                </Grid>
              </ModalContainer>
            </ModalWrapper>
          </form>
        </Fade>
      </Modal>
      <NotesLimitExceededModal
        onClose={handleCloseNotesLimitExceededModal}
        onUpgradeAccount={handleUpgradeAccount}
        open={notesLimitExceededOpen}
      />
    </>
  );
};

export default AddNoteModal;
