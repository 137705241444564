import React from 'react';
import { StyledListItemButton } from '../../../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/styles';
import { FolderRenameActionProps } from './types';

import EditPencilIcon from '../../../../../../../../../shared/images/EditPencilIcon';
import { RenameFolderModal } from '../../Modals';

import { ListItemTypography, StyledListItem } from '../styles';
import { useFolders } from '../../../../../../../../../hooks';

const RenameAction: React.FC<FolderRenameActionProps> = ({
  folderId,
  folderName,
  popupClose,
}) => {
  const { onSetRowSelectionModel } = useFolders();

  const [isOpenNewFolderModal, setIsOpenNewFolderModal] =
    React.useState<boolean>(false);

  const handleOpenNewFolderModal = () => setIsOpenNewFolderModal(true);
  const handleCloseNewFolderModal = () => setIsOpenNewFolderModal(false);

  const onRename = () => {
    handleOpenNewFolderModal();
  };

  const onClose = () => {
    onSetRowSelectionModel([]);
    popupClose();
    handleCloseNewFolderModal();
  };

  return (
    <StyledListItem onClick={onRename}>
      <StyledListItemButton>
        <EditPencilIcon />
        <ListItemTypography marginLeft='8px'>Rename</ListItemTypography>
      </StyledListItemButton>
      <RenameFolderModal
        open={isOpenNewFolderModal}
        handleClose={onClose}
        folderId={folderId}
        folderName={folderName}
      />
    </StyledListItem>
  );
};

export default RenameAction;
