import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const ListItem = styled(Grid)`
  justify-content: space-between;
  padding-bottom: 16px;
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

export { ListItem };
