import React from 'react';
import { EllipsisMenuProps } from './types';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListActions from './ListActions';
import { Popover } from '../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';

const EllipsisMenu: React.FC<EllipsisMenuProps> = ({ id }) => {
  return (
    <PopupState variant='popover'>
      {popupState => (
        <>
          <IconButton
            {...bindTrigger(popupState)}
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              bindTrigger(popupState).onClick(event);
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <ListActions id={id} popupClose={popupState.close} />
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export default EllipsisMenu;
