import React from 'react';

const ProfessionalIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='44'
    height='44'
    fill='none'
    viewBox='0 0 44 44'
  >
    <g filter='url(#filter0_d_1827_108639)'>
      <rect width='40' height='40' x='2' y='1' fill='#fff' rx='8'></rect>
      <path
        stroke='#475467'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.667'
        d='M13.667 23.083l8.035 4.018c.11.054.164.082.221.092.051.01.103.01.154 0 .057-.01.112-.038.221-.092l8.036-4.018m-16.667-4.167l8.035-4.017c.11-.055.164-.082.221-.093.051-.01.103-.01.154 0 .057.01.112.038.221.093l8.036 4.017-8.035 4.018a.909.909 0 01-.222.093.418.418 0 01-.154 0 .907.907 0 01-.22-.093l-8.036-4.018z'
      ></path>
      <rect
        width='39'
        height='39'
        x='2.5'
        y='1.5'
        stroke='#EAECF0'
        rx='7.5'
      ></rect>
    </g>
    <defs>
      <filter
        id='filter0_d_1827_108639'
        width='44'
        height='44'
        x='0'
        y='0'
        colorInterpolationFilters='sRGB'
        filterUnits='userSpaceOnUse'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
        <feColorMatrix
          in='SourceAlpha'
          result='hardAlpha'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        ></feColorMatrix>
        <feOffset dy='1'></feOffset>
        <feGaussianBlur stdDeviation='1'></feGaussianBlur>
        <feColorMatrix values='0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0'></feColorMatrix>
        <feBlend
          in2='BackgroundImageFix'
          result='effect1_dropShadow_1827_108639'
        ></feBlend>
        <feBlend
          in='SourceGraphic'
          in2='effect1_dropShadow_1827_108639'
          result='shape'
        ></feBlend>
      </filter>
    </defs>
  </svg>
);

export default ProfessionalIcon;
