import React from 'react';

import ExternalLinkIcon from '../../../../../../../../../shared/images/ExternalLinkIcon';
import { DeleteProjectActionProps } from './types';
import ROUTES from '../../../../../../../../../routes/constants';

import {
  ListItemTypography,
  StyledListItem,
  StyledListItemButton,
} from '../styles';
import { encryptData } from '../../../../../../../../../utils/cryptoJs';
import { useProjects } from '../../../../../../../../../hooks';

const OpenInNewTabAction: React.FC<DeleteProjectActionProps> = ({
  projectId,
  popupClose,
}) => {
  const { onSetRowSelectionModel } = useProjects();

  const onNavigate = () => {
    onSetRowSelectionModel([]);
    popupClose();
    window.open(
      `${window.location.origin}${ROUTES.dynamic.projectExpanded(
        encryptData(projectId.toString())
      )}`
    );
  };

  return (
    <StyledListItem onClick={onNavigate}>
      <StyledListItemButton>
        <ExternalLinkIcon />
        <ListItemTypography marginLeft='8px'>
          Open in New Tab
        </ListItemTypography>
      </StyledListItemButton>
    </StyledListItem>
  );
};

export default OpenInNewTabAction;
