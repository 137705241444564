import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router';
import { IFolder, IRootFolder } from '../../../../../../types';
import useFolders from '../../../../../../hooks/useFolders';

import CheckboxWrapper from '../../../../../../../auth/components/Settings/components/Team/components/CheckboxWrapper';
import CustomNoRowsOverlay from './CustomNoRowsOverlay';
import ROUTES from '../../../../../../../../routes/constants';
import Pagination from '../Pagination/Pagination';

import { CustomDataGrid } from './styles';
import {
  useFoldersTable,
  useRootFolderTable,
} from '../../../../../../../../hooks';
import { GridRowParams } from '@mui/x-data-grid';
import { useAppSelector } from '../../../../../../../../store';
import { encryptData } from '../../../../../../../../utils/cryptoJs';
import { arraysAreEqual } from '../../../../../../../../utils';
import PlusIcon from '../../../../../../../../shared/images/PlusIcon';
import EmptyTable from '../../../../../../../../shared/components/EmptyTable/EmptyTable';
import { NewFolderModal } from '../Modals';
import {
  setTableColumns,
  TABLES_STORAGE_KEYS,
} from '../../../../../../../projects/tablesColumnStase';

const Table = () => {
  const navigate = useNavigate();

  const { currentAccount } = useAppSelector(state => state.auth);
  const openTariffPlanBanner = useAppSelector(
    state => state.auth.openTariffPlanBanner
  );

  const {
    onSetSortModelNestedElements,
    onSetSortModelRootFolder,
    onSetCurrentFolder,
    onSetRowSelectionModel,
    isLoadingFolder,
    isRootView,
    rootFolder,
    folders,
    tables: {
      rootFolder: {
        rootFolderColumnVisibilityModel,
        childrenFolderColumnVisibilityModel,
        rowSelectionModel,
        search,
        deleteElements,
        sortModel,
        sortModelNestedElements,
        paginationModel: { pageSize },
      },
    },
  } = useFolders();

  const columnVisibilityModel = useMemo(
    () =>
      isRootView
        ? rootFolderColumnVisibilityModel
        : childrenFolderColumnVisibilityModel,
    [
      childrenFolderColumnVisibilityModel,
      isRootView,
      rootFolderColumnVisibilityModel,
    ]
  );

  const { columns: rootColumns, columnsSkeleton: rootColumnsSkeleton } =
    useRootFolderTable();
  const { columns: foldersColumn, columnsSkeleton: foldersColumnsSkeleton } =
    useFoldersTable();

  const [itemCount, setItemCount] = useState(
    isRootView
      ? rootFolder?.meta?.itemCount || pageSize
      : folders?.meta?.itemCount || pageSize
  );

  const [isOpenNewFolderModal, setIsOpenNewFolderModal] = useState(false);

  const handleOpenNewFolderModal = () => {
    setIsOpenNewFolderModal(true);
  };

  const handleCloseNewFolderModal = () => {
    setIsOpenNewFolderModal(false);
  };

  const arrayOfObjects = useMemo(
    () => Array.from({ length: itemCount }, (_, index) => ({ id: index + 1 })),
    [itemCount]
  );

  const isRowSelectable = (params: GridRowParams) => {
    if (currentAccount?.role?.name === 'ViewOnly') {
      return false;
    }
    return params.row.name !== 'Uncategorized Projects';
  };

  const rowsElements = isRootView
    ? rootFolder?.items?.length
      ? rootFolder.items
      : ([] as IRootFolder[])
    : folders?.items?.length
    ? folders.items
    : ([] as IFolder[]);

  const rows = useMemo(() => {
    const arr1 = [...rowsElements];
    const arr2Ids = deleteElements.map(element => element.id);
    return arr1.filter(item => !arr2Ids.includes(item.id));
  }, [deleteElements, rowsElements]);

  const isShowTableSkeleton = isRootView
    ? (isLoadingFolder && !rows.length) || !rootFolder?.items
    : (isLoadingFolder && !rows.length) || !folders?.items;

  const renderRows = useMemo(() => {
    return isRootView
      ? !rootFolder?.items
        ? arrayOfObjects
        : rows
      : !folders?.items
      ? arrayOfObjects
      : rows;
  }, [arrayOfObjects, folders?.items, isRootView, rootFolder?.items, rows]);

  const renderColumns = useMemo(() => {
    return isRootView
      ? !rootFolder?.items
        ? rootColumnsSkeleton
        : rootColumns
      : !folders?.items
      ? foldersColumnsSkeleton
      : foldersColumn;
  }, [
    folders?.items,
    foldersColumn,
    foldersColumnsSkeleton,
    isRootView,
    rootColumns,
    rootColumnsSkeleton,
    rootFolder?.items,
  ]);

  const NoRowsOverlay = useCallback(
    () =>
      isShowTableSkeleton ? (
        <Box />
      ) : search && !rows.length ? (
        <CustomNoRowsOverlay />
      ) : (
        <EmptyTable
          buttonText={'You don’t have any folders'}
          handleButton={handleOpenNewFolderModal}
          supportingText={'Start by clicking the ‘Add New Folder’ button.'}
          text={'You don’t have any folders'}
          startIcon={<PlusIcon />}
        />
      ),
    [isShowTableSkeleton, rows?.length, search]
  );

  useEffect(() => {
    if (isRootView && rootFolder?.meta?.itemCount) {
      setItemCount(rootFolder.meta.itemCount);
    }
  }, [isRootView, rootFolder?.meta?.itemCount]);

  useEffect(() => {
    if (!isRootView && folders?.meta?.itemCount) {
      setItemCount(folders.meta.itemCount);
    }
  }, [isRootView, folders?.meta?.itemCount]);

  useEffect(() => {
    const filtered = rowsElements
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .filter(obj => rowSelectionModel.some(item => item.id === obj.id))
      .filter(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        item => !deleteElements.some(element => element.id === item.id)
      ) as IFolder[];

    const filteredIds = filtered.map(item => item.id);
    const selectionModelIds = rowSelectionModel.map(item => item.id);

    if (filtered && !arraysAreEqual(selectionModelIds, filteredIds)) {
      onSetRowSelectionModel(filtered);
    }
  }, [deleteElements, onSetRowSelectionModel, rowsElements, rowSelectionModel]);

  const handleRowClick = useCallback(
    (params: GridRowParams<any>) => {
      if (params.row.createdAt) {
        if (params.row.type === 'project') {
          navigate(
            ROUTES.dynamic.projectExpanded(
              encryptData(params.row.id.toString())
            )
          );
          return;
        }
        onSetCurrentFolder({
          folder: { id: params.row.id, name: params.row.name },
          isRootView: false,
        });
        navigate(ROUTES.dynamic.folders(params.id as string));
      }
    },
    [navigate, onSetCurrentFolder]
  );

  useEffect(() => {
    setTableColumns(
      TABLES_STORAGE_KEYS.childrenFoldersPage,
      childrenFolderColumnVisibilityModel
    );
  }, [childrenFolderColumnVisibilityModel]);

  useEffect(() => {
    setTableColumns(
      TABLES_STORAGE_KEYS.rootFoldersPage,
      rootFolderColumnVisibilityModel
    );
  }, [rootFolderColumnVisibilityModel]);

  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          initialState={{
            pinnedColumns: {
              right: ['actions'],
            },
          }}
          isSelectedRow={true}
          columnHeaderHeight={40}
          rowHeight={40}
          rows={renderRows}
          onRowClick={handleRowClick}
          rowSelectionModel={rowSelectionModel.map(item => item.id)}
          onRowSelectionModelChange={params => {
            const selectedIDs = new Set(params);
            const selectedRowData = isRootView
              ? rootFolder.items.filter(row => selectedIDs.has(row.id))
              : folders.items.filter(row => selectedIDs.has(row.id));
            onSetRowSelectionModel(selectedRowData as IFolder[]);
          }}
          sortModel={isRootView ? sortModel : sortModelNestedElements}
          onSortModelChange={
            isRootView ? onSetSortModelRootFolder : onSetSortModelNestedElements
          }
          columns={renderColumns}
          columnVisibilityModel={columnVisibilityModel}
          components={{
            BaseCheckbox: CheckboxWrapper,
            NoRowsOverlay: NoRowsOverlay,
          }}
          isRowSelectable={isRowSelectable}
          keepNonExistentRowsSelected
          hideFooterPagination
          checkboxSelection
          disableRowSelectionOnClick
          disableColumnMenu
          $openTariffPlanBanner={openTariffPlanBanner}
        />
        <Pagination />
      </Grid>
      <NewFolderModal
        open={isOpenNewFolderModal}
        handleClose={handleCloseNewFolderModal}
      />
    </>
  );
};

export default Table;
