import {
  Fade,
  FormHelperText,
  Grid,
  InputLabel,
  Modal,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import {
  ModalContainer,
  ModalWrapper,
} from '../../../projects/components/Projects/components/ProjectRedactorModal/styles';
import { EditNoteModalProps } from './types';
import { CloseDeletePopoverOne } from '../../../auth/components/Settings/components/Account/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomFormHelperText } from '../../../../shared/components';
import { useNotes, useProjects } from '../../../../hooks';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../shared/buttons/styles';
import { ButtonsWrapper } from '../../../auth/components/Settings/components/Team/components/customTableStyles';
import { DEFAULT_GET_USERS_PARAMS } from '../../../auth/components/Settings/components/Team/constants';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from '../../../../store';

const EditNoteModal: React.FC<EditNoteModalProps> = ({
  onClose,
  onDone,
  open,
  noteId,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const { onGetProjectsListForInvite } = useProjects();

  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const {
    onEditNote,
    onGetProjectNotes,
    projectNotesData,
    tables: {
      projectNotes: { sortModel, search, paginationModel },
    },
  } = useNotes();

  const note = useMemo(
    () => projectNotesData?.items?.find(item => item.id === noteId),
    [noteId, projectNotesData?.items]
  );

  const formik = useFormik({
    initialValues: {
      note: note?.text || '',
    },
    validationSchema: Yup.object().shape({
      note: Yup.string()
        .max(2500, 'Note must be at most 2,500 characters')
        .required('Note is required.')
        .trim()
        .matches(/\S/, 'Note should not contain only spaces.'),
    }),
    onSubmit: (values, { setStatus, setSubmitting, setErrors, resetForm }) => {
      if (currentAccount?.accountId && projectExpanded?.id) {
        const requestData = {
          id: currentAccount.accountId,
          projectId: projectExpanded?.id,
          noteId: noteId,
          text: values.note,
          successFn: () => {
            resetForm();
            handleClose();
            onDone && onDone();
            onGetProjectNotes({
              id: currentAccount.accountId,
              projectId: projectExpanded.id as number,
              page: paginationModel.page,
              limit: paginationModel.pageSize,
              search: search || DEFAULT_GET_USERS_PARAMS.search,
              sortBy: sortModel[0]?.field,
              sortOrder: sortModel[0]?.sort,
            });
          },
          errorFn: (error: any) => {
            setStatus({ success: false });
            setSubmitting(false);
            setErrors(error);
          },
        };

        onEditNote({ ...requestData });
      }
    },
  });

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  useEffect(() => {
    if (open && currentAccount?.accountId) {
      onGetProjectsListForInvite({
        accountId: currentAccount.accountId,
        search: '',
      });
    }
  }, [currentAccount.accountId, onGetProjectsListForInvite, open]);

  return (
    <Modal
      aria-labelledby='EditNoteModal'
      aria-describedby='EditNoteModal'
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <ModalWrapper>
          <ModalContainer container xs={12} sx={{ gap: '8px' }}>
            <Grid xs={12}>
              <Grid item xs={12} sx={{ position: 'relative' }}>
                <CloseDeletePopoverOne onClick={handleClose}>
                  <CloseIcon fontSize='small' />
                </CloseDeletePopoverOne>
                <Typography
                  fontSize='18px'
                  fontWeight='600'
                  lineHeight='28px'
                  color='#101828'
                >
                  Edit Note
                </Typography>
              </Grid>
              <Grid xs={12} padding={'20px 0'}>
                <InputLabel shrink htmlFor='note'>
                  Note
                </InputLabel>
                <OutlinedInput
                  multiline
                  rows={4}
                  fullWidth
                  id='note'
                  size='small'
                  type='text'
                  name={`note`}
                  placeholder='Enter a description...'
                  value={formik.values.note}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={!!(formik.touched.note && formik.errors?.note)}
                />
                {formik.touched.note && formik.errors?.note && (
                  <FormHelperText error id='note'>
                    <CustomFormHelperText error={formik.errors?.note} />
                  </FormHelperText>
                )}
              </Grid>
              <ButtonsWrapper container xs={12} sx={{ paddingTop: '32px' }}>
                <Grid item xs={extraSmallScreen && 12}>
                  <GrayButtonSmall
                    fullWidth={extraSmallScreen}
                    size='small'
                    outline
                    onClick={handleClose}
                  >
                    Cancel
                  </GrayButtonSmall>
                </Grid>
                <Grid item xs={extraSmallScreen && 12}>
                  <PrimaryButtonSmall
                    fullWidth={extraSmallScreen}
                    variant='contained'
                    size='small'
                    type='submit'
                    disabled={formik.isSubmitting || !formik.isValid}
                    onClick={() => formik.handleSubmit()}
                  >
                    Done
                  </PrimaryButtonSmall>
                </Grid>
              </ButtonsWrapper>
            </Grid>
          </ModalContainer>
        </ModalWrapper>
      </Fade>
    </Modal>
  );
};

export default EditNoteModal;
