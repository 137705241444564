import React, { useState } from 'react';
import { KeywordTrendsItem } from '../../../../../../types';
import { useMediaQuery } from '@mui/material';
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import dayjs from 'dayjs';
import * as Tooltips from './CustomTooltipsForGraph';

interface KeywordTrendsBarChartProps {
  getCheckedData: KeywordTrendsItem[];
}

const KeywordTrendsBarChart: React.FC<KeywordTrendsBarChartProps> = ({
  getCheckedData,
}) => {
  const lg = useMediaQuery('(min-width:900px)');
  const md = useMediaQuery('(min-width:900px)');

  const [startIndex, setStartIndex] = useState<number | undefined>(
    getCheckedData.length ? Math.max(0, getCheckedData.length - 24) : undefined
  );
  const [endIndex, setEndIndex] = useState<number | undefined>(
    getCheckedData.length ? getCheckedData.length - 1 : undefined
  );

  const handleBrushChange = (data: {
    endIndex?: number;
    startIndex?: number;
  }) => {
    setEndIndex(data.endIndex);
    setStartIndex(data.startIndex);
  };

  return (
    <ResponsiveContainer width={'100%'} height={311}>
      <BarChart data={getCheckedData}>
        <CartesianGrid vertical={false} stroke='#EDEDED' strokeWidth={2} />
        <XAxis
          interval={'equidistantPreserveStart'}
          axisLine={false}
          tickLine={false}
          dataKey='date'
          stroke='#D8DAE5'
          dy={5}
          tickFormatter={value => {
            return dayjs(value).format('D MMM');
          }}
        />
        <YAxis
          allowDecimals={false}
          axisLine={false}
          tickLine={false}
          stroke='#D8DAE5'
          orientation='right'
        >
          <Label
            value='Total Keywords'
            angle={90}
            position={'insideRight'}
            style={{
              color: '#84818A',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '15px',
            }}
          />
        </YAxis>
        <Tooltip
          cursor={{ fill: '#F6F6F6' }}
          content={<Tooltips.KeywordTrends />}
        />
        <Bar
          dataKey='notRanked'
          stackId='a'
          fill='#AEA5E0'
          barSize={lg ? 22 : md ? 14 : 8}
        />
        <Bar
          dataKey='fiftyOneToOneHundred'
          stackId='a'
          fill='#1E406B'
          barSize={lg ? 22 : md ? 14 : 8}
        />
        <Bar
          dataKey='fromTwentyOneToFifty'
          stackId='a'
          fill='#D160D2'
          barSize={lg ? 22 : md ? 14 : 8}
        />
        <Bar
          dataKey='fromElevenToTwenty'
          stackId='a'
          fill='#FFAB44'
          barSize={lg ? 22 : md ? 14 : 8}
        />
        <Bar
          dataKey='fromFourToTen'
          stackId='a'
          fill='#58B0F1'
          barSize={lg ? 22 : md ? 14 : 8}
        />
        <Bar
          dataKey='top3'
          stackId='a'
          fill='#AAE247'
          barSize={lg ? 22 : md ? 14 : 8}
        />
        {getCheckedData.length > 24 ? (
          <Brush
            dataKey='date'
            height={40}
            stroke='#126FE9'
            startIndex={startIndex}
            endIndex={endIndex}
            travellerWidth={12}
            onChange={handleBrushChange}
            tickFormatter={() => ''}
          />
        ) : null}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default React.memo(KeywordTrendsBarChart);
