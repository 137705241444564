import React from 'react';

import { Header, Content } from './styles';

const Date = () => (
  <div>
    <Header>Date</Header>
    <Content>When the note was created.</Content>
  </div>
);

const Author = () => (
  <div>
    <Header>Author</Header>
    <Content>Email of the person who wrote the note.</Content>
  </div>
);

const Note = () => (
  <div>
    <Header>Note</Header>
    <Content>A brief description or comment related to the project.</Content>
  </div>
);

export { Date, Author, Note };
