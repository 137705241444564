import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import {
  getProjectsAPI,
  getCheckFrequencyAPI,
  getLanguagesAPI,
  getGoogleDomainsAPI,
  getDeviceTypesAPI,
  getAccountTagsAPI,
  newProjectAPI,
  deleteOneProjectAPI,
  bulkDeleteProjectsAPI,
  editProjectAPI,
  addKeywordsAPI,
  getProjectExpandedAPI,
  getRefreshAllKeywordsAPI,
  changeFrequencyForProjectsAPI,
  addTagsAPI,
  getListAvailableProjectsAPI,
  getProjectsOverviewAPI,
  getProjectsImprovedVsDeclinedAPI,
  getKeywordPositionHistoryAPI,
  getKeywordRankingsPI,
  deleteKeywordsAPI,
  getProjectsKeywordTrendsAPI,
  getKeywordSearchResultsAPI,
  getKeywordInfoAPI,
  getKeywordPositionsInfoAPI,
  getProjectPerformanceAPI,
  moveProjectsToFolderAPI,
  moveFoldersToFolderAPI,
  updateKeywordPositionAPI,
  exportToCsvKeywordPositionsInfoAPI,
  searchLocationsAPI,
  refreshKeywordsAPI,
  getNumberOfAvailableKeywordsToUpdateAPI,
  getKeywordTagsAPI,
  getProjectsNumberOfAvailableKeywordsToUpdateAPI,
  geLocationsOfChinaAPI,
  newProjectForGoogleLocalAPI,
  newProjectForGoogleMapsAPI,
  newProjectForYoutubeAPI,
  newProjectForBingAPI,
  newProjectForYahooAPI,
  newProjectForBaiduAPI,
  getLanguagesBingAPI,
  getLanguagesYoutubeAPI,
  getLanguagesYahooAPI,
  getLanguagesBaiduAPI,
  searchLocationsBingAPI,
  searchLocationsYoutubeAPI,
  searchLocationsYahooAPI,
  searchLocationsBaiduAPI,
  downloadProjectInfoToCSVAPI,
} from '../api';
import {
  ProjectsState,
  GetProjectsParams,
  Language,
  CheckFrequency,
  GoogleDomain,
  IDeviceType,
  AccountTag,
  GetAccountTagsParams,
  NewProjectParams,
  DeleteOneProjectParams,
  BulkDeleteProjectsParams,
  EditProjectParams,
  AddKeywordsParams,
  GetProjectExpandedParams,
  ProjectExpanded,
  GetRefreshAllKeywordsParams,
  ChangeFrequencyForProjectsParams,
  AddTagsParams,
  GetListAvailableProjectsParams,
  GetListAvailableProjectsResponse,
  GetProjectsOverviewParams,
  ProjectOverview,
  ProjectImprovedVsDeclined,
  GetProjectsImprovedVsDeclinedParams,
  GetKeywordPositionHistoryParams,
  GetKeywordRankingParams,
  KeywordRankingState,
  RefreshKeywordsParams,
  GetProjectsKeywordTrendsParams,
  ProjectKeywordTrends,
  KeywordAnalytics,
  GetKeywordSearchResultsParams,
  KeywordSearchResultsState,
  MoveProjectsToFolderParams,
  MoveFoldersToFolderParams,
  UpdateKeywordPositionParams,
  ExportToCsvKeywordPositionsInfoParams,
  KeywordRanking,
  SearchLocationsParams,
  SearchLocationsResponse,
  GetNumberOfAvailableKeywordsToUpdateParams,
  GetKeywordPositionHistoryResponse,
  GetProjectsNumberOfAvailableKeywordsToUpdateParams,
  IDownloadProjectInfoToCSVParams,
} from '../types';
import {
  GetKeywordInfoParams,
  GetKeywordInfoResponse,
  GetKeywordPositionsInfoParams,
  GetKeywordPositionsInfoResponse,
} from '../types/keywordInfo';
import {
  GetProjectPerformanceParams,
  GetProjectPerformanceResponse,
} from '../types/projectPerformance';
import { enqueueSnackbar } from 'notistack';
import CustomToast from '../../../shared/CustomToast/CustomToast';
import { closeSnackbarAction } from '../../../utils/snackbar';
import React from 'react';
import {
  checkAndRedirectToMaintenancePage,
  convertErrorsForCompetitorsUrl,
  removeCompetitorUrlKeys,
} from '../../../utils';
import { downloadCsvFile } from '../utils';

export const getProjectsThunk = createAsyncThunk<
  ProjectsState,
  GetProjectsParams,
  { rejectValue: string }
>('get-projects', async (params, thunkAPI) => {
  try {
    const { data } = await getProjectsAPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getCheckFrequencyThunk = createAsyncThunk<
  CheckFrequency[],
  undefined,
  { rejectValue: string }
>('get-check-frequency', async (_, thunkAPI) => {
  try {
    const { data } = await getCheckFrequencyAPI();
    return data.items;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getLanguagesThunk = createAsyncThunk<
  Language[],
  undefined,
  { rejectValue: string }
>('get-languages', async (_, thunkAPI) => {
  try {
    const { data } = await getLanguagesAPI();
    return data.items;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getLanguagesBingThunk = createAsyncThunk<
  Language[],
  undefined,
  { rejectValue: string }
>('getLanguagesBingThunk', async (_, thunkAPI) => {
  try {
    const { data } = await getLanguagesBingAPI();
    return data.items;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getLanguagesYoutubeThunk = createAsyncThunk<
  Language[],
  undefined,
  { rejectValue: string }
>('getLanguagesYoutubeThunk', async (_, thunkAPI) => {
  try {
    const { data } = await getLanguagesYoutubeAPI();
    return data.items;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getLanguagesYahooThunk = createAsyncThunk<
  Language[],
  undefined,
  { rejectValue: string }
>('getLanguagesYahooThunk', async (_, thunkAPI) => {
  try {
    const { data } = await getLanguagesYahooAPI();
    return data.items;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getLanguagesBaiduThunk = createAsyncThunk<
  Language[],
  undefined,
  { rejectValue: string }
>('getLanguagesBaiduThunk', async (_, thunkAPI) => {
  try {
    const { data } = await getLanguagesBaiduAPI();
    return data.items;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getGoogleDomainsThunk = createAsyncThunk<
  GoogleDomain[],
  undefined,
  { rejectValue: string }
>('get-google-domains', async (_, thunkAPI) => {
  try {
    const { data } = await getGoogleDomainsAPI();
    return data.items.sort((a, b) =>
      a.countryName.localeCompare(b.countryName)
    );
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getDeviceTypesThunk = createAsyncThunk<
  IDeviceType[],
  undefined,
  { rejectValue: string }
>('get-device-type', async (_, thunkAPI) => {
  try {
    const { data } = await getDeviceTypesAPI();
    return data.items;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getAccountTagsThunk = createAsyncThunk<
  AccountTag[],
  GetAccountTagsParams,
  { rejectValue: string }
>('get-account-tags', async (params, thunkAPI) => {
  try {
    const { data } = await getAccountTagsAPI(params);
    return data.items;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const newProjectThunk = createAsyncThunk<
  undefined,
  NewProjectParams,
  { rejectValue: string }
>('create-new-project', async (params, thunkAPI) => {
  try {
    const { data } = await newProjectAPI(params);

    params.successFn(data.keywordUpdateWasSkipped);
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        params.errorFn(errors);
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const newProjectForGoogleLocalThunk = createAsyncThunk<
  undefined,
  NewProjectParams,
  { rejectValue: string }
>('newProjectForGoogleLocalThunk', async (params, thunkAPI) => {
  try {
    const { data } = await newProjectForGoogleLocalAPI(params);

    params.successFn(data.keywordUpdateWasSkipped);
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        const competitorErrors = convertErrorsForCompetitorsUrl(errors);
        if (competitorErrors.length) {
          const errorObj = {
            ...removeCompetitorUrlKeys(errors),
            competitors: competitorErrors,
          };
          params.errorFn(errorObj);
        } else {
          params.errorFn(errors);
        }
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const newProjectForGoogleMapsThunk = createAsyncThunk<
  undefined,
  NewProjectParams,
  { rejectValue: string }
>('newProjectForGoogleMapsThunk', async (params, thunkAPI) => {
  try {
    const { data } = await newProjectForGoogleMapsAPI(params);

    params.successFn(data.keywordUpdateWasSkipped);
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        const competitorErrors = convertErrorsForCompetitorsUrl(errors);
        if (competitorErrors.length) {
          const errorObj = {
            ...removeCompetitorUrlKeys(errors),
            competitors: competitorErrors,
          };
          params.errorFn(errorObj);
        } else {
          params.errorFn(errors);
        }
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const newProjectForYoutubeThunk = createAsyncThunk<
  undefined,
  NewProjectParams,
  { rejectValue: string }
>('newProjectForYoutubeThunk', async (params, thunkAPI) => {
  try {
    const { data } = await newProjectForYoutubeAPI(params);

    params.successFn(data.keywordUpdateWasSkipped);
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        params.errorFn(errors);
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const newProjectForBingThunk = createAsyncThunk<
  undefined,
  NewProjectParams,
  { rejectValue: string }
>('newProjectForBingThunk', async (params, thunkAPI) => {
  try {
    const { data } = await newProjectForBingAPI(params);

    params.successFn(data.keywordUpdateWasSkipped);
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        params.errorFn(errors);
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const newProjectForYahooThunk = createAsyncThunk<
  undefined,
  NewProjectParams,
  { rejectValue: string }
>('newProjectForYahooThunk', async (params, thunkAPI) => {
  try {
    const { data } = await newProjectForYahooAPI(params);

    params.successFn(data.keywordUpdateWasSkipped);
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        params.errorFn(errors);
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const newProjectForBaiduThunk = createAsyncThunk<
  undefined,
  NewProjectParams,
  { rejectValue: string }
>('newProjectForBaiduThunk', async (params, thunkAPI) => {
  try {
    const { data } = await newProjectForBaiduAPI(params);

    params.successFn(data.keywordUpdateWasSkipped);
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        params.errorFn(errors);
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const deleteOneProjectThunk = createAsyncThunk<
  undefined,
  DeleteOneProjectParams,
  { rejectValue: string }
>('delete-one-project', async (params, thunkAPI) => {
  try {
    await deleteOneProjectAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const bulkDeleteProjectsThunk = createAsyncThunk<
  undefined,
  BulkDeleteProjectsParams,
  { rejectValue: string }
>('bulk-delete-projects', async (params, thunkAPI) => {
  try {
    await bulkDeleteProjectsAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const onGetCurrentProjectThunk = createAsyncThunk<
  ProjectExpanded,
  GetProjectExpandedParams,
  { rejectValue: string }
>('get-current-project', async (params, thunkAPI) => {
  try {
    const { data } = await getProjectExpandedAPI(params);
    params?.successFn && params.successFn();
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const editProjectThunk = createAsyncThunk<
  undefined,
  EditProjectParams,
  { rejectValue: string }
>('edit-project', async (params, thunkAPI) => {
  try {
    const { data } = await editProjectAPI(params);

    if (data.duplicateKeywordsWereMissed) {
      enqueueSnackbar(
        <CustomToast
          message='Info'
          submessage='Some duplicate keywords have been removed'
        />,
        { action: closeSnackbarAction, variant: 'warning' }
      );
    }
    params.successFn(data.keywordUpdateWasSkipped);
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        params.errorFn(errors);
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const addKeywordsThunk = createAsyncThunk<
  undefined,
  AddKeywordsParams,
  { rejectValue: string }
>('add-keywords', async (params, thunkAPI) => {
  try {
    const { data } = await addKeywordsAPI(params);

    if (data.duplicateKeywordsWereMissed) {
      enqueueSnackbar(
        <CustomToast
          message=''
          submessage='Some duplicate keywords have been removed'
        />,
        { action: closeSnackbarAction }
      );
    }
    params.successFn(data.keywordUpdateWasSkipped);
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        params.errorFn(errors);
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

interface IGetProjectExpandedThunk {
  data: ProjectExpanded;
  deviceType?: string;
}

export const getProjectExpandedThunk = createAsyncThunk<
  IGetProjectExpandedThunk,
  GetProjectExpandedParams,
  { rejectValue: string }
>('get-project-expanded', async (params, thunkAPI) => {
  try {
    const { data } = await getProjectExpandedAPI(params);
    return { data, deviceType: params.keywordDeviceType };
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const refreshAllKeywordsThunk = createAsyncThunk<
  undefined,
  GetRefreshAllKeywordsParams,
  { rejectValue: string }
>('refresh-all-keywords', async (params, thunkAPI) => {
  try {
    await getRefreshAllKeywordsAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const changeFrequencyForProjectsThunk = createAsyncThunk<
  undefined,
  ChangeFrequencyForProjectsParams,
  { rejectValue: string }
>('change-frequency-for-projects', async (params, thunkAPI) => {
  try {
    await changeFrequencyForProjectsAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const addTagsThunk = createAsyncThunk<
  undefined,
  AddTagsParams,
  { rejectValue: string }
>('add-tags', async (params, thunkAPI) => {
  try {
    await addTagsAPI(params);

    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        params.errorFn(errors);
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getListAvailableProjectsThunk = createAsyncThunk<
  GetListAvailableProjectsResponse,
  GetListAvailableProjectsParams,
  { rejectValue: string }
>('get-list-available-projects', async (params, thunkAPI) => {
  try {
    const { data } = await getListAvailableProjectsAPI(params);
    params.successFn && params.successFn();

    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    params.successFn && params.successFn();
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getProjectsOverviewThunk = createAsyncThunk<
  ProjectOverview,
  GetProjectsOverviewParams,
  { rejectValue: string }
>('get-projects-overview', async (params, thunkAPI) => {
  try {
    const { data } = await getProjectsOverviewAPI(params);

    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getProjectsImprovedVsDeclinedThunk = createAsyncThunk<
  ProjectImprovedVsDeclined,
  GetProjectsImprovedVsDeclinedParams,
  { rejectValue: string }
>('get-projects-improved-vs-declined', async (params, thunkAPI) => {
  try {
    const { data } = await getProjectsImprovedVsDeclinedAPI(params);

    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getKeywordRankingsThunk = createAsyncThunk<
  KeywordRankingState,
  GetKeywordRankingParams,
  { rejectValue: string }
>('get-keyword-rankings', async (params, thunkAPI) => {
  try {
    const { data } = await getKeywordRankingsPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getKeywordPositionHistoryThunk = createAsyncThunk<
  KeywordAnalytics,
  GetKeywordPositionHistoryParams,
  { rejectValue: string }
>('get-keyword-position-history', async (params, thunkAPI) => {
  try {
    const { data } = await getKeywordPositionHistoryAPI(params);

    return {
      id: params.keywordId,
      keywordPositionHistory: data.positionHistory,
      showCompetitors: data.historyOfCompetitorPositions.map(item => item.id),
      competitors: data.historyOfCompetitorPositions,
      activePeriod: {
        label: '1m',
        value: 'Month',
      },
      isLinearGraph: true,
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const refreshKeywordsThunk = createAsyncThunk<
  undefined,
  RefreshKeywordsParams,
  { rejectValue: string }
>('refresh-selected-keywords', async (params, thunkAPI) => {
  try {
    await refreshKeywordsAPI(params);
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const deleteKeywordsThunk = createAsyncThunk<
  undefined,
  RefreshKeywordsParams,
  { rejectValue: string }
>('delete-keywords', async (params, thunkAPI) => {
  try {
    await deleteKeywordsAPI(params);

    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getProjectsKeywordTrendsThunk = createAsyncThunk<
  ProjectKeywordTrends,
  GetProjectsKeywordTrendsParams,
  { rejectValue: string }
>('getProjectsKeywordTrends', async (params, thunkAPI) => {
  try {
    const { data } = await getProjectsKeywordTrendsAPI(params);

    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getKeywordSearchResultsThunk = createAsyncThunk<
  { id: number; state: KeywordSearchResultsState },
  GetKeywordSearchResultsParams,
  { rejectValue: string }
>('getKeywordSearchResults', async (params, thunkAPI) => {
  try {
    const { data } = await getKeywordSearchResultsAPI(params);

    return { state: data, id: params.keywordId };
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getKeywordInfoThunk = createAsyncThunk<
  GetKeywordInfoResponse,
  GetKeywordInfoParams,
  { rejectValue: string }
>('getKeywordInfoThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getKeywordInfoAPI(params);

    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getSingleKeywordPositionHistoryThunk = createAsyncThunk<
  GetKeywordPositionHistoryResponse,
  GetKeywordPositionHistoryParams,
  { rejectValue: string }
>('getSingleKeywordPositionHistory', async (params, thunkAPI) => {
  try {
    const { data } = await getKeywordPositionHistoryAPI(params);

    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getKeywordPositionsInfoThunk = createAsyncThunk<
  GetKeywordPositionsInfoResponse,
  GetKeywordPositionsInfoParams,
  { rejectValue: string }
>('getKeywordPositionsInfo', async (params, thunkAPI) => {
  try {
    const { data } = await getKeywordPositionsInfoAPI(params);

    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getProjectPerformanceThunk = createAsyncThunk<
  GetProjectPerformanceResponse,
  GetProjectPerformanceParams
>('getProjectPerformanceThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getProjectPerformanceAPI(params);

    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const moveProjectsToFolderThunk = createAsyncThunk<
  undefined,
  MoveProjectsToFolderParams
>('moveProjectsToFolderThunk', async (params, thunkAPI) => {
  try {
    await moveProjectsToFolderAPI(params);

    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const moveFoldersToFolderThunk = createAsyncThunk<
  undefined,
  MoveFoldersToFolderParams
>('moveFoldersToFolderThunk', async (params, thunkAPI) => {
  try {
    await moveFoldersToFolderAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      if (
        error?.response?.data?.message ===
        'A folder with that name is already in this folder.'
      ) {
        params.errorFn(error.response.data.message);
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const updateKeywordPositionThunk = createAsyncThunk<
  KeywordRanking,
  UpdateKeywordPositionParams
>('updateKeywordPositionThunk', async (params, thunkAPI) => {
  try {
    const { data } = await updateKeywordPositionAPI(params);
    params.successFn();
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const exportToCsvKeywordPositionsInfoThunk = createAsyncThunk<
  undefined,
  ExportToCsvKeywordPositionsInfoParams
>('exportToCsvKeywordPositionsInfoThunk', async (params, thunkAPI) => {
  try {
    const response = await exportToCsvKeywordPositionsInfoAPI(params);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const disposition = response.headers.get('Content-Disposition');
    let filename = 'KeywordPositions.csv';

    if (disposition && disposition.includes('filename=')) {
      const filenameMatch = disposition.match(/filename="(.+)"/);
      if (filenameMatch?.[1]) {
        filename = filenameMatch[1];
      }
    }

    downloadCsvFile(response.data, filename);
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const searchLocationsThunk = createAsyncThunk<
  SearchLocationsResponse,
  SearchLocationsParams
>('searchLocationsThunk', async (params, thunkAPI) => {
  try {
    const { data } = await searchLocationsAPI(params);

    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getNumberOfAvailableKeywordsToUpdateThunk = createAsyncThunk<
  undefined,
  GetNumberOfAvailableKeywordsToUpdateParams
>('getNumberOfAvailableKeywordsToUpdateThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getNumberOfAvailableKeywordsToUpdateAPI(params);
    params.successFn(data.keywordCount);
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getKeywordTagsThunk = createAsyncThunk<
  AccountTag[],
  GetAccountTagsParams,
  { rejectValue: string }
>('get-keyword-tags', async (params, thunkAPI) => {
  try {
    const { data } = await getKeywordTagsAPI(params);
    return data.items;
  } catch (error) {
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getProjectsNumberOfAvailableKeywordsToUpdateThunk =
  createAsyncThunk<
    undefined,
    GetProjectsNumberOfAvailableKeywordsToUpdateParams
  >(
    'getProjectsNumberOfAvailableKeywordsToUpdateThunk',
    async (params, thunkAPI) => {
      try {
        const { data } = await getProjectsNumberOfAvailableKeywordsToUpdateAPI(
          params
        );
        params.successFn(data.keywordCount);
      } catch (error) {
        return thunkAPI.rejectWithValue('Server error!');
      }
    }
  );

export const geLocationsOfChinaThunk = createAsyncThunk<
  SearchLocationsResponse,
  SearchLocationsParams
>('geLocationsOfChinaThunk', async (params, thunkAPI) => {
  try {
    const { data } = await geLocationsOfChinaAPI(params);

    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const geLocationsBingThunk = createAsyncThunk<
  SearchLocationsResponse,
  SearchLocationsParams
>('geLocationsBingThunk', async (params, thunkAPI) => {
  try {
    const { data } = await searchLocationsBingAPI(params);

    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const geLocationsYoutubeThunk = createAsyncThunk<
  SearchLocationsResponse,
  SearchLocationsParams
>('geLocationsYoutubeThunk', async (params, thunkAPI) => {
  try {
    const { data } = await searchLocationsYoutubeAPI(params);

    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const geLocationsYahooThunk = createAsyncThunk<
  SearchLocationsResponse,
  SearchLocationsParams
>('geLocationsYahooThunk', async (params, thunkAPI) => {
  try {
    const { data } = await searchLocationsYahooAPI(params);

    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const geLocationsBaiduThunk = createAsyncThunk<
  SearchLocationsResponse,
  SearchLocationsParams
>('geLocationsBaiduThunk', async (params, thunkAPI) => {
  try {
    const { data } = await searchLocationsBaiduAPI(params);

    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const downloadProjectInfoToCSVThunk = createAsyncThunk<
  undefined,
  IDownloadProjectInfoToCSVParams
>('downloadProjectInfoToCSVThunk', async (params, thunkAPI) => {
  try {
    const response = await downloadProjectInfoToCSVAPI(params);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const disposition = response.headers.get('Content-Disposition');
    let filename = 'serpnest_export.csv';

    if (disposition && disposition.includes('filename=')) {
      const filenameMatch = disposition.match(/filename="(.+)"/);
      if (filenameMatch?.[1]) {
        filename = filenameMatch[1];
      }
    }

    downloadCsvFile(response.data, filename);
  } catch (error) {
    return thunkAPI.rejectWithValue('Server error!');
  }
});
