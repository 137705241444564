import React from 'react';
import { PrimaryButtonCustom } from '../shared/buttons/styles';
import usePayment from '../features/payment/hooks/usePayment';

const TestPayPage = () => {
  const { paddle } = usePayment();

  const handleTestPay = () => {
    paddle.Checkout.open({
      items: [{ price_id: 'pri_01hcvnrc8f0h2va8400yqnerb6', quantity: 1 }],
      customer: { email: 'dmitriy.elementika@gmail.com' },
      settings: {
        allowLogout: false,
        showAddDiscounts: true,
        showAddTaxId: false,
      },
      customData: {
        id: 1,
        environment: 'development',
      },
    });
  };

  return (
    <div>
      <PrimaryButtonCustom
        variant='contained'
        size='small'
        $padding='12px 16px'
        onClick={handleTestPay}
      >
        Test Pay
      </PrimaryButtonCustom>
    </div>
  );
};

export default TestPayPage;
