import { Fade, Grid, Modal } from '@mui/material';
import useAccountLimits from '../../../features/auth/hooks/useAccountLimits';
import { useAppSelector } from '../../../store';
import React from 'react';
import {
  ModalContainer,
  ModalWrapper,
} from '../../../features/projects/components/Projects/components/ProjectRedactorModal/styles';
import {
  CloseDeletePopoverOne,
  HeaderContainer,
} from '../../../features/auth/components/Settings/components/Account/styles';
import CloseIcon from '@mui/icons-material/Close';
import AlertCircleIcon from '../../images/AlertCircleIcon';
import {
  StyledLabel,
  StyledMainText,
} from '../KeywordsLimitExceededModal/styles';
import { ButtonsWrapper } from '../../../features/auth/components/Settings/components/Team/components/customTableStyles';
import { PrimaryButtonSmall } from '../../buttons/styles';

const ActionRequiredCancelSubscriptionModal = () => {
  const actionRequiredCancelSubscription = useAppSelector(
    state =>
      state.auth.accountDeleteErrorModals.actionRequiredCancelSubscription
  );

  const { onSetActionRequiredCancelSubscriptionModal } = useAccountLimits();

  const onClose = () => {
    onSetActionRequiredCancelSubscriptionModal(false);
  };

  return (
    <Modal
      open={actionRequiredCancelSubscription.open}
      onClose={onClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={actionRequiredCancelSubscription.open}>
        <ModalWrapper>
          <ModalContainer>
            <HeaderContainer>
              <CloseDeletePopoverOne color='error' onClick={onClose}>
                <CloseIcon fontSize='small' />
              </CloseDeletePopoverOne>
              <AlertCircleIcon />
              <StyledLabel padding='24px 0'>
                Action Required: Cancel Subscription
              </StyledLabel>
            </HeaderContainer>

            <Grid item xs={12}>
              <StyledMainText>
                Please cancel your active subscription before deleting your
                account. This ensures that you are not billed in the future and
                that your account deletion proceeds smoothly.
              </StyledMainText>
            </Grid>
            <br />
            <Grid item xs={12}>
              <StyledMainText>
                Visit the Billing page to manage your subscription or contact
                support for assistance.
              </StyledMainText>
            </Grid>

            <ButtonsWrapper container xs={12}>
              <Grid item>
                <PrimaryButtonSmall
                  variant='contained'
                  size='small'
                  type='submit'
                  onClick={onClose}
                >
                  Got it
                </PrimaryButtonSmall>
              </Grid>
            </ButtonsWrapper>
          </ModalContainer>
        </ModalWrapper>
      </Fade>
    </Modal>
  );
};

export default ActionRequiredCancelSubscriptionModal;
