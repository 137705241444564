import { styled } from '@mui/system';
import { Grid } from '@mui/material';

const StylesActionRequiredToast = styled(Grid)`
  padding: 16px;
  background: #fee4e2;
  border-radius: 8px;
  color: #475467;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export { StylesActionRequiredToast };
