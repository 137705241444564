import { useAppDispatch, useAppSelector } from '../../../store';
import { useCallback, useMemo } from 'react';
import { setIsOpenSuccessPayModal, setPaddle } from '../redux/actions';
import {
  getTransactionToUpdatePaymentMethodThunk,
  getUpdateSubscriptionReviewThunk,
  reactivateSubscriptionThunk,
  subscriptionUpdateThunk,
  switchToAnnualPaymentThunk,
  unsubscriptionThunk,
} from '../redux/thunk';
import {
  GetTransactionToUpdatePaymentMethodParams,
  GetUpdateSubscriptionReviewParams,
  ReactivateSubscriptionParams,
  SubscriptionUpdateParams,
  SwitchToAnnualPaymentParams,
  UnsubscriptionParams,
} from '../types';

export default function usePayment() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(state => state.payment);

  const memoizedState = useMemo(() => ({ ...state }), [state]);

  const onSetPayment = useCallback(
    (payment: any) => {
      dispatch(setPaddle(payment));
    },
    [dispatch]
  );

  const onSetIsOpenSuccessPayModal = useCallback(
    (isOpen: boolean) => {
      dispatch(setIsOpenSuccessPayModal(isOpen));
    },
    [dispatch]
  );

  const onGetTransactionToUpdatePaymentMethod = useCallback(
    (params: GetTransactionToUpdatePaymentMethodParams) => {
      dispatch(getTransactionToUpdatePaymentMethodThunk(params));
    },

    [dispatch]
  );

  const onSwitchToAnnualPayment = useCallback(
    (params: SwitchToAnnualPaymentParams) => {
      dispatch(switchToAnnualPaymentThunk(params));
    },
    [dispatch]
  );

  const onUnsubscription = useCallback(
    (params: UnsubscriptionParams) => {
      dispatch(unsubscriptionThunk(params));
    },
    [dispatch]
  );

  const onReactivateSubscription = useCallback(
    (params: ReactivateSubscriptionParams) => {
      dispatch(reactivateSubscriptionThunk(params));
    },
    [dispatch]
  );

  const ongGetUpdateSubscriptionReviewThunkSubscription = useCallback(
    (params: GetUpdateSubscriptionReviewParams) => {
      dispatch(getUpdateSubscriptionReviewThunk(params));
    },
    [dispatch]
  );

  const onSubscriptionUpdate = useCallback(
    (params: SubscriptionUpdateParams) => {
      dispatch(subscriptionUpdateThunk(params));
    },
    [dispatch]
  );

  return {
    ...memoizedState,
    onSetPayment,
    onSetIsOpenSuccessPayModal,
    onGetTransactionToUpdatePaymentMethod,
    onSwitchToAnnualPayment,
    onUnsubscription,
    onReactivateSubscription,
    ongGetUpdateSubscriptionReviewThunkSubscription,
    onSubscriptionUpdate,
  };
}
