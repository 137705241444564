import React from 'react';

import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';

import DownIcon from '../../../../../../../../../../shared/images/DownIcon';
import ListActionsEllipsis from '../EllipsisMenu/components/ListActions';
import ListActions from './components/ListActions';

import { PrimaryButtonCustom } from '../../../../../../../../../../shared/buttons/styles';
import { Popover } from '../../../../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import { useAppSelector } from '../../../../../../../../../../store';

const ActionsMenu = () => {
  const rowSelectionModel = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table
        .rowSelectionModel
  );

  return (
    <PopupState variant='popover'>
      {popupState => (
        <>
          <PrimaryButtonCustom
            $padding='8px 12px'
            variant='contained'
            size='small'
            endIcon={<DownIcon />}
            {...bindTrigger(popupState)}
          >
            Actions
          </PrimaryButtonCustom>

          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {rowSelectionModel.length !== 1 ? (
              <ListActions popupClose={popupState.close} />
            ) : (
              <ListActionsEllipsis
                keywordId={Number(rowSelectionModel[0])}
                popupClose={popupState.close}
              />
            )}
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export default ActionsMenu;
