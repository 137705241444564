import { styled } from '@mui/system';

const FormHelperTextErrorWrapper = styled('div')`
  display: flex;
  align-items: center;

  .MuiFormHelperText-root {
    margin-top: 2px;
    font-size: 14px;
  }
`;

const AccountDeletionScheduledBannerContainer = styled('div')`
  width: calc(100% - 8px);
  background: #fef0c7;
  border: 1px solid #fedf89;
  border-radius: 16px;
  margin-bottom: 16px;
  padding: 4px;
  font-size: 14px;
  line-height: 18px;
  color: #b54708;
  display: flex;
  align-items: center;

  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    width: calc(100% - 32px);
    padding: 16px;
  }
`;

const AccountDeletionScheduledBannerLabelContainer = styled('div')`
  text-align: center;
  background: #dc6803;
  border: 1px solid #fedf89;
  border-radius: 16px;
  padding: 4px 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: white;
  width: fit-content;
  margin-right: 8px;

  ${props => props.theme.breakpoints.down('sm')} {
    margin-right: 0;
    margin-bottom: 8px;
  }
`;

export {
  FormHelperTextErrorWrapper,
  AccountDeletionScheduledBannerContainer,
  AccountDeletionScheduledBannerLabelContainer,
};
