import React, { useEffect, useMemo } from 'react';
import {
  Autocomplete,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { AdvancedSettingsProps } from '../../../../types';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

import * as Tooltips from './Tooltips';
import HelperIcon from '../../../../../../shared/images/HelperIcon';
import { CustomFormHelperText } from '../../../../../../shared/components';
import DesktopIcon from '../../../../../../shared/images/DesctopIcon';
import PhoneIcon from '../../../../../../shared/images/PhoneIcon';
import DesktopAndMobileIcon from '../../../../../../shared/images/DesctopAndMobileIcon';
import TagIcon from '../../../../../../shared/images/TagIcon';
import AddTagsPopup from './AddTagsPopup';
import CloseIcon from '../../../../../../shared/images/CloseIcon';

import { CustomTooltip } from '../../../../../../shared/tooltip/styles';
import {
  DeviceTypeContainer,
  DeviceType,
  AddTagsContainer,
  AddedTag,
  AddTagsInput,
  ButtonTooltipContainer,
} from './styles';
import { Popover } from '../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import SelectIcon from '../../../../../../shared/components/SelectIcon';
import { useAppSelector } from '../../../../../../store';
import {
  ModalAutocompleteStylesProps,
  ModalOutlinedInput,
} from '../../../../../../shared/modal/styled';
import CompetitorsSection from './CompetitorsSection/CompetitorsSection';
import NotificationDeviceType from '../../../NotificationDeviceType/NotificationDeviceType';
import BusinessCompetitors from './Fields/BusinessCompetitors /BusinessCompetitors';
import VideoURLCompetitors from './Fields/VideoURLCompetitors/VideoURLCompetitors';
import { getCompetitorsErrors } from '../../../../utils';

const AdvancedSettings: React.FC<AdvancedSettingsProps> = ({
  showTooltip,
  openTooltip,
  onClickTooltip,
  onOpenTooltip,
  formik,
  isNew,
  isOpenAdvancedSettings,
}) => {
  const smallScreen = useMediaQuery('(max-width:500px)');

  const deviceTypes = useAppSelector(state => state.projects.deviceTypes);
  const checkFrequency = useAppSelector(state => state.projects.checkFrequency);
  const accountTags = useAppSelector(state => state.projects.accountTags);

  const languages = useAppSelector(state => state.projects.languages);
  const languagesBing = useAppSelector(state => state.projects.languagesBing);
  const languagesYoutube = useAppSelector(
    state => state.projects.languagesYoutube
  );
  const languagesYahoo = useAppSelector(state => state.projects.languagesYahoo);
  const languagesBaidu = useAppSelector(state => state.projects.languagesBaidu);

  const refTagsInput = React.useRef<HTMLInputElement>(null);
  const refCompetitorsInput = React.useRef<HTMLInputElement>(null);

  const filterDeviceTypes = useMemo(() => {
    if (
      formik.values.searchEngine === 'YouTube' ||
      formik.values.searchEngine === 'Google My Business' ||
      formik.values.searchEngine === 'Google Maps'
    ) {
      return deviceTypes.filter(item => item.name === 'Desktop');
    }
    return deviceTypes;
  }, [deviceTypes, formik.values.searchEngine]);

  const CompetitorsField = useMemo(() => {
    if (
      formik.values.searchEngine === 'Google My Business' ||
      formik.values.searchEngine === 'Google Maps'
    ) {
      return <BusinessCompetitors formik={formik} isNew={isNew} />;
    }

    if (formik.values.searchEngine === 'YouTube') {
      return <VideoURLCompetitors formik={formik} isNew={isNew} />;
    }

    return (
      <>
        <CompetitorsSection
          setFieldValue={formik.setFieldValue}
          refInput={refCompetitorsInput}
          competitors={formik.values.competitors}
          competitorIds={formik.values.competitorIds}
          isNew={isNew}
        />
        {formik.touched.competitors &&
          formik.errors.competitors &&
          getCompetitorsErrors(
            formik.errors.competitors,
            formik.values.searchEngine
          )}
      </>
    );
  }, [formik, isNew]);

  const defaultLanguages = useMemo(() => {
    switch (formik.values.searchEngine) {
      case 'Bing':
        return languagesBing;
      case 'YouTube':
        return languagesYoutube;
      case 'Yahoo':
        return languagesYahoo;
      case 'Baidu':
        return languagesBaidu;
      default:
        return languages;
    }
  }, [
    formik?.values?.searchEngine,
    languages,
    languagesBaidu,
    languagesBing,
    languagesYahoo,
    languagesYoutube,
  ]);

  useEffect(() => {
    if (defaultLanguages.length) {
      const language =
        defaultLanguages.find(x => x.name === 'English') ||
        defaultLanguages[0] ||
        '';
      formik.setFieldValue('languageId', language);
    }
  }, [defaultLanguages]);

  const getDeviceTypeIcon = (deviceName: string) => {
    switch (deviceName) {
      case 'Desktop':
        return <DesktopIcon />;
      case 'Mobile':
        return <PhoneIcon />;
      case 'DesktopAndMobile':
        return <DesktopAndMobileIcon />;
      default:
        return;
    }
  };

  if (!isOpenAdvancedSettings) {
    return null;
  }

  return (
    <>
      {isNew ? (
        <>
          <Grid item xs={12}>
            <Grid
              container
              fontWeight='500'
              alignItems='center'
              fontSize='14px'
            >
              <Grid item>Search Device Type</Grid>
              <Grid item>
                <CustomTooltip
                  title={<Tooltips.SearchDeviceType />}
                  placement='top-start'
                  arrow
                  open={showTooltip && openTooltip === 5}
                  onOpen={() => onOpenTooltip(5)}
                  onClose={() => onClickTooltip(5)}
                  onClick={() => onClickTooltip(5)}
                >
                  <ButtonTooltipContainer onClick={() => onClickTooltip(5)}>
                    <HelperIcon />
                  </ButtonTooltipContainer>
                </CustomTooltip>
              </Grid>
            </Grid>
            <DeviceTypeContainer>
              {filterDeviceTypes.map(item => (
                <DeviceType
                  key={item.name}
                  isSelected={formik.values.deviceType.name === item.name}
                  onClick={() => {
                    formik.setFieldValue('deviceType', item);
                  }}
                >
                  <>
                    {getDeviceTypeIcon(item.name)}
                    <Typography component='span'>
                      {item.name === 'DesktopAndMobile'
                        ? !smallScreen
                          ? 'Desktop & Mobile'
                          : 'Both'
                        : item.name}
                    </Typography>
                  </>
                </DeviceType>
              ))}
            </DeviceTypeContainer>
          </Grid>

          {formik.values.deviceType.name === 'DesktopAndMobile' ? (
            <Grid item xs={12}>
              <NotificationDeviceType />
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <Grid
              container
              fontWeight='500'
              alignItems='center'
              fontSize='14px'
            >
              <Grid item>Language</Grid>
              <Grid item>
                <CustomTooltip
                  title={<Tooltips.Language />}
                  placement='top-start'
                  arrow
                  open={showTooltip && openTooltip === 7}
                  onOpen={() => onOpenTooltip(7)}
                  onClose={() => onClickTooltip(7)}
                  onClick={() => onClickTooltip(7)}
                >
                  <ButtonTooltipContainer onClick={() => onClickTooltip(7)}>
                    <HelperIcon />
                  </ButtonTooltipContainer>
                </CustomTooltip>
              </Grid>
              <Autocomplete
                sx={ModalAutocompleteStylesProps}
                fullWidth
                size={'small'}
                id='languageId'
                options={defaultLanguages}
                getOptionLabel={option => `${option.code} (${option.name})`}
                value={
                  formik?.values?.languageId?.name
                    ? formik.values.languageId
                    : null
                }
                onChange={(_, value) =>
                  formik.setFieldValue('languageId', value || '')
                }
                onBlur={formik.handleBlur}
                popupIcon={<SelectIcon />}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder='Select language...'
                    name='languageId'
                    error={
                      !!(formik.touched.languageId && formik.errors.languageId)
                    }
                  />
                )}
              />
              {formik.touched.languageId && formik.errors.languageId?.name && (
                <FormHelperText error id='languageId'>
                  <CustomFormHelperText error={formik.errors.languageId.name} />
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </>
      ) : null}

      <Grid item xs={12}>
        <Grid container fontWeight='500' alignItems='center' fontSize='14px'>
          <Grid item>Check Frequency</Grid>
          <Grid item>
            <CustomTooltip
              title={<Tooltips.CheckFrequency />}
              placement='top-start'
              arrow
              open={showTooltip && openTooltip === 8}
              onOpen={() => onOpenTooltip(8)}
              onClose={() => onClickTooltip(8)}
              onClick={() => onClickTooltip(8)}
            >
              <ButtonTooltipContainer onClick={() => onClickTooltip(8)}>
                <HelperIcon />
              </ButtonTooltipContainer>
            </CustomTooltip>
          </Grid>
          <Autocomplete
            sx={ModalAutocompleteStylesProps}
            disableClearable
            fullWidth
            size={'small'}
            id='checkFrequency'
            options={checkFrequency}
            getOptionLabel={option => option.name}
            value={formik.values.checkFrequency}
            onChange={(_, value) =>
              formik.setFieldValue('checkFrequency', value || '')
            }
            onBlur={formik.handleBlur}
            popupIcon={<SelectIcon />}
            renderInput={params => (
              <TextField
                {...params}
                name='checkFrequency'
                error={
                  !!(
                    formik.touched.checkFrequency &&
                    formik.errors.checkFrequency
                  )
                }
              />
            )}
          />
          {formik.touched.checkFrequency &&
            formik.errors.checkFrequency?.name && (
              <FormHelperText error id='checkFrequency'>
                <CustomFormHelperText
                  error={formik.errors.checkFrequency.name}
                />
              </FormHelperText>
            )}
        </Grid>
      </Grid>

      {CompetitorsField}

      <Grid item xs={12} paddingTop='8px'>
        <Grid
          container
          fontWeight='500'
          alignItems='center'
          fontSize='14px'
          gap='8px'
        >
          <Grid item>Tags (Optional)</Grid>
          <PopupState variant='popover' popupId='add-tag-setting'>
            {popupState => (
              <>
                <AddTagsInput ref={refTagsInput} {...bindTrigger(popupState)}>
                  {formik.values.tags.length
                    ? formik.values.tags.map(item => (
                        <AddedTag key={item}>
                          <Typography component='span'>{item}</Typography>
                          <Typography
                            component='p'
                            sx={{ fontSize: '0' }}
                            onClick={event => {
                              event.stopPropagation();
                              event.preventDefault();
                              formik.setFieldValue(
                                'tags',
                                formik.values.tags.filter(
                                  filterItem => filterItem !== item
                                )
                              );
                            }}
                          >
                            <CloseIcon width='12' height='12' />
                          </Typography>
                        </AddedTag>
                      ))
                    : null}

                  {formik.values.tagIds.length
                    ? formik.values.tagIds.map(item => (
                        <AddedTag key={item.id}>
                          <Typography component='span'>{item.name}</Typography>
                          <Typography
                            component='p'
                            sx={{ fontSize: '0' }}
                            onClick={event => {
                              event.stopPropagation();
                              event.preventDefault();
                              formik.setFieldValue(
                                'tagIds',
                                formik.values.tagIds.filter(
                                  filterItem => filterItem.id !== item.id
                                )
                              );
                            }}
                          >
                            <CloseIcon width='12' height='12' />
                          </Typography>
                        </AddedTag>
                      ))
                    : null}

                  <AddTagsContainer>
                    <TagIcon />
                    <Typography component='span'>Add a Tag</Typography>
                  </AddTagsContainer>
                </AddTagsInput>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  sx={{
                    boxShadow:
                      '0px 12px 16px -4px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.06)',
                    borderRadius: '8px',
                    marginTop: '7px',
                  }}
                >
                  <AddTagsPopup
                    closePopover={popupState.close}
                    tags={formik.values.tags}
                    tagIds={formik.values.tagIds}
                    listTags={accountTags}
                    setFieldValue={formik.setFieldValue}
                    refTagsInput={refTagsInput}
                  />
                </Popover>
              </>
            )}
          </PopupState>
        </Grid>
      </Grid>

      <Grid item xs={12} paddingTop='8px'>
        <Grid
          container
          fontWeight='500'
          alignItems='center'
          fontSize='14px'
          gap='8px'
        >
          <Grid item>Notes (Optional)</Grid>
          <ModalOutlinedInput
            sx={{ padding: '8px 12px' }}
            multiline
            rows={4}
            fullWidth
            type={'tagline'}
            size={'small'}
            id='note'
            placeholder='Enter your notes here...'
            name='note'
            value={formik.values.note}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={!!formik.errors?.note}
          />
          {formik.touched.note && formik.errors?.note && (
            <FormHelperText error id='note'>
              <CustomFormHelperText error={formik.errors?.note} />
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AdvancedSettings;
