import { Grid, styled } from '@mui/material';

const StylesPriceContainer = styled(Grid)`
  border-radius: 16px;
  border: 1px solid #eaecf0;
  background: #fff;
  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  margin-bottom: 16px;
`;

const StylesPlanName = styled(Grid)`
  color: #475467;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
`;

const StylesPlanPrice = styled(Grid)`
  color: #101828;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.96px;
`;

const StylesPlanPerMonth = styled(Grid)`
  color: #475467;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

const StylesPlanDescription = styled(Grid)`
  color: #475467;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

const StylesPlanCheckItemText = styled(Grid)`
  color: #475467;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 16px 0;
  padding-left: 8px;
`;

export {
  StylesPlanName,
  StylesPlanPrice,
  StylesPlanPerMonth,
  StylesPlanDescription,
  StylesPriceContainer,
  StylesPlanCheckItemText,
};
