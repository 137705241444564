import { styled } from '@mui/system';
import { Grid, OutlinedInput } from '@mui/material';

const StyledOutlinedInput = styled(OutlinedInput)`
  padding: 7.5px 16px;
  border-radius: 0 !important;

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
`;

const StyledBlockTitle = styled(Grid)`
  color: #808080;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const StyledClearButton = styled(Grid)`
  color: #126fe9;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
`;

const StyledWrapContainer = styled(Grid)`
  padding: 12px 16px 8px 16px;
`;

export {
  StyledOutlinedInput,
  StyledBlockTitle,
  StyledClearButton,
  StyledWrapContainer,
};
