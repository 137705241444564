import React, { useMemo } from 'react';
import {
  Fade,
  FormHelperText,
  Grid,
  Modal,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { INewFolderModalProps } from './types';
import { enqueueSnackbar } from 'notistack';
import { closeSnackbarAction } from '../../../../../../../../utils/snackbar';
import { useAuth, useFolders } from '../../../../../../../../hooks';

import CustomToast from '../../../../../../../../shared/CustomToast/CustomToast';
import { CustomFormHelperText } from '../../../../../../../../shared/components';

import { ButtonsWrapper } from '../../../../../../../auth/components/Settings/components/Team/components/customTableStyles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../../../../shared/buttons/styles';
import {
  ModalContainer,
  ModalOutlinedInput,
  ModalWrapper,
} from '../../../../../../../../shared/modal/styled';
import { CloseDeletePopoverOne } from '../../../../../../../auth/components/Settings/components/Account/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from '../../../../../../../../store';

const NewFolderModal: React.FC<INewFolderModalProps> = ({
  open,
  handleClose,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const { onGetMyAccount } = useAuth();

  const folderTree = useAppSelector(state => state.auth.myAccount.folderTree);
  const myAccount = useAppSelector(state => state.auth.myAccount);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const {
    onCreateFolder,
    currentFolder,
    onGetFoldersById,
    onGetRootFolders,
    onGetAvailableFolders,
    isRootView,
    tables: {
      rootFolder: {
        paginationModel,
        search,
        sortModel,
        rootFolderColumnVisibilityModel,
        childrenFolderColumnVisibilityModel,
      },
    },
  } = useFolders();

  const columnVisibilityModel = useMemo(
    () =>
      isRootView
        ? rootFolderColumnVisibilityModel
        : childrenFolderColumnVisibilityModel,
    [
      childrenFolderColumnVisibilityModel,
      isRootView,
      rootFolderColumnVisibilityModel,
    ]
  );

  const selectedTags = useAppSelector(state => state.projects.selectedTags);

  const folderId = isRootView ? folderTree?.id : currentFolder?.id;

  const onClose = () => {
    handleClose();
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      folderName: '',
      message: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      folderName: Yup.string()
        .required('Folder Name is required.')
        .min(2, 'Folder name must be at least a 2 characters')
        .matches(/\S/, 'Folder name should not contain only spaces.'),
    }),

    onSubmit: (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
      const requestData = {
        name: values.folderName,
      };

      onCreateFolder({
        accountId: currentAccount.accountId,
        folderId: folderId,
        data: requestData,
        successFn: () => {
          resetForm();
          handleClose();
          isRootView
            ? onGetRootFolders({
                accountId: currentAccount.accountId,
                params: {
                  page: paginationModel.page,
                  limit: paginationModel.pageSize,
                  search: search,
                  tagIds: selectedTags.map(item => item.id).join(),
                  sortBy: sortModel[0].field,
                  sortOrder: sortModel[0].sort,
                },
              })
            : onGetFoldersById({
                accountId: currentAccount.accountId,
                folderId: currentFolder.id,
                params: {
                  page: paginationModel.page,
                  limit: paginationModel.pageSize,
                  search: search,
                  tagIds: selectedTags.map(item => item.id).join(),
                  dailyAverage: columnVisibilityModel.dailyAverage,
                  // sortBy: sortModel[0].field,
                  // sortOrder: sortModel[0].sort,
                },
              });
          onGetMyAccount(currentAccount.accountId);
          onGetAvailableFolders({ accountId: myAccount.id });

          enqueueSnackbar(
            <CustomToast
              message='Success'
              submessage='New folder created successfully.'
            />,
            { action: closeSnackbarAction }
          );
        },
        errorFn: (error: any) => {
          setStatus({ success: false });
          setSubmitting(false);
          setErrors(error);
        },
      });
    },
  });

  return (
    <Modal
      aria-labelledby='folder-modal-title'
      aria-describedby='folder-modal-description'
      open={open}
      onClose={onClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <form onSubmit={formik.handleSubmit}>
          <ModalWrapper>
            <ModalContainer container xs={12} sx={{ gap: '24px' }}>
              <Grid item xs={12} sx={{ position: 'relative' }}>
                <CloseDeletePopoverOne onClick={onClose}>
                  <CloseIcon fontSize='small' />
                </CloseDeletePopoverOne>
                <Typography
                  fontSize='18px'
                  fontWeight='600'
                  lineHeight='28px'
                  color='#101828'
                >
                  Create a New Folder
                </Typography>
              </Grid>

              <Grid item xs={12} paddingTop='4px'>
                <Grid
                  container
                  fontWeight='500'
                  alignItems='center'
                  fontSize='14px'
                  gap='6px'
                >
                  <Grid item>Folder Name</Grid>
                  <ModalOutlinedInput
                    size='small'
                    fullWidth
                    type='text'
                    id='folderName'
                    name='folderName'
                    placeholder='Enter folder name'
                    value={formik.values.folderName}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      !!(formik.touched.folderName && formik.errors.folderName)
                    }
                  />
                  {formik.touched.folderName && formik.errors.folderName && (
                    <FormHelperText error id='folderName'>
                      <CustomFormHelperText error={formik.errors.folderName} />
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>

              <ButtonsWrapper
                container
                xs={12}
                sx={{ paddingTop: '0 !important' }}
              >
                <Grid item xs={extraSmallScreen && 12}>
                  <GrayButtonSmall
                    fullWidth={extraSmallScreen}
                    size='small'
                    outline
                    onClick={handleClose}
                  >
                    Cancel
                  </GrayButtonSmall>
                </Grid>
                <Grid item xs={extraSmallScreen && 12}>
                  <PrimaryButtonSmall
                    fullWidth={extraSmallScreen}
                    variant='contained'
                    size='small'
                    type='submit'
                    disabled={
                      formik.isSubmitting || !formik.isValid || !folderId
                    }
                  >
                    Create
                  </PrimaryButtonSmall>
                </Grid>
              </ButtonsWrapper>
            </ModalContainer>
          </ModalWrapper>
        </form>
      </Fade>
    </Modal>
  );
};

export default NewFolderModal;
