import React from 'react';
import { StyledListItem } from '../../Projects/components/TableProjects/components/EllipsisMenu/styles';
import { StylesFoldersListItem } from '../styles';
import { Grid } from '@mui/material';
import { CustomTooltip } from '../../../../../shared/tooltip/styles';
import TextTooltip from '../../../../../shared/components/TextTooltip';
import GlobeIcon from '../../../../../shared/images/GlobeIcon';

interface ProjectsListItemProps {
  project: { id: number; projectName: string; favicon?: string };
}

const ProjectsListItem: React.FC<ProjectsListItemProps> = ({ project }) => {
  return (
    <StyledListItem>
      <StylesFoldersListItem container justifyContent={'space-between'}>
        <CustomTooltip
          title={
            <TextTooltip
              text={'This item cannot be selected as it is not a folder.'}
            />
          }
          placement='right-end'
        >
          <Grid item>
            <Grid container alignItems={'center'} gap={1}>
              {project?.favicon ? (
                <img
                  src={project.favicon}
                  width={16}
                  height={16}
                  alt={'Company Logo URL'}
                />
              ) : (
                <GlobeIcon />
              )}

              <Grid item color={'#98A2B3'}>
                {project.projectName}
              </Grid>
            </Grid>
          </Grid>
        </CustomTooltip>
      </StylesFoldersListItem>
    </StyledListItem>
  );
};

export default ProjectsListItem;
