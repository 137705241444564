import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Paper } from '../../../../../../shared/paper/styles';

const CompanyProfileContainer = styled(Grid)`
  border-bottom: 1px solid #eaecf0;
  justify-content: space-between;
  padding-top: 32px;
  padding-bottom: 32px;
  ${props => props.theme.breakpoints.down('sm')} {
    > div {
      :last-child {
        padding-top: 16px;
        justify-content: flex-start;
      }
    }
  }
`;

const FormItem = styled(Grid)`
  border-bottom: 1px solid #eaecf0;
  padding-top: 16px;
  padding-bottom: 16px;

  .MuiFormControlLabel-root {
    margin-right: 0;
    margin-left: 0;
    position: relative;
    bottom: 8px;
  }

  .MuiCheckbox-root {
    padding: 8px;
    //padding-right: 5px;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    > div {
      :last-child {
        padding-top: 16px;
      }
    }
  }
`;

const LogoUploadContainer = styled(Paper)`
  text-align: center;
  cursor: pointer;
`;

const BrandingFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-root {
    margin-right: 0;
  }
`;

const LinkStartAdornment = styled('div')`
  padding-left: 3px;
  padding-right: 18px;
  margin-right: 8px;
  position: relative;
  color: #475467;
  font-size: 14px;

  div {
    width: 100%;
    height: 40px;
    position: absolute;
    top: -8.5px;
    left: -5px;
    border-right: 1px solid #e1e1e1;
  }
`;

const ShowBrandingItem = styled(Grid)`
  padding-left: 6px !important;
`;

export {
  CompanyProfileContainer,
  FormItem,
  LogoUploadContainer,
  BrandingFormControlLabel,
  LinkStartAdornment,
  ShowBrandingItem,
};
