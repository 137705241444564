import React from 'react';

const UploadIcon: React.FC = () => {
  return (
    <svg
      width='47'
      height='46'
      viewBox='0 0 47 46'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='3.25977' y='3' width='40' height='40' rx='20' fill='#F2F4F7' />
      <path
        d='M19.9268 26.3333L23.2601 23M23.2601 23L26.5934 26.3333M23.2601 23V30.5M29.9268 26.9524C30.9447 26.1117 31.5934 24.8399 31.5934 23.4167C31.5934 20.8854 29.5414 18.8333 27.0101 18.8333C26.828 18.8333 26.6576 18.7383 26.5652 18.5814C25.4785 16.7374 23.4721 15.5 21.1768 15.5C17.725 15.5 14.9268 18.2982 14.9268 21.75C14.9268 23.4718 15.623 25.0309 16.7492 26.1613'
        stroke='#475467'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect
        x='3.25977'
        y='3'
        width='40'
        height='40'
        rx='20'
        stroke='#F9FAFB'
        strokeWidth='6'
      />
    </svg>
  );
};

export default UploadIcon;
