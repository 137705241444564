import { styled } from '@mui/system';
import { Typography } from '@mui/material';

const HeadTypography = styled(Typography)`
  color: #101828;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  padding-bottom: 12px;
`;

const DescriptionTypography = styled(Typography)`
  color: #475467;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export { HeadTypography, DescriptionTypography };
