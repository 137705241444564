import { useSharedLinks } from '../../../../../../hooks';
import React, { useCallback, useState } from 'react';
import { debounce, Grid, InputAdornment, OutlinedInput } from '@mui/material';
import { StyledRightTools } from '../../../../../notes/components/ProjectExpandedNotes/components/ProjectExpandedNotesTable/components/Header/styles';
import SearchIcon from '../../../../../../shared/images/SearchIcon';
import PopupTableSettings from '../PopupTableSettings/PopupTableSettings';
import { useAppSelector } from '../../../../../../store';

const Header = () => {
  const { onSearchSharedLinkContentsList } = useSharedLinks();

  const search = useAppSelector(
    state => state.sharedLinks.tables.sharedLinkContentsList.search
  );

  const [localSearch, setLocalSearch] = useState(search);

  const debounceSearch = useCallback(
    debounce(value => {
      onSearchSharedLinkContentsList(value);
    }, 500),
    [onSearchSharedLinkContentsList]
  );

  const handleSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLocalSearch(event.target.value);
    debounceSearch(event.target.value);
  };

  return (
    <Grid xs={12} padding={'24px'}>
      <Grid
        item
        container
        xs={12}
        flexDirection={'row'}
        justifyContent={'flex-end'}
      >
        <StyledRightTools
          container
          item
          alignItems='center'
          xs={12}
          spacing={2}
        >
          <Grid item>
            <OutlinedInput
              size='small'
              type='text'
              id='Search'
              name='Search'
              placeholder='Search'
              value={localSearch}
              onChange={handleSearch}
              startAdornment={
                <InputAdornment position='start' disablePointerEvents>
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Grid>

          <Grid item>
            <PopupTableSettings />
          </Grid>
        </StyledRightTools>
      </Grid>
    </Grid>
  );
};

export default Header;
