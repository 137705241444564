import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import {
  Circle,
  CircleContainer,
  CircleLabel,
  CirclesContainer,
} from '../../../../../ProjectPerformance/components/GraphLabels/styles';
import { CustomFormControlLabel } from '../../../../../KeywordTrends/components/Header/styled';
import { CustomCheckbox } from '../../../../../../../../../../shared/checkbox/styles';
import CustomCheckboxImage from '../../../../../../../../../../shared/images/CustomCheckbox';
import CheckedIcon from '../../../../../../../../../../shared/images/CheckedIcon';
import { COMPETITORS_COLORS } from '../../../../../../../../../../shared/layout/PrivateLayout/constant';
import { useProjects } from '../../../../../../../../../../hooks';
import { KeywordAnalytics } from '../../../../../../../../types';
import { useAppSelector } from '../../../../../../../../../../store';

interface GraphLabelsProps {
  keywordAnalytic: KeywordAnalytics;
}

const GraphLabels: React.FC<GraphLabelsProps> = ({ keywordAnalytic }) => {
  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );

  const { onSetShowCompetitorsForPositionHistory } = useProjects();

  const handleSetShowCompetitorsForPositionHistory = (
    competitorId: number,
    keywordAnalyticId: number,
    checked: boolean
  ) => {
    let showCompetitors = keywordAnalytic.showCompetitors;
    if (checked) {
      showCompetitors = [...showCompetitors, competitorId];
    } else {
      showCompetitors = showCompetitors.filter(item => item !== competitorId);
    }

    onSetShowCompetitorsForPositionHistory(showCompetitors, keywordAnalyticId);
  };

  const isBaseSearchEngine = useMemo(() => {
    return (
      projectExpanded &&
      projectExpanded.searchEngine.name !== 'Google My Business' &&
      projectExpanded.searchEngine.name !== 'Google Maps'
    );
  }, [projectExpanded?.searchEngine?.name]);

  return (
    <Grid>
      <CirclesContainer>
        <CircleContainer>
          <Circle backgroundColor={'#1A73E9'} />
          <CircleLabel>Position</CircleLabel>
        </CircleContainer>
      </CirclesContainer>

      {keywordAnalytic.competitors?.length ? (
        <Grid>
          {keywordAnalytic.competitors.map(competitor => {
            const label = isBaseSearchEngine
              ? competitor.domainName
              : `${competitor.businessName}${
                  competitor?.url ? ` (${competitor?.url})` : ''
                }`;
            return (
              <Grid key={competitor.id}>
                <CustomFormControlLabel
                  control={
                    <CustomCheckbox
                      checked={
                        !!keywordAnalytic.showCompetitors.find(
                          item => item === competitor.id
                        )
                      }
                      onChange={(event, checked) => {
                        handleSetShowCompetitorsForPositionHistory(
                          competitor.id,
                          keywordAnalytic.id,
                          checked
                        );
                      }}
                      icon={<CustomCheckboxImage />}
                      checkedIcon={
                        <CheckedIcon
                          checkedColor={
                            COMPETITORS_COLORS[
                              competitor.id % COMPETITORS_COLORS.length
                            ]
                          }
                        />
                      }
                    />
                  }
                  label={label}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : null}
    </Grid>
  );
};

export default GraphLabels;
