import { createAction } from '@reduxjs/toolkit';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import { IColumnVisibilityKeyEmailReports } from '../types';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';

/**
 * Action to set the search term for email reports.
 * @param {string} value - The search term.
 * @returns {Object} The action to set the search term.
 */
const setSearchEmailReports = createAction(
  'setSearchEmailReports',
  function prepare(value: string) {
    return {
      payload: value,
    };
  }
);

/**
 * Action to set the row selection model for email reports.
 * @param {GridRowSelectionModel} rowSelectionModel - The row selection model.
 * @returns {Object} The action to set the row selection model.
 */
const setRowSelectionModelEmailReports = createAction(
  'setRowSelectionModelEmailReports',
  function prepare(rowSelectionModel: GridRowSelectionModel) {
    return {
      payload: rowSelectionModel,
    };
  }
);

/**
 * Action to set the column visibility model for email reports.
 * @param {IColumnVisibilityKeyEmailReports} key - The column key.
 * @param {boolean} value - Whether the column is visible.
 * @returns {Object} The action to set the column visibility.
 */
const setColumnVisibilityModelEmailReports = createAction(
  'setColumnVisibilityModelEmailReports',
  function prepare(key: IColumnVisibilityKeyEmailReports, value: boolean) {
    return {
      payload: {
        key,
        value,
      },
    };
  }
);

/**
 * Action to reset the column visibility model for email reports.
 * @returns {Object} The action to reset the column visibility model.
 */
const resetColumnVisibilityModelEmailReports = createAction(
  'resetColumnVisibilityModelEmailReports'
);

/**
 * Action to set the sort model for email reports.
 * @param {GridSortModel} sortModel - The sort model.
 * @returns {Object} The action to set the sort model.
 */
const setSortModelEmailReports = createAction(
  'setSortModelEmailReports',
  function prepare(sortModel: GridSortModel) {
    return {
      payload: sortModel,
    };
  }
);

/**
 * Action to set the page size for email reports pagination.
 * @param {number} pageSize - The page size.
 * @returns {Object} The action to set the pagination page size.
 */
const setPaginationPageSizeEmailReports = createAction(
  'setPaginationPageSizeEmailReports',
  function prepare(pageSize: number) {
    return {
      payload: pageSize,
    };
  }
);

/**
 * Action to set the page number for email reports pagination.
 * @param {number} page - The page number.
 * @returns {Object} The action to set the pagination page.
 */
const setPaginationPageEmailReports = createAction(
  'setPaginationPageEmailReports',
  function prepare(page: number) {
    return {
      payload: page,
    };
  }
);

/**
 * Action to reset the state of email reports.
 * @returns {Object} The action to reset the email reports state.
 */
const resetEmailReportsState = createAction('resetEmailReportsState');

/**
 * Action to set the delete elements for the scheduled email reports table.
 * @param {GridRowSelectionModel} deleteElements - Elements to be deleted.
 * @returns {Object} The action to set the delete elements.
 */
const setDeleteElementsScheduledEmailReportsTable = createAction(
  'setDeleteElementsScheduledEmailReportsTable',
  function prepare(deleteElements: GridRowSelectionModel) {
    return {
      payload: { deleteElements },
    };
  }
);

export {
  setSearchEmailReports,
  setRowSelectionModelEmailReports,
  setColumnVisibilityModelEmailReports,
  resetColumnVisibilityModelEmailReports,
  setSortModelEmailReports,
  setPaginationPageSizeEmailReports,
  setPaginationPageEmailReports,
  resetEmailReportsState,
  setDeleteElementsScheduledEmailReportsTable,
};
