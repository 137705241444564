import React, { useEffect, useMemo } from 'react';
import { useSharedLinks } from '../../../../hooks';
import { useNavigate, useParams } from 'react-router';
import {
  clearStorage,
  getSharedLinkToken,
  STORAGE_KEYS,
} from '../../../../utils/localStorage';
import { Wrapper } from './styles';
import { Grid } from '@mui/material';
import {
  Header,
  ShortInfo,
  Overview,
  ImprovedVsDeclined,
  KeywordTrends,
  KeywordRankings,
} from './components';

import { ContentContainer } from '../../../projects/components/ProjectExpanded/components/styled';
import ProjectPerformance from './components/ProjectPerformance/ProjectPerformance';
import Footer from '../../../../shared/layout/Footer/Footer';
import ReportValidated from '../../../../shared/ReportValidated/ReportValidated';
import SharedCompanyInfo from '../../../../shared/SharedCompanyInfo/SharedCompanyInfo';
import ROUTES from '../../../../routes/constants';
import dayjs from 'dayjs';
import { decryptData } from '../../../../utils/cryptoJs';
import { useAppSelector } from '../../../../store';
import { getUpdatedSharedLinkPasswordToServer } from '../../socket';

const SharedProject = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();

  const decryptId = useMemo(() => {
    if (projectId) {
      return decryptData(projectId);
    }
  }, [projectId]);

  const {
    ongGetSharedLinkSettings,
    onGetSharedProjectPerformance,
    onGetSharedProjectKeywordTrends,
    onGetSharedProjectImprovedVsDeclined,
    onGetSharedProjectOverview,
    onGetSharedProject,
    onSetIsPasswordUpdated,
  } = useSharedLinks();

  const noChange = useAppSelector(
    state =>
      state.sharedLinks.projectGraphs.showImprovedVsDeclinedVisibilityModel
        .noChange
  );
  const lost = useAppSelector(
    state =>
      state.sharedLinks.projectGraphs.showImprovedVsDeclinedVisibilityModel.lost
  );
  const declined = useAppSelector(
    state =>
      state.sharedLinks.projectGraphs.showImprovedVsDeclinedVisibilityModel
        .declined
  );
  const improved = useAppSelector(
    state =>
      state.sharedLinks.projectGraphs.showImprovedVsDeclinedVisibilityModel
        .improved
  );
  const overview = useAppSelector(
    state => state.sharedLinks.projectGraphs.overview
  );
  const activePeriodProjectPerformance = useAppSelector(
    state => state.sharedLinks.projectGraphs.activePeriodProjectPerformance
  );
  const activePeriodKeywordTrends = useAppSelector(
    state => state.sharedLinks.projectGraphs.activePeriodKeywordTrends
  );
  const showKeywordTrendsVisibilityModel = useAppSelector(
    state => state.sharedLinks.projectGraphs.showKeywordTrendsVisibilityModel
  );
  const activePeriodImprovedVsDeclined = useAppSelector(
    state => state.sharedLinks.projectGraphs.activePeriodImprovedVsDeclined
  );
  const isShowImprovedVsDeclined = useAppSelector(
    state => state.sharedLinks.projectGraphs.isShowImprovedVsDeclined
  );
  const projectShared = useAppSelector(
    state => state.sharedLinks.projectShared
  );
  const projectSharedRangeDate = useAppSelector(
    state => state.sharedLinks.projectSharedRangeDate
  );
  const projectSharedDeviceType = useAppSelector(
    state => state.sharedLinks.projectSharedDeviceType
  );
  const isPasswordUpdated = useAppSelector(
    state => state.sharedLinks.isPasswordUpdated
  );

  useEffect(() => {
    const sharedLinkToken = getSharedLinkToken();
    if (decryptId && sharedLinkToken) {
      onGetSharedProject({
        link: sharedLinkToken,
        projectId: Number(decryptId),
        deviceType: projectSharedDeviceType,
      });
    }
  }, [
    onGetSharedProject,
    decryptId,
    projectSharedDeviceType,
    isPasswordUpdated,
  ]);

  useEffect(() => {
    const sharedLinkToken = getSharedLinkToken();
    if (
      projectShared?.id &&
      projectSharedDeviceType &&
      sharedLinkToken &&
      (!projectSharedRangeDate.startDate ||
        dayjs(projectSharedRangeDate.startDate).format('YYYY-MM-DD') !==
          dayjs(overview?.fromDate).format('YYYY-MM-DD'))
    ) {
      onGetSharedProjectOverview({
        link: sharedLinkToken,
        projectId: projectShared?.id,
        deviceType: projectSharedDeviceType,
        fromDate: projectSharedRangeDate.startDate
          ? dayjs(projectSharedRangeDate.startDate).format('YYYY-MM-DD')
          : undefined,
        toDate: projectSharedRangeDate.startDate
          ? dayjs(projectSharedRangeDate.endDate).format('YYYY-MM-DD')
          : undefined,
      });
    }
  }, [
    onGetSharedProjectOverview,
    overview?.fromDate,
    projectShared?.id,
    projectSharedDeviceType,
    projectSharedRangeDate.endDate?.toString(),
    projectSharedRangeDate.startDate?.toString(),
  ]);

  useEffect(() => {
    const sharedLinkToken = getSharedLinkToken();
    if (
      projectShared?.id &&
      projectSharedDeviceType &&
      sharedLinkToken &&
      projectSharedRangeDate.startDate
    ) {
      onGetSharedProjectOverview({
        link: sharedLinkToken,
        projectId: projectShared?.id,
        deviceType: projectSharedDeviceType,
        fromDate: projectSharedRangeDate.startDate
          ? dayjs(projectSharedRangeDate.startDate).format('YYYY-MM-DD')
          : undefined,
        toDate: projectSharedRangeDate.startDate
          ? dayjs(projectSharedRangeDate.endDate).format('YYYY-MM-DD')
          : undefined,
      });
    }
  }, [projectSharedDeviceType]);

  useEffect(() => {
    const sharedLinkToken = getSharedLinkToken();
    if (projectShared?.id && projectSharedDeviceType && sharedLinkToken) {
      onGetSharedProjectImprovedVsDeclined({
        link: sharedLinkToken,
        projectId: projectShared?.id,
        period: activePeriodImprovedVsDeclined.value,
        improvedFilter: improved,
        declinedFilter: declined,
        lostFilter: lost,
        noChange: noChange,
        deviceType: projectSharedDeviceType,
      });
    }
  }, [
    activePeriodImprovedVsDeclined.value,
    declined,
    improved,
    lost,
    noChange,
    onGetSharedProjectImprovedVsDeclined,
    onGetSharedProjectOverview,
    projectShared?.id,
    projectSharedDeviceType,
  ]);

  useEffect(() => {
    const sharedLinkToken = getSharedLinkToken();
    if (projectShared?.id && projectSharedDeviceType && sharedLinkToken) {
      onGetSharedProjectKeywordTrends({
        link: sharedLinkToken,
        projectId: projectShared?.id,
        deviceType: projectSharedDeviceType,
        top3Filter: showKeywordTrendsVisibilityModel['top3'],
        fromFourToTen: showKeywordTrendsVisibilityModel['top4-10'],
        fromElevenToTwenty: showKeywordTrendsVisibilityModel['top11-20'],
        fromTwentyOneToFifty: showKeywordTrendsVisibilityModel['top21-50'],
        fiftyOneToOneHundred: showKeywordTrendsVisibilityModel['top51-100'],
        notRanked: showKeywordTrendsVisibilityModel['notRanked'],
        period: activePeriodKeywordTrends.value,
      });
    }
  }, [
    activePeriodKeywordTrends.value,
    onGetSharedProjectKeywordTrends,
    projectShared?.id,
    projectSharedDeviceType,
    showKeywordTrendsVisibilityModel,
  ]);

  useEffect(() => {
    const sharedLinkToken = getSharedLinkToken();
    if (
      projectShared?.id &&
      projectSharedDeviceType &&
      sharedLinkToken &&
      projectShared
    ) {
      onGetSharedProjectPerformance({
        link: sharedLinkToken,
        projectId: projectShared?.id,
        period: activePeriodProjectPerformance.value,
        competitorIds: projectShared.competitors.map(item =>
          item.id.toString()
        ),
        deviceType: projectSharedDeviceType,
      });
    }
  }, [
    onGetSharedProjectPerformance,
    activePeriodProjectPerformance.value,
    projectShared?.id,
    projectShared,
    projectSharedDeviceType,
  ]);

  useEffect(() => {
    const sharedLinkToken = getSharedLinkToken();
    if (sharedLinkToken) {
      ongGetSharedLinkSettings({
        link: sharedLinkToken,
      });
    }
  }, [decryptId]);

  useEffect(() => {
    const sharedLinkToken = getSharedLinkToken();
    if (!projectId || !sharedLinkToken) {
      navigate(ROUTES.login);
    }

    getUpdatedSharedLinkPasswordToServer(data => {
      if (sharedLinkToken === data.sharedLink) {
        clearStorage(STORAGE_KEYS.sharedLinkAccessToken);
        onSetIsPasswordUpdated(true);
      }
    });
  }, []);

  return (
    <Wrapper>
      <Grid
        container
        xs={12}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'center'}
      >
        <ReportValidated />
        <SharedCompanyInfo />
        <Grid xs={11} sm={10} md={10} lg={10} overflow={'clip'}>
          <Header />
          <ShortInfo />
          <Overview />
          {isShowImprovedVsDeclined && (
            <ContentContainer>
              <ImprovedVsDeclined />
              <KeywordTrends />
            </ContentContainer>
          )}
          <KeywordRankings />
          <ProjectPerformance />
        </Grid>
        <Footer />
      </Grid>
    </Wrapper>
  );
};

export default SharedProject;
