import React, { useCallback, useEffect, useMemo } from 'react';
import { FormHelperText, Grid } from '@mui/material';
import { ModalOutlinedInput } from '../../../../../../../shared/modal/styled';
import { CustomFormHelperText } from '../../../../../../../shared/components';
import { FormikProps } from 'formik';
import { NewProjectValues } from '../../../../../types';
import { CustomTooltip } from '../../../../../../../shared/tooltip/styles';
import * as Tooltips from '../Tooltips';
import { ButtonTooltipContainer } from '../styles';
import HelperIcon from '../../../../../../../shared/images/HelperIcon';

interface BusinessURLFieldProps {
  formik: FormikProps<NewProjectValues>;
}

const BusinessURLField: React.FC<BusinessURLFieldProps> = ({ formik }) => {
  const TooltipTitle = useCallback(() => {
    if (formik.values.searchEngine === 'Google My Business') {
      return <Tooltips.BusinessURLGoogleLocal />;
    }
    if (formik.values.searchEngine === 'Google Maps') {
      return <Tooltips.BusinessURLGoogleMaps />;
    }
    return <div />;
  }, [formik.values.searchEngine]);

  const isRenderField = useMemo(() => {
    return (
      formik.values.searchEngine === 'Google My Business' ||
      formik.values.searchEngine === 'Google Maps'
    );
  }, [formik.values.searchEngine]);

  if (!isRenderField) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Grid container fontWeight='500' alignItems='center' fontSize='14px'>
        <Grid item>Business URL (Optional)</Grid>
        <Grid item>
          <CustomTooltip title={<TooltipTitle />} placement='top-start'>
            <ButtonTooltipContainer>
              <HelperIcon />
            </ButtonTooltipContainer>
          </CustomTooltip>
        </Grid>
        <ModalOutlinedInput
          size='small'
          fullWidth
          type='text'
          id='businessUrl'
          name='businessUrl'
          placeholder={'example.com'}
          value={formik.values.businessUrl}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={!!(formik.touched.businessUrl && formik.errors.businessUrl)}
        />
        {formik.touched.businessUrl && formik.errors.businessUrl && (
          <FormHelperText error id='businessUrl'>
            <CustomFormHelperText error={formik.errors.businessUrl} />
          </FormHelperText>
        )}
      </Grid>
    </Grid>
  );
};

export default BusinessURLField;
