import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StyledCanceledPlanCard = styled(Grid)`
  border-radius: 12px;
  border: 2px solid #d87407;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.06),
    0 1px 3px 0 rgba(16, 24, 40, 0.1);
  padding: 24px 24px 0 24px;
`;

const StyledHead = styled(Grid)`
  color: #d87407;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`;

const StyledExpireDate = styled(Grid)`
  color: #475467;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export { StyledCanceledPlanCard, StyledHead, StyledExpireDate };
