import { styled } from '@mui/system';
import { Paper } from '../../../../shared/paper/styles';
import { Grid } from '@mui/material';

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 24px;
  margin-bottom: 8px;
`;

const ModalWrapper = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 560px;
  outline: none;
  overflow-y: auto;
  max-height: calc(100vh - 50px);
  -ms-overflow-style: none;
  ${props => props.theme.breakpoints.down('sm')} {
    transform: translate(calc(-50% - 24px), -50%);
    width: calc(100% - 48px);
    margin-right: 24px;
    margin-left: 24px;
  }
`;

const ModalContainer = styled(Grid)`
  padding: 24px;
  ${props => props.theme.breakpoints.down('sm')} {
    padding: 16px;
  }
`;

export { HeaderContainer, ModalWrapper, ModalContainer };
