import { createAction } from '@reduxjs/toolkit';
import {
  IColumnVisibilityKeyAlertsByKeywords,
  IColumnVisibilityKeyAlertsByProject,
} from '../types/types';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';

/**
 * Action to set the visibility of a column in the Alerts by Project table.
 * @param key - The key representing the column.
 * @param value - The visibility status of the column.
 */
const setColumnVisibilityModelAlerts = createAction(
  'setColumnVisibilityModelAlerts',
  function prepare(key: IColumnVisibilityKeyAlertsByProject, value: boolean) {
    return {
      payload: {
        key,
        value,
      },
    };
  }
);

/**
 * Action to set the visibility of a column in the Alerts by Keywords table.
 * @param key - The key representing the column.
 * @param value - The visibility status of the column.
 */
const setColumnVisibilityModelAlertsByKeyword = createAction(
  'setColumnVisibilityModelAlertsByKeyword',
  function prepare(key: IColumnVisibilityKeyAlertsByKeywords, value: boolean) {
    return {
      payload: {
        key,
        value,
      },
    };
  }
);

/**
 * Action to reset the column visibility model for Alerts by Project.
 */
const resetColumnVisibilityModelAlerts = createAction(
  'resetColumnVisibilityModelAlerts'
);

/**
 * Action to reset the column visibility model for Alerts by Keywords.
 */
const resetColumnVisibilityModelAlertsByKeywords = createAction(
  'resetColumnVisibilityModelAlertsByKeywords'
);

/**
 * Action to set the page size for Alerts pagination.
 * @param pageSize - The number of rows per page.
 */
const setPaginationPageSizeAlerts = createAction(
  'setPaginationPageSizeAlerts',
  function prepare(pageSize: number) {
    return {
      payload: pageSize,
    };
  }
);

/**
 * Action to set the current page for Alerts pagination.
 * @param page - The current page number.
 */
const setPaginationPageAlerts = createAction(
  'setPaginationPageAlerts',
  function prepare(page: number) {
    return {
      payload: page,
    };
  }
);

/**
 * Action to set the row selection model for Triggers.
 * @param rowSelectionModel - The selection model for rows.
 */
const setRowSelectionModelTriggers = createAction(
  'setRowSelectionModelTriggers',
  function prepare(rowSelectionModel: GridRowSelectionModel) {
    return {
      payload: rowSelectionModel,
    };
  }
);

/**
 * Action to set the sort model for Alerts.
 * @param sortModel - The sort model for the grid.
 */
const setSortModelAlerts = createAction(
  'setSortModelAlerts',
  function prepare(sortModel: GridSortModel) {
    return {
      payload: sortModel,
    };
  }
);

/**
 * Action to set the page size for triggered Alerts pagination.
 * @param pageSize - The number of rows per page.
 */
const setPaginationPageSizeAlertTriggered = createAction(
  'setPaginationPageSizeAlertTriggered',
  function prepare(pageSize: number) {
    return {
      payload: pageSize,
    };
  }
);

/**
 * Action to set the current page for triggered Alerts pagination.
 * @param page - The current page number.
 */
const setPaginationPageAlertTriggered = createAction(
  'setPaginationPageAlertTriggered',
  function prepare(page: number) {
    return {
      payload: page,
    };
  }
);

/**
 * Action to set the sort model for triggered Alerts.
 * @param sortModel - The sort model for the grid.
 */
const setSortModelAlertTriggered = createAction(
  'setSortModelAlertTriggered',
  function prepare(sortModel: GridSortModel) {
    return {
      payload: sortModel,
    };
  }
);

/**
 * Action to set the page size for Triggers pagination.
 * @param pageSize - The number of rows per page.
 */
const setPaginationPageSizeTriggers = createAction(
  'setPaginationPageSizeTriggers',
  function prepare(pageSize: number) {
    return {
      payload: pageSize,
    };
  }
);

/**
 * Action to set the current page for Triggers pagination.
 * @param page - The current page number.
 */
const setPaginationPageTriggers = createAction(
  'setPaginationPageTriggers',
  function prepare(page: number) {
    return {
      payload: page,
    };
  }
);

/**
 * Action to set the sort model for Triggers.
 * @param sortModel - The sort model for the grid.
 */
const setSortModelTriggers = createAction(
  'setSortModelTriggers',
  function prepare(sortModel: GridSortModel) {
    return {
      payload: sortModel,
    };
  }
);

/**
 * Action to set the row selection model for Trigger Keywords.
 * @param rowSelectionModel - The selection model for rows.
 */
const setRowSelectionModelTriggerKeywords = createAction(
  'setRowSelectionModelTriggerKeywords',
  function prepare(rowSelectionModel: GridRowSelectionModel) {
    return {
      payload: rowSelectionModel,
    };
  }
);

/**
 * Action to set the page size for Trigger Keywords pagination.
 * @param pageSize - The number of rows per page.
 */
const setPaginationPageSizeTriggerKeywords = createAction(
  'setPaginationPageSizeTriggerKeywords',
  function prepare(pageSize: number) {
    return {
      payload: pageSize,
    };
  }
);

/**
 * Action to set the current page for Trigger Keywords pagination.
 * @param page - The current page number.
 */
const setPaginationPageTriggerKeywords = createAction(
  'setPaginationPageTriggerKeywords',
  function prepare(page: number) {
    return {
      payload: page,
    };
  }
);

/**
 * Action to set the sort model for Trigger Keywords.
 * @param sortModel - The sort model for the grid.
 */
const setSortModelTriggerKeywords = createAction(
  'setSortModelTriggerKeywords',
  function prepare(sortModel: GridSortModel) {
    return {
      payload: sortModel,
    };
  }
);

/**
 * Action to change the type of Triggers.
 */
const changeTriggersType = createAction('changeTriggersType');

/**
 * Action to reset the Trigger Keywords state.
 */
const resetTriggerKeywordsState = createAction('resetTriggerKeywordsState');

/**
 * Action to set the search query for Alerts.
 * @param value - The search query value.
 */
const setSearchAlerts = createAction(
  'set-search-alerts',
  function prepare(value: string) {
    return {
      payload: value,
    };
  }
);

/**
 * Action to set the active project for filtering Alerts.
 * @param id - The ID of the active project.
 */
const setActiveProjectForFilterAlerts = createAction(
  'setActiveProjectForFilterAlerts',
  function prepare(id: string) {
    return {
      payload: id,
    };
  }
);

/**
 * Action to reset the Alerts state.
 */
const resetAlertsState = createAction('resetAlertsState');

/**
 * Action to set the elements to delete from the Triggers table.
 * @param deleteElements - The selected rows for deletion.
 */
const setDeleteElementsTriggersTable = createAction(
  'setDeleteElementsTriggersTable',
  function prepare(deleteElements: GridRowSelectionModel) {
    return {
      payload: { deleteElements },
    };
  }
);

/**
 * Action to set the elements to delete from the Trigger Keywords table.
 * @param deleteElements - The selected rows for deletion.
 */
const setDeleteElementsTriggerKeywordsTable = createAction(
  'setDeleteElementsTriggerKeywordsTable',
  function prepare(deleteElements: GridRowSelectionModel) {
    return {
      payload: { deleteElements },
    };
  }
);

/**
 * Action to set the status of an Alert.
 * @param alertId - The ID of the Alert.
 */
const setAlertStatus = createAction(
  'setAlertStatus',
  function prepare(alertId: number) {
    return {
      payload: { alertId },
    };
  }
);

/**
 * Action to set the selection type for Alerts.
 * @param selectByAlerts - The selection type ('by Project' or 'by Keywords').
 */
const setSelectByAlerts = createAction(
  'setSelectByAlerts',
  function prepare(selectByAlerts: 'by Project' | 'by Keywords') {
    return {
      payload: { selectByAlerts },
    };
  }
);

/**
 * Action to set the selection type for Triggers.
 * @param value - The selection type ('by Project' or 'by Keywords').
 */
const setTriggersSelectBy = createAction(
  'setTriggersSelectBy',
  function prepare(value: 'by Project' | 'by Keywords') {
    return {
      payload: {
        value,
      },
    };
  }
);

/**
 * Action to set the visibility of the Trigger Keywords modal.
 * @param value - The visibility status of the modal.
 */
const setIsOpenTriggerKeywordsModal = createAction(
  'setIsOpenTriggerKeywordsModal',
  function prepare(value: boolean) {
    return {
      payload: {
        value,
      },
    };
  }
);

/**
 * Action to set the refresh status of the table.
 * @param value - Whether the table should be refreshed.
 */
const setShouldRefreshTable = createAction(
  'setShouldRefreshTable',
  function prepare(value: boolean) {
    return {
      payload: {
        value,
      },
    };
  }
);

export {
  resetColumnVisibilityModelAlerts,
  setColumnVisibilityModelAlertsByKeyword,
  setColumnVisibilityModelAlerts,
  setPaginationPageSizeAlerts,
  setPaginationPageAlerts,
  setRowSelectionModelTriggers,
  setSortModelAlerts,
  setPaginationPageSizeAlertTriggered,
  setPaginationPageAlertTriggered,
  setSortModelAlertTriggered,
  setPaginationPageSizeTriggers,
  setPaginationPageTriggers,
  setSortModelTriggers,
  setRowSelectionModelTriggerKeywords,
  setPaginationPageSizeTriggerKeywords,
  setPaginationPageTriggerKeywords,
  setSortModelTriggerKeywords,
  changeTriggersType,
  resetTriggerKeywordsState,
  resetColumnVisibilityModelAlertsByKeywords,
  setSearchAlerts,
  resetAlertsState,
  setActiveProjectForFilterAlerts,
  setDeleteElementsTriggersTable,
  setDeleteElementsTriggerKeywordsTable,
  setAlertStatus,
  setSelectByAlerts,
  setTriggersSelectBy,
  setIsOpenTriggerKeywordsModal,
  setShouldRefreshTable,
};
