import React, { useCallback, useMemo } from 'react';
import { FormHelperText, Grid } from '@mui/material';
import { ModalOutlinedInput } from '../../../../../../../shared/modal/styled';
import { CustomFormHelperText } from '../../../../../../../shared/components';
import { FormikProps } from 'formik';
import { NewProjectValues } from '../../../../../types';
import { CustomTooltip } from '../../../../../../../shared/tooltip/styles';
import * as Tooltips from '../Tooltips';
import { ButtonTooltipContainer } from '../styles';
import HelperIcon from '../../../../../../../shared/images/HelperIcon';

interface BusinessNameFieldProps {
  formik: FormikProps<NewProjectValues>;
}

const BusinessNameField: React.FC<BusinessNameFieldProps> = ({ formik }) => {
  const TooltipTitle = useCallback(() => {
    if (formik.values.searchEngine === 'Google My Business') {
      return <Tooltips.BusinessNameGoogleLocal />;
    }
    if (formik.values.searchEngine === 'Google Maps') {
      return <Tooltips.BusinessNameGoogleMaps />;
    }
    return <div />;
  }, [formik.values.searchEngine]);

  const InputPlaceholder = useMemo(() => {
    if (formik.values.searchEngine === 'Google My Business') {
      return 'Exact business name in Google Local (GMB)';
    }
    if (formik.values.searchEngine === 'Google Maps') {
      return 'Exact business name in Google Maps';
    }
    return '';
  }, [formik.values.searchEngine]);

  const isRenderField = useMemo(() => {
    return (
      formik.values.searchEngine === 'Google My Business' ||
      formik.values.searchEngine === 'Google Maps'
    );
  }, [formik.values.searchEngine]);

  if (!isRenderField) {
    return null;
  }

  return (
    <Grid item xs={12} paddingTop='8px'>
      <Grid container fontWeight='500' alignItems='center' fontSize='14px'>
        <Grid item>Business Name</Grid>
        <Grid item>
          <CustomTooltip title={<TooltipTitle />} placement='top-start' arrow>
            <ButtonTooltipContainer>
              <HelperIcon />
            </ButtonTooltipContainer>
          </CustomTooltip>
        </Grid>
        <ModalOutlinedInput
          size='small'
          fullWidth
          type='text'
          id='businessName'
          name='businessName'
          placeholder={InputPlaceholder}
          value={formik.values.businessName}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={!!(formik.touched.businessName && formik.errors.businessName)}
        />
        {formik.touched.businessName && formik.errors.businessName && (
          <FormHelperText error id='businessName'>
            <CustomFormHelperText error={formik.errors.businessName} />
          </FormHelperText>
        )}
      </Grid>
    </Grid>
  );
};

export default BusinessNameField;
