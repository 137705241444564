import { useSharedLinks } from '../../../../../../hooks';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { GrayButtonCustom } from '../../../../../../shared/buttons/styles';
import SettingsIcon from '../../../../../../shared/images/SettingsIcon';
import DownIcon from '../../../../../../shared/images/DownIcon';
import { Popover } from '../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import {
  ListItemTypography,
  MainContainer,
  PopupLabel,
  ResetDefaultButton,
  StyledListItem,
} from '../../../../../projects/components/Projects/components/TableProjects/components/Header/components/PopupTableSettings/styles';
import { List, ListItemButton } from '@mui/material';
import { ColumnVisibilityKey } from '../../../../../projects/types';
import { CustomCheckbox } from '../../../../../../shared/checkbox/styles';
import CustomCheckboxImage from '../../../../../../shared/images/CustomCheckbox';
import CheckedIcon from '../../../../../../shared/images/CheckedIcon';
import { renderListItemTitle } from '../../../../../projects/components/Projects/components/TableProjects/components/Header/components/PopupTableSettings/utils';
import React from 'react';
import { useAppSelector } from '../../../../../../store';

const PopupTableSettings = () => {
  const {
    onSetColumnVisibilityModelForSharedLinkContentsListTable,
    onResetColumnVisibilityModelForSharedLinkContentsListTable,
  } = useSharedLinks();

  const columnVisibilityModel = useAppSelector(
    state =>
      state.sharedLinks.tables.sharedLinkContentsList.columnVisibilityModel
  );

  const handleResetColumnVisibilityModel = () => {
    onResetColumnVisibilityModelForSharedLinkContentsListTable();
  };

  return (
    <>
      <PopupState variant='popover'>
        {popupState => (
          <>
            <GrayButtonCustom
              $padding='7px 12px'
              size='small'
              outline
              startIcon={<SettingsIcon stroke='#344054' />}
              endIcon={<DownIcon stroke='#344054' />}
              {...bindTrigger(popupState)}
            >
              Table Settings
            </GrayButtonCustom>

            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MainContainer>
                <PopupLabel>Show or Hide Columns</PopupLabel>
                <ResetDefaultButton onClick={handleResetColumnVisibilityModel}>
                  Reset to default
                </ResetDefaultButton>
                <List>
                  {Object.keys(columnVisibilityModel).map(item => {
                    const columnKey = item as ColumnVisibilityKey;
                    return (
                      <StyledListItem
                        key={item}
                        disablePadding
                        checkBackgroundColor={
                          columnVisibilityModel[columnKey]
                            ? '#EEF3FF'
                            : undefined
                        }
                      >
                        <ListItemButton
                          onClick={() =>
                            onSetColumnVisibilityModelForSharedLinkContentsListTable(
                              columnKey,
                              !columnVisibilityModel[columnKey]
                            )
                          }
                        >
                          <CustomCheckbox
                            checked={columnVisibilityModel[columnKey]}
                            icon={<CustomCheckboxImage />}
                            checkedIcon={<CheckedIcon />}
                            disableFocusRipple
                          />

                          <ListItemTypography>
                            {renderListItemTitle(columnKey)}
                          </ListItemTypography>
                        </ListItemButton>
                      </StyledListItem>
                    );
                  })}
                </List>
              </MainContainer>
            </Popover>
          </>
        )}
      </PopupState>
    </>
  );
};

export default PopupTableSettings;
