import React from 'react';
import { MainContainer } from '../../../../../../notes/components/ProjectExpandedNotes/components/ProjectExpandedNotesTable/components/ActionsMenu/styles';
import { List } from '@mui/material';
import DeleteAction from './DeleteAction';
import { KeywordsActionProps } from '../../../../../../notes/components/ProjectExpandedNotes/components/ProjectExpandedNotesTable/components/ActionsMenu/components/types';

const ListActions: React.FC<KeywordsActionProps> = ({ popupClose }) => {
  return (
    <MainContainer>
      <List>
        <DeleteAction popupClose={popupClose} />
      </List>
    </MainContainer>
  );
};

export default ListActions;
