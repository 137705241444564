import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

import { NoBorderButton } from '../../../../../../../../../../shared/buttons/styles';

const MainContainer = styled('div')`
  width: 158px;
  padding: 8px 0;
`;

const StyledLinearLogSwitch = styled(Grid)`
  margin: 0 16px;
  padding: 8px 0;
  display: flex;
  justify-content: space-between;

  color: #1a1919;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
`;

const StyledExportButton = styled(NoBorderButton)`
  width: calc(100% - 16px);
  margin: 0 8px;
  padding: 9.5px 8px !important;
  justify-content: flex-start !important;
`;

export { MainContainer, StyledLinearLogSwitch, StyledExportButton };
