import React, { useMemo, useState } from 'react';
import { DeleteProjectsActionProps } from './types';
import { ListItemTypography, StyledListItem } from '../styles';
import RefreshIcon from '../../../../../../../../../shared/images/RefreshIcon';
import { useProjects } from '../../../../../../../../../hooks';
import { StyledListItemButton } from '../../EllipsisMenu/styles';
import RefreshAllKeywordsModal from '../../../../../../modals/RefreshAllKeywordsModal/RefreshAllKeywordsModal';
import { useAppSelector } from '../../../../../../../../../store';
import useAccountLimits from '../../../../../../../../auth/hooks/useAccountLimits';

const RefreshAllKeywordsAction: React.FC<DeleteProjectsActionProps> = ({
  popupClose,
}) => {
  const { onSetRowSelectionModel, onSetRefreshAllKeywordsModal } =
    useProjects();
  const { onCheckSubscriptionExpiration } = useAccountLimits();

  const rowSelectionModel = useAppSelector(
    state => state.projects.tables.projects.rowSelectionModel
  );

  const projects = useAppSelector(state => state.projects.projects);

  const projectsList = useMemo(() => projects?.items || [], [projects?.items]);

  const filteredProjects = useMemo(
    () =>
      projectsList.filter(project => rowSelectionModel.includes(project.id)),
    [projectsList, rowSelectionModel]
  );

  const totalKeywordsSum = useMemo(
    () =>
      filteredProjects.reduce((sum, project) => sum + project.totalKeywords, 0),
    [filteredProjects]
  );

  const open = useAppSelector(
    state => state.projects.modals.refreshAllKeywords
  );

  const handleOpenModal = () => {
    const isExpiration = onCheckSubscriptionExpiration();
    if (!isExpiration) {
      onSetRefreshAllKeywordsModal(true);
    }
  };
  const handleCloseModal = () => {
    onSetRowSelectionModel([]);
    onSetRefreshAllKeywordsModal(false);
    popupClose();
  };

  return (
    <>
      <StyledListItem onClick={handleOpenModal}>
        <StyledListItemButton>
          <RefreshIcon />
          <ListItemTypography marginLeft='8px'>
            Refresh All Keywords
          </ListItemTypography>
        </StyledListItemButton>
      </StyledListItem>
      <RefreshAllKeywordsModal
        onClose={handleCloseModal}
        open={open}
        projectIds={rowSelectionModel as number[]}
        keywordCount={totalKeywordsSum}
      />
    </>
  );
};

export default RefreshAllKeywordsAction;
