import React from 'react';

const StarterIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='44'
    height='44'
    fill='none'
    viewBox='0 0 44 44'
  >
    <g filter='url(#filter0_d_1827_108608)'>
      <rect width='40' height='40' x='2' y='1' fill='#fff' rx='8'></rect>
      <path
        stroke='#475467'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.667'
        d='M22.833 12.667l-7.422 8.906c-.29.35-.436.524-.438.671a.417.417 0 00.154.33c.115.093.342.093.796.093H22l-.834 6.667 7.422-8.907c.291-.349.436-.523.439-.67a.417.417 0 00-.155-.33c-.115-.093-.342-.093-.796-.093H22l.833-6.667z'
      ></path>
      <rect
        width='39'
        height='39'
        x='2.5'
        y='1.5'
        stroke='#EAECF0'
        rx='7.5'
      ></rect>
    </g>
    <defs>
      <filter
        id='filter0_d_1827_108608'
        width='44'
        height='44'
        x='0'
        y='0'
        colorInterpolationFilters='sRGB'
        filterUnits='userSpaceOnUse'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
        <feColorMatrix
          in='SourceAlpha'
          result='hardAlpha'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        ></feColorMatrix>
        <feOffset dy='1'></feOffset>
        <feGaussianBlur stdDeviation='1'></feGaussianBlur>
        <feColorMatrix values='0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0'></feColorMatrix>
        <feBlend
          in2='BackgroundImageFix'
          result='effect1_dropShadow_1827_108608'
        ></feBlend>
        <feBlend
          in='SourceGraphic'
          in2='effect1_dropShadow_1827_108608'
          result='shape'
        ></feBlend>
      </filter>
    </defs>
  </svg>
);

export default StarterIcon;
