import React from 'react';
import { Grid, Typography } from '@mui/material';

const Header = () => {
  return (
    <Grid xs={12}>
      <Typography
        sx={{ pt: 1, pb: '32px' }}
        fontWeight='600'
        color='#101828'
        fontSize='28px'
      >
        Notes
      </Typography>
    </Grid>
  );
};

export default Header;
