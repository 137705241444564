/**
 * Renders the title for a list item based on the provided parameter.
 * @param param - The parameter to determine the list item title (e.g., 'top3', 'improved', 'declined').
 * @returns The corresponding title as a string, or an empty string if the parameter does not match any case.
 */
function renderListItemTitle(param: string) {
  switch (param) {
    case 'top3':
      return 'Top 3';
    case 'top10':
      return 'Top 10';
    case 'top30':
      return 'Top 30';
    case 'top100':
      return 'Top 100';
    case 'improved':
      return 'Improved';
    case 'declined':
      return 'Declined';
    case 'lost':
      return 'Lost';
    case 'notRanked':
      return 'Not Ranked';
    case 'noChange':
      return 'No Change';
    default:
      return '';
  }
}

export { renderListItemTitle };
