import React, { useEffect, useMemo, useState } from 'react';
import { Fade, Grid, Modal, Typography, useMediaQuery } from '@mui/material';
import {
  ModalContainer,
  ModalWrapper,
} from '../../Projects/components/ProjectRedactorModal/styles';
import { CloseDeletePopoverOne } from '../../../../auth/components/Settings/components/Account/styles';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonsWrapper } from '../../../../auth/components/Settings/components/Team/components/customTableStyles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../shared/buttons/styles';
import { RefreshAllKeywordsModalProps } from './types';
import { useProjects } from '../../../../../hooks';
import { enqueueSnackbar } from 'notistack';
import CustomToast from '../../../../../shared/CustomToast/CustomToast';
import { closeSnackbarAction } from '../../../../../utils/snackbar';
import { useAppSelector } from '../../../../../store';
import DailyRefreshesLimitExceededModal from '../../../../../shared/components/DailyRefreshesLimitExceededModal/DailyRefreshesLimitExceededModal';
import ROUTES from '../../../../../routes/constants';
import useAccountLimits from '../../../../auth/hooks/useAccountLimits';
import { useNavigate } from 'react-router';
import { roundNegativeToZero } from '../../../../../utils';

const RefreshAllKeywordsModal: React.FC<RefreshAllKeywordsModalProps> = ({
  open,
  projectIds,
  folderIds,
  keywordCount,
  onClose,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const navigate = useNavigate();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const dailyRefreshesLimitExceededOpen = useAppSelector(
    state => state.auth.accountLimitErrors.dailyRefreshesLimitExceeded.open
  );

  const {
    onRefreshAllKeywords,
    onGetProjectsNumberOfAvailableKeywordsToUpdate,
  } = useProjects();
  const {
    onCloseRefreshesLimitLimitExceededModal,
    onCheckCannotRefreshExcessKeywordsModal,
    onCheckYouveReachedYourRefreshLimitForTodayModal,
  } = useAccountLimits();

  const [projectsKeywordsSkipped, setProjectsKeywordsSkipped] = useState(0);
  const [foldersKeywordsSkipped, setFoldersKeywordsSkipped] = useState(0);

  const keywordsSkipped = useMemo(
    () => projectsKeywordsSkipped + foldersKeywordsSkipped,
    [foldersKeywordsSkipped, projectsKeywordsSkipped]
  );

  const successFn = () => {
    onClose();
    const toastMessage =
      keywordCount === keywordsSkipped ? 'Info' : 'Keywords are being updated.';
    const toastSubmessage =
      keywordCount === keywordsSkipped
        ? 'Keyword update skipped due to prior manual updates today.'
        : '';
    const toastVariant =
      keywordCount === keywordsSkipped ? 'warning' : 'default';

    enqueueSnackbar(
      <CustomToast message={toastMessage} submessage={toastSubmessage} />,
      {
        action: closeSnackbarAction,
        variant: toastVariant,
      }
    );
  };

  const onRefresh = () => {
    let checkCannotRefreshExcessKeywordsModal;
    const checkYouveReachedYourRefreshLimitForTodayModal =
      onCheckYouveReachedYourRefreshLimitForTodayModal();

    if (!checkYouveReachedYourRefreshLimitForTodayModal) {
      checkCannotRefreshExcessKeywordsModal =
        onCheckCannotRefreshExcessKeywordsModal(
          roundNegativeToZero(keywordCount - keywordsSkipped)
        );
    }

    if (
      !checkCannotRefreshExcessKeywordsModal &&
      !checkYouveReachedYourRefreshLimitForTodayModal
    ) {
      onRefreshAllKeywords({
        accountId: currentAccount.accountId,
        projectIds: projectIds,
        folderIds: folderIds,
        successFn,
      });
      onClose();
    }
  };

  const handleCloseRefreshesLimitExceededModal = () => {
    onCloseRefreshesLimitLimitExceededModal();
  };

  const handleDailyRefreshesUpgradeAccount = () => {
    onCloseRefreshesLimitLimitExceededModal();
    navigate(ROUTES.upgradePlan);
  };

  useEffect(() => {
    if (open && projectIds) {
      onGetProjectsNumberOfAvailableKeywordsToUpdate({
        accountId: currentAccount.accountId,
        projectIds: projectIds,
        folderIds: [],

        successFn: keywordCount => setProjectsKeywordsSkipped(keywordCount),
      });
    }
  }, [
    currentAccount?.accountId,
    onGetProjectsNumberOfAvailableKeywordsToUpdate,
    open,
    projectIds,
  ]);

  useEffect(() => {
    if (open && folderIds) {
      onGetProjectsNumberOfAvailableKeywordsToUpdate({
        accountId: currentAccount.accountId,
        projectIds: [],
        folderIds: folderIds,
        successFn: keywordCount => setFoldersKeywordsSkipped(keywordCount),
      });
    }
  }, [
    currentAccount?.accountId,
    folderIds,
    onGetProjectsNumberOfAvailableKeywordsToUpdate,
    open,
    projectIds,
  ]);

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <ModalWrapper>
            <ModalContainer container xs={12}>
              <Grid item xs={12} sx={{ position: 'relative' }}>
                <CloseDeletePopoverOne onClick={onClose}>
                  <CloseIcon fontSize='small' />
                </CloseDeletePopoverOne>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  fontSize='18px'
                  fontWeight='600'
                  lineHeight='28px'
                  color='#101828'
                  textAlign='center'
                >
                  Refresh All Keywords?
                </Typography>
                <Typography
                  fontSize='14px'
                  fontWeight='400'
                  lineHeight='24px'
                  color='#475467'
                  marginTop='24px'
                  textAlign={'center'}
                >
                  Are you sure you want to refresh all keywords? This action
                  will count towards your daily keyword refresh credit.
                  {keywordsSkipped ? (
                    <>
                      <br />
                      <br />
                      {keywordsSkipped
                        ? `Out of the selected keywords, ${keywordsSkipped} will be skipped as they have already been manually updated today.`
                        : null}
                    </>
                  ) : null}
                </Typography>
              </Grid>

              <ButtonsWrapper container xs={12}>
                <Grid item xs={extraSmallScreen && 12}>
                  <GrayButtonSmall
                    fullWidth={extraSmallScreen}
                    size='small'
                    outline
                    onClick={onClose}
                  >
                    Cancel
                  </GrayButtonSmall>
                </Grid>
                <Grid item xs={extraSmallScreen && 12}>
                  <PrimaryButtonSmall
                    fullWidth={extraSmallScreen}
                    variant='contained'
                    size='small'
                    type='submit'
                    onClick={onRefresh}
                  >
                    Yes, Refresh Keywords
                  </PrimaryButtonSmall>
                </Grid>
              </ButtonsWrapper>
            </ModalContainer>
          </ModalWrapper>
        </Fade>
      </Modal>
      <DailyRefreshesLimitExceededModal
        onClose={handleCloseRefreshesLimitExceededModal}
        onUpgradeAccount={handleDailyRefreshesUpgradeAccount}
        open={dailyRefreshesLimitExceededOpen}
      />
    </>
  );
};

export default RefreshAllKeywordsModal;
