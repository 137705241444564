import React from 'react';
import { Divider, List } from '@mui/material';
import { EllipsisActionsProps } from '../types';

import DeleteKeywordAction from './DeleteKeywordAction';
import ShowOnGraphAction from './ShowOnGraphAction';

import { MainContainer } from '../styles';
import RefreshKeywordAction from './RefreshKeywordAction';

const ListActions: React.FC<EllipsisActionsProps> = ({
  keyword,
  popupClose,
  open,
}) => {
  if (!open) return null;
  return (
    <MainContainer>
      <List>
        <ShowOnGraphAction keyword={keyword} popupClose={popupClose} />
        <RefreshKeywordAction keyword={keyword} popupClose={popupClose} />

        <Divider
          orientation='horizontal'
          flexItem
          sx={{
            margin: '8px 0',
            color: '#EDEDED',
          }}
        />

        <DeleteKeywordAction keyword={keyword} popupClose={popupClose} />
      </List>
    </MainContainer>
  );
};

export default React.memo(ListActions);
