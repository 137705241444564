import { styled } from '@mui/system';
import { Grid, OutlinedInput } from '@mui/material';

const StyledBadge = styled(Grid)<{ $isActive?: boolean }>`
  background: ${props => (props.$isActive ? `#126fe9` : 'white')};
  color: ${props => (props.$isActive ? `white` : '#344054')};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 2px 8px;
  border-radius: 16px;
  border: ${props =>
    props.$isActive ? `1px solid white` : '1px solid #d0d5dd'};
  margin: 0 8px;
  cursor: pointer;

  :first-child {
    margin-left: 0;
  }
`;

export { StyledBadge };
