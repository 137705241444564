import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import { CustomDataGrid } from '../../../../../../../auth/components/Settings/components/Team/components/customTableStyles';
import CheckboxWrapper from '../../../../../../../auth/components/Settings/components/Team/components/CheckboxWrapper';
import {
  useSharedLinks,
  useSharedLinksTable,
} from '../../../../../../../../hooks';
import { arraysAreEqual } from '../../../../../../../../utils';
import TableSkeleton from '../../../../../../../../shared/graphSkeletons/TableSkeleton';
import { useAppSelector } from '../../../../../../../../store';
import EmptyTable from '../../../../../../../../shared/components/EmptyTable/EmptyTable';
import PlusIcon from '../../../../../../../../shared/images/PlusIcon';
import { AddSharedLinkModal } from '../../../../../modals';
import useAccountLimits from '../../../../../../../auth/hooks/useAccountLimits';
import {
  setTableColumns,
  TABLES_STORAGE_KEYS,
} from '../../../../../../../projects/tablesColumnStase';

const Table = () => {
  const { columns, columnsSkeleton } = useSharedLinksTable();
  const { onSetRowSelectionModelSharedLinks } = useSharedLinks();
  const { onCheckSubscriptionExpiration } = useAccountLimits();

  const sharedLinksList = useAppSelector(
    state => state.sharedLinks.sharedLinksList
  );
  const sharedLinksPage = useAppSelector(
    state => state.sharedLinks.tables.sharedLinksPage
  );
  const openTariffPlanBanner = useAppSelector(
    state => state.auth.openTariffPlanBanner
  );

  const [itemCount, setItemCount] = useState(
    sharedLinksList?.meta?.itemCount || sharedLinksPage.paginationModel.pageSize
  );

  const [isOpenModal, setIsOpenModal] = useState(false);

  const arrayOfObjects = useMemo(
    () => Array.from({ length: itemCount }, (_, index) => ({ id: index + 1 })),
    [itemCount]
  );

  const rows = useMemo(() => {
    const arr1 = [
      ...(sharedLinksList?.items?.length ? sharedLinksList.items : []),
    ];
    const arr2 = [...sharedLinksPage.deleteElements];

    return arr1.filter(item => !arr2.includes(item.id));
  }, [sharedLinksPage.deleteElements, sharedLinksList?.items]);

  const renderRows = useMemo(() => {
    return !sharedLinksList?.items ? arrayOfObjects : rows;
  }, [arrayOfObjects, sharedLinksList?.items, rows]);

  const renderColumns = useMemo(() => {
    return !sharedLinksList?.items ? columnsSkeleton : columns;
  }, [columns, columnsSkeleton, sharedLinksList?.items]);

  const onOpenModal = () => {
    const isExpiration = onCheckSubscriptionExpiration();
    if (!isExpiration) {
      setIsOpenModal(true);
    }
  };

  const onCloseModal = () => {
    setIsOpenModal(false);
  };

  const NoRowsOverlay = useCallback(
    () =>
      !sharedLinksList?.items ? (
        <TableSkeleton />
      ) : (
        <EmptyTable
          handleButton={onOpenModal}
          startIcon={<PlusIcon />}
          text={'You don’t have any shared links'}
          buttonText={'Add Shared Link'}
          supportingText={'Start by clicking ‘the Add Shared Link’ button.'}
        />
      ),
    [sharedLinksList?.items]
  );

  useEffect(() => {
    if (sharedLinksList?.meta?.itemCount) {
      setItemCount(sharedLinksList.meta.itemCount);
    }
  }, [sharedLinksList?.meta?.itemCount]);

  useEffect(() => {
    const filtered = sharedLinksList?.items
      ?.filter(obj => sharedLinksPage.rowSelectionModel.includes(obj.id))
      ?.map(item => item.id)
      .filter(item => !sharedLinksPage.deleteElements.includes(item));
    if (
      filtered &&
      !arraysAreEqual(sharedLinksPage.rowSelectionModel, filtered)
    ) {
      onSetRowSelectionModelSharedLinks(filtered);
    }
  }, [
    sharedLinksPage.deleteElements,
    onSetRowSelectionModelSharedLinks,
    sharedLinksList?.items,
    sharedLinksPage.rowSelectionModel,
  ]);

  useEffect(() => {
    setTableColumns(
      TABLES_STORAGE_KEYS.sharedLinksPage,
      sharedLinksPage.columnVisibilityModel
    );
  }, [sharedLinksPage.columnVisibilityModel]);

  return (
    <>
      <Grid xs={12}>
        <CustomDataGrid
          initialState={{
            pinnedColumns: {
              right: ['actions'],
            },
          }}
          columnHeaderHeight={40}
          rowHeight={40}
          autoHeight
          rows={renderRows}
          rowSelectionModel={sharedLinksPage.rowSelectionModel}
          onRowSelectionModelChange={onSetRowSelectionModelSharedLinks}
          columns={renderColumns}
          components={{
            BaseCheckbox: CheckboxWrapper,
            NoRowsOverlay: NoRowsOverlay,
          }}
          columnVisibilityModel={sharedLinksPage.columnVisibilityModel}
          keepNonExistentRowsSelected
          hideFooterPagination
          checkboxSelection
          disableRowSelectionOnClick
          disableColumnMenu
          $openTariffPlanBanner={openTariffPlanBanner}
        />
      </Grid>
      <AddSharedLinkModal open={isOpenModal} onClose={onCloseModal} />
    </>
  );
};

export default Table;
