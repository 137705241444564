import React from 'react';
import {
  Fade,
  FormHelperText,
  Grid,
  Modal,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FormikProvider } from 'formik';
import { ProjectModalProps } from '../../../../types';

import { CustomFormHelperText } from '../../../../../../shared/components';
import { ButtonsWrapper } from '../../../../../auth/components/Settings/components/Team/components/customTableStyles';

import {
  AddedTag,
  AddTagsContainer,
  AddTagsInput,
  ModalContainer,
  ModalWrapper,
} from './styles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../../shared/buttons/styles';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import CloseIcon from '../../../../../../shared/images/CloseIcon';
import TagIcon from '../../../../../../shared/images/TagIcon';
import { Popover } from '../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import AddTagsPopup from './AddTagsPopup';
import { useAppSelector } from '../../../../../../store';

const ProjectTagsModalContainer: React.FC<ProjectModalProps> = ({
  formik,
  open,
  handleClose,
  children,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');
  const refTagsInput = React.useRef<HTMLInputElement>(null);

  const accountTags = useAppSelector(state => state.projects.accountTags);

  const onClose = () => {
    handleClose();
    formik.resetForm();
  };

  return (
    <Modal
      aria-labelledby='project-modal-title'
      aria-describedby='project-modal-description'
      open={open}
      onClose={onClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <form onSubmit={formik.handleSubmit}>
          <FormikProvider value={formik}>
            <ModalWrapper>
              <ModalContainer container xs={12} sx={{ gap: '8px' }}>
                {children}

                <Grid item xs={12} paddingTop='8px'>
                  <Grid
                    container
                    fontWeight='500'
                    alignItems='center'
                    fontSize='14px'
                    gap='8px'
                  >
                    <Grid item>Tags</Grid>
                    <PopupState variant='popover' popupId='add-tag-setting'>
                      {popupState => (
                        <>
                          <AddTagsInput
                            {...bindTrigger(popupState)}
                            ref={refTagsInput}
                          >
                            {formik.values.tags.length
                              ? formik.values.tags.map(item => (
                                  <AddedTag key={item}>
                                    <Typography component='span'>
                                      {item}
                                    </Typography>
                                    <Typography
                                      component='p'
                                      sx={{ fontSize: '0' }}
                                      onClick={event => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        formik.setFieldValue(
                                          'tags',
                                          formik.values.tags.filter(
                                            filterItem => filterItem !== item
                                          )
                                        );
                                      }}
                                    >
                                      <CloseIcon width='12' height='12' />
                                    </Typography>
                                  </AddedTag>
                                ))
                              : null}

                            {formik.values.tagIds.length
                              ? formik.values.tagIds.map(item => (
                                  <AddedTag key={item.id}>
                                    <Typography component='span'>
                                      {item.name}
                                    </Typography>
                                    <Typography
                                      component='p'
                                      sx={{ fontSize: '0' }}
                                      onClick={event => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        formik.setFieldValue(
                                          'tagIds',
                                          formik.values.tagIds.filter(
                                            filterItem =>
                                              filterItem.id !== item.id
                                          )
                                        );
                                      }}
                                    >
                                      <CloseIcon width='12' height='12' />
                                    </Typography>
                                  </AddedTag>
                                ))
                              : null}

                            <AddTagsContainer>
                              <TagIcon />
                              <Typography component='span'>
                                Add a Tag
                              </Typography>
                            </AddTagsContainer>
                          </AddTagsInput>
                          <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            sx={{
                              boxShadow:
                                '0px 12px 16px -4px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.06)',
                              borderRadius: '8px',
                              marginTop: '7px',
                            }}
                          >
                            <AddTagsPopup
                              closePopover={popupState.close}
                              tags={formik.values.tags}
                              tagIds={formik.values.tagIds}
                              listTags={accountTags}
                              setFieldValue={formik.setFieldValue}
                              refTagsInput={refTagsInput}
                            />
                          </Popover>
                        </>
                      )}
                    </PopupState>
                  </Grid>
                </Grid>

                {formik.errors.message && (
                  <Grid item xs={12}>
                    <FormHelperText
                      error={!!formik.errors.message}
                      id='message'
                    >
                      {formik.errors.message && (
                        <CustomFormHelperText error={formik.errors.message} />
                      )}
                    </FormHelperText>
                  </Grid>
                )}

                <ButtonsWrapper
                  container
                  xs={12}
                  sx={{ paddingTop: '8px !important' }}
                >
                  <Grid item xs={extraSmallScreen && 12}>
                    <GrayButtonSmall
                      fullWidth={extraSmallScreen}
                      size='small'
                      outline
                      onClick={handleClose}
                    >
                      Cancel
                    </GrayButtonSmall>
                  </Grid>
                  <Grid item xs={extraSmallScreen && 12}>
                    <PrimaryButtonSmall
                      fullWidth={extraSmallScreen}
                      variant='contained'
                      size='small'
                      type='submit'
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      Save Tags
                    </PrimaryButtonSmall>
                  </Grid>
                </ButtonsWrapper>
              </ModalContainer>
            </ModalWrapper>
          </FormikProvider>
        </form>
      </Fade>
    </Modal>
  );
};

export default ProjectTagsModalContainer;
