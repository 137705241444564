import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

import background from '../../../../../../../../../../shared/images/backgroundPatternDecorative.svg';
import { getPositionTableHeader } from '../../../../../../../../../../utils';

const CustomDataGrid = styled(DataGridPro)`
  --unstable_DataGrid-radius: 0px;

  max-width: 100%;

  .MuiDataGrid-overlayWrapper {
    height: fit-content;
  }
  .MuiDataGrid-overlayWrapperInner {
    height: fit-content !important;
    min-height: max-content;
  }

  height: fit-content;

  border-top: 1px solid #dedede;
  border-bottom: none;
  border-right-width: 0;
  border-left-width: 0;
  background: #fff;
  box-shadow: 0 5px 3px -2px rgba(0, 0, 0, 0.02),
    0px 3px 1px -2px rgba(0, 0, 0, 0.06);

  .MuiDataGrid-virtualScroller,
  .MuiDataGrid-overlayWrapperInner {
    min-height: 390px;
  }

  .MuiDataGrid-row--lastVisible {
    .MuiDataGrid-cell {
      border-bottom-color: rgba(224, 224, 224, 1) !important;
    }
  }

  .MuiDataGrid-columnHeaders {
    -webkit-appearance: none;
    line-height: initial !important;
    position: sticky;
    top: 65px;
    z-index: 10;

    @media (max-width: 599px) {
      top: 57px;
    }
  }

  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  .MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
  }

  .MuiDataGrid-columnHeaders {
    background-color: #fafafa;
  }

  .MuiDataGrid-columnSeparator {
    opacity: 0 !important;
  }

  .MuiDataGrid-row:hover {
    background-color: #f6f6f6 !important;
  }

  .MuiDataGrid-row.Mui-selected {
    background-color: #e8f1fd;
  }

  .MuiDataGrid-footerContainer {
    display: none;
  }

  .MuiDataGrid-row {
    cursor: pointer;
  }

  .MuiDataGrid-main {
    z-index: 10;
    overflow: visible;
  }

  .MuiDataGrid-row--lastVisible {
    .MuiDataGrid-cell {
      border-bottom-color: #dedede !important;
    }
  }
`;

const StyledCustomNoRowsOverlay = styled(Grid)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${background});
  background-position: top;
  background-repeat: no-repeat;
`;

const EmptyState = styled(Grid)`
  max-width: 365px;
  margin-top: 40px;
  padding: 0 16px;
`;

const Content = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const KeywordRankingsCustomDataGrid = styled(CustomDataGrid)<{
  $openTariffPlanBanner: boolean;
}>`
  @keyframes blink {
    0% {
      background-color: transparent;
    }
    50% {
      background-color: #d1e9ff;
    }
    100% {
      background-color: transparent;
    }
  }

  .blinking {
    animation: blink 1s infinite;
    animation-iteration-count: 1;
  }

  .MuiDataGrid-columnHeaders {
    line-height: initial !important;
    position: sticky;
    top: ${props => getPositionTableHeader(props.$openTariffPlanBanner, false)};
    z-index: 5;
  }
`;

export {
  CustomDataGrid,
  StyledCustomNoRowsOverlay,
  EmptyState,
  Content,
  KeywordRankingsCustomDataGrid,
};
