/**
 * Default visibility model for email reports columns.
 */
const DEFAULT_EMAIL_REPORTS_COLUMN_VISIBILITY_MODEL = {
  project: true,
  recipient: true,
  frequency: true,
  lastSent: true,
  nextDelivery: true,
  format: true,
};

export { DEFAULT_EMAIL_REPORTS_COLUMN_VISIBILITY_MODEL };
