import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import errorllustration from '../images/Errorllustration.png';
import {
  StylesHeading,
  StylesSupportingText,
} from '../components/NotFoundBlock/styles';
import { PrimaryButton } from '../buttons/styles';
import { useNavigate } from 'react-router';
import ROUTES from '../../routes/constants';

const ErrorPage = () => {
  const navigate = useNavigate();
  const mediumScreen = useMediaQuery('(max-width:900px)');

  const handleGoToHomepage = () => {
    navigate(ROUTES.projects);
    window.location.reload();
  };

  return (
    <Grid
      xs={12}
      container
      align-items='center'
      height='calc(100vh - 380px)'
      padding='120px 24px'
    >
      <Grid
        xs={12}
        container
        item
        gap='48px'
        justifyContent='center'
        height='fit-content'
      >
        <Grid item width={mediumScreen ? '100%' : undefined}>
          <img
            width={mediumScreen ? '152px' : '350px'}
            src={errorllustration}
            alt={'errorllustration'}
          />
        </Grid>
        <Grid
          display={'flex'}
          item
          justifyContent={'center'}
          flexDirection={'column'}
        >
          <StylesHeading item>Oops! Something went wrong.</StylesHeading>
          <StylesSupportingText item paddingTop='16px' paddingBottom='48px'>
            We encountered an unexpected issue. Our team is already working on a
            fix. Please try refreshing the page or check back shortly.
          </StylesSupportingText>
          <Grid item>
            <PrimaryButton
              onClick={handleGoToHomepage}
              variant='contained'
              color='primary'
            >
              Go to homepage
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ErrorPage;
