import React, { useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  Fade,
  FormHelperText,
  Grid,
  InputLabel,
  Modal,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  ModalContainer,
  ModalWrapper,
} from '../../../../../../../projects/components/Projects/components/ProjectRedactorModal/styles';
import { CloseDeletePopoverOne } from '../../../../../../../auth/components/Settings/components/Account/styles';
import CloseIcon from '@mui/icons-material/Close';
import { CustomFormHelperText } from '../../../../../../../../shared/components';
import { ButtonsWrapper } from '../../../../../../../auth/components/Settings/components/Team/components/customTableStyles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../../../../shared/buttons/styles';
import { AddNewAlertProps } from './types';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { StylesAddAnotherRecipientButton, StylesLabelModal } from './styles';
import { StyledAutocompleteLi } from '../../../../../../../auth/components/Settings/components/Team/components/styles';
import SelectIcon from '../../../../../../../../shared/components/SelectIcon';
import { CustomCheckbox } from '../../../../../../../../shared/checkbox/styles';
import CustomCheckboxImage from '../../../../../../../../shared/images/CustomCheckbox';
import CheckedIcon from '../../../../../../../../shared/images/CheckedIcon';
import PlusIcon from '../../../../../../../../shared/images/PlusIcon';
import useAlerts from '../../../../../../hooks/useAlerts';
import {
  IAlertsTrigger,
  IKeywordByProject,
} from '../../../../../../types/types';
import { getDeviceTypeIcon } from '../../../../../../../projects/utils';
import CheckboxWrapper from '../../../../../../../auth/components/Settings/components/Team/components/CheckboxWrapper';
import AutocompletePaper from '../../../../../../../../shared/AutocompletePaper';
import { useAppSelector } from '../../../../../../../../store';
import {
  ModalAutocompleteStylesProps,
  ModalMultipleAutocompleteStylesProps,
  ModalOutlinedInput,
} from '../../../../../../../../shared/modal/styled';
import GlobeIcon from '../../../../../../../../shared/images/GlobeIcon';
import { useProjects } from '../../../../../../../../hooks';
import useAccountLimits from '../../../../../../../auth/hooks/useAccountLimits';
import { enqueueSnackbar } from 'notistack';
import CustomToast from '../../../../../../../../shared/CustomToast/CustomToast';
import { closeSnackbarAction } from '../../../../../../../../utils/snackbar';

export const defaultOptionAlertTriggerTriggerRule = {
  id: 1,
  name: 'Changes by more than',
};

/**
 * Component for adding a new alert with various options such as project selection, keywords, and recipients.
 */
const AddNewAlert: React.FC<AddNewAlertProps> = ({
  handleClose,
  open,
  type,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const projectsListForInvite = useAppSelector(
    state => state.projects.projectsListForInvite
  );

  const { onCheckTriggersLimit } = useAccountLimits();

  const {
    onGetKeywordsByProject,
    onGetAlertsTriggersTypes,
    onCreateTrigger,
    onGetTriggersByKeywords,
    onGetTriggersByProjects,
    alertsTriggers,
    tables: {
      triggers: { paginationModel, sortModel },
    },
  } = useAlerts();

  const { onGetProjectsListForInvite } = useProjects();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const validationSchema = Yup.object().shape({
    project: Yup.object().required('Project is required.'),
    triggerRule: Yup.object().required('Trigger is required.'),
    threshold: Yup.string().required('Threshold is required.'),
    emails: Yup.array()
      .of(
        Yup.object().shape({
          email: Yup.string()
            .email('Invalid email address.')
            .test(
              'email-validation',
              'Email Address is required.',
              function (value) {
                if (value && value.trim() !== '') {
                  return Yup.string().email().isValidSync(value);
                }
                return true;
              }
            ),
        })
      )
      .test(
        'at-least-one-email',
        'At least one email address is required.',
        function (emails) {
          if (!emails) return false;
          return emails.some(email => email.email && email.email.trim() !== '');
        }
      ),
  });

  const initialValues = {
    triggerRule: defaultOptionAlertTriggerTriggerRule as
      | IAlertsTrigger
      | undefined,
    threshold: '',
    project: undefined as
      | { id: number; projectName: string; favicon?: string }
      | undefined,
    keywordIds: [] as IKeywordByProject[],
    emails: [
      {
        email: '',
        subscribed: true,
      },
    ] as {
      email: string;
      subscribed: true;
    }[],
    message: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting, setErrors }) => {
      const triggersLimit = onCheckTriggersLimit(1);
      if (
        currentAccount?.accountId &&
        values?.project &&
        values?.triggerRule?.name &&
        !triggersLimit
      ) {
        const emails = values.emails.filter(item => /\S/.test(item.email));
        onCreateTrigger({
          id: currentAccount.accountId,
          data: {
            triggerType: type,
            triggerRule: values.triggerRule.name,
            projectId: values.project.id,
            keywordIds: values.keywordIds.length
              ? values.keywordIds.map(item => item.id)
              : undefined,
            emails: emails.length ? emails : undefined,
            threshold: Number(values.threshold),
          },
          successFn: () => {
            enqueueSnackbar(
              <CustomToast
                message='Success'
                submessage='The trigger has been created.'
              />,
              { action: closeSnackbarAction }
            );
            handleCloseModal();
            if (type === 'by Project') {
              onGetTriggersByProjects({
                id: currentAccount.accountId,
                page: paginationModel.page,
                limit: paginationModel.pageSize,
                sortBy: sortModel[0]?.field,
                sortOrder: sortModel[0]?.sort,
              });
            }
            if (type === 'by Keywords') {
              onGetTriggersByKeywords({
                id: currentAccount.accountId,
                page: paginationModel.page,
                limit: paginationModel.pageSize,
                sortBy: sortModel[0]?.field,
                sortOrder: sortModel[0]?.sort,
              });
            }
          },
          errorFn: (error: any) => {
            setStatus({ success: false });
            setSubmitting(false);
            setErrors(error);
          },
        });
      } else {
        setStatus({ success: false });
        setSubmitting(false);
      }
    },
  });

  const [keywordsOptions, setKeywordsOptions] = useState<IKeywordByProject[]>(
    []
  );
  const [isLastScrollData, setIsLastScrollData] = useState(false);

  const [keywordsByProjectPage, setKeywordsByProjectPage] = useState(1);

  const validateForm = useCallback(
    (values: typeof initialValues) => {
      formik.validateForm(values);
    },
    [formik]
  );

  const handleScroll = (event: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const listboxNode = event.currentTarget;
    if (
      formik.values.project &&
      listboxNode.scrollTop + listboxNode.clientHeight - 0.5 ===
        listboxNode.scrollHeight
    ) {
      if (!isLastScrollData) {
        const nextPage = keywordsByProjectPage + 1;
        setKeywordsByProjectPage(nextPage);
        onGetKeywordsByProject({
          id: currentAccount.accountId,
          projectId: formik.values.project.id,
          page: nextPage,
          limit: 20,
          successFn: (keywords: IKeywordByProject[]) => {
            if (!keywords.length) {
              setIsLastScrollData(true);
              return;
            } else {
              setKeywordsOptions([...keywordsOptions, ...keywords]);
            }
          },
        });
      }
    }
  };

  const handleCloseModal = () => {
    setKeywordsOptions([]);
    setIsLastScrollData(false);
    setKeywordsByProjectPage(1);
    handleClose();
    formik.resetForm();
  };

  useEffect(() => {
    if (!alertsTriggers.length && open) {
      onGetAlertsTriggersTypes();
    }
  }, [alertsTriggers, onGetAlertsTriggersTypes, open]);

  useEffect(() => {
    if (formik.values.project && currentAccount?.accountId) {
      onGetKeywordsByProject({
        id: currentAccount.accountId,
        projectId: formik.values.project.id,
        page: 1,
        limit: 20,
        successFn: (keywords: IKeywordByProject[]) => {
          setKeywordsOptions([...keywords]);
        },
      });
    }
  }, [currentAccount.accountId, formik.values.project, onGetKeywordsByProject]);

  useEffect(() => {
    if (open) {
      validateForm(formik.values);
    }
  }, [open]);

  useEffect(() => {
    if (open && currentAccount?.accountId) {
      onGetProjectsListForInvite({
        accountId: currentAccount.accountId,
        search: '',
      });
    }
  }, [currentAccount?.accountId, onGetProjectsListForInvite, open]);

  return (
    <Modal
      aria-labelledby='AddNoteModal'
      aria-describedby='EditNoteModal'
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <form onSubmit={formik.handleSubmit}>
          <FormikProvider value={formik}>
            <ModalWrapper>
              <ModalContainer container xs={12} sx={{ gap: '8px' }}>
                <Grid xs={12}>
                  <Grid item xs={12} sx={{ position: 'relative' }}>
                    <CloseDeletePopoverOne onClick={handleCloseModal}>
                      <CloseIcon fontSize='small' />
                    </CloseDeletePopoverOne>
                    <Typography
                      fontSize='18px'
                      fontWeight='600'
                      lineHeight='28px'
                      color='#101828'
                    >
                      {`Add a Trigger for the ${
                        type === 'by Project' ? 'Project' : 'Keywords'
                      }`}
                    </Typography>
                  </Grid>
                  <StylesLabelModal sx={{ paddingBottom: '32px' }}>
                    Select the project and keywords for this alert.
                  </StylesLabelModal>
                  <Grid
                    container
                    xs={12}
                    spacing={2}
                    paddingBottom={'16px'}
                    sx={{ marginLeft: '0' }}
                  >
                    <Grid item xs={9} sx={{ paddingLeft: '0 !important' }}>
                      <Autocomplete
                        sx={ModalAutocompleteStylesProps}
                        fullWidth
                        size={'small'}
                        id='triggerRule'
                        limitTags={2}
                        options={alertsTriggers}
                        getOptionLabel={option => option.name}
                        value={formik.values.triggerRule}
                        onChange={(_, value) => {
                          formik.setFieldValue(
                            'triggerRule',
                            value || undefined
                          );
                        }}
                        onBlur={formik.handleBlur}
                        popupIcon={<SelectIcon />}
                        renderInput={params => (
                          <TextField
                            {...params}
                            placeholder='Changes by more than'
                            name='changesByMoreThan'
                            error={
                              !!(
                                formik.touched.triggerRule &&
                                formik.errors.triggerRule
                              )
                            }
                          />
                        )}
                        PaperComponent={paperProps => {
                          const { children, ...restPaperProps } = paperProps;
                          return <Paper {...restPaperProps}>{children}</Paper>;
                        }}
                      />
                      {formik.touched.triggerRule &&
                        formik.errors?.triggerRule && (
                          <FormHelperText error id='triggerRule'>
                            <CustomFormHelperText
                              error={formik.errors.triggerRule.toString()}
                            />
                          </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={3} sx={{ paddingLeft: '8px !important' }}>
                      <ModalOutlinedInput
                        fullWidth
                        id='threshold'
                        size='small'
                        type='text'
                        name={`threshold`}
                        value={formik.values.threshold}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder={'10'}
                        error={
                          !!(
                            formik.touched.threshold && formik.errors?.threshold
                          )
                        }
                      />
                      {formik.touched.threshold && formik.errors?.threshold && (
                        <FormHelperText error id='threshold'>
                          <CustomFormHelperText
                            error={formik.errors?.threshold}
                          />
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid paddingBottom={'16px'}>
                    <Autocomplete
                      sx={ModalAutocompleteStylesProps}
                      fullWidth
                      size={'small'}
                      id='project'
                      limitTags={2}
                      options={projectsListForInvite.items}
                      getOptionLabel={option => option.projectName}
                      renderOption={(props, option) => {
                        return (
                          <StyledAutocompleteLi {...props} key={option.id}>
                            <Grid container gap={'8px'}>
                              <Grid item display={'flex'} alignItems={'center'}>
                                {option?.favicon ? (
                                  <img
                                    src={option.favicon}
                                    width={16}
                                    height={16}
                                    alt={'Company Logo URL'}
                                  />
                                ) : (
                                  <GlobeIcon />
                                )}
                              </Grid>
                              <Grid item>{option.projectName}</Grid>
                            </Grid>
                          </StyledAutocompleteLi>
                        );
                      }}
                      value={formik.values.project}
                      onChange={(_, value) => {
                        formik.setFieldValue('project', value || undefined);
                        formik.setFieldValue('keywordIds', []);
                      }}
                      onBlur={formik.handleBlur}
                      popupIcon={<SelectIcon />}
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder='Select project'
                          name='project'
                          error={
                            !!(formik.touched.project && formik.errors.project)
                          }
                        />
                      )}
                      PaperComponent={paperProps => {
                        const { children, ...restPaperProps } = paperProps;
                        return <Paper {...restPaperProps}>{children}</Paper>;
                      }}
                    />
                    {formik.touched.project && formik.errors?.project && (
                      <FormHelperText error id='project'>
                        <CustomFormHelperText
                          error={formik.errors.project.toString()}
                        />
                      </FormHelperText>
                    )}
                  </Grid>

                  {type !== 'by Project' ? (
                    <Grid paddingBottom={'16px'}>
                      <Autocomplete
                        sx={
                          formik.values.keywordIds.length
                            ? ModalMultipleAutocompleteStylesProps
                            : ModalAutocompleteStylesProps
                        }
                        disabled={!formik.values.project}
                        value={formik.values.keywordIds}
                        multiple
                        disableCloseOnSelect
                        fullWidth
                        size={'small'}
                        id='keywordIds'
                        limitTags={2}
                        options={keywordsOptions}
                        ListboxProps={{
                          onScroll: handleScroll,
                        }}
                        getOptionLabel={option => option.name}
                        renderOption={(props, option, { selected }) => {
                          return (
                            <StyledAutocompleteLi {...props} key={option.id}>
                              <CheckboxWrapper checked={selected} />
                              {getDeviceTypeIcon(option.deviceType.name)}
                              <Grid marginLeft={'8px'}>{option.name}</Grid>
                            </StyledAutocompleteLi>
                          );
                        }}
                        onChange={(_, value) => {
                          formik.setFieldValue(
                            'keywordIds',
                            value || undefined
                          );
                        }}
                        onBlur={formik.handleBlur}
                        popupIcon={<SelectIcon />}
                        renderInput={params => (
                          <TextField
                            {...params}
                            placeholder='Select keywords'
                            name='keywordIds'
                            error={
                              !!(
                                formik.touched.keywordIds &&
                                formik.errors.keywordIds
                              )
                            }
                          />
                        )}
                        PaperComponent={AutocompletePaper}
                      />
                      {formik.touched.keywordIds &&
                        formik.errors?.keywordIds && (
                          <FormHelperText error id='keywords'>
                            <CustomFormHelperText
                              error={formik.errors.keywordIds.toString()}
                            />
                          </FormHelperText>
                        )}
                    </Grid>
                  ) : null}

                  <FieldArray
                    name='emails'
                    render={arrayHelpers => {
                      return (
                        <Grid>
                          <Grid container gap={2}>
                            <Grid item xs={7}>
                              <InputLabel shrink>Email Address</InputLabel>
                            </Grid>
                            <Grid item xs={4}>
                              <InputLabel shrink>Alert via email</InputLabel>
                            </Grid>
                          </Grid>
                          {formik.values.emails.map((recipient, index) => {
                            const error =
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              formik.errors.emails?.[index]?.email as string;
                            return (
                              <Grid
                                key={index}
                                container
                                gap={2}
                                paddingBottom={'8px'}
                              >
                                <Grid item xs={7}>
                                  <ModalOutlinedInput
                                    fullWidth
                                    size='small'
                                    type='email'
                                    name={`emails[${index}].email`}
                                    placeholder={'email@example.com'}
                                    value={formik.values.emails[index].email}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={
                                      formik.touched.emails?.[index]?.email &&
                                      !!error
                                    }
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    helperText={
                                      formik.touched.emails?.[index]?.email &&
                                      error
                                    }
                                  />
                                  {formik.touched.emails?.[index]?.email &&
                                    error && (
                                      <Grid item xs={12}>
                                        <FormHelperText error>
                                          <CustomFormHelperText error={error} />
                                        </FormHelperText>
                                      </Grid>
                                    )}
                                </Grid>
                                <Grid item xs={4}>
                                  <CustomCheckbox
                                    icon={<CustomCheckboxImage />}
                                    checkedIcon={<CheckedIcon />}
                                    checked={
                                      formik.values.emails[index].subscribed
                                    }
                                    onChange={event =>
                                      formik.setFieldValue(
                                        `emails[${index}].subscribed`,
                                        event.target.checked
                                      )
                                    }
                                    sx={{ padding: '6.5px 8px' }}
                                  />
                                </Grid>
                              </Grid>
                            );
                          })}
                          {formik.touched.emails &&
                            formik.errors?.emails &&
                            typeof formik.errors?.emails === 'string' && (
                              <Grid paddingBottom={'16px'}>
                                <FormHelperText error id='triggerRule'>
                                  <CustomFormHelperText
                                    error={formik.errors.emails.toString()}
                                  />
                                </FormHelperText>
                              </Grid>
                            )}

                          <StylesAddAnotherRecipientButton
                            onClick={() =>
                              arrayHelpers.push({ email: '', subscribed: true })
                            }
                          >
                            <PlusIcon stroke={'#1A73E9'} />
                            Add another recipient
                          </StylesAddAnotherRecipientButton>

                          <FormHelperText
                            error={!!formik.errors.message}
                            id='message'
                          >
                            {formik.errors.message && (
                              <CustomFormHelperText
                                error={formik.errors.message}
                              />
                            )}
                          </FormHelperText>
                        </Grid>
                      );
                    }}
                  />

                  <ButtonsWrapper container xs={12} sx={{ paddingTop: '32px' }}>
                    <Grid item xs={extraSmallScreen && 12}>
                      <GrayButtonSmall
                        fullWidth={extraSmallScreen}
                        size='small'
                        outline
                        onClick={handleCloseModal}
                      >
                        Cancel
                      </GrayButtonSmall>
                    </Grid>
                    <Grid item xs={extraSmallScreen && 12}>
                      <PrimaryButtonSmall
                        fullWidth={extraSmallScreen}
                        variant='contained'
                        size='small'
                        type='submit'
                        disabled={formik.isSubmitting || !formik.isValid}
                        onClick={() => formik.handleSubmit()}
                      >
                        Add Alert
                      </PrimaryButtonSmall>
                    </Grid>
                  </ButtonsWrapper>
                </Grid>
              </ModalContainer>
            </ModalWrapper>
          </FormikProvider>
        </form>
      </Fade>
    </Modal>
  );
};

export default AddNewAlert;
