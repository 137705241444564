/**
 * Checks if the size of the provided file is within the allowable limit.
 *
 * This function compares the size of the given file against a predefined maximum
 * file size limit (3 MB) and returns a boolean indicating whether the file
 * can be selected or not.
 *
 * @param {File} file - The file to be checked for size.
 * @returns {boolean} True if the file size is less than or equal to the maximum limit, otherwise false.
 *
 * @example
 * const file = new File(['content'], 'example.txt', { type: 'text/plain', size: 2000000 });
 * const isValid = checkSize(file); // returns true if file size is <= 3MB
 */
export const checkSize = (file: File) => {
  const selectableMaxFileSize = 1024 * 1024 * 3; // 3 Megabyte
  return file.size <= selectableMaxFileSize;
};
