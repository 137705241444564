import React from 'react';
import { StylesYourCurrentPlan } from '../PricingTable/styles';
import { Grid } from '@mui/material';
import {
  CustomSwitch,
  StylesMonthlyYearly,
  StylesMonthsFree,
} from '../CustomPlan/styles';

interface PricingTableHeaderProps {
  userPlanName: string;
  planType: string;
  onSwitch: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

const PricingTableHeader: React.FC<PricingTableHeaderProps> = ({
  userPlanName,
  planType,
  onSwitch,
}) => {
  return (
    <Grid xs={12} paddingBottom={'44px'}>
      {userPlanName && (
        <StylesYourCurrentPlan paddingBottom={'24px'}>
          {`Your current plan: ${userPlanName}`}
        </StylesYourCurrentPlan>
      )}
      <Grid
        xs={12}
        container
        alignItems={'center'}
        justifyContent={'center'}
        spacing={2}
      >
        <StylesMonthlyYearly item>Monthly</StylesMonthlyYearly>
        <Grid item>
          <CustomSwitch checked={planType === 'yearly'} onChange={onSwitch} />
        </Grid>
        <StylesMonthlyYearly item>Yearly</StylesMonthlyYearly>
        <Grid item>
          <StylesMonthsFree>Get 2 months free</StylesMonthsFree>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PricingTableHeader;
