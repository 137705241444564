import { styled } from '@mui/system';
import { Grid, OutlinedInput } from '@mui/material';
import { Paper } from '../paper/styles';

const ModalWrapper = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 560px;

  overflow-y: scroll;
  max-height: calc(100vh - 50px);
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  ${props => props.theme.breakpoints.down('sm')} {
    transform: translate(calc(-50% - 24px), -50%);
    width: calc(100% - 48px);
    margin-right: 24px;
    margin-left: 24px;
  }
`;

const ModalContainer = styled(Grid)`
  padding: 24px;
  ${props => props.theme.breakpoints.down('sm')} {
    padding: 16px;
  }
`;

const ModalOutlinedInput = styled(OutlinedInput)`
  .MuiOutlinedInput-input {
    padding-top: 7.94px;
    padding-bottom: 7.94px;
  }
`;

const ModalAutocompleteStylesProps = {
  '.MuiOutlinedInput-input': {
    paddingTop: '1.94px !important',
    paddingBottom: '1.94px !important',
  },
};

const ModalMultipleAutocompleteStylesProps = {
  '.MuiOutlinedInput-root': {
    paddingTop: '4px !important',
    paddingBottom: '4px !important',
  },
};

export {
  ModalWrapper,
  ModalContainer,
  ModalOutlinedInput,
  ModalAutocompleteStylesProps,
  ModalMultipleAutocompleteStylesProps,
};
