import { Fade, Grid, Modal, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router';
import useAccountLimits from '../../../features/auth/hooks/useAccountLimits';
import { useAppSelector } from '../../../store';
import React, { useCallback, useMemo } from 'react';
import ROUTES from '../../../routes/constants';
import {
  ModalContainer,
  ModalWrapper,
} from '../../../features/projects/components/Projects/components/ProjectRedactorModal/styles';
import {
  CloseDeletePopoverOne,
  HeaderContainer,
} from '../../../features/auth/components/Settings/components/Account/styles';
import CloseIcon from '@mui/icons-material/Close';
import AlertCircleIcon from '../../images/AlertCircleIcon';
import {
  StyledLabel,
  StyledMainText,
} from '../KeywordsLimitExceededModal/styles';
import { ButtonsWrapper } from '../../../features/auth/components/Settings/components/Team/components/customTableStyles';
import { GrayButtonSmall, PrimaryButtonSmall } from '../../buttons/styles';
import { useProjects } from '../../../hooks';

const CannotAddExcessKeywordsModal = () => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const navigate = useNavigate();

  const { onCloseCannotAddExcessKeywordsModal } = useAccountLimits();

  const { onSetNewProjectModal } = useProjects();

  const accountLimit = useAppSelector(state => state.auth.accountLimit);
  const cannotAddExcessKeywords = useAppSelector(
    state => state.auth.accountLimitErrors.cannotAddExcessKeywords
  );
  const keywordsAdd = useAppSelector(
    state => state.auth.accountLimitErrors.cannotAddExcessKeywords.keywordsAdd
  );
  const desktopAndMobile = useAppSelector(
    state =>
      state.auth.accountLimitErrors.cannotAddExcessKeywords.desktopAndMobile
  );
  const googleMyBusinessOrYouTube = useAppSelector(
    state =>
      state.auth.accountLimitErrors.cannotAddExcessKeywords
        .googleMyBusinessOrYouTube
  );
  const searchEngine = useAppSelector(
    state => state.auth.accountLimitErrors.cannotAddExcessKeywords.searchEngine
  );

  const numberOfDailyUpdatesOfKeywordPositions = useMemo(
    () =>
      accountLimit?.defaultAccountLimits
        ?.numberOfDailyUpdatesOfKeywordPositions || 0,
    [accountLimit?.defaultAccountLimits?.numberOfDailyUpdatesOfKeywordPositions]
  );
  const numberOfKeywords = useMemo(
    () => accountLimit?.accountLimitsUsed?.numberOfKeywords || 0,
    [accountLimit?.accountLimitsUsed?.numberOfKeywords]
  );

  const youCanAddKeywords = useMemo(
    () => numberOfDailyUpdatesOfKeywordPositions - numberOfKeywords,
    [numberOfDailyUpdatesOfKeywordPositions, numberOfKeywords]
  );

  const handleUpgradeAccount = useCallback(() => {
    navigate(ROUTES.upgradePlan);
    onCloseCannotAddExcessKeywordsModal();
  }, [navigate, onCloseCannotAddExcessKeywordsModal]);

  const handleGotIt = () => {
    onSetNewProjectModal(false);
    onCloseCannotAddExcessKeywordsModal();
  };

  return (
    <Modal
      open={cannotAddExcessKeywords.open}
      onClose={onCloseCannotAddExcessKeywordsModal}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={cannotAddExcessKeywords.open}>
        <ModalWrapper>
          <ModalContainer>
            <HeaderContainer>
              <CloseDeletePopoverOne
                color='error'
                onClick={onCloseCannotAddExcessKeywordsModal}
              >
                <CloseIcon fontSize='small' />
              </CloseDeletePopoverOne>
              <AlertCircleIcon />
              <StyledLabel padding='24px 0'>
                Cannot Add Excess Keywords
              </StyledLabel>
            </HeaderContainer>

            <Grid item xs={12}>
              <StyledMainText>
                {`You are attempting to add ${keywordsAdd} keywords, but`}{' '}
                <b>{`your account can only add ${youCanAddKeywords} more keywords.`}</b>
              </StyledMainText>
            </Grid>
            {desktopAndMobile && (
              <>
                <br />
                <Grid item xs={12}>
                  <StyledMainText>
                    <b>
                      <u>Note</u>: Selecting &apos;Desktop & Mobile&apos; counts
                      each keyword as two, one for desktop and one for mobile.
                    </b>
                  </StyledMainText>
                </Grid>
              </>
            )}

            {googleMyBusinessOrYouTube && (
              <>
                <br />
                <Grid item xs={12}>
                  <StyledMainText>
                    <b>
                      <u>Note</u>:
                      {` Selecting ${searchEngine} will consume 5
                      keyword credits for each keyword.`}
                    </b>
                  </StyledMainText>
                </Grid>
              </>
            )}

            <br />
            <Grid item xs={12}>
              <StyledMainText>
                Please adjust the number of keywords or upgrade your plan to
                increase your limit.
              </StyledMainText>
            </Grid>

            <ButtonsWrapper container xs={12}>
              <Grid item xs={extraSmallScreen && 12}>
                <GrayButtonSmall
                  fullWidth={extraSmallScreen}
                  size='small'
                  outline
                  onClick={handleUpgradeAccount}
                >
                  Upgrade Account
                </GrayButtonSmall>
              </Grid>
              <Grid item xs={extraSmallScreen && 12}>
                <PrimaryButtonSmall
                  fullWidth={extraSmallScreen}
                  variant='contained'
                  size='small'
                  type='submit'
                  onClick={handleGotIt}
                >
                  Got it
                </PrimaryButtonSmall>
              </Grid>
            </ButtonsWrapper>
          </ModalContainer>
        </ModalWrapper>
      </Fade>
    </Modal>
  );
};

export default CannotAddExcessKeywordsModal;
