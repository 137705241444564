/**
 * Renders the title for a list item based on the provided parameter.
 * @param param - The parameter to determine the list item title ('created', 'type', 'lastViewed', 'link').
 * @returns The corresponding title as a string, or an empty string if the parameter does not match any case.
 */
function renderListItemTitle(param: string) {
  switch (param) {
    case 'created':
      return 'Created';
    case 'type':
      return 'Type';
    case 'lastViewed':
      return 'Last viewed';
    case 'link':
      return 'Link';
    default:
      return '';
  }
}

export { renderListItemTitle };
