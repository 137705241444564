import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router';

import { useProjects, useProjectsTable } from '../../../../../../../../hooks';
import CheckboxWrapper from '../../../../../../../auth/components/Settings/components/Team/components/CheckboxWrapper';
import Pagination from '../Pagination/Pagination';
import CustomNoRowsOverlay from './CustomNoRowsOverlay';
import ROUTES from '../../../../../../../../routes/constants';

import { CustomDataGrid } from './styles';
import { GridRowParams } from '@mui/x-data-grid';
import { encryptData } from '../../../../../../../../utils/cryptoJs';
import { TableNoData } from '../../../../../../../../shared/components';
import { arraysAreEqual } from '../../../../../../../../utils';
import { useAppSelector } from '../../../../../../../../store';
import {
  setTableColumns,
  TABLES_STORAGE_KEYS,
} from '../../../../../../tablesColumnStase';

const Table = () => {
  const navigate = useNavigate();

  const { onSetSortModelProjects, onSetRowSelectionModel } = useProjects();

  const pageSize = useAppSelector(
    state => state.projects.tables.projects.paginationModel.pageSize
  );
  const projects = useAppSelector(state => state.projects.projects);

  const [itemCount, setItemCount] = useState(
    projects?.meta?.itemCount || pageSize
  );

  const deleteElements = useAppSelector(
    state => state.projects.tables.projects.deleteElements
  );
  const columnVisibilityModel = useAppSelector(
    state => state.projects.tables.projects.columnVisibilityModel
  );
  const rowSelectionModel = useAppSelector(
    state => state.projects.tables.projects.rowSelectionModel
  );
  const search = useAppSelector(state => state.projects.tables.projects.search);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const openTariffPlanBanner = useAppSelector(
    state => state.auth.openTariffPlanBanner
  );

  const { columns, columnsSkeleton } = useProjectsTable();

  const rows = useMemo(() => {
    const arr1 = [...(projects?.items?.length ? projects.items : [])];
    const arr2 = [...deleteElements];

    return arr1.filter(item => !arr2.includes(item.id));
  }, [deleteElements, projects?.items]);

  const isRowSelectable = (params: GridRowParams) => {
    if (currentAccount?.role?.name === 'ViewOnly') {
      return false;
    }
    return params.row.name !== 'Uncategorized Projects';
  };

  const NoRowsOverlay = !projects?.items
    ? () => <div />
    : search && !projects?.items?.length
    ? CustomNoRowsOverlay
    : !projects?.items?.length
    ? TableNoData
    : Box;

  const arrayOfObjects = useMemo(
    () => Array.from({ length: itemCount }, (_, index) => ({ id: index + 1 })),
    [itemCount]
  );

  const renderRows = useMemo(() => {
    return !projects?.items ? arrayOfObjects : rows;
  }, [arrayOfObjects, projects?.items, rows]);

  const renderColumns = useMemo(() => {
    return !projects?.items ? columnsSkeleton : columns;
  }, [columns, columnsSkeleton, projects?.items]);

  useEffect(() => {
    const filtered = projects?.items
      ?.filter(obj => rowSelectionModel.includes(obj.id))
      ?.map(item => item.id)
      .filter(item => !deleteElements.includes(item));

    if (filtered && !arraysAreEqual(rowSelectionModel, filtered)) {
      onSetRowSelectionModel(filtered);
    }
  }, [
    deleteElements,
    onSetRowSelectionModel,
    projects?.items,
    rowSelectionModel,
  ]);

  useEffect(() => {
    if (projects?.meta?.itemCount) {
      setItemCount(projects.meta.itemCount);
    }
  }, [projects?.meta?.itemCount]);

  useEffect(() => {
    setTableColumns(TABLES_STORAGE_KEYS.projectsPage, columnVisibilityModel);
  }, [columnVisibilityModel]);

  const pinnedColumns = useMemo(
    () => ({
      right:
        currentAccount?.accountId && currentAccount?.role?.name !== 'ViewOnly'
          ? ['actions']
          : [],
    }),
    [currentAccount?.role?.name, currentAccount?.accountId]
  );

  const handleRowClick = useCallback(
    (params: GridRowParams<any>) => {
      if (projects?.items)
        navigate(
          ROUTES.dynamic.projectExpanded(encryptData(params.id.toString()))
        );
    },
    [navigate, projects?.items]
  );

  return (
    <Grid item xs={12}>
      <CustomDataGrid
        pinnedColumns={pinnedColumns}
        autoHeight
        columnHeaderHeight={40}
        rowHeight={40}
        rows={renderRows}
        onRowClick={handleRowClick}
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={onSetRowSelectionModel}
        columns={renderColumns}
        onSortModelChange={onSetSortModelProjects}
        columnVisibilityModel={columnVisibilityModel}
        isRowSelectable={isRowSelectable}
        components={{
          BaseCheckbox: CheckboxWrapper,
          NoRowsOverlay: NoRowsOverlay,
        }}
        keepNonExistentRowsSelected
        hideFooterPagination
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnMenu
        isSelectedRow
        columnBuffer={100}
        columnThreshold={100}
        $openTariffPlanBanner={openTariffPlanBanner}
      />
      <Pagination />
    </Grid>
  );
};

export default Table;
