import React from 'react';

const BaiduIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
  >
    <g clipPath='url(#clip0_9233_77527)'>
      <path
        d='M15.3 0H2.7C1.20883 0 0 1.20883 0 2.7V15.3C0 16.7912 1.20883 18 2.7 18H15.3C16.7912 18 18 16.7912 18 15.3V2.7C18 1.20883 16.7912 0 15.3 0Z'
        fill='white'
      />
      <path
        d='M4.60482 8.82422C6.04623 8.50781 5.83529 6.78516 5.80014 6.43359C5.72982 5.83594 5.06186 4.85156 4.11264 4.92188C2.95248 5.02734 2.81186 6.67969 2.81186 6.67969C2.63607 7.45312 3.16342 9.14062 4.60482 8.82422ZM7.2767 5.94141C8.05014 5.94141 8.68295 5.02734 8.68295 3.90234C8.68295 2.77734 8.05014 1.86328 7.2767 1.86328C6.46811 1.86328 5.83529 2.77734 5.83529 3.90234C5.83529 5.02734 6.46811 5.94141 7.2767 5.94141ZM10.6517 6.08203C11.7415 6.22266 12.4095 5.09766 12.5501 4.21875C12.6908 3.375 11.9876 2.39062 11.2494 2.21484C10.5111 2.03906 9.56186 3.23438 9.49154 4.04297C9.38607 4.99219 9.59701 5.94141 10.6517 6.08203ZM14.8704 7.52344C14.8704 7.10156 14.5189 5.87109 13.2533 5.87109C11.9876 5.87109 11.8119 7.03125 11.8119 7.875C11.8119 8.64844 11.8822 9.73828 13.4642 9.70312C15.0462 9.66797 14.8704 7.91016 14.8704 7.52344ZM13.2533 11.1094C13.2533 11.1094 11.6361 9.84375 10.6517 8.47266C9.38607 6.46875 7.52279 7.27734 6.92514 8.29688C6.29232 9.31641 5.34311 9.98438 5.20248 10.1602C5.06186 10.3008 3.23373 11.3203 3.65561 13.1133C4.04232 14.9414 5.48373 14.9062 5.48373 14.9062C5.48373 14.9062 6.53842 15.0117 7.76889 14.7305C8.99936 14.4492 10.054 14.8008 10.054 14.8008C10.054 14.8008 12.9017 15.75 13.7103 13.9219C14.4837 12.0586 13.2533 11.1094 13.2533 11.1094Z'
        fill='#2319DC'
      />
      <path
        d='M7.52413 9.35156V10.5469H6.53976C6.53976 10.5469 5.52023 10.6523 5.16866 11.7773C5.0632 12.5156 5.30929 12.9727 5.34445 13.043C5.3796 13.1484 5.69601 13.7109 6.5046 13.8516H8.36788V9.35156H7.52413ZM7.48898 13.1133H6.7507C6.7507 13.1133 6.22335 13.0781 6.08273 12.4805C5.97726 12.2344 6.08273 11.918 6.11788 11.7773C6.15304 11.6719 6.32882 11.3906 6.71554 11.2852H7.48898V13.1133ZM8.82491 10.6523V13.043C8.82491 13.043 8.86007 13.6406 9.66866 13.8516H11.8132V10.6523H10.8991V13.043H10.0202C10.0202 13.043 9.73898 13.0078 9.66866 12.7969V10.6523H8.82491Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_9233_77527'>
        <rect width='18' height='18' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default BaiduIcon;
