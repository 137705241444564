import React from 'react';
import { List } from '@mui/material';

import DeleteProjectsAction from './DeleteProjectsAction';
import { KeywordsActionProps } from './types';

import { MainContainer } from '../styles';

const ListActions: React.FC<KeywordsActionProps> = ({ popupClose }) => {
  return (
    <MainContainer>
      <List>
        {/*<Divider*/}
        {/*  orientation='horizontal'*/}
        {/*  flexItem*/}
        {/*  sx={{*/}
        {/*    margin: '8px 0',*/}
        {/*    color: '#EDEDED',*/}
        {/*  }}*/}
        {/*/>*/}

        <DeleteProjectsAction popupClose={popupClose} />
      </List>
    </MainContainer>
  );
};

export default ListActions;
