import { GetUpdatedSharedLinkPassword } from './types';
import { socket } from '../../constants/socketConfig';

const getUpdatedSharedLinkPasswordToServer = (
  successFn: (data: GetUpdatedSharedLinkPassword) => void
) => {
  socket.off('updated-shared-link-password');
  socket.on(
    'updated-shared-link-password',
    (data: GetUpdatedSharedLinkPassword) => {
      successFn(data);
    }
  );
};

export { getUpdatedSharedLinkPasswordToServer };
