import React from 'react';

const GlobeIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <g clipPath='url(#clip0_8820_76710)'>
      <path
        d='M1.3335 8.00001H14.6668M1.3335 8.00001C1.3335 11.6819 4.31826 14.6667 8.00016 14.6667M1.3335 8.00001C1.3335 4.31811 4.31826 1.33334 8.00016 1.33334M14.6668 8.00001C14.6668 11.6819 11.6821 14.6667 8.00016 14.6667M14.6668 8.00001C14.6668 4.31811 11.6821 1.33334 8.00016 1.33334M8.00016 1.33334C9.66768 3.15891 10.6153 5.52803 10.6668 8.00001C10.6153 10.472 9.66768 12.8411 8.00016 14.6667M8.00016 1.33334C6.33264 3.15891 5.38499 5.52803 5.3335 8.00001C5.38499 10.472 6.33264 12.8411 8.00016 14.6667'
        stroke='#667085'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_8820_76710'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default GlobeIcon;
