import React from 'react';

import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';

import ListActions from './components/ListActions';
import EllipsisListActions from '../EllipsisMenu/ListActions';
import { useNotes } from '../../../../../../../../hooks';
import { PrimaryButtonCustom } from '../../../../../../../../shared/buttons/styles';
import { Popover } from '../../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import DownIcon from '../../../../../../../../shared/images/DownIcon';

const ActionsMenu = () => {
  const {
    tables: {
      projectNotes: { rowSelectionModel },
    },
  } = useNotes();
  return (
    <PopupState variant='popover'>
      {popupState => (
        <>
          <PrimaryButtonCustom
            $padding='8px 12px'
            variant='contained'
            size='small'
            endIcon={<DownIcon />}
            {...bindTrigger(popupState)}
          >
            Actions
          </PrimaryButtonCustom>

          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {rowSelectionModel.length !== 1 ? (
              <ListActions popupClose={popupState.close} />
            ) : (
              <EllipsisListActions
                popupClose={popupState.close}
                projectNoteId={Number(rowSelectionModel[0])}
              />
            )}
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export default ActionsMenu;
