import { styled } from '@mui/system';
import { ModalWrapper } from '../../../auth/components/Settings/components/Team/components/customTableStyles';

const StylesReviewUpdateSubscriptionModal = styled(ModalWrapper)`
  width: 890px;

  @media (max-width:900px) {
    max-width: 550px;
  },
`;

export { StylesReviewUpdateSubscriptionModal };
