import React from 'react';
import {
  ListItemTypography,
  StyledListItem,
} from '../../../../../../../projects/components/ProjectExpanded/components/KeywordRankings/components/KeywordRankingsTable/components/EllipsisMenu/styles';
import { CopySharedLinkActionProps } from './types';
import ExternalLinkIcon from '../../../../../../../../shared/images/ExternalLinkIcon';
import { StyledListItemButton } from '../../../../../../../../shared/buttons/styles';
import { useSharedLinks } from '../../../../../../../../hooks';

const OpenInNewTabAction: React.FC<CopySharedLinkActionProps> = ({
  link,
  popupClose,
}) => {
  const { onSetRowSelectionModelSharedLinks } = useSharedLinks();

  const onOpen = () => {
    onSetRowSelectionModelSharedLinks([]);
    window.open(link);
    popupClose();
  };

  return (
    <StyledListItem>
      <StyledListItemButton onClick={onOpen}>
        <ExternalLinkIcon />
        <ListItemTypography marginLeft='8px'>
          Open in New Tab
        </ListItemTypography>
      </StyledListItemButton>
    </StyledListItem>
  );
};

export default OpenInNewTabAction;
