/**
 * Renders the title for a list item based on the provided parameter.
 * @param param - The parameter to determine the list item title.
 * @returns The corresponding title as a string, or an empty string if the parameter does not match any case.
 */
function renderListItemTitle(param: string) {
  switch (param) {
    case 'name':
      return 'Name';
    case 'created':
      return 'Created';
    case 'createdAt':
      return 'Created';
    case 'createdBy':
      return 'Created by';
    case 'lastModified':
      return 'Last modified';
    case 'deviceType':
      return 'Type';
    case 'url':
      return 'URL';
    case 'totalKeywords':
      return 'Total Keywords';
    case 'improved':
      return 'Improved';
    case 'declined':
      return 'Declined';
    case 'noChange':
      return 'No Change';
    case 'dailyAverage':
      return 'Daily Average';
    case 'frequency':
      return 'Frequency';
    case 'updatedAt':
      return 'Updated';
    default:
      return '';
  }
}

/**
 * Renders the title for a root list item based on the provided parameter.
 * @param param - The parameter to determine the root list item title.
 * @returns The corresponding title as a string, or an empty string if the parameter does not match any case.
 */
function renderRootListItemTitle(param: string) {
  switch (param) {
    case 'name':
      return 'Name';
    case 'created':
      return 'Created';
    case 'createdBy':
      return 'Created by';
    case 'updatedAt':
      return 'Last modified';
    default:
      return '';
  }
}

export { renderListItemTitle, renderRootListItemTitle };
