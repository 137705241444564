import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StylesText = styled(Grid)`
  color: #101828;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const StylesSupportingText = styled(Grid)`
  color: #475467;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export { StylesText, StylesSupportingText };
