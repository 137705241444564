import React from 'react';

interface Props {
  stroke?: string;
}

const EmailIcon: React.FC<Props> = ({ stroke }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <path
      d='M2.66683 2.66602H13.3335C14.0668 2.66602 14.6668 3.26602 14.6668 3.99935V11.9993C14.6668 12.7327 14.0668 13.3327 13.3335 13.3327H2.66683C1.9335 13.3327 1.3335 12.7327 1.3335 11.9993V3.99935C1.3335 3.26602 1.9335 2.66602 2.66683 2.66602Z'
      stroke={stroke || 'rgb(102, 112, 133)'}
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.6668 4L8.00016 8.66667L1.3335 4'
      stroke={stroke || 'rgb(102, 112, 133)'}
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default EmailIcon;
