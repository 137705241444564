import React, { useState } from 'react';
import {
  Fade,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  ButtonsWrapper,
  CloseDeletePopoverOne,
  CustomModal,
  HeaderContainer,
  ModalContainer,
} from '../../../Account/styles';
import CloseIcon from '@mui/icons-material/Close';
import Trash from '../../../../../../../../shared/images/Trash';
import { CustomFormHelperText } from '../../../../../../../../shared/components';
import {
  ErrorButtonSmall,
  GrayButtonSmall,
} from '../../../../../../../../shared/buttons/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CancelSubscriptionModalProps } from './types';
import { useAuth } from '../../../../../../../../hooks';
import { useAppSelector } from '../../../../../../../../store';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { PasswordStateLabel } from '../../../../../RegisterForm/styles';

/**
 * CancelSubscriptionModal component renders a modal that prompts the user to enter their password to confirm the cancellation of their subscription.
 */
const CancelSubscriptionModal: React.FC<CancelSubscriptionModalProps> = ({
  handleOpenSadToSeeYouGoModal,
  handleClose,
  open,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const { onPasswordConfirmation } = useAuth();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const formik = useFormik({
    initialValues: {
      deletionReason: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required('Password is required.'),
    }),
    onSubmit: (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
      onPasswordConfirmation({
        password: formik.values.password,
        id: currentAccount.accountId,
        successFn: () => {
          handleClose();
          resetForm();
          handleOpenSadToSeeYouGoModal();
        },
        errorFn: (error: any) => {
          setStatus({ success: false });
          setSubmitting(false);
          setErrors(error);
        },
      });
    },
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleCloseAndReset = () => {
    handleClose();
    formik.resetForm();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        disableAutoFocus
        open={open}
        onClose={handleCloseAndReset}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <CustomModal>
            <ModalContainer>
              <HeaderContainer>
                <CloseDeletePopoverOne onClick={handleCloseAndReset}>
                  <CloseIcon fontSize='small' />
                </CloseDeletePopoverOne>
                <Trash />
                <Typography variant='h6' fontWeight='600' paddingTop='29px'>
                  Cancel Subscription?
                </Typography>
              </HeaderContainer>

              <div style={{ paddingTop: '24px' }}>
                <InputLabel shrink htmlFor='confirm_password'>
                  <Typography variant='h6' fontWeight='500' color='#475467'>
                    Enter your password to continue
                  </Typography>
                </InputLabel>
                <OutlinedInput
                  size='small'
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  id='password'
                  name='password'
                  placeholder='Enter your account password'
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={!!(formik.touched.password && formik.errors.password)}
                  endAdornment={
                    <InputAdornment
                      position='end'
                      onClick={handleClickShowPassword}
                      sx={{ cursor: 'pointer' }}
                    >
                      <IconButton
                        aria-label='toggle password visibility'
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                      <PasswordStateLabel>
                        {showPassword ? <b>Hide</b> : <b>Show</b>}
                      </PasswordStateLabel>
                    </InputAdornment>
                  }
                />
                {formik.touched.password && formik.errors.password && (
                  <FormHelperText error id='password'>
                    <CustomFormHelperText error={formik.errors.password} />
                  </FormHelperText>
                )}
              </div>

              <ButtonsWrapper container xs={12}>
                <Grid item xs={extraSmallScreen && 12}>
                  <GrayButtonSmall
                    fullWidth={extraSmallScreen}
                    size='medium'
                    outline
                    onClick={handleCloseAndReset}
                  >
                    Cancel
                  </GrayButtonSmall>
                </Grid>
                <Grid item xs={extraSmallScreen && 12}>
                  <ErrorButtonSmall
                    fullWidth={extraSmallScreen}
                    onClick={() => formik.handleSubmit()}
                    disabled={!formik.isValid || !formik.values.password}
                    type='submit'
                    variant='contained'
                    color='error'
                  >
                    Continue
                  </ErrorButtonSmall>
                </Grid>
              </ButtonsWrapper>
            </ModalContainer>
          </CustomModal>
        </Fade>
      </Modal>
    </form>
  );
};

export default CancelSubscriptionModal;
