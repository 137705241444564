import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StyledKeywordRankingsTable = styled(Grid)`
  position: relative;
  background: white;
  border-radius: 8px 8px 0 0;
`;

const StyledArrow = styled('div')`
  position: absolute;
  bottom: -19.5px;
  left: 5%;
`;

export { StyledKeywordRankingsTable, StyledArrow };
