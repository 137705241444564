import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

/**
 * Downloads the content of a referenced HTML element as either a PNG image or a PDF document.
 *
 * This function captures the content of the specified element using `html2canvas`, converts it to an image,
 * and then generates a PDF document if requested. The user can choose to download the content as either
 * a PNG or a PDF by specifying the `type` parameter.
 *
 * @async
 * @function downloadPDForPNG
 * @param {React.RefObject<HTMLInputElement>} ref - A React ref pointing to the HTML element to capture.
 * @param {'PNG' | 'PDF'} type - The type of file to download. Accepts 'PNG' to download as an image or 'PDF' to download as a PDF document.
 * @returns {Promise<void>} A promise that resolves when the download action has been initiated.
 * @throws {Error} Throws an error if the reference is not valid or if the download fails.
 *
 * @example
 * // Usage example within a component:
 * const ref = useRef<HTMLDivElement>(null);
 * const handleDownload = () => {
 *   downloadPDForPNG(ref, 'PDF');
 * };
 */
export const downloadPDForPNG = async (
  ref: React.RefObject<HTMLInputElement>,
  type: string
) => {
  if (ref.current) {
    const canvas = await html2canvas(ref.current);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF();

    const contentWidth = pdf.internal.pageSize.getWidth() - 20;
    const contentHeight = pdf.internal.pageSize.getHeight() - 20;

    const imgAspectRatio = canvas.width / canvas.height;
    let imgWidth = contentWidth;
    let imgHeight = contentWidth / imgAspectRatio;

    if (imgHeight > contentHeight) {
      imgHeight = contentHeight;
      imgWidth = contentHeight * imgAspectRatio;
    }

    pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);

    const pdfBlob = pdf.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);

    if (type === 'PNG') {
      const a = document.createElement('a');
      a.href = imgData;
      a.download = 'Graph.png';
      a.click();
    }

    if (type === 'PDF') {
      const a = document.createElement('a');
      a.href = pdfUrl;
      a.download = 'Graph.pdf';
      a.click();
    }

    URL.revokeObjectURL(pdfUrl);
  }
};
