import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useLocation } from 'react-router-dom';
import { useKeywordRankHistory, useProjects } from '../../../../../../hooks';
import ROUTES from '../../../../../../routes/constants';
import { BreadcrumbLink } from '../../../../../../shared/Breadcrumbs/styled';
import { useParams } from 'react-router';
import { useAppSelector } from '../../../../../../store';

const BreadcrumbsMain = () => {
  const location = useLocation();
  const { projectId, keywordId } = useParams();

  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );

  const { keywordInfo } = useKeywordRankHistory();

  const pathSnippets = location.pathname.split('/').filter(i => i);

  const breadcrumbNameMap: Record<string, string> = {
    [ROUTES.projects]: 'Projects',
    [ROUTES.dynamic.projectExpanded(
      `${projectId}`
    )]: `${projectExpanded?.projectName}`,
    [ROUTES.dynamic.keywordRankHistory(
      `${projectId}`,
      `${keywordId}`
    )]: `${keywordInfo?.keyword} `,
  };

  const extraBreadcrumbItems = pathSnippets.map((i, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return i !== 'keywords' && breadcrumbNameMap[url] ? (
      <BreadcrumbLink key={index} to={url}>
        {breadcrumbNameMap[url] !== 'undefined' ? breadcrumbNameMap[url] : ''}
      </BreadcrumbLink>
    ) : null;
  });

  const breadcrumbItems: JSX.Element[] = [
    <BreadcrumbLink key='Dashboard' to='#'>
      Dashboard
    </BreadcrumbLink>,
  ].concat(extraBreadcrumbItems.filter(item => item !== null) as JSX.Element[]);

  return (
    <MuiBreadcrumbs
      separator={<NavigateNextIcon fontSize='small' />}
      aria-label='breadcrumb'
    >
      {breadcrumbItems}
    </MuiBreadcrumbs>
  );
};

export default BreadcrumbsMain;
