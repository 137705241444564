import { useAppDispatch, useAppSelector } from '../../../store';
import { useCallback } from 'react';
import {
  updateCompetitorsBalanceCount,
  updateEmailReportsBalanceCount,
  updateDailyKeywordsBalanceCount,
  updateNotesBalanceCount,
  updateTriggersBalanceCount,
  updateUsersBalanceCount,
  updateMonthlyKeywordsBalanceCount,
  openKeywordsLimitExceededModal,
  closeKeywordsLimitExceededModal,
  closeEmailReportsLimitExceededModal,
  openEmailReportsLimitExceededModal,
  openSharedLinksLimitExceededModal,
  closeSharedLinksLimitExceededModal,
  openUsersLimitExceededModal,
  closeUsersLimitExceededModal,
  updateSharedLinksBalanceCount,
  closeNotesLimitExceededModal,
  openNotesLimitExceededModal,
  updateProjectsBalanceCount,
  openCompetitorsLimitExceededModal,
  closeCompetitorsLimitExceededModal,
  openTagsPerKeywordLimitExceededModal,
  closeTagsPerKeywordLimitExceededModal,
  openTagsPerProjectLimitExceededModal,
  closeTagsPerProjectLimitExceededModal,
  updateInvitationsBalanceCount,
  closeRefreshesLimitExceededModal,
  openRefreshesLimitLimitExceededModal,
  openCannotAddExcessKeywordsModal,
  closeCannotAddExcessKeywordsModal,
  openKeywordUpdateScheduledModal,
  closeKeywordUpdateScheduledModal,
  closeCannotRefreshExcessKeywordsModal,
  openCannotRefreshExcessKeywordsModal,
  openYouveReachedYourRefreshLimitForToday,
  closeYouveReachedYourRefreshLimitForToday,
  setFreeTrialModal,
  setSubscriptionModal,
  setActionRequiredCancelSubscriptionModal,
  setNoticeAccountDeletionCancelledModal,
  openTriggersLimitExceededModal,
  closeTriggersLimitExceededModal,
} from '../redux/actions';
import { IUpdateBalanceCount } from '../types';
import { roundNegativeToZero } from '../../../utils';

export default function useAccountLimits() {
  const accountLimit = useAppSelector(state => state.auth.accountLimit);
  const billing = useAppSelector(state => state.auth.payment.billing);

  const dispatch = useAppDispatch();

  const onUpdateEmailReportsBalanceCount = useCallback(
    (value: IUpdateBalanceCount) => {
      dispatch(updateEmailReportsBalanceCount(value));
    },
    [dispatch]
  );

  const onUpdateTriggersBalanceCount = useCallback(
    (value: IUpdateBalanceCount) => {
      dispatch(updateTriggersBalanceCount(value));
    },
    [dispatch]
  );

  const onUpdateUsersBalanceCount = useCallback(
    (value: IUpdateBalanceCount) => {
      dispatch(updateUsersBalanceCount(value));
    },
    [dispatch]
  );

  const onUpdateInvitationsBalanceCount = useCallback(
    (value: IUpdateBalanceCount) => {
      dispatch(updateInvitationsBalanceCount(value));
    },
    [dispatch]
  );

  const onUpdateCompetitorsBalanceCount = useCallback(
    (value: IUpdateBalanceCount) => {
      dispatch(updateCompetitorsBalanceCount(value));
    },
    [dispatch]
  );

  const onUpdateNotesBalanceCount = useCallback(
    (value: IUpdateBalanceCount) => {
      dispatch(updateNotesBalanceCount(value));
    },
    [dispatch]
  );

  const onUpdateDailyKeywordsBalanceCount = useCallback(
    (value: IUpdateBalanceCount) => {
      dispatch(updateDailyKeywordsBalanceCount(value));
    },
    [dispatch]
  );

  const onUpdateMonthlyKeywordsBalanceCount = useCallback(
    (value: IUpdateBalanceCount) => {
      dispatch(updateMonthlyKeywordsBalanceCount(value));
    },
    [dispatch]
  );

  const onUpdateSharedLinksBalanceCount = useCallback(
    (value: IUpdateBalanceCount) => {
      dispatch(updateSharedLinksBalanceCount(value));
    },
    [dispatch]
  );

  const onUpdateProjectsBalanceCount = useCallback(
    (value: IUpdateBalanceCount) => {
      dispatch(updateProjectsBalanceCount(value));
    },
    [dispatch]
  );

  const onCheckKeywordLimit = useCallback(() => {
    if (!accountLimit) return;

    const numberOfKeywords =
      accountLimit?.accountLimitsUsed?.numberOfKeywords || 0;

    const numberOfDailyUpdatesOfKeywordPositions =
      accountLimit?.defaultAccountLimits
        .numberOfDailyUpdatesOfKeywordPositions || 0;

    if (numberOfKeywords >= numberOfDailyUpdatesOfKeywordPositions) {
      dispatch(
        openKeywordsLimitExceededModal({
          open: true,
        })
      );
      return true;
    }
    return;
  }, [accountLimit, dispatch]);

  const onCloseKeywordsLimitExceededModal = useCallback(() => {
    dispatch(closeKeywordsLimitExceededModal());
  }, [dispatch]);

  const onCheckRefreshesLimitLimit = useCallback(
    (keywordsAddLength: number) => {
      if (!accountLimit) return;

      const defaultNumberOfDailyUpdatesOfKeywordPositions =
        accountLimit.defaultAccountLimits
          .numberOfDailyUpdatesOfKeywordPositions;

      const accountLimitsUsed =
        defaultNumberOfDailyUpdatesOfKeywordPositions -
        accountLimit.balanceAccountLimits
          .currentNumberOfDailyUpdatesOfKeywordPositions +
        keywordsAddLength;

      const youCanAdd = roundNegativeToZero(
        defaultNumberOfDailyUpdatesOfKeywordPositions -
          defaultNumberOfDailyUpdatesOfKeywordPositions -
          accountLimit.balanceAccountLimits
            .currentNumberOfDailyUpdatesOfKeywordPositions
      );

      if (accountLimitsUsed > defaultNumberOfDailyUpdatesOfKeywordPositions) {
        dispatch(
          openRefreshesLimitLimitExceededModal({
            open: true,
            currentDailyRefreshes:
              defaultNumberOfDailyUpdatesOfKeywordPositions -
              accountLimit.balanceAccountLimits
                .currentNumberOfDailyUpdatesOfKeywordPositions,
            dailyRefreshesLimit: defaultNumberOfDailyUpdatesOfKeywordPositions,
            dailyRefreshesYouCanAdd: youCanAdd,
          })
        );
        return true;
      }
      return;
    },
    [accountLimit, dispatch]
  );

  const onCloseRefreshesLimitLimitExceededModal = useCallback(() => {
    dispatch(closeRefreshesLimitExceededModal());
  }, [dispatch]);

  const onCheckEmailReportsLimit = useCallback(() => {
    if (!accountLimit) return;

    const defaultNumberOfAvailableEmailReports =
      accountLimit.defaultAccountLimits.numberOfAvailableEmailReports;

    const accountLimitsUsedNumberOfEmailReports =
      accountLimit.accountLimitsUsed.numberOfEmailReports;

    if (
      defaultNumberOfAvailableEmailReports <
      accountLimitsUsedNumberOfEmailReports + 1
    ) {
      dispatch(
        openEmailReportsLimitExceededModal({
          open: true,
          currentEmailReports: accountLimitsUsedNumberOfEmailReports,
          emailReportsLimit: defaultNumberOfAvailableEmailReports,
          emailReportsYouCanAdd: defaultNumberOfAvailableEmailReports + 1,
        })
      );
      return true;
    }
    return;
  }, [accountLimit, dispatch]);

  const onCloseEmailReportsLimitExceededModal = useCallback(() => {
    dispatch(closeEmailReportsLimitExceededModal());
  }, [dispatch]);

  const onCheckSharedLinksLimit = useCallback(() => {
    if (!accountLimit) return;

    const defaultNumberOfAvailableSharedLinks =
      accountLimit.defaultAccountLimits.numberOfSharedLinksAvailable;

    const accountLimitsUsedNumberOfSharedLinks =
      accountLimit.accountLimitsUsed.numberOfSharedLinks;

    if (
      defaultNumberOfAvailableSharedLinks <
      accountLimitsUsedNumberOfSharedLinks + 1
    ) {
      dispatch(
        openSharedLinksLimitExceededModal({
          open: true,
          currentSharedLinks: accountLimitsUsedNumberOfSharedLinks,
          sharedLinksLimit: defaultNumberOfAvailableSharedLinks,
          sharedLinksYouCanAdd: defaultNumberOfAvailableSharedLinks + 1,
        })
      );
      return true;
    }
    return;
  }, [accountLimit, dispatch]);

  const onCloseSharedLinksLimitExceededModal = useCallback(() => {
    dispatch(closeSharedLinksLimitExceededModal());
  }, [dispatch]);

  const onCheckUsersLimit = useCallback(() => {
    if (!accountLimit) return;

    const defaultNumberOfUsersWhoHaveAccessToTheAccount =
      accountLimit.defaultAccountLimits.numberOfUsersWhoHaveAccessToTheAccount;

    const accountLimitsUsedNumberOfUsers =
      accountLimit.accountLimitsUsed.numberOfUsers +
      accountLimit.accountLimitsUsed.numberOfInvitations;

    if (
      defaultNumberOfUsersWhoHaveAccessToTheAccount <
      accountLimitsUsedNumberOfUsers
    ) {
      dispatch(
        openUsersLimitExceededModal({
          open: true,
          currentUsers: accountLimitsUsedNumberOfUsers - 1,
          usersLimit: defaultNumberOfUsersWhoHaveAccessToTheAccount,
          usersYouCanAdd: defaultNumberOfUsersWhoHaveAccessToTheAccount + 1,
        })
      );
      return true;
    }
    return;
  }, [accountLimit, dispatch]);

  const onCloseUsersLimitExceededModal = useCallback(() => {
    dispatch(closeUsersLimitExceededModal());
  }, [dispatch]);

  const onCheckNotesLimit = useCallback(
    (notesAddLength: number) => {
      if (!accountLimit) return;

      const defaultNumberOfAvailableNotes =
        accountLimit.defaultAccountLimits.numberOfAvailableNotes;

      const accountLimitsUsedNumberOfNotes =
        accountLimit.accountLimitsUsed.numberOfNotes;

      if (
        defaultNumberOfAvailableNotes <
        accountLimitsUsedNumberOfNotes + notesAddLength
      ) {
        dispatch(
          openNotesLimitExceededModal({
            open: true,
            currentNotes: accountLimitsUsedNumberOfNotes,
            notesLimit: defaultNumberOfAvailableNotes,
            notesYouCanAdd: defaultNumberOfAvailableNotes + notesAddLength,
          })
        );
        return true;
      }
      return;
    },
    [accountLimit, dispatch]
  );

  const onCheckTriggersLimit = useCallback(
    (triggersAddLength: number) => {
      if (!accountLimit) return;

      const defaultNumberOfAvailableTriggers =
        accountLimit.defaultAccountLimits.numberOfTriggersAvailable;

      const accountLimitsUsedNumberOfTriggers =
        accountLimit.accountLimitsUsed.numberOfTriggers;

      if (
        defaultNumberOfAvailableTriggers <
        accountLimitsUsedNumberOfTriggers + triggersAddLength
      ) {
        dispatch(
          openTriggersLimitExceededModal({
            open: true,
            currentTriggers: accountLimitsUsedNumberOfTriggers,
            triggersLimit: defaultNumberOfAvailableTriggers,
            triggersYouCanAdd:
              defaultNumberOfAvailableTriggers + triggersAddLength,
          })
        );
        return true;
      }
      return;
    },
    [accountLimit, dispatch]
  );

  const onCloseTriggersLimitExceededModal = useCallback(() => {
    dispatch(closeTriggersLimitExceededModal());
  }, [dispatch]);

  const onCloseNotesLimitExceededModal = useCallback(() => {
    dispatch(closeNotesLimitExceededModal());
  }, [dispatch]);

  const onCheckCompetitorsLimit = useCallback(
    (competitorsAddLength: number) => {
      if (!accountLimit) return;

      const defaultNumberOfAvailableCompetitors =
        accountLimit.defaultAccountLimits.availableNumberOfCompetitors;

      const accountLimitsUsedNumberOfCompetitors =
        accountLimit.accountLimitsUsed.numberOfCompetitors;

      if (
        defaultNumberOfAvailableCompetitors <
        accountLimitsUsedNumberOfCompetitors + competitorsAddLength
      ) {
        dispatch(
          openCompetitorsLimitExceededModal({
            open: true,
            currentCompetitors: accountLimitsUsedNumberOfCompetitors,
            competitorsLimit: defaultNumberOfAvailableCompetitors,
            competitorsYouCanAdd: competitorsAddLength,
          })
        );
        return true;
      }
      return;
    },
    [accountLimit, dispatch]
  );

  const onCloseCompetitorsLimitExceededModal = useCallback(() => {
    dispatch(closeCompetitorsLimitExceededModal());
  }, [dispatch]);

  const onCheckTagsPerKeywordLimit = useCallback(
    (tagsLength: number) => {
      if (!accountLimit) return;

      const accountBalanceTagsPerKeyword =
        accountLimit.balanceAccountLimits
          .currentNumberOfAvailableTagsPerKeyword;

      if (accountBalanceTagsPerKeyword < tagsLength) {
        dispatch(
          openTagsPerKeywordLimitExceededModal({
            open: true,
            currentTagsPerKeyword: accountBalanceTagsPerKeyword,
            tagsPerKeywordLimit: accountBalanceTagsPerKeyword,
            tagsPerKeywordYouCanAdd: tagsLength,
          })
        );
        return true;
      }
      return;
    },
    [accountLimit, dispatch]
  );

  const onCloseTagsPerKeywordLimitExceededModal = useCallback(() => {
    dispatch(closeTagsPerKeywordLimitExceededModal());
  }, [dispatch]);

  const onCheckTagsPerProjectLimit = useCallback(
    (tagsLength: number) => {
      if (!accountLimit) return;

      const accountBalanceTagsPerProject =
        accountLimit.balanceAccountLimits
          .currentNumberOfAvailableTagsPerProject;

      if (accountBalanceTagsPerProject < tagsLength) {
        dispatch(
          openTagsPerProjectLimitExceededModal({
            open: true,
            currentTagsPerProject: accountBalanceTagsPerProject,
            tagsPerProjectLimit: accountBalanceTagsPerProject,
            tagsPerProjectYouCanAdd: tagsLength,
          })
        );
        return true;
      }
      return;
    },
    [accountLimit, dispatch]
  );

  const onCloseTagsPerProjectLimitExceededModal = useCallback(() => {
    dispatch(closeTagsPerProjectLimitExceededModal());
  }, [dispatch]);

  const onCheckCannotAddExcessKeywords = useCallback(
    (
      keywordsAddLength: number,
      desktopAndMobile: boolean,
      googleMyBusinessOrYouTube: boolean,
      searchEngine: string
    ) => {
      if (!accountLimit) return;

      const defaultNumberOfDailyUpdatesOfKeywordPositions =
        accountLimit.defaultAccountLimits
          .numberOfDailyUpdatesOfKeywordPositions;
      const accountLimitsUsedNumberOfKeywords =
        accountLimit.accountLimitsUsed.numberOfKeywords;

      const keywordsDesktopAndMobile = desktopAndMobile
        ? keywordsAddLength * 2
        : keywordsAddLength;

      const keywordsGoogleMyBusinessOrYouTube = googleMyBusinessOrYouTube
        ? keywordsDesktopAndMobile * 5
        : keywordsDesktopAndMobile;

      if (
        defaultNumberOfDailyUpdatesOfKeywordPositions <
        accountLimitsUsedNumberOfKeywords + keywordsGoogleMyBusinessOrYouTube
      ) {
        dispatch(
          openCannotAddExcessKeywordsModal({
            open: true,
            desktopAndMobile: desktopAndMobile,
            googleMyBusinessOrYouTube,
            keywordsAdd: keywordsGoogleMyBusinessOrYouTube,
            searchEngine,
          })
        );
        return true;
      }
      return;
    },
    [accountLimit, dispatch]
  );

  const onCloseCannotAddExcessKeywordsModal = useCallback(() => {
    dispatch(closeCannotAddExcessKeywordsModal());
  }, [dispatch]);

  const onCheckKeywordUpdateScheduledModal = useCallback(
    (
      keywordsAddLength: number,
      desktopAndMobile: boolean,
      googleMyBusinessOrYouTube: boolean
    ) => {
      if (!accountLimit) return;

      const keywordsDesktopAndMobile = desktopAndMobile
        ? keywordsAddLength * 2
        : keywordsAddLength;

      const keywordsGoogleMyBusinessOrYouTube = googleMyBusinessOrYouTube
        ? keywordsDesktopAndMobile * 5
        : keywordsDesktopAndMobile;

      const usedNumberOfKeywords =
        accountLimit.accountLimitsUsed.numberOfKeywords;

      const defaultDailyUpdatesOfKeyword =
        accountLimit.defaultAccountLimits
          .numberOfDailyUpdatesOfKeywordPositions;

      const balanceDailyUpdatesOfKeyword =
        accountLimit.balanceAccountLimits
          .currentNumberOfDailyUpdatesOfKeywordPositions;

      if (
        balanceDailyUpdatesOfKeyword - keywordsGoogleMyBusinessOrYouTube <= 0 &&
        usedNumberOfKeywords + keywordsGoogleMyBusinessOrYouTube <=
          defaultDailyUpdatesOfKeyword
      ) {
        dispatch(openKeywordUpdateScheduledModal({ open: true }));
        return true;
      }

      return;
    },
    [accountLimit, dispatch]
  );

  const onCloseKeywordUpdateScheduledModal = useCallback(() => {
    dispatch(closeKeywordUpdateScheduledModal());
  }, [dispatch]);

  const onOpenKeywordUpdateScheduledModal = useCallback(() => {
    dispatch(openKeywordUpdateScheduledModal({ open: true }));
  }, [dispatch]);

  const onCheckCannotRefreshExcessKeywordsModal = useCallback(
    (keywordsAddLength: number) => {
      if (!accountLimit) return;

      const currentNumberOfDailyUpdatesOfKeywordPositions =
        accountLimit.balanceAccountLimits
          .currentNumberOfDailyUpdatesOfKeywordPositions;

      if (keywordsAddLength > currentNumberOfDailyUpdatesOfKeywordPositions) {
        dispatch(
          openCannotRefreshExcessKeywordsModal({
            open: true,
            keywordsAdd: keywordsAddLength,
          })
        );
        return true;
      }

      return;
    },
    [accountLimit, dispatch]
  );

  const onCloseCannotRefreshExcessKeywordsModal = useCallback(() => {
    dispatch(closeCannotRefreshExcessKeywordsModal());
  }, [dispatch]);

  const onCheckYouveReachedYourRefreshLimitForTodayModal = useCallback(() => {
    if (!accountLimit) return;

    const currentNumberOfDailyUpdatesOfKeywordPositions =
      accountLimit.balanceAccountLimits
        .currentNumberOfDailyUpdatesOfKeywordPositions;

    if (!currentNumberOfDailyUpdatesOfKeywordPositions) {
      dispatch(
        openYouveReachedYourRefreshLimitForToday({
          open: true,
        })
      );
      return true;
    }

    return;
  }, [accountLimit, dispatch]);

  const onCloseYouveReachedYourRefreshLimitForTodayModal = useCallback(() => {
    dispatch(closeYouveReachedYourRefreshLimitForToday());
  }, [dispatch]);

  const onSetFreeTrialModal = useCallback(
    (value: boolean) => {
      dispatch(setFreeTrialModal(value));
    },
    [dispatch]
  );

  const onSetSubscriptionModal = useCallback(
    (value: boolean) => {
      dispatch(setSubscriptionModal(value));
    },
    [dispatch]
  );

  const onCheckSubscriptionExpiration = useCallback(() => {
    if (billing?.status?.name === 'deactivated') {
      if (billing?.tariffPlan?.name === 'Trial Period') {
        dispatch(setFreeTrialModal(true));
        return true;
      } else {
        dispatch(setSubscriptionModal(true));
        return true;
      }
    }
  }, [dispatch, billing]);

  const onSetActionRequiredCancelSubscriptionModal = useCallback(
    (value: boolean) => {
      dispatch(setActionRequiredCancelSubscriptionModal(value));
    },
    [dispatch]
  );

  const onSetNoticeAccountDeletionCancelledModal = useCallback(
    (value: boolean) => {
      dispatch(setNoticeAccountDeletionCancelledModal(value));
    },
    [dispatch]
  );

  return {
    onUpdateEmailReportsBalanceCount,
    onUpdateTriggersBalanceCount,
    onUpdateUsersBalanceCount,
    onUpdateInvitationsBalanceCount,
    onUpdateCompetitorsBalanceCount,
    onUpdateNotesBalanceCount,
    onUpdateDailyKeywordsBalanceCount,
    onUpdateMonthlyKeywordsBalanceCount,
    onUpdateSharedLinksBalanceCount,
    onUpdateProjectsBalanceCount,
    onCheckKeywordLimit,
    onCloseKeywordsLimitExceededModal,
    onCheckEmailReportsLimit,
    onCloseEmailReportsLimitExceededModal,
    onCheckSharedLinksLimit,
    onCloseSharedLinksLimitExceededModal,
    onCheckUsersLimit,
    onCloseUsersLimitExceededModal,
    onCheckNotesLimit,
    onCloseNotesLimitExceededModal,
    onCheckCompetitorsLimit,
    onCloseCompetitorsLimitExceededModal,
    onCheckTagsPerKeywordLimit,
    onCloseTagsPerKeywordLimitExceededModal,
    onCheckTagsPerProjectLimit,
    onCloseTagsPerProjectLimitExceededModal,
    onCheckRefreshesLimitLimit,
    onCloseRefreshesLimitLimitExceededModal,
    onCheckCannotAddExcessKeywords,
    onCloseCannotAddExcessKeywordsModal,
    onCheckKeywordUpdateScheduledModal,
    onCloseKeywordUpdateScheduledModal,
    onOpenKeywordUpdateScheduledModal,
    onCheckCannotRefreshExcessKeywordsModal,
    onCloseCannotRefreshExcessKeywordsModal,
    onCheckYouveReachedYourRefreshLimitForTodayModal,
    onCloseYouveReachedYourRefreshLimitForTodayModal,
    onSetFreeTrialModal,
    onSetSubscriptionModal,
    onCheckSubscriptionExpiration,
    onSetActionRequiredCancelSubscriptionModal,
    onSetNoticeAccountDeletionCancelledModal,
    onCheckTriggersLimit,
    onCloseTriggersLimitExceededModal,
  };
}
