import React from 'react';

const CopyIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
  >
    <g clipPath='url(#clip0_4903_42737)'>
      <path
        d='M4.16699 12.4993C3.39042 12.4993 3.00214 12.4993 2.69585 12.3725C2.28747 12.2033 1.96302 11.8789 1.79386 11.4705C1.66699 11.1642 1.66699 10.7759 1.66699 9.99935V4.33268C1.66699 3.39926 1.66699 2.93255 1.84865 2.57603C2.00844 2.26243 2.2634 2.00746 2.57701 1.84767C2.93353 1.66602 3.40024 1.66602 4.33366 1.66602H10.0003C10.7769 1.66602 11.1652 1.66602 11.4715 1.79288C11.8798 1.96204 12.2043 2.2865 12.3735 2.69488C12.5003 3.00116 12.5003 3.38945 12.5003 4.16602M10.167 18.3327H15.667C16.6004 18.3327 17.0671 18.3327 17.4236 18.151C17.7372 17.9912 17.9922 17.7363 18.152 17.4227C18.3337 17.0661 18.3337 16.5994 18.3337 15.666V10.166C18.3337 9.23259 18.3337 8.76588 18.152 8.40937C17.9922 8.09576 17.7372 7.84079 17.4236 7.68101C17.0671 7.49935 16.6004 7.49935 15.667 7.49935H10.167C9.23357 7.49935 8.76686 7.49935 8.41034 7.68101C8.09674 7.84079 7.84177 8.09576 7.68198 8.40937C7.50033 8.76588 7.50033 9.2326 7.50033 10.166V15.666C7.50033 16.5994 7.50033 17.0661 7.68198 17.4227C7.84177 17.7363 8.09674 17.9912 8.41034 18.151C8.76686 18.3327 9.23357 18.3327 10.167 18.3327Z'
        stroke='#344054'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_4903_42737'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default CopyIcon;
