/**
 * Enum for storage keys used in local and session storage.
 */
export enum STORAGE_KEYS {
  access = 'access',
  refresh = 'refresh',
  currentAccount = 'currentAccount',
  sharedLinkToken = 'sharedLinkToken',
  sharedLinkAccessToken = 'sharedLinkAccessToken',
  sharedLinkIsRequiredPassword = 'sharedLinkIsRequiredPassword',
  yourFreeTrialEndsBanner = 'yourFreeTrialEndsBanner',
  verifyEmail = 'verifyEmail',
  googleKeepMeLoggedIn = 'googleKeepMeLoggedIn',
  googleAuthLoader = 'googleAuthLoader',
}

/**
 * Clears an item from both local and session storage based on the provided key.
 * @param key The storage key to clear.
 */
export const clearStorage = (key: STORAGE_KEYS) => {
  localStorage.removeItem(STORAGE_KEYS[key]);
  sessionStorage.removeItem(STORAGE_KEYS[key]);
};

/**
 * Retrieves an item from local storage.
 * @param key The storage key to retrieve.
 * @returns The value of the item or null if it doesn't exist.
 */
export const getLocalStorage = (key: STORAGE_KEYS): string | null =>
  localStorage.getItem(STORAGE_KEYS[key]);

/**
 * Sets an item in local storage.
 * @param key The storage key to set.
 * @param value The value to store. If not provided, the item will not be set.
 */
export const setLocalStorage = (key: STORAGE_KEYS, value?: string) => {
  value && localStorage.setItem(STORAGE_KEYS[key], value);
};

/**
 * Clears an item from local storage.
 * @param key The storage key to clear.
 */
export const clearLocalStorage = (key: STORAGE_KEYS) => {
  localStorage.removeItem(STORAGE_KEYS[key]);
};

/**
 * Retrieves an item from session storage.
 * @param key The storage key to retrieve.
 * @returns The value of the item or null if it doesn't exist.
 */
export const getSessionStorage = (key: STORAGE_KEYS): string | null =>
  sessionStorage.getItem(STORAGE_KEYS[key]);

/**
 * Sets an item in session storage.
 * @param key The storage key to set.
 * @param value The value to store. If not provided, the item will not be set.
 */
export const setSessionStorage = (key: STORAGE_KEYS, value?: string) => {
  value && sessionStorage.setItem(STORAGE_KEYS[key], value);
};

/**
 * Clears an item from session storage.
 * @param key The storage key to clear.
 */
export const clearSessionStorage = (key: STORAGE_KEYS) => {
  sessionStorage.removeItem(STORAGE_KEYS[key]);
};

/**
 * Saves an access token in local storage.
 * @param token The access token to save.
 */
export const saveAccessInLocalStorage = (token: string) => {
  token && setLocalStorage(STORAGE_KEYS.access, token);
};

/**
 * Saves a refresh token in local storage.
 * @param token The refresh token to save.
 */
export const saveRefreshInLocalStorage = (token: string) => {
  token && setLocalStorage(STORAGE_KEYS.refresh, token);
};

/**
 * Saves an access token in session storage.
 * @param token The access token to save.
 */
export const saveAccessInSessionStorage = (token: string) => {
  token && setSessionStorage(STORAGE_KEYS.access, token);
};

/**
 * Saves a refresh token in session storage.
 * @param token The refresh token to save.
 */
export const saveRefreshInSessionStorage = (token: string) => {
  token && setSessionStorage(STORAGE_KEYS.refresh, token);
};

/**
 * Retrieves the access token from local storage.
 * @returns The access token or null if it doesn't exist.
 */
export const getAccessWithLocalStorage = (): string | null =>
  getLocalStorage(STORAGE_KEYS.access);

/**
 * Retrieves the refresh token from local storage.
 * @returns The refresh token or null if it doesn't exist.
 */
export const getRefreshWithLocalStorage = (): string | null =>
  getLocalStorage(STORAGE_KEYS.refresh);

/**
 * Retrieves the access token from session storage.
 * @returns The access token or null if it doesn't exist.
 */
export const getAccessWithSessionStorage = (): string | null =>
  getSessionStorage(STORAGE_KEYS.access);

/**
 * Retrieves the refresh token from session storage.
 * @returns The refresh token or null if it doesn't exist.
 */
export const getRefreshWithSessionStorage = (): string | null =>
  getSessionStorage(STORAGE_KEYS.refresh);

/**
 * Retrieves the shared link token from local storage.
 * @returns The shared link token or null if it doesn't exist.
 */
export const getSharedLinkToken = (): string | null =>
  getLocalStorage(STORAGE_KEYS.sharedLinkToken);

/**
 * Retrieves the shared link access token from local storage.
 * @returns The shared link access token or null if it doesn't exist.
 */
export const getSharedLinkAccessToken = (): string | null =>
  getLocalStorage(STORAGE_KEYS.sharedLinkAccessToken);

/**
 * Checks if a password is required for the shared link from local storage.
 * @returns The value indicating if a password is required or null if it doesn't exist.
 */
export const getSharedLinkIsRequiredPassword = (): string | null =>
  getLocalStorage(STORAGE_KEYS.sharedLinkIsRequiredPassword);

/**
 * Saves the shared link token in local storage.
 * @param token The shared link token to save.
 */
export const saveSharedLinkToken = (token: string) => {
  token && setLocalStorage(STORAGE_KEYS.sharedLinkToken, token);
};

/**
 * Saves the shared link access token in local storage.
 * @param token The shared link access token to save.
 */
export const saveSharedLinkAccessToken = (token: string) => {
  token && setLocalStorage(STORAGE_KEYS.sharedLinkAccessToken, token);
};

/**
 * Saves the requirement for a password for the shared link in local storage.
 * @param isRequiredPassword Indicates if a password is required.
 */
export const saveSharedLinkIsRequiredPassword = (
  isRequiredPassword: string
) => {
  isRequiredPassword &&
    setLocalStorage(
      STORAGE_KEYS.sharedLinkIsRequiredPassword,
      isRequiredPassword
    );
};

/**
 * Retrieves the banner for the free trial end status from session storage.
 * @returns The banner status or null if it doesn't exist.
 */
export const getYourFreeTrialEndsBanner = (): string | null =>
  getSessionStorage(STORAGE_KEYS.yourFreeTrialEndsBanner);

/**
 * Hides the banner for the free trial end status in session storage.
 */
export const hideYourFreeTrialEndsBanner = () => {
  setSessionStorage(STORAGE_KEYS.yourFreeTrialEndsBanner, '1');
};

/**
 * Retrieves the email verification status from local storage.
 * @returns The verification email or null if it doesn't exist.
 */
export const getVerifyEmailLocalStorage = (): string | null =>
  getLocalStorage(STORAGE_KEYS.verifyEmail);

/**
 * Saves the verification email in local storage.
 * @param email The email to save.
 */
export const saveVerifyEmailLocalStorage = (email: string) => {
  setLocalStorage(STORAGE_KEYS.verifyEmail, email);
};

/**
 * Retrieves the 'Keep me logged in' status from local storage.
 * @returns The status or null if it doesn't exist.
 */
export const getGoogleKeepMeLoggedInLocalStorage = (): string | null =>
  getLocalStorage(STORAGE_KEYS.googleKeepMeLoggedIn);

/**
 * Saves the 'Keep me logged in' status in local storage.
 * @param value The value to save.
 */
export const saveGoogleKeepMeLoggedInLocalStorage = (value: string) => {
  setLocalStorage(STORAGE_KEYS.googleKeepMeLoggedIn, value);
};

/**
 * Retrieves the Google authentication loader status from local storage.
 * @returns The loader status or null if it doesn't exist.
 */
export const getGoogleAuthLoader = (): string | null =>
  getLocalStorage(STORAGE_KEYS.googleAuthLoader);

/**
 * Saves the Google authentication loader status in local storage.
 * @param value The value to save.
 */
export const saveGoogleAuthLoader = (value: string) => {
  setLocalStorage(STORAGE_KEYS.googleAuthLoader, value);
};
