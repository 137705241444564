import React from 'react';

import { KeywordRankingsTable } from './components';
import { StyledKeywordRankings } from './styles';
import Pagination from './components/KeywordRankingsTable/components/Table/components/Pagination/Pagination';

const KeywordRankings = () => {
  return (
    <StyledKeywordRankings>
      <KeywordRankingsTable />
      <Pagination />
    </StyledKeywordRankings>
  );
};

export default KeywordRankings;
