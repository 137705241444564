import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';

const StyledRefreshKeywordAction = styled(IconButton)`
  &&.positionUpdate {
    animation: 1s linear 0s normal none infinite running positionUpdate;
    -webkit-animation: 1s linear 0s normal none infinite running positionUpdate;
  }

  @keyframes positionUpdate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes positionUpdate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export { StyledRefreshKeywordAction };
