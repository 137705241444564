import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  createFolderAPI,
  deletionOfFolderContentsAPI,
  getAvailableFoldersAPI,
  getFoldersAPI,
  getFoldersByIdAPI,
  getRootFoldersAPI,
  renameFolderAPI,
} from '../api';

import {
  GetFoldersByIdParams,
  FoldersListResponse,
  FoldersResponse,
  IFolder,
  RootFoldersResponse,
  GetRootFoldersParams,
  CreateFolderParams,
  RenameFolderParams,
  deletionOfFolderContentsParams,
} from '../types';
import { AxiosError } from 'axios';
import { checkAndRedirectToMaintenancePage } from '../../../utils';

/**
 * Thunk to get the list of available folders.
 * @param {{ accountId: number }} params - Parameters containing the account ID.
 * @returns {Promise<FoldersListResponse>} The list of available folders.
 */
export const getAvailableFoldersThunk = createAsyncThunk<
  FoldersListResponse,
  { accountId: number },
  { rejectValue: string }
>('get-list-available-folders', async (params, thunkAPI) => {
  try {
    const { data } = await getAvailableFoldersAPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to get all folders for a specific account.
 * @param {{ accountId: number }} params - Parameters containing the account ID.
 * @returns {Promise<IFolder[]>} The list of folders.
 */
export const getFoldersThunk = createAsyncThunk<
  IFolder[],
  { accountId: number },
  { rejectValue: string }
>('get-folders', async (params, thunkAPI) => {
  try {
    const { data } = await getFoldersAPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to get folders by their ID.
 * @param {GetFoldersByIdParams} params - Parameters containing the folder ID and additional options.
 * @returns {Promise<FoldersResponse>} The folder details.
 */
export const getFoldersByIdThunk = createAsyncThunk<
  FoldersResponse,
  GetFoldersByIdParams,
  { rejectValue: string }
>('get-folders-by-id', async (params, thunkAPI) => {
  try {
    const { data } = await getFoldersByIdAPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to get root folders for a specific account.
 * @param {GetRootFoldersParams} params - Parameters containing the account ID and additional options.
 * @returns {Promise<RootFoldersResponse>} The list of root folders.
 */
export const getRootFoldersThunk = createAsyncThunk<
  RootFoldersResponse,
  GetRootFoldersParams,
  { rejectValue: string }
>('get-root-folders', async (params, thunkAPI) => {
  try {
    const { data } = await getRootFoldersAPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to create a new folder.
 * @param {CreateFolderParams} params - Parameters for creating a folder.
 * @returns {Promise<void>}
 */
export const createFolderThunk = createAsyncThunk<
  undefined,
  CreateFolderParams,
  { rejectValue: string }
>('create-folder', async (params, thunkAPI) => {
  try {
    await createFolderAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      if (
        error?.response?.data?.message ===
        'A folder with this name already exists in this folder.'
      ) {
        params.errorFn({
          name: error.response.data.message,
          folderName: error.response.data.message,
        });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to rename an existing folder.
 * @param {RenameFolderParams} params - Parameters for renaming a folder.
 * @returns {Promise<void>}
 */
export const renameFolderThunk = createAsyncThunk<
  undefined,
  RenameFolderParams,
  { rejectValue: string }
>('rename-folder', async (params, thunkAPI) => {
  try {
    await renameFolderAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      if (
        error?.response?.data?.message ===
        'A folder with this name already exists in this folder.'
      ) {
        params.errorFn({ folderName: error.response.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to delete the contents of a folder.
 * @param {deletionOfFolderContentsParams} params - Parameters for deleting folder contents.
 * @returns {Promise<void>}
 */
export const deletionOfFolderContentsThunk = createAsyncThunk<
  undefined,
  deletionOfFolderContentsParams,
  { rejectValue: string }
>('deletion-of-folder-contents', async (params, thunkAPI) => {
  try {
    await deletionOfFolderContentsAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});
