import React from 'react';
import { KeywordsActionProps } from '../../../../../../../../notes/components/ProjectExpandedNotes/components/ProjectExpandedNotesTable/components/ActionsMenu/components/types';
import { MainContainer } from '../../../../../../../../notes/components/ProjectExpandedNotes/components/ProjectExpandedNotesTable/components/ActionsMenu/styles';
import { List } from '@mui/material';
import DeleteSharedLinksAction from './DeleteSharedLinksAction';

const ListActions: React.FC<KeywordsActionProps> = ({ popupClose }) => {
  return (
    <MainContainer>
      <List>
        <DeleteSharedLinksAction popupClose={popupClose} />
      </List>
    </MainContainer>
  );
};

export default ListActions;
