import React from 'react';

import { Header, Content } from './styles';

const EmailForNewUser = () => (
  <div>
    <Header>Email</Header>
    <Content>
      Enter the email address for the new user. This will be used for login and
      communication purposes.
    </Content>
  </div>
);

const RoleForNewUser = () => (
  <div>
    <Header>Role</Header>
    <Content>
      &apos;Admin&apos; users have full control over the account except they
      can&apos;t close the account, &apos;Addon&apos; users can view and edit
      assigned projects or folders, and &apos;View Only&apos; users can only
      view assigned projects.
    </Content>
  </div>
);

const AccessForNewUser = () => (
  <div>
    <Header>Access</Header>
    <Content>
      Select the projects and/or folders the new user should have access to.
    </Content>
  </div>
);

const RoleForTeamTable = () => (
  <div>
    <Header>Role</Header>
    <Content>
      The user&apos;s level of access. Admins have full control over the
      account, Addon users can view and edit assigned projects or folders, and
      View Only users can only view assigned projects.
    </Content>
  </div>
);

const DateAddedForTeamTable = () => (
  <div>
    <Header>Date Added</Header>
    <Content>The date when this user was added to the account.</Content>
  </div>
);

const LastActivityForTeamTable = () => (
  <div>
    <Header>Last Activity</Header>
    <Content>
      The date and time of the user&apos;s most recent activity within the
      account.
    </Content>
  </div>
);

export {
  EmailForNewUser,
  RoleForNewUser,
  AccessForNewUser,
  RoleForTeamTable,
  DateAddedForTeamTable,
  LastActivityForTeamTable,
};
