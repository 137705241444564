import React from 'react';
import { Grid } from '@mui/material';

import { Header, Table } from './components';

const TableFolders = () => {
  return (
    <Grid xs={12}>
      <Header />
      <Table />
    </Grid>
  );
};

export default TableFolders;
