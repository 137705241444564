import React from 'react';
import { APIAccess, MoreAPICredits } from './components';
import { Grid } from '@mui/material';

/**
 * APITab component displays the API Access and More API Credits sections within a grid.
 *
 * @component
 * @returns {JSX.Element} Rendered APITab component.
 */
const APITab = () => {
  return (
    <Grid xs={12} paddingTop={'32px'}>
      <APIAccess />
      <MoreAPICredits />
    </Grid>
  );
};

export default APITab;
