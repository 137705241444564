import { Button, Grid, styled } from '@mui/material';

const StylesPlanName = styled(Grid)`
  color: #475467;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  padding-top: 20px;
  padding-bottom: 8px;
`;

const StylesPriceContainer = styled(Grid)`
  border-radius: 12px;
  border: 1px solid transparent;
`;

const StylesPlanPrice = styled(Grid)`
  color: #101828;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.96px;
`;

const StylesPlanPerMonth = styled(Grid)`
  color: #475467;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

const StylesPlanDescription = styled(Grid)`
  color: #475467;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-top: 16px;
`;

const StylesTable = styled('table')`
  border: none;
  width: 100%;
`;

const StylesTh = styled('th')`
  border: none;
  text-align: center;
  height: 45px;
  color: #101828;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const StylesTr = styled('tr')`
  border: none;
  :nth-child(odd) {
    background-color: #f9fafb;
  }
`;

const StylesButton = styled(Button)`
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  padding: 12px 20px !important;
  border-radius: 8px !important;
  text-transform: none !important;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05) !important;
`;

const StylesYourCurrentPlan = styled(Grid)`
  color: #475467;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
`;

export {
  StylesPlanName,
  StylesPlanPrice,
  StylesPlanPerMonth,
  StylesPlanDescription,
  StylesTable,
  StylesTh,
  StylesTr,
  StylesButton,
  StylesPriceContainer,
  StylesYourCurrentPlan,
};
