import { Divider, Grid } from '@mui/material';
import React from 'react';
import { writeText } from 'clipboard-polyfill';
import { StyledDescription, StyledHead } from '../../styles';
import {
  StyledLeftCell,
  StyledLeftCellTypography,
  StyledRightCell,
  StyledRow,
} from '../../../../../../../projects/components/KeywordRankHistory/components/KeywordInfo/styles';
import { StyledAPIdInfo, StyledRightCellTypography } from './styles';
import { GrayButtonCustom } from '../../../../../../../../shared/buttons/styles';
import CopyIcon from '../../../../../../../../shared/images/CopyIcon';
import { useAuth } from '../../../../../../../../hooks';
import { useAppSelector } from '../../../../../../../../store';

const APIAccess = () => {
  const { onRequestNewKey, onGetMyAccount } = useAuth();
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const myAccount = useAppSelector(state => state.auth.myAccount);
  const accountLimit = useAppSelector(state => state.auth.accountLimit);

  const handleRequestNewKey = () => {
    if (currentAccount?.accountId) {
      onRequestNewKey({
        id: currentAccount.accountId,
        successFn: () => {
          onGetMyAccount(currentAccount.accountId);
        },
      });
    }
  };

  const handleCopyAPIKey = () => {
    if (myAccount?.apiAccess?.apiKey) {
      writeText(myAccount?.apiAccess?.apiKey);
    }
  };

  const handleCopyAPIEndpoints = () => {
    if (myAccount?.apiAccess?.apiEndpoints) {
      writeText(myAccount?.apiAccess?.apiEndpoints);
    }
  };

  return (
    <Grid xs={12}>
      <Grid>
        <StyledHead>API Access</StyledHead>
        <StyledDescription>API details</StyledDescription>
      </Grid>

      <StyledAPIdInfo marginTop={'24px'} marginBottom={'32px'}>
        <StyledRow container xs={12}>
          <StyledLeftCell item xs={5} sm={4}>
            <StyledLeftCellTypography>API Key</StyledLeftCellTypography>
          </StyledLeftCell>

          <StyledRightCell
            container
            item
            xs={7}
            sm={8}
            justifyContent={'space-between'}
            alignItems={'center'}
            gap={'8px'}
          >
            <Grid item>
              <StyledRightCellTypography>
                {myAccount?.apiAccess?.apiKey}
              </StyledRightCellTypography>
            </Grid>
            <Grid item>
              <Grid container gap={'8px'}>
                <Grid item>
                  <GrayButtonCustom
                    $padding='8px 12px'
                    size='small'
                    outline
                    startIcon={<CopyIcon />}
                    onClick={handleCopyAPIKey}
                  >
                    Copy
                  </GrayButtonCustom>
                </Grid>
                <Grid item>
                  <GrayButtonCustom
                    $padding='8px 12px'
                    size='small'
                    outline
                    onClick={handleRequestNewKey}
                  >
                    Request new key
                  </GrayButtonCustom>
                </Grid>
              </Grid>
            </Grid>
          </StyledRightCell>
        </StyledRow>
        <Divider
          orientation='horizontal'
          flexItem
          sx={{
            color: '#EDEDED',
          }}
        />

        <StyledRow container xs={12}>
          <StyledLeftCell item xs={5} sm={4}>
            <StyledLeftCellTypography>API Endpoints</StyledLeftCellTypography>
          </StyledLeftCell>

          <StyledRightCell
            container
            item
            xs={7}
            sm={8}
            justifyContent={'space-between'}
            alignItems={'center'}
            gap={'8px'}
          >
            <Grid item>
              <StyledRightCellTypography>
                {myAccount?.apiAccess?.apiEndpoints}
              </StyledRightCellTypography>
            </Grid>
            <Grid item>
              <Grid container gap={'8px'}>
                <GrayButtonCustom
                  $padding='8px 12px'
                  size='small'
                  outline
                  startIcon={<CopyIcon />}
                  onClick={handleCopyAPIEndpoints}
                >
                  Copy
                </GrayButtonCustom>
              </Grid>
            </Grid>
          </StyledRightCell>
        </StyledRow>
        <Divider
          orientation='horizontal'
          flexItem
          sx={{
            color: '#EDEDED',
          }}
        />

        <StyledRow container xs={12}>
          <StyledLeftCell item xs={5} sm={4}>
            <StyledLeftCellTypography>API Credits</StyledLeftCellTypography>
          </StyledLeftCell>

          <StyledRightCell item xs={7} sm={8}>
            <StyledRightCellTypography>
              {accountLimit?.balanceAccountLimits
                ?.currentNumberOfDailyUpdatesOfKeywordPositions || 0}
            </StyledRightCellTypography>
          </StyledRightCell>
        </StyledRow>
      </StyledAPIdInfo>
    </Grid>
  );
};

export default APIAccess;
