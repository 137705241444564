import React from 'react';

const PaginationChevronPrevIcon = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='chevron-left'>
      <path
        id='Icon'
        d='M12.5 15L7.5 10L12.5 5'
        stroke='#344054'
        strokeWidth='1.67'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);

export default PaginationChevronPrevIcon;
