import React from 'react';
import { KeywordsActionProps } from '../../../../../../ProjectExpandedNotes/components/ProjectExpandedNotesTable/components/ActionsMenu/components/types';
import { MainContainer } from '../../../../../../ProjectExpandedNotes/components/ProjectExpandedNotesTable/components/ActionsMenu/styles';
import { List } from '@mui/material';
import DeleteProjectNotesAction from './DeleteProjectNotesAction';

const ListActions: React.FC<KeywordsActionProps> = ({ popupClose }) => {
  return (
    <MainContainer>
      <List>
        <DeleteProjectNotesAction popupClose={popupClose} />
      </List>
    </MainContainer>
  );
};

export default ListActions;
