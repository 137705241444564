import React, { useMemo } from 'react';
import { PERIOD } from '../../../../../../constants';
import { useKeywordRankHistory } from '../../../../../../../../hooks';
import { TimesGroup } from '../../../../../index';
import { GraphOptions } from '../';
import { Grid } from '@mui/material';
import { ExportGraphProps } from '../types';

const Header: React.FC<ExportGraphProps> = ({ refGraph }) => {
  const { onSetActivePeriodKeywordRankHistory, activePeriod } =
    useKeywordRankHistory();

  const timesGroupItems = useMemo(() => {
    return PERIOD.map(period => ({
      label: period.label,
      value: period.value,
      isActive: period.value === activePeriod.value,
      onChange: () => {
        onSetActivePeriodKeywordRankHistory(period);
      },
    }));
  }, [activePeriod.value, onSetActivePeriodKeywordRankHistory]);

  return (
    <Grid
      paddingBottom={'32px'}
      justifyContent={'space-between'}
      container
      xs={12}
    >
      <Grid marginTop={'32px'}>
        <TimesGroup items={timesGroupItems} />
      </Grid>
      <Grid marginTop={'32px'}>
        <GraphOptions refGraph={refGraph} />
      </Grid>
    </Grid>
  );
};

export default Header;
