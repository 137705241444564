import { styled } from '@mui/system';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box, Grid, IconButton } from '@mui/material';

import { Paper } from '../../../../../../shared/paper/styles';

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const WarningFinishContainer = styled('div')`
  display: flex;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 16px;
  text-align: center;
`;

const WarningContainer = styled(Box)`
  display: flex;
  background-color: #fee4e2;
  padding: 16px;
  border-radius: 8px;

  > svg {
    padding-right: 18px;
  }
`;

const CloseDeletePopoverOne = styled(IconButton)`
  color: #667085;
  position: absolute;
  top: -4px;
  right: 0;
  cursor: pointer;
`;

const ButtonsWrapper = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  padding-top: 32px;

  > div {
    :nth-child(2) {
      margin-left: 8px;
    }
  }

  ${props => props.theme.breakpoints.down('sm')} {
    padding-top: 24px;
    flex-direction: column-reverse;

    > div {
      :nth-child(2) {
        margin-left: 0;
      }

      :nth-child(1) {
        margin-top: 8px;
      }
    }
  }
`;

const CustomFormControlLabel = styled(FormControlLabel)`
  max-width: 344px;
  display: flex;
  align-items: flex-start;
  .MuiTypography-root {
    font-size: 14px;
    font-weight: 700;
    margin-top: 8px;
  }
`;

const CustomModal = styled(Grid)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 448px;

  ${props => props.theme.breakpoints.down('sm')} {
    transform: translate(calc(-50% - 24px), -50%);
    width: calc(100% - 48px);
    margin-right: 24px;
    margin-left: 24px;
  }
`;

const ModalContainer = styled(Paper)`
  padding: 24px;
  ${props => props.theme.breakpoints.down('sm')} {
    padding: 16px;
  }
`;

export {
  HeaderContainer,
  WarningContainer,
  CloseDeletePopoverOne,
  ButtonsWrapper,
  WarningFinishContainer,
  CustomFormControlLabel,
  CustomModal,
  ModalContainer,
};
