import React from 'react';
import useAlerts from '../../../../../../../../hooks/useAlerts';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { PrimaryButtonCustom } from '../../../../../../../../../../shared/buttons/styles';
import DownIcon from '../../../../../../../../../../shared/images/DownIcon';
import { Popover } from '../../../../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import ListActions from '../ActionsMenu/components/ListActions';
import EllipsisListActions from '../EllipsisMenu/ListActions';

const ActionsMenu: React.FC = () => {
  const {
    tables: {
      triggerKeywords: { rowSelectionModel },
    },
  } = useAlerts();

  return (
    <PopupState variant='popover'>
      {popupState => (
        <>
          <PrimaryButtonCustom
            $padding='8px 12px'
            variant='contained'
            size='small'
            endIcon={<DownIcon />}
            {...bindTrigger(popupState)}
          >
            Actions
          </PrimaryButtonCustom>

          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {rowSelectionModel.length !== 1 ? (
              <ListActions popupClose={popupState.close} />
            ) : (
              <EllipsisListActions
                popupClose={popupState.close}
                id={Number(rowSelectionModel[0])}
              />
            )}
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export default ActionsMenu;
