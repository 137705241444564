import React from 'react';
import { StyledListItemButton } from '../../../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/styles';
import { FolderActionProps } from './types';

import SmallTrashIcon from '../../../../../../../../../shared/images/SmallTrashIcom';
import { DeleteFolderModal } from '../../Modals';

import { ListItemTypography, StyledListItem } from '../styles';

const DeleteFolderAction: React.FC<FolderActionProps> = ({
  itemId,
  itemName,
  itemType,
  popupClose,
}) => {
  const [isOpenDeleteFolderModal, setIsOpenDeleteFolderModal] =
    React.useState<boolean>(false);

  const handleOpenDeleteFolderModal = () => setIsOpenDeleteFolderModal(true);
  const handleCloseDeleteFolderModal = () => setIsOpenDeleteFolderModal(false);

  const onDelete = () => {
    handleOpenDeleteFolderModal();
  };

  const handleClose = () => {
    popupClose();
    handleCloseDeleteFolderModal();
  };

  return (
    <StyledListItem onClick={onDelete}>
      <StyledListItemButton>
        <SmallTrashIcon />
        <ListItemTypography marginLeft='8px' sx={{ color: '#B42318' }}>
          Delete {itemType === 'folder' ? 'Folder' : 'Project'}
        </ListItemTypography>
      </StyledListItemButton>
      <DeleteFolderModal
        itemId={itemId}
        itemName={itemName}
        itemType={itemType}
        open={isOpenDeleteFolderModal}
        handleClose={handleClose}
      />
    </StyledListItem>
  );
};

export default DeleteFolderAction;
