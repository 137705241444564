import React from 'react';

const MastercardIcon = () => {
  return (
    <svg
      width='58'
      height='42'
      viewBox='0 0 58 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4 1.57575H54C55.861 1.57575 57.3696 3.08435 57.3696 4.94531V37.2931C57.3696 39.1541 55.861 40.6627 54 40.6627H4C2.13904 40.6627 0.630435 39.1541 0.630435 37.2931V4.94532C0.630435 3.08436 2.13904 1.57575 4 1.57575Z'
        fill='white'
      />
      <path
        d='M4 1.57575H54C55.861 1.57575 57.3696 3.08435 57.3696 4.94531V37.2931C57.3696 39.1541 55.861 40.6627 54 40.6627H4C2.13904 40.6627 0.630435 39.1541 0.630435 37.2931V4.94532C0.630435 3.08436 2.13904 1.57575 4 1.57575Z'
        stroke='#EAECF0'
        strokeWidth='1.26087'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.8804 29.2373C26.8898 30.9151 24.3078 31.9279 21.4863 31.9279C15.1906 31.9279 10.0869 26.885 10.0869 20.6642C10.0869 14.4434 15.1906 9.40039 21.4863 9.40039C24.3078 9.40039 26.8898 10.4132 28.8804 12.091C30.871 10.4132 33.453 9.40039 36.2745 9.40039C42.5702 9.40039 47.6739 14.4434 47.6739 20.6642C47.6739 26.885 42.5702 31.9279 36.2745 31.9279C33.453 31.9279 30.871 30.9151 28.8804 29.2373Z'
        fill='#ED0006'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.8799 29.2373C31.331 27.1713 32.8852 24.0971 32.8852 20.6642C32.8852 17.2312 31.331 14.157 28.8799 12.091C30.8704 10.4132 33.4525 9.40039 36.2739 9.40039C42.5697 9.40039 47.6734 14.4434 47.6734 20.6642C47.6734 26.885 42.5697 31.9279 36.2739 31.9279C33.4525 31.9279 30.8704 30.9151 28.8799 29.2373Z'
        fill='#F9A000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.8804 29.2381C31.3315 27.1721 32.8857 24.0979 32.8857 20.6649C32.8857 17.232 31.3315 14.1578 28.8804 12.0918C26.4292 14.1578 24.875 17.232 24.875 20.6649C24.875 24.0979 26.4292 27.1721 28.8804 29.2381Z'
        fill='#FF5E00'
      />
    </svg>
  );
};

export default MastercardIcon;
