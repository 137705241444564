import React from 'react';

import { Header, Content } from './styles';

const Date = () => (
  <div>
    <Header>Date</Header>
    <Content>
      The specific date when the keyword&apos;s rank was tracked.
    </Content>
  </div>
);

const Position = () => (
  <div>
    <Header>Position</Header>
    <Content>
      The rank of the keyword on a given date in the search results.
    </Content>
  </div>
);

const FoundURL = () => (
  <div>
    <Header>Found URL</Header>
    <Content>
      The specific URL from your website that was found in the search results
      for the keyword on a given date.
    </Content>
  </div>
);

export { Date, Position, FoundURL };
