import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Typography,
  List,
  ListItem,
  InputLabel,
  ListItemButton,
  FormControlLabel,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Avatar from '@mui/material/Avatar';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

import { useAuth } from '../../../../../hooks';
import { avatarStyles, myAccountAvatarStyles } from '../../utils';
import { SwitchAccountProps } from '../../types';
import { IAvailableAccount } from '../../../../../features/auth/types';
import { PopoverSx } from '../../constant';
import CustomCheckboxImage from '../../../../../shared/images/CustomCheckbox';

import { StyledInputBase } from './styles';

import {
  AccountListContainer,
  AccessStatus,
  SearchContainer,
  Popover,
  AccountInfo,
} from './styles';
import { SmallContainerCheckbox } from '../../../../checkbox/styles';
import SearchIcon from '../../../../images/SearchIcon';
import CheckedIcon from '../../../../images/CheckedIcon';
import RemoveAccessModal from '../../../../../features/auth/modals/RemoveAccessModal/RemoveAccessModal';
import { useAppSelector } from '../../../../../store';
import { useNavigate } from 'react-router';
import ROUTES from '../../../../../routes/constants';
import {
  offAllSockets,
  updatedUserSocket,
} from '../../../../../features/auth/socket';

const filterAccounts = (a: IAvailableAccount) => {
  if (a.isMyAccount) {
    return -1;
  }
  return 0;
};

const SwitchAccess = ({ closeSwitchAccess, isOpen }: SwitchAccountProps) => {
  const navigate = useNavigate();
  const {
    onSelectAccount,
    onRemoveAccessesAccounts,
    onResetPayment,
    onUpdatedUser,
  } = useAuth();

  const availableAccounts = useAppSelector(
    state => state.auth.availableAccounts
  );
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const user = useAppSelector(state => state.auth.user);

  const [searchString, setSearchString] = useState('');
  const [availableAccountsState, setAvailableAccountsState] = useState<
    IAvailableAccount[]
  >(
    [currentAccount]
      .concat(
        availableAccounts.filter(
          item => item.accountId !== currentAccount.accountId
        )
      )
      .sort(filterAccounts)
  );

  const [isShowCheckBoxes, setIsShowCheckBoxes] = useState(false);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState<number[]>([]);

  const [openRemoveAccessModal, setOpenRemoveAccessModal] =
    React.useState(false);

  const handleSelectAccount = (account: IAvailableAccount) => {
    closeSwitchAccess();
    if (account.accountId === currentAccount.accountId) {
      closeSwitchAccess();
    } else {
      offAllSockets();
      if (user?.id) {
        updatedUserSocket(user.id, user => onUpdatedUser(user));
      }
      onSelectAccount({
        account,
        successFn: () => {
          closeSwitchAccess();
          onResetPayment();
        },
      });
      navigate(ROUTES.projects);
    }
  };

  const handleRemoveAccessesAccounts = () => {
    onRemoveAccessesAccounts({
      data: { accountIds: isCheck },
      successFn: closeSwitchAccess,
    });
  };

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(
      availableAccountsState
        .filter(user => !user.isMyAccount)
        .map(user => user.accountId)
    );
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleSelect = (accountId: number, checked: boolean) => {
    setIsCheck([...isCheck, accountId]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== accountId));
    }
  };

  const handleOpenRemoveAccessModal = () => {
    setOpenRemoveAccessModal(previousOpen => !previousOpen);
  };

  const handleCloseRemoveAccessModal = () => {
    setOpenRemoveAccessModal(false);
  };

  useEffect(() => {
    if (!isOpen) {
      closeSwitchAccess();
    }
  }, [isOpen, closeSwitchAccess]);

  useEffect(() => {
    if (searchString) {
      const newArray = [currentAccount]
        .concat(
          availableAccounts.filter(
            item => item.accountId !== currentAccount.accountId
          )
        )
        .filter(account =>
          `${account.owner.ownerEmail} ${account.owner.ownerEmail}`.includes(
            searchString
          )
        )
        .sort(filterAccounts);
      setAvailableAccountsState(newArray);
    } else {
      setAvailableAccountsState(
        [currentAccount]
          .concat(
            availableAccounts.filter(
              item => item.accountId !== currentAccount.accountId
            )
          )
          .sort(filterAccounts)
      );
    }
  }, [searchString]);

  return (
    <Box
      sx={{
        padding: '8px 0',
        minWidth: '254px',
        maxWidth: '275px',
        width: '100%',
      }}
    >
      {availableAccounts.length ? (
        <>
          <PopupState variant='popover' popupId='account-setting'>
            {popupState => (
              <>
                <Box
                  sx={{
                    padding: '8px 16px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  {isShowCheckBoxes ? (
                    <FormControlLabel
                      label='Select all'
                      sx={{ marginLeft: '0', gap: '8px' }}
                      control={
                        <SmallContainerCheckbox
                          onChange={handleSelectAll}
                          checked={isCheckAll}
                          icon={<CustomCheckboxImage />}
                          checkedIcon={<CheckedIcon />}
                        />
                      }
                    />
                  ) : null}
                  <MoreVertIcon
                    sx={{
                      fontSize: '24px',
                      color: '#667085',
                      cursor: 'pointer',
                      marginLeft: 'auto',
                    }}
                    {...bindTrigger(popupState)}
                  />
                </Box>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  sx={PopoverSx}
                >
                  <Box sx={{ padding: '2px 0' }}>
                    <List>
                      <ListItem disablePadding>
                        <ListItemButton
                          sx={{
                            padding: '9px 16px',
                            color: '#101828',
                            cursor: 'pointer',
                          }}
                          onClick={() => setIsShowCheckBoxes(!isShowCheckBoxes)}
                        >
                          Select Items
                        </ListItemButton>
                      </ListItem>
                      {isCheck.length ? (
                        <ListItem disablePadding>
                          <ListItemButton
                            sx={{
                              padding: '9px 16px',
                              color: '#B42318',
                              cursor: 'pointer',
                            }}
                            onClick={handleOpenRemoveAccessModal}
                          >
                            Remove My Access
                          </ListItemButton>
                        </ListItem>
                      ) : null}
                    </List>
                  </Box>
                </Popover>
              </>
            )}
          </PopupState>

          <Divider
            orientation='horizontal'
            flexItem
            sx={{
              margin: '8px 0',
              color: '#EDEDED',
            }}
          />

          <SearchContainer>
            <InputLabel htmlFor='searchSwitchAccess'>
              <SearchIcon />
            </InputLabel>
            <StyledInputBase
              fullWidth
              id='searchSwitchAccess'
              placeholder='Search'
              value={searchString}
              onChange={e => setSearchString(e.target.value as string)}
            />
          </SearchContainer>

          <Divider
            orientation='horizontal'
            flexItem
            sx={{
              margin: '8px 0 0',
              color: '#EDEDED',
            }}
          />
        </>
      ) : null}

      <List disablePadding>
        {availableAccountsState.map((user, index) => {
          const stylesAccountAvatar = user.isMyAccount
            ? {
                ...myAccountAvatarStyles(
                  `${user.owner.ownerUserFirstName}`,
                  `${user.owner.ownerUserLastName}`,
                  user.accountId,
                  '40px',
                  '20px'
                ),
              }
            : {
                ...avatarStyles(
                  `${user.owner.ownerUserFirstName}`,
                  `${user.owner.ownerUserLastName}`,
                  user.accountId,
                  '40px',
                  '20px'
                ),
              };

          return (
            <ListItem
              key={user.accountId}
              sx={{
                flexDirection: 'column',
                alignItems: 'start',
                padding: '0 8px',
                width: '100%',
              }}
              disablePadding
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '2px',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <SmallContainerCheckbox
                  sx={{
                    marginLeft: '8px',
                    display: isShowCheckBoxes ? 'block' : 'none',
                    visibility: user.isMyAccount ? 'hidden' : null,
                  }}
                  icon={<CustomCheckboxImage />}
                  checkedIcon={<CheckedIcon />}
                  onChange={() =>
                    handleSelect(
                      user.accountId,
                      !isCheck.includes(user.accountId)
                    )
                  }
                  checked={isCheck.includes(user.accountId)}
                />
                <AccountListContainer onClick={() => handleSelectAccount(user)}>
                  <Avatar {...stylesAccountAvatar} />
                  <AccountInfo>
                    <Typography sx={{ color: '#344054', fontWeight: 600 }}>
                      {`${user.owner.ownerUserFirstName} ${user.owner.ownerUserLastName}`}
                    </Typography>
                    <Typography sx={{ color: '#475467' }}>
                      {user.owner.ownerEmail}
                    </Typography>
                    <AccessStatus
                      sx={{ marginBottom: '0', marginTop: '4px' }}
                      borderColor={(user.isMyAccount && '#ABEFC6') || '#F9DBAF'}
                      backgroundColor={
                        (user.isMyAccount && '#ECFDF3') || '#FEF6EE'
                      }
                      color={(user.isMyAccount && '#067647') || '#B93815'}
                    >
                      {user.isMyAccount
                        ? 'Primary'
                        : `${user.role.name} Access`}
                    </AccessStatus>
                  </AccountInfo>
                </AccountListContainer>
              </Box>
              {availableAccountsState.length === index + 1 ? null : (
                <Divider
                  orientation='horizontal'
                  flexItem
                  sx={{
                    margin: '0',
                    color: '#EDEDED',
                  }}
                />
              )}
            </ListItem>
          );
        })}
      </List>
      <RemoveAccessModal
        openRemoveAccessModal={openRemoveAccessModal}
        onCloseRemoveAccessModal={handleCloseRemoveAccessModal}
        onRemoveAccesses={handleRemoveAccessesAccounts}
      />
    </Box>
  );
};

export default SwitchAccess;
