import {
  buttonColors,
  errorColors,
  grayColors,
  primaryColors,
  shadow,
  successColors,
  typography,
  warningColors,
} from './constants';

const Default = () => ({
  palette: {
    primary: {
      ...primaryColors,
      ...buttonColors.primary,
    },
    neutral: {
      ...grayColors,
    },
    danger: { ...errorColors },
    error: {
      main: '#B42318',
    },
    success: { ...successColors },
    warning: { ...warningColors },
  },
  typography,
  shadow,
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px',
          backgroundColor: '#101828',
          padding: '12px',
          borderRadius: '8px',
        },
        arrow: {
          '&:before': {
            backgroundColor: '#101828',
          },
        },
      },
    },
  },
});

export default Default;
