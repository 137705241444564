import { Grid } from '@mui/material';
import React from 'react';

import { Header } from './components';
import Table from './components/Table/Table';
import Pagination from './components/Pagination/Pagination';

const SharedLinksTable = () => {
  return (
    <Grid xs={12}>
      <Header />
      <Table />
      <Pagination />
    </Grid>
  );
};

export default SharedLinksTable;
