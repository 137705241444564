import React, { useEffect } from 'react';
import { Fade, Grid, Modal, Typography } from '@mui/material';
import {
  ModalContainer,
  ModalDynamicWidthWrapper,
} from '../../../../../../../projects/components/Projects/components/ProjectRedactorModal/styles';
import { CloseDeletePopoverOne } from '../../../../../../../auth/components/Settings/components/Account/styles';
import CloseIcon from '@mui/icons-material/Close';
import { AlertTriggeredModalProps } from './types';
import { InfoSection } from './components';
import AlertTriggeredTable from './components/AlertTriggeredTable/AlertTriggeredTable';
import useAlerts from '../../../../../../hooks/useAlerts';
import { useAppSelector } from '../../../../../../../../store';

/**
 * AlertTriggeredModal is a component that displays a modal with information about a triggered alert.
 * It fetches and displays alert information and the list of affected keywords.
 */
const AlertTriggeredModal: React.FC<AlertTriggeredModalProps> = ({
  handleClose,
  open,
  triggerId,
}) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const {
    onSetAlertStatus,
    onGetAlertInfo,
    onGetAlertKeywords,
    alertInfo,
    tables: {
      alertTriggered: { paginationModel, sortModel },
    },
  } = useAlerts();

  useEffect(() => {
    if (currentAccount?.accountId && open) {
      onGetAlertInfo({ id: currentAccount.accountId, alertId: triggerId });
      onSetAlertStatus(triggerId);
    }
  }, [
    currentAccount?.accountId,
    onGetAlertInfo,
    onSetAlertStatus,
    triggerId,
    open,
  ]);

  useEffect(() => {
    if (currentAccount?.accountId && open) {
      onGetAlertKeywords({
        id: currentAccount.accountId,
        alertId: triggerId,
        page: paginationModel.page,
        limit: paginationModel.pageSize,
        sortBy: sortModel[0]?.field,
        sortOrder: sortModel[0]?.sort,
      });
    }
  }, [
    currentAccount?.accountId,
    triggerId,
    open,
    onGetAlertKeywords,
    paginationModel.page,
    paginationModel.pageSize,
    sortModel,
  ]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <ModalDynamicWidthWrapper>
          <ModalContainer container xs={12} sx={{ gap: '8px' }}>
            <Grid xs={12}>
              <Grid item xs={12} sx={{ position: 'relative' }}>
                <CloseDeletePopoverOne onClick={handleClose}>
                  <CloseIcon fontSize='small' />
                </CloseDeletePopoverOne>
                <Typography
                  fontSize='18px'
                  fontWeight='600'
                  lineHeight='28px'
                  color='#101828'
                >
                  {`Alert Triggered on ${alertInfo?.date}`}
                </Typography>
              </Grid>
              <InfoSection />
              <AlertTriggeredTable />
            </Grid>
          </ModalContainer>
        </ModalDynamicWidthWrapper>
      </Fade>
    </Modal>
  );
};

export default AlertTriggeredModal;
