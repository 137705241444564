import { Content } from '../../features/projects/components/Projects/components/TableProjects/components/Header/components/Tooltips/styles';
import React from 'react';

const TextTooltip: React.FC<{ text: string }> = ({ text }) => (
  <div>
    <Content>{text}</Content>
  </div>
);

export default TextTooltip;
