import React, { useMemo } from 'react';
import { Grid, IconButton } from '@mui/material';
import { useSharedLinks } from '../../../../../../../../hooks';

import CustomCheckboxImage from '../../../../../../../../shared/images/CustomCheckbox';
import CheckedIcon from '../../../../../../../../shared/images/CheckedIcon';

import { CustomFormControlLabel } from './styled';
import { CustomCheckbox } from '../../../../../../../../shared/checkbox/styles';
import CloseIcon from '@mui/icons-material/Close';
import { CustomTooltip } from '../../../../../../../../shared/tooltip/styles';
import * as Tooltips from '../../../Toltips';
import InfoIcon from '../../../../../../../../shared/images/InfoIcon';
import { PERIOD } from '../../../../../../../projects/constants';
import { TimesGroup } from '../../../../../../../projects/components';
import { useAppSelector } from '../../../../../../../../store';

const Header = () => {
  const {
    onSetActivePeriodImprovedVsDeclined,
    onSetIsShowImprovedVsDeclined,
    onImprovedVsDeclinedVisibilityModel,
  } = useSharedLinks();

  const noChange = useAppSelector(
    state =>
      state.sharedLinks.projectGraphs.showImprovedVsDeclinedVisibilityModel
        .noChange
  );
  const lost = useAppSelector(
    state =>
      state.sharedLinks.projectGraphs.showImprovedVsDeclinedVisibilityModel.lost
  );
  const declined = useAppSelector(
    state =>
      state.sharedLinks.projectGraphs.showImprovedVsDeclinedVisibilityModel
        .declined
  );
  const improved = useAppSelector(
    state =>
      state.sharedLinks.projectGraphs.showImprovedVsDeclinedVisibilityModel
        .improved
  );
  const activePeriodImprovedVsDeclined = useAppSelector(
    state => state.sharedLinks.projectGraphs.activePeriodImprovedVsDeclined
  );

  const timesGroupItems = useMemo(() => {
    return PERIOD.map(period => ({
      label: period.label,
      value: period.value,
      isActive: period.value === activePeriodImprovedVsDeclined.value,
      onChange: () => onSetActivePeriodImprovedVsDeclined(period),
    }));
  }, [
    activePeriodImprovedVsDeclined.value,
    onSetActivePeriodImprovedVsDeclined,
  ]);

  const onCloseCharts = () => onSetIsShowImprovedVsDeclined(false);

  return (
    <Grid
      item
      container
      sx={{ position: 'relative', marginBottom: '35px' }}
      xs={12}
    >
      <IconButton
        sx={{ position: 'absolute', top: '-8px', right: '-8px' }}
        onClick={onCloseCharts}
      >
        <CloseIcon />
      </IconButton>
      <Grid xs={12}>
        <CustomTooltip
          title={<Tooltips.ImprovedVsDeclined />}
          placement='top-start'
          arrow
        >
          <strong>
            <span
              style={{
                color: '#101828',
                fontSize: '18px',
                fontWeight: '700',
              }}
            >
              Improved vs. Declined <InfoIcon />
            </span>
          </strong>
        </CustomTooltip>

        <Grid container item xs={12} flexWrap={'wrap-reverse'}>
          <Grid container item xs={12} sm={8} sx={{ mt: '16px' }}>
            <Grid item>
              <CustomFormControlLabel
                control={
                  <CustomCheckbox
                    checked={improved}
                    onChange={event => {
                      onImprovedVsDeclinedVisibilityModel(
                        'improved',
                        event.target.checked
                      );
                    }}
                    icon={<CustomCheckboxImage />}
                    checkedIcon={<CheckedIcon checkedColor='#81D32F' />}
                  />
                }
                label='Improved'
              />
            </Grid>
            <Grid item>
              <CustomFormControlLabel
                control={
                  <CustomCheckbox
                    checked={declined}
                    onChange={event => {
                      onImprovedVsDeclinedVisibilityModel(
                        'declined',
                        event.target.checked
                      );
                    }}
                    icon={<CustomCheckboxImage />}
                    checkedIcon={<CheckedIcon checkedColor='#FF9F9F' />}
                  />
                }
                label='Declined'
              />
            </Grid>
            <Grid item>
              <CustomFormControlLabel
                control={
                  <CustomCheckbox
                    checked={lost}
                    onChange={event => {
                      onImprovedVsDeclinedVisibilityModel(
                        'lost',
                        event.target.checked
                      );
                    }}
                    icon={<CustomCheckboxImage />}
                    checkedIcon={<CheckedIcon checkedColor='#D86868' />}
                  />
                }
                label='Lost'
              />
            </Grid>
            <Grid item>
              <CustomFormControlLabel
                control={
                  <CustomCheckbox
                    checked={noChange}
                    onChange={event => {
                      onImprovedVsDeclinedVisibilityModel(
                        'noChange',
                        event.target.checked
                      );
                    }}
                    icon={<CustomCheckboxImage />}
                    checkedIcon={
                      <CheckedIcon
                        checkedColor='#D0D5DD'
                        checkedMarkColor='#101828'
                      />
                    }
                  />
                }
                label='No change'
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={4}
            sx={{
              mt: '16px',
              alignItems: 'center',
              justifyContent: 'flex-end',
              '@media (max-width:600px)': {
                justifyContent: 'flex-start',
              },
            }}
          >
            <TimesGroup items={timesGroupItems} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
