import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StyledKeywordRankings = styled(Grid)`
  margin-top: 16px;
  border-radius: 8px;
  border: 1px solid #dedede;
  box-shadow: 0px 3px 2px -1px rgba(0, 0, 0, 0.02),
    0px 1px 1px -1px rgba(0, 0, 0, 0.04);
  overflow: clip;
  background: white;
`;

const StyledArrow = styled(Grid)`
  margin-top: 16px;
  border-radius: 8px;
  border: 1px solid #dedede;
  box-shadow: 0px 3px 2px -1px rgba(0, 0, 0, 0.02),
    0px 1px 1px -1px rgba(0, 0, 0, 0.04);
`;

export { StyledKeywordRankings };
