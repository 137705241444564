import { VisibilityOff, Visibility } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link as MIULink,
  Typography,
  OutlinedInput,
  FormHelperText,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { CustomFormHelperText } from '../../../../shared/components';
import SerpNestLogo from '../../../../shared/images/SerpNestLogo';
import { useAuth } from '../../../../hooks';
import ROUTES from '../../../../routes/constants';
import {
  GoogleAuthButton,
  PrimaryButton,
} from '../../../../shared/buttons/styles';
import { Paper } from '../../../../shared/paper/styles';
import {
  LogoContainer,
  PasswordStateLabel,
  SingUpLabel,
  Wrapper,
} from './styles';
import useURLSearchParams from '../../../../hooks/useURLSearchParams';
import { LANDING_PAGE_URL } from '../../../../constants/config';
import { useAppSelector } from '../../../../store';
import {
  saveGoogleAuthLoader,
  saveVerifyEmailLocalStorage,
} from '../../../../utils/localStorage';
import GoogleIconIcon from '../../../../shared/images/GoogleIcon';
import { DividerForGoogleAuth } from '../Login/styles';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { openGoogleAuthWindow } from '../../utils';

/**
 * Component representing the registration form.
 *
 * @component
 * @returns {JSX.Element} The RegisterForm component.
 */
const RegisterForm = () => {
  const {
    onSignUp,
    onGetTariffPlans,
    onGetAddressGeolocation,
    onGetTimezones,
    onGetCountries,
  } = useAuth();

  const [googleAuthLoader] = useLocalStorage('googleAuthLoader', '0');

  const [reloadOnReturn, setReloadOnReturn] = useState(false);

  const tariffPlansList = useAppSelector(
    state => state.auth.payment.tariffPlansList
  );

  const timezonesList = useAppSelector(state => state.auth.timezones);

  const countriesList = useAppSelector(state => state.auth.countries);

  const navigate = useNavigate();
  const searchQuery = useURLSearchParams();

  const planId = searchQuery.get('planId');

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [timeZone, setTimeZone] = useState<string | null>(null);
  const [country, setCountry] = useState<string | null>(null);

  const freePlan = useMemo(
    () => tariffPlansList.find(item => item.name === 'Trial Period'),
    [tariffPlansList]
  );

  useEffect(() => {
    onGetTimezones();
    onGetCountries();
  }, [onGetCountries, onGetTimezones]);

  useEffect(() => {
    onGetTariffPlans({ type: 'monthly' });
  }, [onGetTariffPlans]);

  useEffect(() => {
    onGetAddressGeolocation({
      successFn: data => {
        setCountry(data.country_name);
        if (data.timezone === 'Europe/Kyiv') {
          setTimeZone('Europe/Kiev');
        } else setTimeZone(data.timezone);
      },
    });
  }, [onGetAddressGeolocation]);

  const formik = useFormik({
    initialValues: {
      fullname: '',
      username: '',
      password: '',
      message: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      fullname: Yup.string().required('Name is required.'),
      username: Yup.string()
        .email('Invalid email address.')
        .max(255)
        .required('Email is required.'),
      password: Yup.string().max(255).required('Password is required.'),
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      const errors = await formik.validateForm();
      formik.setErrors(errors);
      if (Object.keys(errors).length === 0) {
        const localCountry = countriesList.find(item => item.name === country);
        const localTimeZone = timezonesList.find(
          item => item.tzCode === timeZone
        );

        onSignUp({
          ...values,
          username: values.fullname,
          email: values.username,
          timezoneId: localTimeZone?.id,
          countryId: localCountry?.id,
          tariffPlan: Number(planId || freePlan?.settings?.id),
          successFn: () => {
            saveVerifyEmailLocalStorage(values.username);
            navigate(ROUTES.verifyEmail);
          },
          errorFn: (error: any) => {
            setStatus({ success: false });
            setSubmitting(false);
            setErrors(error);
          },
        });
      } else {
        setStatus({ success: false });
        setSubmitting(false);
      }
    },
  });

  /**
   * Toggles the visibility of the password field.
   */
  const handleClickShowPassword = () => setShowPassword(show => !show);

  /**
   * Prevents default action on mouse down for the password visibility icon.
   *
   * @param {React.MouseEvent<HTMLButtonElement>} event - The mouse down event.
   */
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  /**
   * Handles Google authentication.
   */
  const handleGoogleAuth = () => {
    openGoogleAuthWindow();
  };

  useEffect(() => {
    if (reloadOnReturn) {
      setReloadOnReturn(false);
      window.location.reload();
    }
  }, [reloadOnReturn]);

  useEffect(() => {
    if (Number(googleAuthLoader)) {
      saveGoogleAuthLoader('0');
      window.location.reload();
    }
  }, [googleAuthLoader]);

  return (
    <Wrapper>
      <Grid container>
        <Grid xs={1} lg={4} md={2} />
        <Grid xs={10} lg={4} md={8}>
          <Paper
            sx={{
              p: 4,
            }}
          >
            <LogoContainer>
              <SerpNestLogo />
            </LogoContainer>
            <SingUpLabel variant='h5'>Sign Up</SingUpLabel>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  mt: '24px',
                }}
              >
                <GoogleAuthButton
                  fullWidth
                  startIcon={<GoogleIconIcon width={'24px'} height={'24px'} />}
                  onClick={handleGoogleAuth}
                >
                  Sign up with Google
                </GoogleAuthButton>
              </Grid>
              <Grid
                item
                container
                justifyContent={'center'}
                xs={12}
                sx={{
                  mt: 2,
                }}
              >
                <DividerForGoogleAuth
                  orientation='horizontal'
                  variant={'fullWidth'}
                  flexItem
                >
                  OR
                </DividerForGoogleAuth>
              </Grid>
            </Grid>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel shrink htmlFor='fullname'>
                      Name
                    </InputLabel>
                    <OutlinedInput
                      autoFocus
                      fullWidth
                      id='fullname'
                      placeholder='What should we call you?'
                      name='fullname'
                      value={formik.values.fullname}
                      onChange={e => {
                        formik.setFieldValue('fullname', e.target.value);
                        const errors = { ...formik.errors };
                        delete errors.fullname;
                        delete errors.message;
                        formik.setErrors(errors);
                      }}
                      error={!!formik.errors.fullname}
                    />
                    {formik.errors.fullname && (
                      <FormHelperText error id='fullname'>
                        <CustomFormHelperText error={formik.errors.fullname} />
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel shrink htmlFor='email'>
                      Email Address
                    </InputLabel>
                    <OutlinedInput
                      fullWidth
                      type='email'
                      id='username'
                      name='username'
                      autoComplete='username'
                      placeholder='name@example.com'
                      value={formik.values.username}
                      onChange={e => {
                        formik.setFieldValue('username', e.target.value);
                        const errors = { ...formik.errors };
                        delete errors.username;
                        delete errors.message;
                        formik.setErrors(errors);
                      }}
                      error={!!formik.errors.username}
                    />
                    {formik.errors.username && (
                      <FormHelperText error id='username'>
                        <CustomFormHelperText error={formik.errors.username} />
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel shrink htmlFor='password'>
                      Password
                    </InputLabel>
                    <OutlinedInput
                      fullWidth
                      id='password'
                      type={showPassword ? 'text' : 'password'}
                      value={formik.values.password}
                      onChange={e => {
                        formik.setFieldValue('password', e.target.value);
                        const errors = { ...formik.errors };
                        delete errors.password;
                        delete errors.message;
                        formik.setErrors(errors);
                      }}
                      error={!!formik.errors.password}
                      endAdornment={
                        <InputAdornment
                          position='end'
                          onClick={handleClickShowPassword}
                          sx={{ cursor: 'pointer' }}
                        >
                          <IconButton
                            aria-label='toggle password visibility'
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                          <PasswordStateLabel>
                            {showPassword ? <b>Hide</b> : <b>Show</b>}
                          </PasswordStateLabel>
                        </InputAdornment>
                      }
                      placeholder='Password'
                      name='password'
                    />
                    <FormHelperText>
                      <Typography variant='body2' color='#666666'>
                        Minimum 8 characters with at least one number.
                      </Typography>
                    </FormHelperText>
                    {
                      <FormHelperText
                        error={!!formik.errors.password}
                        id='password'
                      >
                        {formik.errors.password && (
                          <CustomFormHelperText
                            error={formik.errors.password}
                          />
                        )}
                      </FormHelperText>
                    }
                  </Grid>
                  {formik.errors.message && (
                    <Grid item xs={12}>
                      <FormHelperText
                        error={!!formik.errors.message}
                        id='message'
                      >
                        {formik.errors.message && (
                          <CustomFormHelperText error={formik.errors.message} />
                        )}
                      </FormHelperText>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography variant='body2'>
                      By signing up, you agree to the{' '}
                      <Link to={`${LANDING_PAGE_URL}/terms-of-service`}>
                        Terms and Conditions
                      </Link>{' '}
                      and{' '}
                      <Link to={`${LANDING_PAGE_URL}/privacy-policy`}>
                        Privacy Policy
                      </Link>
                      .
                    </Typography>
                  </Grid>
                </Grid>
                <PrimaryButton
                  size='large'
                  fullWidth
                  variant='contained'
                  color={'primary'}
                  type='submit'
                  sx={{
                    mt: 3,
                    mb: 2,
                  }}
                  disabled={formik.isSubmitting || (!planId && !freePlan)}

                  // onClick={handleSingUp
                  // }
                >
                  Agree and Sign up
                </PrimaryButton>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant='body2'>
                      Already a member? <Link to={ROUTES.login}>Login</Link>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body2' color='#666666'>
                      This site is protected by reCAPTCHA and the Google{' '}
                      <MIULink
                        color='#666666'
                        href='https://policies.google.com/privacy'
                      >
                        Privacy Policy
                      </MIULink>{' '}
                      and{' '}
                      <MIULink
                        color='#666666'
                        href='https://policies.google.com/terms'
                      >
                        Terms of Service
                      </MIULink>{' '}
                      apply.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default RegisterForm;
