import { useAppSelector } from '../../../store';
import React, { useEffect, useMemo, useState } from 'react';
import { findLongestString, getColumnWidth } from '../../../utils/table';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { customSortComparator } from '../../emailReports/hooks/useScheduledEmailReportsTable';
import {
  CustomTooltip,
  DateCustomTooltip,
} from '../../../shared/tooltip/styles';
import * as Tooltips from '../../projects/components/KeywordRankHistory/components/Graph/components/Tooltips';
import { TableHeaderStyles } from '../../../shared/stylesTable';
import InfoIcon from '../../../shared/images/InfoIcon';
import { convertToTimezone, getLocalUtc } from '../../../utils/date';
import { SERVER_UTS } from '../../../constants/config';
import TextTooltip from '../../../shared/components/TextTooltip';
import { Skeleton } from '@mui/material';
import useSharedKeywordRankHistory from './useKeywordRankHistory';

export default function useSharedKeywordPositionsInfoTable() {
  const user = useAppSelector(state => state.auth.user);

  const { keywordPositionsInfo } = useSharedKeywordRankHistory();

  const [showDateFullFormat, setShowDateFullFormat] = useState<boolean>(false);

  const [dateWidth, setDateWidth] = useState<number>(100);
  const [foundUrlWidth, setFoundUrlWidth] = useState<number>(130);

  useEffect(() => {
    if (keywordPositionsInfo?.items) {
      setDateWidth(
        getColumnWidth(
          findLongestString(
            keywordPositionsInfo?.items?.map(item => item.date)
          ) || ''
        )
      );
      setFoundUrlWidth(
        getColumnWidth(
          findLongestString(
            keywordPositionsInfo?.items?.map(item => item.foundUrl)
          ) || ''
        )
      );
    }
  }, [keywordPositionsInfo?.items]);

  const tableWidth = useMemo(
    () => ({
      name: keywordPositionsInfo?.items
        ? getColumnWidth(
            findLongestString(
              keywordPositionsInfo?.items?.map(item => item.date)
            ) || ''
          )
        : dateWidth,
      foundUrl: keywordPositionsInfo?.items
        ? getColumnWidth(
            findLongestString(
              keywordPositionsInfo?.items?.map(item => item.foundUrl)
            ) || ''
          )
        : foundUrlWidth,
    }),
    [keywordPositionsInfo?.items, dateWidth, foundUrlWidth]
  );

  const popperProps = {
    sx: {
      '& .MuiTooltip-tooltip': {
        backgroundColor: '#fff',
        color: '#344054',
        boxShadow:
          '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
        border: '1px solid #EDEDED',
      },
      '.MuiTooltip-arrow::before': {
        backgroundColor: '#fff',
        border: '1px solid #EDEDED',
      },
    },
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'left',
        headerName: '',
        sortable: false,
        maxWidth: 16,
        minWidth: 16,
        renderCell: () => <div />,
      },
      {
        field: 'date',
        sortable: true,
        headerName: 'Date',
        maxWidth: 140,
        minWidth: 100,
        width: tableWidth.name,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Date />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Date '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          const date = showDateFullFormat
            ? convertToTimezone(
                params.row.dateFullFormat,
                SERVER_UTS,
                getLocalUtc()
              )
            : params.row.date;
          return (
            <DateCustomTooltip
              title={<TextTooltip text={date} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
              onClick={e => {
                e.stopPropagation();
                setShowDateFullFormat(!showDateFullFormat);
              }}
            >
              {date}
            </DateCustomTooltip>
          );
        },
      },
      {
        field: 'position',
        sortable: true,
        headerName: 'Position',
        maxWidth: 110,
        minWidth: 110,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Position />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Position '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          const position =
            params.row.position === 101 ? '> 100' : params.row.position;
          return (
            <CustomTooltip
              title={<TextTooltip text={position} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {position}
            </CustomTooltip>
          );
        },
      },
      {
        field: 'foundUrl',
        sortable: true,
        headerName: 'Found URL',
        maxWidth: 300,
        minWidth: 130,
        width: tableWidth.foundUrl,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.FoundURL />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Found URL '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.foundUrl || '-'} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params.row.foundUrl || '-'}
            </CustomTooltip>
          );
        },
      },
      {
        field: 'right',
        headerName: '',
        sortable: false,
        maxWidth: 16,
        minWidth: 16,
        renderCell: () => <div />,
      },
    ],
    [tableWidth, showDateFullFormat, user?.timezone]
  );

  const columnsSkeleton: GridColDef[] = useMemo(
    () => [
      {
        field: 'left',
        headerName: '',
        sortable: false,
        maxWidth: 16,
        minWidth: 16,
        renderCell: () => <div />,
      },
      {
        field: 'date',
        sortable: true,
        headerName: 'Date',
        maxWidth: 140,
        minWidth: 100,
        width: tableWidth.name,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'position',
        sortable: true,
        headerName: 'Position',
        maxWidth: 110,
        minWidth: 110,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'foundUrl',
        sortable: true,
        headerName: 'Found URL',
        maxWidth: 300,
        minWidth: 130,
        width: tableWidth.foundUrl,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'right',
        headerName: '',
        sortable: false,
        maxWidth: 16,
        minWidth: 16,
        renderCell: () => <div />,
      },
    ],
    [tableWidth]
  );
  return {
    columns,
    columnsSkeleton,
  };
}
