import { styled } from '@mui/material/styles';
import { Grid, Link } from '@mui/material';

const StyledSharedCompanyInfo = styled(Grid)`
  width: 100%;
  background: white;
`;

const StyledCompanyName = styled(Grid)`
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const StyledCompanyDescription = styled(Grid)`
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const StyledCompanyUrl = styled(Link)`
  color: #126fe9;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  cursor: pointer;
`;

const StyledSocialNetwork = styled(Grid)`
  cursor: pointer;
  padding-top: 2px;
`;

export {
  StyledSharedCompanyInfo,
  StyledCompanyName,
  StyledCompanyDescription,
  StyledCompanyUrl,
  StyledSocialNetwork,
};
