import React from 'react';

const DesktopAndMobileDeviceIcon = () => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_3367_190916)'>
        <path
          d='M3.83023 13.33H5.50023M5.50023 4.00003H3.63023C2.88023 4.00003 2.51023 4.00003 2.22023 4.15003C1.97023 4.28003 1.77023 4.48003 1.64023 4.73003C1.49023 5.02003 1.49023 5.39003 1.49023 6.14003V8.54003C1.49023 9.29003 1.49023 9.66003 1.64023 9.95003C1.77023 10.2 1.97023 10.4 2.22023 10.53C2.51023 10.68 2.88023 10.68 3.63023 10.68H5.50023M10.3002 13.35H12.7002C13.4502 13.35 13.8202 13.35 14.1102 13.2C14.3602 13.07 14.5602 12.87 14.6902 12.62C14.8402 12.33 14.8402 11.96 14.8402 11.21V4.80003C14.8402 4.05003 14.8402 3.68003 14.6902 3.39003C14.5602 3.14003 14.3602 2.94003 14.1102 2.81003C13.8202 2.66003 13.4502 2.66003 12.7002 2.66003H10.3002C9.55023 2.66003 9.18023 2.66003 8.89023 2.81003C8.64023 2.94003 8.44023 3.14003 8.31023 3.39003C8.16023 3.68003 8.16023 4.05003 8.16023 4.80003V11.2C8.16023 11.95 8.16023 12.32 8.31023 12.61C8.44023 12.86 8.64023 13.06 8.89023 13.19C9.18023 13.34 9.55023 13.34 10.3002 13.34V13.35Z'
          stroke='#101828'
          strokeWidth='1.33'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.2902 11.21C12.2902 11.59 11.9802 11.9 11.6002 11.9C11.2202 11.9 10.9102 11.59 10.9102 11.21C10.9102 10.83 11.2202 10.52 11.6002 10.52C11.9802 10.52 12.2902 10.83 12.2902 11.21Z'
          fill='#101828'
        />
      </g>
      <defs>
        <clipPath id='clip0_3367_190916'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DesktopAndMobileDeviceIcon;
