import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import {
  Account,
  TabPanel,
  Branding,
  Team,
  Billing,
  APITab,
} from './components';
import { useAuth } from '../../../../hooks';

import { StyledTab, TeamCountIcon } from './styles';
import ROUTES from '../../../../routes/constants';
import { useAppSelector } from '../../../../store';

export const TABS = {
  0: '/settings/account',
  1: '/settings/branding',
  2: '/settings/team',
  3: '/settings/billing',
  4: '/settings/API',
};

/**
 * Component representing the settings page with various tabs for account, branding, team, billing, and API.
 *
 * @component
 * @returns {JSX.Element} The Settings component.
 */
const Settings = () => {
  const { onGetCountries, onGetTimezones, onGetRoles } = useAuth();

  const roles = useAppSelector(state => state.auth.roles);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const timezones = useAppSelector(state => state.auth.timezones);
  const countries = useAppSelector(state => state.auth.countries);
  const myAccount = useAppSelector(state => state.auth.myAccount);
  const isAuth = useAppSelector(state => state.auth.isAuth);
  const accountLimit = useAppSelector(state => state.auth.accountLimit);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const currentPath = location.pathname;
  const pathExists = Object.values(TABS).includes(currentPath);

  useEffect(() => {
    if (!pathExists) {
      navigate(ROUTES.notFound);
    }
  }, [pathExists, navigate]);

  const showBilling = useMemo(
    () => currentAccount?.isMyAccount || currentAccount?.role?.name === 'Admin',
    [currentAccount?.isMyAccount, currentAccount?.role?.name]
  );

  const [value, setValue] = React.useState<string>(pathname);

  /**
   * Handles tab change for larger screens.
   *
   * @param {React.SyntheticEvent} event - The event that triggers the tab change.
   * @param {string} newValue - The value of the selected tab.
   */
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    navigate(newValue);
  };

  /**
   * Handles tab change for small screens (using a select dropdown).
   *
   * @param {SelectChangeEvent} event - The select change event.
   */
  const handleSmallChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
    navigate(event.target.value);
  };

  useEffect(() => {
    setValue(pathname);
  }, [pathname]);

  useEffect(() => {
    if (value === TABS['0'] && isAuth && myAccount?.id) {
      if (!countries.length) {
        onGetCountries();
      }
    }
  }, [countries.length, isAuth, onGetCountries, value, myAccount?.id]);

  useEffect(() => {
    if (value === TABS['0'] && isAuth && myAccount?.id) {
      if (!timezones.length) {
        onGetTimezones();
      }
    }
  }, [timezones.length, isAuth, value, myAccount?.id, onGetTimezones]);

  useEffect(() => {
    if (isAuth && myAccount?.id) {
      if (!roles.length) {
        onGetRoles();
      }
    }
  }, [roles.length, isAuth, value, myAccount?.id, onGetRoles]);

  useEffect(() => {
    if (pathname === '/settings/billing' && !showBilling) {
      navigate(ROUTES.dynamic.settings('account'));
    }
  }, [showBilling, navigate, pathname]);

  return (
    <Box>
      <Typography
        sx={{ pt: 1, pb: '32px' }}
        fontWeight='600'
        color='#101828'
        fontSize='28px'
      >
        Settings
      </Typography>
      <Box sx={{ width: '100%' }}>
        {extraSmallScreen ? (
          <Select
            fullWidth
            size='small'
            value={value}
            onChange={handleSmallChange}
          >
            <MenuItem value={TABS['0']}>Account</MenuItem>
            <MenuItem value={TABS['1']}>Branding</MenuItem>
            <MenuItem value={TABS['2']}>Team</MenuItem>
            {currentAccount?.isMyAccount ? (
              <MenuItem value={TABS['3']}>Billing</MenuItem>
            ) : null}
            <MenuItem value={TABS['4']}>API</MenuItem>
          </Select>
        ) : (
          <Box sx={{ borderBottom: 1, borderColor: '#EAECF0' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label='ant example'
            >
              <StyledTab label='Account' value={TABS['0']} />
              <StyledTab label='Branding' value={TABS['1']} />
              <StyledTab
                label='Team'
                icon={
                  <TeamCountIcon>
                    {accountLimit?.accountLimitsUsed?.numberOfUsers ||
                    accountLimit?.accountLimitsUsed?.numberOfInvitations
                      ? accountLimit.accountLimitsUsed.numberOfUsers +
                        accountLimit.accountLimitsUsed.numberOfInvitations
                      : 0}
                  </TeamCountIcon>
                }
                iconPosition='end'
                value={TABS['2']}
              />
              {showBilling ? (
                <StyledTab label='Billing' value={TABS['3']} />
              ) : null}
              <StyledTab label='API' value={TABS['4']} />
            </Tabs>
          </Box>
        )}

        <TabPanel value={value} index={TABS['0']}>
          {myAccount?.id && <Account />}
        </TabPanel>
        <TabPanel value={value} index={TABS['1']}>
          {myAccount?.id && <Branding />}
        </TabPanel>
        <TabPanel value={value} index={TABS['2']}>
          {myAccount?.id && <Team />}
        </TabPanel>
        {showBilling ? (
          <TabPanel value={value} index={TABS['3']}>
            {myAccount?.id && <Billing />}
          </TabPanel>
        ) : null}
        <TabPanel value={value} index={TABS['4']}>
          {myAccount?.id && <APITab />}
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Settings;
