import React from 'react';
import { IconButton } from '@mui/material';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';

import { KeywordProps } from './types';
import ListActions from './components/ListActions';

import { Popover } from '../../../../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import { StyledMoreVertIcon } from './styles';

const EllipsisMenu: React.FC<KeywordProps> = ({ keyword }) => {
  return (
    <PopupState variant='popover'>
      {popupState =>
        popupState.open ? (
          <>
            <IconButton
              {...bindTrigger(popupState)}
              onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                bindTrigger(popupState).onClick(event);
              }}
            >
              <StyledMoreVertIcon />
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <ListActions
                keyword={keyword}
                popupClose={popupState.close}
                open={popupState.open as unknown as boolean}
              />
            </Popover>
          </>
        ) : null
      }
    </PopupState>
  );
};

export default React.memo(EllipsisMenu);
