import React, { useMemo, useState } from 'react';
import { KeywordsActionProps } from './types';
import { ListItemTypography, StyledListItem } from '../styles';
import { StyledListItemButton } from '../../../../../../../../Projects/components/TableProjects/components/EllipsisMenu/styles';
import ManualRefreshKeywordsModal from '../../../../../../../../modals/ManualRefreshKeywordsModal/ManualRefreshKeywordsModal';
import RefreshIcon from '../../../../../../../../../../../shared/images/RefreshIcon';
import { useProjects } from '../../../../../../../../../../../hooks';
import { useAppSelector } from '../../../../../../../../../../../store';
import useAccountLimits from '../../../../../../../../../../auth/hooks/useAccountLimits';

const RefreshKeywordsAction: React.FC<KeywordsActionProps> = ({
  popupClose,
}) => {
  const { onCheckSubscriptionExpiration } = useAccountLimits();
  const { onSetRowSelectionKeywordsModel, onSetRefreshKeywordsModal } =
    useProjects();

  const keywordRanking = useAppSelector(
    state => state.projects.projectExpandedGraphs.keywordRankings.keywordRanking
  );
  const rowSelectionModel = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table
        .rowSelectionModel
  );

  const openRefreshKeywords = useAppSelector(
    state => state.projects.modals.refreshKeywords
  );

  const [open, setOpen] = useState(false);

  const allElementsUpdated = useMemo(() => {
    return keywordRanking?.items?.length
      ? keywordRanking.items
          .filter(item => rowSelectionModel.includes(item.id))
          .every(item => item.updateAllowed === false)
      : false;
  }, [rowSelectionModel, keywordRanking?.items]);

  const handleOpenModal = () => {
    if (!allElementsUpdated) {
      const isExpiration = onCheckSubscriptionExpiration();
      if (!isExpiration) {
        setOpen(true);
        onSetRefreshKeywordsModal(true);
      }
    }
  };

  const handleCloseModal = () => {
    onSetRowSelectionKeywordsModel([]);
    setOpen(false);
    onSetRefreshKeywordsModal(false);
    popupClose();
  };

  return (
    <>
      <StyledListItem>
        <StyledListItemButton
          onClick={handleOpenModal}
          disabled={allElementsUpdated}
        >
          <RefreshIcon />
          <ListItemTypography marginLeft='8px'>
            Refresh Keywords
          </ListItemTypography>
        </StyledListItemButton>
      </StyledListItem>
      {open && (
        <ManualRefreshKeywordsModal
          onClose={handleCloseModal}
          open={openRefreshKeywords}
          keywordIds={rowSelectionModel as number[]}
        />
      )}
    </>
  );
};

export default RefreshKeywordsAction;
