import React from 'react';

import { Header, Content } from './styles';

const Created = () => (
  <div>
    <Header>Created</Header>
    <Content>The date when the link was generated.</Content>
  </div>
);

const Type = () => (
  <div>
    <Header>Type</Header>
    <Content>
      Indicates whether the link provides access to a project or a folder.
    </Content>
  </div>
);

const LastViewed = () => (
  <div>
    <Header>Last Viewed</Header>
    <Content>The most recent date when the link was accessed.</Content>
  </div>
);

const Link = () => (
  <div>
    <Header>Link</Header>
    <Content>Click to view the shared link.</Content>
  </div>
);

export { Created, Type, LastViewed, Link };
