import React from 'react';
import {
  PaginationContainer,
  StyledTablePagination,
} from '../../../../../../../projects/components/Projects/components/TableProjects/components/Pagination/styles';
import useAlerts from '../../../../../../hooks/useAlerts';

interface PaginationProps {
  selectBy: 'by Project' | 'by Keywords';
}

const Pagination: React.FC<PaginationProps> = ({ selectBy }) => {
  const {
    onSetPaginationPageSizeAlerts,
    onSetPaginationPageAlerts,
    alerts,
    tables: {
      alerts: { paginationModel },
    },
  } = useAlerts();

  const handleSetPaginationPageSize = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onSetPaginationPageSizeAlerts(Number(e.target.value));
  };

  const handleSetPaginationPage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    onSetPaginationPageAlerts(Number(page));
  };

  const count =
    selectBy === 'by Project'
      ? alerts?.byProjects?.meta?.totalItems || 0
      : alerts?.byKeywords?.meta?.totalItems || 0;

  return (
    <PaginationContainer xs={12}>
      <StyledTablePagination
        labelRowsPerPage='Show rows:'
        count={count}
        page={paginationModel.page}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onPageChange={handleSetPaginationPage}
        rowsPerPage={paginationModel.pageSize}
        onRowsPerPageChange={handleSetPaginationPageSize}
      />
    </PaginationContainer>
  );
};

export default Pagination;
