import React from 'react';
import { StyleNotificationDeviceTypeContainer } from './styles';
import { Grid } from '@mui/material';
import FeaturedOutlineIcon from '../../../../shared/images/FeaturedOutlineIcon';

const NotificationDeviceType = () => {
  return (
    <StyleNotificationDeviceTypeContainer>
      <Grid paddingRight={'16px'}>
        <FeaturedOutlineIcon />
      </Grid>
      <Grid>
        <b>Desktop & Mobile </b>
        option uses 2 credits per keyword (1 for desktop and 1 for mobile).
      </Grid>
    </StyleNotificationDeviceTypeContainer>
  );
};

export default NotificationDeviceType;
