import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { CustomDataGrid } from '../../../../../../../auth/components/Settings/components/Team/components/customTableStyles';
import CheckboxWrapper from '../../../../../../../auth/components/Settings/components/Team/components/CheckboxWrapper';
import { Pagination } from './components';
import useAlerts from '../../../../../../hooks/useAlerts';
import { useTriggerKeywordsTable } from '../../../../../../../../hooks';
import { TableNoData } from '../../../../../../../../shared/components';
import { arraysAreEqual } from '../../../../../../../../utils';
import { useAppSelector } from '../../../../../../../../store';

const KeywordsAssociatedWithTriggerTable = () => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const isAuth = useAppSelector(state => state.auth.isAuth);

  const {
    onSetSortModelTriggerKeywords,
    onSetRowSelectionModelTriggerKeywords,
    triggerKeywords,
    tables: {
      triggerKeywords: {
        rowSelectionModel,
        deleteElements,
        paginationModel: { pageSize },
      },
    },
  } = useAlerts();

  const { columns, columnsSkeleton } = useTriggerKeywordsTable();

  const openTariffPlanBanner = useAppSelector(
    state => state.auth.openTariffPlanBanner
  );

  const [itemCount, setItemCount] = useState(
    triggerKeywords?.meta?.itemCount || pageSize
  );

  const isViewOnly = useMemo(
    () => !isAuth || (isAuth && currentAccount?.role?.name === 'ViewOnly'),
    [currentAccount?.role?.name, isAuth]
  );

  const pinnedColumns = useMemo(
    () => ({
      right: !isViewOnly ? ['actions'] : [],
    }),
    [isViewOnly]
  );

  const isRowSelectable = useCallback(() => !isViewOnly, [isViewOnly]);

  const arrayOfObjects = useMemo(
    () =>
      Array.from({ length: itemCount }, (_, index) => ({
        triggerKeywordId: index + 1,
      })),
    [itemCount]
  );

  const rows = useMemo(() => {
    const arr1 = [
      ...(triggerKeywords?.items?.length ? triggerKeywords.items : []),
    ];
    const arr2 = [...deleteElements];

    return arr1.filter(item => !arr2.includes(item.triggerKeywordId));
  }, [deleteElements, triggerKeywords?.items]);

  const renderRows = useMemo(() => {
    return !triggerKeywords?.items ? arrayOfObjects : rows;
  }, [arrayOfObjects, triggerKeywords?.items, rows]);

  const renderColumns = useMemo(() => {
    return !triggerKeywords?.items ? columnsSkeleton : columns;
  }, [columns, columnsSkeleton, triggerKeywords?.items]);

  useEffect(() => {
    const filtered = triggerKeywords?.items
      ?.filter(obj => rowSelectionModel.includes(obj.triggerKeywordId))
      ?.map(item => item.triggerKeywordId)
      .filter(item => !deleteElements.includes(item));
    if (filtered && !arraysAreEqual(rowSelectionModel, filtered)) {
      onSetRowSelectionModelTriggerKeywords(filtered);
    }
  }, [
    deleteElements,
    onSetRowSelectionModelTriggerKeywords,
    triggerKeywords?.items,
    rowSelectionModel,
  ]);

  useEffect(() => {
    if (triggerKeywords?.meta?.itemCount) {
      setItemCount(triggerKeywords.meta.itemCount);
    }
  }, [triggerKeywords?.meta?.itemCount]);

  return (
    <>
      <Grid xs={12}>
        <CustomDataGrid
          pinnedColumns={pinnedColumns}
          columnHeaderHeight={40}
          rowHeight={40}
          rows={renderRows}
          columns={renderColumns}
          rowSelectionModel={rowSelectionModel}
          onSortModelChange={onSetSortModelTriggerKeywords}
          onRowSelectionModelChange={onSetRowSelectionModelTriggerKeywords}
          isRowSelectable={isRowSelectable}
          getRowId={row => row.triggerKeywordId}
          components={{
            BaseCheckbox: CheckboxWrapper,
            NoRowsOverlay: !triggerKeywords ? Box : TableNoData,
          }}
          keepNonExistentRowsSelected
          hideFooterPagination
          checkboxSelection
          disableRowSelectionOnClick
          disableColumnMenu
          $openTariffPlanBanner={openTariffPlanBanner}
          $hardPositionTop={'0px'}
        />
      </Grid>
      <Pagination />
    </>
  );
};

export default KeywordsAssociatedWithTriggerTable;
