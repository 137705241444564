import React, { useEffect } from 'react';

import { StyledKeywordRankingsTable } from './styles';
import { Header } from './components';
import Table from './components/Table/Table';
import { useSharedLinks } from '../../../../../../../../hooks';
import { getSharedLinkToken } from '../../../../../../../../utils/localStorage';
import { checkObjectProperties } from '../../../../../../../projects/utils';
import { DEFAULT_KEYWORD_POSITIONS_MODEL } from '../../../../../../../projects/constants';
import { useAppSelector } from '../../../../../../../../store';

const KeywordRankingsTable = () => {
  const { onGetSharedProjectKeywordRankings } = useSharedLinks();

  const projectSharedDeviceType = useAppSelector(
    state => state.sharedLinks.projectSharedDeviceType
  );
  const keywordPositionModel = useAppSelector(
    state =>
      state.sharedLinks.projectGraphs.keywordRankings.table.keywordPositionModel
  );
  const paginationModel = useAppSelector(
    state =>
      state.sharedLinks.projectGraphs.keywordRankings.table.paginationModel
  );
  const sortModel = useAppSelector(
    state => state.sharedLinks.projectGraphs.keywordRankings.table.sortModel
  );
  const search = useAppSelector(
    state => state.sharedLinks.projectGraphs.keywordRankings.table.search
  );
  const selectedTags = useAppSelector(
    state => state.sharedLinks.projectGraphs.keywordRankings.table.selectedTags
  );
  const projectShared = useAppSelector(
    state => state.sharedLinks.projectShared
  );

  useEffect(() => {
    const sharedLinkToken = getSharedLinkToken();

    if (sharedLinkToken && projectSharedDeviceType && projectShared?.id) {
      onGetSharedProjectKeywordRankings({
        link: sharedLinkToken,
        projectId: projectShared?.id,
        params: {
          page: paginationModel.page,
          limit: paginationModel.pageSize,
          search: search,
          sortBy: sortModel[0]?.field,
          sortOrder: sortModel[0]?.sort,
        },
        filters: checkObjectProperties(
          DEFAULT_KEYWORD_POSITIONS_MODEL,
          keywordPositionModel
        ),
        deviceType: projectSharedDeviceType,
        tagIds: selectedTags.map(item => item.id),
      });
    }
  }, [
    onGetSharedProjectKeywordRankings,
    projectShared?.id,
    search,
    sortModel,
    paginationModel.page,
    paginationModel.pageSize,
    keywordPositionModel,
    projectSharedDeviceType,
    selectedTags,
  ]);

  return (
    <StyledKeywordRankingsTable>
      <Header />
      <Table />
    </StyledKeywordRankingsTable>
  );
};

export default KeywordRankingsTable;
