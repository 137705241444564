import React, { useState } from 'react';
import { ListActionsProps } from './types';
import {
  ListItemTypography,
  StyledListItem,
} from '../../../../../../../projects/components/ProjectExpanded/components/KeywordRankings/components/KeywordRankingsTable/components/EllipsisMenu/styles';
import EditPencilIcon from '../../../../../../../../shared/images/EditPencilIcon';
import EditNoteModal from '../../../../../../modals/NotesPage/EditNoteModal/EditNoteModal';
import { StyledListItemButton } from '../../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/styles';
import { useNotes } from '../../../../../../../../hooks';

const EditProjectNoteAction: React.FC<ListActionsProps> = ({
  projectId,
  noteId,
  popupClose,
}) => {
  const { onSetRowSelectionModelAllNotes } = useNotes();

  const [isOpenEditNoteModal, setOpenEditNoteModal] = useState(false);

  const onOpen = () => {
    setOpenEditNoteModal(true);
  };

  const onClose = () => {
    onSetRowSelectionModelAllNotes([]);
    setOpenEditNoteModal(false);
    popupClose();
  };

  return (
    <StyledListItem onClick={onOpen}>
      <StyledListItemButton>
        <EditPencilIcon />
        <ListItemTypography marginLeft='8px'>Edit Note</ListItemTypography>
      </StyledListItemButton>
      <EditNoteModal
        projectId={projectId}
        noteId={noteId}
        onClose={onClose}
        open={isOpenEditNoteModal}
      />
    </StyledListItem>
  );
};

export default EditProjectNoteAction;
