import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StyledHeadText = styled(Grid)`
  color: #101828;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
`;

export { StyledHeadText };
