import React, { useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { GrayButtonCustom } from '../../../../../../../../../../../shared/buttons/styles';
import RefreshIcon from '../../../../../../../../../../../shared/images/RefreshIcon';
import RefreshAllKeywordsModal from '../../../../../../../../modals/RefreshAllKeywordsModal/RefreshAllKeywordsModal';
import { useParams } from 'react-router';
import { decryptData } from '../../../../../../../../../../../utils/cryptoJs';
import { useAppSelector } from '../../../../../../../../../../../store';
import useAccountLimits from '../../../../../../../../../../auth/hooks/useAccountLimits';
import { useProjects } from '../../../../../../../../../../../hooks';

const RefreshAllKeywordsButton = () => {
  const { id } = useParams();

  const { onCheckSubscriptionExpiration } = useAccountLimits();
  const { onSetRefreshAllKeywordsModal } = useProjects();

  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );

  const open = useAppSelector(
    state => state.projects.modals.refreshAllKeywords
  );

  const decryptId = useMemo(() => {
    if (id) {
      return decryptData(id);
    }
  }, [id]);

  const handleOpenModal = () => {
    const isExpiration = onCheckSubscriptionExpiration();
    if (!isExpiration) {
      onSetRefreshAllKeywordsModal(true);
    }
  };
  const handleCloseModal = () => {
    onSetRefreshAllKeywordsModal(false);
  };

  return (
    <>
      <Grid item>
        <GrayButtonCustom
          $padding='7px 12px'
          size='small'
          outline
          startIcon={<RefreshIcon />}
          onClick={handleOpenModal}
        >
          Refresh All Keywords
        </GrayButtonCustom>
      </Grid>
      {decryptId && projectExpanded ? (
        <RefreshAllKeywordsModal
          onClose={handleCloseModal}
          open={open}
          projectIds={[Number(decryptId)]}
          keywordCount={projectExpanded.keywordCount}
        />
      ) : null}
    </>
  );
};

export default RefreshAllKeywordsButton;
