import { styled } from '@mui/material/styles';

import {
  CustomDataGrid as ProjectCustomDataGrid,
  Content as ProjectContent,
  EmptyState as ProjectEmptyState,
  StyledCustomNoRowsOverlay as ProjectStyledCustomNoRowsOverlay,
} from '../../../../../../../projects/components/Projects/components/TableProjects/components/Table/styles';

const CustomDataGrid = styled(ProjectCustomDataGrid)``;

const StyledCustomNoRowsOverlay = styled(ProjectStyledCustomNoRowsOverlay)``;

const EmptyState = styled(ProjectEmptyState)``;

const Content = styled(ProjectContent)``;

export { CustomDataGrid, StyledCustomNoRowsOverlay, EmptyState, Content };
