import { styled } from '@mui/system';
import { Chip } from '@mui/material';

const StyledAutocompleteLi = styled('li')`
  span {
    padding: 0;
    margin-right: 8px;
  }
`;

const StyledChip = styled(Chip)`
  color: #344054;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  height: 24px;

  border-radius: 6px;
  border: 1px solid #d0d5dd;
  background: #fff;
`;

export { StyledAutocompleteLi, StyledChip };
