import React from 'react';
import { Box } from '@mui/material';
import ROUTES from '../../../../../../routes/constants';
import useAlerts from '../../../../hooks/useAlerts';
import { encryptData } from '../../../../../../utils/cryptoJs';
import { useAppSelector } from '../../../../../../store';

interface KeywordViewProps {
  projectId: number;
  keywordId: number;
  alertId: number;
  keywordName: string;
}

/**
 * React component that displays a clickable keyword name, triggering an alert view and opening the keyword rank history.
 */
const KeywordView: React.FC<KeywordViewProps> = ({
  projectId,
  keywordId,
  alertId,
  keywordName,
}) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const {
    onSetViewAlertByKeywords,
    onGetAlertsByKeywords,
    activeProjectForFilterAlerts,
    tables: {
      alerts: { paginationModel, sortModel, search },
    },
  } = useAlerts();

  /**
   * Handles the click event on the keyword name.
   * Sets the view for the alert and opens the keyword rank history.
   */
  const handleClick = () => {
    if (currentAccount?.accountId) {
      onSetViewAlertByKeywords({
        id: currentAccount.accountId,
        alertId: alertId,
        successFn: () => {
          onGetAlertsByKeywords({
            id: currentAccount.accountId,
            projectIds: activeProjectForFilterAlerts
              ? [activeProjectForFilterAlerts]
              : [],
            page: paginationModel.page,
            limit: paginationModel.pageSize,
            search: search,
            sortBy: sortModel[0]?.field,
            sortOrder: sortModel[0]?.sort,
          });
        },
      });
      window.open(
        `${window.location.origin}${ROUTES.dynamic.keywordRankHistory(
          encryptData(projectId.toString()),
          encryptData(keywordId.toString())
        )}`
      );
    }
  };

  return (
    <Box display='flex' alignItems='center'>
      <Box
        className={'view'}
        style={{
          color: '#126FE9',
          cursor: 'pointer',
          marginLeft: '8px',
        }}
        onClick={handleClick}
      >
        {keywordName}
      </Box>
    </Box>
  );
};

export default KeywordView;
