import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StyledSearchResultContainer = styled(Grid)`
  width: 100%;
  position: relative;

  border-bottom: 1px solid #dedede;
  border-radius: 8px 8px 0 0;
  margin-bottom: 8px;
`;

const Header = styled(Grid)`
  color: #020202;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
`;

export { StyledSearchResultContainer, Header };
