import { NewProject, NewProjectParams, NewProjectValues } from './types';

interface getCreateProjectActions {
  google: (params: NewProjectParams) => void;
  googleMyBusiness: (params: NewProjectParams) => void;
  googleMaps: (params: NewProjectParams) => void;
  bing: (params: NewProjectParams) => void;
  yahoo: (params: NewProjectParams) => void;
  baidu: (params: NewProjectParams) => void;
  youTube: (params: NewProjectParams) => void;
}

function determineProjectTypeAndAction(
  searchEngine: string,
  createProjectActions: getCreateProjectActions
) {
  switch (searchEngine) {
    case 'Google':
      return createProjectActions.google;
    case 'Google My Business':
      return createProjectActions.googleMyBusiness;
    case 'Google Maps':
      return createProjectActions.googleMaps;
    case 'Bing':
      return createProjectActions.bing;
    case 'Yahoo':
      return createProjectActions.yahoo;
    case 'Baidu':
      return createProjectActions.baidu;
    case 'YouTube':
      return createProjectActions.youTube;
    default:
      return undefined;
  }
}

function convertRequestDataByProjectType(
  searchEngine: string,
  values: NewProjectValues
) {
  switch (searchEngine) {
    case 'Google': {
      const requestData: NewProject = {
        projectName: values.projectName,
        url: values.url,
        projectUrlType: values.projectUrlType.value,
        keywords: values.keywords.filter(Boolean),
        searchEngine: values.searchEngine,
        regionId: values.regionId.id,
        languageId: values.languageId.id,
        checkFrequency: values.checkFrequency.name,
        deviceType: values.deviceType.name,
      };
      if (values?.location?.id) {
        requestData.locationId = values.location.id;
      }
      if (values.competitors.length)
        requestData.competitors = values.competitors;
      if (values.tags.length) requestData.tags = values.tags;
      if (values.tagIds.length)
        requestData.tagIds = values.tagIds.map(item => item.id);
      if (values.note) requestData.note = values.note;
      return requestData;
    }
    case 'Google My Business': {
      const requestData: NewProject = {
        projectName: values.projectName,
        businessName: values.businessName,
        businessUrl: values.businessUrl,
        keywords: values.keywords.filter(Boolean),
        checkFrequency: values.checkFrequency.name,
        languageId: values.languageId.id,
      };
      if (values?.location?.id) {
        requestData.locationId = values.location.id;
      }
      if (values.businessCompetitors.length)
        requestData.competitors = values.businessCompetitors;
      if (values.tags.length) requestData.tags = values.tags;
      if (values.tagIds.length)
        requestData.tagIds = values.tagIds.map(item => item.id);
      if (values.note) requestData.note = values.note;

      return requestData;
    }
    case 'Google Maps': {
      const requestData: NewProject = {
        projectName: values.projectName,
        businessName: values.businessName,
        businessUrl: values.businessUrl,
        keywords: values.keywords.filter(Boolean),
        checkFrequency: values.checkFrequency.name,
        languageId: values.languageId.id,
        regionId: values.regionId.id,
      };
      if (values?.location?.id) {
        requestData.locationId = values.location.id;
      }
      if (values.businessCompetitors.length)
        requestData.competitors = values.businessCompetitors;
      if (values.tags.length) requestData.tags = values.tags;
      if (values.tagIds.length)
        requestData.tagIds = values.tagIds.map(item => item.id);
      if (values.note) requestData.note = values.note;

      return requestData;
    }
    case 'Bing': {
      const requestData: NewProject = {
        projectName: values.projectName,
        url: values.url,
        projectUrlType: values.projectUrlType.value,
        keywords: values.keywords.filter(Boolean),
        deviceType: values.deviceType.name,
        languageId: values.languageId.id,
        checkFrequency: values.checkFrequency.name,
      };
      if (values?.location?.id) {
        requestData.locationId = values.location.id;
      }
      if (values.competitors.length)
        requestData.competitors = values.competitors;
      if (values.tags.length) requestData.tags = values.tags;
      if (values.tagIds.length)
        requestData.tagIds = values.tagIds.map(item => item.id);
      if (values.note) requestData.note = values.note;

      return requestData;
    }
    case 'Yahoo': {
      const requestData: NewProject = {
        projectName: values.projectName,
        url: values.url,
        projectUrlType: values.projectUrlType.value,
        keywords: values.keywords.filter(Boolean),
        deviceType: values.deviceType.name,
        languageId: values.languageId.id,
        checkFrequency: values.checkFrequency.name,
      };
      if (values?.location?.id) {
        requestData.locationId = values.location.id;
      }
      if (values.competitors.length)
        requestData.competitors = values.competitors;
      if (values.tags.length) requestData.tags = values.tags;
      if (values.tagIds.length)
        requestData.tagIds = values.tagIds.map(item => item.id);
      if (values.note) requestData.note = values.note;

      return requestData;
    }
    case 'Baidu': {
      const requestData: NewProject = {
        projectName: values.projectName,
        url: values.url,
        projectUrlType: values.projectUrlType.value,
        keywords: values.keywords.filter(Boolean),
        deviceType: values.deviceType.name,
        languageId: values.languageId.id,
        checkFrequency: values.checkFrequency.name,
      };
      if (values?.location?.id) {
        requestData.locationId = values.location.id;
      }
      if (values.competitors.length)
        requestData.competitors = values.competitors;
      if (values.tags.length) requestData.tags = values.tags;
      if (values.tagIds.length)
        requestData.tagIds = values.tagIds.map(item => item.id);
      if (values.note) requestData.note = values.note;

      return requestData;
    }
    case 'YouTube': {
      const requestData: NewProject = {
        projectName: values.projectName,
        videoUrl: values.url,
        keywords: values.keywords.filter(Boolean),
        languageId: values.languageId.id,
        checkFrequency: values.checkFrequency.name,
      };
      if (values?.location?.id) {
        requestData.locationId = values.location.id;
      }
      if (values?.competitorsVideoUrl?.length)
        requestData.competitorsVideoUrl = values.competitorsVideoUrl;
      if (values.tags.length) requestData.tags = values.tags;
      if (values.tagIds.length)
        requestData.tagIds = values.tagIds.map(item => item.id);
      if (values.note) requestData.note = values.note;

      return requestData;
    }
    default:
      return null;
  }
}

export { determineProjectTypeAndAction, convertRequestDataByProjectType };
