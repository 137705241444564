import React from 'react';
import { Grid, Slider, styled, Switch, SwitchProps } from '@mui/material';

const StylesLabel = styled(Grid)`
  text-align: center;
  color: #0e59ba;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

const StylesDescription = styled(Grid)`
  text-align: center;
  color: #101828;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;
`;

const StylesSlider = styled(Slider)`
  margin-top: 90px;

  .MuiSlider-markLabel {
    top: -250%;
    color: #1d293f;
    font-size: 18px;
    font-weight: 500;

    @media (max-width: 900px) {
      top: -140%;
    }
  }

  .MuiSlider-mark {
    top: -80%;
    height: 8px;

    @media (max-width: 900px) {
      top: -25%;
    }
  }

  @media (max-width: 900px) {
    margin-top: 40px;
  }
`;

const StylesSliderContainer = styled(Grid)`
  border-radius: 8px;
  border: 1px solid #ededed;
  background: #fff;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.04),
    0px 4px 8px -2px rgba(0, 0, 0, 0.08);
  padding: 48px;
  margin: 49px auto;
`;

const StylesSliderLabel = styled(Grid)`
  color: #1d293f;
  font-size: 24px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: -0.48px;
  padding-bottom: 50px;
`;

const StylesSliderActiveKeywordsCount = styled(Grid)`
  color: #1d293f;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 24px;
`;
const StylesSliderNeedMore = styled(Grid)`
  color: #1d293f;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const StylesTotalContainer = styled(Grid)`
  padding: 24px 0;
`;

const StylesTotalValue = styled(Grid)`
  color: #1d293f;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -2px;
`;

const StylesTotalLabel = styled(Grid)`
  color: #475467;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding-top: 8px;
`;

const StylesMonthlyYearly = styled(Grid)`
  color: #1d293f;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const StylesMonthsFree = styled('span')`
  color: #126fe9;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  padding: 8px;
  border-radius: 8px;
  background: #eef3ff;
`;

const CustomSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#126FE9' : '#126FE9',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#126FE9',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? '#126FE9' : '#126FE9',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export {
  StylesLabel,
  StylesDescription,
  StylesSlider,
  StylesSliderContainer,
  StylesSliderLabel,
  StylesSliderActiveKeywordsCount,
  StylesSliderNeedMore,
  StylesTotalContainer,
  StylesTotalValue,
  StylesTotalLabel,
  StylesMonthlyYearly,
  StylesMonthsFree,
  CustomSwitch,
};
