import useAlerts from '../../../../../../../../hooks/useAlerts';
import React, { useMemo } from 'react';
import {
  PaginationContainer,
  StyledTablePagination,
} from '../../../../../../../../../projects/components/Projects/components/TableProjects/components/Pagination/styles';

interface PaginationProps {
  selectBy: 'by Project' | 'by Keywords';
}

const Pagination: React.FC<PaginationProps> = ({ selectBy }) => {
  const {
    onSetPaginationPageSizeTriggers,
    onSetPaginationPageTriggers,
    triggers: { byProjects, byKeywords },
    tables: {
      triggers: { paginationModel },
    },
  } = useAlerts();

  const handleSetPaginationPageSize = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onSetPaginationPageSizeTriggers(Number(e.target.value));
  };

  const handleSetPaginationPage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    onSetPaginationPageTriggers(Number(page));
  };

  const count = useMemo(() => {
    if (selectBy === 'by Project') {
      return byProjects?.meta?.totalItems || 0;
    } else {
      return byKeywords?.meta?.totalItems || 0;
    }
  }, [byKeywords?.meta?.totalItems, byProjects?.meta?.totalItems, selectBy]);

  return (
    <PaginationContainer xs={12}>
      <StyledTablePagination
        labelRowsPerPage='Show rows:'
        count={count}
        page={paginationModel.page}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onPageChange={handleSetPaginationPage}
        rowsPerPage={paginationModel.pageSize}
        onRowsPerPageChange={handleSetPaginationPageSize}
      />
    </PaginationContainer>
  );
};

export default Pagination;
