import { Box } from '@mui/material';
import React from 'react';
import { CustomToastProps } from './types';

const CustomToast: React.FC<CustomToastProps> = ({ message, submessage }) => (
  <Box sx={{ marginLeft: '18px' }}>
    <Box
      sx={{
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#EBEBEB',
      }}
    >
      {message}
    </Box>
    <Box sx={{ fontWeight: 'normal', fontSize: '14px', lineHeight: '24px' }}>
      {submessage}
    </Box>
  </Box>
);

export default CustomToast;
