import React, { useEffect, useMemo, useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Box, Grid, Skeleton } from '@mui/material';

import { EllipsisMenu } from '../components/Folders/components/TableFolders/components';
import * as Tooltips from '../components/Folders/components/TableFolders/components/Header/components/Tooltips';
import * as ProjectTooltips from '../../projects/components/Projects/components/TableProjects/components/Header/components/Tooltips';
import InfoIcon from '../../../shared/images/InfoIcon';
import FolderIcon from '../../../shared/images/FolderIcon';

import {
  CustomTooltip,
  DateCustomTooltip,
} from '../../../shared/tooltip/styles';
import TextTooltip from '../../../shared/components/TextTooltip';
import DailyAverageGraph from '../../projects/components/DailyAverageGraph/DailyAverageGraph';
import useFolders from './useFolders';
import { findLongestString, getColumnWidth } from '../../../utils/table';
import { customSortComparator } from '../../emailReports/hooks/useScheduledEmailReportsTable';
import { getDeviceTypeIcon, getSearchEngineIcon } from '../../projects/utils';
import { useAppSelector } from '../../../store';
import GlobeIcon from '../../../shared/images/GlobeIcon';
import { TableHeaderStyles } from '../../../shared/stylesTable';
import { convertToTimezone } from '../../../utils/date';
import { SERVER_UTS } from '../../../constants/config';
import { getFlagEmoji } from '../../../utils/getCountryCode';

export default function useFoldersTable() {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const user = useAppSelector(state => state.auth.user);

  const { folders } = useFolders();

  const [showModifiedFullFormat, setShowModifiedFullFormat] =
    useState<boolean>(false);
  const [showUpdatedFullFormat, setShowUpdatedFullFormat] =
    useState<boolean>(false);
  const [showCreatedAtFullFormat, setShowCreatedAtFullFormat] =
    useState<boolean>(false);

  const popperProps = {
    sx: {
      '& .MuiTooltip-tooltip': {
        backgroundColor: '#fff',
        color: '#344054',
        boxShadow:
          '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
        border: '1px solid #EDEDED',
      },
      '.MuiTooltip-arrow::before': {
        backgroundColor: '#fff',
        border: '1px solid #EDEDED',
      },
    },
  };

  const [nameWidth, setNameWidth] = useState<number>(100);
  const [createdByWidth, setCreatedByWidth] = useState<number>(100);
  const [deviceTypeNameWidth, setDeviceTypeNameWidth] = useState<number>(100);
  const [urlWidth, setUrlWidth] = useState<number>(100);
  const [createdAtWidth, setCreatedAtWidth] = useState<number>(100);

  useEffect(() => {
    if (folders?.items) {
      setNameWidth(
        getColumnWidth(
          findLongestString(folders?.items?.map(item => item.name)) || ''
        )
      );
      setCreatedByWidth(
        getColumnWidth(
          findLongestString(folders?.items?.map(item => item.createdBy)) || ''
        )
      );
      setDeviceTypeNameWidth(
        getColumnWidth(
          findLongestString(folders?.items?.map(item => item.deviceTypeName)) ||
            ''
        )
      );
      setUrlWidth(
        getColumnWidth(
          findLongestString(folders?.items?.map(item => item.url)) || ''
        )
      );
      setCreatedAtWidth(
        getColumnWidth(
          findLongestString(folders?.items?.map(item => item.createdAt)) || ''
        )
      );
    }
  }, [folders?.items]);

  const tableWidth = useMemo(
    () => ({
      name: folders?.items
        ? getColumnWidth(
            findLongestString(folders?.items?.map(item => item.name)) || ''
          )
        : nameWidth,
      createdBy: folders?.items
        ? getColumnWidth(
            findLongestString(folders?.items?.map(item => item.createdBy)) || ''
          )
        : createdByWidth,
      deviceTypeName: 90,
      url: folders?.items
        ? getColumnWidth(
            findLongestString(folders?.items?.map(item => item.url)) || ''
          )
        : urlWidth,
      createdAt: folders?.items
        ? getColumnWidth(
            findLongestString(folders?.items?.map(item => item.createdAt)) || ''
          )
        : createdAtWidth,
    }),
    [folders, nameWidth, createdByWidth, urlWidth]
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      sortable: true,
      headerName: 'Name',
      maxWidth: 300,
      minWidth: 100,
      width: tableWidth.name + 20,
      sortComparator: customSortComparator,
      renderHeader: () => {
        return (
          <CustomTooltip title={<Tooltips.Name />} placement='top-start' arrow>
            <TableHeaderStyles>
              {'Name '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <CustomTooltip
            title={<TextTooltip text={params.row.name} />}
            placement='top-start'
            arrow
            PopperProps={popperProps}
          >
            <Grid display={'flex'} alignItems={'center'}>
              {params.row.type === 'folder' ? (
                <FolderIcon />
              ) : params.row?.favicon ? (
                <img
                  src={params.row.favicon}
                  width={16}
                  height={16}
                  alt={'Company Logo URL'}
                />
              ) : (
                <GlobeIcon />
              )}
              <Box display='flex' alignItems='center'>
                <Box marginLeft='8px'>{params.row.name}</Box>
              </Box>
            </Grid>
          </CustomTooltip>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      sortable: true,
      maxWidth: 300,
      minWidth: 130,
      width: tableWidth.createdAt,
      headerAlign: 'left',
      align: 'left',
      sortComparator: customSortComparator,
      renderHeader: () => {
        return (
          <CustomTooltip
            title={<Tooltips.CreatedAt />}
            placement='top-start'
            arrow
          >
            <TableHeaderStyles>
              {'Created  '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        const date = showCreatedAtFullFormat
          ? convertToTimezone(
              params.row.createdAtFullFormat,
              SERVER_UTS,
              user.timezone.utc
            )
          : params.row.createdAt;
        return (
          <DateCustomTooltip
            title={<TextTooltip text={date} />}
            placement='top-start'
            arrow
            PopperProps={popperProps}
            sx={{
              borderBottom:
                params.row.createdAt === '-' ? 'initial' : 'dashed 1px #d0d5dd',
            }}
            onClick={e => {
              e.stopPropagation();
              setShowCreatedAtFullFormat(!showCreatedAtFullFormat);
            }}
          >
            {date}
          </DateCustomTooltip>
        );
      },
    },
    {
      field: 'createdBy',
      headerName: 'Created by',
      sortable: false,
      maxWidth: 300,
      minWidth: 130,
      width: tableWidth.createdBy,
      headerAlign: 'left',
      align: 'left',
      sortComparator: customSortComparator,
      renderHeader: () => {
        return (
          <CustomTooltip
            title={<Tooltips.CreatedBy />}
            placement='top-start'
            arrow
          >
            <TableHeaderStyles>
              {'Created by '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <CustomTooltip
            title={<TextTooltip text={params.row.createdBy} />}
            placement='top-start'
            arrow
            PopperProps={popperProps}
          >
            {params.row.createdBy}
          </CustomTooltip>
        );
      },
    },
    {
      field: 'lastModified',
      headerName: 'Last modified',
      sortable: true,
      maxWidth: 150,
      minWidth: 150,
      headerAlign: 'left',
      align: 'left',
      sortComparator: customSortComparator,
      renderHeader: () => {
        return (
          <CustomTooltip
            title={<Tooltips.Updated />}
            placement='top-start'
            arrow
          >
            <TableHeaderStyles>
              {'Last modified '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        const date = showModifiedFullFormat
          ? params.row.lastModifiedFullFormat === '-'
            ? params.row.lastModifiedFullFormat
            : convertToTimezone(
                params.row.lastModifiedFullFormat,
                SERVER_UTS,
                user.timezone.utc
              )
          : params.row.lastModified;

        return (
          <DateCustomTooltip
            title={<TextTooltip text={date} />}
            placement='top-start'
            arrow
            PopperProps={popperProps}
            sx={{
              borderBottom:
                params.row.lastModified === '-'
                  ? 'initial'
                  : 'dashed 1px #d0d5dd',
            }}
            onClick={e => {
              e.stopPropagation();
              setShowModifiedFullFormat(!showModifiedFullFormat);
            }}
          >
            {date}
          </DateCustomTooltip>
        );
      },
    },
    {
      field: 'deviceType',
      headerName: 'type',
      sortable: true,
      width: tableWidth.deviceTypeName,
      headerAlign: 'left',
      align: 'left',
      sortComparator: customSortComparator,
      renderHeader: () => {
        return (
          <CustomTooltip
            title={<ProjectTooltips.Type />}
            placement='top-start'
            arrow
          >
            <TableHeaderStyles>
              {'Type '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        const deviceType =
          params.row.deviceTypeName !== '-'
            ? params.row.deviceTypeName === 'DesktopAndMobile'
              ? 'Desktop & Mobile'
              : params.row.deviceTypeName
            : '';

        const region = `${
          params?.row?.region?.name !== '-'
            ? params?.row?.region?.name
              ? ` / ${params.row.region.name}`
              : ''
            : ''
        }`;

        const searchEngine =
          params.row.searchEngine.name !== '-'
            ? ` / ${params.row.searchEngine.name}`
            : '';

        const tooltipTitle = `${deviceType}${searchEngine}${region}`;

        const deviceTypeIcon =
          params.row?.deviceTypeName && params.row.deviceTypeName !== '-'
            ? getDeviceTypeIcon(params.row.deviceTypeName)
            : null;

        const searchEngineIcon =
          params.row?.searchEngine?.name && params.row.searchEngine.name !== '-'
            ? getSearchEngineIcon(params.row.searchEngine.name)
            : null;

        const flagEmoji =
          params.row?.region?.name && params.row.region.name !== '-'
            ? getFlagEmoji(params.row.region.name)
            : null;

        return (
          <CustomTooltip
            title={
              tooltipTitle ? <TextTooltip text={tooltipTitle} /> : undefined
            }
            placement='top-start'
            arrow
            PopperProps={popperProps}
          >
            <Box
              gap={1}
              display='flex'
              alignItems='center'
              sx={{ cursor: 'pointer' }}
            >
              {deviceTypeIcon}
              {searchEngineIcon}
              {flagEmoji}
            </Box>
          </CustomTooltip>
        );
      },
    },
    {
      field: 'url',
      headerName: 'URL',
      sortable: true,
      maxWidth: 300,
      minWidth: 100,
      width: tableWidth.url,
      headerAlign: 'left',
      align: 'left',
      sortComparator: customSortComparator,
      renderHeader: () => {
        return (
          <CustomTooltip
            title={<ProjectTooltips.URL />}
            placement='top-start'
            arrow
          >
            <TableHeaderStyles>
              {'URL '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <CustomTooltip
            title={<TextTooltip text={params.row.url || '-'} />}
            placement='top-start'
            arrow
            PopperProps={popperProps}
          >
            {params.row.url || '-'}
          </CustomTooltip>
        );
      },
    },
    {
      field: 'totalKeywords',
      headerName: 'totalKeywords',
      sortable: true,
      minWidth: 160,
      maxWidth: 160,
      headerAlign: 'left',
      align: 'left',
      sortComparator: customSortComparator,
      renderHeader: () => {
        return (
          <CustomTooltip
            title={<ProjectTooltips.TotalKeywords />}
            placement='top-start'
            arrow
          >
            <TableHeaderStyles>
              {'Total Keywords '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <CustomTooltip
            title={<TextTooltip text={params.row.totalKeywords} />}
            placement='top-start'
            arrow
            PopperProps={popperProps}
          >
            {params.row.totalKeywords}
          </CustomTooltip>
        );
      },
    },
    {
      field: 'improved',
      headerName: 'improved',
      sortable: true,
      maxWidth: 120,
      minWidth: 120,
      headerAlign: 'left',
      align: 'left',
      sortComparator: customSortComparator,
      renderHeader: () => {
        return (
          <CustomTooltip
            title={<ProjectTooltips.Improved />}
            placement='top-start'
            arrow
          >
            <TableHeaderStyles>
              {'Improved '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <CustomTooltip
            title={<TextTooltip text={params.row.improved} />}
            placement='top-start'
            arrow
            PopperProps={popperProps}
          >
            <span style={{ color: '#007C65' }}>{params.row.improved}</span>
          </CustomTooltip>
        );
      },
    },
    {
      field: 'declined',
      headerName: 'declined',
      sortable: true,
      maxWidth: 120,
      minWidth: 120,
      headerAlign: 'left',
      align: 'left',
      sortComparator: customSortComparator,
      renderHeader: () => {
        return (
          <CustomTooltip
            title={<ProjectTooltips.Declined />}
            placement='top-start'
            arrow
          >
            <TableHeaderStyles>
              {'Declined '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <CustomTooltip
            title={<TextTooltip text={params.row.declined} />}
            placement='top-start'
            arrow
            PopperProps={popperProps}
          >
            <span style={{ color: '#DD1100' }}>{params.row.declined}</span>
          </CustomTooltip>
        );
      },
    },
    {
      field: 'noChange',
      headerName: 'noChange',
      sortable: true,
      maxWidth: 130,
      minWidth: 130,
      headerAlign: 'left',
      align: 'left',
      sortComparator: customSortComparator,
      renderHeader: () => {
        return (
          <CustomTooltip
            title={<ProjectTooltips.NoChange />}
            placement='top-start'
            arrow
          >
            <TableHeaderStyles>
              {'No Change '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <CustomTooltip
            title={<TextTooltip text={params.row.noChange} />}
            placement='top-start'
            arrow
            PopperProps={popperProps}
          >
            {params.row.noChange}
          </CustomTooltip>
        );
      },
    },
    {
      field: 'dailyAverage',
      headerName: 'dailyAverage',
      sortable: false,
      maxWidth: 200,
      minWidth: 200,
      headerAlign: 'left',
      align: 'left',
      sortComparator: customSortComparator,
      renderHeader: () => {
        return (
          <CustomTooltip
            title={<ProjectTooltips.DailyAverage />}
            placement='top-start'
            arrow
          >
            <TableHeaderStyles>
              {'Daily Average '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return params.row.type === 'project' ? (
          <DailyAverageGraph dailyAverage={params.row.dailyAverage} />
        ) : null;
      },
    },
    {
      field: 'frequency',
      headerName: 'frequency',
      sortable: true,
      maxWidth: 130,
      minWidth: 130,
      headerAlign: 'left',
      align: 'left',
      sortComparator: customSortComparator,
      renderHeader: () => {
        return (
          <CustomTooltip
            title={<ProjectTooltips.Frequency />}
            placement='top-start'
            arrow
          >
            <TableHeaderStyles>
              {'Frequency '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <CustomTooltip
            title={<TextTooltip text={params.row.frequencyName} />}
            placement='top-start'
            arrow
            PopperProps={popperProps}
          >
            {params.row.frequencyName}
          </CustomTooltip>
        );
      },
    },
    {
      field: 'updatedAt',
      headerName: 'updatedAt',
      sortable: true,
      maxWidth: 135,
      minWidth: 135,
      headerAlign: 'left',
      align: 'left',
      sortComparator: customSortComparator,
      renderHeader: () => {
        return (
          <CustomTooltip
            title={<ProjectTooltips.Updated />}
            placement='top-start'
            arrow
          >
            <TableHeaderStyles>
              {'Updated '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        const date = params.row.updated
          ? showUpdatedFullFormat
            ? params.row.updatedFullFormat === '-'
              ? params.row.updatedFullFormat
              : convertToTimezone(
                  params.row.updatedFullFormat,
                  SERVER_UTS,
                  user.timezone.utc
                )
            : params.row.updated
          : '-';
        return (
          <DateCustomTooltip
            title={<TextTooltip text={date} />}
            placement='top-start'
            arrow
            PopperProps={popperProps}
            onClick={e => {
              e.stopPropagation();
              setShowUpdatedFullFormat(!showUpdatedFullFormat);
            }}
          >
            <div>{date}</div>
          </DateCustomTooltip>
        );
      },
    },
    {
      field: 'actions',
      headerName: '',
      cellClassName: 'actions',
      sortable: false,
      width: 50,
      align: 'center',
      renderCell: (params: GridRenderCellParams) =>
        currentAccount?.role?.name !== 'ViewOnly' && (
          <EllipsisMenu folder={params.row} />
        ),
    },
  ];

  const columnsSkeleton: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        sortable: true,
        headerName: 'Name',
        maxWidth: 300,
        minWidth: 100,
        width: tableWidth.name + 20,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'createdAt',
        headerName: 'Created',
        sortable: true,
        maxWidth: 300,
        minWidth: 130,
        width: tableWidth.createdAt,
        headerAlign: 'left',
        align: 'left',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'createdBy',
        headerName: 'Created by',
        sortable: false,
        maxWidth: 300,
        minWidth: 130,
        width: tableWidth.createdBy,
        headerAlign: 'left',
        align: 'left',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'lastModified',
        headerName: 'Last modified',
        sortable: true,
        maxWidth: 150,
        minWidth: 150,
        headerAlign: 'left',
        align: 'left',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'deviceType',
        headerName: 'type',
        sortable: true,
        width: tableWidth.deviceTypeName,
        headerAlign: 'left',
        align: 'left',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'url',
        headerName: 'URL',
        sortable: true,
        maxWidth: 300,
        minWidth: 100,
        width: tableWidth.url,
        headerAlign: 'left',
        align: 'left',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'totalKeywords',
        headerName: 'totalKeywords',
        sortable: true,
        minWidth: 160,
        maxWidth: 160,
        headerAlign: 'left',
        align: 'left',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'improved',
        headerName: 'improved',
        sortable: true,
        maxWidth: 120,
        minWidth: 120,
        headerAlign: 'left',
        align: 'left',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'declined',
        headerName: 'declined',
        sortable: true,
        maxWidth: 120,
        minWidth: 120,
        headerAlign: 'left',
        align: 'left',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'noChange',
        headerName: 'noChange',
        sortable: true,
        maxWidth: 130,
        minWidth: 130,
        headerAlign: 'left',
        align: 'left',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'dailyAverage',
        headerName: 'dailyAverage',
        sortable: false,
        maxWidth: 200,
        minWidth: 200,
        headerAlign: 'left',
        align: 'left',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'frequency',
        headerName: 'frequency',
        sortable: true,
        maxWidth: 130,
        minWidth: 130,
        headerAlign: 'left',
        align: 'left',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'updatedAt',
        headerName: 'updatedAt',
        sortable: true,
        maxWidth: 135,
        minWidth: 135,
        headerAlign: 'left',
        align: 'left',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 50,
        align: 'center',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
    ],
    [tableWidth]
  );

  return {
    columns,
    columnsSkeleton,
  };
}
