import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useState } from 'react';
import EllipsisMenu from '../../sharedLinks/components/sharedLinksPage/components/SharedLinksTable/components/EllipsisMenu/EllipsisMenu';
import {
  CustomTooltip,
  DateCustomTooltip,
} from '../../../shared/tooltip/styles';
import TextTooltip from '../../../shared/components/TextTooltip';
import * as Tooltips from '../../sharedLinks/components/sharedLinksPage/components/SharedLinksTable/components/Header/Tooltips';
import InfoIcon from '../../../shared/images/InfoIcon';
import { findLongestString, getColumnWidth } from '../../../utils/table';
import { customSortComparator } from '../../emailReports/hooks/useScheduledEmailReportsTable';
import { useAppSelector } from '../../../store';
import { Skeleton } from '@mui/material';
import { TableHeaderStyles } from '../../../shared/stylesTable';
import { convertToTimezone } from '../../../utils/date';
import { SERVER_UTS } from '../../../constants/config';

export default function useSharedLinksTable() {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const sharedLinksList = useAppSelector(
    state => state.sharedLinks.sharedLinksList
  );
  const user = useAppSelector(state => state.auth.user);

  const [showCreatedFullFormat, setShowCreatedFullFormat] =
    useState<boolean>(false);

  const [showLastViewedFullFormat, setShowLastViewedFullFormat] =
    useState<boolean>(false);

  const popperProps = {
    sx: {
      '& .MuiTooltip-tooltip': {
        backgroundColor: '#fff',
        color: '#344054',
        boxShadow:
          '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
        border: '1px solid #EDEDED',
      },
      '.MuiTooltip-arrow::before': {
        backgroundColor: '#fff',
        border: '1px solid #EDEDED',
      },
    },
  };

  const [linkWidth, setLinkWidth] = useState<number>(120);

  useEffect(() => {
    if (sharedLinksList?.items) {
      setLinkWidth(
        getColumnWidth(
          findLongestString(sharedLinksList?.items?.map(item => item.link)) ||
            ''
        )
      );
    }
  }, [sharedLinksList?.items]);

  const tableWidth = useMemo(
    () => ({
      link: sharedLinksList?.items
        ? getColumnWidth(
            findLongestString(sharedLinksList?.items?.map(item => item.link)) ||
              ''
          )
        : linkWidth,
    }),
    [sharedLinksList?.items, linkWidth]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'created',
        sortable: true,
        headerName: 'Created',
        maxWidth: 150,
        minWidth: 150,
        sortComparator: customSortComparator,
        renderCell: (params: GridRenderCellParams) => {
          const date = showCreatedFullFormat
            ? convertToTimezone(
                params.row.createdFullFormat,
                SERVER_UTS,
                user.timezone.utc
              )
            : params.row.created;
          return (
            <DateCustomTooltip
              title={<TextTooltip text={date} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
              onClick={e => {
                e.stopPropagation();
                setShowCreatedFullFormat(!showCreatedFullFormat);
              }}
            >
              {date}
            </DateCustomTooltip>
          );
        },
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Created />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Created '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
      },
      {
        field: 'type',
        sortable: false,
        headerName: 'Type',
        maxWidth: 70,
        minWidth: 70,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.type.name} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params.row.type.name}
            </CustomTooltip>
          );
        },
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Type />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Type '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
      },
      {
        field: 'lastViewed',
        sortable: true,
        headerName: 'Last viewed',
        maxWidth: 150,
        minWidth: 150,
        sortComparator: customSortComparator,
        renderCell: (params: GridRenderCellParams) => {
          const date = params.row.lastViewed
            ? showLastViewedFullFormat
              ? convertToTimezone(
                  params.row.lastViewedFullFormat,
                  SERVER_UTS,
                  user.timezone.utc
                )
              : params.row.lastViewed
            : '-';
          return (
            <DateCustomTooltip
              title={<TextTooltip text={date} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
              onClick={e => {
                e.stopPropagation();
                setShowLastViewedFullFormat(!showLastViewedFullFormat);
              }}
            >
              {date}
            </DateCustomTooltip>
          );
        },
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.LastViewed />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Last viewed '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
      },
      {
        field: 'link',
        sortable: true,
        headerName: 'Link',
        maxWidth: 300,
        minWidth: 120,
        width: tableWidth.link,
        sortComparator: customSortComparator,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.link} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params.row.link}
            </CustomTooltip>
          );
        },
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Link />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Link '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
      },
      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 50,
        align: 'center',
        renderCell: (params: GridRenderCellParams) =>
          currentAccount?.role?.name !== 'ViewOnly' && (
            <div>
              <EllipsisMenu linkId={params.row.id} link={params.row.link} />
            </div>
          ),
      },
    ],
    [
      currentAccount?.role?.name,
      tableWidth,
      showCreatedFullFormat,
      showLastViewedFullFormat,
      user?.timezone,
    ]
  );

  const columnsSkeleton: GridColDef[] = useMemo(
    () => [
      {
        field: 'created',
        sortable: true,
        headerName: 'Created',
        maxWidth: 150,
        minWidth: 150,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'type',
        sortable: false,
        headerName: 'Type',
        maxWidth: 70,
        minWidth: 70,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'lastViewed',
        sortable: true,
        headerName: 'Last viewed',
        maxWidth: 150,
        minWidth: 150,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'link',
        sortable: true,
        headerName: 'Link',
        maxWidth: 300,
        minWidth: 120,
        width: tableWidth.link,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 50,
        align: 'center',
        renderCell: () => <Skeleton width='100%' />,
      },
    ],
    [tableWidth]
  );

  return {
    columns,
    columnsSkeleton,
  };
}
