import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import {
  cancelAccountDeletionAPI,
  changeAccountBrandingAPI,
  changeAccountSettingsAPI,
  changeEmailAPI,
  checkAuthAPI,
  clearRecentlyViewed,
  confirmEmailAPI,
  deleteAccountAPI,
  deleteOneInvitationTeamAPI,
  deleteOneUserTeamAPI,
  editUserTeamAPI,
  emailChangeConfirmationAPI,
  forgotAPI,
  getAccountLimit,
  getAllCountriesAPI,
  getAllRolesAPI,
  getAllTimezonesAPI,
  getBilling,
  getCheckoutSettings,
  getInvitationTeamFullInfoAPI,
  getInvoicePDF,
  getInvoices,
  getMyAccountAPI,
  getSearchEngines,
  getTariffPlansAPI,
  getUsersAPI,
  getUserTeamFullInfoAPI,
  globalSearchAPI,
  loginAPI,
  necessaryRemoval,
  passwordConfirmationAPI,
  removeAccessAccountAPI,
  removeAccessesAccountsAPI,
  requestNewKeyAPI,
  resendingForgotPasswordLetterAPI,
  resendingMailConfirmationLetterAPI,
  resetAPI,
  sendInvitationAPI,
  setAsDefaultAccountAPI,
  signUpAPI,
  usersBulkDeleteTeamAPI,
  userSearches,
} from '../api';
import {
  CancelAccountDeletionParams,
  ChangeAccountBrandingParams,
  ChangeAccountSettingsThunkParams,
  ConfirmEmailParams,
  DeleteAccountParams,
  DeleteOneUserTeamParams,
  EditUserTeamParams,
  ForgetParams,
  GetUsersParams,
  ICountry,
  ITimezone,
  LoginParams,
  LoginResponse,
  MyAccount,
  PasswordConfirmationParams,
  ResendingForgotPasswordLetterThunkParams,
  ResetParams,
  Role,
  sendInvitationParams,
  SetAsDefaultAccountParams,
  UsersBulkDeleteParams,
  UserSignUpData,
  UsersState,
  RemoveAccessAccountParams,
  RemoveAccessAccountPayload,
  RemoveAccessesAccountsPayload,
  RemoveAccessesAccountsParams,
  GlobalSearchParams,
  GlobalSearchResponse,
  GetTariffPlansResponse,
  ChangeEmailParams,
  GetTInvoicesParams,
  IInvoicesState,
  GetInvoicePDFParams,
  GetBillingParams,
  GetBillingResponse,
  UserSearchesParams,
  ClearRecentlyViewedParams,
  DeleteInvitationsTeamParams,
  EmailChangeConfirmationParams,
  GetAccountLimitParams,
  GetAccountLimitResponse,
  NecessaryRemovalParams,
  NecessaryRemovalResponse,
  GetCheckoutSettingsResponse,
  IGetAddressGeolocationActionParams,
  GetSearchEnginesResponse,
  ITeamFullInfo,
  GetUserTeamFullInfoParams,
} from '../types';
import {
  saveAccessInLocalStorage,
  saveRefreshInLocalStorage,
  saveAccessInSessionStorage,
  saveRefreshInSessionStorage,
  clearStorage,
  STORAGE_KEYS,
  getSessionStorage,
  getRefreshWithLocalStorage,
  getRefreshWithSessionStorage,
} from '../../../utils/localStorage';
import { getAddressGeolocationAPI, IAddressGeolocation } from '../ipapi';
import { checkAndRedirectToMaintenancePage } from '../../../utils';

/**
 * Thunk to handle user sign-up.
 * @param {UserSignUpData} data - User data required for sign-up.
 * @returns {Promise<string>} A promise that resolves to a success message.
 */
export const signUpThunk = createAsyncThunk<
  string,
  UserSignUpData,
  { rejectValue: string }
>('sign_up', async (data, thunkAPI) => {
  try {
    const response = await signUpAPI(data);

    data.successFn();
    return response.data.alert;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        if (errors?.username) {
          data.errorFn({
            ...errors,
            fullname: errors.username,
            username: undefined,
          });
          return thunkAPI.rejectWithValue('Server error!');
        }
        if (errors?.email) {
          data.errorFn({
            ...errors,
            username: errors.email,
            email: undefined,
          });
          return thunkAPI.rejectWithValue('Server error!');
        }
        data.errorFn(errors);
      } else {
        data.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to handle user login.
 * @param {LoginParams} params - Parameters required for user login.
 * @returns {Promise<LoginResponse>} A promise that resolves to the user data after successful login.
 */
export const loginThunk = createAsyncThunk<
  LoginResponse,
  LoginParams,
  { rejectValue: string }
>('login', async ({ data, checked, successFn, errorFn }, thunkAPI) => {
  try {
    const response = await loginAPI(data.email, data.password);
    const { accessToken, refreshToken } = response.data;

    if (checked) {
      saveAccessInLocalStorage(accessToken);
      saveRefreshInLocalStorage(refreshToken);
    } else {
      saveAccessInSessionStorage(accessToken);
      saveRefreshInSessionStorage(refreshToken);
    }

    successFn();
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        if (errors?.email) {
          errorFn({
            ...errors,
            username: errors.email,
            email: undefined,
          });
        } else {
          errorFn(errors);
        }
      } else {
        errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to handle forgot password.
 * @param {ForgetParams} params - Parameters required for forgot password process.
 * @returns {Promise<string>} A promise that resolves to a status message.
 */
export const forgotPasswordThunk = createAsyncThunk<
  string,
  ForgetParams,
  { rejectValue: string }
>('forgot', async ({ email, successFn, errorFn }, thunkAPI) => {
  try {
    const response = await forgotAPI(email);
    successFn();

    return response.data.status;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        errorFn(errors);
      } else {
        errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to handle password reset.
 * @param {ResetParams} params - Parameters required for password reset.
 * @returns {Promise<string>} A promise that resolves to a status message.
 */
export const resetPasswordThunk = createAsyncThunk<
  string,
  ResetParams,
  { rejectValue: string }
>(
  'reset',
  async (
    { passwordResetConfirmationToken, password, successFn, errorFn },
    thunkAPI
  ) => {
    try {
      const response = await resetAPI(passwordResetConfirmationToken, password);

      successFn();
      return response.data.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        checkAndRedirectToMaintenancePage(error);
        if (error?.response?.status === 404) {
          successFn();
          return 'success';
        }

        const errors = error?.response?.data?.errors;
        if (typeof errors === 'object') {
          errorFn(errors);
        } else {
          errorFn({ message: error?.response?.data.message });
        }
      }
      return thunkAPI.rejectWithValue('Server error!');
    }
  }
);

/**
 * Thunk to check user authentication.
 * @param {string | undefined | null} token - Token to check authentication.
 * @returns {Promise<LoginResponse>} A promise that resolves to the user data.
 */
export const checkAuthThunk = createAsyncThunk<
  LoginResponse,
  string | undefined | null,
  { rejectValue: string }
>('check_auth', async (token, thunkAPI) => {
  try {
    const response = await checkAuthAPI(token);
    const { refreshToken, accessToken } = response.data;

    const refreshWithLocalStorage = getRefreshWithLocalStorage();
    const refreshWithSessionStorage = getRefreshWithSessionStorage();

    if (refreshWithLocalStorage && !refreshWithSessionStorage) {
      saveAccessInLocalStorage(accessToken);
      saveRefreshInLocalStorage(refreshToken);
    }
    if (!refreshWithLocalStorage && refreshWithSessionStorage) {
      saveAccessInSessionStorage(accessToken);
      saveRefreshInSessionStorage(refreshToken);
    }

    const currentAccount = getSessionStorage(STORAGE_KEYS.currentAccount);

    if (currentAccount) {
      const resultAccount = JSON.parse(currentAccount);
      response.data.availableAccounts = response.data.availableAccounts
        .concat(response.data.currentAccount)
        .filter(({ accountId }) => accountId !== resultAccount.accountId);
      response.data.currentAccount = resultAccount;
    }

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      if (error?.response?.status === 406) {
        clearStorage(STORAGE_KEYS.access);
        clearStorage(STORAGE_KEYS.refresh);
        clearStorage(STORAGE_KEYS.currentAccount);
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to confirm user email.
 * @param {ConfirmEmailParams} params - Parameters required for email confirmation.
 * @returns {Promise<string>} A promise that resolves to a detail message.
 */
export const confirmEmailThunk = createAsyncThunk<
  string,
  ConfirmEmailParams,
  { rejectValue: string }
>('confirm-email', async ({ emailConfirmationToken, errorFn }, thunkAPI) => {
  try {
    const response = await confirmEmailAPI(emailConfirmationToken);
    const { detail } = response.data;

    return detail;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    errorFn();
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to handle email change confirmation.
 * @param {EmailChangeConfirmationParams} params - Parameters required for email change confirmation.
 * @returns {Promise<undefined>} A promise that resolves when the email change is confirmed.
 */
export const emailChangeConfirmationThunk = createAsyncThunk<
  undefined,
  EmailChangeConfirmationParams,
  { rejectValue: string }
>(
  'emailChangeConfirmationThunk',
  async (
    { emailConfirmationToken, newEmail, errorFn, successFn },
    thunkAPI
  ) => {
    try {
      await emailChangeConfirmationAPI(emailConfirmationToken, newEmail);
      successFn();
    } catch (error) {
      if (error instanceof AxiosError) {
        checkAndRedirectToMaintenancePage(error);
      }
      errorFn();
      return thunkAPI.rejectWithValue('Server error!');
    }
  }
);

/**
 * Thunk to resend forgot password letter.
 * @param {ResendingForgotPasswordLetterThunkParams} params - Parameters required for resending forgot password letter.
 * @returns {Promise<string>} A promise that resolves to a status message.
 */
export const resendingForgotPasswordLetterThunk = createAsyncThunk<
  string,
  ResendingForgotPasswordLetterThunkParams,
  { rejectValue: string }
>(
  'resending_forgot_password_letter',
  async ({ email, errorFn, errorNavigate, successFn }, thunkAPI) => {
    try {
      const response = await resendingForgotPasswordLetterAPI(email);
      if (response.data.status) {
        errorFn(false);
      } else {
        errorNavigate();
      }

      if (successFn) {
        successFn(true);
      }

      return response.data.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        checkAndRedirectToMaintenancePage(error);
        if (error?.response?.data?.message === 'Please try again later') {
          errorFn(true);
        }
      }
      return thunkAPI.rejectWithValue('Server error!');
    }
  }
);

/**
 * Thunk to resend mail confirmation letter.
 * @param {ResendingForgotPasswordLetterThunkParams} params - Parameters required for resending mail confirmation letter.
 * @returns {Promise<string>} A promise that resolves to a status message.
 */
export const resendingMailConfirmationLetterThunk = createAsyncThunk<
  string,
  ResendingForgotPasswordLetterThunkParams,
  { rejectValue: string }
>(
  'resendingMailConfirmationLetter',
  async ({ email, errorFn, errorNavigate, successFn }, thunkAPI) => {
    try {
      const response = await resendingMailConfirmationLetterAPI(email);
      if (response.data.status) {
        errorFn(false);
      } else {
        errorNavigate();
      }

      if (successFn) {
        successFn(true);
      }

      return response.data.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        checkAndRedirectToMaintenancePage(error);
        if (error?.response?.data?.message === 'Please try again later') {
          errorFn(true);
        }
      }
      return thunkAPI.rejectWithValue('Server error!');
    }
  }
);

/**
 * Thunk to get user account details.
 * @param {number} id - User account ID.
 * @returns {Promise<MyAccount>} A promise that resolves to the user account data.
 */
export const getMyAccountThunk = createAsyncThunk<
  MyAccount,
  number,
  { rejectValue: string }
>('get_my_account', async (id, thunkAPI) => {
  try {
    const { data } = await getMyAccountAPI(id);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to get list of all countries.
 * @returns {Promise<ICountry[]>} A promise that resolves to the list of countries.
 */
export const getCountriesThunk = createAsyncThunk<
  ICountry[],
  undefined,
  { rejectValue: string }
>('get_countries', async (_, thunkAPI) => {
  try {
    const { data } = await getAllCountriesAPI();
    return data.items.sort((a, b) => a.name.localeCompare(b.name));
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to get list of all timezones.
 * @returns {Promise<ITimezone[]>} A promise that resolves to the list of timezones.
 */
export const getTimezonesThunk = createAsyncThunk<
  ITimezone[],
  undefined,
  { rejectValue: string }
>('get_timezones', async (_, thunkAPI) => {
  try {
    const { data } = await getAllTimezonesAPI();
    return data.items;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to handle account deletion.
 * @param {DeleteAccountParams} params - Parameters required for account deletion.
 * @returns {Promise<string>} A promise that resolves to the deletion timestamp.
 */
export const deleteAccountThunk = createAsyncThunk<
  string,
  DeleteAccountParams,
  { rejectValue: string }
>('delete-account', async (params, thunkAPI) => {
  try {
    const { data } = await deleteAccountAPI(params.reason, params.id);
    params.successFn();
    return data.deletedAt;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      if (
        error?.response?.status === 400 &&
        error?.response?.data?.message === 'cancel active subscription'
      ) {
        params.errorFn(error.response.data.message);
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to change account settings.
 * @param {ChangeAccountSettingsThunkParams} params - Parameters required to change account settings.
 * @returns {Promise<MyAccount>} A promise that resolves to the updated account data.
 */
export const changeAccountSettingsThunk = createAsyncThunk<
  MyAccount,
  ChangeAccountSettingsThunkParams,
  { rejectValue: string }
>('change-account-settings', async (params, thunkAPI) => {
  try {
    const { data } = await changeAccountSettingsAPI(params);
    params.successFn();
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        params.errorFn(errors);
      } else {
        params.errorFn({ accountInfoMessage: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to confirm user password.
 * @param {PasswordConfirmationParams} params - Parameters required for password confirmation.
 * @returns {Promise<undefined>} A promise that resolves when password is confirmed.
 */
export const passwordConfirmationThunk = createAsyncThunk<
  undefined,
  PasswordConfirmationParams,
  { rejectValue: string }
>('password-confirmation', async (params, thunkAPI) => {
  try {
    const { data } = await passwordConfirmationAPI(params);
    if (data.status) {
      params.successFn();
    } else {
      params.errorFn({ password: 'Invalid password!' });
    }
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        params.errorFn(errors);
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to change account branding.
 * @param {ChangeAccountBrandingParams} params - Parameters to change account branding.
 * @returns {Promise<MyAccount>} A promise that resolves to the updated account branding.
 */
export const changeAccountBrandingThunk = createAsyncThunk<
  MyAccount,
  ChangeAccountBrandingParams,
  { rejectValue: string }
>('change-account-branding', async (params, thunkAPI) => {
  try {
    const { data } = await changeAccountBrandingAPI(params);

    params.successFn();
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        params.errorFn(errors);
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to cancel account deletion.
 * @param {CancelAccountDeletionParams} params - Parameters to cancel account deletion.
 * @returns {Promise<void>} A promise that resolves when the account deletion is successfully canceled.
 */
export const cancelAccountDeletionThunk = createAsyncThunk<
  undefined,
  CancelAccountDeletionParams,
  { rejectValue: string }
>('cancel-account-deletion', async (params, thunkAPI) => {
  try {
    await cancelAccountDeletionAPI(params.id);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to get users.
 * @param {GetUsersParams} params - Parameters to get users.
 * @returns {Promise<UsersState>} A promise that resolves to the list of users.
 */
export const getUsersThunk = createAsyncThunk<
  UsersState,
  GetUsersParams,
  { rejectValue: string }
>('get-users', async (params, thunkAPI) => {
  try {
    const { data } = await getUsersAPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to get roles.
 * @returns {Promise<Role[]>} A promise that resolves to the list of roles.
 */
export const getRolesThunk = createAsyncThunk<
  Role[],
  undefined,
  { rejectValue: string }
>('get_roles', async (_, thunkAPI) => {
  try {
    const { data } = await getAllRolesAPI();
    return data.items;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to send an invitation.
 * @param {sendInvitationParams} params - Parameters to send an invitation.
 * @returns {Promise<void>} A promise that resolves when the invitation is successfully sent.
 */
export const sendInvitationThunk = createAsyncThunk<
  undefined,
  sendInvitationParams,
  { rejectValue: string }
>('send-invitation', async (params, thunkAPI) => {
  try {
    await sendInvitationAPI(params);

    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        params.errorFn(errors);
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to delete invitations for a team.
 * @param {DeleteInvitationsTeamParams} params - Parameters to delete invitations for a team.
 * @returns {Promise<void>} A promise that resolves when the invitations are successfully deleted.
 */
export const deleteInvitationsTeamThunk = createAsyncThunk<
  undefined,
  DeleteInvitationsTeamParams,
  { rejectValue: string }
>('delete-invitations-team-thunk', async (params, thunkAPI) => {
  try {
    await deleteOneInvitationTeamAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to edit a user team.
 * @param {EditUserTeamParams} params - Parameters to edit a user team.
 * @returns {Promise<void>} A promise that resolves when the user team is successfully edited.
 */
export const editUserTeamThunk = createAsyncThunk<
  undefined,
  EditUserTeamParams,
  { rejectValue: string }
>('edit-user-team', async (params, thunkAPI) => {
  try {
    await editUserTeamAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        params.errorFn(errors);
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to delete a user team.
 * @param {DeleteOneUserTeamParams} params - Parameters to delete a user team.
 * @returns {Promise<void>} A promise that resolves when the user team is successfully deleted.
 */
export const deleteOneUserTeamThunk = createAsyncThunk<
  undefined,
  DeleteOneUserTeamParams,
  { rejectValue: string }
>('delete-one-user-team', async (params, thunkAPI) => {
  try {
    await deleteOneUserTeamAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }

    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const usersBulkDeleteTeamThunk = createAsyncThunk<
  undefined,
  UsersBulkDeleteParams,
  { rejectValue: string }
>('users-bulk-delete-team', async (params, thunkAPI) => {
  try {
    await usersBulkDeleteTeamAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const setAsDefaultAccountThunk = createAsyncThunk<
  undefined,
  SetAsDefaultAccountParams,
  { rejectValue: string }
>('set-as-default', async (params, thunkAPI) => {
  try {
    await setAsDefaultAccountAPI(params.accountId);

    clearStorage(STORAGE_KEYS.currentAccount);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const removeAccessAccountThunk = createAsyncThunk<
  RemoveAccessAccountPayload,
  RemoveAccessAccountParams,
  { rejectValue: string }
>('remove-access', async (params, thunkAPI) => {
  try {
    await removeAccessAccountAPI(params.accountId);

    clearStorage(STORAGE_KEYS.currentAccount);
    params.successFn();

    return params;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const removeAccessesAccountsThunk = createAsyncThunk<
  RemoveAccessesAccountsPayload,
  RemoveAccessesAccountsParams,
  { rejectValue: string }
>('remove-accesses', async (params, thunkAPI) => {
  try {
    await removeAccessesAccountsAPI(params.data);

    clearStorage(STORAGE_KEYS.currentAccount);
    params.successFn();

    return params;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const globalSearchThunk = createAsyncThunk<
  GlobalSearchResponse,
  GlobalSearchParams,
  { rejectValue: string }
>('globalSearchThunk', async (params, thunkAPI) => {
  try {
    const { data } = await globalSearchAPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const requestNewKeyThunk = createAsyncThunk<
  undefined,
  { id: number; successFn: () => void },
  { rejectValue: string }
>('requestNewKeyThunk', async (params, thunkAPI) => {
  try {
    const { data } = await requestNewKeyAPI(params);
    params.successFn();
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getTariffPlansThunk = createAsyncThunk<
  GetTariffPlansResponse,
  { type: string }
>('getTariffPlansThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getTariffPlansAPI(params.type);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const changeEmailThunk = createAsyncThunk<
  undefined,
  ChangeEmailParams,
  { rejectValue: string }
>('changeEmailThunk', async (params, thunkAPI) => {
  try {
    await changeEmailAPI(params);

    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        params.errorFn(errors);
      } else {
        params.errorFn({ changeEmailMessage: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getInvoicesThunk = createAsyncThunk<
  IInvoicesState,
  GetTInvoicesParams
>('getInvoicesThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getInvoices(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getInvoicePDFThunk = createAsyncThunk<
  undefined,
  GetInvoicePDFParams
>('getInvoicePDFThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getInvoicePDF(params);
    params.successFn(data.url);
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getBillingThunk = createAsyncThunk<
  GetBillingResponse,
  GetBillingParams
>('getBillingThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getBilling(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const userSearchesThunk = createAsyncThunk<
  undefined,
  UserSearchesParams
>('userSearches', async (params, thunkAPI) => {
  try {
    await userSearches(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const clearRecentlyViewedThunk = createAsyncThunk<
  undefined,
  ClearRecentlyViewedParams
>('clearRecentlyViewedThunk', async (params, thunkAPI) => {
  try {
    await clearRecentlyViewed();
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getAccountLimitThunk = createAsyncThunk<
  GetAccountLimitResponse,
  GetAccountLimitParams
>('getAccountLimitThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getAccountLimit(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const necessaryRemovalThunk = createAsyncThunk<
  NecessaryRemovalResponse,
  NecessaryRemovalParams
>('necessaryRemovalThunk', async (params, thunkAPI) => {
  try {
    const { data } = await necessaryRemoval(params);
    params.successFn(data);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getCheckoutSettingsThunk = createAsyncThunk<
  GetCheckoutSettingsResponse,
  undefined
>('getCheckoutSettingsThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getCheckoutSettings();

    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getAddressGeolocationThunk = createAsyncThunk<
  IAddressGeolocation,
  IGetAddressGeolocationActionParams
>('getAddressGeolocationThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getAddressGeolocationAPI();
    params.successFn(data);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getSearchEnginesThunk = createAsyncThunk<GetSearchEnginesResponse>(
  'getSearchEnginesThunk',
  async (params, thunkAPI) => {
    try {
      const { data } = await getSearchEngines();

      return data;
    } catch (error) {
      if (error instanceof AxiosError) {
        checkAndRedirectToMaintenancePage(error);
      }
      return thunkAPI.rejectWithValue('Server error!');
    }
  }
);

export const getUserTeamFullInfoThunk = createAsyncThunk<
  ITeamFullInfo,
  GetUserTeamFullInfoParams
>('getUserTeamFullInfoThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getUserTeamFullInfoAPI(params);

    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getInvitationTeamFullInfoThunk = createAsyncThunk<
  ITeamFullInfo,
  GetUserTeamFullInfoParams
>('getInvitationTeamFullInfoThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getInvitationTeamFullInfoAPI(params);

    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});
