import React, { useState, useEffect } from 'react';

import { KeywordRankingsTable } from './components';
import { StyledKeywordRankings } from './styles';
import Pagination from './components/KeywordRankingsTable/components/Table/components/Pagination/Pagination';
import { GridEventListener, useGridApiRef } from '@mui/x-data-grid';
import { useProjects } from '../../../../../../hooks';

interface KeywordRankingsProps {
  updatedKeywordsTrigger: number[];
  onSetUpdatedKeywordsTrigger: (ids: number[]) => void;
}

const KeywordRankings: React.FC<KeywordRankingsProps> = ({
  updatedKeywordsTrigger,
  onSetUpdatedKeywordsTrigger,
}) => {
  const { onResetKeywordRankingsState } = useProjects();

  const apiRef = useGridApiRef();
  const containerRef = React.useRef<HTMLInputElement>(null);

  const [resizeFromPaginate, setResizeFromPaginate] = useState<boolean>(false);

  const handleResizeFromPaginate = () => {
    setResizeFromPaginate(true);
  };

  useEffect(() => {
    return () => {
      onResetKeywordRankingsState();
    };
  }, [onResetKeywordRankingsState]);

  useEffect(() => {
    const handleEvent: GridEventListener<
      'viewportInnerSizeChange'
    > = params => {
      if (params.height && resizeFromPaginate) {
        containerRef?.current?.scrollIntoView(false);
        scrollBy(window.screenX, 116);
        setResizeFromPaginate(() => false);
      }
    };

    // The `subscribeEvent` method will automatically unsubscribe in the cleanup function of the `useEffect`.
    return apiRef.current.subscribeEvent(
      'viewportInnerSizeChange',
      handleEvent
    );
  }, [apiRef, resizeFromPaginate]);

  return (
    <StyledKeywordRankings>
      <KeywordRankingsTable
        apiRef={apiRef}
        containerRef={containerRef}
        updatedKeywordsTrigger={updatedKeywordsTrigger}
        onSetUpdatedKeywordsTrigger={onSetUpdatedKeywordsTrigger}
      />
      <Pagination handleResizeFromPaginate={handleResizeFromPaginate} />
    </StyledKeywordRankings>
  );
};

export default React.memo(KeywordRankings);
