import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  bulkDeleteEmailReportsAPI,
  createEmailReportAPI,
  editEmailReportAPI,
  getEmailReportFrequencyAPI,
  getEmailReportFullAPI,
  getEmailReportsAPI,
  getReportDeliveryTimesAPI,
  getReportTypesAPI,
} from '../api';
import {
  BulkDeleteEmailReportsParams,
  CreateEmailReportParams,
  EditEmailReportParams,
  EmailReportFrequencyResponse,
  EmailReportsResponse,
  GetEmailReportFullParams,
  GetEmailReportsParams,
  IEmailReportFull,
} from '../types';
import { AxiosError } from 'axios';
import { checkAndRedirectToMaintenancePage } from '../../../utils';

/**
 * Thunk to get email reports.
 * @param {GetEmailReportsParams} params - Parameters for getting email reports.
 * @returns {Promise<EmailReportsResponse>} The email reports data.
 */
export const getEmailReportsThunk = createAsyncThunk<
  EmailReportsResponse,
  GetEmailReportsParams,
  { rejectValue: string }
>('getEmailReportsThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getEmailReportsAPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to get email report frequency options.
 * @returns {Promise<EmailReportFrequencyResponse>} The email report frequency options.
 */
export const getEmailReportFrequencyThunk = createAsyncThunk<
  EmailReportFrequencyResponse,
  undefined,
  { rejectValue: string }
>('getEmailReportFrequencyThunk', async (_, thunkAPI) => {
  try {
    const { data } = await getEmailReportFrequencyAPI();
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to get report delivery times.
 * @param {{ accountId: number }} params - Parameters containing the account ID.
 * @returns {Promise<EmailReportFrequencyResponse>} The report delivery times data.
 */
export const getReportDeliveryTimesThunk = createAsyncThunk<
  EmailReportFrequencyResponse,
  { accountId: number },
  { rejectValue: string }
>('getReportDeliveryTimesThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getReportDeliveryTimesAPI(params.accountId);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to get report types.
 * @returns {Promise<EmailReportFrequencyResponse>} The report types data.
 */
export const getReportTypesThunk = createAsyncThunk<
  EmailReportFrequencyResponse,
  undefined,
  { rejectValue: string }
>('getReportTypesThunk', async (_, thunkAPI) => {
  try {
    const { data } = await getReportTypesAPI();
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to create a new email report.
 * @param {CreateEmailReportParams} params - Parameters for creating an email report.
 * @returns {Promise<void>}
 */
export const createEmailReportThunk = createAsyncThunk<
  undefined,
  CreateEmailReportParams,
  { rejectValue: string }
>('createEmailReportThunk', async (params, thunkAPI) => {
  try {
    await createEmailReportAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        params.errorFn(errors);
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to bulk delete email reports.
 * @param {BulkDeleteEmailReportsParams} params - Parameters for bulk deleting email reports.
 * @returns {Promise<void>}
 */
export const bulkDeleteEmailReportsThunk = createAsyncThunk<
  undefined,
  BulkDeleteEmailReportsParams,
  { rejectValue: string }
>('bulkDeleteEmailReportsThunk', async (params, thunkAPI) => {
  try {
    await bulkDeleteEmailReportsAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to get full details of an email report.
 * @param {GetEmailReportFullParams} params - Parameters for getting full details of an email report.
 * @returns {Promise<IEmailReportFull>} The full email report data.
 */
export const getEmailReportFullThunk = createAsyncThunk<
  IEmailReportFull,
  GetEmailReportFullParams,
  { rejectValue: string }
>('getEmailReportFullThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getEmailReportFullAPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to edit an existing email report.
 * @param {EditEmailReportParams} params - Parameters for editing an email report.
 * @returns {Promise<void>}
 */
export const editEmailReportThunk = createAsyncThunk<
  undefined,
  EditEmailReportParams,
  { rejectValue: string }
>('editEmailReportThunk', async (params, thunkAPI) => {
  try {
    await editEmailReportAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        params.errorFn(errors);
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});
