import { useAppDispatch, useAppSelector } from '../../../store';
import { useCallback, useMemo } from 'react';
import {
  bulkDeleteNotesThunk,
  createNoteThunk,
  editNoteThunk,
  getAllNotesThunk,
  getProjectNotesThunk,
} from '../redux/thunk';
import {
  BulkDeleteNotesParams,
  CreateNoteParams,
  EditNoteParams,
  GetAllNotesParams,
  GetProjectNotesParams,
} from '../types';
import {
  setAllNotesPageSize,
  setAllNotesPaginationPage,
  setDeleteElementsAllNotesTable,
  setDeleteElementsProjectNotesTable,
  setProjectNotesPageSize,
  setProjectNotesPaginationPage,
  setRowSelectionModelAllNotes,
  setRowSelectionModelProjectNotes,
  setSearchAllNotes,
  setSearchProjectNotes,
  setSortModelAllNotes,
  setSortModelProjectNotes,
} from '../redux/actions';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';

export default function useNotes() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(state => state.notes);

  const memoizedState = useMemo(() => ({ ...state }), [state]);

  const onGetProjectNotes = useCallback(
    (param: GetProjectNotesParams) => {
      dispatch(getProjectNotesThunk(param));
    },
    [dispatch]
  );

  const onCreateNote = useCallback(
    (param: CreateNoteParams) => {
      dispatch(createNoteThunk(param));
    },
    [dispatch]
  );

  const onSetProjectNotesPageSize = useCallback(
    (pageSize: number) => {
      dispatch(setProjectNotesPageSize(pageSize));
    },
    [dispatch]
  );

  const onSetProjectNotesPaginationPage = useCallback(
    (page: number) => {
      dispatch(setProjectNotesPaginationPage(page));
    },
    [dispatch]
  );

  const onSetSortModelProjectNotes = useCallback(
    (sortModel: GridSortModel) => {
      dispatch(setSortModelProjectNotes(sortModel));
    },
    [dispatch]
  );

  const onSetRowSelectionModelProjectNotes = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => {
      dispatch(setRowSelectionModelProjectNotes(rowSelectionModel));
    },
    [dispatch]
  );

  const onEditNote = useCallback(
    (param: EditNoteParams) => {
      dispatch(editNoteThunk(param));
    },
    [dispatch]
  );

  const onSearchProjectNotes = useCallback(
    (value: string) => {
      dispatch(setSearchProjectNotes(value));
    },
    [dispatch]
  );

  const onBulkDeleteNotesNotes = useCallback(
    (params: BulkDeleteNotesParams) => {
      dispatch(bulkDeleteNotesThunk(params));
    },
    [dispatch]
  );

  const onGetAllNotes = useCallback(
    (param: GetAllNotesParams) => {
      dispatch(getAllNotesThunk(param));
    },
    [dispatch]
  );

  const onSetAllNotesPageSize = useCallback(
    (pageSize: number) => {
      dispatch(setAllNotesPageSize(pageSize));
    },
    [dispatch]
  );

  const onSetAllNotesPaginationPage = useCallback(
    (page: number) => {
      dispatch(setAllNotesPaginationPage(page));
    },
    [dispatch]
  );

  const onSetSortModelAllNotes = useCallback(
    (sortModel: GridSortModel) => {
      dispatch(setSortModelAllNotes(sortModel));
    },
    [dispatch]
  );

  const onSetRowSelectionModelAllNotes = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => {
      dispatch(setRowSelectionModelAllNotes(rowSelectionModel));
    },
    [dispatch]
  );

  const onSearchAllNotes = useCallback(
    (value: string) => {
      dispatch(setSearchAllNotes(value));
    },
    [dispatch]
  );

  const onSetDeleteElementsAllNotesTable = useCallback(
    (deleteElements: GridRowSelectionModel) => {
      dispatch(setDeleteElementsAllNotesTable(deleteElements));
    },
    [dispatch]
  );

  const onSetDeleteElementsProjectNotesTable = useCallback(
    (deleteElements: GridRowSelectionModel) => {
      dispatch(setDeleteElementsProjectNotesTable(deleteElements));
    },
    [dispatch]
  );

  return {
    ...memoizedState,
    onGetProjectNotes,
    onCreateNote,
    onSetProjectNotesPageSize,
    onSetProjectNotesPaginationPage,
    onSetSortModelProjectNotes,
    onSetRowSelectionModelProjectNotes,
    onEditNote,
    onSearchProjectNotes,
    onBulkDeleteNotesNotes,
    onGetAllNotes,
    onSetAllNotesPageSize,
    onSetAllNotesPaginationPage,
    onSetSortModelAllNotes,
    onSetRowSelectionModelAllNotes,
    onSearchAllNotes,
    onSetDeleteElementsAllNotesTable,
    onSetDeleteElementsProjectNotesTable,
  };
}
