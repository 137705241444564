import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useFolders } from '../../../../../../../../hooks';

import FeaturedIcon from '../../../../../../../../shared/images/FeaturedIcon';

import { GrayButtonSmall } from '../../../../../../../../shared/buttons/styles';
import { StyledCustomNoRowsOverlay, EmptyState, Content } from './styles';

const CustomNoRowsOverlay = () => {
  const {
    onSetSearchFolders,
    tables: {
      rootFolder: { search },
    },
  } = useFolders();

  const handleClose = () => {
    onSetSearchFolders('');
  };

  return (
    <StyledCustomNoRowsOverlay>
      <EmptyState>
        <Content>
          <FeaturedIcon />
          <Typography
            color='#101828'
            fontSize='16px'
            fontWeight='600'
            lineHeight='24px'
            paddingTop='16px'
            paddingBottom='4px'
          >
            No result found
          </Typography>
          <Typography
            color='#475467'
            fontSize='14px'
            fontWeight='400'
            lineHeight='20px'
            textAlign='center'
          >
            {`Your search “${search}” did not match any projects in the folder. Please try again`}
          </Typography>
        </Content>
        <Grid container xs={12} justifyContent='center' paddingTop='24px'>
          <Grid item xs={5.7}>
            <GrayButtonSmall
              fullWidth
              size='small'
              outline
              onClick={handleClose}
            >
              Clear Search
            </GrayButtonSmall>
          </Grid>
        </Grid>
      </EmptyState>
    </StyledCustomNoRowsOverlay>
  );
};

export default CustomNoRowsOverlay;
