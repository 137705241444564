import React, { useCallback } from 'react';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ROUTES from '../../../../../../../routes/constants';
import { useAuth, useFolders } from '../../../../../../../hooks';

import { BreadcrumbLink } from './styled';
import { useAppSelector } from '../../../../../../../store';

const Breadcrumbs = () => {
  const { currentFolder, isRootView, onSetDefaultCurrentFolder } = useFolders();

  const myAccount = useAppSelector(state => state.auth.myAccount);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const resetDefaultCurrentFolder = useCallback(() => {
    if (myAccount?.folderTree?.id) {
      const mainFolder = { id: myAccount.folderTree.id, name: 'My Folders' };
      onSetDefaultCurrentFolder(mainFolder);
    }
  }, [myAccount?.folderTree?.id, onSetDefaultCurrentFolder]);

  const extraBreadcrumbItems: JSX.Element[] = [
    <BreadcrumbLink key={currentFolder.name} to='#'>
      {currentFolder.name}
    </BreadcrumbLink>,
  ];

  const breadcrumbItemsIsMyAccount: JSX.Element[] = [
    <BreadcrumbLink
      key='Fodler'
      to={ROUTES.rootFolders}
      onClick={resetDefaultCurrentFolder}
    >
      Folders
    </BreadcrumbLink>,
  ].concat(
    extraBreadcrumbItems.filter(item => {
      if (!isRootView) return item;
    }) as JSX.Element[]
  );

  const breadcrumbItems: JSX.Element[] = extraBreadcrumbItems.filter(item => {
    if (!isRootView) return item;
  }) as JSX.Element[];

  return (
    <MuiBreadcrumbs
      separator={<NavigateNextIcon fontSize='small' />}
      aria-label='breadcrumb'
    >
      {currentAccount.isMyAccount
        ? breadcrumbItemsIsMyAccount
        : breadcrumbItems}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
