import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const ContentContainer = styled(Grid)`
  background-color: white;
  border: 1px solid #e4e4e4;
  box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.04),
    0px 3px 2px -1px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  padding: 24px 0;
`;

const BreadcrumbsPadding = styled(Grid)`
  padding: 0 24px;
`;

export { ContentContainer, BreadcrumbsPadding };
