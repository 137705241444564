import { styled } from '@mui/material/styles';

import {
  ListItemTypography as ProjectListItemTypography,
  MainContainer as ProjectMainContainer,
  StyledListItem as ProjectStyledListItem,
} from '../../../../../../../../../projects/components/Projects/components/TableProjects/components/Header/components/PopupTableSettings/styles';

const MainContainer = styled(ProjectMainContainer)`
  max-height: 479px;
`;

const ListItemTypography = styled(ProjectListItemTypography)``;

const StyledListItem = styled(ProjectStyledListItem)``;

export { MainContainer, ListItemTypography, StyledListItem };
