import Dash from '../../../../../../../../../../projects/components/Dash';
import React from 'react';
import DownRedIcon from '../../../../../../../../../../../shared/images/DownRedIcon';
import UpGreenIcon from '../../../../../../../../../../../shared/images/UpGreenIcon';
import { Grid } from '@mui/material';
import {
  LabelStyles,
  LineStyles,
  ValueStyles,
} from '../../../../../../../../../../../shared/components/PositionInfoTooltip/styles';
import { getKeywordRankingTableValue } from '../../../../../../../../../../projects/utils';
import { IAlertKeywords } from '../../../../../../../../../types/types';

/**
 * Returns the display value for a ranking position.
 * @param value - The ranking value.
 * @returns A React component displaying '> 100' if the value exceeds 100, otherwise the value as a string.
 */
const getValue = (value: number) =>
  value > 100 ? <Dash text={'> 100'} /> : value?.toString();

/**
 * Returns the tooltip text for a ranking position.
 * @param value - The ranking value.
 * @returns A string displaying '> 100' if the value exceeds 100, otherwise the value as a string.
 */
const getTextTooltip = (value: number) =>
  value > 100 ? '> 100' : value?.toString();

/**
 * Returns the appropriate icon based on the difference and whether the changes are positive or negative.
 * @param positiveChanges - A boolean indicating if the changes are positive.
 * @param difference - The numerical difference in ranking.
 * @returns A React component for the up or down icon, or null if no difference.
 */
const getDifferenceIcon = (positiveChanges: boolean, difference: number) => {
  if (!difference) return null;

  if (positiveChanges) {
    return <UpGreenIcon />;
  } else {
    return <DownRedIcon />;
  }
};

/**
 * A React component that displays a tooltip for the new rank, showing the previous and current positions along with the difference.
 * @param alertKeyword - The data object containing information about keyword alerts.
 * @returns A React component displaying the ranking information in a tooltip format.
 */
const NewRankTooltip: React.FC<IAlertKeywords> = alertKeyword => {
  if (!alertKeyword.difference) {
    return <Grid>No changes</Grid>;
  }

  return (
    <Grid>
      <LineStyles container justifyContent={'space-between'} gap={'8px'}>
        <LabelStyles item>{`Position on ${
          alertKeyword.dateOfPreviousPosition || ''
        }:`}</LabelStyles>
        <ValueStyles item>
          {getKeywordRankingTableValue(alertKeyword.previousPosition)}
        </ValueStyles>
      </LineStyles>
      <LineStyles container justifyContent={'space-between'} gap={'8px'}>
        <LabelStyles item>{`Position on ${
          alertKeyword.dateOfCurrentPosition || ''
        }:`}</LabelStyles>
        <ValueStyles item>
          {getKeywordRankingTableValue(alertKeyword.currentPosition)}
        </ValueStyles>
      </LineStyles>
      <LineStyles container justifyContent={'space-between'} gap={'8px'}>
        <LabelStyles item>Difference:</LabelStyles>
        <Grid item>
          <Grid container gap={'4px'} alignItems={'center'}>
            <Grid item display={'flex'} alignItems={'center'}>
              {alertKeyword.positiveChanges ? <UpGreenIcon /> : <DownRedIcon />}
            </Grid>

            <ValueStyles
              $color={
                alertKeyword.difference
                  ? alertKeyword.positiveChanges
                    ? '#007C65'
                    : '#D1002F'
                  : undefined
              }
              item
            >
              {!alertKeyword.difference || alertKeyword.difference > 100 ? (
                <Dash />
              ) : (
                getKeywordRankingTableValue(alertKeyword.difference)
              )}
            </ValueStyles>
          </Grid>
        </Grid>
      </LineStyles>
    </Grid>
  );
};

export { getValue, getTextTooltip, getDifferenceIcon, NewRankTooltip };
