import { AxiosResponse } from 'axios';

import api from '../../api';
import {
  FoldersListResponse,
  IFolder,
  FoldersResponse,
  GetFoldersByIdParams,
  GetRootFoldersParams,
  RootFoldersResponse,
  CreateFolderParams,
  RenameFolderParams,
  deletionOfFolderContentsParams,
} from './types';
import {
  GetListAvailableProjectsParams,
  GetListAvailableProjectsResponse,
} from '../projects/types';

/**
 * API call to get the list of available folders.
 * @param {{ accountId: number }} params - Parameters for fetching available folders.
 * @returns {Promise<AxiosResponse<FoldersListResponse>>} The response containing the list of available folders.
 */
export const getAvailableFoldersAPI = async (params: {
  accountId: number;
}): Promise<AxiosResponse<FoldersListResponse>> => {
  return api.get<FoldersListResponse>(
    `/accounts/${params.accountId}/folders/list-available-folders`
  );
};

/**
 * API call to get all folders for a specific account.
 * @param {{ accountId: number }} params - Parameters for fetching folders.
 * @returns {Promise<AxiosResponse<IFolder[]>>} The response containing the folders.
 */
export const getFoldersAPI = async (params: {
  accountId: number;
}): Promise<AxiosResponse<IFolder[]>> => {
  return api.get<IFolder[]>(`/accounts/${params.accountId}/folders`);
};

/**
 * API call to get root folders for a specific account.
 * @param {GetRootFoldersParams} params - Parameters for fetching root folders.
 * @returns {Promise<AxiosResponse<RootFoldersResponse>>} The response containing the root folders.
 */
export const getRootFoldersAPI = async (
  params: GetRootFoldersParams
): Promise<AxiosResponse<RootFoldersResponse>> => {
  return api.get<RootFoldersResponse>(
    `/accounts/${params.accountId}/folders/my-folders`,
    {
      params: {
        ...params.params,
        sortOrder: params.params.sortOrder
          ? params.params.sortOrder.toUpperCase()
          : null,
        page: params.params.page + 1,
        tagIds: params.params.tagIds ? params.params.tagIds : null,
      },
    }
  );
};

/**
 * API call to get folders by their ID.
 * @param {GetFoldersByIdParams} params - Parameters for fetching folders by ID.
 * @returns {Promise<AxiosResponse<FoldersResponse>>} The response containing the folders.
 */
export const getFoldersByIdAPI = async (
  params: GetFoldersByIdParams
): Promise<AxiosResponse<FoldersResponse>> => {
  return api.get<FoldersResponse>(
    `/accounts/${params.accountId}/folders/${params.folderId}`,
    {
      params: {
        search: params.params.search,
        page: params.params.page + 1,
        limit: params.params.limit,
        tagIds: params.params.tagIds ? params.params.tagIds : null,
        dailyAverage: params.params.dailyAverage,
        sortBy: params.params.sortBy,
        sortOrder: params.params.sortOrder
          ? params.params.sortOrder.toUpperCase()
          : null,
      },
    }
  );
};

/**
 * API call to create a new folder.
 * @param {CreateFolderParams} params - Parameters for creating a folder.
 * @returns {Promise<AxiosResponse<any>>} The response for creating the folder.
 */
export const createFolderAPI = async (params: CreateFolderParams) => {
  return api.post(`/accounts/${params.accountId}/folders/${params.folderId}`, {
    ...params.data,
  });
};

/**
 * API call to rename an existing folder.
 * @param {RenameFolderParams} params - Parameters for renaming a folder.
 * @returns {Promise<AxiosResponse<any>>} The response for renaming the folder.
 */
export const renameFolderAPI = async (params: RenameFolderParams) => {
  return api.patch(`/accounts/${params.accountId}/folders/${params.folderId}`, {
    ...params.data,
  });
};

/**
 * API call to delete the contents of a folder.
 * @param {deletionOfFolderContentsParams} params - Parameters for deleting folder contents.
 * @returns {Promise<AxiosResponse<any>>} The response for deleting the folder contents.
 */
export const deletionOfFolderContentsAPI = async (
  params: deletionOfFolderContentsParams
) => {
  return api.post(
    `/accounts/${params.accountId}/folders/${params.folderId}/deletion-of-folder-contents`,
    {
      ...params.data,
    }
  );
};

/**
 * API call to get the list of available projects.
 * @param {GetListAvailableProjectsParams} params - Parameters for fetching available projects.
 * @returns {Promise<AxiosResponse<GetListAvailableProjectsResponse>>} The response containing the list of available projects.
 */
export const getListAvailableProjectsAPI = async (
  params: GetListAvailableProjectsParams
): Promise<AxiosResponse<GetListAvailableProjectsResponse>> => {
  return api.get<GetListAvailableProjectsResponse>(
    `/accounts/${params.accountId}/projects/list-available-projects?search=${params.search}`
  );
};
