import {
  FormControl,
  Autocomplete,
  TextField,
  MenuItem,
  Box,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useProjects } from '../../../../hooks';
import { useLocation, useNavigate, useParams } from 'react-router';
import ROUTES from '../../../../routes/constants';
import { decryptData, encryptData } from '../../../../utils/cryptoJs';
import AutocompletePaper from './components/AutocompletePaper';
import { NewProjectModal } from '../../../../features/projects/components/Projects/components/ProjectRedactorModal';
import { useSelectProjectProvider } from './context/SelectProjectProvider';
import { SelectIcon } from '../../../components';
import { useAppSelector } from '../../../../store';
import GlobeIcon from '../../../images/GlobeIcon';

const SelectProject = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();

  const decryptId = useMemo(() => {
    if (pathname === `/projects/${id}` && id) return decryptData(id);
  }, [id, pathname]);

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const projectsListForInvite = useAppSelector(
    state => state.projects.projectsListForInvite
  );
  const openNewProject = useAppSelector(
    state => state.projects.modals.newProject
  );

  const { onGetProjectsListForInvite, onSetNewProjectModal } = useProjects();

  const { isOpenNewProjectModal, handleCloseNewProjectModal, limit, setLimit } =
    useSelectProjectProvider();

  const [isLastScrollData, setIsLastScrollData] = useState<boolean>(false);
  const [isLoadingProjects, setIsLoadingProjects] = useState<boolean>(false);

  const currentProjectPage = useMemo(
    () =>
      projectsListForInvite.items.find(item => item.id === Number(decryptId)),
    [projectsListForInvite, decryptId]
  );

  const [searchProject, setSearchProject] = useState<string>(
    currentProjectPage?.projectName || ''
  );

  const [inputLabel, setInputLabel] = useState<string>(
    currentProjectPage?.projectName || ''
  );
  const [autocompleteIsOpen, setAutocompleteIsOpen] = useState<boolean>(false);
  const [prevLabel, setPrevLabel] = useState<string>('');

  const noOptionsText = useMemo(
    () => (isLoadingProjects ? 'Loading projects...' : 'No projects'),
    [isLoadingProjects]
  );

  useEffect(() => {
    if (!decryptId) {
      setInputLabel('');
    } else if (currentProjectPage?.projectName && !autocompleteIsOpen)
      setInputLabel(currentProjectPage?.projectName);
  }, [currentProjectPage, autocompleteIsOpen, decryptId]);

  const handleChange = (value: number | string) => {
    navigate(ROUTES.dynamic.projectExpanded(encryptData(value.toString())));
  };

  const handleOpen = () => {
    setPrevLabel(inputLabel);
    setAutocompleteIsOpen(true);
    setInputLabel('');

    if (currentAccount?.accountId) {
      setIsLoadingProjects(true);
      onGetProjectsListForInvite({
        accountId: currentAccount.accountId,
        search: searchProject,
        successFn: () => {
          setIsLoadingProjects(false);
        },
      });
    }
  };

  const handleClose = () => {
    setInputLabel(prevLabel);
    setPrevLabel('');
    setAutocompleteIsOpen(false);

    setLimit(12);
    setIsLastScrollData(false);
    setSearchProject('');
  };

  const handleScroll = (event: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const listBoxNode = event.currentTarget;
    if (
      projectsListForInvite?.items &&
      listBoxNode.scrollTop + listBoxNode.clientHeight ===
        listBoxNode.scrollHeight
    ) {
      if (!isLastScrollData) {
        const nextPage = limit === 12 ? limit + 8 : limit + 10;
        setLimit(nextPage);
        if (nextPage >= projectsListForInvite?.items?.length) {
          setIsLastScrollData(true);
        }
      }
    }
  };

  const onHandleCloseNewProjectModal = () => {
    onSetNewProjectModal(false);
    handleCloseNewProjectModal();
  };

  useEffect(() => {
    if (currentAccount?.accountId) {
      setIsLoadingProjects(true);
      onGetProjectsListForInvite({
        accountId: currentAccount.accountId,
        search: searchProject,
        successFn: () => {
          setIsLoadingProjects(false);
        },
      });
    }
  }, [currentAccount?.accountId]);

  useEffect(() => {
    if (!openNewProject && isOpenNewProjectModal) {
      handleCloseNewProjectModal();
    }
  }, [openNewProject]);

  return (
    <FormControl>
      <Autocomplete
        sx={{ width: '280px' }}
        size='small'
        id='projectName'
        limitTags={2}
        disableClearable
        noOptionsText={noOptionsText}
        options={projectsListForInvite?.items.slice(0, limit)}
        getOptionLabel={option => option.projectName}
        renderOption={(props, option) => {
          return (
            <MenuItem {...props} key={option.id} value={option.id}>
              <Box gap={1} display='flex' alignItems='center'>
                {option?.favicon ? (
                  <img
                    src={option.favicon}
                    width={16}
                    height={16}
                    alt={'Company Logo URL'}
                  />
                ) : (
                  <GlobeIcon />
                )}
                {option.projectName}
              </Box>
            </MenuItem>
          );
        }}
        renderInput={params => (
          <TextField {...params} placeholder='Projects' name='projectName' />
        )}
        inputValue={searchProject || inputLabel || ''}
        onInputChange={(_, value, reason) => {
          if (reason === 'reset') {
            return;
          } else {
            setSearchProject(value);
          }
        }}
        onChange={(_, value) => {
          if (value) {
            setPrevLabel(value.projectName);
            setInputLabel(value.projectName);
            handleChange(value.id);
          }
        }}
        PaperComponent={AutocompletePaper}
        popupIcon={<SelectIcon />}
        onOpen={handleOpen}
        onClose={handleClose}
        ListboxProps={{
          onScroll: handleScroll,
        }}
      />
      {isOpenNewProjectModal && (
        <NewProjectModal
          handleClose={onHandleCloseNewProjectModal}
          open={openNewProject}
        />
      )}
    </FormControl>
  );
};

export default SelectProject;
