import { styled } from '@mui/system';

const HelperTextErrorWrapper = styled('div')`
  display: flex;
  align-items: center;

  .MuiFormHelperText-root {
    margin-top: 2px;
    font-size: 14px;
  }
`;

export { HelperTextErrorWrapper };
