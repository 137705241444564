import React from 'react';
import dayjs from 'dayjs';

import {
  AccountDeletionScheduledBannerContainer,
  AccountDeletionScheduledBannerLabelContainer,
} from './styles';
import { useAppSelector } from '../../store';

const AccountDeletionScheduledBanner = () => {
  const myAccount = useAppSelector(state => state.auth.myAccount);

  if (!myAccount?.deletedAt) {
    return null;
  }

  function getNumberOfDays(start: string, end: string) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    const oneDay = 1000 * 60 * 60 * 24;

    const diffInTime = date2.getTime() - date1.getTime();

    return Math.round(diffInTime / oneDay);
  }

  return (
    <AccountDeletionScheduledBannerContainer>
      <AccountDeletionScheduledBannerLabelContainer>
        Account deletion scheduled
      </AccountDeletionScheduledBannerLabelContainer>
      <span>
        This account has been scheduled for deletion in{' '}
        <b>
          {getNumberOfDays(
            myAccount.deletedAt,
            dayjs(myAccount.deletedAt).add(30, 'days').format()
          )}{' '}
          days.
        </b>{' '}
        To cancel the deletion, please go to Settings -{'>'} Account -{'>'}{' '}
        Cancel Deletion.
      </span>
      <b></b>
    </AccountDeletionScheduledBannerContainer>
  );
};

export default AccountDeletionScheduledBanner;
