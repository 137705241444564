import { Box, FormHelperText, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';

import SerpNestLogo from '../../../../shared/images/SerpNestLogo';
import VerifyEmailImage from '../../../../shared/images/VerifyEmail';
import { useAuth } from '../../../../hooks';
import { CustomFormHelperText } from '../../../../shared/components';
import ROUTES from '../../../../routes/constants';

import { LogoContainer, PaperContainer, Wrapper } from './styles';
import CustomHelperText from '../ForgetPasswordStep3/components/CustomHelperText';
import { useAppSelector } from '../../../../store';
import { getVerifyEmailLocalStorage } from '../../../../utils/localStorage';
import {
  onGetInfoHelpScout,
  onInit,
  onLogout,
  onPrefill,
  onSetConfig,
  onToggleHelpScout,
} from '../../../../utils/helpScout';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const { onResendingMailConfirmationLetterThunkEmail } = useAuth();

  const isLoading = useAppSelector(state => state.auth.isLoading);

  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const [email, setEmail] = useState<null | string>(null);

  useEffect(() => {
    const verifyEmailLocalStorage = getVerifyEmailLocalStorage();
    if (verifyEmailLocalStorage) {
      setEmail(verifyEmailLocalStorage);
    } else {
      navigate(ROUTES.main);
    }
  }, [navigate]);

  const onResendEmail = () => {
    setSuccess(false);
    if (!isLoading && email) {
      onResendingMailConfirmationLetterThunkEmail({
        email,
        errorFn: setError,
        errorNavigate: () => {
          navigate(ROUTES.forgotPasswordContactSupport);
        },
        successFn: setSuccess,
      });
    }
  };

  const onContactUs = () => {
    if (!onGetInfoHelpScout()) {
      onSetConfig();
      onInit();
      onLogout();
      onPrefill();
    }
    onToggleHelpScout();
  };

  return (
    <Wrapper>
      <Grid container>
        <Grid xs={1} lg={3} md={2} />
        <Grid xs={10} lg={6} md={8}>
          <Box>
            <LogoContainer>
              <SerpNestLogo />
            </LogoContainer>
            <PaperContainer>
              <VerifyEmailImage />
              <Typography variant='h4' fontWeight='700' sx={{ mt: 3, mb: 3 }}>
                Please verify your email
              </Typography>
              <Typography variant='body1'>
                You’re almost there! We sent an email to
              </Typography>
              <Typography variant='body1' fontWeight='700'>
                {email}
              </Typography>
              <Typography variant='body1' sx={{ mt: 3 }}>
                Just click on the link in that email to complete your signup.
              </Typography>
              <Typography variant='body1'>
                If you don’t see it, you may need to <b>check your spam</b>{' '}
                folder.
              </Typography>
              {success && (
                <FormHelperText id='success'>
                  <CustomHelperText text={'Email has been resent.'} />
                </FormHelperText>
              )}
              {error && (
                <FormHelperText error id='error'>
                  <CustomFormHelperText
                    error={
                      'We have already sent you an email. Please, try again later.'
                    }
                  />
                </FormHelperText>
              )}
              <Typography variant='body1' sx={{ mt: 3, mb: 3 }}>
                Didn’t receive your confirmation email?{' '}
                <Link to='#' onClick={onResendEmail}>
                  Resend it
                </Link>
              </Typography>
              <Typography variant='body1'>
                Need help?{' '}
                <Link to='#' onClick={onContactUs}>
                  Contact us
                </Link>
              </Typography>
            </PaperContainer>
          </Box>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default VerifyEmail;
