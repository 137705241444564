import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StyledHead = styled(Grid)`
  color: #101828;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`;

const StyledDescription = styled(Grid)`
  color: #475467;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export { StyledHead, StyledDescription };
