import React, { useMemo } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Outlet } from 'react-router-dom';

import Footer from './Footer/Footer';
import { RECAPTCHA_V3_SITE_KEY } from '../../constants/config';
import { useLocation } from 'react-router';

const BaseLayout = () => {
  const { pathname } = useLocation();

  const RenderFooter = useMemo(
    () => (pathname.split('/')[1] !== 'auth-google' ? <Footer /> : null),
    [pathname]
  );

  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_V3_SITE_KEY}>
      <Outlet />
      {RenderFooter}
    </GoogleReCaptchaProvider>
  );
};

export default BaseLayout;
