import React, { useMemo } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { StyledAccountContainer, Title } from '../AccountUsage/syles';
import { ListItem } from './styles';
import ROUTES from '../../../../routes/constants';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../store';

const AccountSummary = () => {
  const mobileScreen = useMediaQuery('(max-width:1010px)');

  const accountLimit = useAppSelector(state => state.auth.accountLimit);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const isViewOnly = useMemo(() => {
    return currentAccount?.role?.name == 'ViewOnly';
  }, [currentAccount?.role?.name]);

  const numberOfProjects = useMemo(
    () => accountLimit?.accountLimitsUsed?.numberOfProjects || 0,
    [accountLimit?.accountLimitsUsed?.numberOfProjects]
  );

  const numberOfUsersAndInvitations = useMemo(
    () =>
      accountLimit?.accountLimitsUsed?.numberOfUsers ||
      accountLimit?.accountLimitsUsed?.numberOfInvitations
        ? accountLimit?.accountLimitsUsed?.numberOfUsers ||
          0 + accountLimit?.accountLimitsUsed?.numberOfInvitations ||
          0
        : 0,
    [
      accountLimit?.accountLimitsUsed.numberOfUsers,
      accountLimit?.accountLimitsUsed?.numberOfInvitations,
    ]
  );
  const numberOfUsersWhoHaveAccessToTheAccount = useMemo(
    () =>
      accountLimit?.defaultAccountLimits
        ?.numberOfUsersWhoHaveAccessToTheAccount,
    [accountLimit?.defaultAccountLimits?.numberOfUsersWhoHaveAccessToTheAccount]
  );

  const numberOfTriggers = useMemo(
    () => accountLimit?.accountLimitsUsed?.numberOfTriggers || 0,
    [accountLimit?.accountLimitsUsed?.numberOfTriggers]
  );
  const numberOfTriggersAvailable = useMemo(
    () => accountLimit?.defaultAccountLimits?.numberOfTriggersAvailable || 0,
    [accountLimit?.defaultAccountLimits?.numberOfTriggersAvailable]
  );

  const numberOfEmailReports = useMemo(
    () => accountLimit?.accountLimitsUsed?.numberOfEmailReports || 0,
    [accountLimit?.accountLimitsUsed?.numberOfEmailReports]
  );
  const numberOfAvailableEmailReports = useMemo(
    () =>
      accountLimit?.defaultAccountLimits?.numberOfAvailableEmailReports || 0,
    [accountLimit?.defaultAccountLimits?.numberOfAvailableEmailReports]
  );

  const numberOfSharedLinks = useMemo(
    () => accountLimit?.accountLimitsUsed?.numberOfSharedLinks || 0,
    [accountLimit?.accountLimitsUsed?.numberOfSharedLinks]
  );
  const numberOfSharedLinksAvailable = useMemo(
    () => accountLimit?.defaultAccountLimits?.numberOfSharedLinksAvailable || 0,
    [accountLimit?.defaultAccountLimits?.numberOfSharedLinksAvailable]
  );

  const numberOfNotes = useMemo(
    () => accountLimit?.accountLimitsUsed?.numberOfNotes || 0,
    [accountLimit?.accountLimitsUsed?.numberOfNotes]
  );
  const numberOfAvailableNotes = useMemo(
    () => accountLimit?.defaultAccountLimits?.numberOfAvailableNotes || 0,
    [accountLimit?.defaultAccountLimits?.numberOfAvailableNotes]
  );

  const numberOfCompetitors = useMemo(
    () => accountLimit?.accountLimitsUsed?.numberOfCompetitors || 0,
    [accountLimit?.accountLimitsUsed?.numberOfCompetitors]
  );
  const availableNumberOfCompetitors = useMemo(
    () => accountLimit?.defaultAccountLimits?.availableNumberOfCompetitors || 0,
    [accountLimit?.defaultAccountLimits?.availableNumberOfCompetitors]
  );

  const numberOfAvailableTagsPerProject = useMemo(
    () =>
      accountLimit?.defaultAccountLimits?.numberOfAvailableTagsPerProject || 0,
    [accountLimit?.defaultAccountLimits?.numberOfAvailableTagsPerProject]
  );

  const numberOfAvailableTagsPerKeyword = useMemo(
    () =>
      accountLimit?.defaultAccountLimits?.numberOfAvailableTagsPerKeyword || 0,
    [accountLimit?.defaultAccountLimits?.numberOfAvailableTagsPerKeyword]
  );

  return (
    <Grid item xs={12} md={6} mt={{ xs: 2, md: 0 }}>
      <StyledAccountContainer container xs={12}>
        <Title item xs={12}>
          Account Summary
        </Title>
        <Grid
          xs={12}
          container
          item
          spacing={mobileScreen ? '16px' : '32px'}
          justifyContent={'space-between'}
        >
          <Grid xs={mobileScreen ? 12 : 6} item>
            <ListItem container item spacing={2}>
              <Grid item>Projects</Grid>
              <Grid item>
                <Link to={ROUTES.projects}>{numberOfProjects}</Link>
              </Grid>
            </ListItem>
            {!isViewOnly && (
              <ListItem container item spacing={2}>
                <Grid item>Email Reports</Grid>
                <Grid item>
                  <Link to={ROUTES.scheduledEmailReports}>
                    {`${numberOfEmailReports}/${numberOfAvailableEmailReports}`}
                  </Link>
                </Grid>
              </ListItem>
            )}
            {!isViewOnly && (
              <ListItem container item spacing={2}>
                <Grid item>Triggers</Grid>
                <Grid item>
                  <Link
                    to={ROUTES.alerts}
                  >{`${numberOfTriggers}/${numberOfTriggersAvailable}`}</Link>
                </Grid>
              </ListItem>
            )}
            {!isViewOnly && (
              <ListItem container item spacing={2}>
                <Grid item>Shared Links</Grid>
                <Grid item>
                  <Link to={ROUTES.sharedLinks}>
                    {`${numberOfSharedLinks}/${numberOfSharedLinksAvailable}`}
                  </Link>
                </Grid>
              </ListItem>
            )}
            {!isViewOnly && (
              <ListItem container item spacing={2}>
                <Grid item>Users</Grid>
                <Grid item>
                  <Link to={ROUTES.dynamic.settings('team')}>
                    {`${numberOfUsersAndInvitations}/${numberOfUsersWhoHaveAccessToTheAccount}`}
                  </Link>
                </Grid>
              </ListItem>
            )}
          </Grid>

          <Grid xs={mobileScreen ? 12 : 6} item>
            <ListItem container item spacing={2}>
              <Grid item>Notes</Grid>
              <Grid item>
                <Link
                  to={ROUTES.notes}
                >{`${numberOfNotes}/${numberOfAvailableNotes}`}</Link>
              </Grid>
            </ListItem>
            {!isViewOnly && (
              <ListItem container item spacing={2}>
                <Grid item>Competitors</Grid>
                <Grid item>
                  {`${numberOfCompetitors}/${availableNumberOfCompetitors}`}
                </Grid>
              </ListItem>
            )}
            {!isViewOnly && (
              <ListItem container item spacing={2}>
                <Grid item>Tags Limit Per Project</Grid>
                <Grid item>{numberOfAvailableTagsPerProject}</Grid>
              </ListItem>
            )}
            {!isViewOnly && (
              <ListItem container item spacing={2}>
                <Grid item>Tags Limit Per Keyword</Grid>
                <Grid item>{numberOfAvailableTagsPerKeyword}</Grid>
              </ListItem>
            )}
          </Grid>
        </Grid>
      </StyledAccountContainer>
    </Grid>
  );
};

export default AccountSummary;
