import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StyleNotificationDeviceTypeContainer = styled(Grid)`
  display: flex;

  border-radius: 12px;
  border: 1px solid #d1e9ff;
  background: #f5faff;
  padding: 16px;

  color: #126fe9;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export { StyleNotificationDeviceTypeContainer };
