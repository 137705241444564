import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StyledRightTools = styled(Grid)`
  ${props => props.theme.breakpoints.up('sm')} {
    justify-content: flex-end;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    padding-top: 16px;
  }

  .MuiGrid-item {
    padding-left: 8px;
  }
`;

export { StyledRightTools };
