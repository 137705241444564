import { Domains } from '../features/projects/types';

const LIST_DOMAINS: Domains[] = [
  {
    id: '1',
    name: '*.domain/*',
    value: 'domain',
  },
  {
    id: '2',
    name: 'Exact URL',
    value: 'url',
  },
];

const SEARCH_LOCATION_GOOGLE = {
  id: 229,
  locationName: 'United States',
};

const SEARCH_LOCATION_CHINA = {
  id: 43,
  locationName: 'China',
};

export { LIST_DOMAINS, SEARCH_LOCATION_GOOGLE, SEARCH_LOCATION_CHINA };
