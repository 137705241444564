import React, { useEffect } from 'react';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { Box, IconButton, List, ListItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemText from '@mui/material/ListItemText';
import { enqueueSnackbar, SnackbarKey } from 'notistack';

import EditPencilIcon from '../../../../../../../shared/images/EditPencilIcon';
import SmallTrashIcon from '../../../../../../../shared/images/SmallTrashIcom';
import EditUserModal from './EditUserModal';
import { UserTeam } from '../../../../../types';
import { useAuth } from '../../../../../../../hooks';
import { RenderCellUserActionsProps } from '../types';
import CustomToast from '../../../../../../../shared/CustomToast/CustomToast';
import { closeAndUndoSnackbarAction } from '../../../../../../../utils/snackbar';

import { Popover } from '../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import { StyledListItemButton } from '../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/styles';
import { useAppSelector } from '../../../../../../../store';

const RenderCellUserActions: React.FC<RenderCellUserActionsProps> = ({
  userEmail,
  registered,
  onRowSelectionModelChange,
}) => {
  const {
    onDeleteOneUserTeam,
    onDeleteInvitationsTeam,
    onGetUsers,
    onGetMyAccount,
    onSetDeleteElementsTeamsTable,
    onGetUserTeamFullInfo,
    onGetInvitationTeamFullInfo,
  } = useAuth();

  const sortModelTeam = useAppSelector(
    state => state.auth.sorting.sortModelTeam
  );
  const searchKeywordUsersTeam = useAppSelector(
    state => state.auth.search.searchKeywordUsersTeam
  );
  const users = useAppSelector(state => state.auth.users);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const [openEditUserModal, setEditInviteUser] = React.useState(false);
  const [user, setUser] = React.useState<UserTeam | null>(null);

  useEffect(() => {
    if (users?.items?.length) {
      setUser(users.items.find(item => item.email === userEmail) || null);
    }
  }, [users.items, userEmail]);

  const handleOpenEditUserModal = () => {
    if (user && currentAccount?.accountId) {
      if (user?.registered) {
        onGetUserTeamFullInfo({
          id: currentAccount.accountId,
          userId: user.id,
        });
      } else {
        onGetInvitationTeamFullInfo({
          id: currentAccount.accountId,
          userId: user.id,
        });
      }
      setEditInviteUser(true);
    }
  };
  const handleCloseEditUserModal = () => {
    onRowSelectionModelChange([]);
    setEditInviteUser(false);
  };

  const handleHardDelete = () => {
    if (user) {
      if (registered) {
        onDeleteOneUserTeam({
          accountId: currentAccount.accountId,
          userId: user.id,
          successFn: () => {
            onGetUsers({
              accountId: currentAccount.accountId,
              page: users.meta.currentPage,
              limit: users.meta.itemsPerPage,
              search: searchKeywordUsersTeam,
              sortBy: sortModelTeam[0]?.field,
              sortOrder: sortModelTeam[0]?.sort,
            });
            onGetMyAccount(currentAccount.accountId);
            onSetDeleteElementsTeamsTable([]);
          },
        });
      } else {
        onDeleteInvitationsTeam({
          accountId: currentAccount.accountId,
          ids: [user.id],
          successFn: () => {
            onGetUsers({
              accountId: currentAccount.accountId,
              page: users.meta.currentPage,
              limit: users.meta.itemsPerPage,
              search: searchKeywordUsersTeam,
              sortBy: sortModelTeam[0]?.field,
              sortOrder: sortModelTeam[0]?.sort,
            });
            onGetMyAccount(currentAccount.accountId);
            onSetDeleteElementsTeamsTable([]);
          },
        });
      }
    }
  };

  const handleDeleteOneUserOrInvitationTeam = (popupStateClose: () => void) => {
    if (user) {
      onSetDeleteElementsTeamsTable([user.id]);

      let undoDelete = false;
      popupStateClose();

      const onUndo = () => {
        undoDelete = true;
        onSetDeleteElementsTeamsTable([]);
      };

      enqueueSnackbar(
        <CustomToast
          message='User Removed'
          submessage={
            registered
              ? `User ${user?.name} has been successfully removed.`
              : `User ${user?.email} has been successfully removed.`
          }
        />,
        {
          action: (snackbarId: SnackbarKey) =>
            closeAndUndoSnackbarAction(snackbarId, onUndo),
          variant: 'error',
        }
      );

      setTimeout(() => {
        !undoDelete && handleHardDelete();
      }, 5000);
    }
  };

  return (
    <>
      <PopupState variant='popover' popupId='demo-popup-popover'>
        {popupState => (
          <div>
            <IconButton {...bindTrigger(popupState)}>
              <MoreVertIcon />
            </IconButton>

            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box sx={{ p: '0 8px', width: '241px' }}>
                {registered ? (
                  <List>
                    <ListItem disablePadding onClick={handleOpenEditUserModal}>
                      <StyledListItemButton>
                        <EditPencilIcon />
                        <ListItemText
                          sx={{ color: '#101828', paddingLeft: '8px' }}
                        >
                          Edit User
                        </ListItemText>
                      </StyledListItemButton>
                    </ListItem>
                    <ListItem
                      disablePadding
                      onClick={() => {
                        handleDeleteOneUserOrInvitationTeam(popupState.close);
                      }}
                    >
                      <StyledListItemButton>
                        <SmallTrashIcon />
                        <ListItemText
                          sx={{ color: '#B42318', paddingLeft: '8px' }}
                        >
                          Delete User
                        </ListItemText>
                      </StyledListItemButton>
                    </ListItem>
                  </List>
                ) : (
                  <List>
                    <ListItem
                      disablePadding
                      onClick={() => {
                        handleDeleteOneUserOrInvitationTeam(popupState.close);
                      }}
                    >
                      <StyledListItemButton>
                        <SmallTrashIcon />
                        <ListItemText
                          sx={{ color: '#B42318', paddingLeft: '8px' }}
                        >
                          Delete User
                        </ListItemText>
                      </StyledListItemButton>
                    </ListItem>
                  </List>
                )}
              </Box>
            </Popover>
            {openEditUserModal && (
              <EditUserModal
                handleClose={handleCloseEditUserModal}
                popupStateClose={popupState.close}
                open={openEditUserModal}
                registered={user?.registered}
              />
            )}
          </div>
        )}
      </PopupState>
    </>
  );
};

export default RenderCellUserActions;
