import { useSharedLinks } from '../../../../../../hooks';
import ROUTES from '../../../../../../routes/constants';
import { BreadcrumbLink } from '../../../../../../shared/Breadcrumbs/styled';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React from 'react';
import { getSharedLinkToken } from '../../../../../../utils/localStorage';
import { useAppSelector } from '../../../../../../store';

const Breadcrumbs = () => {
  const projectShared = useAppSelector(
    state => state.sharedLinks.projectShared
  );

  const breadcrumbItems: JSX.Element[] = projectShared
    ? [
        <BreadcrumbLink
          key='Projects'
          to={`${ROUTES.dynamic.sharedLinkContents(
            getSharedLinkToken() || undefined
          )}`}
        >
          Projects
        </BreadcrumbLink>,
        <BreadcrumbLink key='project' to='#'>
          {projectShared.projectName}
        </BreadcrumbLink>,
      ]
    : [];

  return (
    <MuiBreadcrumbs
      separator={<NavigateNextIcon fontSize='small' />}
      aria-label='breadcrumb'
    >
      {breadcrumbItems}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
