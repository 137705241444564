import { styled } from '@mui/material/styles';
import {
  Content as ProjectContent,
  Header as ProjectHeader
} from "../../../../../../../../../projects/components/Projects/components/TableProjects/components/Header/components/Tooltips/styles";

const Header = styled(ProjectHeader)``;

const Content = styled(ProjectContent)``;

export { Header, Content };
