import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Wrapper } from './styles';
import { useSharedLinks } from '../../../../hooks';
import { Paper } from '../../../../shared/paper/styles';
import { Table, Pagination, Header } from './components';
import {
  clearStorage,
  getSharedLinkToken,
  saveSharedLinkToken,
  STORAGE_KEYS,
} from '../../../../utils/localStorage';
import ReportValidated from '../../../../shared/ReportValidated/ReportValidated';
import Footer from '../../../../shared/layout/Footer/Footer';
import SharedCompanyInfo from '../../../../shared/SharedCompanyInfo/SharedCompanyInfo';
import { useAppSelector } from '../../../../store';
import { getUpdatedSharedLinkPasswordToServer } from '../../socket';

const SharedLinkContents = () => {
  const navigate = useNavigate();
  const { sharedLinkToken } = useParams();

  const {
    ongGetSharedLinkSettings,
    onResetSharedLinksState,
    onGetShared,
    onSetIsPasswordUpdated,
  } = useSharedLinks();

  const columnVisibilityModel = useAppSelector(
    state =>
      state.sharedLinks.tables.sharedLinkContentsList.columnVisibilityModel
  );
  const sortModel = useAppSelector(
    state => state.sharedLinks.tables.sharedLinkContentsList.sortModel
  );
  const search = useAppSelector(
    state => state.sharedLinks.tables.sharedLinkContentsList.search
  );
  const paginationModel = useAppSelector(
    state => state.sharedLinks.tables.sharedLinkContentsList.paginationModel
  );
  const isPasswordUpdated = useAppSelector(
    state => state.sharedLinks.isPasswordUpdated
  );

  useEffect(() => {
    if (sharedLinkToken) {
      const localStorageSharedLinkToken = getSharedLinkToken();

      if (sharedLinkToken !== localStorageSharedLinkToken) {
        clearStorage(STORAGE_KEYS.sharedLinkToken);
        clearStorage(STORAGE_KEYS.sharedLinkAccessToken);
        saveSharedLinkToken(sharedLinkToken);
        onResetSharedLinksState();
      }
    }
  }, [onResetSharedLinksState, sharedLinkToken]);

  useEffect(() => {
    if (sharedLinkToken) {
      onGetShared({
        link: sharedLinkToken,
        page: paginationModel.page,
        limit: paginationModel.pageSize,
        search: search,
        sortBy: sortModel[0]?.field,
        sortOrder: sortModel[0]?.sort,
        dailyAverage: columnVisibilityModel.dailyAverage,
      });
    }
  }, [
    sharedLinkToken,
    onGetShared,
    paginationModel.page,
    paginationModel.pageSize,
    search,
    sortModel,
    navigate,
    columnVisibilityModel.dailyAverage,
    isPasswordUpdated,
  ]);

  useEffect(() => {
    const sharedLinkToken = getSharedLinkToken();
    if (sharedLinkToken) {
      ongGetSharedLinkSettings({
        link: sharedLinkToken,
      });

      getUpdatedSharedLinkPasswordToServer(data => {
        if (sharedLinkToken === data.sharedLink) {
          clearStorage(STORAGE_KEYS.sharedLinkAccessToken);
          onSetIsPasswordUpdated(true);
        }
      });
    }
  }, []);

  return (
    <Wrapper>
      <Grid
        container
        xs={12}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'center'}
      >
        <ReportValidated />
        <SharedCompanyInfo />
        <Grid
          xs={11}
          sm={10}
          md={10}
          lg={10}
          overflow={'clip'}
          marginTop={'24px'}
        >
          <Paper>
            <Grid>
              <Typography
                padding={'24px'}
                fontWeight='600'
                color='#101828'
                fontSize='28px'
              >
                Shared With You
              </Typography>
            </Grid>
            <Header />
            <Table />
            <Pagination />
          </Paper>
        </Grid>
        <Footer />
      </Grid>
    </Wrapper>
  );
};

export default SharedLinkContents;
