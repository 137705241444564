/**
 * Renders the title for a list item based on the provided parameter.
 * @param param - The parameter to determine the list item title (e.g., 'name', 'position', 'd1').
 * @returns The corresponding title as a string, or an empty string if the parameter does not match any case.
 */
function renderListItemTitle(param: string) {
  switch (param) {
    case 'name':
      return 'Name';
    case 'position':
      return 'Position';
    case 'd1':
      return '1d';
    case 'd7':
      return '7d';
    case 'd30':
      return '30d';
    case 'best':
      return 'Best';
    case 'life':
      return 'Life';
    case 'first':
      return 'Start';
    case 'searchValue':
      return 'Volume';
    case 'cpc':
      return 'CPC';
    case 'url':
      return 'URL';
    case 'updated':
      return 'Updated';

    default:
      return '';
  }
}

export { renderListItemTitle };
