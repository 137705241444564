import React from 'react';
import { Grid } from '@mui/material';
import { StyleNotificationDeviceTypeContainer } from '../NotificationDeviceType/styles';
import FeaturedOutlineIcon from '../../../../shared/images/FeaturedOutlineIcon';

interface NotificationSelectSearchEngineProps {
  searchEngine: string;
}

const NotificationSelectSearchEngine: React.FC<
  NotificationSelectSearchEngineProps
> = ({ searchEngine }) => {
  return (
    <StyleNotificationDeviceTypeContainer marginTop={'8px'}>
      <Grid paddingRight={'16px'}>
        <FeaturedOutlineIcon />
      </Grid>
      <Grid>
        Selecting <b>{searchEngine}</b> will consume 5 keyword credits for each
        keyword.
      </Grid>
    </StyleNotificationDeviceTypeContainer>
  );
};

export default NotificationSelectSearchEngine;
