import React from 'react';
import { ListActionsProps } from './types';
import { Divider, List } from '@mui/material';
import EditAction from './EditAction';
import DeleteAction from './DeleteAction';
import { MainContainer } from '../../../../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/styles';

const ListActions: React.FC<ListActionsProps> = ({
  id,
  popupClose,
  selectBy,
}) => {
  return (
    <MainContainer>
      <List>
        <EditAction id={id} popupClose={popupClose} selectBy={selectBy} />

        <Divider
          orientation='horizontal'
          flexItem
          sx={{
            margin: '8px 0',
            color: '#EDEDED',
          }}
        />

        <DeleteAction id={id} popupClose={popupClose} selectBy={selectBy} />
      </List>
    </MainContainer>
  );
};

export default ListActions;
