import { styled } from '@mui/system';

const TableHeaderStyles = styled('p')`
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

export { TableHeaderStyles };
