import React from 'react';

import { useProjects } from '../../../../../../../../hooks';

import { StyledTablePagination, PaginationContainer } from './styles';
import { useAppSelector } from '../../../../../../../../store';

const Pagination = () => {
  const { onSetPaginationPageSize, onSetPaginationPage } = useProjects();

  const projects = useAppSelector(state => state.projects.projects);
  const paginationModel = useAppSelector(
    state => state.projects.tables.projects.paginationModel
  );

  const handleSetPaginationPageSize = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onSetPaginationPageSize(Number(e.target.value));
  };

  const handleSetPaginationPage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    onSetPaginationPage(Number(page));
  };

  return (
    <PaginationContainer xs={12}>
      <StyledTablePagination
        labelRowsPerPage='Show rows:'
        count={projects?.meta?.totalItems || 0}
        page={paginationModel.page}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onPageChange={handleSetPaginationPage}
        rowsPerPage={paginationModel.pageSize}
        onRowsPerPageChange={handleSetPaginationPageSize}
      />
    </PaginationContainer>
  );
};

export default Pagination;
