import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useLocation } from 'react-router-dom';
import { useProjects } from '../../../../../../hooks';
import ROUTES from '../../../../../../routes/constants';
import { BreadcrumbLink } from '../../../ProjectExpanded/components/Header/Breadcrumbs/styled';
import { useParams } from 'react-router';
import { useAppSelector } from '../../../../../../store';

const BreadcrumbsAdditional = () => {
  const location = useLocation();
  const { projectId } = useParams();

  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );

  const pathSnippets = location.pathname.split('/').filter(i => i);

  const breadcrumbNameMap: Record<string, string> = {
    [ROUTES.projects]: 'Projects',
    [ROUTES.dynamic.projectExpanded(
      `${projectId}`
    )]: `${projectExpanded?.projectName}`,
  };

  const extraBreadcrumbItems = pathSnippets.map((i, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return breadcrumbNameMap[url] ? (
      <BreadcrumbLink key={index} to={url}>
        {breadcrumbNameMap[url] !== 'undefined' ? breadcrumbNameMap[url] : ''}
      </BreadcrumbLink>
    ) : null;
  });

  return (
    <MuiBreadcrumbs
      separator={<NavigateNextIcon fontSize='small' />}
      aria-label='breadcrumb'
    >
      {
        extraBreadcrumbItems
          .slice(0, 2)
          .filter(item => item !== null) as JSX.Element[]
      }
    </MuiBreadcrumbs>
  );
};

export default BreadcrumbsAdditional;
