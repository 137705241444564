import React, { useMemo, useState } from 'react';
import { KeywordsActionProps } from './types';
import { closeAndUndoSnackbarAction } from '../../../../../../../../../../../utils/snackbar';
import { useParams } from 'react-router';
import { useAuth, useProjects } from '../../../../../../../../../../../hooks';
import { enqueueSnackbar, SnackbarKey } from 'notistack';

import SmallTrashIcon from '../../../../../../../../../../../shared/images/SmallTrashIcom';
import CustomToast from '../../../../../../../../../../../shared/CustomToast/CustomToast';

import { ListItemTypography, StyledListItem } from '../styles';
import { StyledListItemButton } from '../../../../../../../../Projects/components/TableProjects/components/EllipsisMenu/styles';
import { checkObjectProperties } from '../../../../../../../../../utils';
import { DEFAULT_KEYWORD_POSITIONS_MODEL } from '../../../../../../../../../constants';
import { decryptData } from '../../../../../../../../../../../utils/cryptoJs';
import DeleteKeywordModal from '../../../../../../../../modals/DeleteKeywordModal/DeleteKeywordModal';
import { useAppSelector } from '../../../../../../../../../../../store';

const DeleteKeywordsAction: React.FC<KeywordsActionProps> = ({
  popupClose,
}) => {
  const { id } = useParams();

  const decryptId = useMemo(() => {
    if (id) {
      return decryptData(id);
    }
  }, [id]);

  const { onGetMyAccount, onGetAccountLimit } = useAuth();
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const {
    onSetDeleteElementsKeywordRankingsTable,
    onDeleteKeywords,
    onGetKeywordRankings,
  } = useProjects();

  const deviceType = useAppSelector(
    state => state.projects.projectExpandedGraphs.deviceType
  );
  const search = useAppSelector(
    state => state.projects.projectExpandedGraphs.keywordRankings.table.search
  );
  const sortModel = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table.sortModel
  );
  const paginationModel = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table.paginationModel
  );
  const rowSelectionModel = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table
        .rowSelectionModel
  );
  const keywordPositionModel = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table
        .keywordPositionModel
  );

  const keywordSelectedTags = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table.selectedTags
  );

  const memoKeywordSelectedTags = useMemo(
    () => keywordSelectedTags.map(item => item.id),
    [keywordSelectedTags]
  );

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleHardDelete = () => {
    if (decryptId && deviceType) {
      onDeleteKeywords({
        accountId: currentAccount.accountId,
        projectId: Number(decryptId),
        data: {
          keywordIds: rowSelectionModel as number[],
        },
        successFn: () => {
          onGetAccountLimit({ id: currentAccount.accountId });
          onGetKeywordRankings({
            accountId: currentAccount.accountId,
            projectId: Number(decryptId),
            params: {
              page: paginationModel.page,
              limit: paginationModel.pageSize,
              search: search,
              sortBy: sortModel[0]?.field,
              sortOrder: sortModel[0]?.sort,
            },
            filters: checkObjectProperties(
              DEFAULT_KEYWORD_POSITIONS_MODEL,
              keywordPositionModel
            ),
            deviceType: deviceType,
            tagIds: memoKeywordSelectedTags,
          });
          onGetMyAccount(currentAccount.accountId);
          onSetDeleteElementsKeywordRankingsTable([]);
        },
      });
    }
  };

  const handleDeleteOneKeywords = () => {
    onSetDeleteElementsKeywordRankingsTable(rowSelectionModel);

    popupClose();
    let undoDelete = false;
    const onUndo = () => {
      undoDelete = true;
      onSetDeleteElementsKeywordRankingsTable([]);
    };
    enqueueSnackbar(
      <CustomToast
        message='Keywords Removed'
        submessage={`Keywords has been successfully removed.`}
      />,
      {
        action: (snackbarId: SnackbarKey) =>
          closeAndUndoSnackbarAction(snackbarId, onUndo),
        variant: 'error',
      }
    );
    setTimeout(() => {
      !undoDelete && handleHardDelete();
    }, 5000);
  };

  return (
    <>
      <StyledListItem>
        <StyledListItemButton onClick={handleOpenModal}>
          <SmallTrashIcon />
          <ListItemTypography marginLeft='8px' sx={{ color: '#B42318' }}>
            Delete Keywords
          </ListItemTypography>
        </StyledListItemButton>
      </StyledListItem>
      <DeleteKeywordModal
        isMultiple={true}
        onClose={handleCloseModal}
        onDelete={handleDeleteOneKeywords}
        open={openModal}
        count={rowSelectionModel.length}
      />
    </>
  );
};

export default DeleteKeywordsAction;
