import React, { useMemo } from 'react';
import { ResponsiveContainer } from 'recharts';
import './rechart.css';
import { ChartContainer } from './styled';
import { useProjects } from '../../../../../../../../hooks';
import GraphNoData from '../../../../../../../../shared/components/graphNoData/GraphNoData';
import { ImprovedVsDeclinedSkeleton } from '../../../../../../../../shared/graphSkeletons';
import KeywordTrendsBarChart from './KeywordTrendsBarChart';
import { useAppSelector } from '../../../../../../../../store';

const Graph = () => {
  const isLoadingProjectKeywordsTrends = useAppSelector(
    state => state.projects.projectExpandedGraphs.isLoadingProjectKeywordsTrends
  );
  const projectKeywordTrends = useAppSelector(
    state => state.projects.projectExpandedGraphs.projectKeywordTrends
  );

  const getCheckedData = useMemo(() => {
    return projectKeywordTrends?.items?.length
      ? projectKeywordTrends.items
      : [];
  }, [projectKeywordTrends?.items]);

  return (
    <ChartContainer>
      <ResponsiveContainer width={'100%'} height={311}>
        {isLoadingProjectKeywordsTrends || !projectKeywordTrends ? (
          <ImprovedVsDeclinedSkeleton />
        ) : getCheckedData.length ? (
          <KeywordTrendsBarChart getCheckedData={getCheckedData} />
        ) : (
          <GraphNoData />
        )}
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default Graph;
