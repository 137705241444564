import React, { useEffect, useMemo, useState } from 'react';
import { useEmailReports } from '../../../hooks';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  CustomTooltip,
  DateCustomTooltip,
} from '../../../shared/tooltip/styles';
import TextTooltip from '../../../shared/components/TextTooltip';
import EllipsisMenu from '../components/ScheduledEmailReports/components/EllipsisMenu/EllipsisMenu';
import * as Tooltips from '../components/ScheduledEmailReports/components/ScheduledEmailReportsTable/components/Header/Tooltips';
import InfoIcon from '../../../shared/images/InfoIcon';
import { findLongestString, getColumnWidth } from '../../../utils/table';
import { useAppSelector } from '../../../store';
import { Skeleton } from '@mui/material';
import { TableHeaderStyles } from '../../../shared/stylesTable';
import { capitalizeFirstLetter } from '../../../utils';

export const customSortComparator = (v1: string, v2: string) => {
  return 0;
};

export default function useScheduledEmailReportsTable() {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const { emailReports } = useEmailReports();

  const [showLastSentFullFormat, setShowLastSentFullFormat] =
    useState<boolean>(false);

  const [projectWidth, setProjectWidth] = useState<number>(130);
  const [recipientWidth, setRecipientWidth] = useState<number>(130);
  const [frequencyNameWidth, setFrequencyNameWidth] = useState<number>(130);
  const [lastSentWidth, setLastSentWidth] = useState<number>(150);
  const [nextDeliveryWidth, setNextDeliveryWidth] = useState<number>(150);

  useEffect(() => {
    if (emailReports?.items) {
      setProjectWidth(
        getColumnWidth(
          findLongestString(emailReports?.items?.map(item => item.project)) ||
            ''
        )
      );
      setRecipientWidth(
        getColumnWidth(
          findLongestString(emailReports?.items?.map(item => item.recipient)) ||
            ''
        )
      );
      setFrequencyNameWidth(
        getColumnWidth(
          findLongestString(
            emailReports?.items?.map(item => item.frequency.name)
          ) || ''
        )
      );
      setLastSentWidth(
        getColumnWidth(
          findLongestString(
            emailReports?.items?.map(item => item.lastSentFullFormat)
          ) || ''
        )
      );
      setNextDeliveryWidth(
        getColumnWidth(
          findLongestString(
            emailReports?.items?.map(item => item.nextDelivery)
          ) || ''
        )
      );
    }
  }, [emailReports?.items]);

  const tableWidth = useMemo(
    () => ({
      project: emailReports?.items
        ? getColumnWidth(
            findLongestString(emailReports?.items?.map(item => item.project)) ||
              ''
          )
        : projectWidth,
      recipient: emailReports?.items
        ? getColumnWidth(
            findLongestString(
              emailReports?.items?.map(item => item.recipient)
            ) || ''
          )
        : recipientWidth,
      frequency: emailReports?.items
        ? getColumnWidth(
            findLongestString(
              emailReports?.items?.map(item => item.frequency.name)
            ) || ''
          )
        : frequencyNameWidth,
      lastSent: emailReports?.items
        ? getColumnWidth(
            findLongestString(
              emailReports?.items?.map(item => item.lastSentFullFormat)
            ) || ''
          )
        : lastSentWidth,
      nextDelivery: emailReports?.items
        ? getColumnWidth(
            findLongestString(
              emailReports?.items?.map(item => item.nextDelivery)
            ) || ''
          )
        : nextDeliveryWidth,
    }),
    [
      emailReports?.items,
      projectWidth,
      recipientWidth,
      frequencyNameWidth,
      lastSentWidth,
      nextDeliveryWidth,
    ]
  );

  const popperProps = {
    sx: {
      '& .MuiTooltip-tooltip': {
        backgroundColor: '#fff',
        color: '#344054',
        boxShadow:
          '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
        border: '1px solid #EDEDED',
      },
      '.MuiTooltip-arrow::before': {
        backgroundColor: '#fff',
        border: '1px solid #EDEDED',
      },
    },
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'project',
        sortable: true,
        headerName: 'Project',
        maxWidth: 300,
        minWidth: 130,
        width: tableWidth.project,
        sortComparator: customSortComparator,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.project} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params.row.project}
            </CustomTooltip>
          );
        },
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Project />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Project '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
      },
      {
        field: 'recipient',
        sortable: true,
        headerName: 'Recipient',
        maxWidth: 300,
        minWidth: 130,
        width: tableWidth.recipient,
        sortComparator: customSortComparator,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.recipient} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params.row.recipient}
            </CustomTooltip>
          );
        },
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Recipient />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Recipient '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
      },
      {
        field: 'frequency',
        sortable: true,
        headerName: 'Frequency',
        maxWidth: 250,
        minWidth: 130,
        sortComparator: customSortComparator,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={
                <TextTooltip
                  text={capitalizeFirstLetter(params.row.frequency.name)}
                />
              }
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              <div>{capitalizeFirstLetter(params.row.frequency.name)}</div>
            </CustomTooltip>
          );
        },
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Frequency />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Frequency '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
      },
      {
        field: 'lastSent',
        sortable: true,
        sortComparator: customSortComparator,
        headerName: 'Last Sent',
        maxWidth: 150,
        minWidth: 150,
        width: tableWidth.lastSent,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <DateCustomTooltip
              title={
                <TextTooltip
                  text={
                    showLastSentFullFormat
                      ? params.row.lastSentFullFormat
                      : params.row.lastSent
                  }
                />
              }
              placement='top-start'
              arrow
              PopperProps={popperProps}
              onClick={e => {
                e.stopPropagation();
                setShowLastSentFullFormat(!showLastSentFullFormat);
              }}
            >
              {showLastSentFullFormat
                ? params.row.lastSentFullFormat
                : params.row.lastSent}
            </DateCustomTooltip>
          );
        },
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.LastSent />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Last Sent '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
      },
      {
        field: 'nextDelivery',
        sortable: true,
        sortComparator: customSortComparator,
        headerName: 'Next Delivery',
        maxWidth: 150,
        minWidth: 150,
        width: tableWidth.nextDelivery,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.nextDelivery} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params.row.nextDelivery}
            </CustomTooltip>
          );
        },
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.NextDelivery />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Next Delivery '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
      },
      {
        field: 'format',
        sortable: true,
        headerName: 'Format',
        maxWidth: 120,
        minWidth: 120,
        width: 120,
        sortComparator: customSortComparator,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={
                <TextTooltip text={params.row.format.name.toUpperCase()} />
              }
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params.row.format.name.toUpperCase()}
            </CustomTooltip>
          );
        },
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Format />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Format '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
      },
      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 50,
        align: 'center',
        renderCell: (params: GridRenderCellParams) =>
          currentAccount?.role?.name !== 'ViewOnly' && (
            <div>
              <EllipsisMenu id={params.row.id} />
            </div>
          ),
      },
    ],
    [currentAccount?.role?.name, tableWidth, showLastSentFullFormat]
  );

  const columnsSkeleton: GridColDef[] = useMemo(
    () => [
      {
        field: 'project',
        sortable: true,
        headerName: 'Project',
        maxWidth: 300,
        minWidth: 130,
        width: tableWidth.project,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'recipient',
        sortable: true,
        headerName: 'Recipient',
        maxWidth: 300,
        minWidth: 130,
        width: tableWidth.recipient,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'frequency',
        sortable: true,
        headerName: 'Frequency',
        maxWidth: 250,
        minWidth: 130,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'lastSent',
        sortable: true,
        headerName: 'Last Sent',
        maxWidth: 150,
        minWidth: 150,
        width: tableWidth.lastSent,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'nextDelivery',
        sortable: true,
        headerName: 'Next Delivery',
        maxWidth: 150,
        minWidth: 150,
        width: tableWidth.nextDelivery,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'format',
        sortable: true,
        headerName: 'Format',
        maxWidth: 120,
        minWidth: 120,
        width: 120,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 50,
        align: 'center',
        renderCell: () => <Skeleton width='100%' />,
      },
    ],
    [tableWidth]
  );

  return { columns, columnsSkeleton };
}
