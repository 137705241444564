import React, { useEffect, useMemo, useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { useAppSelector } from '../../../store';
import {
  RenderCellUserActions,
  RoleLabel,
} from '../components/Settings/components/Team/components';
import InfoIcon from '../../../shared/images/InfoIcon';
import * as Tooltips from '../../auth/components/Settings/components/Team/components/Tooltips';

import {
  CustomTooltip,
  DateCustomTooltip,
} from '../../../shared/tooltip/styles';
import TextTooltip from '../../../shared/components/TextTooltip';
import { findLongestString, getColumnWidth } from '../../../utils/table';
import { customSortComparator } from '../../emailReports/hooks/useScheduledEmailReportsTable';
import { Skeleton } from '@mui/material';
import { TableHeaderStyles } from '../../../shared/stylesTable';
import { convertToTimezone } from '../../../utils/date';
import { SERVER_UTS } from '../../../constants/config';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';

/**
 * Custom hook to manage the columns and column skeletons for the Teams table.
 * @param {function} onRowSelectionModelChange - Callback for when the row selection model changes.
 * @returns {{ columns: GridColDef[], columnsSkeleton: GridColDef[] }} The columns and their skeletons for the Teams table.
 */
export default function useTeams(
  onRowSelectionModelChange: (rowSelectionModel: GridRowSelectionModel) => void
) {
  const { user, currentAccount, users } = useAppSelector(state => state.auth);

  const [showDateAddedFullFormat, setShowDateAddedFullFormat] =
    useState<boolean>(false);
  const [showLastActivityFullFormat, setShowLastActivityFullFormat] =
    useState<boolean>(false);

  const popperProps = {
    sx: {
      '& .MuiTooltip-tooltip': {
        backgroundColor: '#fff',
        color: '#344054',
        boxShadow:
          '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
        border: '1px solid #EDEDED',
      },
      '.MuiTooltip-arrow::before': {
        backgroundColor: '#fff',
        border: '1px solid #EDEDED',
      },
    },
  };

  const [nameWidth, setNameWidth] = useState<number>(100);
  const [emailWidth, setEmailWidth] = useState<number>(100);

  useEffect(() => {
    if (users?.items) {
      setNameWidth(
        getColumnWidth(
          findLongestString(users?.items?.map(item => item.name)) || ''
        )
      );
      setEmailWidth(
        getColumnWidth(
          findLongestString(users?.items?.map(item => item.email)) || ''
        )
      );
    }
  }, [users?.items]);

  const tableWidth = useMemo(
    () => ({
      name: users?.items
        ? getColumnWidth(
            findLongestString(users?.items?.map(item => item.name)) || ''
          )
        : nameWidth,
      email: users?.items
        ? getColumnWidth(
            findLongestString(users?.items?.map(item => item.email)) || ''
          )
        : emailWidth,
    }),
    [users?.items, nameWidth, emailWidth]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        sortable: true,
        headerName: 'Name',
        maxWidth: 220,
        minWidth: 95,
        width: tableWidth.name,
        sortComparator: customSortComparator,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.name} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params.row.name}
            </CustomTooltip>
          );
        },
      },
      {
        field: 'email',
        headerName: 'Email',
        sortable: true,
        maxWidth: 300,
        minWidth: 100,
        width: tableWidth.email,
        sortComparator: customSortComparator,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.email} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params.row.email}
            </CustomTooltip>
          );
        },
      },
      {
        field: 'role',
        sortable: true,
        maxWidth: 100,
        minWidth: 100,
        sortComparator: customSortComparator,
        renderCell: (params: GridRenderCellParams) => {
          return <RoleLabel roleName={params.row.role} />;
        },
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.RoleForTeamTable />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Role '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
      },
      {
        field: 'dateAdded',
        sortable: true,
        maxWidth: 135,
        minWidth: 135,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.DateAddedForTeamTable />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Date Added '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          const date = showDateAddedFullFormat
            ? convertToTimezone(
                params.row.dateAddedFullFormat,
                SERVER_UTS,
                user.timezone.utc
              )
            : params.row.dateAdded;
          return (
            <DateCustomTooltip
              title={<TextTooltip text={date} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
              onClick={e => {
                e.stopPropagation();
                setShowDateAddedFullFormat(!showDateAddedFullFormat);
              }}
            >
              {date}
            </DateCustomTooltip>
          );
        },
      },
      {
        field: 'lastActivity',
        sortable: true,
        maxWidth: 140,
        minWidth: 140,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.LastActivityForTeamTable />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Last Activity '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          const date = showLastActivityFullFormat
            ? convertToTimezone(
                params.row.lastActivityFullFormat,
                SERVER_UTS,
                user.timezone.utc
              )
            : params.row.lastActivity;
          return (
            <DateCustomTooltip
              title={<TextTooltip text={date} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
              onClick={e => {
                e.stopPropagation();
                setShowLastActivityFullFormat(!showLastActivityFullFormat);
              }}
            >
              {date}
            </DateCustomTooltip>
          );
        },
      },
      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 50,
        align: 'center',
        renderCell: (params: GridRenderCellParams) => {
          if (currentAccount.role.name === 'ViewOnly') {
            return false;
          }
          if (params.row.id === user.id)
            return <div style={{ width: '40px', height: '40px' }} />;
          if (
            params.row.id !== user.id &&
            currentAccount.owner.id === params.row.id
          )
            return <div style={{ width: '40px', height: '40px' }} />;

          return (
            <RenderCellUserActions
              userEmail={params.row.email}
              registered={params.row.registered}
              onRowSelectionModelChange={onRowSelectionModelChange}
            />
          );
        },
      },
    ],
    [
      currentAccount,
      user.id,
      user?.timezone,
      tableWidth,
      showDateAddedFullFormat,
      showLastActivityFullFormat,
    ]
  );

  const columnsSkeleton: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        sortable: true,
        headerName: 'Name',
        maxWidth: 220,
        minWidth: 95,
        width: tableWidth.name,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'email',
        headerName: 'Email',
        sortable: true,
        maxWidth: 300,
        minWidth: 100,
        width: tableWidth.email,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'role',
        sortable: true,
        maxWidth: 100,
        minWidth: 100,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'dateAdded',
        sortable: true,
        maxWidth: 135,
        minWidth: 135,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'lastActivity',
        sortable: true,
        maxWidth: 140,
        minWidth: 140,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 50,
        align: 'center',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
    ],
    [tableWidth]
  );

  return {
    columns,
    columnsSkeleton,
  };
}
