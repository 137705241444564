import React from 'react';
import { List } from '@mui/material';
import { EllipsisActionsProps } from '../types';

import ShowOnGraphAction from './ShowOnGraphAction';

import { MainContainer } from '../styles';

const ListActions: React.FC<EllipsisActionsProps> = ({
  keywordId,
  keywordName,
  popupClose,
}) => {
  return (
    <MainContainer>
      <List>
        <ShowOnGraphAction
          keywordId={keywordId}
          keywordName={keywordName}
          popupClose={popupClose}
        />
      </List>
    </MainContainer>
  );
};

export default React.memo(ListActions);
