import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';

import {
  cancelAccountDeletionThunk,
  changeAccountBrandingThunk,
  changeAccountSettingsThunk,
  checkAuthThunk,
  confirmEmailThunk,
  deleteAccountThunk,
  deleteOneUserTeamThunk,
  editUserTeamThunk,
  emailChangeConfirmationThunk,
  getAccountLimitThunk,
  getBillingThunk,
  getCheckoutSettingsThunk,
  getCountriesThunk,
  getInvitationTeamFullInfoThunk,
  getInvoicesThunk,
  getMyAccountThunk,
  getRolesThunk,
  getSearchEnginesThunk,
  getTariffPlansThunk,
  getTimezonesThunk,
  getUsersThunk,
  getUserTeamFullInfoThunk,
  globalSearchThunk,
  loginThunk,
  passwordConfirmationThunk,
  removeAccessAccountThunk,
  removeAccessesAccountsThunk,
  resendingForgotPasswordLetterThunk,
  resendingMailConfirmationLetterThunk,
  sendInvitationThunk,
  setAsDefaultAccountThunk,
  signUpThunk,
  usersBulkDeleteTeamThunk,
} from './thunk';
import {
  GetAccountLimitResponse,
  GetBillingResponse,
  GlobalSearchResponse,
  IAvailableAccount,
  ICheckoutSettings,
  ICountry,
  IInvoicesState,
  ISearchEngine,
  ITariffPlan,
  ITeamFullInfo,
  ITimezone,
  MyAccount,
  NecessaryRemovalResponse,
  Role,
  SelectAccountParams,
  User,
  UsersState,
} from '../types';
import {
  checkCancelSubscriptionAction,
  closeCannotAddExcessKeywordsModal,
  closeCannotRefreshExcessKeywordsModal,
  closeCompetitorsLimitExceededModal,
  closeEmailReportsLimitExceededModal,
  closeKeywordsLimitExceededModal,
  closeKeywordUpdateScheduledModal,
  closeNotesLimitExceededModal,
  closeRefreshesLimitExceededModal,
  closeSharedLinksLimitExceededModal,
  closeTagsPerKeywordLimitExceededModal,
  closeTagsPerProjectLimitExceededModal,
  closeTriggersLimitExceededModal,
  closeUsersLimitExceededModal,
  closeYouveReachedYourRefreshLimitForToday,
  openCannotAddExcessKeywordsModal,
  openCannotRefreshExcessKeywordsModal,
  openCompetitorsLimitExceededModal,
  openEmailReportsLimitExceededModal,
  openKeywordsLimitExceededModal,
  openKeywordUpdateScheduledModal,
  openNotesLimitExceededModal,
  openRefreshesLimitLimitExceededModal,
  openSharedLinksLimitExceededModal,
  openTagsPerKeywordLimitExceededModal,
  openTagsPerProjectLimitExceededModal,
  openTriggersLimitExceededModal,
  openUsersLimitExceededModal,
  openYouveReachedYourRefreshLimitForToday,
  resetAuthState,
  resetPayment,
  seOpenSiteBar,
  setAccountBillingCard,
  setActionRequiredBeforeDowngrading,
  setActionRequiredCancelSubscriptionModal,
  setDeleteElementsTeamsTable,
  setFolderTreeAction,
  setFreeTrialModal,
  setInvoicesPageSize,
  setInvoicesPaginationPage,
  setNoticeAccountDeletionCancelledModal,
  setOpenTariffPlanBanner,
  setSearchKeywordUsersTeam,
  setSortModelInvoices,
  setSortModelTeam,
  setSubscriptionModal,
  updateCompetitorsBalanceCount,
  updateDailyKeywordsBalanceCount,
  updatedUserAction,
  updateEmailReportsBalanceCount,
  updateInvitationsBalanceCount,
  updateMonthlyKeywordsBalanceCount,
  updateNotesBalanceCount,
  updateProjectsBalanceCount,
  updateSharedLinksBalanceCount,
  updateTriggersBalanceCount,
  updateUsersBalanceCount,
} from './actions';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import { getPage } from '../../../utils';
import {
  initialAccountDeleteErrorModals,
  initialAccountLimitErrorModals,
  initialActionRequiredBeforeDowngrading,
  initialCannotAddExcessKeywords,
  initialCannotRefreshExcessKeywords,
  initialCompetitorsLimitExceeded,
  initialDailyRefreshesLimitExceeded,
  initialEmailReportsLimitExceeded,
  initialFreeTrialSubscriptionEndedModals,
  initialKeywordsLimitExceeded,
  initialKeywordUpdateScheduled,
  initialNotesLimitExceeded,
  initialSharedLinksLimitExceeded,
  initialTagsPerKeywordLimitExceeded,
  initialTagsPerProjectLimitExceeded,
  initialTriggersLimitExceeded,
  initialUsersLimitExceeded,
  initialYouveReachedYourRefreshLimitForToday,
} from './constants';
import { IAccountLimitErrorModals } from '../types/errors';
import { decryptData } from '../../../utils/cryptoJs';
import { getInvitationTeamFullInfoAPI, getUserTeamFullInfoAPI } from '../api';

const initialState = {
  openSiteBar: false,
  openTariffPlanBanner: false,
  isAuth: false,
  isLogin: false,
  isLoading: false,
  isVisitPreferredTariffPlan: false,
  isLoadingDeleteAccount: false,
  isLoadingChangeAccount: false,
  isLoadingChangeBranding: false,
  isLoadingUsers: true,
  isLoadingEmailChangeConfirmation: false,
  error: '' as undefined | string,
  globalSearch: null as GlobalSearchResponse | null,
  user: {} as User,
  users: {} as UsersState,
  search: {
    searchKeywordUsersTeam: '',
  },
  sorting: {
    sortModelTeam: [{ field: 'name', sort: 'desc' }] as GridSortModel,
  },
  teamsDeleteElements: [] as GridRowSelectionModel,
  currentAccount: {} as IAvailableAccount,
  myAccount: {} as MyAccount,
  countries: [] as ICountry[],
  timezones: [] as ITimezone[],
  searchEngines: [] as ISearchEngine[],
  roles: [] as Role[],
  availableAccounts: [] as IAvailableAccount[],
  tables: {
    invoice: {
      sortModel: [{ field: 'billingDate', sort: 'desc' }] as GridSortModel,
      paginationModel: {
        pageSize: 10,
        page: 0,
      },
    },
  },
  payment: {
    isLoadingBilling: false,
    tariffPlansList: [] as ITariffPlan[],
    invoicesState: null as IInvoicesState | null,
    billing: null as GetBillingResponse | null,
  },
  checkoutSettings: null as ICheckoutSettings | null,
  accountLimit: null as GetAccountLimitResponse | null,
  accountLimitErrors:
    initialAccountLimitErrorModals as IAccountLimitErrorModals,
  actionRequiredBeforeDowngrading:
    initialActionRequiredBeforeDowngrading as NecessaryRemovalResponse,
  freeTrialSubscriptionEndedModals: initialFreeTrialSubscriptionEndedModals,
  accountDeleteErrorModals: initialAccountDeleteErrorModals,
  activeUserTeam: {
    userTeam: null as ITeamFullInfo | null,
    invitationTeam: null as ITeamFullInfo | null,
  },
};

export type AuthState = typeof initialState;

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    visitPreferredTariffPlan(state) {
      state.isVisitPreferredTariffPlan = true;
    },
    setToken(state) {
      state.isAuth = true;
    },
    removeToken(state) {
      state.isAuth = false;
    },
    selectCurrentAccount(state, action: PayloadAction<SelectAccountParams>) {
      state.availableAccounts = state.availableAccounts
        .filter(
          ({ accountId }) => accountId !== action.payload.account.accountId
        )
        .concat(state.currentAccount);
      state.currentAccount = action.payload.account;
      state.myAccount = {} as MyAccount;
    },
  },
  extraReducers: builder => {
    builder.addCase(signUpThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(signUpThunk.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(signUpThunk.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    builder.addCase(loginThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(loginThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isAuth = true;
      state.isLogin = true;
      state.user = payload.user;
      state.currentAccount = payload.currentAccount;
      state.availableAccounts = payload.availableAccounts;
    });
    builder.addCase(loginThunk.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    builder.addCase(checkAuthThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(checkAuthThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isAuth = true;
      state.user = payload.user;
      state.currentAccount = payload.currentAccount;
      state.availableAccounts = payload.availableAccounts;
    });
    builder.addCase(checkAuthThunk.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    builder.addCase(confirmEmailThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(confirmEmailThunk.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(confirmEmailThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(resendingForgotPasswordLetterThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(resendingForgotPasswordLetterThunk.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(resendingForgotPasswordLetterThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(resendingMailConfirmationLetterThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(resendingMailConfirmationLetterThunk.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(resendingMailConfirmationLetterThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(getMyAccountThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getMyAccountThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.myAccount = payload;
    });
    builder.addCase(getMyAccountThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(getCountriesThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getCountriesThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.countries = payload;
    });
    builder.addCase(getCountriesThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(getTimezonesThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getTimezonesThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.timezones = payload;
    });
    builder.addCase(getTimezonesThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(deleteAccountThunk.pending, state => {
      state.isLoadingDeleteAccount = true;
    });
    builder.addCase(deleteAccountThunk.fulfilled, state => {
      state.isLoadingDeleteAccount = false;
    });
    builder.addCase(deleteAccountThunk.rejected, state => {
      state.isLoadingDeleteAccount = false;
    });

    builder.addCase(passwordConfirmationThunk.pending, state => {
      state.isLoadingDeleteAccount = true;
    });
    builder.addCase(passwordConfirmationThunk.fulfilled, state => {
      state.isLoadingDeleteAccount = false;
    });
    builder.addCase(passwordConfirmationThunk.rejected, state => {
      state.isLoadingDeleteAccount = false;
    });

    builder.addCase(changeAccountSettingsThunk.pending, state => {
      state.isLoadingChangeAccount = true;
    });
    builder.addCase(
      changeAccountSettingsThunk.fulfilled,
      (state, { payload }) => {
        state.isLoadingChangeAccount = false;
        state.myAccount = payload;
      }
    );
    builder.addCase(changeAccountSettingsThunk.rejected, state => {
      state.isLoadingChangeAccount = false;
    });

    builder.addCase(changeAccountBrandingThunk.pending, state => {
      state.isLoadingChangeBranding = true;
    });
    builder.addCase(
      changeAccountBrandingThunk.fulfilled,
      (state, { payload }) => {
        state.isLoadingChangeBranding = false;
        state.myAccount = { ...state.myAccount, ...payload };
      }
    );
    builder.addCase(changeAccountBrandingThunk.rejected, state => {
      state.isLoadingChangeBranding = false;
    });

    builder.addCase(cancelAccountDeletionThunk.pending, state => {
      state.isLoadingDeleteAccount = true;
    });
    builder.addCase(cancelAccountDeletionThunk.fulfilled, state => {
      state.isLoadingDeleteAccount = false;
    });
    builder.addCase(cancelAccountDeletionThunk.rejected, state => {
      state.isLoadingDeleteAccount = false;
    });

    builder.addCase(getUsersThunk.pending, state => {
      state.isLoadingUsers = true;
      state.users = {} as UsersState;
    });
    builder.addCase(getUsersThunk.fulfilled, (state, { payload }) => {
      state.isLoadingUsers = false;
      state.users = payload;
    });
    builder.addCase(getUsersThunk.rejected, state => {
      state.isLoadingUsers = false;
    });

    builder.addCase(getRolesThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getRolesThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.roles = payload;
    });
    builder.addCase(getRolesThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(sendInvitationThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(sendInvitationThunk.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(sendInvitationThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(editUserTeamThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(editUserTeamThunk.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(editUserTeamThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(setSearchKeywordUsersTeam, (state, { payload }) => {
      state.search.searchKeywordUsersTeam = payload;
    });

    builder.addCase(deleteOneUserTeamThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteOneUserTeamThunk.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteOneUserTeamThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(usersBulkDeleteTeamThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(usersBulkDeleteTeamThunk.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(usersBulkDeleteTeamThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(setSortModelTeam, (state, { payload }) => {
      state.sorting.sortModelTeam = payload;
    });

    builder.addCase(setAsDefaultAccountThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(setAsDefaultAccountThunk.fulfilled, state => {
      state.isLoading = false;
      state.currentAccount.byDefault = true;
    });
    builder.addCase(setAsDefaultAccountThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(removeAccessAccountThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(removeAccessAccountThunk.fulfilled, state => {
      state.isLoading = false;
      state.currentAccount = state.availableAccounts.find(
        ({ isMyAccount }) => isMyAccount
      ) as IAvailableAccount;
      state.availableAccounts = state.availableAccounts.filter(
        ({ isMyAccount }) => !isMyAccount
      );
    });
    builder.addCase(removeAccessAccountThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(removeAccessesAccountsThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(
      removeAccessesAccountsThunk.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.currentAccount =
          (state.availableAccounts.find(
            ({ isMyAccount }) => isMyAccount
          ) as IAvailableAccount) || state.currentAccount;
        state.availableAccounts = state.availableAccounts.filter(
          ({ accountId, isMyAccount }) =>
            !payload.data.accountIds.includes(accountId) && !isMyAccount
        );
      }
    );
    builder.addCase(removeAccessesAccountsThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(globalSearchThunk.fulfilled, (state, { payload }) => {
      state.globalSearch = payload;
    });

    builder.addCase(getTariffPlansThunk.fulfilled, (state, { payload }) => {
      state.payment.tariffPlansList = payload.items;
    });

    builder.addCase(resetAuthState, state => {
      return (state = initialState);
    });

    builder.addCase(getInvoicesThunk.pending, state => {
      state.payment.invoicesState = null;
    });
    builder.addCase(getInvoicesThunk.fulfilled, (state, { payload }) => {
      state.payment.invoicesState = payload;
      state.tables.invoice.paginationModel.page = getPage(
        state.tables.invoice.paginationModel.page,
        payload.meta.currentPage,
        payload.meta.totalPages
      );
    });

    builder.addCase(setInvoicesPaginationPage, (state, { payload }) => {
      state.tables.invoice.paginationModel.page = payload;
    });

    builder.addCase(setInvoicesPageSize, (state, { payload }) => {
      state.tables.invoice.paginationModel.pageSize = payload;
    });

    builder.addCase(setSortModelInvoices, (state, { payload }) => {
      state.tables.invoice.sortModel = payload;
    });

    builder.addCase(getBillingThunk.pending, state => {
      state.payment.isLoadingBilling = true;
    });
    builder.addCase(getBillingThunk.fulfilled, (state, { payload }) => {
      state.payment.billing = payload;
      state.payment.isLoadingBilling = false;
    });
    builder.addCase(getBillingThunk.rejected, state => {
      state.payment.isLoadingBilling = false;
    });
    builder.addCase(seOpenSiteBar, (state, { payload }) => {
      state.openSiteBar = payload;
    });

    builder.addCase(setDeleteElementsTeamsTable, (state, { payload }) => {
      state.teamsDeleteElements = payload.deleteElements;
    });

    builder.addCase(emailChangeConfirmationThunk.pending, state => {
      state.isLoadingEmailChangeConfirmation = true;
    });
    builder.addCase(emailChangeConfirmationThunk.fulfilled, state => {
      state.isLoadingEmailChangeConfirmation = false;
    });
    builder.addCase(emailChangeConfirmationThunk.rejected, state => {
      state.isLoadingEmailChangeConfirmation = false;
    });

    builder.addCase(getAccountLimitThunk.fulfilled, (state, { payload }) => {
      state.accountLimit = payload;
    });

    builder.addCase(updateEmailReportsBalanceCount, (state, { payload }) => {
      if (state?.accountLimit) {
        state.accountLimit.accountLimitsUsed.numberOfEmailReports = Number(
          payload.value.accountLimitUsed
        );

        state.accountLimit.balanceAccountLimits.currentNumberOfAvailableEmailReports =
          Number(payload.value.balanceAccountLimit);
      }
    });

    builder.addCase(updateTriggersBalanceCount, (state, { payload }) => {
      if (state?.accountLimit) {
        state.accountLimit.accountLimitsUsed.numberOfTriggers = Number(
          payload.value.accountLimitUsed
        );

        state.accountLimit.balanceAccountLimits.currentNumberOfTriggersAvailable =
          Number(payload.value.balanceAccountLimit);
      }
    });

    builder.addCase(updateUsersBalanceCount, (state, { payload }) => {
      if (state?.accountLimit) {
        state.accountLimit.accountLimitsUsed.numberOfUsers = Number(
          payload.value.accountLimitUsed
        );

        state.accountLimit.balanceAccountLimits.currentNumberOfUsersWhoHaveAccessToTheAccount =
          Number(payload.value.balanceAccountLimit);
      }
    });

    builder.addCase(updateInvitationsBalanceCount, (state, { payload }) => {
      if (state?.accountLimit) {
        state.accountLimit.accountLimitsUsed.numberOfInvitations = Number(
          payload.value.accountLimitUsed
        );

        state.accountLimit.balanceAccountLimits.currentNumberOfUsersWhoHaveAccessToTheAccount =
          Number(payload.value.balanceAccountLimit);
      }
    });

    builder.addCase(updateCompetitorsBalanceCount, (state, { payload }) => {
      if (state?.accountLimit) {
        state.accountLimit.accountLimitsUsed.numberOfCompetitors = Number(
          payload.value.accountLimitUsed
        );

        state.accountLimit.balanceAccountLimits.currentAvailableNumberOfCompetitors =
          Number(payload.value.balanceAccountLimit);
      }
    });

    builder.addCase(updateNotesBalanceCount, (state, { payload }) => {
      if (state?.accountLimit) {
        state.accountLimit.accountLimitsUsed.numberOfNotes = Number(
          payload.value.accountLimitUsed
        );

        state.accountLimit.balanceAccountLimits.currentNumberOfAvailableNotes =
          Number(payload.value.balanceAccountLimit);
      }
    });

    builder.addCase(updateDailyKeywordsBalanceCount, (state, { payload }) => {
      if (state?.accountLimit) {
        state.accountLimit.balanceAccountLimits.currentNumberOfDailyUpdatesOfKeywordPositions =
          Number(payload.value.balanceAccountLimit);
        state.accountLimit.accountLimitsUsed.numberOfKeywords = Number(
          payload.value.accountLimitUsed
        );
      }
    });

    builder.addCase(updateMonthlyKeywordsBalanceCount, (state, { payload }) => {
      if (state?.accountLimit) {
        state.accountLimit.balanceAccountLimits.currentNumberOfMonthlyUpdatesOfKeywordPositions =
          Number(payload.value.balanceAccountLimit);
        state.accountLimit.accountLimitsUsed.numberOfKeywords = Number(
          payload.value.accountLimitUsed
        );
      }
    });

    builder.addCase(updateSharedLinksBalanceCount, (state, { payload }) => {
      if (state?.accountLimit) {
        state.accountLimit.accountLimitsUsed.numberOfSharedLinks = Number(
          Number(payload.value.accountLimitUsed)
        );

        state.accountLimit.balanceAccountLimits.currentNumberOfSharedLinksAvailable =
          Number(payload.value.balanceAccountLimit);
      }
    });

    builder.addCase(updateProjectsBalanceCount, (state, { payload }) => {
      if (state?.accountLimit) {
        state.accountLimit.accountLimitsUsed.numberOfProjects = Number(
          payload.value.accountLimitUsed
        );
      }
    });

    builder.addCase(openKeywordsLimitExceededModal, (state, { payload }) => {
      state.accountLimitErrors.keywordsLimitExceeded = payload;
    });

    builder.addCase(closeKeywordsLimitExceededModal, state => {
      state.accountLimitErrors.keywordsLimitExceeded =
        initialKeywordsLimitExceeded;
    });

    builder.addCase(
      openRefreshesLimitLimitExceededModal,
      (state, { payload }) => {
        state.accountLimitErrors.dailyRefreshesLimitExceeded = payload;
      }
    );

    builder.addCase(closeRefreshesLimitExceededModal, state => {
      state.accountLimitErrors.dailyRefreshesLimitExceeded =
        initialDailyRefreshesLimitExceeded;
    });

    builder.addCase(
      openEmailReportsLimitExceededModal,
      (state, { payload }) => {
        state.accountLimitErrors.emailReportsLimitExceeded = payload;
      }
    );

    builder.addCase(closeEmailReportsLimitExceededModal, state => {
      state.accountLimitErrors.emailReportsLimitExceeded =
        initialEmailReportsLimitExceeded;
    });

    builder.addCase(openSharedLinksLimitExceededModal, (state, { payload }) => {
      state.accountLimitErrors.sharedLinksLimitExceeded = payload;
    });

    builder.addCase(closeSharedLinksLimitExceededModal, state => {
      state.accountLimitErrors.sharedLinksLimitExceeded =
        initialSharedLinksLimitExceeded;
    });

    builder.addCase(openUsersLimitExceededModal, (state, { payload }) => {
      state.accountLimitErrors.usersLimitExceeded = payload;
    });

    builder.addCase(closeUsersLimitExceededModal, state => {
      state.accountLimitErrors.usersLimitExceeded = initialUsersLimitExceeded;
    });

    builder.addCase(openNotesLimitExceededModal, (state, { payload }) => {
      state.accountLimitErrors.notesLimitExceeded = payload;
    });

    builder.addCase(closeNotesLimitExceededModal, state => {
      state.accountLimitErrors.notesLimitExceeded = initialNotesLimitExceeded;
    });

    builder.addCase(openTriggersLimitExceededModal, (state, { payload }) => {
      state.accountLimitErrors.triggersLimitExceeded = payload;
    });

    builder.addCase(closeTriggersLimitExceededModal, state => {
      state.accountLimitErrors.triggersLimitExceeded =
        initialTriggersLimitExceeded;
    });

    builder.addCase(openCompetitorsLimitExceededModal, (state, { payload }) => {
      state.accountLimitErrors.competitorsLimitExceeded = payload;
    });

    builder.addCase(closeCompetitorsLimitExceededModal, state => {
      state.accountLimitErrors.competitorsLimitExceeded =
        initialCompetitorsLimitExceeded;
    });

    builder.addCase(
      openTagsPerKeywordLimitExceededModal,
      (state, { payload }) => {
        state.accountLimitErrors.tagsPerKeywordLimitExceeded = payload;
      }
    );

    builder.addCase(closeTagsPerKeywordLimitExceededModal, state => {
      state.accountLimitErrors.tagsPerKeywordLimitExceeded =
        initialTagsPerKeywordLimitExceeded;
    });

    builder.addCase(
      openTagsPerProjectLimitExceededModal,
      (state, { payload }) => {
        state.accountLimitErrors.tagsPerProjectLimitExceeded = payload;
      }
    );

    builder.addCase(closeTagsPerProjectLimitExceededModal, state => {
      state.accountLimitErrors.tagsPerProjectLimitExceeded =
        initialTagsPerProjectLimitExceeded;
    });

    builder.addCase(setOpenTariffPlanBanner, (state, { payload }) => {
      state.openTariffPlanBanner = payload;
    });

    builder.addCase(resetPayment, state => {
      state.payment.billing = null;
      state.payment.invoicesState = null;
    });

    builder.addCase(
      setActionRequiredBeforeDowngrading,
      (state, { payload }) => {
        state.actionRequiredBeforeDowngrading = payload;
      }
    );

    builder.addCase(setAccountBillingCard, (state, { payload }) => {
      if (state.payment.billing) {
        state.payment.billing.card = payload
          ? {
              ...state.payment.billing.card,
              ...payload,
            }
          : null;
      }
    });

    builder.addCase(
      getCheckoutSettingsThunk.fulfilled,
      (state, { payload }) => {
        const data = decryptData(payload.data);
        state.checkoutSettings = JSON.parse(data);
      }
    );

    builder.addCase(openCannotAddExcessKeywordsModal, (state, { payload }) => {
      state.accountLimitErrors.cannotAddExcessKeywords = payload;
    });

    builder.addCase(closeCannotAddExcessKeywordsModal, state => {
      state.accountLimitErrors.cannotAddExcessKeywords =
        initialCannotAddExcessKeywords;
    });

    builder.addCase(openKeywordUpdateScheduledModal, (state, { payload }) => {
      state.accountLimitErrors.keywordUpdateScheduled = payload;
    });

    builder.addCase(closeKeywordUpdateScheduledModal, state => {
      state.accountLimitErrors.keywordUpdateScheduled =
        initialKeywordUpdateScheduled;
    });

    builder.addCase(
      openCannotRefreshExcessKeywordsModal,
      (state, { payload }) => {
        state.accountLimitErrors.cannotRefreshExcessKeywords = payload;
      }
    );

    builder.addCase(closeCannotRefreshExcessKeywordsModal, state => {
      state.accountLimitErrors.cannotRefreshExcessKeywords =
        initialCannotRefreshExcessKeywords;
    });

    builder.addCase(
      openYouveReachedYourRefreshLimitForToday,
      (state, { payload }) => {
        state.accountLimitErrors.youveReachedYourRefreshLimitForToday = payload;
      }
    );

    builder.addCase(closeYouveReachedYourRefreshLimitForToday, state => {
      state.accountLimitErrors.youveReachedYourRefreshLimitForToday =
        initialYouveReachedYourRefreshLimitForToday;
    });

    builder.addCase(updatedUserAction, (state, { payload }) => {
      state.user = payload;
    });

    builder.addCase(setFreeTrialModal, (state, { payload }) => {
      state.freeTrialSubscriptionEndedModals.freeTrial = payload;
    });
    builder.addCase(setSubscriptionModal, (state, { payload }) => {
      state.freeTrialSubscriptionEndedModals.subscription = payload;
    });

    builder.addCase(
      setActionRequiredCancelSubscriptionModal,
      (state, { payload }) => {
        state.accountDeleteErrorModals.actionRequiredCancelSubscription.open =
          payload;
      }
    );
    builder.addCase(
      setNoticeAccountDeletionCancelledModal,
      (state, { payload }) => {
        state.accountDeleteErrorModals.noticeAccountDeletionCancelled.open =
          payload;
      }
    );

    builder.addCase(checkCancelSubscriptionAction, (state, { payload }) => {
      state.payment.billing = payload;
    });

    builder.addCase(getSearchEnginesThunk.fulfilled, (state, { payload }) => {
      state.searchEngines = payload.items;
    });

    builder.addCase(getUserTeamFullInfoThunk.pending, state => {
      state.activeUserTeam.userTeam = null;
    });
    builder.addCase(
      getUserTeamFullInfoThunk.fulfilled,
      (state, { payload }) => {
        state.activeUserTeam.userTeam = payload;
      }
    );

    builder.addCase(getInvitationTeamFullInfoThunk.pending, state => {
      state.activeUserTeam.invitationTeam = null;
    });
    builder.addCase(
      getInvitationTeamFullInfoThunk.fulfilled,
      (state, { payload }) => {
        state.activeUserTeam.invitationTeam = payload;
      }
    );

    builder.addCase(setFolderTreeAction, (state, { payload }) => {
      state.myAccount.folderTree = payload;
    });
  },
});

export const {
  visitPreferredTariffPlan,
  setToken,
  removeToken,
  selectCurrentAccount,
} = authSlice.actions;

export default authSlice.reducer;
