import React from 'react';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  ModalContainer,
  ModalWrapper,
} from '../../Projects/components/ProjectRedactorModal/styles';
import { DeleteProjectModalProps } from '../DeleteProjectModal/types';
import { CloseDeletePopoverOne } from '../../../../auth/components/Settings/components/Account/styles';
import CloseIcon from '@mui/icons-material/Close';
import Trash from '../../../../../shared/images/Trash';
import { ButtonsWrapper } from '../../../../auth/components/Settings/components/Team/components/customTableStyles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../shared/buttons/styles';

const DeleteKeywordModal: React.FC<DeleteProjectModalProps> = ({
  onDelete,
  onClose,
  open,
  isMultiple,
  count,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      aria-labelledby='ExportGraphModal'
      aria-describedby='ExportGraphModal'
      open={open}
      onClose={onClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <ModalWrapper>
          <ModalContainer container xs={12}>
            <Grid item xs={12} sx={{ position: 'relative' }}>
              <CloseDeletePopoverOne onClick={onClose}>
                <CloseIcon fontSize='small' />
              </CloseDeletePopoverOne>
              <Grid display='flex' justifyContent='center'>
                <Trash width='40' height='40' />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography
                fontSize='18px'
                fontWeight='600'
                lineHeight='28px'
                color='#101828'
                textAlign='center'
                paddingTop={'24px'}
              >
                Delete keywords?
              </Typography>
              <Typography
                fontSize='14px'
                fontWeight='400'
                lineHeight='24px'
                color='#475467'
                marginTop='16px'
                textAlign={'center'}
              >
                Are you sure you want to delete keyword(s)? You won’t be able to
                recover once you delete it. All the data associated with it will
                be lost.
                <br />
                <br />
                <b>{`${count} keywords(s)`}</b>&nbsp;will be permanently
                deleted.
              </Typography>
            </Grid>

            <ButtonsWrapper container xs={12}>
              <Grid item xs={extraSmallScreen && 12}>
                <GrayButtonSmall
                  fullWidth={extraSmallScreen}
                  size='small'
                  outline
                  onClick={handleClose}
                >
                  Cancel
                </GrayButtonSmall>
              </Grid>
              <Grid item xs={extraSmallScreen && 12}>
                <PrimaryButtonSmall
                  fullWidth={extraSmallScreen}
                  variant='contained'
                  size='small'
                  type='submit'
                  onClick={onDelete}
                  sx={{
                    background: '#D92D20',
                    '&.Mui-disabled': {
                      background: 'rgba(217,45,32,0.6)',
                    },
                    '&.MuiButton-root:hover': {
                      background: '#D92D20',
                    },
                  }}
                >
                  {`Delete Keyword${isMultiple ? 's' : ''}`}
                </PrimaryButtonSmall>
              </Grid>
            </ButtonsWrapper>
          </ModalContainer>
        </ModalWrapper>
      </Fade>
    </Modal>
  );
};

export default DeleteKeywordModal;
