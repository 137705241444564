import React, { useCallback, useState } from 'react';
import { useEmailReports } from '../../../../../../../../hooks';
import {
  debounce,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ActionsMenu from '../../../ActionsMenu/ActionsMenu';
import { CountLabel } from '../../../../../../../projects/components/Projects/components/TableProjects/components/Header/styles';
import { StyledRightTools } from '../../../../../../../notes/components/ProjectExpandedNotes/components/ProjectExpandedNotesTable/components/Header/styles';
import SearchIcon from '../../../../../../../../shared/images/SearchIcon';
import PopupTableSettings from '../PopupTableSettings/PopupTableSettings';
import { PrimaryButtonCustom } from '../../../../../../../../shared/buttons/styles';
import PlusIcon from '../../../../../../../../shared/images/PlusIcon';

import CreateProjectReport from '../../../../../modals/CreateProjectReport/CreateProjectReport';
import { OutlinedInputCustom } from '../../../../../../../../shared/inputs/styled';
import { useAppSelector } from '../../../../../../../../store';
import useAccountLimits from '../../../../../../../auth/hooks/useAccountLimits';

const Header = () => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const { onCheckSubscriptionExpiration } = useAccountLimits();
  const {
    onSetRowSelectionModelEmailReports,
    onSetSearchEmailReports,
    tables: {
      scheduledEmailReports: { rowSelectionModel, search },
    },
  } = useEmailReports();

  const accountLimit = useAppSelector(state => state.auth.accountLimit);

  const [isOpen, setIsOpenModal] = useState(false);

  const [localSearch, setLocalSearch] = useState(search);

  const debounceSearch = useCallback(
    debounce(value => {
      onSetSearchEmailReports(value);
    }, 500),
    [onSetSearchEmailReports]
  );

  const handleSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLocalSearch(event.target.value);
    debounceSearch(event.target.value);
  };

  const onOpen = () => {
    const isExpiration = onCheckSubscriptionExpiration();
    if (!isExpiration) {
      setIsOpenModal(true);
    }
  };

  const onClose = () => {
    setIsOpenModal(false);
  };

  const onClearSelected = () => {
    onSetRowSelectionModelEmailReports([]);
  };

  return (
    <Grid xs={12} padding={'24px 0'}>
      <Grid
        item
        container
        xs={12}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Grid container item alignItems='center' xs={12} sm={3}>
          {rowSelectionModel.length ? (
            <>
              <Grid item>
                <IconButton
                  onClick={onClearSelected}
                  sx={{ padding: '0 !important', marginRight: '8px' }}
                >
                  <CloseIcon fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item sx={{ paddingRight: '16px' }}>
                <Typography
                  fontSize='14px'
                  lineHeight='14px'
                  color='#101828'
                  fontWeight='500'
                  alignItems='center'
                >
                  {`${rowSelectionModel.length} Selected`}
                </Typography>
              </Grid>
              <Grid item>
                <ActionsMenu />
              </Grid>
            </>
          ) : (
            <Grid item display={'flex'} alignItems={'center'}>
              <Grid marginRight={'8px'}>
                <Typography
                  variant='h6'
                  fontWeight='600'
                  color='#101828'
                  fontSize='18px'
                  lineHeight='28px'
                >
                  All Reports
                </Typography>
              </Grid>
              <Grid item>
                <CountLabel>
                  {accountLimit?.accountLimitsUsed?.numberOfEmailReports || 0}
                </CountLabel>
              </Grid>
            </Grid>
          )}
        </Grid>
        <StyledRightTools
          container
          item
          alignItems='center'
          xs={12}
          sm={9}
          spacing={2}
        >
          <Grid item>
            <OutlinedInputCustom
              $padding={'7.94px 14px 7.94px 0'}
              size='small'
              type='text'
              id='Search'
              name='Search'
              placeholder='Search'
              value={localSearch}
              onChange={handleSearch}
              startAdornment={
                <InputAdornment position='start' disablePointerEvents>
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Grid>

          <Grid item>
            <PopupTableSettings />
          </Grid>

          <Grid item>
            <PrimaryButtonCustom
              sx={{
                minWidth: extraSmallScreen ? '40px !important' : undefined,
              }}
              variant='contained'
              size='small'
              $padding={extraSmallScreen ? '10px 12px' : '8px 12px'}
              startIcon={!extraSmallScreen ? <PlusIcon /> : undefined}
              onClick={onOpen}
            >
              {!extraSmallScreen ? 'Add Scheduled Report' : <PlusIcon />}
            </PrimaryButtonCustom>
          </Grid>
        </StyledRightTools>
      </Grid>
      <CreateProjectReport open={isOpen} onClose={onClose} />
    </Grid>
  );
};

export default Header;
