import React from 'react';

const GraphIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <g clipPath='url(#clip0_852_34595)'>
      <path
        d='M0 14.618V15.4908H16V0.509277H15.1273V14.6182H3.71933e-05V14.618H0ZM13.2492 7.34026C13.1259 7.34026 13.009 7.31491 12.8995 7.27473L11.283 9.80574C11.5073 10.018 11.6489 10.3164 11.6489 10.6495C11.6489 11.2919 11.1278 11.8128 10.4853 11.8128C9.84275 11.8128 9.32177 11.2919 9.32177 10.6495C9.32177 10.4711 9.36495 10.3044 9.43648 10.1533L7.31419 8.60214C7.14875 8.74321 6.93748 8.83121 6.7035 8.83121C6.44476 8.83121 6.21063 8.72674 6.04005 8.55849L3.86933 9.91609C3.98045 10.1096 4.04482 10.3333 4.04482 10.5725C4.04482 11.3036 3.4525 11.8959 2.72176 11.8959C1.99114 11.8959 1.39879 11.3036 1.39879 10.5725C1.39879 9.84152 1.99114 9.24921 2.72176 9.24921C3.05448 9.24921 3.35796 9.37303 3.59046 9.57609L5.81156 8.18645C5.77959 8.09148 5.7583 7.99153 5.7583 7.88573C5.7583 7.36366 6.18151 6.94041 6.7035 6.94041C7.22577 6.94041 7.6491 7.36366 7.6491 7.88573C7.6491 8.01582 7.62266 8.13965 7.57488 8.25252L9.69378 9.80068C9.90159 9.60681 10.1785 9.48575 10.4851 9.48575C10.6373 9.48575 10.782 9.51706 10.9154 9.57032L12.5297 7.04286C12.3448 6.85857 12.2305 6.60372 12.2305 6.32228C12.2305 5.75979 12.6863 5.30403 13.2488 5.30403C13.8111 5.30403 14.267 5.75979 14.267 6.32228C14.2671 6.88473 13.8115 7.34026 13.2492 7.34026Z'
        fill='#667085'
      />
    </g>
    <defs>
      <clipPath id='clip0_852_34595'>
        <rect
          width='16'
          height='16'
          fill='white'
          transform='matrix(-1 0 0 1 16 0)'
        />
      </clipPath>
    </defs>
  </svg>
);

export default GraphIcon;
