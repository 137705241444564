import React from 'react';
import { Grid, Typography } from '@mui/material';
import {
  AddedTag,
  AddTagsContainer,
  AddTagsInput,
  ButtonTooltipContainer,
} from '../styles';
import { CustomTooltip } from '../../../../../../../shared/tooltip/styles';
import * as Tooltips from '../Tooltips';
import HelperIcon from '../../../../../../../shared/images/HelperIcon';
import { Competitors } from '../../../../../types';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import CloseIcon from '../../../../../../../shared/images/CloseIcon';
import { Popover } from '../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import AddCompetitorsPopup from './AddCompetitorsPopup';

interface CompetitorsSection {
  refInput: React.RefObject<HTMLInputElement>;
  competitors: string[];
  competitorIds: Competitors[];
  setFieldValue: any;
  isNew: boolean;
}

const CompetitorsSection: React.FC<CompetitorsSection> = ({
  refInput,
  competitors,
  competitorIds,
  setFieldValue,
  isNew,
}) => {
  return (
    <Grid item xs={12} paddingTop='8px'>
      <Grid container fontWeight='500' alignItems='center' fontSize='14px'>
        <Grid item>Competitors (Optional)</Grid>
        <Grid item>
          <CustomTooltip
            title={<Tooltips.Competitors />}
            placement='top-start'
            arrow
          >
            <ButtonTooltipContainer>
              <HelperIcon />
            </ButtonTooltipContainer>
          </CustomTooltip>
        </Grid>

        <PopupState variant='popover' popupId='add-tag-setting'>
          {popupState => (
            <>
              <AddTagsInput ref={refInput} {...bindTrigger(popupState)}>
                {competitors.length
                  ? competitors.map(item => (
                      <AddedTag key={item}>
                        <Typography component='span'>{item}</Typography>
                        <Typography
                          component='p'
                          sx={{ fontSize: '0' }}
                          onClick={event => {
                            event.stopPropagation();
                            event.preventDefault();
                            setFieldValue(
                              'competitors',
                              competitors.filter(
                                filterItem => filterItem !== item
                              )
                            );
                          }}
                        >
                          <CloseIcon width='12' height='12' />
                        </Typography>
                      </AddedTag>
                    ))
                  : null}

                {competitorIds.length
                  ? competitorIds.map(item => (
                      <AddedTag key={item.id}>
                        <Typography component='span'>
                          {item.domainName}
                        </Typography>
                        <Typography
                          component='p'
                          sx={{ fontSize: '0' }}
                          onClick={event => {
                            event.stopPropagation();
                            event.preventDefault();
                            setFieldValue(
                              'competitorIds',
                              competitorIds.filter(
                                filterItem => filterItem.id !== item.id
                              )
                            );
                          }}
                        >
                          <CloseIcon width='12' height='12' />
                        </Typography>
                      </AddedTag>
                    ))
                  : null}

                <AddTagsContainer>
                  <Typography component='span'>Add competitors</Typography>
                </AddTagsContainer>
              </AddTagsInput>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                sx={{
                  boxShadow:
                    '0px 12px 16px -4px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.06)',
                  borderRadius: '8px',
                  marginTop: '7px',
                }}
              >
                <AddCompetitorsPopup
                  closePopover={popupState.close}
                  competitors={competitors}
                  competitorIds={competitorIds}
                  setFieldValue={setFieldValue}
                  refInput={refInput}
                  isNew={isNew}
                />
              </Popover>
            </>
          )}
        </PopupState>
      </Grid>
    </Grid>
  );
};
export default CompetitorsSection;
