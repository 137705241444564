import React from 'react';

const VerifyEmailSuccessfulImage: React.FC = () => {
  return (
    <svg
      width='179'
      height='160'
      viewBox='0 0 179 160'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_213_30138)'>
        <path
          d='M177.056 61.1872C176.992 61.1873 176.93 61.1677 176.878 61.1312L90.3981 0.904351C90.1349 0.721923 89.8222 0.624429 89.502 0.624976C89.1818 0.625522 88.8694 0.72408 88.6068 0.907404L2.75017 61.1306C2.68226 61.1782 2.59821 61.1969 2.51651 61.1826C2.43481 61.1683 2.36215 61.122 2.31452 61.0541C2.26689 60.9862 2.24818 60.9022 2.26252 60.8205C2.27686 60.7388 2.32307 60.6661 2.39098 60.6185L88.2477 0.395299C88.6153 0.138683 89.0527 0.000739727 89.501 2.96675e-06C89.9493 -0.000733793 90.3871 0.13577 90.7555 0.391177L177.235 60.6179C177.29 60.6559 177.331 60.7105 177.352 60.7735C177.374 60.8365 177.375 60.9047 177.355 60.9682C177.335 61.0318 177.295 61.0873 177.241 61.1267C177.188 61.166 177.123 61.1873 177.056 61.1872L177.056 61.1872Z'
          fill='#3F3D56'
        />
        <path
          d='M7.65625 63.3223L89.587 2.60156L172.139 67.6429L93.8085 114.079L51.2815 104.385L7.65625 63.3223Z'
          fill='#E6E6E6'
        />
        <path
          d='M54.8712 142.76H14.3954C14.1469 142.76 13.9007 142.711 13.671 142.616C13.4413 142.521 13.2325 142.382 13.0567 142.207C12.8808 142.031 12.7413 141.822 12.6461 141.593C12.551 141.363 12.502 141.117 12.502 140.869C12.502 140.62 12.551 140.374 12.6461 140.144C12.7413 139.915 12.8808 139.706 13.0567 139.531C13.2325 139.355 13.4413 139.216 13.671 139.121C13.9007 139.026 14.1469 138.977 14.3954 138.978H54.8712C55.1197 138.977 55.3659 139.026 55.5956 139.121C55.8253 139.216 56.0341 139.355 56.2099 139.531C56.3858 139.706 56.5253 139.915 56.6205 140.144C56.7156 140.374 56.7646 140.62 56.7646 140.869C56.7646 141.117 56.7156 141.363 56.6205 141.593C56.5253 141.822 56.3858 142.031 56.2099 142.207C56.0341 142.382 55.8253 142.521 55.5956 142.616C55.3659 142.711 55.1197 142.76 54.8712 142.76V142.76Z'
          fill='#126FE9'
        />
        <path
          d='M28.9172 134.63H14.3954C14.1469 134.63 13.9007 134.581 13.671 134.486C13.4413 134.392 13.2325 134.252 13.0567 134.077C12.8808 133.901 12.7413 133.692 12.6461 133.463C12.551 133.233 12.502 132.987 12.502 132.739C12.502 132.49 12.551 132.244 12.6461 132.014C12.7413 131.785 12.8808 131.576 13.0567 131.401C13.2325 131.225 13.4413 131.086 13.671 130.991C13.9007 130.896 14.1469 130.847 14.3954 130.848H28.9172C29.1657 130.847 29.4119 130.896 29.6416 130.991C29.8713 131.086 30.0801 131.225 30.2559 131.401C30.4318 131.576 30.5713 131.785 30.6665 132.014C30.7617 132.244 30.8106 132.49 30.8106 132.739C30.8106 132.987 30.7617 133.233 30.6665 133.463C30.5713 133.692 30.4318 133.901 30.2559 134.077C30.0801 134.252 29.8713 134.392 29.6416 134.486C29.4119 134.581 29.1657 134.63 28.9172 134.63Z'
          fill='#126FE9'
        />
        <path
          d='M90.7389 97.1426C90.4312 97.1429 90.1265 97.0832 89.8417 96.9668L39.3125 76.0008V10.3735C39.3132 9.79314 39.544 9.23678 39.9543 8.82643C40.3647 8.41608 40.9211 8.18525 41.5014 8.18457H138.438C139.018 8.18525 139.575 8.41608 139.985 8.82643C140.395 9.23678 140.626 9.79314 140.627 10.3735V76.0466L140.532 76.0872L91.6632 96.9566C91.3706 97.0796 91.0563 97.1428 90.7389 97.1426Z'
          fill='white'
        />
        <path
          d='M90.739 97.2989C90.4109 97.2992 90.086 97.2357 89.7823 97.1117L39.1562 76.1053V10.3736C39.157 9.75178 39.4043 9.15567 39.8439 8.716C40.2836 8.27634 40.8797 8.02902 41.5015 8.02832H138.438C139.06 8.02902 139.656 8.27634 140.096 8.716C140.535 9.15567 140.783 9.75178 140.783 10.3736V76.1499L91.7247 97.1004C91.4127 97.2317 91.0775 97.2992 90.739 97.2989V97.2989ZM39.7816 75.6876L90.0208 96.5335C90.4893 96.7236 91.0141 96.7205 91.4804 96.5248L140.158 75.737V10.3736C140.157 9.9176 139.976 9.48046 139.654 9.15805C139.331 8.83563 138.894 8.65426 138.438 8.65372H41.5015C41.0455 8.65426 40.6084 8.83563 40.286 9.15805C39.9636 9.48046 39.7822 9.9176 39.7817 10.3736L39.7816 75.6876Z'
          fill='#3F3D56'
        />
        <path
          d='M176.43 60.562H176.368L140.47 75.8905L91.3543 96.8632C91.1624 96.9439 90.9565 96.986 90.7483 96.9871C90.5401 96.9882 90.3337 96.9482 90.1409 96.8695L39.4682 75.8467L2.68854 60.587L2.63235 60.562H2.56975C1.98942 60.5627 1.43304 60.7935 1.02268 61.2038C0.612323 61.6142 0.381503 62.1706 0.380859 62.7509V157.811C0.381504 158.392 0.612323 158.948 1.02268 159.358C1.43304 159.769 1.98942 160 2.56975 160H176.43C177.011 160 177.567 159.769 177.977 159.358C178.388 158.948 178.618 158.392 178.619 157.811V62.7509C178.618 62.1706 178.388 61.6142 177.977 61.2038C177.567 60.7935 177.011 60.5627 176.43 60.562V60.562ZM177.994 157.811C177.993 158.226 177.829 158.623 177.536 158.917C177.242 159.21 176.845 159.375 176.43 159.375H2.56975C2.15514 159.375 1.75755 159.21 1.46438 158.917C1.1712 158.623 1.00642 158.226 1.00626 157.811V62.7509C1.00682 62.3466 1.16366 61.9581 1.44397 61.6668C1.72428 61.3754 2.10636 61.2036 2.51036 61.1874L39.4682 76.5221L89.9003 97.4479C90.4462 97.6701 91.0579 97.6667 91.6014 97.4386L140.47 76.5691L176.493 61.1874C176.896 61.2048 177.277 61.3771 177.557 61.6683C177.836 61.9595 177.993 62.3472 177.994 62.7509V157.811Z'
          fill='#3F3D56'
        />
        <path
          d='M90.1807 127.896C83.5877 127.896 77.1427 125.941 71.6609 122.279C66.179 118.616 61.9063 113.41 59.3833 107.319C56.8602 101.227 56.2 94.5249 57.4862 88.0586C58.7724 81.5923 61.9472 75.6526 66.6091 70.9907C71.271 66.3287 77.2107 63.1539 83.677 61.8676C90.1433 60.5814 96.8458 61.2415 102.937 63.7645C109.028 66.2875 114.234 70.5601 117.897 76.0419C121.56 81.5238 123.515 87.9687 123.515 94.5617C123.505 103.399 119.99 111.872 113.741 118.122C107.491 124.371 99.0184 127.886 90.1807 127.896V127.896Z'
          fill='#57AD23'
        />
        <path
          d='M85.8628 107.152C85.2617 107.153 84.6827 106.925 84.2428 106.516L73.251 96.3063C72.7891 95.8763 72.5167 95.2806 72.4938 94.6499C72.4708 94.0193 72.6991 93.4053 73.1286 92.9429C73.5581 92.4806 74.1535 92.2076 74.7841 92.184C75.4147 92.1604 76.0289 92.3881 76.4918 92.8171L85.802 101.465L104.222 83.0451C104.443 82.8226 104.706 82.6459 104.995 82.5251C105.284 82.4044 105.594 82.3419 105.908 82.3413C106.221 82.3407 106.532 82.402 106.822 82.5217C107.111 82.6415 107.374 82.8172 107.596 83.0389C107.818 83.2605 107.993 83.5238 108.113 83.8135C108.233 84.1033 108.294 84.4138 108.293 84.7272C108.293 85.0407 108.23 85.3509 108.109 85.6402C107.988 85.9294 107.812 86.1919 107.589 86.4127L87.5469 106.455C87.3259 106.676 87.0634 106.852 86.7744 106.971C86.4854 107.091 86.1756 107.153 85.8628 107.152V107.152Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_213_30138'>
          <rect
            width='178.238'
            height='160'
            fill='white'
            transform='translate(0.380859)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VerifyEmailSuccessfulImage;
