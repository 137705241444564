import { styled } from '@mui/material/styles';

import {
  ListItemTypography as ProjectListItemTypography,
  MainContainer as ProjectMainContainer,
  StyledListItem as ProjectStyledListItem,
} from '../../../../../../../../../projects/components/Projects/components/TableProjects/components/Header/components/PopupTableSettings/styles';
import { NoBorderButton } from '../../../../../../../../../../shared/buttons/styles';

const MainContainer = styled(ProjectMainContainer)`
  max-height: 479px;
`;

const ResetNoBorderButton = styled(NoBorderButton)`
  padding: 11.5px 16px !important;
`;

const ListItemTypography = styled(ProjectListItemTypography)``;

const StyledListItem = styled(ProjectStyledListItem)``;

export {
  MainContainer,
  ListItemTypography,
  StyledListItem,
  ResetNoBorderButton,
};
