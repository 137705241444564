import { Fade, Grid, Modal, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import dayjs from 'dayjs';

import { DeleteAccountStep3Props } from './types';
import Trash from '../../../../../../shared/images/Trash';

import {
  ButtonsWrapper,
  CloseDeletePopoverOne,
  CustomModal,
  HeaderContainer,
  ModalContainer,
  WarningContainer,
} from './styles';
import { PrimaryButtonSmall } from '../../../../../../shared/buttons/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from '../../../../../../store';

/**
 * DeleteAccountStep3 component displays the final step in the delete account process, informing the user that their account deletion is scheduled and providing additional information about the grace period.
 */
const DeleteAccountStep3: React.FC<DeleteAccountStep3Props> = ({
  open,
  handleClose,
}) => {
  const myAccount = useAppSelector(state => state.auth.myAccount);
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <CustomModal>
          <ModalContainer>
            <HeaderContainer>
              <CloseDeletePopoverOne color='error' onClick={handleClose}>
                <CloseIcon fontSize='small' />
              </CloseDeletePopoverOne>
              <Trash />
            </HeaderContainer>
            <WarningContainer sx={{ marginTop: '24px' }}>
              <div>
                <Typography variant='h6' fontWeight='600'>
                  Account deletion scheduled
                </Typography>
                <Typography
                  variant='body2'
                  color='#475467'
                  fontSize='14px'
                  lineHeight='24px'
                  paddingTop='16px'
                  paddingBottom='32px'
                >
                  Thank you for using our service. We&apos;re sorry to see you
                  go. Your account is scheduled to be deleted in 30 days, on{' '}
                  {myAccount?.deletedAt && (
                    <b>{dayjs(myAccount.deletedAt).format('MMMM D, YYYY')}</b>
                  )}
                </Typography>
                <Typography
                  variant='body2'
                  lineHeight='24px'
                  color='#475467'
                  paddingBottom='32px'
                >
                  If you change your mind within this period, you can log back
                  in and choose <b>&rsquo;Cancel Deletion&rsquo;</b> from your
                  account settings.
                </Typography>
                <Typography variant='body2' lineHeight='24px' color='#475467'>
                  Remember, after the 30-day grace period, your account and all
                  associated data will be permanently deleted and cannot be
                  recovered.
                </Typography>
              </div>
            </WarningContainer>
            <ButtonsWrapper container xs={12}>
              <Grid item xs={extraSmallScreen && 12}>
                <PrimaryButtonSmall
                  fullWidth={extraSmallScreen}
                  onClick={handleClose}
                  variant='contained'
                  color='primary'
                >
                  Got it
                </PrimaryButtonSmall>
              </Grid>
            </ButtonsWrapper>
          </ModalContainer>
        </CustomModal>
      </Fade>
    </Modal>
  );
};

export default DeleteAccountStep3;
