import axios, { AxiosResponse } from 'axios';

/**
 * Interface representing the structure of the geolocation data returned from the API.
 */
export interface IAddressGeolocation {
  ip: string;
  network: string;
  version: string;
  city: string;
  region: string;
  region_code: string;
  country: string;
  country_name: string;
  country_code: string;
  country_code_iso3: string;
  country_capital: string;
  country_tld: string;
  continent_code: string;
  in_eu: boolean;
  postal: string;
  latitude: number;
  longitude: number;
  timezone: string;
  utc_offset: string;
  country_calling_code: string;
  currency: string;
  currency_name: string;
  languages: string;
  country_area: number;
  country_population: number;
  asn: string;
  org: string;
}

/**
 * Axios instance configured for the ipapi service.
 */
const apiIpapi = axios.create({
  withCredentials: false,
  baseURL: 'https://ipapi.co/json/',
});

/**
 * Fetches geolocation data based on the IP address.
 *
 * @returns {Promise<AxiosResponse<IAddressGeolocation>>} A promise that resolves to the geolocation data response.
 */
const getAddressGeolocationAPI = async (): Promise<
  AxiosResponse<IAddressGeolocation>
> => {
  return apiIpapi.get<IAddressGeolocation>('');
};

export { getAddressGeolocationAPI };
