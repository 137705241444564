import React from 'react';
import { StyledListItemButton } from '../../../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/styles';
import { FolderActionProps } from './types';
import { useFolders } from '../../../../../../../../../hooks';

import SmallTrashIcon from '../../../../../../../../../shared/images/SmallTrashIcom';
import { DeleteFolderModal } from '../../Modals';

import { ListItemTypography, StyledListItem } from '../styles';

const DeleteFoldersAction: React.FC<FolderActionProps> = ({ popupClose }) => {
  const {
    tables: {
      rootFolder: { rowSelectionModel },
    },
  } = useFolders();

  const [isOpenDeleteFolderModal, setIsOpenDeleteFolderModal] =
    React.useState<boolean>(false);

  const handleOpenDeleteFolderModal = () => setIsOpenDeleteFolderModal(true);
  const handleCloseDeleteFolderModal = () => setIsOpenDeleteFolderModal(false);

  const onDelete = () => {
    handleOpenDeleteFolderModal();
  };

  const handleClose = () => {
    popupClose();
    handleCloseDeleteFolderModal();
  };

  const getString = () => {
    if (
      rowSelectionModel.find(item => item.type === 'folder') &&
      rowSelectionModel.find(item => item.type === 'project')
    ) {
      return 'Selected';
    }
    if (!rowSelectionModel.find(item => item.type === 'project'))
      return 'Folders';
    if (!rowSelectionModel.find(item => item.type === 'folder'))
      return 'Projects';
  };

  return (
    <StyledListItem onClick={onDelete}>
      <StyledListItemButton>
        <SmallTrashIcon />
        <ListItemTypography marginLeft='8px' sx={{ color: '#B42318' }}>
          Delete {getString()}
        </ListItemTypography>
      </StyledListItemButton>
      <DeleteFolderModal
        open={isOpenDeleteFolderModal}
        handleClose={handleClose}
      />
    </StyledListItem>
  );
};

export default DeleteFoldersAction;
