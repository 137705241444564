import React from 'react';
import { MainContainer } from '../../../../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/styles';
import { List } from '@mui/material';
import DeleteAction from './DeleteAction';

interface ListActionsProps {
  id: number;
  popupClose: () => void;
}

const ListActions: React.FC<ListActionsProps> = ({ id, popupClose }) => {
  return (
    <MainContainer>
      <List>
        <DeleteAction id={id} popupClose={popupClose} />
      </List>
    </MainContainer>
  );
};

export default ListActions;
