import React from 'react';
import { KeywordsActionProps } from '../../../../../../../../projects/components/ProjectExpanded/components/KeywordRankings/components/KeywordRankingsTable/components/ActionsMenu/components/types';
import { useAuth, useNotes } from '../../../../../../../../../hooks';
import { DEFAULT_GET_USERS_PARAMS } from '../../../../../../../../auth/components/Settings/components/Team/constants';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import CustomToast from '../../../../../../../../../shared/CustomToast/CustomToast';
import { closeAndUndoSnackbarAction } from '../../../../../../../../../utils/snackbar';
import {
  ListItemTypography,
  StyledListItem,
} from '../../../../../../../../projects/components/ProjectExpanded/components/KeywordRankings/components/KeywordRankingsTable/components/ActionsMenu/styles';
import SmallTrashIcon from '../../../../../../../../../shared/images/SmallTrashIcom';
import { StyledListItemButton } from '../../../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/styles';
import { useAppSelector } from '../../../../../../../../../store';

const DeleteProjectNotesAction: React.FC<KeywordsActionProps> = ({
  popupClose,
}) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const {
    onSetDeleteElementsAllNotesTable,
    onBulkDeleteNotesNotes,
    onGetAllNotes,
    tables: {
      allNotes: { paginationModel, search, sortModel, rowSelectionModel },
    },
  } = useNotes();

  const handleHardDelete = () => {
    onBulkDeleteNotesNotes({
      id: currentAccount.accountId,
      noteIds: rowSelectionModel as number[],
      successFn: () => {
        if (currentAccount?.accountId) {
          onGetAllNotes({
            id: currentAccount.accountId,
            page: paginationModel.page,
            limit: paginationModel.pageSize,
            search: search || DEFAULT_GET_USERS_PARAMS.search,
            sortBy: sortModel[0]?.field,
            sortOrder: sortModel[0]?.sort,
          });
          onSetDeleteElementsAllNotesTable([]);
        }
      },
    });
  };

  const handleDeleteOneKeywords = () => {
    onSetDeleteElementsAllNotesTable(rowSelectionModel);

    popupClose();
    let undoDelete = false;
    const onUndo = () => {
      undoDelete = true;
      onSetDeleteElementsAllNotesTable([]);
    };
    enqueueSnackbar(
      <CustomToast
        message='Notes Removed'
        submessage={`Notes has been successfully removed.`}
      />,
      {
        action: (snackbarId: SnackbarKey) =>
          closeAndUndoSnackbarAction(snackbarId, onUndo),
        variant: 'error',
      }
    );
    setTimeout(() => {
      !undoDelete && handleHardDelete();
    }, 5000);
  };

  return (
    <StyledListItem onClick={handleDeleteOneKeywords}>
      <StyledListItemButton>
        <SmallTrashIcon />
        <ListItemTypography marginLeft='8px' sx={{ color: '#B42318' }}>
          Delete Notes
        </ListItemTypography>
      </StyledListItemButton>
    </StyledListItem>
  );
};

export default DeleteProjectNotesAction;
