import React from 'react';

const DollarIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        d='M8 0.666016V15.3327'
        stroke='#667085'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.3333 3.33398H6.33333C5.71449 3.33398 5.121 3.57982 4.68342 4.0174C4.24583 4.45499 4 5.04848 4 5.66732C4 6.28616 4.24583 6.87965 4.68342 7.31723C5.121 7.75482 5.71449 8.00065 6.33333 8.00065H9.66667C10.2855 8.00065 10.879 8.24648 11.3166 8.68407C11.7542 9.12165 12 9.71515 12 10.334C12 10.9528 11.7542 11.5463 11.3166 11.9839C10.879 12.4215 10.2855 12.6673 9.66667 12.6673H4'
        stroke='#667085'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default DollarIcon;
