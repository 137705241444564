import { useAppDispatch } from '../../../store';
import { useCallback } from 'react';
import {
  bulkDeleteSharedLinksThunk,
  createSharedLinkThunk,
  getAllSharedLinksThunk,
  getSharedImprovedVsDeclinedThunk,
  getSharedKeywordRankingsThunk,
  getSharedKeywordTrendsThunk,
  getSharedProjectOverviewThunk,
  getSharedProjectThunk,
  getSharedThunk,
  loginThunk,
  getKeywordPositionHistoryThunk,
  getKeywordSearchResultsThunk,
  getSharedProjectPerformanceThunk,
  getSharedLinkFullThunk,
  editSharedLinkThunk,
  getSharedLinkSettingsThunk,
} from '../redux/thunk';
import {
  BulkDeleteSharedLinksParams,
  CreateSharedLinkParams,
  GetAllSharedLinksParams,
  GetSharedParams,
  GetSharedProjectImprovedVsDeclinedParams,
  GetSharedProjectKeywordTrendsParams,
  GetSharedProjectOverviewParams,
  GetSharedProjectParams,
  LoginSharedParams,
  GetSharedProjectKeywordRankingParams,
  GetSharedProjectKeywordPositionsInfoParams,
  GetSharedProjectKeywordSearchResultsParams,
  GetSharedProjectPerformanceParams,
  GetSharedLinkFullParams,
  EditSharedLinkParams,
  GetSharedLinkSettingsParams,
} from '../types';
import {
  setSearchAllSharedLinks,
  setColumnVisibilityModel,
  resetColumnVisibilityModel,
  setPaginationPage,
  setPaginationPageSize,
  setRowSelectionModelSharedLinks,
  setPaginationPageSizeForSharedLinkContentsListTable,
  setPaginationPageForSharedLinkContentsListTable,
  setColumnVisibilityModelForSharedLinkContentsListTable,
  resetColumnVisibilityModelForSharedLinkContentsListTable,
  setSearchSharedLinkContentsList,
  setDeviceTypeForProject,
  setRangeDateForProject,
  setIsShowImprovedVsDeclined,
  setImprovedVsDeclinedVisibilityModel,
  setActivePeriodImprovedVsDeclined,
  setKeywordTrendsVisibilityModel,
  setActivePeriodKeywordTrends,
  resetColumnVisibilityModelKeywordRankings,
  setColumnVisibilityModelKeywordRankings,
  setRowKeywordsSelectionModel,
  setSearchKeywordRankings,
  setSortModelKeywordRankings,
  setKeywordRankingsPaginationPageSize,
  setKeywordRankingsPaginationPage,
  setKeywordAnalyticPeriod,
  setCloseKeywordPositionHistory,
  setActivePeriodSharedProjectPerformance,
  setSharedProjectPerformanceIsShowNotes,
  setSharedProjectPerformanceIsLinearGraph,
  setSharedProjectShowCompetitorsForGraph,
  resetSharedLinksState,
  setSortModelSharedProjects,
  setSharedLinksColumnKeywordPositionModel,
  resetSharedLinksKeywordPositionModel,
  setSharedShowCompetitorsForPositionHistory,
  setDeleteElementsSharedLinksPageTable,
  setIsPasswordUpdated,
} from '../redux/actions';
import { ColumnVisibilityKey } from '../types';
import {
  AccountTag,
  ColumnVisibilityKey as ColumnVisibilityKeyForProject,
  ColumnVisibilityKeyKeywordRankings,
  ImprovedVsDeclinedVisibilityKey,
  NotRankedVisibilityKey,
} from '../../projects/types';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import { Range } from 'react-date-range';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { selectKeywordTags } from '../../projects/redux/slice';
import { selectSharedKeywordTags } from '../redux/slice';

export default function useSharedLinks() {
  const dispatch = useAppDispatch();

  const onGetSharedLinks = useCallback(
    (param: GetAllSharedLinksParams) => {
      dispatch(getAllSharedLinksThunk(param));
    },
    [dispatch]
  );

  const onSearchAllSharedLinks = useCallback(
    (value: string) => {
      dispatch(setSearchAllSharedLinks(value));
    },
    [dispatch]
  );

  const onSetColumnVisibilityModel = useCallback(
    (key: ColumnVisibilityKey, value: boolean) => {
      dispatch(setColumnVisibilityModel(key, value));
    },
    [dispatch]
  );

  const onResetColumnVisibilityModel = useCallback(() => {
    dispatch(resetColumnVisibilityModel());
  }, [dispatch]);

  const onSetPaginationPageSize = useCallback(
    (pageSize: number) => {
      dispatch(setPaginationPageSize(pageSize));
    },
    [dispatch]
  );

  const onSetPaginationPage = useCallback(
    (page: number) => {
      dispatch(setPaginationPage(page));
    },
    [dispatch]
  );

  const onSetRowSelectionModelSharedLinks = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => {
      dispatch(setRowSelectionModelSharedLinks(rowSelectionModel));
    },
    [dispatch]
  );

  const onCreateSharedLink = useCallback(
    (params: CreateSharedLinkParams) => {
      dispatch(createSharedLinkThunk(params));
    },
    [dispatch]
  );

  const onBulkDeleteSharedLinks = useCallback(
    (params: BulkDeleteSharedLinksParams) => {
      dispatch(bulkDeleteSharedLinksThunk(params));
    },
    [dispatch]
  );

  const onGetShared = useCallback(
    (params: GetSharedParams) => {
      dispatch(getSharedThunk(params));
    },
    [dispatch]
  );

  const onSetPaginationPageSizeForSharedLinkContentsListTable = useCallback(
    (pageSize: number) => {
      dispatch(setPaginationPageSizeForSharedLinkContentsListTable(pageSize));
    },
    [dispatch]
  );

  const onSetPaginationPageForSharedLinkContentsListTable = useCallback(
    (page: number) => {
      dispatch(setPaginationPageForSharedLinkContentsListTable(page));
    },
    [dispatch]
  );

  const onSetColumnVisibilityModelForSharedLinkContentsListTable = useCallback(
    (key: ColumnVisibilityKeyForProject, value: boolean) => {
      dispatch(
        setColumnVisibilityModelForSharedLinkContentsListTable(key, value)
      );
    },
    [dispatch]
  );

  const onResetColumnVisibilityModelForSharedLinkContentsListTable =
    useCallback(() => {
      dispatch(resetColumnVisibilityModelForSharedLinkContentsListTable());
    }, [dispatch]);

  const onSearchSharedLinkContentsList = useCallback(
    (value: string) => {
      dispatch(setSearchSharedLinkContentsList(value));
    },
    [dispatch]
  );

  const onLoginThunk = useCallback(
    (params: LoginSharedParams) => {
      dispatch(loginThunk(params));
    },
    [dispatch]
  );

  const onGetSharedProject = useCallback(
    (params: GetSharedProjectParams) => {
      dispatch(getSharedProjectThunk(params));
    },
    [dispatch]
  );

  const onSetProjectSharedDeviceType = useCallback(
    (deviceType: string) => {
      dispatch(setDeviceTypeForProject(deviceType));
    },
    [dispatch]
  );

  const onSetRangeDateForProject = useCallback(
    (prams: Range) => {
      dispatch(setRangeDateForProject(prams));
    },
    [dispatch]
  );

  const onGetSharedProjectOverview = useCallback(
    (params: GetSharedProjectOverviewParams) => {
      dispatch(getSharedProjectOverviewThunk(params));
    },
    [dispatch]
  );

  const onSetIsShowImprovedVsDeclined = useCallback(
    (prams: boolean) => {
      dispatch(setIsShowImprovedVsDeclined(prams));
    },
    [dispatch]
  );

  const onImprovedVsDeclinedVisibilityModel = useCallback(
    (key: ImprovedVsDeclinedVisibilityKey, value: boolean) => {
      dispatch(setImprovedVsDeclinedVisibilityModel(key, value));
    },
    [dispatch]
  );

  const onSetActivePeriodImprovedVsDeclined = useCallback(
    (prams: { label: string; value: string }) => {
      dispatch(setActivePeriodImprovedVsDeclined(prams));
    },
    [dispatch]
  );

  const onGetSharedProjectImprovedVsDeclined = useCallback(
    (params: GetSharedProjectImprovedVsDeclinedParams) => {
      dispatch(getSharedImprovedVsDeclinedThunk(params));
    },
    [dispatch]
  );

  const onSetKeywordTrendsVisibilityModel = useCallback(
    (key: NotRankedVisibilityKey, value: boolean) => {
      dispatch(setKeywordTrendsVisibilityModel(key, value));
    },
    [dispatch]
  );

  const onSetActivePeriodKeywordTrends = useCallback(
    (prams: { label: string; value: string }) => {
      dispatch(setActivePeriodKeywordTrends(prams));
    },
    [dispatch]
  );

  const onGetSharedProjectKeywordTrends = useCallback(
    (params: GetSharedProjectKeywordTrendsParams) => {
      dispatch(getSharedKeywordTrendsThunk(params));
    },
    [dispatch]
  );

  const onGetSharedProjectKeywordRankings = useCallback(
    (prams: GetSharedProjectKeywordRankingParams) => {
      dispatch(getSharedKeywordRankingsThunk(prams));
    },
    [dispatch]
  );

  const onSetColumnVisibilityModelKeywordRankings = useCallback(
    (key: ColumnVisibilityKeyKeywordRankings, value: boolean) => {
      dispatch(setColumnVisibilityModelKeywordRankings(key, value));
    },
    [dispatch]
  );

  const onResetColumnVisibilityModelKeywordRankings = useCallback(() => {
    dispatch(resetColumnVisibilityModelKeywordRankings());
  }, [dispatch]);

  const onSetRowSelectionKeywordsModel = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => {
      dispatch(setRowKeywordsSelectionModel(rowSelectionModel));
    },
    [dispatch]
  );

  const onSetSearchKeywordRankings = useCallback(
    (value: string) => {
      dispatch(setSearchKeywordRankings(value));
    },
    [dispatch]
  );

  const onSetSortModelKeywordRankings = useCallback(
    (sortModel: GridSortModel) => {
      dispatch(setSortModelKeywordRankings(sortModel));
    },
    [dispatch]
  );

  const onSetKeywordRankingsPaginationPageSize = useCallback(
    (pageSize: number) => {
      dispatch(setKeywordRankingsPaginationPageSize(pageSize));
    },
    [dispatch]
  );

  const onSetKeywordRankingsPaginationPage = useCallback(
    (page: number) => {
      dispatch(setKeywordRankingsPaginationPage(page));
    },
    [dispatch]
  );

  const onGetKeywordPositionHistory = useCallback(
    (prams: GetSharedProjectKeywordPositionsInfoParams) => {
      dispatch(getKeywordPositionHistoryThunk(prams));
    },
    [dispatch]
  );

  const onSetKeywordAnalyticPeriod = useCallback(
    (prams: { id: number; period: { label: string; value: string } }) => {
      dispatch(setKeywordAnalyticPeriod(prams.id, prams.period));
    },
    [dispatch]
  );

  const onCloseKeywordPositionHistory = useCallback(
    (prams: { id: number }) => {
      dispatch(setCloseKeywordPositionHistory(prams.id));
    },
    [dispatch]
  );

  const onGetKeywordSearchResults = useCallback(
    (prams: GetSharedProjectKeywordSearchResultsParams) => {
      dispatch(getKeywordSearchResultsThunk(prams));
    },
    [dispatch]
  );

  const onGetSharedProjectPerformance = useCallback(
    (prams: GetSharedProjectPerformanceParams) => {
      dispatch(getSharedProjectPerformanceThunk(prams));
    },
    [dispatch]
  );

  const onSetActivePeriodSharedProjectPerformance = useCallback(
    (prams: { label: string; value: string }) => {
      dispatch(setActivePeriodSharedProjectPerformance(prams));
    },
    [dispatch]
  );

  const onSetProjectPerformanceIsShowNotes = useCallback(
    (prams: boolean) => {
      dispatch(setSharedProjectPerformanceIsShowNotes(prams));
    },
    [dispatch]
  );

  const onSetProjectPerformanceIsLinearGraph = useCallback(
    (prams: boolean) => {
      dispatch(setSharedProjectPerformanceIsLinearGraph(prams));
    },
    [dispatch]
  );

  const onSetShowCompetitorsForGraph = useCallback(
    (values: number[]) => {
      dispatch(setSharedProjectShowCompetitorsForGraph(values));
    },
    [dispatch]
  );

  const onResetSharedLinksState = useCallback(() => {
    dispatch(resetSharedLinksState());
  }, [dispatch]);

  const onSetSortModelSharedProjects = useCallback(
    (sortModel: GridSortModel) => {
      dispatch(setSortModelSharedProjects(sortModel));
    },
    [dispatch]
  );

  const onGetSharedLinkFull = useCallback(
    (params: GetSharedLinkFullParams) => {
      dispatch(getSharedLinkFullThunk(params));
    },
    [dispatch]
  );

  const onEditSharedLink = useCallback(
    (params: EditSharedLinkParams) => {
      dispatch(editSharedLinkThunk(params));
    },
    [dispatch]
  );

  const ongGetSharedLinkSettings = useCallback(
    (params: GetSharedLinkSettingsParams) => {
      dispatch(getSharedLinkSettingsThunk(params));
    },
    [dispatch]
  );

  const onSetSharedLinksColumnKeywordPositionModel = useCallback(
    (params: string[]) => {
      dispatch(setSharedLinksColumnKeywordPositionModel(params));
    },
    [dispatch]
  );

  const onResetSharedLinksKeywordPositionModel = useCallback(() => {
    dispatch(resetSharedLinksKeywordPositionModel());
  }, [dispatch]);

  const onSetSharedShowCompetitorsForPositionHistory = useCallback(
    (showCompetitors: number[], keywordAnalyticId: number) => {
      dispatch(
        setSharedShowCompetitorsForPositionHistory(
          showCompetitors,
          keywordAnalyticId
        )
      );
    },
    [dispatch]
  );

  const onSetDeleteElementsSharedLinksPageTable = useCallback(
    (deleteElements: GridRowSelectionModel) => {
      dispatch(setDeleteElementsSharedLinksPageTable(deleteElements));
    },
    [dispatch]
  );

  const onSetIsPasswordUpdated = useCallback(
    (value: boolean) => {
      dispatch(setIsPasswordUpdated(value));
    },
    [dispatch]
  );

  const onSelectSharedKeywordTags = useCallback(
    (tags: AccountTag[]) => {
      dispatch(selectSharedKeywordTags(tags));
    },
    [dispatch]
  );

  return {
    onGetSharedLinks,
    onSearchAllSharedLinks,
    onSetColumnVisibilityModel,
    onResetColumnVisibilityModel,
    onSetPaginationPageSize,
    onSetPaginationPage,
    onSetRowSelectionModelSharedLinks,
    onCreateSharedLink,
    onBulkDeleteSharedLinks,
    onGetShared,
    onSetPaginationPageSizeForSharedLinkContentsListTable,
    onSetPaginationPageForSharedLinkContentsListTable,
    onSetColumnVisibilityModelForSharedLinkContentsListTable,
    onResetColumnVisibilityModelForSharedLinkContentsListTable,
    onSearchSharedLinkContentsList,
    onLoginThunk,
    onGetSharedProject,
    onSetProjectSharedDeviceType,
    onSetRangeDateForProject,
    onGetSharedProjectOverview,
    onSetIsShowImprovedVsDeclined,
    onImprovedVsDeclinedVisibilityModel,
    onSetActivePeriodImprovedVsDeclined,
    onGetSharedProjectImprovedVsDeclined,
    onSetKeywordTrendsVisibilityModel,
    onSetActivePeriodKeywordTrends,
    onGetSharedProjectKeywordTrends,
    onGetSharedProjectKeywordRankings,
    onSetColumnVisibilityModelKeywordRankings,
    onResetColumnVisibilityModelKeywordRankings,
    onSetRowSelectionKeywordsModel,
    onSetSearchKeywordRankings,
    onSetSortModelKeywordRankings,
    onSetKeywordRankingsPaginationPageSize,
    onSetKeywordRankingsPaginationPage,
    onGetKeywordPositionHistory,
    onSetKeywordAnalyticPeriod,
    onCloseKeywordPositionHistory,
    onGetKeywordSearchResults,
    onGetSharedProjectPerformance,
    onSetActivePeriodSharedProjectPerformance,
    onSetProjectPerformanceIsShowNotes,
    onSetProjectPerformanceIsLinearGraph,
    onSetShowCompetitorsForGraph,
    onResetSharedLinksState,
    onSetSortModelSharedProjects,
    onGetSharedLinkFull,
    onEditSharedLink,
    ongGetSharedLinkSettings,
    onSetSharedLinksColumnKeywordPositionModel,
    onResetSharedLinksKeywordPositionModel,
    onSetSharedShowCompetitorsForPositionHistory,
    onSetDeleteElementsSharedLinksPageTable,
    onSetIsPasswordUpdated,
    onSelectSharedKeywordTags,
  };
}
