import { CustomDataGrid } from '../../../../../../../Team/components/customTableStyles';
import { styled } from '@mui/material/styles';

const CustomDataGridInvoice = styled(CustomDataGrid)`
  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell {
    padding: 0 16px;
  }
`;

export { CustomDataGridInvoice };
