import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

const StyledMainText = styled(Grid)`
  color: #475467;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const StyledContactUs = styled(Grid)`
  color: #126fe9;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
`;

export { StyledMainText, StyledContactUs };
