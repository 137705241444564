import { styled } from '@mui/material/styles';

const Header = styled('div')`
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  padding-bottom: 4px;
`;

const Content = styled('div')`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;

export { Header, Content };
