import React, { useEffect, useMemo, useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Box, Grid, Skeleton } from '@mui/material';

import { EllipsisMenu } from '../components/Folders/components/TableFolders/components';
import * as Tooltips from '../components/Folders/components/TableFolders/components/Header/components/Tooltips';
import InfoIcon from '../../../shared/images/InfoIcon';
import FolderIcon from '../../../shared/images/FolderIcon';

import {
  CustomTooltip,
  DateCustomTooltip,
} from '../../../shared/tooltip/styles';
import TextTooltip from '../../../shared/components/TextTooltip';
import useFolders from './useFolders';
import { findLongestString, getColumnWidth } from '../../../utils/table';
import { customSortComparator } from '../../emailReports/hooks/useScheduledEmailReportsTable';
import { useAppSelector } from '../../../store';
import { TableHeaderStyles } from '../../../shared/stylesTable';
import { convertToTimezone } from '../../../utils/date';
import { SERVER_UTS } from '../../../constants/config';
import EllipsisMenuForSystemFolder from '../components/Folders/components/TableFolders/components/EllipsisMenu/EllipsisMenuForSystemFolder/EllipsisMenuForSystemFolder';

export default function useRootFolderTable() {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const user = useAppSelector(state => state.auth.user);

  const { rootFolder } = useFolders();

  const [showCreatedFullFormat, setShowCreatedFullFormat] =
    useState<boolean>(false);
  const [showUpdatedFullFormat, setShowUpdatedFullFormat] =
    useState<boolean>(false);

  const popperProps = {
    sx: {
      '& .MuiTooltip-tooltip': {
        backgroundColor: '#fff',
        color: '#344054',
        boxShadow:
          '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
        border: '1px solid #EDEDED',
      },
      '.MuiTooltip-arrow::before': {
        backgroundColor: '#fff',
        border: '1px solid #EDEDED',
      },
    },
  };

  const [nameWidth, setNameWidth] = useState<number>(100);
  const [createdByWidth, setCreatedByWidth] = useState<number>(130);

  useEffect(() => {
    if (rootFolder?.items) {
      setNameWidth(
        getColumnWidth(
          findLongestString(rootFolder?.items?.map(item => item.name)) || ''
        )
      );
      setCreatedByWidth(
        getColumnWidth(
          findLongestString(rootFolder?.items?.map(item => item.createdBy)) ||
            ''
        )
      );
    }
  }, [rootFolder?.items]);

  const tableWidth = useMemo(
    () => ({
      name: rootFolder?.items
        ? getColumnWidth(
            findLongestString(rootFolder?.items?.map(item => item.name)) || ''
          )
        : nameWidth,
      createdBy: rootFolder?.items
        ? getColumnWidth(
            findLongestString(rootFolder?.items?.map(item => item.createdBy)) ||
              ''
          )
        : createdByWidth,
    }),
    [rootFolder, nameWidth, createdByWidth]
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      sortable: true,
      headerName: 'Name',
      maxWidth: 300,
      minWidth: 100,
      width: tableWidth.name + 20,
      sortComparator: customSortComparator,
      renderHeader: () => {
        return (
          <CustomTooltip title={<Tooltips.Name />} placement='top-start' arrow>
            <TableHeaderStyles>
              {'Name '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <CustomTooltip
            title={<TextTooltip text={params.row.name} />}
            placement='top-start'
            arrow
            PopperProps={popperProps}
          >
            <Grid display={'flex'} alignItems={'center'}>
              <FolderIcon />
              <Box display='flex' alignItems='center'>
                <Box marginLeft='8px'>{params.row.name}</Box>
              </Box>
            </Grid>
          </CustomTooltip>
        );
      },
    },
    {
      field: 'created',
      headerName: 'Created',
      sortable: true,
      maxWidth: 150,
      minWidth: 150,
      headerAlign: 'left',
      align: 'left',
      sortComparator: customSortComparator,
      renderHeader: () => {
        return (
          <CustomTooltip
            title={<Tooltips.CreatedAt />}
            placement='top-start'
            arrow
          >
            <TableHeaderStyles>
              {'Created '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        const date = showCreatedFullFormat
          ? convertToTimezone(
              params.row.createdAtFullFormat,
              SERVER_UTS,
              user.timezone.utc
            )
          : params.row.createdAt;
        return (
          <DateCustomTooltip
            title={<TextTooltip text={date} />}
            placement='top-start'
            arrow
            PopperProps={popperProps}
            onClick={e => {
              e.stopPropagation();
              setShowCreatedFullFormat(!showCreatedFullFormat);
            }}
          >
            {date}
          </DateCustomTooltip>
        );
      },
    },
    {
      field: 'createdBy',
      headerName: 'Created by',
      sortable: true,
      maxWidth: 300,
      minWidth: 130,
      width: tableWidth.createdBy,
      headerAlign: 'left',
      align: 'left',
      sortComparator: customSortComparator,
      renderHeader: () => {
        return (
          <CustomTooltip
            title={<Tooltips.CreatedBy />}
            placement='top-start'
            arrow
          >
            <TableHeaderStyles>
              {'Created by '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <CustomTooltip
            title={<TextTooltip text={params.row.createdBy} />}
            placement='top-start'
            arrow
            PopperProps={popperProps}
          >
            {params.row.createdBy}
          </CustomTooltip>
        );
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Last modified',
      sortable: true,
      maxWidth: 150,
      minWidth: 150,
      headerAlign: 'left',
      align: 'left',
      sortComparator: customSortComparator,
      renderHeader: () => {
        return (
          <CustomTooltip
            title={<Tooltips.Updated />}
            placement='top-start'
            arrow
          >
            <TableHeaderStyles>
              {'Last Modified '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        const date = showUpdatedFullFormat
          ? convertToTimezone(
              params.row.updatedAtFullFormat,
              SERVER_UTS,
              user.timezone.utc
            )
          : params.row.updatedAt;
        return (
          <DateCustomTooltip
            title={<TextTooltip text={date} />}
            placement='top-start'
            arrow
            PopperProps={popperProps}
            onClick={e => {
              e.stopPropagation();
              setShowUpdatedFullFormat(!showUpdatedFullFormat);
            }}
          >
            {date}
          </DateCustomTooltip>
        );
      },
    },
    {
      field: 'actions',
      headerName: '',
      cellClassName: 'actions',
      sortable: false,
      width: 50,
      align: 'center',
      renderCell: (params: GridRenderCellParams) => {
        if (currentAccount?.role?.name !== 'ViewOnly') {
          if (params.row.name === 'Uncategorized Projects') {
            return <EllipsisMenuForSystemFolder folder={params.row} />;
          } else {
            return (
              <div>
                <EllipsisMenu folder={params.row} />
              </div>
            );
          }
        } else return null;
      },
    },
  ];

  const columnsSkeleton: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        sortable: true,
        headerName: 'Name',
        maxWidth: 300,
        minWidth: 100,
        width: tableWidth.name + 20,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'created',
        headerName: 'Created',
        sortable: true,
        maxWidth: 150,
        minWidth: 150,
        headerAlign: 'left',
        align: 'left',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'createdBy',
        headerName: 'Created by',
        sortable: true,
        maxWidth: 300,
        minWidth: 130,
        width: tableWidth.createdBy,
        headerAlign: 'left',
        align: 'left',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'updatedAt',
        headerName: 'Last modified',
        sortable: true,
        maxWidth: 150,
        minWidth: 150,
        headerAlign: 'left',
        align: 'left',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 50,
        align: 'center',
        renderCell: () => <Skeleton width='100%' />,
      },
    ],
    [tableWidth]
  );

  return {
    columns,
    columnsSkeleton,
  };
}
