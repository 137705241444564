import React from 'react';

const UpgradeMessageIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='180'
    height='200'
    viewBox='0 0 180 200'
    fill='none'
  >
    <g clipPath='url(#clip0_213_30234)'>
      <path
        d='M115.583 148.067C116.683 148.627 118.012 148.216 118.581 147.17C118.885 146.609 118.923 145.937 118.695 145.339C118.733 145.115 118.771 144.965 118.809 144.741C121.2 142.537 124.996 142.649 127.235 145.04C128.336 146.198 128.905 147.768 128.829 149.337C128.563 155.913 128.867 163.049 124.426 168.354C144.733 152.027 153.994 124.192 147.807 99.1594C144.771 94.9748 143.328 89.4452 144.695 84.4013C146.403 86.0453 149.743 85.5969 149.249 82.7574C148.984 81.6365 148.68 80.5157 148.414 79.3948C160.105 74.9113 163.179 92.0232 149.325 99.01C150.464 103.755 151.071 108.649 151.147 113.506C152.703 109.098 155.322 105.137 158.738 101.887C162.078 98.9352 166.443 97.2166 170.543 95.2364C175.515 92.8452 181.018 97.5902 179.083 102.709C175.781 102.298 173.427 106.183 176.426 108.276C171.15 116.981 161.054 122.548 150.768 122.623C149.743 132.561 146.555 142.201 141.43 150.831C147.997 141.005 160.712 143.845 170.732 143.434C172.63 143.209 174.376 144.517 174.604 146.385C174.718 147.245 174.49 148.104 173.997 148.776C172.668 148.702 171.34 148.739 170.049 148.926C168.417 149.15 167.278 150.682 167.506 152.288C167.62 153.185 168.151 153.932 168.949 154.418C160.143 162.376 146.479 164.058 135.775 159.014C132.625 162.937 129.019 166.523 125.109 169.737L109.168 162.264C109.206 162.077 109.244 161.853 109.282 161.666C110.724 162.451 112.242 163.049 113.799 163.46C112.129 157.071 110.231 153.335 115.583 148.067Z'
        fill='#F2F2F2'
      />
      <path
        d='M87.4572 132.972C86.6601 133.906 85.2557 134.018 84.3448 133.271C83.8514 132.86 83.5477 132.262 83.5477 131.59C83.4339 131.403 83.32 131.254 83.2061 131.067C80.1317 129.908 76.6777 131.44 75.5011 134.467C74.9317 135.961 74.9697 137.605 75.6909 139.062C78.4616 145.078 80.8908 151.765 87.0397 155.053C61.9508 147.469 42.7072 125.126 38.8736 99.6451C40.0882 94.6386 39.2912 88.9969 36.1029 84.8496C35.154 87.0167 31.8898 87.8386 31.2825 84.9991C31.0927 83.8409 30.9409 82.72 30.7511 81.5618C18.1877 81.7486 21.9074 98.7484 37.4313 100.056C38.2284 104.876 39.5189 109.621 41.3028 114.179C38.1525 110.667 34.243 107.977 29.8022 106.258C25.5891 104.764 20.8826 104.801 16.3278 104.465C10.7863 104.091 7.52208 110.555 11.2797 114.553C14.2023 112.946 17.8461 115.673 15.8724 118.737C24.1088 124.865 35.6094 126.247 45.1364 122.511C49.9188 131.328 56.5611 139.062 64.6077 145.19C54.7772 138.539 44.0736 145.862 34.6226 149.187C32.7627 149.673 31.662 151.541 32.1934 153.372C32.4211 154.194 32.9525 154.904 33.6737 155.352C34.8503 154.792 36.1029 154.343 37.3934 154.044C39.0255 153.671 40.6576 154.642 41.0371 156.211C41.2649 157.071 41.0751 158.005 40.5057 158.715C51.7027 162.787 64.9873 159.313 72.996 150.645C77.4368 153.11 82.1054 155.091 86.9638 156.622L98.844 143.807C98.7301 143.62 98.6162 143.471 98.5403 143.284C97.5155 144.554 96.3389 145.675 95.0484 146.647C94.0995 140.071 94.4411 135.886 87.4572 132.972Z'
        fill='#F2F2F2'
      />
      <path
        d='M90.4935 78.7595C91.7081 78.6474 92.619 77.5639 92.4672 76.3683C92.3913 75.7332 92.0497 75.1354 91.5183 74.7991C91.4424 74.5749 91.3665 74.4255 91.2905 74.2387C92.0497 71.0629 95.238 69.1201 98.4642 69.8299C100.058 70.2036 101.387 71.175 102.222 72.52C105.714 78.1244 110.041 83.8781 109.358 90.7154C116.949 66.019 108.902 37.8105 89.5446 20.4371C84.6483 18.6437 80.3213 14.8327 78.6133 9.90093C80.9665 10.3119 83.4716 8.10754 81.46 6.05262C80.587 5.26801 79.714 4.4834 78.879 3.6988C86.0526 -6.46373 98.2744 6.08998 90.7212 19.4657C94.365 22.7909 97.6292 26.4898 100.476 30.4875C99.2613 25.9667 99.1854 21.259 100.21 16.7009C101.311 12.4416 103.968 8.59325 106.245 4.70758C108.978 -6.23292e-05 116.266 0.933994 117.556 6.23943C114.558 7.69656 114.823 12.2174 118.505 12.3295C119.074 22.4546 113.798 32.6172 105.334 38.3336C110.117 47.1511 112.925 56.9026 113.571 66.8783C113.457 55.1092 125.603 50.4763 133.687 44.6478C135.13 43.4148 137.331 43.5643 138.584 44.9841C139.153 45.6192 139.457 46.4412 139.419 47.3005C138.28 47.973 137.217 48.7203 136.23 49.617C135.016 50.7378 134.902 52.6059 136.041 53.8015C136.648 54.4741 137.521 54.8103 138.432 54.773C135.623 66.2058 125.261 75.098 113.495 76.7793C113.077 81.7859 112.166 86.7177 110.724 91.5374L93.2643 94.078C93.1883 93.8912 93.0745 93.7044 92.9986 93.5176C94.6307 93.3681 96.2248 93.0692 97.781 92.5462C92.7329 88.1374 89.0512 86.0825 90.4935 78.7595Z'
        fill='#F2F2F2'
      />
      <path
        d='M108.067 73.2674C100.134 73.2674 95.8071 72.2959 94.2889 70.2784C92.9604 68.485 93.8334 66.0938 94.8962 63.3664C95.6553 61.3862 96.4903 59.1444 96.4903 56.9401C96.4903 46.1424 102.032 40.6875 113.001 40.6875C118.467 40.6875 124.312 43.527 128.639 48.3094C132.586 52.6434 134.56 57.8368 134.029 62.5444C133.611 66.4301 131.789 68.9707 128.259 70.5399L128.07 70.6146L127.994 70.3905C127.69 69.2696 127.197 68.2235 126.475 67.3268C126.4 68.7839 126.02 70.2037 125.375 71.5113L125.337 71.5861L125.261 71.6234C121.313 72.7443 116.607 73.0432 112.925 73.1553C111.217 73.23 109.585 73.2674 108.067 73.2674Z'
        fill='#2F2E41'
      />
      <path
        d='M110.23 88.287C109.168 85.2233 105.789 83.5794 102.677 84.6255C102.07 84.8497 101.463 85.1486 100.969 85.5222L74.8175 100.915L66.657 81.2629C68.4788 78.6475 67.7956 75.0981 65.1387 73.3047C62.4818 71.5114 58.876 72.1839 57.0541 74.7992C55.2322 77.4146 55.9154 80.964 58.5723 82.7574C59.0658 83.0936 59.6351 83.3552 60.2424 83.542C60.7358 89.2211 63.5446 114.515 73.2992 113.619C81.4977 112.834 101.842 99.4957 108.143 94.9001C110.23 93.3309 111.065 90.6782 110.23 88.287Z'
        fill='#FFB6B6'
      />
      <path
        d='M129.93 200H107.194L103.398 181.431L102.146 200H81.0044C81.0044 185.429 81.8015 170.857 83.3197 156.361L83.5854 155.502L84.0029 154.082L84.2686 153.185L88.6335 138.39L88.9751 137.231H115.317C116.835 138.614 118.239 140.146 119.492 141.79C119.909 142.313 120.365 142.911 120.82 143.546C121.124 143.994 121.465 144.442 121.807 144.965C124.73 149.262 126.931 154.007 128.297 159.014C128.525 159.873 128.715 160.732 128.905 161.629C129.057 162.414 129.208 163.236 129.322 164.058C129.398 164.655 129.474 165.253 129.512 165.888C129.816 169.288 129.702 172.763 129.17 176.126L129.93 200Z'
        fill='#2F2E41'
      />
      <path
        d='M9.30964 30.2482C10.7954 29.6821 11.6747 28.3964 11.2736 27.3764C10.8726 26.3565 9.34298 25.9886 7.85721 26.5548C6.37145 27.1209 5.49213 28.4066 5.8932 29.4266C6.29428 30.4465 7.82387 30.8144 9.30964 30.2482Z'
        fill='#E6E6E6'
      />
      <path
        d='M27.9068 27.8951C29.3926 27.3289 30.2719 26.0432 29.8708 25.0233C29.4697 24.0034 27.9402 23.6355 26.4544 24.2016C24.9686 24.7677 24.0893 26.0535 24.4904 27.0734C24.8914 28.0933 26.421 28.4612 27.9068 27.8951Z'
        fill='#3F3D56'
      />
      <path
        d='M7.71296 45.8597C9.19872 45.2935 10.078 44.0078 9.67697 42.9879C9.27589 41.968 7.7463 41.6001 6.26054 42.1662C4.77477 42.7323 3.89545 44.0181 4.29652 45.038C4.6976 46.0579 6.22719 46.4258 7.71296 45.8597Z'
        fill='#FF6584'
      />
      <path
        d='M2.32071 52.7448C2.99386 52.588 3.3517 51.6795 3.11998 50.7157C2.88825 49.7519 2.1547 49.0977 1.48155 49.2545C0.808394 49.4113 0.450548 50.3198 0.682275 51.2836C0.914003 52.2474 1.64755 52.9016 2.32071 52.7448Z'
        fill='#E6E6E6'
      />
      <path
        d='M2.2045 64.4026C2.87765 64.2458 3.23549 63.3374 3.00377 62.3735C2.77204 61.4097 2.03849 60.7555 1.36534 60.9123C0.692183 61.0692 0.334337 61.9776 0.566064 62.9414C0.797792 63.9053 1.53134 64.5595 2.2045 64.4026Z'
        fill='#3F3D56'
      />
      <path
        d='M18.5321 40.2675C19.2053 40.1107 19.5631 39.2022 19.3314 38.2384C19.0997 37.2746 18.3661 36.6204 17.693 36.7772C17.0198 36.934 16.662 37.8425 16.8937 38.8063C17.1254 39.7701 17.859 40.4243 18.5321 40.2675Z'
        fill='#E6E6E6'
      />
      <path
        d='M8.73672 37.913C9.40987 37.7562 9.76772 36.8477 9.53599 35.8839C9.30427 34.9201 8.57072 34.2659 7.89756 34.4227C7.22441 34.5795 6.86656 35.488 7.09829 36.4518C7.33002 37.4156 8.06357 38.0698 8.73672 37.913Z'
        fill='#FF6584'
      />
      <path
        d='M19.141 27.1861C19.8142 27.0293 20.172 26.1208 19.9403 25.157C19.7086 24.1932 18.975 23.539 18.3019 23.6958C17.6287 23.8526 17.2709 24.7611 17.5026 25.7249C17.7343 26.6887 18.4679 27.3429 19.141 27.1861Z'
        fill='#3F3D56'
      />
      <path
        d='M23.9575 107.926C24.6777 107.093 24.278 105.594 23.0648 104.577C21.8517 103.561 20.2844 103.412 19.5642 104.245C18.8441 105.077 19.2437 106.576 20.4569 107.593C21.6701 108.61 23.2374 108.758 23.9575 107.926Z'
        fill='#E6E6E6'
      />
      <path
        d='M13.0176 92.9262C13.7377 92.0934 13.3381 90.5942 12.1249 89.5777C10.9117 88.5611 9.34443 88.4122 8.62427 89.245C7.90412 90.0778 8.30379 91.577 9.51697 92.5935C10.7302 93.61 12.2974 93.759 13.0176 92.9262Z'
        fill='#3F3D56'
      />
      <path
        d='M38.6743 101.873C39.3945 101.041 38.9948 99.5413 37.7816 98.5248C36.5684 97.5083 35.0012 97.3593 34.281 98.1922C33.5609 99.025 33.9605 100.524 35.1737 101.541C36.3869 102.557 37.9542 102.706 38.6743 101.873Z'
        fill='#FF6584'
      />
      <path
        d='M45.9781 102.036C46.7289 101.376 46.964 100.43 46.5034 99.9217C46.0427 99.4137 45.0607 99.5365 44.31 100.196C43.5593 100.856 43.3241 101.802 43.7848 102.31C44.2454 102.818 45.2274 102.695 45.9781 102.036Z'
        fill='#E6E6E6'
      />
      <path
        d='M56.4562 96.5786C57.2069 95.919 57.442 94.9725 56.9814 94.4645C56.5208 93.9564 55.5387 94.0792 54.788 94.7388C54.0373 95.3983 53.8021 96.3448 54.2628 96.8529C54.7234 97.3609 55.7054 97.2381 56.4562 96.5786Z'
        fill='#3F3D56'
      />
      <path
        d='M27.1178 93.9491C27.8685 93.2895 28.1037 92.343 27.643 91.8349C27.1824 91.3269 26.2004 91.4497 25.4496 92.1092C24.6989 92.7688 24.4638 93.7153 24.9244 94.2234C25.385 94.7314 26.3671 94.6086 27.1178 93.9491Z'
        fill='#E6E6E6'
      />
      <path
        d='M29.6769 103.516C30.4276 102.857 30.6627 101.91 30.2021 101.402C29.7415 100.894 28.7594 101.017 28.0087 101.676C27.258 102.336 27.0228 103.282 27.4835 103.79C27.9441 104.298 28.9261 104.176 29.6769 103.516Z'
        fill='#FF6584'
      />
      <path
        d='M15.1549 99.6596C15.9056 99.0001 16.1408 98.0536 15.6801 97.5455C15.2195 97.0375 14.2375 97.1603 13.4867 97.8198C12.736 98.4794 12.5009 99.4259 12.9615 99.9339C13.4222 100.442 14.4042 100.319 15.1549 99.6596Z'
        fill='#3F3D56'
      />
      <path
        d='M97.5349 11.8253C98.5319 11.3427 98.7702 9.81058 98.0671 8.40325C97.3641 6.99592 95.9859 6.2463 94.9889 6.72892C93.9918 7.21154 93.7535 8.74365 94.4566 10.151C95.1596 11.5583 96.5378 12.3079 97.5349 11.8253Z'
        fill='#E6E6E6'
      />
      <path
        d='M101.563 29.8635C102.56 29.3809 102.798 27.8488 102.095 26.4415C101.392 25.0341 100.014 24.2845 99.0167 24.7671C98.0197 25.2498 97.7814 26.7819 98.4844 28.1892C99.1875 29.5965 100.566 30.3461 101.563 29.8635Z'
        fill='#3F3D56'
      />
      <path
        d='M81.6037 11.6322C82.6007 11.1496 82.839 9.61747 82.136 8.21014C81.4329 6.80281 80.0547 6.05319 79.0577 6.53581C78.0607 7.01843 77.8224 8.55053 78.5254 9.95786C79.2285 11.3652 80.6067 12.1148 81.6037 11.6322Z'
        fill='#FF6584'
      />
      <path
        d='M73.2037 5.88136C74.1588 5.56966 74.7566 4.79301 74.5389 4.14666C74.3212 3.50031 73.3705 3.22903 72.4154 3.54072C71.4603 3.85242 70.8625 4.62907 71.0802 5.27542C71.2979 5.92177 72.2486 6.19306 73.2037 5.88136Z'
        fill='#E6E6E6'
      />
      <path
        d='M61.3932 6.77565C62.3482 6.46395 62.946 5.6873 62.7283 5.04095C62.5106 4.3946 61.5599 4.12331 60.6048 4.43501C59.6497 4.74671 59.052 5.52336 59.2697 6.16971C59.4874 6.81606 60.4381 7.08735 61.3932 6.77565Z'
        fill='#3F3D56'
      />
      <path
        d='M87.2818 20.6729C88.2369 20.3612 88.8347 19.5845 88.617 18.9382C88.3993 18.2918 87.4486 18.0205 86.4935 18.3322C85.5384 18.6439 84.9406 19.4206 85.1583 20.0669C85.376 20.7133 86.3268 20.9846 87.2818 20.6729Z'
        fill='#E6E6E6'
      />
      <path
        d='M88.795 10.8452C89.7501 10.5335 90.3479 9.75688 90.1302 9.11053C89.9125 8.46418 88.9618 8.19289 88.0067 8.50459C87.0516 8.81629 86.4538 9.59294 86.6715 10.2393C86.8892 10.8856 87.8399 11.1569 88.795 10.8452Z'
        fill='#FF6584'
      />
      <path
        d='M100.57 20.1141C101.525 19.8025 102.123 19.0258 101.905 18.3795C101.687 17.7331 100.737 17.4618 99.7816 17.7735C98.8265 18.0852 98.2287 18.8619 98.4464 19.5082C98.6641 20.1546 99.6149 20.4258 100.57 20.1141Z'
        fill='#3F3D56'
      />
      <path
        d='M104.234 76.2563L116.759 75.6211L122.566 84.6628C122.566 84.6628 133.536 86.4561 132.776 99.1593C132.017 111.862 119.454 122.996 119.454 122.996L119.985 129.61L119.036 132.225L119.15 133.682L120.441 135.251L120.555 136.858L119.416 139.398L120.934 141.827L88.7097 138.763L87.5331 134.541L89.2411 132.262L88.2922 129.311L89.7345 126.471L90.4936 117.429C90.4936 117.429 82.2572 105.249 91.7082 96.0582L95.5038 89.7067L99.7549 84.588L104.234 76.2563Z'
        fill='#126FE9'
      />
      <path
        d='M110.458 73.7157C117.795 73.7157 123.743 67.861 123.743 60.6389C123.743 53.4168 117.795 47.5621 110.458 47.5621C103.122 47.5621 97.1738 53.4168 97.1738 60.6389C97.1738 67.861 103.122 73.7157 110.458 73.7157Z'
        fill='#FFB6B6'
      />
      <path
        d='M108.674 59.8169C107.535 59.8169 106.397 59.7795 105.258 59.7048H105.068L105.106 59.518C105.258 58.285 105.182 57.0147 104.841 55.7817C104.233 57.1641 103.36 58.3971 102.222 59.4059L102.146 59.4806H102.07C98.9574 59.1443 95.8829 58.5839 92.8465 57.8367L92.7326 57.7993L92.6946 57.6872C92.4669 56.6037 92.353 55.4828 92.353 54.362C92.353 50.8873 93.6435 47.9357 95.9588 46.1049C98.5019 44.0874 102.108 43.4896 106.207 44.3863H119.188C122.148 44.3863 124.578 46.7774 124.578 49.6917V57.7993L124.426 57.8367C119.264 59.1443 113.988 59.8169 108.674 59.8169Z'
        fill='#2F2E41'
      />
      <path
        d='M18.0738 45.283C16.8592 45.7314 16.2519 47.039 16.6694 48.2346L30.6752 86.1199C31.1306 87.3155 32.4591 87.9133 33.6737 87.5023L88.1024 67.9619C89.317 67.5135 89.9243 66.2059 89.5068 65.0103L75.5011 27.1623C75.0456 25.9667 73.7171 25.3689 72.5026 25.7799L18.0738 45.283Z'
        fill='#CCCCCC'
      />
      <path
        d='M67.8715 73.1552L34.5462 85.1111C33.2557 85.5595 31.8134 84.9243 31.32 83.6166L18.6048 49.2807C18.1493 48.0104 18.7945 46.5907 20.123 46.105L71.4773 27.6854C72.7678 27.237 74.2101 27.8722 74.7036 29.1799L80.8524 45.7687C84.9137 56.8279 79.1065 69.0827 67.8715 73.1552Z'
        fill='white'
      />
      <path
        d='M148.073 65.1971L134.029 98.3C132.776 101.214 129.398 102.597 126.4 101.438C123.401 100.243 121.921 96.8803 123.136 93.8913C123.174 93.7792 123.211 93.7045 123.249 93.5924L138.925 59.6675L147.579 38.6326C145.568 36.6524 145.568 33.4766 147.579 31.4964C149.591 29.5162 152.817 29.5162 154.829 31.4964C156.84 33.4766 156.84 36.6524 154.829 38.6326C154.601 38.8567 154.373 39.0435 154.146 39.193L148.073 65.1971Z'
        fill='#FFB6B6'
      />
      <path
        d='M51.7025 72.8189C60.926 72.8189 68.4031 65.4588 68.4031 56.3796C68.4031 47.3003 60.926 39.9402 51.7025 39.9402C42.4791 39.9402 35.002 47.3003 35.002 56.3796C35.002 65.4588 42.4791 72.8189 51.7025 72.8189Z'
        fill='#126FE9'
      />
      <path
        d='M52.5388 56.4276L50.9087 56.7845L52.9852 65.9748L54.6154 65.6179L52.5388 56.4276Z'
        fill='white'
      />
      <path
        d='M58.8383 59.8168L52.196 58.5839L46.6924 62.4696L49.577 46.9642L58.8383 59.8168Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_213_30234'>
        <rect
          width='179'
          height='200'
          fill='white'
          transform='translate(0.5)'
        />
      </clipPath>
    </defs>
  </svg>
);

export default UpgradeMessageIcon;
