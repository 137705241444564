import { styled } from '@mui/material/styles';
import { Paper } from '../../../../../../shared/paper/styles';
import { Grid, Box, Typography } from '@mui/material';

const ModalWrapper = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 560px;

  outline: none;
  overflow-y: auto;
  max-height: calc(100vh - 50px);
  -ms-overflow-style: none;

  ${props => props.theme.breakpoints.down('sm')} {
    transform: translate(calc(-50% - 24px), -50%);
    width: calc(100% - 48px);
    margin-right: 24px;
    margin-left: 24px;
  }
`;

const ModalDynamicWidthWrapper = styled(ModalWrapper)`
  width: 85%;

  .MuiCheckbox-root {
    padding: 0;
  }

  .MuiDataGrid-checkboxInput {
    margin-left: 24px;
  }
`;

const ModalContainer = styled(Grid)`
  padding: 24px;
  ${props => props.theme.breakpoints.down('sm')} {
    padding: 16px;
  }
`;

const SearchEngine = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 6px;
`;

const SearchEngineContainer = styled(Box)(
  ({ isSelected }: { isSelected: boolean }) => ({
    padding: '4px 16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '20px',
    border: isSelected ? '2px solid #126FE9' : '1px solid #DEDEDE',
    boxShadow:
      '0 5px 3px -2px rgba(0, 0, 0, 0.02), 0px 3px 1px -2px rgba(0, 0, 0, 0.06)',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '24px',
    cursor: 'pointer',

    '.MuiTypography-root': {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '24px',
      color: isSelected ? '#126FE9' : '#4D4D4D',
    },

    svg: {
      minWidth: '19px',
    },
  })
);

const DeviceTypeContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  width: fit-content;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
`;

const DeviceType = styled(Box)(({ isSelected }: { isSelected: boolean }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '7.5px 16px',
  gap: '8px',
  color: isSelected ? '#1D2939' : '#344054',
  borderRadius: '8px 0 0 8px',
  borderRight: '1px solid #D0D5DD',
  cursor: 'pointer',
  backgroundColor: isSelected ? '#EAECF0' : 'initial',

  span: {
    fontSize: '14px',
    fontWeight: '500',
  },

  ':nth-of-type(2)': {
    borderRadius: '0',
  },

  ':last-of-type': {
    borderRight: 'none',
    borderRadius: '0 8px 8px 0',
  },

  '@media (max-width:600px)': {
    padding: '6.5px 12px',
  },
}));

const AddedTag = styled(Box)`
  display: flex;
  padding: 2px 1px 2px 8px;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  align-items: center;
  color: #344054;

  span {
    font-size: 12px;
    font-weight: 500;
  }

  svg {
    cursor: pointer;
    padding: 4px;
  }
`;

const AddTagsInput = styled(Box)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 6.5px 14px;
  border: 1px solid #d0d5dd;
  align-items: center;
  border-radius: 8px;
`;

const AddTagsContainer = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
  color: #667085;
  cursor: pointer;

  span {
    font-size: 14px;
  }
`;

const AddTagsPopover = styled(Grid)`
  display: flex;
  flex-direction: column;
  padding: 12px 0 16px 0;
  max-width: calc(560px - 48px);
  max-height: 200px;
  overflow: auto;
`;

const AddTagWithActions = styled(Grid)`
  :hover {
    background-color: #f6f6f6;
    border-radius: 8px;
  }
`;

const HeaderItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 2px;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
`;

const ButtonTooltipContainer = styled(Grid)`
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProjectNameContainer = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export {
  ModalWrapper,
  ModalDynamicWidthWrapper,
  ModalContainer,
  SearchEngineContainer,
  SearchEngine,
  DeviceTypeContainer,
  DeviceType,
  AddTagsContainer,
  AddTagsPopover,
  AddTagWithActions,
  AddedTag,
  AddTagsInput,
  HeaderItem,
  ButtonTooltipContainer,
  ProjectNameContainer,
};
