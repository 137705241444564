import { Grid } from '@mui/material';
import React from 'react';
import { Table, Pagination } from './components';

interface AlertsTableProps {
  selectBy: 'by Project' | 'by Keywords';
}

/**
 * AlertsTable component.
 * Displays a table of alerts along with pagination controls.
 */
const AlertsTable: React.FC<AlertsTableProps> = ({ selectBy }) => {
  return (
    <Grid xs={12} paddingTop={'24px'}>
      <Table selectBy={selectBy} />
      <Pagination selectBy={selectBy} />
    </Grid>
  );
};

export default AlertsTable;
