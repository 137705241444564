import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

const StyledPaymentAndPlan = styled(Grid)`
  padding-top: 32px;
`;

const StyledItemContainer = styled(Grid)`
  border-radius: 8px;
  border: 1px solid #dedede;
  background: #fff;
  box-shadow: 0 5px 3px -2px rgba(0, 0, 0, 0.02),
    0px 3px 1px -2px rgba(0, 0, 0, 0.06);
  padding: 24px;
`;

export { StyledPaymentAndPlan, StyledItemContainer };
