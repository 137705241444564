import React from 'react';
import { Navigate, Outlet } from 'react-router';

import ROUTES from './constants';
import { useAppSelector } from '../store';

/**
 * PublicRoutes component that manages public access routes in the application.
 * It checks if the user is authenticated and either renders the child routes
 * or redirects to the projects page.
 *
 * @returns {JSX.Element} Renders child components if the user is not authenticated,
 * otherwise redirects to the projects page.
 */
const PublicRoutes = () => {
  // Selects the authentication state from the Redux store
  const isAuth = useAppSelector(state => state.auth.isAuth);

  return !isAuth ? (
    <div>
      <Outlet /> {/* Renders child routes for public access */}
    </div>
  ) : (
    <Navigate
      to={ROUTES.projects}
    /> /* Redirects to the projects page if authenticated */
  );
};

export default PublicRoutes;
