import React, { useMemo, useState } from 'react';

import {
  StyledHead,
  StyledItemLabel,
  StyledItemSubLabel,
  StyledItemSubValue,
  StyledItemValue,
  StyledLabel,
  StyledLinearProgress,
  StyledOverview,
  StyledViewChartButton,
} from './styles';

import { Popover } from '../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import { Box, Divider, Grid, Skeleton, useMediaQuery } from '@mui/material';
import ArrowCircleRight from '../../../../../../shared/images/ArrowCircleRight';
import { useSharedLinks } from '../../../../../../hooks';
import TooltipContainer from './TooltipContainer';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import * as Tooltips from '../Toltips';
import InfoIcon from '../../../../../../shared/images/InfoIcon';
import { CustomTooltip } from '../../../../../../shared/tooltip/styles';
import { normaliseLinearProgressOverview } from '../../../../../projects/components/ProjectExpanded/utils';
import { useAppSelector } from '../../../../../../store';

const popperProps = {
  sx: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: '#fff',
      color: '#344054',
      boxShadow:
        '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
      border: '1px solid #EDEDED',
    },
    '.MuiTooltip-arrow::before': {
      backgroundColor: '#fff',
      border: '1px solid #EDEDED',
    },
  },
};

const Overview = () => {
  const mediumScreen = useMediaQuery('(max-width:900px)');
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const [showTooltip, setShowTooltip] = useState(false);

  const [showLastUpdateFullFormat, setShowLastUpdateFullFormat] =
    useState<boolean>(false);

  const { onSetIsShowImprovedVsDeclined } = useSharedLinks();

  const isShowImprovedVsDeclined = useAppSelector(
    state => state.sharedLinks.projectGraphs.isShowImprovedVsDeclined
  );
  const overview = useAppSelector(
    state => state.sharedLinks.projectGraphs.overview
  );

  const overviewAvgPosColor = useMemo(() => {
    if (!overview?.avgPos) {
      return '#101828';
    }
    if (overview.avgPos.avgPos > 100) {
      return '#101828';
    } else {
      return overview?.avgPos?.progress
        ? overview.avgPos.increasingAveragePosition
          ? '#4F9C20'
          : '#D92D20'
        : '#101828';
    }
  }, [overview?.avgPos]);

  const handleSetIsShowImprovedVsDeclined = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    event.stopPropagation();
    event.preventDefault();
    onSetIsShowImprovedVsDeclined(!isShowImprovedVsDeclined);
  };

  return (
    <StyledOverview container xs={12}>
      <Grid container item flexDirection='row' justifyContent={'space-between'}>
        <StyledHead item>Overview</StyledHead>
        <StyledLabel item>
          {`Last updated: `}
          {overview ? (
            <span
              style={{
                borderBottom: 'dashed 1px #d0d5dd',
                cursor: 'pointer',
              }}
              onClick={e => {
                e.stopPropagation();
                setShowLastUpdateFullFormat(!showLastUpdateFullFormat);
              }}
            >
              {`${
                showLastUpdateFullFormat
                  ? overview?.lastUpdateFullFormat
                  : overview?.lastUpdate
              }`}
            </span>
          ) : (
            <Skeleton
              content={'span'}
              width={'30px'}
              style={{ display: 'inline-block' }}
            />
          )}
        </StyledLabel>
      </Grid>
      <Grid item xs={12}>
        <Divider
          orientation='horizontal'
          flexItem
          sx={{
            margin: '10px 0 17px 0',
            color: '#EDEDED',
          }}
        />
      </Grid>
      {!extraSmallScreen ? (
        <Grid container item xs={12}>
          <Grid item xs={3.4} sx={{ cursor: 'pointer' }}>
            <CustomTooltip
              title={<TooltipContainer overview={overview} />}
              placement='top-start'
              arrow
              open={showTooltip}
              onMouseOver={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              PopperProps={popperProps}
            >
              <Grid container item sx={{ position: 'relative' }}>
                <Grid container item xs={6} justifyContent={'space-around'}>
                  <Grid item>
                    <CustomTooltip
                      title={<Tooltips.Improved />}
                      placement='top-start'
                      arrow
                    >
                      <strong>
                        <StyledItemLabel item>
                          Improved <InfoIcon />
                        </StyledItemLabel>
                      </strong>
                    </CustomTooltip>
                    <StyledItemValue $color={'#4F9C20'} item>
                      {overview ? overview.improved : <Skeleton width='15px' />}
                    </StyledItemValue>
                    <Grid
                      item
                      sx={{
                        position: 'absolute',
                        top: '60px',
                        width: mediumScreen ? '90%' : '79%',
                      }}
                    >
                      {overview ? (
                        <Box sx={{ display: 'flex' }}>
                          <Box
                            sx={{
                              width: `${normaliseLinearProgressOverview(
                                overview.improved,
                                overview.improved +
                                  overview.declined +
                                  overview.lost
                              )}%`,
                              mr: '2px',
                            }}
                          >
                            <StyledLinearProgress
                              $backgroundColor={'#8CD940'}
                              variant='determinate'
                              value={0}
                            />
                          </Box>
                          <Box
                            sx={{
                              width: `${normaliseLinearProgressOverview(
                                overview.declined,
                                overview.improved +
                                  overview.declined +
                                  overview.lost
                              )}%`,
                              mr: '2px',
                            }}
                          >
                            <StyledLinearProgress
                              $backgroundColor={'#FF9F9F'}
                              variant='determinate'
                              value={0}
                            />
                          </Box>
                          <Box
                            sx={{
                              width: `${normaliseLinearProgressOverview(
                                overview.lost,
                                overview.improved +
                                  overview.declined +
                                  overview.lost
                              )}%`,
                            }}
                          >
                            <StyledLinearProgress
                              $backgroundColor={'#D86868'}
                              variant='determinate'
                              value={0}
                            />
                          </Box>
                        </Box>
                      ) : (
                        <Skeleton width='100%' />
                      )}
                    </Grid>
                    {overview ? (
                      <StyledViewChartButton
                        container
                        item
                        alignItems={'center'}
                        flexWrap={'nowrap'}
                        onMouseOver={() =>
                          setTimeout(() => {
                            setShowTooltip(false);
                          }, 1)
                        }
                        onClick={handleSetIsShowImprovedVsDeclined}
                      >
                        <Grid item>
                          {!isShowImprovedVsDeclined
                            ? 'View chart'
                            : 'Hide chart'}
                        </Grid>
                        <ArrowCircleRight />
                      </StyledViewChartButton>
                    ) : null}
                  </Grid>
                  <Grid item>
                    <Divider
                      orientation='vertical'
                      flexItem
                      sx={{
                        height: '55%',
                        color: '#EDEDED',
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={6} justifyContent={'space-around'}>
                  <Grid item>
                    <CustomTooltip
                      title={<Tooltips.Declined />}
                      placement='top-start'
                      arrow
                    >
                      <strong>
                        <StyledItemLabel item>
                          Declined <InfoIcon />
                        </StyledItemLabel>
                      </strong>
                    </CustomTooltip>
                    <StyledItemValue $color={'#EC5962'} item>
                      {overview ? overview.declined : <Skeleton width='15px' />}
                    </StyledItemValue>
                  </Grid>
                  <Grid item>
                    <Divider
                      orientation='vertical'
                      flexItem
                      sx={{
                        height: '55%',
                        color: '#EDEDED',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CustomTooltip>
          </Grid>

          <Grid item xs={1.7}>
            <Grid container item justifyContent={'space-around'}>
              <Grid item>
                <Grid item>
                  <CustomTooltip
                    title={<Tooltips.Top3 />}
                    placement='top-start'
                    arrow
                  >
                    <strong>
                      <StyledItemLabel item>
                        Top 3 <InfoIcon />
                      </StyledItemLabel>
                    </strong>
                  </CustomTooltip>
                  <StyledItemValue $color={'#126FE9'} item>
                    {overview ? overview.top3.count : <Skeleton width='15px' />}
                  </StyledItemValue>
                </Grid>
                <Grid item>
                  <Grid item>
                    <StyledItemSubLabel>New:</StyledItemSubLabel>{' '}
                    <StyledItemSubValue $color={'#007C65'}>
                      {overview ? (
                        overview.top3.newCount
                      ) : (
                        <Skeleton width='15px' />
                      )}
                    </StyledItemSubValue>
                  </Grid>
                  <Grid item>
                    <StyledItemSubLabel>Lost:</StyledItemSubLabel>{' '}
                    <StyledItemSubValue $color={'#D92D20'}>
                      {overview ? (
                        overview.top3.lostCount
                      ) : (
                        <Skeleton width='15px' />
                      )}
                    </StyledItemSubValue>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{
                    height: '45%',
                    color: '#EDEDED',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={1.7}>
            <Grid container item justifyContent={'space-around'}>
              <Grid item>
                <Grid item>
                  <CustomTooltip
                    title={<Tooltips.Top10 />}
                    placement='top-start'
                    arrow
                  >
                    <strong>
                      <StyledItemLabel item>
                        Top 10 <InfoIcon />
                      </StyledItemLabel>
                    </strong>
                  </CustomTooltip>
                  <StyledItemValue $color={'#126FE9'} item>
                    {overview ? (
                      overview.top10.count
                    ) : (
                      <Skeleton width='15px' />
                    )}
                  </StyledItemValue>
                </Grid>
                <Grid item>
                  <Grid item>
                    <StyledItemSubLabel>New:</StyledItemSubLabel>{' '}
                    <StyledItemSubValue $color={'#007C65'}>
                      {overview ? (
                        overview.top10.newCount
                      ) : (
                        <Skeleton width='15px' />
                      )}
                    </StyledItemSubValue>
                  </Grid>
                  <Grid item>
                    <StyledItemSubLabel>Lost:</StyledItemSubLabel>{' '}
                    <StyledItemSubValue $color={'#D92D20'}>
                      {overview ? (
                        overview.top10.lostCount
                      ) : (
                        <Skeleton width='15px' />
                      )}
                    </StyledItemSubValue>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{
                    height: '45%',
                    color: '#EDEDED',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={1.7}>
            <Grid container item justifyContent={'space-around'}>
              <Grid item>
                <Grid item>
                  <CustomTooltip
                    title={<Tooltips.Top30 />}
                    placement='top-start'
                    arrow
                  >
                    <strong>
                      <StyledItemLabel item>
                        Top 30 <InfoIcon />
                      </StyledItemLabel>
                    </strong>
                  </CustomTooltip>
                  <StyledItemValue $color={'#126FE9'} item>
                    {overview ? (
                      overview.top30.count
                    ) : (
                      <Skeleton width='15px' />
                    )}
                  </StyledItemValue>
                </Grid>
                <Grid item>
                  <Grid item>
                    <StyledItemSubLabel>New:</StyledItemSubLabel>{' '}
                    <StyledItemSubValue $color={'#007C65'}>
                      {overview ? (
                        overview.top30.newCount
                      ) : (
                        <Skeleton width='15px' />
                      )}
                    </StyledItemSubValue>
                  </Grid>
                  <Grid item>
                    <StyledItemSubLabel>Lost:</StyledItemSubLabel>{' '}
                    <StyledItemSubValue $color={'#D92D20'}>
                      {overview ? (
                        overview.top30.lostCount
                      ) : (
                        <Skeleton width='15px' />
                      )}
                    </StyledItemSubValue>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{
                    height: '45%',
                    color: '#EDEDED',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={1.7}>
            <Grid container item justifyContent={'space-around'}>
              <Grid item>
                <Grid item>
                  <CustomTooltip
                    title={<Tooltips.Top100 />}
                    placement='top-start'
                    arrow
                  >
                    <strong>
                      <StyledItemLabel item>
                        Top 100 <InfoIcon />
                      </StyledItemLabel>
                    </strong>
                  </CustomTooltip>
                  <StyledItemValue $color={'#126FE9'} item>
                    {overview ? (
                      overview.top100.count
                    ) : (
                      <Skeleton width='15px' />
                    )}
                  </StyledItemValue>
                </Grid>
                <Grid item>
                  <Grid item>
                    <StyledItemSubLabel>New:</StyledItemSubLabel>{' '}
                    <StyledItemSubValue $color={'#007C65'}>
                      {overview ? (
                        overview.top100.newCount
                      ) : (
                        <Skeleton width='15px' />
                      )}
                    </StyledItemSubValue>
                  </Grid>
                  <Grid item>
                    <StyledItemSubLabel>Lost:</StyledItemSubLabel>{' '}
                    <StyledItemSubValue $color={'#D92D20'}>
                      {overview ? (
                        overview.top100.lostCount
                      ) : (
                        <Skeleton width='15px' />
                      )}
                    </StyledItemSubValue>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{
                    height: '45%',
                    color: '#EDEDED',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={1.7}>
            <Grid container item justifyContent={'space-around'}>
              <Grid item>
                <CustomTooltip
                  title={<Tooltips.AveragePosition />}
                  placement='top-start'
                  arrow
                >
                  <strong>
                    <StyledItemLabel item>
                      Avg. Pos <InfoIcon />
                    </StyledItemLabel>
                  </strong>
                </CustomTooltip>
                <StyledItemValue item $color={overviewAvgPosColor}>
                  {overview ? (
                    overview.avgPos.avgPos > 100 ? (
                      '> 100'
                    ) : (
                      overview.avgPos.avgPos
                    )
                  ) : (
                    <Skeleton width='15px' />
                  )}
                </StyledItemValue>
                {overview ? (
                  !(overview.avgPos.progress > 100) ? (
                    overview.avgPos.progress ? (
                      <>
                        {overview.avgPos.increasingAveragePosition ? (
                          <StyledItemSubValue $color={'#4F9C20'}>
                            {`-${overview.avgPos.progress}`}
                          </StyledItemSubValue>
                        ) : (
                          <StyledItemSubValue $color={'#D92D20'}>
                            {`+${overview.avgPos.progress}`}
                          </StyledItemSubValue>
                        )}
                      </>
                    ) : (
                      <StyledItemSubValue>
                        {overview.avgPos.progress}
                      </StyledItemSubValue>
                    )
                  ) : null
                ) : (
                  <Skeleton width='100%' />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <>
                <Grid container item xs={12}>
                  <PopupState variant='popover' popupId='demo-popup-popover'>
                    {popupState => (
                      <>
                        <Grid
                          item
                          xs={4}
                          gap={'8px'}
                          {...bindTrigger(popupState)}
                          onClick={event => {
                            event.stopPropagation();
                            event.preventDefault();
                            bindTrigger(popupState).onClick(event);
                          }}
                        >
                          <CustomTooltip
                            title={<Tooltips.Improved />}
                            placement='top-start'
                            arrow
                          >
                            <strong>
                              <StyledItemLabel item>
                                Improved <InfoIcon />
                              </StyledItemLabel>
                            </strong>
                          </CustomTooltip>
                          <Grid sx={{ pb: '4px', pt: '4px' }}>
                            <StyledItemValue $color={'#4F9C20'} item>
                              {overview ? (
                                overview.improved
                              ) : (
                                <Skeleton width='15px' />
                              )}
                            </StyledItemValue>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          gap={'8px'}
                          {...bindTrigger(popupState)}
                          onClick={event => {
                            event.stopPropagation();
                            event.preventDefault();
                            bindTrigger(popupState).onClick(event);
                          }}
                        >
                          <CustomTooltip
                            title={<Tooltips.Declined />}
                            placement='top-start'
                            arrow
                          >
                            <strong>
                              <StyledItemLabel item>
                                Declined <InfoIcon />
                              </StyledItemLabel>
                            </strong>
                          </CustomTooltip>
                          <Grid sx={{ pb: '4px', pt: '4px' }}>
                            <StyledItemValue $color={'#EC5962'} item>
                              {overview ? (
                                overview.declined
                              ) : (
                                <Skeleton width='15px' />
                              )}
                            </StyledItemValue>
                          </Grid>
                        </Grid>
                        <Popover
                          {...bindPopover(popupState)}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          <TooltipContainer overview={overview} />
                        </Popover>
                      </>
                    )}
                  </PopupState>

                  <Grid item xs={4} gap={'8px'}>
                    <CustomTooltip
                      title={<Tooltips.Top3 />}
                      placement='top-start'
                      arrow
                    >
                      <strong>
                        <StyledItemLabel item>
                          Top 3 <InfoIcon />
                        </StyledItemLabel>
                      </strong>
                    </CustomTooltip>
                    <Grid sx={{ pb: '4px', pt: '4px' }}>
                      <StyledItemValue $color={'#126FE9'} item>
                        {overview ? (
                          overview.top3.count
                        ) : (
                          <Skeleton width='15px' />
                        )}
                      </StyledItemValue>
                    </Grid>
                    <Grid item>
                      <Grid item>
                        <StyledItemSubLabel>New:</StyledItemSubLabel>{' '}
                        <StyledItemSubValue $color={'#007C65'}>
                          {overview ? (
                            overview.top3.newCount
                          ) : (
                            <Skeleton width='15px' />
                          )}
                        </StyledItemSubValue>
                      </Grid>
                      <Grid item>
                        <StyledItemSubLabel>Lost:</StyledItemSubLabel>{' '}
                        <StyledItemSubValue $color={'#D92D20'}>
                          {overview ? (
                            overview.top3.lostCount
                          ) : (
                            <Skeleton width='15px' />
                          )}
                        </StyledItemSubValue>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
              {overview ? (
                <Grid xs={7} item>
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '9px 0',
                    }}
                  >
                    <Box
                      sx={{
                        width: `${normaliseLinearProgressOverview(
                          overview.improved,
                          overview.improved + overview.declined + overview.lost
                        )}%`,
                        mr: '2px',
                      }}
                    >
                      <StyledLinearProgress
                        $backgroundColor={'#8CD940'}
                        variant='determinate'
                        value={0}
                      />
                    </Box>
                    <Box
                      sx={{
                        width: `${normaliseLinearProgressOverview(
                          overview.declined,
                          overview.improved + overview.declined + overview.lost
                        )}%`,
                        mr: '2px',
                      }}
                    >
                      <StyledLinearProgress
                        $backgroundColor={'#FF9F9F'}
                        variant='determinate'
                        value={0}
                      />
                    </Box>
                    <Box
                      sx={{
                        width: `${normaliseLinearProgressOverview(
                          overview.lost,
                          overview.improved + overview.declined + overview.lost
                        )}%`,
                      }}
                    >
                      <StyledLinearProgress
                        $backgroundColor={'#D86868'}
                        variant='determinate'
                        value={0}
                      />
                    </Box>
                  </Box>
                </Grid>
              ) : (
                <Skeleton width={'100px'} />
              )}
              {overview ? (
                <Grid
                  container
                  item
                  xs={4}
                  sx={{
                    cursor: 'pointer',
                    alignItems: 'center',
                  }}
                  onClick={handleSetIsShowImprovedVsDeclined}
                >
                  <Grid
                    item
                    sx={{
                      mr: '6px',
                      color: '#126fe9',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '24px',
                    }}
                  >
                    {!isShowImprovedVsDeclined ? 'View chart' : 'Hide chart'}
                  </Grid>
                  <ArrowCircleRight />
                </Grid>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Divider
                orientation='horizontal'
                flexItem
                sx={{
                  margin: '16px 0 16px 0',
                  color: '#EDEDED',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container item xs={12}>
                <Grid item xs={4} gap={'8px'}>
                  <CustomTooltip
                    title={<Tooltips.Top10 />}
                    placement='top-start'
                    arrow
                  >
                    <strong>
                      <StyledItemLabel item>
                        Top 10 <InfoIcon />
                      </StyledItemLabel>
                    </strong>
                  </CustomTooltip>
                  <Grid sx={{ pb: '4px', pt: '4px' }}>
                    <StyledItemValue $color={'#126FE9'} item>
                      {overview ? (
                        overview.top10.count
                      ) : (
                        <Skeleton width='15px' />
                      )}
                    </StyledItemValue>
                  </Grid>
                  <Grid item>
                    <Grid item>
                      <StyledItemSubLabel>New:</StyledItemSubLabel>{' '}
                      <StyledItemSubValue $color={'#007C65'}>
                        {overview ? (
                          overview.top10.newCount
                        ) : (
                          <Skeleton width='15px' />
                        )}
                      </StyledItemSubValue>
                    </Grid>
                    <Grid item>
                      <StyledItemSubLabel>Lost:</StyledItemSubLabel>{' '}
                      <StyledItemSubValue $color={'#D92D20'}>
                        {overview ? (
                          overview.top10.lostCount
                        ) : (
                          <Skeleton width='15px' />
                        )}
                      </StyledItemSubValue>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} gap={'8px'}>
                  <CustomTooltip
                    title={<Tooltips.Top30 />}
                    placement='top-start'
                    arrow
                  >
                    <strong>
                      <StyledItemLabel item>
                        Top 30 <InfoIcon />
                      </StyledItemLabel>
                    </strong>
                  </CustomTooltip>
                  <Grid sx={{ pb: '4px', pt: '4px' }}>
                    <StyledItemValue $color={'#126FE9'} item>
                      {overview ? (
                        overview.top30.count
                      ) : (
                        <Skeleton width='15px' />
                      )}
                    </StyledItemValue>
                  </Grid>
                  <Grid item>
                    <Grid item>
                      <StyledItemSubLabel>New:</StyledItemSubLabel>{' '}
                      <StyledItemSubValue $color={'#007C65'}>
                        {overview ? (
                          overview.top30.newCount
                        ) : (
                          <Skeleton width='15px' />
                        )}
                      </StyledItemSubValue>
                    </Grid>
                    <Grid item>
                      <StyledItemSubLabel>Lost:</StyledItemSubLabel>{' '}
                      <StyledItemSubValue $color={'#D92D20'}>
                        {overview ? (
                          overview.top30.lostCount
                        ) : (
                          <Skeleton width='15px' />
                        )}
                      </StyledItemSubValue>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} gap={'8px'}>
                  <CustomTooltip
                    title={<Tooltips.Top100 />}
                    placement='top-start'
                    arrow
                  >
                    <strong>
                      <StyledItemLabel item>
                        Top 100 <InfoIcon />
                      </StyledItemLabel>
                    </strong>
                  </CustomTooltip>
                  <Grid sx={{ pb: '4px', pt: '4px' }}>
                    <StyledItemValue $color={'#126FE9'} item>
                      {overview ? (
                        overview.top100.count
                      ) : (
                        <Skeleton width='15px' />
                      )}
                    </StyledItemValue>
                  </Grid>
                  <Grid item>
                    <Grid item>
                      <StyledItemSubLabel>New:</StyledItemSubLabel>{' '}
                      <StyledItemSubValue $color={'#007C65'}>
                        {overview ? (
                          overview.top10.newCount
                        ) : (
                          <Skeleton width='15px' />
                        )}
                      </StyledItemSubValue>
                    </Grid>
                    <Grid item>
                      <StyledItemSubLabel>Lost:</StyledItemSubLabel>{' '}
                      <StyledItemSubValue $color={'#D92D20'}>
                        {overview ? (
                          overview.top10.lostCount
                        ) : (
                          <Skeleton width='15px' />
                        )}
                      </StyledItemSubValue>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider
                orientation='horizontal'
                flexItem
                sx={{
                  margin: '16px 0 16px 0',
                  color: '#EDEDED',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container item xs={12}>
                <Grid item xs={4} gap={'8px'}>
                  <CustomTooltip
                    title={<Tooltips.AveragePosition />}
                    placement='top-start'
                    arrow
                  >
                    <strong>
                      <StyledItemLabel item>
                        Avg. Pos <InfoIcon />
                      </StyledItemLabel>
                    </strong>
                  </CustomTooltip>
                  <Grid sx={{ pb: '4px', pt: '4px' }}>
                    <StyledItemValue item $color={overviewAvgPosColor}>
                      {overview ? (
                        overview.avgPos.avgPos > 100 ? (
                          '> 100'
                        ) : (
                          overview.avgPos.avgPos
                        )
                      ) : (
                        <Skeleton width='15px' />
                      )}
                    </StyledItemValue>
                  </Grid>
                  {overview ? (
                    !(overview.avgPos.progress > 100) ? (
                      overview.avgPos.progress ? (
                        <>
                          {overview.avgPos.increasingAveragePosition ? (
                            <StyledItemSubValue $color={'#4F9C20'}>
                              {`-${overview.avgPos.progress}`}
                            </StyledItemSubValue>
                          ) : (
                            <StyledItemSubValue $color={'#D92D20'}>
                              {`+${overview.avgPos.progress}`}
                            </StyledItemSubValue>
                          )}
                        </>
                      ) : (
                        <StyledItemSubValue>
                          {overview.avgPos.progress}
                        </StyledItemSubValue>
                      )
                    ) : null
                  ) : (
                    <Skeleton width='15px' />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </StyledOverview>
  );
};

export default Overview;
