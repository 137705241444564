import { styled } from '@mui/system';
import { Box, Divider, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';

const Wrapper = styled(Box)`
  width: 100%;
  min-height: calc(88vh - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;

  ${props => props.theme.breakpoints.down('sm')} {
    min-height: calc(80vh - 40px);
  }
`;

const SingUpLabel = styled(Typography)`
  font-weight: bold;
`;

const LogoContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
`;

const PasswordTitleContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ForgotPasswordLink = styled(Link)`
  text-decoration: none;
  color: #126fe9;
  font-weight: 500;
  position: relative;
  bottom: 4px;
`;

const CustomFormControlLabel = styled(FormControlLabel)`
  .MuiTypography-root {
    font-size: 14px;
  }
`;

const DividerForGoogleAuth = styled(Divider)`
  color: #475467;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  width: 100%;
  ::before,
  ::after {
    border-top: thin solid #eaecf0;
  }
`;

export {
  Wrapper,
  SingUpLabel,
  LogoContainer,
  PasswordTitleContainer,
  ForgotPasswordLink,
  CustomFormControlLabel,
  DividerForGoogleAuth,
};
