import React from 'react';

import {
  Header,
  Content,
} from '../../Projects/components/TableProjects/components/Header/components/Tooltips/styles';

const Improved = () => (
  <div>
    <Header>Improved</Header>
    <Content>
      The number of keywords for which your search rank has increased compared
      to the previous period.
    </Content>
  </div>
);

const Declined = () => (
  <div>
    <Header>Declined</Header>
    <Content>
      The number of keywords for which your search rank has decreased compared
      to the previous period.
    </Content>
  </div>
);

const Top3 = () => (
  <div>
    <Header>Top 3</Header>
    <Content>
      The number of keywords from your list for which your website is ranked in
      the top 3 search results. &apos;New&apos; represents the number of
      keywords that have entered the top 3 since the last period, and
      &apos;Lost&apos; represents the keywords that have dropped out of the top
      3.
    </Content>
  </div>
);

const Top10 = () => (
  <div>
    <Header>Top 10</Header>
    <Content>
      The number of keywords from your list for which your website is ranked in
      the top 10 search results. &apos;New&apos; represents the keywords that
      have entered the top 10 since the last period, and Lost&apos; represents
      the keywords that have dropped out of the top 10.
    </Content>
  </div>
);

const Top30 = () => (
  <div>
    <Header>Top 30</Header>
    <Content>
      The number of keywords from your list for which your website is ranked in
      the top 30 search results. &apos;New&apos; represents the keywords that
      have entered the top 30 since the last period, and Lost&apos; represents
      the keywords that have dropped out of the top 30.
    </Content>
  </div>
);

const Top100 = () => (
  <div>
    <Header>Top 100</Header>
    <Content>
      The number of keywords from your list for which your website is ranked in
      the top 100 search results. &apos;New&apos; represents the keywords that
      have entered the top 100 since the last period, and Lost&apos; represents
      the keywords that have dropped out of the top 100.
    </Content>
  </div>
);

const AveragePosition = () => (
  <div>
    <Header>Average Position</Header>
    <Content>
      This represents the average rank position for all of your tracked
      keywords. It provides a snapshot of your overall keyword performance.
    </Content>
  </div>
);

const ProjectPerformance = () => (
  <div>
    <Header>Project Performance</Header>
    <Content>
      This graph displays the daily average position of your tracked keywords
      over time.
    </Content>
  </div>
);

const ImprovedVsDeclined = () => (
  <div>
    <Header>Improved vs. Declined</Header>
    <Content>
      This stacked bar chart provides a visual representation of the performance
      of your keywords over time. Each bar corresponds to a specific time
      period.
    </Content>
  </div>
);

const KeywordsTrends = () => (
  <div>
    <Header>Keywords Trends</Header>
    <Content>
      This stacked bar chart provides a visual representation of your keyword
      rankings over time.. Each bar corresponds to a specific time period, and
      each segment of the bar represents a different rank range.
    </Content>
  </div>
);

const Position = () => (
  <div>
    <Header>Position</Header>
    <Content>
      Current last updated position. This shows the most recent ranking position
      for this keyword.
    </Content>
  </div>
);

const D1 = () => (
  <div>
    <Header>1d</Header>
    <Content>
      Difference in rank from 1 day ago. This shows how the ranking has changed
      since yesterday.
    </Content>
  </div>
);

const D7 = () => (
  <div>
    <Header>7d</Header>
    <Content>
      Difference in rank from 7 days ago. This shows how the ranking has changed
      over the past week.
    </Content>
  </div>
);

const D30 = () => (
  <div>
    <Header>30d</Header>
    <Content>
      Difference in rank from 30 days ago. This shows how the ranking has
      changed over the past month.
    </Content>
  </div>
);

const Life = () => (
  <div>
    <Header>Life</Header>
    <Content>
      Difference in rank since the keyword was added. This shows the total
      change in ranking since this keyword started being tracked.
    </Content>
  </div>
);

const Start = () => (
  <div>
    <Header>Start</Header>
    <Content>
      The rank of the keyword when it was first added. This shows where the
      keyword was positioned when you first started tracking it.
    </Content>
  </div>
);

const Volume = () => (
  <div>
    <Header>Volume</Header>
    <Content>
      Search Volume indicates the estimated monthly searches for this keyword
      within your chosen region and language settings.
    </Content>
  </div>
);

const URL = () => (
  <div>
    <Header>URL</Header>
    <Content>
      The URL that ranks for this keyword. This is the page on your site that
      appears in search results for this keyword.
    </Content>
  </div>
);

const Updated = () => (
  <div>
    <Header>Updated</Header>
    <Content>
      The time the keyword ranking was last updated. This shows when the most
      recent ranking data was collected.
    </Content>
  </div>
);

const Best = () => (
  <div>
    <Header>Best</Header>
    <Content>
      ‘Best’ indicates the highest rank a keyword has achieved in search engine
      results since the start of tracking.
    </Content>
  </div>
);

const CPC = () => (
  <div>
    <Header>CPC</Header>
    <Content>
      CPC (Cost Per Click) is the estimated bid price for advertising using this
      keyword on Google AdWords, calculated based on the average cost for this
      keyword in your selected location and for your targeted language audience.
      Note that this is an approximation, and actual costs may vary.
    </Content>
  </div>
);

export {
  Improved,
  Declined,
  Top3,
  Top10,
  Top30,
  Top100,
  AveragePosition,
  ProjectPerformance,
  ImprovedVsDeclined,
  KeywordsTrends,
  Position,
  D1,
  D7,
  D30,
  Life,
  Start,
  Volume,
  URL,
  Updated,
  Best,
  CPC,
};
