import {
  Box,
  Grid,
  InputLabel,
  Typography,
  OutlinedInput,
  FormHelperText,
} from '@mui/material';
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { CustomFormHelperText } from '../../../../shared/components';
import SerpNestLogo from '../../../../shared/images/SerpNestLogo';
import { useAuth } from '../../../../hooks';
import ROUTES from '../../../../routes/constants';

import { GrayButton, PrimaryButton } from '../../../../shared/buttons/styles';
import { Paper } from '../../../../shared/paper/styles';
import { LogoContainer, SingUpLabel, Wrapper } from './styles';

/**
 * Component representing the forget password page.
 *
 * @component
 * @returns {JSX.Element} The ForgetPassword component.
 */
const ForgetPassword = () => {
  const { onForgotPassword } = useAuth();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      message: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address.')
        .max(255)
        .required('Email is required.'),
    }),
    onSubmit: (values, { setErrors, setStatus, setSubmitting }) => {
      onForgotPassword({
        email: values.email,
        successFn: () => {
          navigate(ROUTES.dynamic.forgotPassword(values.email));
        },
        errorFn: (error: any) => {
          setStatus({ success: false });
          setSubmitting(false);
          setErrors(error);
        },
      });
    },
  });

  /**
   * Redirects the user to the login page.
   */
  const onBack = () => {
    navigate(ROUTES.login);
  };

  return (
    <Wrapper>
      <Grid container>
        <Grid xs={1} lg={4} md={2} />
        <Grid xs={10} lg={4} md={8}>
          <Paper
            sx={{
              p: 4,
            }}
          >
            <LogoContainer>
              <SerpNestLogo />
            </LogoContainer>
            <SingUpLabel variant='h5'>Forgot Password</SingUpLabel>
            <Typography sx={{ mt: 1 }} variant='body2'>
              Enter your email address below to reset your password.
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel shrink htmlFor='email'>
                      Email Address
                    </InputLabel>
                    <OutlinedInput
                      fullWidth
                      type='email'
                      id='email'
                      name='email'
                      placeholder='name@example.com'
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={!!(formik.touched.email && formik.errors.email)}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <FormHelperText error id='email'>
                        <CustomFormHelperText error={formik.errors.email} />
                      </FormHelperText>
                    )}
                    <FormHelperText
                      error={!!formik.errors.message}
                      id='password'
                    >
                      {formik.errors.message && (
                        <CustomFormHelperText error={formik.errors.message} />
                      )}
                    </FormHelperText>
                  </Grid>
                </Grid>
                <PrimaryButton
                  size={'large'}
                  type='submit'
                  fullWidth
                  variant='contained'
                  color={'primary'}
                  sx={{
                    mt: 3,
                  }}
                  disabled={
                    formik.isSubmitting || !(formik.isValid && formik.dirty)
                  }
                >
                  Continue
                </PrimaryButton>
                <GrayButton
                  size={'large'}
                  fullWidth
                  variant='text'
                  sx={{
                    mt: 2,
                  }}
                  startIcon={<ArrowBackIcon />}
                  onClick={onBack}
                >
                  Back to log in
                </GrayButton>
              </Box>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default ForgetPassword;
