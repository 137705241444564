import React, { useMemo } from 'react';
import { Line, ResponsiveContainer, LineChart, YAxis } from 'recharts';
import { Grid } from '@mui/material';

interface IDailyAverageProps {
  dailyAverage: { date: string; avg: string }[];
}

const DailyAverageGraph: React.FC<IDailyAverageProps> = ({ dailyAverage }) => {
  const dailyAverageMemo = useMemo(() => {
    return dailyAverage.map(item => ({ ...item, avg: Number(item.avg) }));
  }, [dailyAverage]);

  return (
    <Grid width={'200px'} height={'40px'} display={'flex'}>
      <Grid xs={12}>
        <ResponsiveContainer width='100%' height='100%'>
          <LineChart data={dailyAverageMemo}>
            <YAxis
              allowDecimals={false}
              axisLine={false}
              tickLine={false}
              orientation='right'
              domain={[1, 100]}
              reversed
              interval='preserveStartEnd'
              hide
            />
            <Line dataKey='avg' dot={false} stroke='#126FE9' />
          </LineChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};

export default DailyAverageGraph;
