import React from 'react';
import useAlerts from '../../../../../../../../hooks/useAlerts';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import CustomToast from '../../../../../../../../../../shared/CustomToast/CustomToast';
import { closeAndUndoSnackbarAction } from '../../../../../../../../../../utils/snackbar';
import {
  ListItemTypography,
  StyledListItem,
  StyledListItemButton,
} from '../../../../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/styles';
import SmallTrashIcon from '../../../../../../../../../../shared/images/SmallTrashIcom';
import { useAppSelector } from '../../../../../../../../../../store';

interface DeleteActionProps {
  id: number;
  popupClose: () => void;
}

const DeleteAction: React.FC<DeleteActionProps> = ({ id, popupClose }) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const {
    onSetDeleteElementsTriggerKeywordsTable,
    onSetRowSelectionModelTriggerKeywords,
    onBulkDeleteKeywordsFromTrigger,
    onGetTriggerKeywords,
    onSetShouldRefreshTable,
    tables: {
      triggerKeywords: { paginationModel, sortModel },
    },
    triggerInfo,
  } = useAlerts();

  const handleHardDelete = () => {
    if (currentAccount?.accountId && triggerInfo?.id) {
      onBulkDeleteKeywordsFromTrigger({
        id: currentAccount.accountId,
        triggerKeywordIds: [id],
        successFn: () => {
          onSetRowSelectionModelTriggerKeywords([]);
          onGetTriggerKeywords({
            id: currentAccount.accountId,
            triggerId: triggerInfo.id,
            page: paginationModel.page,
            limit: paginationModel.pageSize,
            sortBy: sortModel[0]?.field,
            sortOrder: sortModel[0]?.sort,
          });
          onSetShouldRefreshTable(true);
          onSetDeleteElementsTriggerKeywordsTable([]);
        },
      });
    }
  };

  const handleDeleteOneKeyword = () => {
    const localDeleteElements = id ? [id] : [];
    onSetDeleteElementsTriggerKeywordsTable(localDeleteElements);

    popupClose();
    let undoDelete = false;
    const onUndo = () => {
      undoDelete = true;
      onSetDeleteElementsTriggerKeywordsTable([]);
    };

    enqueueSnackbar(
      <CustomToast
        message='Keyword Removed'
        submessage={`Keyword has been successfully removed.`}
      />,
      {
        action: (snackbarId: SnackbarKey) =>
          closeAndUndoSnackbarAction(snackbarId, onUndo),
        variant: 'error',
      }
    );
    setTimeout(() => {
      if (!undoDelete) {
        handleHardDelete();
      }
    }, 5000);
  };

  return (
    <StyledListItem onClick={handleDeleteOneKeyword}>
      <StyledListItemButton>
        <SmallTrashIcon />
        <ListItemTypography marginLeft='8px' sx={{ color: '#B42318' }}>
          Remove from Trigger
        </ListItemTypography>
      </StyledListItemButton>
    </StyledListItem>
  );
};

export default DeleteAction;
