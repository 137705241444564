import React, { useState } from 'react';
import {
  ListItemTypography,
  StyledListItem,
} from '../../../../../projects/components/ProjectExpanded/components/KeywordRankings/components/KeywordRankingsTable/components/EllipsisMenu/styles';
import { DeleteActionProps } from './types';
import EditProjectReport from '../../../modals/EditProjectReport/EditProjectReport';
import EditIcon from '../../../../../../shared/images/EditIcon';
import { StyledListItemButton } from '../../../../../../shared/buttons/styles';
import { useEmailReports } from '../../../../../../hooks';

const EditAction: React.FC<DeleteActionProps> = ({ popupClose, id }) => {
  const { onSetRowSelectionModelEmailReports } = useEmailReports();

  const [isOpenEditModal, setOpenEditModal] = useState(false);

  const onOpen = () => {
    setOpenEditModal(true);
  };

  const onClose = () => {
    onSetRowSelectionModelEmailReports([]);
    setOpenEditModal(false);
    popupClose();
  };

  return (
    <StyledListItem>
      <StyledListItemButton onClick={onOpen}>
        <EditIcon />
        <ListItemTypography marginLeft='8px'>
          Edit Email Report
        </ListItemTypography>
      </StyledListItemButton>
      <EditProjectReport id={id} onClose={onClose} open={isOpenEditModal} />
    </StyledListItem>
  );
};

export default EditAction;
