import React from 'react';

import { Header, Content } from './styles';

const URL = () => (
  <div>
    <Header>URL</Header>
    <Content>
      This is the website&apos;s URL associated with the project. All keywords
      are tracked for this URL.
    </Content>
  </div>
);

const Type = () => (
  <div>
    <Header>Type</Header>
    <Content>
      Displays the device (Desktop / Mobile / Desktop & Mobile), search engine,
      and search engine domain associated with the project.
    </Content>
  </div>
);

const TotalKeywords = () => (
  <div>
    <Header>Total Keywords</Header>
    <Content>
      This represents the total number of keywords currently being tracked
      within the project.
    </Content>
  </div>
);

const Improved = () => (
  <div>
    <Header>Improved</Header>
    <Content>
      This displays the number of keywords for which the ranking has improved
      within the selected time period.
    </Content>
  </div>
);

const Declined = () => (
  <div>
    <Header>Declined</Header>
    <Content>
      This shows the number of keywords for which the ranking has declined
      within the selected time period.
    </Content>
  </div>
);

const NoChange = () => (
  <div>
    <Header>No Change</Header>
    <Content>
      This indicates the number of keywords for which the ranking hasn&apos;t
      changed within the selected time period.
    </Content>
  </div>
);

const DailyAverage = () => (
  <div>
    <Header>Daily Average</Header>
    <Content>
      This represents the average position of all keywords in the project,
      calculated on a daily basis. It provides a quick snapshot of how well the
      website is ranking overall.
    </Content>
  </div>
);

const Frequency = () => (
  <div>
    <Header>Frequency</Header>
    <Content>
      This represents how often keyword rankings are checked within the project.
      For instance, &apos;daily&apos; means the rankings are updated every day.
    </Content>
  </div>
);

const Created = () => (
  <div>
    <Header>Created</Header>
    <Content>This shows the date when the project was first created.</Content>
  </div>
);

const Updated = () => (
  <div>
    <Header>Updated</Header>
    <Content>
      This shows the last time any of the keyword rankings within this project
      were updated.
    </Content>
  </div>
);

export {
  URL,
  Type,
  TotalKeywords,
  Improved,
  Declined,
  NoChange,
  DailyAverage,
  Frequency,
  Created,
  Updated,
};
