import React from 'react';
import { SuccessPayModalProps } from './types';
import { Fade, Grid, Modal } from '@mui/material';
import {
  CloseDeletePopoverOne,
  CustomModal,
  HeaderContainer,
  ModalContainer,
} from '../../../auth/components/Settings/components/Account/styles';
import CloseIcon from '@mui/icons-material/Close';
import { StyledDescriptions } from '../../../auth/components/Settings/components/Billing/components/SadToSeeYouGoModal/styles';
import { PrimaryButtonSmall } from '../../../../shared/buttons/styles';
import UpgradeMessageIcon from '../../../../shared/images/UpgradeMessageIcon';
import { StyledHeadText } from './styles';

const SuccessPayModal: React.FC<SuccessPayModalProps> = ({
  open,
  handleClose,
  handleBackToDashboard,
}) => {
  return (
    <Modal
      disableAutoFocus
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <CustomModal>
          <ModalContainer>
            <HeaderContainer>
              <CloseDeletePopoverOne onClick={handleClose}>
                <CloseIcon fontSize='small' />
              </CloseDeletePopoverOne>
            </HeaderContainer>

            <Grid
              container
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              xs={12}
              marginTop={'52px'}
            >
              <Grid item>
                <UpgradeMessageIcon />
              </Grid>

              <Grid item>
                <StyledHeadText>Success!</StyledHeadText>
              </Grid>

              <Grid item padding={'8px 0'}>
                <StyledDescriptions>
                  Congratulations on your upgrade!
                </StyledDescriptions>
              </Grid>

              <Grid item paddingTop={'24px'}>
                <PrimaryButtonSmall
                  variant='contained'
                  size='small'
                  type='submit'
                  onClick={handleBackToDashboard}
                >
                  Back to dashboard
                </PrimaryButtonSmall>
              </Grid>
            </Grid>
          </ModalContainer>
        </CustomModal>
      </Fade>
    </Modal>
  );
};

export default SuccessPayModal;
