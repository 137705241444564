import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useState } from 'react';
import { CustomTooltip } from '../../../shared/tooltip/styles';
import * as Tooltips from '../components/Alerts/components/Tooltips';
import InfoIcon from '../../../shared/images/InfoIcon';
import { Skeleton } from '@mui/material';
import useAlerts from './useAlerts';
import { findLongestString, getColumnWidth } from '../../../utils/table';
import { customSortComparator } from '../../emailReports/hooks/useScheduledEmailReportsTable';
import {
  MemoRenderCellDifference,
  MemoRenderCellKeywordName,
  MemoRenderCellPreviousPosition,
  MemoRenderCellRenderCellNewRank,
} from '../components/Alerts/components/AlertsTable/components/AlertTriggeredModal/components/AlertTriggeredTable/components/RenderCells/RenderCells';
import { TableHeaderStyles } from '../../../shared/stylesTable';

export default function useAlertTriggeredTable() {
  const { alertKeywords } = useAlerts();

  const popperProps = {
    sx: {
      '& .MuiTooltip-tooltip': {
        backgroundColor: '#fff',
        color: '#344054',
        boxShadow:
          '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
        border: '1px solid #EDEDED',
      },
      '.MuiTooltip-arrow::before': {
        backgroundColor: '#fff',
        border: '1px solid #EDEDED',
      },
    },
  };

  const [keywordNameWidth, setKeywordNameWidth] = useState<number>(130);

  useEffect(() => {
    if (alertKeywords?.items) {
      setKeywordNameWidth(
        getColumnWidth(
          findLongestString(
            alertKeywords?.items?.map(item => item.keywordName)
          ) || ''
        )
      );
    }
  }, [alertKeywords?.items]);

  const tableWidth = useMemo(
    () => ({
      keywordName: alertKeywords?.items
        ? getColumnWidth(
            findLongestString(
              alertKeywords.items.map(item => item.keywordName)
            ) || ''
          )
        : keywordNameWidth,
    }),
    [alertKeywords?.items, keywordNameWidth]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'keywordName',
        sortable: true,
        headerName: 'Keyword',
        maxWidth: 425,
        minWidth: 130,
        width: tableWidth.keywordName + 20,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Keyword />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Keyword '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellKeywordName row={params.row} />
        ),
      },
      {
        field: 'previousPosition',
        sortable: true,
        headerName: 'Previous Rank',
        maxWidth: 140,
        minWidth: 140,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.PreviousRank />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Previous Rank '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellPreviousPosition row={params.row} />
        ),
      },
      {
        field: 'currentPosition',
        sortable: true,
        headerName: 'New Rank',
        maxWidth: 140,
        minWidth: 140,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.NewRank />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'New Rank '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellRenderCellNewRank row={params.row} />
        ),
      },
      {
        field: 'difference',
        sortable: true,
        headerName: 'Difference',
        maxWidth: 140,
        minWidth: 140,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Difference />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Difference '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellDifference row={params.row} />
        ),
      },
    ],
    [popperProps, tableWidth.keywordName]
  );

  const columnsSkeleton: GridColDef[] = useMemo(
    () => [
      {
        field: 'keywordName',
        sortable: true,
        headerName: 'Keyword',
        maxWidth: 425,
        minWidth: 130,
        width: tableWidth.keywordName + 20,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'previousRank',
        sortable: true,
        headerName: 'Previous Rank',
        maxWidth: 140,
        minWidth: 140,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'newRank',
        sortable: true,
        headerName: 'New Rank',
        maxWidth: 140,
        minWidth: 140,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'difference',
        sortable: true,
        headerName: 'Difference',
        maxWidth: 140,
        minWidth: 140,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
    ],
    [tableWidth]
  );

  return { columns, columnsSkeleton };
}
