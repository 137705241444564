import { useSharedLinks } from '../../../../../../../../hooks';
import { useAppSelector } from '../../../../../../../../store';
import React, { useEffect, useState } from 'react';
import { AccountTag } from '../../../../../../../projects/types';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import {
  GrayButtonCustom,
  PrimaryButtonSmall,
} from '../../../../../../../../shared/buttons/styles';
import DownIcon from '../../../../../../../../shared/images/DownIcon';
import {
  PopupButtonContainer,
  PopupSelectButton,
  SelectedPopupItems,
} from '../../../../../../../projects/components/Projects/components/TableProjects/components/Header/styles';
import {
  Divider,
  FormControlLabel,
  Grid,
  InputBase,
  InputLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Popover } from '../../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import {
  TagsContainer,
  TagsSearchContainer,
} from '../../../../../../../projects/components/Projects/components/TableProjects/components/Header/components/PopupTags/styles';
import SearchIcon from '../../../../../../../../shared/images/SearchIcon';
import { SmallContainerCheckbox } from '../../../../../../../../shared/checkbox/styles';
import CustomCheckboxImage from '../../../../../../../../shared/images/CustomCheckbox';
import CheckedIcon from '../../../../../../../../shared/images/CheckedIcon';

const PopupKeywordTags = () => {
  const { onSelectSharedKeywordTags } = useSharedLinks();

  const keywordSelectedTags = useAppSelector(
    state => state.sharedLinks.projectGraphs.keywordRankings.table.selectedTags
  );

  const projectShared = useAppSelector(
    state => state.sharedLinks.projectShared
  );

  const [searchString, setSearchString] = useState('');
  const [keywordTags, setKeywordTags] = useState<AccountTag[]>([]);
  const [checkedTags, setCheckedTags] =
    useState<AccountTag[]>(keywordSelectedTags);

  const handleSelectTag = (tag: AccountTag, checked: boolean) => {
    setCheckedTags([...checkedTags, tag]);
    if (!checked) {
      setCheckedTags(checkedTags.filter(item => item.id !== tag.id));
    }
  };

  const handleConfirm = (onClose: () => void) => {
    onSelectSharedKeywordTags(checkedTags);
    onClose();
  };

  const handleClearTags = () => {
    onSelectSharedKeywordTags([]);
    setCheckedTags([]);
  };

  const handleSearch = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value as string;
    setSearchString(value);
    if (value.length) {
      const array = keywordTags;

      const regex = new RegExp(value, 'i');
      const filteredArray = array.filter(item => regex.test(item.name));

      setKeywordTags(filteredArray);
    } else {
      if (projectShared?.keywordTags) {
        setKeywordTags(projectShared.keywordTags);
      }
    }
  };

  useEffect(() => {
    if (projectShared?.keywordTags?.length) {
      setKeywordTags(projectShared.keywordTags);
    }
  }, [projectShared?.keywordTags]);

  return (
    <PopupState variant='popover' popupId='add-tag-setting'>
      {popupState => (
        <>
          {!keywordSelectedTags.length ? (
            <GrayButtonCustom
              $padding='7px 12px'
              size='small'
              outline
              endIcon={<DownIcon stroke='#344054' />}
              {...bindTrigger(popupState)}
            >
              Tags
            </GrayButtonCustom>
          ) : (
            <PopupButtonContainer isSelected={!!keywordSelectedTags.length}>
              <PopupSelectButton
                {...bindTrigger(popupState)}
                isSelected={!!keywordSelectedTags.length}
              >
                <Grid>Tags</Grid>
                <DownIcon
                  stroke={keywordSelectedTags.length ? '#0E59BA' : '#344054'}
                />
              </PopupSelectButton>
              {keywordSelectedTags.length ? (
                <SelectedPopupItems>
                  <Grid>{keywordSelectedTags.length} Selected</Grid>
                  <CloseIcon
                    onClick={handleClearTags}
                    sx={{
                      color: '#000000',
                      position: 'initial',
                      cursor: 'pointer',
                      width: '20px',
                      marginLeft: '4px',
                    }}
                  />
                </SelectedPopupItems>
              ) : null}
            </PopupButtonContainer>
          )}

          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              boxShadow:
                ' 0px 4px 6px -2px rgba(0, 0, 0, 0.06), 0px 12px 16px -4px rgba(0, 0, 0, 0.10)',
              borderRadius: '8px',
              marginTop: '11px',
            }}
          >
            <TagsContainer>
              <TagsSearchContainer>
                <InputLabel htmlFor='searchTags'>
                  <SearchIcon />
                </InputLabel>
                <InputBase
                  fullWidth
                  id='searchTags'
                  placeholder='Search tags...'
                  value={searchString}
                  onChange={handleSearch}
                />
              </TagsSearchContainer>

              <Divider
                orientation='horizontal'
                flexItem
                sx={{
                  margin: '11px 0 16px 0',
                  color: '#EDEDED',
                }}
              />

              {keywordTags.length ? (
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0 8px',
                    cursor: 'pointer',
                  }}
                >
                  {keywordTags.map(item => (
                    <FormControlLabel
                      key={item.id}
                      label={item.name}
                      sx={{
                        marginLeft: '8px',
                        marginRight: '8px',
                        gap: '8px',
                        color: '#101828',
                        fontSize: '14px',
                        padding: '8px 0 8px 8px',
                        backgroundColor: checkedTags.some(x => x.id === item.id)
                          ? '#EEF3FF'
                          : 'initial',
                        borderRadius: '8px',
                        ':hover': {
                          backgroundColor: '#F6F6F6',
                          borderRadius: '8px',
                        },
                      }}
                      control={
                        <SmallContainerCheckbox
                          onChange={() =>
                            handleSelectTag(
                              item,
                              !checkedTags.some(x => x.id === item.id)
                            )
                          }
                          checked={checkedTags.some(x => x.id === item.id)}
                          icon={<CustomCheckboxImage />}
                          checkedIcon={<CheckedIcon />}
                        />
                      }
                    />
                  ))}
                </Grid>
              ) : null}

              <Grid item xs={12} sx={{ m: '16px 16px 0 16px' }}>
                <PrimaryButtonSmall
                  fullWidth
                  variant='contained'
                  size='small'
                  onClick={() => handleConfirm(popupState.close)}
                >
                  Apply
                </PrimaryButtonSmall>
              </Grid>
            </TagsContainer>
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export default PopupKeywordTags;
