import React, { useMemo } from 'react';
import { Autocomplete, FormHelperText, Grid, TextField } from '@mui/material';
import { CustomTooltip } from '../../../../../../shared/tooltip/styles';
import * as Tooltips from './Tooltips';
import { ButtonTooltipContainer } from './styles';
import HelperIcon from '../../../../../../shared/images/HelperIcon';
import {
  ModalAutocompleteStylesProps,
  ModalOutlinedInput,
} from '../../../../../../shared/modal/styled';
import { CustomFormHelperText } from '../../../../../../shared/components';
import { LIST_DOMAINS } from '../../../../../../constants/options';
import SelectIcon from '../../../../../../shared/components/SelectIcon';
import { FormikProps } from 'formik';
import { NewProjectValues } from '../../../../types';

interface URLSectionProps {
  onClickTooltip: (tooltipIndex?: number) => void;
  onOpenTooltip: (tooltipIndex?: number) => void;
  showTooltip: boolean;
  openTooltip: number | undefined;
  formik: FormikProps<NewProjectValues>;
}

const URLSection: React.FC<URLSectionProps> = ({
  onClickTooltip,
  onOpenTooltip,
  showTooltip,
  openTooltip,
  formik,
}) => {
  const isYouTube = useMemo(
    () => formik?.values?.searchEngine === 'YouTube',
    [formik?.values?.searchEngine]
  );

  const isRender = useMemo(
    () =>
      formik.values.searchEngine == 'Google My Business' ||
      formik.values.searchEngine === 'Google Maps',
    [formik?.values?.searchEngine]
  );

  if (isRender) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Grid container fontWeight='500' alignItems='center' fontSize='14px'>
        <Grid item>{isYouTube ? 'Video URL' : 'URL'}</Grid>
        <Grid item>
          <CustomTooltip
            title={isYouTube ? <Tooltips.VideoURL /> : <Tooltips.URL />}
            placement='top-start'
            arrow
            open={showTooltip && openTooltip === 0}
            onOpen={() => onOpenTooltip(0)}
            onClose={() => onClickTooltip(0)}
            onClick={() => onClickTooltip(0)}
          >
            <ButtonTooltipContainer onClick={() => onClickTooltip(0)}>
              <HelperIcon />
            </ButtonTooltipContainer>
          </CustomTooltip>
        </Grid>
        <Grid
          sx={{
            width: '100%',
            display: 'grid',
            gridTemplateColumns: !isYouTube ? '2fr 1fr' : '',
            gap: '6px',
          }}
        >
          <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
            <ModalOutlinedInput
              size='small'
              fullWidth
              type='text'
              id='url'
              name='url'
              placeholder={
                isYouTube ? 'Enter YouTube video’s URL' : 'example.com'
              }
              value={formik.values.url}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={!!(formik.touched.url && formik.errors.url)}
            />
            {formik.touched.url && formik.errors.url && (
              <FormHelperText error id='url'>
                <CustomFormHelperText error={formik.errors.url} />
              </FormHelperText>
            )}
            {isYouTube && formik.touched.videoUrl && formik.errors.videoUrl && (
              <FormHelperText error id='videoUrl'>
                <CustomFormHelperText error={formik.errors.videoUrl} />
              </FormHelperText>
            )}
          </Grid>
          {!isYouTube ? (
            <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
              <Autocomplete
                sx={ModalAutocompleteStylesProps}
                disableClearable
                fullWidth
                size={'small'}
                id='projectUrlType'
                options={LIST_DOMAINS}
                getOptionLabel={option => option.name}
                value={formik.values.projectUrlType}
                onChange={(_, value) =>
                  formik.setFieldValue('projectUrlType', value || null)
                }
                onBlur={formik.handleBlur}
                popupIcon={<SelectIcon />}
                renderInput={params => (
                  <TextField
                    {...params}
                    name='projectUrlType'
                    error={
                      !!(
                        formik.touched.projectUrlType &&
                        formik.errors.projectUrlType
                      )
                    }
                  />
                )}
              />
              {formik.touched.projectUrlType &&
                formik.errors.projectUrlType?.name && (
                  <FormHelperText error id='projectUrlType'>
                    <CustomFormHelperText
                      error={formik.errors.projectUrlType.name}
                    />
                  </FormHelperText>
                )}
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default URLSection;
