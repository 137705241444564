import React from 'react';

import ROUTES from '../../../routes/constants';
import SettingsIcon from '../../images/SettingsIcon';
import ProjectsIcon from '../../images/ProjectsIcon';
import NoteIcon from '../../images/NoteIcon';
import SharedLinksIcon from '../../images/SharedLinksIcon';
import EmailIcon from '../../images/EmailIcon';
import AlertIcon from '../../images/AlertIcon';

const DRAWER_WIDTH = 256;

const DRAWER_LIST = [
  {
    title: 'Projects',
    Icon: (active: boolean) => (
      <ProjectsIcon stroke={active ? '#126FE9' : undefined} />
    ),
    link: ROUTES.projects,
  },
  {
    title: 'Email Reports',
    Icon: (active: boolean) => (
      <EmailIcon stroke={active ? '#126FE9' : undefined} />
    ),
    link: ROUTES.scheduledEmailReports,
  },
  {
    title: 'Alerts',
    Icon: (active: boolean) => (
      <AlertIcon stroke={active ? '#126FE9' : undefined} />
    ),
    link: ROUTES.alerts,
  },
  {
    title: 'Shared Links',
    Icon: (active: boolean) => (
      <SharedLinksIcon stroke={active ? '#126FE9' : undefined} />
    ),
    link: ROUTES.sharedLinks,
  },
  {
    title: 'Notes',
    Icon: (active: boolean) => (
      <NoteIcon stroke={active ? '#126FE9' : undefined} />
    ),
    link: ROUTES.notes,
  },
  {
    title: 'Settings',
    Icon: (active: boolean) => (
      <SettingsIcon stroke={active ? '#126FE9' : undefined} />
    ),
    link: 'settings/account',
  },
];

const DRAWER_ITEMS_CONFIG = {
  projects: 0,
  'scheduled-email-reports': 1,
  alerts: 2,
  'shared-links': 3,
  notes: 4,
  settings: 5,
  folders: 6,
};

const AVATAR_COLORS = [
  '#B71C1C',
  '#880E4F',
  '#4A148C',
  '#311B92',
  '#1A237E',
  '#0D47A1',
  '#01579B',
  '#006064',
  '#004D40',
  '#1B5E20',
  '#33691E',
  '#827717',
  '#A54C00',
  '#A44600',
  '#E65100',
  '#A63F00',
  '#3E2723',
  '#212121',
  '#263238',
  '#37474F',
  '#6C3483',
  '#1F618D',
  '#196F3D',
  '#7E5109',
  '#1B2631',
  '#1E8449',
  '#641E16',
  '#17202A',
  '#7D6608',
  '#4A235A',
  '#154360',
  '#7B241C',
  '#6E2C00',
  '#0E6251',
  '#4D5656',
  '#424949',
  '#17202A',
  '#273746',
  '#641E16',
];

const COMPETITORS_COLORS = [
  '#6929c4',
  '#1192e8',
  '#005d5d',
  '#9f1853',
  '#fa4d56',
  '#570408',
  '#198038',
  '#002d9c',
  '#ee538b',
  '#b28600',
  '#009d9a',
  '#012749',
  '#8a3800',
  '#a56eff',
];

const PopoverSx = {
  boxShadow:
    ' 0px 12px 16px -4px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.06)',
  borderRadius: '8px',
};

export {
  DRAWER_WIDTH,
  DRAWER_LIST,
  DRAWER_ITEMS_CONFIG,
  AVATAR_COLORS,
  PopoverSx,
  COMPETITORS_COLORS,
};
