import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import { Range } from 'react-date-range';

import {
  AccountTag,
  CheckFrequency,
  GoogleDomain,
  IDeviceType,
  KeywordAnalytics,
  KeywordRankingState,
  Language,
  ProjectExpanded,
  ProjectImprovedVsDeclined,
  ProjectKeywordTrends,
  ProjectOverview,
  ProjectPositionHistory,
  ProjectsState,
  SearchLocation,
  SearchLocationsState,
  AvailableProject,
  GetKeywordPositionHistoryResponse,
} from '../types';
import {
  addKeywordsThunk,
  addTagsThunk,
  deleteKeywordsThunk,
  editProjectThunk,
  geLocationsBaiduThunk,
  geLocationsBingThunk,
  geLocationsOfChinaThunk,
  geLocationsYahooThunk,
  geLocationsYoutubeThunk,
  getAccountTagsThunk,
  getCheckFrequencyThunk,
  getDeviceTypesThunk,
  getGoogleDomainsThunk,
  getKeywordInfoThunk,
  getKeywordPositionHistoryThunk,
  getKeywordPositionsInfoThunk,
  getKeywordRankingsThunk,
  getKeywordSearchResultsThunk,
  getKeywordTagsThunk,
  getLanguagesBaiduThunk,
  getLanguagesBingThunk,
  getLanguagesThunk,
  getLanguagesYahooThunk,
  getLanguagesYoutubeThunk,
  getListAvailableProjectsThunk,
  getProjectExpandedThunk,
  getProjectPerformanceThunk,
  getProjectsImprovedVsDeclinedThunk,
  getProjectsKeywordTrendsThunk,
  getProjectsOverviewThunk,
  getProjectsThunk,
  getSingleKeywordPositionHistoryThunk,
  newProjectForBaiduThunk,
  newProjectForBingThunk,
  newProjectForGoogleLocalThunk,
  newProjectForGoogleMapsThunk,
  newProjectForYahooThunk,
  newProjectForYoutubeThunk,
  newProjectThunk,
  onGetCurrentProjectThunk,
  searchLocationsThunk,
  updateKeywordPositionThunk,
} from './thunk';
import {
  clearColumnVisibilityModel,
  closeProjectExpandedPage,
  resetColumnVisibilityModel,
  resetColumnVisibilityModelKeywordRankings,
  resetKeywordPositionModel,
  resetKeywordRankingsState,
  resetProjectsState,
  setActivePeriodImprovedVsDeclined,
  setActivePeriodKeywordRankHistory,
  setActivePeriodKeywordTrends,
  setActivePeriodProjectPerformance,
  setCloseKeywordPositionHistory,
  setColumnKeywordPositionModel,
  setColumnVisibilityModel,
  setColumnVisibilityModelKeywordRankings,
  setDeleteElementsKeywordRankingsTable,
  setDeleteElementsProjectsTable,
  setDeviceTypeForProjectExpanded,
  setFrequencyFilterForProjectsTable,
  setImprovedVsDeclinedVisibilityModel,
  setIsShowImprovedVsDeclined,
  setIsShowPositionHistory,
  setKeywordAnalyticPeriod,
  setKeywordPaginationPage,
  setKeywordPaginationPageSize,
  setKeywordRankHistoryIsLinearGraph,
  setKeywordRankHistoryPaginationPage,
  setKeywordRankHistoryPaginationPageSize,
  setKeywordTrendsVisibilityModel,
  setLocationsBaiduMeta,
  setLocationsBingMeta,
  setLocationsOfChinaMeta,
  setLocationsYahooMeta,
  setLocationsYoutubeMeta,
  setNewProjectModal,
  setPaginationPage,
  setPaginationPageSize,
  setPositionHistoryLinearGraph,
  setProjectExpandedGraphsRangeDate,
  setProjectPerformanceIsLinearGraph,
  setProjectPerformanceIsShowNotes,
  setProjectPerformanceRangeDate,
  setRefreshAllKeywordsModal,
  setRefreshKeywordsModal,
  setRowKeywordsSelectionModel,
  setRowSelectionModel,
  setSearchKeywordRankings,
  setSearchLocationsMeta,
  setSearchProjects,
  setShowCompetitorsForGraph,
  setShowCompetitorsForKeywordRankHistory,
  setShowCompetitorsForPositionHistory,
  setSortModelKeywordRankHistory,
  setSortModelKeywords,
  setSortModelProjects,
  setStartOfKeywordUpdate,
  setUpdatedKeywords,
  updateProjects,
} from './actions';
import {
  CLEARED_COLUMN_VISIBILITY_MODEL,
  DEFAULT_COLUMN_VISIBILITY_MODEL,
  DEFAULT_KEYWORD_POSITION_MODEL,
  DEFAULT_KEYWORD_RANKINGS_VISIBILITY_MODEL,
} from '../constants';
import {
  GetKeywordInfoResponse,
  IKeywordPositionsInfoState,
} from '../types/keywordInfo';
import { GetProjectPerformanceResponse } from '../types/projectPerformance';
import dayjs from 'dayjs';
import { getPage } from '../../../utils';
import {
  initializeTableColumns,
  TABLES_STORAGE_KEYS,
} from '../tablesColumnStase';

const defaultKeywordRankings = {
  keywordRanking: {} as KeywordRankingState,
  keywordsAnalytics: [] as KeywordAnalytics[],
  table: {
    search: '',
    sortModel: [] as GridSortModel,
    columnVisibilityModel: initializeTableColumns(
      TABLES_STORAGE_KEYS.projectKeywordRankings,
      DEFAULT_KEYWORD_RANKINGS_VISIBILITY_MODEL
    ),
    keywordPositionModel: DEFAULT_KEYWORD_POSITION_MODEL as string[],
    rowSelectionModel: [] as GridRowSelectionModel,
    selectedTags: [] as AccountTag[],
    paginationModel: {
      pageSize: 100,
      page: 0,
    },
    deleteElements: [] as GridRowSelectionModel,
  },
};

const defaultProjectExpandedGraphs = {
  isLoadingProjectImprovedVsDeclined: false,
  isLoadingProjectKeywordsTrends: false,
  isLoadingProjectPerformance: false,
  isLoadingProjectKeywordRankings: false,
  deviceType: undefined as string | undefined,
  rangeDate: {
    startDate: undefined,
    endDate: undefined,
    key: 'selection',
  } as Range,
  overview: null as ProjectOverview | null,
  activePeriodImprovedVsDeclined: {
    label: '1m',
    value: 'Month',
  },
  activeKeywordTrends: {
    label: '1m',
    value: 'Month',
  },
  activePeriodProjectPerformance: {
    label: '1m',
    value: 'Month',
  },
  projectImprovedVsDeclined: null as ProjectImprovedVsDeclined | null,
  projectKeywordTrends: null as ProjectKeywordTrends | null,
  projectPositionHistory: null as ProjectPositionHistory | null,
  keywordRankings: defaultKeywordRankings,
  projectPerformance: {
    showCompetitors: [] as number[],
    rangeDate: {
      startDate: undefined,
      endDate: undefined,
      key: 'selection',
    } as Range,
    isShowNotes: true,
    isLinearGraph: true,
    graphData: null as GetProjectPerformanceResponse | null,
  },
  keywordTags: [] as AccountTag[],
};

const initialState = {
  isLoading: false,
  isLoadingProjectExpanded: false,
  projects: {} as ProjectsState,
  projectsListForInvite: {
    items: [] as AvailableProject[],
  },
  projectExpanded: null as ProjectExpanded | null,
  tables: {
    projects: {
      search: '',
      sortModel: [{ field: 'name', sort: 'desc' }] as GridSortModel,
      paginationModel: {
        pageSize: 100,
        page: 0,
      },
      columnVisibilityModel: initializeTableColumns(
        TABLES_STORAGE_KEYS.projectsPage,
        DEFAULT_COLUMN_VISIBILITY_MODEL
      ),
      rowSelectionModel: [] as GridRowSelectionModel,
      frequencyFilter: '24 hours',
      deleteElements: [] as GridRowSelectionModel,
    },
  },
  checkFrequency: [] as CheckFrequency[],
  languages: [] as Language[],
  languagesBing: [] as Language[],
  languagesYoutube: [] as Language[],
  languagesYahoo: [] as Language[],
  languagesBaidu: [] as Language[],
  googleDomains: [] as GoogleDomain[],
  deviceTypes: [] as IDeviceType[],
  accountTags: [] as AccountTag[],
  currentProject: null as ProjectExpanded | null,
  selectedTags: [] as AccountTag[],
  searchLocations: {
    items: [] as SearchLocation[],
    meta: {
      page: 1,
      limit: 30,
      search: 'United States',
    },
  } as SearchLocationsState,
  locationsOfChina: {
    items: [] as SearchLocation[],
    meta: {
      page: 1,
      limit: 30,
      search: 'China',
    },
  } as SearchLocationsState,
  locationsBing: {
    items: [] as SearchLocation[],
    meta: {
      page: 1,
      limit: 30,
      search: 'United States',
    },
  } as SearchLocationsState,
  locationsYoutube: {
    items: [] as SearchLocation[],
    meta: {
      page: 1,
      limit: 30,
      search: 'United States',
    },
  } as SearchLocationsState,
  locationsYahoo: {
    items: [] as SearchLocation[],
    meta: {
      page: 1,
      limit: 30,
      search: 'United States',
    },
  } as SearchLocationsState,
  locationsBaidu: {
    items: [] as SearchLocation[],
    meta: {
      page: 1,
      limit: 30,
      search: 'China',
    },
  } as SearchLocationsState,
  isShowTableFilters: false,
  showBlocks: {
    isShowImprovedVsDeclined: true,
    isShowPositionHistory: true,
  },
  showImprovedVsDeclinedVisibilityModel: {
    improved: true,
    declined: true,
    lost: true,
    noChange: false,
  },
  showKeywordTrendsModel: {
    top3: true,
    'top4-10': true,
    'top11-20': true,
    'top21-50': true,
    'top51-100': true,
    notRanked: false,
  },
  projectExpandedGraphs: defaultProjectExpandedGraphs,
  keywordRankHistory: {
    keywordInfo: null as GetKeywordInfoResponse | null,
    activePeriod: {
      label: '1m',
      value: 'Month',
    },
    keywordPositionHistory: null as GetKeywordPositionHistoryResponse | null,
    keywordPositionsInfo: null as IKeywordPositionsInfoState | null,
    isLinearGraph: true as boolean,
    showCompetitors: [] as number[],
    tableState: {
      search: '',
      sortModel: [{ field: 'date', sort: 'desc' }] as GridSortModel,
      paginationModel: {
        pageSize: 10,
        page: 0,
      },
      rowSelectionModel: [] as GridRowSelectionModel,
    },
  },
  modals: {
    refreshAllKeywords: false,
    refreshKeywords: false,
    newProject: false,
  },
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    selectTags(state, action: PayloadAction<AccountTag[]>) {
      state.selectedTags = action.payload;
    },
    showTableFilters(state, action: PayloadAction<boolean>) {
      state.isShowTableFilters = action.payload;
    },
    selectKeywordTags(state, action: PayloadAction<AccountTag[]>) {
      state.projectExpandedGraphs.keywordRankings.table.selectedTags =
        action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getProjectsThunk.pending, state => {
      state.isLoading = true;
      state.projects = {} as ProjectsState;
    });
    builder.addCase(getProjectsThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.projects = payload;
      state.tables.projects.paginationModel.page = getPage(
        state.tables.projects.paginationModel.page,
        payload.meta.currentPage,
        payload.meta.totalPages
      );
    });
    builder.addCase(getProjectsThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(setSortModelProjects, (state, { payload }) => {
      state.tables.projects.sortModel = payload;
    });

    builder.addCase(setSortModelKeywords, (state, { payload }) => {
      state.projectExpandedGraphs.keywordRankings.table.sortModel = payload;
    });

    builder.addCase(setSearchProjects, (state, { payload }) => {
      state.tables.projects.search = payload;
    });

    builder.addCase(setColumnVisibilityModel, (state, { payload }) => {
      state.tables.projects.columnVisibilityModel[payload.key] = payload.value;
    });

    builder.addCase(
      setColumnVisibilityModelKeywordRankings,
      (state, { payload }) => {
        state.projectExpandedGraphs.keywordRankings.table.columnVisibilityModel[
          payload.key
        ] = payload.value;
      }
    );

    builder.addCase(resetColumnVisibilityModel, state => {
      state.tables.projects.columnVisibilityModel =
        DEFAULT_COLUMN_VISIBILITY_MODEL;
    });

    builder.addCase(resetColumnVisibilityModelKeywordRankings, state => {
      state.projectExpandedGraphs.keywordRankings.table.columnVisibilityModel =
        DEFAULT_KEYWORD_RANKINGS_VISIBILITY_MODEL;
    });

    builder.addCase(clearColumnVisibilityModel, state => {
      state.tables.projects.columnVisibilityModel =
        CLEARED_COLUMN_VISIBILITY_MODEL;
    });

    builder.addCase(setPaginationPageSize, (state, { payload }) => {
      state.tables.projects.paginationModel.pageSize = payload;
    });

    builder.addCase(setKeywordPaginationPageSize, (state, { payload }) => {
      state.projectExpandedGraphs.keywordRankings.table.paginationModel.pageSize =
        payload;
    });

    builder.addCase(setPaginationPage, (state, { payload }) => {
      state.tables.projects.paginationModel.page = payload;
    });

    builder.addCase(setKeywordPaginationPage, (state, { payload }) => {
      state.projectExpandedGraphs.keywordRankings.table.paginationModel.page =
        payload;
    });

    builder.addCase(getCheckFrequencyThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getCheckFrequencyThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.checkFrequency = payload;
    });
    builder.addCase(getCheckFrequencyThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(getLanguagesThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getLanguagesThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.languages = payload;
    });
    builder.addCase(getLanguagesThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(getLanguagesBingThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getLanguagesBingThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.languagesBing = payload;
    });
    builder.addCase(getLanguagesBingThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(getLanguagesYoutubeThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(
      getLanguagesYoutubeThunk.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.languagesYoutube = payload;
      }
    );
    builder.addCase(getLanguagesYoutubeThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(getLanguagesYahooThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getLanguagesYahooThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.languagesYahoo = payload;
    });
    builder.addCase(getLanguagesYahooThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(getLanguagesBaiduThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getLanguagesBaiduThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.languagesBaidu = payload;
    });
    builder.addCase(getLanguagesBaiduThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(getGoogleDomainsThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getGoogleDomainsThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.googleDomains = payload;
    });
    builder.addCase(getGoogleDomainsThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(getDeviceTypesThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getDeviceTypesThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.deviceTypes = payload;
    });
    builder.addCase(getDeviceTypesThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(getAccountTagsThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getAccountTagsThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.accountTags = payload;
    });
    builder.addCase(getAccountTagsThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(newProjectThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(newProjectThunk.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(newProjectThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(newProjectForGoogleLocalThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(newProjectForGoogleLocalThunk.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(newProjectForGoogleLocalThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(newProjectForGoogleMapsThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(newProjectForGoogleMapsThunk.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(newProjectForGoogleMapsThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(newProjectForYoutubeThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(newProjectForYoutubeThunk.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(newProjectForYoutubeThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(newProjectForBingThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(newProjectForBingThunk.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(newProjectForBingThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(newProjectForYahooThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(newProjectForYahooThunk.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(newProjectForYahooThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(newProjectForBaiduThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(newProjectForBaiduThunk.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(newProjectForBaiduThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(onGetCurrentProjectThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(
      onGetCurrentProjectThunk.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.currentProject = payload;
      }
    );
    builder.addCase(onGetCurrentProjectThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(editProjectThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(editProjectThunk.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(editProjectThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(addKeywordsThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(addKeywordsThunk.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(addKeywordsThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(setRowSelectionModel, (state, { payload }) => {
      state.tables.projects.rowSelectionModel = payload;
    });

    builder.addCase(setRowKeywordsSelectionModel, (state, { payload }) => {
      state.projectExpandedGraphs.keywordRankings.table.rowSelectionModel =
        payload;
    });

    builder.addCase(getProjectExpandedThunk.pending, (state, action) => {
      state.isLoadingProjectExpanded = true;

      if (action?.meta?.arg?.projectId !== state?.projectExpanded?.id) {
        state.projectExpandedGraphs = defaultProjectExpandedGraphs;
      }

      state.projectExpanded = null;
    });
    builder.addCase(getProjectExpandedThunk.fulfilled, (state, { payload }) => {
      state.isLoadingProjectExpanded = false;

      state.projectExpanded = payload.data;
      state.projectExpandedGraphs.deviceType = payload.data.deviceType.name;
    });
    builder.addCase(getProjectExpandedThunk.rejected, state => {
      state.isLoadingProjectExpanded = false;
    });

    builder.addCase(addTagsThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(addTagsThunk.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(addTagsThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(
      getListAvailableProjectsThunk.fulfilled,
      (state, { payload }) => {
        state.projectsListForInvite = payload;
      }
    );

    builder.addCase(setIsShowImprovedVsDeclined, (state, { payload }) => {
      state.showBlocks.isShowImprovedVsDeclined = payload;
    });

    builder.addCase(setIsShowPositionHistory, (state, { payload }) => {
      state.showBlocks.isShowPositionHistory = payload;
    });

    builder.addCase(
      setImprovedVsDeclinedVisibilityModel,
      (state, { payload }) => {
        state.showImprovedVsDeclinedVisibilityModel[payload.key] =
          payload.value;
      }
    );

    builder.addCase(getProjectsOverviewThunk.pending, state => {
      state.projectExpandedGraphs.overview = null;
    });

    builder.addCase(
      getProjectsOverviewThunk.fulfilled,
      (state, { payload }) => {
        state.projectExpandedGraphs.overview = payload;
        if (payload.fromDate && payload.toDate) {
          state.projectExpandedGraphs.rangeDate = {
            startDate: dayjs(payload.fromDate).toDate(),
            endDate: dayjs(payload.toDate).toDate(),
            key: 'selection',
          } as Range;
        }
      }
    );

    builder.addCase(setProjectExpandedGraphsRangeDate, (state, { payload }) => {
      state.projectExpandedGraphs.rangeDate = payload;
    });

    builder.addCase(setKeywordTrendsVisibilityModel, (state, { payload }) => {
      state.showKeywordTrendsModel[payload.key] = payload.value;
    });

    builder.addCase(setDeviceTypeForProjectExpanded, (state, { payload }) => {
      state.projectExpandedGraphs.deviceType = payload;
    });

    builder.addCase(getProjectsImprovedVsDeclinedThunk.pending, state => {
      state.projectExpandedGraphs.projectImprovedVsDeclined = null;
      state.projectExpandedGraphs.isLoadingProjectImprovedVsDeclined = true;
    });
    builder.addCase(
      getProjectsImprovedVsDeclinedThunk.fulfilled,
      (state, { payload }) => {
        state.projectExpandedGraphs.projectImprovedVsDeclined = payload;
        state.projectExpandedGraphs.isLoadingProjectImprovedVsDeclined = false;
      }
    );
    builder.addCase(getProjectsImprovedVsDeclinedThunk.rejected, state => {
      state.projectExpandedGraphs.isLoadingProjectImprovedVsDeclined = false;
    });

    builder.addCase(setColumnKeywordPositionModel, (state, { payload }) => {
      state.projectExpandedGraphs.keywordRankings.table.keywordPositionModel =
        payload.items;
    });

    builder.addCase(setSearchKeywordRankings, (state, { payload }) => {
      state.projectExpandedGraphs.keywordRankings.table.search = payload;
    });

    builder.addCase(setActivePeriodImprovedVsDeclined, (state, { payload }) => {
      state.projectExpandedGraphs.activePeriodImprovedVsDeclined = payload;
    });

    builder.addCase(getProjectsKeywordTrendsThunk.pending, state => {
      state.projectExpandedGraphs.isLoadingProjectKeywordsTrends = true;
    });
    builder.addCase(
      getProjectsKeywordTrendsThunk.fulfilled,
      (state, { payload }) => {
        state.projectExpandedGraphs.projectKeywordTrends = payload;
        state.projectExpandedGraphs.isLoadingProjectKeywordsTrends = false;
      }
    );
    builder.addCase(getProjectsKeywordTrendsThunk.rejected, state => {
      state.projectExpandedGraphs.isLoadingProjectKeywordsTrends = false;
    });

    builder.addCase(setActivePeriodKeywordTrends, (state, { payload }) => {
      state.projectExpandedGraphs.activeKeywordTrends = payload;
    });

    builder.addCase(
      getKeywordPositionHistoryThunk.fulfilled,
      (state, { payload }) => {
        const keywordAnalytics =
          state.projectExpandedGraphs.keywordRankings.keywordsAnalytics.find(
            item => item.id === payload.id
          );

        if (keywordAnalytics?.id) {
          state.projectExpandedGraphs.keywordRankings.keywordsAnalytics = [
            ...state.projectExpandedGraphs.keywordRankings.keywordsAnalytics,
          ].map(item => {
            if (item.id === payload.id) {
              return {
                ...keywordAnalytics,
                keywordPositionHistory: payload.keywordPositionHistory,
                competitors: payload.competitors,
                showCompetitors: payload.showCompetitors,
              };
            }
            return item;
          });
        } else {
          state.projectExpandedGraphs.keywordRankings.keywordsAnalytics = [
            ...state.projectExpandedGraphs.keywordRankings.keywordsAnalytics,
            { ...payload },
          ];
        }
      }
    );

    builder.addCase(setCloseKeywordPositionHistory, (state, { payload }) => {
      state.projectExpandedGraphs.keywordRankings.keywordsAnalytics =
        state.projectExpandedGraphs.keywordRankings.keywordsAnalytics.filter(
          item => item.id !== payload
        );
    });

    builder.addCase(setKeywordAnalyticPeriod, (state, { payload }) => {
      const {
        projectExpandedGraphs: {
          keywordRankings: { keywordsAnalytics },
        },
      } = state;
      keywordsAnalytics.map((item, index) => {
        if (payload.id === item.id) {
          state.projectExpandedGraphs.keywordRankings.keywordsAnalytics[
            index
          ].activePeriod.value = payload.period.value;
          state.projectExpandedGraphs.keywordRankings.keywordsAnalytics[
            index
          ].activePeriod.label = payload.period.label;

          return item;
        } else {
          return item;
        }
      });
    });

    builder.addCase(getKeywordRankingsThunk.pending, state => {
      state.projectExpandedGraphs.isLoadingProjectKeywordRankings = true;
      state.projectExpandedGraphs.keywordRankings.keywordRanking =
        {} as KeywordRankingState;
    });
    builder.addCase(getKeywordRankingsThunk.fulfilled, (state, { payload }) => {
      state.projectExpandedGraphs.isLoadingProjectKeywordRankings = false;
      state.projectExpandedGraphs.keywordRankings.keywordRanking = payload;
      state.projectExpandedGraphs.keywordRankings.table.paginationModel.page =
        getPage(
          state.projectExpandedGraphs.keywordRankings.table.paginationModel
            .page,
          payload.meta.currentPage,
          payload.meta.totalPages
        );
    });
    builder.addCase(getKeywordRankingsThunk.rejected, state => {
      state.projectExpandedGraphs.isLoadingProjectKeywordRankings = false;
    });

    builder.addCase(deleteKeywordsThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteKeywordsThunk.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deleteKeywordsThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(setActivePeriodProjectPerformance, (state, { payload }) => {
      state.projectExpandedGraphs.activePeriodProjectPerformance = payload;
    });

    builder.addCase(setProjectPerformanceRangeDate, (state, { payload }) => {
      state.projectExpandedGraphs.projectPerformance.rangeDate = payload;
    });

    builder.addCase(setProjectPerformanceIsShowNotes, (state, { payload }) => {
      state.projectExpandedGraphs.projectPerformance.isShowNotes = payload;
    });

    builder.addCase(
      setProjectPerformanceIsLinearGraph,
      (state, { payload }) => {
        state.projectExpandedGraphs.projectPerformance.isLinearGraph = payload;
      }
    );

    builder.addCase(
      getKeywordSearchResultsThunk.fulfilled,
      (state, { payload }) => {
        const keywordAnalytics =
          state.projectExpandedGraphs.keywordRankings.keywordsAnalytics.find(
            item => item.id === payload.id
          );

        if (keywordAnalytics?.id) {
          state.projectExpandedGraphs.keywordRankings.keywordsAnalytics = [
            ...state.projectExpandedGraphs.keywordRankings.keywordsAnalytics,
          ].map(item => {
            if (item.id === payload.id) {
              return {
                ...keywordAnalytics,
                searchResult: payload.state,
              };
            }
            return item;
          });
        }
      }
    );

    builder.addCase(getKeywordInfoThunk.fulfilled, (state, { payload }) => {
      state.keywordRankHistory.keywordInfo = payload;
      state.keywordRankHistory.showCompetitors = payload.competitors.map(
        item => item.id
      );
    });

    builder.addCase(
      getSingleKeywordPositionHistoryThunk.fulfilled,
      (state, { payload }) => {
        state.keywordRankHistory.keywordPositionHistory = payload;
      }
    );

    builder.addCase(setActivePeriodKeywordRankHistory, (state, { payload }) => {
      state.keywordRankHistory.activePeriod = payload;
    });

    builder.addCase(getKeywordPositionsInfoThunk.pending, state => {
      state.keywordRankHistory.keywordPositionsInfo = null;
    });

    builder.addCase(
      getKeywordPositionsInfoThunk.fulfilled,
      (state, { payload }) => {
        state.keywordRankHistory.keywordPositionsInfo = payload;
      }
    );

    builder.addCase(setSortModelKeywordRankHistory, (state, { payload }) => {
      state.keywordRankHistory.tableState.sortModel = payload;
    });

    builder.addCase(
      setKeywordRankHistoryPaginationPageSize,
      (state, { payload }) => {
        state.keywordRankHistory.tableState.paginationModel.pageSize = payload;
      }
    );

    builder.addCase(
      setKeywordRankHistoryPaginationPage,
      (state, { payload }) => {
        state.keywordRankHistory.tableState.paginationModel.page = payload;
      }
    );

    builder.addCase(
      setKeywordRankHistoryIsLinearGraph,
      (state, { payload }) => {
        state.keywordRankHistory.isLinearGraph = payload;
      }
    );

    builder.addCase(setPositionHistoryLinearGraph, (state, { payload }) => {
      const keywordAnalytics =
        state.projectExpandedGraphs.keywordRankings.keywordsAnalytics.find(
          item => item.id === payload.id
        );

      if (keywordAnalytics?.id) {
        state.projectExpandedGraphs.keywordRankings.keywordsAnalytics = [
          ...state.projectExpandedGraphs.keywordRankings.keywordsAnalytics,
        ].map(item => {
          if (item.id === payload.id) {
            return {
              ...keywordAnalytics,
              isLinearGraph: payload.value,
            };
          }
          return item;
        });
      }
    });

    builder.addCase(getProjectPerformanceThunk.pending, state => {
      state.projectExpandedGraphs.isLoadingProjectPerformance = true;
    });
    builder.addCase(
      getProjectPerformanceThunk.fulfilled,
      (state, { payload }) => {
        state.projectExpandedGraphs.projectPerformance.graphData = payload;
        state.projectExpandedGraphs.isLoadingProjectPerformance = false;
      }
    );
    builder.addCase(getProjectPerformanceThunk.rejected, state => {
      state.projectExpandedGraphs.isLoadingProjectPerformance = false;
    });

    builder.addCase(setShowCompetitorsForGraph, (state, { payload }) => {
      state.projectExpandedGraphs.projectPerformance.showCompetitors =
        payload.values;
    });

    builder.addCase(
      setFrequencyFilterForProjectsTable,
      (state, { payload }) => {
        state.tables.projects.frequencyFilter = payload.frequency;
      }
    );

    builder.addCase(resetProjectsState, state => {
      return (state = initialState);
    });

    builder.addCase(
      updateKeywordPositionThunk.rejected,
      (state, { meta: { arg } }) => {
        state.projectExpandedGraphs.keywordRankings.keywordRanking.items = [
          ...state.projectExpandedGraphs.keywordRankings.keywordRanking.items.map(
            keyword => {
              if (keyword.id === arg.keywordId) {
                keyword.positionUpdate = false;
              }
              return keyword;
            }
          ),
        ];
      }
    );

    builder.addCase(resetKeywordPositionModel, state => {
      state.projectExpandedGraphs.keywordRankings.table.keywordPositionModel =
        DEFAULT_KEYWORD_POSITION_MODEL;
    });

    builder.addCase(closeProjectExpandedPage, state => {
      state.projectExpandedGraphs = defaultProjectExpandedGraphs;
      state.projectExpanded = null;
    });

    builder.addCase(setUpdatedKeywords, (state, { payload }) => {
      if (
        state?.projectExpandedGraphs?.keywordRankings?.keywordRanking?.items
      ) {
        const existingItems = [
          ...state.projectExpandedGraphs.keywordRankings.keywordRanking.items,
        ];

        const updatedItems = payload.map(item => ({
          ...item,
          id: Number(item.id),
        }));

        const mergedItems = existingItems.map(item => {
          const updatedItem = updatedItems.find(upd => upd.id === item.id);
          return updatedItem ? { ...item, ...updatedItem } : item;
        });

        state.projectExpandedGraphs.keywordRankings.keywordRanking.items =
          mergedItems;
      }
    });

    builder.addCase(
      setShowCompetitorsForPositionHistory,
      (state, { payload }) => {
        const keywordsAnalyticIndex =
          state.projectExpandedGraphs.keywordRankings.keywordsAnalytics.findIndex(
            item => item.id === payload.keywordAnalyticId
          );

        state.projectExpandedGraphs.keywordRankings.keywordsAnalytics[
          keywordsAnalyticIndex
        ].showCompetitors = payload.showCompetitors;
      }
    );

    builder.addCase(searchLocationsThunk.pending, state => {
      state.searchLocations.meta.loading = true;
    });
    builder.addCase(searchLocationsThunk.fulfilled, (state, { payload }) => {
      state.searchLocations.items = payload.items;
      state.searchLocations.meta.loading = false;
    });
    builder.addCase(searchLocationsThunk.rejected, state => {
      state.searchLocations.meta.loading = false;
    });

    builder.addCase(setSearchLocationsMeta, (state, { payload }) => {
      state.searchLocations.meta = payload;
    });

    builder.addCase(setDeleteElementsProjectsTable, (state, { payload }) => {
      state.tables.projects.deleteElements = payload.deleteElements;
    });

    builder.addCase(setStartOfKeywordUpdate, (state, { payload }) => {
      if (
        state?.projectExpandedGraphs?.keywordRankings?.keywordRanking?.items
      ) {
        const keywordIdsSet = new Set(payload.keywordIds);

        const updatedItems =
          state.projectExpandedGraphs.keywordRankings.keywordRanking.items.map(
            item => ({
              ...item,
              positionUpdate: keywordIdsSet.has(item.id)
                ? true
                : item.positionUpdate,
              updateAllowed: keywordIdsSet.has(item.id)
                ? false
                : item.updateAllowed,
            })
          );

        state.projectExpandedGraphs.keywordRankings.keywordRanking.items =
          updatedItems;
      }

      if (state.projectExpanded?.id && !state.projectExpanded?.isUpdated) {
        state.projectExpanded.isUpdated = true;
      }
    });

    builder.addCase(
      setDeleteElementsKeywordRankingsTable,
      (state, { payload }) => {
        state.projectExpandedGraphs.keywordRankings.table.deleteElements =
          payload.deleteElements;
      }
    );

    builder.addCase(
      setShowCompetitorsForKeywordRankHistory,
      (state, { payload }) => {
        state.keywordRankHistory.showCompetitors = payload.showCompetitors;
      }
    );

    builder.addCase(updateProjects, (state, { payload }) => {
      if (state?.projects?.items) {
        const arr1 = [...state.projects.items];
        const arr2 = [...payload.projects];

        arr2.forEach(item2 => {
          const indexToUpdate = arr1.findIndex(
            item1 => Number(item1.id) === Number(item2.id)
          );

          if (indexToUpdate !== -1) {
            arr1[indexToUpdate] = {
              ...item2,
              id: Number(item2.id),
            };
          }
        });

        state.projects.items = arr1;
      }
    });

    builder.addCase(getKeywordTagsThunk.fulfilled, (state, { payload }) => {
      state.projectExpandedGraphs.keywordTags = payload;
    });

    builder.addCase(resetKeywordRankingsState, state => {
      state.projectExpandedGraphs.keywordRankings = defaultKeywordRankings;
    });

    builder.addCase(geLocationsOfChinaThunk.pending, state => {
      state.locationsOfChina.meta.loading = true;
    });
    builder.addCase(geLocationsOfChinaThunk.fulfilled, (state, { payload }) => {
      state.locationsOfChina.items = payload.items;
      state.locationsOfChina.meta.loading = false;
    });
    builder.addCase(geLocationsOfChinaThunk.rejected, state => {
      state.locationsOfChina.meta.loading = false;
    });

    builder.addCase(setLocationsOfChinaMeta, (state, { payload }) => {
      state.locationsOfChina.meta = payload;
    });

    builder.addCase(geLocationsBingThunk.pending, state => {
      state.locationsBing.meta.loading = true;
    });
    builder.addCase(geLocationsBingThunk.fulfilled, (state, { payload }) => {
      state.locationsBing.items = payload.items;
      state.locationsBing.meta.loading = false;
    });
    builder.addCase(geLocationsBingThunk.rejected, state => {
      state.locationsBing.meta.loading = false;
    });

    builder.addCase(setLocationsBingMeta, (state, { payload }) => {
      state.locationsBing.meta = payload;
    });

    builder.addCase(geLocationsYoutubeThunk.pending, state => {
      state.locationsYoutube.meta.loading = true;
    });
    builder.addCase(geLocationsYoutubeThunk.fulfilled, (state, { payload }) => {
      state.locationsYoutube.items = payload.items;
      state.locationsYoutube.meta.loading = false;
    });
    builder.addCase(geLocationsYoutubeThunk.rejected, state => {
      state.locationsYoutube.meta.loading = false;
    });

    builder.addCase(setLocationsYoutubeMeta, (state, { payload }) => {
      state.locationsYoutube.meta = payload;
    });

    builder.addCase(geLocationsYahooThunk.pending, state => {
      state.locationsYahoo.meta.loading = true;
    });
    builder.addCase(geLocationsYahooThunk.fulfilled, (state, { payload }) => {
      state.locationsYahoo.items = payload.items;
      state.locationsYahoo.meta.loading = false;
    });
    builder.addCase(geLocationsYahooThunk.rejected, state => {
      state.locationsYahoo.meta.loading = false;
    });

    builder.addCase(setLocationsYahooMeta, (state, { payload }) => {
      state.locationsYahoo.meta = payload;
    });

    builder.addCase(geLocationsBaiduThunk.pending, state => {
      state.locationsBaidu.meta.loading = true;
    });
    builder.addCase(geLocationsBaiduThunk.fulfilled, (state, { payload }) => {
      state.locationsBaidu.items = payload.items;
      state.locationsBaidu.meta.loading = false;
    });
    builder.addCase(geLocationsBaiduThunk.rejected, state => {
      state.locationsBaidu.meta.loading = false;
    });

    builder.addCase(setLocationsBaiduMeta, (state, { payload }) => {
      state.locationsBaidu.meta = payload;
    });

    builder.addCase(setRefreshAllKeywordsModal, (state, { payload }) => {
      state.modals.refreshAllKeywords = payload;
    });

    builder.addCase(setRefreshKeywordsModal, (state, { payload }) => {
      state.modals.refreshKeywords = payload;
    });

    builder.addCase(setNewProjectModal, (state, { payload }) => {
      state.modals.newProject = payload;
    });
  },
});

export const { selectTags, showTableFilters, selectKeywordTags } =
  projectsSlice.actions;
export default projectsSlice.reducer;
