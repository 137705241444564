import React from 'react';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  ButtonsWrapper,
  CloseDeletePopoverOne,
  CustomModal,
  HeaderContainer,
  ModalContainer,
} from '../../styles';
import CloseIcon from '@mui/icons-material/Close';
import { PrimaryButton } from '../../../../../../../../shared/buttons/styles';
import { ConfirmYourEmailChangeModalProps } from './types';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ConfirmYourEmailChangeModal: React.FC<
  ConfirmYourEmailChangeModalProps
> = ({ open, handleClose }) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <CustomModal>
          <ModalContainer>
            <HeaderContainer>
              <CloseDeletePopoverOne color='error' onClick={handleClose}>
                <CloseIcon fontSize='small' />
              </CloseDeletePopoverOne>
              <ErrorOutlineIcon fontSize='large' color='error' />
              <Typography variant='h6' fontWeight='600' paddingTop='29px'>
                Confirm Your Email Change
              </Typography>
            </HeaderContainer>
            <div>
              <Typography
                fontWeight='500'
                fontSize='14px'
                lineHeight='24px'
                color='#475467'
                paddingTop='30px'
                paddingBottom='30px'
              >
                <b>Step 1:</b> Click the link sent to your old email to confirm
                it’s you.
              </Typography>
              <Typography
                fontWeight='500'
                fontSize='14px'
                lineHeight='24px'
                color='#475467'
              >
                <b>Step 2:</b> Once confirmed, click the verification link sent
                to your new email to finalize the update.
              </Typography>
            </div>
            <ButtonsWrapper container xs={12}>
              <Grid item xs={extraSmallScreen && 12}>
                <PrimaryButton
                  fullWidth={extraSmallScreen}
                  onClick={handleClose}
                  variant='contained'
                  color='primary'
                >
                  Got It
                </PrimaryButton>
              </Grid>
            </ButtonsWrapper>
          </ModalContainer>
        </CustomModal>
      </Fade>
    </Modal>
  );
};

export default ConfirmYourEmailChangeModal;
