import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { StyledHeader } from './styles';

/**
 * Invoice component that displays the header and invoice table, and handles fetching invoices for the current account.
 *
 * @component
 * @returns {JSX.Element} Rendered Invoice component.
 */
const Header = () => {
  return (
    <StyledHeader xs={12}>
      <Grid>
        <Typography
          fontWeight='600'
          color='#101828'
          fontSize='18px'
          lineHeight={'28px'}
        >
          Invoice
        </Typography>
      </Grid>
    </StyledHeader>
  );
};

export default Header;
