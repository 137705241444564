import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import SerpNestLogo from '../../../../shared/images/SerpNestLogo';
import ROUTES from '../../../../routes/constants';

import { GrayButton } from '../../../../shared/buttons/styles';
import { Paper } from '../../../../shared/paper/styles';
import { LogoContainer, SingUpLabel, Wrapper } from './styles';

/**
 * Component representing the third step of the forget password process.
 *
 * @component
 * @returns {JSX.Element} The ForgetPasswordStep3 component.
 */
const ForgetPasswordStep3 = () => {
  const navigate = useNavigate();

  /**
   * Redirects the user to the login page.
   */
  const onBack = () => {
    navigate(ROUTES.login);
  };

  return (
    <Wrapper>
      <Grid container>
        <Grid xs={1} lg={4} md={2} />
        <Grid xs={10} lg={4} md={8}>
          <Paper
            sx={{
              p: 4,
            }}
          >
            <LogoContainer>
              <SerpNestLogo />
            </LogoContainer>
            <SingUpLabel variant='h5'>Contact Support</SingUpLabel>
            <Typography variant='body2' sx={{ mt: 1, mb: 1 }}>
              If you still haven’t received the email, please contact support.
            </Typography>
            <GrayButton
              outline
              fullWidth
              variant='text'
              sx={{
                mt: 2,
              }}
            >
              Contact Support
            </GrayButton>
            <GrayButton
              fullWidth
              variant='text'
              sx={{
                mt: 2,
              }}
              startIcon={<ArrowBackIcon />}
              onClick={onBack}
            >
              Back to log in
            </GrayButton>
          </Paper>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default ForgetPasswordStep3;
