import React from 'react';
import { EllipsisListActionsProps } from '../../types';
import { MainContainer } from '../../styles';
import { List } from '@mui/material';
import RefreshAllKeywordsAction from '../../components/RefreshAllKeywordsAction';

const ListActions: React.FC<EllipsisListActionsProps> = ({
  itemId,
  itemType,
  popupClose,
  keywordCount,
}) => {
  return (
    <MainContainer>
      <List>
        <RefreshAllKeywordsAction
          keywordCount={keywordCount}
          itemId={itemId}
          itemType={itemType}
          popupClose={popupClose}
        />
      </List>
    </MainContainer>
  );
};

export default ListActions;
