import React from 'react';

interface Props {
  stroke?: string;
}

const ProjectsIcon: React.FC<Props> = ({ stroke }) => {
  const clipPathValue = 'url(#clip0_1786_111303)';

  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g style={{ clipPath: clipPathValue }}>
        <path
          d='M1.3335 11.3334L8.00016 14.6667L14.6668 11.3334'
          stroke={stroke || 'rgb(102, 112, 133)'}
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M1.3335 8L8.00016 11.3333L14.6668 8'
          stroke={stroke || 'rgb(102, 112, 133)'}
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.00016 1.33337L1.3335 4.66671L8.00016 8.00004L14.6668 4.66671L8.00016 1.33337Z'
          stroke={stroke || 'rgb(102, 112, 133)'}
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};

export default ProjectsIcon;
