import { styled } from '@mui/system';
import { Grid } from '@mui/material';

const SelectProjectStyles = styled(Grid)`
  .MuiInputBase-input {
    padding-top: 7.94px;
    padding-bottom: 7.94px;
  }
`;

export { SelectProjectStyles };
