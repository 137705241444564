import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import * as Tooltips from '../../../Toltips';
import InfoIcon from '../../../../../../../../shared/images/InfoIcon';
import { CustomTooltip } from '../../../../../../../../shared/tooltip/styles';
import { PERIOD } from '../../../../../../constants';
import { useProjects } from '../../../../../../../../hooks';
import { TimesGroup } from '../../../../../index';
import { GraphOptions } from './components';
import { useAppSelector } from '../../../../../../../../store';

const Header = () => {
  const { onSetActivePeriodProjectPerformance } = useProjects();

  const activePeriodProjectPerformance = useAppSelector(
    state => state.projects.projectExpandedGraphs.activePeriodProjectPerformance
  );

  const timesGroupItems = useMemo(() => {
    return PERIOD.map(period => ({
      label: period.label,
      value: period.value,
      isActive: period.value === activePeriodProjectPerformance.value,
      onChange: () => {
        onSetActivePeriodProjectPerformance(period);
      },
    }));
  }, [
    activePeriodProjectPerformance.value,
    onSetActivePeriodProjectPerformance,
  ]);

  return (
    <Grid xs={12}>
      <Grid xs={12}>
        <CustomTooltip
          title={<Tooltips.ProjectPerformance />}
          placement='top-start'
          arrow
        >
          <strong>
            <span
              style={{
                color: '#101828',
                fontSize: '18px',
                fontWeight: '700',
              }}
            >
              Project Performance <InfoIcon />
            </span>
          </strong>
        </CustomTooltip>
      </Grid>
      <Grid
        container
        item
        xs={12}
        justifyContent={'space-between'}
        alignItems={'center'}
        marginBottom={'24px'}
      >
        <Grid item marginTop={'24px'}>
          <TimesGroup items={timesGroupItems} />
        </Grid>
        <Grid item marginTop={'24px'}>
          <Grid item container spacing={2} alignItems={'center'}>
            <Grid item>
              <GraphOptions />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
