import React from 'react';
import { EllipsisListActionsProps } from '../../../../../Projects/components/TableProjects/components/EllipsisMenu/types';
import { MainContainer } from '../../../../../Projects/components/TableProjects/components/EllipsisMenu/styles';
import { Divider, List } from '@mui/material';
import OpenInNewTabAction from '../../../../../Projects/components/TableProjects/components/EllipsisMenu/components/OpenInNewTabAction';
import RefreshAllKeywordsAction from '../../../../../Projects/components/TableProjects/components/EllipsisMenu/components/RefreshAllKeywordsAction';
import EditProjectTagsAction from '../../../../../Projects/components/TableProjects/components/EllipsisMenu/components/EditProjectTagsAction';
import EditFrequencyAction from '../../../../../Projects/components/TableProjects/components/EllipsisMenu/components/EditFrequencyAction';
import MoveToFolderAction from '../../../../../Projects/components/TableProjects/components/EllipsisMenu/components/MoveToFolderAction';
import DeleteProjectAction from '../../../../../Projects/components/TableProjects/components/EllipsisMenu/components/DeleteProjectAction';
import { AddKeywordsAction, EditProjectAction } from './actions';
import DownloadProjectInfoToCSVAction from '../../../../../Projects/components/TableProjects/components/EllipsisMenu/components/DownloadProjectInfoToCSVAction';

const ListActions: React.FC<EllipsisListActionsProps> = ({
  projectId,
  keywordCount,
  popupClose,
  disableExportCSV,
}) => {
  return (
    <MainContainer>
      <List>
        <OpenInNewTabAction projectId={projectId} popupClose={popupClose} />
        <AddKeywordsAction projectId={projectId} popupClose={popupClose} />
        <RefreshAllKeywordsAction
          projectId={projectId}
          keywordCount={keywordCount}
          popupClose={popupClose}
        />
        <EditProjectAction projectId={projectId} popupClose={popupClose} />

        <EditProjectTagsAction projectId={projectId} popupClose={popupClose} />
        <EditFrequencyAction projectId={projectId} popupClose={popupClose} />
        <MoveToFolderAction projectIds={[projectId]} popupClose={popupClose} />
        <DownloadProjectInfoToCSVAction
          projectId={projectId}
          popupClose={popupClose}
          disableExportCSV={disableExportCSV}
        />

        <Divider
          orientation='horizontal'
          flexItem
          sx={{
            margin: '8px 0',
            color: '#EDEDED',
          }}
        />

        <DeleteProjectAction projectId={projectId} popupClose={popupClose} />
      </List>
    </MainContainer>
  );
};

export default ListActions;
