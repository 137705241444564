import { styled } from '@mui/material/styles';

const StyledErrorList = styled('ul')`
  color: #475467;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  margin: 0;
  padding: 0 0 0 20px;
`;

export { StyledErrorList };
