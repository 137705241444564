import React from 'react';
import { Grid } from '@mui/material';
import DownIcon from '../images/DownIcon';

const SelectIcon = () => (
  <Grid
    container
    alignItems='center'
    justifyContent='center'
    width='28px'
    height='28px'
  >
    <Grid item>
      <DownIcon stroke='#667085' />
    </Grid>
  </Grid>
);

export default SelectIcon;
