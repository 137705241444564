import { styled } from '@mui/material/styles';
import { Grid, TablePagination } from '@mui/material';

import { Paper } from '../../../../../../../shared/paper/styles';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getPositionTableHeader } from '../../../../../../../utils';

const CustomDataGrid = styled(DataGridPro)<{
  $isModalTable?: boolean;
  $hardPositionTop?: string;
  $openTariffPlanBanner: boolean;
}>`
  max-width: 100%;
  --unstable_DataGrid-radius: 8px;

  border-radius: 8px;
  border: 1px solid #dedede;
  background: #fff;
  box-shadow: 0px 5px 3px -2px rgba(0, 0, 0, 0.02),
    0px 3px 1px -2px rgba(0, 0, 0, 0.06);

  .MuiDataGrid-virtualScroller,
  .MuiDataGrid-overlayWrapperInner {
    min-height: 400px;
  }

  .MuiDataGrid-columnHeaders {
    line-height: initial !important;
    position: ${props => `${props.$isModalTable ? '' : 'sticky'}`};
    top: ${props =>
      props.$hardPositionTop ||
      getPositionTableHeader(props.$openTariffPlanBanner, props.$isModalTable)};
    z-index: ${props => `${props.$isModalTable ? '' : '5'}`};

    @media (max-width: 599px) {
      top: ${props =>
        props.$hardPositionTop || `${props.$isModalTable ? '' : '57px'}`};
    }
  }

  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  .MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
  }

  .MuiDataGrid-columnHeaders {
    -moz-border-radius-topleft: 8px;
    -moz-border-radius-topright: 8px;
    background-color: #fafafa;
  }

  .MuiDataGrid-columnSeparator {
    opacity: 0 !important;
  }

  .MuiDataGrid-row:hover {
    background-color: #f6f6f6 !important;
  }

  .MuiDataGrid-row.Mui-selected {
    background-color: #e8f1fd;
  }

  .MuiDataGrid-footerContainer {
    display: none;
  }

  .MuiDataGrid-main {
    z-index: 10;
    overflow: ${props => `${props.$isModalTable ? '' : 'visible'}`};
    border-radius: 8px;
  }

  .MuiDataGrid-row--lastVisible {
    .MuiDataGrid-cell {
      border-bottom-color: #dedede !important;
    }
  }
`;

const TeamCountLabel = styled('span')`
  padding: 4px 8px;
  border-radius: 16px;
  border: 1px solid #a0c5f6;
  background: #eff8ff;
  color: #0e59ba;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;

const ModalWrapper = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 384px;
  max-height: 95%;
  overflow: auto;

  ${props => props.theme.breakpoints.down('sm')} {
    transform: translate(calc(-50% - 24px), -50%);
    width: calc(100% - 48px);
    margin-right: 24px;
    margin-left: 24px;
  }
`;

const ModalContainer = styled(Grid)`
  padding: 24px;
  ${props => props.theme.breakpoints.down('sm')} {
    padding: 16px;
  }
`;

const StyledTablePagination = styled(TablePagination)`
  color: #344054;
  font-size: 14px;
  line-height: 24px;
  border-bottom: none;

  .MuiTablePagination-toolbar {
    padding: 0 !important;
  }
  .MuiSelect-icon {
    right: 9px !important;
  }

  .MuiTablePagination-select {
    width: 38px;
    padding: 4px 14px !important;
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #344054;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: flex-start;
  }

  .MuiInputBase-input:focus {
    border-radius: 8px !important;
    border: 1px solid #d0d5dd !important;
    background: #fff !important;
  }

  .MuiTablePagination-actions {
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    margin-left: 8px !important;

    button {
      padding: 4px 8px;

      :first-child {
        border-right: 1px solid #eaecf0;
        border-radius: 0;
      }
    }
  }

  .MuiTablePagination-displayedRows {
    color: #344054;
    font-size: 14px;
    line-height: 24px;
  }

  .MuiTablePagination-selectLabel {
    + div {
      margin-right: 8px;
    }
  }
`;

const ButtonsWrapper = styled(Grid)`
  height: fit-content;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 32px;

  > div {
    :nth-child(2) {
      margin-left: 8px;
    }
  }

  ${props => props.theme.breakpoints.down('sm')} {
    padding-top: 24px;
    flex-direction: column-reverse;

    > div {
      :nth-child(2) {
        margin-left: 0;
      }

      :nth-child(1) {
        margin-top: 8px;
      }
    }
  }
`;

const RoleLabelContainer = styled('div')<{
  backgroundColor: string;
  color: string;
}>(({ backgroundColor, color }) => ({
  backgroundColor: backgroundColor,
  color: color,
  padding: '2px 8px',
  borderRadius: '16px',
  fontSize: '12px',
  fontWeight: '500',
  lineHeight: '18px',
}));

const HeaderRightTolls = styled(Grid)`
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 810px) {
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-top: 18px;

    > div {
      :nth-child(2) {
        margin-right: 18px;
      }
    }

    @media (max-width: 599px) {
      flex-direction: initial;
      margin-top: 0;

      > div {
        :nth-child(2) {
          margin-right: 0;
          > button {
            height: 32px;
          }
        }
      }
    }
  }
`;

const CustomTableHeaderTollsWrapper = styled(Grid)`
  flex-direction: row;
  align-items: center;

  @media (min-width: 810px) {
    > div {
      :nth-child(1) {
        max-width: 33.333333%;
      }
      :nth-child(2) {
        max-width: 66.666667%;
      }
    }
  }
`;

export {
  CustomDataGrid,
  TeamCountLabel,
  ModalWrapper,
  ModalContainer,
  StyledTablePagination,
  ButtonsWrapper,
  RoleLabelContainer,
  HeaderRightTolls,
  CustomTableHeaderTollsWrapper,
};
