import React, { useEffect, useMemo, useState } from 'react';
import {
  Fade,
  FormHelperText,
  Grid,
  IconButton,
  Modal,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import {
  IDeviceType,
  NewProjectModalProps,
  AccountTag,
  IAddKeywordsValue,
} from '../../../../../types';
import useProjects from '../../../../../hooks/useProjects';
import { enqueueSnackbar } from 'notistack';
import { useAuth } from '../../../../../../../hooks';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { closeSnackbarAction } from '../../../../../../../utils/snackbar';

import { CloseDeletePopoverOne } from '../../../../../../auth/components/Settings/components/Account/styles';
import { ButtonsWrapper } from '../../../../../../auth/components/Settings/components/Team/components/customTableStyles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../../../shared/buttons/styles';
import { CustomSwitch } from '../../../../../../../shared/Switch/styles';
import { CustomTooltip } from '../../../../../../../shared/tooltip/styles';
import {
  DeviceTypeContainer,
  ModalContainer,
  ModalWrapper,
  DeviceType,
  AddTagsInput,
  AddedTag,
  AddTagsContainer,
} from '../styles';
import { Popover } from '../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';

import CustomToast from '../../../../../../../shared/CustomToast/CustomToast';
import { CustomFormHelperText } from '../../../../../../../shared/components';
import * as Tooltips from '../Tooltips';
import HelperIcon from '../../../../../../../shared/images/HelperIcon';
import DesktopIcon from '../../../../../../../shared/images/DesctopIcon';
import PhoneIcon from '../../../../../../../shared/images/PhoneIcon';
import DesktopAndMobileIcon from '../../../../../../../shared/images/DesctopAndMobileIcon';
import CloseIcon from '@mui/icons-material/Close';
import CloseIconTag from '../../../../../../../shared/images/CloseIcon';
import TagIcon from '../../../../../../../shared/images/TagIcon';
import AddTagsPopup from '../AddTagsPopup';
import * as Yup from 'yup';
import {
  keywordsSymbolsLimit,
  validateKeywords,
} from '../../../../../../../constants/serpKeywords';
import { useAppSelector } from '../../../../../../../store';
import { ModalOutlinedInput } from '../../../../../../../shared/modal/styled';
import { useNavigate } from 'react-router';
import ROUTES from '../../../../../../../routes/constants';
import useAccountLimits from '../../../../../../auth/hooks/useAccountLimits';
import TagsPreKeywordsExceededModal from '../../../../../../../shared/components/TagsPreKeywordsExceededModal/TagsPreKeywordsExceededModal';
import DailyRefreshesLimitExceededModal from '../../../../../../../shared/components/DailyRefreshesLimitExceededModal/DailyRefreshesLimitExceededModal';
import NotificationDeviceType from '../../../../NotificationDeviceType/NotificationDeviceType';

const AddKeywordsModal: React.FC<NewProjectModalProps> = ({
  open,
  handleClose,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const navigate = useNavigate();

  const { onGetMyAccount } = useAuth();

  const {
    onAddKeywords,
    onGetAccountTags,
    onGetProjects,
    onGetProjectExtended,
    onGetDeviceTypes,
  } = useProjects();

  const {
    onCheckKeywordLimit,
    onCloseTagsPerKeywordLimitExceededModal,
    onCheckTagsPerKeywordLimit,
    onCloseRefreshesLimitLimitExceededModal,
    onCheckCannotAddExcessKeywords,
    onOpenKeywordUpdateScheduledModal,
  } = useAccountLimits();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const deviceTypes = useAppSelector(state => state.projects.deviceTypes);
  const currentProject = useAppSelector(state => state.projects.currentProject);
  const selectedTags = useAppSelector(state => state.projects.selectedTags);
  const paginationModel = useAppSelector(
    state => state.projects.tables.projects.paginationModel
  );
  const search = useAppSelector(state => state.projects.tables.projects.search);
  const sortModel = useAppSelector(
    state => state.projects.tables.projects.sortModel
  );
  const frequencyFilter = useAppSelector(
    state => state.projects.tables.projects.frequencyFilter
  );
  const columnVisibilityModel = useAppSelector(
    state => state.projects.tables.projects.columnVisibilityModel
  );
  const deviceType = useAppSelector(
    state => state.projects.projectExpandedGraphs.deviceType
  );
  const dailyRefreshesLimitExceededOpen = useAppSelector(
    state => state.auth.accountLimitErrors.dailyRefreshesLimitExceeded.open
  );
  const tagsPreKeywordsLimitExceededOpen = useAppSelector(
    state => state.auth.accountLimitErrors.tagsPerKeywordLimitExceeded.open
  );

  const keywordTags = useMemo(
    () => currentProject?.keywordTags || [],
    [currentProject?.keywordTags]
  );

  const [isBulkKeywords, setIsBulkKeywords] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);
  const [openTooltip, setOpenTooltip] = useState<number | undefined>();

  const refTagsInput = React.useRef<HTMLInputElement>(null);

  const onClose = () => {
    handleClose();
    formik.resetForm();
  };

  const onToggleKeywords = () => setIsBulkKeywords(!isBulkKeywords);

  const onOpenTooltip = (tooltipIndex?: number) => {
    setShowTooltip(true);
    setOpenTooltip(tooltipIndex);
  };

  const onClickTooltip = (tooltipIndex?: number) => {
    if (showTooltip) {
      setShowTooltip(false);
      setOpenTooltip(undefined);
    } else {
      setShowTooltip(true);
      setOpenTooltip(tooltipIndex);
    }
  };

  const getDeviceTypeIcon = (deviceName: string) => {
    switch (deviceName) {
      case 'Desktop':
        return <DesktopIcon />;
      case 'Mobile':
        return <PhoneIcon />;
      case 'DesktopAndMobile':
        return <DesktopAndMobileIcon />;
      default:
        return;
    }
  };

  const formik = useFormik({
    initialValues: {
      keywords: [''] as string[],
      tagIds: [] as AccountTag[],
      tags: [] as string[],
      deviceType: (currentProject?.deviceType || null) as IDeviceType,
      message: '',
    },
    validationSchema: Yup.object().shape({
      keywords: Yup.array().of(
        Yup.string()
          .max(
            keywordsSymbolsLimit,
            `Keywords must not exceed ${keywordsSymbolsLimit} characters. Please reduce the length of keywords exceeding this limit.`
          )
          .test('forbidden-word', (value, { createError, path }) =>
            validateKeywords(value, { createError, path })
          )
      ),
    }),
    enableReinitialize: true,
    onSubmit: (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
      const filterKeywordsLength = values.keywords.filter(item =>
        /\S/.test(item)
      ).length;
      const isDesktopAndMobile = values.deviceType.name === 'DesktopAndMobile';
      const googleMyBusinessOrYouTube =
        currentProject?.searchEngine?.name === 'Google My Business' ||
        currentProject?.searchEngine?.name === 'YouTube';

      const checkKeywordLimit = onCheckKeywordLimit();
      let checkCannotAddExcessKeywords;
      if (!checkKeywordLimit) {
        checkCannotAddExcessKeywords = onCheckCannotAddExcessKeywords(
          filterKeywordsLength,
          isDesktopAndMobile,
          googleMyBusinessOrYouTube,
          currentProject?.searchEngine?.name || ''
        );
      }
      const tagsPerKeywordError = onCheckTagsPerKeywordLimit(
        values.tags.filter(item => /\S/.test(item)).length +
          values.tagIds.length
      );

      if (
        currentProject &&
        !checkKeywordLimit &&
        !checkCannotAddExcessKeywords &&
        !tagsPerKeywordError
      ) {
        const requestData: IAddKeywordsValue = {
          keywords: values.keywords.filter(Boolean),
          deviceType: values.deviceType.name,
        };
        if (values.tags.length) requestData.tags = values.tags;
        if (values.tagIds.length)
          requestData.tagIds = values.tagIds.map(item => item.id);

        onAddKeywords({
          accountId: currentAccount.accountId,
          projectId: currentProject.id,
          data: requestData,
          successFn: keywordUpdateWasSkipped => {
            if (keywordUpdateWasSkipped) {
              onOpenKeywordUpdateScheduledModal();
            }
            resetForm();
            handleClose();
            onGetProjects({
              accountId: currentAccount.accountId,
              params: {
                frequencyName: frequencyFilter,
                page: paginationModel.page,
                limit: paginationModel.pageSize,
                tagIds: selectedTags.map(item => item.id).join(),
                search: search,
                sortBy: sortModel[0]?.field,
                sortOrder: sortModel[0]?.sort,
                dailyAverage: columnVisibilityModel.dailyAverage,
              },
            });
            onGetAccountTags({ accountId: currentAccount.accountId });
            onGetMyAccount(currentAccount.accountId);
            onGetProjectExtended({
              accountId: currentAccount.accountId,
              projectId: currentProject.id,
              keywordDeviceType: deviceType,
              notes: true,
            });
            enqueueSnackbar(
              <CustomToast
                message='Success'
                submessage='New keywords added successfully.'
              />,
              { action: closeSnackbarAction }
            );
          },
          errorFn: (error: any) => {
            setStatus({ success: false });
            setSubmitting(false);
            setErrors(error);
          },
        });
      } else {
        setStatus({ success: false });
        setSubmitting(false);
      }
    },
  });

  const ErrorForBulkImport = useMemo(() => {
    const errors = formik.errors?.keywords;
    if (formik.touched.keywords && errors && typeof errors !== 'string') {
      const uniqueErrors = errors.filter(
        (item, index) => errors.indexOf(item) === index
      );
      return uniqueErrors.map((item, index) =>
        uniqueErrors?.length && uniqueErrors[index] ? (
          <FormHelperText key={index} error id='keywords'>
            <CustomFormHelperText error={uniqueErrors[index]} />
          </FormHelperText>
        ) : null
      );
    }
  }, [formik.errors?.keywords, formik.touched.keywords]);

  const handleCloseRefreshesLimitExceededModal = () => {
    onCloseRefreshesLimitLimitExceededModal();
    formik.resetForm();
  };

  const handleDailyRefreshesUpgradeAccount = () => {
    onCloseRefreshesLimitLimitExceededModal();
    navigate(ROUTES.upgradePlan);
  };

  const handleCloseTagsPerKeywordLimitExceededModal = () => {
    onCloseTagsPerKeywordLimitExceededModal();
    formik.resetForm();
  };

  const handleTagsPerKeywordUpgradeAccount = () => {
    onCloseTagsPerKeywordLimitExceededModal();
    navigate(ROUTES.upgradePlan);
  };

  const filterDeviceTypes = useMemo(() => {
    if (
      currentProject &&
      (currentProject.searchEngine.name === 'YouTube' ||
        currentProject.searchEngine.name === 'Google My Business' ||
        currentProject.searchEngine.name === 'Google Maps')
    ) {
      return deviceTypes.filter(item => item.name === 'Desktop');
    }
    return deviceTypes;
  }, [deviceTypes, currentProject?.searchEngine?.name]);

  const projectName = useMemo(() => {
    return `${currentProject?.projectName} ${
      currentProject?.url ? `(${currentProject?.url})` : ''
    } `;
  }, [currentProject?.projectName, currentProject?.url]);

  useEffect(() => {
    const clearedEmpty = formik.values.keywords.filter(item => /\S/.test(item));
    if (clearedEmpty.length) {
      formik.setFieldValue('keywords', clearedEmpty);
    } else {
      formik.setFieldValue('keywords', ['']);
    }
  }, [isBulkKeywords]);

  useEffect(() => {
    !deviceTypes.length && onGetDeviceTypes();
  }, [deviceTypes.length, onGetDeviceTypes]);

  if (!currentProject) {
    return null;
  }

  return (
    <>
      <Modal
        aria-labelledby='add-keywords-modal-title'
        aria-describedby='add-keywords-description'
        open={open}
        onClose={onClose}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <form onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
              <ModalWrapper>
                <ModalContainer container xs={12} sx={{ gap: '8px' }}>
                  <Grid item xs={12} sx={{ position: 'relative' }}>
                    <CloseDeletePopoverOne onClick={onClose}>
                      <CloseIcon fontSize='small' />
                    </CloseDeletePopoverOne>
                    <Typography
                      fontSize='18px'
                      fontWeight='600'
                      lineHeight='28px'
                      color='#101828'
                    >
                      Add Keywords
                    </Typography>
                  </Grid>

                  <Grid item xs={12} paddingTop='8px' marginTop='16px'>
                    <Grid
                      container
                      fontWeight='500'
                      alignItems='center'
                      fontSize='14px'
                      gap='6px'
                    >
                      <Grid item>Project Name</Grid>
                      <ModalOutlinedInput
                        size='small'
                        fullWidth
                        type='text'
                        id='projectName'
                        name='projectName'
                        value={projectName}
                        disabled
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid
                      container
                      fontWeight='500'
                      alignItems='center'
                      fontSize='14px'
                    >
                      <Grid item>Keywords</Grid>
                      <CustomSwitch
                        checked={isBulkKeywords}
                        onChange={onToggleKeywords}
                        sx={{ margin: '0 12px' }}
                      />
                      <Grid item>Bulk Input</Grid>
                      <Grid item>
                        <CustomTooltip
                          title={<Tooltips.Keywords />}
                          placement='top-start'
                          arrow
                          open={showTooltip && openTooltip === 1}
                          onOpen={() => onOpenTooltip(1)}
                          onClose={() => onClickTooltip(1)}
                          onClick={() => onClickTooltip(1)}
                        >
                          <IconButton onClick={() => onClickTooltip(1)}>
                            <HelperIcon />
                          </IconButton>
                        </CustomTooltip>
                      </Grid>

                      <FieldArray
                        name='keywords'
                        render={arrayHelpers => (
                          <Grid
                            width='100%'
                            display='flex'
                            gap='6px'
                            flexDirection='column'
                          >
                            {!isBulkKeywords ? (
                              formik.values.keywords.map((item, index) => (
                                <Grid
                                  key={index}
                                  onClick={() => {
                                    if (
                                      formik.values.keywords.length ===
                                      index + 1
                                    ) {
                                      arrayHelpers.push('');
                                    }
                                  }}
                                >
                                  <ModalOutlinedInput
                                    size='small'
                                    fullWidth
                                    type='text'
                                    name={`keywords[${index}]`}
                                    placeholder='Enter keywords'
                                    value={formik.values.keywords[index]}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={
                                      !!(
                                        formik.touched.keywords &&
                                        formik.errors?.keywords &&
                                        formik.errors?.keywords[index]
                                      )
                                    }
                                  />
                                  {formik.touched.keywords &&
                                    formik.errors?.keywords &&
                                    typeof formik.errors?.keywords !==
                                      'string' &&
                                    formik.errors?.keywords[index] && (
                                      <FormHelperText error id='keywords'>
                                        <CustomFormHelperText
                                          error={formik.errors?.keywords[index]}
                                        />
                                      </FormHelperText>
                                    )}
                                </Grid>
                              ))
                            ) : (
                              <>
                                <OutlinedInput
                                  multiline
                                  rows={4}
                                  size='small'
                                  fullWidth
                                  type='text'
                                  name={`keywords[0]`}
                                  placeholder='Enter keywords to track (one per line)'
                                  value={formik.values.keywords.join('\n')}
                                  onBlur={formik.handleBlur}
                                  onChange={event => {
                                    formik.setFieldValue(
                                      'keywords',
                                      event.target.value.split('\n')
                                    );
                                  }}
                                  error={
                                    !!(
                                      formik.touched.keywords &&
                                      formik.errors?.keywords
                                    )
                                  }
                                />
                                {ErrorForBulkImport}
                              </>
                            )}
                          </Grid>
                        )}
                      />
                    </Grid>
                    {formik.touched.keywords &&
                      typeof formik.errors?.keywords === 'string' && (
                        <FormHelperText error id='keywords'>
                          <CustomFormHelperText
                            error={formik.errors?.keywords as string}
                          />
                        </FormHelperText>
                      )}
                  </Grid>

                  <Grid item xs={12}>
                    <Grid
                      container
                      fontWeight='500'
                      alignItems='center'
                      fontSize='14px'
                    >
                      <Grid item>Search Device Type</Grid>
                      <Grid item>
                        <CustomTooltip
                          title={<Tooltips.SearchDeviceType />}
                          placement='top-start'
                          arrow
                          open={showTooltip && openTooltip === 5}
                          onOpen={() => onOpenTooltip(5)}
                          onClose={() => onClickTooltip(5)}
                          onClick={() => onClickTooltip(5)}
                        >
                          <IconButton onClick={() => onClickTooltip(5)}>
                            <HelperIcon />
                          </IconButton>
                        </CustomTooltip>
                      </Grid>
                    </Grid>
                    <DeviceTypeContainer>
                      {filterDeviceTypes.map(item => (
                        <DeviceType
                          key={item.name}
                          isSelected={
                            formik.values.deviceType.name === item.name
                          }
                          onClick={() => {
                            formik.setFieldValue('deviceType', item);
                          }}
                        >
                          <>
                            {getDeviceTypeIcon(item.name)}
                            <Typography component='span'>
                              {item.name === 'DesktopAndMobile'
                                ? 'Desktop & Mobile'
                                : item.name}
                            </Typography>
                          </>
                        </DeviceType>
                      ))}
                    </DeviceTypeContainer>
                  </Grid>

                  {formik.values.deviceType.name === 'DesktopAndMobile' ? (
                    <Grid item xs={12}>
                      <NotificationDeviceType />
                    </Grid>
                  ) : null}

                  <Grid item xs={12} paddingTop='8px'>
                    <Grid
                      container
                      fontWeight='500'
                      alignItems='center'
                      fontSize='14px'
                      gap='8px'
                    >
                      <Grid item>Tags (Optional)</Grid>
                      <PopupState variant='popover' popupId='add-tag-setting'>
                        {popupState => (
                          <>
                            <AddTagsInput
                              ref={refTagsInput}
                              {...bindTrigger(popupState)}
                            >
                              {formik.values.tags.length
                                ? formik.values.tags.map(item => (
                                    <AddedTag key={item}>
                                      <Typography component='span'>
                                        {item}
                                      </Typography>
                                      <Typography
                                        component='p'
                                        sx={{ fontSize: '0' }}
                                        onClick={() =>
                                          formik.setFieldValue(
                                            'tags',
                                            formik.values.tags.filter(
                                              filterItem => filterItem !== item
                                            )
                                          )
                                        }
                                      >
                                        <CloseIconTag width='12' height='12' />
                                      </Typography>
                                    </AddedTag>
                                  ))
                                : null}

                              {formik.values.tagIds.length
                                ? formik.values.tagIds.map(item => (
                                    <AddedTag key={item.id}>
                                      <Typography component='span'>
                                        {item.name}
                                      </Typography>
                                      <Typography
                                        component='p'
                                        sx={{ fontSize: '0' }}
                                        onClick={() =>
                                          formik.setFieldValue(
                                            'tagIds',
                                            formik.values.tagIds.filter(
                                              filterItem =>
                                                filterItem.id !== item.id
                                            )
                                          )
                                        }
                                      >
                                        <CloseIconTag width='12' height='12' />
                                      </Typography>
                                    </AddedTag>
                                  ))
                                : null}

                              <AddTagsContainer>
                                <TagIcon />
                                <Typography component='span'>
                                  Add a Tag
                                </Typography>
                              </AddTagsContainer>
                            </AddTagsInput>
                            <Popover
                              {...bindPopover(popupState)}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              sx={{
                                boxShadow:
                                  '0px 12px 16px -4px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.06)',
                                borderRadius: '8px',
                                marginTop: '7px',
                              }}
                            >
                              <AddTagsPopup
                                closePopover={popupState.close}
                                tags={formik.values.tags}
                                tagIds={formik.values.tagIds}
                                listTags={keywordTags}
                                setFieldValue={formik.setFieldValue}
                                refTagsInput={refTagsInput}
                              />
                            </Popover>
                          </>
                        )}
                      </PopupState>
                    </Grid>
                  </Grid>

                  {formik.errors.message && (
                    <Grid item xs={12}>
                      <FormHelperText
                        error={!!formik.errors.message}
                        id='message'
                      >
                        {formik.errors.message && (
                          <CustomFormHelperText error={formik.errors.message} />
                        )}
                      </FormHelperText>
                    </Grid>
                  )}

                  <ButtonsWrapper container xs={12} sx={{ paddingTop: '8px' }}>
                    <Grid item xs={extraSmallScreen && 12}>
                      <GrayButtonSmall
                        fullWidth={extraSmallScreen}
                        size='small'
                        outline
                        onClick={handleClose}
                      >
                        Cancel
                      </GrayButtonSmall>
                    </Grid>
                    <Grid item xs={extraSmallScreen && 12}>
                      <PrimaryButtonSmall
                        fullWidth={extraSmallScreen}
                        variant='contained'
                        size='small'
                        type='submit'
                        disabled={
                          formik.isSubmitting ||
                          !formik.isValid ||
                          !formik.values.keywords.filter(Boolean).length
                        }
                      >
                        {`Add Keywords (${
                          formik.values.keywords.filter(Boolean).length
                        })`}
                      </PrimaryButtonSmall>
                    </Grid>
                  </ButtonsWrapper>
                </ModalContainer>
              </ModalWrapper>
            </FormikProvider>
          </form>
        </Fade>
      </Modal>
      <DailyRefreshesLimitExceededModal
        onClose={handleCloseRefreshesLimitExceededModal}
        onUpgradeAccount={handleDailyRefreshesUpgradeAccount}
        open={dailyRefreshesLimitExceededOpen}
      />
      <TagsPreKeywordsExceededModal
        onClose={handleCloseTagsPerKeywordLimitExceededModal}
        onUpgradeAccount={handleTagsPerKeywordUpgradeAccount}
        open={tagsPreKeywordsLimitExceededOpen}
      />
    </>
  );
};

export default AddKeywordsModal;
