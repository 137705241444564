import React from 'react';

const GoogleMapsIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='19'
    height='18'
    viewBox='0 0 19 18'
    fill='none'
  >
    <path
      d='M1.45508 6.72502C1.45508 4.76481 1.45508 3.78472 1.83655 3.03603C2.17211 2.37747 2.70755 1.84204 3.36611 1.50648C4.1148 1.125 5.09489 1.125 7.0551 1.125H11.6051C13.5653 1.125 14.5454 1.125 15.294 1.50648C15.9526 1.84204 16.4881 2.37747 16.8236 3.03603C17.2051 3.78472 17.2051 4.76481 17.2051 6.72502V11.275C17.2051 13.2352 17.2051 14.2153 16.8236 14.964C16.4881 15.6225 15.9526 16.158 15.294 16.4935C14.5454 16.875 13.5653 16.875 11.6051 16.875H7.0551C5.09489 16.875 4.1148 16.875 3.36611 16.4935C2.70755 16.158 2.17211 15.6225 1.83655 14.964C1.45508 14.2153 1.45508 13.2352 1.45508 11.275V6.72502Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.8301 7.20652L13.83 7.23043C13.83 7.2405 13.8299 7.25057 13.8297 7.26064C13.83 7.27239 13.8301 7.28426 13.8301 7.29619C13.8301 9.17286 12.368 10.834 11.3896 11.9456C11.1057 12.2682 10.8626 12.5445 10.7076 12.7663C10.1566 13.5555 9.8658 14.4103 9.78925 14.7391C9.78925 14.9867 9.58371 15.1875 9.33008 15.1875C9.07645 15.1875 8.87091 14.9867 8.87091 14.7391C8.79435 14.4103 8.50354 13.5555 7.95252 12.7663C7.7976 12.5445 7.55443 12.2682 7.27054 11.9456C6.29218 10.834 4.83008 9.17286 4.83008 7.29619C4.83008 7.28426 4.83019 7.27239 4.83042 7.26064C4.83019 7.24264 4.83008 7.22458 4.83008 7.20652C4.83008 4.77976 6.84478 2.8125 9.33008 2.8125C11.8154 2.8125 13.8301 4.77976 13.8301 7.20652ZM9.33008 8.82067C10.2938 8.82067 11.075 8.05781 11.075 7.11686C11.075 6.17586 10.2938 5.41304 9.33008 5.41304C8.3664 5.41304 7.5852 6.17586 7.5852 7.11686C7.5852 8.05781 8.3664 8.82067 9.33008 8.82067Z'
      fill='#34A851'
    />
    <path
      d='M13.3275 5.18599C12.7764 4.14925 11.8147 3.35405 10.6528 3.00586L8.06836 5.94139C8.38612 5.61628 8.8342 5.41375 9.33078 5.41375C10.2945 5.41375 11.0757 6.17658 11.0757 7.11753C11.0757 7.50143 10.9457 7.85564 10.7263 8.14054L13.3275 5.18599Z'
      fill='#4285F5'
    />
    <path
      d='M7.3219 12.0053C7.30463 11.9857 7.28725 11.9659 7.2697 11.946C6.6248 11.2133 5.7697 10.2418 5.26172 9.12712L7.94785 6.07617C7.71993 6.36406 7.58431 6.72518 7.58431 7.11725C7.58431 8.0582 8.36557 8.82106 9.32924 8.82106C9.81738 8.82106 10.2587 8.62531 10.5754 8.3098L7.3219 12.0053Z'
      fill='#F9BB0E'
    />
    <path
      d='M5.89482 4.36816C5.23062 5.13386 4.83008 6.12456 4.83008 7.20658C4.83008 7.22464 4.83019 7.2427 4.83042 7.2607C4.83019 7.27245 4.83008 7.28432 4.83008 7.29625C4.83008 7.93587 4.99993 8.55051 5.26258 9.12679L7.94419 6.08097L5.89482 4.36816Z'
      fill='#E74335'
    />
    <path
      d='M10.6518 3.00518C10.2338 2.87993 9.78985 2.8125 9.32979 2.8125C7.95262 2.8125 6.71995 3.41655 5.89453 4.36811L7.9439 6.08091L7.94834 6.07584C7.98564 6.02876 8.02535 5.98359 8.06737 5.94062L10.6518 3.00518Z'
      fill='#1A73E6'
    />
  </svg>
);

export default GoogleMapsIcon;
