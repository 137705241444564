import React, { useMemo } from 'react';

import {
  StyledColumnField,
  StyledShortInfo,
  StyledColumnValueText,
  StyledShortInfoItem,
} from './styles';
import { Link } from 'react-router-dom';
import ROUTES from '../../../../../../routes/constants';
import { Skeleton } from '@mui/material';
import TextTooltip from '../../../../../../shared/components/TextTooltip';
import { popperProps } from '../../../../hooks/useKeywordRankingsTable';
import { CustomTooltip } from '../../../../../../shared/tooltip/styles';
import { useAppSelector } from '../../../../../../store';

const ShortInfo = () => {
  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );

  const isRenderRegion = useMemo(() => {
    return (
      projectExpanded &&
      projectExpanded.searchEngine.name !== 'YouTube' &&
      projectExpanded.searchEngine.name !== 'Yahoo' &&
      projectExpanded.searchEngine.name !== 'Google My Business' &&
      projectExpanded.searchEngine.name !== 'Bing' &&
      projectExpanded.searchEngine.name !== 'Baidu' &&
      projectExpanded.searchEngine.name !== 'Google Maps'
    );
  }, [projectExpanded?.searchEngine?.name]);

  const isRenderURL = useMemo(() => {
    return (
      projectExpanded &&
      projectExpanded.searchEngine.name !== 'Google My Business' &&
      projectExpanded.searchEngine.name !== 'Google Maps'
    );
  }, [projectExpanded?.searchEngine?.name]);

  const isRenderBusinessNameAndURL = useMemo(() => {
    return (
      projectExpanded &&
      (projectExpanded.searchEngine.name === 'Google My Business' ||
        projectExpanded.searchEngine.name === 'Google Maps')
    );
  }, [projectExpanded?.searchEngine?.name]);

  return (
    <StyledShortInfo container xs={12}>
      <StyledShortInfoItem lg={1.33} sm={2.4} xs={4}>
        <StyledColumnField>Keywords</StyledColumnField>
        <StyledColumnValueText>
          {!projectExpanded ? (
            <Skeleton height={'26px'} width={'20px'} variant='text' />
          ) : (
            projectExpanded.keywordCount
          )}
        </StyledColumnValueText>
      </StyledShortInfoItem>

      {isRenderBusinessNameAndURL && (
        <>
          <StyledShortInfoItem lg={1.33} sm={2.4} xs={4}>
            <StyledColumnField>Business Name</StyledColumnField>
            {!projectExpanded ? (
              <Skeleton height={'26px'} width={'79px'} variant='text' />
            ) : (
              <CustomTooltip
                title={
                  <TextTooltip text={projectExpanded?.businessName || 'N/A'} />
                }
                placement='top-start'
                arrow
                PopperProps={popperProps}
              >
                <StyledColumnValueText>
                  {projectExpanded?.businessName || 'N/A'}
                </StyledColumnValueText>
              </CustomTooltip>
            )}
          </StyledShortInfoItem>

          <StyledShortInfoItem lg={1.33} sm={2.4} xs={4}>
            <StyledColumnField>Business URL</StyledColumnField>
            {!projectExpanded ? (
              <Skeleton height={'26px'} width={'79px'} variant='text' />
            ) : (
              <CustomTooltip
                title={<TextTooltip text={projectExpanded?.url || 'N/A'} />}
                placement='top-start'
                arrow
                PopperProps={popperProps}
              >
                <StyledColumnValueText>
                  {projectExpanded?.url || 'N/A'}
                </StyledColumnValueText>
              </CustomTooltip>
            )}
          </StyledShortInfoItem>
        </>
      )}

      {isRenderURL && (
        <StyledShortInfoItem lg={1.33} sm={2.4} xs={4}>
          <StyledColumnField>URL</StyledColumnField>
          {!projectExpanded ? (
            <Skeleton height={'26px'} width={'79px'} variant='text' />
          ) : (
            <CustomTooltip
              title={<TextTooltip text={projectExpanded?.url} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              <StyledColumnValueText>
                {projectExpanded?.url}
              </StyledColumnValueText>
            </CustomTooltip>
          )}
        </StyledShortInfoItem>
      )}
      {isRenderRegion && (
        <StyledShortInfoItem lg={1.33} sm={2.4} xs={4}>
          <StyledColumnField>Region</StyledColumnField>
          <StyledColumnValueText>
            {!projectExpanded ? (
              <Skeleton height={'26px'} width={'79px'} variant='text' />
            ) : (
              projectExpanded?.region?.name
            )}
          </StyledColumnValueText>
        </StyledShortInfoItem>
      )}
      <StyledShortInfoItem lg={1.33} sm={2.4} xs={4}>
        <StyledColumnField>Language</StyledColumnField>
        <StyledColumnValueText>
          {!projectExpanded ? (
            <Skeleton height={'26px'} width={'20px'} variant='text' />
          ) : (
            projectExpanded?.language?.code
          )}
        </StyledColumnValueText>
      </StyledShortInfoItem>
      <StyledShortInfoItem lg={1.33} sm={2.4} xs={4}>
        <StyledColumnField>Location</StyledColumnField>
        <StyledColumnValueText>
          {!projectExpanded ? (
            <Skeleton height={'26px'} width={'20px'} variant='text' />
          ) : (
            projectExpanded?.location || 'N/A'
          )}
        </StyledColumnValueText>
      </StyledShortInfoItem>
      <StyledShortInfoItem lg={1.33} sm={2.4} xs={4}>
        <StyledColumnField>Device</StyledColumnField>
        <StyledColumnValueText>
          {!projectExpanded ? (
            <Skeleton height={'26px'} width={'110px'} variant='text' />
          ) : projectExpanded?.deviceType?.name ? (
            projectExpanded?.deviceType?.name === 'DesktopAndMobile' ? (
              'Desktop & Mobile'
            ) : (
              projectExpanded?.deviceType?.name
            )
          ) : (
            ''
          )}
        </StyledColumnValueText>
      </StyledShortInfoItem>
      <StyledShortInfoItem lg={1.33} sm={2.4} xs={4}>
        <StyledColumnField>Search Engine</StyledColumnField>
        <StyledColumnValueText>
          {!projectExpanded ? (
            <Skeleton height={'26px'} width={'110px'} variant='text' />
          ) : (
            projectExpanded?.searchEngine?.name
          )}
        </StyledColumnValueText>
      </StyledShortInfoItem>
      <StyledShortInfoItem lg={1.33} sm={2.4} xs={4}>
        <StyledColumnField>Triggers</StyledColumnField>
        <StyledColumnValueText>
          {!projectExpanded ? (
            <Skeleton height={'26px'} width={'20px'} variant='text' />
          ) : (
            <Link to={ROUTES.alerts}>{projectExpanded?.triggerCount || 0}</Link>
          )}
        </StyledColumnValueText>
      </StyledShortInfoItem>
      <StyledShortInfoItem lg={1.33} sm={2.4} xs={4}>
        <StyledColumnField>Email Reports</StyledColumnField>
        <StyledColumnValueText>
          {!projectExpanded ? (
            <Skeleton height={'26px'} width={'20px'} variant='text' />
          ) : (
            <Link to={ROUTES.scheduledEmailReports}>
              {projectExpanded?.emailReportCount || 0}
            </Link>
          )}
        </StyledColumnValueText>
      </StyledShortInfoItem>
    </StyledShortInfo>
  );
};

export default ShortInfo;
