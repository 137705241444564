import { Outlet } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import { MUI_X_PRO_LICENSE_KEY } from '../../../constants/config';

const SharedLinksLayout = () => {
  useEffect(() => {
    LicenseInfo.setLicenseKey(MUI_X_PRO_LICENSE_KEY);
  }, []);

  return (
    <Grid xs={12} bgcolor={'#FAFAFA'} minHeight={'100vh'}>
      <Outlet />
    </Grid>
  );
};

export default SharedLinksLayout;
