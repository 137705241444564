import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getTransactionToUpdatePaymentMethod,
  getUpdateSubscriptionReview,
  reactivateSubscription,
  subscriptionUpdate,
  unsubscription,
} from '../api';
import {
  GetTransactionToUpdatePaymentMethodParams,
  GetUpdateSubscriptionReviewParams,
  IUpdateSubscriptionReview,
  ReactivateSubscriptionParams,
  SubscriptionUpdateParams,
  SwitchToAnnualPaymentParams,
  UnsubscriptionParams,
} from '../types';
import { getTariffPlansAPI } from '../../auth/api';
import { AxiosError } from 'axios';
import { checkAndRedirectToMaintenancePage } from '../../../utils';

export const getTransactionToUpdatePaymentMethodThunk = createAsyncThunk<
  undefined,
  GetTransactionToUpdatePaymentMethodParams
>('getTransactionToUpdatePaymentMethodThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getTransactionToUpdatePaymentMethod(params);

    params.successFn(data);
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const switchToAnnualPaymentThunk = createAsyncThunk<
  undefined,
  SwitchToAnnualPaymentParams
>('switchToAnnualPaymentThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getTariffPlansAPI(params.type);
    params.successFn(data.items);
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const unsubscriptionThunk = createAsyncThunk<
  undefined,
  UnsubscriptionParams
>('unsubscriptionThunk', async (params, thunkAPI) => {
  try {
    await unsubscription(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        params.errorFn(errors);
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const reactivateSubscriptionThunk = createAsyncThunk<
  undefined,
  ReactivateSubscriptionParams
>('reactivateSubscriptionThunk', async (params, thunkAPI) => {
  try {
    await reactivateSubscription(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getUpdateSubscriptionReviewThunk = createAsyncThunk<
  IUpdateSubscriptionReview,
  GetUpdateSubscriptionReviewParams
>('getUpdateSubscriptionReviewThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getUpdateSubscriptionReview(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      if (error.status === 400 && error?.response?.data?.message) {
        params.errorFn(error.response.data.message);
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const subscriptionUpdateThunk = createAsyncThunk<
  undefined,
  SubscriptionUpdateParams
>('subscriptionUpdateThunk', async (params, thunkAPI) => {
  try {
    await subscriptionUpdate(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      if (error?.response?.data?.message === 'subscription_payment_declined') {
        params.errorFn(
          'This payment was declined by your bank. Please try again, or use a different payment method. Contact your bank for further information.'
        );
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});
