import React, { useMemo } from 'react';
import { useKeywordRankHistory } from '../../../../../../hooks';
import { Grid } from '@mui/material';
import {
  Circle,
  CircleContainer,
  CircleLabel,
  CirclesContainer,
} from '../../../ProjectExpanded/components/ProjectPerformance/components/GraphLabels/styles';
import { CustomFormControlLabel } from '../../../ProjectExpanded/components/KeywordTrends/components/Header/styled';
import { CustomCheckbox } from '../../../../../../shared/checkbox/styles';
import CustomCheckboxImage from '../../../../../../shared/images/CustomCheckbox';
import CheckedIcon from '../../../../../../shared/images/CheckedIcon';
import { COMPETITORS_COLORS } from '../../../../../../shared/layout/PrivateLayout/constant';
import { useAppSelector } from '../../../../../../store';

const GraphLabels = () => {
  const {
    keywordInfo,
    showCompetitors,
    onSetShowCompetitorsForKeywordRankHistory,
  } = useKeywordRankHistory();

  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );

  const isBaseSearchEngine = useMemo(() => {
    return (
      projectExpanded &&
      projectExpanded.searchEngine.name !== 'Google My Business' &&
      projectExpanded.searchEngine.name !== 'Google Maps'
    );
  }, [projectExpanded?.searchEngine?.name]);

  const handleSetShowCompetitors = (competitorId: number, checked: boolean) => {
    let newShowCompetitors: number[];
    if (checked) {
      newShowCompetitors = [...showCompetitors, competitorId];
    } else {
      newShowCompetitors = showCompetitors.filter(
        item => item !== competitorId
      );
    }
    onSetShowCompetitorsForKeywordRankHistory(newShowCompetitors);
  };

  return (
    <Grid>
      <CirclesContainer>
        <CircleContainer>
          <Circle backgroundColor={'#1A73E9'} />
          <CircleLabel>Position</CircleLabel>
        </CircleContainer>
      </CirclesContainer>

      {keywordInfo?.competitors?.length ? (
        <Grid>
          {keywordInfo.competitors.map(competitor => {
            const label = isBaseSearchEngine
              ? competitor.domainName
              : `${competitor.businessName}${
                  competitor?.url ? ` (${competitor?.url})` : ''
                }`;
            return (
              <Grid key={competitor.id}>
                <CustomFormControlLabel
                  control={
                    <CustomCheckbox
                      checked={
                        !!showCompetitors.find(item => item === competitor.id)
                      }
                      onChange={(event, checked) => {
                        handleSetShowCompetitors(competitor.id, checked);
                      }}
                      icon={<CustomCheckboxImage />}
                      checkedIcon={
                        <CheckedIcon
                          checkedColor={
                            COMPETITORS_COLORS[
                              competitor.id % COMPETITORS_COLORS.length
                            ]
                          }
                        />
                      }
                    />
                  }
                  label={label}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : null}
    </Grid>
  );
};

export default GraphLabels;
