import { styled } from '@mui/system';
import { PrimaryButtonCustom } from '../../buttons/styles';
import { Grid } from '@mui/material';

const StylesBanner = styled(Grid)`
  z-index: 1110;
  position: fixed;
  top: 0;
  width: 100%;
  background: #f5faff;
  border-bottom: 1px solid #71A9F2;
  padding: 12px 16px;
  align-items: center;

  color: #0e59ba;
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  height: 60px;

  @media (max-width:600px) {
    padding: 12px 12px;
    height: 90px;
  },
`;

const StylesUpgradeNowButton = styled(PrimaryButtonCustom)`
  border-radius: 18px !important;
`;

export { StylesBanner, StylesUpgradeNowButton };
