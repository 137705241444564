import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React, { useState } from 'react';
import { MainContainer, StyledLinearLogSwitch } from './styled';
import { Divider, Grid } from '@mui/material';
import { useKeywordRankHistory } from '../../../../../../../../hooks';
import { GrayButtonCustom } from '../../../../../../../../shared/buttons/styles';
import DownIcon from '../../../../../../../../shared/images/DownIcon';
import { CustomSwitch } from '../../../../../../../../shared/Switch/styles';
import ExportGraphModal from '../../../../../modals/ExportGraphModal/ExportGraphModal';

import { Popover } from '../../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import DownloadIcon from '../../../../../../../../shared/images/DownloadIcon';
import { ExportGraphProps } from '../types';
import { StyledExportButton } from '../../../../../ProjectExpanded/components/KeywordRankings/components/PositionHistory/components/GraphOptions/styled';

const GraphOptions: React.FC<ExportGraphProps> = ({ refGraph }) => {
  const { onSetKeywordRankHistoryIsLinearGraph, isLinearGraph } =
    useKeywordRankHistory();

  const [isOpenExportGraphModal, setOpenExportGraphModal] = useState(false);

  const onClose = () => {
    setOpenExportGraphModal(false);
  };

  const handleSetProjectPerformanceIsLinearGraph = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    onSetKeywordRankHistoryIsLinearGraph(checked);
  };

  return (
    <>
      <PopupState variant='popover'>
        {popupState => (
          <>
            <GrayButtonCustom
              $padding='7px 12px'
              size='small'
              outline
              endIcon={<DownIcon stroke='#344054' />}
              {...bindTrigger(popupState)}
            >
              Graph Options
            </GrayButtonCustom>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MainContainer>
                <Grid xs={12}>
                  <StyledExportButton
                    color={'inherit'}
                    size='small'
                    startIcon={<DownloadIcon />}
                    onClick={() => {
                      popupState.close();
                      setOpenExportGraphModal(true);
                    }}
                  >
                    Export
                  </StyledExportButton>
                </Grid>
                <Grid>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    sx={{
                      margin: '8px 0',
                      color: '#EDEDED',
                    }}
                  />
                </Grid>
                <StyledLinearLogSwitch margin={'16px 0'}>
                  <Grid>Log</Grid>
                  <Grid>
                    <CustomSwitch
                      checked={isLinearGraph}
                      onChange={handleSetProjectPerformanceIsLinearGraph}
                    />
                  </Grid>
                  <Grid>Linear</Grid>
                </StyledLinearLogSwitch>
              </MainContainer>
            </Popover>
          </>
        )}
      </PopupState>

      <ExportGraphModal
        refGraph={refGraph}
        open={isOpenExportGraphModal}
        onClose={onClose}
      />
    </>
  );
};

export default GraphOptions;
