import { Range, StaticRange } from 'react-date-range';

const CUSTOM_STATIC_RANGES: StaticRange[] = [
  {
    label: 'Last 24 hours',
    range: () => ({
      startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
      endDate: new Date(),
    }),
    isSelected: (range: Range) => {
      const last24Hours = new Date(
        new Date().setDate(new Date().getDate() - 1)
      );
      return (
        range?.startDate?.toDateString() === last24Hours.toDateString() &&
        range?.endDate?.toDateString() === new Date().toDateString()
      );
    },
  },
  {
    label: 'Past 7 days',
    range: () => ({
      startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
      endDate: new Date(),
    }),
    isSelected: (range: Range) => {
      const past7Days = new Date(new Date().setDate(new Date().getDate() - 7));
      return (
        range?.startDate?.toDateString() === past7Days.toDateString() &&
        range?.endDate?.toDateString() === new Date().toDateString()
      );
    },
  },
  {
    label: 'Past 30 days',
    range: () => ({
      startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
      endDate: new Date(),
    }),
    isSelected: (range: Range) => {
      const past30Days = new Date(
        new Date().setDate(new Date().getDate() - 30)
      );
      return (
        range?.startDate?.toDateString() === past30Days.toDateString() &&
        range?.endDate?.toDateString() === new Date().toDateString()
      );
    },
  },
  {
    label: 'Past 60 days',
    range: () => ({
      startDate: new Date(new Date().setDate(new Date().getDate() - 60)),
      endDate: new Date(),
    }),
    isSelected: (range: Range) => {
      const past60Days = new Date(
        new Date().setDate(new Date().getDate() - 60)
      );
      return (
        range?.startDate?.toDateString() === past60Days.toDateString() &&
        range?.endDate?.toDateString() === new Date().toDateString()
      );
    },
  },
  {
    label: 'Past 90 days',
    range: () => ({
      startDate: new Date(new Date().setDate(new Date().getDate() - 90)),
      endDate: new Date(),
    }),
    isSelected: (range: Range) => {
      const past90Days = new Date(
        new Date().setDate(new Date().getDate() - 90)
      );
      return (
        range?.startDate?.toDateString() === past90Days.toDateString() &&
        range?.endDate?.toDateString() === new Date().toDateString()
      );
    },
  },
  {
    label: 'Previous month',
    range: () => {
      const today = new Date();
      const firstDayOfPreviousMonth = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        1
      );
      const lastDayOfPreviousMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );

      return {
        startDate: firstDayOfPreviousMonth,
        endDate: lastDayOfPreviousMonth,
      };
    },
    isSelected: (range: Range) => {
      const today = new Date();
      const firstDayOfPreviousMonth = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        1
      );
      const lastDayOfPreviousMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );
      return (
        range?.startDate?.toDateString() ===
          firstDayOfPreviousMonth.toDateString() &&
        range?.endDate?.toDateString() === lastDayOfPreviousMonth.toDateString()
      );
    },
  },
  {
    label: 'This year',
    range: () => {
      const today = new Date();
      const firstDayOfThisYear = new Date(today.getFullYear(), 0, 1);
      const lastDayOfThisYear = new Date(today.getFullYear() + 1, 0, 0);

      return {
        startDate: firstDayOfThisYear,
        endDate: lastDayOfThisYear,
      };
    },
    isSelected: (range: Range) => {
      const today = new Date();
      const firstDayOfThisYear = new Date(today.getFullYear(), 0, 1);
      const lastDayOfThisYear = new Date(today.getFullYear() + 1, 0, 0);
      return (
        range?.startDate?.toDateString() ===
          firstDayOfThisYear.toDateString() &&
        range?.endDate?.toDateString() === lastDayOfThisYear.toDateString()
      );
    },
  },
  {
    label: 'Last year',
    range: () => {
      const today = new Date();
      const firstDayOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
      const lastDayOfLastYear = new Date(today.getFullYear(), 0, 0);

      return {
        startDate: firstDayOfLastYear,
        endDate: lastDayOfLastYear,
      };
    },
    isSelected: (range: Range) => {
      const today = new Date();
      const firstDayOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
      const lastDayOfLastYear = new Date(today.getFullYear(), 0, 0);
      return (
        range?.startDate?.toDateString() ===
          firstDayOfLastYear.toDateString() &&
        range?.endDate?.toDateString() === lastDayOfLastYear.toDateString()
      );
    },
  },
];

export { CUSTOM_STATIC_RANGES };
