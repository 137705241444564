import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useKeywordRankHistory, useProjects } from '../../../../hooks';
import { Grid } from '@mui/material';

import {
  BreadcrumbsAdditional,
  BreadcrumbsMain,
  Graph,
  KeywordInfo,
  KeywordPositionsInfo,
} from './components';
import {
  StyledBreadcrumbsAdditional,
  StyledKeywordRankHistory,
  WrapContainer,
} from './styles';
import { decryptData } from '../../../../utils/cryptoJs';
import { useAppSelector } from '../../../../store';
import GraphLabels from './components/GraphLabels/GraphLabels';

const KeywordRankHistory = () => {
  const { projectId, keywordId } = useParams();

  const decryptProjectId = useMemo(() => {
    if (projectId) {
      return decryptData(projectId);
    }
  }, [projectId]);

  const decryptKeywordId = useMemo(() => {
    if (keywordId) {
      return decryptData(keywordId);
    }
  }, [keywordId]);

  const { onGetProjectExtended } = useProjects();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const deviceType = useAppSelector(
    state => state.projects.projectExpandedGraphs.deviceType
  );

  const {
    onGetKeywordInfo,
    onGetSingleKeywordPositionHistory,
    onGetKeywordPositionsInfo,
    activePeriod,
    tableState,
    showCompetitors,
    keywordInfo,
  } = useKeywordRankHistory();

  useEffect(() => {
    if (currentAccount?.accountId && decryptProjectId) {
      onGetProjectExtended({
        accountId: currentAccount.accountId,
        projectId: Number(decryptProjectId),
        keywordDeviceType: deviceType,
        notes: true,
      });
    }
  }, [currentAccount.accountId, onGetProjectExtended, decryptProjectId]);

  useEffect(() => {
    if (decryptProjectId && decryptKeywordId && currentAccount?.accountId) {
      onGetKeywordInfo({
        id: currentAccount.accountId,
        keywordId: Number(decryptKeywordId),
      });
    }
  }, [
    decryptProjectId,
    decryptKeywordId,
    onGetKeywordInfo,
    currentAccount.accountId,
  ]);

  useEffect(() => {
    if (
      decryptProjectId &&
      decryptKeywordId &&
      currentAccount?.accountId &&
      keywordInfo?.id
    ) {
      onGetSingleKeywordPositionHistory({
        accountId: currentAccount.accountId,
        keywordId: Number(decryptKeywordId),
        projectId: Number(decryptProjectId),
        period: activePeriod.value,
        competitorIds: showCompetitors,
      });
    }
  }, [
    onGetSingleKeywordPositionHistory,
    decryptProjectId,
    decryptKeywordId,
    currentAccount.accountId,
    keywordInfo?.id,
    activePeriod,
    showCompetitors,
  ]);

  useEffect(() => {
    if (currentAccount?.accountId && decryptKeywordId) {
      onGetKeywordPositionsInfo({
        id: currentAccount.accountId,
        keywordId: Number(decryptKeywordId),
        page: tableState.paginationModel.page + 1,
        limit: tableState.paginationModel.pageSize,
        sortBy: tableState?.sortModel[0]?.field,
        sortOrder: tableState?.sortModel[0]?.sort,
      });
    }
  }, [
    currentAccount?.accountId,
    decryptKeywordId,
    onGetKeywordPositionsInfo,
    tableState.paginationModel.page,
    tableState.paginationModel.pageSize,
    tableState?.sortModel,
  ]);

  return (
    <StyledKeywordRankHistory container xs={12}>
      <Grid container xs={12}>
        <WrapContainer item xs={12}>
          <BreadcrumbsMain />
        </WrapContainer>

        <StyledBreadcrumbsAdditional item xs={12}>
          <BreadcrumbsAdditional />
        </StyledBreadcrumbsAdditional>

        <WrapContainer item xs={12}>
          <KeywordInfo />
        </WrapContainer>

        <WrapContainer item xs={12}>
          <Graph />
          <GraphLabels />
        </WrapContainer>

        <KeywordPositionsInfo />
      </Grid>
    </StyledKeywordRankHistory>
  );
};

export default KeywordRankHistory;
