import React from 'react';
import { StyledTablePagination, PaginationContainer } from './styles';
import { useSharedLinks } from '../../../../../../../../../../../../hooks';
import { useAppSelector } from '../../../../../../../../../../../../store';

const Pagination = () => {
  const {
    onSetKeywordRankingsPaginationPageSize,
    onSetKeywordRankingsPaginationPage,
  } = useSharedLinks();

  const paginationModel = useAppSelector(
    state =>
      state.sharedLinks.projectGraphs.keywordRankings.table.paginationModel
  );
  const keywordRanking = useAppSelector(
    state => state.sharedLinks.projectGraphs.keywordRankings.keywordRanking
  );

  const handleSetPaginationPageSize = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onSetKeywordRankingsPaginationPageSize(Number(e.target.value));
  };

  const handleSetPaginationPage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    onSetKeywordRankingsPaginationPage(Number(page));
  };

  return (
    <PaginationContainer xs={12}>
      <StyledTablePagination
        labelRowsPerPage='Show rows:'
        count={keywordRanking?.meta?.totalItems || 0}
        page={paginationModel.page}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onPageChange={handleSetPaginationPage}
        rowsPerPage={paginationModel.pageSize}
        onRowsPerPageChange={handleSetPaginationPageSize}
      />
    </PaginationContainer>
  );
};

export default Pagination;
