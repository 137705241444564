import * as Yup from 'yup';
import {
  keywordsSymbolsLimit,
  validateKeywords,
} from '../../constants/serpKeywords';

/**
 * Validation schema for the project name.
 */
const projectNameValidation = Yup.string()
  .required('Project Name is required.')
  .min(3, 'Project Name must be longer than or equal to 3 characters')
  .trim()
  .matches(/\S/, 'Project Name should not contain only spaces.');

/**
 * Validation schema for the project URL type.
 */
const projectUrlTypeValidation = Yup.object().shape({
  name: Yup.string().required('Domain is required.'),
});

/**
 * Validation schema for the project URL.
 */
const urlValidation = Yup.string().required('URL is required.');

/**
 * Validation schema for the region ID.
 */
const regionIdValidation = Yup.object().shape({
  name: Yup.string().required('Google Region is required.'),
});

/**
 * Validation schema for the language ID, with conditional requirement based on advanced settings.
 * @param isOpenAdvancedSettings - Whether advanced settings are enabled.
 * @returns The language ID validation schema.
 */
const languageIdValidation = (isOpenAdvancedSettings: boolean) => {
  return Yup.object().shape({
    name: isOpenAdvancedSettings
      ? Yup.string().required('Language is required.')
      : Yup.string().notRequired(),
  });
};

/**
 * Validation schema for the check frequency, with conditional requirement based on advanced settings.
 * @param isOpenAdvancedSettings - Whether advanced settings are enabled.
 * @returns The check frequency validation schema.
 */
const checkFrequencyValidation = (isOpenAdvancedSettings: boolean) => {
  return Yup.object().shape({
    name: isOpenAdvancedSettings
      ? Yup.string().required('Check Frequency is required.')
      : Yup.string().notRequired(),
  });
};

/**
 * Validation schema for the search location.
 */
const locationValidation = Yup.object()
  .shape({
    locationName: Yup.string().required('Search Location is required.'),
  })
  .required('Search Location is required.');

/**
 * Validation schema for keywords, including character limit and custom validation.
 */
const keywordsValidation = Yup.array().of(
  Yup.string()
    .max(
      keywordsSymbolsLimit,
      `Keywords must not exceed ${keywordsSymbolsLimit} characters. Please reduce the length of keywords exceeding this limit.`
    )
    .test('forbidden-word', (value, { createError, path }) =>
      validateKeywords(value, { createError, path })
    )
);

/**
 * Validation schema for project notes.
 */
const noteValidation = Yup.string()
  .max(2500, 'Note must be at most 2,500 characters')
  .trim()
  .matches(/\S/, 'Note should not contain only spaces.');

/**
 * Validation schema for video URL.
 */
const videoUrlValidation = Yup.string().required('URL is required.');

/**
 * Validation schema for the business name.
 */
const businessNameValidation = Yup.string().required(
  'Business Name is required.'
);

/**
 * Returns the validation schema for creating a new project, based on the search engine type and whether advanced settings are open.
 * @param isOpenAdvancedSettings - Whether advanced settings are enabled.
 * @param searchEngine - The name of the search engine.
 * @returns The Yup validation schema object for the new project form.
 */
function getValidationSchemaForNewProject(
  isOpenAdvancedSettings: boolean,
  searchEngine?: string
) {
  switch (searchEngine) {
    case 'Google':
      return Yup.object().shape({
        projectName: projectNameValidation,
        projectUrlType: projectUrlTypeValidation,
        url: urlValidation,
        regionId: regionIdValidation,
        languageId: languageIdValidation(isOpenAdvancedSettings),
        checkFrequency: checkFrequencyValidation(isOpenAdvancedSettings),
        location: locationValidation,
        keywords: keywordsValidation,
        note: noteValidation,
      });
    case 'Baidu':
      return Yup.object().shape({
        projectName: projectNameValidation,
        url: urlValidation,
        projectUrlType: projectUrlTypeValidation,
        keywords: keywordsValidation,
        checkFrequency: checkFrequencyValidation(isOpenAdvancedSettings),
        location: locationValidation,
        note: noteValidation,
      });
    case 'Yahoo':
      return Yup.object().shape({
        projectName: projectNameValidation,
        url: urlValidation,
        projectUrlType: projectUrlTypeValidation,
        keywords: keywordsValidation,
        location: locationValidation,
        languageId: languageIdValidation(isOpenAdvancedSettings),
        checkFrequency: checkFrequencyValidation(isOpenAdvancedSettings),
        note: noteValidation,
      });
    case 'Bing':
      return Yup.object().shape({
        projectName: projectNameValidation,
        url: urlValidation,
        projectUrlType: projectUrlTypeValidation,
        keywords: keywordsValidation,
        location: locationValidation,
        languageId: languageIdValidation(isOpenAdvancedSettings),
        checkFrequency: checkFrequencyValidation(isOpenAdvancedSettings),
        note: noteValidation,
      });
    case 'YouTube':
      return Yup.object().shape({
        projectName: projectNameValidation,
        url: videoUrlValidation,
        keywords: keywordsValidation,
        location: locationValidation,
        languageId: languageIdValidation(isOpenAdvancedSettings),
        checkFrequency: checkFrequencyValidation(isOpenAdvancedSettings),
        note: noteValidation,
      });
    case 'Google Maps':
      return Yup.object().shape({
        projectName: projectNameValidation,
        businessName: businessNameValidation,
        keywords: keywordsValidation,
        location: locationValidation,
        languageId: languageIdValidation(isOpenAdvancedSettings),
        checkFrequency: checkFrequencyValidation(isOpenAdvancedSettings),
        note: noteValidation,
        regionId: regionIdValidation,
      });
    case 'Google My Business':
      return Yup.object().shape({
        projectName: projectNameValidation,
        businessName: businessNameValidation,
        keywords: keywordsValidation,
        location: locationValidation,
        languageId: languageIdValidation(isOpenAdvancedSettings),
        checkFrequency: checkFrequencyValidation(isOpenAdvancedSettings),
        note: noteValidation,
      });
    default:
      return Yup.object({});
  }
}

export { getValidationSchemaForNewProject };
