import React from 'react';
import { ListItem, ListItemIcon } from '@mui/material';
import { StyledIListItemButton } from '../styles';
import ListItemText from '@mui/material/ListItemText';
import PaletteIcon from '../../../../../images/PaletteIcon';
import DollarIcon from '../../../../../images/DollarIcon';
import UsersIcon from '../../../../../images/UsersIcon';
import { useNavigate } from 'react-router';
import PackageIcon from '../../../../../images/PackageIcon';
import SettingsIcon from '../../../../../images/SettingsIcon';
import { TabMenuProps } from '../../../types';

const TabMenu = ({ myAccountClosePopup }: TabMenuProps) => {
  const navigate = useNavigate();

  const handleOpenBranding = () => {
    navigate('settings/branding');
    myAccountClosePopup();
  };

  const handleOpenTeam = () => {
    navigate('settings/team');
    myAccountClosePopup();
  };

  const handleOpenBilling = () => {
    navigate('settings/billing');
    myAccountClosePopup();
  };

  const handleOpenAPI = () => {
    navigate('settings/API');
    myAccountClosePopup();
  };

  const handleOpenSettings = () => {
    navigate('settings');
    myAccountClosePopup();
  };

  return (
    <>
      <ListItem disablePadding>
        <StyledIListItemButton sx={{ gap: '8px' }} onClick={handleOpenBranding}>
          <ListItemIcon sx={{ minWidth: '16px' }}>
            <PaletteIcon />
          </ListItemIcon>
          <ListItemText sx={{ color: '#101828' }}>Branding</ListItemText>
        </StyledIListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <StyledIListItemButton sx={{ gap: '8px' }} onClick={handleOpenTeam}>
          <ListItemIcon sx={{ minWidth: '16px' }}>
            <UsersIcon />
          </ListItemIcon>
          <ListItemText sx={{ color: '#101828' }}>Team</ListItemText>
        </StyledIListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <StyledIListItemButton sx={{ gap: '8px' }} onClick={handleOpenBilling}>
          <ListItemIcon sx={{ minWidth: '16px' }}>
            <DollarIcon />
          </ListItemIcon>
          <ListItemText sx={{ color: '#101828' }}>Billing</ListItemText>
        </StyledIListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <StyledIListItemButton sx={{ gap: '8px' }} onClick={handleOpenAPI}>
          <ListItemIcon sx={{ minWidth: '16px' }}>
            <PackageIcon />
          </ListItemIcon>
          <ListItemText sx={{ color: '#101828' }}>API</ListItemText>
        </StyledIListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <StyledIListItemButton sx={{ gap: '8px' }} onClick={handleOpenSettings}>
          <ListItemIcon sx={{ minWidth: '16px' }}>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText sx={{ color: '#101828' }}>Settings</ListItemText>
        </StyledIListItemButton>
      </ListItem>
    </>
  );
};

export default TabMenu;
