import React from 'react';

const EyeIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill='none'
    >
      <path
        d='M2.15544 11.5585C2.04195 11.3788 1.98521 11.289 1.95344 11.1504C1.92958 11.0463 1.92958 10.8821 1.95344 10.778C1.98521 10.6394 2.04195 10.5496 2.15544 10.3699C3.09328 8.88489 5.88484 5.13086 10.139 5.13086C14.3932 5.13086 17.1847 8.88489 18.1226 10.3699C18.2361 10.5496 18.2928 10.6394 18.3246 10.778C18.3484 10.8821 18.3484 11.0463 18.3246 11.1504C18.2928 11.289 18.2361 11.3788 18.1226 11.5585C17.1847 13.0435 14.3932 16.7975 10.139 16.7975C5.88484 16.7975 3.09328 13.0435 2.15544 11.5585Z'
        stroke='white'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.139 13.4642C11.5197 13.4642 12.639 12.3449 12.639 10.9642C12.639 9.58348 11.5197 8.46419 10.139 8.46419C8.7583 8.46419 7.63901 9.58348 7.63901 10.9642C7.63901 12.3449 8.7583 13.4642 10.139 13.4642Z'
        stroke='white'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default EyeIcon;
