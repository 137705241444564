import React from 'react';

const VerifyEmailImage: React.FC = () => {
  return (
    <svg
      width='180'
      height='160'
      viewBox='0 0 180 160'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_213_30102)'>
        <path
          d='M177.556 61.1871C177.492 61.1871 177.43 61.1675 177.378 61.131L90.8982 0.904348C90.635 0.721921 90.3223 0.624428 90.002 0.624974C89.6818 0.62552 89.3694 0.724078 89.1069 0.907402L3.25017 61.1304C3.18226 61.1781 3.09821 61.1968 3.01651 61.1824C2.93481 61.1681 2.86215 61.1219 2.81452 61.054C2.76689 60.9861 2.74818 60.902 2.76252 60.8203C2.77686 60.7386 2.82307 60.666 2.89098 60.6183L88.7477 0.395298C89.1154 0.138683 89.5527 0.000739724 90.001 2.96674e-06C90.4493 -0.000733791 90.8871 0.13577 91.2556 0.391176L177.735 60.6177C177.79 60.6558 177.831 60.7103 177.852 60.7733C177.874 60.8363 177.875 60.9046 177.855 60.9681C177.835 61.0316 177.795 61.0871 177.741 61.1265C177.688 61.1659 177.623 61.1871 177.556 61.1871L177.556 61.1871Z'
          fill='#3F3D56'
        />
        <path
          d='M8.15625 63.3223L90.0869 2.60156L172.639 67.6429L94.3084 114.079L51.7814 104.385L8.15625 63.3223Z'
          fill='#E6E6E6'
        />
        <path
          d='M55.3711 142.76H14.8954C14.6469 142.76 14.4007 142.712 14.171 142.617C13.9413 142.522 13.7325 142.383 13.5567 142.207C13.3808 142.031 13.2413 141.823 13.1461 141.593C13.051 141.364 13.002 141.118 13.002 140.869C13.002 140.62 13.051 140.374 13.1461 140.145C13.2413 139.915 13.3808 139.707 13.5567 139.531C13.7325 139.355 13.9413 139.216 14.171 139.121C14.4007 139.026 14.6469 138.978 14.8954 138.978H55.3711C55.6196 138.978 55.8658 139.026 56.0955 139.121C56.3252 139.216 56.534 139.355 56.7098 139.531C56.8857 139.707 57.0252 139.915 57.1204 140.145C57.2155 140.374 57.2645 140.62 57.2645 140.869C57.2645 141.118 57.2155 141.364 57.1204 141.593C57.0252 141.823 56.8857 142.031 56.7098 142.207C56.534 142.383 56.3252 142.522 56.0955 142.617C55.8658 142.712 55.6196 142.76 55.3711 142.76Z'
          fill='#126FE9'
        />
        <path
          d='M29.4172 134.63H14.8954C14.6469 134.63 14.4007 134.581 14.171 134.486C13.9413 134.392 13.7325 134.252 13.5567 134.077C13.3808 133.901 13.2413 133.692 13.1461 133.463C13.051 133.233 13.002 132.987 13.002 132.739C13.002 132.49 13.051 132.244 13.1461 132.014C13.2413 131.785 13.3808 131.576 13.5567 131.401C13.7325 131.225 13.9413 131.086 14.171 130.991C14.4007 130.896 14.6469 130.847 14.8954 130.848H29.4172C29.6657 130.847 29.9119 130.896 30.1416 130.991C30.3713 131.086 30.5801 131.225 30.7559 131.401C30.9318 131.576 31.0713 131.785 31.1665 132.014C31.2616 132.244 31.3106 132.49 31.3106 132.739C31.3106 132.987 31.2616 133.233 31.1665 133.463C31.0713 133.692 30.9318 133.901 30.7559 134.077C30.5801 134.252 30.3713 134.392 30.1416 134.486C29.9119 134.581 29.6657 134.63 29.4172 134.63Z'
          fill='#126FE9'
        />
        <path
          d='M91.2389 97.1427C90.9312 97.143 90.6265 97.0833 90.3417 96.967L39.8125 76.0009V10.3735C39.8132 9.79314 40.044 9.23678 40.4543 8.82643C40.8647 8.41608 41.4211 8.18525 42.0014 8.18457H138.938C139.518 8.18525 140.075 8.41608 140.485 8.82643C140.895 9.23678 141.126 9.79314 141.127 10.3735V76.0467L141.032 76.0873L92.1632 96.9567C91.8706 97.0797 91.5563 97.1429 91.2389 97.1427Z'
          fill='white'
        />
        <path
          d='M91.239 97.2989C90.9109 97.2993 90.586 97.2357 90.2823 97.1117L39.6562 76.1053V10.3736C39.657 9.75178 39.9043 9.15567 40.3439 8.716C40.7836 8.27634 41.3797 8.02902 42.0015 8.02832H138.938C139.56 8.02902 140.156 8.27634 140.596 8.716C141.035 9.15567 141.283 9.75178 141.283 10.3736V76.1499L92.2247 97.1004C91.9127 97.2317 91.5775 97.2992 91.239 97.2989ZM40.2816 75.6876L90.5207 96.5335C90.9893 96.7237 91.5141 96.7205 91.9804 96.5248L140.658 75.7371V10.3736C140.657 9.9176 140.476 9.48046 140.154 9.15805C139.831 8.83563 139.394 8.65426 138.938 8.65372H42.0015C41.5455 8.65426 41.1084 8.83563 40.786 9.15805C40.4636 9.48046 40.2822 9.9176 40.2817 10.3736L40.2816 75.6876Z'
          fill='#3F3D56'
        />
        <path
          d='M176.93 60.562H176.867L140.97 75.8905L91.8543 96.8631C91.6623 96.9439 91.4564 96.986 91.2482 96.987C91.04 96.9881 90.8336 96.9481 90.6409 96.8694L39.9682 75.8467L3.18854 60.587L3.13235 60.562H3.06975C2.48942 60.5627 1.93304 60.7935 1.52268 61.2038C1.11232 61.6142 0.881503 62.1706 0.880859 62.7509V157.811C0.881504 158.391 1.11232 158.948 1.52268 159.358C1.93304 159.769 2.48942 159.999 3.06975 160H176.93C177.51 159.999 178.067 159.769 178.477 159.358C178.887 158.948 179.118 158.391 179.119 157.811V62.7509C179.118 62.1706 178.887 61.6142 178.477 61.2038C178.067 60.7935 177.51 60.5627 176.93 60.562ZM178.494 157.811C178.493 158.226 178.329 158.623 178.035 158.917C177.742 159.21 177.345 159.374 176.93 159.375H3.06975C2.65514 159.374 2.25755 159.21 1.96438 158.917C1.6712 158.623 1.50642 158.226 1.50626 157.811V62.7509C1.50682 62.3466 1.66366 61.9581 1.94397 61.6668C2.22427 61.3754 2.60636 61.2036 3.01035 61.1874L39.9682 76.5221L90.4002 97.4479C90.9462 97.67 91.5579 97.6666 92.1013 97.4385L140.97 76.569L176.993 61.1874C177.396 61.2048 177.777 61.3771 178.057 61.6683C178.336 61.9595 178.493 62.3472 178.494 62.7509V157.811Z'
          fill='#3F3D56'
        />
        <path
          d='M97.4152 64.782H82.8934C82.6449 64.7823 82.3987 64.7337 82.169 64.6388C81.9393 64.5439 81.7306 64.4046 81.5547 64.229C81.3789 64.0534 81.2394 63.8448 81.1442 63.6152C81.049 63.3856 81 63.1395 81 62.891C81 62.6425 81.049 62.3964 81.1442 62.1668C81.2394 61.9372 81.3789 61.7286 81.5547 61.553C81.7306 61.3774 81.9393 61.2381 82.169 61.1432C82.3987 61.0484 82.6449 60.9997 82.8934 61H97.4152C97.6638 60.9997 97.9099 61.0484 98.1396 61.1432C98.3694 61.2381 98.5781 61.3774 98.754 61.553C98.9298 61.7286 99.0693 61.9372 99.1645 62.1668C99.2597 62.3964 99.3087 62.6425 99.3087 62.891C99.3087 63.1395 99.2597 63.3856 99.1645 63.6152C99.0693 63.8448 98.9298 64.0534 98.754 64.229C98.5781 64.4046 98.3694 64.5439 98.1396 64.6388C97.9099 64.7337 97.6638 64.7823 97.4152 64.782Z'
          fill='#126FE9'
        />
        <path
          d='M110.369 42.782H69.8934C69.6449 42.7823 69.3987 42.7337 69.169 42.6388C68.9393 42.5439 68.7306 42.4046 68.5547 42.229C68.3789 42.0534 68.2394 41.8448 68.1442 41.6152C68.049 41.3856 68 41.1395 68 40.891C68 40.6425 68.049 40.3964 68.1442 40.1668C68.2394 39.9372 68.3789 39.7286 68.5547 39.553C68.7306 39.3774 68.9393 39.2381 69.169 39.1432C69.3987 39.0484 69.6449 38.9997 69.8934 39H110.369C110.618 38.9997 110.864 39.0484 111.094 39.1432C111.323 39.2381 111.532 39.3774 111.708 39.553C111.884 39.7286 112.023 39.9372 112.118 40.1668C112.214 40.3964 112.263 40.6425 112.263 40.891C112.263 41.1395 112.214 41.3856 112.118 41.6152C112.023 41.8448 111.884 42.0534 111.708 42.229C111.532 42.4046 111.323 42.5439 111.094 42.6388C110.864 42.7337 110.618 42.7823 110.369 42.782Z'
          fill='#DEDEDE'
        />
        <path
          d='M110.369 50.782H69.8934C69.6449 50.7823 69.3987 50.7337 69.169 50.6388C68.9393 50.5439 68.7306 50.4046 68.5547 50.229C68.3789 50.0534 68.2394 49.8448 68.1442 49.6152C68.049 49.3856 68 49.1395 68 48.891C68 48.6425 68.049 48.3964 68.1442 48.1668C68.2394 47.9372 68.3789 47.7286 68.5547 47.553C68.7306 47.3774 68.9393 47.2381 69.169 47.1432C69.3987 47.0484 69.6449 46.9997 69.8934 47H110.369C110.618 46.9997 110.864 47.0484 111.094 47.1432C111.323 47.2381 111.532 47.3774 111.708 47.553C111.884 47.7286 112.023 47.9372 112.118 48.1668C112.214 48.3964 112.263 48.6425 112.263 48.891C112.263 49.1395 112.214 49.3856 112.118 49.6152C112.023 49.8448 111.884 50.0534 111.708 50.229C111.532 50.4046 111.323 50.5439 111.094 50.6388C110.864 50.7337 110.618 50.7823 110.369 50.782Z'
          fill='#DEDEDE'
        />
      </g>
      <defs>
        <clipPath id='clip0_213_30102'>
          <rect
            width='178.238'
            height='160'
            fill='white'
            transform='translate(0.880859)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VerifyEmailImage;
