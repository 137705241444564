import { styled } from '@mui/material/styles';
import {
  ListItemTypography as ProjectListItemTypography,
  MainContainer as ProjectMainContainer,
  StyledListItem as ProjectStyledListItem
} from "../../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/styles";

const MainContainer = styled(ProjectMainContainer)``;

const StyledListItem = styled(ProjectStyledListItem)``;

const ListItemTypography = styled(ProjectListItemTypography)``;

export { MainContainer, StyledListItem, ListItemTypography };
