import React, { useCallback } from 'react';
import {
  Fade,
  FormHelperText,
  Grid,
  Modal,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  ButtonTooltipContainer,
  ModalContainer,
  ModalWrapper,
} from '../../styles';
import { CloseDeletePopoverOne } from '../../../../../../../auth/components/Settings/components/Account/styles';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonsWrapper } from '../../../../../../../auth/components/Settings/components/Team/components/customTableStyles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../../../../shared/buttons/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IBusinessCompetitors } from '../../../../../../types';
import { CustomFormHelperText } from '../../../../../../../../shared/components';
import { FieldTextStyle } from './styles';
import { CustomTooltip } from '../../../../../../../../shared/tooltip/styles';
import HelperIcon from '../../../../../../../../shared/images/HelperIcon';
import * as Tooltips from '../../Tooltips';

interface AddCompetitorsModalProps {
  searchEngine: string;
  searchString: string;
  open: boolean;
  onClose: () => void;
  onAdd: (competitor: IBusinessCompetitors) => void;
}

const AddCompetitorsModal: React.FC<AddCompetitorsModalProps> = ({
  searchEngine,
  searchString,
  onClose,
  open,
  onAdd,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const formik = useFormik({
    initialValues: {
      competitorBusinessName: searchString,
      competitorUrl: '',
    },
    validationSchema: Yup.object().shape({
      competitorBusinessName: Yup.string().required(
        'Competitor’s Business Name is required.'
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      onAdd({
        competitorBusinessName: values.competitorBusinessName,
        competitorUrl: values.competitorUrl,
      });
      resetForm();
      onClose();
    },
  });

  const BusinessNameTooltipTitle = useCallback(() => {
    if (searchEngine === 'Google My Business') {
      return <Tooltips.CompetitorsBusinessNameGoogleLocal />;
    }
    if (searchEngine === 'Google Maps') {
      return <Tooltips.CompetitorsBusinessNameGoogleMaps />;
    }
    return <div />;
  }, [searchEngine]);

  const BusinessULRTooltipTitle = useCallback(() => {
    if (searchEngine === 'Google My Business') {
      return <Tooltips.CompetitorsBusinessURLGoogleLocal />;
    }
    if (searchEngine === 'Google Maps') {
      return <Tooltips.CompetitorsBusinessURLGoogleMaps />;
    }
    return <div />;
  }, [searchEngine]);

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Modal
      aria-labelledby='AddNoteModal'
      aria-describedby='EditNoteModal'
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <form onSubmit={formik.handleSubmit}>
          <ModalWrapper>
            <ModalContainer container xs={12} sx={{ gap: '8px' }}>
              <Grid xs={12}>
                <Grid item xs={12} sx={{ position: 'relative' }}>
                  <CloseDeletePopoverOne onClick={handleClose}>
                    <CloseIcon fontSize='small' />
                  </CloseDeletePopoverOne>
                  <Typography
                    fontSize='18px'
                    fontWeight='600'
                    lineHeight='28px'
                    color='#101828'
                  >
                    Add Competitor
                  </Typography>
                </Grid>
                <Grid xs={12} paddingBottom={'16px'} paddingTop={'28px'}>
                  <Grid display={'flex'} alignItems={'center'}>
                    <FieldTextStyle shrink htmlFor='competitorBusinessName'>
                      Competitor’s Business Name
                    </FieldTextStyle>
                    <Grid>
                      <CustomTooltip
                        title={<BusinessNameTooltipTitle />}
                        placement='top-start'
                        arrow
                      >
                        <ButtonTooltipContainer>
                          <HelperIcon />
                        </ButtonTooltipContainer>
                      </CustomTooltip>
                    </Grid>
                  </Grid>
                  <OutlinedInput
                    fullWidth
                    id='competitorBusinessName'
                    size='small'
                    type='text'
                    name={`competitorBusinessName`}
                    placeholder='Enter Business Name'
                    value={formik.values.competitorBusinessName}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      !!(
                        formik.touched.competitorBusinessName &&
                        formik.errors?.competitorBusinessName
                      )
                    }
                  />
                  {formik.touched.competitorBusinessName &&
                    formik.errors?.competitorBusinessName && (
                      <FormHelperText error id='competitorBusinessName'>
                        <CustomFormHelperText
                          error={formik.errors?.competitorBusinessName}
                        />
                      </FormHelperText>
                    )}
                </Grid>
                <Grid xs={12}>
                  <Grid display={'flex'} alignItems={'center'}>
                    <FieldTextStyle shrink htmlFor='competitorUrl'>
                      Competitor’s Business URL (Optional)
                    </FieldTextStyle>
                    <Grid>
                      <CustomTooltip
                        title={<BusinessULRTooltipTitle />}
                        placement='top-start'
                        arrow
                      >
                        <ButtonTooltipContainer>
                          <HelperIcon />
                        </ButtonTooltipContainer>
                      </CustomTooltip>
                    </Grid>
                  </Grid>

                  <OutlinedInput
                    fullWidth
                    id='competitorUrl'
                    size='small'
                    type='text'
                    name={`competitorUrl`}
                    placeholder='Enter Business URL'
                    value={formik.values.competitorUrl}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      !!(
                        formik.touched.competitorUrl &&
                        formik.errors?.competitorUrl
                      )
                    }
                  />
                  {formik.touched.competitorUrl &&
                    formik.errors?.competitorUrl && (
                      <FormHelperText error id='competitorUrl'>
                        <CustomFormHelperText
                          error={formik.errors?.competitorUrl}
                        />
                      </FormHelperText>
                    )}
                </Grid>
                <ButtonsWrapper container xs={12} sx={{ paddingTop: '32px' }}>
                  <Grid item xs={extraSmallScreen && 12}>
                    <GrayButtonSmall
                      fullWidth={extraSmallScreen}
                      size='small'
                      outline
                      onClick={handleClose}
                    >
                      Cancel
                    </GrayButtonSmall>
                  </Grid>
                  <Grid item xs={extraSmallScreen && 12}>
                    <PrimaryButtonSmall
                      fullWidth={extraSmallScreen}
                      variant='contained'
                      size='small'
                      type='submit'
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      Add Competitor
                    </PrimaryButtonSmall>
                  </Grid>
                </ButtonsWrapper>
              </Grid>
            </ModalContainer>
          </ModalWrapper>
        </form>
      </Fade>
    </Modal>
  );
};

export default AddCompetitorsModal;
