import React, { useEffect, useMemo, useState } from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  Brush,
  Scatter,
  Label,
  Tooltip,
} from 'recharts';
import { ChartContainer } from '../../../ImprovedVsDeclined/components/Graph/styled';
import dayjs from 'dayjs';
import { COMPETITORS_COLORS } from '../../../../../../../../shared/layout/PrivateLayout/constant';
import { CustomScatter } from './components';
import { CustomTooltipProjectPerformanceGraph } from './components/CustomTooltipForGraph';
import GraphNoData from '../../../../../../../../shared/components/graphNoData/GraphNoData';
import { LineGraphSkeleton } from '../../../../../../../../shared/graphSkeletons';
import { useAppSelector } from '../../../../../../../../store';
import { IDataGraphAndNotes } from '../../../../../../../projects/components/ProjectExpanded/components/ProjectPerformance/components/Graph/Graph';

const Graph = () => {
  const isShowNotes = useAppSelector(
    state => state.sharedLinks.projectGraphs.projectPerformance.isShowNotes
  );
  const graphData = useAppSelector(
    state => state.sharedLinks.projectGraphs.projectPerformance.graphData
  );
  const isLinearGraph = useAppSelector(
    state => state.sharedLinks.projectGraphs.projectPerformance.isLinearGraph
  );
  const isLoadingProjectPerformance = useAppSelector(
    state => state.sharedLinks.projectGraphs.isLoadingProjectPerformance
  );

  const competitorsMemo = useMemo(
    () =>
      graphData?.competitorsProjectPerformance
        ? graphData.competitorsProjectPerformance.items.map(competitor => ({
            ...competitor,
            items: competitor.items.map(item => ({
              ...item,
              avg: Number(item.avg) || '100',
            })),
          }))
        : [],
    [graphData]
  );

  const dailyAveragePositionData = useMemo(
    () =>
      graphData?.dailyAveragePosition?.items
        ? graphData?.dailyAveragePosition?.items?.map(item => ({
            ...item,
            avg: Number(item.avg) || '100',
          }))
        : [],
    [graphData?.dailyAveragePosition?.items]
  );

  const graphDataMemo = useMemo(
    () =>
      graphData?.dailyAveragePosition
        ? graphData.dailyAveragePosition.items
            .map(item => (item?.avg === 0 ? { ...item, avg: 101 } : item))
            .map(item => {
              if (competitorsMemo?.length) {
                competitorsMemo.forEach(competitor => {
                  const getCompetitorIndex = competitor?.items?.findIndex(
                    competitorItem => competitorItem.date === item.date
                  );
                  if (competitor?.items[getCompetitorIndex]?.avg) {
                    item = {
                      ...item,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      [`competitor${competitor.id}`]:
                        competitor.items[getCompetitorIndex].avg,
                    };
                  }
                });
              }
              return item;
            })
        : [],
    [competitorsMemo, graphData?.dailyAveragePosition]
  );

  const notesMemo = useMemo(
    () =>
      graphData?.notes.items.length
        ? graphData.notes.items.map(item => ({ ...item, startPosition: 100 }))
        : [],
    [graphData?.notes?.items]
  );

  let allData = [...graphDataMemo];
  const dataWithNotes = [...graphDataMemo, ...notesMemo]
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .map(item => (item?.avg === 0 ? { ...item, avg: 101 } : item))
    .sort((a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1));

  if (competitorsMemo?.length) {
    competitorsMemo.forEach(competitor => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      allData = [...allData, ...competitor.items];
    });
  }

  const maxValue = Math.max(...allData.map(entry => entry.avg));

  const [dataAndNotes, setDataAndNotes] = useState<IDataGraphAndNotes[]>([]);

  useEffect(() => {
    const formatDate = (dateString: string) => dateString.split('T')[0];

    const notesAndGraphData = graphDataMemo.map(dataItem => {
      const matchingNotes = notesMemo.filter(
        note => formatDate(note.date) === dataItem.date
      );
      if (matchingNotes.length) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return { ...dataItem, notes: matchingNotes, startPosition: 100 };
      }
      return dataItem;
    });

    setDataAndNotes(notesAndGraphData);
  }, [graphDataMemo, notesMemo]);

  return (
    <ChartContainer xs={12}>
      <ResponsiveContainer width={'100%'} height={311}>
        {isLoadingProjectPerformance || !graphData ? (
          <LineGraphSkeleton />
        ) : dataAndNotes.length ? (
          <ComposedChart data={dataAndNotes}>
            <Tooltip
              cursor={{
                stroke: '#1A73E9',
                strokeDasharray: '3 3',
                strokeWidth: '2',
              }}
              content={<CustomTooltipProjectPerformanceGraph />}
              wrapperStyle={{ width: '280px' }}
            />

            <CartesianGrid vertical={false} stroke='#EDEDED' strokeWidth={2} />

            <XAxis
              interval={'equidistantPreserveStart'}
              axisLine={false}
              tickLine={false}
              dataKey='date'
              stroke='#D8DAE5'
              tickFormatter={value => {
                return dayjs(value).format('D MMM');
              }}
            />

            <YAxis
              allowDecimals={false}
              axisLine={false}
              tickLine={false}
              orientation='right'
              scale={!isLinearGraph ? 'log' : 'linear'}
              domain={[1, maxValue ? maxValue + 10 : 100]}
              reversed
              interval='preserveStartEnd'
            >
              {isLinearGraph ? (
                <Label
                  value='1'
                  position={{ x: 16, y: 9 }}
                  style={{
                    color: '#475467',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '24px',
                  }}
                />
              ) : null}
              <Label
                value='Avg. SERP Position'
                angle={90}
                position={{ x: 40, y: 150 }}
                style={{
                  color: '#84818A',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '15px',
                }}
              />
            </YAxis>

            {dailyAveragePositionData?.length ? (
              <Line
                type={'linear'}
                dataKey={'avg'}
                connectNulls
                stroke='#1A73E9'
                strokeWidth={2}
              />
            ) : null}

            {competitorsMemo?.length
              ? competitorsMemo.map(competitor => {
                  return (
                    <Line
                      type={'linear'}
                      key={`competitor${competitor.id}`}
                      connectNulls
                      dataKey={`competitor${competitor.id}`}
                      stroke={
                        COMPETITORS_COLORS[
                          competitor.id % COMPETITORS_COLORS.length
                        ]
                      }
                      strokeWidth={2}
                    />
                  );
                })
              : null}
            {isShowNotes ? (
              <Scatter
                name='data'
                dataKey='startPosition'
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                shape={<CustomScatter />}
              />
            ) : null}

            {isShowNotes && dataWithNotes.length > 24 ? (
              <Brush
                dataKey='date'
                height={40}
                stroke='#126FE9'
                travellerWidth={12}
                tickFormatter={() => ''}
              />
            ) : null}

            {!isShowNotes && graphDataMemo.length > 24 ? (
              <Brush
                dataKey='date'
                height={40}
                stroke='#126FE9'
                travellerWidth={12}
                tickFormatter={() => ''}
              />
            ) : null}
          </ComposedChart>
        ) : (
          <GraphNoData />
        )}
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default Graph;
