import React, { useState } from 'react';
import {
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useAuth } from '../../../../../../../hooks';
import { CustomTableHeaderTollsProps } from '../types';
import SearchIcon from '../../../../../../../shared/images/SearchIcon';
import PlusIcon from '../../../../../../../shared/images/PlusIcon';
import UsersActionsPopover from './UsersActionsPopover';

import {
  CustomTableHeaderTollsWrapper,
  HeaderRightTolls,
  TeamCountLabel,
} from './customTableStyles';
import { PrimaryButtonCustom } from '../../../../../../../shared/buttons/styles';
import { OutlinedInputCustom } from '../../../../../../../shared/inputs/styled';
import { useAppSelector } from '../../../../../../../store';

const CustomTableHeaderTolls: React.FC<CustomTableHeaderTollsProps> = ({
  rowSelectionModel,
  onRowSelectionModelChange,
  searchKeyword,
  handleOpenInviteUserModal,
  onChangeSearchKeyword,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');
  const seScreen = useMediaQuery('(max-width:400px)');

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const accountLimit = useAppSelector(state => state.auth.accountLimit);

  const [showSearchMobile, setShowSearchMobile] = useState(false);

  const onClearSelected = () => {
    onRowSelectionModelChange([]);
  };

  return (
    <>
      {
        <Grid
          container
          xs={12}
          sx={{
            marginTop: '18px',
            marginBottom: '12px',
            minHeight: '1px',
            justifyContent: 'flex-end',
          }}
        >
          {seScreen && (
            <Grid item xs={12} display='flex' justifyContent='flex-end'>
              <OutlinedInputCustom
                sx={{
                  width: seScreen && !showSearchMobile ? '42px' : undefined,
                  padding: '0px 12px !important',
                  maxHeight: '32px',
                }}
                $padding='7.94px 12px 7.94px 0 !important'
                size='small'
                fullWidth
                type='text'
                id='searchKeyword'
                name='searchKeyword'
                placeholder='Search'
                value={searchKeyword}
                onChange={e => onChangeSearchKeyword(e.target.value)}
                onFocus={() => {
                  extraSmallScreen && setShowSearchMobile(true);
                }}
                startAdornment={
                  <InputAdornment position='start' disablePointerEvents>
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  extraSmallScreen && showSearchMobile ? (
                    <>
                      <Typography
                        fontSize='14px'
                        fontWeight='600'
                        lineHeight='20px'
                        color='#126FE9'
                        sx={{ cursor: 'pointer', paddingRight: '4px' }}
                        onClick={() => onChangeSearchKeyword('')}
                      >
                        Clear
                      </Typography>
                      <CloseIcon
                        fontSize='small'
                        sx={{ color: '#101828', cursor: 'pointer' }}
                        onClick={() =>
                          extraSmallScreen && setShowSearchMobile(false)
                        }
                      />
                    </>
                  ) : null
                }
              />
            </Grid>
          )}
        </Grid>
      }
      <CustomTableHeaderTollsWrapper container item xs={12}>
        <Grid
          item
          container
          xs={
            extraSmallScreen && showSearchMobile
              ? !seScreen && !rowSelectionModel.length
                ? 12
                : seScreen
                ? 8
                : 6
              : !rowSelectionModel.length
              ? 7.5
              : 6
          }
          md={4}
          sm={12}
          flexDirection='row'
          alignItems='center'
          display={
            !seScreen &&
            extraSmallScreen &&
            showSearchMobile &&
            !rowSelectionModel.length
              ? 'none'
              : 'block'
          }
        >
          {!rowSelectionModel.length ? (
            <Grid container item alignItems='center'>
              <Grid item marginRight='8px'>
                <Typography
                  variant='h6'
                  fontWeight='600'
                  color='#101828'
                  fontSize='18px'
                  lineHeight='28px'
                >
                  Manage Users
                </Typography>
              </Grid>
              <Grid item>
                <TeamCountLabel>
                  {accountLimit?.accountLimitsUsed?.numberOfUsers ||
                  accountLimit?.accountLimitsUsed?.numberOfInvitations
                    ? accountLimit.accountLimitsUsed.numberOfUsers +
                      accountLimit.accountLimitsUsed.numberOfInvitations
                    : 0}
                </TeamCountLabel>
              </Grid>
            </Grid>
          ) : (
            <Grid container item>
              <Grid container item alignItems='center'>
                <Grid item>
                  <IconButton
                    onClick={onClearSelected}
                    sx={{ padding: '0 !important', marginRight: '8px' }}
                  >
                    <CloseIcon fontSize='small' />
                  </IconButton>
                </Grid>
                {!extraSmallScreen &&
                  !showSearchMobile &&
                  rowSelectionModel.length && (
                    <Grid item sx={{ paddingRight: '16px' }}>
                      <Typography
                        fontSize='14px'
                        lineHeight='14px'
                        color='#101828'
                        fontWeight='500'
                        alignItems='center'
                      >
                        {`${rowSelectionModel.length} Selected`}
                      </Typography>
                    </Grid>
                  )}
                <Grid item>
                  <UsersActionsPopover
                    rowSelectionModel={rowSelectionModel}
                    showSearchMobile={showSearchMobile}
                    onRowSelectionModelChange={onRowSelectionModelChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <HeaderRightTolls
          container
          item
          xs={
            extraSmallScreen && showSearchMobile
              ? !seScreen && !rowSelectionModel.length
                ? 12
                : seScreen
                ? 4
                : 6
              : !rowSelectionModel.length
              ? 4.5
              : 6
          }
          sm={12}
          md={8}
        >
          <Grid
            item
            xs={extraSmallScreen && showSearchMobile ? 12 : undefined}
            sx={{
              paddingRight:
                (!extraSmallScreen || !showSearchMobile) &&
                currentAccount.role.name !== 'ViewOnly'
                  ? '16px'
                  : null,
              display: seScreen ? 'none' : 'block',
            }}
          >
            <OutlinedInputCustom
              sx={{
                width: extraSmallScreen && !showSearchMobile ? '44px' : null,
                height: extraSmallScreen && !showSearchMobile ? '36px' : null,
              }}
              $padding='7.94px 12px 7.94px 0 !important'
              size='small'
              fullWidth
              type='text'
              id='searchKeyword'
              name='searchKeyword'
              placeholder='Search'
              value={searchKeyword}
              onChange={e => onChangeSearchKeyword(e.target.value)}
              onFocus={() => {
                extraSmallScreen && setShowSearchMobile(true);
              }}
              startAdornment={
                <InputAdornment position='start' disablePointerEvents>
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                extraSmallScreen && showSearchMobile ? (
                  <>
                    <Typography
                      fontSize='14px'
                      fontWeight='600'
                      lineHeight='20px'
                      color='#126FE9'
                      sx={{ cursor: 'pointer', paddingRight: '4px' }}
                      onClick={() => onChangeSearchKeyword('')}
                    >
                      Clear
                    </Typography>
                    <CloseIcon
                      fontSize='small'
                      sx={{ color: '#101828', cursor: 'pointer' }}
                      onClick={() =>
                        extraSmallScreen && setShowSearchMobile(false)
                      }
                    />
                  </>
                ) : (
                  !extraSmallScreen &&
                  searchKeyword && (
                    <IconButton onClick={() => onChangeSearchKeyword('')}>
                      <CloseIcon fontSize='small' />
                    </IconButton>
                  )
                )
              }
            />
          </Grid>
          {currentAccount.role.name !== 'ViewOnly' && (
            <Grid
              item
              display={
                !seScreen && extraSmallScreen && showSearchMobile
                  ? 'none'
                  : 'block'
              }
            >
              <PrimaryButtonCustom
                sx={{
                  minWidth: extraSmallScreen ? '42px !important' : null,
                  height: extraSmallScreen ? '36px !important' : null,
                }}
                variant='contained'
                size='small'
                $padding='8px 12px'
                startIcon={!extraSmallScreen ? <PlusIcon /> : null}
                onClick={handleOpenInviteUserModal}
              >
                {!extraSmallScreen ? 'Add New User' : <PlusIcon />}
              </PrimaryButtonCustom>
            </Grid>
          )}
        </HeaderRightTolls>
      </CustomTableHeaderTollsWrapper>
    </>
  );
};

export default CustomTableHeaderTolls;
