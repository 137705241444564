import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useState } from 'react';
import {
  CustomTooltip,
  DateCustomTooltip,
} from '../../../shared/tooltip/styles';
import TextTooltip from '../../../shared/components/TextTooltip';
import * as Tooltips from '../../alerts/components/Alerts/components/Tooltips/index';
import InfoIcon from '../../../shared/images/InfoIcon';
import StatusDot from '../../../shared/images/StatusDot';
import AlertTriggeredView from '../components/Alerts/components/AlertTriggeredView/AlertTriggeredView';
import { getDeviceTypeIcon } from '../../projects/utils';
import { Box, Grid, Skeleton } from '@mui/material';
import KeywordView from '../components/Alerts/components/KeywordView/KeywordView';
import useAlerts from './useAlerts';
import { findLongestString, getColumnWidth } from '../../../utils/table';
import { customSortComparator } from '../../emailReports/hooks/useScheduledEmailReportsTable';
import {
  getDifferenceIcon,
  getTextTooltip,
  getValue,
} from '../components/Alerts/components/AlertsTable/components/AlertTriggeredModal/components/AlertTriggeredTable/utils/utils';
import { AlertByKeywordNewRankTooltip } from '../components/Alerts/utils/utils';
import { TableHeaderStyles } from '../../../shared/stylesTable';
import { convertToTimezone } from '../../../utils/date';
import { SERVER_UTS } from '../../../constants/config';
import { useAppSelector } from '../../../store';

export default function useAlertsTable() {
  const {
    alerts: { byProjects, byKeywords },
  } = useAlerts();
  const user = useAppSelector(state => state.auth.user);

  const [showFullDateByProject, setShowFullDateByProject] =
    useState<boolean>(false);

  const [showFullDateByKeywords, setShowFullDateByKeywords] =
    useState<boolean>(false);

  const [projectNameWidth, setProjectNameWidth] = useState<number>(130);

  useEffect(() => {
    if (byProjects?.items) {
      setProjectNameWidth(
        getColumnWidth(
          findLongestString(byProjects?.items?.map(item => item.projectName)) ||
            ''
        )
      );
    }
  }, [byProjects?.items]);

  const alertsByProjectTableWidth = useMemo(
    () => ({
      projectName: byProjects?.items
        ? getColumnWidth(
            findLongestString(
              byProjects?.items?.map(item => item.projectName)
            ) || ''
          )
        : projectNameWidth,
    }),
    [byProjects?.items, projectNameWidth]
  );

  const [keywordNameWidth, setKeywordNameWidth] = useState<number>(130);
  const [keywordProjectNameWidth, setKeywordProjectNameWidth] =
    useState<number>(130);

  useEffect(() => {
    if (byKeywords?.items) {
      setKeywordNameWidth(
        getColumnWidth(
          findLongestString(byKeywords?.items?.map(item => item.keywordName)) ||
            ''
        )
      );
      setKeywordProjectNameWidth(
        getColumnWidth(
          findLongestString(byKeywords?.items?.map(item => item.projectName)) ||
            ''
        )
      );
    }
  }, [byKeywords?.items]);

  const alertsByKeywordsTableWidth = useMemo(
    () => ({
      keywordName: byKeywords?.items
        ? getColumnWidth(
            findLongestString(
              byKeywords?.items?.map(item => item.keywordName)
            ) || ''
          )
        : keywordNameWidth,
      projectName: byKeywords?.items
        ? getColumnWidth(
            findLongestString(
              byKeywords?.items?.map(item => item.projectName)
            ) || ''
          )
        : keywordProjectNameWidth,
    }),
    [byKeywords?.items, keywordNameWidth, keywordProjectNameWidth]
  );

  const popperProps = {
    sx: {
      '& .MuiTooltip-tooltip': {
        backgroundColor: '#fff',
        color: '#344054',
        boxShadow:
          '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
        border: '1px solid #EDEDED',
      },
      '.MuiTooltip-arrow::before': {
        backgroundColor: '#fff',
        border: '1px solid #EDEDED',
      },
    },
  };

  const alertsByProjectColumns: GridColDef[] = useMemo(
    () => [
      {
        field: 'status',
        headerName: '',
        cellClassName: 'status',
        sortable: false,
        minWidth: 40,
        maxWidth: 40,
        align: 'right',
        renderCell: (params: GridRenderCellParams) => (
          <div>{!params.row.viewed && <StatusDot />}</div>
        ),
      },
      {
        field: 'date',
        sortable: true,
        headerName: 'Date',
        maxWidth: 150,
        minWidth: 150,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Date />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Date '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          const date = showFullDateByProject
            ? convertToTimezone(
                params.row.dateFullFormat,
                SERVER_UTS,
                user.timezone.utc
              )
            : params.row.date;
          return (
            <DateCustomTooltip
              title={<TextTooltip text={date} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
              onClick={e => {
                e.stopPropagation();
                setShowFullDateByProject(!showFullDateByProject);
              }}
            >
              {date}
            </DateCustomTooltip>
          );
        },
      },
      {
        field: 'project',
        sortable: true,
        headerName: 'Project',
        maxWidth: 300,
        minWidth: 130,
        sortComparator: customSortComparator,
        width: alertsByProjectTableWidth.projectName,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Project />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Project '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.projectName} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params.row.projectName}
            </CustomTooltip>
          );
        },
      },
      {
        field: 'numberOfKeywords',
        sortable: false,
        headerName: 'Number of Keywords',
        maxWidth: 170,
        minWidth: 170,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.NumberOfKeywords />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Number of Keywords '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <AlertTriggeredView
              keywordCount={params.row.numberOfKeywords}
              triggerId={params.row.id}
            />
          );
        },
      },
      {
        field: 'rule',
        sortable: true,
        headerName: 'Rule',
        maxWidth: 180,
        minWidth: 180,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Rule />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Rule '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params?.row?.rule?.name} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params?.row?.rule?.name}
            </CustomTooltip>
          );
        },
      },
      {
        field: 'threshold',
        sortable: true,
        headerName: 'Threshold',
        maxWidth: 120,
        minWidth: 120,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Threshold />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Threshold '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.threshold} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params.row.threshold}
            </CustomTooltip>
          );
        },
      },
    ],
    [alertsByProjectTableWidth, showFullDateByProject, user?.timezone]
  );

  const alertsByProjectColumnsSkeleton: GridColDef[] = useMemo(
    () => [
      {
        field: 'status',
        headerName: '',
        cellClassName: 'status',
        sortable: false,
        minWidth: 40,
        maxWidth: 40,
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'date',
        sortable: true,
        headerName: 'Date',
        maxWidth: 150,
        minWidth: 150,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'project',
        sortable: true,
        headerName: 'Project',
        maxWidth: 300,
        minWidth: 130,
        width: alertsByProjectTableWidth.projectName,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'numberOfKeywords',
        sortable: false,
        headerName: 'Number of Keywords',
        maxWidth: 170,
        minWidth: 170,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'rule',
        sortable: true,
        headerName: 'Rule',
        maxWidth: 180,
        minWidth: 180,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'threshold',
        sortable: true,
        headerName: 'Threshold',
        maxWidth: 120,
        minWidth: 120,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
    ],
    [alertsByProjectTableWidth]
  );

  const alertsByKeywordsColumns: GridColDef[] = useMemo(
    () => [
      {
        field: 'status',
        headerName: '',
        cellClassName: 'status',
        sortable: false,
        minWidth: 40,
        maxWidth: 40,
        align: 'right',
        renderCell: (params: GridRenderCellParams) => (
          <div>{!params.row.viewed && <StatusDot />}</div>
        ),
      },
      {
        field: 'date',
        sortable: true,
        headerName: 'Date',
        maxWidth: 150,
        minWidth: 150,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Date />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Date '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <DateCustomTooltip
              title={
                <TextTooltip
                  text={
                    showFullDateByKeywords
                      ? params.row.dateFullFormat
                      : params.row.date
                  }
                />
              }
              placement='top-start'
              arrow
              PopperProps={popperProps}
              onClick={e => {
                e.stopPropagation();
                setShowFullDateByKeywords(!showFullDateByKeywords);
              }}
            >
              {showFullDateByKeywords
                ? params.row.dateFullFormat
                : params.row.date}
            </DateCustomTooltip>
          );
        },
      },
      {
        field: 'keyword',
        sortable: true,
        headerName: 'Keyword',
        maxWidth: 425,
        minWidth: 130,
        width: alertsByKeywordsTableWidth.keywordName + 20,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Keyword />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Keyword '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.keywordName} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              <Grid display={'flex'} alignItems={'center'}>
                {getDeviceTypeIcon(params?.row?.deviceType?.name)}
                <KeywordView
                  keywordId={params.row.keywordId}
                  keywordName={params.row.keywordName}
                  alertId={params.row.id}
                  projectId={params.row.projectId}
                />
              </Grid>
            </CustomTooltip>
          );
        },
      },
      {
        field: 'project',
        sortable: true,
        headerName: 'Project',
        maxWidth: 300,
        minWidth: 130,
        width: alertsByKeywordsTableWidth.projectName,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Project />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Project '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.projectName} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params.row.projectName}
            </CustomTooltip>
          );
        },
      },
      {
        field: 'previousRank',
        sortable: true,
        headerName: 'Previous Rank',
        maxWidth: 155,
        minWidth: 155,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.PreviousRank />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Previous Rank '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={
                <TextTooltip text={getTextTooltip(params.row.previousRank)} />
              }
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              <div>{getValue(params.row.previousRank)}</div>
            </CustomTooltip>
          );
        },
      },
      {
        field: 'newRank',
        sortable: true,
        headerName: 'New Rank',
        maxWidth: 130,
        minWidth: 130,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.NewRank />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'New Rank '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={AlertByKeywordNewRankTooltip(params.row)}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              <div>{getValue(params.row.newRank)}</div>
            </CustomTooltip>
          );
        },
      },
      {
        field: 'difference',
        sortable: true,
        headerName: 'Difference',
        maxWidth: 140,
        minWidth: 140,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Difference />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Difference '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box display='flex' alignItems='center'>
              <Grid container gap={'6px'} alignItems={'center'}>
                <Grid item display={'contents'}>
                  {getDifferenceIcon(
                    params.row.positiveChanges,
                    params.row.difference
                  )}
                </Grid>
                <Grid
                  width={'20px'}
                  textAlign={'center'}
                  item
                  sx={{
                    color: !params.row.difference
                      ? '#101828'
                      : params.row.positiveChanges
                      ? '#007C65'
                      : '#D1002F',
                  }}
                >
                  {getValue(params.row.difference)}
                </Grid>
              </Grid>
            </Box>
          );
        },
      },
      {
        field: 'rule',
        sortable: true,
        headerName: 'Rule',
        maxWidth: 180,
        minWidth: 180,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Rule />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Rule '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params?.row?.rule?.name} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params?.row?.rule?.name}
            </CustomTooltip>
          );
        },
      },
      {
        field: 'threshold',
        sortable: true,
        headerName: 'Threshold',
        maxWidth: 130,
        minWidth: 130,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Threshold />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Threshold '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.threshold} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params.row.threshold}
            </CustomTooltip>
          );
        },
      },
    ],
    [alertsByKeywordsTableWidth, showFullDateByKeywords]
  );

  const alertsByKeywordsColumnsSkeleton: GridColDef[] = useMemo(
    () => [
      {
        field: 'status',
        headerName: '',
        cellClassName: 'status',
        sortable: false,
        minWidth: 40,
        maxWidth: 40,
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'date',
        sortable: true,
        headerName: 'Date',
        maxWidth: 150,
        minWidth: 150,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'keyword',
        sortable: true,
        headerName: 'Keyword',
        maxWidth: 425,
        minWidth: 130,
        width: alertsByKeywordsTableWidth.keywordName + 20,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'project',
        sortable: true,
        headerName: 'Project',
        maxWidth: 300,
        minWidth: 130,
        width: alertsByKeywordsTableWidth.projectName,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'previousRank',
        sortable: true,
        headerName: 'Previous Rank',
        maxWidth: 155,
        minWidth: 155,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'newRank',
        sortable: true,
        headerName: 'New Rank',
        maxWidth: 130,
        minWidth: 130,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'difference',
        sortable: true,
        headerName: 'Difference',
        maxWidth: 140,
        minWidth: 140,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'rule',
        sortable: true,
        headerName: 'Rule',
        maxWidth: 180,
        minWidth: 180,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'threshold',
        sortable: true,
        headerName: 'Threshold',
        maxWidth: 130,
        minWidth: 130,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
    ],
    [alertsByKeywordsTableWidth]
  );

  return {
    alertsByProjectColumns,
    alertsByKeywordsColumns,
    alertsByProjectColumnsSkeleton,
    alertsByKeywordsColumnsSkeleton,
  };
}
