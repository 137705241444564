import React, { useCallback, useMemo, useState } from 'react';
import { Divider, Grid } from '@mui/material';
import {
  StylesPlanDescription,
  StylesPlanName,
  StylesPlanPerMonth,
  StylesPlanPrice,
  StylesPriceContainer,
  StylesPlanCheckItemText,
} from './styles';
import CircleCheckIcon from '../../../../../../shared/images/CircleCheckIcon';
import { PrimaryButton } from '../../../../../../shared/buttons/styles';
import { PricingTableProps } from '../../types';
import usePayment from '../../../../../payment/hooks/usePayment';
import { ENVIRONMENT } from '../../../../../../constants/config';
import ReviewUpdateSubscriptionModal from '../../../../../payment/components/ReviewUpdateSubscriptionModal/ReviewUpdateSubscriptionModal';
import { useAppSelector } from '../../../../../../store';
import { ITariffPlan } from '../../../../types';
import PricingTableHeader from '../PricingTableHeader/PricingTableHeader';
import { UpgradePlanPrimaryButton } from '../../styles';

const PricingTableMobile: React.FC<PricingTableProps> = ({
  mainTariffPlans,
  planType,
  setPlanType,
}) => {
  const { paddle } = usePayment();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const billing = useAppSelector(state => state.auth.payment.billing);
  const checkoutSettings = useAppSelector(state => state.auth.checkoutSettings);

  const [isReviewUpdateSubscriptionModal, setIsReviewUpdateSubscriptionModal] =
    useState(false);
  const [activePaddleProductId, setActivePaddleProductId] = useState<
    null | string
  >(null);

  const userPlanName = useMemo(
    () => billing?.tariffPlan?.name || '',
    [billing?.tariffPlan?.name]
  );

  const userPlanCanceled = useMemo(
    () => billing?.status?.name === 'canceled',
    [billing?.status?.name]
  );

  const userPlanDeactivated = useMemo(
    () => billing?.status?.name === 'deactivated',
    [billing?.status?.name]
  );

  const starterPackage = useMemo(
    () => mainTariffPlans.find(item => item.name === 'Starter Plan'),
    [mainTariffPlans]
  );
  const professionalPackage = useMemo(
    () => mainTariffPlans.find(item => item.name === 'Professional Plan'),
    [mainTariffPlans]
  );
  const enterprisePackage = useMemo(
    () => mainTariffPlans.find(item => item.name === 'Enterprise Plan'),
    [mainTariffPlans]
  );

  const onSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setPlanType(checked ? 'yearly' : 'monthly');
  };

  const openPaddle = (paddleProductId?: string) => {
    if (
      paddle &&
      paddleProductId &&
      currentAccount?.accountId &&
      checkoutSettings
    ) {
      paddle.Checkout.open({
        items: [{ price_id: paddleProductId, quantity: 1 }],
        customer: { email: currentAccount.owner.ownerEmail },
        settings: {
          allowLogout: false,
          showAddDiscounts: checkoutSettings.showAddDiscounts,
          showAddTaxId: false,
          allowedPaymentMethods: checkoutSettings.paymentMethods,
        },
        customData: { id: currentAccount.accountId, environment: ENVIRONMENT },
      });
    }
  };

  const handleOpen = (paddleProductId?: string) => {
    if (paddleProductId) {
      if (
        billing &&
        billing.tariffPlan.name !== 'Trial Period' &&
        !userPlanDeactivated
      ) {
        setActivePaddleProductId(paddleProductId);
        setIsReviewUpdateSubscriptionModal(true);
      } else {
        openPaddle(paddleProductId);
      }
    }
  };

  const handleClose = () => {
    setActivePaddleProductId(null);
    setIsReviewUpdateSubscriptionModal(false);
  };

  const getDisabledPlan = useCallback(
    (plan?: ITariffPlan) => {
      return !billing
        ? true
        : userPlanDeactivated
        ? false
        : userPlanCanceled ||
          (billing?.tariffPlan?.id === plan?.id &&
            billing?.tariffPlan?.settings?.type?.name ===
              plan?.settings?.type?.name);
    },
    [billing, userPlanCanceled, userPlanDeactivated]
  );

  return (
    <>
      <PricingTableHeader
        onSwitch={onSwitch}
        planType={planType}
        userPlanName={userPlanName}
      />
      <Grid>
        <StylesPriceContainer>
          <Grid padding={'24px'}>
            <StylesPlanName>{starterPackage?.name || ''}</StylesPlanName>
            <Grid container spacing={1} padding={'16px 0'}>
              <StylesPlanPrice item>{`$${
                starterPackage?.settings?.price || ''
              }`}</StylesPlanPrice>
              <StylesPlanPerMonth item alignSelf={'flex-end'}>
                {`per ${planType === 'monthly' ? 'month' : 'year'}`}
              </StylesPlanPerMonth>
            </Grid>
            <StylesPlanDescription>
              Best for growing business.
            </StylesPlanDescription>
            <Grid paddingTop={'32px'} paddingBottom={'8px'}>
              <UpgradePlanPrimaryButton
                variant='contained'
                size='medium'
                type='submit'
                disabled={getDisabledPlan(starterPackage)}
                onClick={() =>
                  handleOpen(starterPackage?.settings?.paddleProductId)
                }
              >
                Get started
              </UpgradePlanPrimaryButton>
            </Grid>
          </Grid>
          <Grid>
            <Divider
              orientation='horizontal'
              flexItem
              sx={{
                color: '#EDEDED',
              }}
            />
          </Grid>
          <Grid padding={'32px 24px'}>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                {`${
                  starterPackage?.settings?.dailyWordCount || ''
                } keywords tracked`}
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                Unlimited domains
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                Automatic daily updates
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                On-demand checks
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                Competitor tracking
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>Email reports</StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>Alerts</StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                One-click sharing
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                Organize with folders
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                White-label reporting
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>API</StylesPlanCheckItemText>
            </Grid>
          </Grid>
        </StylesPriceContainer>
        <StylesPriceContainer>
          <Grid padding={'24px'}>
            <StylesPlanName>{professionalPackage?.name || ''}</StylesPlanName>
            <Grid container spacing={1} padding={'16px 0'}>
              <StylesPlanPrice item>{`$${
                professionalPackage?.settings?.price || ''
              }`}</StylesPlanPrice>
              <StylesPlanPerMonth item alignSelf={'flex-end'}>
                {`per ${planType === 'monthly' ? 'month' : 'year'}`}
              </StylesPlanPerMonth>
            </Grid>
            <StylesPlanDescription>
              Our most popular plan.
            </StylesPlanDescription>
            <Grid paddingTop={'32px'} paddingBottom={'8px'}>
              <UpgradePlanPrimaryButton
                variant='contained'
                size='medium'
                type='submit'
                disabled={getDisabledPlan(professionalPackage)}
                onClick={() =>
                  handleOpen(professionalPackage?.settings?.paddleProductId)
                }
              >
                Get started
              </UpgradePlanPrimaryButton>
            </Grid>
          </Grid>
          <Grid>
            <Divider
              orientation='horizontal'
              flexItem
              sx={{
                color: '#EDEDED',
              }}
            />
          </Grid>
          <Grid padding={'32px 24px'}>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                {`${
                  professionalPackage?.settings?.dailyWordCount || ''
                } keywords tracked`}
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                Unlimited domains
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                Automatic daily updates
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                On-demand checks
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                Competitor tracking
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>Email reports</StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>Alerts</StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                One-click sharing
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                Organize with folders
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                White-label reporting
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>API</StylesPlanCheckItemText>
            </Grid>
          </Grid>
        </StylesPriceContainer>
        <StylesPriceContainer>
          <Grid padding={'24px'}>
            <StylesPlanName>{enterprisePackage?.name || ''}</StylesPlanName>
            <Grid container spacing={1} padding={'16px 0'}>
              <StylesPlanPrice item>
                {`$${enterprisePackage?.settings?.price || ''}`}
              </StylesPlanPrice>
              <StylesPlanPerMonth item alignSelf={'flex-end'}>
                {`per ${planType === 'monthly' ? 'month' : 'year'}`}
              </StylesPlanPerMonth>
            </Grid>
            <StylesPlanDescription>Best for large teams.</StylesPlanDescription>
            <Grid paddingTop={'32px'} paddingBottom={'8px'}>
              <UpgradePlanPrimaryButton
                variant='contained'
                size='medium'
                type='submit'
                disabled={getDisabledPlan(enterprisePackage)}
                onClick={() =>
                  handleOpen(enterprisePackage?.settings?.paddleProductId)
                }
              >
                Get started
              </UpgradePlanPrimaryButton>
            </Grid>
          </Grid>
          <Grid>
            <Divider
              orientation='horizontal'
              flexItem
              sx={{
                color: '#EDEDED',
              }}
            />
          </Grid>
          <Grid padding={'32px 24px'}>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                {`${
                  enterprisePackage?.settings?.dailyWordCount || ''
                } keywords tracked`}
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                Unlimited domains
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                Automatic daily updates
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                On-demand checks
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                Competitor tracking
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>Email reports</StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>Alerts</StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                One-click sharing
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                Organize with folders
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>
                White-label reporting
              </StylesPlanCheckItemText>
            </Grid>
            <Grid container spacing={1} alignItems={'center'}>
              <CircleCheckIcon />
              <StylesPlanCheckItemText>API</StylesPlanCheckItemText>
            </Grid>
          </Grid>
        </StylesPriceContainer>
        <ReviewUpdateSubscriptionModal
          handleClose={handleClose}
          open={isReviewUpdateSubscriptionModal}
          paddleProductId={activePaddleProductId}
        />
      </Grid>
    </>
  );
};

export default PricingTableMobile;
