import React, { useEffect } from 'react';
import { Navigate, Outlet, useNavigate, useParams } from 'react-router';
import { LicenseInfo } from '@mui/x-license-pro';
import ROUTES from './constants';
import {
  getRefreshWithLocalStorage,
  getRefreshWithSessionStorage,
} from '../utils/localStorage';
import { useAppSelector } from '../store';
import { MUI_X_PRO_LICENSE_KEY } from '../constants/config';
import {
  updateCompetitorsBalanceCountSocket,
  updateDailyKeywordsBalanceCountSocket,
  updateEmailReportsBalanceCountSocket,
  updateInvitationsBalanceCountSocket,
  updateMonthlyKeywordsBalanceCountSocket,
  updateNotesBalanceCountSocket,
  updateProjectsBalanceCountSocket,
  updateSharedLinksBalanceCountSocket,
  updateTriggersBalanceCountSocket,
  updateUsersBalanceCountSocket,
} from '../features/auth/socket';
import { IUpdateBalanceCount } from '../features/auth/types';
import useAccountLimits from '../features/auth/hooks/useAccountLimits';
import { getRemoteProjectsToServer } from '../features/projects/socket';
import { useLocation } from 'react-router-dom';

/**
 * PrivateRoutes component that protects routes requiring authentication.
 * It checks user authentication status and manages socket connections
 * for real-time updates on balance counts.
 *
 * @returns {JSX.Element} Renders child components if authenticated,
 * otherwise redirects to the login page.
 */
const PrivateRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const {
    onUpdateEmailReportsBalanceCount,
    onUpdateTriggersBalanceCount,
    onUpdateUsersBalanceCount,
    onUpdateInvitationsBalanceCount,
    onUpdateCompetitorsBalanceCount,
    onUpdateNotesBalanceCount,
    onUpdateDailyKeywordsBalanceCount,
    onUpdateMonthlyKeywordsBalanceCount,
    onUpdateSharedLinksBalanceCount,
    onUpdateProjectsBalanceCount,
  } = useAccountLimits();

  const isAuth = useAppSelector(state => state.auth.isAuth);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const refresh = getRefreshWithLocalStorage();
  const sessionRefresh = getRefreshWithSessionStorage();

  useEffect(() => {
    // Set the license key for MUI X Pro components on component mount.
    LicenseInfo.setLicenseKey(MUI_X_PRO_LICENSE_KEY);
  }, []);

  useEffect(() => {
    if (isAuth && currentAccount?.accountId) {
      updateEmailReportsBalanceCountSocket(
        currentAccount.accountId,
        (value: IUpdateBalanceCount) => {
          onUpdateEmailReportsBalanceCount(value);
        }
      );
      updateTriggersBalanceCountSocket(
        currentAccount.accountId,
        (value: IUpdateBalanceCount) => {
          onUpdateTriggersBalanceCount(value);
        }
      );
      updateUsersBalanceCountSocket(
        currentAccount.accountId,
        (value: IUpdateBalanceCount) => {
          onUpdateUsersBalanceCount(value);
        }
      );
      updateInvitationsBalanceCountSocket(
        currentAccount.accountId,
        (value: IUpdateBalanceCount) => {
          onUpdateInvitationsBalanceCount(value);
        }
      );
      updateCompetitorsBalanceCountSocket(
        currentAccount.accountId,
        (value: IUpdateBalanceCount) => {
          onUpdateCompetitorsBalanceCount(value);
        }
      );
      updateNotesBalanceCountSocket(
        currentAccount.accountId,
        (value: IUpdateBalanceCount) => {
          onUpdateNotesBalanceCount(value);
        }
      );
      updateDailyKeywordsBalanceCountSocket(
        currentAccount.accountId,
        (value: IUpdateBalanceCount) => {
          onUpdateDailyKeywordsBalanceCount(value);
        }
      );
      updateMonthlyKeywordsBalanceCountSocket(
        currentAccount.accountId,
        (value: IUpdateBalanceCount) => {
          onUpdateMonthlyKeywordsBalanceCount(value);
        }
      );
      updateSharedLinksBalanceCountSocket(
        currentAccount.accountId,
        (value: IUpdateBalanceCount) => {
          onUpdateSharedLinksBalanceCount(value);
        }
      );
      updateProjectsBalanceCountSocket(
        currentAccount.accountId,
        (value: IUpdateBalanceCount) => {
          onUpdateProjectsBalanceCount(value);
        }
      );
    }
  }, [
    isAuth,
    currentAccount?.accountId,
    onUpdateCompetitorsBalanceCount,
    onUpdateDailyKeywordsBalanceCount,
    onUpdateEmailReportsBalanceCount,
    onUpdateMonthlyKeywordsBalanceCount,
    onUpdateNotesBalanceCount,
    onUpdateTriggersBalanceCount,
    onUpdateUsersBalanceCount,
    onUpdateSharedLinksBalanceCount,
    onUpdateProjectsBalanceCount,
  ]);

  useEffect(() => {
    if (params?.id && currentAccount.accountId) {
      getRemoteProjectsToServer(currentAccount.accountId, projectIds => {
        if (
          params?.id &&
          projectIds.length &&
          location?.pathname === `/projects/${params?.id}`
        ) {
          navigate(ROUTES.projects);
        }
      });
    }
  }, [currentAccount?.accountId, navigate, params?.id, location?.pathname]);

  return isAuth || refresh || sessionRefresh ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to={ROUTES.login} />
  );
};

export default PrivateRoutes;
