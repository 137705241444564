import React, { useEffect, useMemo, useState } from 'react';

import CheckboxWrapper from './CheckboxWrapper';
import { CustomTableProps } from '../types';
import { useTeams } from '../../../../../../../hooks';

import { CustomDataGrid } from './customTableStyles';
import { GridRowParams } from '@mui/x-data-grid';
import { CustomNoRowsOverlay } from './';
import { TableNoData } from '../../../../../../../shared/components';
import { arraysAreEqual } from '../../../../../../../utils';
import { Box } from '@mui/material';
import { useAppSelector } from '../../../../../../../store';

const CustomTable: React.FC<CustomTableProps> = ({
  rows,
  rowSelectionModel,
  sortModel,
  paginationModel,
  onRowSelectionModelChange,
  onSortModelChange,
}) => {
  const { columns, columnsSkeleton } = useTeams(onRowSelectionModelChange);

  const teamsDeleteElements = useAppSelector(
    state => state.auth.teamsDeleteElements
  );
  const user = useAppSelector(state => state.auth.user);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const users = useAppSelector(state => state.auth.users);
  const searchKeywordUsersTeam = useAppSelector(
    state => state.auth.search.searchKeywordUsersTeam
  );
  const openTariffPlanBanner = useAppSelector(
    state => state.auth.openTariffPlanBanner
  );

  const [itemCount, setItemCount] = useState(
    users?.meta?.itemCount || paginationModel.pageSize
  );

  const arrayOfObjects = useMemo(
    () =>
      Array.from({ length: itemCount }, (_, index) => ({
        email: (index + 1).toString(),
      })),
    [itemCount]
  );

  const isRowSelectable = (params: GridRowParams) => {
    if (currentAccount.role.name === 'ViewOnly') {
      return false;
    }

    if (params.row.id === user.id) return false;

    return !(
      params.row.id !== user.id && currentAccount.owner.id === params.row.id
    );
  };

  const renderRows = useMemo(() => {
    return !users?.items ? arrayOfObjects : rows;
  }, [arrayOfObjects, users?.items, rows]);

  const renderColumns = useMemo(() => {
    return !users?.items ? columnsSkeleton : columns;
  }, [columns, columnsSkeleton, users?.items]);

  useEffect(() => {
    if (users?.meta?.itemCount) {
      setItemCount(users.meta.itemCount);
    }
  }, [users?.meta?.itemCount]);

  useEffect(() => {
    const filtered = users?.items
      ?.filter(obj => rowSelectionModel.includes(obj.email))
      ?.map(item => item.email)
      .filter(item => !teamsDeleteElements.includes(item));
    if (filtered && !arraysAreEqual(rowSelectionModel, filtered)) {
      onRowSelectionModelChange(filtered);
    }
  }, [
    teamsDeleteElements,
    onRowSelectionModelChange,
    users?.items,
    rowSelectionModel,
  ]);

  return (
    <>
      <CustomDataGrid
        initialState={{
          pinnedColumns: {
            right: ['actions'],
          },
        }}
        autoHeight
        columnHeaderHeight={40}
        rowHeight={40}
        getRowId={row => {
          return row.email;
        }}
        rows={renderRows}
        columns={renderColumns}
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={onRowSelectionModelChange}
        keepNonExistentRowsSelected
        hideFooterPagination
        checkboxSelection={currentAccount.role.name !== 'ViewOnly'}
        disableRowSelectionOnClick
        disableColumnMenu
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        isRowSelectable={isRowSelectable}
        components={{
          BaseCheckbox: CheckboxWrapper,
          NoRowsOverlay: !users?.items
            ? Box
            : searchKeywordUsersTeam && !users?.items?.length
            ? CustomNoRowsOverlay
            : !users?.items?.length
            ? TableNoData
            : Box,
        }}
        $openTariffPlanBanner={openTariffPlanBanner}
      />
    </>
  );
};

export default CustomTable;
