import { IFolderTree } from '../features/auth/types';

/**
 * Finds the parent folder of a given target folder in a folder tree.
 *
 * This function searches through a hierarchical structure of folders to find
 * the parent of the specified target folder by its ID.
 *
 * @param {IFolderTree} tree - The root folder node of the tree to search through.
 * @param {number} targetFolderId - The ID of the target folder whose parent is to be found.
 * @returns {IFolderTree | null} The parent folder node if found; otherwise, null.
 */
function findParentFolder(
  tree: IFolderTree,
  targetFolderId: number
): IFolderTree | null {
  function findFolder(
    node: IFolderTree,
    parentId: number | null
  ): IFolderTree | null {
    if (node.id === targetFolderId) {
      if (parentId !== null) {
        return node;
      } else {
        return null;
      }
    }

    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        const result = findFolder(child, node.id);
        if (result !== null) {
          return result;
        }
      }
    }

    return null;
  }

  return findFolder(tree, null);
}

/**
 * Finds all parent folders of a specified target folder in a folder tree.
 *
 * This function performs a depth-first search (DFS) to traverse the folder
 * structure and collect all ancestors of the target folder.
 *
 * @param {IFolderTree} tree - The root folder node of the tree to search through.
 * @param {number} targetFolderId - The ID of the target folder whose parents are to be found.
 * @returns {IFolderTree[]} An array of parent folder nodes leading to the target folder.
 */
const findParentFolders = (
  tree: IFolderTree,
  targetFolderId: number
): IFolderTree[] => {
  const parentIds: IFolderTree[] = [];
  const stack: IFolderTree[] = [];

  const dfs = (node: IFolderTree) => {
    stack.push(node);

    if (node.id === targetFolderId) {
      for (const item of stack) {
        parentIds.push(item);
      }
      return;
    }

    if (node.children) {
      for (const childNode of node.children) {
        dfs(childNode);
      }
    }

    stack.pop();
  };

  dfs(tree);
  return parentIds;
};

/**
 * Finds all parent folders of a specified target folder from multiple trees.
 *
 * This function extends the parent folder search to multiple folder trees,
 * allowing the retrieval of all ancestors of the target folder.
 *
 * @param {IFolderTree[]} trees - An array of root folder nodes representing multiple folder trees.
 * @param {number} targetFolderId - The ID of the target folder whose parents are to be found.
 * @returns {IFolderTree[]} An array of parent folder nodes leading to the target folder.
 */
const newFindParentFolders = (
  trees: IFolderTree[],
  targetFolderId: number
): IFolderTree[] => {
  const parentIds: IFolderTree[] = [];
  const stack: IFolderTree[] = [];

  const dfs = (node: IFolderTree) => {
    stack.push(node);

    if (node.id === targetFolderId) {
      parentIds.push(...stack);
      return true;
    }

    if (node.children) {
      for (const childNode of node.children) {
        if (dfs(childNode)) {
          return true;
        }
      }
    }

    stack.pop();
    return false;
  };

  for (const tree of trees) {
    if (dfs(tree)) {
      break;
    }
  }

  return parentIds;
};

/**
 * Finds a folder by its ID within a root folder and its children.
 *
 * This function traverses the folder structure recursively to locate a folder
 * based on its unique identifier.
 *
 * @param {IFolderTree} rootFolder - The root folder node to search through.
 * @param {number} targetFolderId - The ID of the target folder to find.
 * @returns {IFolderTree | null} The found folder node if it exists; otherwise, null.
 */
const findFolderById = (
  rootFolder: IFolderTree,
  targetFolderId: number
): IFolderTree | null => {
  if (rootFolder.id === targetFolderId) {
    return rootFolder;
  }

  for (let i = 0; i < rootFolder.children.length; i++) {
    const found = findFolderById(rootFolder.children[i], targetFolderId);
    if (found) {
      return found;
    }
  }

  return null;
};

export {
  findParentFolder,
  findParentFolders,
  findFolderById,
  newFindParentFolders,
};
