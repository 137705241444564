import { styled } from '@mui/material/styles';
import {Grid} from "@mui/material";

const CountLabel = styled('span')`
  padding: 4px 8px;
  border-radius: 16px;
  border: 1px solid #a0c5f6;
  background: #eff8ff;
  color: #0e59ba;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;

const PopupButtonContainer = styled(Grid)(({ isSelected }: { isSelected: boolean }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '8px',
  border: isSelected ? '1px solid #A0C5F6' : '1px solid #D0D5DD',
  background: isSelected ? '#EFF8FF' : '#FFFFFF',
  boxShadow: '0 1px 2px 0 rgba(16, 24, 40, 0.05)',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '20px',
  color: isSelected ? '#0E59BA' : '#475467',

  ':hover': {
    color: '#344054',
    background: '#f9fafb',
  }
}));

const PopupSelectButton = styled(Grid)(({ isSelected }: { isSelected?: boolean }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '20px',
  color: isSelected ? '#0E59BA' : '#475467',
  fontFamily: 'Inter, Arial',
  padding: !isSelected ? '7px 12px' : '5.5px 12px',
}));

const SelectedPopupItems = styled(Grid)`
  display: flex;
  align-items: center;
  color: #101828;
  border-left: 1px solid #A0C5F6;
  padding: 5px 12px 5px 8px;
`;

export { CountLabel, PopupButtonContainer, PopupSelectButton, SelectedPopupItems };
