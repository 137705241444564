import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';

import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import PlusIcon from '../../../../../../shared/images/PlusIcon';
import {
  NewFolderModal,
  NewProjectModal,
} from '../TableFolders/components/Modals';

import { BreadcrumbsPadding } from '../../styles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../../shared/buttons/styles';
import { useFolders } from '../../../../../../hooks';
import { useAppSelector } from '../../../../../../store';
import useAccountLimits from '../../../../../auth/hooks/useAccountLimits';

/**
 * Header component for the Folders view.
 * Provides breadcrumbs and options for adding new folders and projects.
 */
const Header = () => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const { onCheckSubscriptionExpiration } = useAccountLimits();
  const { currentFolder, isRootView } = useFolders();

  const folderTree = useAppSelector(state => state.auth.myAccount.folderTree);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const [isOpenNewFolderModal, setIsOpenNewFolderModal] =
    React.useState<boolean>(false);
  const [isOpenNewProjectModal, setIsOpenNewProjectModal] =
    React.useState<boolean>(false);

  /**
   * Opens the "Add New Folder" modal.
   */
  const handleOpenNewFolderModal = () => setIsOpenNewFolderModal(true);

  /**
   * Closes the "Add New Folder" modal.
   */
  const handleCloseNewFolderModal = () => setIsOpenNewFolderModal(false);

  /**
   * Opens the "Add New Project" modal if the subscription has not expired.
   */
  const handleOpenNewProjectModal = () => {
    const isExpiration = onCheckSubscriptionExpiration();
    if (!isExpiration) {
      setIsOpenNewProjectModal(true);
    }
  };

  /**
   * Closes the "Add New Project" modal.
   */
  const handleCloseNewProjectModal = () => setIsOpenNewProjectModal(false);

  return (
    <Grid
      width='100%'
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      xs={12}
    >
      <BreadcrumbsPadding container item xs={12} sm={5}>
        <Breadcrumbs />
      </BreadcrumbsPadding>
      {!extraSmallScreen ? (
        <Grid container item sm={7} justifyContent={'flex-end'}>
          {!isRootView && currentAccount?.role?.name !== 'ViewOnly' && (
            <Grid item>
              <Grid container item paddingRight='8px'>
                <GrayButtonSmall
                  sx={{ padding: '5px 15px !important' }}
                  disabled={!folderTree}
                  outline
                  size='small'
                  startIcon={<PlusIcon stroke={'#475467'} />}
                  onClick={handleOpenNewProjectModal}
                >
                  Add New Project
                </GrayButtonSmall>
              </Grid>
            </Grid>
          )}
          {currentAccount?.role?.name !== 'ViewOnly' && (
            <Grid item>
              <Grid container item paddingRight='24px'>
                <PrimaryButtonSmall
                  sx={{ padding: '6px 16px !important' }}
                  variant='contained'
                  size='small'
                  startIcon={<PlusIcon />}
                  onClick={handleOpenNewFolderModal}
                >
                  Add New Folder
                </PrimaryButtonSmall>
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : null}

      <NewFolderModal
        open={isOpenNewFolderModal}
        handleClose={handleCloseNewFolderModal}
      />
      <NewProjectModal
        handleClose={handleCloseNewProjectModal}
        open={isOpenNewProjectModal}
        folderId={currentFolder.id}
      />
    </Grid>
  );
};

export default Header;
