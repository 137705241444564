import React from 'react';
import { Fade, Grid, Modal, Typography } from '@mui/material';
import {
  CloseDeletePopoverOne,
  CustomModal,
  HeaderContainer,
  ModalContainer,
} from '../../../auth/components/Settings/components/Account/styles';
import CloseIcon from '@mui/icons-material/Close';
import { StyledDescriptions } from '../../../auth/components/Settings/components/Billing/components/SadToSeeYouGoModal/styles';
import { SubscriptionUpdateSuccessfullModalProps } from './types';
import { PrimaryButtonSmall } from '../../../../shared/buttons/styles';
import CheckCircle from '../../../../shared/images/CheckCircle';
import { useNavigate } from 'react-router';

const SubscriptionUpdateSuccessfullModal: React.FC<
  SubscriptionUpdateSuccessfullModalProps
> = ({ open, handleClose }) => {
  const navigate = useNavigate();

  const onBackSubscription = () => {
    handleClose();
    navigate('/settings/billing');
  };

  return (
    <Modal
      disableAutoFocus
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <CustomModal>
          <ModalContainer>
            <HeaderContainer>
              <CloseDeletePopoverOne onClick={handleClose}>
                <CloseIcon fontSize='small' />
              </CloseDeletePopoverOne>
              <Grid paddingTop={'6px'}>
                <CheckCircle />
              </Grid>
              <Typography variant='h6' fontWeight='600' paddingTop='29px'>
                Update Successful
              </Typography>
              <StyledDescriptions>
                Your subscription has been updated.
              </StyledDescriptions>
            </HeaderContainer>

            <Grid item paddingTop={'32px'}>
              <PrimaryButtonSmall
                fullWidth
                variant='contained'
                size='small'
                type='submit'
                onClick={onBackSubscription}
              >
                Back to subscription details
              </PrimaryButtonSmall>
            </Grid>
          </ModalContainer>
        </CustomModal>
      </Fade>
    </Modal>
  );
};

export default SubscriptionUpdateSuccessfullModal;
