import { useAuth } from '../../../../../../../../../../../../hooks';
import React from 'react';
import {
  PaginationContainer,
  StyledTablePagination,
} from '../../../../../../../../../../../projects/components/Projects/components/TableProjects/components/Pagination/styles';
import { useAppSelector } from '../../../../../../../../../../../../store';

/**
 * Pagination component for handling the pagination of the invoice table.
 * It allows users to navigate through pages and set the number of rows displayed per page.
 */
const Pagination = () => {
  const { onSetInvoicesPaginationPage, onSetInvoicesPageSize } = useAuth();

  const invoicesState = useAppSelector(
    state => state.auth.payment.invoicesState
  );
  const paginationModel = useAppSelector(
    state => state.auth.tables.invoice.paginationModel
  );

  /**
   * Handles changing the number of rows displayed per page.
   * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} e - The event containing the new page size.
   */
  const handleSetPaginationPageSize = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onSetInvoicesPageSize(Number(e.target.value));
  };

  /**
   * Handles changing the current page.
   * @param {React.MouseEvent<HTMLButtonElement> | null} event - The event triggering the page change.
   * @param {number} page - The new page number.
   */
  const handleSetPaginationPage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    onSetInvoicesPaginationPage(Number(page));
  };

  return (
    <PaginationContainer xs={12}>
      <StyledTablePagination
        labelRowsPerPage='Show rows:'
        count={Number(invoicesState?.meta?.totalItems) || 0}
        page={paginationModel.page}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onPageChange={handleSetPaginationPage}
        rowsPerPage={paginationModel.pageSize}
        onRowsPerPageChange={handleSetPaginationPageSize}
      />
    </PaginationContainer>
  );
};

export default Pagination;
