import { styled } from '@mui/material/styles';

const CirclesContainer = styled('div')`
  display: flex;
  padding: 18px 0;
`;

const CircleContainer = styled('div')`
  display: flex;
  align-items: center;
  :first-child {
    padding-right: 24px;
  }
`;

const Circle = styled('div')<{ backgroundColor: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${props => props.backgroundColor};
  margin-right: 8px;
`;

const CircleLabel = styled('div')`
  color: #171717;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

export { Circle, CircleLabel, CirclesContainer, CircleContainer };
