/**
 * Default visibility model for root columns.
 */
export const DEFAULT_ROOT_COLUMN_VISIBILITY_MODEL = {
  name: true,
  created: true,
  createdBy: true,
  updatedAt: true,
};

/**
 * Default visibility model for columns.
 */
export const DEFAULT_COLUMN_VISIBILITY_MODEL = {
  name: true,
  createdAt: true,
  createdBy: false,
  lastModified: false,
  deviceType: false,
  url: false,
  totalKeywords: false,
  improved: true,
  declined: true,
  noChange: false,
  dailyAverage: false,
  frequency: false,
  updatedAt: true,
};
