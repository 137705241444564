import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel/InputLabel';

const FieldTextStyle = styled(InputLabel)`
  color: #344054;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  transform-origin: initial;
  transform: initial;
`;

export { FieldTextStyle };
