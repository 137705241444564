import { Autocomplete, Grid, TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { StyledDescription, StyledHead } from '../../styles';
import { PrimaryButtonCustom } from '../../../../../../../../shared/buttons/styles';
import SelectIcon from '../../../../../../../../shared/components/SelectIcon';
import { useAuth } from '../../../../../../../../hooks';
import { ITariffPlan } from '../../../../../../types';
import usePayment from '../../../../../../../payment/hooks/usePayment';
import { ENVIRONMENT } from '../../../../../../../../constants/config';
import ReviewUpdateSubscriptionModal from '../../../../../../../payment/components/ReviewUpdateSubscriptionModal/ReviewUpdateSubscriptionModal';
import { useAppSelector } from '../../../../../../../../store';

const MoreAPICredits = () => {
  const { onGetTariffPlans } = useAuth();
  const billing = useAppSelector(state => state.auth.payment.billing);
  const tariffPlansList = useAppSelector(
    state => state.auth.payment.tariffPlansList
  );
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const checkoutSettings = useAppSelector(state => state.auth.checkoutSettings);

  const { paddle } = usePayment();

  const [plan, setPlan] = useState<ITariffPlan | undefined>(undefined);
  const [isReviewUpdateSubscriptionModal, setIsReviewUpdateSubscriptionModal] =
    useState(false);

  const userPlanCanceled = useMemo(
    () => billing?.status?.name === 'canceled',
    [billing?.status?.name]
  );

  const userPlanDeactivated = useMemo(
    () => billing?.status?.name === 'deactivated',
    [billing?.status?.name]
  );

  const tariffPlansOptions = useMemo(
    () =>
      tariffPlansList
        .filter(item =>
          !userPlanDeactivated
            ? billing?.tariffPlan?.settings?.paddleProductId !==
              item?.settings?.paddleProductId
            : true
        )
        .filter(item => item.name !== 'Trial Period'),
    [
      billing?.tariffPlan?.settings?.paddleProductId,
      tariffPlansList,
      userPlanDeactivated,
    ]
  );

  const onGetAdditionalCredits = () => {
    if (paddle && plan && currentAccount?.accountId && checkoutSettings) {
      paddle.Checkout.open({
        items: [{ price_id: plan.settings.paddleProductId, quantity: 1 }],
        customer: { email: currentAccount.owner.ownerEmail },
        settings: {
          allowLogout: false,
          showAddDiscounts: checkoutSettings.showAddDiscounts,
          showAddTaxId: false,
          allowedPaymentMethods: checkoutSettings.paymentMethods,
        },
        customData: { id: currentAccount.accountId, environment: ENVIRONMENT },
      });
    }
  };

  const handleOpen = () => {
    if (plan) {
      if (
        billing &&
        billing.tariffPlan.name !== 'Trial Period' &&
        !userPlanDeactivated
      ) {
        setIsReviewUpdateSubscriptionModal(true);
      } else {
        onGetAdditionalCredits();
      }
    }
  };

  const handleClose = () => {
    setPlan(undefined);
    setIsReviewUpdateSubscriptionModal(false);
  };

  useEffect(() => {
    if (!tariffPlansOptions.length) {
      onGetTariffPlans({ type: 'monthly' });
    }
  }, [onGetTariffPlans, tariffPlansOptions.length]);

  return (
    <>
      <Grid xs={12}>
        <Grid>
          <StyledHead>Get more API credits</StyledHead>

          <StyledDescription>
            Upgrade your plan to get more API credits.
          </StyledDescription>
        </Grid>

        <Grid padding={'24px 0'}>
          <Autocomplete
            sx={{ width: '300px' }}
            disableClearable
            size={'small'}
            id='plan'
            options={tariffPlansOptions}
            getOptionLabel={option =>
              `${option.name} ${option.settings.dailyWordCount} keywords`
            }
            disabled={userPlanCanceled}
            value={plan}
            popupIcon={<SelectIcon />}
            renderInput={params => (
              <TextField
                {...params}
                name='plan'
                placeholder={'Choose credit amount'}
              />
            )}
            onChange={(_, value) => {
              setPlan(value);
            }}
          />
        </Grid>

        <Grid>
          <PrimaryButtonCustom
            disabled={!plan}
            variant='contained'
            size='small'
            $padding='8px 16px'
            onClick={handleOpen}
          >
            Get Additional Credits
          </PrimaryButtonCustom>
        </Grid>
      </Grid>
      <ReviewUpdateSubscriptionModal
        handleClose={handleClose}
        open={isReviewUpdateSubscriptionModal}
        paddleProductId={plan?.settings?.paddleProductId || null}
      />
    </>
  );
};

export default MoreAPICredits;
