import React from 'react';
import {
  PaginationContainer,
  StyledTablePagination,
} from '../../../../../../../../../projects/components/Projects/components/TableProjects/components/Pagination/styles';
import useAlerts from '../../../../../../../../hooks/useAlerts';

const Pagination = () => {
  const {
    onSetPaginationPageSizeTriggerKeywords,
    onSetPaginationPageTriggerKeywords,
    triggerKeywords,
    tables: {
      triggerKeywords: { paginationModel },
    },
  } = useAlerts();

  const handleSetPaginationPageSize = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onSetPaginationPageSizeTriggerKeywords(Number(e.target.value));
  };

  const handleSetPaginationPage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    onSetPaginationPageTriggerKeywords(Number(page));
  };

  return (
    <PaginationContainer xs={12}>
      <StyledTablePagination
        labelRowsPerPage='Show rows:'
        count={triggerKeywords?.meta?.totalItems || 0}
        page={paginationModel.page}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onPageChange={handleSetPaginationPage}
        rowsPerPage={paginationModel.pageSize}
        onRowsPerPageChange={handleSetPaginationPageSize}
      />
    </PaginationContainer>
  );
};

export default Pagination;
