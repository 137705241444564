import React from 'react';

const ChevronRightFolderIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.22871 2.60448C4.9138 2.28928 4.375 2.51231 4.375 2.95788L4.375 11.0437C4.375 11.489 4.91335 11.7121 5.22839 11.3974L9.27129 7.35812C9.46664 7.16295 9.46678 6.84637 9.27161 6.65102L5.22871 2.60448Z'
      fill='#667085'
    />
  </svg>
);

export default ChevronRightFolderIcon;
