import { styled } from '@mui/system';
import { Autocomplete, Popover } from '@mui/material';

const StyledAutocomplete = styled(Autocomplete)`
  width: 300px;
  .MuiAutocomplete-popupIndicator {
    transform: rotate(0) !important;
  }
`;

const StyledPopoverMobile = styled(Popover)<{ isFreeTrial?: boolean }>`
  .MuiPopover-paper {
    position: fixed;
    top: ${props =>
      props.isFreeTrial ? '70px !important' : '10px !important'};
    left: 50% !important;
    transform: translate(-50%, 0) !important;
    width: 100%;
  }
`;

const StyledPopoverHeader = styled(Popover)<{ isFreeTrial?: boolean }>`
  .MuiPopover-paper {
    width: 495px;
    top: ${props =>
      props.isFreeTrial ? '70px !important' : '10px !important'};
  }
`;

export { StyledAutocomplete, StyledPopoverMobile, StyledPopoverHeader };
