import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { StyledItemContainer } from '../../styles';
import { StyledDescription, StyledStarterPlan } from '../StarterPlan/styles';
import {
  StyledCancelSubscriptionButton,
  StyledEmail,
  StyledExpiry,
  StyledPaymentMethodItemCard,
  StyledVisaEnding,
} from './steles';
import { GrayButtonCustom } from '../../../../../../../../../../shared/buttons/styles';
import EmailIcon from '../../../../../../../../../../shared/images/EmailIcon';
import CancelSubscriptionModal from '../../../CancelSubscriptionModal/CancelSubscription';
import SadToSeeYouGoModal from '../../../SadToSeeYouGoModal/SadToSeeYouGoModal';
import usePayment from '../../../../../../../../../payment/hooks/usePayment';
import { GetTransactionToUpdatePaymentMethodResponse } from '../../../../../../../../../payment/types';
import { ENVIRONMENT } from '../../../../../../../../../../constants/config';
import { useAppSelector } from '../../../../../../../../../../store';
import {
  getPaymentIcon,
  getPaymentLabel,
} from '../../../../../../../../../../utils/payment';

/**
 * PaymentMethod component to display current payment method details, allow editing of payment method,
 * and provide an option to cancel the subscription.
 *
 * @returns {JSX.Element} A component displaying payment method details with options to edit or cancel.
 */
const PaymentMethod = () => {
  const {
    paddle,
    onGetTransactionToUpdatePaymentMethod,
    isLoadingTransactionToUpdatePaymentMethod,
  } = usePayment();

  const billing = useAppSelector(state => state.auth.payment.billing);
  const user = useAppSelector(state => state.auth.user);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const checkoutSettings = useAppSelector(state => state.auth.checkoutSettings);

  const [openModal, setOpenModal] = React.useState(false);
  const [openSadToSeeYouGoModal, setOpenSadToSeeYouGoModal] =
    React.useState(false);

  const passwordExists = useMemo(() => !!user && user.passwordExists, [user]);

  /**
   * Handle opening and closing the Sad To See You Go modal.
   */
  const handleOpenSadToSeeYouGoModal = () => {
    setOpenSadToSeeYouGoModal(true);
  };
  const handleCloseSadToSeeYouGoModal = () => {
    setOpenSadToSeeYouGoModal(false);
  };

  /**
   * Handle opening and closing the Cancel Subscription modal.
   */
  const handleClick = () => {
    if (passwordExists) {
      setOpenModal(previousOpen => !previousOpen);
    } else {
      handleOpenSadToSeeYouGoModal();
    }
  };

  /**
   * Handle opening and closing the Sad To See You Go modal.
   */
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  /**
   * Open the Paddle payment method update interface.
   */
  const openPaddle = (data: GetTransactionToUpdatePaymentMethodResponse) => {
    if (paddle && currentAccount?.accountId && checkoutSettings) {
      paddle.Checkout.open({
        transactionId: data.id,
        customer: { email: currentAccount.owner.ownerEmail },
        items: { quantity: 1 },
        settings: {
          allowLogout: false,
          showAddDiscounts: checkoutSettings.showAddDiscounts,
          showAddTaxId: false,
          allowedPaymentMethods: checkoutSettings.paymentMethods,
        },
        customData: { id: currentAccount.accountId, environment: ENVIRONMENT },
      });
    }
  };

  /**
   * Handle editing the payment method.
   */
  const onEdit = () => {
    if (currentAccount?.accountId) {
      onGetTransactionToUpdatePaymentMethod({
        accountId: currentAccount.accountId,
        successFn: openPaddle,
      });
    }
  };

  const GetPaymentMethod = useMemo(() => {
    if (!billing?.card && !billing?.paymentMethod?.name) {
      return null;
    } else {
      if (billing?.card) {
        return (
          <>
            <StyledVisaEnding>{`${getPaymentLabel(
              billing.card.type
            )} ending in ${billing.card.last4}`}</StyledVisaEnding>
            <StyledExpiry>{`Expiry ${billing.card.expiry}`}</StyledExpiry>
          </>
        );
      }
      if (billing?.paymentMethod?.name) {
        return (
          <>
            <StyledVisaEnding>
              {getPaymentLabel(billing.paymentMethod.name)}
            </StyledVisaEnding>
          </>
        );
      }
    }

    if (billing?.card?.type === 'card') {
      return;
    }

    return <Grid>GetPaymentMethod</Grid>;
  }, [billing?.card, billing?.paymentMethod?.name]);

  if (!billing?.card && !billing?.paymentMethod?.name) {
    return null;
  }

  return (
    <>
      <StyledItemContainer container xs={12}>
        <Grid item xs={12}>
          <StyledStarterPlan>Payment method</StyledStarterPlan>
          <StyledDescription>
            Change how you pay for your plan.
          </StyledDescription>
        </Grid>

        <StyledPaymentMethodItemCard
          container
          item
          xs={12}
          gap={1}
          justifyContent={'space-between'}
        >
          <Grid item>
            <Grid container item gap={'16px'}>
              <Grid item>
                {getPaymentIcon(
                  billing.paymentMethod.name,
                  billing?.card?.type
                )}
              </Grid>

              <Grid item>
                <Grid>{GetPaymentMethod}</Grid>

                <StyledEmail item>
                  <Grid container item gap={'8px'} alignItems={'center'}>
                    <EmailIcon />
                    <Grid>{billing.email}</Grid>
                  </Grid>
                </StyledEmail>
              </Grid>
            </Grid>
          </Grid>

          <Grid>
            <GrayButtonCustom
              $padding='6px 12px'
              size='small'
              outline
              disabled={isLoadingTransactionToUpdatePaymentMethod}
              onClick={onEdit}
            >
              Edit
            </GrayButtonCustom>
          </Grid>
        </StyledPaymentMethodItemCard>

        <StyledCancelSubscriptionButton
          style={{
            visibility:
              billing?.status?.name === 'canceled' ? 'hidden' : undefined,
          }}
          item
          xs={12}
          onClick={handleClick}
        >
          Cancel Subscription
        </StyledCancelSubscriptionButton>
      </StyledItemContainer>
      <CancelSubscriptionModal
        handleOpenSadToSeeYouGoModal={handleOpenSadToSeeYouGoModal}
        handleClose={handleCloseModal}
        open={openModal}
      />
      <SadToSeeYouGoModal
        handleClose={handleCloseSadToSeeYouGoModal}
        open={openSadToSeeYouGoModal}
      />
    </>
  );
};

export default PaymentMethod;
