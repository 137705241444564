import React from 'react';
import { Grid, Typography } from '@mui/material';

import FeaturedIcon from '../../../../../../../../../../shared/images/FeaturedIcon';
import { useProjects } from '../../../../../../../../../../hooks';

import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../../../../../../shared/buttons/styles';
import { StyledCustomNoRowsOverlay, EmptyState, Content } from './styles';
import AddKeywordsModal from '../../../../../../../Projects/components/ProjectRedactorModal/AddKeywords/AddKeywordsModal';
import PlusIcon from '../../../../../../../../../../shared/images/PlusIcon';
import { useAppSelector } from '../../../../../../../../../../store';
import useAccountLimits from '../../../../../../../../../auth/hooks/useAccountLimits';

const CustomNoRowsOverlay = () => {
  const [isOpenAddKeywordsModal, setIsOpenAddKeywordsModal] =
    React.useState(false);

  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const { onGetDeviceTypes, onGetCurrentProject, onSetSearchKeywordRankings } =
    useProjects();
  const { onCheckSubscriptionExpiration } = useAccountLimits();

  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );
  const search = useAppSelector(
    state => state.projects.projectExpandedGraphs.keywordRankings.table.search
  );
  const deviceTypes = useAppSelector(state => state.projects.deviceTypes);

  const handleClose = () => {
    onSetSearchKeywordRankings('');
  };

  const handleOpenAddKeywordsModal = () => {
    if (projectExpanded?.id) {
      const isExpiration = onCheckSubscriptionExpiration();
      if (!isExpiration) {
        !deviceTypes.length && onGetDeviceTypes();
        onGetCurrentProject({
          accountId: currentAccount.accountId,
          projectId: projectExpanded.id,
          tags: true,
          notes: true,
          successFn: () => {
            setIsOpenAddKeywordsModal(true);
          },
        });
      }
    }
  };

  const handleCloseAddKeywordsModal = () => {
    setIsOpenAddKeywordsModal(false);
  };

  return (
    <StyledCustomNoRowsOverlay>
      <EmptyState>
        <Content>
          <FeaturedIcon />
          <Typography
            color='#101828'
            fontSize='16px'
            fontWeight='600'
            lineHeight='24px'
            paddingTop='16px'
            paddingBottom='4px'
          >
            No result found
          </Typography>
          <Typography
            color='#475467'
            fontSize='14px'
            fontWeight='400'
            lineHeight='20px'
            textAlign='center'
          >
            {`Your search “${search}” did not match any keyword. Please try again.`}
          </Typography>
        </Content>
        <Grid
          container
          xs={12}
          justifyContent='space-between'
          paddingTop='24px'
        >
          <Grid item xs={5.7}>
            <GrayButtonSmall
              fullWidth
              size='small'
              outline
              onClick={handleClose}
            >
              Clear Search
            </GrayButtonSmall>
          </Grid>
          <Grid item xs={5.7}>
            <PrimaryButtonSmall
              fullWidth
              variant='contained'
              size='small'
              type='submit'
              startIcon={<PlusIcon />}
              onClick={handleOpenAddKeywordsModal}
            >
              Add Keywords
            </PrimaryButtonSmall>
          </Grid>
        </Grid>
      </EmptyState>
      {isOpenAddKeywordsModal ? (
        <AddKeywordsModal
          open={isOpenAddKeywordsModal}
          handleClose={handleCloseAddKeywordsModal}
        />
      ) : null}
    </StyledCustomNoRowsOverlay>
  );
};

export default CustomNoRowsOverlay;
