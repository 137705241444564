import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { SearchContainer } from '../../../../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';

const TagsContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  width: 100%;
  max-width: 256px;
`;

const TagsSearchContainer = styled(SearchContainer)`
  padding: 0 16px;
  gap: 0;
`;

export { TagsContainer, TagsSearchContainer };
