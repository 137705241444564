import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React, { useState } from 'react';
import { Grid, List, ListItemButton } from '@mui/material';
import { renderListItemTitle } from './utils';
import DownIcon from '../../../../../../../../../../shared/images/DownIcon';
import CustomCheckboxImage from '../../../../../../../../../../shared/images/CustomCheckbox';
import CheckedIcon from '../../../../../../../../../../shared/images/CheckedIcon';

import { CustomCheckbox } from '../../../../../../../../../../shared/checkbox/styles';
import { Popover } from '../../../../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import {
  NoBorderButton,
  PrimaryButtonSmall,
} from '../../../../../../../../../../shared/buttons/styles';
import { ListItemTypography, MainContainer, StyledListItem } from './styles';
import { useSharedLinks } from '../../../../../../../../../../hooks';
import { positionFilter } from '../../../../../../../../../projects/utils';
import {
  DEFAULT_KEYWORD_POSITION_MODEL,
  DEFAULT_KEYWORD_POSITIONS_MODEL,
} from '../../../../../../../../../projects/constants';
import { ColumnKeywordPositionModel } from '../../../../../../../../../projects/types';
import {
  PopupButtonContainer,
  PopupSelectButton,
  SelectedPopupItems,
} from '../../../../../../../../../folders/components/Folders/components/TableFolders/components/Header/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from '../../../../../../../../../../store';

const PopupTablePosition = () => {
  const {
    onSetSharedLinksColumnKeywordPositionModel,
    onResetSharedLinksKeywordPositionModel,
  } = useSharedLinks();

  const keywordPositionModel = useAppSelector(
    state =>
      state.sharedLinks.projectGraphs.keywordRankings.table.keywordPositionModel
  );

  const [checkedItems, setCheckedItems] =
    useState<string[]>(keywordPositionModel);

  const handleSelectItem = (keywordSetting: string, checked: boolean) => {
    setCheckedItems(
      Array.from(new Set(positionFilter([...checkedItems, keywordSetting])))
    );

    if (!checked) {
      if (checkedItems.length === 1) {
        setCheckedItems([]);
      } else {
        setCheckedItems(
          Array.from(
            new Set(
              positionFilter(
                checkedItems.filter((item: string) => item !== keywordSetting),
                keywordSetting
              )
            )
          )
        );
      }
    }
  };

  const handleConfirm = (onClose: () => void) => {
    onSetSharedLinksColumnKeywordPositionModel(checkedItems);
    onClose();
  };

  return (
    <>
      <PopupState variant='popover'>
        {popupState => (
          <>
            <PopupButtonContainer isSelected={!!keywordPositionModel.length}>
              <PopupSelectButton
                {...bindTrigger(popupState)}
                isSelected={!!keywordPositionModel.length}
              >
                <Grid>Positions</Grid>
                <DownIcon
                  stroke={keywordPositionModel.length ? '#0E59BA' : '#344054'}
                />
              </PopupSelectButton>
              {keywordPositionModel.length ? (
                <SelectedPopupItems>
                  <Grid>{keywordPositionModel.length} Selected</Grid>
                  <CloseIcon
                    onClick={() => {
                      setCheckedItems(DEFAULT_KEYWORD_POSITION_MODEL);
                      onResetSharedLinksKeywordPositionModel();
                    }}
                    sx={{
                      color: '#000000',
                      position: 'initial',
                      cursor: 'pointer',
                      width: '20px',
                      marginLeft: '4px',
                    }}
                  />
                </SelectedPopupItems>
              ) : null}
            </PopupButtonContainer>

            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MainContainer>
                <List>
                  {Object.keys(DEFAULT_KEYWORD_POSITIONS_MODEL).map(item => {
                    const columnKey = item as ColumnKeywordPositionModel;
                    return (
                      <StyledListItem
                        key={item}
                        disablePadding
                        checkBackgroundColor={
                          checkedItems.some(x => x === item)
                            ? '#EEF3FF'
                            : undefined
                        }
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          '& div': {
                            width: '100%',
                          },
                        }}
                      >
                        <ListItemButton
                          onClick={() => {
                            handleSelectItem(
                              item,
                              !checkedItems.some(x => x === item)
                            );
                          }}
                        >
                          <CustomCheckbox
                            checked={checkedItems.some(x => x === item)}
                            icon={<CustomCheckboxImage />}
                            checkedIcon={<CheckedIcon />}
                            disableFocusRipple
                          />

                          <ListItemTypography>
                            {renderListItemTitle(columnKey)}
                          </ListItemTypography>
                        </ListItemButton>
                      </StyledListItem>
                    );
                  })}
                </List>

                <Grid item xs={12} sx={{ padding: '0 16px' }}>
                  <PrimaryButtonSmall
                    fullWidth
                    variant='contained'
                    size='small'
                    onClick={() => handleConfirm(popupState.close)}
                  >
                    Apply
                  </PrimaryButtonSmall>
                </Grid>
                <Grid item xs={12} sx={{ padding: '8px 16px 8px 16px' }}>
                  <NoBorderButton
                    fullWidth
                    color={'primary'}
                    size='small'
                    onClick={() => {
                      setCheckedItems(DEFAULT_KEYWORD_POSITION_MODEL);
                      onResetSharedLinksKeywordPositionModel();
                      popupState.close();
                    }}
                  >
                    Reset
                  </NoBorderButton>
                </Grid>
              </MainContainer>
            </Popover>
          </>
        )}
      </PopupState>
    </>
  );
};

export default PopupTablePosition;
