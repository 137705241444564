import React, { useMemo } from 'react';

import {
  FooterContainer,
  FooterItem,
  FooterItems,
  FooterMobileContainer,
  FooterMobileLabel,
  FooterWrapper,
} from './styles';
import { useMediaQuery } from '@mui/material';
import { LANDING_PAGE_URL } from '../../../constants/config';
import { onToggleHelpScout } from '../../../utils/helpScout';

const Footer = () => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const items = useMemo(
    () => [
      {
        key: '0',
        title: 'Terms',
        link: `${LANDING_PAGE_URL}/terms-of-service`,
        onClick: () => {
          return;
        },
      },
      {
        key: '1',
        title: 'Privacy',
        link: `${LANDING_PAGE_URL}/privacy-policy`,
        onClick: () => {
          return;
        },
      },
      {
        key: '2',
        title: 'Pricing',
        link: `${LANDING_PAGE_URL}/pricing`,
        onClick: () => {
          return;
        },
      },
      {
        key: '3',
        title: 'Help Center',
        onClick: () => window.open('https://help.serpnest.com'),
      },
      {
        key: '4',
        title: 'Contact Support',
        onClick: () => onToggleHelpScout(),
      },
    ],
    []
  );

  const currentDate = useMemo(() => new Date().getFullYear(), []);

  return (
    <FooterWrapper>
      <FooterContainer>
        <FooterMobileLabel variant='body2' color='#667085'>
          {`© ${currentDate} Serpnest.com. All rights reserved.`}
        </FooterMobileLabel>
        {extraSmallScreen ? (
          <FooterMobileContainer>
            <div>
              {items.map(
                (item, index) =>
                  index <= 2 && (
                    <FooterItem
                      key={item.key}
                      variant='body2'
                      color='#667085'
                      href={item?.link}
                      onClick={item.onClick}
                    >
                      {item.title}
                    </FooterItem>
                  )
              )}
            </div>
            <div>
              {items.map(
                (item, index) =>
                  index > 2 && (
                    <FooterItem
                      key={item.key}
                      variant='body2'
                      color='#667085'
                      href={item?.link}
                      onClick={item.onClick}
                    >
                      {item.title}
                    </FooterItem>
                  )
              )}
            </div>
          </FooterMobileContainer>
        ) : (
          <FooterItems>
            {items.map(item => (
              <FooterItem
                key={item.key}
                variant='body2'
                color='#667085'
                href={item?.link}
                onClick={item.onClick}
              >
                {item.title}
              </FooterItem>
            ))}
          </FooterItems>
        )}
      </FooterContainer>
    </FooterWrapper>
  );
};

export default Footer;
