import { useSharedLinks } from '../../../../../../hooks';
import { Range } from 'react-date-range';
import React, { useMemo } from 'react';
import { DEVISE_TYPE } from '../../../../../projects/constants';
import MobileDeviceIcon from '../../../../../../shared/images/MobileDeviceIcon';
import DesktopDeviceIcon from '../../../../../../shared/images/DesktopDeviceIcon';
import { StyledHeader } from '../../../../../projects/components/ProjectExpanded/components/Header/styles';
import { Grid } from '@mui/material';
import Breadcrumbs from '../Breadcrumbs/Breadcrumds';
import TimesGroup from '../../../../../projects/components/TimesGroup/TimesGroup';
import CustomDateRangePicker from '../../../../../projects/components/ProjectExpanded/components/Header/DateRangePicker';
import { useAppSelector } from '../../../../../../store';

const Header = () => {
  const { onSetRangeDateForProject, onSetProjectSharedDeviceType } =
    useSharedLinks();

  const projectSharedRangeDate = useAppSelector(
    state => state.sharedLinks.projectSharedRangeDate
  );
  const projectSharedDeviceType = useAppSelector(
    state => state.sharedLinks.projectSharedDeviceType
  );
  const projectShared = useAppSelector(
    state => state.sharedLinks.projectShared
  );

  const handleDateRangeChange = (range: Range) => {
    onSetRangeDateForProject(range);
  };

  const timesGroupItems = useMemo(() => {
    return DEVISE_TYPE.map(type => ({
      icon: type === 'Mobile' ? <MobileDeviceIcon /> : <DesktopDeviceIcon />,
      label: type === 'DesktopAndMobile' ? 'Desktop & Mobile' : type,
      value: type,
      isActive: type === projectSharedDeviceType,
      onChange: () => onSetProjectSharedDeviceType(type),
    }));
  }, [projectSharedDeviceType, onSetProjectSharedDeviceType]);

  const filterTimesGroupItems = useMemo(() => {
    if (
      projectShared &&
      (projectShared.searchEngine.name === 'YouTube' ||
        projectShared.searchEngine.name === 'Google My Business' ||
        projectShared.searchEngine.name === 'Google Maps')
    ) {
      return timesGroupItems.filter(item => item.label === 'Desktop');
    }
    return timesGroupItems;
  }, [timesGroupItems, projectShared?.searchEngine?.name]);

  return (
    <StyledHeader container xs={12} marginTop={'24px'}>
      <Grid container item>
        <Breadcrumbs />
      </Grid>
      <Grid
        container
        item
        justifyContent={'space-between'}
        alignItems={'center'}
        xs={12}
      >
        <Grid sx={{ pt: '16px' }}>
          <TimesGroup items={filterTimesGroupItems} />
        </Grid>

        <Grid sx={{ pt: '16px' }}>
          <CustomDateRangePicker
            onChange={handleDateRangeChange}
            rangeDate={projectSharedRangeDate}
          />
        </Grid>
      </Grid>
    </StyledHeader>
  );
};

export default Header;
