import React from 'react';
import { ListActionsProps } from './types';

import { Divider, List } from '@mui/material';
import DeleteAction from './DeleteAction';
import { MainContainer } from '../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/styles';
import EditAction from './EditAction';

const ListActions: React.FC<ListActionsProps> = ({ id, popupClose }) => {
  return (
    <MainContainer>
      <List>
        <EditAction id={id} popupClose={popupClose} />

        <Divider
          orientation='horizontal'
          flexItem
          sx={{
            margin: '8px 0',
            color: '#EDEDED',
          }}
        />

        <DeleteAction id={id} popupClose={popupClose} />
      </List>
    </MainContainer>
  );
};

export default ListActions;
