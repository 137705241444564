import React from 'react';
import { FolderProps } from '../types';
import { useFolders } from '../../../../../../../../../hooks';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Popover } from '../../../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import ListActions from './components/ListActions';

const EllipsisMenuForSystemFolder: React.FC<FolderProps> = ({ folder }) => {
  const { isRootView } = useFolders();

  return (
    <PopupState variant='popover'>
      {popupState => (
        <>
          <IconButton
            {...bindTrigger(popupState)}
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              bindTrigger(popupState).onClick(event);
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <ListActions
              itemId={folder.id}
              itemName={folder.name}
              itemType={folder.type ? folder.type : 'folder'}
              keywordCount={
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                isRootView ? folder.keywordCount : folder.totalKeywords
              }
              popupClose={popupState.close}
              disableExportCSV={
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                folder?.isUpdated
              }
            />
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export default EllipsisMenuForSystemFolder;
