import React from 'react';

const YahooIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
  >
    <g clipPath='url(#clip0_9233_77520)'>
      <path
        d='M15.3 0H2.7C1.20883 0 0 1.20883 0 2.7V15.3C0 16.7912 1.20883 18 2.7 18H15.3C16.7912 18 18 16.7912 18 15.3V2.7C18 1.20883 16.7912 0 15.3 0Z'
        fill='#5F01D1'
      />
      <path
        d='M7.13672 14.2031H4.95703L5.83594 12.1289L3.41016 6.32812H5.625L6.92578 9.66797L8.22656 6.32812H10.4062M12.4453 9H10.0195L12.1992 3.79688H14.625'
        fill='white'
      />
      <path
        d='M10.6523 12.1641C11.3902 12.1641 11.9883 11.5659 11.9883 10.8281C11.9883 10.0903 11.3902 9.49219 10.6523 9.49219C9.91453 9.49219 9.31641 10.0903 9.31641 10.8281C9.31641 11.5659 9.91453 12.1641 10.6523 12.1641Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_9233_77520'>
        <rect width='18' height='18' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default YahooIcon;
