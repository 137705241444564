import React from 'react';
import {
  Box,
  FormHelperText,
  Grid,
  OutlinedInput,
  Typography,
  Autocomplete,
  InputAdornment,
  TextField,
  useMediaQuery,
  Skeleton,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { CustomFormHelperText } from '../../../../../../shared/components';

import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../../shared/buttons/styles';
import { PersonalInfoContainer, FormItem } from '../../styles';
import { AccountInfoFormProps } from './types';
import SelectIcon from '../../../../../../shared/components/SelectIcon';
import { useAppSelector } from '../../../../../../store';

/**
 * AccountInfoForm component represents a form that allows users to update their account information including personal details, country, and timezone.
 *
 * @component
 * @param {AccountInfoFormProps} formik - Formik props to handle form state and submission.
 * @returns {JSX.Element} Rendered AccountInfoForm component.
 */
const AccountInfoForm: React.FC<AccountInfoFormProps> = formik => {
  const countries = useAppSelector(state => state.auth.countries);
  const timezones = useAppSelector(state => state.auth.timezones);
  const isLoadingChangeAccount = useAppSelector(
    state => state.auth.isLoadingChangeAccount
  );
  const isLoading = useAppSelector(state => state.auth.isLoading);
  const isAuth = useAppSelector(state => state.auth.isAuth);

  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  return (
    <Grid container>
      <PersonalInfoContainer
        container
        item
        sx={{
          borderBottom: '1px solid #EAECF0',
          justifyContent: 'space-between',
          pt: 4,
          pb: 4,
        }}
      >
        <Grid item sm={4} xs={12}>
          <Box>
            <Typography
              variant='h6'
              fontWeight='600'
              color='#101828'
              fontSize='18px'
              lineHeight='28px'
            >
              Account info
            </Typography>
          </Box>
          <Box>
            <Typography
              variant='body2'
              color='#475467'
              fontSize='14px'
              lineHeight='20px'
            >
              Update your personal details here.
            </Typography>
          </Box>
          {formik.touched.accountInfoMessage &&
            formik.errors.accountInfoMessage && (
              <FormHelperText error id='accountInfoMessage'>
                <CustomFormHelperText
                  error={formik.errors.accountInfoMessage}
                />
              </FormHelperText>
            )}
        </Grid>
        <Grid
          container
          item
          sm={5}
          xs={12}
          spacing={2}
          sx={{ justifyContent: 'flex-end' }}
        >
          <Grid item>
            {!isAuth || isLoading ? (
              <Skeleton width='88px' height='43px' />
            ) : (
              <GrayButtonSmall
                size='medium'
                outline
                onClick={formik.handleCloseAccountInfo}
              >
                Cancel
              </GrayButtonSmall>
            )}
          </Grid>
          <Grid item>
            {!isAuth || isLoading ? (
              <Skeleton width='130px' height='43px' />
            ) : (
              <PrimaryButtonSmall
                variant='contained'
                size='medium'
                type='submit'
                disabled={
                  isLoadingChangeAccount ||
                  formik.isSubmitting ||
                  !formik.isValid
                }
              >
                Save All Changes
              </PrimaryButtonSmall>
            )}
          </Grid>
        </Grid>
      </PersonalInfoContainer>
      <Grid
        container
        item
        sx={{ borderBottom: '1px solid #EAECF0', pt: 4, pb: 4 }}
      >
        {!extraSmallScreen && (
          <Grid item lg={2} sm={4}>
            <Typography variant='body2' fontWeight='500' color='#344054'>
              Name
            </Typography>
          </Grid>
        )}
        <Grid container item lg={10} sm={8} spacing={2}>
          {extraSmallScreen && (
            <Grid item xs={12}>
              <Typography variant='body2' fontWeight='500' color='#344054'>
                First name
              </Typography>
            </Grid>
          )}
          <Grid item lg={4.5} sm={6} xs={12}>
            {!isAuth || isLoading ? (
              <Skeleton height='40px' width='100%' />
            ) : (
              <>
                <OutlinedInput
                  fullWidth
                  size={'small'}
                  id='firstName'
                  placeholder='First name'
                  name='firstName'
                  value={formik.values.firstName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!(formik.touched.firstName && formik.errors.firstName)
                  }
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <FormHelperText error id='firstName'>
                    <CustomFormHelperText error={formik.errors.firstName} />
                  </FormHelperText>
                )}
              </>
            )}
          </Grid>
          {extraSmallScreen && (
            <Grid item xs={12}>
              <Typography variant='body2' fontWeight='500' color='#344054'>
                Last name
              </Typography>
            </Grid>
          )}
          <Grid item lg={4.5} sm={6} xs={12}>
            {!isAuth || isLoading ? (
              <Skeleton height='40px' width='100%' />
            ) : (
              <>
                <OutlinedInput
                  fullWidth
                  size={'small'}
                  id='lastName'
                  placeholder='Last name'
                  name='lastName'
                  value={formik.values.lastName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={!!(formik.touched.lastName && formik.errors.lastName)}
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <FormHelperText error id='lastName'>
                    <CustomFormHelperText error={formik.errors.lastName} />
                  </FormHelperText>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <FormItem container item sx={{ pt: 4, pb: 4 }}>
        <Grid item lg={2} sm={4} xs={12}>
          <Typography variant='body2' fontWeight='500' color='#344054'>
            Country
          </Typography>
        </Grid>
        <Grid item lg={7.5} sm={8} xs={12}>
          {!isAuth || isLoading ? (
            <Skeleton height='40px' width='100%' />
          ) : (
            <Autocomplete
              fullWidth
              size={'small'}
              id='country'
              options={countries}
              getOptionLabel={option => option.name}
              popupIcon={<SelectIcon />}
              renderOption={(props, option) => (
                <Box
                  component='li'
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    src={option.image}
                    alt={option.name}
                    height='20xp'
                    width='20xp'
                  />
                  <span>{option.name}</span>
                </Box>
              )}
              value={formik.values.country}
              onChange={(_, value) =>
                formik.setFieldValue('country', value || null)
              }
              onBlur={formik.handleBlur}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder='Country'
                  name='country'
                  error={!!(formik.touched.country && formik.errors.country)}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: formik.values?.country?.image ? (
                      <InputAdornment position='start' sx={{ ml: '10px' }}>
                        <img
                          src={formik.values?.country?.image}
                          alt={formik.values?.country.name}
                          height='20xp'
                          width='20xp'
                        />
                      </InputAdornment>
                    ) : null,
                  }}
                />
              )}
            />
          )}
        </Grid>
      </FormItem>
      <FormItem container item sx={{ pt: 4, pb: 4 }}>
        <Grid item lg={2} sm={4} xs={12}>
          <Typography variant='body2' fontWeight='500' color='#344054'>
            Timezone
          </Typography>
          <Typography variant='body2' fontWeight='400' color='#475467'>
            Set the reporting timzone.
          </Typography>
        </Grid>
        <Grid item lg={7.5} sm={8} xs={12}>
          {!isAuth || isLoading ? (
            <Skeleton height='40px' width='100%' />
          ) : (
            <>
              <Autocomplete
                fullWidth
                size={'small'}
                id='timezone'
                options={timezones}
                getOptionLabel={option => option.name}
                value={formik.values.timezone}
                onChange={(_, value) =>
                  formik.setFieldValue('timezone', value || null)
                }
                onBlur={formik.handleBlur}
                popupIcon={<SelectIcon />}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder='Timezones'
                    name='timezone'
                    error={
                      !!(formik.touched.timezone && formik.errors.timezone)
                    }
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position='start' sx={{ ml: '15px' }}>
                          <AccessTimeIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </>
          )}
        </Grid>
      </FormItem>
    </Grid>
  );
};

export default AccountInfoForm;
