import React from 'react';

const SmallTrashIcon: React.FC = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2 4H3.33333H14'
        stroke='#B42318'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.33301 4.00065V2.66732C5.33301 2.3137 5.47348 1.97456 5.72353 1.72451C5.97358 1.47446 6.31272 1.33398 6.66634 1.33398H9.33301C9.68663 1.33398 10.0258 1.47446 10.2758 1.72451C10.5259 1.97456 10.6663 2.3137 10.6663 2.66732V4.00065M12.6663 4.00065V13.334C12.6663 13.6876 12.5259 14.0267 12.2758 14.2768C12.0258 14.5268 11.6866 14.6673 11.333 14.6673H4.66634C4.31272 14.6673 3.97358 14.5268 3.72353 14.2768C3.47348 14.0267 3.33301 13.6876 3.33301 13.334V4.00065H12.6663Z'
        stroke='#B42318'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.33301 7.33398V11.334'
        stroke='#B42318'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.66699 7.33398V11.334'
        stroke='#B42318'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default SmallTrashIcon;
