import React from 'react';
import {
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  useMediaQuery,
} from '@mui/material';
import SearchIcon from '../../../images/SearchIcon';
import FilterIcon from '../../../images/FilterIcon';
import SearchPopoverMobile from '../SearchModal/SearchPopoverMobile';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { StyledPopoverHeader, StyledPopoverMobile } from './styles';
import { SearchProps } from '../../../layout/PrivateLayout/types';

const Search: React.FC<SearchProps> = ({ isFreeTrial }) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  return (
    <>
      {extraSmallScreen ? (
        <PopupState variant='popover' popupId='demo-popup-popover'>
          {popupState => (
            <div>
              <Grid>
                <IconButton {...bindTrigger(popupState)}>
                  <SearchIcon />
                </IconButton>
              </Grid>

              <StyledPopoverMobile
                {...bindPopover(popupState)}
                isFreeTrial={isFreeTrial}
              >
                <SearchPopoverMobile onClose={popupState.close} />
              </StyledPopoverMobile>
            </div>
          )}
        </PopupState>
      ) : (
        <PopupState variant='popover' popupId='demo-popup-popover'>
          {popupState => (
            <div>
              <OutlinedInput
                value={''}
                fullWidth
                type='text'
                id='search'
                name='search'
                placeholder='Search...'
                size={'small'}
                {...bindTrigger(popupState)}
                startAdornment={
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  <>
                    <InputAdornment position='start'>
                      <FilterIcon />
                    </InputAdornment>
                  </>
                }
              />
              <StyledPopoverHeader
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
                isFreeTrial={isFreeTrial}
              >
                <SearchPopoverMobile onClose={popupState.close} />
              </StyledPopoverHeader>
            </div>
          )}
        </PopupState>
      )}
    </>
  );
};

export default Search;
