import React from 'react';
import { KeywordsActionProps } from '../../../../../../../../projects/components/ProjectExpanded/components/KeywordRankings/components/KeywordRankingsTable/components/ActionsMenu/components/types';
import { useAuth, useSharedLinks } from '../../../../../../../../../hooks';
import { DEFAULT_GET_USERS_PARAMS } from '../../../../../../../../auth/components/Settings/components/Team/constants';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import CustomToast from '../../../../../../../../../shared/CustomToast/CustomToast';
import { closeAndUndoSnackbarAction } from '../../../../../../../../../utils/snackbar';
import {
  ListItemTypography,
  StyledListItem,
} from '../../../../../../../../projects/components/ProjectExpanded/components/KeywordRankings/components/KeywordRankingsTable/components/ActionsMenu/styles';
import SmallTrashIcon from '../../../../../../../../../shared/images/SmallTrashIcom';
import { StyledListItemButton } from '../../../../../../../../../shared/buttons/styles';
import { useAppSelector } from '../../../../../../../../../store';

const DeleteSharedLinksAction: React.FC<KeywordsActionProps> = ({
  popupClose,
}) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const {
    onSetDeleteElementsSharedLinksPageTable,
    onGetSharedLinks,
    onBulkDeleteSharedLinks,
  } = useSharedLinks();

  const paginationModel = useAppSelector(
    state => state.sharedLinks.tables.sharedLinksPage.paginationModel
  );
  const search = useAppSelector(
    state => state.sharedLinks.tables.sharedLinksPage.search
  );
  const sortModel = useAppSelector(
    state => state.sharedLinks.tables.sharedLinksPage.sortModel
  );
  const rowSelectionModel = useAppSelector(
    state => state.sharedLinks.tables.sharedLinksPage.rowSelectionModel
  );

  const handleHardDelete = () => {
    onBulkDeleteSharedLinks({
      accountId: currentAccount.accountId,
      ids: rowSelectionModel,
      successFn: () => {
        onGetSharedLinks({
          id: currentAccount.accountId,
          page: paginationModel.page,
          limit: paginationModel.pageSize,
          search: search || DEFAULT_GET_USERS_PARAMS.search,
          sortBy: sortModel[0]?.field,
          sortOrder: sortModel[0]?.sort,
        });
        onSetDeleteElementsSharedLinksPageTable([]);
      },
    });
  };

  const handleDeleteOneKeywords = () => {
    onSetDeleteElementsSharedLinksPageTable(rowSelectionModel);

    popupClose();
    let undoDelete = false;
    const onUndo = () => {
      undoDelete = true;
      onSetDeleteElementsSharedLinksPageTable([]);
    };
    enqueueSnackbar(
      <CustomToast
        message='Shared Links Removed'
        submessage={`Shared Links has been successfully removed.`}
      />,
      {
        action: (snackbarId: SnackbarKey) =>
          closeAndUndoSnackbarAction(snackbarId, onUndo),
        variant: 'error',
      }
    );
    setTimeout(() => {
      !undoDelete && handleHardDelete();
    }, 5000);
  };

  return (
    <StyledListItem>
      <StyledListItemButton onClick={handleDeleteOneKeywords}>
        <SmallTrashIcon />
        <ListItemTypography marginLeft='8px' sx={{ color: '#B42318' }}>
          Delete Shared Links
        </ListItemTypography>
      </StyledListItemButton>
    </StyledListItem>
  );
};

export default DeleteSharedLinksAction;
