import React from 'react';

type Props = {
  stroke?: string;
};

const AlertCircleIcon: React.FC<Props> = ({ stroke = '#F79009' }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
  >
    <path
      d='M19.9997 13.334V20.0007M19.9997 26.6673H20.0163M36.6663 20.0007C36.6663 29.2054 29.2044 36.6673 19.9997 36.6673C10.7949 36.6673 3.33301 29.2054 3.33301 20.0007C3.33301 10.7959 10.7949 3.33398 19.9997 3.33398C29.2044 3.33398 36.6663 10.7959 36.6663 20.0007Z'
      stroke={stroke}
      strokeWidth='3.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default AlertCircleIcon;
