import React from 'react';

const LinkBrokenIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='50'
    height='50'
    viewBox='0 0 50 50'
    fill='none'
  >
    <path
      d='M18.7494 8.33268V4.16602M31.2494 41.666V45.8327M8.33268 18.7494H4.16602M41.666 31.2494H45.8327M10.2373 10.2373L7.29102 7.29102M39.7614 39.7614L42.7077 42.7077M24.9994 36.7845L20.5799 41.2039C17.3256 44.4582 12.0492 44.4582 8.79482 41.2039C5.54045 37.9495 5.54045 32.6731 8.79482 29.4188L13.2142 24.9994M36.7845 24.9994L41.2039 20.5799C44.4582 17.3256 44.4582 12.0492 41.2039 8.79482C37.9495 5.54045 32.6731 5.54045 29.4188 8.79482L24.9994 13.2142'
      stroke='black'
      strokeWidth='1.78571'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default LinkBrokenIcon;
