import React, { useMemo, useState } from 'react';

import SmallTrashIcon from '../../../../../../../../../shared/images/SmallTrashIcom';
import { ProjectActionProps } from './types';
import { useAuth, useProjects } from '../../../../../../../../../hooks';

import {
  ListItemTypography,
  StyledListItem,
  StyledListItemButton,
} from '../styles';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import CustomToast from '../../../../../../../../../shared/CustomToast/CustomToast';
import { closeAndUndoSnackbarAction } from '../../../../../../../../../utils/snackbar';
import { useNavigate, useParams } from 'react-router';
import ROUTES from '../../../../../../../../../routes/constants';
import DeleteProjectModal from '../../../../../../modals/DeleteProjectModal/DeleteProjectModal';
import { useAppSelector } from '../../../../../../../../../store';

const DeleteProjectAction: React.FC<ProjectActionProps> = ({
  projectId,
  popupClose,
}) => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { onGetMyAccount, onGetAccountLimit } = useAuth();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const {
    onSetDeleteElementsProjectsTable,
    onDeleteOneProject,
    onGetProjects,
    onGetAccountTags,
    onGetProjectsListForInvite,
    onSetRowSelectionModel,
  } = useProjects();

  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );
  const selectedTags = useAppSelector(state => state.projects.selectedTags);
  const projects = useAppSelector(state => state.projects.projects);
  const search = useAppSelector(state => state.projects.tables.projects.search);
  const paginationModel = useAppSelector(
    state => state.projects.tables.projects.paginationModel
  );
  const sortModel = useAppSelector(
    state => state.projects.tables.projects.sortModel
  );
  const frequencyFilter = useAppSelector(
    state => state.projects.tables.projects.frequencyFilter
  );
  const columnVisibilityModel = useAppSelector(
    state => state.projects.tables.projects.columnVisibilityModel
  );

  const project = useMemo(
    () =>
      projects?.items?.find(project => project.id === projectId) ||
      projectExpanded,
    [projectExpanded, projectId, projects?.items]
  );

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleHardDelete = () => {
    if (project) {
      onDeleteOneProject({
        accountId: currentAccount.accountId,
        projectId: project.id,
        successFn: () => {
          onSetRowSelectionModel([]);
          onGetAccountLimit({ id: currentAccount.accountId });
          onGetProjectsListForInvite({
            accountId: currentAccount.accountId,
            search: '',
          });
          onGetAccountTags({ accountId: currentAccount.accountId });
          onGetProjects({
            accountId: currentAccount.accountId,
            params: {
              frequencyName: frequencyFilter,
              page: paginationModel.page,
              limit: paginationModel.pageSize,
              tagIds: selectedTags.map(item => item.id).join(),
              search: search,
              sortBy: sortModel[0]?.field,
              sortOrder: sortModel[0]?.sort,
              dailyAverage: columnVisibilityModel.dailyAverage,
            },
          });
          onGetMyAccount(currentAccount.accountId);
          if (id) {
            navigate(ROUTES.projects);
          }
          onSetDeleteElementsProjectsTable([]);
        },
      });
    }
  };

  const handleDeleteOneProject = () => {
    const localDeleteElements = project?.id ? [project.id] : [];
    onSetDeleteElementsProjectsTable(localDeleteElements);

    popupClose();
    let undoDelete = false;
    const onUndo = () => {
      undoDelete = true;
      onSetDeleteElementsProjectsTable([]);
    };

    enqueueSnackbar(
      <CustomToast
        message='Project Removed'
        submessage={`Project ${project?.projectName} has been successfully removed.`}
      />,
      {
        action: (snackbarId: SnackbarKey) =>
          closeAndUndoSnackbarAction(snackbarId, onUndo),
        variant: 'error',
      }
    );

    setTimeout(() => {
      if (!undoDelete) {
        handleHardDelete();
      }
    }, 5000);

    handleCloseModal();
  };

  return (
    <>
      <StyledListItem onClick={handleOpenModal}>
        <StyledListItemButton>
          <SmallTrashIcon />
          <ListItemTypography marginLeft='8px' sx={{ color: '#B42318' }}>
            Delete Project
          </ListItemTypography>
        </StyledListItemButton>
      </StyledListItem>
      <DeleteProjectModal
        isMultiple={false}
        onClose={handleCloseModal}
        onDelete={handleDeleteOneProject}
        open={openModal}
        count={1}
      />
    </>
  );
};

export default DeleteProjectAction;
