import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { CustomDataGrid } from '../../../../../../../projects/components/Projects/components/TableProjects/components/Table/styles';
import { useNotes, useNotesTable } from '../../../../../../../../hooks';
import CheckboxWrapper from '../../../../../../../auth/components/Settings/components/Team/components/CheckboxWrapper';
import { CustomNoRowsOverlay } from '../';
import { TableNoData } from '../../../../../../../../shared/components';
import { arraysAreEqual } from '../../../../../../../../utils';
import { useAppSelector } from '../../../../../../../../store';
import { GridRowParams } from '@mui/x-data-grid';

const Table = () => {
  const { columns, columnsSkeleton } = useNotesTable();
  const {
    onSetRowSelectionModelProjectNotes,
    onSetSortModelProjectNotes,
    projectNotesData,
    tables: { projectNotes },
  } = useNotes();

  const openTariffPlanBanner = useAppSelector(
    state => state.auth.openTariffPlanBanner
  );

  const isRowSelectable = (params: GridRowParams) => {
    return params.row.editOption;
  };

  const [itemCount, setItemCount] = useState(
    projectNotesData?.meta?.itemCount || projectNotes.paginationModel.pageSize
  );

  const arrayOfObjects = useMemo(
    () => Array.from({ length: itemCount }, (_, index) => ({ id: index + 1 })),
    [itemCount]
  );

  const rows = useMemo(() => {
    const arr1 = [
      ...(projectNotesData?.items?.length ? projectNotesData.items : []),
    ];
    const arr2 = [...projectNotes.deleteElements];

    return arr1.filter(item => !arr2.includes(item.id));
  }, [projectNotes.deleteElements, projectNotesData?.items]);

  const isShowActions = useMemo(() => {
    return rows.find(item => item.editOption);
  }, [rows]);

  const renderRows = useMemo(() => {
    return !projectNotesData?.items ? arrayOfObjects : rows;
  }, [arrayOfObjects, projectNotesData?.items, rows]);

  const renderColumns = useMemo(() => {
    return !projectNotesData?.items ? columnsSkeleton : columns;
  }, [columns, columnsSkeleton, projectNotesData?.items]);

  useEffect(() => {
    if (projectNotesData?.meta?.itemCount) {
      setItemCount(projectNotesData.meta.itemCount);
    }
  }, [projectNotesData?.meta?.itemCount]);

  useEffect(() => {
    const filtered = projectNotesData?.items
      ?.filter(obj => projectNotes.rowSelectionModel.includes(obj.id))
      ?.map(item => item.id)
      .filter(item => !projectNotes.deleteElements.includes(item));
    if (filtered && !arraysAreEqual(projectNotes.rowSelectionModel, filtered)) {
      onSetRowSelectionModelProjectNotes(filtered);
    }
  }, [
    projectNotes.deleteElements,
    onSetRowSelectionModelProjectNotes,
    projectNotesData?.items,
    projectNotes.rowSelectionModel,
  ]);

  const pinnedColumns = useMemo(
    () => ({
      right: isShowActions ? ['actions'] : [],
    }),
    [isShowActions]
  );

  return (
    <Grid xs={12}>
      <CustomDataGrid
        pinnedColumns={pinnedColumns}
        autoHeight
        isSelectedRow={false}
        columnHeaderHeight={40}
        rowHeight={40}
        isRowSelectable={isRowSelectable}
        rows={renderRows}
        rowSelectionModel={projectNotes.rowSelectionModel}
        onRowSelectionModelChange={onSetRowSelectionModelProjectNotes}
        columns={renderColumns}
        onSortModelChange={onSetSortModelProjectNotes}
        components={{
          BaseCheckbox: CheckboxWrapper,
          NoRowsOverlay: !projectNotesData
            ? Box
            : projectNotes.search
            ? CustomNoRowsOverlay
            : TableNoData,
        }}
        keepNonExistentRowsSelected
        hideFooterPagination
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnMenu
        $openTariffPlanBanner={openTariffPlanBanner}
      />
    </Grid>
  );
};

export default Table;
