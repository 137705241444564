import React, { useMemo } from 'react';
import { MainContainer } from '../../../../../../../projects/components/ProjectExpanded/components/KeywordRankings/components/KeywordRankingsTable/components/EllipsisMenu/styles';
import { Divider, List } from '@mui/material';
import { ListActionsProps } from './types';
import DeleteProjectNoteAction from './DeleteProjectNoteAction';
import EditProjectNoteAction from './EditProjectNoteAction';
import { useAppSelector } from '../../../../../../../../store';

const ListActions: React.FC<ListActionsProps> = ({
  projectNoteId,
  popupClose,
}) => {
  const isAuth = useAppSelector(state => state.auth.isAuth);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const isViewOnly = useMemo(
    () => isAuth && currentAccount?.role?.name === 'ViewOnly',
    [currentAccount?.role?.name, isAuth]
  );

  return (
    <MainContainer>
      <List>
        {!isViewOnly && (
          <>
            <EditProjectNoteAction
              projectNoteId={projectNoteId}
              popupClose={popupClose}
            />

            <Divider
              orientation='horizontal'
              flexItem
              sx={{
                margin: '8px 0',
                color: '#EDEDED',
              }}
            />
          </>
        )}

        <DeleteProjectNoteAction
          projectNoteId={projectNoteId}
          popupClose={popupClose}
        />
      </List>
    </MainContainer>
  );
};

export default ListActions;
