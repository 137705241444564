import React, { useMemo } from 'react';
import './rechart.css';
import { ChartContainer } from './styled';
import GraphNoData from '../../../../../../../../shared/components/graphNoData/GraphNoData';
import { ImprovedVsDeclinedSkeleton } from '../../../../../../../../shared/graphSkeletons';
import ImprovedVsDeclinedBarChart from './ImprovedVsDeclinedBarChart';
import { useAppSelector } from '../../../../../../../../store';

const Graph = () => {
  const projectImprovedVsDeclined = useAppSelector(
    state => state.projects.projectExpandedGraphs.projectImprovedVsDeclined
  );
  const isLoadingProjectImprovedVsDeclined = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.isLoadingProjectImprovedVsDeclined
  );

  const getCheckedData = useMemo(() => {
    const items = projectImprovedVsDeclined?.items?.length
      ? projectImprovedVsDeclined.items
      : [];

    return items.map(item => ({
      ...item,
      declined: -Number(item.declined),
      lost: -Number(item.lost),
    }));
  }, [projectImprovedVsDeclined?.items]);

  return (
    <ChartContainer>
      {isLoadingProjectImprovedVsDeclined || !projectImprovedVsDeclined ? (
        <ImprovedVsDeclinedSkeleton />
      ) : getCheckedData.length ? (
        <ImprovedVsDeclinedBarChart getCheckedData={getCheckedData} />
      ) : (
        <GraphNoData />
      )}
    </ChartContainer>
  );
};

export default React.memo(Graph);
