import React, { useMemo, useState } from 'react';

import { FolderActionProps } from './types';

import { ListItemTypography, StyledListItem } from '../styles';
import RefreshIcon from '../../../../../../../../../shared/images/RefreshIcon';
import { useFolders, useProjects } from '../../../../../../../../../hooks';

import { StyledListItemButton } from '../../../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/styles';
import RefreshAllKeywordsModal from '../../../../../../../../projects/components/modals/RefreshAllKeywordsModal/RefreshAllKeywordsModal';
import useAccountLimits from '../../../../../../../../auth/hooks/useAccountLimits';
import { useAppSelector } from '../../../../../../../../../store';

const RefreshAllKeywordsAction: React.FC<FolderActionProps> = ({
  itemId,
  itemType,
  keywordCount,
  popupClose,
}) => {
  const {
    onSetRowSelectionModel,
    onGetFoldersById,
    isRootView,
    currentFolder,
    tables: {
      rootFolder: {
        rowSelectionModel,
        paginationModel,
        search,
        sortModelNestedElements,
        rootFolderColumnVisibilityModel,
        childrenFolderColumnVisibilityModel,
      },
    },
  } = useFolders();
  const { onSetRefreshAllKeywordsModal } = useProjects();
  const { onCheckSubscriptionExpiration } = useAccountLimits();

  const myAccount = useAppSelector(state => state.auth.myAccount);
  const open = useAppSelector(
    state => state.projects.modals.refreshAllKeywords
  );
  const selectedTags = useAppSelector(state => state.projects.selectedTags);

  const disableRefreshAllKeywords = useMemo(
    () => !keywordCount && itemType === 'folder',
    [itemType, keywordCount]
  );

  const columnVisibilityModel = useMemo(
    () =>
      isRootView
        ? rootFolderColumnVisibilityModel
        : childrenFolderColumnVisibilityModel,
    [
      childrenFolderColumnVisibilityModel,
      isRootView,
      rootFolderColumnVisibilityModel,
    ]
  );

  const handleOpenModal = () => {
    if (!disableRefreshAllKeywords) {
      const isExpiration = onCheckSubscriptionExpiration();
      if (!isExpiration) {
        onSetRefreshAllKeywordsModal(true);
      }
    }
  };
  const handleCloseModal = () => {
    onSetRowSelectionModel([]);
    onSetRefreshAllKeywordsModal(false);
    if (itemType === 'project' && currentFolder?.id && myAccount?.id) {
      onGetFoldersById({
        accountId: myAccount.id,
        folderId: currentFolder.id,
        params: {
          page: paginationModel.page,
          limit: paginationModel.pageSize,
          search: search,
          sortBy: sortModelNestedElements[0]?.field,
          sortOrder: sortModelNestedElements[0]?.sort,
          tagIds: selectedTags.map(item => item.id).join(),
          dailyAverage: columnVisibilityModel.dailyAverage,
        },
      });
    }
    popupClose();
  };

  const { foldersIds, projectsIds, foldersKeywordCount } = useMemo(() => {
    let foldersIds = [] as number[];
    let projectsIds = [] as number[];

    let foldersKeywordCount = 0;
    let projectsKeywordCount = 0;

    if (rowSelectionModel.length > 0 && !itemId) {
      if (!('type' in rowSelectionModel[0])) {
        foldersIds = rowSelectionModel.map(item => item.id);
        foldersKeywordCount = rowSelectionModel.reduce(
          (sum, project) =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sum + (isRootView ? project.keywordCount : project.totalKeywords),
          0
        );
      } else {
        const folders = rowSelectionModel.filter(
          item => item.type === 'folder'
        );
        foldersIds = folders.map(item => item.id);
        foldersKeywordCount = folders.reduce(
          (sum, project) =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sum + (isRootView ? project.keywordCount : project.totalKeywords),
          0
        );

        const projects = rowSelectionModel.filter(
          item => item.type === 'project'
        );
        projectsIds = projects.map(item => item.id);
        projectsKeywordCount = projects.reduce(
          (sum, project) =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sum + (isRootView ? project.keywordCount : project.totalKeywords),
          0
        );
      }
    }

    return {
      foldersIds,
      projectsIds,
      foldersKeywordCount,
      projectsKeywordCount,
    };
  }, [isRootView, itemId, rowSelectionModel]);

  return (
    <>
      <StyledListItem onClick={handleOpenModal}>
        <StyledListItemButton disabled={disableRefreshAllKeywords}>
          <RefreshIcon />
          <ListItemTypography marginLeft='8px'>
            Refresh All Keywords
          </ListItemTypography>
        </StyledListItemButton>
      </StyledListItem>
      <RefreshAllKeywordsModal
        onClose={handleCloseModal}
        open={open}
        keywordCount={foldersKeywordCount + keywordCount}
        projectIds={
          itemType === 'project' && itemId
            ? [itemId]
            : projectsIds.length
            ? projectsIds
            : undefined
        }
        folderIds={
          itemType === 'folder' && itemId
            ? [itemId]
            : foldersIds.length
            ? foldersIds
            : undefined
        }
      />
    </>
  );
};

export default RefreshAllKeywordsAction;
