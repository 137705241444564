import React, { useState } from 'react';
import ViewNoteModal from '../../../../../../../../../notes/modals/ViewNoteModal/ViewNoteModal';
import { INoteProjectPerformance } from '../../../../../../../../../projects/types/projectPerformance';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { Popover } from '../../../../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import {
  StyledNotePopover,
  StyledNotePopoverButton,
  StyledNotePopoverDate,
  StyledNotePopoverText,
} from './syles';
import dayjs from 'dayjs';
import { IDataGraphAndNotes } from '../../../../../../../../../projects/components/ProjectExpanded/components/ProjectPerformance/components/Graph/Graph';
import { Grid } from '@mui/material';

const CustomScatter: React.FC<{
  cx: number;
  cy: number;
  name: string;
  payload: IDataGraphAndNotes;
}> = props => {
  const { cx, cy, payload } = props;

  const [activeNote, setActiveNote] = useState<INoteProjectPerformance | null>(
    null
  );
  const [isOpenModal, setIsOpenModal] = useState(false);

  const onOpen = (note: INoteProjectPerformance | null) => {
    setActiveNote(note);
    setIsOpenModal(true);
  };

  const onClose = () => {
    setActiveNote(null);
    setIsOpenModal(false);
  };

  if (payload?.notes && payload.notes.length) {
    return (
      <>
        <PopupState variant='popover'>
          {popupState => (
            <>
              <g
                transform={`translate(${cx},${cy - 13})`}
                cursor={'pointer'}
                {...bindTrigger(popupState)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='14'
                  height='14'
                  viewBox='0 0 14 14'
                  fill='none'
                >
                  <path
                    d='M13 9C13 9.35362 12.8595 9.69276 12.6095 9.94281C12.3594 10.1929 12.0203 10.3333 11.6667 10.3333H3.66667L1 13V2.33333C1 1.97971 1.14048 1.64057 1.39052 1.39052C1.64057 1.14048 1.97971 1 2.33333 1H11.6667C12.0203 1 12.3594 1.14048 12.6095 1.39052C12.8595 1.64057 13 1.97971 13 2.33333V9Z'
                    fill='#FA32CE'
                    stroke='#FA32CE'
                    strokeWidth='1.33333'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </g>

              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                disableScrollLock={true}
              >
                <StyledNotePopover>
                  <StyledNotePopoverDate>
                    {dayjs(payload.date).format('MMMM D, YYYY')}
                  </StyledNotePopoverDate>
                  <Grid width={'100%'}>
                    {payload?.notes?.map(note => {
                      return (
                        <Grid
                          xs={12}
                          key={note.id}
                          container
                          justifyContent={'space-between'}
                        >
                          <Grid item>
                            <StyledNotePopoverText>
                              {note.text}
                            </StyledNotePopoverText>
                          </Grid>
                          <Grid item>
                            <StyledNotePopoverButton
                              onClick={() => onOpen(note)}
                            >
                              View
                            </StyledNotePopoverButton>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </StyledNotePopover>
              </Popover>
            </>
          )}
        </PopupState>
        {activeNote && (
          <ViewNoteModal
            note={activeNote}
            open={isOpenModal}
            onClose={onClose}
          />
        )}
      </>
    );
  } else {
    return null;
  }
};

export default CustomScatter;
