import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StylesInfoSection = styled(Grid)`
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  padding: 16px 0;
  margin-top: 16px;
  margin-bottom: 32px;
`;

const StylesInfoSectionLabel = styled(Grid)`
  color: #84818a;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const StylesInfoSectionValue = styled(Grid)`
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export { StylesInfoSection, StylesInfoSectionLabel, StylesInfoSectionValue };
