import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

const MainContainer = styled('div')`
  min-width: 256px;
  max-height: 400px;
  padding: 8px;

  .MuiListItemButton-root {
    padding: 0;
    border-radius: 8px;
  }

  .MuiCheckbox-root:hover {
    background-color: transparent;
  }
`;

const PopupLabel = styled(Typography)`
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 10px;
  margin-top: 10px;
`;

const ResetDefaultButton = styled(Typography)`
  color: #126fe9;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 6px 10px;
  cursor: pointer;
`;

const ListItemTypography = styled(Typography)`
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const StyledListItem = styled(ListItem)(
  ({ checkBackgroundColor }: { checkBackgroundColor?: string }) => ({
    '.MuiListItemButton-root': {
      backgroundColor: checkBackgroundColor,
      ':hover': {
        backgroundColor: '#F6F6F6',
      },
    },
  })
);

export {
  MainContainer,
  PopupLabel,
  ResetDefaultButton,
  ListItemTypography,
  StyledListItem,
};
