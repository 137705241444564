import React from 'react';

interface Props {
  stroke?: string;
}

const AlertIcon: React.FC<Props> = ({ stroke }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <path
      d='M9.99981 12.6663C9.99981 13.7709 9.10438 14.6663 7.99981 14.6663C6.89524 14.6663 5.99981 13.7709 5.99981 12.6663M9.19748 4.15871C9.48781 3.85877 9.66648 3.45009 9.66648 2.99967C9.66648 2.0792 8.92029 1.33301 7.99981 1.33301C7.07934 1.33301 6.33315 2.0792 6.33315 2.99967C6.33315 3.45009 6.51182 3.85877 6.80214 4.15871M11.9998 7.46634C11.9998 6.54692 11.5784 5.66516 10.8282 5.01504C10.0781 4.36491 9.06068 3.99967 7.99981 3.99967C6.93895 3.99967 5.92153 4.36491 5.17139 5.01504C4.42124 5.66516 3.99981 6.54692 3.99981 7.46634C3.99981 8.98754 3.62257 10.1001 3.15186 10.8961C2.61537 11.8034 2.34713 12.2571 2.35772 12.3654C2.36984 12.4894 2.39216 12.5285 2.49271 12.6021C2.58059 12.6663 3.02215 12.6663 3.90527 12.6663H12.0944C12.9775 12.6663 13.419 12.6663 13.5069 12.6021C13.6075 12.5285 13.6298 12.4894 13.6419 12.3654C13.6525 12.2571 13.3843 11.8034 12.8478 10.8961C12.3771 10.1001 11.9998 8.98754 11.9998 7.46634Z'
      stroke={stroke || 'rgb(102, 112, 133)'}
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default AlertIcon;
