import { styled } from '@mui/material';
import { PrimaryButton } from '../../../../shared/buttons/styles';

const UpgradePlanPrimaryButton = styled(PrimaryButton)`
  font-weight: 600;
  line-height: 24px;
  padding: 12px 20px !important;

  &.Mui-disabled {
    background: #f9fafb;
    color: #1d2939;
    border: 1px solid var(--Gray-300, #d0d5dd);
  }
`;

export { UpgradePlanPrimaryButton };
