import React from 'react';
import { List } from '@mui/material';
import DeleteAction from './DeleteAction';
import { MainContainer } from '../../../../../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/styles';

interface ListActionsProps {
  popupClose: () => void;
  selectBy: 'by Project' | 'by Keywords';
}

const ListActions: React.FC<ListActionsProps> = ({ popupClose, selectBy }) => {
  return (
    <MainContainer>
      <List>
        <DeleteAction popupClose={popupClose} selectBy={selectBy} />
      </List>
    </MainContainer>
  );
};

export default ListActions;
