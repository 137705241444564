import React from 'react';
import { Grid } from '@mui/material';

import { Header, Table } from './components';

const TableProjects = () => {
  return (
    <Grid container>
      <Header />
      <Table />
    </Grid>
  );
};

export default TableProjects;
