import React, { useMemo, useState } from 'react';
import { StyledItemContainer } from '../../styles';
import { Divider, Grid } from '@mui/material';
import {
  StyledStarterPlan,
  StyledChip,
  StyledСurrency,
  StyledPrice,
  StyledPeriod,
  StyledDescription,
  StyledSwitchAnnualPayment,
  StyledSPriceContainer,
} from './styles';
import { Progress } from '../../../../../../../../../projects/components/AccountUsage/components';
import { PrimaryButtonSmall } from '../../../../../../../../../../shared/buttons/styles';
import { useNavigate } from 'react-router';
import ROUTES from '../../../../../../../../../../routes/constants';
import usePayment from '../../../../../../../../../payment/hooks/usePayment';
import { ITariffPlan } from '../../../../../../../../types';
import ReviewUpdateSubscriptionModal from '../../../../../../../../../payment/components/ReviewUpdateSubscriptionModal/ReviewUpdateSubscriptionModal';
import { useAppSelector } from '../../../../../../../../../../store';

const StarterPlan = () => {
  const navigate = useNavigate();

  const myAccount = useAppSelector(state => state.auth.myAccount);
  const billing = useAppSelector(state => state.auth.payment.billing);
  const accountLimit = useAppSelector(state => state.auth.accountLimit);

  const { onSwitchToAnnualPayment, paddle } = usePayment();

  const [isReviewUpdateSubscriptionModal, setIsReviewUpdateSubscriptionModal] =
    useState(false);
  const [activePaddleProductId, setActivePaddleProductId] = useState<
    null | string
  >(null);

  const numberOfDailyUpdatesOfKeywordPositions = useMemo(
    () =>
      accountLimit?.defaultAccountLimits
        ?.numberOfDailyUpdatesOfKeywordPositions || 0,
    [accountLimit?.defaultAccountLimits?.numberOfDailyUpdatesOfKeywordPositions]
  );

  const numberOfKeywords = useMemo(
    () => accountLimit?.accountLimitsUsed?.numberOfKeywords || 0,
    [accountLimit?.accountLimitsUsed?.numberOfKeywords]
  );

  const isTrialPeriod = useMemo(
    () => billing?.tariffPlan?.name === 'Trial Period',
    [billing?.tariffPlan?.name]
  );

  const isYearlyPeriod = useMemo(
    () => billing?.tariffPlan?.settings?.type?.name === 'yearly',
    [billing?.tariffPlan?.settings?.type?.name]
  );

  const handleUpgradePlan = () => {
    navigate(ROUTES.upgradePlan);
  };

  const openUpdatePlan = (tariffPlans: ITariffPlan[]) => {
    if (paddle && myAccount?.id && billing) {
      const yearPlan = tariffPlans.find(
        plan => plan.name === billing.tariffPlan.name
      );
      if (yearPlan) {
        setActivePaddleProductId(yearPlan.settings.paddleProductId);
        setIsReviewUpdateSubscriptionModal(true);
      }
    }
  };

  const handleClose = () => {
    setActivePaddleProductId(null);
    setIsReviewUpdateSubscriptionModal(false);
  };

  const handleSwitchToAnnualPayment = () => {
    onSwitchToAnnualPayment({ type: 'yearly', successFn: openUpdatePlan });
  };

  if (!billing) {
    return null;
  }

  return (
    <>
      <StyledItemContainer container xs={12}>
        <Grid container item xs={12} justifyContent={'space-between'}>
          <Grid item>
            <Grid container gap={'8px'}>
              <StyledStarterPlan>{billing?.tariffPlan?.name}</StyledStarterPlan>
              {!isTrialPeriod && (
                <StyledChip>
                  {billing?.tariffPlan?.settings?.type?.name}
                </StyledChip>
              )}
            </Grid>
            <StyledDescription>Your current plan.</StyledDescription>
          </Grid>
          <StyledSPriceContainer item>
            <Grid container alignItems={'center'} gap={'2px'}>
              <StyledСurrency>$</StyledСurrency>
              <StyledPrice>{billing?.tariffPlan?.settings?.price}</StyledPrice>
              {!isTrialPeriod && (
                <StyledPeriod alignSelf={'end'}>{`per ${
                  isYearlyPeriod ? 'year' : 'month'
                } `}</StyledPeriod>
              )}
            </Grid>
          </StyledSPriceContainer>
        </Grid>

        <Grid item xs={12} paddingTop={'24px'}>
          <Progress
            title='Keyword limit'
            value={numberOfKeywords}
            maxValue={numberOfDailyUpdatesOfKeywordPositions}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider
            orientation='horizontal'
            flexItem
            sx={{
              color: '#EDEDED',
            }}
          />
        </Grid>

        <Grid
          container
          item
          xs={12}
          gap={1}
          paddingTop={'24px'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          {billing?.tariffPlan?.settings?.type?.name !== 'yearly' &&
          billing?.tariffPlan?.name !== 'Trial Period' ? (
            <StyledSwitchAnnualPayment onClick={handleSwitchToAnnualPayment}>
              Switch to annual payment
            </StyledSwitchAnnualPayment>
          ) : null}
          <Grid>
            <PrimaryButtonSmall
              variant='contained'
              size='small'
              onClick={handleUpgradePlan}
            >
              Upgrade Plan
            </PrimaryButtonSmall>
          </Grid>
        </Grid>
      </StyledItemContainer>
      <ReviewUpdateSubscriptionModal
        handleClose={handleClose}
        open={isReviewUpdateSubscriptionModal}
        paddleProductId={activePaddleProductId}
      />
    </>
  );
};

export default StarterPlan;
