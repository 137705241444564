import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const StyledMainText = styled(Typography)`
  color: #344054;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const StyledLabel = styled(Typography)`
  color: #101828;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`;

const StyledErrors = styled(Typography)`
  color: #344054;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

export { StyledMainText, StyledLabel, StyledErrors };
