import React from 'react';
import { BreadcrumbLink } from '../../../../../shared/Breadcrumbs/styled';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { IFolderTree } from '../../../../auth/types';

interface BreadcrumbsFoldersProps {
  folders: IFolderTree[];
  handleSetCurrentFolder: (folder: IFolderTree) => void;
}

const BreadcrumbsFolders: React.FC<BreadcrumbsFoldersProps> = ({
  folders,
  handleSetCurrentFolder,
}) => {
  const breadcrumbItems = folders.map(folder => {
    if (!folder.available) {
      return null;
    }
    const onHandleSetCurrentFolder = () => {
      handleSetCurrentFolder(folder);
    };
    return (
      <BreadcrumbLink
        key={folder.id}
        to={'#'}
        onClick={onHandleSetCurrentFolder}
      >
        {folder.name}
      </BreadcrumbLink>
    );
  });

  return (
    <MuiBreadcrumbs
      separator={<NavigateNextIcon fontSize='small' />}
      aria-label='breadcrumb'
    >
      {breadcrumbItems}
    </MuiBreadcrumbs>
  );
};

export default BreadcrumbsFolders;
