import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useProjects,
  useKeywordRankingsTable,
} from '../../../../../../../../../../hooks';
import { KeywordRankingsCustomDataGrid } from './styles';
import CheckboxWrapper from '../../../../../../../../../auth/components/Settings/components/Team/components/CheckboxWrapper';
import CustomNoRowsOverlay from './CustomNoRowsOverlay';
import { GridRowParams } from '@mui/x-data-grid';
import { TableNoData } from '../../../../../../../../../../shared/components';
import { arraysAreEqual } from '../../../../../../../../../../utils';
import KeywordsAnalyticsModal from '../../../../../../../modals/KeywordsAnalyticsModal/KeywordsAnalyticsModal';
import { useAppSelector } from '../../../../../../../../../../store';
import { KeywordRankingsTableProps } from '../../../types';
import { useMediaQuery } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import {
  setTableColumns,
  TABLES_STORAGE_KEYS,
} from '../../../../../../../../tablesColumnStase';

const Table: React.FC<KeywordRankingsTableProps> = ({
  apiRef,
  containerRef,
  updatedKeywordsTrigger,
  onSetUpdatedKeywordsTrigger,
}) => {
  const md = useMediaQuery('(min-width:900px)');
  const {
    onCloseKeywordPositionHistory,
    onSetSortModelKeywords,
    onSetRowSelectionKeywordsModel,
    onGetKeywordPositionHistory,
  } = useProjects();

  const deleteElements = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table.deleteElements
  );
  const rowSelectionModel = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table
        .rowSelectionModel
  );
  const columnVisibilityModel = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table
        .columnVisibilityModel
  );

  const search = useAppSelector(
    state => state.projects.projectExpandedGraphs.keywordRankings.table.search
  );
  const keywordRanking = useAppSelector(
    state => state.projects.projectExpandedGraphs.keywordRankings.keywordRanking
  );
  const activeKeywordTrends = useAppSelector(
    state => state.projects.projectExpandedGraphs.activeKeywordTrends
  );
  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );
  const openTariffPlanBanner = useAppSelector(
    state => state.auth.openTariffPlanBanner
  );

  const { columns, columnsSkeleton } = useKeywordRankingsTable();
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const handleSetRowSelectionKeywordsModel = (
    rowSelectionModel: GridRowSelectionModel
  ) => {
    if (memoizedValues.keywordRanking?.items) {
      onSetRowSelectionKeywordsModel(rowSelectionModel);
    }
  };

  const memoizedValues = useMemo(
    () => ({
      columns,
      currentAccount,
      onCloseKeywordPositionHistory,
      onSetSortModelKeywords,
      onSetRowSelectionKeywordsModel,
      onGetKeywordPositionHistory,
      projectExpanded,
      activeKeywordTrends,
      keywordRanking,
      search,
      columnVisibilityModel,
      rowSelectionModel,
      deleteElements,
    }),
    [
      columns,
      currentAccount,
      onCloseKeywordPositionHistory,
      onSetSortModelKeywords,
      onSetRowSelectionKeywordsModel,
      onGetKeywordPositionHistory,
      projectExpanded,
      activeKeywordTrends,
      keywordRanking,
      search,
      columnVisibilityModel,
      rowSelectionModel,
      deleteElements,
    ]
  );

  const pageSize = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table.paginationModel
        .pageSize
  );

  const [itemCount, setItemCount] = useState(
    memoizedValues.keywordRanking?.meta?.itemCount || pageSize
  );
  const [openModal, setOpenModal] = useState(false);
  const [activeKeywordId, setActiveKeywordId] = useState<number | null>(null);
  const [activeKeywordName, setActiveKeywordName] = useState<string | null>(
    null
  );

  const isRowSelectable = useCallback(
    () =>
      !(
        !currentAccount?.accountId || currentAccount?.role?.name === 'ViewOnly'
      ),
    [currentAccount?.accountId, currentAccount?.role?.name]
  );

  const rows = useMemo(() => {
    const arr1 = [
      ...(memoizedValues.keywordRanking?.items?.length
        ? memoizedValues.keywordRanking.items
        : []),
    ];
    const arr2 = [...memoizedValues.deleteElements];

    return arr1.filter(item => !arr2.includes(item.id));
  }, [memoizedValues.deleteElements, memoizedValues.keywordRanking?.items]);

  const onClose = () => {
    if (activeKeywordId) {
      memoizedValues.onCloseKeywordPositionHistory({ id: activeKeywordId });
      setOpenModal(false);
      setActiveKeywordId(null);
      setActiveKeywordName(null);
    }
  };

  const handleRowClick = (params: GridRowParams<any>) => {
    if (
      memoizedValues.projectExpanded &&
      memoizedValues.keywordRanking?.items
    ) {
      setOpenModal(true);
      setActiveKeywordId(params.row.id);
      setActiveKeywordName(params.row.name);
      memoizedValues.onGetKeywordPositionHistory({
        accountId: memoizedValues.currentAccount.accountId,
        keywordId: params.row.id,
        projectId: memoizedValues.projectExpanded.id,
        period: memoizedValues.activeKeywordTrends.value,
        competitorIds: memoizedValues.projectExpanded.competitors.map(
          item => item.id
        ),
      });
    }
  };

  useEffect(() => {
    const filtered = memoizedValues.keywordRanking?.items
      ?.filter(obj => memoizedValues.rowSelectionModel.includes(obj.id))
      ?.map(item => item.id)
      .filter(item => !memoizedValues.deleteElements.includes(item));
    if (
      filtered &&
      !arraysAreEqual(memoizedValues.rowSelectionModel, filtered)
    ) {
      memoizedValues.onSetRowSelectionKeywordsModel(filtered);
    }
  }, [
    memoizedValues.deleteElements,
    memoizedValues.onSetRowSelectionKeywordsModel,
    memoizedValues.keywordRanking?.items,
    memoizedValues.rowSelectionModel,
  ]);

  useEffect(() => {
    if (memoizedValues.keywordRanking?.meta?.itemCount) {
      setItemCount(memoizedValues.keywordRanking.meta.itemCount);
    }
  }, [memoizedValues.keywordRanking?.meta?.itemCount]);

  const NoRowsOverlay = useMemo(
    () =>
      !memoizedValues.keywordRanking?.items
        ? () => <div />
        : memoizedValues.search && !rows.length
        ? CustomNoRowsOverlay
        : !rows.length
        ? TableNoData
        : () => <div />,
    [memoizedValues.keywordRanking?.items, memoizedValues.search, rows.length]
  );

  const arrayOfObjects = useMemo(
    () => Array.from({ length: itemCount }, (_, index) => ({ id: index + 1 })),
    [itemCount]
  );

  const renderRows = useMemo(() => {
    return !memoizedValues.keywordRanking?.items ? arrayOfObjects : rows;
  }, [arrayOfObjects, memoizedValues.keywordRanking?.items, rows]);

  const renderColumns = useMemo(() => {
    return !memoizedValues.keywordRanking?.items ? columnsSkeleton : columns;
  }, [columns, columnsSkeleton, memoizedValues.keywordRanking?.items]);

  const getRowClassName = (params: GridRowParams) => {
    const keyword = updatedKeywordsTrigger.find(
      item => Number(item) === params.row.id
    );
    if (keyword) {
      setTimeout(() => {
        onSetUpdatedKeywordsTrigger(
          updatedKeywordsTrigger.filter(item => Number(item) !== keyword)
        );
      }, 1000);
      return 'blinking';
    } else return '';
  };

  const pinnedColumns = useMemo(
    () => ({
      left: md ? ['__check__', 'name'] : [],
      right:
        currentAccount?.accountId && currentAccount?.role?.name !== 'ViewOnly'
          ? ['refresh', 'actions']
          : [],
    }),
    [currentAccount?.role?.name, currentAccount?.accountId, md]
  );

  const filterColumnVisibilityModel = useMemo(() => {
    if (
      projectExpanded?.searchEngine?.name !== 'Google' &&
      projectExpanded?.searchEngine?.name !== 'Bing'
    ) {
      return { ...columnVisibilityModel, cpc: false, searchValue: false };
    }
    return columnVisibilityModel;
  }, [projectExpanded?.searchEngine?.name, columnVisibilityModel]);

  useEffect(() => {
    setTableColumns(
      TABLES_STORAGE_KEYS.projectKeywordRankings,
      columnVisibilityModel
    );
  }, [columnVisibilityModel]);

  return (
    <div style={{ width: '100%', position: 'relative' }} ref={containerRef}>
      <KeywordRankingsCustomDataGrid
        pinnedColumns={pinnedColumns}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        apiRef={apiRef}
        autoHeight
        onRowClick={handleRowClick}
        columnHeaderHeight={40}
        rowHeight={40}
        isRowSelectable={isRowSelectable}
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={handleSetRowSelectionKeywordsModel}
        onSortModelChange={onSetSortModelKeywords}
        columnVisibilityModel={filterColumnVisibilityModel}
        components={{
          BaseCheckbox: CheckboxWrapper,
          NoRowsOverlay: NoRowsOverlay,
        }}
        getRowClassName={getRowClassName}
        rows={renderRows}
        columns={renderColumns}
        getRowId={row => row.id}
        keepNonExistentRowsSelected
        hideFooterPagination
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnMenu
        disableVirtualization
        $openTariffPlanBanner={openTariffPlanBanner}
      />

      {activeKeywordId && activeKeywordName && (
        <KeywordsAnalyticsModal
          id={activeKeywordId}
          name={activeKeywordName}
          onClose={onClose}
          open={openModal}
        />
      )}
    </div>
  );
};

export default React.memo(Table);
