import { IFolderTree } from '../../../../auth/types';
import React, { useEffect } from 'react';
import { FormHelperText, Grid, Typography, useMediaQuery } from '@mui/material';
import { useAuth, useFolders } from '../../../../../hooks';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { enqueueSnackbar } from 'notistack';
import CustomToast from '../../../../../shared/CustomToast/CustomToast';
import { closeSnackbarAction } from '../../../../../utils/snackbar';
import { ModalContainer } from '../../Projects/components/ProjectRedactorModal/styles';
import { CustomFormHelperText } from '../../../../../shared/components';
import { ButtonsWrapper } from '../../../../auth/components/Settings/components/Team/components/customTableStyles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../shared/buttons/styles';
import { useAppSelector } from '../../../../../store';
import { ModalOutlinedInput } from '../../../../../shared/modal/styled';

interface NewFolderProps {
  handleClose: () => void;
  open: boolean;
  currentFolder: IFolderTree;
}

const NewFolder: React.FC<NewFolderProps> = ({
  handleClose,
  open,
  currentFolder,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const { onGetMyAccount } = useAuth();
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const { onCreateFolder } = useFolders();

  const formik = useFormik({
    initialValues: {
      folderName: 'Untitled folder',
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      folderName: Yup.string()
        .required('Folder Name is required.')
        .min(2, 'Folder name must be at least a 2 characters')
        .matches(/\S/, 'Folder name should not contain only spaces.'),
    }),

    onSubmit: (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
      const requestData = {
        name: values.folderName,
      };
      if (currentAccount?.accountId) {
        onCreateFolder({
          accountId: currentAccount.accountId,
          folderId: currentFolder.id,
          data: requestData,
          successFn: () => {
            onGetMyAccount(currentAccount.accountId);
            enqueueSnackbar(
              <CustomToast
                message='Success'
                submessage='New folder created successfully.'
              />,
              { action: closeSnackbarAction }
            );
            resetForm();
            handleClose();
          },
          errorFn: (error: any) => {
            setStatus({ success: false });
            setSubmitting(false);
            setErrors(error);
          },
        });
      }
    },
  });

  useEffect(() => {
    if (open) {
      formik.setFieldValue('folderName', 'Untitled folder');
    }
  }, [open]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <ModalContainer container xs={12} sx={{ gap: '8px' }}>
        <Typography
          fontSize='18px'
          fontWeight='600'
          lineHeight='28px'
          color='#101828'
          paddingBottom={'24px'}
        >
          New Folder
        </Typography>

        <Grid
          container
          fontWeight='500'
          alignItems='center'
          fontSize='14px'
          gap='6px'
        >
          <Grid item>Folder Name</Grid>
          <ModalOutlinedInput
            size='small'
            fullWidth
            type='text'
            placeholder='Enter folder name'
            id='folderName'
            name='folderName'
            value={formik.values.folderName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={!!(formik.touched.folderName && formik.errors.folderName)}
          />
          {formik.touched.folderName && formik.errors.folderName && (
            <FormHelperText error id='folderName'>
              <CustomFormHelperText error={formik.errors.folderName} />
            </FormHelperText>
          )}
        </Grid>
        <ButtonsWrapper container xs={12}>
          <Grid item xs={extraSmallScreen && 12}>
            <GrayButtonSmall
              fullWidth={extraSmallScreen}
              size='small'
              outline
              onClick={handleClose}
            >
              Cancel
            </GrayButtonSmall>
          </Grid>
          <Grid item xs={extraSmallScreen && 12}>
            <PrimaryButtonSmall
              fullWidth={extraSmallScreen}
              variant='contained'
              size='small'
              type='submit'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Create
            </PrimaryButtonSmall>
          </Grid>
        </ButtonsWrapper>
      </ModalContainer>
    </form>
  );
};

export default NewFolder;
