import { styled } from '@mui/material/styles';
import { Grid, OutlinedInput } from '@mui/material';
import { DateRangePicker } from 'react-date-range';

const StyledHeader = styled(Grid)`
  padding-bottom: 16px;
`;

const StyledDateRangePicker = styled(DateRangePicker)`
  &.rdrDateRangePickerWrapper {
    display: flex;
    flex-direction: row-reverse;
  }
  .rdrDateDisplayWrapper {
    background-color: white;
  }

  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge {
    background-color: #126fe9;
  }

  .rdrMonthAndYearPickers,
  .rdrMonthName {
    color: #344054;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .rdrWeekDay {
    color: #344054;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .rdrDayNumber {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .rdrDateDisplayItem input {
    color: #101828;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .rdrStaticRange {
    color: #344054;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-bottom: none;
  }

  .rdrStaticRangeLabel {
    padding: 10px 16px;
    border-radius: 6px;
  }

  .rdrStaticRange:hover .rdrStaticRangeLabel,
  .rdrStaticRange:focus .rdrStaticRangeLabel {
    background-color: #f9fafb;
  }

  .rdrStaticRanges {
    margin-top: 8px;
    margin-right: 8px;
  }

  .rdrDefinedRangesWrapper .rdrStaticRangeSelected {
    font-weight: 500;
    color: #126fe9;
    background-color: #f9fafb;
    border-radius: 6px;
  }

  .rdrDefinedRangesWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 160px;
    min-width: 160px;
    padding-bottom: 24px;
  }

  .rdrMonth {
    width: 280px;
  }

  .rdrInputRanges {
    display: none;
  }
`;

const StyledOutlinedInput = styled(OutlinedInput)`
  background-color: white;
  padding-left: 4px;

  .MuiInputBase-input {
    color: #344054 !important;
    cursor: pointer;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    min-width: 200px;
  }
`;

export { StyledHeader, StyledDateRangePicker, StyledOutlinedInput };
