/**
 * Default configuration values for the application.
 */
const DEFAULT_CONFIG = {
  /** Base API URL for local development */
  apiUrl: `http://localhost:8000/api`,
  /** Environment type, e.g., local, staging, production */
  appEnv: 'local',
  /** Recaptcha V3 site key */
  recaptchaV3SiteKey: '',
  /** URL of the landing page */
  landingPageUrl: '',
  /** Paddle configuration object */
  paddle: {
    /** Paddle script URL */
    url: '',
    /** Paddle seller identifier */
    seller: '',
    /** Paddle environment */
    enviroment: '',
  },
  /** Application environment */
  enviroment: 'localhost',
  /** CryptoJS secret key */
  cryptoJsSecretKey: '',
  /** Material-UI X Pro license key */
  muiXProLicenseKey: '',
  /** Default server UTC offset time */
  serverUts: '00:00',
  /** Beacon ID for analytics or tracking */
  beaconId: '',
  /** Paddle client-side token */
  paddleClientSideToken: '',
  /** Maintenance mode flag (0 for disabled, 1 for enabled) */
  enableMaintenancePage: '0',
};

export const API_URL = process.env.REACT_APP_API_URL || DEFAULT_CONFIG.apiUrl;

export const APP_ENV = process.env.REACT_APP_ENV || DEFAULT_CONFIG.appEnv;

export const RECAPTCHA_V3_SITE_KEY =
  process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY ||
  DEFAULT_CONFIG.recaptchaV3SiteKey;

export const LANDING_PAGE_URL =
  process.env.REACT_APP_LANDING_PAGE || DEFAULT_CONFIG.landingPageUrl;

export const PADDLE = {
  URL: process.env.REACT_APP_PADDLE_SCRIPT_URL || DEFAULT_CONFIG.paddle.url,
};

export const ENVIRONMENT =
  process.env.REACT_APP_ENVIRONMENT || DEFAULT_CONFIG.enviroment;

export const CRYPTO_JS_SECRET_KEY =
  process.env.REACT_APP_CRYPTO_JS_SECRET_KEY ||
  DEFAULT_CONFIG.cryptoJsSecretKey;

export const MUI_X_PRO_LICENSE_KEY =
  process.env.REACT_APP_MUI_X_PRO_LICENSE_KEY ||
  DEFAULT_CONFIG.muiXProLicenseKey;

export const SERVER_UTS =
  process.env.REACT_APP_SERVER_UTS || DEFAULT_CONFIG.serverUts;

export const BEACON_ID =
  process.env.REACT_APP_BEACON_ID || DEFAULT_CONFIG.beaconId;

export const PADDLE_CLIENT_SIDE_TOKEN =
  process.env.REACT_APP_PADDLE_CLIENT_SIDE_TOKEN ||
  DEFAULT_CONFIG.paddleClientSideToken;

export const ENABLE_MAINTENANCE_PAGE =
  process.env.REACT_APP_ENABLE_MAINTENANCE_PAGE ||
  DEFAULT_CONFIG.enableMaintenancePage;

const config = {
  API_URL,
  APP_ENV,
  RECAPTCHA_V3_SITE_KEY,
  LANDING_PAGE_URL,
  PADDLE,
  ENVIRONMENT,
  CRYPTO_JS_SECRET_KEY,
  MUI_X_PRO_LICENSE_KEY,
  SERVER_UTS,
  BEACON_ID,
  PADDLE_CLIENT_SIDE_TOKEN,
  ENABLE_MAINTENANCE_PAGE,
};

export default config;
