import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { CustomDataGrid } from '../../../../../../../auth/components/Settings/components/Team/components/customTableStyles';
import CheckboxWrapper from '../../../../../../../auth/components/Settings/components/Team/components/CheckboxWrapper';
import { useAlertsTable } from '../../../../../../../../hooks';
import useAlerts from '../../../../../../hooks/useAlerts';
import { useAppSelector } from '../../../../../../../../store';
import EmptyTable from '../../../../../../../../shared/components/EmptyTable/EmptyTable';
import TriggersModal from '../../../../../Triggers/components/TriggersModal/TriggersModal';
import EyeIcon from '../../../../../../../../shared/images/EyeIcon';
import {
  setTableColumns,
  TABLES_STORAGE_KEYS,
} from '../../../../../../../projects/tablesColumnStase';

interface TableProps {
  selectBy: 'by Project' | 'by Keywords';
}

const Table: React.FC<TableProps> = ({ selectBy }) => {
  const {
    alertsByKeywordsColumns,
    alertsByProjectColumns,
    alertsByKeywordsColumnsSkeleton,
    alertsByProjectColumnsSkeleton,
  } = useAlertsTable();
  const {
    onSetSortModelAlerts,
    tables: { alerts },
    alerts: { byProjects, byKeywords },
  } = useAlerts();

  const openTariffPlanBanner = useAppSelector(
    state => state.auth.openTariffPlanBanner
  );

  const [isOpenTriggersModal, setIsOpenTriggersModal] = useState(false);

  const handleOpenTriggersModal = () => {
    setIsOpenTriggersModal(true);
  };

  const handleCloseTriggersModal = () => {
    setIsOpenTriggersModal(false);
  };

  const columnVisibilityModel = useMemo(
    () =>
      selectBy === 'by Project'
        ? alerts.columnVisibilityModelByProject
        : alerts.columnVisibilityModelByKeywords,
    [
      alerts.columnVisibilityModelByKeywords,
      alerts.columnVisibilityModelByProject,
      selectBy,
    ]
  );

  const [itemCount, setItemCount] = useState(
    selectBy === 'by Project'
      ? byProjects?.meta?.itemCount || alerts.paginationModel.pageSize
      : byKeywords?.meta?.itemCount || alerts.paginationModel.pageSize
  );

  const arrayOfObjects = useMemo(
    () => Array.from({ length: itemCount }, (_, index) => ({ id: index + 1 })),
    [itemCount]
  );

  const renderColumns = useMemo(() => {
    return selectBy === 'by Project'
      ? !byProjects?.items
        ? alertsByProjectColumnsSkeleton
        : alertsByProjectColumns
      : !byKeywords?.items
      ? alertsByKeywordsColumnsSkeleton
      : alertsByKeywordsColumns;
  }, [
    alertsByKeywordsColumns,
    alertsByKeywordsColumnsSkeleton,
    alertsByProjectColumns,
    alertsByProjectColumnsSkeleton,
    byKeywords?.items,
    byProjects?.items,
    selectBy,
  ]);

  const rows = useMemo(() => {
    if (selectBy === 'by Project') {
      return byProjects?.items?.length ? byProjects.items : [];
    } else {
      return byKeywords?.items?.length ? byKeywords.items : [];
    }
  }, [byKeywords?.items, byProjects?.items, selectBy]);

  const renderRows = useMemo(() => {
    return selectBy === 'by Project'
      ? !byProjects?.items
        ? arrayOfObjects
        : rows
      : !byKeywords?.items
      ? arrayOfObjects
      : rows;
  }, [arrayOfObjects, byKeywords?.items, byProjects?.items, rows, selectBy]);

  const NoRowsOverlay = useCallback(() => {
    const NoData = (
      <EmptyTable
        buttonText={'View Triggers'}
        handleButton={handleOpenTriggersModal}
        supportingText={'Alerts will show up here once they’re triggered.'}
        text={'No alerts yet'}
        startIcon={<EyeIcon />}
      />
    );
    return selectBy === 'by Project' ? (
      !byProjects?.items ? (
        <Box />
      ) : (
        NoData
      )
    ) : !byKeywords?.items ? (
      <Box />
    ) : (
      NoData
    );
  }, [byKeywords?.items, byProjects?.items, selectBy]);

  useEffect(() => {
    if (selectBy === 'by Project' && byProjects?.meta?.itemCount) {
      setItemCount(byProjects.meta.itemCount);
    }
  }, [byProjects?.meta?.itemCount, selectBy]);

  useEffect(() => {
    if (selectBy === 'by Keywords' && byKeywords?.meta?.itemCount) {
      setItemCount(byKeywords.meta.itemCount);
    }
  }, [byKeywords?.meta?.itemCount, selectBy]);

  useEffect(() => {
    setTableColumns(
      TABLES_STORAGE_KEYS.alertsByProject,
      alerts.columnVisibilityModelByProject
    );
  }, [alerts.columnVisibilityModelByProject]);

  useEffect(() => {
    setTableColumns(
      TABLES_STORAGE_KEYS.alertsByKeywords,
      alerts.columnVisibilityModelByKeywords
    );
  }, [alerts.columnVisibilityModelByKeywords]);

  return (
    <>
      <Grid xs={12}>
        <CustomDataGrid
          autoHeight
          columnHeaderHeight={40}
          rowHeight={40}
          rows={renderRows}
          columns={renderColumns}
          onSortModelChange={onSetSortModelAlerts}
          components={{
            BaseCheckbox: CheckboxWrapper,
            NoRowsOverlay: NoRowsOverlay,
          }}
          columnVisibilityModel={columnVisibilityModel}
          keepNonExistentRowsSelected
          hideFooterPagination
          disableRowSelectionOnClick
          disableColumnMenu
          $openTariffPlanBanner={openTariffPlanBanner}
        />
      </Grid>
      <TriggersModal
        handleClose={handleCloseTriggersModal}
        open={isOpenTriggersModal}
      />
    </>
  );
};

export default Table;
