import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useMemo } from 'react';
import { CustomTooltip } from '../../../shared/tooltip/styles';
import TextTooltip from '../../../shared/components/TextTooltip';
import * as Tooltips from '../components/Alerts/components/Tooltips';
import InfoIcon from '../../../shared/images/InfoIcon';
import EllipsisMenu from '../components/Triggers/components/KeywordsAssociatedWithTriggerModal/components/KeywordsAssociatedWithTriggerTable/components/EllipsisMenu/EllipsisMenu';
import { Box, Grid, Skeleton } from '@mui/material';
import { getDeviceTypeIcon } from '../../projects/utils';
import { customSortComparator } from '../../emailReports/hooks/useScheduledEmailReportsTable';
import { TableHeaderStyles } from '../../../shared/stylesTable';

export default function useTriggerKeywordsTable() {
  const popperProps = {
    sx: {
      '& .MuiTooltip-tooltip': {
        backgroundColor: '#fff',
        color: '#344054',
        boxShadow:
          '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
        border: '1px solid #EDEDED',
      },
      '.MuiTooltip-arrow::before': {
        backgroundColor: '#fff',
        border: '1px solid #EDEDED',
      },
    },
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'keywordName',
        sortable: true,
        headerName: 'Keyword',
        minWidth: 425,
        flex: 1,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Keyword />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Keyword '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.name} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              <Grid display={'flex'} alignItems={'center'}>
                {getDeviceTypeIcon(params.row.deviceType.name)}
                <Box display='flex' alignItems='center'>
                  <Box marginLeft='8px'>{params.row.name}</Box>
                </Box>
              </Grid>
            </CustomTooltip>
          );
        },
      },
      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 50,
        align: 'center',
        renderCell: (params: GridRenderCellParams) => (
          <EllipsisMenu id={params.row.triggerKeywordId} />
        ),
      },
    ],
    []
  );

  const columnsSkeleton: GridColDef[] = useMemo(
    () => [
      {
        field: 'keywordName',
        sortable: true,
        headerName: 'Keyword',
        minWidth: 425,
        flex: 1,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 50,
        align: 'center',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
    ],
    []
  );

  return { columns, columnsSkeleton };
}
