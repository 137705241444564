import { useEmailReports } from '../../../../../../../../hooks';
import React from 'react';
import {
  PaginationContainer,
  StyledTablePagination,
} from '../../../../../../../projects/components/Projects/components/TableProjects/components/Pagination/styles';

const Pagination = () => {
  const {
    onSetPaginationPageEmailReports,
    onSetPaginationPageSizeEmailReports,
    emailReports,
    tables: {
      scheduledEmailReports: { paginationModel },
    },
  } = useEmailReports();

  const handleSetPaginationPageSize = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onSetPaginationPageSizeEmailReports(Number(e.target.value));
  };

  const handleSetPaginationPage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    onSetPaginationPageEmailReports(Number(page));
  };

  return (
    <PaginationContainer xs={12}>
      <StyledTablePagination
        labelRowsPerPage='Show rows:'
        count={emailReports?.meta?.totalItems || 0}
        page={paginationModel.page}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onPageChange={handleSetPaginationPage}
        rowsPerPage={paginationModel.pageSize}
        onRowsPerPageChange={handleSetPaginationPageSize}
      />
    </PaginationContainer>
  );
};

export default Pagination;
