import React, { useState } from 'react';
import { ImprovedVsDeclinedItem } from '../../../../../../types';
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import dayjs from 'dayjs';
import * as Tooltips from './CustomTooltipsForGraph';
import { useMediaQuery } from '@mui/material';

interface ImprovedVsDeclinedBarChartProps {
  getCheckedData: ImprovedVsDeclinedItem[];
}

const ImprovedVsDeclinedBarChart: React.FC<ImprovedVsDeclinedBarChartProps> = ({
  getCheckedData,
}) => {
  const lg = useMediaQuery('(min-width:900px)');
  const md = useMediaQuery('(min-width:900px)');

  const [startIndex, setStartIndex] = useState<number | undefined>(
    getCheckedData.length ? Math.max(0, getCheckedData.length - 24) : undefined
  );
  const [endIndex, setEndIndex] = useState<number | undefined>(
    getCheckedData.length ? getCheckedData.length - 1 : undefined
  );

  const handleBrushChange = (data: {
    endIndex?: number;
    startIndex?: number;
  }) => {
    setEndIndex(data.endIndex);
    setStartIndex(data.startIndex);
  };

  return (
    <ResponsiveContainer width={'100%'} height={311}>
      <BarChart data={getCheckedData} stackOffset='sign'>
        <CartesianGrid vertical={false} stroke='#EDEDED' strokeWidth={2} />
        <XAxis
          interval={'equidistantPreserveStart'}
          allowDataOverflow
          tickCount={24}
          axisLine={false}
          tickLine={false}
          dataKey='date'
          stroke='#D8DAE5'
          dy={5}
          tickFormatter={value => {
            return dayjs(value).format('D MMM');
          }}
        />
        <YAxis
          allowDecimals={false}
          axisLine={false}
          tickLine={false}
          stroke='#D8DAE5'
          orientation='right'
        />

        <Tooltip
          cursor={{ fill: '#F6F6F6' }}
          content={<Tooltips.ImprovedDeclined />}
          wrapperStyle={{ width: '230px' }}
        />
        <ReferenceLine y={0} stroke='#EDEDED' />
        <Bar
          dataKey='lost'
          stackId='a'
          fill='#D86868'
          barSize={lg ? 22 : md ? 14 : 8}
        />
        <Bar
          dataKey='declined'
          stackId='a'
          fill='#FF9F9F'
          barSize={lg ? 22 : md ? 14 : 8}
        />
        <Bar
          dataKey='improved'
          stackId='a'
          fill='#8CD940'
          barSize={lg ? 22 : md ? 14 : 8}
        />
        <Bar
          dataKey='noChange'
          stackId='a'
          fill='#D0D5DD'
          barSize={lg ? 22 : md ? 14 : 8}
        />
        {getCheckedData.length > 24 ? (
          <Brush
            dataKey='date'
            height={40}
            stroke='#126FE9'
            travellerWidth={12}
            onChange={handleBrushChange}
            startIndex={startIndex}
            endIndex={endIndex}
            tickFormatter={() => ''}
          />
        ) : null}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default React.memo(ImprovedVsDeclinedBarChart);
