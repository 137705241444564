import React, { useEffect, useMemo, useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import * as Tooltips from '../components/ProjectExpanded/components/Toltips';
import InfoIcon from '../../../shared/images/InfoIcon';
import { CustomTooltip } from '../../../shared/tooltip/styles';
import EllipsisMenu from '../components/ProjectExpanded/components/KeywordRankings/components/KeywordRankingsTable/components/EllipsisMenu/EllipsisMenu';
import TextTooltip from '../../../shared/components/TextTooltip';
import { getDeviceTypeIcon } from '../utils';
import {
  findLongestString,
  getColumnWidth,
  getMinLineSizeForMainColumn,
} from '../../../utils/table';

import {
  MemoRenderCellBest,
  MemoRenderCellCPC,
  MemoRenderCellD1,
  MemoRenderCellD30,
  MemoRenderCellD7,
  MemoRenderCellFirst,
  MemoRenderCellLife,
  MemoRenderCellPosition,
  MemoRenderCellRefresh,
  MemoRenderCellSearchValue,
  MemoRenderCellUpdated,
  MemoRenderCellURL,
} from '../components/ProjectExpanded/components/KeywordRankings/components/KeywordRankingsTable/components/Table/components/RenderCells/useRenderCells';
import { customSortComparator } from '../../emailReports/hooks/useScheduledEmailReportsTable';
import { useAppSelector } from '../../../store';
import { TableHeaderStyles } from '../../../shared/stylesTable';

export const popperProps = {
  sx: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: '#fff',
      color: '#344054',
      boxShadow:
        '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
      border: '1px solid #EDEDED',
    },
    '.MuiTooltip-arrow::before': {
      backgroundColor: '#fff',
      border: '1px solid #EDEDED',
    },
  },
};

export default function useTeams() {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const keywordRankings = useAppSelector(
    state => state.projects.projectExpandedGraphs.keywordRankings
  );
  const user = useAppSelector(state => state.auth.user);

  const [showUpdatedFullFormat, setShowUpdatedFullFormat] =
    useState<boolean>(false);

  const isViewOnly =
    !currentAccount?.accountId || currentAccount?.role?.name === 'ViewOnly';

  const [nameWidth, setNameWidth] = useState<number>(130);
  const [urlWidth, setUrlWidth] = useState<number>(130);
  const [updatedWidth, setUpdatedWidth] = useState<number>(120);

  useEffect(() => {
    if (keywordRankings?.keywordRanking?.items) {
      setNameWidth(
        getColumnWidth(
          findLongestString(
            keywordRankings?.keywordRanking?.items?.map(item => item.name)
          ) || ''
        )
      );
      setUrlWidth(
        getColumnWidth(
          findLongestString(
            keywordRankings?.keywordRanking?.items?.map(item => item.url)
          ) || ''
        )
      );
      setUpdatedWidth(
        getColumnWidth(
          findLongestString(
            keywordRankings?.keywordRanking?.items?.map(item =>
              showUpdatedFullFormat
                ? item?.updatedFullFormat || '-'
                : item?.updated || '-'
            )
          ) || ''
        )
      );
    }
  }, [keywordRankings?.keywordRanking?.items, showUpdatedFullFormat]);

  const tableWidth = useMemo(
    () => ({
      name: keywordRankings?.keywordRanking?.items
        ? getMinLineSizeForMainColumn(
            findLongestString(
              keywordRankings?.keywordRanking?.items.map(item => item.name)
            ) || '',
            25
          )
        : nameWidth,
      url: keywordRankings?.keywordRanking?.items
        ? getColumnWidth(
            findLongestString(
              keywordRankings?.keywordRanking?.items.map(item => item.url || '')
            ) || ''
          )
        : urlWidth,
      updated: keywordRankings?.keywordRanking?.items
        ? getColumnWidth(
            findLongestString(
              keywordRankings?.keywordRanking?.items.map(item =>
                showUpdatedFullFormat
                  ? item?.updatedFullFormat || '-'
                  : item?.updated || '-'
              )
            ) || ''
          )
        : updatedWidth,
    }),
    [
      keywordRankings?.keywordRanking?.items,
      showUpdatedFullFormat,
      nameWidth,
      urlWidth,
      updatedWidth,
    ]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        sortable: true,
        headerName: 'Keyword',
        maxWidth: 425,
        minWidth: 130,
        width: tableWidth.name,
        sortComparator: customSortComparator,
        renderCell: (params: GridRenderCellParams) => (
          <CustomTooltip
            title={<TextTooltip text={params.row.name} />}
            placement='top-start'
            arrow
            PopperProps={popperProps}
          >
            <Grid display={'flex'} alignItems={'center'}>
              {getDeviceTypeIcon(params.row.deviceType.name)}
              <Box display='flex' alignItems='center'>
                <Box marginLeft='8px'> {params.row.name}</Box>
              </Box>
            </Grid>
          </CustomTooltip>
        ),
      },
      {
        field: 'position',
        headerName: 'Position',
        sortable: true,
        maxWidth: 110,
        minWidth: 110,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => (
          <CustomTooltip
            title={<Tooltips.Position />}
            placement='top-start'
            arrow
          >
            <TableHeaderStyles>
              {'Position '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        ),
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellPosition row={params.row} />
        ),
      },
      {
        field: 'd1',
        headerName: '1d',
        sortable: true,
        maxWidth: 80,
        minWidth: 80,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => (
          <CustomTooltip title={<Tooltips.D1 />} placement='top-start' arrow>
            <TableHeaderStyles>
              {'1d '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        ),
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellD1 row={params.row} />
        ),
      },
      {
        field: 'd7',
        headerName: '7d',
        sortable: true,
        maxWidth: 80,
        minWidth: 80,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => (
          <CustomTooltip title={<Tooltips.D7 />} placement='top-start' arrow>
            <TableHeaderStyles>
              {'7d '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        ),
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellD7 row={params.row} />
        ),
      },
      {
        field: 'd30',
        headerName: '30d',
        sortable: true,
        maxWidth: 85,
        minWidth: 85,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => (
          <CustomTooltip title={<Tooltips.D30 />} placement='top-start' arrow>
            <TableHeaderStyles>
              {'30d '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        ),
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellD30 row={params.row} />
        ),
      },
      {
        field: 'life',
        headerName: 'Life',
        sortable: true,
        maxWidth: 85,
        minWidth: 85,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => (
          <CustomTooltip title={<Tooltips.Life />} placement='top-start' arrow>
            <TableHeaderStyles>
              {'Life '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        ),
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellLife row={params.row} />
        ),
      },
      {
        field: 'best',
        headerName: 'Best',
        sortable: true,
        maxWidth: 85,
        minWidth: 85,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => (
          <CustomTooltip title={<Tooltips.Best />} placement='top-start' arrow>
            <TableHeaderStyles>
              {'Best '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        ),
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellBest row={params.row} />
        ),
      },
      {
        field: 'first',
        headerName: 'start',
        sortable: true,
        maxWidth: 90,
        minWidth: 90,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => (
          <CustomTooltip title={<Tooltips.Start />} placement='top-start' arrow>
            <TableHeaderStyles>
              {'Start '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        ),
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellFirst row={params.row} />
        ),
      },
      {
        field: 'searchValue',
        headerName: 'Search Value',
        sortable: true,
        maxWidth: 110,
        minWidth: 110,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => (
          <CustomTooltip
            title={<Tooltips.Volume />}
            placement='top-start'
            arrow
          >
            <TableHeaderStyles>
              {'Volume '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        ),
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellSearchValue row={params.row} />
        ),
      },
      {
        field: 'cpc',
        headerName: 'L-Vol',
        sortable: true,
        maxWidth: 100,
        minWidth: 100,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => (
          <CustomTooltip title={<Tooltips.CPC />} placement='top-start' arrow>
            <TableHeaderStyles>
              {'CPC '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        ),
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellCPC row={params.row} />
        ),
      },
      {
        field: 'url',
        headerName: 'URL',
        sortable: true,
        maxWidth: 300,
        minWidth: 90,
        width: tableWidth.url,
        sortComparator: customSortComparator,
        renderHeader: () => (
          <CustomTooltip title={<Tooltips.URL />} placement='top-start' arrow>
            <TableHeaderStyles>
              {'URL '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        ),
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellURL row={params.row} />
        ),
      },
      {
        field: 'updated',
        headerName: 'Updated',
        sortable: true,
        maxWidth: 300,
        minWidth: 130,
        sortComparator: customSortComparator,
        renderHeader: () => (
          <CustomTooltip
            title={<Tooltips.Updated />}
            placement='top-start'
            arrow
          >
            <TableHeaderStyles>
              {'Updated '}
              <span role='img' aria-label='enjoy'>
                <InfoIcon />
              </span>
            </TableHeaderStyles>
          </CustomTooltip>
        ),
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellUpdated
            row={params.row}
            showUpdatedFullFormat={showUpdatedFullFormat}
            utc={user.timezone.utc}
            handleSetShowUpdatedFullFormat={value =>
              setShowUpdatedFullFormat(value)
            }
          />
        ),
      },
      {
        field: 'refresh',
        headerName: '',
        sortable: false,
        minWidth: 45,
        maxWidth: 45,
        width: 45,
        align: 'center',
        renderCell: (params: GridRenderCellParams) => (
          <MemoRenderCellRefresh isViewOnly={isViewOnly} keyword={params.row} />
        ),
      },
      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 50,
        align: 'right',
        renderCell: (params: GridRenderCellParams) =>
          !isViewOnly ? <EllipsisMenu keyword={params.row} /> : null,
      },
    ],
    [isViewOnly, tableWidth, showUpdatedFullFormat, user?.timezone]
  );

  const columnsSkeleton: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        sortable: true,
        headerName: 'Keyword',
        maxWidth: 425,
        minWidth: 130,
        width: tableWidth.name,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'position',
        headerName: 'Position',
        sortable: true,
        maxWidth: 110,
        minWidth: 110,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'd1',
        headerName: '1d',
        sortable: true,
        maxWidth: 80,
        minWidth: 80,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'd7',
        headerName: '7d',
        sortable: true,
        maxWidth: 80,
        minWidth: 80,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'd30',
        headerName: '30d',
        sortable: true,
        maxWidth: 85,
        minWidth: 85,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'life',
        headerName: 'Life',
        sortable: true,
        maxWidth: 85,
        minWidth: 85,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'best',
        headerName: 'Best',
        sortable: true,
        maxWidth: 85,
        minWidth: 85,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'first',
        headerName: 'start',
        sortable: true,
        maxWidth: 90,
        minWidth: 90,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'searchValue',
        headerName: 'Search Value',
        sortable: true,
        maxWidth: 110,
        minWidth: 110,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'cpc',
        headerName: 'L-Vol',
        sortable: true,
        maxWidth: 100,
        minWidth: 100,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'url',
        headerName: 'URL',
        sortable: true,
        maxWidth: 300,
        minWidth: 90,
        width: tableWidth.url,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'updated',
        headerName: 'Updated',
        sortable: true,
        maxWidth: 150,
        minWidth: 120,
        width: tableWidth.updated,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'refresh',
        headerName: '',
        sortable: false,
        minWidth: 45,
        maxWidth: 45,
        width: 45,
        align: 'center',
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 50,
        align: 'right',
        renderCell: () => <Skeleton width='100%' />,
      },
    ],
    [tableWidth]
  );

  return {
    columns,
    columnsSkeleton,
  };
}
