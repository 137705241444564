import React from 'react';
import { useAuth } from '../../../../../../../../../../../../hooks';
import { useAppSelector } from '../../../../../../../../../../../../store';

interface ViewInvoiceProps {
  transactionId: string;
}

/**
 * ViewInvoice component that provides a link to view an invoice PDF for a given transaction.
 */
const ViewInvoice: React.FC<ViewInvoiceProps> = ({ transactionId }) => {
  const successFn = (url: string) => {
    window.open(url);
  };

  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const { onGetInvoicePDF } = useAuth();

  const onClick = () => {
    if (currentAccount?.accountId) {
      onGetInvoicePDF({
        id: currentAccount.accountId,
        transactionId,
        successFn,
      });
    }
  };

  if (!transactionId) return null;

  return (
    <a style={{ cursor: 'pointer' }} onClick={onClick}>
      View
    </a>
  );
};

export default ViewInvoice;
