import React, { useEffect } from 'react';
import { Fade, Grid, IconButton, Modal, Typography } from '@mui/material';
import {
  ModalContainer,
  ModalWrapper,
} from '../../../../../projects/components/Projects/components/ProjectRedactorModal/styles';
import { CloseDeletePopoverOne } from '../../../../../auth/components/Settings/components/Account/styles';
import CloseIcon from '@mui/icons-material/Close';
import { InfoSection } from './components';
import { KeywordsAssociatedWithTriggerModalProps } from './types';
import KeywordsAssociatedWithTriggerTable from './components/KeywordsAssociatedWithTriggerTable/KeywordsAssociatedWithTriggerTable';
import useAlerts from '../../../../hooks/useAlerts';
import ActionsMenu from './components/KeywordsAssociatedWithTriggerTable/components/ActionsMenu/ActionsMenu';
import { useAppSelector } from '../../../../../../store';

const KeywordsAssociatedWithTriggerModal: React.FC<
  KeywordsAssociatedWithTriggerModalProps
> = ({ triggerId, isOpen, onClose }) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const deleteElements = useAppSelector(
    state => state.alerts.tables.triggerKeywords.deleteElements
  );

  const {
    onGetTriggerKeywords,
    onGetTriggerInfo,
    onSetRowSelectionModelTriggerKeywords,
    onResetTriggerKeywordsState,
    onGetTriggersByKeywords,
    tables: {
      triggerKeywords: { paginationModel, sortModel, rowSelectionModel },
      triggers,
    },
  } = useAlerts();

  const handleClose = () => {
    if (!deleteElements.length) {
      onGetTriggersByKeywords({
        id: currentAccount.accountId,
        page: triggers.paginationModel.page,
        limit: triggers.paginationModel.pageSize,
        sortBy: triggers.sortModel[0]?.field,
        sortOrder: triggers.sortModel[0]?.sort,
      });
    }
    onClose();
  };

  const onClearSelected = () => {
    onSetRowSelectionModelTriggerKeywords([]);
  };

  const handleCloseModal = () => {
    onResetTriggerKeywordsState();
    handleClose();
  };

  useEffect(() => {
    if (currentAccount?.accountId && isOpen) {
      onGetTriggerInfo({ id: currentAccount.accountId, triggerId: triggerId });
    }
  }, [currentAccount?.accountId, onGetTriggerInfo, open, triggerId]);

  useEffect(() => {
    if (currentAccount?.accountId && isOpen) {
      onGetTriggerKeywords({
        id: currentAccount.accountId,
        triggerId: triggerId,
        page: paginationModel.page,
        limit: paginationModel.pageSize,
        sortBy: sortModel[0]?.field,
        sortOrder: sortModel[0]?.sort,
      });
    }
  }, [
    currentAccount.accountId,
    onGetTriggerKeywords,
    open,
    paginationModel.page,
    paginationModel.pageSize,
    sortModel,
    triggerId,
  ]);

  return (
    <Modal
      open={isOpen}
      onClose={handleCloseModal}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isOpen}>
        <ModalWrapper>
          <ModalContainer container xs={12} sx={{ gap: '8px' }}>
            <Grid xs={12}>
              <Grid item xs={12} sx={{ position: 'relative' }}>
                <CloseDeletePopoverOne onClick={handleCloseModal}>
                  <CloseIcon fontSize='small' />
                </CloseDeletePopoverOne>
                <Typography
                  fontSize='18px'
                  fontWeight='600'
                  lineHeight='28px'
                  color='#101828'
                >
                  {`Keywords Associated with Trigger`}
                </Typography>
              </Grid>
              <InfoSection />
              <Grid
                container
                item
                alignItems='center'
                xs={12}
                paddingBottom={'16px'}
              >
                {rowSelectionModel.length ? (
                  <>
                    <Grid item>
                      <IconButton
                        onClick={onClearSelected}
                        sx={{ padding: '0 !important', marginRight: '8px' }}
                      >
                        <CloseIcon fontSize='small' />
                      </IconButton>
                    </Grid>
                    <Grid item sx={{ paddingRight: '16px' }}>
                      <Typography
                        fontSize='14px'
                        lineHeight='14px'
                        color='#101828'
                        fontWeight='500'
                        alignItems='center'
                      >
                        {`${rowSelectionModel.length} Selected`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <ActionsMenu />
                    </Grid>
                  </>
                ) : null}
              </Grid>
              <KeywordsAssociatedWithTriggerTable />
            </Grid>
          </ModalContainer>
        </ModalWrapper>
      </Fade>
    </Modal>
  );
};

export default KeywordsAssociatedWithTriggerModal;
