import React from 'react';
import Grid from '@mui/material/Grid';

import {
  StyledITooltipContainerHead,
  StyledStatus,
  StyledStatusLabel,
  StyledStatusValue,
} from '../../../Overview/styles';
import { StyledITooltipContainer } from './styled';
import { TooltipProps } from 'recharts';
import dayjs from 'dayjs';

const ImprovedDeclined = ({
  active,
  label,
  payload,
}: TooltipProps<number, string>) => {
  if (active) {
    return (
      <StyledITooltipContainer item>
        <StyledITooltipContainerHead item>{label}</StyledITooltipContainerHead>
        {payload?.length && (
          <>
            {payload[2] && (
              <Grid container item xs={12}>
                <Grid container item xs={8} alignItems={'center'}>
                  <StyledStatus item $backgroundColor={'#81D32F'} />
                  <StyledStatusLabel item>Improved:</StyledStatusLabel>
                </Grid>
                <Grid xs={4} container item justifyContent={'flex-end'}>
                  <StyledStatusValue item>{payload[2].value}</StyledStatusValue>
                </Grid>
              </Grid>
            )}
            {payload[1] && (
              <Grid container item xs={12}>
                <Grid container item xs={8} alignItems={'center'}>
                  <StyledStatus item $backgroundColor={'#FF9F9F'} />
                  <StyledStatusLabel item>Declined:</StyledStatusLabel>
                </Grid>
                <Grid xs={4} container item justifyContent={'flex-end'}>
                  <StyledStatusValue item>{payload[1].value}</StyledStatusValue>
                </Grid>
              </Grid>
            )}
            {payload[0] && (
              <Grid container item xs={12}>
                <Grid container item xs={8} alignItems={'center'}>
                  <StyledStatus item $backgroundColor={'#D86868'} />
                  <StyledStatusLabel item>Lost:</StyledStatusLabel>
                </Grid>
                <Grid xs={4} container item justifyContent={'flex-end'}>
                  <StyledStatusValue item>{payload[0].value}</StyledStatusValue>
                </Grid>
              </Grid>
            )}
            {payload[3] && (
              <Grid container item xs={12}>
                <Grid container item xs={8} alignItems={'center'}>
                  <StyledStatus item $backgroundColor={'#D0D5DD'} />
                  <StyledStatusLabel item>No change:</StyledStatusLabel>
                </Grid>
                <Grid xs={4} container item justifyContent={'flex-end'}>
                  <StyledStatusValue item>{payload[3].value}</StyledStatusValue>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </StyledITooltipContainer>
    );
  }

  return null;
};

const KeywordTrends = ({
  active,
  label,
  payload,
}: TooltipProps<number, string>) => {
  if (active) {
    const top3 = payload?.find(item => item.dataKey === 'top3');
    const fromFourToTen = payload?.find(
      item => item.dataKey === 'fromFourToTen'
    );
    const fromElevenToTwenty = payload?.find(
      item => item.dataKey === 'fromElevenToTwenty'
    );
    const fromTwentyOneToFifty = payload?.find(
      item => item.dataKey === 'fromTwentyOneToFifty'
    );
    const fiftyOneToOneHundred = payload?.find(
      item => item.dataKey === 'fiftyOneToOneHundred'
    );
    const notRanked = payload?.find(item => item.dataKey === 'notRanked');

    return (
      <StyledITooltipContainer item>
        <StyledITooltipContainerHead item>
          {dayjs(label).format('MMMM DD, YYYY')}
        </StyledITooltipContainerHead>
        {payload?.length && (
          <>
            {top3 && (
              <Grid container item xs={12}>
                <Grid container item xs={8} alignItems={'center'}>
                  <StyledStatus item $backgroundColor={top3.color} />
                  <StyledStatusLabel item>Top 3</StyledStatusLabel>
                </Grid>
                <Grid xs={4} container item justifyContent={'flex-end'}>
                  <StyledStatusValue item>{top3.value}</StyledStatusValue>
                </Grid>
              </Grid>
            )}
            {fromFourToTen && (
              <Grid container item xs={12}>
                <Grid container item xs={8} alignItems={'center'}>
                  <StyledStatus item $backgroundColor={fromFourToTen.color} />
                  <StyledStatusLabel item>Top 4-10</StyledStatusLabel>
                </Grid>
                <Grid xs={4} container item justifyContent={'flex-end'}>
                  <StyledStatusValue item>
                    {fromFourToTen.value}
                  </StyledStatusValue>
                </Grid>
              </Grid>
            )}
            {fromElevenToTwenty && (
              <Grid container item xs={12}>
                <Grid container item xs={8} alignItems={'center'}>
                  <StyledStatus
                    item
                    $backgroundColor={fromElevenToTwenty.color}
                  />
                  <StyledStatusLabel item>Top 11-20</StyledStatusLabel>
                </Grid>
                <Grid xs={4} container item justifyContent={'flex-end'}>
                  <StyledStatusValue item>
                    {fromElevenToTwenty.value}
                  </StyledStatusValue>
                </Grid>
              </Grid>
            )}
            {fromTwentyOneToFifty && (
              <Grid container item xs={12}>
                <Grid container item xs={8} alignItems={'center'}>
                  <StyledStatus
                    item
                    $backgroundColor={fromTwentyOneToFifty.color}
                  />
                  <StyledStatusLabel item>Top 21-50</StyledStatusLabel>
                </Grid>
                <Grid xs={4} container item justifyContent={'flex-end'}>
                  <StyledStatusValue item>
                    {fromTwentyOneToFifty.value}
                  </StyledStatusValue>
                </Grid>
              </Grid>
            )}
            {fiftyOneToOneHundred && (
              <Grid container item xs={12}>
                <Grid container item xs={8} alignItems={'center'}>
                  <StyledStatus
                    item
                    $backgroundColor={fiftyOneToOneHundred.color}
                  />
                  <StyledStatusLabel item>Top 51-100</StyledStatusLabel>
                </Grid>
                <Grid xs={4} container item justifyContent={'flex-end'}>
                  <StyledStatusValue item>
                    {fiftyOneToOneHundred.value}
                  </StyledStatusValue>
                </Grid>
              </Grid>
            )}
            {notRanked && (
              <Grid container item xs={12}>
                <Grid container item xs={8} alignItems={'center'}>
                  <StyledStatus item $backgroundColor={notRanked.color} />
                  <StyledStatusLabel item>Not ranked</StyledStatusLabel>
                </Grid>
                <Grid xs={4} container item justifyContent={'flex-end'}>
                  <StyledStatusValue item>{notRanked.value}</StyledStatusValue>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </StyledITooltipContainer>
    );
  }

  return null;
};

export { ImprovedDeclined, KeywordTrends };
