import { useAppDispatch, useAppSelector } from '../store';
import { useCallback } from 'react';
import { resetAuthState } from '../features/auth/redux/actions';
import { resetProjectsState } from '../features/projects/redux/actions';
import { resetFoldersState } from '../features/folders/redux/actions';
import { resetNotesState } from '../features/notes/redux/actions';
import { resetSharedLinksState } from '../features/sharedLinks/redux/actions';
import { resetEmailReportsState } from '../features/emailReports/redux/actions';
import { resetPaddleState } from '../features/payment/redux/actions';
import { offAllSockets } from '../features/auth/socket';

/**
 * Custom hook that provides access to the global state and a method to reset
 * the application state.
 *
 * @returns {Object} An object containing the current state and a method
 * to reset the application state.
 * @property {Function} onResetApp - A function that resets all application
 * states and disconnects sockets.
 */
export default function useApp() {
  const dispatch = useAppDispatch();

  // Selects the entire application state from the Redux store
  const state = useAppSelector(state => state);

  /**
   * Resets the application state by dispatching actions for each state slice.
   * Also disconnects all active sockets.
   */
  const onResetApp = useCallback(() => {
    dispatch(resetAuthState());
    dispatch(resetProjectsState());
    dispatch(resetFoldersState());
    dispatch(resetNotesState());
    dispatch(resetSharedLinksState());
    dispatch(resetEmailReportsState());
    dispatch(resetPaddleState());
    offAllSockets(); // Disconnects all active sockets
  }, [dispatch]);

  return {
    ...state, // Spreads the current state into the returned object
    onResetApp, // Exposes the reset function
  };
}
