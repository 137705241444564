import React from 'react';
import { ListActionsProps } from './types';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import {
  ListItemTypography,
  StyledListItem,
  StyledListItemButton,
} from '../../../../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/styles';
import SmallTrashIcon from '../../../../../../../../../../shared/images/SmallTrashIcom';
import { closeAndUndoSnackbarAction } from '../../../../../../../../../../utils/snackbar';
import CustomToast from '../../../../../../../../../../shared/CustomToast/CustomToast';
import useAlerts from '../../../../../../../../hooks/useAlerts';
import { useAuth } from '../../../../../../../../../../hooks';
import { useAppSelector } from '../../../../../../../../../../store';

const DeleteAction: React.FC<ListActionsProps> = ({
  id,
  selectBy,
  popupClose,
}) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const {
    onSetDeleteElementsTriggersTable,
    onBulkDeleteTriggersByProjects,
    onBulkDeleteTriggersByKeywords,
    onGetTriggersByProjects,
    onGetTriggersByKeywords,
    onSetRowSelectionModelTriggers,
    tables: {
      triggers: { paginationModel, sortModel },
    },
  } = useAlerts();

  const handleHardDelete = () => {
    if (currentAccount?.accountId) {
      if (selectBy === 'by Project') {
        onBulkDeleteTriggersByProjects({
          id: currentAccount.accountId,
          ids: [id],
          successFn: () => {
            onGetTriggersByProjects({
              id: currentAccount.accountId,
              page: paginationModel.page,
              limit: paginationModel.pageSize,
              sortBy: sortModel[0]?.field,
              sortOrder: sortModel[0]?.sort,
            });
            onSetRowSelectionModelTriggers([]);
            onSetDeleteElementsTriggersTable([]);
            popupClose();
          },
        });
      }
      if (selectBy === 'by Keywords') {
        onBulkDeleteTriggersByKeywords({
          id: currentAccount.accountId,
          ids: [id],
          successFn: () => {
            onGetTriggersByKeywords({
              id: currentAccount.accountId,
              page: paginationModel.page,
              limit: paginationModel.pageSize,
              sortBy: sortModel[0]?.field,
              sortOrder: sortModel[0]?.sort,
            });
            onSetRowSelectionModelTriggers([]);
            onSetDeleteElementsTriggersTable([]);
            popupClose();
          },
        });
      }
    }
  };

  const handleDeleteOneKeyword = () => {
    onSetDeleteElementsTriggersTable([id]);
    popupClose();
    let undoDelete = false;
    const onUndo = () => {
      undoDelete = true;
      onSetDeleteElementsTriggersTable([]);
    };

    enqueueSnackbar(
      <CustomToast
        message='Trigger Removed'
        submessage={`Trigger has been successfully removed.`}
      />,
      {
        action: (snackbarId: SnackbarKey) =>
          closeAndUndoSnackbarAction(snackbarId, onUndo),
        variant: 'error',
      }
    );
    setTimeout(() => {
      if (!undoDelete) {
        handleHardDelete();
      }
    }, 5000);
  };

  return (
    <StyledListItem onClick={handleDeleteOneKeyword}>
      <StyledListItemButton>
        <SmallTrashIcon />
        <ListItemTypography marginLeft='8px' sx={{ color: '#B42318' }}>
          Delete Trigger
        </ListItemTypography>
      </StyledListItemButton>
    </StyledListItem>
  );
};

export default DeleteAction;
