import React from 'react';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import CheckedIcon from '../../../../../../../shared/images/CheckedIcon';

import CustomCheckbox from '../../../../../../../shared/images/CustomCheckbox';

const CheckboxWrapper: React.FC<CheckboxProps> = props => {
  return (
    <Checkbox
      icon={<CustomCheckbox />}
      checkedIcon={<CheckedIcon />}
      {...props}
    />
  );
};

export default CheckboxWrapper;
