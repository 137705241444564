import { styled } from '@mui/material/styles';
import { Grid, LinearProgress } from '@mui/material';

const StyledProgress = styled(Grid)`
  padding-bottom: 24px;
`;

const Title = styled(Grid)`
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

const StyledLinearProgress = styled(LinearProgress)`
  margin-top: 14px;
  margin-bottom: 3px;
  border-radius: 8px;
  background-color: #e0e0e0;
  height: 6px;
`;

export { StyledProgress, Title, StyledLinearProgress };
