import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import TablePagination from '@mui/material/TablePagination';

const StyledTablePagination = styled(TablePagination)`
  color: #344054;
  font-size: 14px;
  line-height: 24px;
  border-bottom: none;

  .MuiTablePagination-toolbar {
    padding: 0 !important;
  }
  .MuiSelect-icon {
    right: 9px !important;
  }

  .MuiTablePagination-select {
    width: 38px;
    padding: 4px 14px !important;
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #344054;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: flex-start;
  }

  .MuiInputBase-input:focus {
    border-radius: 8px !important;
    border: 1px solid #d0d5dd !important;
    background: #fff !important;
  }

  .MuiTablePagination-actions {
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    margin-left: 8px !important;

    button {
      padding: 4px 8px;

      :first-child {
        border-right: 1px solid #eaecf0;
        border-radius: 0;
      }
    }
  }

  .MuiTablePagination-displayedRows {
    color: #344054;
    font-size: 14px;
    line-height: 24px;
  }

  .MuiTablePagination-selectLabel {
    + div {
      margin-right: 8px;
    }
  }
`;

const PaginationContainer = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  padding: 24px;
  background: white;
`;

export { StyledTablePagination, PaginationContainer };
