import React from 'react';

const EmptyBoxIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='137'
    height='100'
    viewBox='0 0 137 100'
    fill='none'
  >
    <path
      d='M111.214 43.0243C111.214 52.7152 107.969 61.8213 102.561 69.006C99.5656 72.9324 95.988 76.3577 91.828 79.031C85.1719 83.4588 77.1014 85.965 68.5317 85.965C44.9858 86.0486 25.8496 66.8339 25.8496 43.0243C25.8496 19.2983 44.9026 0 68.5317 0C77.1014 0 85.0887 2.50627 91.828 6.93401C95.988 9.60737 99.5656 13.0326 102.561 16.9591C107.969 24.2273 111.214 33.2498 111.214 43.0243Z'
      fill='#EAEEF9'
    />
    <path
      d='M36.0261 37.273H35.5108C35.1243 37.273 34.8667 37.1528 34.8667 36.9725C34.8667 36.7921 35.1243 36.6719 35.5108 36.6719H36.0261C36.4125 36.6719 36.6702 36.7921 36.6702 36.9725C36.6702 37.1528 36.4125 37.273 36.0261 37.273Z'
      fill='#2D5887'
    />
    <path
      d='M36.0261 39.6754H35.5108C35.1243 39.6754 34.8667 39.5551 34.8667 39.3748C34.8667 39.1945 35.1243 39.0742 35.5108 39.0742H36.0261C36.4125 39.0742 36.6702 39.1945 36.6702 39.3748C36.6702 39.5551 36.4125 39.6754 36.0261 39.6754Z'
      fill='#2D5887'
    />
    <path
      d='M36.0261 42.0816H35.5108C35.1243 42.0816 34.8667 41.9614 34.8667 41.781C34.8667 41.6007 35.1243 41.4805 35.5108 41.4805H36.0261C36.4125 41.4805 36.6702 41.6007 36.6702 41.781C36.6702 41.9614 36.4125 42.0816 36.0261 42.0816Z'
      fill='#2D5887'
    />
    <path
      d='M36.0261 44.4859H35.5108C35.1243 44.4859 34.8667 44.3657 34.8667 44.1853C34.8667 44.005 35.1243 43.8848 35.5108 43.8848H36.0261C36.4125 43.8848 36.6702 44.005 36.6702 44.1853C36.6702 44.3657 36.4125 44.4859 36.0261 44.4859Z'
      fill='#2D5887'
    />
    <path
      d='M59.5426 37.273H51.0691C50.7256 37.273 50.4966 37.1528 50.4966 36.9725C50.4966 36.7921 50.7256 36.6719 51.0691 36.6719H59.5426C59.8861 36.6719 60.1151 36.7921 60.1151 36.9725C60.1151 37.1528 59.8861 37.273 59.5426 37.273Z'
      fill='white'
    />
    <path
      d='M77.5967 44.4859H51.0496C50.7178 44.4859 50.4966 44.3657 50.4966 44.1853C50.4966 44.005 50.7178 43.8848 51.0496 43.8848H77.5967C77.9285 43.8848 78.1498 44.005 78.1498 44.1853C78.1498 44.3657 77.9285 44.4859 77.5967 44.4859Z'
      fill='url(#paint0_linear_8976_77438)'
    />
    <path
      d='M59.576 39.6754H48.0307C47.707 39.6754 47.4912 39.5551 47.4912 39.3748C47.4912 39.1944 47.707 39.0742 48.0307 39.0742H59.576C59.8997 39.0742 60.1155 39.1944 60.1155 39.3748C60.1155 39.5551 59.8997 39.6754 59.576 39.6754Z'
      fill='#2D5887'
    />
    <path
      d='M68.5945 42.0816H48.0296C47.7066 42.0816 47.4912 41.9614 47.4912 41.781C47.4912 41.6007 47.7066 41.4805 48.0296 41.4805H68.5945C68.9175 41.4805 69.1328 41.6007 69.1328 41.781C69.0252 41.9614 68.8098 42.0816 68.5945 42.0816Z'
      fill='#2D5887'
    />
    <path
      d='M77.5646 42.0816H69.7183C69.367 42.0816 69.1328 41.9614 69.1328 41.781C69.1328 41.6007 69.367 41.4805 69.7183 41.4805H77.5646C77.9159 41.4805 78.1502 41.6007 78.1502 41.781C78.1502 41.9614 77.9159 42.0816 77.5646 42.0816Z'
      fill='white'
    />
    <path
      d='M82.3909 42.0816H79.3201C78.9789 42.0816 78.7515 41.9614 78.7515 41.781C78.7515 41.6007 78.9789 41.4805 79.3201 41.4805H82.3909C82.7321 41.4805 82.9596 41.6007 82.9596 41.781C82.9596 41.9614 82.7321 42.0816 82.3909 42.0816Z'
      fill='url(#paint1_linear_8976_77438)'
    />
    <path
      d='M16.231 75.1445H117.225'
      stroke='#D6DEE8'
      strokeWidth='1.46524'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <g filter='url(#filter0_d_8976_77438)'>
      <path
        d='M87.1671 36.6719V75.1459H32.6205C30.4688 75.1459 28.855 73.4167 28.855 71.3633V36.6719H87.1671Z'
        fill='white'
      />
    </g>
    <path
      d='M108.809 36.6719V71.3633C108.809 73.5248 107.015 75.1459 104.884 75.1459H87.1675V36.6719H108.809Z'
      fill='#DAE1ED'
    />
    <g filter='url(#filter1_d_8976_77438)'>
      <path
        d='M108.809 36.6719V71.3633C108.809 73.5248 107.015 75.1459 104.884 75.1459H87.1675V36.6719H108.809Z'
        fill='#E7EAF4'
      />
    </g>
    <path
      d='M49.896 36.6714L61.3661 18.6367H120.231L108.437 36.6714H49.896Z'
      fill='#C5CDDB'
    />
    <g filter='url(#filter2_d_8976_77438)'>
      <path
        d='M49.896 36.6714L61.3661 18.6367H120.231L108.437 36.6714H49.896Z'
        fill='url(#paint2_linear_8976_77438)'
      />
    </g>
    <path
      opacity='0.3'
      d='M108.809 36.7772V57.1112H93.0287C91.5634 57.1112 90.549 56.163 90.3236 54.7933L87.1675 36.6719L108.809 36.7772Z'
      fill='url(#paint3_linear_8976_77438)'
    />
    <g filter='url(#filter3_d_8976_77438)'>
      <path
        d='M108.356 36.6719H87.1675L97.9262 52.9787C98.6947 54.0586 99.9023 54.7066 101.11 54.7066H117.577C118.675 54.7066 119.444 53.4107 118.785 52.5467L108.356 36.6719Z'
        fill='url(#paint4_linear_8976_77438)'
      />
    </g>
    <g filter='url(#filter4_d_8976_77438)'>
      <path
        d='M87.1674 36.6714L75.6171 18.6367H16.231L28.2171 36.6714H87.1674Z'
        fill='#E7EAF4'
      />
    </g>
    <path
      d='M57.9569 58.9128H35.2218C34.6906 58.9128 34.2656 58.5121 34.2656 58.0111C34.2656 57.5101 34.6906 57.1094 35.2218 57.1094H57.9569C58.4881 57.1094 58.913 57.5101 58.913 58.0111C58.8068 58.5121 58.4881 58.9128 57.9569 58.9128Z'
      fill='#DAE1ED'
    />
    <path
      d='M57.9569 63.1222H35.2218C34.6906 63.1222 34.2656 62.8551 34.2656 62.5211C34.2656 62.1871 34.6906 61.9199 35.2218 61.9199H57.9569C58.4881 61.9199 58.913 62.1871 58.913 62.5211C58.8068 62.8551 58.4881 63.1222 57.9569 63.1222Z'
      fill='#DAE1ED'
    />
    <path
      d='M45.9188 67.9308H35.2367C34.6972 67.9308 34.2656 67.6636 34.2656 67.3297C34.2656 66.9957 34.6972 66.7285 35.2367 66.7285H45.9188C46.4583 66.7285 46.8899 66.9957 46.8899 67.3297C46.782 67.6636 46.3504 67.9308 45.9188 67.9308Z'
      fill='#DAE1ED'
    />
    <defs>
      <filter
        id='filter0_d_8976_77438'
        x='12.7373'
        y='28.613'
        width='90.5474'
        height='70.71'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='8.05884' />
        <feGaussianBlur stdDeviation='8.05884' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_8976_77438'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_8976_77438'
          result='shape'
        />
      </filter>
      <filter
        id='filter1_d_8976_77438'
        x='71.0498'
        y='28.613'
        width='53.8769'
        height='70.71'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='8.05884' />
        <feGaussianBlur stdDeviation='8.05884' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_8976_77438'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_8976_77438'
          result='shape'
        />
      </filter>
      <filter
        id='filter2_d_8976_77438'
        x='33.7783'
        y='10.5779'
        width='102.571'
        height='50.2705'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='8.05884' />
        <feGaussianBlur stdDeviation='8.05884' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_8976_77438'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_8976_77438'
          result='shape'
        />
      </filter>
      <filter
        id='filter3_d_8976_77438'
        x='71.0498'
        y='28.613'
        width='64.0967'
        height='50.2705'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='8.05884' />
        <feGaussianBlur stdDeviation='8.05884' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_8976_77438'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_8976_77438'
          result='shape'
        />
      </filter>
      <filter
        id='filter4_d_8976_77438'
        x='0.113283'
        y='10.5779'
        width='103.172'
        height='50.2705'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='8.05884' />
        <feGaussianBlur stdDeviation='8.05884' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_8976_77438'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_8976_77438'
          result='shape'
        />
      </filter>
      <linearGradient
        id='paint0_linear_8976_77438'
        x1='50.6208'
        y1='44.1975'
        x2='78.1818'
        y2='44.1975'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF66A9' />
        <stop offset='1' stopColor='#F53689' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_8976_77438'
        x1='78.7796'
        y1='41.7627'
        x2='82.9588'
        y2='41.7627'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#83A6FF' />
        <stop offset='1' stopColor='#5A78FF' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_8976_77438'
        x1='85.0407'
        y1='18.2196'
        x2='85.0407'
        y2='36.8658'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FDFEFF' />
        <stop offset='0.9964' stopColor='#ECF0F5' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_8976_77438'
        x1='97.9932'
        y1='57.5307'
        x2='97.9932'
        y2='38.9129'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.00289017' stopColor='#606673' stopOpacity='0' />
        <stop offset='1' stopColor='#AAB2C5' />
      </linearGradient>
      <linearGradient
        id='paint4_linear_8976_77438'
        x1='103.088'
        y1='36.2547'
        x2='103.088'
        y2='54.901'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FDFEFF' />
        <stop offset='0.9964' stopColor='#ECF0F5' />
      </linearGradient>
    </defs>
  </svg>
);

export default EmptyBoxIcon;
