import { Grid } from '@mui/material';
import React from 'react';
import {
  StylesInfoSection,
  StylesInfoSectionLabel,
  StylesInfoSectionValue,
} from './styles';
import useAlerts from '../../../../../../../../hooks/useAlerts';

const InfoSection = () => {
  const { alertInfo } = useAlerts();

  return (
    <StylesInfoSection container gap={4}>
      <Grid item>
        <StylesInfoSectionLabel>Domain</StylesInfoSectionLabel>
        <StylesInfoSectionValue>{alertInfo?.url}</StylesInfoSectionValue>
      </Grid>
      <Grid item>
        <StylesInfoSectionLabel>Rule</StylesInfoSectionLabel>
        <StylesInfoSectionValue>{alertInfo?.rule?.name}</StylesInfoSectionValue>
      </Grid>
      <Grid item>
        <StylesInfoSectionLabel>Threshold</StylesInfoSectionLabel>
        <StylesInfoSectionValue>{alertInfo?.threshold}</StylesInfoSectionValue>
      </Grid>
      <Grid item>
        <StylesInfoSectionLabel>Affected keywords</StylesInfoSectionLabel>
        <StylesInfoSectionValue>
          {alertInfo?.affectedKeywords}
        </StylesInfoSectionValue>
      </Grid>
    </StylesInfoSection>
  );
};

export default InfoSection;
