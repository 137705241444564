import React from 'react';
import { Grid } from '@mui/material';
import { StylesActive, StylesAlertsSwitch, StylesNoActive } from './styles';
import { AlertsSwitchProps } from './types';
import { capitalizeFirstLetter } from '../../../../../../../../utils';
import useAlerts from '../../../../../../hooks/useAlerts';
import { useAppSelector } from '../../../../../../../../store';

export const ALERTS_SWITCH_OPTIONS = [
  {
    name: 'by Project',
    value: 0,
  },
  {
    name: 'by Keywords',
    value: 1,
  },
];

/**
 * AlertsSwitch component for switching between different alert views.
 *
 * This component allows users to switch between "by Project" and "by Keywords" alert views.
 * It uses the current account information to fetch the corresponding project alerts and update the UI accordingly.
 */
const AlertsSwitch: React.FC<AlertsSwitchProps> = ({
  selectBy,
  setSelectBy,
}) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const { onSetActiveProjectForFilterAlerts, onGetProjectsByAlerts } =
    useAlerts();

  const handleSwitch = (name: 'by Project' | 'by Keywords') => {
    if (currentAccount?.accountId) {
      setSelectBy(name);
      onSetActiveProjectForFilterAlerts('0');
      onGetProjectsByAlerts({
        id: currentAccount.accountId,
        selectBy: name,
      });
    }
  };
  return (
    <StylesAlertsSwitch container>
      {ALERTS_SWITCH_OPTIONS.map(option => {
        const name = option.name as 'by Project' | 'by Keywords';
        return (
          <Grid key={option.value} item onClick={() => handleSwitch(name)}>
            {name === selectBy ? (
              <StylesActive>{capitalizeFirstLetter(name)}</StylesActive>
            ) : (
              <StylesNoActive>{capitalizeFirstLetter(name)}</StylesNoActive>
            )}
          </Grid>
        );
      })}
    </StylesAlertsSwitch>
  );
};

export default AlertsSwitch;
