import React, { useEffect, useMemo } from 'react';

import {
  Header,
  StyledSearchResultContainer,
} from '../../../../../../../projects/components/ProjectExpanded/components/KeywordRankings/components/SearchResult/styles';

import { SearchResultList, Pagination } from './components';

import { useSharedLinks } from '../../../../../../../../hooks';
import { getSharedLinkToken } from '../../../../../../../../utils/localStorage';
import { SearchResultProps } from '../../../../../../../projects/components/ProjectExpanded/components/KeywordRankings/components/SearchResult/types';
import Grid from '@mui/material/Grid';
import { useAppSelector } from '../../../../../../../../store';

const SearchResult: React.FC<SearchResultProps> = ({ id }) => {
  const { onGetKeywordSearchResults } = useSharedLinks();

  const keywordsAnalytics = useAppSelector(
    state => state.sharedLinks.projectGraphs.keywordRankings.keywordsAnalytics
  );

  const keywordAnalytic = useMemo(
    () => keywordsAnalytics.find(item => item.id === id),
    [id, keywordsAnalytics]
  );

  const paginationData = useMemo(() => {
    return {
      page: keywordAnalytic?.searchResult?.meta.currentPage || 0,
      totalItems: keywordAnalytic?.searchResult?.meta.totalItems || 0,
    };
  }, [
    keywordAnalytic?.searchResult?.meta?.currentPage,
    keywordAnalytic?.searchResult?.meta?.totalItems,
  ]);

  useEffect(() => {
    const sharedLinkToken = getSharedLinkToken();
    if (
      keywordAnalytic?.id &&
      !keywordAnalytic?.searchResult &&
      sharedLinkToken
    ) {
      onGetKeywordSearchResults({
        link: sharedLinkToken,
        keywordId: id,
        limit: 10,
      });
    }
  }, [id, keywordAnalytic?.id]);

  return (
    <StyledSearchResultContainer>
      <Header>Search Results</Header>
      <Grid xs={12}>
        <SearchResultList searchResult={keywordAnalytic?.searchResult} />
        <Pagination paginationData={paginationData} id={id} />
      </Grid>
    </StyledSearchResultContainer>
  );
};

export default SearchResult;
