import React, { useEffect, useMemo } from 'react';
import useFolders from '../../hooks/useFolders';
import { useParams } from 'react-router-dom';

import { TableFolders } from './components';
import { Header } from './components/Header';

import { ContentContainer } from './styles';
import { getUpdatedProjectsToServer } from '../../../projects/socket';
import { useAppSelector } from '../../../../store';

/**
 * Component to display folders and their contents.
 */
const Folders = () => {
  const { id: dynamicFolderId } = useParams();

  const myAccount = useAppSelector(state => state.auth.myAccount);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const {
    folders,
    onUpdateProjectsInFolder,
    onGetRootFolders,
    isRootView,
    onGetFoldersById,
    onSetDefaultCurrentFolder,
    tables: {
      rootFolder: {
        paginationModel,
        search,
        sortModel,
        sortModelNestedElements,
        rootFolderColumnVisibilityModel,
        childrenFolderColumnVisibilityModel,
      },
    },
  } = useFolders();

  const selectedTags = useAppSelector(state => state.projects.selectedTags);

  /**
   * Determines the column visibility model based on the current view.
   */
  const columnVisibilityModel = useMemo(
    () =>
      isRootView
        ? rootFolderColumnVisibilityModel
        : childrenFolderColumnVisibilityModel,
    [
      childrenFolderColumnVisibilityModel,
      isRootView,
      rootFolderColumnVisibilityModel,
    ]
  );

  /**
   * Fetch folders by ID when the folder ID changes.
   */
  useEffect(() => {
    if (myAccount.id && dynamicFolderId) {
      onGetFoldersById({
        accountId: myAccount.id,
        folderId: Number(dynamicFolderId),
        params: {
          page: paginationModel.page,
          limit: paginationModel.pageSize,
          search: search,
          sortBy: sortModelNestedElements[0]?.field,
          sortOrder: sortModelNestedElements[0]?.sort,
          tagIds: selectedTags.map(item => item.id).join(),
          dailyAverage: columnVisibilityModel.dailyAverage,
        },
      });
    }
  }, [
    myAccount,
    dynamicFolderId,
    paginationModel.page,
    paginationModel.pageSize,
    search,
    sortModelNestedElements,
    onGetFoldersById,
    selectedTags,
    columnVisibilityModel.dailyAverage,
  ]);

  /**
   * Fetch root folders when the component is in root view mode.
   */
  useEffect(() => {
    if (myAccount.id && isRootView && !dynamicFolderId) {
      onGetRootFolders({
        accountId: myAccount.id,
        params: {
          page: paginationModel.page,
          limit: paginationModel.pageSize,
          search: search,
          sortBy: sortModel[0]?.field,
          sortOrder: sortModel[0]?.sort,
          tagIds: selectedTags.map(item => item.id).join(),
        },
      });
    }
  }, [
    myAccount,
    paginationModel.page,
    paginationModel.pageSize,
    search,
    sortModel,
    onGetRootFolders,
    isRootView,
    selectedTags,
    dynamicFolderId,
  ]);

  /**
   * Sets the default current folder when the root view is active and there is no selected folder.
   */
  useEffect(() => {
    if (
      folders?.items?.length &&
      !dynamicFolderId &&
      myAccount?.folderTree?.id
    ) {
      const mainFolder = { id: myAccount.folderTree.id, name: 'My Folders' };
      onSetDefaultCurrentFolder(mainFolder);
    }
  }, [
    folders?.items,
    onSetDefaultCurrentFolder,
    dynamicFolderId,
    myAccount?.folderTree?.id,
  ]);

  /**
   * Establishes a socket connection to get updated projects data for the current account.
   */
  useEffect(() => {
    if (currentAccount?.accountId) {
      getUpdatedProjectsToServer(
        currentAccount.accountId,
        onUpdateProjectsInFolder
      );
    }
  }, [currentAccount?.accountId, onUpdateProjectsInFolder]);

  return (
    <ContentContainer container xs={12}>
      <Header />
      <TableFolders />
    </ContentContainer>
  );
};

export default Folders;
