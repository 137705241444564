import { Box, Skeleton } from '@mui/material';
import React from 'react';

const TableSkeleton = () => {
  return (
    <Box
      sx={{
        height: 'max-content',
      }}
    >
      {[...Array(7)].map(value => (
        <Skeleton key={value} height={'35px'} sx={{ my: 0.5, mx: 1 }} />
      ))}
    </Box>
  );
};

export default TableSkeleton;
