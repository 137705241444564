import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React from 'react';
import { List, ListItemButton, useMediaQuery } from '@mui/material';
import { useEmailReports } from '../../../../../../../../hooks';
import { GrayButtonCustom } from '../../../../../../../../shared/buttons/styles';
import SettingsIcon from '../../../../../../../../shared/images/SettingsIcon';
import DownIcon from '../../../../../../../../shared/images/DownIcon';
import { Popover } from '../../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import {
  ListItemTypography,
  MainContainer,
  PopupLabel,
  ResetDefaultButton,
  StyledListItem,
} from '../../../../../../../projects/components/Projects/components/TableProjects/components/Header/components/PopupTableSettings/styles';
import { CustomCheckbox } from '../../../../../../../../shared/checkbox/styles';
import CustomCheckboxImage from '../../../../../../../../shared/images/CustomCheckbox';
import CheckedIcon from '../../../../../../../../shared/images/CheckedIcon';
import { renderListItemTitle } from './utils';
import { IColumnVisibilityKeyEmailReports } from '../../../../../../types';

const PopupTableSettings = () => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const {
    onSetColumnVisibilityModelEmailReports,
    onResetColumnVisibilityModelEmailReports,
    tables: {
      scheduledEmailReports: { columnVisibilityModel },
    },
  } = useEmailReports();

  const handleResetColumnVisibilityModel = () => {
    onResetColumnVisibilityModelEmailReports();
  };

  return (
    <>
      <PopupState variant='popover'>
        {popupState => (
          <>
            <GrayButtonCustom
              sx={{
                minWidth: extraSmallScreen ? '40px !important' : undefined,
              }}
              $padding={extraSmallScreen ? '9px 12px' : '7px 12px'}
              size='small'
              outline
              startIcon={
                !extraSmallScreen ? (
                  <SettingsIcon stroke='#344054' />
                ) : undefined
              }
              endIcon={
                !extraSmallScreen ? <DownIcon stroke='#344054' /> : undefined
              }
              {...bindTrigger(popupState)}
            >
              {!extraSmallScreen ? (
                'Table Settings'
              ) : (
                <SettingsIcon stroke='#344054' />
              )}
            </GrayButtonCustom>

            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MainContainer>
                <PopupLabel>Show or Hide Columns</PopupLabel>
                <ResetDefaultButton onClick={handleResetColumnVisibilityModel}>
                  Reset to default
                </ResetDefaultButton>
                <List>
                  {Object.keys(columnVisibilityModel).map(item => {
                    const columnKey = item as IColumnVisibilityKeyEmailReports;
                    return (
                      <StyledListItem
                        key={item}
                        disablePadding
                        checkBackgroundColor={
                          columnVisibilityModel[columnKey]
                            ? '#EEF3FF'
                            : undefined
                        }
                      >
                        <ListItemButton
                          onClick={() =>
                            onSetColumnVisibilityModelEmailReports(
                              columnKey,
                              !columnVisibilityModel[columnKey]
                            )
                          }
                        >
                          <CustomCheckbox
                            checked={columnVisibilityModel[columnKey]}
                            icon={<CustomCheckboxImage />}
                            checkedIcon={<CheckedIcon />}
                            disableFocusRipple
                          />

                          <ListItemTypography>
                            {renderListItemTitle(columnKey)}
                          </ListItemTypography>
                        </ListItemButton>
                      </StyledListItem>
                    );
                  })}
                </List>
              </MainContainer>
            </Popover>
          </>
        )}
      </PopupState>
    </>
  );
};

export default PopupTableSettings;
