import Grid from '@mui/material/Grid';
import React, { useMemo } from 'react';

import WelcomeProjectIcon from '../../../../../../shared/images/WelcomeProjectIcon';
import PlusIcon from '../../../../../../shared/images/PlusIcon';
import { NewProjectModal } from '../ProjectRedactorModal';

import { PrimaryButton } from '../../../../../../shared/buttons/styles';
import {
  WelcomeProjectWrapper,
  WelcomeProjectContent,
  WelcomeTitle,
  WelcomeText,
} from './styled';
import useAccountLimits from '../../../../../auth/hooks/useAccountLimits';
import { useAppSelector } from '../../../../../../store';

const WelcomeProject = () => {
  const { onCheckSubscriptionExpiration } = useAccountLimits();

  const [isOpenNewProjectModal, setIsOpenNewProjectModal] =
    React.useState(false);

  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const showNewProjectModal = useMemo(
    () => currentAccount.role.name !== 'ViewOnly',
    [currentAccount?.role?.name]
  );

  const handleOpenNewProjectModal = () => {
    const isExpiration = onCheckSubscriptionExpiration();
    if (!isExpiration) {
      setIsOpenNewProjectModal(true);
    }
  };
  const handleCloseNewProjectModal = () => setIsOpenNewProjectModal(false);

  return (
    <WelcomeProjectWrapper container xs={12}>
      <WelcomeProjectContent item xs={10} lg={4} md={8}>
        <WelcomeProjectIcon />
        <WelcomeTitle>Welcome to Serpnest! 🎉</WelcomeTitle>
        {showNewProjectModal && (
          <>
            <WelcomeText>
              Let’s get started by creating a new project:
            </WelcomeText>
            <Grid>
              <PrimaryButton
                variant='contained'
                startIcon={<PlusIcon />}
                onClick={handleOpenNewProjectModal}
              >
                Create New Project
              </PrimaryButton>
            </Grid>
          </>
        )}
      </WelcomeProjectContent>
      <NewProjectModal
        open={isOpenNewProjectModal}
        handleClose={handleCloseNewProjectModal}
      />
    </WelcomeProjectWrapper>
  );
};

export default WelcomeProject;
