import React, { useEffect, useState } from 'react';
import { Range, RangeKeyDict } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { StyledDateRangePicker, StyledOutlinedInput } from './styles';
import { Box, IconButton } from '@mui/material';
import CalendarIcon from '../../../../../../shared/images/CalendarIcon';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../../shared/buttons/styles';
import dayjs from 'dayjs';
import { CUSTOM_STATIC_RANGES } from '../../constants';
import { Popover } from '../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { projectExpandedFormatterDate } from '../../../../utils';

interface DateRangePickerProps {
  onChange: (range: Range) => void;
  rangeDate: Range;
}

const CustomDateRangePicker: React.FC<DateRangePickerProps> = ({
  rangeDate,
  onChange,
}) => {
  const [dateRange, setDateRange] = useState<Range>(rangeDate);

  const handleDateRangeChange = (rangesByKey: RangeKeyDict) => {
    setDateRange(rangesByKey.selection);
  };

  const handleSetInputData = () => {
    onChange(projectExpandedFormatterDate(dateRange));
  };

  useEffect(() => {
    setDateRange(rangeDate);
  }, [rangeDate]);

  return (
    <>
      <PopupState variant='popover'>
        {popupState => (
          <>
            <StyledOutlinedInput
              disabled={!rangeDate?.startDate && !rangeDate?.endDate}
              size='small'
              type='text'
              {...bindTrigger(popupState)}
              value={
                rangeDate?.startDate && rangeDate?.endDate
                  ? `${dayjs(rangeDate.startDate).format(
                      'MMM DD, YYYY'
                    )} vs ${dayjs(rangeDate.endDate).format('MMM DD, YYYY')}`
                  : 'Select Date'
              }
              startAdornment={
                <IconButton>
                  <CalendarIcon />
                </IconButton>
              }
            />
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <StyledDateRangePicker
                direction='horizontal'
                ranges={[dateRange]}
                onChange={handleDateRangeChange}
                footerContent={
                  <Box
                    sx={{
                      alignSelf: 'flex-end',
                      width: 'calc(100% - 16px)',
                      pl: '8px',
                      pr: '8px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                    }}
                  >
                    <GrayButtonSmall
                      size='small'
                      outline
                      onClick={popupState.close}
                    >
                      Cancel
                    </GrayButtonSmall>
                    <PrimaryButtonSmall
                      sx={{ ml: '8px' }}
                      variant='contained'
                      size='small'
                      onClick={() => {
                        handleSetInputData();
                        popupState.close();
                      }}
                    >
                      Apply
                    </PrimaryButtonSmall>
                  </Box>
                }
                staticRanges={CUSTOM_STATIC_RANGES}
                inputRanges={[]}
                months={2}
              />
            </Popover>
          </>
        )}
      </PopupState>
    </>
  );
};

export default CustomDateRangePicker;
