import {
  Box,
  Fade,
  Grid,
  Modal,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';

import { DeleteAccountStep2Props } from './types';
import Trash from '../../../../../../shared/images/Trash';
import { CustomCheckbox } from '../../../../../../shared/checkbox/styles';
import CustomCheckboxImage from '../../../../../../shared/images/CustomCheckbox';

import {
  HeaderContainer,
  CloseDeletePopoverOne,
  ButtonsWrapper,
  WarningFinishContainer,
  CustomModal,
  ModalContainer,
} from './styles';
import {
  ErrorButtonSmall,
  GrayButtonSmall,
} from '../../../../../../shared/buttons/styles';
import { CustomFormControlLabel } from './styles';
import CheckedIcon from '../../../../../../shared/images/CheckedIcon';
import CloseIcon from '@mui/icons-material/Close';

/**
 * DeleteAccountStep2 component displays the second step in the delete account process, confirming the user's intent to delete their account by typing "delete" and agreeing to the terms.
 */
const DeleteAccountStep2: React.FC<DeleteAccountStep2Props> = ({
  open,
  handleClose,
  confirmText,
  isLoadingDeleteAccount,
  handleBlur,
  handleChange,
  handleSubmit,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const [checked, setChecked] = useState(false);

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <CustomModal>
          <ModalContainer>
            <HeaderContainer>
              <CloseDeletePopoverOne color='error' onClick={handleClose}>
                <CloseIcon fontSize='small' />
              </CloseDeletePopoverOne>
              <Trash />
              <Typography variant='h6' fontWeight='600' paddingTop='29px'>
                Delete account?
              </Typography>
            </HeaderContainer>
            <WarningFinishContainer>
              <Typography variant='body2' lineHeight='24px' color='#475467'>
                Are you sure you want to delete your account? You won’t be able
                to recover once you delete it. All the data associated with it
                will be lost.
              </Typography>
            </WarningFinishContainer>
            <Typography
              fontWeight='500'
              fontSize='14px'
              lineHeight='20px'
              color='#475467'
              textAlign='center'
              paddingBottom='6px'
            >
              Type “delete” to confirm
            </Typography>
            <div>
              <Box>
                <OutlinedInput
                  size='small'
                  fullWidth
                  type='text'
                  id='confirmText'
                  name='confirmText'
                  value={confirmText}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Box>
            </div>
            <div>
              <CustomFormControlLabel
                control={
                  <CustomCheckbox
                    checked={checked}
                    onChange={event => setChecked(event.target.checked)}
                    icon={<CustomCheckboxImage />}
                    checkedIcon={<CheckedIcon />}
                  />
                }
                label='I confirm and authorize the permanent deletion of my account and all associated data.'
              />
            </div>
            <ButtonsWrapper container xs={12}>
              <Grid item xs={extraSmallScreen && 12}>
                <GrayButtonSmall
                  fullWidth={extraSmallScreen}
                  size='medium'
                  outline
                  onClick={handleClose}
                >
                  Cancel
                </GrayButtonSmall>
              </Grid>
              <Grid item xs={extraSmallScreen && 12}>
                <ErrorButtonSmall
                  fullWidth={extraSmallScreen}
                  disabled={
                    isLoadingDeleteAccount ||
                    confirmText !== 'delete' ||
                    !checked
                  }
                  onClick={() => handleSubmit()}
                  variant='contained'
                  color='error'
                >
                  Delete My Account
                </ErrorButtonSmall>
              </Grid>
            </ButtonsWrapper>
          </ModalContainer>
        </CustomModal>
      </Fade>
    </Modal>
  );
};

export default DeleteAccountStep2;
