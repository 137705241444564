import React, { useEffect } from 'react';
import { Outlet } from 'react-router';
import { useAppSelector } from '../../store';
import {
  onIdentify,
  onInit,
  onLogout,
  onPrefill,
  onSetConfig,
} from '../../utils/helpScout';

const HelpScoutLayout = () => {
  const user = useAppSelector(state => state.auth.user);

  useEffect(() => {
    if (window.Beacon) {
      setTimeout(() => {
        onSetConfig();
        onInit();
        onLogout();
        onPrefill();
        if (user?.helpScoutSignature) {
          onIdentify(user.username, user.email, user.helpScoutSignature);
        }
      }, 5000);
    }
  }, [user?.email, user?.helpScoutSignature, user?.username]);
  return <Outlet />;
};

export default HelpScoutLayout;
