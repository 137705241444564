import { Box } from '@mui/material';
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import { AccountInfoForm, AccountPasswordForm } from '../index';
import { useAuth } from '../../../../../../hooks';
import CustomToast from '../../../../../../shared/CustomToast/CustomToast';
import { closeSnackbarAction } from '../../../../../../utils/snackbar';
import AccountChangeEmailForm from './AccountChangeEmailForm/AccountChangeEmailForm';
import ConfirmYourEmailChangeModal from './AccountChangeEmailForm/ConfirmYourEmailChangeModal/ConfirmYourEmailChangeModal';
import { useAppSelector } from '../../../../../../store';

/**
 * Component representing the Account settings, allowing users to change account information, passwords, and emails.
 *
 * @component
 * @returns {JSX.Element} The Account component.
 */
const Account = () => {
  const { onChangeAccountSettings, onChangeEmail } = useAuth();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const myAccount = useAppSelector(state => state.auth.myAccount);

  const { enqueueSnackbar } = useSnackbar();

  const [openConfirmYourEmailChangeModal, setOpenConfirmYourEmailChangeModal] =
    React.useState(false);

  /**
   * Opens the modal for confirming email change.
   */
  const handleOpenConfirmYourEmailChangeModal = () => {
    setOpenConfirmYourEmailChangeModal(true);
  };

  /**
   * Closes the modal for confirming email change.
   */
  const handleCloseConfirmYourEmailChangeModal = () => {
    setOpenConfirmYourEmailChangeModal(false);
  };

  const initialValues = {
    firstName: myAccount.firstName,
    lastName: myAccount.lastName || '',
    country: myAccount.country,
    timezone: myAccount.timezone,
    currentPassword: '',
    newPassword: '',
    confirm_new_password: '',
    currentEmail: currentAccount.owner.ownerEmail,
    newEmail: '',
    confirmationPassword: '',
    accountInfoMessage: '',
    changeEmailMessage: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      newPassword: Yup.string().max(255),
      confirm_new_password: Yup.string().max(255),
      currentEmail: Yup.string().email('Invalid email address.'),
      newEmail: Yup.string()
        .email('Invalid email address.')
        .max(255)
        .notOneOf(
          [Yup.ref('currentEmail')],
          'The new email should not match the current one.'
        ),
    }),
    onSubmit: (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
      const requestData = {
        firstName: values.firstName,
        lastName: values.lastName,
        countryId: values?.country?.id?.toString(),
        timezoneId: values?.timezone?.id?.toString(),
        currentPassword: values.currentPassword || undefined,
        newPassword: values.newPassword || undefined,
      };

      onChangeAccountSettings({
        requestData,
        id: currentAccount.accountId,
        successFn: () => {
          enqueueSnackbar(
            <CustomToast
              message='Successfully updated account info'
              submessage='Account info changes have been saved.'
            />,
            { action: closeSnackbarAction }
          );
        },
        errorFn: (error: any) => {
          setStatus({ success: false });
          setSubmitting(false);
          setErrors(error);
        },
      });

      if (values.newEmail) {
        onChangeEmail({
          accountId: currentAccount.accountId,
          data: {
            currentEmail: values.currentEmail,
            newEmail: values.newEmail,
            confirmationPassword: values.confirmationPassword,
          },
          successFn: () => {
            resetForm({ values: { ...values } });
            handleOpenConfirmYourEmailChangeModal();
            enqueueSnackbar(
              <CustomToast
                message='Successfully updated email'
                submessage='Email changes have been saved.'
              />,
              { action: closeSnackbarAction }
            );
          },
          errorFn: (error: any) => {
            setStatus({ success: false });
            setSubmitting(false);
            setErrors(error);
          },
        });
      }
    },
  });

  const handleCloseAccountInfo = () => {
    formik.resetForm();
  };

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <AccountInfoForm
          values={formik.values}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          errors={formik.errors}
          touched={formik.touched}
          handleSubmit={formik.handleSubmit}
          setFieldValue={formik.setFieldValue}
          dirty={formik.dirty}
          isSubmitting={formik.isSubmitting}
          isValid={formik.isValid}
          handleCloseAccountInfo={handleCloseAccountInfo}
        />
        <AccountPasswordForm
          values={formik.values}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          errors={formik.errors}
          touched={formik.touched}
          handleSubmit={formik.handleSubmit}
          setFieldValue={formik.setFieldValue}
          dirty={formik.dirty}
          isSubmitting={formik.isSubmitting}
          isValid={formik.isValid}
          handleCloseAccountInfo={handleCloseAccountInfo}
        />
        <AccountChangeEmailForm
          values={formik.values}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          errors={formik.errors}
          touched={formik.touched}
          handleSubmit={formik.handleSubmit}
          setFieldValue={formik.setFieldValue}
          dirty={formik.dirty}
          isSubmitting={formik.isSubmitting}
          isValid={formik.isValid}
          handleCloseAccountInfo={handleCloseAccountInfo}
        />
      </form>
      <ConfirmYourEmailChangeModal
        open={openConfirmYourEmailChangeModal}
        handleClose={handleCloseConfirmYourEmailChangeModal}
      />
    </Box>
  );
};

export default Account;
