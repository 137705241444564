import { styled } from '@mui/material/styles';

import {
  TagsContainer as ProjectTagsContainer,
  TagsSearchContainer as ProjectTagsSearchContainer
} from "../../../../../../../../../projects/components/Projects/components/TableProjects/components/Header/components/PopupTags/styles";

const TagsContainer = styled(ProjectTagsContainer)``;

const TagsSearchContainer = styled(ProjectTagsSearchContainer)``;

export {
  TagsContainer,
  TagsSearchContainer,
};
