import React from 'react';
import { Grid, Skeleton } from '@mui/material';

const ImprovedVsDeclinedSkeleton = () => {
  return (
    <Grid
      container
      gap={1.5}
      alignItems={'center'}
      justifyContent={'space-between'}
      flexWrap={'nowrap'}
      width={'100%'}
      height={'100%'}
      padding={'15px'}
      overflow={'hidden'}
    >
      <Grid item>
        <Skeleton width='20px' height='350px' />
      </Grid>
      <Grid item>
        <Skeleton width='20px' height='300px' />
      </Grid>
      <Grid item>
        <Skeleton width='20px' height='320px' />
      </Grid>
      <Grid item>
        <Skeleton width='20px' height='140px' />
      </Grid>
      <Grid item>
        <Skeleton width='20px' height='350px' />
      </Grid>
      <Grid item>
        <Skeleton width='20px' height='300px' />
      </Grid>
      <Grid item>
        <Skeleton width='20px' height='320px' />
      </Grid>
      <Grid item>
        <Skeleton width='20px' height='140px' />
      </Grid>
      <Grid item>
        <Skeleton width='20px' height='350px' />
      </Grid>
      <Grid item>
        <Skeleton width='20px' height='300px' />
      </Grid>
      <Grid item>
        <Skeleton width='20px' height='320px' />
      </Grid>
      <Grid item>
        <Skeleton width='20px' height='140px' />
      </Grid>
      <Grid item>
        <Skeleton width='20px' height='350px' />
      </Grid>
      <Grid item>
        <Skeleton width='20px' height='300px' />
      </Grid>
      <Grid item>
        <Skeleton width='20px' height='320px' />
      </Grid>
      <Grid item>
        <Skeleton width='20px' height='140px' />
      </Grid>
      <Grid item>
        <Skeleton width='20px' height='350px' />
      </Grid>
      <Grid item>
        <Skeleton width='20px' height='300px' />
      </Grid>
      <Grid item>
        <Skeleton width='20px' height='320px' />
      </Grid>
      <Grid item>
        <Skeleton width='20px' height='140px' />
      </Grid>
    </Grid>
  );
};

export default ImprovedVsDeclinedSkeleton;
