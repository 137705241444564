import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { MaterialDesignContent } from 'notistack';
import { styled } from '@mui/material/styles';

import reportWebVitals from './reportWebVitals';
import store from './store';
import AppRoutes from './routes';
import CheckCircle from './shared/images/CheckCircle';
import ToastTrashIcon from './shared/images/ToastTrashIcon';

import './index.css';
import SnackbarCircleIcon from './shared/images/SnackbarCircleIcon';
import { ENABLE_MAINTENANCE_PAGE } from './constants/config';
import MaintenancePage from './pages/Maintenance';

/**
 * Custom styled Notistack content for showing notifications with specific styles.
 *
 * @component
 * @see {@link https://mui.com/system/styled/} for more information on MUI styled components.
 */
const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-default': {
    backgroundColor: '#2B2F34',
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#2B2F34',
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: '#2B2F34',
  },
}));

/**
 * Root element for rendering the React application.
 * Uses `ReactDOM.createRoot` to target the element with ID 'root'.
 *
 * @type {ReactDOM.Root}
 */
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

/**
 * Main application render.
 * Wraps the entire app with Redux `Provider` for state management, `BrowserRouter` for routing, and `SnackbarProvider` for notifications.
 * If the maintenance page is enabled via configuration, the `MaintenancePage` is displayed instead of the main routes.
 */
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <SnackbarProvider
        maxSnack={5} // Limit the number of visible snackbars to 5
        autoHideDuration={5000} // Auto hide snackbars after 5000ms (5 seconds)
        iconVariant={{
          default: <CheckCircle />, // Default icon for snackbars
          error: <ToastTrashIcon />, // Icon for error messages
          warning: <SnackbarCircleIcon />, // Icon for warning messages
        }}
        Components={{
          default: StyledMaterialDesignContent, // Use custom styled content for default messages
          error: StyledMaterialDesignContent, // Custom styled content for error messages
          warning: StyledMaterialDesignContent, // Custom styled content for warnings
        }}
      >
        {Number(ENABLE_MAINTENANCE_PAGE) ? <MaintenancePage /> : <AppRoutes />}
      </SnackbarProvider>
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
