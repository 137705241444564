import React, { memo } from 'react';
import { StyledBlockTitle, StyledWrapContainer } from '../../styles';
import { Divider, Grid, ListItemButton } from '@mui/material';
import { useAuth } from '../../../../../../hooks';
import { useNavigate } from 'react-router';
import ROUTES from '../../../../../../routes/constants';
import {
  ClearButton,
  StyledItemDomain,
  StyledItemName,
  StyledList,
  StyledListItem,
} from './styles';
import { encryptData } from '../../../../../../utils/cryptoJs';
import KeywordKeyIcon from '../../../../../images/KeywordKeyIcon';
import FolderIcon from '../../../../../images/FolderIcon';
import TagIcon from '../../../../../images/TagIcon';
import { useAppSelector } from '../../../../../../store';
import GlobeIcon from '../../../../../images/GlobeIcon';

interface SearchResultProps {
  onClose: () => void;
  value: string;
  filterBy: string;
}

const SearchResult: React.FC<SearchResultProps> = ({
  onClose,
  value,
  filterBy,
}) => {
  const navigate = useNavigate();

  const { onUserSearches, onGlobalSearch, onClearRecentlyViewed } = useAuth();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const globalSearch = useAppSelector(state => state.auth.globalSearch);

  const handleClickKeyword = (id: number, projectId: string) => {
    navigate(
      ROUTES.dynamic.keywordRankHistory(
        encryptData(projectId.toString()),
        encryptData(id.toString())
      )
    );
  };

  const handleClearRecentlyViewed = () => {
    const successFn = () => {
      if (currentAccount) {
        onGlobalSearch({
          accountId: currentAccount.accountId,
          search: value,
          sortBy: filterBy,
        });
      }
    };
    onClearRecentlyViewed({ successFn });
  };

  if (value.length < 4) return null;

  return (
    <>
      {globalSearch?.recentlyViewed?.length ? (
        <>
          <Grid>
            <Divider
              orientation='horizontal'
              flexItem
              sx={{
                color: '#EDEDED',
              }}
            />
          </Grid>
          <StyledWrapContainer>
            <Grid
              container
              xs={12}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <StyledBlockTitle item> Recently viewed </StyledBlockTitle>
              <ClearButton item onClick={handleClearRecentlyViewed}>
                Clear
              </ClearButton>
            </Grid>
            <StyledList>
              {globalSearch.recentlyViewed.map(item => (
                <StyledListItem key={item.name} disablePadding>
                  <ListItemButton
                    style={{ borderRadius: '8px' }}
                    onClick={() => {
                      onClose();
                      onUserSearches({
                        projectId: item.id,
                        successFn: () => {
                          if (currentAccount) {
                            onGlobalSearch({
                              accountId: currentAccount.accountId,
                              search: value,
                              sortBy: filterBy,
                            });
                          }
                        },
                      });
                      navigate(
                        ROUTES.dynamic.projectExpanded(
                          encryptData(item.id.toString())
                        )
                      );
                    }}
                  >
                    <Grid container gap={'8px'}>
                      <Grid item display={'flex'} alignItems={'center'}>
                        {item?.favicon ? (
                          <img
                            src={item.favicon}
                            width={16}
                            height={16}
                            alt={'Company Logo URL'}
                          />
                        ) : (
                          <GlobeIcon />
                        )}
                      </Grid>
                      <Grid item>
                        <Grid container spacing={1}>
                          <StyledItemName item>{item.name}</StyledItemName>
                          <StyledItemDomain
                            item
                          >{`(${item.domain})`}</StyledItemDomain>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItemButton>
                </StyledListItem>
              ))}
            </StyledList>
          </StyledWrapContainer>
        </>
      ) : null}
      {globalSearch?.projects?.length ? (
        <>
          <Grid>
            <Divider
              orientation='horizontal'
              flexItem
              sx={{
                color: '#EDEDED',
              }}
            />
          </Grid>
          <StyledWrapContainer>
            <Grid container xs={12} justifyContent={'space-between'}>
              <StyledBlockTitle item> Projects </StyledBlockTitle>
            </Grid>
            <Grid xs={12}>
              <StyledList>
                {globalSearch.projects.map(item => (
                  <>
                    <StyledListItem
                      key={item.name}
                      disablePadding
                      onClick={() => {
                        onClose();
                        onUserSearches({
                          projectId: item.id,
                          successFn: () => {
                            if (currentAccount) {
                              onGlobalSearch({
                                accountId: currentAccount.accountId,
                                search: value,
                                sortBy: filterBy,
                              });
                            }
                          },
                        });
                        navigate(
                          ROUTES.dynamic.projectExpanded(
                            encryptData(item.id.toString())
                          )
                        );
                      }}
                    >
                      <ListItemButton style={{ borderRadius: '8px' }}>
                        <Grid container gap={'8px'}>
                          <Grid item display={'flex'} alignItems={'center'}>
                            {item?.favicon ? (
                              <img
                                src={item.favicon}
                                width={16}
                                height={16}
                                alt={'Company Logo URL'}
                              />
                            ) : (
                              <GlobeIcon />
                            )}
                          </Grid>
                          <Grid item>
                            <Grid container spacing={1}>
                              <StyledItemName item>{item.name}</StyledItemName>
                              <StyledItemDomain
                                item
                              >{`(${item.domain})`}</StyledItemDomain>
                            </Grid>
                          </Grid>
                        </Grid>
                      </ListItemButton>
                    </StyledListItem>
                  </>
                ))}
              </StyledList>
            </Grid>
          </StyledWrapContainer>
        </>
      ) : null}
      {globalSearch?.keywords?.length ? (
        <>
          <Grid>
            <Divider
              orientation='horizontal'
              flexItem
              sx={{
                color: '#EDEDED',
              }}
            />
          </Grid>
          <StyledWrapContainer>
            <Grid container xs={12} justifyContent={'space-between'}>
              <StyledBlockTitle item> Keywords </StyledBlockTitle>
            </Grid>
            <Grid xs={12}>
              <StyledList>
                {globalSearch.keywords.map(item => (
                  <StyledListItem
                    key={item.name}
                    disablePadding
                    onClick={() => handleClickKeyword(item.id, item.projectId)}
                  >
                    <ListItemButton style={{ borderRadius: '8px' }}>
                      <Grid container gap={'8px'}>
                        <Grid item display={'flex'} alignItems={'center'}>
                          <KeywordKeyIcon />
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1}>
                            <StyledItemName item>{item.name}</StyledItemName>
                            <StyledItemDomain
                              item
                            >{`(${item.domain})`}</StyledItemDomain>
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItemButton>
                  </StyledListItem>
                ))}
              </StyledList>
            </Grid>
          </StyledWrapContainer>
        </>
      ) : null}
      {globalSearch?.folders?.length ? (
        <>
          <Grid>
            <Divider
              orientation='horizontal'
              flexItem
              sx={{
                color: '#EDEDED',
              }}
            />
          </Grid>
          <StyledWrapContainer>
            <Grid container xs={12} justifyContent={'space-between'}>
              <StyledBlockTitle item> Folders </StyledBlockTitle>
            </Grid>
            <Grid xs={12}>
              <StyledList>
                {globalSearch.folders.map(item => (
                  <StyledListItem key={item.name} disablePadding>
                    <ListItemButton
                      style={{ borderRadius: '8px' }}
                      onClick={() => {
                        onClose();
                        navigate(ROUTES.dynamic.folders(item.id.toString()));
                      }}
                    >
                      <Grid container gap={'8px'}>
                        <Grid item display={'flex'} alignItems={'center'}>
                          <FolderIcon />
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1}>
                            <StyledItemName item>{item.name}</StyledItemName>
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItemButton>
                  </StyledListItem>
                ))}
              </StyledList>
            </Grid>
          </StyledWrapContainer>
        </>
      ) : null}
      {globalSearch?.projectTags?.length ? (
        <>
          <Grid>
            <Divider
              orientation='horizontal'
              flexItem
              sx={{
                color: '#EDEDED',
              }}
            />
          </Grid>
          <StyledWrapContainer>
            <Grid container xs={12} justifyContent={'space-between'}>
              <StyledBlockTitle item> Project Tags </StyledBlockTitle>
            </Grid>
            <Grid xs={12}>
              <StyledList>
                {globalSearch.projectTags.map(item => (
                  <StyledListItem key={item.name} disablePadding>
                    <ListItemButton>
                      <Grid container gap={'8px'}>
                        <Grid item display={'flex'} alignItems={'center'}>
                          <TagIcon />
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1}>
                            <StyledItemName item>{item.name}</StyledItemName>
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItemButton>
                  </StyledListItem>
                ))}
              </StyledList>
            </Grid>
          </StyledWrapContainer>
        </>
      ) : null}
      {globalSearch?.projectKeywordTags?.length ? (
        <>
          <Grid>
            <Divider
              orientation='horizontal'
              flexItem
              sx={{
                color: '#EDEDED',
              }}
            />
          </Grid>
          <StyledWrapContainer>
            <Grid container xs={12} justifyContent={'space-between'}>
              <StyledBlockTitle item> Project Keyword Tags </StyledBlockTitle>
            </Grid>
            <Grid xs={12}>
              <StyledList>
                {globalSearch.projectKeywordTags.map(item => (
                  <StyledListItem key={item.name} disablePadding>
                    <ListItemButton style={{ borderRadius: '8px' }}>
                      <Grid container spacing={1}>
                        <StyledItemName item>{item.name}</StyledItemName>
                      </Grid>
                    </ListItemButton>
                  </StyledListItem>
                ))}
              </StyledList>
            </Grid>
          </StyledWrapContainer>
        </>
      ) : null}
    </>
  );
};

export default memo(SearchResult);
