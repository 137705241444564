import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const MainContainer = styled('div')`
  min-width: 158px;
  padding: 8px 16px;
`;

const StyledLinearLogSwitch = styled(Grid)`
  margin: 16px 8px;
  display: flex;
  justify-content: space-between;

  color: #1a1919;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
`;

export { MainContainer, StyledLinearLogSwitch };
