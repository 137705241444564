import React, { createContext, ReactNode, useContext, useState } from 'react';

const SelectProjectContext = createContext<any>(0);

export const SelectProjectProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isOpenNewProjectModal, setIsOpenNewProjectModal] =
    useState<boolean>(false);

  const [limit, setLimit] = useState<number>(12);

  const handleOpenNewProjectModal = () => setIsOpenNewProjectModal(true);
  const handleCloseNewProjectModal = () => setIsOpenNewProjectModal(false);

  const contextValue = {
    isOpenNewProjectModal,
    handleOpenNewProjectModal,
    handleCloseNewProjectModal,
    limit,
    setLimit,
  };

  return (
    <SelectProjectContext.Provider value={contextValue}>
      {children}
    </SelectProjectContext.Provider>
  );
};

export const useSelectProjectProvider = () => {
  return useContext(SelectProjectContext);
};
