import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import { styled, Theme, CSSObject } from '@mui/material/styles';

import { DRAWER_WIDTH } from './constant';
import { AppBarProps, BoxProps, DrawerProps, ListItemProps } from './types';
import { Box, ListItem as MuiListItem, ListItemText } from '@mui/material';

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: 0,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme, mobile: boolean): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: 0,
  }),
  overflowX: 'hidden',
  width: mobile ? '0' : `72px`,
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open, mobile, isFreeTrial }) => ({
  background: '#FFFFFF',
  boxShadow: 'none',
  borderBottom: '1px solid #E4E4E4',
  borderLeft: '1px solid #E4E4E4',
  width: `calc(100% - ${mobile ? 0 : 72}px)`,

  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: 0,
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: 0,
    }),
  }),

  top: isFreeTrial ? '60px' : '0',
  '@media (max-width:600px)': {
    top: isFreeTrial ? '90px' : '0',
  },
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})<DrawerProps>(({ theme, open, mobile, isFreeTrial }) => ({
  '.MuiDrawer-paperAnchorDockedLeft': {
    top: isFreeTrial ? '60px' : '0',
  },
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme, mobile),
    '& .MuiDrawer-paper': closedMixin(theme, mobile),
  }),
  '.MuiDrawer-paper': {
    borderBottom: '1px solid #E4E4E4',
    borderRightColor: '#E4E4E4',
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const ListItem = styled(MuiListItem)<ListItemProps>(
  ({ active }) => `
  svg {
    stroke:   ${active ? '#126FE9' : '#667085'};;
  }
  .MuiListItemButton-root {
    background-color: ${active ? '#E8F1FD' : 'white'};
    padding: 8px;
    margin: 0 16px;
    border-radius: 8px;
    justify-content: center;
    
    :hover {
      background-color: #F6F6F6;
    }
  }
  
 
`
);

const Content = styled(Box)<BoxProps>(({ theme, open, mobile }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',

  '> div': {
    margin: '16px',
    width: mobile
      ? 'calc(100% - 32px)'
      : open
      ? `calc(100% - 288px)`
      : 'calc(100% - 104px)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 0,
    }),

    '> div': {
      position: 'relative',
    },
  },
}));

const ContentTop = styled('div')<{ isFreeTrial: boolean }>(
  ({ theme, isFreeTrial }) => {
    const toolbar = {
      minHeight: 56,
      '@media (min-width:0px)': {
        '@media (orientation: landscape)': {
          minHeight: 48,
        },
      },
      '@media (min-width:600px)': {
        minHeight: 64,
      },
    };
    toolbar.minHeight = 56 + (isFreeTrial ? 90 : 0);
    toolbar['@media (min-width:600px)'].minHeight = 64 + (isFreeTrial ? 60 : 0);
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: theme.spacing(0, 1),
      ...toolbar,
      backgroundColor: '#FAFAFA',
    };
  }
);

const ContentBody = styled(Box)`
  background-color: white;
  border: 1px solid #e4e4e4;
  box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.04),
    0px 3px 2px -1px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  padding: 24px;
`;

const CustomListItemText = styled(ListItemText)<{ active: boolean }>`
  margin-left: 12px;
  color: ${props => (props.active ? `#126FE9` : '#101828')};

  .MuiTypography-root {
    font-size: 14px !important;
  }
`;

export {
  AppBar,
  Drawer,
  DrawerHeader,
  ListItem,
  Content,
  ContentTop,
  ContentBody,
  CustomListItemText,
};
