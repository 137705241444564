import useAlerts from '../../../../../../../../../../hooks/useAlerts';
import React from 'react';
import {
  PaginationContainer,
  StyledTablePagination,
} from '../../../../../../../../../../../projects/components/Projects/components/TableProjects/components/Pagination/styles';

const Pagination = () => {
  const {
    onSetPaginationPageAlertTriggered,
    onSetPaginationPageSizeAlertTriggered,
    alertKeywords,
    tables: {
      alertTriggered: { paginationModel },
    },
  } = useAlerts();

  const handleSetPaginationPageSize = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onSetPaginationPageSizeAlertTriggered(Number(e.target.value));
  };

  const handleSetPaginationPage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    onSetPaginationPageAlertTriggered(Number(page));
  };

  return (
    <PaginationContainer xs={12}>
      <StyledTablePagination
        labelRowsPerPage='Show rows:'
        count={alertKeywords?.meta?.totalItems || 0}
        page={paginationModel.page}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onPageChange={handleSetPaginationPage}
        rowsPerPage={paginationModel.pageSize}
        onRowsPerPageChange={handleSetPaginationPageSize}
      />
    </PaginationContainer>
  );
};

export default Pagination;
