import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNotes, useAllNotesTable } from '../../../../../../../../hooks';
import { Box, Grid } from '@mui/material';
import { CustomDataGrid } from '../../../../../../../auth/components/Settings/components/Team/components/customTableStyles';
import CheckboxWrapper from '../../../../../../../auth/components/Settings/components/Team/components/CheckboxWrapper';
import { CustomNoRowsOverlay } from '../';
import { arraysAreEqual } from '../../../../../../../../utils';
import { useAppSelector } from '../../../../../../../../store';
import { GridRowParams } from '@mui/x-data-grid';
import PlusIcon from '../../../../../../../../shared/images/PlusIcon';
import EmptyTable from '../../../../../../../../shared/components/EmptyTable/EmptyTable';
import AddNoteModal from '../../../../../../modals/NotesPage/AddNoteModal/AddNoteModal';
import useAccountLimits from '../../../../../../../auth/hooks/useAccountLimits';

const Table = () => {
  const { columns, columnsSkeleton } = useAllNotesTable();
  const {
    onSetRowSelectionModelAllNotes,
    onSetSortModelAllNotes,
    allNotesData,
    tables: { allNotes },
  } = useNotes();
  const { onCheckSubscriptionExpiration } = useAccountLimits();

  const openTariffPlanBanner = useAppSelector(
    state => state.auth.openTariffPlanBanner
  );

  const isRowSelectable = (params: GridRowParams) => {
    return params.row.editOption;
  };

  const [itemCount, setItemCount] = useState(
    allNotesData?.meta?.itemCount || allNotes.paginationModel.pageSize
  );
  const [isOpenNoteModal, setOpenNoteModal] = useState(false);

  const onOpenNoteModal = () => {
    const isExpiration = onCheckSubscriptionExpiration();
    if (!isExpiration) {
      setOpenNoteModal(true);
    }
  };

  const onCloseNoteModal = () => {
    setOpenNoteModal(false);
  };

  const arrayOfObjects = useMemo(
    () => Array.from({ length: itemCount }, (_, index) => ({ id: index + 1 })),
    [itemCount]
  );

  const rows = useMemo(() => {
    const arr1 = [...(allNotesData?.items?.length ? allNotesData.items : [])];
    const arr2 = [...allNotes.deleteElements];

    return arr1.filter(item => !arr2.includes(item.id));
  }, [allNotes.deleteElements, allNotesData?.items]);

  const renderRows = useMemo(() => {
    return !allNotesData?.items ? arrayOfObjects : rows;
  }, [arrayOfObjects, allNotesData?.items, rows]);

  const renderColumns = useMemo(() => {
    return !allNotesData?.items ? columnsSkeleton : columns;
  }, [columns, columnsSkeleton, allNotesData?.items]);

  const isShowActions = useMemo(() => {
    return rows.find(item => item.editOption);
  }, [rows]);

  useEffect(() => {
    if (allNotesData?.meta?.itemCount) {
      setItemCount(allNotesData.meta.itemCount);
    }
  }, [allNotesData?.meta?.itemCount]);

  useEffect(() => {
    const filtered = allNotesData?.items
      ?.filter(obj => allNotes.rowSelectionModel.includes(obj.id))
      ?.map(item => item.id)
      .filter(item => !allNotes.deleteElements.includes(item));
    if (filtered && !arraysAreEqual(allNotes.rowSelectionModel, filtered)) {
      onSetRowSelectionModelAllNotes(filtered);
    }
  }, [
    allNotes.deleteElements,
    onSetRowSelectionModelAllNotes,
    allNotesData?.items,
    allNotes.rowSelectionModel,
  ]);

  const pinnedColumns = useMemo(
    () => ({
      right: isShowActions ? ['actions'] : [],
    }),
    [isShowActions]
  );

  const NoRowsOverlay = useCallback(
    () =>
      !allNotesData?.items ? (
        <Box />
      ) : allNotes.search && !allNotesData?.items?.length ? (
        <CustomNoRowsOverlay />
      ) : !allNotesData?.items?.length ? (
        <EmptyTable
          buttonText={'Add Note'}
          handleButton={onOpenNoteModal}
          supportingText={'Start by clicking the ‘Add Note’ button.'}
          text={'You don’t have any notes'}
          startIcon={<PlusIcon />}
        />
      ) : (
        <Box />
      ),
    [allNotes.search, allNotesData?.items, onOpenNoteModal]
  );

  return (
    <>
      <Grid xs={12}>
        <CustomDataGrid
          pinnedColumns={pinnedColumns}
          columnHeaderHeight={40}
          rowHeight={40}
          autoHeight
          isRowSelectable={isRowSelectable}
          rows={renderRows}
          rowSelectionModel={allNotes.rowSelectionModel}
          onRowSelectionModelChange={onSetRowSelectionModelAllNotes}
          columns={renderColumns}
          onSortModelChange={onSetSortModelAllNotes}
          components={{
            BaseCheckbox: CheckboxWrapper,
            NoRowsOverlay: NoRowsOverlay,
          }}
          keepNonExistentRowsSelected
          hideFooterPagination
          checkboxSelection
          disableRowSelectionOnClick
          disableColumnMenu
          columnBuffer={100}
          columnThreshold={100}
          $openTariffPlanBanner={openTariffPlanBanner}
        />
      </Grid>
      <AddNoteModal open={isOpenNoteModal} onClose={onCloseNoteModal} />
    </>
  );
};

export default Table;
