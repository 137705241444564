import React, { useMemo } from 'react';

import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';

import ListActions from './components/ListActions';
import EllipsisListActions from '../EllipsisMenu/components/ListActions';
import DownIcon from '../../../../../../../../shared/images/DownIcon';

import { PrimaryButtonCustom } from '../../../../../../../../shared/buttons/styles';
import { Popover } from '../../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import { useAppSelector } from '../../../../../../../../store';

const ActionsMenu = () => {
  const rowSelectionModel = useAppSelector(
    state => state.projects.tables.projects.rowSelectionModel
  );

  const projects = useAppSelector(state => state.projects.projects);

  const projectsList = useMemo(() => projects?.items || [], [projects?.items]);

  const filteredProjects = useMemo(
    () =>
      projectsList.filter(project =>
        [rowSelectionModel[0]].includes(project.id)
      ),
    [projectsList, rowSelectionModel]
  );

  const totalKeywordsSum = useMemo(
    () =>
      filteredProjects.reduce((sum, project) => sum + project.totalKeywords, 0),
    [filteredProjects]
  );
  const disableExportCSV = useMemo(
    () => !filteredProjects.filter(project => project.updatedAt).length,
    [filteredProjects]
  );

  return (
    <PopupState variant='popover'>
      {popupState => (
        <>
          <PrimaryButtonCustom
            $padding='8px 12px'
            variant='contained'
            size='small'
            endIcon={<DownIcon />}
            {...bindTrigger(popupState)}
          >
            Actions
          </PrimaryButtonCustom>

          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {rowSelectionModel.length !== 1 ? (
              <ListActions popupClose={popupState.close} />
            ) : (
              <EllipsisListActions
                projectId={Number(rowSelectionModel[0])}
                keywordCount={totalKeywordsSum}
                disableExportCSV={disableExportCSV}
                popupClose={popupState.close}
              />
            )}
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export default ActionsMenu;
