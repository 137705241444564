const colors = {
  primaryColors: {
    base: [
      { background: '#FFFFFF', color: '#000000' },
      { background: '#000000', color: '#FFFFFF' },
    ],
    gray: [
      { background: '#FCFCFD', color: '#667085' },
      { background: '#F9FAFB', color: '#667085' },
      { background: '#F2F4F7', color: '#667085' },
      { background: '#EAECF0', color: '#667085' },
      { background: '#D0D5DD', color: '#FFFFFF' },
      { background: '#98A2B3', color: '#FFFFFF' },
      { background: '#667085', color: '#FFFFFF' },
      { background: '#475467', color: '#FFFFFF' },
      { background: '#344054', color: '#FFFFFF' },
      { background: '#1D2939', color: '#FFFFFF' },
      { background: '#101828', color: '#FFFFFF' },
      { background: '#0C111D', color: '#FFFFFF' },
    ],
    primary: [
      { background: '#F5FAFF', color: '#0E59BA' },
      { background: '#EFF8FF', color: '#0E59BA' },
      { background: '#D1E9FF', color: '#0E59BA' },
      { background: '#A0C5F6', color: '#0E59BA' },
      { background: '#71A9F2', color: '#FFFFFF' },
      { background: '#418CED', color: '#FFFFFF' },
      { background: '#2A7DEB', color: '#FFFFFF' },
      { background: '#126FE9', color: '#FFFFFF' },
      { background: '#0E59BA', color: '#FFFFFF' },
      { background: '#0B438C', color: '#FFFFFF' },
      { background: '#072C5D', color: '#FFFFFF' },
      { background: '#052146', color: '#FFFFFF' },
    ],
    error: [
      { background: '#FFFBFA', color: '#B42318' },
      { background: '#FEF3F2', color: '#B42318' },
      { background: '#FEE4E2', color: '#B42318' },
      { background: '#FECDCA', color: '#B42318' },
      { background: '#FDA29B', color: '#FFFFFF' },
      { background: '#F97066', color: '#FFFFFF' },
      { background: '#F04438', color: '#FFFFFF' },
      { background: '#D92D20', color: '#FFFFFF' },
      { background: '#B42318', color: '#FFFFFF' },
      { background: '#912018', color: '#FFFFFF' },
      { background: '#7A271A', color: '#FFFFFF' },
      { background: '#55160C', color: '#FFFFFF' },
    ],
    warning: [
      { background: '#FFFCF5', color: '#B54708' },
      { background: '#FFFAEB', color: '#B54708' },
      { background: '#FEF0C7', color: '#B54708' },
      { background: '#FEDF89', color: '#B54708' },
      { background: '#FEC84B', color: '#FFFFFF' },
      { background: '#FDB022', color: '#FFFFFF' },
      { background: '#F79009', color: '#FFFFFF' },
      { background: '#DC6803', color: '#FFFFFF' },
      { background: '#B54708', color: '#FFFFFF' },
      { background: '#93370D', color: '#FFFFFF' },
      { background: '#7A2E0E', color: '#FFFFFF' },
      { background: '#4E1D09', color: '#FFFFFF' },
    ],
    success: [
      { background: '#F6FEF9', color: '#067647' },
      { background: '#ECFDF3', color: '#067647' },
      { background: '#DCFAE6', color: '#067647' },
      { background: '#A9EFC5', color: '#067647' },
      { background: '#75E0A7', color: '#FFFFFF' },
      { background: '#47CD89', color: '#FFFFFF' },
      { background: '#17B26A', color: '#FFFFFF' },
      { background: '#079455', color: '#FFFFFF' },
      { background: '#067647', color: '#FFFFFF' },
      { background: '#085D3A', color: '#FFFFFF' },
      { background: '#074D31', color: '#FFFFFF' },
      { background: '#053321', color: '#FFFFFF' },
    ],
  },
};

const buttonColors = {
  primary: {
    solidBg: '#126FE9',
    solidHoverBg: '#0E59BA',
    solidDisabledColor: '#FFFFFF',
    solidDisabledBg: '#A0C5F6',
  },
};

const grayColors = {
  50: colors.primaryColors.gray[1].background,
  100: colors.primaryColors.gray[2].background,
  200: colors.primaryColors.gray[3].background,
  300: colors.primaryColors.gray[4].background,
  400: colors.primaryColors.gray[5].background,
  500: colors.primaryColors.gray[6].background,
  600: colors.primaryColors.gray[7].background,
  700: colors.primaryColors.gray[8].background,
  800: colors.primaryColors.gray[9].background,
  900: colors.primaryColors.gray[10].background,
};

const primaryColors = {
  50: colors.primaryColors.primary[1].background,
  100: colors.primaryColors.primary[2].background,
  200: colors.primaryColors.primary[3].background,
  300: colors.primaryColors.primary[4].background,
  400: colors.primaryColors.primary[5].background,
  500: colors.primaryColors.primary[6].background,
  600: colors.primaryColors.primary[7].background,
  700: colors.primaryColors.primary[8].background,
  800: colors.primaryColors.primary[9].background,
  900: colors.primaryColors.primary[10].background,
  main: colors.primaryColors.primary[7].background,
};

const errorColors = {
  50: colors.primaryColors.error[1].background,
  100: colors.primaryColors.error[2].background,
  200: colors.primaryColors.error[3].background,
  300: colors.primaryColors.error[4].background,
  400: colors.primaryColors.error[5].background,
  500: colors.primaryColors.error[6].background,
  600: colors.primaryColors.error[7].background,
  700: colors.primaryColors.error[8].background,
  800: colors.primaryColors.error[9].background,
  900: colors.primaryColors.error[10].background,
};

const warningColors = {
  50: colors.primaryColors.warning[1].background,
  100: colors.primaryColors.warning[2].background,
  200: colors.primaryColors.warning[3].background,
  300: colors.primaryColors.warning[4].background,
  400: colors.primaryColors.warning[5].background,
  500: colors.primaryColors.warning[6].background,
  600: colors.primaryColors.warning[7].background,
  700: colors.primaryColors.warning[8].background,
  800: colors.primaryColors.warning[9].background,
  900: colors.primaryColors.warning[10].background,
};

const successColors = {
  50: colors.primaryColors.success[1].background,
  100: colors.primaryColors.success[2].background,
  200: colors.primaryColors.success[3].background,
  300: colors.primaryColors.success[4].background,
  400: colors.primaryColors.success[5].background,
  500: colors.primaryColors.success[6].background,
  600: colors.primaryColors.success[7].background,
  700: colors.primaryColors.success[8].background,
  800: colors.primaryColors.success[9].background,
  900: colors.primaryColors.success[10].background,
};

const typography = {
  fontFamily: 'Inter, Arial',
  button: {
    lineHeight: '24px',
  },
};

const shadow = {
  xs: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  md: '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
  lg: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
  xl: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
};

export {
  colors,
  grayColors,
  primaryColors,
  errorColors,
  warningColors,
  successColors,
  buttonColors,
  typography,
  shadow,
};
