import React from 'react';

const PaginationChevronNextIcon = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='chevron-right'>
      <path
        id='Icon'
        d='M7.5 15L12.5 10L7.5 5'
        stroke='#344054'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);

export default PaginationChevronNextIcon;
