import { useAppDispatch, useAppSelector } from '../../../store';
import { useCallback, useMemo } from 'react';

import {
  exportToCsvKeywordPositionsInfoThunk,
  getKeywordInfoThunk,
  getKeywordPositionsInfoThunk,
  getSingleKeywordPositionHistoryThunk,
} from '../redux/thunk';
import {
  GetKeywordInfoParams,
  GetKeywordPositionsInfoParams,
} from '../types/keywordInfo';
import {
  ExportToCsvKeywordPositionsInfoParams,
  GetKeywordPositionHistoryParams,
} from '../types';
import {
  setActivePeriodKeywordRankHistory,
  setKeywordRankHistoryIsLinearGraph,
  setKeywordRankHistoryPaginationPage,
  setKeywordRankHistoryPaginationPageSize,
  setShowCompetitorsForKeywordRankHistory,
  setSortModelKeywordRankHistory,
} from '../redux/actions';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';

export default function useKeywordRankHistory() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(state => state.projects.keywordRankHistory);

  const memoizedState = useMemo(() => ({ ...state }), [state]);

  const onGetKeywordInfo = useCallback(
    (params: GetKeywordInfoParams) => {
      dispatch(getKeywordInfoThunk(params));
    },
    [dispatch]
  );

  const onGetSingleKeywordPositionHistory = useCallback(
    (params: GetKeywordPositionHistoryParams) => {
      dispatch(getSingleKeywordPositionHistoryThunk(params));
    },
    [dispatch]
  );

  const onSetActivePeriodKeywordRankHistory = useCallback(
    (params: { label: string; value: string }) => {
      dispatch(setActivePeriodKeywordRankHistory(params));
    },
    [dispatch]
  );

  const onGetKeywordPositionsInfo = useCallback(
    (params: GetKeywordPositionsInfoParams) => {
      dispatch(getKeywordPositionsInfoThunk(params));
    },
    [dispatch]
  );

  const onSetSortModelKeywordRankHistory = useCallback(
    (sortModel: GridSortModel) => {
      dispatch(setSortModelKeywordRankHistory(sortModel));
    },
    [dispatch]
  );

  const onSetKeywordRankHistoryPaginationPageSize = useCallback(
    (pageSize: number) => {
      dispatch(setKeywordRankHistoryPaginationPageSize(pageSize));
    },
    [dispatch]
  );

  const onSetKeywordRankHistoryPaginationPage = useCallback(
    (page: number) => {
      dispatch(setKeywordRankHistoryPaginationPage(page));
    },
    [dispatch]
  );

  const onSetKeywordRankHistoryIsLinearGraph = useCallback(
    (prams: boolean) => {
      dispatch(setKeywordRankHistoryIsLinearGraph(prams));
    },
    [dispatch]
  );

  const onExportToCsvKeywordPositionsInfo = useCallback(
    (params: ExportToCsvKeywordPositionsInfoParams) => {
      dispatch(exportToCsvKeywordPositionsInfoThunk(params));
    },
    [dispatch]
  );

  const onSetShowCompetitorsForKeywordRankHistory = useCallback(
    (showCompetitors: number[]) => {
      dispatch(setShowCompetitorsForKeywordRankHistory(showCompetitors));
    },
    [dispatch]
  );

  return {
    ...memoizedState,
    onGetKeywordInfo,
    onGetSingleKeywordPositionHistory,
    onSetActivePeriodKeywordRankHistory,
    onGetKeywordPositionsInfo,
    onSetSortModelKeywordRankHistory,
    onSetKeywordRankHistoryPaginationPageSize,
    onSetKeywordRankHistoryPaginationPage,
    onSetKeywordRankHistoryIsLinearGraph,
    onExportToCsvKeywordPositionsInfo,
    onSetShowCompetitorsForKeywordRankHistory,
  };
}
