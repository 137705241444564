import { styled } from '@mui/system';
import { Box } from '@mui/material';

const Wrapper = styled(Box)`
  width: 100%;
  min-height: calc(88vh - 40px);
  display: flex;
  justify-content: center;
  align-items: center;

  ${props => props.theme.breakpoints.down('sm')} {
    min-height: calc(80vh - 40px);
  }
`;

export { Wrapper };
