import React, { useMemo } from 'react';

import { Graph, Header, GraphLabels } from './components';
import { PositionHistoryContainer } from './styled';
import { PositionHistoryProps } from './types';
import { useAppSelector } from '../../../../../../../../store';

const PositionHistory: React.FC<PositionHistoryProps> = ({
  onClose,
  id,
  name,
}) => {
  const keywordsAnalytics = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.keywordsAnalytics
  );

  const keywordAnalytic = useMemo(() => {
    return keywordsAnalytics.find(item => item.id === id);
  }, [id, keywordsAnalytics]);

  const refGraph = React.useRef<HTMLInputElement>(null);

  return (
    <PositionHistoryContainer ref={refGraph} xs={12}>
      <Header
        refGraph={refGraph}
        onClose={onClose}
        id={id}
        name={name}
        keywordAnalytic={keywordAnalytic}
      />
      <Graph keywordAnalytic={keywordAnalytic} />
      {keywordAnalytic ? (
        <GraphLabels keywordAnalytic={keywordAnalytic} />
      ) : null}
    </PositionHistoryContainer>
  );
};

export default React.memo(PositionHistory);
