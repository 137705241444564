import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';

import { KeywordProps } from './types';
import ListActions from './components/ListActions';

import { Popover } from '../../../../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';

const EllipsisMenu: React.FC<KeywordProps> = ({ keyword }) => {
  return (
    <PopupState variant='popover'>
      {popupState => (
        <>
          <IconButton
            {...bindTrigger(popupState)}
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              bindTrigger(popupState).onClick(event);
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <ListActions
              keywordId={keyword.id}
              keywordName={keyword.name}
              popupClose={popupState.close}
            />
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export default React.memo(EllipsisMenu);
