import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  GetSharedKeywordInfoParams,
  GetSharedKeywordPositionsInfoParams,
} from '../../projects/types/keywordInfo';
import {
  getSharedKeywordInfoThunk,
  getSharedKeywordPositionsInfoThunk,
  getSharedSingleKeywordPositionHistoryThunk,
  sharedExportToCsvKeywordPositionsInfoThunk,
} from '../redux/thunk';
import {
  GetSharedKeywordPositionHistoryParams,
  SharedExportToCsvKeywordPositionsInfoParams,
} from '../../projects/types';
import {
  setSharedActivePeriodKeywordRankHistory,
  setSharedKeywordRankHistoryIsLinearGraph,
  setSharedKeywordRankHistoryPaginationPage,
  setSharedKeywordRankHistoryPaginationPageSize,
  setSharedShowCompetitorsForKeywordRankHistory,
  setSharedSortModelKeywordRankHistory,
} from '../redux/actions';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';

export default function useSharedKeywordRankHistory() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(state => state.sharedLinks.keywordRankHistory);

  const memoizedState = useMemo(() => ({ ...state }), [state]);

  const onGetSharedKeywordInfo = useCallback(
    (params: GetSharedKeywordInfoParams) => {
      dispatch(getSharedKeywordInfoThunk(params));
    },
    [dispatch]
  );

  const onGetSharedSingleKeywordPositionHistory = useCallback(
    (params: GetSharedKeywordPositionHistoryParams) => {
      dispatch(getSharedSingleKeywordPositionHistoryThunk(params));
    },
    [dispatch]
  );

  const onSetSharedActivePeriodKeywordRankHistory = useCallback(
    (params: { label: string; value: string }) => {
      dispatch(setSharedActivePeriodKeywordRankHistory(params));
    },
    [dispatch]
  );

  const onSetSharedKeywordRankHistoryIsLinearGraph = useCallback(
    (prams: boolean) => {
      dispatch(setSharedKeywordRankHistoryIsLinearGraph(prams));
    },
    [dispatch]
  );

  const onSetSharedShowCompetitorsForKeywordRankHistory = useCallback(
    (showCompetitors: number[]) => {
      dispatch(setSharedShowCompetitorsForKeywordRankHistory(showCompetitors));
    },
    [dispatch]
  );

  const onGetSharedKeywordPositionsInfo = useCallback(
    (params: GetSharedKeywordPositionsInfoParams) => {
      dispatch(getSharedKeywordPositionsInfoThunk(params));
    },
    [dispatch]
  );

  const onSharedExportToCsvKeywordPositionsInfo = useCallback(
    (params: SharedExportToCsvKeywordPositionsInfoParams) => {
      dispatch(sharedExportToCsvKeywordPositionsInfoThunk(params));
    },
    [dispatch]
  );

  const onSetSharedSortModelKeywordRankHistory = useCallback(
    (sortModel: GridSortModel) => {
      dispatch(setSharedSortModelKeywordRankHistory(sortModel));
    },
    [dispatch]
  );

  const onSetKeywordRankHistoryPaginationPageSize = useCallback(
    (pageSize: number) => {
      dispatch(setSharedKeywordRankHistoryPaginationPageSize(pageSize));
    },
    [dispatch]
  );

  const onSetKeywordRankHistoryPaginationPage = useCallback(
    (page: number) => {
      dispatch(setSharedKeywordRankHistoryPaginationPage(page));
    },
    [dispatch]
  );

  return {
    ...memoizedState,
    onGetSharedKeywordInfo,
    onGetSharedSingleKeywordPositionHistory,
    onSetSharedActivePeriodKeywordRankHistory,
    onSetSharedKeywordRankHistoryIsLinearGraph,
    onSetSharedShowCompetitorsForKeywordRankHistory,
    onGetSharedKeywordPositionsInfo,
    onSharedExportToCsvKeywordPositionsInfo,
    onSetSharedSortModelKeywordRankHistory,
    onSetKeywordRankHistoryPaginationPageSize,
    onSetKeywordRankHistoryPaginationPage,
  };
}
