import React, { useMemo } from 'react';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { PrimaryButtonCustom } from '../../../../../../shared/buttons/styles';
import DownIcon from '../../../../../../shared/images/DownIcon';
import { Popover } from '../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import ListActions from './components/ListActions';
import EllipsisListActions from '../EllipsisMenu/ListActions';
import { useEmailReports } from '../../../../../../hooks';

const ActionsMenu = () => {
  const {
    emailReports,
    tables: {
      scheduledEmailReports: { rowSelectionModel },
    },
  } = useEmailReports();

  const firstEmailReport = useMemo(() => {
    if (emailReports?.items.length && rowSelectionModel[0]) {
      return emailReports.items.find(item => item.id === rowSelectionModel[0]);
    }
  }, [emailReports?.items, rowSelectionModel]);

  return (
    <PopupState variant='popover'>
      {popupState => (
        <>
          <PrimaryButtonCustom
            $padding='8px 12px'
            variant='contained'
            size='small'
            endIcon={<DownIcon />}
            {...bindTrigger(popupState)}
          >
            Actions
          </PrimaryButtonCustom>

          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {rowSelectionModel.length !== 1 ? (
              <ListActions popupClose={popupState.close} />
            ) : (
              <EllipsisListActions
                popupClose={popupState.close}
                id={firstEmailReport?.id as number}
              />
            )}
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export default ActionsMenu;
