import { socket } from '../../constants/socketConfig';
import { KeywordRanking, Project } from './types';

const getUpdatedKeywordsToServer = (
  accountId: number,
  successFn: (data: KeywordRanking[]) => void
) => {
  socket.off(`accounts/${accountId}/updated-keywords`);
  socket.on(
    `accounts/${accountId}/updated-keywords`,
    (data: KeywordRanking[]) => {
      successFn(data);
    }
  );
};

const getUpdatedProjectsToServer = (
  accountId: number,
  successFn: (data: Project[]) => void
) => {
  socket.off(`accounts/${accountId}/updated-projects`);
  socket.on(`accounts/${accountId}/updated-projects`, (data: Project[]) => {
    successFn(data);
  });
};

const getUpdateProjectGraphicsToServer = (
  accountId: number,
  successFn: (projectIds: number[]) => void
) => {
  socket.off(`accounts/${accountId}/update-project-graphics`);
  socket.on(
    `accounts/${accountId}/update-project-graphics`,
    (data: { projectIds: number[] }) => {
      successFn(data.projectIds);
    }
  );
};

const getStartOfKeywordUpdateToServer = (
  accountId: number,
  successFn: (data: number[]) => void
) => {
  socket.off(`accounts/${accountId}/start-of-keyword-update`);
  socket.on(
    `accounts/${accountId}/start-of-keyword-update`,
    (data: number[]) => {
      successFn(data);
    }
  );
};

const getRemoteProjectsToServer = (
  accountId: number,
  successFn: (data: number[]) => void
) => {
  socket.off(`accounts/${accountId}/remote-projects`);
  socket.on(
    `accounts/${accountId}/remote-projects`,
    (data: { projectIds: number[] }) => {
      successFn(data.projectIds);
    }
  );
};

export {
  getUpdatedKeywordsToServer,
  getUpdatedProjectsToServer,
  getUpdateProjectGraphicsToServer,
  getStartOfKeywordUpdateToServer,
  getRemoteProjectsToServer,
};
