import React from 'react';

const EditPencilIcon = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.0003 13.9996H8.66701M1.66699 14.3329L5.36651 12.91C5.60313 12.819 5.72145 12.7735 5.83214 12.7141C5.93046 12.6613 6.02419 12.6004 6.11235 12.532C6.21161 12.4549 6.30124 12.3653 6.48051 12.186L14.0003 4.66622C14.7367 3.92984 14.7367 2.73593 14.0003 1.99955C13.264 1.26317 12.0701 1.26317 11.3337 1.99955L3.81385 9.51936C3.63458 9.69864 3.54494 9.78827 3.46792 9.88752C3.39951 9.97569 3.33859 10.0694 3.28582 10.1677C3.2264 10.2784 3.18089 10.3967 3.08988 10.6334L1.66699 14.3329ZM1.66699 14.3329L3.03907 10.7655C3.13726 10.5102 3.18635 10.3826 3.27055 10.3241C3.34414 10.273 3.43519 10.2537 3.52319 10.2705C3.62388 10.2898 3.72058 10.3865 3.91398 10.5799L5.42004 12.0859C5.61344 12.2793 5.71014 12.376 5.72937 12.4767C5.74617 12.5647 5.72685 12.6558 5.67576 12.7293C5.61729 12.8135 5.48965 12.8626 5.23437 12.9608L1.66699 14.3329Z'
      stroke='#667085'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default EditPencilIcon;
