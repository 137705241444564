import React, { useEffect, useMemo, useState } from 'react';
import useAlertTriggeredTable from '../../../../../../../../hooks/useAlertTriggeredTable';
import { CustomDataGrid } from '../../../../../../../../../auth/components/Settings/components/Team/components/customTableStyles';
import CheckboxWrapper from '../../../../../../../../../auth/components/Settings/components/Team/components/CheckboxWrapper';
import { Box, Grid } from '@mui/material';
import useAlerts from '../../../../../../../../hooks/useAlerts';
import { Pagination } from './components';
import { TableNoData } from '../../../../../../../../../../shared/components';
import { useAppSelector } from '../../../../../../../../../../store';

const AlertTriggeredTable = () => {
  const {
    onSetSortModelAlertTriggered,
    alertKeywords,
    tables: {
      alertTriggered: {
        paginationModel: { pageSize },
      },
    },
  } = useAlerts();

  const { columns, columnsSkeleton } = useAlertTriggeredTable();

  const openTariffPlanBanner = useAppSelector(
    state => state.auth.openTariffPlanBanner
  );

  const [itemCount, setItemCount] = useState(
    alertKeywords?.meta?.itemCount || pageSize
  );

  const arrayOfObjects = useMemo(
    () =>
      Array.from({ length: itemCount }, (_, index) => ({
        alertKeywordId: index + 1,
      })),
    [itemCount]
  );

  const rows = useMemo(
    () => (alertKeywords?.items?.length ? alertKeywords.items : []),
    [alertKeywords?.items]
  );

  const renderRows = useMemo(() => {
    return !alertKeywords?.items ? arrayOfObjects : rows;
  }, [arrayOfObjects, alertKeywords?.items, rows]);

  const renderColumns = useMemo(() => {
    return !alertKeywords?.items ? columnsSkeleton : columns;
  }, [columns, columnsSkeleton, alertKeywords?.items]);

  useEffect(() => {
    if (alertKeywords?.meta?.itemCount) {
      setItemCount(alertKeywords.meta.itemCount);
    }
  }, [alertKeywords?.meta?.itemCount]);

  return (
    <>
      <Grid xs={12}>
        <CustomDataGrid
          columnHeaderHeight={40}
          rowHeight={40}
          rows={renderRows}
          columns={renderColumns}
          onSortModelChange={onSetSortModelAlertTriggered}
          getRowId={row => row.alertKeywordId}
          components={{
            BaseCheckbox: CheckboxWrapper,
            NoRowsOverlay: !alertKeywords ? Box : TableNoData,
          }}
          keepNonExistentRowsSelected
          hideFooterPagination
          disableRowSelectionOnClick
          disableColumnMenu
          $isModalTable={true}
          $openTariffPlanBanner={openTariffPlanBanner}
        />
      </Grid>
      <Pagination />
    </>
  );
};

export default AlertTriggeredTable;
