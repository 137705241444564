import React from 'react';

type Props = {
  checkedColor?: string;
  checkedMarkColor?: string;
};

const CheckedIcon: React.FC<Props> = ({
  checkedColor = '#126FE9',
  checkedMarkColor = 'white',
}) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g>
      <rect x='3' y='3' width='18' height='18' rx='4' fill={checkedColor} />
    </g>
    <path
      d='M17 8L11 16L7 12'
      stroke={checkedMarkColor}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <defs>
      <filter
        id='filter0_dd_3057_184787'
        x='0'
        y='0'
        width='22'
        height='22'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feBlend
          in='SourceGraphic'
          in2='effect2_dropShadow_3057_184787'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
);

export default CheckedIcon;
