import React, { useState } from 'react';
import { MoveToFolderProjectsAction } from './types';
import {
  ListItemTypography,
  StyledListItem,
  StyledListItemButton,
} from '../styles';
import MoveTo from '../../../../../../MoveTo/MoveTo';
import FolderIcon from '../../../../../../../../../shared/images/FolderIcon';
import { useAuth, useProjects } from '../../../../../../../../../hooks';
import { useAppSelector } from '../../../../../../../../../store';

const MoveToFolderAction: React.FC<MoveToFolderProjectsAction> = ({
  projectIds,
  popupClose,
}) => {
  const { onGetMyAccount } = useAuth();
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const { onMoveProjectsToFolder, onGetProjects, onSetRowSelectionModel } =
    useProjects();

  const columnVisibilityModel = useAppSelector(
    state => state.projects.tables.projects.columnVisibilityModel
  );
  const frequencyFilter = useAppSelector(
    state => state.projects.tables.projects.frequencyFilter
  );
  const sortModel = useAppSelector(
    state => state.projects.tables.projects.sortModel
  );
  const search = useAppSelector(state => state.projects.tables.projects.search);
  const paginationModel = useAppSelector(
    state => state.projects.tables.projects.paginationModel
  );
  const selectedTags = useAppSelector(state => state.projects.selectedTags);

  const [isOpenMoveToFolder, setIsOpenMoveToFolder] = useState(false);

  const handleOpenMoveToFolder = () => {
    setIsOpenMoveToFolder(true);
  };
  const handleCloseMoveToFolder = () => {
    onSetRowSelectionModel([]);
    setIsOpenMoveToFolder(false);
    popupClose();
  };

  const handleMove = (folderId: number) => {
    if (currentAccount?.accountId)
      onMoveProjectsToFolder({
        id: currentAccount.accountId,
        folderId: folderId,
        projectIds: projectIds,
        successFn: () => {
          onGetProjects({
            accountId: currentAccount.accountId,
            params: {
              frequencyName: frequencyFilter,
              page: paginationModel.page,
              limit: paginationModel.pageSize,
              tagIds: selectedTags.map(item => item.id).join(),
              search: search,
              sortBy: sortModel[0]?.field,
              sortOrder: sortModel[0]?.sort,
              dailyAverage: columnVisibilityModel.dailyAverage,
            },
          });
          onGetMyAccount(currentAccount.accountId);
          handleCloseMoveToFolder();
        },
      });
  };

  return (
    <>
      <StyledListItem onClick={handleOpenMoveToFolder}>
        <StyledListItemButton>
          <FolderIcon />
          <ListItemTypography marginLeft='8px'>
            Move to Folder
          </ListItemTypography>
        </StyledListItemButton>
      </StyledListItem>
      {isOpenMoveToFolder && (
        <MoveTo
          isOpen={isOpenMoveToFolder}
          handleClose={handleCloseMoveToFolder}
          handleMove={handleMove}
          type={'moveProject'}
          folderErrorMessage={''}
        />
      )}
    </>
  );
};

export default MoveToFolderAction;
