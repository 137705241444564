import {
  Box,
  Grid,
  IconButton,
  List,
  ListItemButton,
  SwipeableDrawer,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link, Outlet } from 'react-router-dom';

import SlideRight from '../../images/SlideRight';
import SlideLeft from '../../images/SlideLeft';
import SerpNestLogo from '../../images/SerpNestLogo';
import { DRAWER_ITEMS_CONFIG, DRAWER_LIST } from './constant';
import { getPathIndex } from './utils';
import Breadcrumbs from '../../../shared/Breadcrumbs/Breadcrumbs';
import { MyAccount } from './components';
import { AccountDeletionScheduledBanner } from '../../../shared/components';
import { Footer } from './components';
import { DrawerItemsConfigType } from './types';
import FolderIcon from '../../images/FolderIcon';
import { CustomListItemText } from './styles';
import {
  AppBar,
  Content,
  Drawer,
  DrawerHeader,
  ListItem,
  ContentTop,
  ContentBody,
} from './styles';
import { useAuth, useFolders } from '../../../hooks';
import FoldersDrawer from '../../../features/projects/components/FoldersDrawer/FoldersDrawer';
import ROUTES from '../../../routes/constants';
import KeywordLimitSidebar from '../../../features/auth/components/KeywordLimitSidebar/KeywordLimitSidebar';
import TopSearchbarExpand from '../../TopSearchbarExpand/TopSearchbarExpand';
import TextTooltip from '../../components/TextTooltip';
import { CustomTooltip } from '../../tooltip/styles';
import {
  getYourFreeTrialEndsBanner,
  hideYourFreeTrialEndsBanner,
} from '../../../utils/localStorage';
import TrialBanners from '../../components/TrialBanners/TrialBanners';
import dayjs from 'dayjs';
import { useAppSelector } from '../../../store';
import {
  checkCancelSubscription,
  setFolderTreeSocket,
  updatedAllAccountLimitsSocket,
  updatedUserSocket,
  updatePaymentMethodSocket,
} from '../../../features/auth/socket';

const PrivateLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const mediumScreen = useMediaQuery('(max-width:900px)');
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const {
    onSeOpenSiteBar,
    onSetOpenTariffPlanBanner,
    onSetAccountBillingCard,
    onGetAccountLimit,
    onUpdatedUser,
    onCheckCancelSubscriptionAction,
    onSetFolderTree,
  } = useAuth();
  const { onSetDefaultCurrentFolder } = useFolders();

  const isAuth = useAppSelector(state => state.auth.isAuth);
  const billing = useAppSelector(state => state.auth.payment.billing);
  const openSiteBar = useAppSelector(state => state.auth.openSiteBar);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const myAccount = useAppSelector(state => state.auth.myAccount);
  const user = useAppSelector(state => state.auth.user);
  const openTariffPlanBanner = useAppSelector(
    state => state.auth.openTariffPlanBanner
  );

  const isFreeTrial = useMemo(
    () => billing?.tariffPlan?.name === 'Trial Period',
    [billing?.tariffPlan?.name]
  );

  const getIsViewDrawerItem = useCallback(
    (index: number) => {
      if (isAuth) {
        if (currentAccount?.role?.name === 'ViewOnly') {
          return index === 1 || index === 3 || index === 5;
        }
        if (currentAccount?.role?.name === 'Addon') {
          return index === 5;
        }
      } else {
        return true;
      }
    },
    [currentAccount?.role?.name, isAuth]
  );

  const daysRemaining = useMemo(() => {
    const subscriptionExpirationDate = billing?.subscriptionExpirationDate
      ? dayjs(billing.subscriptionExpirationDate)
      : undefined;

    if (subscriptionExpirationDate) {
      const daysRemaining = subscriptionExpirationDate.diff(dayjs(), 'day');
      if (daysRemaining > 0) return daysRemaining;
    }
    return 0;
  }, [billing?.subscriptionExpirationDate]);

  useEffect(() => {
    onSeOpenSiteBar(!mediumScreen);
  }, []);

  const [openSwipeableDrawer, setOpenopenSwipeableDrawer] =
    React.useState(false);

  const [selectedIndex, setSelectedIndex] = useState(getPathIndex(pathname));

  const [openFreeTrialBanner, setOpenFreeTrialBanner] = useState(
    !getYourFreeTrialEndsBanner()
  );

  const onCloseFreeTrialBanner = () => {
    hideYourFreeTrialEndsBanner();
    setOpenFreeTrialBanner(false);
  };

  const handleDrawerOpen = () => {
    onSeOpenSiteBar(true);
  };

  const handleDrawerClose = () => {
    onSeOpenSiteBar(false);
  };

  const handleSwipeableDrawerOpen = () => {
    setOpenopenSwipeableDrawer(true);
  };

  const handleSwipeableDrawerClose = () => {
    setOpenopenSwipeableDrawer(false);
  };

  const handleListItemClick = (index: number, route: string) => {
    setSelectedIndex(index);
    if (index === 6 && myAccount?.folderTree?.id) {
      const mainFolder = { id: myAccount.folderTree.id, name: 'My Folders' };
      onSetDefaultCurrentFolder(mainFolder);
    }
    navigate(route);
    handleSwipeableDrawerClose();
  };

  useEffect(() => {
    setSelectedIndex(
      DRAWER_ITEMS_CONFIG[
        (pathname.split('/')[1] as DrawerItemsConfigType) || 0
      ]
    );
  }, [pathname]);

  const DrawerList = useCallback(() => {
    return (
      <Box
        sx={{
          minWidth: mediumScreen ? '256px' : 0,
        }}
      >
        <List
          sx={{
            display: 'flex',
            gap: `${openSiteBar || mediumScreen ? '0' : '8px'}`,
            flexDirection: 'column',
          }}
        >
          {DRAWER_LIST.map(({ title, Icon, link }, index) => (
            <ListItem
              key={title}
              disablePadding
              active={selectedIndex === index}
              onClick={() => handleListItemClick(index, link)}
              style={
                getIsViewDrawerItem(index) ? { display: 'none' } : undefined
              }
            >
              {openSiteBar || mediumScreen ? (
                <ListItemButton>
                  {Icon(selectedIndex === index)}
                  <CustomListItemText
                    active={selectedIndex === index}
                    primary={title}
                  />
                </ListItemButton>
              ) : (
                <CustomTooltip
                  title={<TextTooltip text={title} />}
                  placement='right'
                  arrow
                >
                  <ListItemButton>
                    {Icon(selectedIndex === index)}
                  </ListItemButton>
                </CustomTooltip>
              )}
            </ListItem>
          ))}
          {!(open || mediumScreen) ? (
            <ListItem
              disablePadding
              active={selectedIndex === 6}
              onClick={() => handleListItemClick(6, ROUTES.rootFolders)}
            >
              <ListItemButton>
                <FolderIcon
                  stroke={selectedIndex === 6 ? '#126FE9' : undefined}
                />
              </ListItemButton>
            </ListItem>
          ) : null}
        </List>
      </Box>
    );
  }, [handleListItemClick, mediumScreen, open, selectedIndex]);

  useEffect(() => {
    if (billing) {
      const planEndsIn =
        billing.tariffPlan.name === 'Trial Period' &&
        daysRemaining <= 14 &&
        openFreeTrialBanner;

      const trialDeactivated =
        billing.tariffPlan.name === 'Trial Period' &&
        billing.status.name === 'deactivated';

      const planDeactivated =
        billing.tariffPlan.name !== 'Trial Period' &&
        billing.status.name === 'deactivated';

      const planCanceled =
        billing.tariffPlan.name !== 'Trial Period' &&
        billing.status.name === 'canceled';

      onSetOpenTariffPlanBanner(
        planEndsIn || trialDeactivated || planDeactivated || planCanceled
      );
    } else {
      onSetOpenTariffPlanBanner(false);
    }
  }, [billing, daysRemaining, onSetOpenTariffPlanBanner, openFreeTrialBanner]);

  useEffect(() => {
    if (currentAccount?.accountId) {
      updatePaymentMethodSocket(
        currentAccount.accountId,
        onSetAccountBillingCard
      );
      updatedAllAccountLimitsSocket(currentAccount.accountId, updateLimits => {
        updateLimits && onGetAccountLimit({ id: currentAccount.accountId });
      });
      checkCancelSubscription(currentAccount.accountId, billing => {
        onCheckCancelSubscriptionAction(billing);
      });
    }
  }, [currentAccount?.accountId]);

  useEffect(() => {
    if (user?.id) {
      updatedUserSocket(user.id, user => onUpdatedUser(user));
    }
  }, [onUpdatedUser, user?.id]);

  useEffect(() => {
    if (currentAccount?.accountId && user?.id) {
      setFolderTreeSocket(currentAccount.accountId, user.id, data => {
        onSetFolderTree(data);
      });
    }
  }, [currentAccount?.accountId, user?.id]);

  return (
    <>
      <SwipeableDrawer
        anchor={'left'}
        open={openSwipeableDrawer}
        onClose={handleSwipeableDrawerClose}
        onOpen={handleSwipeableDrawerOpen}
      >
        <DrawerList />
        {
          <FoldersDrawer
            handleSwipeableDrawerClose={handleSwipeableDrawerClose}
          />
        }
        <KeywordLimitSidebar
          handleSwipeableDrawerClose={handleSwipeableDrawerClose}
        />
      </SwipeableDrawer>
      <Box sx={{ background: '#FAFAFA', minHeight: '100vh' }}>
        {openTariffPlanBanner && billing && (
          <TrialBanners
            onCloseYourFreeTrialEndsBanner={onCloseFreeTrialBanner}
            daysRemaining={daysRemaining}
            tariffPlanName={billing.tariffPlan.name}
            tariffPlanStatus={billing.status.name}
            lastTransactionStatus={billing?.lastTransactionStatus?.name}
          />
        )}
        <AppBar
          isFreeTrial={openTariffPlanBanner}
          open={openSiteBar}
          mobile={mediumScreen}
        >
          <Toolbar>
            {mediumScreen && (
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={handleSwipeableDrawerOpen}
                edge='start'
                sx={{
                  marginRight:
                    !openSwipeableDrawer && extraSmallScreen ? '12px' : 5,
                  ...(openSiteBar && { display: 'none' }),
                }}
              >
                {mediumScreen ? <SlideRight /> : <SlideLeft />}
              </IconButton>
            )}
            <Grid container gap={'24px'} alignItems={'center'}>
              {!openSwipeableDrawer && extraSmallScreen && (
                <Link to={ROUTES.projects} style={{ height: '20px' }}>
                  <SerpNestLogo />
                </Link>
              )}
              <Grid item alignSelf={'flex-end'}>
                <TopSearchbarExpand
                  isFreeTrial={
                    isFreeTrial && (daysRemaining <= 0 || openFreeTrialBanner)
                  }
                />
              </Grid>
            </Grid>
            <Box sx={{ flexGrow: 1 }} />
            <MyAccount />
          </Toolbar>
        </AppBar>
        <Drawer
          variant='permanent'
          open={openSiteBar}
          mobile={mediumScreen}
          isFreeTrial={openTariffPlanBanner}
        >
          <DrawerHeader>
            <IconButton
              sx={{ ml: '8px', mr: '16px' }}
              onClick={
                openSiteBar
                  ? handleDrawerClose
                  : mediumScreen
                  ? handleSwipeableDrawerOpen
                  : handleDrawerOpen
              }
            >
              {!open ? <SlideRight /> : <SlideLeft />}
            </IconButton>
            {openSiteBar ? (
              <Link to={ROUTES.projects}>
                <SerpNestLogo />
              </Link>
            ) : null}
          </DrawerHeader>
          <DrawerList />
          {
            <Grid sx={{ display: openSiteBar ? 'block' : 'none' }}>
              <FoldersDrawer
                handleSwipeableDrawerClose={handleSwipeableDrawerClose}
              />
            </Grid>
          }
          {openSiteBar && (
            <KeywordLimitSidebar
              handleSwipeableDrawerClose={handleSwipeableDrawerClose}
            />
          )}
        </Drawer>
        <Content open={openSiteBar} mobile={mediumScreen}>
          <div>
            <div>
              <ContentTop isFreeTrial={openTariffPlanBanner} />
              <AccountDeletionScheduledBanner />
              {pathname.split('/')[1] !== 'projects' &&
              pathname.split('/')[1] !== 'folders' ? (
                <ContentBody>
                  {pathname !== `${ROUTES.notFound}` && (
                    <>
                      <Breadcrumbs />
                    </>
                  )}
                  <Outlet />
                </ContentBody>
              ) : (
                <Outlet />
              )}
            </div>
            {myAccount?.id && <Footer />}
          </div>
        </Content>
      </Box>
    </>
  );
};

export default PrivateLayout;
