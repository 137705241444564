import React, { useState } from 'react';
import {
  Fade,
  Grid,
  Modal,
  Typography,
  useMediaQuery,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import {
  ModalContainer,
  ModalWrapper,
} from '../../Projects/components/ProjectRedactorModal/styles';
import { CloseDeletePopoverOne } from '../../../../auth/components/Settings/components/Account/styles';
import { ButtonsWrapper } from '../../../../auth/components/Settings/components/Team/components/customTableStyles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../shared/buttons/styles';
import { ExportGraphModalProps } from './types';
import { StyledExportGraphLabel } from './styles';
import PNGIcon from '../../../../../shared/images/PNGIcon';
import PDFIcon from '../../../../../shared/images/PDFIcon';
import { downloadPDForPNG } from '../../../../../utils/downloadPDForPNG';
import CloseIcon from '@mui/icons-material/Close';

const ExportGraphModal: React.FC<ExportGraphModalProps> = ({
  onClose,
  open,
  refGraph,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const [exportValue, setExportValue] = useState('PNG');

  const onChangeRadioGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExportValue(event.target.value);
  };

  const handleClose = () => {
    onClose();
  };

  const onExport = () => {
    downloadPDForPNG(refGraph, exportValue).then(() => onClose());
  };

  return (
    <Modal
      aria-labelledby='ExportGraphModal'
      aria-describedby='ExportGraphModal'
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <ModalWrapper>
          <ModalContainer container xs={12} sx={{ gap: '8px' }}>
            <Grid xs={12}>
              <Grid item xs={12} sx={{ position: 'relative' }}>
                <CloseDeletePopoverOne onClick={handleClose}>
                  <CloseIcon fontSize='small' />
                </CloseDeletePopoverOne>
                <Typography
                  fontSize='18px'
                  fontWeight='600'
                  lineHeight='28px'
                  color='#101828'
                >
                  Export
                </Typography>
              </Grid>

              <Grid xs={12} padding={'20px 0'}>
                <RadioGroup
                  aria-labelledby='ExportGraphModal'
                  value={exportValue}
                  name='ExportGraphModal'
                  onChange={onChangeRadioGroup}
                >
                  <FormControlLabel
                    value='PNG'
                    control={<Radio size='small' />}
                    label={
                      <StyledExportGraphLabel>
                        <PNGIcon />
                        <span style={{ paddingLeft: '8px' }}>PNG</span>
                      </StyledExportGraphLabel>
                    }
                  />
                  <FormControlLabel
                    value='PDF'
                    control={<Radio size='small' />}
                    label={
                      <StyledExportGraphLabel>
                        <PDFIcon />
                        <span style={{ paddingLeft: '8px' }}>PDF</span>
                      </StyledExportGraphLabel>
                    }
                  />
                </RadioGroup>
              </Grid>

              <ButtonsWrapper container xs={12} sx={{ paddingTop: '32px' }}>
                <Grid item xs={extraSmallScreen && 12}>
                  <GrayButtonSmall
                    fullWidth={extraSmallScreen}
                    size='small'
                    outline
                    onClick={handleClose}
                  >
                    Cancel
                  </GrayButtonSmall>
                </Grid>
                <Grid item xs={extraSmallScreen && 12}>
                  <PrimaryButtonSmall
                    fullWidth={extraSmallScreen}
                    variant='contained'
                    size='small'
                    type='submit'
                    // disabled={formik.isSubmitting || !formik.isValid}
                    onClick={onExport}
                  >
                    Export
                  </PrimaryButtonSmall>
                </Grid>
              </ButtonsWrapper>
            </Grid>
          </ModalContainer>
        </ModalWrapper>
      </Fade>
    </Modal>
  );
};

export default ExportGraphModal;
