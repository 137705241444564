import { Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Header, AlertsTable } from './components';
import useAlerts from '../../hooks/useAlerts';
import { useAppSelector } from '../../../../store';
import { useLocation } from 'react-router';
import AlertTriggeredModal from './components/AlertsTable/components/AlertTriggeredModal/AlertTriggeredModal';

type TTypeQuery = 'by Project' | 'by Keywords' | null;

/**
 * Alerts Component
 *
 * This component is responsible for displaying the alerts page, including the header, alerts table, and alert triggered modal.
 * It manages the state and fetches alerts data based on user actions and query parameters.
 */
const Alerts = () => {
  const searchParams = new URLSearchParams(useLocation().search);

  const typeQuery = searchParams.get('type') as TTypeQuery;
  const projectIdsQuery = searchParams.get('projectIds');
  const alertIdQuery = searchParams.get('alertId');

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const {
    onSetSelectByAlerts,
    onSetViewAlertByProject,
    onGetProjectsByAlerts,
    onSetActiveProjectForFilterAlerts,
    onGetAlertsByProject,
    onGetAlertsByKeywords,
    onResetAlertsState,
    activeProjectForFilterAlerts,
    tables: {
      alerts: { paginationModel, sortModel, search },
    },
  } = useAlerts();

  const [selectBy, setSelectBy] = useState<'by Project' | 'by Keywords'>(
    typeQuery || 'by Project'
  );

  useEffect(() => {
    onSetSelectByAlerts(selectBy);
  }, [onSetSelectByAlerts, selectBy]);

  const [isFirstRender, setIsFirstRender] = useState(true);

  const [isOpenModal, setIsOpenModal] = useState(false);

  /**
   * Closes the Alert Triggered Modal
   */
  const handleClose = () => {
    setIsOpenModal(false);
  };

  /**
   * Opens the Alert Triggered Modal
   *
   * Checks if there is a current account and sets the modal open state to true.
   * Calls the onSetViewAlertByProject function to set the alert view by project.
   */
  const handleOpen = () => {
    if (currentAccount?.accountId) {
      setIsOpenModal(true);
      onSetViewAlertByProject({
        id: currentAccount.accountId,
        alertId: Number(alertIdQuery),
        successFn: () => {
          console.log('successFn');
        },
      });
    }
  };

  useEffect(() => {
    return () => setIsFirstRender(true);
  }, []);

  useEffect(() => {
    if (typeQuery && alertIdQuery) {
      handleOpen();
    }
  }, [typeQuery, alertIdQuery, currentAccount?.accountId]);

  useEffect(() => {
    if (projectIdsQuery && typeQuery && currentAccount?.accountId) {
      onGetProjectsByAlerts({
        id: currentAccount.accountId,
        selectBy: typeQuery,
      });
      onSetActiveProjectForFilterAlerts(projectIdsQuery);
    }
  }, [
    onSetActiveProjectForFilterAlerts,
    onGetProjectsByAlerts,
    projectIdsQuery,
    currentAccount?.accountId,
  ]);

  useEffect(() => {
    onResetAlertsState();
  }, [onResetAlertsState, selectBy]);

  /**
   * Fetches alerts data based on the selected type (by Project or by Keywords).
   */
  const handleGetAlerts = useCallback(
    (isChangeType?: boolean) => {
      if (selectBy === 'by Project') {
        onGetAlertsByProject({
          id: currentAccount.accountId,
          projectIds: activeProjectForFilterAlerts
            ? [activeProjectForFilterAlerts]
            : [],
          page: paginationModel.page,
          limit: paginationModel.pageSize,
          search: search,
          sortBy: sortModel[0]?.field,
          sortOrder: sortModel[0]?.sort,
          isChangeType: isChangeType,
        });
      }
      if (selectBy === 'by Keywords') {
        onGetAlertsByKeywords({
          id: currentAccount.accountId,
          projectIds: activeProjectForFilterAlerts
            ? [activeProjectForFilterAlerts]
            : [],
          page: paginationModel.page,
          limit: paginationModel.pageSize,
          search: search,
          sortBy: sortModel[0]?.field,
          sortOrder: sortModel[0]?.sort,
        });
      }
    },
    [
      currentAccount?.accountId,
      selectBy,
      onGetAlertsByProject,
      activeProjectForFilterAlerts,
      paginationModel.page,
      paginationModel.pageSize,
      search,
      sortModel[0]?.field,
      sortModel[0]?.sort,
      onGetAlertsByKeywords,
    ]
  );

  useEffect(() => {
    if (currentAccount?.accountId) {
      handleGetAlerts();
      setIsFirstRender(false);
    }
  }, [
    paginationModel.page,
    paginationModel.pageSize,
    currentAccount?.accountId,
  ]);

  useEffect(() => {
    if (!isFirstRender) {
      handleGetAlerts(true);
    }
  }, [selectBy]);

  useEffect(() => {
    if (sortModel[0]?.field && sortModel[0]?.sort && !isFirstRender) {
      handleGetAlerts();
    }
  }, [sortModel[0]?.field, sortModel[0]?.sort]);

  useEffect(() => {
    if (!isFirstRender) {
      handleGetAlerts();
    }
  }, [search]);

  useEffect(() => {
    if (activeProjectForFilterAlerts && !isFirstRender) {
      handleGetAlerts();
    }
  }, [activeProjectForFilterAlerts]);

  return (
    <>
      <Grid xs={12}>
        <Header selectBy={selectBy} setSelectBy={setSelectBy} />
        <AlertsTable selectBy={selectBy} />
      </Grid>
      {alertIdQuery ? (
        <AlertTriggeredModal
          handleClose={handleClose}
          open={isOpenModal}
          triggerId={Number(alertIdQuery)}
        />
      ) : null}
    </>
  );
};

export default Alerts;
