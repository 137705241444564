import CryptoJS from 'crypto-js';
import { CRYPTO_JS_SECRET_KEY } from '../constants/config';
import ROUTES from '../routes/constants';

/**
 * Encrypts a string using the RC4 algorithm and a secret key.
 * The result is processed to replace slashes (`/`) with underscores (`_`) for URL-safe usage.
 *
 * @param {string} data - The string to encrypt.
 * @returns {string} The encrypted string with URL-safe encoding.
 *
 * @example
 * const encrypted = encryptData('mySecretData');
 * console.log(encrypted); // Outputs encrypted and URL-safe string
 */
const encryptData = (data: string): string => {
  const encrypted = CryptoJS.RC4.encrypt(data, CRYPTO_JS_SECRET_KEY).toString();
  return encrypted.replace(/\//g, '_');
};

/**
 * Decrypts a previously encrypted string using the RC4 algorithm and a secret key.
 * Reverses the URL-safe encoding by replacing underscores (`_`) back with slashes (`/`).
 * If decryption fails, the user is redirected to a "Not Found" page.
 *
 * @param {string} encryptedData - The string to decrypt, expected in URL-safe format.
 * @returns {string} The decrypted original string, or an empty string if decryption fails.
 *
 * @throws Will redirect to the "Not Found" page if decryption fails.
 *
 * @example
 * const decrypted = decryptData(encrypted);
 * console.log(decrypted); // Outputs the original decrypted string
 */
const decryptData = (encryptedData: string): string => {
  const processedEncryptedData = encryptedData.replace(/_/g, '/');
  const bytes = CryptoJS.RC4.decrypt(
    processedEncryptedData,
    CRYPTO_JS_SECRET_KEY
  );

  try {
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (e) {
    window.location.href = `${window.location.origin}${ROUTES.notFound}`;
  }

  return '';
};

export { encryptData, decryptData };
