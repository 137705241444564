import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

const StyledHeader = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
`;

export { StyledHeader };
