import { createAction } from '@reduxjs/toolkit';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';

const setProjectNotesPageSize = createAction(
  'setProjectNotesPageSize',
  function prepare(pageSize: number) {
    return {
      payload: pageSize,
    };
  }
);

const setProjectNotesPaginationPage = createAction(
  'setProjectNotesPaginationPage',
  function prepare(page: number) {
    return {
      payload: page,
    };
  }
);

const setSortModelProjectNotes = createAction(
  'setSortModelProjectNotes',
  function prepare(sortModel: GridSortModel) {
    return {
      payload: sortModel,
    };
  }
);

const setRowSelectionModelProjectNotes = createAction(
  'setRowSelectionModelProjectNotes',
  function prepare(rowSelectionModel: GridRowSelectionModel) {
    return {
      payload: rowSelectionModel,
    };
  }
);

const setSearchProjectNotes = createAction(
  'setSearchProjectNotes',
  function prepare(value: string) {
    return {
      payload: value,
    };
  }
);

const setAllNotesPageSize = createAction(
  'setAllNotesPageSize',
  function prepare(pageSize: number) {
    return {
      payload: pageSize,
    };
  }
);

const setAllNotesPaginationPage = createAction(
  'setAllNotesPaginationPage',
  function prepare(page: number) {
    return {
      payload: page,
    };
  }
);

const setSortModelAllNotes = createAction(
  'setSortModelAllNotes',
  function prepare(sortModel: GridSortModel) {
    return {
      payload: sortModel,
    };
  }
);

const setRowSelectionModelAllNotes = createAction(
  'setRowSelectionModelAllNotes',
  function prepare(rowSelectionModel: GridRowSelectionModel) {
    return {
      payload: rowSelectionModel,
    };
  }
);

const setSearchAllNotes = createAction(
  'setSearchAllNotes',
  function prepare(value: string) {
    return {
      payload: value,
    };
  }
);

const resetNotesState = createAction('resetNotesState');

const setDeleteElementsAllNotesTable = createAction(
  'setDeleteElementsAllNotesTable',
  function prepare(deleteElements: GridRowSelectionModel) {
    return {
      payload: { deleteElements },
    };
  }
);

const setDeleteElementsProjectNotesTable = createAction(
  'setDeleteElementsProjectNotesTable',
  function prepare(deleteElements: GridRowSelectionModel) {
    return {
      payload: { deleteElements },
    };
  }
);

export {
  setProjectNotesPageSize,
  setProjectNotesPaginationPage,
  setSortModelProjectNotes,
  setRowSelectionModelProjectNotes,
  setSearchProjectNotes,
  setAllNotesPageSize,
  setAllNotesPaginationPage,
  setSortModelAllNotes,
  setRowSelectionModelAllNotes,
  setSearchAllNotes,
  resetNotesState,
  setDeleteElementsAllNotesTable,
  setDeleteElementsProjectNotesTable,
};
