import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const CustomSwitchContainer = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 26px;
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export { CustomSwitchContainer };
