import { AUTH_GOOGLE_URL } from './constants';

/**
 * Opens a new window for Google authentication.
 *
 * This function calculates the center position of the window based on the
 * current screen dimensions and opens a new window with the specified size
 * for Google authentication.
 *
 * @returns {Window | undefined} The reference to the newly opened window,
 * or `undefined` if the window couldn't be opened.
 */
function openGoogleAuthWindow() {
  const screenWidth = window.screen.width; // Get the width of the screen
  const screenHeight = window.screen.height; // Get the height of the screen

  // Calculate the position for centering the window
  const left = Math.round((screenWidth - 500) / 2);
  const top = Math.round((screenHeight - 600) / 2);

  // Define the window features for opening
  const windowFeatures = `width=${500},height=${600},left=${left},top=${top}`;

  // Open a new window for Google authentication
  const newWindow = window.open(AUTH_GOOGLE_URL, '_blank', windowFeatures);

  // Focus on the new window if it was successfully opened
  if (newWindow) {
    newWindow.focus();
    return newWindow; // Return the reference to the new window
  }
}

export { openGoogleAuthWindow };
