import React, { useState } from 'react';
import { EllipsisActionsProps } from '../types';
import { useProjects } from '../../../../../../../../../../../hooks';

import { ListItemTypography, StyledListItem } from '../styles';
import GraphIcon from '../../../../../../../../../../../shared/images/GraphIcon';
import { StyledListItemButton } from '../../../../../../../../Projects/components/TableProjects/components/EllipsisMenu/styles';
import KeywordsAnalyticsModal from '../../../../../../../../modals/KeywordsAnalyticsModal/KeywordsAnalyticsModal';
import { useAppSelector } from '../../../../../../../../../../../store';

const ShowOnGraphAction: React.FC<EllipsisActionsProps> = ({
  keyword,
  popupClose,
}) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const {
    onCloseKeywordPositionHistory,
    onGetKeywordPositionHistory,
    onSetRowSelectionKeywordsModel,
  } = useProjects();

  const activeKeywordTrends = useAppSelector(
    state => state.projects.projectExpandedGraphs.activeKeywordTrends
  );
  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );

  const [openModal, setOpenModal] = useState(false);
  const [activeKeywordId, setActiveKeywordId] = useState<number | null>(null);
  const [activeKeywordName, setActiveKeywordName] = useState<string | null>(
    null
  );

  const onClose = () => {
    if (activeKeywordId) {
      onCloseKeywordPositionHistory({ id: activeKeywordId });
      setOpenModal(false);
      setActiveKeywordId(null);
      setActiveKeywordName(null);
    }
    onSetRowSelectionKeywordsModel([]);
    popupClose();
  };

  const handleClick = () => {
    if (projectExpanded) {
      setOpenModal(true);
      setActiveKeywordId(keyword.id);
      setActiveKeywordName(keyword.name);
      onGetKeywordPositionHistory({
        accountId: currentAccount.accountId,
        keywordId: keyword.id,
        projectId: projectExpanded.id,
        period: activeKeywordTrends.value,
        competitorIds: projectExpanded.competitors.map(item => item.id),
      });
    }
  };

  return (
    <>
      <StyledListItem onClick={handleClick}>
        <StyledListItemButton>
          <GraphIcon />
          <ListItemTypography marginLeft='8px' sx={{ color: '#101828' }}>
            Show on Graph
          </ListItemTypography>
        </StyledListItemButton>
      </StyledListItem>
      {activeKeywordId && activeKeywordName && (
        <KeywordsAnalyticsModal
          id={activeKeywordId}
          name={activeKeywordName}
          onClose={onClose}
          open={openModal}
        />
      )}
    </>
  );
};

export default React.memo(ShowOnGraphAction);
