import React from 'react';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { closeAndUndoSnackbarAction } from '../../../../../../../../../../../utils/snackbar';
import CustomToast from '../../../../../../../../../../../shared/CustomToast/CustomToast';
import {
  ListItemTypography,
  StyledListItem,
  StyledListItemButton,
} from '../../../../../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/styles';
import SmallTrashIcon from '../../../../../../../../../../../shared/images/SmallTrashIcom';
import { useAuth } from '../../../../../../../../../../../hooks';
import useAlerts from '../../../../../../../../../hooks/useAlerts';
import { useAppSelector } from '../../../../../../../../../../../store';

interface DeleteActionProps {
  popupClose: () => void;
  selectBy: 'by Project' | 'by Keywords';
}

const DeleteAction: React.FC<DeleteActionProps> = ({
  popupClose,
  selectBy,
}) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const {
    onSetDeleteElementsTriggersTable,
    onBulkDeleteTriggersByProjects,
    onBulkDeleteTriggersByKeywords,
    onGetTriggersByProjects,
    onGetTriggersByKeywords,
    onSetRowSelectionModelTriggers,
    tables: {
      triggers: { paginationModel, sortModel, rowSelectionModel },
    },
  } = useAlerts();

  const handleHardDelete = () => {
    if (currentAccount?.accountId) {
      if (selectBy === 'by Project') {
        onBulkDeleteTriggersByProjects({
          id: currentAccount.accountId,
          ids: rowSelectionModel as number[],
          successFn: () => {
            onGetTriggersByProjects({
              id: currentAccount.accountId,
              page: paginationModel.page,
              limit: paginationModel.pageSize,
              sortBy: sortModel[0]?.field,
              sortOrder: sortModel[0]?.sort,
            });
            onSetRowSelectionModelTriggers([]);
            onSetDeleteElementsTriggersTable([]);
            popupClose();
          },
        });
      }
      if (selectBy === 'by Keywords') {
        onBulkDeleteTriggersByKeywords({
          id: currentAccount.accountId,
          ids: rowSelectionModel as number[],
          successFn: () => {
            onGetTriggersByKeywords({
              id: currentAccount.accountId,
              page: paginationModel.page,
              limit: paginationModel.pageSize,
              sortBy: sortModel[0]?.field,
              sortOrder: sortModel[0]?.sort,
            });
            onSetRowSelectionModelTriggers([]);
            onSetDeleteElementsTriggersTable([]);
            popupClose();
          },
        });
      }
    }
  };

  const handleDeleteOne = () => {
    onSetDeleteElementsTriggersTable(rowSelectionModel);
    popupClose();
    let undoDelete = false;
    const onUndo = () => {
      undoDelete = true;
      onSetDeleteElementsTriggersTable([]);
    };
    enqueueSnackbar(
      <CustomToast
        message='Triggers Removed'
        submessage={`Triggers has been successfully removed.`}
      />,
      {
        action: (snackbarId: SnackbarKey) =>
          closeAndUndoSnackbarAction(snackbarId, onUndo),
        variant: 'error',
      }
    );
    setTimeout(() => {
      if (!undoDelete) {
        handleHardDelete();
      }
    }, 5000);
  };

  return (
    <StyledListItem onClick={handleDeleteOne}>
      <StyledListItemButton>
        <SmallTrashIcon />
        <ListItemTypography marginLeft='8px' sx={{ color: '#B42318' }}>
          Delete Triggers
        </ListItemTypography>
      </StyledListItemButton>
    </StyledListItem>
  );
};

export default DeleteAction;
