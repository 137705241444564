import React from 'react';

const PaletteIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <g clipPath='url(#clip0_2905_186653)'>
        <path
          d='M1.33301 8.00065C1.33301 11.6826 4.31778 14.6673 7.99967 14.6673C9.10424 14.6673 9.99967 13.7719 9.99967 12.6673V12.334C9.99967 12.0244 9.99967 11.8696 10.0168 11.7396C10.1349 10.8421 10.8412 10.1359 11.7386 10.0178C11.8686 10.0007 12.0234 10.0007 12.333 10.0007H12.6663C13.7709 10.0007 14.6663 9.10522 14.6663 8.00065C14.6663 4.31875 11.6816 1.33398 7.99967 1.33398C4.31778 1.33398 1.33301 4.31875 1.33301 8.00065Z'
          stroke='#667085'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.66634 8.66732C5.03453 8.66732 5.33301 8.36884 5.33301 8.00065C5.33301 7.63246 5.03453 7.33398 4.66634 7.33398C4.29815 7.33398 3.99967 7.63246 3.99967 8.00065C3.99967 8.36884 4.29815 8.66732 4.66634 8.66732Z'
          stroke='#667085'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.6663 6.00065C11.0345 6.00065 11.333 5.70217 11.333 5.33398C11.333 4.96579 11.0345 4.66732 10.6663 4.66732C10.2982 4.66732 9.99967 4.96579 9.99967 5.33398C9.99967 5.70217 10.2982 6.00065 10.6663 6.00065Z'
          stroke='#667085'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.66634 5.33398C7.03453 5.33398 7.33301 5.03551 7.33301 4.66732C7.33301 4.29913 7.03453 4.00065 6.66634 4.00065C6.29815 4.00065 5.99967 4.29913 5.99967 4.66732C5.99967 5.03551 6.29815 5.33398 6.66634 5.33398Z'
          stroke='#667085'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2905_186653'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PaletteIcon;
