import React, { useState } from 'react';
import { Grid, SwipeableDrawer } from '@mui/material';
import { PrimaryButtonCustom } from '../../../../../shared/buttons/styles';
import PlusIcon from '../../../../../shared/images/PlusIcon';
import { StylesNoFolders } from '../styles';
import NewFolder from '../NewFolder/NewFolder';
import { IFolderTree } from '../../../../auth/types';

interface NoFoldersFoundProps {
  currentFolder: IFolderTree;
}

const NoFoldersFound: React.FC<NoFoldersFoundProps> = ({ currentFolder }) => {
  const [isOpenNewFolder, setIsOpenNewFolder] = useState(false);

  const handleOpenNewFolder = () => setIsOpenNewFolder(true);
  const handleCloseNewFolder = () => setIsOpenNewFolder(false);

  return (
    <Grid>
      <StylesNoFolders>
        No folders found. Please create a new folder for your project.
      </StylesNoFolders>
      <Grid paddingTop={'16px'}>
        <PrimaryButtonCustom
          variant='contained'
          size='small'
          $padding='8px 12px'
          startIcon={<PlusIcon />}
          onClick={handleOpenNewFolder}
        >
          Create New Folder
        </PrimaryButtonCustom>
      </Grid>
      <SwipeableDrawer
        container={() => document.querySelector('.moveToFolder')}
        anchor={'bottom'}
        open={isOpenNewFolder}
        onClose={handleCloseNewFolder}
        onOpen={handleOpenNewFolder}
      >
        <NewFolder
          open={isOpenNewFolder}
          handleClose={handleCloseNewFolder}
          currentFolder={currentFolder}
        />
      </SwipeableDrawer>
    </Grid>
  );
};

export default NoFoldersFound;
