import React from 'react';

import PayPalIcon from '../shared/images/PaymentMethod/PayPalIcon';
import ApplePayIcon from '../shared/images/PaymentMethod/ApplePayIcon';
import GooglePayIcon from '../shared/images/PaymentMethod/GooglePayIcon';
import CardIcon from '../shared/images/PaymentMethod/CardIcon';
import VisaIcon from '../shared/images/PaymentMethod/VisaIcon';
import MastercardIcon from '../shared/images/PaymentMethod/MastercardIcon';

/**
 * Returns the corresponding payment icon based on the payment method.
 *
 * @param {string} [paymentMethod] - The payment method (e.g., 'paypal', 'card').
 * @param {string} [cardType] - The type of card (e.g., 'visa', 'mastercard') if the payment method is 'card'.
 * @returns {JSX.Element} The corresponding payment icon component.
 */
const getPaymentIcon = (paymentMethod?: string, cardType?: string) => {
  switch (paymentMethod) {
    case 'paypal':
      return <PayPalIcon />;
    case 'card':
      return getCardTypeIcon(cardType);
    case 'ideal':
      return <CardIcon />;
    case 'bancontact':
      return <CardIcon />;
    case 'google_pay':
      return <GooglePayIcon />;
    case 'apple_pay':
      return <ApplePayIcon />;
    default:
      return <CardIcon />;
  }
};

/**
 * Returns the corresponding card type icon based on the card type.
 *
 * @param {string} [cardType] - The type of card (e.g., 'visa', 'mastercard').
 * @returns {JSX.Element} The corresponding card type icon component.
 */
const getCardTypeIcon = (cardType?: string) => {
  switch (cardType) {
    case 'visa':
      return <VisaIcon />;
    case 'mastercard':
      return <MastercardIcon />;
    default:
      return <CardIcon />;
  }
};

/**
 * Returns the corresponding payment label based on the payment method.
 *
 * @param {string} [paymentMethod] - The payment method (e.g., 'paypal', 'ideal').
 * @returns {string} The corresponding payment label.
 */
const getPaymentLabel = (paymentMethod?: string) => {
  switch (paymentMethod) {
    case 'paypal':
      return 'PayPal';
    case 'ideal':
      return 'iDEAL';
    case 'bancontact':
      return 'Bancontact';
    case 'google_pay':
      return 'Google Pay';
    case 'apple_pay':
      return 'Apple Pay';
    case 'visa':
      return 'Visa';
    case 'mastercard':
      return 'Mastercard';
    default:
      return 'Unknown';
  }
};

export { getPaymentIcon, getCardTypeIcon, getPaymentLabel };
