import { CreateErrorOptions, ValidationError } from 'yup';

export const keywordsSymbolsLimit = 700;

/**
 * List of forbidden keyword prefixes that are not allowed in the search.
 * These prefixes are typically used for special search queries.
 * @type {string[]}
 */
export const keywordsForbiddenPrefixes = [
  'allinanchor:',
  'allintext:',
  'allintitle:',
  'allinurl:',
  'define:',
  'filetype:',
  'id:',
  'inanchor:',
  'info:',
  'intext:',
  'intitle:',
  'inurl:',
  'link:',
  'site:',
  'cache:',
];

/**
 * Validates a keyword string, checking for forbidden prefixes.
 */
export const validateKeywords = (
  value: string | undefined,
  {
    createError,
    path,
  }: {
    createError: (params?: CreateErrorOptions) => ValidationError;
    path: string;
  }
) => {
  if (!value) return true;

  const forbiddenWords = [];

  // Check if the keyword contains any forbidden prefixes
  for (const word of keywordsForbiddenPrefixes) {
    if (value.includes(word)) {
      forbiddenWords.push(word);
    }
  }

  // If any forbidden words are found, return an error
  if (forbiddenWords.length) {
    return createError({
      path,
      message: `The keyword containing "${forbiddenWords.join(
        ', '
      )}" is unavailable. Please remove it and try again.`,
    });
  }

  // Return true if validation is successful
  return true;
};
