import React, { useMemo } from 'react';

import { StyledHeader } from './styles';
import CustomDateRangePicker from './DateRangePicker';
import { Range } from 'react-date-range';
import { useProjects } from '../../../../../../hooks';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import { Grid } from '@mui/material';
import TimesGroup from '../../../TimesGroup/TimesGroup';
import { DEVISE_TYPE } from '../../../../constants';
import ProjectOptions from './ProjectOptions/ProjectOptions';
import { getDeviceTypeIcon } from '../../../../utils';
import { useAppSelector } from '../../../../../../store';

const Header = () => {
  const {
    onSetProjectExpandedGraphsRangeDate,
    onSetDeviceTypeForProjectExpanded,
    onGetProjectExtended,
  } = useProjects();

  const rangeDate = useAppSelector(
    state => state.projects.projectExpandedGraphs.rangeDate
  );
  const deviceType = useAppSelector(
    state => state.projects.projectExpandedGraphs.deviceType
  );

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );

  const isViewOnly =
    !currentAccount?.accountId || currentAccount?.role?.name === 'ViewOnly';

  const handleDateRangeChange = (range: Range) => {
    onSetProjectExpandedGraphsRangeDate(range);
  };

  const timesGroupItems = useMemo(() => {
    return DEVISE_TYPE.map(type => ({
      icon: getDeviceTypeIcon(type),
      label: type === 'DesktopAndMobile' ? 'Desktop & Mobile' : type,
      value: type,
      isActive: type === deviceType,
      onChange: () => {
        if (projectExpanded?.id) {
          onSetDeviceTypeForProjectExpanded(type);
          onGetProjectExtended({
            accountId: currentAccount.accountId,
            projectId: projectExpanded.id,
            keywordDeviceType: type,
            notes: true,
          });
        }
      },
    }));
  }, [deviceType, onSetDeviceTypeForProjectExpanded]);

  const filterTimesGroupItems = useMemo(() => {
    if (
      projectExpanded &&
      (projectExpanded.searchEngine.name === 'YouTube' ||
        projectExpanded.searchEngine.name === 'Google My Business' ||
        projectExpanded.searchEngine.name === 'Google Maps')
    ) {
      return timesGroupItems.filter(item => item.label === 'Desktop');
    }
    return timesGroupItems;
  }, [timesGroupItems, projectExpanded?.searchEngine?.name]);

  return (
    <StyledHeader container xs={12}>
      <Grid container item>
        <Breadcrumbs />
      </Grid>
      <Grid
        container
        item
        justifyContent={'space-between'}
        alignItems={'center'}
        xs={12}
        gap={2}
        paddingTop={'16px'}
      >
        <Grid item>
          {<TimesGroup items={filterTimesGroupItems} disabled={!deviceType} />}
        </Grid>

        <Grid item>
          <Grid container gap={1}>
            <Grid item>
              <CustomDateRangePicker
                onChange={handleDateRangeChange}
                rangeDate={rangeDate}
              />
            </Grid>
            {!isViewOnly ? (
              <Grid item>
                <ProjectOptions />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </StyledHeader>
  );
};

export default Header;
