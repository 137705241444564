import React, { useState } from 'react';
import { Competitors } from '../../../../../types';
import { SearchContainer } from '../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import {
  Divider,
  FormControlLabel,
  Grid,
  InputBase,
  InputLabel,
  Typography,
  useMediaQuery,
} from '@mui/material';
import SearchIcon from '../../../../../../../shared/images/SearchIcon';
import PlusIcon from '../../../../../../../shared/images/PlusIcon';
import { SmallContainerCheckbox } from '../../../../../../../shared/checkbox/styles';
import CustomCheckboxImage from '../../../../../../../shared/images/CustomCheckbox';
import CheckedIcon from '../../../../../../../shared/images/CheckedIcon';
import { ButtonsWrapper } from '../../../../../../auth/components/Settings/components/Team/components/customTableStyles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../../../shared/buttons/styles';
import { AddTagsPopover } from '../styles';
import { useAppSelector } from '../../../../../../../store';

interface AddCompetitorsPopupProps {
  closePopover: any;
  competitors: string[];
  competitorIds: Competitors[];
  setFieldValue: any;
  refInput: React.RefObject<HTMLInputElement>;
  isNew: boolean;
}

const AddCompetitorsPopup: React.FC<AddCompetitorsPopupProps> = ({
  setFieldValue,
  closePopover,
  refInput,
  competitors,
  competitorIds,
  isNew,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const currentProject = useAppSelector(state => state.projects.currentProject);

  const [searchString, setSearchString] = useState('');

  const [createdCompetitors, setCreatedCompetitors] =
    useState<string[]>(competitors);
  const [checkedCreatedCompetitors, setCheckedCreatedCompetitors] =
    useState<string[]>(competitors);

  const [checkedCompetitors, setCheckedCompetitors] =
    useState<Competitors[]>(competitorIds);

  const handleSelectCreatedCompetitor = (item: string, checked: boolean) => {
    setCheckedCreatedCompetitors([...checkedCreatedCompetitors, item]);
    if (!checked) {
      setCheckedCreatedCompetitors(
        checkedCreatedCompetitors.filter(filterItem => filterItem !== item)
      );
    }
  };

  const handleSelectCompetitors = (
    competitors: Competitors,
    checked: boolean
  ) => {
    setCheckedCompetitors([...checkedCompetitors, competitors]);
    if (!checked) {
      setCheckedCompetitors(
        checkedCompetitors.filter(item => item.id !== competitors.id)
      );
    }
  };

  const handleConfirm = () => {
    setFieldValue('competitors', checkedCreatedCompetitors);
    setFieldValue('competitorIds', checkedCompetitors);
    closePopover();
  };

  return (
    <AddTagsPopover sx={{ width: refInput?.current?.clientWidth || 512 }}>
      <SearchContainer sx={{ padding: '0 16px', gap: '0' }}>
        <InputLabel htmlFor='searchCompetitor'>
          <SearchIcon />
        </InputLabel>
        <InputBase
          fullWidth
          id='searchCompetitor'
          placeholder='Enter competitor'
          value={searchString}
          onChange={e => setSearchString(e.target.value as string)}
        />
      </SearchContainer>
      <Divider
        orientation='horizontal'
        flexItem
        sx={{
          margin: '8px 16px',
          color: '#EDEDED',
        }}
      />

      {searchString && !createdCompetitors.includes(searchString) ? (
        <Grid
          sx={{
            display: 'flex',
            gap: '8px',
            padding: '8px 16px',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            setCreatedCompetitors(prevState => prevState.concat(searchString));
            setCheckedCreatedCompetitors(prevState =>
              prevState.concat(searchString)
            );
            setSearchString('');
          }}
        >
          <PlusIcon stroke={'#667085'} />
          <Typography
            component='span'
            sx={{ color: '#475467', fontSize: '14px', fontWeight: '600' }}
          >
            {`Create competitor ${searchString ? `"${searchString}"` : ''}`}
          </Typography>
        </Grid>
      ) : null}

      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer',
        }}
      >
        {createdCompetitors.length ? (
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '8px',
              cursor: 'pointer',
            }}
          >
            {createdCompetitors.map(item => (
              <FormControlLabel
                key={item}
                label={item}
                sx={{
                  marginLeft: '6px',
                  gap: '8px',
                  color: '#101828',
                  fontSize: '14px',
                  padding: '8px',
                  backgroundColor: checkedCreatedCompetitors.includes(item)
                    ? '#EEF3FF'
                    : 'initial',
                  borderRadius: '8px',
                  ':hover': {
                    backgroundColor: '#F6F6F6',
                    borderRadius: '8px',
                  },
                }}
                control={
                  <SmallContainerCheckbox
                    onChange={() =>
                      handleSelectCreatedCompetitor(
                        item,
                        !checkedCreatedCompetitors.includes(item)
                      )
                    }
                    checked={checkedCreatedCompetitors.includes(item)}
                    icon={<CustomCheckboxImage />}
                    checkedIcon={<CheckedIcon />}
                  />
                }
              />
            ))}
          </Grid>
        ) : null}
        {currentProject?.competitors?.length && !isNew ? (
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '8px',
              cursor: 'pointer',
            }}
          >
            {currentProject.competitors.map(item => (
              <FormControlLabel
                key={item.id}
                label={item.domainName}
                sx={{
                  marginLeft: '6px',
                  gap: '8px',
                  color: '#101828',
                  fontSize: '14px',
                  padding: '8px',
                  backgroundColor: checkedCompetitors.some(
                    x => x.id === item.id
                  )
                    ? '#EEF3FF'
                    : 'initial',
                  borderRadius: '8px',
                  ':hover': {
                    backgroundColor: '#F6F6F6',
                    borderRadius: '8px',
                  },
                }}
                control={
                  <SmallContainerCheckbox
                    onChange={() =>
                      handleSelectCompetitors(
                        item,
                        !checkedCompetitors.some(x => x.id === item.id)
                      )
                    }
                    checked={checkedCompetitors.some(x => x.id === item.id)}
                    icon={<CustomCheckboxImage />}
                    checkedIcon={<CheckedIcon />}
                  />
                }
              />
            ))}
          </Grid>
        ) : null}
      </Grid>

      <ButtonsWrapper
        container
        xs={12}
        sx={{ padding: '8px 16px 0 !important' }}
      >
        <Grid item xs={extraSmallScreen && 12}>
          <PrimaryButtonSmall
            fullWidth={extraSmallScreen}
            variant='contained'
            size='small'
            onClick={handleConfirm}
          >
            Apply
          </PrimaryButtonSmall>
        </Grid>
        <Grid item xs={extraSmallScreen && 12}>
          <GrayButtonSmall
            fullWidth={extraSmallScreen}
            size='small'
            outline
            onClick={closePopover}
          >
            Cancel
          </GrayButtonSmall>
        </Grid>
      </ButtonsWrapper>
    </AddTagsPopover>
  );
};

export default AddCompetitorsPopup;
