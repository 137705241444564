// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect } from 'react';
import ScriptLoader from 'react-script-loader-hoc';
import { Outlet, useNavigate } from 'react-router';
import usePayment from '../../features/payment/hooks/usePayment';
import { PADDLE, PADDLE_CLIENT_SIDE_TOKEN } from '../../constants/config';
import { useAuth } from '../../hooks';
import SuccessPayModal from '../../features/payment/components/SuccessPayModal/SuccessPayModal';
import ROUTES from '../../routes/constants';
import { useAppSelector } from '../../store';

const PaddleLayout = () => {
  const navigate = useNavigate();

  const { onSetIsOpenSuccessPayModal, onSetPayment, isOpenSuccessPayModal } =
    usePayment();
  const { onGetBilling, onGetMyAccount, onGetCheckoutSettings } = useAuth();
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const checkoutSettings = useAppSelector(state => state.auth.checkoutSettings);
  const paddle = useAppSelector(state => state.payment.paddle);

  const handleCloseSuccessPayModal = () => {
    onGetMyAccount(currentAccount.accountId);
    onGetBilling({ id: currentAccount.accountId });
    onSetIsOpenSuccessPayModal(false);
  };

  const handleBackToDashboard = () => {
    navigate(ROUTES.projects);
    handleCloseSuccessPayModal();
  };

  useEffect(() => {
    onGetCheckoutSettings();
  }, [onGetCheckoutSettings]);

  useEffect(() => {
    if (
      window.Paddle &&
      checkoutSettings?.environment &&
      !paddle &&
      currentAccount?.accountId
    ) {
      window.Paddle.Environment.set(checkoutSettings.environment);
      window.Paddle.Initialize({
        token: PADDLE_CLIENT_SIDE_TOKEN,
        pwCustomer: { email: currentAccount.owner.ownerEmail },
        eventCallback: function (data) {
          if (
            data.name == 'checkout.completed' &&
            data.data.status === 'completed'
          ) {
            window.Paddle.Checkout.close();
            onSetIsOpenSuccessPayModal(true);
          }
        },
      });
      onSetPayment(window.Paddle);
    }
  }, [
    onGetBilling,
    onGetMyAccount,
    onSetIsOpenSuccessPayModal,
    onSetPayment,
    checkoutSettings?.environment,
    currentAccount?.accountId,
  ]);
  return (
    <>
      <Outlet />
      <SuccessPayModal
        handleClose={handleCloseSuccessPayModal}
        handleBackToDashboard={handleBackToDashboard}
        open={isOpenSuccessPayModal}
      />
    </>
  );
};

export default ScriptLoader(PADDLE.URL)(PaddleLayout);
