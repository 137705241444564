import React from 'react';
import { PaginationItem } from '@mui/material';

import PaginationChevronPrevIcon from '../../../../../../../../../../shared/images/PaginationChevronPrevIcon';
import PaginationChevronNextIcon from '../../../../../../../../../../shared/images/PaginationChevronNextIcon';

import { StyledPagination, PaginationContainer } from './styles';
import { PaginationProps } from '../../types';
import { useProjects } from '../../../../../../../../../../hooks';
import { useAppSelector } from '../../../../../../../../../../store';

const Pagination: React.FC<PaginationProps> = ({ paginationData, id }) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const { onGetKeywordSearchResults } = useProjects();

  const handleSetPaginationPage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    onGetKeywordSearchResults({
      accountId: currentAccount.accountId,
      keywordId: id,
      page: value,
      limit: 10,
    });
  };

  return (
    <PaginationContainer xs={12}>
      <StyledPagination
        count={Math.ceil(paginationData.totalItems / 10)}
        page={paginationData.page}
        onChange={handleSetPaginationPage}
        renderItem={item => (
          <PaginationItem
            slots={{
              previous: PaginationChevronPrevIcon,
              next: PaginationChevronNextIcon,
            }}
            {...item}
          />
        )}
      />
    </PaginationContainer>
  );
};

export default Pagination;
