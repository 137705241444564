import { DEFAULT_COLUMN_VISIBILITY_MODEL } from '../../../../../../../../constants';

/**
 * Renders the title for a list item based on the provided parameter.
 * @param param - The parameter to determine the list item title (e.g., 'name', 'url', 'deviceType').
 * @returns The corresponding title as a string, or an empty string if the parameter does not match any case.
 */
function renderListItemTitle(param: string) {
  switch (param) {
    case 'name':
      return 'Project';
    case 'url':
      return 'URL';
    case 'deviceType':
      return 'Type';
    case 'totalKeywords':
      return 'Total Keywords';
    case 'improved':
      return 'Improved';
    case 'declined':
      return 'Declined';
    case 'noChange':
      return 'No Change';
    case 'dailyAverage':
      return 'Daily Average';
    case 'frequency':
      return 'Frequency';
    case 'created':
      return 'Created';
    case 'updated':
      return 'Updated';
    default:
      return '';
  }
}

/**
 * Checks if any settings are selected in the column visibility model.
 * @param columnVisibilityModel - The column visibility model object.
 * @returns A boolean indicating if any columns are set to be visible.
 */
function getIsSelectedSettings(
  columnVisibilityModel: typeof DEFAULT_COLUMN_VISIBILITY_MODEL
) {
  return Object.values(columnVisibilityModel).includes(true);
}

/**
 * Gets the number of selected settings (visible columns) in the column visibility model.
 * @param columnVisibilityModel - The column visibility model object.
 * @returns The count of columns that are set to be visible.
 */
function getLengthSelectedSettings(
  columnVisibilityModel: typeof DEFAULT_COLUMN_VISIBILITY_MODEL
) {
  return Object.values(columnVisibilityModel)
    .map(e => e)
    .filter(e => e).length;
}

export {
  renderListItemTitle,
  getIsSelectedSettings,
  getLengthSelectedSettings,
};
