import React from 'react';
import { Grid } from '@mui/material';
import { LabelStyles, LineStyles, ValueStyles } from './styles';
import UpGreenIcon from '../../images/UpGreenIcon';
import DownRedIcon from '../../images/DownRedIcon';
import {
  getKeywordRankingTableValue,
  IPositionKeywordRankings,
} from '../../../features/projects/utils';
import Dash from '../../../features/projects/components/Dash';

interface PositionInfoTooltipProps {
  positionInfo: {
    difference: number;
    isDeclined: boolean;
    isImproved: boolean;
    lastPosition: number;
    lastPositionDate: string;
    periodPosition: number;
    positionDateForPeriod: string;
  };
  position: IPositionKeywordRankings;
  changes: number;
}

const PositionInfoTooltip: React.FC<PositionInfoTooltipProps> = ({
  positionInfo,
  position,
  changes,
}) => {
  if (
    !positionInfo.difference &&
    !positionInfo.isDeclined &&
    !positionInfo.isImproved
  ) {
    return <Grid>No changes</Grid>;
  }

  return (
    <Grid>
      <LineStyles container justifyContent={'space-between'} gap={'8px'}>
        <LabelStyles item>{`Position on ${
          positionInfo.positionDateForPeriod || ''
        }:`}</LabelStyles>
        <ValueStyles item>
          {getKeywordRankingTableValue(positionInfo.periodPosition)}
        </ValueStyles>
      </LineStyles>
      <LineStyles container justifyContent={'space-between'} gap={'8px'}>
        <LabelStyles item>{`Position on ${
          positionInfo.lastPositionDate || ''
        }:`}</LabelStyles>
        <ValueStyles item>
          {getKeywordRankingTableValue(positionInfo.lastPosition)}
        </ValueStyles>
      </LineStyles>
      <LineStyles container justifyContent={'space-between'} gap={'8px'}>
        <LabelStyles item>Difference:</LabelStyles>
        <Grid item>
          <Grid container gap={'4px'} alignItems={'center'}>
            <Grid item display={'flex'} alignItems={'center'}>
              {positionInfo.isImproved ? <UpGreenIcon /> : null}
            </Grid>
            <Grid item display={'flex'} alignItems={'center'}>
              {positionInfo.isDeclined ? <DownRedIcon /> : null}
            </Grid>
            <ValueStyles
              $color={
                positionInfo.isImproved
                  ? '#007C65'
                  : positionInfo.isDeclined
                  ? '#D1002F'
                  : undefined
              }
              item
            >
              {(!changes &&
                position.position === 100 &&
                positionInfo.isImproved) ||
              (!changes &&
                position.position === 101 &&
                positionInfo.isDeclined) ? (
                <Dash />
              ) : (
                getKeywordRankingTableValue(positionInfo.difference)
              )}
            </ValueStyles>
          </Grid>
        </Grid>
      </LineStyles>
    </Grid>
  );
};

export default PositionInfoTooltip;
