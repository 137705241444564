import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import SerpNestLogo from '../../../../shared/images/SerpNestLogo';
import VerifyEmailSuccessfulImage from '../../../../shared/images/VerifyEmailSuccessfu';
import { useAuth } from '../../../../hooks';
import ROUTES from '../../../../routes/constants';

import { PrimaryButton } from '../../../../shared/buttons/styles';
import { LogoContainer, PaperContainer, Wrapper } from './styles';
import { useAppSelector } from '../../../../store';

const VerifyEmailSuccessful = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { onConfirmEmail } = useAuth();

  const isLoading = useAppSelector(state => state.auth.isLoading);

  const pathSnippets = pathname.split('/');
  const emailConfirmationToken = pathSnippets[2];

  const onRedirect = () => {
    navigate(ROUTES.login);
  };

  useEffect(() => {
    onConfirmEmail({ emailConfirmationToken, errorFn: onRedirect });
  }, []);

  return (
    <Wrapper>
      <Grid container>
        <Grid xs={1} lg={3} md={2} />
        <Grid xs={10} lg={6} md={8}>
          <Box>
            <LogoContainer>
              <SerpNestLogo />
            </LogoContainer>
            <PaperContainer>
              {isLoading ? (
                <Skeleton width='179px' height='160px' />
              ) : (
                <VerifyEmailSuccessfulImage />
              )}

              {isLoading ? (
                <Skeleton width='70%' height='30px' />
              ) : (
                <Typography variant='h4' fontWeight='700' sx={{ mt: 3, mb: 3 }}>
                  Account activation successful!
                </Typography>
              )}

              {isLoading ? (
                <Skeleton width='60%' height='20px' />
              ) : (
                <Typography variant='body1' sx={{ mb: 3 }}>
                  Your account has been successfully activated.
                </Typography>
              )}

              {isLoading ? (
                <Skeleton width='158px' height='56px' />
              ) : (
                <PrimaryButton
                  size='large'
                  variant='contained'
                  onClick={onRedirect}
                >
                  Get Started
                </PrimaryButton>
              )}
            </PaperContainer>
          </Box>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default VerifyEmailSuccessful;
