/**
 * Extracts the country code from a given domain.
 *
 * This function splits the domain by dots and retrieves the last part as the country code.
 * It also accounts for special cases, such as domains ending with '.co.uk', which are
 * mapped to 'GB', and '.com', which is mapped to 'US'.
 *
 * @param {string} domain - The domain from which to extract the country code.
 * @returns {string} The country code associated with the domain.
 *                   Returns 'GB' for '.co.uk' domains and 'US' for '.com'.
 *                   For other domains, returns the last part in uppercase.
 *
 * @example
 * getCountryCode('example.com'); // Returns 'US'
 * getCountryCode('example.co.uk'); // Returns 'GB'
 * getCountryCode('example.fr'); // Returns 'FR'
 */
export const getCountryCode = (domain: string) => {
  const code = domain.split('.')[domain.split('.').length - 1];

  if (domain.endsWith('.co.uk')) return 'GB';

  return code === 'com' ? 'US' : code.toUpperCase();
};

/**
 * Retrieves the flag emoji corresponding to the country code derived from the domain.
 *
 * This function calls `getCountryCode` to obtain the country code and then converts
 * that code into its corresponding flag emoji using Unicode code points.
 *
 * @param {string} domain - The domain from which to derive the flag emoji.
 * @returns {string} The flag emoji corresponding to the country code derived from the domain.
 *
 * @example
 * getFlagEmoji('example.com'); // Returns 🇺🇸
 * getFlagEmoji('example.co.uk'); // Returns 🇬🇧
 * getFlagEmoji('example.fr'); // Returns 🇫🇷
 */
export const getFlagEmoji = (domain: string) => {
  const countryCode = getCountryCode(domain);

  const codePoints: any = countryCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
};
