import { Grid, Typography } from '@mui/material';
import React from 'react';

const Header = () => {
  return (
    <Grid xs={12}>
      <Typography
        sx={{ pt: 1, pb: 2 }}
        fontWeight='600'
        color='#101828'
        fontSize='28px'
      >
        Shared Links
      </Typography>
    </Grid>
  );
};

export default Header;
