import React, { useEffect, useMemo, useState } from 'react';
import {
  findFolderById,
  newFindParentFolders,
} from '../../../../utils/folders';
import { IFolderTree } from '../../../auth/types';
import {
  ModalContainer,
  ModalWrapper,
} from '../Projects/components/ProjectRedactorModal/styles';
import {
  Divider,
  Fade,
  FormHelperText,
  Grid,
  Modal,
  SwipeableDrawer,
} from '@mui/material';
import BackIcon from '../../../../shared/images/BackIcon';
import {
  StylesCurrentLocation,
  StylesFoldersList,
} from '../MoveToFolder/styles';
import FoldersListItem from '../MoveToFolder/FoldersListItem/FoldersListItem';
import NoFoldersFound from '../MoveToFolder/NoFoldersFound/NoFoldersFound';
import BreadcrumbsFolders from '../MoveToFolder/BreadcrumbsFolders/BreadcrumbsFolders';
import FolderPlusIcon from '../../../../shared/images/FolderPlusIcon';
import {
  GrayButtonCustom,
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../shared/buttons/styles';
import NewFolder from './NewFolder/NewFolder';
import FolderIcon from '../../../../shared/images/FolderIcon';
import ProjectsListItem from '../MoveToFolder/ProjectsListItem/ProjectsListItem';
import { useAppSelector } from '../../../../store';
import { CustomFormHelperText } from '../../../../shared/components';
import { processFolderTree } from '../../utils';

interface MoveToProps {
  isOpen: boolean;
  type: 'moveFolder' | 'moveProject';
  folderErrorMessage: string;
  handleClose: () => void;
  handleMove: (folderId: number) => void;
}

const MoveTo: React.FC<MoveToProps> = ({
  isOpen,
  type,
  folderErrorMessage,
  handleClose,
  handleMove,
}) => {
  const folderTree = useAppSelector(state => state.auth.myAccount.folderTree);

  const [currentFolder, setCurrentFolder] = useState(folderTree);
  const [isOpenNewFolder, setIsOpenNewFolder] = useState(false);

  const parentFolders = useMemo(() => {
    const data = processFolderTree(folderTree);
    return newFindParentFolders(data, currentFolder.id);
  }, [currentFolder.id, folderTree]);

  const parentFolder = useMemo(
    () => parentFolders[parentFolders.length - 2],
    [parentFolders]
  );

  const foldersList = useMemo(() => {
    const data = processFolderTree(currentFolder);
    if (data.length === 1 && data.find(item => item.id === currentFolder.id)) {
      return data[0].children;
    }

    return data;
  }, [currentFolder]);

  const handleSetCurrentFolder = (folder: IFolderTree) => {
    setCurrentFolder(folder);
  };

  const handleOpenNewFolder = () => setIsOpenNewFolder(true);
  const handleCloseNewFolder = () => setIsOpenNewFolder(false);

  const handleConfirmFolder = () => {
    handleMove(currentFolder.id);
  };

  useEffect(() => {
    if (isOpen) {
      handleSetCurrentFolder(folderTree);
    }
  }, [isOpen]);

  useEffect(() => {
    if (folderTree) {
      const folder = findFolderById(folderTree, currentFolder.id);
      if (folder) {
        setCurrentFolder(folder);
      }
    }
  }, [folderTree]);

  const isRenderBackIcon = useMemo(() => {
    if (!parentFolder && currentFolder?.name !== 'My Folders') {
      return true;
    }
    if (parentFolder) {
      return true;
    }
    return false;
  }, [currentFolder?.name, parentFolder]);

  const isRenderNewFolderIcon = useMemo(
    () => !(currentFolder?.name === 'My Folders' && !currentFolder?.available),
    [currentFolder?.name, currentFolder?.available]
  );

  const backToFolder = () => {
    if (parentFolder) {
      handleSetCurrentFolder(parentFolder);
    } else {
      handleSetCurrentFolder(folderTree);
    }
  };

  if (!isOpen) {
    return null;
  }
  return (
    <Modal
      aria-labelledby='move-modal-title'
      aria-describedby='move-description'
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isOpen}>
        <ModalWrapper className={'moveToFolder'}>
          <ModalContainer container xs={12} sx={{ gap: '8px' }}>
            <Grid xs={12}>
              <Grid
                container
                gap={1}
                alignItems={'center'}
                paddingBottom={'16px'}
              >
                <StylesCurrentLocation item>
                  Current Location:
                </StylesCurrentLocation>
                <GrayButtonCustom
                  $padding='7px 8px'
                  size='small'
                  outline
                  startIcon={<FolderIcon />}
                >
                  {currentFolder.name}
                </GrayButtonCustom>
              </Grid>

              <Grid>
                <Grid container gap={1}>
                  {isRenderBackIcon && (
                    <Grid style={{ cursor: 'pointer' }} onClick={backToFolder}>
                      <BackIcon />
                    </Grid>
                  )}
                  <Grid item>{currentFolder.name}</Grid>
                </Grid>
                <Grid>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    sx={{
                      margin: '16px 0',
                      color: '#EDEDED',
                    }}
                  />
                </Grid>
              </Grid>
              <StylesFoldersList>
                {foldersList.map(folder => (
                  <FoldersListItem
                    key={folder.id}
                    handleSetCurrentFolder={handleSetCurrentFolder}
                    folder={folder}
                  />
                ))}

                {currentFolder.projects.map(project =>
                  project.available ? (
                    <ProjectsListItem key={project.id} project={project} />
                  ) : null
                )}

                {!foldersList.length && (
                  <NoFoldersFound currentFolder={currentFolder} />
                )}
              </StylesFoldersList>
              {folderErrorMessage ? (
                <FormHelperText error id='folderErrorMessage'>
                  <CustomFormHelperText error={folderErrorMessage} />
                </FormHelperText>
              ) : null}
              <Grid>
                <Divider
                  orientation='horizontal'
                  flexItem
                  sx={{
                    margin: '16px 0',
                    color: '#EDEDED',
                  }}
                />
              </Grid>
              <Grid>
                <BreadcrumbsFolders
                  folders={parentFolders}
                  handleSetCurrentFolder={handleSetCurrentFolder}
                />
              </Grid>
              <Grid
                container
                justifyContent={'space-between'}
                alignItems={'center'}
                paddingTop={'24px'}
                paddingBottom={'16px'}
              >
                <Grid item>
                  {isRenderNewFolderIcon && (
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={handleOpenNewFolder}
                    >
                      <FolderPlusIcon />
                    </div>
                  )}
                </Grid>
                <Grid item>
                  <Grid container gap={2}>
                    <Grid item>
                      <GrayButtonSmall
                        size='small'
                        outline
                        onClick={handleClose}
                      >
                        Cancel
                      </GrayButtonSmall>
                    </Grid>
                    {!(
                      type === 'moveProject' &&
                      currentFolder.name === 'My Folders'
                    ) ? (
                      <Grid item>
                        <PrimaryButtonSmall
                          variant='contained'
                          size='small'
                          onClick={handleConfirmFolder}
                        >
                          Move
                        </PrimaryButtonSmall>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <SwipeableDrawer
              container={() => document.querySelector('.moveToFolder')}
              anchor={'bottom'}
              open={isOpenNewFolder}
              onClose={handleCloseNewFolder}
              onOpen={handleOpenNewFolder}
            >
              <NewFolder
                handleClose={handleCloseNewFolder}
                open={isOpenNewFolder}
                currentFolder={currentFolder}
              />
            </SwipeableDrawer>
          </ModalContainer>
        </ModalWrapper>
      </Fade>
    </Modal>
  );
};

export default MoveTo;
