import React from 'react';

import { Header, Content } from './styles';

const URL = () => (
  <div>
    <Header>URL</Header>
    <Content>
      Enter the domain or the exact URL of the webpage you want to track. For
      example, &apos;https://example.com/page&apos; or &apos;example.com&apos;.
    </Content>
  </div>
);

const Keywords = () => (
  <div>
    <Header>Keywords</Header>
    <Content>
      Enter the keywords you want to track for this project. You can enter
      keywords one by one or in bulk. For bulk entries, make sure to enter one
      keyword per line.
    </Content>
  </div>
);

const SearchEngine = () => (
  <div>
    <Header>Search Engine</Header>
    <Content>
      Select the search engine where you want to track your keywords.
    </Content>
  </div>
);

const GoogleRegion = () => (
  <div>
    <Header>Google Region</Header>
    <Content>
      Select the specific Google region for which you want to track the
      keywords. For example, &apos;google.com&apos; for USA or
      &apos;google.co.uk&apos; for the UK.
    </Content>
  </div>
);

const SearchLocation = () => (
  <div>
    <Header>Search Location</Header>
    <Content>
      This field determines the geographic location where the keyword search
      query is executed.
    </Content>
  </div>
);

const SearchDeviceType = () => (
  <div>
    <Header>Search Device Type</Header>
    <Content>
      Choose the device type for which the keyword rankings will be checked. You
      can choose from desktop, mobile, or both.
    </Content>
  </div>
);

const GoogleCountry = () => (
  <div>
    <Header>Google Country</Header>
    <Content>
      This parameter allows you to specify the country version of Google for
      your search results. For example, if your target audience is in Canada,
      but you are located in UK, you can set &apos;Google Country&apos; to
      &apos;CA&apos; to get results as if you were searching from Canada.
    </Content>
  </div>
);

const Language = () => (
  <div>
    <Header>Language</Header>
    <Content>
      This determines the language used for Google&apos;s user interface during
      the search.
    </Content>
  </div>
);

const CheckFrequency = () => (
  <div>
    <Header>Check Frequency</Header>
    <Content>
      Select how frequently you want to check the keyword rankings.
    </Content>
  </div>
);

const Competitors = () => (
  <div>
    <Header>Competitors</Header>
    <Content>
      Enter the domains of your competitors you wish to monitor. These will be
      tracked for the same keywords as the main URL.
    </Content>
  </div>
);

const VideoURL = () => (
  <div>
    <Header>Video URL</Header>
    <Content>
      Input the YouTube video or Shorts URL to monitor its ranking.
    </Content>
  </div>
);

const BusinessNameGoogleLocal = () => (
  <div>
    <Header>Business Name</Header>
    <Content>
      Enter the business name exactly as it appears in the title of your Google
      Local (GMB) listing.
    </Content>
  </div>
);

const BusinessURLGoogleLocal = () => (
  <div>
    <Header>Business URL</Header>
    <Content>
      If available, enter the exact URL associated with your Google Local (GMB)
      listing.
    </Content>
  </div>
);

const BusinessNameGoogleMaps = () => (
  <div>
    <Header>Business Name</Header>
    <Content>
      Enter the business name exactly as it appears in the title of your Google
      Maps listing.
    </Content>
  </div>
);

const BusinessURLGoogleMaps = () => (
  <div>
    <Header>Business URL</Header>
    <Content>
      If available, enter the exact URL associated with your Google Maps
      listing.
    </Content>
  </div>
);

const CompetitorsBusinessNameGoogleLocal = () => (
  <div>
    <Header>Competitor’s Business Name</Header>
    <Content>
      Enter your competitor’s business name exactly as it appears in the title
      of their Google Local (GMB) listing.
    </Content>
  </div>
);

const CompetitorsBusinessURLGoogleLocal = () => (
  <div>
    <Header>Competitor’s Business URL</Header>
    <Content>
      If available, enter the exact business URL of your competitor as
      associated with their Google Local (GMB) listing.
    </Content>
  </div>
);

const CompetitorsBusinessNameGoogleMaps = () => (
  <div>
    <Header>Competitor’s Business Name</Header>
    <Content>
      Enter your competitor’s business name exactly as it appears in the title
      of their Google Maps listing.
    </Content>
  </div>
);

const CompetitorsBusinessURLGoogleMaps = () => (
  <div>
    <Header>Competitor’s Business URL</Header>
    <Content>
      If available, enter the exact business URL of your competitor as
      associated with their Google Maps listing.
    </Content>
  </div>
);

const CompetitorsVideoURL = () => (
  <div>
    <Header>Competitor’s Video URL</Header>
    <Content>
      Input the URL of your competitor&apos;s YouTube video or Shorts.
    </Content>
  </div>
);

const SearchEngineDomain = () => (
  <div>
    <Header>Search Engine Domain</Header>
    <Content>
      Choose the search engine domain to track keywords, such as
      &apos;google.com&apos; for the USA or &apos;google.co.uk&apos; for the UK.
    </Content>
  </div>
);

export {
  URL,
  Keywords,
  SearchEngine,
  GoogleRegion,
  SearchLocation,
  SearchDeviceType,
  GoogleCountry,
  Language,
  CheckFrequency,
  Competitors,
  VideoURL,
  BusinessNameGoogleLocal,
  BusinessURLGoogleLocal,
  BusinessNameGoogleMaps,
  BusinessURLGoogleMaps,
  CompetitorsBusinessNameGoogleLocal,
  CompetitorsBusinessURLGoogleLocal,
  CompetitorsBusinessNameGoogleMaps,
  CompetitorsBusinessURLGoogleMaps,
  CompetitorsVideoURL,
  SearchEngineDomain,
};
