import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  BulkDeleteSharedLinksParams,
  CreateSharedLinkParams,
  GetAllSharedLinksParams,
  GetSharedParams,
  GetSharedProjectImprovedVsDeclinedParams,
  GetSharedProjectKeywordPositionsInfoParams,
  GetSharedProjectKeywordRankingParams,
  GetSharedProjectKeywordSearchResultsParams,
  GetSharedProjectKeywordTrendsParams,
  GetSharedProjectOverviewParams,
  GetSharedProjectParams,
  LoginSharedParams,
  LoginSharedResponse,
  SharedLinksResponse,
  GetSharedProjectPerformanceParams,
  GetSharedLinkFullParams,
  ISharedLinkFullInfo,
  EditSharedLinkParams,
  GetSharedLinkSettingsParams,
  ISharedLinkSettings,
} from '../types';
import {
  bulkDeleteSharedLinksAPI,
  createSharedLinkAPI,
  getAllSharedLinksAPI,
  getSharedAPI,
  getSharedProjectAPI,
  getSharedProjectImprovedVsDeclinedAPI,
  getSharedProjectKeywordPositionsInfoPI,
  getSharedProjectKeywordRankingsPI,
  getSharedProjectKeywordTrendsAPI,
  getSharedProjectOverviewAPI,
  LoginSharedAPI,
  getKeywordSearchResultsAPI,
  getProjectPerformanceAPI,
  getSharedLinkFullAPI,
  editSharedLinkAPI,
  getSharedLinkSettingsAPI,
  getSharedKeywordInfoAPI,
  geSharedKeywordPositionHistoryAPI,
  getSharedKeywordPositionsInfoAPI,
  exportSharedToCsvKeywordPositionsInfoAPI,
} from '../api';
import { AxiosError } from 'axios';
import {
  GetKeywordPositionHistoryResponse,
  GetSharedKeywordPositionHistoryParams,
  KeywordAnalytics,
  KeywordRankingState,
  KeywordSearchResultsState,
  ProjectExpanded,
  ProjectImprovedVsDeclined,
  ProjectKeywordTrends,
  ProjectOverview,
  ProjectsState,
  SharedExportToCsvKeywordPositionsInfoParams,
} from '../../projects/types';
import { GetProjectPerformanceResponse } from '../../projects/types/projectPerformance';
import { checkAndRedirectToMaintenancePage } from '../../../utils';
import {
  GetKeywordInfoResponse,
  GetKeywordPositionsInfoResponse,
  GetSharedKeywordInfoParams,
  GetSharedKeywordPositionsInfoParams,
} from '../../projects/types/keywordInfo';

import { downloadCsvFile } from '../../projects/utils';

export const getAllSharedLinksThunk = createAsyncThunk<
  SharedLinksResponse,
  GetAllSharedLinksParams,
  { rejectValue: string }
>('getAllSharedLinks', async (params, thunkAPI) => {
  try {
    const { data } = await getAllSharedLinksAPI(params);

    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const createSharedLinkThunk = createAsyncThunk<
  undefined,
  CreateSharedLinkParams,
  { rejectValue: string }
>('createSharedLink', async (params, thunkAPI) => {
  try {
    const { data } = await createSharedLinkAPI(params);
    params.successFn(data.link);
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        params.errorFn(errors);
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const bulkDeleteSharedLinksThunk = createAsyncThunk<
  undefined,
  BulkDeleteSharedLinksParams,
  { rejectValue: string }
>('bulkDeleteSharedLinksThunk', async (params, thunkAPI) => {
  try {
    await bulkDeleteSharedLinksAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getSharedThunk = createAsyncThunk<
  ProjectsState,
  GetSharedParams,
  { rejectValue: string }
>('getSharedThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getSharedAPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const loginThunk = createAsyncThunk<
  LoginSharedResponse,
  LoginSharedParams,
  { rejectValue: string }
>('loginThunk', async (params, thunkAPI) => {
  try {
    const { data } = await LoginSharedAPI(params);

    params.successFn(data.accessToken);

    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        params.errorFn(errors);
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

interface IGetSharedProjectData {
  data: ProjectExpanded;
  deviceType?: string;
}

export const getSharedProjectThunk = createAsyncThunk<
  IGetSharedProjectData,
  GetSharedProjectParams,
  { rejectValue: string }
>('getSharedProjectThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getSharedProjectAPI(params);
    return { data, deviceType: params.deviceType };
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getSharedProjectOverviewThunk = createAsyncThunk<
  ProjectOverview,
  GetSharedProjectOverviewParams,
  { rejectValue: string }
>('getSharedProjectOverviewThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getSharedProjectOverviewAPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getSharedImprovedVsDeclinedThunk = createAsyncThunk<
  ProjectImprovedVsDeclined,
  GetSharedProjectImprovedVsDeclinedParams,
  { rejectValue: string }
>('getSharedImprovedVsDeclinedThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getSharedProjectImprovedVsDeclinedAPI(params);

    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getSharedKeywordTrendsThunk = createAsyncThunk<
  ProjectKeywordTrends,
  GetSharedProjectKeywordTrendsParams,
  { rejectValue: string }
>('getSharedKeywordTrendsThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getSharedProjectKeywordTrendsAPI(params);

    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getSharedKeywordRankingsThunk = createAsyncThunk<
  KeywordRankingState,
  GetSharedProjectKeywordRankingParams,
  { rejectValue: string }
>('getSharedKeywordRankingsThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getSharedProjectKeywordRankingsPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getKeywordPositionHistoryThunk = createAsyncThunk<
  KeywordAnalytics,
  GetSharedProjectKeywordPositionsInfoParams,
  { rejectValue: string }
>('getKeywordPositionHistoryThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getSharedProjectKeywordPositionsInfoPI(params);

    return {
      id: params.keywordId,
      keywordPositionHistory: data.positionHistory,
      showCompetitors: data.historyOfCompetitorPositions.map(item => item.id),
      competitors: data.historyOfCompetitorPositions,
      activePeriod: {
        label: '1m',
        value: 'Month',
      },
      isLinearGraph: true,
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getKeywordSearchResultsThunk = createAsyncThunk<
  { id: number; state: KeywordSearchResultsState },
  GetSharedProjectKeywordSearchResultsParams,
  { rejectValue: string }
>('getKeywordSearchResults', async (params, thunkAPI) => {
  try {
    const { data } = await getKeywordSearchResultsAPI(params);

    return { state: data, id: params.keywordId };
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getSharedProjectPerformanceThunk = createAsyncThunk<
  GetProjectPerformanceResponse,
  GetSharedProjectPerformanceParams
>('getSharedProjectPerformanceThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getProjectPerformanceAPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getSharedLinkFullThunk = createAsyncThunk<
  ISharedLinkFullInfo,
  GetSharedLinkFullParams
>('getSharedLinkFullThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getSharedLinkFullAPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const editSharedLinkThunk = createAsyncThunk<
  undefined,
  EditSharedLinkParams,
  { rejectValue: string }
>('editSharedLinkThunk', async (params, thunkAPI) => {
  try {
    await editSharedLinkAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        params.errorFn(errors);
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getSharedLinkSettingsThunk = createAsyncThunk<
  ISharedLinkSettings,
  GetSharedLinkSettingsParams
>('getSharedLinkSettingsThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getSharedLinkSettingsAPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getSharedKeywordInfoThunk = createAsyncThunk<
  GetKeywordInfoResponse,
  GetSharedKeywordInfoParams,
  { rejectValue: string }
>('getSharedKeywordInfoThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getSharedKeywordInfoAPI(params);

    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getSharedSingleKeywordPositionHistoryThunk = createAsyncThunk<
  GetKeywordPositionHistoryResponse,
  GetSharedKeywordPositionHistoryParams,
  { rejectValue: string }
>('getSharedSingleKeywordPositionHistoryThunk', async (params, thunkAPI) => {
  try {
    const { data } = await geSharedKeywordPositionHistoryAPI(params);

    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getSharedKeywordPositionsInfoThunk = createAsyncThunk<
  GetKeywordPositionsInfoResponse,
  GetSharedKeywordPositionsInfoParams,
  { rejectValue: string }
>('getSharedKeywordPositionsInfoThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getSharedKeywordPositionsInfoAPI(params);

    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const sharedExportToCsvKeywordPositionsInfoThunk = createAsyncThunk<
  undefined,
  SharedExportToCsvKeywordPositionsInfoParams
>('sharedExportToCsvKeywordPositionsInfoThunk', async (params, thunkAPI) => {
  try {
    const response = await exportSharedToCsvKeywordPositionsInfoAPI(params);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const disposition = response.headers.get('Content-Disposition');
    let filename = 'KeywordPositions.csv';

    if (disposition && disposition.includes('filename=')) {
      const filenameMatch = disposition.match(/filename="(.+)"/);
      if (filenameMatch?.[1]) {
        filename = filenameMatch[1];
      }
    }

    downloadCsvFile(response.data, filename);
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});
