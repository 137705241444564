import React, { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  FormHelperText,
  Grid,
  OutlinedInput,
  SwipeableDrawer,
  TextField,
  Typography,
} from '@mui/material';
import { FieldArray } from 'formik';
import { getFlagEmoji } from '../../../../../../utils/getCountryCode';
import { MainSettingsProps } from '../../../../types';
import useProjects from '../../../../hooks/useProjects';

import * as Tooltips from './Tooltips';
import HelperIcon from '../../../../../../shared/images/HelperIcon';
import { CustomFormHelperText } from '../../../../../../shared/components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PlusIcon from '../../../../../../shared/images/PlusIcon';

import {
  ButtonTooltipContainer,
  DeviceType,
  DeviceTypeContainer,
} from './styles';
import { CustomTooltip } from '../../../../../../shared/tooltip/styles';
import { CustomSwitch } from '../../../../../../shared/Switch/styles';
import SelectIcon from '../../../../../../shared/components/SelectIcon';
import { GrayButtonCustom } from '../../../../../../shared/buttons/styles';
import MoveToFolder from '../../../MoveToFolder/MoveToFolder';
import { useAppSelector } from '../../../../../../store';
import {
  ModalAutocompleteStylesProps,
  ModalOutlinedInput,
} from '../../../../../../shared/modal/styled';
import { getDeviceTypeIcon } from '../../../../utils';
import NotificationDeviceType from '../../../NotificationDeviceType/NotificationDeviceType';
import { useAuth } from '../../../../../../hooks';
import SearchEnginesList from './SearchEnginesList';
import URLSection from './URLSection';
import BusinessNameField from './Fields/BusinessNameField';
import BusinessURLField from './Fields/BusinessURLField';
import SearchLocation from './Fields/SearchLocationField/SearchLocationField';

const MainSettings: React.FC<MainSettingsProps> = ({
  isBulkKeywords,
  onToggleKeywords,
  isOpenAdvancedSettings,
  onToggleAdvancedSettings,
  showTooltip,
  openTooltip,
  onClickTooltip,
  onOpenTooltip,
  formik,
  isNew,
}) => {
  const {
    onGetSearchLocations,
    onGetGoogleDomains,
    onGetLanguages,
    onGetLanguagesBing,
    onGetLanguagesYoutube,
    onGetLanguagesYahoo,
    onGetLanguagesBaidu,
    onGetDeviceTypes,
    onGetCheckFrequency,
  } = useProjects();

  const { onGetSearchEngines } = useAuth();

  const searchLocations = useAppSelector(
    state => state.projects.searchLocations
  );
  const googleDomains = useAppSelector(state => state.projects.googleDomains);
  const deviceTypes = useAppSelector(state => state.projects.deviceTypes);
  const checkFrequency = useAppSelector(state => state.projects.checkFrequency);
  const searchEngines = useAppSelector(state => state.auth.searchEngines);

  const isAuth = useAppSelector(state => state.auth.isAuth);

  const [isOpenKeywords, setIsOpenKeywords] = useState(isNew);

  const [isOpenMoveToFolder, setIsOpenMoveToFolder] = useState(false);

  const handleOpenKeywords = () => setIsOpenKeywords(true);

  const handleOpenMoveToFolder = () => setIsOpenMoveToFolder(true);
  const handleCloseMoveToFolder = () => setIsOpenMoveToFolder(false);

  const setFolder = (id: number, name: string) => {
    formik.setFieldValue('folder', { id, name });
  };

  const ErrorForBulkImport = useMemo(() => {
    const errors = formik.errors?.keywords;
    if (formik.touched.keywords && errors && typeof errors !== 'string') {
      const uniqueErrors = errors.filter(
        (item, index) => errors.indexOf(item) === index
      );
      return uniqueErrors.map((item, index) =>
        uniqueErrors?.length && uniqueErrors[index] ? (
          <FormHelperText key={index} error id='keywords'>
            <CustomFormHelperText error={uniqueErrors[index]} />
          </FormHelperText>
        ) : null
      );
    }
  }, [formik.errors?.keywords, formik.touched.keywords]);

  const isRenderSearchEngineDomain = useMemo(
    () =>
      formik.values.searchEngine !== 'Google My Business' &&
      formik.values.searchEngine !== 'Bing' &&
      formik.values.searchEngine !== 'Yahoo' &&
      formik.values.searchEngine !== 'Baidu' &&
      formik.values.searchEngine !== 'YouTube',
    [formik.values.searchEngine]
  );

  const filterDeviceTypes = useMemo(() => {
    if (
      formik.values.searchEngine === 'YouTube' ||
      formik.values.searchEngine === 'Google My Business' ||
      formik.values.searchEngine === 'Google Maps'
    ) {
      return deviceTypes.filter(item => item.name === 'Desktop');
    }
    return deviceTypes;
  }, [deviceTypes, formik.values.searchEngine]);

  useEffect(() => {
    if (searchLocations.meta.search.length >= 2)
      if (isNew) {
        onGetSearchLocations({
          search: searchLocations.meta.search,
          page: searchLocations.meta.page,
          limit: searchLocations.meta.limit,
        });
      }
  }, [
    onGetSearchLocations,
    searchLocations.meta.search,
    searchLocations.meta.page,
    searchLocations.meta.limit,
    isNew,
  ]);

  useEffect(() => {
    if (!googleDomains.length) {
      onGetGoogleDomains();
    }
  }, [onGetGoogleDomains, googleDomains]);

  useEffect(() => {
    switch (formik.values.searchEngine) {
      case 'Bing':
        return onGetLanguagesBing();
      case 'YouTube':
        return onGetLanguagesYoutube();
      case 'Yahoo':
        return onGetLanguagesYahoo();
      case 'Baidu':
        return onGetLanguagesBaidu();
      default:
        return onGetLanguages();
    }
  }, [
    formik?.values?.searchEngine,
    onGetLanguages,
    onGetLanguagesBing,
    onGetLanguagesYoutube,
    onGetLanguagesYahoo,
    onGetLanguagesBaidu,
  ]);

  useEffect(() => {
    if (!deviceTypes?.length) {
      onGetDeviceTypes();
    }
  }, [onGetDeviceTypes, deviceTypes]);

  useEffect(() => {
    if (!checkFrequency?.length) {
      onGetCheckFrequency();
    }
  }, [onGetDeviceTypes, checkFrequency]);

  useEffect(() => {
    if (!searchEngines.length) {
      onGetSearchEngines();
    }
  }, [searchEngines]);

  return (
    <>
      <Grid item xs={12} paddingTop='8px'>
        <Grid
          container
          fontWeight='500'
          alignItems='center'
          fontSize='14px'
          gap='6px'
        >
          <Grid item>Project Name</Grid>
          <ModalOutlinedInput
            size='small'
            fullWidth
            type='text'
            id='projectName'
            name='projectName'
            placeholder='Enter project name'
            value={formik.values.projectName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={!!(formik.touched.projectName && formik.errors.projectName)}
          />
          {formik.touched.projectName && formik.errors.projectName && (
            <FormHelperText error id='projectName'>
              <CustomFormHelperText error={formik.errors.projectName} />
            </FormHelperText>
          )}
        </Grid>
      </Grid>

      {isNew ? (
        <SearchEnginesList
          formik={formik}
          searchEngines={searchEngines}
          deviceTypes={deviceTypes}
          showTooltip={showTooltip}
          openTooltip={openTooltip}
          onOpenTooltip={onOpenTooltip}
          onClickTooltip={onClickTooltip}
        />
      ) : null}

      {isNew ? <BusinessNameField formik={formik} /> : null}

      {isNew ? <BusinessURLField formik={formik} /> : null}

      {isNew ? (
        <URLSection
          formik={formik}
          showTooltip={showTooltip}
          openTooltip={openTooltip}
          onOpenTooltip={onOpenTooltip}
          onClickTooltip={onClickTooltip}
        />
      ) : null}

      {isOpenKeywords ? (
        <Grid item xs={12}>
          <Grid container fontWeight='500' alignItems='center' fontSize='14px'>
            <Grid item>Keywords</Grid>
            <CustomSwitch
              checked={isBulkKeywords}
              onChange={onToggleKeywords}
              sx={{ margin: '0 12px' }}
            />
            <Grid item>Bulk Input</Grid>
            <Grid item>
              <CustomTooltip
                title={<Tooltips.Keywords />}
                placement='top-start'
                arrow
                open={showTooltip && openTooltip === 1}
                onOpen={() => onOpenTooltip(1)}
                onClose={() => onClickTooltip(1)}
                onClick={() => onClickTooltip(1)}
              >
                <ButtonTooltipContainer onClick={() => onClickTooltip(1)}>
                  <HelperIcon />
                </ButtonTooltipContainer>
              </CustomTooltip>
            </Grid>

            <FieldArray
              name='keywords'
              render={arrayHelpers => (
                <Grid
                  width='100%'
                  display='flex'
                  gap='6px'
                  flexDirection='column'
                >
                  {!isBulkKeywords ? (
                    formik.values.keywords.map((item, index) => (
                      <Grid
                        key={index}
                        onClick={() => {
                          if (formik.values.keywords.length === index + 1) {
                            arrayHelpers.push('');
                          }
                        }}
                      >
                        <ModalOutlinedInput
                          size='small'
                          fullWidth
                          type='text'
                          name={`keywords[${index}]`}
                          placeholder='Enter keywords'
                          value={formik.values.keywords[index]}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={
                            !!(
                              formik.touched.keywords &&
                              formik.errors?.keywords &&
                              formik.errors?.keywords[index]
                            )
                          }
                        />
                        {formik.touched.keywords &&
                          formik.errors?.keywords &&
                          typeof formik.errors?.keywords !== 'string' &&
                          formik.errors?.keywords[index] && (
                            <FormHelperText error id='keywords'>
                              <CustomFormHelperText
                                error={formik.errors?.keywords[index]}
                              />
                            </FormHelperText>
                          )}
                      </Grid>
                    ))
                  ) : (
                    <>
                      <OutlinedInput
                        sx={{ padding: '8px 12px' }}
                        multiline
                        rows={4}
                        size='small'
                        fullWidth
                        type='text'
                        name={`keywords[0]`}
                        placeholder='Enter keywords to track (one per line)'
                        value={formik.values.keywords.join('\n')}
                        onBlur={formik.handleBlur}
                        onChange={event => {
                          formik.setFieldValue(
                            'keywords',
                            event.target.value.split('\n')
                          );
                        }}
                        error={
                          !!(formik.touched.keywords && formik.errors?.keywords)
                        }
                      />
                      {ErrorForBulkImport}
                    </>
                  )}
                </Grid>
              )}
            />
          </Grid>
          {formik.touched.keywords &&
            typeof formik.errors?.keywords === 'string' && (
              <FormHelperText error id='keywords'>
                <CustomFormHelperText
                  error={formik.errors?.keywords as string}
                />
              </FormHelperText>
            )}
        </Grid>
      ) : (
        <Grid item xs={12} onClick={handleOpenKeywords} paddingTop='8px'>
          <GrayButtonCustom
            size='small'
            outline
            $padding='6px 12px'
            sx={{ gap: '4px' }}
          >
            <PlusIcon stroke='#344054' />
            Add Keywords
          </GrayButtonCustom>
        </Grid>
      )}

      <>
        {isNew ? (
          <>
            {isRenderSearchEngineDomain && (
              <Grid item xs={12}>
                <Grid
                  container
                  fontWeight='500'
                  alignItems='center'
                  fontSize='14px'
                >
                  <Grid item>Search Engine Domain</Grid>
                  <Grid item>
                    <CustomTooltip
                      title={<Tooltips.SearchEngineDomain />}
                      placement='top-start'
                      arrow
                      open={showTooltip && openTooltip === 3}
                      onOpen={() => onOpenTooltip(3)}
                      onClose={() => onClickTooltip(3)}
                      onClick={() => onClickTooltip(3)}
                    >
                      <ButtonTooltipContainer onClick={() => onClickTooltip(3)}>
                        <HelperIcon />
                      </ButtonTooltipContainer>
                    </CustomTooltip>
                  </Grid>
                  <Autocomplete
                    sx={ModalAutocompleteStylesProps}
                    fullWidth
                    size={'small'}
                    id='regionId'
                    options={googleDomains}
                    getOptionLabel={option =>
                      `${getFlagEmoji(option.name)} ${option.countryName} (${
                        option.name
                      })`
                    }
                    value={
                      formik.values.regionId.name
                        ? formik.values.regionId
                        : null
                    }
                    onChange={(_, value) =>
                      formik.setFieldValue('regionId', value || '')
                    }
                    onBlur={formik.handleBlur}
                    popupIcon={<SelectIcon />}
                    renderInput={params => (
                      <TextField
                        {...params}
                        sx={{ paddingLeft: '2px' }}
                        placeholder='Select region'
                        name='regionId'
                        error={
                          !!(formik.touched.regionId && formik.errors.regionId)
                        }
                      />
                    )}
                  />
                  {formik.touched.regionId && formik.errors.regionId?.name && (
                    <FormHelperText error id='regionId'>
                      <CustomFormHelperText
                        error={formik.errors.regionId.name}
                      />
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            )}

            <SearchLocation formik={formik} isNew={isNew} />

            {isAuth && (
              <>
                <Grid item xs={12} paddingTop='8px'>
                  <Grid
                    container
                    fontWeight='500'
                    alignItems='center'
                    fontSize='14px'
                    gap='6px'
                  >
                    <Grid item>Folder (Optional)</Grid>
                    <ModalOutlinedInput
                      size='small'
                      fullWidth
                      type='text'
                      id='folder'
                      name='folder'
                      placeholder='My Test Folder '
                      value={formik.values.folder?.name}
                      onClick={handleOpenMoveToFolder}
                    />
                    {formik.touched.folder && formik.errors.folder && (
                      <FormHelperText error id='folder'>
                        <CustomFormHelperText error={formik.errors.folder} />
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </>
        ) : isOpenKeywords ? (
          <>
            <Grid item xs={12}>
              <Grid
                container
                fontWeight='500'
                alignItems='center'
                fontSize='14px'
              >
                <Grid item>Search Device Type</Grid>
                <Grid item>
                  <CustomTooltip
                    title={<Tooltips.SearchDeviceType />}
                    placement='top-start'
                    arrow
                    open={showTooltip && openTooltip === 5}
                    onOpen={() => onOpenTooltip(5)}
                    onClose={() => onClickTooltip(5)}
                    onClick={() => onClickTooltip(5)}
                  >
                    <ButtonTooltipContainer onClick={() => onClickTooltip(5)}>
                      <HelperIcon />
                    </ButtonTooltipContainer>
                  </CustomTooltip>
                </Grid>
              </Grid>
              <DeviceTypeContainer>
                {filterDeviceTypes.map(item => (
                  <DeviceType
                    key={item.name}
                    isSelected={formik.values.deviceType.name === item.name}
                    onClick={() => {
                      formik.setFieldValue('deviceType', item);
                    }}
                  >
                    <>
                      {getDeviceTypeIcon(item.name)}
                      <Typography component='span'>
                        {item.name === 'DesktopAndMobile'
                          ? 'Desktop & Mobile'
                          : item.name}
                      </Typography>
                    </>
                  </DeviceType>
                ))}
              </DeviceTypeContainer>
            </Grid>
            {formik.values.deviceType.name === 'DesktopAndMobile' ? (
              <Grid item xs={12}>
                <NotificationDeviceType />
              </Grid>
            ) : null}
          </>
        ) : null}
      </>

      <Grid
        sx={{
          paddingTop: '8px',
          alignItems: 'center',
          display: 'flex',
          fontSize: '14px',
          color: '#126FE9',
          fontWeight: 500,
          cursor: 'pointer',
        }}
        onClick={onToggleAdvancedSettings}
      >
        <span>
          {isOpenAdvancedSettings
            ? 'Hide Advanced Settings'
            : 'Show Advanced Settings '}
        </span>
        {isOpenAdvancedSettings ? (
          <KeyboardArrowUpIcon />
        ) : (
          <KeyboardArrowDownIcon />
        )}
      </Grid>
      <Grid>
        <SwipeableDrawer
          container={() => document.querySelector('.moveToFolder')}
          anchor={'bottom'}
          open={isOpenMoveToFolder}
          disableBackdropTransition
          disableDiscovery
          disableSwipeToOpen
          onClose={handleCloseMoveToFolder}
          onOpen={handleOpenMoveToFolder}
        >
          <MoveToFolder
            isOpen={isOpenMoveToFolder}
            handleClose={handleCloseMoveToFolder}
            setFolder={setFolder}
          />
        </SwipeableDrawer>
      </Grid>
    </>
  );
};

export default MainSettings;
