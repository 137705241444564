/**
 * Calculates the width of a column based on the length of a given string.
 *
 * @param {string} field - The string used to calculate the column width.
 * @returns {number} The calculated column width.
 */
function getColumnWidth(field: string) {
  return field.length * 9;
}

/**
 * Finds the longest string in an array of strings.
 *
 * @param {string[]} arr - An array of strings to search.
 * @returns {string|null} The longest string in the array, or null if the array is empty or invalid.
 */
function findLongestString(arr: string[]) {
  const noUndefinedArr = arr?.filter(item => item !== undefined);

  if (
    !noUndefinedArr ||
    !Array.isArray(noUndefinedArr) ||
    noUndefinedArr.length === 0
  ) {
    return null;
  }

  let longestString = noUndefinedArr[0];

  for (let i = 1; i < noUndefinedArr.length; i++) {
    const currentString = noUndefinedArr[i];
    if (currentString.length > longestString.length) {
      longestString = currentString;
    }
  }

  return longestString;
}

/**
 * Returns the number of visible columns based on the column visibility model.
 *
 * @param {Record<string, boolean>} columnVisibilityModel - An object representing the visibility state of columns.
 * @returns {number} The count of visible columns.
 */
function getPinColIndex(columnVisibilityModel: Record<string, boolean>) {
  return Object.entries(columnVisibilityModel)
    .filter(([key, value]) => value)
    .map(([key]) => key).length;
}

/**
 * Checks if the user's browser is Safari and below a specified version.
 *
 * @param {number} version - The version number to compare against.
 * @returns {boolean} True if the browser is Safari and below the specified version; otherwise, false.
 */
function isSafariBelowVersion(version: number): boolean {
  const userAgent = navigator.userAgent;

  return (
    userAgent.indexOf('Safari') !== -1 &&
    userAgent.indexOf('Chrome') === -1 &&
    parseFloat(userAgent.substring(userAgent.indexOf('Version/') + 8)) < version
  );
}

/**
 * Calculates the minimum line size for the main column based on a character string and icon width.
 *
 * @param {string} character - The character string used for the calculation.
 * @param {number} iconWidth - The width of the icon to be included in the line size.
 * @returns {number} The minimum line size for the main column.
 */
const getMinLineSizeForMainColumn = (character: string, iconWidth: number) => {
  const minNumberOfCharacters = 40;
  if (character.length < minNumberOfCharacters) {
    return character.length * 9 + iconWidth;
  } else {
    return minNumberOfCharacters * 9;
  }
};

export {
  getColumnWidth,
  findLongestString,
  getPinColIndex,
  isSafariBelowVersion,
  getMinLineSizeForMainColumn,
};
