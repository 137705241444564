import { Grid } from '@mui/material';
import React from 'react';

import UpgradePlan from '../features/auth/components/UpgradePlan/UpgradePlan';

const UpgradePlanPage = () => {
  return (
    <Grid container xs={12}>
      <Grid item xs={12}>
        <UpgradePlan />
      </Grid>
    </Grid>
  );
};

export default UpgradePlanPage;
