import React from 'react';
import { Divider, List } from '@mui/material';
import { EllipsisListActionsProps } from '../types';

import DeleteFolderAction from './DeleteFolderAction';
import RenameAction from './RenameAction';
import RefreshAllKeywordsAction from './RefreshAllKeywordsAction';
import EditFrequencyAction from '../../../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/components/EditFrequencyAction';
import EditProjectTagsAction from '../../../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/components/EditProjectTagsAction';

import { MainContainer } from '../styles';
import MoveToFolderAction from './MoveToFolderAction';
import DownloadProjectInfoToCSVAction from '../../../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/components/DownloadProjectInfoToCSVAction';

const ListActions: React.FC<EllipsisListActionsProps> = ({
  itemId,
  itemName,
  itemType,
  popupClose,
  keywordCount,
  disableExportCSV,
}) => {
  return (
    <MainContainer>
      <List>
        {itemType === 'project' ? (
          <EditFrequencyAction projectId={itemId} popupClose={popupClose} />
        ) : null}

        {itemType === 'folder' ? (
          <RenameAction
            folderId={itemId}
            folderName={itemName}
            popupClose={popupClose}
          />
        ) : null}

        <RefreshAllKeywordsAction
          keywordCount={keywordCount}
          itemId={itemId}
          itemType={itemType}
          popupClose={popupClose}
        />

        {itemType === 'project' ? (
          <EditProjectTagsAction projectId={itemId} popupClose={popupClose} />
        ) : null}

        <MoveToFolderAction
          itemType={itemType}
          currentItemId={itemId}
          popupClose={popupClose}
        />

        {itemType === 'project' ? (
          <DownloadProjectInfoToCSVAction
            projectId={itemId}
            popupClose={popupClose}
            disableExportCSV={disableExportCSV}
          />
        ) : null}

        <Divider
          orientation='horizontal'
          flexItem
          sx={{
            margin: '8px 0',
            color: '#EDEDED',
          }}
        />

        <DeleteFolderAction
          itemId={itemId}
          itemName={itemName}
          itemType={itemType}
          popupClose={popupClose}
          keywordCount={0}
        />
      </List>
    </MainContainer>
  );
};

export default ListActions;
