import React, { memo, useMemo } from 'react';
import {
  CustomTooltip,
  DateCustomTooltip,
} from '../../../../../../../../../../../../shared/tooltip/styles';
import TextTooltip from '../../../../../../../../../../../../shared/components/TextTooltip';
import {
  getBestTooltipForKeywordRankingsTable,
  getBestValueForKeywordRankingsTable,
  getPositionIconForKeywordRankingsTable,
  getPositionTooltipForKeywordRankingsTable,
  getPositionValueForKeywordRankingsTable,
  getStartTooltipForKeywordRankingsTable,
  getStartValueForKeywordRankingsTable,
  getTopIconForKeywordRankingsTable,
  getTopValueForKeywordRankingsTable,
} from '../../../../../../../../../../utils';
import { Box, Grid } from '@mui/material';
import PositionInfoTooltip from '../../../../../../../../../../../../shared/components/PositionInfoTooltip/PositionInfoTooltip';
import Dash from '../../../../../../../../../Dash';
import RefreshKeywordAction from '../refresh/RefreshKeywordAction';
import { convertToTimezone } from '../../../../../../../../../../../../utils/date';
import { SERVER_UTS } from '../../../../../../../../../../../../constants/config';

export const popperProps = {
  sx: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: '#fff',
      color: '#344054',
      boxShadow:
        '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
      border: '1px solid #EDEDED',
    },
    '.MuiTooltip-arrow::before': {
      backgroundColor: '#fff',
      border: '1px solid #EDEDED',
    },
  },
};

export interface RenderCellProps {
  row: any;
}

const RenderCellPosition: React.FC<RenderCellProps> = params => {
  const color = useMemo(
    () =>
      params.row.position.isImproved
        ? '#007C65'
        : params.row.position.isDeclined
        ? '#D1002F'
        : '#101828',
    [params.row.position.isDeclined, params.row.position.isImproved]
  );
  const textTooltip = useMemo(
    () => (
      <TextTooltip
        text={getPositionTooltipForKeywordRankingsTable(params.row.position)}
      />
    ),
    [params.row.position]
  );

  const icon = useMemo(
    () => getPositionIconForKeywordRankingsTable(params.row.position),
    [params.row.position]
  );

  const value = useMemo(
    () => getPositionValueForKeywordRankingsTable(params.row.position),
    [params.row.position]
  );

  return (
    <CustomTooltip
      title={textTooltip}
      placement='top-start'
      arrow
      PopperProps={popperProps}
    >
      <Box display='flex' alignItems='center'>
        <Grid container gap={'6px'} alignItems={'center'}>
          <Grid item display={'contents'}>
            {icon}
          </Grid>
          <Grid
            item
            style={{ textAlign: 'center', wordSpacing: '-1px' }}
            sx={{
              color: color,
            }}
          >
            {value}
          </Grid>
        </Grid>
      </Box>
    </CustomTooltip>
  );
};

const RenderCellD1: React.FC<RenderCellProps> = params => {
  const color = useMemo(
    () =>
      params.row.d1.isImproved
        ? '#007C65'
        : params.row.d1.isDeclined
        ? '#D1002F'
        : '#101828',
    [params.row.d1.isDeclined, params.row.d1.isImproved]
  );

  const textTooltip = useMemo(
    () =>
      !params.row.d1.dash ? (
        <PositionInfoTooltip
          positionInfo={params.row.d1.positionInfo}
          position={params.row.position}
          changes={params.row.d1.changes}
        />
      ) : (
        <Grid>No data</Grid>
      ),
    [
      params.row.d1.changes,
      params.row.d1.dash,
      params.row.d1.positionInfo,
      params.row.position,
    ]
  );

  const icon = useMemo(
    () => getTopIconForKeywordRankingsTable(params.row.d1),
    [params.row.d1]
  );

  const value = useMemo(
    () =>
      getTopValueForKeywordRankingsTable(params.row.d1, params.row.position),
    [params.row.d1, params.row.position]
  );

  return (
    <CustomTooltip
      title={textTooltip}
      placement='top-start'
      arrow
      PopperProps={popperProps}
    >
      <Box display='flex' alignItems='center'>
        <Grid container gap={'6px'} alignItems={'center'}>
          <Grid item display={'contents'}>
            {icon}
          </Grid>
          <Grid
            width={'20px'}
            textAlign={'center'}
            item
            sx={{
              color: color,
            }}
          >
            {value}
          </Grid>
        </Grid>
      </Box>
    </CustomTooltip>
  );
};

const RenderCellD7: React.FC<RenderCellProps> = params => {
  const color = useMemo(
    () =>
      params.row.d7.isImproved
        ? '#007C65'
        : params.row.d7.isDeclined
        ? '#D1002F'
        : '#101828',
    [params.row.d7.isDeclined, params.row.d7.isImproved]
  );

  const textTooltip = useMemo(
    () =>
      !params.row.d7.dash ? (
        <PositionInfoTooltip
          positionInfo={params.row.d7.positionInfo}
          position={params.row.position}
          changes={params.row.d7.changes}
        />
      ) : (
        <Grid>No data</Grid>
      ),
    [
      params.row.d7.changes,
      params.row.d7.dash,
      params.row.d7.positionInfo,
      params.row.position,
    ]
  );

  const icon = useMemo(
    () => getTopIconForKeywordRankingsTable(params.row.d7),
    [params.row.d7]
  );

  const value = useMemo(
    () =>
      getTopValueForKeywordRankingsTable(params.row.d7, params.row.position),
    [params.row.d7, params.row.position]
  );

  return (
    <CustomTooltip
      title={textTooltip}
      placement='top-start'
      arrow
      PopperProps={popperProps}
    >
      <Box display='flex' alignItems='center'>
        <Grid container gap={'6px'} alignItems={'center'}>
          <Grid item display={'contents'}>
            {icon}
          </Grid>
          <Grid
            width={'20px'}
            textAlign={'center'}
            item
            sx={{
              color: color,
            }}
          >
            {value}
          </Grid>
        </Grid>
      </Box>
    </CustomTooltip>
  );
};

const RenderCellD30: React.FC<RenderCellProps> = params => {
  const color = useMemo(
    () =>
      params.row.d30.isImproved
        ? '#007C65'
        : params.row.d30.isDeclined
        ? '#D1002F'
        : '#101828',
    [params.row.d30.isDeclined, params.row.d30.isImproved]
  );

  const textTooltip = useMemo(
    () =>
      !params.row.d30.dash ? (
        <PositionInfoTooltip
          positionInfo={params.row.d30.positionInfo}
          position={params.row.position}
          changes={params.row.d30.changes}
        />
      ) : (
        <Grid>No data</Grid>
      ),
    [
      params.row.d30.changes,
      params.row.d30.dash,
      params.row.d30.positionInfo,
      params.row.position,
    ]
  );

  const icon = useMemo(
    () => getTopIconForKeywordRankingsTable(params.row.d30),
    [params.row.d30]
  );

  const value = useMemo(
    () =>
      getTopValueForKeywordRankingsTable(params.row.d30, params.row.position),
    [params.row.d30, params.row.position]
  );

  return (
    <CustomTooltip
      title={textTooltip}
      placement='top-start'
      arrow
      PopperProps={popperProps}
    >
      <Box display='flex' alignItems='center'>
        <Grid container gap={'6px'} alignItems={'center'}>
          <Grid item display={'contents'}>
            {icon}
          </Grid>
          <Grid
            width={'20px'}
            textAlign={'center'}
            item
            sx={{
              color: color,
            }}
          >
            {value}
          </Grid>
        </Grid>
      </Box>
    </CustomTooltip>
  );
};

const RenderCellLife: React.FC<RenderCellProps> = params => {
  const color = useMemo(
    () =>
      params.row.life.isImproved
        ? '#007C65'
        : params.row.life.isDeclined
        ? '#D1002F'
        : '#101828',
    [params.row.life.isDeclined, params.row.life.isImproved]
  );

  const textTooltip = useMemo(
    () =>
      !params.row.life.dash ? (
        <PositionInfoTooltip
          positionInfo={params.row.life.positionInfo}
          position={params.row.position}
          changes={params.row.life.changes}
        />
      ) : (
        <Grid>No data</Grid>
      ),
    [
      params.row.life.changes,
      params.row.life.dash,
      params.row.life.positionInfo,
      params.row.position,
    ]
  );

  const icon = useMemo(
    () => getTopIconForKeywordRankingsTable(params.row.life),
    [params.row.life]
  );

  const value = useMemo(
    () =>
      getTopValueForKeywordRankingsTable(params.row.life, params.row.position),
    [params.row.life, params.row.position]
  );

  return (
    <CustomTooltip
      title={textTooltip}
      placement='top-start'
      arrow
      PopperProps={popperProps}
    >
      <Box display='flex' alignItems='center'>
        <Grid container gap={'6px'} alignItems={'center'}>
          <Grid item display={'contents'}>
            {icon}
          </Grid>
          <Grid
            width={'20px'}
            textAlign={'center'}
            item
            sx={{
              color: color,
            }}
          >
            {value}
          </Grid>
        </Grid>
      </Box>
    </CustomTooltip>
  );
};

const RenderCellBest: React.FC<RenderCellProps> = params => {
  const textTooltip = useMemo(
    () => (
      <TextTooltip
        text={getBestTooltipForKeywordRankingsTable(params.row.best)}
      />
    ),
    [params.row.best]
  );

  const value = useMemo(
    () => getBestValueForKeywordRankingsTable(params.row.best),
    [params.row.best]
  );

  return (
    <CustomTooltip
      title={textTooltip}
      placement='top-start'
      arrow
      PopperProps={popperProps}
    >
      {<div>{value}</div>}
    </CustomTooltip>
  );
};

const RenderCellFirst: React.FC<RenderCellProps> = params => {
  const textTooltip = useMemo(
    () => (
      <TextTooltip
        text={
          !params.row.first.dash
            ? getStartTooltipForKeywordRankingsTable(params.row.first.position)
            : '-'
        }
      />
    ),
    [params.row.first.dash, params.row.first.position]
  );

  const value = useMemo(
    () =>
      !params.row.first.dash ? (
        getStartValueForKeywordRankingsTable(params.row.first.position)
      ) : (
        <Dash />
      ),
    [params.row.first.dash, params.row.first.position]
  );

  return (
    <CustomTooltip
      title={textTooltip}
      placement='top-start'
      arrow
      PopperProps={popperProps}
    >
      <div>{value}</div>
    </CustomTooltip>
  );
};

const RenderCellSearchValue: React.FC<RenderCellProps> = params => {
  const textTooltip = useMemo(
    () => <TextTooltip text={params.row.searchValue || 'No data'} />,
    [params.row.searchValue]
  );

  const value = useMemo(
    () => params.row.searchValue || <Dash />,
    [params.row.searchValue]
  );

  return (
    <CustomTooltip
      title={textTooltip}
      placement='top-start'
      arrow
      PopperProps={popperProps}
    >
      <div>{value}</div>
    </CustomTooltip>
  );
};

const RenderCellCPC: React.FC<RenderCellProps> = params => {
  const textTooltip = useMemo(
    () => <TextTooltip text={params.row.cpc || 'No data'} />,
    [params.row.cpc]
  );

  const value = useMemo(() => params.row.cpc || <Dash />, [params.row.cpc]);

  return (
    <CustomTooltip
      title={textTooltip}
      placement='top-start'
      arrow
      PopperProps={popperProps}
    >
      <div>{value}</div>
    </CustomTooltip>
  );
};

const RenderCellURL: React.FC<RenderCellProps> = params => {
  const textTooltip = useMemo(
    () => <TextTooltip text={params.row.url || <Dash />} />,
    [params.row.url]
  );

  const value = useMemo(() => params.row.url || <Dash />, [params.row.url]);

  return (
    <CustomTooltip
      title={textTooltip}
      placement='top-start'
      arrow
      PopperProps={popperProps}
    >
      {value}
    </CustomTooltip>
  );
};

const RenderCellUpdated: React.FC<
  RenderCellProps & {
    showUpdatedFullFormat: boolean;
    handleSetShowUpdatedFullFormat: (value: boolean) => void;
    utc: string;
  }
> = params => {
  const date = useMemo(
    () =>
      params.row.updated
        ? params.showUpdatedFullFormat
          ? convertToTimezone(
              params.row.updatedFullFormat,
              SERVER_UTS,
              params.utc
            )
          : params.row.updated
        : '-',
    [
      params.row.updated,
      params.row.updatedFullFormat,
      params.showUpdatedFullFormat,
      params.utc,
    ]
  );
  const textTooltip = useMemo(() => <TextTooltip text={date} />, [date]);

  return (
    <DateCustomTooltip
      title={textTooltip}
      placement='top-start'
      arrow
      PopperProps={popperProps}
      onClick={e => {
        if (params.row.updated) {
          e.stopPropagation();
          params.handleSetShowUpdatedFullFormat(!params.showUpdatedFullFormat);
        }
      }}
    >
      {date}
    </DateCustomTooltip>
  );
};

const RenderCellRefresh: React.FC<{
  isViewOnly: boolean;
  keyword: any;
}> = params => {
  if (params.isViewOnly) return null;

  return !params.isViewOnly ? (
    <RefreshKeywordAction keyword={params.keyword} />
  ) : null;
};

const MemoRenderCellPosition = memo(RenderCellPosition);
const MemoRenderCellD1 = memo(RenderCellD1);
const MemoRenderCellD7 = memo(RenderCellD7);
const MemoRenderCellD30 = memo(RenderCellD30);
const MemoRenderCellLife = memo(RenderCellLife);
const MemoRenderCellBest = memo(RenderCellBest);
const MemoRenderCellFirst = memo(RenderCellFirst);
const MemoRenderCellSearchValue = memo(RenderCellSearchValue);
const MemoRenderCellCPC = memo(RenderCellCPC);
const MemoRenderCellURL = memo(RenderCellURL);
const MemoRenderCellUpdated = memo(RenderCellUpdated);
const MemoRenderCellRefresh = memo(RenderCellRefresh);

export {
  MemoRenderCellPosition,
  MemoRenderCellD1,
  MemoRenderCellD7,
  MemoRenderCellD30,
  MemoRenderCellLife,
  MemoRenderCellBest,
  MemoRenderCellFirst,
  MemoRenderCellSearchValue,
  MemoRenderCellCPC,
  MemoRenderCellURL,
  MemoRenderCellUpdated,
  MemoRenderCellRefresh,
};
