import React, { useMemo } from 'react';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useFolders } from '../../../../../../../../hooks';

import ListActions from './components/ListActions';
import EllipsisListActions from '../EllipsisMenu/components/ListActions';
import DownIcon from '../../../../../../../../shared/images/DownIcon';

import { PrimaryButtonCustom } from '../../../../../../../../shared/buttons/styles';
import { Popover } from '../../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';

const ActionsMenu = () => {
  const {
    folders,
    tables: {
      rootFolder: { rowSelectionModel },
    },
  } = useFolders();

  const actionItem = useMemo(() => {
    return folders?.items?.find(
      folder => folder.id === rowSelectionModel[0]?.id
    );
  }, [folders?.items, rowSelectionModel]);

  return (
    <PopupState variant='popover'>
      {popupState => (
        <>
          <PrimaryButtonCustom
            $padding='8px 12px'
            variant='contained'
            size='small'
            endIcon={<DownIcon />}
            {...bindTrigger(popupState)}
          >
            Actions
          </PrimaryButtonCustom>

          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {rowSelectionModel.length !== 1 ? (
              <ListActions popupClose={popupState.close} />
            ) : (
              <EllipsisListActions
                itemId={Number(rowSelectionModel[0].id)}
                itemName={rowSelectionModel[0].name}
                itemType={rowSelectionModel[0].type || 'folder'}
                popupClose={popupState.close}
                keywordCount={
                  rowSelectionModel[0].totalKeywords ||
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  rowSelectionModel[0].keywordCount ||
                  0
                }
                disableExportCSV={
                  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                  // @ts-ignore
                  !!actionItem?.isUpdated
                }
              />
            )}
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export default ActionsMenu;
