import { Grid, useMediaQuery } from '@mui/material';
import React, { useMemo } from 'react';
import EmptyBoxIcon from '../../images/EmptyBoxIcon';
import { PrimaryButtonCustom } from '../../buttons/styles';
import { StylesSupportingText, StylesText } from './styles';
import { useAppSelector } from '../../../store';

interface EmptyTableProps {
  text: string;
  supportingText: string;
  buttonText: string;
  handleButton: () => void;
  startIcon: React.ReactNode;
}

const EmptyTable: React.FC<EmptyTableProps> = ({
  text,
  supportingText,
  buttonText,
  startIcon,
  handleButton,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const isAuth = useAppSelector(state => state.auth.isAuth);

  const isViewOnly = useMemo(
    () => !isAuth || (isAuth && currentAccount?.role?.name === 'ViewOnly'),
    [currentAccount?.role?.name, isAuth]
  );

  return (
    <Grid
      container
      width='100%'
      height='100%'
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      padding={'40px'}
    >
      <Grid item>
        <EmptyBoxIcon />
      </Grid>
      <Grid
        paddingTop='24px'
        paddingBottom={extraSmallScreen ? '20px' : '32px'}
      >
        <StylesText item>{text}</StylesText>
        <StylesSupportingText item>{supportingText}</StylesSupportingText>
      </Grid>
      <Grid item>
        <PrimaryButtonCustom
          variant='contained'
          size='small'
          startIcon={startIcon}
          onClick={handleButton}
          disabled={isViewOnly}
        >
          {buttonText}
        </PrimaryButtonCustom>
      </Grid>
    </Grid>
  );
};

export default EmptyTable;
