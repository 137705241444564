import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StyledStarterPlan = styled(Grid)`
  color: #101828;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`;

const StyledChip = styled(Grid)`
  border-radius: 16px;
  background: #eef3ff;
  color: #126fe9;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 10px;

  :first-letter {
    text-transform: uppercase;
  }
`;

const StyledСurrency = styled(Grid)`
  color: #101828;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.48px;
`;

const StyledPrice = styled(Grid)`
  color: #101828;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.96px;
`;

const StyledPeriod = styled(Grid)`
  color: #475467;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const StyledDescription = styled(Grid)`
  color: #475467;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const StyledSwitchAnnualPayment = styled(Grid)`
  color: #126fe9;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  cursor: pointer;
`;

const StyledSPriceContainer = styled(Grid)`
  @media (max-width: 470px) {
    padding-top: 16px;
`;
export {
  StyledStarterPlan,
  StyledChip,
  StyledСurrency,
  StyledPrice,
  StyledPeriod,
  StyledDescription,
  StyledSwitchAnnualPayment,
  StyledSPriceContainer,
};
