import { styled } from '@mui/system';
import { Link, Typography } from '@mui/material';

const FooterWrapper = styled('div')`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fafafa;
`;

const FooterContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 14px;
  border-top: 1px solid rgba(102, 112, 133, 0.3);
  margin-top: 70px;

  ${props => props.theme.breakpoints.down('md')} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column-reverse;
    align-items: center;
    padding-top: 35px;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    align-items: flex-start;
  }
`;

const FooterMobileContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;

  > div:last-child {
    padding-right: 50px;
  }
`;

const FooterItems = styled('div')`
  display: flex;
  flex-wrap: wrap;
  ${props => props.theme.breakpoints.down('sm')} {
    justify-content: space-around;
    padding-top: 10px;
  }
`;

const FooterItem = styled(Link)`
  padding-right: 16px;

  display: block;
  text-transform: none;
  text-decoration: none;
  cursor: pointer;
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;

  ${props => props.theme.breakpoints.down('sm')} {
    justify-content: space-around;
    padding-bottom: 15px;
  }

  :last-child {
    padding-right: 0;
  }
`;

const FooterMobileLabel = styled(Typography)`
  ${props => props.theme.breakpoints.down('md')} {
    padding-top: 16px;
  }
`;

export {
  FooterWrapper,
  FooterContainer,
  FooterMobileContainer,
  FooterItems,
  FooterItem,
  FooterMobileLabel,
};
