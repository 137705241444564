import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import React from 'react';

import { DeleteAccountStep3Props } from './types';
import Trash from '../../../../../../shared/images/Trash';
import { useAuth } from '../../../../../../hooks';

import {
  HeaderContainer,
  CloseDeletePopoverOne,
  ButtonsWrapper,
  CustomModal,
  ModalContainer,
} from './styles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../../shared/buttons/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from '../../../../../../store';

/**
 * CancelDeleteModal component allows users to cancel the deletion process of their account by showing a confirmation modal.
 *
 * @component
 * @param {DeleteAccountStep3Props} props - The props for the CancelDeleteModal component.
 * @param {boolean} props.open - Boolean flag to control if the modal is open.
 * @param {Function} props.handleClose - Function to handle the closing of the modal.
 * @param {Function} [props.handleSubmit] - Optional function to handle additional actions after cancellation.
 * @returns {JSX.Element} Rendered CancelDeleteModal component.
 */
const CancelDeleteModal: React.FC<DeleteAccountStep3Props> = ({
  open,
  handleClose,
  handleSubmit,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');
  const { onCancelAccountDeletionBranding, onGetMyAccount } = useAuth();
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  /**
   * Handles the account deletion cancellation process by calling onCancelAccountDeletionBranding and optionally fetching the account details.
   */
  const onDelete = () => {
    onCancelAccountDeletionBranding({
      id: currentAccount.accountId,
      successFn: () => {
        currentAccount?.accountId && onGetMyAccount(currentAccount.accountId);
        if (handleSubmit) {
          handleSubmit();
        }
      },
    });
  };

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <CustomModal>
          <ModalContainer>
            <HeaderContainer>
              <CloseDeletePopoverOne color='error' onClick={handleClose}>
                <CloseIcon fontSize='small' />
              </CloseDeletePopoverOne>
              <Trash />
              <Typography variant='h6' fontWeight='600' paddingTop='29px'>
                Cancel deletion?
              </Typography>
            </HeaderContainer>
            <div>
              <Typography
                fontWeight='500'
                fontSize='14px'
                lineHeight='24px'
                color='#475467'
                paddingTop='30px'
                paddingBottom='30px'
              >
                Are you sure you want to cancel the deletion of your account?
              </Typography>
              <Typography
                fontWeight='500'
                fontSize='14px'
                lineHeight='24px'
                color='#475467'
              >
                If you choose to cancel this request, your account and all
                associated data will be retained. You can continue using our
                services as before.
              </Typography>
            </div>
            <ButtonsWrapper container xs={12}>
              <Grid item xs={extraSmallScreen && 12}>
                <GrayButtonSmall
                  fullWidth={extraSmallScreen}
                  size='medium'
                  outline
                  onClick={handleClose}
                >
                  Dismiss
                </GrayButtonSmall>
              </Grid>
              <Grid item xs={extraSmallScreen && 12}>
                <PrimaryButtonSmall
                  fullWidth={extraSmallScreen}
                  onClick={onDelete}
                  variant='contained'
                  color='primary'
                >
                  Yes, Keep My Account
                </PrimaryButtonSmall>
              </Grid>
            </ButtonsWrapper>
          </ModalContainer>
        </CustomModal>
      </Fade>
    </Modal>
  );
};

export default CancelDeleteModal;
