import React, { useCallback, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import {
  StylesPlanDescription,
  StylesPlanName,
  StylesPlanPerMonth,
  StylesPlanPrice,
  StylesTable,
  StylesTh,
  StylesTr,
  StylesPriceContainer,
} from './styles';
import CheckIcon from '../../../../../../shared/images/CheckIcon';
import { PrimaryButton } from '../../../../../../shared/buttons/styles';
import { PricingTableProps } from '../../types';
import usePayment from '../../../../../payment/hooks/usePayment';
import { ENVIRONMENT } from '../../../../../../constants/config';
import ReviewUpdateSubscriptionModal from '../../../../../payment/components/ReviewUpdateSubscriptionModal/ReviewUpdateSubscriptionModal';
import StarterIcon from '../../../../../../shared/images/StarterIcon';
import ProfessionalIcon from '../../../../../../shared/images/ProfessionalIcon';
import EnterpriseIcon from '../../../../../../shared/images/EnterpriseIcon';
import { useAppSelector } from '../../../../../../store';
import { ITariffPlan } from '../../../../types';
import PricingTableHeader from '../PricingTableHeader/PricingTableHeader';
import { UpgradePlanPrimaryButton } from '../../styles';

const PricingTable: React.FC<PricingTableProps> = ({
  mainTariffPlans,
  planType,
  setPlanType,
}) => {
  const { paddle } = usePayment();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const billing = useAppSelector(state => state.auth.payment.billing);
  const checkoutSettings = useAppSelector(state => state.auth.checkoutSettings);

  const [activePlan, setActivePlan] = useState<number | null>(null);
  const [isReviewUpdateSubscriptionModal, setIsReviewUpdateSubscriptionModal] =
    useState(false);
  const [activePaddleProductId, setActivePaddleProductId] = useState<
    null | string
  >(null);

  const userPlanCanceled = useMemo(
    () => billing?.status?.name === 'canceled',
    [billing?.status?.name]
  );

  const userPlanDeactivated = useMemo(
    () => billing?.status?.name === 'deactivated',
    [billing?.status?.name]
  );

  const userPlanName = useMemo(
    () => billing?.tariffPlan?.name || '',
    [billing?.tariffPlan?.name]
  );

  const starterPackage = useMemo(
    () => mainTariffPlans.find(item => item.name === 'Starter Plan'),
    [mainTariffPlans]
  );
  const professionalPackage = useMemo(
    () => mainTariffPlans.find(item => item.name === 'Professional Plan'),
    [mainTariffPlans]
  );
  const enterprisePackage = useMemo(
    () => mainTariffPlans.find(item => item.name === 'Enterprise Plan'),
    [mainTariffPlans]
  );

  const openPaddle = (paddleProductId?: string) => {
    if (
      paddle &&
      paddleProductId &&
      currentAccount?.accountId &&
      checkoutSettings
    ) {
      paddle.Checkout.open({
        items: [{ price_id: paddleProductId, quantity: 1 }],
        customer: { email: currentAccount.owner.ownerEmail },
        settings: {
          allowLogout: false,
          showAddDiscounts: checkoutSettings.showAddDiscounts,
          showAddTaxId: false,
          allowedPaymentMethods: checkoutSettings.paymentMethods,
        },
        customData: { id: currentAccount.accountId, environment: ENVIRONMENT },
      });
    }
  };

  const handleOpen = (paddleProductId?: string) => {
    if (paddleProductId) {
      if (
        billing &&
        billing.tariffPlan.name !== 'Trial Period' &&
        !userPlanDeactivated
      ) {
        setActivePaddleProductId(paddleProductId);
        setIsReviewUpdateSubscriptionModal(true);
      } else {
        openPaddle(paddleProductId);
      }
    }
  };

  const handleClose = () => {
    setActivePaddleProductId(null);
    setIsReviewUpdateSubscriptionModal(false);
  };

  const onSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setPlanType(checked ? 'yearly' : 'monthly');
  };

  const getDisabledPlan = useCallback(
    (plan?: ITariffPlan) => {
      return !billing
        ? true
        : userPlanDeactivated
        ? false
        : userPlanCanceled ||
          (billing?.tariffPlan?.id === plan?.id &&
            billing?.tariffPlan?.settings?.type?.name ===
              plan?.settings?.type?.name);
    },
    [billing, userPlanCanceled, userPlanDeactivated]
  );

  return (
    <>
      <PricingTableHeader
        onSwitch={onSwitch}
        planType={planType}
        userPlanName={userPlanName}
      />
      <Grid xs={12} container justifyContent={'space-between'}>
        <Grid item xs={3} paddingTop={'32px'}>
          <Grid
            container
            item
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
            style={{ visibility: 'hidden' }}
          >
            <Grid item>
              <StarterIcon />
            </Grid>
            <StylesPlanName item>Starter</StylesPlanName>
            <Grid
              container
              item
              justifyContent={'center'}
              alignItems={'flex-end'}
              spacing={1}
              flexWrap={'nowrap'}
            >
              <StylesPlanPrice item>$4.99</StylesPlanPrice>
              <StylesPlanPerMonth item>{`per ${
                planType === 'monthly' ? 'month' : 'year'
              }`}</StylesPlanPerMonth>
            </Grid>
            <StylesPlanDescription item>
              Best for growing business.
            </StylesPlanDescription>
            <Grid item paddingTop={'32px'}>
              <PrimaryButton variant='contained' size='medium' type='submit'>
                Get started
              </PrimaryButton>
            </Grid>
          </Grid>
          <Grid xs={12} padding={'40px 0'}>
            <StylesTable>
              <StylesTr>
                <StylesTh>Keywords tracked</StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>Unlimited domains</StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>Automatic daily updates</StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>On-demand checks</StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>Competitor tracking</StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>Email reports</StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>Alerts</StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>One-click sharing</StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>Folders</StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>White-label</StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>API</StylesTh>
              </StylesTr>
            </StylesTable>
          </Grid>
        </Grid>
        <StylesPriceContainer
          item
          xs={3}
          border={'1px solid red'}
          paddingTop={'24px'}
          onMouseEnter={() => setActivePlan(1)}
          onMouseLeave={() => setActivePlan(null)}
        >
          <Grid
            container
            item
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
            padding={'0 24px'}
          >
            <Grid item>
              <StarterIcon />
            </Grid>
            <StylesPlanName item>
              {starterPackage?.name || 'Package'}
            </StylesPlanName>
            <Grid
              container
              item
              justifyContent={'center'}
              alignItems={'flex-end'}
              spacing={1}
            >
              <StylesPlanPrice item>{`$${
                starterPackage?.settings?.price || '0'
              }`}</StylesPlanPrice>
              <StylesPlanPerMonth item>{`per ${
                planType === 'monthly' ? 'month' : 'year'
              }`}</StylesPlanPerMonth>
            </Grid>
            <StylesPlanDescription item>
              Best for growing business.
            </StylesPlanDescription>
            <Grid width={'100%'} item paddingTop={'32px'}>
              <UpgradePlanPrimaryButton
                fullWidth
                variant='contained'
                size='medium'
                type='submit'
                disabled={getDisabledPlan(starterPackage)}
                onClick={() =>
                  handleOpen(starterPackage?.settings?.paddleProductId)
                }
              >
                Get started
              </UpgradePlanPrimaryButton>
            </Grid>
          </Grid>
          <Grid xs={12} padding={'40px 0'}>
            <StylesTable>
              <StylesTr>
                <StylesTh>{`${
                  starterPackage?.settings?.dailyWordCount || '0'
                } keywords`}</StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
            </StylesTable>
          </Grid>
          <Grid container paddingBottom={'32px'} justifyContent={'center'}>
            <Grid xs={12} item padding={'0 24px'}>
              <UpgradePlanPrimaryButton
                fullWidth
                variant='contained'
                size='medium'
                type='submit'
                disabled={getDisabledPlan(starterPackage)}
                onClick={() =>
                  handleOpen(starterPackage?.settings?.paddleProductId)
                }
              >
                Get started
              </UpgradePlanPrimaryButton>
            </Grid>
          </Grid>
        </StylesPriceContainer>
        <StylesPriceContainer
          item
          xs={3}
          paddingTop={'24px'}
          style={{ border: '2px solid #2A7DEB' }}
          onMouseEnter={() => setActivePlan(2)}
          onMouseLeave={() => setActivePlan(null)}
        >
          <Grid
            container
            item
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
            padding={'0 24px'}
          >
            <Grid item>
              <ProfessionalIcon />
            </Grid>
            <StylesPlanName item>
              {professionalPackage?.name || 'Package'}
            </StylesPlanName>
            <Grid
              container
              item
              justifyContent={'center'}
              alignItems={'flex-end'}
              spacing={1}
              flexWrap={'nowrap'}
            >
              <StylesPlanPrice item>{`$${
                professionalPackage?.settings?.price || '0'
              }`}</StylesPlanPrice>
              <StylesPlanPerMonth item>{`per ${
                planType === 'monthly' ? 'month' : 'year'
              }`}</StylesPlanPerMonth>
            </Grid>
            <StylesPlanDescription item>
              Our most popular plan.
            </StylesPlanDescription>
            <Grid width={'100%'} item paddingTop={'32px'}>
              <UpgradePlanPrimaryButton
                fullWidth
                variant='contained'
                size='medium'
                type='submit'
                disabled={getDisabledPlan(professionalPackage)}
                onClick={() =>
                  handleOpen(professionalPackage?.settings?.paddleProductId)
                }
              >
                Get started
              </UpgradePlanPrimaryButton>
            </Grid>
          </Grid>
          <Grid xs={12} padding={'40px 0'}>
            <StylesTable>
              <StylesTr>
                <StylesTh>{`${
                  professionalPackage?.settings?.dailyWordCount || '0'
                } keywords`}</StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
            </StylesTable>
          </Grid>
          <Grid
            xs={12}
            container
            paddingBottom={'32px'}
            justifyContent={'center'}
          >
            <Grid xs={12} item padding={'0 24px'}>
              <UpgradePlanPrimaryButton
                fullWidth
                variant='contained'
                size='medium'
                type='submit'
                disabled={getDisabledPlan(professionalPackage)}
                onClick={() =>
                  handleOpen(professionalPackage?.settings?.paddleProductId)
                }
              >
                Get started
              </UpgradePlanPrimaryButton>
            </Grid>
          </Grid>
        </StylesPriceContainer>
        <StylesPriceContainer
          item
          xs={3}
          paddingTop={'24px'}
          onMouseEnter={() => setActivePlan(3)}
          onMouseLeave={() => setActivePlan(null)}
        >
          <Grid
            container
            item
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
            padding={'0 24px'}
          >
            <Grid item>
              <EnterpriseIcon />
            </Grid>
            <StylesPlanName item>
              {enterprisePackage?.name || 'Package'}
            </StylesPlanName>
            <Grid
              container
              item
              justifyContent={'center'}
              alignItems={'flex-end'}
              spacing={1}
              flexWrap={'nowrap'}
            >
              <StylesPlanPrice item>{`$${
                enterprisePackage?.settings?.price || '0'
              }`}</StylesPlanPrice>
              <StylesPlanPerMonth item>{`per ${
                planType === 'monthly' ? 'month' : 'year'
              }`}</StylesPlanPerMonth>
            </Grid>
            <StylesPlanDescription item>
              Best for large teams.
            </StylesPlanDescription>
            <Grid width={'100%'} item paddingTop={'32px'}>
              <UpgradePlanPrimaryButton
                fullWidth
                variant='contained'
                size='medium'
                type='submit'
                disabled={getDisabledPlan(enterprisePackage)}
                onClick={() =>
                  handleOpen(enterprisePackage?.settings?.paddleProductId)
                }
              >
                Get started
              </UpgradePlanPrimaryButton>
            </Grid>
          </Grid>
          <Grid xs={12} padding={'40px 0'}>
            <StylesTable>
              <StylesTr>
                <StylesTh>{`${
                  enterprisePackage?.settings?.dailyWordCount || '0'
                } keywords`}</StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
              <StylesTr>
                <StylesTh>
                  <CheckIcon />
                </StylesTh>
              </StylesTr>
            </StylesTable>
          </Grid>
          <Grid
            xs={12}
            container
            paddingBottom={'32px'}
            justifyContent={'center'}
          >
            <Grid xs={12} item padding={'0 24px'}>
              <UpgradePlanPrimaryButton
                fullWidth
                variant='contained'
                size='medium'
                type='submit'
                disabled={getDisabledPlan(enterprisePackage)}
                onClick={() =>
                  handleOpen(enterprisePackage?.settings?.paddleProductId)
                }
              >
                Get started
              </UpgradePlanPrimaryButton>
            </Grid>
          </Grid>
        </StylesPriceContainer>
      </Grid>
      <ReviewUpdateSubscriptionModal
        handleClose={handleClose}
        open={isReviewUpdateSubscriptionModal}
        paddleProductId={activePaddleProductId}
      />
    </>
  );
};

export default PricingTable;
