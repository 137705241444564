import React, { useMemo } from 'react';
import { StyledFoldersDrawer } from '../../../projects/components/FoldersDrawer/styles';
import { StyledLinearProgress } from '../../../projects/components/AccountUsage/components/Progress/styles';
import { Grid } from '@mui/material';
import { GrayButtonSmall } from '../../../../shared/buttons/styles';
import { StyledKeywordLimit } from './styles';
import { useNavigate } from 'react-router';
import ROUTES from '../../../../routes/constants';
import { useAppSelector } from '../../../../store';
import { normalise } from '../../../projects/components/AccountUsage/components/Progress/Progress';

interface IKeywordLimitSidebarProps {
  handleSwipeableDrawerClose: () => void;
}

/**
 * Component representing a sidebar that displays the keyword limit information.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {function} props.handleSwipeableDrawerClose - Function to handle closing of the drawer.
 * @returns {JSX.Element | null} The KeywordLimitSidebar component or null if the user is not an admin.
 */
const KeywordLimitSidebar: React.FC<IKeywordLimitSidebarProps> = ({
  handleSwipeableDrawerClose,
}) => {
  const navigate = useNavigate();

  const accountLimit = useAppSelector(state => state.auth.accountLimit);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  /**
   * Handles navigation to the upgrade plan page and closes the drawer.
   */
  const handleUpgradePlan = () => {
    navigate(ROUTES.upgradePlan);
    handleSwipeableDrawerClose();
  };

  const isAdmin = useMemo(
    () => currentAccount?.role?.name === 'Admin',
    [currentAccount?.role?.name]
  );

  const numberOfDailyUpdatesOfKeywordPositions = useMemo(
    () =>
      accountLimit?.defaultAccountLimits
        ?.numberOfDailyUpdatesOfKeywordPositions || 0,
    [accountLimit?.defaultAccountLimits?.numberOfDailyUpdatesOfKeywordPositions]
  );

  const numberOfKeywords = useMemo(
    () => accountLimit?.accountLimitsUsed?.numberOfKeywords || 0,
    [accountLimit?.accountLimitsUsed?.numberOfKeywords]
  );

  if (!isAdmin) return null;

  return (
    <StyledFoldersDrawer>
      <Grid>
        <StyledLinearProgress
          variant='determinate'
          value={normalise(
            numberOfKeywords,
            numberOfDailyUpdatesOfKeywordPositions
          )}
        />
        <StyledKeywordLimit>{`Keyword limit: ${numberOfKeywords} of ${numberOfDailyUpdatesOfKeywordPositions} used`}</StyledKeywordLimit>
      </Grid>
      <Grid padding={'12px 0'}>
        <GrayButtonSmall size='small' outline onClick={handleUpgradePlan}>
          Upgrade Plan
        </GrayButtonSmall>
      </Grid>
    </StyledFoldersDrawer>
  );
};

export default KeywordLimitSidebar;

/**
 * Props for the KeywordLimitSidebar component.
 *
 * @typedef {Object} IKeywordLimitSidebarProps
 * @property {function} handleSwipeableDrawerClose - Function to handle closing of the drawer.
 */
