import React from 'react';

import { useKeywordRankHistory } from '../../../../../../hooks';

import {
  StyledKeywordInfo,
  StyledLeftCell,
  StyledLeftCellTypography,
  StyledRightCell,
  StyledRightCellTypography,
  StyledRow,
} from './styles';
import { Divider } from '@mui/material';
import { ICompetitor } from '../../../../types/keywordInfo';

const KeywordInfo = () => {
  const { keywordInfo } = useKeywordRankHistory();

  const renderCompetitorName = (competitor: ICompetitor) => {
    if (competitor?.domainName) {
      return competitor.domainName;
    } else {
      return competitor?.url
        ? `${competitor.businessName} (${competitor.url})`
        : competitor.businessName;
    }
  };

  return (
    <StyledKeywordInfo>
      <StyledRow container xs={12}>
        <StyledLeftCell item xs={5} sm={4}>
          <StyledLeftCellTypography>Keyword</StyledLeftCellTypography>
        </StyledLeftCell>

        <StyledRightCell item xs={7} sm={8}>
          <StyledRightCellTypography>
            {keywordInfo?.keyword}
          </StyledRightCellTypography>
        </StyledRightCell>
      </StyledRow>
      <Divider
        orientation='horizontal'
        flexItem
        sx={{
          color: '#EDEDED',
        }}
      />

      {keywordInfo?.businessName && (
        <>
          <StyledRow container xs={12}>
            <StyledLeftCell item xs={5} sm={4}>
              <StyledLeftCellTypography>Business name</StyledLeftCellTypography>
            </StyledLeftCell>

            <StyledRightCell item xs={7} sm={8}>
              <StyledRightCellTypography>
                {keywordInfo?.businessName}
              </StyledRightCellTypography>
            </StyledRightCell>
          </StyledRow>
          <Divider
            orientation='horizontal'
            flexItem
            sx={{
              color: '#EDEDED',
            }}
          />
        </>
      )}

      {keywordInfo?.domain && (
        <>
          <StyledRow container xs={12}>
            <StyledLeftCell item xs={5} sm={4}>
              <StyledLeftCellTypography>Domain</StyledLeftCellTypography>
            </StyledLeftCell>

            <StyledRightCell item xs={7} sm={8}>
              <StyledRightCellTypography>
                {keywordInfo?.domain}
              </StyledRightCellTypography>
            </StyledRightCell>
          </StyledRow>
          <Divider
            orientation='horizontal'
            flexItem
            sx={{
              color: '#EDEDED',
            }}
          />
        </>
      )}

      {keywordInfo?.region?.name && (
        <>
          <StyledRow container xs={12}>
            <StyledLeftCell item xs={5} sm={4}>
              <StyledLeftCellTypography>Region</StyledLeftCellTypography>
            </StyledLeftCell>

            <StyledRightCell item xs={7} sm={8}>
              <StyledRightCellTypography>
                {keywordInfo?.region?.name}
              </StyledRightCellTypography>
            </StyledRightCell>
          </StyledRow>
          <Divider
            orientation='horizontal'
            flexItem
            sx={{
              color: '#EDEDED',
            }}
          />
        </>
      )}

      <StyledRow container xs={12}>
        <StyledLeftCell item xs={5} sm={4}>
          <StyledLeftCellTypography>Competitors</StyledLeftCellTypography>
        </StyledLeftCell>

        <StyledRightCell item xs={7} sm={8}>
          {keywordInfo?.competitors?.length ? (
            keywordInfo.competitors.map(item => {
              return (
                <StyledRightCellTypography key={item.id}>
                  {renderCompetitorName(item)}
                </StyledRightCellTypography>
              );
            })
          ) : (
            <StyledRightCellTypography>none</StyledRightCellTypography>
          )}
        </StyledRightCell>
      </StyledRow>
    </StyledKeywordInfo>
  );
};

export default KeywordInfo;
