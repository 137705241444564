import React, { useEffect, useMemo, useState } from 'react';
import {
  Fade,
  FormHelperText,
  Grid,
  Modal,
  useMediaQuery,
} from '@mui/material';
import {
  ButtonsWrapper,
  CloseDeletePopoverOne,
  HeaderContainer,
  ModalContainer,
} from '../../../auth/components/Settings/components/Account/styles';
import CloseIcon from '@mui/icons-material/Close';
import { ReviewUpdateSubscriptionModalProps } from './types';
import { StylesReviewUpdateSubscriptionModal } from './styles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../shared/buttons/styles';
import usePayment from '../../hooks/usePayment';
import { useAuth } from '../../../../hooks';
import SubscriptionUpdateSuccessfullModal from '../SubscriptionUpdateSuccessfullModal/SubscriptionUpdateSuccessfullModal';
import { useAppSelector } from '../../../../store';
import { NecessaryRemovalResponse } from '../../../auth/types';
import ImportantActionRequiredBeforeDowngradingModal from '../../../../shared/components/ImportantActionRequiredBeforeDowngradingModal/ImportantActionRequiredBeforeDowngradingModal';
import { CustomFormHelperText } from '../../../../shared/components';
import { getPaymentLabel } from '../../../../utils/payment';
import { enqueueSnackbar } from 'notistack';
import CustomToast from '../../../../shared/CustomToast/CustomToast';
import { closeSnackbarAction } from '../../../../utils/snackbar';

const ReviewUpdateSubscriptionModal: React.FC<
  ReviewUpdateSubscriptionModalProps
> = ({ open, paddleProductId, handleClose }) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const {
    onGetMyAccount,
    onGetBilling,
    onNecessaryRemoval,
    onSetActionRequiredBeforeDowngrading,
  } = useAuth();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const {
    ongGetUpdateSubscriptionReviewThunkSubscription,
    onSubscriptionUpdate,
    updateSubscriptionReview,
  } = usePayment();

  const [isOpenSuccessfullModal, setIsOpenSuccessfullModal] = useState(false);
  const [isOpenDowngradingModal, setIsOpenDowngradingModal] = useState(false);

  const [errorPayment, setErrorPayment] = useState('');

  useEffect(() => {
    if (!open) {
      setErrorPayment('');
    }
  }, [open]);

  const isImmediatePayment = updateSubscriptionReview
    ? updateSubscriptionReview.currentTariffPlan.settings.price >=
      updateSubscriptionReview.subscriptionSummary.product.settings.price
    : false;

  const downgradingSuccessFn = (data: NecessaryRemovalResponse) => {
    if (paddleProductId) {
      if (
        !data.numberOfKeywords &&
        !data.numberOfNotes &&
        !data.numberOfEmailReports &&
        !data.numberOfTriggers
      ) {
        onSubscriptionUpdate({
          id: currentAccount.accountId,
          paddleProductId,
          successFn: () => {
            setIsOpenSuccessfullModal(true);
            handleClose();
            setErrorPayment('');
            setTimeout(() => {
              onGetMyAccount(currentAccount.accountId);
              onGetBilling({ id: currentAccount.accountId });
            }, 10000);
          },
          errorFn: error => {
            setErrorPayment(error);
          },
        });
      } else {
        onSetActionRequiredBeforeDowngrading(data);
        setIsOpenDowngradingModal(true);
      }
    }
  };

  const handleSetActionRequiredBeforeDowngrading = () => {
    if (currentAccount?.accountId && paddleProductId) {
      onNecessaryRemoval({
        id: currentAccount.accountId,
        paddleProductId: paddleProductId,
        successFn: downgradingSuccessFn,
      });
    }
  };

  const handleClick = () => {
    if (currentAccount?.accountId && paddleProductId) {
      handleSetActionRequiredBeforeDowngrading();
    }
  };

  const handleCloseOpenSuccessfullModal = () => {
    setIsOpenSuccessfullModal(false);
  };

  const handleCloseDowngradingModal = () => {
    setIsOpenDowngradingModal(false);
  };

  const GetPaymentMethod = useMemo(() => {
    if (updateSubscriptionReview?.subscriptionSummary.card) {
      return (
        <Grid container item gap={2}>
          <Grid item>
            <b>
              {`${updateSubscriptionReview.subscriptionSummary.card.type} ending in ${updateSubscriptionReview.subscriptionSummary.card.last4}`}
            </b>
          </Grid>
          <Grid
            item
          >{`Expires: ${updateSubscriptionReview.subscriptionSummary.card.expiry}`}</Grid>
        </Grid>
      );
    } else {
      if (updateSubscriptionReview?.subscriptionSummary?.paymentMethod?.name)
        return (
          <Grid container item gap={2}>
            <Grid item>
              <b>
                {getPaymentLabel(
                  updateSubscriptionReview.subscriptionSummary.paymentMethod
                    .name
                )}
              </b>
            </Grid>
            <Grid item>
              {updateSubscriptionReview.subscriptionSummary.email}
            </Grid>
          </Grid>
        );
    }
  }, [
    updateSubscriptionReview?.subscriptionSummary?.card,
    updateSubscriptionReview?.subscriptionSummary?.email,
    updateSubscriptionReview?.subscriptionSummary?.paymentMethod?.name,
  ]);

  useEffect(() => {
    if (currentAccount?.accountId && open && paddleProductId) {
      ongGetUpdateSubscriptionReviewThunkSubscription({
        id: currentAccount.accountId,
        paddleProductId: paddleProductId,
        errorFn: error => {
          enqueueSnackbar(<CustomToast message='' submessage={error} />, {
            action: closeSnackbarAction,
            variant: 'warning',
          });
        },
      });
    }
  }, [
    currentAccount.accountId,
    open,
    ongGetUpdateSubscriptionReviewThunkSubscription,
    paddleProductId,
  ]);

  if (!updateSubscriptionReview) {
    return null;
  }

  return (
    <>
      <Modal
        disableAutoFocus
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <StylesReviewUpdateSubscriptionModal>
            <ModalContainer>
              <HeaderContainer>
                <CloseDeletePopoverOne onClick={handleClose}>
                  <CloseIcon fontSize='small' />
                </CloseDeletePopoverOne>
              </HeaderContainer>
              <Grid container xs={12} justifyContent={'space-between'}>
                <Grid container xs={12} md={8} paddingRight={'24px'}>
                  <Grid
                    container
                    item
                    padding={'24px 0'}
                    borderBottom={'1px solid #ebecf0'}
                  >
                    <b>Subscription summary</b>
                  </Grid>
                  <Grid
                    container
                    item
                    gap={1}
                    borderBottom={'1px solid #ebecf0'}
                    padding={'24px 0'}
                  >
                    <Grid container item>
                      Product
                    </Grid>
                    <Grid container item>
                      <b>
                        {`${updateSubscriptionReview.subscriptionSummary.product.name}: ${updateSubscriptionReview.subscriptionSummary.product.settings.dailyWordCount}Keywords/day (${updateSubscriptionReview.subscriptionSummary.product.settings.monthlyWordCount} Keywords/${updateSubscriptionReview.subscriptionSummary.product.settings.type.name})`}
                      </b>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    gap={1}
                    padding={'24px 0'}
                    borderBottom={'1px solid #ebecf0'}
                  >
                    <Grid container item>
                      Payment charges
                    </Grid>
                    <Grid container item wrap={'nowrap'}>
                      <Grid container>
                        <b>Prorated amount applied</b>
                      </Grid>
                      <Grid container>
                        {`The customer will be ${
                          isImmediatePayment ? 'billed' : 'payment'
                        } $${
                          updateSubscriptionReview.immediatePayment
                            .updateSummaryAmount
                        } on ${
                          updateSubscriptionReview.immediatePayment.startsAt
                        }.
                      Their recurring amount will be $${
                        updateSubscriptionReview.subscriptionSummary.product
                          .settings.price
                      } every ${
                          updateSubscriptionReview.subscriptionSummary.product
                            .settings.type.name
                        }.`}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    gap={1}
                    padding={'24px 0'}
                    borderBottom={'1px solid #ebecf0'}
                  >
                    <Grid item>Payment method</Grid>
                    {GetPaymentMethod}
                  </Grid>
                  <Grid container item paddingTop={'24px'}>
                    <div>Customer</div>&nbsp;
                    <div>
                      {updateSubscriptionReview.subscriptionSummary.email}
                    </div>
                  </Grid>
                </Grid>
                <Grid container item xs={12} md={4} alignItems={'center'}>
                  <Grid container item gap={1}>
                    <Grid container item>
                      {`Immediate ${isImmediatePayment ? 'payment' : 'credit'}`}
                    </Grid>
                    <Grid container item>
                      <b>{`$${updateSubscriptionReview.immediatePayment.updateSummaryAmount}`}</b>
                    </Grid>
                    <Grid container item>
                      {`on ${updateSubscriptionReview.immediatePayment.startsAt}`}
                    </Grid>
                  </Grid>
                  <Grid container item gap={1}>
                    <Grid container item>
                      Next payment
                    </Grid>
                    <Grid container item>
                      <b>{`$${updateSubscriptionReview.subscriptionSummary.product.settings.price}`}</b>
                    </Grid>
                    <Grid container item>
                      {`on ${updateSubscriptionReview.nextPayment.date}`}
                      &nbsp;
                      <span style={{ textDecoration: 'line-through' }}>
                        {`$${updateSubscriptionReview.currentTariffPlan.settings.price}`}
                      </span>
                    </Grid>
                  </Grid>

                  <Grid container item gap={1}>
                    <Grid container item>
                      Payment
                    </Grid>
                    <Grid container item>
                      <b>Charged automatically when payment is due.</b>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {errorPayment && (
                <Grid item xs={12}>
                  <FormHelperText error={!!errorPayment} id='message'>
                    {errorPayment && (
                      <CustomFormHelperText error={errorPayment} />
                    )}
                  </FormHelperText>
                </Grid>
              )}
              <ButtonsWrapper container xs={12}>
                <Grid item xs={extraSmallScreen && 12}>
                  <GrayButtonSmall
                    fullWidth={extraSmallScreen}
                    size='medium'
                    outline
                    onClick={handleClose}
                  >
                    Cancel
                  </GrayButtonSmall>
                </Grid>
                <Grid item xs={extraSmallScreen && 12}>
                  <PrimaryButtonSmall
                    fullWidth={extraSmallScreen}
                    variant='contained'
                    size='medium'
                    type='submit'
                    onClick={handleClick}
                  >
                    Continue
                  </PrimaryButtonSmall>
                </Grid>
              </ButtonsWrapper>
            </ModalContainer>
          </StylesReviewUpdateSubscriptionModal>
        </Fade>
      </Modal>
      <SubscriptionUpdateSuccessfullModal
        handleClose={handleCloseOpenSuccessfullModal}
        open={isOpenSuccessfullModal}
      />
      <ImportantActionRequiredBeforeDowngradingModal
        onClose={handleCloseDowngradingModal}
        onGotIt={handleCloseDowngradingModal}
        open={isOpenDowngradingModal}
        productName={updateSubscriptionReview.subscriptionSummary.product.name}
      />
    </>
  );
};

export default React.memo(ReviewUpdateSubscriptionModal);
