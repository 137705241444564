import React, { useEffect } from 'react';
import { Header, NotesTable } from './components';
import { Grid } from '@mui/material';
import { useNotes } from '../../../../hooks';
import { DEFAULT_GET_USERS_PARAMS } from '../../../auth/components/Settings/components/Team/constants';
import { useAppSelector } from '../../../../store';

const NotesPage = () => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const {
    onSetRowSelectionModelAllNotes,
    onGetAllNotes,
    tables: {
      allNotes: { paginationModel, search, sortModel },
    },
  } = useNotes();

  useEffect(() => {
    if (currentAccount?.accountId) {
      onGetAllNotes({
        id: currentAccount.accountId,
        page: paginationModel.page,
        limit: paginationModel.pageSize,
        search: search || DEFAULT_GET_USERS_PARAMS.search,
        sortBy: sortModel[0]?.field,
        sortOrder: sortModel[0]?.sort,
      });
    }
  }, [
    currentAccount.accountId,
    onGetAllNotes,
    paginationModel.page,
    paginationModel.pageSize,
    search,
    sortModel,
  ]);

  useEffect(() => {
    return () => onSetRowSelectionModelAllNotes([]);
  }, [onSetRowSelectionModelAllNotes]);

  return (
    <Grid xs={12}>
      <Header />
      <NotesTable />
    </Grid>
  );
};

export default NotesPage;
