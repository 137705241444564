import { Fade, Modal } from '@mui/material';
import React from 'react';
import { ModalContainer } from '../../Projects/components/ProjectRedactorModal/styles';
import { KeywordsAnalyticsModalModalProps } from './types';
import PositionHistory from '../../ProjectExpanded/components/KeywordRankings/components/PositionHistory/PositionHistory';
import { SearchResult } from '../../ProjectExpanded/components/KeywordRankings/components';
import { StyledKeywordsAnalyticsModal } from './styles';

const KeywordsAnalyticsModal: React.FC<KeywordsAnalyticsModalModalProps> = ({
  onClose,
  open,
  id,
  name,
}) => {
  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <Fade in={open}>
        <StyledKeywordsAnalyticsModal>
          <ModalContainer container xs={12}>
            <PositionHistory onClose={onClose} id={id} name={name} />
            <SearchResult id={id} />
          </ModalContainer>
        </StyledKeywordsAnalyticsModal>
      </Fade>
    </Modal>
  );
};

export default KeywordsAnalyticsModal;
