import { AxiosResponse } from 'axios';

import api from '../../api';
import {
  BulkDeleteSharedLinksParams,
  CreateSharedLinkParams,
  CreateSharedLinkResponse,
  EditSharedLinkParams,
  GetAllSharedLinksParams,
  GetSharedLinkFullParams,
  GetSharedLinkSettingsParams,
  GetSharedParams,
  GetSharedProjectImprovedVsDeclinedParams,
  GetSharedProjectKeywordPositionsInfoParams,
  GetSharedProjectKeywordRankingParams,
  GetSharedProjectKeywordSearchResultsParams,
  GetSharedProjectKeywordTrendsParams,
  GetSharedProjectOverviewParams,
  GetSharedProjectParams,
  GetSharedProjectPerformanceParams,
  ISharedLinkFullInfo,
  ISharedLinkSettings,
  LoginSharedParams,
  LoginSharedResponse,
  SharedLinksResponse,
} from './types';
import {
  GetKeywordPositionHistoryResponse,
  GetSharedKeywordPositionHistoryParams,
  KeywordRankingState,
  KeywordSearchResultsState,
  ProjectExpanded,
  ProjectImprovedVsDeclined,
  ProjectKeywordTrends,
  ProjectOverview,
  ProjectsState,
  SharedExportToCsvKeywordPositionsInfoParams,
} from '../projects/types';
import { GetProjectPerformanceResponse } from '../projects/types/projectPerformance';
import {
  GetKeywordInfoResponse,
  GetKeywordPositionsInfoResponse,
  GetSharedKeywordInfoParams,
  GetSharedKeywordPositionsInfoParams,
} from '../projects/types/keywordInfo';

export const getAllSharedLinksAPI = async (
  params: GetAllSharedLinksParams
): Promise<AxiosResponse<SharedLinksResponse>> => {
  return api.get<SharedLinksResponse>(`/accounts/${params.id}/shared-links`, {
    params: {
      page: params.page + 1,
      limit: params.limit,
      sortBy: params.sortBy || undefined,
      sortOrder: params?.sortOrder ? params.sortOrder.toUpperCase() : null,
      search: params.search,
    },
  });
};

export const createSharedLinkAPI = async (
  params: CreateSharedLinkParams
): Promise<AxiosResponse<CreateSharedLinkResponse>> => {
  return api.post<CreateSharedLinkResponse>(
    `/accounts/${params.accountId}/shared-links`,
    {
      projectIds: params?.projectIds,
      folderIds: params?.folderIds,
      enableSharing: params.enableSharing,
      requiredPassword: params.requiredPassword,
      password: params.password,
      position: params.position,
      oneDayChange: params.oneDayChange,
      sevenDayChange: params.sevenDayChange,
      thirtyDayChange: params.thirtyDayChange,
      startingRank: params.startingRank,
      bestRank: params.bestRank,
      lifeTimeChange: params.lifeTimeChange,
      volume: params.volume,
      url: params.url,
      updated: params.updated,
    }
  );
};

export const bulkDeleteSharedLinksAPI = async (
  params: BulkDeleteSharedLinksParams
): Promise<AxiosResponse<null>> => {
  return api.post<null>(
    `/accounts/${params.accountId}/shared-links/bulk-delete`,
    {
      ids: params.ids,
    }
  );
};

export const getSharedAPI = async (
  params: GetSharedParams
): Promise<AxiosResponse<ProjectsState>> => {
  return api.get<ProjectsState>(`/shared/${params.link}`, {
    params: {
      page: params.page + 1,
      limit: params.limit,
      sortBy: params.sortBy || undefined,
      sortOrder: params?.sortOrder ? params.sortOrder.toUpperCase() : null,
      search: params.search,
      dailyAverage: params.dailyAverage,
    },
  });
};

export const LoginSharedAPI = async (
  params: LoginSharedParams
): Promise<AxiosResponse<LoginSharedResponse>> => {
  return api.post<LoginSharedResponse>(`/shared/${params.link}/login`, {
    password: params.password,
  });
};

export const getSharedProjectAPI = async (
  params: GetSharedProjectParams
): Promise<AxiosResponse<ProjectExpanded>> => {
  return api.get<ProjectExpanded>(
    `/shared/${params.link}/projects/${params.projectId}`,
    { params: { keywordDeviceType: params.deviceType, keywordTags: true } }
  );
};

export const getSharedProjectOverviewAPI = async (
  params: GetSharedProjectOverviewParams
): Promise<AxiosResponse<ProjectOverview>> => {
  const paramsObj =
    params.fromDate && params.toDate
      ? {
          fromDate: params.fromDate,
          toDate: params.toDate,
          deviceType: params.deviceType,
        }
      : {
          deviceType: params.deviceType,
        };
  return api.get<ProjectOverview>(
    `/shared/${params.link}/projects/${params.projectId}/overview`,
    {
      params: {
        ...paramsObj,
      },
    }
  );
};

export const getSharedProjectImprovedVsDeclinedAPI = async (
  params: GetSharedProjectImprovedVsDeclinedParams
): Promise<AxiosResponse<ProjectImprovedVsDeclined>> => {
  return api.get<ProjectImprovedVsDeclined>(
    `/shared/${params.link}/projects/${params.projectId}/improved-vs-declined`,
    {
      params: {
        period: params.period,
        improvedFilter: params.improvedFilter,
        declinedFilter: params.declinedFilter,
        lostFilter: params.lostFilter,
        noChange: params.noChange,
        deviceType: params.deviceType,
      },
    }
  );
};

export const getSharedProjectKeywordTrendsAPI = async (
  params: GetSharedProjectKeywordTrendsParams
): Promise<AxiosResponse<ProjectKeywordTrends>> => {
  return api.get<ProjectKeywordTrends>(
    `/shared/${params.link}/projects/${params.projectId}/keyword-trends`,
    {
      params: {
        period: params.period,
        top3Filter: params.top3Filter,
        fromFourToTen: params.fromFourToTen,
        fromElevenToTwenty: params.fromElevenToTwenty,
        fromTwentyOneToFifty: params.fromTwentyOneToFifty,
        fiftyOneToOneHundred: params.fiftyOneToOneHundred,
        notRanked: params.notRanked,
        deviceType: params.deviceType,
      },
    }
  );
};

export const getSharedProjectKeywordRankingsPI = async (
  params: GetSharedProjectKeywordRankingParams
): Promise<AxiosResponse<KeywordRankingState>> => {
  return api.get<KeywordRankingState>(
    `/shared/${params.link}/projects/${params.projectId}/keyword-rankings`,
    {
      params: {
        ...params.params,
        ...params.filters,
        deviceType: params.deviceType,
        page: params.params.page + 1,
        sortOrder: params.params.sortOrder
          ? params.params.sortOrder.toUpperCase()
          : null,
        tagIds: params.tagIds,
      },
    }
  );
};

export const getSharedProjectKeywordPositionsInfoPI = async (
  params: GetSharedProjectKeywordPositionsInfoParams
): Promise<AxiosResponse<GetKeywordPositionHistoryResponse>> => {
  return api.get<GetKeywordPositionHistoryResponse>(
    `/shared/${params.link}/keywords/${params.keywordId}`,
    {
      params: {
        period: params.period,
        competitorIds: params.competitorIds,
      },
    }
  );
};

export const getKeywordSearchResultsAPI = async (
  params: GetSharedProjectKeywordSearchResultsParams
): Promise<AxiosResponse<KeywordSearchResultsState>> => {
  return api.get<KeywordSearchResultsState>(
    `/shared/${params.link}/keywords/${params.keywordId}/search-results`,
    {
      params: {
        page: params.page,
        limit: params.limit,
      },
    }
  );
};

export const getProjectPerformanceAPI = async (
  params: GetSharedProjectPerformanceParams
): Promise<AxiosResponse<GetProjectPerformanceResponse>> => {
  return api.get<GetProjectPerformanceResponse>(
    `/shared/${params.link}/projects/${params.projectId}/project-performance`,
    {
      params: {
        deviceType: params.deviceType,
        period: params.period,
        fromDate: params.fromDate,
        toDate: params.toDate,
        competitorIds: params.competitorIds,
      },
    }
  );
};

export const getSharedLinkFullAPI = async (
  params: GetSharedLinkFullParams
): Promise<AxiosResponse<ISharedLinkFullInfo>> => {
  return api.get<ISharedLinkFullInfo>(
    `/accounts/${params.accountId}/shared-links/${params.linkId}`
  );
};

export const editSharedLinkAPI = async (
  params: EditSharedLinkParams
): Promise<AxiosResponse<null>> => {
  return api.patch<null>(
    `/accounts/${params.accountId}/shared-links/${params.linkId}`,
    {
      enableSharing: params.enableSharing,
      requiredPassword: params.requiredPassword,
      password: params.password,
      position: params.position,
      oneDayChange: params.oneDayChange,
      sevenDayChange: params.sevenDayChange,
      thirtyDayChange: params.thirtyDayChange,
      startingRank: params.startingRank,
      bestRank: params.bestRank,
      lifeTimeChange: params.lifeTimeChange,
      volume: params.volume,
      url: params.url,
      updated: params.updated,
      projectIds: params.projectIds,
    }
  );
};

export const getSharedLinkSettingsAPI = async (
  params: GetSharedLinkSettingsParams
): Promise<AxiosResponse<ISharedLinkSettings>> => {
  return api.get<ISharedLinkSettings>(
    `/shared/${params.link}/info?timestamp=${Date.now()}`
  );
};

export const getSharedKeywordInfoAPI = async (
  params: GetSharedKeywordInfoParams
): Promise<AxiosResponse<GetKeywordInfoResponse>> => {
  return api.get<GetKeywordInfoResponse>(
    `/shared/${params.link}/keywords/${params.keywordId}/keyword-info`
  );
};

export const geSharedKeywordPositionHistoryAPI = async (
  params: GetSharedKeywordPositionHistoryParams
): Promise<AxiosResponse<GetKeywordPositionHistoryResponse>> => {
  return api.get<GetKeywordPositionHistoryResponse>(
    `/shared/${params.link}/keywords/${params.keywordId}`,
    {
      params: {
        period: params.period,
        competitorIds: params.competitorIds,
      },
    }
  );
};

export const getSharedKeywordPositionsInfoAPI = async (
  params: GetSharedKeywordPositionsInfoParams
): Promise<AxiosResponse<GetKeywordPositionsInfoResponse>> => {
  return api.get<GetKeywordPositionsInfoResponse>(
    `/shared/${params.link}/keywords/${params.keywordId}/keyword-positions-info`,
    {
      params: {
        page: params.page,
        limit: params.limit,
        sortBy: params.sortBy,
        sortOrder: params?.sortOrder ? params.sortOrder.toUpperCase() : null,
      },
    }
  );
};

export const exportSharedToCsvKeywordPositionsInfoAPI = async (
  params: SharedExportToCsvKeywordPositionsInfoParams
): Promise<AxiosResponse<string>> => {
  return api.get<string>(
    `/shared/${params.link}/keywords/${params.keywordId}/keyword-positions-info/export-to-csv`
  );
};
