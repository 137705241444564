import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';

const CloseChart = styled(CloseIcon)`
  color: #667085;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

const CustomFormControlLabel = styled(FormControlLabel)`
  .MuiTypography-root {
    color: #101828;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
`;

export { CloseChart, CustomFormControlLabel };
