import { createSlice } from '@reduxjs/toolkit';
import {
  resetPaddleState,
  setIsOpenSuccessPayModal,
  setPaddle,
} from './actions';
import {
  getTransactionToUpdatePaymentMethodThunk,
  getUpdateSubscriptionReviewThunk,
} from './thunk';
import { IUpdateSubscriptionReview } from '../types';

const initialState = {
  isLoaded: false,
  isLoadingTransactionToUpdatePaymentMethod: false,
  isOpenSuccessPayModal: false,
  paddle: null as any,
  updateSubscriptionReview: null as IUpdateSubscriptionReview | null,
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setPaddle, (state, { payload }) => {
      state.isLoaded = true;
      state.paddle = payload;
    });

    builder.addCase(resetPaddleState, state => {
      return (state = initialState);
    });

    builder.addCase(setIsOpenSuccessPayModal, (state, { payload }) => {
      state.isOpenSuccessPayModal = payload;
    });

    builder.addCase(getTransactionToUpdatePaymentMethodThunk.pending, state => {
      state.isLoadingTransactionToUpdatePaymentMethod = true;
    });
    builder.addCase(
      getTransactionToUpdatePaymentMethodThunk.fulfilled,
      state => {
        state.isLoadingTransactionToUpdatePaymentMethod = false;
      }
    );
    builder.addCase(
      getTransactionToUpdatePaymentMethodThunk.rejected,
      state => {
        state.isLoadingTransactionToUpdatePaymentMethod = false;
      }
    );

    builder.addCase(
      getUpdateSubscriptionReviewThunk.fulfilled,
      (state, { payload }) => {
        state.updateSubscriptionReview = payload;
      }
    );
  },
});

export default paymentSlice.reducer;
