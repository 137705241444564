import React from 'react';

interface Props {
  color?: string;
}

const FilterIcon: React.FC<Props> = ({ color = '#344054' }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='filter-lines'>
        <path
          id='Icon'
          d='M5 10H15M2.5 5H17.5M7.5 15H12.5'
          stroke={color}
          strokeWidth='1.66667'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};

export default FilterIcon;
