import React from 'react';
import { Fade, Grid, Modal } from '@mui/material';
import {
  ModalContainer,
  ModalWrapper,
} from '../../../features/projects/components/Projects/components/ProjectRedactorModal/styles';
import {
  CloseDeletePopoverOne,
  HeaderContainer,
} from '../../../features/auth/components/Settings/components/Account/styles';
import CloseIcon from '@mui/icons-material/Close';
import TrialExpiredModalIcon from '../../images/TrialExpiredModalIcon';
import { StyledLabel } from '../KeywordsLimitExceededModal/styles';
import { StyledContactUs, StyledMainText } from './styles';
import { PrimaryButtonSmall } from '../../buttons/styles';
import useAccountLimits from '../../../features/auth/hooks/useAccountLimits';
import { useAppSelector } from '../../../store';
import { useNavigate } from 'react-router';
import ROUTES from '../../../routes/constants';
import {
  onGetInfoHelpScout,
  onInit,
  onLogout,
  onPrefill,
  onSetConfig,
  onToggleHelpScout,
} from '../../../utils/helpScout';

const YourFreeTrialHasExpiredModal = () => {
  const navigate = useNavigate();

  const { onSetFreeTrialModal } = useAccountLimits();

  const isOpen = useAppSelector(
    state => state.auth.freeTrialSubscriptionEndedModals.freeTrial
  );

  const handleClose = () => {
    onSetFreeTrialModal(false);
  };

  const handleOk = () => {
    navigate(ROUTES.upgradePlan);
    handleClose();
  };

  const onContactUs = () => {
    if (!onGetInfoHelpScout()) {
      onSetConfig();
      onInit();
      onLogout();
      onPrefill();
    }
    onToggleHelpScout();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isOpen}>
        <ModalWrapper>
          <ModalContainer>
            <HeaderContainer>
              <CloseDeletePopoverOne color='error' onClick={handleClose}>
                <CloseIcon fontSize='small' />
              </CloseDeletePopoverOne>
              <TrialExpiredModalIcon />
              <StyledLabel padding='24px 0'>
                Your free trial has expired
              </StyledLabel>
            </HeaderContainer>

            <Grid container justifyContent={'center'}>
              <StyledMainText>
                We hope you enjoyed your Serpnest trial. 🤗
              </StyledMainText>
              <br />
              <br />

              <StyledMainText>
                To continue using Serpnest with your existing data,
              </StyledMainText>

              <StyledMainText>please upgrade to a paid plan.</StyledMainText>
              <br />
              <br />

              <StyledMainText
                container
                flexDirection={'column'}
                alignItems={'center'}
              >
                <Grid item>
                  If you have any questions or concerns, feel free to
                </Grid>

                <Grid item onClick={onContactUs}>
                  <StyledContactUs>contact us.</StyledContactUs>
                </Grid>

                <br />
                <Grid item>
                  <PrimaryButtonSmall
                    variant='contained'
                    size='small'
                    type='submit'
                    onClick={handleOk}
                  >
                    Choose a plan
                  </PrimaryButtonSmall>
                </Grid>
              </StyledMainText>
              <br />
              <br />
            </Grid>
          </ModalContainer>
        </ModalWrapper>
      </Fade>
    </Modal>
  );
};
export default YourFreeTrialHasExpiredModal;
