import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const WelcomeProjectWrapper = styled(Grid)`
  width: 100%;
  height: calc(100vh - 164px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WelcomeProjectContent = styled(Grid)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const WelcomeTitle = styled(Grid)`
  margin-top: 48px;
  margin-bottom: 8px;
  color: #101828;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
`;

const WelcomeText = styled(Grid)`
  color: #101828;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 48px;
`;

export {
  WelcomeProjectWrapper,
  WelcomeProjectContent,
  WelcomeTitle,
  WelcomeText,
};
