import { useSharedLinks } from '../../../../../../hooks';
import React from 'react';
import {
  PaginationContainer,
  StyledTablePagination,
} from '../../../../../projects/components/ProjectExpanded/components/KeywordRankings/components/KeywordRankingsTable/components/Table/components/Pagination/styles';
import { useAppSelector } from '../../../../../../store';

const Pagination = () => {
  const {
    onSetPaginationPageSizeForSharedLinkContentsListTable,
    onSetPaginationPageForSharedLinkContentsListTable,
  } = useSharedLinks();

  const sharedLinkContentsList = useAppSelector(
    state => state.sharedLinks.sharedLinkContentsList
  );
  const paginationModel = useAppSelector(
    state => state.sharedLinks.tables.sharedLinkContentsList.paginationModel
  );

  const handleSetPaginationPageSize = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onSetPaginationPageSizeForSharedLinkContentsListTable(
      Number(e.target.value)
    );
  };

  const handleSetPaginationPage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    onSetPaginationPageForSharedLinkContentsListTable(Number(page));
  };

  return (
    <PaginationContainer xs={12}>
      <StyledTablePagination
        labelRowsPerPage='Show rows:'
        count={sharedLinkContentsList?.meta?.totalItems || 0}
        page={paginationModel.page}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onPageChange={handleSetPaginationPage}
        rowsPerPage={paginationModel.pageSize}
        onRowsPerPageChange={handleSetPaginationPageSize}
      />
    </PaginationContainer>
  );
};

export default Pagination;
