import React from 'react';
import { Fade, Grid, Modal, useMediaQuery } from '@mui/material';
import { useAppSelector } from '../../../store';
import {
  ModalContainer,
  ModalWrapper,
} from '../../../features/projects/components/Projects/components/ProjectRedactorModal/styles';
import {
  CloseDeletePopoverOne,
  HeaderContainer,
} from '../../../features/auth/components/Settings/components/Account/styles';
import CloseIcon from '@mui/icons-material/Close';
import AlertCircleIcon from '../../images/AlertCircleIcon';
import {
  StyledErrors,
  StyledLabel,
  StyledMainText,
} from '../KeywordsLimitExceededModal/styles';
import { ButtonsWrapper } from '../../../features/auth/components/Settings/components/Team/components/customTableStyles';
import { GrayButtonSmall, PrimaryButtonSmall } from '../../buttons/styles';

interface TagsPreKeywordsExceededModalProps {
  onClose: () => void;
  onUpgradeAccount: () => void;
  open: boolean;
}

const TagsPreKeywordsExceededModal: React.FC<
  TagsPreKeywordsExceededModalProps
> = ({ onClose, onUpgradeAccount, open }) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const tagsPerKeywordLimitExceeded = useAppSelector(
    state => state.auth.accountLimitErrors.tagsPerKeywordLimitExceeded
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <ModalWrapper>
          <ModalContainer>
            <HeaderContainer>
              <CloseDeletePopoverOne color='error' onClick={onClose}>
                <CloseIcon fontSize='small' />
              </CloseDeletePopoverOne>
              <AlertCircleIcon />
              <StyledLabel padding='24px 0'>
                Tags Per Keyword Limit Exceeded
              </StyledLabel>
            </HeaderContainer>

            <Grid item xs={12}>
              <StyledMainText>
                You have reached the maximum number of tags per keyword allowed
                for your account plan.
              </StyledMainText>
            </Grid>
            <br />
            <Grid item xs={12}>
              <StyledErrors>
                {`Tags per keyword limit: ${tagsPerKeywordLimitExceeded.tagsPerKeywordYouCanAdd}/${tagsPerKeywordLimitExceeded.tagsPerKeywordLimit} used`}
              </StyledErrors>
            </Grid>
            <br />
            <Grid item xs={12}>
              <StyledMainText>
                To add more tags per keyword, please consider upgrading your
                account or removing some of the existing tags per keyword.
              </StyledMainText>
            </Grid>

            <ButtonsWrapper container xs={12}>
              <Grid item xs={extraSmallScreen && 12}>
                <GrayButtonSmall
                  fullWidth={extraSmallScreen}
                  size='small'
                  outline
                  onClick={onUpgradeAccount}
                >
                  Upgrade Account
                </GrayButtonSmall>
              </Grid>
              <Grid item xs={extraSmallScreen && 12}>
                <PrimaryButtonSmall
                  fullWidth={extraSmallScreen}
                  variant='contained'
                  size='small'
                  type='submit'
                  onClick={onClose}
                >
                  Got it
                </PrimaryButtonSmall>
              </Grid>
            </ButtonsWrapper>
          </ModalContainer>
        </ModalWrapper>
      </Fade>
    </Modal>
  );
};

export default TagsPreKeywordsExceededModal;
