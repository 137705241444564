import React from 'react';
import { StyledKeywordRankings } from '../../../../../projects/components/ProjectExpanded/components/KeywordRankings/styles';
import { Header, Table } from './components';
import { Pagination } from './components';

const ProjectExpandedNotesTable = () => {
  return (
    <StyledKeywordRankings>
      <Header />
      <Table />
      <Pagination />
    </StyledKeywordRankings>
  );
};

export default ProjectExpandedNotesTable;
