import React from 'react';

const GooglePayIcon = () => {
  return (
    <svg
      width='58'
      height='41'
      viewBox='0 0 58 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.630435 5.2388C0.630435 2.80154 2.60622 0.825747 5.04348 0.825747H52.9565C55.3938 0.825747 57.3696 2.80153 57.3696 5.23879V35.4997C57.3696 37.9369 55.3938 39.9127 52.9565 39.9127H5.04348C2.60622 39.9127 0.630435 37.9369 0.630435 35.4997V5.2388Z'
        fill='white'
      />
      <path
        d='M0.630435 5.2388C0.630435 2.80154 2.60622 0.825747 5.04348 0.825747H52.9565C55.3938 0.825747 57.3696 2.80153 57.3696 5.23879V35.4997C57.3696 37.9369 55.3938 39.9127 52.9565 39.9127H5.04348C2.60622 39.9127 0.630435 37.9369 0.630435 35.4997V5.2388Z'
        stroke='#EAECF0'
        strokeWidth='1.26087'
      />
      <g clipPath='url(#clip0_8902_89782)'>
        <path
          d='M27.8222 20.8453V25.9142H26.2129V13.3935H30.4789C31.508 13.3716 32.5025 13.762 33.2415 14.4773C33.9896 15.1506 34.4111 16.114 34.3946 17.1194C34.4165 18.1321 33.995 19.1028 33.2415 19.7779C32.4952 20.4895 31.5737 20.8453 30.4789 20.8435L27.8222 20.8453ZM27.8222 14.9353V19.3072H30.5191C31.1176 19.3254 31.6942 19.09 32.1102 18.6594C32.955 17.8383 32.9751 16.4862 32.1521 15.6414C32.1375 15.6268 32.1248 15.6122 32.1102 15.5995C31.6996 15.1597 31.1194 14.9189 30.5191 14.9353H27.8222ZM38.1023 17.0683C39.292 17.0683 40.2299 17.3858 40.9178 18.0208C41.6057 18.6558 41.9487 19.5279 41.9487 20.6337V25.9142H40.4105V24.7246H40.3412C39.6752 25.7044 38.7884 26.1934 37.6827 26.1934C36.7393 26.1934 35.9493 25.9142 35.3143 25.3541C34.6975 24.834 34.3472 24.064 34.3618 23.2557C34.3618 22.3689 34.6975 21.6646 35.3672 21.1409C36.0368 20.6173 36.9309 20.3545 38.0513 20.3545C39.0055 20.3545 39.7938 20.5297 40.4105 20.8782V20.5096C40.4142 19.9586 40.1697 19.4367 39.7464 19.0864C39.3212 18.7032 38.7665 18.4934 38.1936 18.5007C37.2958 18.5007 36.5842 18.8802 36.0606 19.6374L34.6446 18.7452C35.4219 17.6267 36.5751 17.0683 38.1023 17.0683ZM36.0204 23.2922C36.0186 23.7082 36.2175 24.0987 36.5532 24.3414C36.909 24.6206 37.3506 24.7702 37.8031 24.7611C38.4819 24.7592 39.1333 24.4892 39.6132 24.0093C40.146 23.5075 40.4124 22.92 40.4124 22.243C39.9106 21.8434 39.2117 21.6427 38.314 21.6427C37.6608 21.6427 37.1152 21.7996 36.6791 22.1153C36.2394 22.4364 36.0204 22.8251 36.0204 23.2922ZM50.7801 17.3475L45.4101 29.6913H43.7497L45.7422 25.3723L42.2096 17.3475H43.9577L46.5104 23.502H46.545L49.0284 17.3475H50.7801Z'
          fill='#5F6368'
        />
        <path
          d='M21.3212 19.7505C21.3212 19.2597 21.2811 18.7706 21.1972 18.2871H14.4131V21.0569H18.2978C18.1372 21.951 17.619 22.7411 16.8618 23.2429V25.042H19.1809C20.5385 23.7921 21.3212 21.9401 21.3212 19.7505Z'
          fill='#4285F4'
        />
        <path
          d='M14.4137 26.7818C16.3552 26.7818 17.9901 26.145 19.1816 25.0448L16.8624 23.2457C16.2165 23.6836 15.3863 23.9336 14.4137 23.9336C12.538 23.9336 10.9451 22.6691 10.3758 20.9648H7.9873V22.8187C9.208 25.2491 11.695 26.7818 14.4137 26.7818Z'
          fill='#34A853'
        />
        <path
          d='M10.3755 20.9627C10.0745 20.0705 10.0745 19.1016 10.3755 18.2093V16.3555H7.98706C6.96525 18.3881 6.96525 20.7839 7.98706 22.8166L10.3755 20.9627Z'
          fill='#FBBC04'
        />
        <path
          d='M14.4137 15.2413C15.4392 15.2249 16.43 15.6117 17.1726 16.3197L19.2272 14.2669C17.9244 13.0444 16.2001 12.3729 14.4137 12.393C11.695 12.393 9.208 13.9276 7.9873 16.3562L10.3758 18.21C10.9451 16.5058 12.538 15.2413 14.4137 15.2413Z'
          fill='#EA4335'
        />
      </g>
      <defs>
        <clipPath id='clip0_8902_89782'>
          <rect
            width='43.56'
            height='18.15'
            fill='white'
            transform='translate(7.21973 11.9688)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GooglePayIcon;
