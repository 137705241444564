import axios, { AxiosResponse } from 'axios';

import api from '../../api';
import {
  AuthResponse,
  ChangeAccountBrandingParams,
  ChangeAccountSettingsThunkParams,
  ChangeEmailParams,
  DeleteOneUserTeamParams,
  EditUserTeamParams,
  GetBillingParams,
  GetBillingResponse,
  GetInvoicePDFParams,
  GetInvoicePDFResponse,
  GetTariffPlansResponse,
  GetTInvoicesParams,
  GetUsersParams,
  GlobalSearchParams,
  GlobalSearchResponse,
  IInvoicesState,
  LoginResponse,
  MyAccount,
  PasswordConfirmationParams,
  ResponseCountry,
  ResponseDeleteAccount,
  ResponseForgot,
  ResponseRoles,
  ResponseTimezones,
  sendInvitationParams,
  DeleteInvitationsTeamParams,
  UsersBulkDeleteParams,
  UserSearchesParams,
  UserSignUpData,
  UsersState,
  GetAccountLimitParams,
  GetAccountLimitResponse,
  NecessaryRemovalParams,
  NecessaryRemovalResponse,
  GetCheckoutSettingsResponse,
  GetSearchEnginesResponse,
  GetUserTeamFullInfoParams,
  ITeamFullInfo,
} from './types';
import { API_URL } from '../../constants/config';

/**
 * Registers a new user.
 * @param {UserSignUpData} data - The data required for user registration.
 * @returns {Promise<AxiosResponse<AuthResponse>>} - Axios response containing authentication information.
 */
const signUpAPI = async (
  data: UserSignUpData
): Promise<AxiosResponse<AuthResponse>> => {
  return api.post<AuthResponse>('/auth/register-user', data);
};

/**
 * Logs in a user.
 * @param {string} email - The user's email.
 * @param {string} password - The user's password.
 * @returns {Promise<AxiosResponse<LoginResponse>>} - Axios response containing login information.
 */
export const loginAPI = async (
  email: string,
  password: string
): Promise<AxiosResponse<LoginResponse>> => {
  return api.post<LoginResponse>('/auth/login-user', { email, password });
};

/**
 * Sends a forgot password email.
 * @param {string} email - The user's email.
 * @returns {Promise<AxiosResponse<ResponseForgot>>} - Axios response confirming the email was sent.
 */
export const forgotAPI = async (
  email: string
): Promise<AxiosResponse<ResponseForgot>> => {
  return api.post<ResponseForgot>('/auth/forgot-password-letter', { email });
};

/**
 * Resets a user's password.
 * @param {string} passwordResetConfirmationToken - The token confirming the password reset.
 * @param {string} password - The new password.
 * @returns {Promise<AxiosResponse<ResponseForgot>>} - Axios response confirming the password reset.
 */
export const resetAPI = async (
  passwordResetConfirmationToken: string,
  password: string
): Promise<AxiosResponse<ResponseForgot>> => {
  return api.patch<ResponseForgot>('/auth/reset-password', {
    passwordResetConfirmationToken,
    password,
  });
};

/**
 * Checks authentication using a refresh token.
 * @param {string | null} [refresh] - The refresh token.
 * @returns {Promise<AxiosResponse<LoginResponse>>} - Axios response containing login information.
 */
export const checkAuthAPI = async (refresh?: string | null) => {
  return await axios.post<LoginResponse>(`${API_URL}/auth/refresh-token`, {
    refreshToken: refresh,
  });
};

/**
 * Confirms a user's email.
 * @param {string} emailConfirmationToken - The token for email confirmation.
 * @returns {Promise<AxiosResponse<ResponseForgot>>} - Axios response confirming the email.
 */
export const confirmEmailAPI = async (
  emailConfirmationToken: string
): Promise<AxiosResponse<ResponseForgot>> => {
  return api.patch<ResponseForgot>('/auth/confirm-email', {
    emailConfirmationToken,
  });
};

/**
 * Confirms an email change request.
 * @param {string} emailConfirmationToken - The token for email confirmation.
 * @param {string} newEmail - The new email address.
 * @returns {Promise<AxiosResponse<null>>} - Axios response confirming the email change.
 */
export const emailChangeConfirmationAPI = async (
  emailConfirmationToken: string,
  newEmail: string
): Promise<AxiosResponse<null>> => {
  return api.get<null>(
    `/accounts/email-change-confirmation/${emailConfirmationToken}`,
    {
      params: { newEmail },
    }
  );
};

/**
 * Resends a forgot password letter.
 * @param {string} email - The user's email.
 * @returns {Promise<AxiosResponse<ResponseForgot>>} - Axios response confirming the email was resent.
 */
export const resendingForgotPasswordLetterAPI = async (
  email: string
): Promise<AxiosResponse<ResponseForgot>> => {
  return api.post<ResponseForgot>('/auth/resending-forgot-password-letter', {
    email,
  });
};

/**
 * Resends a mail confirmation letter.
 * @param {string} email - The user's email.
 * @returns {Promise<AxiosResponse<ResponseForgot>>} - Axios response confirming the email was resent.
 */
export const resendingMailConfirmationLetterAPI = async (
  email: string
): Promise<AxiosResponse<ResponseForgot>> => {
  return api.post<ResponseForgot>('/auth/resending-mail-confirmation-letter', {
    email,
  });
};

/**
 * Retrieves account details by ID.
 * @param {number} id - The account ID.
 * @returns {Promise<AxiosResponse<MyAccount>>} - Axios response containing account details.
 */
export const getMyAccountAPI = async (
  id: number
): Promise<AxiosResponse<MyAccount>> => {
  return api.get<MyAccount>(`accounts/${id}`);
};

/**
 * Retrieves all available countries.
 * @returns {Promise<AxiosResponse<ResponseCountry>>} - Axios response containing countries.
 */
export const getAllCountriesAPI = async (): Promise<
  AxiosResponse<ResponseCountry>
> => {
  return api.get<ResponseCountry>('countries');
};

/**
 * Retrieves all available timezones.
 * @returns {Promise<AxiosResponse<ResponseTimezones>>} - Axios response containing timezones.
 */
export const getAllTimezonesAPI = async (): Promise<
  AxiosResponse<ResponseTimezones>
> => {
  return api.get<ResponseTimezones>('timezones');
};

/**
 * Deletes an account.
 * @param {string} reason - The reason for deleting the account.
 * @param {number} id - The account ID.
 * @returns {Promise<AxiosResponse<ResponseDeleteAccount>>} - Axios response confirming the account deletion.
 */
export const deleteAccountAPI = async (
  reason: string,
  id: number
): Promise<AxiosResponse<ResponseDeleteAccount>> => {
  return api.post<ResponseDeleteAccount>(`accounts/${id}`, { reason });
};

/**
 * Changes account settings.
 * @param {ChangeAccountSettingsThunkParams} data - The data required to change account settings.
 * @returns {Promise<AxiosResponse<MyAccount>>} - Axios response containing updated account details.
 */
export const changeAccountSettingsAPI = async (
  data: ChangeAccountSettingsThunkParams
): Promise<AxiosResponse<MyAccount>> => {
  return api.patch<MyAccount>(`accounts/${data.id}/change-account-settings`, {
    ...data.requestData,
  });
};

/**
 * Confirms a user's password.
 * @param {PasswordConfirmationParams} data - The data required for password confirmation.
 * @returns {Promise<AxiosResponse<ResponseForgot>>} - Axios response confirming the password.
 */
export const passwordConfirmationAPI = async (
  data: PasswordConfirmationParams
): Promise<AxiosResponse<ResponseForgot>> => {
  return api.post<ResponseForgot>(
    `/accounts/${data.id}/password-confirmation`,
    {
      password: data.password,
    }
  );
};

/**
 * Changes account branding.
 * @param {ChangeAccountBrandingParams} data - The data required to change account branding.
 * @returns {Promise<AxiosResponse<MyAccount>>} - Axios response containing updated account details.
 */
export const changeAccountBrandingAPI = async (
  data: ChangeAccountBrandingParams
): Promise<AxiosResponse<MyAccount>> => {
  return api.patch<MyAccount>(
    `/accounts/${data.id}/change-account-branding`,
    data.formData
  );
};

/**
 * Cancels account deletion.
 * @param {number} id - The account ID.
 * @returns {Promise<AxiosResponse<MyAccount>>} - Axios response confirming the account deletion was canceled.
 */
export const cancelAccountDeletionAPI = async (
  id: number
): Promise<AxiosResponse<MyAccount>> => {
  return api.patch<MyAccount>(`/accounts/${id}/cancel-account-deletion`);
};

/**
 * Retrieves users based on account ID and parameters.
 * @param {GetUsersParams} params - The parameters required to get users.
 * @returns {Promise<AxiosResponse<UsersState>>} - Axios response containing users.
 */
export const getUsersAPI = async (
  params: GetUsersParams
): Promise<AxiosResponse<UsersState>> => {
  return api.get<UsersState>(
    `/accounts/${params.accountId}/users-invitations`,
    {
      params: {
        page: params.page,
        limit: params.limit,
        search: params.search,
        sortBy: params.sortBy,
        sortOrder: params.sortOrder ? params.sortOrder.toUpperCase() : null,
      },
    }
  );
};

/**
 * Retrieves all roles.
 * @returns {Promise<AxiosResponse<ResponseRoles>>} - Axios response containing roles.
 */
export const getAllRolesAPI = async (): Promise<
  AxiosResponse<ResponseRoles>
> => {
  return api.get<ResponseRoles>('/roles');
};

/**
 * Sends an invitation.
 * @param {sendInvitationParams} data - The data required to send an invitation.
 * @returns {Promise<AxiosResponse<ResponseForgot>>} - Axios response confirming the invitation was sent.
 */
export const sendInvitationAPI = async (
  data: sendInvitationParams
): Promise<AxiosResponse<ResponseForgot>> => {
  return api.post<ResponseForgot>(`/accounts/${data.accountId}/invitations`, {
    ...data.data,
  });
};

/**
 * Deletes one invitation from a team.
 * @param {DeleteInvitationsTeamParams} params - The parameters required to delete the invitation.
 * @returns {Promise<AxiosResponse<ResponseForgot>>} - Axios response confirming the invitation was deleted.
 */
export const deleteOneInvitationTeamAPI = async (
  params: DeleteInvitationsTeamParams
): Promise<AxiosResponse<ResponseForgot>> => {
  return api.post<ResponseForgot>(
    `/accounts/${params.accountId}/invitation/bulk-delete`,
    { ids: params.ids }
  );
};

/**
 * Edits user details in a team.
 * @param {EditUserTeamParams} params - The parameters required to edit the user.
 * @returns {Promise<AxiosResponse<ResponseForgot>>} - Axios response confirming the user details were edited.
 */
export const editUserTeamAPI = async (
  params: EditUserTeamParams
): Promise<AxiosResponse<ResponseForgot>> => {
  return api.patch<ResponseForgot>(
    `/accounts/${params.accountId}/users/${params.userId}`,
    {
      ...params.data,
    }
  );
};

/**
 * Deletes one user from a team.
 * @param {DeleteOneUserTeamParams} params - The parameters required to delete the user.
 * @returns {Promise<AxiosResponse<ResponseForgot>>} - Axios response confirming the user was deleted.
 */
export const deleteOneUserTeamAPI = async (
  params: DeleteOneUserTeamParams
): Promise<AxiosResponse<ResponseForgot>> => {
  return api.delete<ResponseForgot>(
    `/accounts/${params.accountId}/users/${params.userId}`
  );
};

/**
 * Deletes multiple users from a team.
 * @param {UsersBulkDeleteParams} params - The parameters required to delete the users.
 * @returns {Promise<AxiosResponse<ResponseForgot>>} - Axios response confirming the users were deleted.
 */
export const usersBulkDeleteTeamAPI = async (
  params: UsersBulkDeleteParams
): Promise<AxiosResponse<ResponseForgot>> => {
  return api.post<ResponseForgot>(
    `/accounts/${params.accountId}/users/bulk-delete`,
    { ...params }
  );
};

/**
 * Sets an account as the default account.
 * @param {number} accountId - The account ID.
 * @returns {Promise<AxiosResponse>} - Axios response confirming the account was set as default.
 */
export const setAsDefaultAccountAPI = async (accountId: number) => {
  return api.patch(`/accounts/${accountId}/set-as-default`);
};

/**
 * Removes access to an account.
 * @param {number} accountId - The account ID.
 * @returns {Promise<AxiosResponse>} - Axios response confirming the access was removed.
 */
export const removeAccessAccountAPI = async (accountId: number) => {
  return api.delete(`/accounts/${accountId}`);
};

/**
 * Removes access to multiple accounts.
 * @param {{ accountIds: number[] }} data - The account IDs.
 * @returns {Promise<AxiosResponse>} - Axios response confirming the accesses were removed.
 */
export const removeAccessesAccountsAPI = async (data: {
  accountIds: number[];
}) => {
  return api.post(`/accounts/bulk-delete`, {
    accountIds: data.accountIds.map(String),
  });
};

/**
 * Performs a global search within an account.
 * @param {GlobalSearchParams} params - The parameters required for the search.
 * @returns {Promise<AxiosResponse<GlobalSearchResponse>>} - Axios response containing the search results.
 */
export const globalSearchAPI = async (
  params: GlobalSearchParams
): Promise<AxiosResponse<GlobalSearchResponse>> => {
  const filters = params.sortBy
    ? {
        projects: params.sortBy === 'projects',
        folders: params.sortBy === 'folders',
        keywords: params.sortBy === 'keywords',
        tags: params.sortBy === 'tags',
      }
    : { projects: true, folders: true, keywords: true, tags: true };

  return api.get<GlobalSearchResponse>(`/accounts/${params.accountId}/search`, {
    params: {
      search: params.search,
      ...filters,
    },
  });
};

/**
 * Requests a new API key.
 * @param {{ id: number }} params - The parameters required to request a new key.
 * @returns {Promise<AxiosResponse>} - Axios response confirming the request.
 */
export const requestNewKeyAPI = async (params: { id: number }) => {
  return api.post(`/accounts/${params.id}/api/request-new-key`);
};

/**
 * Retrieves tariff plans.
 * @param {string} type - The type of tariff plan.
 * @returns {Promise<AxiosResponse<GetTariffPlansResponse>>} - Axios response containing tariff plans.
 */
export const getTariffPlansAPI = async (
  type: string
): Promise<AxiosResponse<GetTariffPlansResponse>> => {
  return api.get<GetTariffPlansResponse>(
    `/accounts/subscriptions/tariff-plans`,
    { params: { type: type } }
  );
};

/**
 * Changes the account email.
 * @param {ChangeEmailParams} params - The parameters required to change the email.
 * @returns {Promise<AxiosResponse<null>>} - Axios response confirming the email change.
 */
export const changeEmailAPI = async (
  params: ChangeEmailParams
): Promise<AxiosResponse<null>> => {
  return api.post<null>(`/accounts/${params.accountId}/change-email`, {
    currentEmail: params.data.currentEmail,
    newEmail: params.data.newEmail,
    confirmationPassword: params.data.confirmationPassword,
  });
};

/**
 * Retrieves invoices.
 * @param {GetTInvoicesParams} params - The parameters required to get invoices.
 * @returns {Promise<AxiosResponse<IInvoicesState>>} - Axios response containing invoices.
 */
export const getInvoices = async (
  params: GetTInvoicesParams
): Promise<AxiosResponse<IInvoicesState>> => {
  return api.get<IInvoicesState>(`accounts/${params.id}/invoices`, {
    params: {
      page: params.page + 1,
      limit: params.limit,
      sortBy: params.sortBy,
      sortOrder: params?.sortOrder ? params.sortOrder.toUpperCase() : null,
      search: params.search,
    },
  });
};

/**
 * Retrieves an invoice PDF.
 * @param {GetInvoicePDFParams} params - The parameters required to get the invoice PDF.
 * @returns {Promise<AxiosResponse<GetInvoicePDFResponse>>} - Axios response containing the invoice PDF.
 */
export const getInvoicePDF = async (
  params: GetInvoicePDFParams
): Promise<AxiosResponse<GetInvoicePDFResponse>> => {
  return api.get<GetInvoicePDFResponse>(
    `accounts/${params.id}/invoices/${params.transactionId}`
  );
};

/**
 * Retrieves billing information.
 * @param {GetBillingParams} params - The parameters required to get billing information.
 * @returns {Promise<AxiosResponse<GetBillingResponse>>} - Axios response containing billing information.
 */
export const getBilling = async (
  params: GetBillingParams
): Promise<AxiosResponse<GetBillingResponse>> => {
  return api.get<GetBillingResponse>(`accounts/${params.id}/billing`);
};

/**
 * Logs a user search.
 * @param {UserSearchesParams} params - The parameters required to log the search.
 * @returns {Promise<AxiosResponse<null>>} - Axios response confirming the search was logged.
 */
export const userSearches = async (
  params: UserSearchesParams
): Promise<AxiosResponse<null>> => {
  return api.post<null>(`user-searches`, { projectId: params.projectId });
};

/**
 * Clears recently viewed user searches.
 * @returns {Promise<AxiosResponse<null>>} - Axios response confirming recently viewed searches were cleared.
 */
export const clearRecentlyViewed = async (): Promise<AxiosResponse<null>> => {
  return api.delete<null>(`user-searches`);
};

/**
 * Retrieves account limits.
 * @param {GetAccountLimitParams} params - The parameters required to get account limits.
 * @returns {Promise<AxiosResponse<GetAccountLimitResponse>>} - Axios response containing account limits.
 */
export const getAccountLimit = async (
  params: GetAccountLimitParams
): Promise<AxiosResponse<GetAccountLimitResponse>> => {
  return api.get<GetAccountLimitResponse>(`accounts/${params.id}/limits`);
};

/**
 * Requests necessary removal.
 * @param {NecessaryRemovalParams} params - The parameters required for necessary removal.
 * @returns {Promise<AxiosResponse<NecessaryRemovalResponse>>} - Axios response confirming necessary removal.
 */
export const necessaryRemoval = async (
  params: NecessaryRemovalParams
): Promise<AxiosResponse<NecessaryRemovalResponse>> => {
  return api.post<NecessaryRemovalResponse>(
    `accounts/${params.id}/necessary-removal`,
    { paddleProductId: params.paddleProductId }
  );
};

/**
 * Retrieves checkout settings.
 * @returns {Promise<AxiosResponse<GetCheckoutSettingsResponse>>} - Axios response containing checkout settings.
 */
export const getCheckoutSettings = async (): Promise<
  AxiosResponse<GetCheckoutSettingsResponse>
> => {
  return api.get<GetCheckoutSettingsResponse>(`payments/checkout-settings`);
};

/**
 * Retrieves search engines.
 * @returns {Promise<AxiosResponse<GetSearchEnginesResponse>>} - Axios response containing search engines.
 */
export const getSearchEngines = async (): Promise<
  AxiosResponse<GetSearchEnginesResponse>
> => {
  return api.get<GetSearchEnginesResponse>(`search-engines`);
};

/**
 * Retrieves full user team information.
 * @param {GetUserTeamFullInfoParams} params - The parameters required to get user team information.
 * @returns {Promise<AxiosResponse<ITeamFullInfo>>} - Axios response containing user team information.
 */
export const getUserTeamFullInfoAPI = async (
  params: GetUserTeamFullInfoParams
): Promise<AxiosResponse<ITeamFullInfo>> => {
  return api.get<ITeamFullInfo>(`accounts/${params.id}/users/${params.userId}`);
};

/**
 * Retrieves full invitation team information.
 * @param {GetUserTeamFullInfoParams} params - The parameters required to get invitation team information.
 * @returns {Promise<AxiosResponse<ITeamFullInfo>>} - Axios response containing invitation team information.
 */
export const getInvitationTeamFullInfoAPI = async (
  params: GetUserTeamFullInfoParams
): Promise<AxiosResponse<ITeamFullInfo>> => {
  return api.get<ITeamFullInfo>(
    `/accounts/${params.id}/invitations/${params.userId}`
  );
};

export { signUpAPI };
