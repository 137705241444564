import React, { useMemo } from 'react';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';

import DeleteProjectsAction from './DeleteProjectsAction';
import { DeleteProjectsActionProps } from './types';

import { MainContainer } from '../styles';
import RefreshAllKeywordsAction from './RefreshAllKeywordsAction';
import EditFrequencyAction from './EditFrequencyAction';
import { useAppSelector } from '../../../../../../../../../store';
import MoveToFolderAction from '../../EllipsisMenu/components/MoveToFolderAction';

const ListActions: React.FC<DeleteProjectsActionProps> = ({ popupClose }) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const isAuth = useAppSelector(state => state.auth.isAuth);

  const projectIds = useAppSelector(
    state => state.projects.tables.projects.rowSelectionModel
  );

  const isAddon = useMemo(
    () => !isAuth || (isAuth && currentAccount?.role?.name === 'Addon'),
    [currentAccount?.role?.name, isAuth]
  );

  return (
    <MainContainer>
      <List>
        <EditFrequencyAction popupClose={popupClose} />

        <RefreshAllKeywordsAction popupClose={popupClose} />

        {!isAddon && (
          <MoveToFolderAction
            projectIds={projectIds as number[]}
            popupClose={popupClose}
          />
        )}

        {!isAddon && (
          <>
            <Divider
              orientation='horizontal'
              flexItem
              sx={{
                margin: '8px 0',
                color: '#EDEDED',
              }}
            />

            <DeleteProjectsAction popupClose={popupClose} />
          </>
        )}
      </List>
    </MainContainer>
  );
};

export default ListActions;
