import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StyledKeywordRankHistory = styled(Grid)`
  border-radius: 8px;
  border: 1px solid #ededed;
  background: #fff;
  box-shadow: 0 3px 2px -1px rgba(0, 0, 0, 0.02),
    0px 1px 1px -1px rgba(0, 0, 0, 0.04);

  padding: 24px 0;

  ${props => props.theme.breakpoints.down('sm')} {
    padding: 16px 0;
  }
`;

const WrapContainer = styled(Grid)`
  padding: 0 24px;

  ${props => props.theme.breakpoints.down('sm')} {
    padding: 0 16px;
  }
`;

const StyledBreadcrumbsAdditional = styled(WrapContainer)`
  margin-top: 16px;
  margin-bottom: 36px;
`;

export { StyledKeywordRankHistory, WrapContainer, StyledBreadcrumbsAdditional };
