import { styled } from '@mui/material/styles';
import { ListItem, ListItemButton, Typography } from '@mui/material';

const MainContainer = styled('div')`
  min-width: 196px;
  padding: 0 8px;
`;

const StyledListItem = styled(ListItem)`
  border-radius: 8px;
  padding: 0;
  cursor: pointer;

  .MuiListItemButton-root {
    border-radius: 8px;
  }

  :hover {
    background-color: #f6f6f6;
  }
`;

const ListItemTypography = styled(Typography)`
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const StyledListItemButton = styled(ListItemButton)`
  display: flex;
  padding: 8px;
  align-items: center;
`;

export {
  MainContainer,
  StyledListItem,
  ListItemTypography,
  StyledListItemButton,
};
