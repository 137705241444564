import React from 'react';
import { writeText } from 'clipboard-polyfill';
import {
  ListItemTypography,
  StyledListItem,
} from '../../../../../../../projects/components/ProjectExpanded/components/KeywordRankings/components/KeywordRankingsTable/components/EllipsisMenu/styles';
import { CopySharedLinkActionProps } from './types';
import CopySmallIcon from '../../../../../../../../shared/images/CopySmallIcon';
import { StyledListItemButton } from '../../../../../../../../shared/buttons/styles';
import { useSharedLinks } from '../../../../../../../../hooks';

const CopySharedLinkAction: React.FC<CopySharedLinkActionProps> = ({
  link,
  popupClose,
}) => {
  const { onSetRowSelectionModelSharedLinks } = useSharedLinks();

  const onOpen = () => {
    onSetRowSelectionModelSharedLinks([]);
    writeText(link);
    popupClose();
  };

  return (
    <StyledListItem>
      <StyledListItemButton onClick={onOpen}>
        <CopySmallIcon />
        <ListItemTypography marginLeft='8px'>
          Copy Shared Link
        </ListItemTypography>
      </StyledListItemButton>
    </StyledListItem>
  );
};

export default CopySharedLinkAction;
