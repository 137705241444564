import { styled } from '@mui/material/styles';

import {
  ListItemTypography as ProjectListItemTypography,
  MainContainer as ProjectMainContainer,
  PopupLabel as ProjectPopupLabel,
  ResetDefaultButton as ProjectResetDefaultButton,
  StyledListItem as ProjectStyledListItem,
} from '../../../../../../../../../projects/components/Projects/components/TableProjects/components/Header/components/PopupTableSettings/styles';

const MainContainer = styled(ProjectMainContainer)``;

const PopupLabel = styled(ProjectPopupLabel)``;

const ResetDefaultButton = styled(ProjectResetDefaultButton)``;

const ListItemTypography = styled(ProjectListItemTypography)``;

const StyledListItem = styled(ProjectStyledListItem)``;

export {
  MainContainer,
  PopupLabel,
  ResetDefaultButton,
  ListItemTypography,
  StyledListItem,
};
