import React, { useState } from 'react';
import {
  Box,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material';

import { CustomFormHelperText } from '../../../../../../shared/components';
import { AccountPasswordFormProps } from './types';
import { FormItem } from '../../styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { PasswordStateLabel } from '../../../RegisterForm/styles';
import { useAppSelector } from '../../../../../../store';

/**
 * AccountPasswordForm component allows users to change their account password by entering the current password, new password, and confirming the new password.
 *
 * @component
 * @param {AccountPasswordFormProps} formik - Formik props to handle form state and submission.
 * @returns {JSX.Element} Rendered AccountPasswordForm component.
 */
const AccountPasswordForm: React.FC<AccountPasswordFormProps> = formik => {
  const user = useAppSelector(state => state.auth.user);

  const [showCurrentPassword, setShowCurrentPassword] =
    useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] =
    useState<boolean>(false);

  const handleClickShowCurrentPassword = () =>
    setShowCurrentPassword(show => !show);

  const handleClickShowNewPassword = () => setShowNewPassword(show => !show);

  const handleClickShowConfirmNewPassword = () =>
    setShowConfirmNewPassword(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      <Grid container>
        <Grid
          container
          item
          sx={{ borderBottom: '1px solid #EAECF0', pt: 4, pb: 4 }}
        >
          <Grid item lg={12}>
            <Box>
              <Typography
                variant='h6'
                fontWeight='600'
                color='#101828'
                fontSize='18px'
                lineHeight='28px'
              >
                Password
              </Typography>
            </Box>
            <Box>
              <Typography
                variant='body2'
                color='#475467'
                fontSize='14px'
                lineHeight='20px'
              >
                Please enter your current password to change your password.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {user?.passwordExists && (
          <FormItem container item sx={{ pt: 4, pb: 4 }}>
            <Grid item lg={2} sm={4} xs={12}>
              <Typography variant='body2' fontWeight='500' color='#344054'>
                Current password
              </Typography>
            </Grid>
            <Grid container item lg={7.5} sm={8} xs={12}>
              <>
                <OutlinedInput
                  fullWidth
                  type={showCurrentPassword ? 'text' : 'password'}
                  size={'small'}
                  id='currentPassword'
                  placeholder='Current password'
                  name='currentPassword'
                  value={formik.values.currentPassword}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!(
                      formik.touched.currentPassword &&
                      formik.errors.currentPassword
                    )
                  }
                  endAdornment={
                    <InputAdornment
                      position='end'
                      onClick={handleClickShowCurrentPassword}
                      sx={{ cursor: 'pointer' }}
                    >
                      <IconButton
                        aria-label='toggle password visibility'
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showCurrentPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                      <PasswordStateLabel>
                        {showCurrentPassword ? <b>Hide</b> : <b>Show</b>}
                      </PasswordStateLabel>
                    </InputAdornment>
                  }
                />
                {formik.touched.currentPassword &&
                  formik.errors.currentPassword && (
                    <FormHelperText error id='currentPassword'>
                      <CustomFormHelperText
                        error={formik.errors.currentPassword}
                      />
                    </FormHelperText>
                  )}
              </>
            </Grid>
          </FormItem>
        )}
        <FormItem container item sx={{ pt: 4, pb: 4 }}>
          <Grid item lg={2} sm={4} xs={12}>
            <Typography variant='body2' fontWeight='500' color='#344054'>
              New password
            </Typography>
          </Grid>
          <Grid container item lg={7.5} sm={8} xs={12}>
            <OutlinedInput
              fullWidth
              type={showNewPassword ? 'text' : 'password'}
              size={'small'}
              id='newPassword'
              placeholder='New password'
              name='newPassword'
              value={formik.values.newPassword}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                !!(formik.touched.newPassword && formik.errors.newPassword)
              }
              endAdornment={
                <InputAdornment
                  position='end'
                  onClick={handleClickShowNewPassword}
                  sx={{ cursor: 'pointer' }}
                >
                  <IconButton
                    aria-label='toggle password visibility'
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                  <PasswordStateLabel>
                    {showNewPassword ? <b>Hide</b> : <b>Show</b>}
                  </PasswordStateLabel>
                </InputAdornment>
              }
            />
            <FormHelperText>
              <Typography variant='body2' color='#666666'>
                Minimum 8 characters with at least one number.
              </Typography>
            </FormHelperText>
            {formik.touched.newPassword && formik.errors.newPassword && (
              <FormHelperText error id='newPassword'>
                <CustomFormHelperText error={formik.errors.newPassword} />
              </FormHelperText>
            )}
          </Grid>
        </FormItem>
        <FormItem container item sx={{ pt: 4, pb: 4 }}>
          <Grid item lg={2} sm={4} xs={12}>
            <Typography variant='body2' fontWeight='500' color='#344054'>
              Confirm new password
            </Typography>
          </Grid>
          <Grid container item lg={7.5} sm={8} xs={12}>
            <OutlinedInput
              fullWidth
              type={showConfirmNewPassword ? 'text' : 'password'}
              size={'small'}
              id='confirm_new_password'
              placeholder='Confirm new password'
              name='confirm_new_password'
              value={formik.values.confirm_new_password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                !!(
                  formik.touched.confirm_new_password &&
                  formik.errors.confirm_new_password
                )
              }
              endAdornment={
                <InputAdornment
                  position='end'
                  onClick={handleClickShowConfirmNewPassword}
                  sx={{ cursor: 'pointer' }}
                >
                  <IconButton
                    aria-label='toggle password visibility'
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showConfirmNewPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                  <PasswordStateLabel>
                    {showConfirmNewPassword ? <b>Hide</b> : <b>Show</b>}
                  </PasswordStateLabel>
                </InputAdornment>
              }
            />
            {formik.touched.confirm_new_password &&
              formik.errors.confirm_new_password && (
                <FormHelperText error id='confirm_new_password'>
                  <CustomFormHelperText
                    error={formik.errors.confirm_new_password}
                  />
                </FormHelperText>
              )}
          </Grid>
        </FormItem>
      </Grid>
    </>
  );
};

export default AccountPasswordForm;
