import React from 'react';

const GreenCheckMarkIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
  >
    <path
      d='M6.61765 0.5C3.03357 0.5 0.117645 3.41585 0.117645 7C0.117645 10.5841 3.03357 13.5001 6.61765 13.5001C10.2017 13.5001 13.1176 10.5841 13.1176 7C13.1176 3.41585 10.2018 0.5 6.61765 0.5ZM10.3501 5.9021L6.26644 9.98572C6.0928 10.1594 5.86199 10.2549 5.61646 10.2549C5.37093 10.2549 5.14012 10.1594 4.96648 9.98572L2.88523 7.90446C2.7116 7.73083 2.61596 7.50002 2.61596 7.25448C2.61596 7.00889 2.7116 6.77807 2.88523 6.60444C3.0588 6.4308 3.28961 6.33517 3.53521 6.33517C3.78074 6.33517 4.01162 6.4308 4.18519 6.60451L5.61639 8.03564L9.04997 4.60207C9.2236 4.42843 9.45441 4.33287 9.69994 4.33287C9.94547 4.33287 10.1763 4.42843 10.3499 4.60207C10.7085 4.9606 10.7085 5.5437 10.3501 5.9021Z'
      fill='#1EA13A'
    />
  </svg>
);

export default GreenCheckMarkIcon;
