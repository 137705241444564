import React from 'react';
import { Content } from '../../features/projects/components/Projects/components/TableProjects/components/Header/components/Tooltips/styles';

interface ChildrenTooltipProps {
  children?: React.ReactNode;
}

const ChildrenTooltip: React.FC<ChildrenTooltipProps> = ({ children }) => (
  <div>
    <Content>{children}</Content>
  </div>
);

export default ChildrenTooltip;
