import React, { useMemo } from 'react';
import { StylesBanner, StylesUpgradeNowButton } from './styles';
import LightningIcon from '../../images/LightningIcon';
import ROUTES from '../../../routes/constants';
import { useNavigate } from 'react-router';
import { Grid, IconButton, useMediaQuery } from '@mui/material';
import CloseBannerIcon from '../../images/CloseBannerIcon';
interface YourFreeTrialEndsBannerProps {
  daysRemaining: number;
  tariffPlanName: string;
  tariffPlanStatus: string;
  onClose: () => void;
}

const YourFreeTrialEndsBanner: React.FC<YourFreeTrialEndsBannerProps> = ({
  onClose,
  daysRemaining,
  tariffPlanName,
  tariffPlanStatus,
}) => {
  const navigate = useNavigate();
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const onUpgradePlan = () => {
    navigate(ROUTES.upgradePlan);
  };

  const textError = useMemo(() => {
    if (tariffPlanName === 'Trial Period') {
      if (
        (tariffPlanStatus === 'activated' || tariffPlanStatus === 'updated') &&
        !daysRemaining
      ) {
        return `Your free trial ends today.`;
      } else {
        return `Your free trial ends in ${daysRemaining} days.`;
      }
    } else {
      return '';
    }
  }, [tariffPlanName, daysRemaining, tariffPlanStatus]);

  return (
    <StylesBanner container justifyContent={'space-between'} xs={12}>
      <Grid
        display={'flex'}
        alignItems={'center'}
        xs={12}
        justifyContent={'center'}
        gap={'16px'}
      >
        <Grid>{textError}</Grid>
        <StylesUpgradeNowButton
          variant='contained'
          size='small'
          $padding='8px 14px'
          startIcon={<LightningIcon />}
          onClick={onUpgradePlan}
        >
          Upgrade Plan
        </StylesUpgradeNowButton>
      </Grid>
      {!extraSmallScreen && (
        <Grid position={'fixed'} right={16}>
          <IconButton onClick={onClose}>
            <CloseBannerIcon />
          </IconButton>
        </Grid>
      )}
    </StylesBanner>
  );
};

export default YourFreeTrialEndsBanner;
