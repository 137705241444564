import React, { useEffect, useMemo } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';

import { Errors } from '../shared';
import ROUTES from './constants';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import * as Pages from '../pages';
import PrivateLayout from '../shared/layout/PrivateLayout/PrivateLayout';
import Default from '../themes/default';
import PublicLayout from '../shared/layout/PublicLayout';
import { useAuth } from '../hooks';
import {
  getAccessWithLocalStorage,
  getAccessWithSessionStorage,
  getRefreshWithLocalStorage,
  getRefreshWithSessionStorage,
} from '../utils/localStorage';
import SharedLinksRoutes from './SharedLinksRoutes';
import SharedLinksLayout from '../shared/layout/SharedLinksLayout/SharedLinksLayout';
import PaddleLayout from '../shared/layout/PaddleLayout';
import PreferredTariffPlanLayout from '../shared/layout/PreferredTariffPlanLayout';
import { useAppSelector } from '../store';
import LoginLayout from '../shared/layout/LoginLayout';
import TestPayRoutes from './TestPayRoutes';
import TestPayPage from '../pages/TestPayPage';
import AccountLimitsLayout from '../shared/layout/AccountLimitsLayout';
import HelpScoutLayout from '../shared/layout/HelpScoutLayout';

/**
 * Main application routes component.
 *
 * @returns {JSX.Element} The rendered routes for the application, wrapped in various layout components.
 *
 * This component handles the authentication state, fetches user account details,
 * and configures the theme for the application. It defines both public and private routes
 * along with their respective layouts.
 */
const AppRoutes = () => {
  const location = useLocation();

  const { checkAuth, onGetMyAccount, onGetBilling, onGetAccountLimit } =
    useAuth();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const isAuth = useAppSelector(state => state.auth.isAuth);

  const isViewOnly = useMemo(
    () => isAuth && currentAccount?.role?.name === 'ViewOnly',
    [currentAccount?.role?.name, isAuth]
  );

  const isAddon = useMemo(
    () => isAuth && currentAccount?.role?.name === 'Addon',
    [currentAccount?.role?.name, isAuth]
  );

  const themeOptions = useMemo(
    () => ({
      ...Default(),
    }),
    []
  );

  const themes = createTheme(themeOptions);

  const access = useMemo(
    () => getAccessWithLocalStorage(),
    [location.pathname]
  );

  const refresh = useMemo(
    () => getRefreshWithLocalStorage(),
    [location.pathname]
  );

  const accessSession = useMemo(
    () => getAccessWithSessionStorage(),
    [location.pathname]
  );

  const refreshSession = useMemo(
    () => getRefreshWithSessionStorage(),
    [location.pathname]
  );

  useEffect(() => {
    if (!isAuth && (access || accessSession)) {
      checkAuth(refresh || refreshSession);
    }
  }, [isAuth, access, accessSession, checkAuth, refresh, refreshSession]);

  useEffect(() => {
    if (isAuth && currentAccount?.accountId) {
      onGetMyAccount(currentAccount.accountId);
      onGetAccountLimit({ id: currentAccount.accountId });
      if (currentAccount.isMyAccount || currentAccount.role.name === 'Admin')
        onGetBilling({ id: currentAccount.accountId });
    }
  }, [
    onGetBilling,
    onGetAccountLimit,
    onGetMyAccount,
    isAuth,
    currentAccount?.accountId,
    currentAccount?.isMyAccount,
    currentAccount?.role?.name,
  ]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <Errors.Boundary>
          <Routes>
            <Route element={<PaddleLayout />}>
              <Route element={<HelpScoutLayout />}>
                <Route
                  path={ROUTES.errNetwork}
                  element={<Pages.Maintenance />}
                />
                <Route element={<PrivateRoutes />}>
                  <Route element={<PrivateLayout />}>
                    <Route element={<AccountLimitsLayout />}>
                      <Route element={<PreferredTariffPlanLayout />}>
                        <Route
                          path={ROUTES.main}
                          element={<Navigate to={ROUTES.projects} />}
                        />

                        <Route
                          path='/settings'
                          element={
                            <Navigate
                              to={ROUTES.dynamic.settings('account')}
                              replace
                            />
                          }
                        />
                        <Route
                          path={ROUTES.dynamic.settings()}
                          element={
                            isViewOnly || isAddon ? (
                              <Navigate to={ROUTES.projects} />
                            ) : (
                              <Pages.Settings />
                            )
                          }
                        />
                        <Route
                          path={ROUTES.projects}
                          element={<Pages.Projects />}
                        />
                        <Route
                          path={ROUTES.dynamic.projectExpanded()}
                          element={<Pages.ProjectExpanded />}
                        />
                        <Route
                          path={ROUTES.rootFolders}
                          element={<Pages.Folders />}
                        />
                        <Route
                          path={ROUTES.dynamic.folders()}
                          element={<Pages.Folders />}
                        />
                        <Route
                          path={ROUTES.dynamic.keywordRankHistory()}
                          element={<Pages.KeywordRankHistory />}
                        />
                        <Route path={ROUTES.notes} element={<Pages.Notes />} />
                        <Route
                          path={ROUTES.upgradePlan}
                          element={
                            isViewOnly || isAddon ? (
                              <Navigate to={ROUTES.projects} />
                            ) : (
                              <Pages.UpgradePlan />
                            )
                          }
                        />
                        <Route
                          path={ROUTES.alerts}
                          element={<Pages.Alerts />}
                        />

                        <Route
                          path={ROUTES.scheduledEmailReports}
                          element={
                            isViewOnly ? (
                              <Navigate to={ROUTES.projects} />
                            ) : (
                              <Pages.ScheduledEmailReports />
                            )
                          }
                        />
                        <Route
                          path={ROUTES.sharedLinks}
                          element={
                            isViewOnly ? (
                              <Navigate to={ROUTES.projects} />
                            ) : (
                              <Pages.SharedLinks />
                            )
                          }
                        />
                      </Route>
                    </Route>
                    <Route path='*' element={<Pages.NotFound />} />
                  </Route>
                </Route>
                <Route element={<LoginLayout />}>
                  <Route element={<PublicRoutes />}>
                    <Route element={<PublicLayout />}>
                      <Route path={ROUTES.signup} element={<Pages.SingUp />} />
                      <Route
                        path={ROUTES.verifyEmail}
                        element={<Pages.VerifyEmail />}
                      />
                      <Route path={ROUTES.login} element={<Pages.Login />} />
                      <Route
                        path={ROUTES.forgotPassword}
                        element={<Pages.ForgotPassword />}
                      />
                      <Route
                        path={ROUTES.forgotPasswordContactSupport}
                        element={<Pages.ForgetPasswordStep3 />}
                      />
                      <Route
                        path={ROUTES.dynamic.forgotPassword()}
                        element={<Pages.ForgetPasswordStep2 />}
                      />
                      <Route
                        path={ROUTES.dynamic.resetPassword()}
                        element={<Pages.ResetPassword />}
                      />
                      <Route
                        path={ROUTES.dynamic.authGoogle()}
                        element={<Pages.AuthGoogle />}
                      />
                    </Route>
                  </Route>
                </Route>
                <Route element={<SharedLinksRoutes />}>
                  <Route element={<SharedLinksLayout />}>
                    <Route
                      path={ROUTES.dynamic.sharedLinkLogin()}
                      element={<Pages.SharedLinkLogin />}
                    />
                    <Route
                      path={ROUTES.dynamic.sharedLinkContents()}
                      element={<Pages.SharedLinkContents />}
                    />
                    <Route
                      path={ROUTES.dynamic.sharedProject()}
                      element={<Pages.SharedProject />}
                    />
                    <Route
                      path={ROUTES.dynamic.sharedKeywordRankHistory()}
                      element={<Pages.SharedKeywordRankHistoryPage />}
                    />
                    <Route
                      path={ROUTES.sharedLinkUnavailable}
                      element={<Pages.SharedLinkUnavailable />}
                    />
                  </Route>
                </Route>
                <Route element={<SharedLinksRoutes />}>
                  <Route element={<PublicLayout />}>
                    <Route
                      path={ROUTES.dynamic.changeEmail()}
                      element={<Pages.ChangeEmailSuccessful />}
                    />
                    <Route
                      path={ROUTES.dynamic.confirmEmail()}
                      element={<Pages.VerifyEmailSuccessful />}
                    />
                  </Route>
                </Route>
                <Route element={<TestPayRoutes />}>
                  <Route path={ROUTES.testPay} element={<TestPayPage />} />
                </Route>
              </Route>
            </Route>
          </Routes>
        </Errors.Boundary>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppRoutes;
