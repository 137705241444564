import React, { useMemo } from 'react';
import './rechart.css';
import { ChartContainer } from './styled';
import GraphNoData from '../../../../../../../../shared/components/graphNoData/GraphNoData';
import { ImprovedVsDeclinedSkeleton } from '../../../../../../../../shared/graphSkeletons';
import KeywordTrendsBarChart from './KeywordTrendsBarChart';
import { useAppSelector } from '../../../../../../../../store';

const Graph = () => {
  const isLoadingProjectKeywordTrends = useAppSelector(
    state => state.sharedLinks.projectGraphs.isLoadingProjectKeywordTrends
  );
  const projectKeywordTrends = useAppSelector(
    state => state.sharedLinks.projectGraphs.projectKeywordTrends
  );

  const getCheckedData = useMemo(() => {
    return projectKeywordTrends?.items?.length
      ? projectKeywordTrends.items
      : [];
  }, [projectKeywordTrends?.items]);

  return (
    <ChartContainer>
      {!projectKeywordTrends || isLoadingProjectKeywordTrends ? (
        <ImprovedVsDeclinedSkeleton />
      ) : getCheckedData.length ? (
        <KeywordTrendsBarChart getCheckedData={getCheckedData} />
      ) : (
        <GraphNoData />
      )}
    </ChartContainer>
  );
};

export default Graph;
