import React from 'react';

import {
  StyledITooltipContainer,
  StyledITooltipContainerHead,
  StyledStatus,
  StyledStatusLabel,
  StyledStatusValue,
} from './styles';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import { ProjectOverview } from '../../../../../projects/types';
import { useAppSelector } from '../../../../../../store';

const TooltipContainer: React.FC<{ overview: ProjectOverview | null }> = ({
  overview,
}) => {
  const projectSharedRangeDate = useAppSelector(
    state => state.sharedLinks.projectSharedRangeDate
  );

  if (!overview) {
    return null;
  }
  return (
    <StyledITooltipContainer item>
      <StyledITooltipContainerHead item>
        {`${dayjs(projectSharedRangeDate.startDate).format(
          'MMMM D, YYYY'
        )} vs ${dayjs(projectSharedRangeDate.endDate).format('MMMM D, YYYY')}`}
      </StyledITooltipContainerHead>

      <Grid container item xs={12}>
        <Grid container item xs={8} alignItems={'center'}>
          <StyledStatus item $backgroundColor={'#81D32F'} />
          <StyledStatusLabel item>Improved:</StyledStatusLabel>
        </Grid>
        <Grid xs={4} container item justifyContent={'flex-end'}>
          <StyledStatusValue item>{overview.improved}</StyledStatusValue>
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Grid container item xs={8} alignItems={'center'}>
          <StyledStatus item $backgroundColor={'#FF9F9F'} />
          <StyledStatusLabel item>Declined:</StyledStatusLabel>
        </Grid>
        <Grid xs={4} container item justifyContent={'flex-end'}>
          <StyledStatusValue item>{overview.declined}</StyledStatusValue>
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Grid container item xs={8} alignItems={'center'}>
          <StyledStatus item $backgroundColor={'#D86868'} />
          <StyledStatusLabel item>Lost:</StyledStatusLabel>
        </Grid>
        <Grid xs={4} container item justifyContent={'flex-end'}>
          <StyledStatusValue item>{overview.lost}</StyledStatusValue>
        </Grid>
      </Grid>
    </StyledITooltipContainer>
  );
};

export default TooltipContainer;
