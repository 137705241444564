import React from 'react';

const VisaIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='58'
    height='40'
    viewBox='0 0 58 40'
    fill='none'
  >
    <rect x='0.5' y='0.5' width='57' height='39' rx='5.5' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.9171 26.4304H14.4842L11.9099 16.3206C11.7877 15.8556 11.5283 15.4444 11.1467 15.2507C10.1943 14.7637 9.14486 14.3762 8 14.1807V13.7915H13.5301C14.2934 13.7915 14.8658 14.3762 14.9612 15.0552L16.2969 22.3477L19.7281 13.7915H23.0656L17.9171 26.4304ZM24.9737 26.4304H21.7316L24.4013 13.7915H27.6434L24.9737 26.4304ZM31.8378 17.2929C31.9332 16.6121 32.5057 16.2229 33.1735 16.2229C34.223 16.1252 35.3661 16.3206 36.3202 16.8059L36.8926 14.0847C35.9386 13.6955 34.8891 13.5 33.9367 13.5C30.7901 13.5 28.5003 15.2507 28.5003 17.6804C28.5003 19.5288 30.1222 20.4993 31.2671 21.084C32.5057 21.667 32.9827 22.0563 32.8873 22.6393C32.8873 23.5137 31.9332 23.903 30.9809 23.903C29.836 23.903 28.6911 23.6115 27.6434 23.1245L27.0709 25.8474C28.2158 26.3327 29.4544 26.5281 30.5992 26.5281C34.1276 26.6242 36.3202 24.8752 36.3202 22.25C36.3202 18.9441 31.8378 18.7504 31.8378 17.2929ZM47.6667 26.4304L45.0924 13.7915H42.3273C41.7549 13.7915 41.1825 14.1807 40.9917 14.7637L36.2248 26.4304H39.5623L40.2284 24.5837H44.3292L44.7108 26.4304H47.6667ZM42.8044 17.1951L43.7567 21.9585H41.0871L42.8044 17.1951Z'
      fill='#172B85'
    />
    <rect x='0.5' y='0.5' width='57' height='39' rx='5.5' stroke='#F2F4F7' />
  </svg>
);

export default VisaIcon;
