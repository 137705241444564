import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useState } from 'react';
import EllipsisMenu from '../../notes/components/NotesPage/components/NotesTable/components/EllipsisMenu/EllipsisMenu';
import { useNotes } from '../../../hooks';
import {
  CustomTooltip,
  DateCustomTooltip,
} from '../../../shared/tooltip/styles';
import TextTooltip from '../../../shared/components/TextTooltip';
import * as Tooltips from '../components/Tooltips';
import InfoIcon from '../../../shared/images/InfoIcon';
import { findLongestString, getColumnWidth } from '../../../utils/table';
import { customSortComparator } from '../../emailReports/hooks/useScheduledEmailReportsTable';
import { useAppSelector } from '../../../store';
import { Skeleton } from '@mui/material';
import { TableHeaderStyles } from '../../../shared/stylesTable';
import { convertToTimezone } from '../../../utils/date';
import { SERVER_UTS } from '../../../constants/config';

export default function useAllNotesTable() {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const user = useAppSelector(state => state.auth.user);

  const { allNotesData } = useNotes();

  const [showDateFullFormat, setShowDateFullFormat] = useState<boolean>(false);

  const popperProps = {
    sx: {
      '& .MuiTooltip-tooltip': {
        backgroundColor: '#fff',
        color: '#344054',
        boxShadow:
          '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
        border: '1px solid #EDEDED',
      },
      '.MuiTooltip-arrow::before': {
        backgroundColor: '#fff',
        border: '1px solid #EDEDED',
      },
    },
  };

  const [authorWidth, setAuthorWidth] = useState<number>(100);
  const [textWidth, setTextWidth] = useState<number>(100);

  useEffect(() => {
    if (allNotesData?.items) {
      setAuthorWidth(
        getColumnWidth(
          findLongestString(allNotesData?.items?.map(item => item.author)) || ''
        )
      );
      setTextWidth(
        getColumnWidth(
          findLongestString(allNotesData?.items?.map(item => item.text)) || ''
        )
      );
    }
  }, [allNotesData?.items]);

  const tableWidth = useMemo(
    () => ({
      author: allNotesData?.items
        ? getColumnWidth(
            findLongestString(allNotesData?.items?.map(item => item.author)) ||
              ''
          )
        : authorWidth,
      text: allNotesData?.items
        ? getColumnWidth(
            findLongestString(allNotesData?.items?.map(item => item.text)) || ''
          )
        : textWidth,
    }),
    [allNotesData?.items, authorWidth, textWidth]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'date',
        sortable: true,
        headerName: 'Date',
        maxWidth: 150,
        minWidth: 150,
        sortComparator: customSortComparator,
        renderCell: (params: GridRenderCellParams) => {
          const date = showDateFullFormat
            ? convertToTimezone(
                params.row.dateFullFormat,
                SERVER_UTS,
                user.timezone.utc
              )
            : params.row.date;
          return (
            <DateCustomTooltip
              title={<TextTooltip text={date} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
              onClick={e => {
                e.stopPropagation();
                setShowDateFullFormat(!showDateFullFormat);
              }}
            >
              {date}
            </DateCustomTooltip>
          );
        },
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Date />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Date '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
      },
      {
        field: 'author',
        sortable: true,
        headerName: 'Author',
        maxWidth: 300,
        minWidth: 100,
        width: tableWidth.author,
        sortComparator: customSortComparator,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.author} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params.row.author}
            </CustomTooltip>
          );
        },
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Author />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Author '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
      },
      {
        field: 'text',
        sortable: true,
        headerName: 'Note',
        maxWidth: 300,
        minWidth: 100,
        width: tableWidth.text,
        sortComparator: customSortComparator,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.text} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params.row.text}
            </CustomTooltip>
          );
        },
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Note />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Note '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
      },
      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 50,
        align: 'center',
        renderCell: (params: GridRenderCellParams) =>
          params.row.editOption && (
            <div>
              <EllipsisMenu
                noteId={params.row.id}
                projectId={params.row.projectId}
              />
            </div>
          ),
      },
    ],
    [currentAccount?.role?.name, tableWidth, showDateFullFormat, user?.timezone]
  );

  const columnsSkeleton: GridColDef[] = useMemo(
    () => [
      {
        field: 'date',
        sortable: true,
        headerName: 'Date',
        maxWidth: 150,
        minWidth: 150,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'author',
        sortable: true,
        headerName: 'Author',
        maxWidth: 300,
        minWidth: 100,
        width: tableWidth.author,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'text',
        sortable: true,
        headerName: 'Note',
        maxWidth: 300,
        minWidth: 100,
        width: tableWidth.text,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 50,
        align: 'center',
        renderCell: () => <Skeleton width='100%' />,
      },
    ],
    [tableWidth]
  );

  return {
    columns,
    columnsSkeleton,
  };
}
