import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StyledDescriptions = styled(Grid)`
  color: #667085;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export { StyledDescriptions };
