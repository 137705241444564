import React from 'react';
import {
  StylesBanner,
  StylesUpgradeNowButton,
} from '../YourTrialHasEndedBanner/styles';
import { Grid } from '@mui/material';
import LightningIcon from '../../images/LightningIcon';
import ClockStopWatchIcon from '../../images/ClockStopWatchIcon';
import { useNavigate } from 'react-router';
import ROUTES from '../../../routes/constants';

const YourSubscriptionHasEndedBanner = () => {
  const navigate = useNavigate();

  const onUpgradePlan = () => {
    navigate(ROUTES.upgradePlan);
  };

  return (
    <StylesBanner container justifyContent={'space-between'} xs={12}>
      <Grid
        display={'flex'}
        alignItems={'center'}
        xs={12}
        justifyContent={'center'}
        gap={'16px'}
      >
        <Grid display={'flex'} alignItems={'center'} gap={'8px'}>
          <ClockStopWatchIcon />
          <Grid>{`Your subscription has ended. Renew to regain full access.`}</Grid>
        </Grid>
        <StylesUpgradeNowButton
          $padding='8px 14px'
          size='small'
          outline
          startIcon={<LightningIcon stroke={'#101828'} />}
          onClick={onUpgradePlan}
        >
          Upgrade Plan
        </StylesUpgradeNowButton>
      </Grid>
    </StylesBanner>
  );
};

export default YourSubscriptionHasEndedBanner;
