import React from 'react';
import { Fade, Grid, Modal } from '@mui/material';
import {
  ModalContainer,
  ModalWrapper,
} from '../../../features/projects/components/Projects/components/ProjectRedactorModal/styles';
import {
  CloseDeletePopoverOne,
  HeaderContainer,
} from '../../../features/auth/components/Settings/components/Account/styles';
import CloseIcon from '@mui/icons-material/Close';
import AlertCircleIcon from '../../images/AlertCircleIcon';
import {
  StyledLabel,
  StyledMainText,
} from '../KeywordsLimitExceededModal/styles';
import { ButtonsWrapper } from '../../../features/auth/components/Settings/components/Team/components/customTableStyles';
import { PrimaryButtonSmall } from '../../buttons/styles';
import { useAppSelector } from '../../../store';
import useAccountLimits from '../../../features/auth/hooks/useAccountLimits';

const NoticeAccountDeletionCancelledModal = () => {
  const noticeAccountDeletionCancelled = useAppSelector(
    state => state.auth.accountDeleteErrorModals.noticeAccountDeletionCancelled
  );

  const { onSetNoticeAccountDeletionCancelledModal } = useAccountLimits();

  const onClose = () => {
    onSetNoticeAccountDeletionCancelledModal(false);
  };

  return (
    <Modal
      open={noticeAccountDeletionCancelled.open}
      onClose={onClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={noticeAccountDeletionCancelled.open}>
        <ModalWrapper>
          <ModalContainer>
            <HeaderContainer>
              <CloseDeletePopoverOne color='error' onClick={onClose}>
                <CloseIcon fontSize='small' />
              </CloseDeletePopoverOne>
              <AlertCircleIcon stroke={'#126FE9'} />
              <StyledLabel padding='24px 0'>
                Notice: Account Deletion Cancelled
              </StyledLabel>
            </HeaderContainer>

            <Grid item xs={12}>
              <StyledMainText>
                Your request to delete your account has been automatically
                cancelled because you have reactivated your subscription.
              </StyledMainText>
            </Grid>
            <br />
            <Grid item xs={12}>
              <StyledMainText>
                If this was not your intention or if you wish to delete your
                account, please cancel your subscription first. Visit the
                Billing page for more details or contact support if you need
                help.
              </StyledMainText>
            </Grid>

            <ButtonsWrapper container xs={12}>
              <Grid item>
                <PrimaryButtonSmall
                  variant='contained'
                  size='small'
                  type='submit'
                  onClick={onClose}
                >
                  Got it
                </PrimaryButtonSmall>
              </Grid>
            </ButtonsWrapper>
          </ModalContainer>
        </ModalWrapper>
      </Fade>
    </Modal>
  );
};

export default NoticeAccountDeletionCancelledModal;
