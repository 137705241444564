import React, { useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  IDeviceType,
  Domains,
  GoogleDomain,
  Language,
  NewProjectModalProps,
  SearchLocation,
  CheckFrequency,
  IBusinessCompetitors,
} from '../../../../types';
import useProjects from '../../../../hooks/useProjects';
import { enqueueSnackbar } from 'notistack';
import { useAuth } from '../../../../../../hooks';
import { closeSnackbarAction } from '../../../../../../utils/snackbar';

import { CloseDeletePopoverOne } from '../../../../../auth/components/Settings/components/Account/styles';

import CustomToast from '../../../../../../shared/CustomToast/CustomToast';
import ProjectTagsModalContainer from './ProjectTagsModalContainer';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from '../../../../../../store';
import useAccountLimits from '../../../../../auth/hooks/useAccountLimits';
import ROUTES from '../../../../../../routes/constants';
import { useNavigate } from 'react-router';
import TagsPreProjectExceededModal from '../../../../../../shared/components/TagsPreProjectExceededModal/TagsPreProjectExceededModal';
import { convertRequestEditDataByProjectType } from '../../../../editProjectUtils';
import { ProjectNameContainer } from './styles';
import { CustomTooltip } from '../../../../../../shared/tooltip/styles';

const EditProjectTagsModal: React.FC<NewProjectModalProps> = ({
  open,
  handleClose,
}) => {
  const navigate = useNavigate();

  const { onGetMyAccount } = useAuth();

  const { onEditProject, onGetAccountTags, onGetProjects } = useProjects();

  const {
    onCloseTagsPerProjectLimitExceededModal,
    onCheckTagsPerProjectLimit,
  } = useAccountLimits();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const columnVisibilityModel = useAppSelector(
    state => state.projects.tables.projects.columnVisibilityModel
  );
  const frequencyFilter = useAppSelector(
    state => state.projects.tables.projects.frequencyFilter
  );
  const sortModel = useAppSelector(
    state => state.projects.tables.projects.sortModel
  );
  const search = useAppSelector(state => state.projects.tables.projects.search);
  const paginationModel = useAppSelector(
    state => state.projects.tables.projects.paginationModel
  );
  const selectedTags = useAppSelector(state => state.projects.selectedTags);
  const currentProject = useAppSelector(state => state.projects.currentProject);
  const tagsPreProjectLimitExceededOpen = useAppSelector(
    state => state.auth.accountLimitErrors.tagsPerProjectLimitExceeded.open
  );

  const onClose = () => {
    handleClose();
    formik.resetForm();
  };

  const [isOpenAdvancedSettings, setIsOpenAdvancedSettings] = useState(false);
  const onToggleAdvancedSettings = () =>
    setIsOpenAdvancedSettings(!isOpenAdvancedSettings);

  const formik = useFormik({
    initialValues: {
      projectName: currentProject?.projectName || '',
      keywords: [''],
      checkFrequency: currentProject?.checkFrequency || ({} as CheckFrequency),
      competitorIds: currentProject?.competitors || [],
      competitors: [] as string[],
      tagIds: currentProject?.tags || [],
      tags: [] as string[],
      note: '',
      message: '',
      url: '',
      projectUrlType: {} as Domains,
      searchEngine: currentProject?.searchEngine?.name || '',
      regionId: {} as GoogleDomain,
      location: null as SearchLocation | null,
      deviceType: {} as IDeviceType,
      languageId: {} as Language,
      folder: null as { id: number; name: string } | null,
      businessName: '',
      businessUrl: '',
      businessCompetitors: [] as IBusinessCompetitors[],
      competitorsVideoUrl: [] as string[],
      videoUrl: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      projectName: Yup.string()
        .required('Project Name is required.')
        .trim()
        .matches(/\S/, 'Project Name should not contain only spaces.'),
      checkFrequency: Yup.object().shape({
        name: isOpenAdvancedSettings
          ? Yup.string().required('Check Frequency is required.')
          : Yup.string().notRequired(),
      }),
      note: Yup.string()
        .max(2500, 'Note must be at most 2,500 characters')
        .matches(/\S/, 'Note should not contain only spaces.'),
    }),
    onSubmit: (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
      const tagsPerProjectError = onCheckTagsPerProjectLimit(
        values.tags.filter(item => /\S/.test(item)).length +
          values.tagIds.length
      );
      if (currentProject && !tagsPerProjectError) {
        const convertRequestEditData = convertRequestEditDataByProjectType(
          values.searchEngine,
          values
        );
        if (convertRequestEditData) {
          onEditProject({
            accountId: currentAccount.accountId,
            projectId: currentProject.id,
            data: convertRequestEditData,
            successFn: () => {
              resetForm();
              handleClose();
              onGetProjects({
                accountId: currentAccount.accountId,
                params: {
                  frequencyName: frequencyFilter,
                  page: paginationModel.page,
                  limit: paginationModel.pageSize,
                  tagIds: selectedTags.map(item => item.id).join(),
                  search: search,
                  sortBy: sortModel[0].field,
                  sortOrder: sortModel[0].sort,
                  dailyAverage: columnVisibilityModel.dailyAverage,
                },
              });
              onGetAccountTags({ accountId: currentAccount.accountId });
              onGetMyAccount(currentAccount.accountId);
              enqueueSnackbar(
                <CustomToast
                  message='Success'
                  submessage='Project tags have been successfully updated.'
                />,
                { action: closeSnackbarAction }
              );
            },
            errorFn: (error: any) => {
              setStatus({ success: false });
              setSubmitting(false);
              setErrors(error);
            },
          });
        }
      }
    },
  });

  const projectDescription = useMemo(
    () =>
      currentProject?.id
        ? ` ${currentProject.projectName}${
            currentProject?.url ? ` (${currentProject?.url})` : ''
          }`
        : '',
    [currentProject?.id, currentProject?.projectName, currentProject?.url]
  );

  const handleCloseTagsPerProjectLimitExceededModal = () => {
    onCloseTagsPerProjectLimitExceededModal();
    formik.resetForm();
  };

  const handleTagsPerProjectUpgradeAccount = () => {
    onCloseTagsPerProjectLimitExceededModal();
    navigate(ROUTES.upgradePlan);
  };

  if (!currentProject || !formik) {
    return null;
  }

  return (
    <>
      <ProjectTagsModalContainer
        formik={formik}
        open={open}
        handleClose={handleClose}
        isOpenAdvancedSettings={isOpenAdvancedSettings}
        onToggleAdvancedSettings={onToggleAdvancedSettings}
        isNew={false}
      >
        <Grid xs={12}>
          <Grid item xs={12} sx={{ position: 'relative' }}>
            <CloseDeletePopoverOne onClick={onClose}>
              <CloseIcon fontSize='small' />
            </CloseDeletePopoverOne>
            <Typography
              fontSize='18px'
              fontWeight='600'
              lineHeight='28px'
              color='#101828'
            >
              Edit Tags
            </Typography>
            <CustomTooltip
              title={projectDescription}
              placement='top-start'
              arrow
            >
              <ProjectNameContainer
                fontSize='14px'
                lineHeight='20px'
                color='#475467'
              >
                Project:
                {projectDescription}
              </ProjectNameContainer>
            </CustomTooltip>
          </Grid>
        </Grid>
      </ProjectTagsModalContainer>
      <TagsPreProjectExceededModal
        onClose={handleCloseTagsPerProjectLimitExceededModal}
        onUpgradeAccount={handleTagsPerProjectUpgradeAccount}
        open={tagsPreProjectLimitExceededOpen}
      />
    </>
  );
};

export default EditProjectTagsModal;
