import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { Header, ScheduledEmailReportsTable } from './components';
import { useEmailReports } from '../../../../hooks';
import { DEFAULT_GET_USERS_PARAMS } from '../../../auth/components/Settings/components/Team/constants';
import { useAppSelector } from '../../../../store';

/**
 * Component to display scheduled email reports.
 * @component
 * @returns {JSX.Element} The rendered ScheduledEmailReports component.
 */
const ScheduledEmailReports = () => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const {
    onSetRowSelectionModelEmailReports,
    onGetEmailReports,
    tables: {
      scheduledEmailReports: { paginationModel, search, sortModel },
    },
  } = useEmailReports();

  useEffect(() => {
    if (currentAccount?.accountId) {
      onGetEmailReports({
        id: currentAccount.accountId,
        page: paginationModel.page,
        limit: paginationModel.pageSize,
        search: search || DEFAULT_GET_USERS_PARAMS.search,
        sortBy: sortModel[0]?.field,
        sortOrder: sortModel[0]?.sort,
      });
    }
  }, [
    currentAccount.accountId,
    onGetEmailReports,
    paginationModel.page,
    paginationModel.pageSize,
    search,
    sortModel,
  ]);

  useEffect(() => {
    return () => onSetRowSelectionModelEmailReports([]);
  }, [onSetRowSelectionModelEmailReports]);

  return (
    <Grid xs={12}>
      <Header />
      <ScheduledEmailReportsTable />
    </Grid>
  );
};

export default ScheduledEmailReports;
