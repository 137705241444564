import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React, { useMemo } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import useFolders from '../../../../../../../../hooks/useFolders';
import { renderListItemTitle, renderRootListItemTitle } from './utils';
import SettingsIcon from '../../../../../../../../../../shared/images/SettingsIcon';
import DownIcon from '../../../../../../../../../../shared/images/DownIcon';
import CustomCheckboxImage from '../../../../../../../../../../shared/images/CustomCheckbox';
import CheckedIcon from '../../../../../../../../../../shared/images/CheckedIcon';
import { CustomCheckbox } from '../../../../../../../../../../shared/checkbox/styles';
import { Popover } from '../../../../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import { GrayButtonCustom } from '../../../../../../../../../../shared/buttons/styles';
import {
  ListItemTypography,
  MainContainer,
  PopupLabel,
  ResetDefaultButton,
  StyledListItem,
} from './styles';

const PopupTableSettings = () => {
  const {
    onSetRootColumnVisibilityModel,
    onSetColumnVisibilityModel,
    isRootView,
    onResetColumnVisibilityModel,
    tables: {
      rootFolder: {
        rootFolderColumnVisibilityModel,
        childrenFolderColumnVisibilityModel,
      },
    },
  } = useFolders();

  const handleResetColumnVisibilityModel = () => {
    onResetColumnVisibilityModel();
  };

  const columnVisibilityModel = useMemo(
    () =>
      isRootView
        ? rootFolderColumnVisibilityModel
        : childrenFolderColumnVisibilityModel,
    [
      childrenFolderColumnVisibilityModel,
      isRootView,
      rootFolderColumnVisibilityModel,
    ]
  );

  return (
    <>
      <PopupState variant='popover'>
        {popupState => (
          <>
            <GrayButtonCustom
              $padding='7px 12px'
              size='small'
              outline
              startIcon={<SettingsIcon stroke='#344054' />}
              endIcon={<DownIcon stroke='#344054' />}
              {...bindTrigger(popupState)}
            >
              Table Settings
            </GrayButtonCustom>

            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MainContainer>
                <PopupLabel>Show or Hide Columns</PopupLabel>
                <ResetDefaultButton onClick={handleResetColumnVisibilityModel}>
                  Reset to default
                </ResetDefaultButton>
                <List>
                  {Object.keys(columnVisibilityModel).map(item => {
                    const columnKey = item as any;
                    return (
                      <StyledListItem
                        key={item}
                        disablePadding
                        checkBackgroundColor={
                          columnVisibilityModel[columnKey]
                            ? '#EEF3FF'
                            : undefined
                        }
                      >
                        <ListItemButton
                          onClick={() => {
                            isRootView
                              ? onSetRootColumnVisibilityModel(
                                  columnKey,
                                  !columnVisibilityModel[columnKey]
                                )
                              : onSetColumnVisibilityModel(
                                  columnKey,
                                  !columnVisibilityModel[columnKey]
                                );
                          }}
                        >
                          <CustomCheckbox
                            checked={columnVisibilityModel[columnKey]}
                            icon={<CustomCheckboxImage />}
                            checkedIcon={<CheckedIcon />}
                            disableFocusRipple
                          />

                          <ListItemTypography>
                            {isRootView
                              ? renderRootListItemTitle(columnKey)
                              : renderListItemTitle(columnKey)}
                          </ListItemTypography>
                        </ListItemButton>
                      </StyledListItem>
                    );
                  })}
                </List>
              </MainContainer>
            </Popover>
          </>
        )}
      </PopupState>
    </>
  );
};

export default PopupTableSettings;
