import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StylesCurrentLocation = styled(Grid)`
  color: #344054;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const StylesFoldersList = styled(Grid)`
  margin: 0 12px;
  overflow: scroll;
  max-height: 310px;
`;

const StylesFoldersListItem = styled(Grid)`
  padding: 8px 12px;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #4d4d4d;
`;

const StylesSelectIcon = styled(Grid)`
  transform: rotate(-90deg);
  cursor: pointer;
`;

const StylesNoFolders = styled(Grid)`
  color: #4d4d4d;
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  line-height: 24px;
`;

export {
  StylesCurrentLocation,
  StylesFoldersList,
  StylesFoldersListItem,
  StylesSelectIcon,
  StylesNoFolders,
};
