import React from 'react';

import { FormHelperText, Grid, Typography } from '@mui/material';
import { HelperTextErrorWrapper } from './styles';
import GreenCheckMarkIcon from '../../../../../shared/images/GreenCheckMarkIcon';

interface CustomHelperTextProps {
  text: string;
}

/**
 * Component representing custom helper text with a green checkmark icon.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.text - The text to display in the helper text.
 * @returns {JSX.Element} The CustomHelperText component.
 */

const CustomHelperText = ({ text }: CustomHelperTextProps) => {
  return (
    <HelperTextErrorWrapper>
      <Grid sx={{ width: '14px', height: '14px', marginTop: '2px' }}>
        <GreenCheckMarkIcon />
      </Grid>

      <Typography
        paddingLeft={'5px'}
        variant='body2'
        sx={{ margin: '0px', fontSize: '122px' }}
      >
        <FormHelperText sx={{ color: '#17B26A' }}>{text}</FormHelperText>
      </Typography>
    </HelperTextErrorWrapper>
  );
};

export default CustomHelperText;

/**
 * Props for the CustomHelperText component.
 *
 * @typedef {Object} CustomHelperTextProps
 * @property {string} text - The text to display in the helper text.
 */
