import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StyledPaymentMethodItemCard = styled(Grid)`
  border-radius: 8px;
  border: 1px solid #eaecf0;
  background: #fff;
  padding: 16px;
  margin: 20px 0;
`;

const StyledVisaEnding = styled(Grid)`
  color: #344054;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const StyledExpiry = styled(Grid)`
  color: #475467;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-bottom: 8px;
`;

const StyledEmail = styled(Grid)`
  color: #475467;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const StyledCancelSubscriptionButton = styled(Grid)`
  color: #c90000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  cursor: pointer;
`;

export {
  StyledPaymentMethodItemCard,
  StyledVisaEnding,
  StyledExpiry,
  StyledEmail,
  StyledCancelSubscriptionButton,
};
