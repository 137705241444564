import { styled } from '@mui/material/styles';
import {
  PaginationContainer as ProjectPaginationContainer,
  StyledTablePagination as ProjectStyledTablePagination
} from "../../../../../../../projects/components/Projects/components/TableProjects/components/Pagination/styles";

const StyledTablePagination = styled(ProjectStyledTablePagination)``;

const PaginationContainer = styled(ProjectPaginationContainer)``;

export { StyledTablePagination, PaginationContainer };
