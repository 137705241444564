import React from 'react';
import { useNotes } from '../../../../hooks';
import { NoNotes, ProjectExpandedNotesTable } from './components';
import { useAppSelector } from '../../../../store';

const ProjectExpandedNotes = () => {
  const {
    isLoading,
    projectNotesData,
    tables: {
      projectNotes: { search },
    },
  } = useNotes();
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const isViewOnly =
    !currentAccount?.accountId || currentAccount?.role?.name === 'ViewOnly';

  if (
    !isLoading &&
    !search &&
    !projectNotesData?.items?.length &&
    !isViewOnly
  ) {
    return <NoNotes />;
  }

  return <ProjectExpandedNotesTable />;
};

export default ProjectExpandedNotes;
