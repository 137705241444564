import { Fade, Grid, Modal, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { SetAsDefaultModalProps } from './types';

import CloseIcon from '@mui/icons-material/Close';
import { CloseDeletePopoverOne } from '../../components/Settings/components/Account/styles';
import { GrayButton, PrimaryButton } from '../../../../shared/buttons/styles';
import { ButtonsWrapper } from '../../components/Settings/components/Team/components/customTableStyles';
import { HeaderContainer, ModalContainer, ModalWrapper } from './styles';
import { ModalText } from '../RemoveAccessModal/styles';

/**
 * Modal component for setting an account as the default account.
 * @param {SetAsDefaultModalProps} props - Props for SetAsDefaultModal.
 * @returns {React.ReactElement} The rendered modal component.
 */
const SetAsDefaultModal: React.FC<SetAsDefaultModalProps> = ({
  openSetAsDefaultModal,
  onCloseSetAsDefaultModal,
  onSetAsDefault,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const handleClose = () => {
    onCloseSetAsDefaultModal();
  };

  const handleSetAsDefault = () => {
    try {
      onSetAsDefault();
      onCloseSetAsDefaultModal();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      aria-labelledby='EditNoteModal'
      aria-describedby='EditNoteModal'
      open={openSetAsDefaultModal}
      onClose={handleClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={openSetAsDefaultModal}>
        <ModalWrapper>
          <ModalContainer container xs={12}>
            <Grid item xs={12} sx={{ position: 'relative' }}>
              <CloseDeletePopoverOne onClick={handleClose} sx={{ zIndex: 1 }}>
                <CloseIcon fontSize='small' />
              </CloseDeletePopoverOne>
              <HeaderContainer>
                <Typography
                  fontSize='18px'
                  fontWeight='600'
                  lineHeight='28px'
                  color='#101828'
                >
                  Set as Default Account
                </Typography>
              </HeaderContainer>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                gap: '4px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <ModalText>
                <p>
                  By setting this account as your default, you will be
                  automatically directed to it each time you log in.
                </p>
                <p>
                  Please note, you can change your default account at any time
                  by selecting the desired account from the &apos;Switch
                  Access&apos; menu and then setting it as default from the
                  &apos;Account&apos; menu.
                </p>
                <p>
                  Would you like to proceed and set this as your default
                  account?
                </p>
              </ModalText>
            </Grid>
            <ButtonsWrapper
              container
              xs={12}
              sx={{
                paddingTop: '32px',
              }}
            >
              <Grid item xs={extraSmallScreen && 12}>
                <GrayButton
                  sx={{ padding: '8px 18px !important', maxHeight: '40px' }}
                  fullWidth={extraSmallScreen}
                  outline
                  onClick={handleClose}
                >
                  Cancel
                </GrayButton>
              </Grid>
              <Grid item xs={extraSmallScreen && 12}>
                <PrimaryButton
                  sx={{ padding: '8px 16px !important' }}
                  fullWidth={extraSmallScreen}
                  variant='contained'
                  onClick={handleSetAsDefault}
                >
                  Yes, Set as Default
                </PrimaryButton>
              </Grid>
            </ButtonsWrapper>
          </ModalContainer>
        </ModalWrapper>
      </Fade>
    </Modal>
  );
};

export default SetAsDefaultModal;
