import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

/**
 * A component that displays the content of a specific tab based on the current value.
 *
 * @component
 * @param {TabPanelProps} props - The props for the TabPanel component.
 * @returns {JSX.Element} The TabPanel component.
 */
const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;

  return <>{value === index && children}</>;
};

export default TabPanel;
