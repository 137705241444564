import React from 'react';

import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { DeleteActionProps } from './types';
import {
  ListItemTypography,
  StyledListItem,
} from '../../../../../projects/components/ProjectExpanded/components/KeywordRankings/components/KeywordRankingsTable/components/EllipsisMenu/styles';
import SmallTrashIcon from '../../../../../../shared/images/SmallTrashIcom';
import CustomToast from '../../../../../../shared/CustomToast/CustomToast';
import { closeAndUndoSnackbarAction } from '../../../../../../utils/snackbar';
import { useAuth, useEmailReports } from '../../../../../../hooks';
import { DEFAULT_GET_USERS_PARAMS } from '../../../../../auth/components/Settings/components/Team/constants';
import { StyledListItemButton } from '../../../../../../shared/buttons/styles';
import { useAppSelector } from '../../../../../../store';

const DeleteAction: React.FC<DeleteActionProps> = ({ id, popupClose }) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const {
    onSetDeleteElementsScheduledEmailReportsTable,
    onBulkDeleteEmailReports,
    onGetEmailReports,
    tables: {
      scheduledEmailReports: { paginationModel, search, sortModel },
    },
  } = useEmailReports();

  const handleHardDelete = () => {
    if (currentAccount?.accountId) {
      onBulkDeleteEmailReports({
        accountId: currentAccount.accountId,
        emailReportIds: [id],
        successFn: () => {
          if (currentAccount?.accountId) {
            onGetEmailReports({
              id: currentAccount.accountId,
              page: paginationModel.page,
              limit: paginationModel.pageSize,
              search: search || DEFAULT_GET_USERS_PARAMS.search,
              sortBy: sortModel[0]?.field,
              sortOrder: sortModel[0]?.sort,
            });
            onSetDeleteElementsScheduledEmailReportsTable([]);
          }
        },
      });
    }
  };

  const handleDeleteOneKeyword = () => {
    const localDeleteElements = id ? [id] : [id];
    onSetDeleteElementsScheduledEmailReportsTable(localDeleteElements);

    popupClose();
    let undoDelete = false;
    const onUndo = () => {
      undoDelete = true;
      onSetDeleteElementsScheduledEmailReportsTable([]);
    };
    enqueueSnackbar(
      <CustomToast
        message='Email Report Removed'
        submessage={`Email Report has been successfully removed.`}
      />,
      {
        action: (snackbarId: SnackbarKey) =>
          closeAndUndoSnackbarAction(snackbarId, onUndo),
        variant: 'error',
      }
    );
    setTimeout(() => {
      if (!undoDelete) {
        handleHardDelete();
      }
    }, 5000);
  };

  return (
    <StyledListItem>
      <StyledListItemButton onClick={handleDeleteOneKeyword}>
        <SmallTrashIcon />
        <ListItemTypography marginLeft='8px' sx={{ color: '#B42318' }}>
          Delete Email Report
        </ListItemTypography>
      </StyledListItemButton>
    </StyledListItem>
  );
};

export default DeleteAction;
