import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

const StyledShortInfo = styled(Grid)`
  padding-bottom: 16px;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  display: flex;
  align-items: flex-start;
  align-self: stretch;
`;

const StyledShortInfoItem = styled(Grid)`
  padding: 0 8px;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const StyledColumnField = styled(Typography)`
  color: #667085;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const StyledColumnValueText = styled('div')`
  width: 100%;
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export {
  StyledShortInfo,
  StyledShortInfoItem,
  StyledColumnField,
  StyledColumnValueText,
};
