import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { useNotes, useProjects } from '../../../../hooks';
import { ContentContainer } from './components/styled';
import {
  Header,
  ShortInfo,
  Overview,
  ImprovedVsDeclined,
  KeywordTrends,
  KeywordRankings,
  ProjectPerformance,
} from './components';
import { DEFAULT_GET_USERS_PARAMS } from '../../../auth/components/Settings/components/Team/constants';
import ProjectExpandedNotes from '../../../notes/components/ProjectExpandedNotes/ProjectExpandedNotes';
import {
  getStartOfKeywordUpdateToServer,
  getUpdatedKeywordsToServer,
  getUpdateProjectGraphicsToServer,
} from '../../socket';
import dayjs from 'dayjs';
import { decryptData } from '../../../../utils/cryptoJs';
import { useAppSelector } from '../../../../store';

const ProjectExpanded = () => {
  const { id } = useParams();

  const decryptId = useMemo(() => {
    if (id) {
      return decryptData(id);
    }
  }, [id]);

  const {
    onGetKeywordRankings,
    onSetStartOfKeywordUpdate,
    onSetUpdatedKeywords,
    onCloseProjectExpandedPage,
    onGetProjectPerformance,
    onGetProjectsKeywordTrends,
    onGetProjectsImprovedVsDeclined,
    onGetProjectExtended,
    onGetProjectsOverview,
  } = useProjects();

  const {
    onGetProjectNotes,
    tables: {
      projectNotes: { sortModel, search, paginationModel },
    },
  } = useNotes();

  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );
  const isShowImprovedVsDeclined = useAppSelector(
    state => state.projects.showBlocks.isShowImprovedVsDeclined
  );
  const keywordRankings = useAppSelector(
    state => state.projects.projectExpandedGraphs.keywordRankings
  );
  const rangeDate = useAppSelector(
    state => state.projects.projectExpandedGraphs.rangeDate
  );
  const deviceType = useAppSelector(
    state => state.projects.projectExpandedGraphs.deviceType
  );
  const activePeriodImprovedVsDeclined = useAppSelector(
    state => state.projects.projectExpandedGraphs.activePeriodImprovedVsDeclined
  );
  const activeKeywordTrends = useAppSelector(
    state => state.projects.projectExpandedGraphs.activeKeywordTrends
  );
  const activePeriodProjectPerformance = useAppSelector(
    state => state.projects.projectExpandedGraphs.activePeriodProjectPerformance
  );
  const overview = useAppSelector(
    state => state.projects.projectExpandedGraphs.overview
  );
  const showKeywordTrendsModel = useAppSelector(
    state => state.projects.showKeywordTrendsModel
  );
  const improved = useAppSelector(
    state => state.projects.showImprovedVsDeclinedVisibilityModel.improved
  );
  const declined = useAppSelector(
    state => state.projects.showImprovedVsDeclinedVisibilityModel.declined
  );
  const lost = useAppSelector(
    state => state.projects.showImprovedVsDeclinedVisibilityModel.lost
  );
  const noChange = useAppSelector(
    state => state.projects.showImprovedVsDeclinedVisibilityModel.noChange
  );

  const isLoadingProjectExpanded = useAppSelector(
    state => state.projects.isLoadingProjectExpanded
  );

  const keywordSelectedTags = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table.selectedTags
  );

  const memoKeywordSelectedTags = useMemo(
    () => keywordSelectedTags.map(item => item.id),
    [keywordSelectedTags]
  );

  const [updatedKeywordsTrigger, setUpdatedKeywordsTrigger] = useState<
    number[]
  >([]);

  const onSetUpdatedKeywordsTrigger = (ids: number[]) => {
    setUpdatedKeywordsTrigger(ids);
  };

  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const overviewFromDate = useMemo(
    () => dayjs(overview?.fromDate).format('YYYY-MM-DD'),
    [overview?.fromDate]
  );

  const overviewToDate = useMemo(
    () => dayjs(overview?.toDate).format('YYYY-MM-DD'),
    [overview?.fromDate]
  );

  const rangeStartDateString = useMemo(
    () => dayjs(rangeDate.startDate).format('YYYY-MM-DD'),
    [rangeDate?.startDate]
  );
  const rangeEndDateDateString = useMemo(
    () => dayjs(rangeDate.endDate).format('YYYY-MM-DD'),
    [rangeDate?.endDate]
  );

  useEffect(() => {
    if (currentAccount?.accountId && decryptId) {
      onGetProjectExtended({
        accountId: currentAccount.accountId,
        projectId: Number(decryptId),
        notes: true,
      });
    }
  }, [currentAccount.accountId, decryptId]);

  useEffect(() => {
    if (currentAccount?.accountId && decryptId && deviceType) {
      if (
        !rangeDate.startDate ||
        !(
          rangeStartDateString === overviewFromDate &&
          rangeEndDateDateString === overviewToDate
        )
      )
        onGetProjectsOverview({
          deviceType: deviceType,
          accountId: currentAccount.accountId,
          projectId: Number(decryptId),
          fromDate: rangeDate.startDate ? rangeStartDateString : undefined,
          toDate: rangeDate.endDate ? rangeEndDateDateString : undefined,
        });
    }
  }, [
    currentAccount.accountId,
    decryptId,
    onGetProjectsOverview,
    rangeStartDateString,
    rangeEndDateDateString,
    deviceType,
  ]);

  useEffect(() => {
    if (currentAccount?.accountId && projectExpanded?.id && deviceType) {
      onGetProjectsImprovedVsDeclined({
        accountId: currentAccount.accountId,
        projectId: projectExpanded.id,
        period: activePeriodImprovedVsDeclined.value,
        improvedFilter: improved,
        declinedFilter: declined,
        lostFilter: lost,
        noChange: noChange,
        deviceType: deviceType,
      });
    }
  }, [
    deviceType,
    currentAccount.accountId,
    projectExpanded?.id,
    onGetProjectsImprovedVsDeclined,
    improved,
    declined,
    lost,
    noChange,
    activePeriodImprovedVsDeclined.value,
  ]);

  useEffect(() => {
    if (currentAccount?.accountId && projectExpanded?.id && deviceType) {
      onGetProjectsKeywordTrends({
        deviceType: deviceType,
        accountId: currentAccount.accountId,
        projectId: projectExpanded.id,
        top3Filter: showKeywordTrendsModel['top3'],
        fromFourToTen: showKeywordTrendsModel['top4-10'],
        fromElevenToTwenty: showKeywordTrendsModel['top11-20'],
        fromTwentyOneToFifty: showKeywordTrendsModel['top21-50'],
        fiftyOneToOneHundred: showKeywordTrendsModel['top51-100'],
        notRanked: showKeywordTrendsModel['notRanked'],
        period: activeKeywordTrends.value,
      });
    }
  }, [
    deviceType,
    currentAccount.accountId,
    projectExpanded?.id,
    onGetProjectsOverview,
    showKeywordTrendsModel,
    onGetProjectsKeywordTrends,
    activeKeywordTrends.value,
  ]);

  useEffect(() => {
    if (
      currentAccount?.accountId &&
      projectExpanded?.id &&
      projectExpanded?.id &&
      deviceType
    ) {
      onGetProjectPerformance({
        id: currentAccount.accountId,
        projectId: projectExpanded.id,
        period: activePeriodProjectPerformance.value,
        competitorIds: projectExpanded.competitors.map(item =>
          item.id.toString()
        ),
        deviceType: deviceType,
      });
    }
  }, [
    currentAccount.accountId,
    projectExpanded?.id,
    onGetProjectPerformance,
    projectExpanded?.competitors,
    projectExpanded?.id,
    activePeriodProjectPerformance.value,
    deviceType,
  ]);

  useEffect(() => {
    if (currentAccount?.accountId && projectExpanded?.id) {
      onGetProjectNotes({
        id: currentAccount.accountId,
        projectId: projectExpanded.id,
        page: paginationModel.page,
        limit: paginationModel.pageSize,
        search: search || DEFAULT_GET_USERS_PARAMS.search,
        sortBy: sortModel[0]?.field,
        sortOrder: sortModel[0]?.sort,
      });
    }
  }, [
    currentAccount.accountId,
    projectExpanded?.id,
    onGetProjectNotes,
    paginationModel.page,
    paginationModel.pageSize,
    search,
    sortModel,
  ]);

  const successFnGetUpdateProjectGraphicsToServer = (projectIds: number[]) => {
    const projectId = projectIds.find(item => item === Number(decryptId));
    if (
      currentAccount?.accountId &&
      projectExpanded?.id &&
      projectId &&
      deviceType
    ) {
      onGetProjectExtended({
        accountId: currentAccount.accountId,
        projectId: Number(decryptId),
        notes: true,
      });
      onGetProjectsOverview({
        deviceType: deviceType,
        accountId: currentAccount.accountId,
        projectId: Number(decryptId),
        fromDate: rangeDate.startDate ? rangeStartDateString : undefined,
        toDate: rangeDate.endDate ? rangeEndDateDateString : undefined,
      });
    }
  };

  useEffect(() => {
    if (currentAccount?.accountId && projectExpanded?.id) {
      getStartOfKeywordUpdateToServer(currentAccount.accountId, data => {
        onSetStartOfKeywordUpdate(data);
      });
      getUpdatedKeywordsToServer(currentAccount.accountId, data => {
        onSetUpdatedKeywords(data);
      });
      getUpdateProjectGraphicsToServer(
        currentAccount.accountId,
        successFnGetUpdateProjectGraphicsToServer
      );
    }
  }, [currentAccount?.accountId, projectExpanded?.id]);

  useEffect(() => {
    return () => {
      onCloseProjectExpandedPage();
    };
  }, []);

  return (
    <div>
      <Header />
      <ShortInfo />
      <Overview />
      {isShowImprovedVsDeclined && (
        <ContentContainer>
          <ImprovedVsDeclined />
          <KeywordTrends />
        </ContentContainer>
      )}
      <KeywordRankings
        updatedKeywordsTrigger={updatedKeywordsTrigger}
        onSetUpdatedKeywordsTrigger={onSetUpdatedKeywordsTrigger}
      />
      <ProjectPerformance />
      <ProjectExpandedNotes />
    </div>
  );
};

export default ProjectExpanded;
