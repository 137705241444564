import { styled } from '@mui/system';
import { Checkbox } from '@mui/material';

const CustomCheckbox = styled(Checkbox)`
  color: #dedede;
`;

const SmallContainerCheckbox = styled(Checkbox)`
  color: #dedede;
  height: fit-content;
  padding: 0;
`;

export { CustomCheckbox, SmallContainerCheckbox };
