import React, { useEffect } from 'react';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import { enqueueSnackbar, SnackbarKey } from 'notistack';

import DownIcon from '../../../../../../../shared/images/DownIcon';
import EditPencilIcon from '../../../../../../../shared/images/EditPencilIcon';
import SmallTrashIcon from '../../../../../../../shared/images/SmallTrashIcom';
import { UsersActionsPopoverProps } from '../types';
import { UserTeam } from '../../../../../types';
import { useAuth } from '../../../../../../../hooks';
import EditUserModal from './EditUserModal';
import CustomToast from '../../../../../../../shared/CustomToast/CustomToast';
import { closeAndUndoSnackbarAction } from '../../../../../../../utils/snackbar';

import { Popover } from '../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import { PrimaryButtonCustom } from '../../../../../../../shared/buttons/styles';
import { StyledListItemButton } from '../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/styles';
import { useAppSelector } from '../../../../../../../store';

const UsersActionsPopover: React.FC<UsersActionsPopoverProps> = ({
  rowSelectionModel,
  onRowSelectionModelChange,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const {
    onDeleteInvitationsTeam,
    onUsersBulkDeleteTeam,
    onGetUsers,
    onGetMyAccount,
    onSetDeleteElementsTeamsTable,
    onGetUserTeamFullInfo,
    onGetInvitationTeamFullInfo,
  } = useAuth();

  const sortModelTeam = useAppSelector(
    state => state.auth.sorting.sortModelTeam
  );
  const searchKeywordUsersTeam = useAppSelector(
    state => state.auth.search.searchKeywordUsersTeam
  );
  const users = useAppSelector(state => state.auth.users);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const [openEditUserModal, setEditUserModal] = React.useState(false);
  const [isMultipleSelect, seTisMultipleSelect] = React.useState(
    rowSelectionModel.length > 1
  );
  const [editUser, setEditUser] = React.useState<UserTeam | null>(null);
  const [registeredUsers, setRegisteredUsers] = React.useState<number[]>([]);
  const [invites, setInvites] = React.useState<number[]>([]);

  const handleOpenEditUserModal = () => {
    const user =
      users.items.find(user => user.email === rowSelectionModel[0]) || null;
    if (user && currentAccount?.accountId) {
      if (user?.registered) {
        onGetUserTeamFullInfo({
          id: currentAccount.accountId,
          userId: user.id,
        });
      } else {
        onGetInvitationTeamFullInfo({
          id: currentAccount.accountId,
          userId: user.id,
        });
      }

      setEditUser(user);
      setEditUserModal(true);
    }
  };
  const handleCloseEditUserModal = () => {
    onRowSelectionModelChange([]);
    setEditUserModal(false);
    setEditUser(null);
  };

  const handleHardDelete = () => {
    const successFn = () => {
      onGetUsers({
        accountId: currentAccount.accountId,
        page: users.meta.currentPage,
        limit: users.meta.itemsPerPage,
        search: searchKeywordUsersTeam,
        sortBy: sortModelTeam[0]?.field,
        sortOrder: sortModelTeam[0]?.sort,
      });
      onGetMyAccount(currentAccount.accountId);
      onSetDeleteElementsTeamsTable([]);
    };

    if (registeredUsers.length) {
      onUsersBulkDeleteTeam({
        accountId: currentAccount.accountId,
        userIds: registeredUsers,
        successFn,
      });
    }
    if (invites.length) {
      onDeleteInvitationsTeam({
        accountId: currentAccount.accountId,
        ids: invites,
        successFn,
      });
    }
  };

  const handleDeleteUsersTeam = (popupStateClose: () => void) => {
    onSetDeleteElementsTeamsTable(rowSelectionModel);

    let undoDelete = false;
    popupStateClose();

    const onUndo = () => {
      undoDelete = true;
      onSetDeleteElementsTeamsTable([]);
    };

    enqueueSnackbar(
      <CustomToast
        message='User Removed'
        submessage={`${rowSelectionModel.length} users have been successfully removed.`}
      />,
      {
        action: (snackbarId: SnackbarKey) =>
          closeAndUndoSnackbarAction(snackbarId, onUndo),
        variant: 'error',
      }
    );

    setTimeout(() => {
      !undoDelete && handleHardDelete();
    }, 5000);
  };

  useEffect(() => {
    seTisMultipleSelect(rowSelectionModel.length > 1);
  }, [rowSelectionModel.length]);

  useEffect(() => {
    const registeredUsers = users?.items
      ?.filter(
        user => rowSelectionModel.includes(user.email) && user.registered
      )
      .map(user => user.id);
    const invites = users?.items
      ?.filter(
        user => rowSelectionModel.includes(user.email) && !user.registered
      )
      .map(user => user.id);
    setRegisteredUsers(registeredUsers);
    setInvites(invites);
  }, [users?.items, rowSelectionModel]);

  return (
    <>
      <PopupState variant='popover' popupId='demo-popup-popover'>
        {popupState => (
          <div>
            <PrimaryButtonCustom
              variant='contained'
              size='small'
              $padding='6px 12px'
              endIcon={<DownIcon />}
              {...bindTrigger(popupState)}
            >
              {extraSmallScreen
                ? `${rowSelectionModel.length} Selected`
                : 'Actions'}
            </PrimaryButtonCustom>

            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box sx={{ p: '0 8px', width: '241px' }}>
                <List>
                  {!isMultipleSelect && !invites?.length && (
                    <ListItem disablePadding onClick={handleOpenEditUserModal}>
                      <StyledListItemButton>
                        <EditPencilIcon />
                        <ListItemText
                          sx={{ color: '#101828', paddingLeft: '8px' }}
                        >
                          Edit User
                        </ListItemText>
                      </StyledListItemButton>
                    </ListItem>
                  )}

                  <ListItem
                    disablePadding
                    onClick={() => handleDeleteUsersTeam(popupState.close)}
                  >
                    <StyledListItemButton>
                      <SmallTrashIcon />
                      <ListItemText
                        sx={{ color: '#B42318', paddingLeft: '8px' }}
                      >
                        {!isMultipleSelect ? 'Delete User' : 'Delete Users'}
                      </ListItemText>
                    </StyledListItemButton>
                  </ListItem>
                </List>
              </Box>
            </Popover>
            {editUser && (
              <EditUserModal
                handleClose={handleCloseEditUserModal}
                open={openEditUserModal}
                popupStateClose={popupState.close}
                registered={editUser?.registered}
              />
            )}
          </div>
        )}
      </PopupState>
    </>
  );
};

export default UsersActionsPopover;
