import { useMemo } from 'react';
import { useLocation } from 'react-router';

/**
 * A custom hook that provides access to the URL search parameters.
 *
 * This hook uses the `useLocation` hook from `react-router` to extract the
 * search portion of the URL and returns a `URLSearchParams` object for easy
 * access to query parameters.
 *
 * @returns {URLSearchParams} An instance of `URLSearchParams` containing the
 * current URL's query parameters.
 */
const useURLSearchParams = () => {
  const { search } = useLocation(); // Get the search string from the current location

  // Memoize the URLSearchParams instance to optimize performance
  return useMemo(() => new URLSearchParams(search), [search]);
};

export default useURLSearchParams;
