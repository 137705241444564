import React from 'react';

const PhoneIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="phone-01">
        <path id="Icon" d="M9.99996 14.5834H10.0083M6.83329 18.3334H13.1666C14.1 18.3334 14.5668 18.3334 14.9233 18.1518C15.2369 17.992 15.4918 17.737 15.6516 17.4234C15.8333 17.0669 15.8333 16.6002 15.8333 15.6667V4.33341C15.8333 3.39999 15.8333 2.93328 15.6516 2.57676C15.4918 2.26316 15.2369 2.00819 14.9233 1.8484C14.5668 1.66675 14.1 1.66675 13.1666 1.66675H6.83329C5.89987 1.66675 5.43316 1.66675 5.07664 1.8484C4.76304 2.00819 4.50807 2.26316 4.34828 2.57676C4.16663 2.93328 4.16663 3.39999 4.16663 4.33342V15.6667C4.16663 16.6002 4.16663 17.0669 4.34828 17.4234C4.50807 17.737 4.76304 17.992 5.07664 18.1518C5.43316 18.3334 5.89987 18.3334 6.83329 18.3334ZM10.4166 14.5834C10.4166 14.8135 10.2301 15.0001 9.99996 15.0001C9.76984 15.0001 9.58329 14.8135 9.58329 14.5834C9.58329 14.3533 9.76984 14.1667 9.99996 14.1667C10.2301 14.1667 10.4166 14.3533 10.4166 14.5834Z" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </svg>
  );
};

export default PhoneIcon;