import React from 'react';

import { Header, Content } from './styles';

const Name = () => (
  <div>
    <Header>Name</Header>
    <Content>
      Displays the names of both folders and projects. Click on a name to view its contents or details.
    </Content>
  </div>
);

const CreatedAt = () => (
  <div>
    <Header>Created</Header>
    <Content>
      Shows the date when the folder or project was created.
    </Content>
  </div>
);

const CreatedBy = () => (
  <div>
    <Header>Created By</Header>
    <Content>
      Indicates the user who created the folder or project.
    </Content>
  </div>
);

const Updated = () => (
  <div>
    <Header>Last Modified</Header>
    <Content>
      Shows the date when the folder was last updated or modified.
    </Content>
  </div>
);

export {
  Name,
  CreatedAt,
  CreatedBy,
  Updated,
};
