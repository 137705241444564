import { AxiosResponse } from 'axios';
import api from '../../api';
import {
  BulkDeleteEmailReportsParams,
  CreateEmailReportParams,
  EditEmailReportParams,
  EmailReportFrequencyResponse,
  EmailReportsResponse,
  GetEmailReportFullParams,
  GetEmailReportsParams,
  IEmailReportFull,
} from './types';

/**
 * Fetches the email reports for a specific account.
 * @param {GetEmailReportsParams} params - The parameters for fetching email reports.
 * @returns {Promise<AxiosResponse<EmailReportsResponse>>} The response containing email reports data.
 */
export const getEmailReportsAPI = async (
  params: GetEmailReportsParams
): Promise<AxiosResponse<EmailReportsResponse>> => {
  return api.get<EmailReportsResponse>(`/accounts/${params.id}/email-reports`, {
    params: {
      page: params.page + 1,
      limit: params.limit,
      sortBy: params.sortBy,
      sortOrder: params?.sortOrder ? params.sortOrder.toUpperCase() : null,
      search: params.search,
    },
  });
};

/**
 * Fetches the available email report frequencies.
 * @returns {Promise<AxiosResponse<EmailReportFrequencyResponse>>} The response containing frequency options.
 */
export const getEmailReportFrequencyAPI = async (): Promise<
  AxiosResponse<EmailReportFrequencyResponse>
> => {
  return api.get<EmailReportFrequencyResponse>(`/email-report-frequency`);
};

/**
 * Fetches the report delivery times for a specific account.
 * @param {number} accountId - The ID of the account.
 * @returns {Promise<AxiosResponse<EmailReportFrequencyResponse>>} The response containing delivery times.
 */
export const getReportDeliveryTimesAPI = async (
  accountId: number
): Promise<AxiosResponse<EmailReportFrequencyResponse>> => {
  return api.get<EmailReportFrequencyResponse>(
    `accounts/${accountId}/report-delivery-times`
  );
};

/**
 * Fetches the available report types.
 * @returns {Promise<AxiosResponse<EmailReportFrequencyResponse>>} The response containing report types.
 */
export const getReportTypesAPI = async (): Promise<
  AxiosResponse<EmailReportFrequencyResponse>
> => {
  return api.get<EmailReportFrequencyResponse>(`/report-types`);
};

/**
 * Creates a new email report for a specific account.
 * @param {CreateEmailReportParams} params - The parameters for creating an email report.
 * @returns {Promise<AxiosResponse<null>>} The response indicating the result of the creation operation.
 */
export const createEmailReportAPI = async (
  params: CreateEmailReportParams
): Promise<AxiosResponse<null>> => {
  return api.post<null>(`/accounts/${params.accountId}/email-reports`, {
    projectId: params.projectId,
    recipients: params.recipients,
    frequency: params.frequency,
    reportType: params.reportType,
    reportDeliveryTimeId: params.reportDeliveryTimeId,
  });
};

/**
 * Deletes multiple email reports in bulk for a specific account.
 * @param {BulkDeleteEmailReportsParams} params - The parameters for bulk deleting email reports.
 * @returns {Promise<AxiosResponse<null>>} The response indicating the result of the deletion operation.
 */
export const bulkDeleteEmailReportsAPI = async (
  params: BulkDeleteEmailReportsParams
): Promise<AxiosResponse<null>> => {
  return api.post<null>(
    `/accounts/${params.accountId}/email-reports/bulk-delete`,
    {
      emailReportIds: params.emailReportIds,
    }
  );
};

/**
 * Fetches the details of a specific email report for an account.
 * @param {GetEmailReportFullParams} params - The parameters to get the full email report details.
 * @returns {Promise<AxiosResponse<IEmailReportFull>>} The response containing the email report details.
 */
export const getEmailReportFullAPI = async (
  params: GetEmailReportFullParams
): Promise<AxiosResponse<IEmailReportFull>> => {
  return api.get<IEmailReportFull>(
    `/accounts/${params.accountId}/email-reports/${params.emailReportId}`
  );
};

/**
 * Edits an existing email report for a specific account.
 * @param {EditEmailReportParams} params - The parameters for editing an email report.
 * @returns {Promise<AxiosResponse<null>>} The response indicating the result of the edit operation.
 */
export const editEmailReportAPI = async (
  params: EditEmailReportParams
): Promise<AxiosResponse<null>> => {
  return api.patch<null>(
    `/accounts/${params.accountId}/email-reports/${params.emailReportId}`,
    {
      projectId: params.projectId,
      recipients: params.recipients,
      frequency: params.frequency,
      reportType: params.reportType,
      reportDeliveryTimeId: params.reportDeliveryTimeId,
    }
  );
};
