import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useLocation } from 'react-router-dom';

import { BreadcrumbLink } from './styled';

import ROUTES from '../../routes/constants';
import { encryptData } from '../../utils/cryptoJs';
import { useAppSelector } from '../../store';

const Breadcrumbs = () => {
  const location = useLocation();

  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );

  const pathSnippets = location.pathname.split('/').filter(i => i);

  const breadcrumbNameMap: Record<string, string> = {
    ['/settings']: `Settings`,
    [ROUTES.projects]: 'Projects',
    [ROUTES.notes]: 'Notes',
    [ROUTES.sharedLinks]: 'Shared Links',
    [ROUTES.scheduledEmailReports]: 'Scheduled Email Reports',
    [ROUTES.upgradePlan]: 'Upgrade Plan',
    [ROUTES.alerts]: 'Alerts',
    [ROUTES.dynamic.projectExpanded(
      `${encryptData(projectExpanded?.id.toString() as string)}`
    )]: `${projectExpanded?.projectName}`,
  };

  const extraBreadcrumbItems = pathSnippets.map((i, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return i !== 'account' &&
      i !== 'branding' &&
      i !== 'team' &&
      i !== 'billing' &&
      i !== 'API' ? (
      <BreadcrumbLink key={index} to={url}>
        {breadcrumbNameMap[url]}
      </BreadcrumbLink>
    ) : null;
  });

  const breadcrumbItems: JSX.Element[] = [
    <BreadcrumbLink key='Dashboard' to='#'>
      Dashboard
    </BreadcrumbLink>,
  ].concat(extraBreadcrumbItems.filter(item => item !== null) as JSX.Element[]);

  return (
    <MuiBreadcrumbs
      separator={<NavigateNextIcon fontSize='small' />}
      aria-label='breadcrumb'
    >
      {breadcrumbItems}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
