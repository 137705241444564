import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  FolderState,
  IFolderFromList,
  RootFolderState,
  SetCurrentFolderParams,
  IFolder,
} from '../types';
import {
  createFolderThunk,
  deletionOfFolderContentsThunk,
  getAvailableFoldersThunk,
  getFoldersByIdThunk,
  getRootFoldersThunk,
  renameFolderThunk,
} from './thunk';
import {
  setRootColumnVisibilityModel,
  resetColumnVisibilityModel,
  setPaginationPageSize,
  setPaginationPage,
  setSearchFolders,
  setRowSelectionModel,
  setColumnVisibilityModel,
  resetFoldersState,
  setDeleteElementsRootFoldersTable,
  setSortModelRootFolder,
  setSortModelNestedElements,
  updateProjectsInFolder,
} from './actions';

import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import {
  DEFAULT_COLUMN_VISIBILITY_MODEL,
  DEFAULT_ROOT_COLUMN_VISIBILITY_MODEL,
} from '../constants';
import { getPage } from '../../../utils';

const initialState = {
  isLoading: false,
  isLoadingFolder: false,
  listAvailableFolders: [] as IFolderFromList[],
  folders: {} as FolderState,
  currentFolder: {} as IFolderFromList,
  rootFolder: {} as RootFolderState,
  tables: {
    rootFolder: {
      search: '',
      sortModel: [{ field: 'name', sort: 'desc' }] as GridSortModel,
      sortModelNestedElements: [] as GridSortModel,
      paginationModel: {
        pageSize: 10,
        page: 0,
      },
      rootFolderColumnVisibilityModel:
        DEFAULT_ROOT_COLUMN_VISIBILITY_MODEL as any,
      childrenFolderColumnVisibilityModel:
        DEFAULT_COLUMN_VISIBILITY_MODEL as any,
      rowSelectionModel: [] as IFolder[],
      deleteElements: [] as IFolder[],
    },
  },
  isRootView: true,
};

export const foldersSlice = createSlice({
  name: 'folders',
  initialState,
  reducers: {
    setDefaultCurrentFolder(state, action: PayloadAction<IFolderFromList>) {
      state.currentFolder = action.payload;
      state.isRootView = true;
      state.folders = {} as FolderState;
    },
    setCurrentFolder(state, action: PayloadAction<SetCurrentFolderParams>) {
      state.currentFolder = action.payload.folder;
      state.isRootView = action.payload.isRootView;
    },
  },
  extraReducers: builder => {
    builder.addCase(getAvailableFoldersThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(
      getAvailableFoldersThunk.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.listAvailableFolders = payload.items;
      }
    );
    builder.addCase(getAvailableFoldersThunk.rejected, state => {
      state.isLoading = false;
      state.isLoadingFolder = false;
    });

    builder.addCase(getFoldersByIdThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getFoldersByIdThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.folders = payload;
      state.tables.rootFolder.deleteElements = [];
      state.tables.rootFolder.paginationModel.page = getPage(
        state.tables.rootFolder.paginationModel.page,
        payload.meta.currentPage,
        payload.meta.totalPages
      );
    });
    builder.addCase(getFoldersByIdThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(setRootColumnVisibilityModel, (state, { payload }) => {
      state.tables.rootFolder.rootFolderColumnVisibilityModel[payload.key] =
        payload.value;
    });

    builder.addCase(setColumnVisibilityModel, (state, { payload }) => {
      state.tables.rootFolder.childrenFolderColumnVisibilityModel[payload.key] =
        payload.value;
    });

    builder.addCase(resetColumnVisibilityModel, state => {
      if (state.isRootView) {
        state.tables.rootFolder.rootFolderColumnVisibilityModel =
          DEFAULT_ROOT_COLUMN_VISIBILITY_MODEL;
      } else {
        state.tables.rootFolder.childrenFolderColumnVisibilityModel =
          DEFAULT_COLUMN_VISIBILITY_MODEL;
      }
    });

    builder.addCase(setPaginationPageSize, (state, { payload }) => {
      state.tables.rootFolder.paginationModel.pageSize = payload;
    });

    builder.addCase(setPaginationPage, (state, { payload }) => {
      state.tables.rootFolder.paginationModel.page = payload;
    });

    builder.addCase(setSearchFolders, (state, { payload }) => {
      state.tables.rootFolder.search = payload;
    });

    builder.addCase(setRowSelectionModel, (state, { payload }) => {
      state.tables.rootFolder.rowSelectionModel = payload;
    });

    builder.addCase(getRootFoldersThunk.pending, state => {
      state.isLoading = true;
      state.isLoadingFolder = true;
    });
    builder.addCase(getRootFoldersThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isLoadingFolder = false;
      state.rootFolder = payload;
      state.tables.rootFolder.deleteElements = [];
      state.tables.rootFolder.paginationModel.page = getPage(
        state.tables.rootFolder.paginationModel.page,
        payload.meta.currentPage,
        payload.meta.totalPages
      );
    });
    builder.addCase(getRootFoldersThunk.rejected, state => {
      state.isLoading = false;
      state.isLoadingFolder = false;
    });

    builder.addCase(createFolderThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createFolderThunk.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createFolderThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(renameFolderThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(renameFolderThunk.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(renameFolderThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(deletionOfFolderContentsThunk.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deletionOfFolderContentsThunk.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(deletionOfFolderContentsThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(resetFoldersState, state => {
      return (state = initialState);
    });

    builder.addCase(setDeleteElementsRootFoldersTable, (state, { payload }) => {
      state.tables.rootFolder.deleteElements = payload.deleteElements;
    });

    builder.addCase(setSortModelRootFolder, (state, { payload }) => {
      state.tables.rootFolder.sortModel = payload;
    });
    builder.addCase(setSortModelNestedElements, (state, { payload }) => {
      state.tables.rootFolder.sortModelNestedElements = payload;
    });

    builder.addCase(updateProjectsInFolder, (state, { payload }) => {
      if (state?.folders?.items) {
        const arr1 = [...state.folders.items];
        const arr2 = [...payload.projects];

        arr2.forEach(item2 => {
          const indexToUpdate = arr1.findIndex(
            item1 =>
              Number(item1.id) === Number(item2.id) && item1.type === 'project'
          );

          if (indexToUpdate !== -1) {
            arr1[indexToUpdate] = {
              ...arr1[indexToUpdate],
              id: item2.id,
              updated: item2.updatedAt,
              updatedFullFormat: item2.updatedAtFullFormat,
              totalKeywords: item2.totalKeywords,
              improved: item2.improved,
              declined: item2.declined,
              noChange: item2.noChange,
              isUpdated: item2.isUpdated,
            };
          }
        });

        state.folders.items = arr1;
      }
    });
  },
});

export const { setDefaultCurrentFolder, setCurrentFolder } =
  foldersSlice.actions;

export default foldersSlice.reducer;
