import { styled } from '@mui/system';
import { GrayButtonCustom } from '../../buttons/styles';
import { Grid } from '@mui/material';

const StylesBanner = styled(Grid)`
  z-index: 1110;
  position: fixed;
  top: 0;
  width: 100%;
  background: #126fe9;
  padding: 12px 16px;
  align-items: center;

  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  height: 60px;

  @media (max-width:600px) {
    padding: 12px 12px;
    height: 90px;
  },
`;

const StylesUpgradeNowButton = styled(GrayButtonCustom)`
  border-radius: 18px !important;
  color: #101828;
  border: none;
`;

export { StylesBanner, StylesUpgradeNowButton };
