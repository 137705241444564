import { styled } from '@mui/material/styles';
import { Grid, Pagination } from '@mui/material';

const PaginationContainer = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const StyledPagination = styled(Pagination)`
  color: #344054;
  font-size: 14px;
  line-height: 24px;
  border-bottom: none;
  background: #fff;

  .MuiPagination-ul {
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

    li:nth-of-type(2) .MuiPaginationItem-root {
      border-left: 1px solid #d0d5dd;
    }
  }

  .MuiPaginationItem-root {
    margin: 0;
    padding: 6px 10px;
    min-width: 40px;
    border-radius: 0;
    color: #344054;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border-right: 1px solid #d0d5dd;

    &.MuiPaginationItem-ellipsis {
      border-right: 1px solid #d0d5dd;
    }

    &.MuiPaginationItem-previousNext {
      &:last-of-type {
        border-right: none;
      }
    }
  }

  .Mui-selected {
    background: #eaecf0;
  }
`;

export { StyledPagination, PaginationContainer };
