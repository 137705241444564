import React from 'react';

const AlertTriangleIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
    >
      <path
        d='M10.5001 7.50019V10.8335M10.5001 14.1669H10.5085M9.34622 3.24329L2.49215 15.0821C2.11198 15.7388 1.92189 16.0671 1.94999 16.3366C1.97449 16.5716 2.09763 16.7852 2.28876 16.9242C2.5079 17.0835 2.88728 17.0835 3.64605 17.0835H17.3542C18.113 17.0835 18.4923 17.0835 18.7115 16.9242C18.9026 16.7852 19.0258 16.5716 19.0503 16.3366C19.0784 16.0671 18.8883 15.7388 18.5081 15.0821L11.654 3.24329C11.2752 2.58899 11.0858 2.26184 10.8387 2.15196C10.6232 2.05612 10.3771 2.05612 10.1615 2.15196C9.91443 2.26184 9.72503 2.58899 9.34622 3.24329Z'
        stroke='#344054'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default AlertTriangleIcon;
