import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StylesHeading = styled(Grid)`
  color: #101828;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;
`;

const StylesSupportingText = styled(Grid)`
  color: #475467;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`;

export { StylesHeading, StylesSupportingText };
