import React, { useMemo } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import { useAuth, useProjects } from '../../../../../../../../../../hooks';

import CloseIcon from '@mui/icons-material/Close';
import { TimesGroup } from '../../../../../../../index';
import { PERIOD } from '../../../../../../../../constants';
import { PositionHistoryHeaderProps } from '../../types';
import GraphOptions from '../GraphOptions/GraphOptions';
import { GrayButtonCustom } from '../../../../../../../../../../shared/buttons/styles';
import ROUTES from '../../../../../../../../../../routes/constants';
import { encryptData } from '../../../../../../../../../../utils/cryptoJs';
import { useAppSelector } from '../../../../../../../../../../store';

const Header: React.FC<PositionHistoryHeaderProps> = ({
  onClose,
  id,
  name,
  keywordAnalytic,
  refGraph,
}) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const { onSetKeywordAnalyticPeriod, onGetKeywordPositionHistory } =
    useProjects();

  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );

  const timesGroupItems = useMemo(() => {
    return PERIOD.map(period => ({
      label: period.label,
      value: period.value,
      isActive: keywordAnalytic?.activePeriod?.value
        ? period.value === keywordAnalytic.activePeriod.value
        : true,
      onChange: () => {
        if (projectExpanded) {
          onSetKeywordAnalyticPeriod({ period: period, id: id });
          onGetKeywordPositionHistory({
            accountId: currentAccount.accountId,
            keywordId: id,
            projectId: projectExpanded?.id as number,
            period: period.value,
            competitorIds: projectExpanded.competitors.map(item => item.id),
          });
        }
      },
    }));
  }, [
    currentAccount.accountId,
    id,
    keywordAnalytic?.activePeriod?.value,
    onGetKeywordPositionHistory,
    onSetKeywordAnalyticPeriod,
    projectExpanded?.id,
    projectExpanded?.competitors,
  ]);

  const onCloseCharts = () => {
    onClose();
  };

  return (
    <Grid
      item
      container
      sx={{ position: 'relative', marginBottom: '27px' }}
      xs={12}
    >
      <IconButton
        sx={{ position: 'absolute', top: '-8px', right: '-8px' }}
        onClick={onCloseCharts}
      >
        <CloseIcon />
      </IconButton>

      <Grid xs={12}>
        <Typography
          sx={{
            color: '#020202',
            fontSize: '18px',
            fontWeight: '700',
          }}
        >
          Position History
        </Typography>

        <Typography
          sx={{
            marginTop: '18px',
            color: '#000',
            fontSize: '14px',
            fontWeight: '400',
          }}
        >
          Keyword:{' '}
          <Typography
            component='span'
            sx={{
              color: '#000',
              fontSize: '14px',
              fontWeight: '500',
            }}
          >
            {name || ''}
          </Typography>
        </Typography>

        <Grid
          item
          container
          gap={2}
          xs={12}
          sx={{
            marginTop: '27px',
            alignItems: 'center',
            justifyContent: 'space-between',
            '@media (max-width:600px)': {
              mt: '24px',
              mb: '24px',
            },
          }}
        >
          <Grid item>
            {timesGroupItems && <TimesGroup items={timesGroupItems} />}
          </Grid>
          <Grid item justifyContent={'flex-end'}>
            <Grid container gap={2}>
              <Grid item>
                <GrayButtonCustom
                  $padding='6px 12px'
                  size='small'
                  outline
                  fullWidth
                  onClick={() => {
                    window.open(
                      `${
                        window.location.origin
                      }${ROUTES.dynamic.keywordRankHistory(
                        encryptData(projectExpanded?.id?.toString() as string),
                        encryptData(id.toString())
                      )}`
                    );
                  }}
                >
                  View Full Details
                </GrayButtonCustom>
              </Grid>
              <Grid item>
                <GraphOptions refGraph={refGraph} id={id} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
