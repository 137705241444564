import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import authReducer from '../features/auth/redux/slice';
import projectsReducer from '../features/projects/redux/slice';
import foldersReducer from '../features/folders/redux/slice';
import notesReducer from '../features/notes/redux/slice';
import sharedLinksReducer from '../features/sharedLinks/redux/slice';
import emailReportsReducer from '../features/emailReports/redux/slice';
import alertsReducer from '../features/alerts/redux/slice';
import paymentReducer from '../features/payment/redux/slice';

/**
 * Configures the Redux store with multiple slices of state.
 *
 * The store holds the complete state tree of your application.
 * The state is managed by reducers that respond to dispatched actions.
 *
 * @see {@link https://redux-toolkit.js.org/api/configureStore} for more information on configureStore.
 */
const store = configureStore({
  reducer: {
    auth: authReducer,
    projects: projectsReducer,
    folders: foldersReducer,
    notes: notesReducer,
    sharedLinks: sharedLinksReducer,
    emailReports: emailReportsReducer,
    alerts: alertsReducer,
    payment: paymentReducer,
  },
});

/**
 * Represents the root state of the Redux store.
 *
 * This type can be used to infer the overall structure of the Redux state.
 *
 * @type {RootState}
 */
export type RootState = ReturnType<typeof store.getState>;

/**
 * Represents the dispatch function of the Redux store.
 *
 * This type can be used to ensure that dispatched actions have the correct types.
 *
 * @type {AppDispatch}
 */
export type AppDispatch = typeof store.dispatch;

/**
 * Custom hook that wraps the useSelector hook to use the RootState type.
 *
 * This ensures that the selector has access to the correct state type,
 * improving type safety when selecting state slices.
 *
 * @type {TypedUseSelectorHook<RootState>}
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 * Custom hook that wraps the useDispatch hook to use the AppDispatch type.
 *
 * This allows for better type inference when dispatching actions, ensuring
 * that the dispatched actions have the correct type.
 *
 * @returns {AppDispatch} The dispatch function of the Redux store.
 */
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
