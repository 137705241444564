import React, { useEffect } from 'react';

import { StyledOverview } from '../Overview/styles';
import { Graph, Header, GraphLabels } from './components';
import { useSharedLinks } from '../../../../../../hooks';
import { useAppSelector } from '../../../../../../store';

const ProjectPerformance = () => {
  const { onSetShowCompetitorsForGraph } = useSharedLinks();

  const projectShared = useAppSelector(
    state => state.sharedLinks.projectShared
  );
  const showCompetitors = useAppSelector(
    state => state.sharedLinks.projectGraphs.projectPerformance.showCompetitors
  );

  useEffect(() => {
    if (projectShared?.competitors.length && !showCompetitors.length) {
      onSetShowCompetitorsForGraph(
        projectShared.competitors.map(item => item.id)
      );
    }
  }, [onSetShowCompetitorsForGraph, projectShared?.competitors]);

  return (
    <StyledOverview container xs={12}>
      <Header />
      <Graph />
      <GraphLabels />
    </StyledOverview>
  );
};

export default ProjectPerformance;
