import React from 'react';

import { StyledTablePagination } from './customTableStyles';
import { CustomTablePaginationProps } from '../types';

const CustomTablePagination: React.FC<CustomTablePaginationProps> = ({
  page,
  handleChangePage,
  count,
  rowsPerPage,
  handleChangeRowsPerPage,
}) => {
  return (
    <StyledTablePagination
      labelRowsPerPage='Show rows:'
      count={count}
      page={page}
      rowsPerPageOptions={[10, 25, 50, 100]}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default CustomTablePagination;
