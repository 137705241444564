import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { decryptData } from '../../../../../../../../../utils/cryptoJs';
import { Grid } from '@mui/material';
import { GrayButtonCustom } from '../../../../../../../../../shared/buttons/styles';
import DownloadIcon from '../../../../../../../../../shared/images/DownloadIcon';
import useSharedKeywordRankHistory from '../../../../../../../hooks/useKeywordRankHistory';
import { getSharedLinkToken } from '../../../../../../../../../utils/localStorage';

const ExportCSV = () => {
  const { keywordId } = useParams();

  const { onSharedExportToCsvKeywordPositionsInfo } =
    useSharedKeywordRankHistory();

  const decryptKeywordId = useMemo(() => {
    if (keywordId) {
      return decryptData(keywordId);
    }
  }, [keywordId]);

  const handleExportToCsvKeywordPositionsInfo = () => {
    const sharedLinkToken = getSharedLinkToken();
    if (sharedLinkToken) {
      onSharedExportToCsvKeywordPositionsInfo({
        link: sharedLinkToken,
        keywordId: Number(decryptKeywordId),
      });
    }
  };
  return (
    <Grid paddingBottom='24px' paddingLeft='16px' paddingRight='16px'>
      <GrayButtonCustom
        $padding='7px 12px'
        size='small'
        outline
        startIcon={<DownloadIcon />}
        onClick={handleExportToCsvKeywordPositionsInfo}
      >
        Export CSV
      </GrayButtonCustom>
    </Grid>
  );
};

export default ExportCSV;
