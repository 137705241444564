import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

const BreadcrumbLink = styled(Link)`
  color: #667085;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;

  :hover {
    color: #101828;
  }
`;

export { BreadcrumbLink };
