import { Grid } from '@mui/material';
import React from 'react';
import {
  StylesInfoSection,
  StylesInfoSectionLabel,
  StylesInfoSectionValue,
} from './styles';
import useAlerts from '../../../../../../hooks/useAlerts';

const InfoSection = () => {
  const { triggerInfo } = useAlerts();

  return (
    <StylesInfoSection container gap={4}>
      <Grid item>
        <StylesInfoSectionLabel>Domain</StylesInfoSectionLabel>
        <StylesInfoSectionValue>
          {triggerInfo?.project?.url}
        </StylesInfoSectionValue>
      </Grid>
      <Grid item>
        <StylesInfoSectionLabel>Rule</StylesInfoSectionLabel>
        <StylesInfoSectionValue>
          {triggerInfo?.rule?.name}
        </StylesInfoSectionValue>
      </Grid>
      <Grid item>
        <StylesInfoSectionLabel>Threshold</StylesInfoSectionLabel>
        <StylesInfoSectionValue>
          {triggerInfo?.threshold}
        </StylesInfoSectionValue>
      </Grid>
      <Grid item>
        <StylesInfoSectionLabel>Affected keywords</StylesInfoSectionLabel>
        <StylesInfoSectionValue>
          {triggerInfo?.totalKeywords}
        </StylesInfoSectionValue>
      </Grid>
    </StylesInfoSection>
  );
};

export default InfoSection;
