/**
 * Renders the title for a list item based on the provided parameter.
 * @param param - The parameter to determine the list item title ('project', 'recipient', 'frequency', 'lastSent', 'nextDelivery', 'format').
 * @returns The corresponding title as a string, or an empty string if the parameter does not match any case.
 */
function renderListItemTitle(param: string) {
  switch (param) {
    case 'project':
      return 'Project';
    case 'recipient':
      return 'Recipient';
    case 'frequency':
      return 'Frequency';
    case 'lastSent':
      return 'Last Sent';
    case 'nextDelivery':
      return 'Next Delivery';
    case 'format':
      return 'Format';
    default:
      return '';
  }
}

export { renderListItemTitle };
