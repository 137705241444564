import React, { useEffect } from 'react';

import { StyledOverview } from '../Overview/styles';
import { Graph, Header, GraphLabels } from './components';
import { useProjects } from '../../../../../../hooks';
import { useAppSelector } from '../../../../../../store';

const ProjectPerformance = () => {
  const { onSetShowCompetitorsForGraph } = useProjects();

  const showCompetitors = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.projectPerformance.showCompetitors
  );
  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );

  useEffect(() => {
    if (projectExpanded?.competitors.length && !showCompetitors.length) {
      onSetShowCompetitorsForGraph(
        projectExpanded.competitors.map(item => item.id)
      );
    }
  }, [onSetShowCompetitorsForGraph, projectExpanded?.competitors]);

  return (
    <StyledOverview container xs={12}>
      <Header />
      <Graph />
      <GraphLabels />
    </StyledOverview>
  );
};

export default ProjectPerformance;
