import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StyledHeader = styled(Grid)`
  position: relative;
  padding: 24px;
`;

const StyledHead = styled('span')`
  color: #020202;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const AllKeywordsLabel = styled('span')`
  color: #101828;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`;

export { StyledHeader, StyledHead, AllKeywordsLabel };
