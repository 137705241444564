import { AxiosResponse } from 'axios';

import api from '../../api';
import {
  AllNotesData,
  BulkDeleteNotesParams,
  CreateNoteParams,
  EditNoteParams,
  GetAllNotesParams,
  GetProjectNotesParams,
  ProjectNotesData,
} from './types';

export const getProjectNotesAPI = async (
  params: GetProjectNotesParams
): Promise<AxiosResponse<ProjectNotesData>> => {
  return api.get<ProjectNotesData>(
    `/accounts/${params.id}/projects/${params.projectId}/notes`,
    {
      params: {
        page: params.page + 1,
        limit: params.limit,
        sortBy: params.sortBy,
        sortOrder: params?.sortOrder ? params.sortOrder.toUpperCase() : null,
        search: params.search,
      },
    }
  );
};

export const createNoteAPI = async (
  params: CreateNoteParams
): Promise<AxiosResponse<null>> => {
  return api.post<null>(
    `/accounts/${params.id}/projects/${params.projectId}/create-note`,
    { text: params.text }
  );
};

export const editNoteAPI = async (
  params: EditNoteParams
): Promise<AxiosResponse<null>> => {
  return api.patch<null>(
    `/accounts/${params.id}/projects/${params.projectId}/notes/${params.noteId}`,
    { text: params.text }
  );
};

export const bulkDeleteNotesAPI = async (
  params: BulkDeleteNotesParams
): Promise<AxiosResponse<null>> => {
  return api.post<null>(`/accounts/${params.id}/notes/bulk-delete`, {
    noteIds: params.noteIds,
  });
};

export const getAllNotesAPI = async (
  params: GetAllNotesParams
): Promise<AxiosResponse<AllNotesData>> => {
  return api.get<AllNotesData>(`/accounts/${params.id}/notes`, {
    params: {
      page: params.page + 1,
      limit: params.limit,
      sortBy: params.sortBy,
      sortOrder: params?.sortOrder ? params.sortOrder.toUpperCase() : null,
      search: params.search,
    },
  });
};
