import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StyledAPIdInfo = styled(Grid)`
  border-radius: 8px;
  border: 1px solid #dedede;
  background: #fff;
  overflow: scroll;
  max-width: 100%;
  width: 100%;
`;

const StyledRightCellTypography = styled(Grid)`
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

export { StyledAPIdInfo, StyledRightCellTypography };
