import React from 'react';
import { Grid } from '@mui/material';
import { StylesErrorBanner } from './styles';
import AlertTriangleIcon from '../../images/AlertTriangleIcon';
import { useNavigate } from 'react-router';
import ROUTES from '../../../routes/constants';

const YourLastPaymentFailedBanner = () => {
  const navigate = useNavigate();

  const onUpgradePlan = () => {
    navigate(ROUTES.upgradePlan);
  };

  return (
    <StylesErrorBanner container justifyContent={'space-between'} xs={12}>
      <Grid
        display={'flex'}
        alignItems={'center'}
        xs={12}
        justifyContent={'center'}
        gap={'16px'}
      >
        <Grid display={'flex'} alignItems={'center'} gap={'8px'}>
          <AlertTriangleIcon />
          <Grid>
            <b>{`Your last payment failed. `}</b>
            {`To keep your subscription, `}
            <b
              style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
              onClick={onUpgradePlan}
            >
              retry or update your details now.
            </b>
          </Grid>
        </Grid>
      </Grid>
    </StylesErrorBanner>
  );
};

export default YourLastPaymentFailedBanner;
