import React from 'react';

const DesktopAndMobileIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="monitor-05">
        <path id="Icon" d="M4.58329 16.6666H6.66663M6.66663 4.99992H4.33329C3.39987 4.99992 2.93316 4.99992 2.57664 5.18157C2.26304 5.34136 2.00807 5.59633 1.84828 5.90993C1.66663 6.26645 1.66663 6.73316 1.66663 7.66658V10.6666C1.66663 11.6 1.66663 12.0667 1.84828 12.4232C2.00807 12.7368 2.26304 12.9918 2.57664 13.1516C2.93316 13.3333 3.39987 13.3333 4.33329 13.3333H6.66663M12.6666 16.6666H15.6666C16.6 16.6666 17.0668 16.6666 17.4233 16.4849C17.7369 16.3251 17.9918 16.0702 18.1516 15.7566C18.3333 15.4 18.3333 14.9333 18.3333 13.9999V5.99992C18.3333 5.0665 18.3333 4.59979 18.1516 4.24327C17.9918 3.92966 17.7369 3.6747 17.4233 3.51491C17.0668 3.33325 16.6 3.33325 15.6666 3.33325H12.6666C11.7332 3.33325 11.2665 3.33325 10.91 3.51491C10.5964 3.6747 10.3414 3.92966 10.1816 4.24327C9.99996 4.59979 9.99996 5.0665 9.99996 5.99992V13.9999C9.99996 14.9333 9.99996 15.4 10.1816 15.7566C10.3414 16.0702 10.5964 16.3251 10.91 16.4849C11.2665 16.6666 11.7332 16.6666 12.6666 16.6666ZM15 12.4999C15 12.9602 14.6269 13.3333 14.1666 13.3333C13.7064 13.3333 13.3333 12.9602 13.3333 12.4999C13.3333 12.0397 13.7064 11.6666 14.1666 11.6666C14.6269 11.6666 15 12.0397 15 12.4999Z" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </svg>
  );
};

export default DesktopAndMobileIcon;