import React from 'react';

import {
  StyledBlockTitle,
  StyledClearButton,
  StyledWrapContainer,
} from '../../styles';
import { Grid } from '@mui/material';
import { StyledBadge } from './styles';

interface FilterByProps {
  handleFilterBy: (value: string) => void;
  filterBy: string;
  openFilter: boolean;
}

const FILTER_BY_OPTIONS = [
  { name: 'Projects', value: 'projects' },
  { name: 'Folders', value: 'folders' },
  { name: 'Keywords', value: 'keywords' },
  { name: 'Tags', value: 'tags' },
];

const FilterBy: React.FC<FilterByProps> = ({
  filterBy,
  openFilter,
  handleFilterBy,
}) => {
  const Options = () =>
    FILTER_BY_OPTIONS.map(item => (
      <StyledBadge
        key={item.value}
        item
        $isActive={item.value === filterBy || !filterBy}
        onClick={() => handleFilterBy(item.value)}
      >
        {item.name}
      </StyledBadge>
    ));

  if (!openFilter) return null;

  return (
    <StyledWrapContainer>
      <Grid container xs={12} justifyContent={'space-between'}>
        <StyledBlockTitle item> Filter by</StyledBlockTitle>
        <StyledClearButton item onClick={() => handleFilterBy('')}>
          Clear filter
        </StyledClearButton>
      </Grid>
      <Grid container xs={12} paddingTop={'8px'}>
        {Options()}
      </Grid>
    </StyledWrapContainer>
  );
};

export default FilterBy;
