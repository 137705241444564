import React from 'react';

const TrophyIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
    >
      <path
        d='M7.64206 8.84499C7.58047 8.86038 7.52915 8.88091 7.46243 8.89118C7.44703 8.89118 7.43676 8.90658 7.43676 8.92197C7.43676 9.44033 7.43676 9.96382 7.43676 10.4873C7.43676 11.0211 7.74983 11.4419 8.27846 11.56C8.45295 11.601 8.67877 11.5856 8.8738 11.5856C8.87893 11.5856 8.88406 11.5856 8.88406 11.5959V12.4838C8.88406 12.4838 8.89433 12.494 8.89946 12.494H9.83353C9.83353 12.494 9.84379 12.494 9.84379 12.5043V13.4845C9.84379 13.4845 9.83353 13.4999 9.82326 13.4999H4.0854C4.0854 13.4999 4.07514 13.4999 4.07514 13.4897V12.5094C4.07514 12.5094 4.0854 12.4992 4.09053 12.4992H4.99894C4.99894 12.4992 5.0246 12.4889 5.0246 12.4735V11.601C5.0246 11.601 5.0246 11.5907 5.03487 11.5907C5.21963 11.5907 5.42492 11.601 5.59429 11.5702C5.78418 11.5343 5.95868 11.4624 6.10238 11.3341C6.33846 11.1237 6.46164 10.8517 6.46677 10.5232C6.46677 10.3025 6.46677 9.7688 6.46677 8.92197C6.46677 8.91684 6.46677 8.91171 6.4565 8.91171C5.56349 8.73208 4.79365 8.10594 4.34201 7.32584C4.10593 6.92039 3.97249 6.44309 3.91604 5.98119C3.90064 5.87341 3.89551 5.73997 3.89551 5.57061C3.89551 3.8821 3.89551 2.19358 3.89551 0.510203C3.89551 0.499939 3.89551 0.499939 3.90577 0.499939H10.0132C10.0132 0.499939 10.0234 0.499939 10.0234 0.510203C10.0234 2.15766 10.0234 3.81024 10.0234 5.4577C10.0234 5.75024 10.0132 6.04278 9.94644 6.32505C9.86945 6.67404 9.74628 7.02304 9.57178 7.33097C9.1612 8.04949 8.45808 8.63457 7.64719 8.85525L7.64206 8.84499Z'
        fill='#1EA13A'
      />
      <path
        d='M1.82748 2.11148C1.70431 2.58364 1.69918 3.07121 1.77103 3.55364C1.79156 3.68708 1.81722 3.82052 1.84801 3.95396C1.99685 4.61602 2.29452 5.18057 2.71536 5.70919C2.9925 6.05818 3.32097 6.34559 3.70589 6.57141C3.71615 6.57654 3.72642 6.59194 3.72642 6.6022V7.7159C3.72642 7.7159 3.71102 7.7313 3.70589 7.7313C3.70589 7.7313 3.70589 7.7313 3.70076 7.7313C3.09515 7.40797 2.56653 6.98712 2.11489 6.46876C1.26807 5.5039 0.795898 4.33374 0.795898 3.04041C0.795898 2.41428 0.795898 1.78301 0.795898 1.15174C0.795898 1.14661 0.795898 1.14148 0.806163 1.14148H3.38256C3.38256 1.14148 3.39795 1.14148 3.39795 1.15174V2.06015C3.39795 2.06015 3.38256 2.09095 3.36203 2.09095H1.84288C1.84288 2.09095 1.82748 2.09608 1.82748 2.10634V2.11148Z'
        fill='#1EA13A'
      />
      <path
        d='M12.0662 2.09608H10.5213C10.5213 2.09608 10.5111 2.09095 10.5111 2.08068V1.16714C10.5111 1.16714 10.5265 1.14148 10.5419 1.14148H13.108C13.108 1.14148 13.1183 1.15174 13.1183 1.15688C13.1183 1.88052 13.1183 2.60417 13.1183 3.32782C13.1183 3.48692 13.0926 3.70248 13.0362 3.96935C12.9027 4.63141 12.6615 5.24729 12.3022 5.81183C12.2047 5.9658 12.0867 6.11977 11.9686 6.2686C11.6504 6.67405 11.2861 7.02818 10.8652 7.33098C10.6394 7.49008 10.4341 7.62865 10.198 7.74156C10.1929 7.74156 10.1877 7.74156 10.1826 7.73643V6.59707C10.1826 6.59707 10.1929 6.5868 10.198 6.58167C10.3879 6.46363 10.5111 6.38151 10.5624 6.33532C11.1783 5.8221 11.6761 5.13951 11.9327 4.37993C12.0354 4.08226 12.1072 3.79486 12.1431 3.52285C12.1791 3.2919 12.1893 3.03015 12.1791 2.73761C12.1688 2.50153 12.138 2.29111 12.0764 2.10121C12.0764 2.09608 12.0662 2.09095 12.061 2.09095L12.0662 2.09608Z'
        fill='#1EA13A'
      />
    </svg>
  );
};

export default TrophyIcon;
