// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect } from 'react';
import { Outlet } from 'react-router';
import usePayment from '../features/payment/hooks/usePayment';
import { PADDLE } from '../constants/config';
import ScriptLoader from 'react-script-loader-hoc';

const TestPayRoutes = () => {
  const { onSetIsOpenSuccessPayModal, onSetPayment } = usePayment();

  useEffect(() => {
    if (window.Paddle) {
      window.Paddle.Environment.set('sandbox');
      window.Paddle.Initialize({
        seller: 14292,
      });
      onSetPayment(window.Paddle);
    }
  }, [onSetIsOpenSuccessPayModal, onSetPayment]);
  return (
    <>
      <Outlet />
    </>
  );
};

export default ScriptLoader(PADDLE.URL)(TestPayRoutes);
