import React, { useMemo } from 'react';
import { List, ListItem, Skeleton } from '@mui/material';
import {
  PositionBlock,
  URLBlock,
  HeaderTypography,
  ListItemTypography,
} from './styles';
import { SearchResultListProps } from '../../types';
import { useAppSelector } from '../../../../../../../../../../store';
import {
  CustomTooltip,
  popperWhiteProps,
} from '../../../../../../../../../../shared/tooltip/styles';

const SearchResultList: React.FC<SearchResultListProps> = ({
  searchResult,
}) => {
  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );

  const skeletonItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const labelColumnName = useMemo(
    () =>
      projectExpanded?.searchEngine?.name === 'Google My Business' ||
      projectExpanded?.searchEngine?.name === 'Google Maps'
        ? 'Business Name'
        : 'URL',
    [projectExpanded?.searchEngine?.name]
  );

  const PosItems = useMemo(() => {
    return searchResult?.items
      ? searchResult?.items?.map(item => (
          <ListItem key={item.position}>
            <ListItemTypography keywordPosition={item.keywordPosition}>
              {item.position}
            </ListItemTypography>
          </ListItem>
        ))
      : skeletonItems.map(item => (
          <ListItem key={item}>
            <Skeleton width={'20px'} />
          </ListItem>
        ));
  }, [searchResult?.items]);

  const URLItems = useMemo(() => {
    return searchResult?.items
      ? searchResult?.items?.map(item => (
          <ListItem key={item.position}>
            <CustomTooltip
              title={<div dangerouslySetInnerHTML={{ __html: item.url }} />}
              placement='top-start'
              arrow
              PopperProps={popperWhiteProps}
            >
              <ListItemTypography
                keywordPosition={item.keywordPosition}
                dangerouslySetInnerHTML={{
                  __html: item.url,
                }}
              ></ListItemTypography>
            </CustomTooltip>
          </ListItem>
        ))
      : skeletonItems.map(item => (
          <ListItem key={item}>
            <Skeleton width={'200px'} />
          </ListItem>
        ));
  }, [searchResult?.items]);

  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '16px',
      }}
    >
      <PositionBlock>
        <HeaderTypography
          sx={{
            textAlign: 'right',
            padding: '12px 16px 12px 0',
            boxSizing: 'border-box',
          }}
        >
          Pos.
        </HeaderTypography>
        {PosItems}
      </PositionBlock>
      <URLBlock>
        <HeaderTypography
          sx={{
            textAlign: 'left',
            padding: '12px 0 12px',
          }}
        >
          {labelColumnName}
        </HeaderTypography>
        {URLItems}
      </URLBlock>
    </List>
  );
};

export default SearchResultList;
