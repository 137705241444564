import { AxiosResponse } from 'axios';
import api from '../../api';
import {
  GetTransactionToUpdatePaymentMethodParams,
  GetTransactionToUpdatePaymentMethodResponse,
  GetUpdateSubscriptionReviewParams,
  IUpdateSubscriptionReview,
  ReactivateSubscriptionParams,
  SubscriptionUpdateParams,
  UnsubscriptionParams,
} from './types';

export const getTransactionToUpdatePaymentMethod = async (
  params: GetTransactionToUpdatePaymentMethodParams
): Promise<AxiosResponse<GetTransactionToUpdatePaymentMethodResponse>> => {
  return api.post<GetTransactionToUpdatePaymentMethodResponse>(
    `accounts/${params.accountId}/subscriptions/update-payment-method`
  );
};

export const unsubscription = async (
  params: UnsubscriptionParams
): Promise<AxiosResponse<undefined>> => {
  return api.post<undefined>(
    `accounts/${params.id}/subscriptions/unsubscription`,
    {
      ...params.data,
    }
  );
};

export const reactivateSubscription = async (
  params: ReactivateSubscriptionParams
): Promise<AxiosResponse<undefined>> => {
  return api.post<undefined>(
    `accounts/${params.id}/subscriptions/restore-a-canceled-subscription`,
    { ...params.data }
  );
};

export const getUpdateSubscriptionReview = async (
  params: GetUpdateSubscriptionReviewParams
): Promise<AxiosResponse<IUpdateSubscriptionReview>> => {
  return api.patch<IUpdateSubscriptionReview>(
    `/accounts/${params.id}/subscriptions/update-review`,
    {
      paddleProductId: params.paddleProductId,
    }
  );
};

export const subscriptionUpdate = async (
  params: SubscriptionUpdateParams
): Promise<AxiosResponse<undefined>> => {
  return api.patch<undefined>(`/accounts/${params.id}/subscriptions/update`, {
    paddleProductId: params.paddleProductId,
  });
};
