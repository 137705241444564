import React from 'react';

import { RoleLabelProps } from '../types';

import { RoleLabelContainer } from './customTableStyles';

const RoleLabel: React.FC<RoleLabelProps> = ({ roleName }) => {
  switch (roleName) {
    case 'Admin':
      return (
        <RoleLabelContainer backgroundColor='#EFF8FF' color='#175CD3'>
          {roleName}
        </RoleLabelContainer>
      );
    case 'Addon':
      return (
        <RoleLabelContainer backgroundColor='#FFF2E4' color='#ED810C'>
          {roleName}
        </RoleLabelContainer>
      );
    case 'ViewOnly':
      return (
        <RoleLabelContainer backgroundColor='#F6F6F6' color='#808080'>
          {roleName}
        </RoleLabelContainer>
      );
    default:
      return (
        <RoleLabelContainer backgroundColor='#EFF8FF' color='#175CD3'>
          {roleName}
        </RoleLabelContainer>
      );
  }
};

export default RoleLabel;
