import { createFilterOptions } from '@mui/material';

/**
 * Creates a filter function for options with `id` and `projectName` properties.
 */
const filter = createFilterOptions<{ id: number; projectName: string }>();

/**
 * Creates a filter function for options with `id` and `name` properties.
 */
const filterFolder = createFilterOptions<{ id: number; name: string }>();

export { filter, filterFolder };
