import {
  IAccountLimitErrorModals,
  ICompetitorsLimitExceeded,
  IDailyRefreshesLimitExceeded,
  IEmailReportsLimitExceeded,
  ICannotAddExcessKeywords,
  IKeywordsLimitExceeded,
  INotesLimitExceeded,
  ISharedLinksLimitExceeded,
  ITagsPerKeywordLimitExceeded,
  ITagsPerProjectLimitExceeded,
  IUsersLimitExceeded,
  IKeywordUpdateScheduled,
  ICannotRefreshExcessKeywords,
  IYouveReachedYourRefreshLimitForToday,
  ITriggersLimitExceeded,
} from '../types/errors';
import {
  IInitialFreeTrialSubscriptionEndedModals,
  NecessaryRemovalResponse,
} from '../types';

/**
 * Initial state for keywords limit exceeded modal.
 * @type {IKeywordsLimitExceeded}
 */
const initialKeywordsLimitExceeded: IKeywordsLimitExceeded = {
  open: false,
};

/**
 * Initial state for email reports limit exceeded modal.
 * @type {IEmailReportsLimitExceeded}
 */
const initialEmailReportsLimitExceeded: IEmailReportsLimitExceeded = {
  open: false,
  currentEmailReports: 0,
  emailReportsLimit: 0,
  emailReportsYouCanAdd: 0,
};

/**
 * Initial state for shared links limit exceeded modal.
 * @type {ISharedLinksLimitExceeded}
 */
const initialSharedLinksLimitExceeded: ISharedLinksLimitExceeded = {
  open: false,
  currentSharedLinks: 0,
  sharedLinksLimit: 0,
  sharedLinksYouCanAdd: 0,
};

/**
 * Initial state for users limit exceeded modal.
 * @type {IUsersLimitExceeded}
 */
const initialUsersLimitExceeded: IUsersLimitExceeded = {
  open: false,
  currentUsers: 0,
  usersLimit: 0,
  usersYouCanAdd: 0,
};

/**
 * Initial state for notes limit exceeded modal.
 * @type {INotesLimitExceeded}
 */
const initialNotesLimitExceeded: INotesLimitExceeded = {
  open: false,
  currentNotes: 0,
  notesLimit: 0,
  notesYouCanAdd: 0,
};

/**
 * Initial state for competitors limit exceeded modal.
 * @type {ICompetitorsLimitExceeded}
 */
const initialCompetitorsLimitExceeded: ICompetitorsLimitExceeded = {
  open: false,
  currentCompetitors: 0,
  competitorsLimit: 0,
  competitorsYouCanAdd: 0,
};

/**
 * Initial state for tags per keyword limit exceeded modal.
 * @type {ITagsPerKeywordLimitExceeded}
 */
const initialTagsPerKeywordLimitExceeded: ITagsPerKeywordLimitExceeded = {
  open: false,
  currentTagsPerKeyword: 0,
  tagsPerKeywordLimit: 0,
  tagsPerKeywordYouCanAdd: 0,
};

/**
 * Initial state for tags per project limit exceeded modal.
 * @type {ITagsPerProjectLimitExceeded}
 */
const initialTagsPerProjectLimitExceeded: ITagsPerProjectLimitExceeded = {
  open: false,
  currentTagsPerProject: 0,
  tagsPerProjectLimit: 0,
  tagsPerProjectYouCanAdd: 0,
};

/**
 * Initial state for daily refreshes limit exceeded modal.
 * @type {IDailyRefreshesLimitExceeded}
 */
const initialDailyRefreshesLimitExceeded: IDailyRefreshesLimitExceeded = {
  open: false,
  currentDailyRefreshes: 0,
  dailyRefreshesLimit: 0,
  dailyRefreshesYouCanAdd: 0,
};

/**
 * Initial state for cannot add excess keywords modal.
 * @type {ICannotAddExcessKeywords}
 */
const initialCannotAddExcessKeywords: ICannotAddExcessKeywords = {
  open: false,
  desktopAndMobile: false,
  googleMyBusinessOrYouTube: false,
  searchEngine: '',
  keywordsAdd: 0,
};

/**
 * Initial state for triggers limit exceeded modal.
 * @type {ITriggersLimitExceeded}
 */
const initialTriggersLimitExceeded: ITriggersLimitExceeded = {
  open: false,
  currentTriggers: 0,
  triggersLimit: 0,
  triggersYouCanAdd: 0,
};

/**
 * Initial state for keyword update scheduled modal.
 * @type {IKeywordUpdateScheduled}
 */
const initialKeywordUpdateScheduled: IKeywordUpdateScheduled = {
  open: false,
};

/**
 * Initial state for cannot refresh excess keywords modal.
 * @type {ICannotRefreshExcessKeywords}
 */
const initialCannotRefreshExcessKeywords: ICannotRefreshExcessKeywords = {
  open: false,
  keywordsAdd: 0,
};

/**
 * Initial state for refresh limit reached modal.
 * @type {IYouveReachedYourRefreshLimitForToday}
 */
const initialYouveReachedYourRefreshLimitForToday: IYouveReachedYourRefreshLimitForToday =
  {
    open: false,
  };

/**
 * Initial state for account limit error modals.
 * @type {IAccountLimitErrorModals}
 */
const initialAccountLimitErrorModals: IAccountLimitErrorModals = {
  keywordsLimitExceeded: initialKeywordsLimitExceeded,
  emailReportsLimitExceeded: initialEmailReportsLimitExceeded,
  sharedLinksLimitExceeded: initialSharedLinksLimitExceeded,
  usersLimitExceeded: initialUsersLimitExceeded,
  notesLimitExceeded: initialNotesLimitExceeded,
  competitorsLimitExceeded: initialCompetitorsLimitExceeded,
  tagsPerKeywordLimitExceeded: initialTagsPerKeywordLimitExceeded,
  tagsPerProjectLimitExceeded: initialTagsPerProjectLimitExceeded,
  dailyRefreshesLimitExceeded: initialDailyRefreshesLimitExceeded,
  cannotAddExcessKeywords: initialCannotAddExcessKeywords,
  keywordUpdateScheduled: initialKeywordUpdateScheduled,
  cannotRefreshExcessKeywords: initialCannotRefreshExcessKeywords,
  youveReachedYourRefreshLimitForToday:
    initialYouveReachedYourRefreshLimitForToday,
  triggersLimitExceeded: initialTriggersLimitExceeded,
};

/**
 * Initial state for actions required before downgrading.
 * @type {NecessaryRemovalResponse}
 */
const initialActionRequiredBeforeDowngrading: NecessaryRemovalResponse = {
  numberOfKeywords: 0,
  numberOfTriggers: 0,
  numberOfEmailReports: 0,
  numberOfNotes: 0,
};

/**
 * Initial state for free trial subscription ended modals.
 * @type {IInitialFreeTrialSubscriptionEndedModals}
 */
const initialFreeTrialSubscriptionEndedModals: IInitialFreeTrialSubscriptionEndedModals =
  {
    freeTrial: false,
    subscription: false,
  };

/**
 * Initial state for action required before cancelling subscription.
 * @type {object}
 */
const initialActionRequiredCancelSubscription = {
  open: false,
};

/**
 * Initial state for notice account deletion cancelled modal.
 * @type {object}
 */
const initialNoticeAccountDeletionCancelled = {
  open: false,
};

/**
 * Initial state for account delete error modals.
 * @type {object}
 */
const initialAccountDeleteErrorModals = {
  actionRequiredCancelSubscription: initialActionRequiredCancelSubscription,
  noticeAccountDeletionCancelled: initialNoticeAccountDeletionCancelled,
};

export {
  initialKeywordsLimitExceeded,
  initialAccountLimitErrorModals,
  initialEmailReportsLimitExceeded,
  initialSharedLinksLimitExceeded,
  initialUsersLimitExceeded,
  initialNotesLimitExceeded,
  initialCompetitorsLimitExceeded,
  initialTagsPerKeywordLimitExceeded,
  initialTagsPerProjectLimitExceeded,
  initialDailyRefreshesLimitExceeded,
  initialActionRequiredBeforeDowngrading,
  initialCannotAddExcessKeywords,
  initialKeywordUpdateScheduled,
  initialCannotRefreshExcessKeywords,
  initialYouveReachedYourRefreshLimitForToday,
  initialFreeTrialSubscriptionEndedModals,
  initialActionRequiredCancelSubscription,
  initialNoticeAccountDeletionCancelled,
  initialAccountDeleteErrorModals,
  initialTriggersLimitExceeded,
};
