import {
  Box,
  Grid,
  InputLabel,
  Typography,
  OutlinedInput,
  FormHelperText,
  Link as MIULink,
  InputAdornment,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { CustomFormHelperText } from '../../../../shared/components';
import SerpNestLogo from '../../../../shared/images/SerpNestLogo';
import { useAuth } from '../../../../hooks';
import ROUTES from '../../../../routes/constants';

import {
  GoogleAuthButton,
  PrimaryButton,
} from '../../../../shared/buttons/styles';
import {
  ForgotPasswordLink,
  LogoContainer,
  PasswordTitleContainer,
  SingUpLabel,
  Wrapper,
  CustomFormControlLabel,
  DividerForGoogleAuth,
} from './styles';
import { CustomCheckbox } from '../../../../shared/checkbox/styles';
import CustomCheckboxImage from '../../../../shared/images/CustomCheckbox';
import { Paper } from '../../../../shared/paper/styles';
import CheckedIcon from '../../../../shared/images/CheckedIcon';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { PasswordStateLabel } from '../RegisterForm/styles';
import {
  saveGoogleAuthLoader,
  saveVerifyEmailLocalStorage,
} from '../../../../utils/localStorage';
import GoogleIconIcon from '../../../../shared/images/GoogleIcon';
import { openGoogleAuthWindow } from '../../utils';
import useLocalStorage from '../../../../hooks/useLocalStorage';

/**
 * Component representing the login page.
 *
 * @component
 * @returns {JSX.Element} The Login component.
 */
const Login = () => {
  const navigate = useNavigate();

  const { onLogin, onResendingMailConfirmationLetterThunkEmail } = useAuth();

  const [googleAuthLoader] = useLocalStorage('googleAuthLoader', '0');

  const [reloadOnReturn, setReloadOnReturn] = useState(false);

  const [checked, setChecked] = React.useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  /**
   * Resends the confirmation email.
   *
   * @param {string} email - The email to resend the confirmation letter to.
   */
  const onResendEmail = (email: string) => {
    onResendingMailConfirmationLetterThunkEmail({
      email,
      errorFn: () => {
        saveVerifyEmailLocalStorage(email);
        navigate(ROUTES.verifyEmail);
      },
      errorNavigate: () => {
        navigate(ROUTES.forgotPasswordContactSupport);
      },
    });
  };

  /**
   * Toggles the visibility of the password field.
   */
  const handleClickShowPassword = () => setShowPassword(show => !show);

  /**
   * Prevents default action on mouse down for the password visibility icon.
   *
   * @param {React.MouseEvent<HTMLButtonElement>} event - The mouse down event.
   */
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      message: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      username: Yup.string().required('Email is required.'),
      password: Yup.string().max(255).required('Password is required.'),
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      const errors = await formik.validateForm();
      if (Object.keys(errors).length === 0) {
        onLogin({
          data: { ...values, email: values.username },
          checked,
          successFn: () => {
            navigate(ROUTES.main);
          },
          errorFn: (error: any) => {
            if (error?.message === 'User account not confirmed') {
              onResendEmail(values.username);
            } else {
              setStatus({ success: false });
              setSubmitting(false);
              setErrors(error);
            }
          },
        });
      } else {
        setStatus({ success: false });
        setSubmitting(false);
      }
    },
  });

  /**
   * Handles Google authentication.
   */
  const handleGoogleAuth = () => {
    openGoogleAuthWindow();
  };

  useEffect(() => {
    if (reloadOnReturn) {
      setReloadOnReturn(false);
      window.location.reload();
    }
  }, [reloadOnReturn]);

  useEffect(() => {
    if (Number(googleAuthLoader)) {
      saveGoogleAuthLoader('0');
      window.location.reload();
    }
  }, [googleAuthLoader]);

  return (
    <Wrapper>
      <Grid container>
        <Grid xs={1} lg={4} md={2} />
        <Grid xs={10} lg={4} md={8}>
          <Paper
            sx={{
              p: 4,
            }}
          >
            <LogoContainer>
              <SerpNestLogo />
            </LogoContainer>
            <SingUpLabel variant='h5'>Login</SingUpLabel>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  mt: '24px',
                }}
              >
                <GoogleAuthButton
                  fullWidth
                  startIcon={<GoogleIconIcon width={'24px'} height={'24px'} />}
                  onClick={handleGoogleAuth}
                >
                  Sign in with Google
                </GoogleAuthButton>
              </Grid>
              <Grid
                item
                container
                justifyContent={'center'}
                xs={12}
                sx={{
                  mt: 2,
                }}
              >
                <DividerForGoogleAuth
                  orientation='horizontal'
                  variant={'fullWidth'}
                  flexItem
                >
                  OR
                </DividerForGoogleAuth>
              </Grid>
            </Grid>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel shrink htmlFor='email'>
                      Email address
                    </InputLabel>
                    <OutlinedInput
                      autoFocus
                      fullWidth
                      type='email'
                      id='username'
                      name='username'
                      autoComplete='username'
                      placeholder='name@example.com'
                      value={formik.values.username}
                      onChange={async (
                        e: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        await formik.setFieldValue('username', e.target.value);
                        const errors = { ...formik.errors };
                        delete errors.username;
                        delete errors.message;
                        formik.setErrors(errors);
                      }}
                      error={!!formik.errors.username}
                    />
                    {formik.errors.username && (
                      <FormHelperText error id='username'>
                        <CustomFormHelperText error={formik.errors.username} />
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <PasswordTitleContainer>
                      <div>
                        <InputLabel shrink htmlFor='password'>
                          Password
                        </InputLabel>
                      </div>
                      <div>
                        <Typography variant={'body2'}>
                          <ForgotPasswordLink to={ROUTES.forgotPassword}>
                            Forgot password?
                          </ForgotPasswordLink>
                        </Typography>
                      </div>
                    </PasswordTitleContainer>
                    <OutlinedInput
                      fullWidth
                      id='password'
                      type={showPassword ? 'text' : 'password'}
                      value={formik.values.password}
                      onChange={async (
                        e: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        await formik.setFieldValue('password', e.target.value);
                        const errors = { ...formik.errors };
                        delete errors.password;
                        delete errors.message;
                        formik.setErrors(errors);
                      }}
                      error={!!formik.errors.password}
                      placeholder='Password'
                      name='password'
                      autoComplete={'current-password'}
                      endAdornment={
                        <InputAdornment
                          position='end'
                          onClick={handleClickShowPassword}
                          sx={{ cursor: 'pointer' }}
                        >
                          <IconButton
                            aria-label='toggle password visibility'
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                          <PasswordStateLabel>
                            {showPassword ? <b>Hide</b> : <b>Show</b>}
                          </PasswordStateLabel>
                        </InputAdornment>
                      }
                    />
                    {
                      <FormHelperText
                        error={!!formik.errors.password}
                        id='password'
                      >
                        {formik.errors.password && (
                          <CustomFormHelperText
                            error={formik.errors.password}
                          />
                        )}
                      </FormHelperText>
                    }
                  </Grid>
                  <Grid
                    xs={12}
                    sx={{
                      pl: 2,
                      pt: 1,
                      pb: 1,
                    }}
                  >
                    <CustomFormControlLabel
                      control={
                        <CustomCheckbox
                          checked={checked}
                          onChange={event => setChecked(event.target.checked)}
                          icon={<CustomCheckboxImage />}
                          checkedIcon={<CheckedIcon />}
                        />
                      }
                      label='Keep me logged in'
                    />
                  </Grid>
                </Grid>
                <PrimaryButton
                  size={'large'}
                  fullWidth
                  variant='contained'
                  color={'primary'}
                  type='submit'
                  disabled={formik.isSubmitting}
                >
                  Log in
                </PrimaryButton>
                {
                  <FormHelperText error={!!formik.errors.message} id='password'>
                    {formik.errors.message && (
                      <CustomFormHelperText error={formik.errors.message} />
                    )}
                  </FormHelperText>
                }
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      mt: 2,
                    }}
                  >
                    <Typography variant='body2'>
                      Not a member? <Link to={ROUTES.signup}>Sign up now</Link>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body2' color='#666666'>
                      This site is protected by reCAPTCHA and the Google{' '}
                      <MIULink
                        color='#666666'
                        href='https://policies.google.com/privacy'
                      >
                        Privacy Policy
                      </MIULink>{' '}
                      and{' '}
                      <MIULink
                        color='#666666'
                        href='https://policies.google.com/terms'
                      >
                        Terms of Service
                      </MIULink>{' '}
                      apply.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default Login;
