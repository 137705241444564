import { Stack } from '@mui/material';
import React from 'react';

import { useAppSelector } from '../../../../../../../store';

const CustomNoRowsOverlay = () => {
  const searchKeywordUsersTeam = useAppSelector(
    state => state.auth.search.searchKeywordUsersTeam
  );
  return (
    <Stack
      height='100%'
      alignItems='center'
      justifyContent='center'
      textAlign='center'
    >
      No user found under search term “{searchKeywordUsersTeam}”.
    </Stack>
  );
};

export default CustomNoRowsOverlay;
