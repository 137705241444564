import React from 'react';

import DesktopDeviceIcon from '../../shared/images/DesktopDeviceIcon';
import MobileDeviceIcon from '../../shared/images/MobileDeviceIcon';
import DesktopAndMobileDeviceIcon from '../../shared/images/DesktopAndMobileDeviceIcon';
import { DEFAULT_KEYWORD_POSITIONS_MODEL } from './constants';
import UpGreenIcon from '../../shared/images/UpGreenIcon';
import DownRedIcon from '../../shared/images/DownRedIcon';
import TrophyIcon from '../../shared/images/TrophyIcon';
import GreenCheckMarkIcon from '../../shared/images/GreenCheckMarkIcon';
import Dash from './components/Dash';
import Grid from '@mui/material/Grid';
import { Range } from 'react-date-range';
import GoogleIconIcon from '../../shared/images/GoogleIcon';
import GoogleBusinessIcon from '../../shared/images/GoogleBusinessIcon';
import GoogleMapsIcon from '../../shared/images/SearchEngineIcons/GoogleMapsIcon';
import MicrosoftBingIcon from '../../shared/images/SearchEngineIcons/MicrosoftBingIcon';
import YahooIcon from '../../shared/images/SearchEngineIcons/YahooIcon';
import BaiduIcon from '../../shared/images/SearchEngineIcons/BaiduIcon';
import YouTubeIcon from '../../shared/images/SearchEngineIcons/YouTubeIcon';
import { IFolderTree } from '../auth/types';
import { FormHelperText } from '@mui/material';
import { CustomFormHelperText } from '../../shared/components';

interface ObjectWithBooleans {
  top3: boolean;
  top10: boolean;
  top30: boolean;
  top100: boolean;
}

interface ITopKeywordRankings {
  isImproved: boolean;
  isDeclined: boolean;
  dash: boolean;
  changes: number;
  positionInfo: {
    difference: number;
    lastPositionDate: string;
    lastPosition: number;
    periodPosition: number;
    positionDateForPeriod: string;
    isImproved: boolean;
    isDeclined: boolean;
  };
}

export interface IPositionKeywordRankings {
  position: number;
  trophy: true;
  greenCheckMark: boolean;
  dash: boolean;
}

/**
 * Returns the corresponding icon component for the specified device type.
 * @param type - The device type ('Desktop', 'Mobile', 'DesktopAndMobile').
 * @returns The React component representing the device icon or a default element.
 */
function getDeviceTypeIcon(type: string) {
  switch (type) {
    case 'Desktop':
      return <DesktopDeviceIcon />;
    case 'Mobile':
      return <MobileDeviceIcon />;
    case 'DesktopAndMobile':
      return <DesktopAndMobileDeviceIcon />;
    default:
      return <div>-</div>;
  }
}

/**
 * Filters the position items based on specific criteria.
 * @param items - An array of position status strings.
 * @param value - An optional filter value to determine how the items are filtered.
 * @returns A filtered array of position items.
 */
function positionFilter(items: string[], value?: string) {
  const noTopArray = items.filter(
    item =>
      item === 'improved' ||
      item === 'declined' ||
      item === 'lost' ||
      item === 'notRanked' ||
      item === 'noChange'
  );

  const defaultArray: string[] = Object.keys(DEFAULT_KEYWORD_POSITIONS_MODEL);

  function filterArray(arr: string[], filter: string): string[] {
    const index = arr.indexOf(filter);
    if (
      filter === 'improved' ||
      filter === 'declined' ||
      filter === 'lost' ||
      filter === 'notRanked' ||
      filter === 'noChange'
    ) {
      return items;
    }
    if (index !== -1) {
      return arr.slice(0, value ? index : index + 1);
    }
    return [];
  }

  if (value) {
    return [...filterArray(defaultArray, value), ...noTopArray];
  } else {
    return items.length
      ? [...filterArray(defaultArray, items[items.length - 1]), ...noTopArray]
      : [];
  }
}

/**
 * Updates the boolean properties of an object based on the provided list of properties to check.
 * @param obj - An object containing boolean properties.
 * @param propsToCheck - An array of property names to check and set to true.
 * @returns A new object with the updated boolean properties.
 */
function checkObjectProperties(
  obj: ObjectWithBooleans,
  propsToCheck: string[]
): ObjectWithBooleans {
  const result: ObjectWithBooleans = { ...obj };

  for (const prop in obj) {
    if (propsToCheck.includes(prop)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result[prop] = true;
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result[prop] = false;
    }
  }

  return result;
}

/**
 * Returns the appropriate icon for top keyword rankings based on the ranking data.
 * @param topKeywordRankings - An object containing information about top keyword rankings.
 * @returns The React component for the icon or null if conditions are not met.
 */
function getTopIconForKeywordRankingsTable(
  topKeywordRankings: ITopKeywordRankings
) {
  if (topKeywordRankings.changes >= 101) return null;
  if (topKeywordRankings.dash) return null;
  if (topKeywordRankings.isDeclined) return <DownRedIcon />;
  if (topKeywordRankings.isImproved) return <UpGreenIcon />;
  if (!topKeywordRankings.changes) return null;
  return null;
}

/**
 * Returns the appropriate position icon for keyword rankings based on the ranking data.
 * @param positionKeywordRankings - An object containing information about position keyword rankings.
 * @returns The React component for the icon or null if conditions are not met.
 */
function getPositionIconForKeywordRankingsTable(
  positionKeywordRankings: IPositionKeywordRankings
) {
  if (positionKeywordRankings.position >= 101) return null;
  if (positionKeywordRankings.dash) return null;
  if (positionKeywordRankings.trophy) return <TrophyIcon />;
  if (positionKeywordRankings.greenCheckMark) return <GreenCheckMarkIcon />;
  if (!positionKeywordRankings.position) return null;
  return null;
}

/**
 * Gets the tooltip text for top keyword rankings based on the ranking data.
 * @param topKeywordRankings - An object containing information about top keyword rankings.
 * @returns The tooltip text as a string.
 */
function getTopTooltipForKeywordRankingsTable(
  topKeywordRankings: ITopKeywordRankings
) {
  if (topKeywordRankings.changes >= 101) return '> 100';
  if (topKeywordRankings.dash) return 'No data';
  if (topKeywordRankings.isDeclined)
    return topKeywordRankings.changes.toString();
  if (topKeywordRankings.isImproved)
    return topKeywordRankings.changes.toString();
  if (!topKeywordRankings.changes) return 'No data';
  return topKeywordRankings.changes.toString();
}

/**
 * Gets the tooltip text for position keyword rankings based on the ranking data.
 * @param positionKeywordRankings - An object containing information about position keyword rankings.
 * @returns The tooltip text as a string.
 */
function getPositionTooltipForKeywordRankingsTable(
  positionKeywordRankings: IPositionKeywordRankings
) {
  if (positionKeywordRankings.position >= 101) return '> 100';
  if (positionKeywordRankings.dash) return 'No data';
  if (positionKeywordRankings.trophy) return 'Best Position';
  if (positionKeywordRankings.greenCheckMark) return 'Found';
  if (!positionKeywordRankings.position) return 'No data';
  return positionKeywordRankings.position.toString();
}

/**
 * Gets the appropriate display value for position keyword rankings in the table.
 * @param positionKeywordRankings - An object containing information about position keyword rankings.
 * @returns A React component representing the position value or a dash.
 */
function getPositionValueForKeywordRankingsTable(
  positionKeywordRankings: IPositionKeywordRankings
) {
  if (positionKeywordRankings.position >= 101) return <Dash text={`> 100`} />;
  if (positionKeywordRankings.dash)
    return (
      <Grid width={'20px'}>
        <Dash />
      </Grid>
    );
  if (positionKeywordRankings.trophy)
    return <Grid width={'20px'}>{positionKeywordRankings.position}</Grid>;
  if (positionKeywordRankings.greenCheckMark)
    return <Grid width={'20px'}>{positionKeywordRankings.position}</Grid>;
  if (!positionKeywordRankings.position)
    return (
      <Grid width={'20px'}>
        <Dash />
      </Grid>
    );
  return <Grid width={'20px'}>{positionKeywordRankings.position}</Grid>;
}

/**
 * Gets the appropriate display value for top keyword rankings in the table.
 * @param topKeywordRankings - An object containing information about top keyword rankings.
 * @param position - An object containing information about position keyword rankings.
 * @returns A React component representing the top value or a dash.
 */
function getTopValueForKeywordRankingsTable(
  topKeywordRankings: ITopKeywordRankings,
  position: IPositionKeywordRankings
) {
  if (topKeywordRankings.changes >= 101) return <Dash text={`> 100`} />;
  if (topKeywordRankings.dash) return <Dash />;
  if (
    !topKeywordRankings.changes &&
    position.position === 100 &&
    topKeywordRankings.isImproved
  )
    return <Dash />;
  if (
    !topKeywordRankings.changes &&
    position.position === 101 &&
    topKeywordRankings.isDeclined
  )
    return <Dash />;
  if (topKeywordRankings.isDeclined) return topKeywordRankings.changes;
  if (topKeywordRankings.isImproved) return topKeywordRankings.changes;
  if (!topKeywordRankings.changes) return <Dash />;
  return topKeywordRankings.changes;
}

/**
 * Adjusts the date range if both start and end dates are the same, to ensure proper display.
 * @param dateRange - The date range object containing start and end dates.
 * @returns The adjusted date range.
 */
function projectExpandedFormatterDate(dateRange: Range) {
  if (
    dateRange.startDate?.toDateString() === dateRange.endDate?.toDateString() &&
    dateRange.startDate &&
    dateRange.endDate
  ) {
    dateRange.startDate = new Date(dateRange.startDate.getTime() - 86400000);
  }

  return dateRange;
}

/**
 * Returns a display value for keyword rankings in the table based on the numeric value.
 * @param value - The numeric ranking value.
 * @returns A string representing the ranking or '> 100' if the value is 101 or more.
 */
function getKeywordRankingTableValue(value: number) {
  if (Number(value) >= 101) {
    return '> 100';
  }
  return value;
}

/**
 * Gets the best ranking value for keyword rankings in the table, with conditional display.
 * @param best - The best ranking value.
 * @returns A React component or dash element for the best value.
 */
function getBestValueForKeywordRankingsTable(best?: number) {
  if (Number(best) >= 101) {
    return <Dash />;
  }
  if (!best) {
    return <Dash />;
  }
  return best;
}

/**
 * Gets the tooltip text for the best keyword ranking based on the provided value.
 * @param best - The best ranking value.
 * @returns The tooltip text as a string.
 */
function getBestTooltipForKeywordRankingsTable(best?: number) {
  if (Number(best) >= 101) {
    return `No data`;
  }
  if (!best) {
    return 'No changes';
  }
  return best.toString();
}

/**
 * Gets the start value for keyword rankings based on the provided value.
 * @param best - The start ranking value.
 * @returns A React component displaying the start value or a dash.
 */
function getStartValueForKeywordRankingsTable(best?: number) {
  if (Number(best) >= 101) {
    return <Dash text={`> 100`} />;
  }
  if (!best) {
    return <Dash />;
  }
  return best;
}

/**
 * Gets the tooltip text for the start ranking based on the provided value.
 * @param best - The start ranking value.
 * @returns The tooltip text as a string.
 */
function getStartTooltipForKeywordRankingsTable(best?: number) {
  if (Number(best) >= 101) {
    return `> 100`;
  }
  if (!best) {
    return '-';
  }
  return best.toString();
}

/**
 * Gets the corresponding icon for the specified search engine.
 * @param name - The name of the search engine.
 * @returns The React component for the search engine icon.
 */
function getSearchEngineIcon(name: string) {
  switch (name) {
    case 'Google':
      return <GoogleIconIcon />;
    case 'Google My Business':
      return <GoogleBusinessIcon />;
    case 'Google Maps':
      return <GoogleMapsIcon />;
    case 'Bing':
      return <MicrosoftBingIcon />;
    case 'Yahoo':
      return <YahooIcon />;
    case 'Baidu':
      return <BaiduIcon />;
    case 'YouTube':
      return <YouTubeIcon />;

    default:
      return <GoogleIconIcon />;
  }
}

/**
 * Flattens a folder tree structure, including only available folders.
 * @param folder - The root folder object.
 * @returns An array of flattened folder objects.
 */
function flattenFolders(folder: IFolderTree) {
  if (!folder || typeof folder.available === 'undefined') {
    return [];
  }

  let result: IFolderTree[] = [];

  if (folder.available) {
    result.push({
      ...folder,
      children: folder.children.flatMap(flattenFolders),
    });
  } else {
    result = folder.children.flatMap(flattenFolders);
  }

  return result;
}

/**
 * Cleans a folder tree by removing unavailable folders and flattening the structure.
 * @param folderTree - The root folder tree.
 * @returns A cleaned folder tree object.
 */
function cleanFolderTree(folderTree: IFolderTree) {
  if (!folderTree || typeof folderTree.available === 'undefined') {
    return [];
  }

  const cleanedFolders = flattenFolders(folderTree);

  if (!folderTree.available) {
    return cleanedFolders;
  }

  return {
    ...folderTree,
    children: cleanedFolders,
  };
}

/**
 * Processes a folder tree, flattening the structure and including only available folders.
 * @param folder - The root folder object.
 * @returns An array of processed folder objects.
 */
function processFolderTree(folder: IFolderTree): IFolderTree[] {
  const result: IFolderTree[] = [];

  const processedChildren = folder.children.flatMap(processFolderTree);

  if (folder.available) {
    result.push({
      ...folder,
      children: processedChildren,
    });
  } else {
    result.push(...processedChildren);
  }

  return result;
}

/**
 * Downloads a CSV file based on the provided data and filename.
 * @param csvData - The CSV data as a string.
 * @param filename - The name of the file to be downloaded.
 */
function downloadCsvFile(csvData: string, filename: string) {
  const blob = new Blob([csvData], { type: 'text/csv' });

  const url = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);

  document.body.appendChild(link);
  link.click();

  link.parentNode?.removeChild(link);
  window.URL.revokeObjectURL(url);
}

/**
 * Gets error messages for competitor inputs based on the search engine type.
 * @param competitorsErrors - The errors related to competitors.
 * @param searchEngine - The name of the search engine.
 * @returns React components displaying error messages for competitors or null if no errors.
 */
function getCompetitorsErrors(competitorsErrors: any, searchEngine: string) {
  if (searchEngine !== 'Google My Business' && searchEngine !== 'Google Maps') {
    if (typeof competitorsErrors === 'string') {
      return (
        <FormHelperText error id='uniqueBusinessCompetitorErrors'>
          <CustomFormHelperText error={competitorsErrors} />
        </FormHelperText>
      );
    }
  } else {
    if (Array.isArray(competitorsErrors) && competitorsErrors.length) {
      const arrayStringErrors = competitorsErrors.map(
        item => item?.competitorUrl || ''
      );
      const uniqueCompetitorErrors = [
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...new Set(arrayStringErrors),
      ] as string[];

      return uniqueCompetitorErrors.map((item, index) => (
        <FormHelperText error id='uniqueBusinessCompetitorErrors' key={index}>
          <CustomFormHelperText error={item} />
        </FormHelperText>
      ));
    }
  }
}

export {
  getDeviceTypeIcon,
  positionFilter,
  checkObjectProperties,
  getTopIconForKeywordRankingsTable,
  getPositionIconForKeywordRankingsTable,
  getPositionTooltipForKeywordRankingsTable,
  getTopTooltipForKeywordRankingsTable,
  getPositionValueForKeywordRankingsTable,
  getTopValueForKeywordRankingsTable,
  projectExpandedFormatterDate,
  getKeywordRankingTableValue,
  getBestValueForKeywordRankingsTable,
  getBestTooltipForKeywordRankingsTable,
  getStartValueForKeywordRankingsTable,
  getStartTooltipForKeywordRankingsTable,
  getSearchEngineIcon,
  processFolderTree,
  flattenFolders,
  cleanFolderTree,
  downloadCsvFile,
  getCompetitorsErrors,
};
