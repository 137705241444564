import { styled } from '@mui/material/styles';
import {
  InputBase,
  List,
  ListItemButton,
  Popover as MuiPopover,
} from '@mui/material';

const MyAccountContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #101828;
  padding: 10px;
  cursor: pointer;
`;

const Popover = styled(MuiPopover)`
  .MuiPopover-paper {
    box-shadow: 0px 12px 16px -4px rgba(0, 0, 0, 0.1),
      0px 4px 6px -2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    background: #ffffff;
    border: 1px solid #ededed;
  }

  .MuiTypography-root {
    font-size: 14px;
  }

  .MuiListItemButton-root:hover {
    background: #f6f6f6;
    border-radius: 8px;
  }
`;

const PrimaryAccountContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  margin: 12px 0 0;
`;

const AccountListContainer = styled(PrimaryAccountContainer)`
  margin: 8px 0;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  :hover {
    border-radius: 8px;
    outline: 1px solid #126fe9;
    background: #e8f1fd;
    cursor: pointer;
  }
`;

const AccessStatus = styled('div')(
  ({
    borderColor,
    backgroundColor,
    color,
  }: {
    borderColor: string;
    backgroundColor: string;
    color: string;
  }) => ({
    width: 'fit-content',
    padding: '2px 10px',
    border: `1px solid ${borderColor}`,
    borderRadius: '16px',
    backgroundColor: backgroundColor,
    color: color,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    marginBottom: '8px',
  })
);

const SearchContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 8px 16px;

  .MuiInputLabel-root {
    width: 24px;
    height: 24px;
  }

  .MuiInputBase-root {
    color: #667085;
  }
`;

const AccountInfo = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  p {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
`;

const StyledList = styled(List)`
  padding-top: 0;
  padding-bottom: 0;
  .MuiListItemText-root {
    margin: 1.5px 0;
  }
`;

const StyledInputBase = styled(InputBase)`
  .MuiInputBase-input {
    color: #101828;
  }
  .MuiInputBase-input::placeholder {
    opacity: 1;
    color: #667085;
  }
`;

const StyledIListItemButton = styled(ListItemButton)`
  padding: 8px;
  margin: 0 8px;
`;

export {
  MyAccountContainer,
  Popover,
  PrimaryAccountContainer,
  AccessStatus,
  AccountListContainer,
  SearchContainer,
  AccountInfo,
  StyledList,
  StyledInputBase,
  StyledIListItemButton,
};
