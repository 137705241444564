import React, { memo, useMemo } from 'react';
import TextTooltip from '../../../../../../../../../../../../shared/components/TextTooltip';
import { getDeviceTypeIcon } from '../../../../../../../../../../../projects/utils';
import { CustomTooltip } from '../../../../../../../../../../../../shared/tooltip/styles';
import { Box, Grid } from '@mui/material';
import {
  popperProps,
  RenderCellProps,
} from '../../../../../../../../../../../projects/components/ProjectExpanded/components/KeywordRankings/components/KeywordRankingsTable/components/Table/components/RenderCells/useRenderCells';
import {
  getDifferenceIcon,
  getTextTooltip,
  getValue,
  NewRankTooltip,
} from '../../utils/utils';

const RenderCellKeywordName: React.FC<RenderCellProps> = params => {
  const textTooltip = useMemo(
    () => <TextTooltip text={params.row.keywordName} />,
    [params.row.keywordName]
  );

  const icon = useMemo(
    () => getDeviceTypeIcon(params.row?.deviceType?.name || ''),
    [params.row?.deviceType?.name]
  );

  return (
    <CustomTooltip
      title={textTooltip}
      placement='top-start'
      arrow
      PopperProps={popperProps}
    >
      <Grid display={'flex'} alignItems={'center'}>
        {icon}
        <Box display='flex' alignItems='center'>
          <Box marginLeft='8px'>{params.row.keywordName}</Box>
        </Box>
      </Grid>
    </CustomTooltip>
  );
};

const RenderCellPreviousPosition: React.FC<RenderCellProps> = params => {
  const textTooltip = useMemo(
    () => <TextTooltip text={getTextTooltip(params.row.previousPosition)} />,
    [params.row.previousPosition]
  );

  return (
    <CustomTooltip
      title={textTooltip}
      placement='top-start'
      arrow
      PopperProps={popperProps}
    >
      <div>{getValue(params.row.previousPosition)}</div>
    </CustomTooltip>
  );
};

const RenderCellNewRank: React.FC<RenderCellProps> = params => {
  return (
    <CustomTooltip
      title={NewRankTooltip(params.row)}
      placement='top-start'
      arrow
      PopperProps={popperProps}
    >
      <div>{getValue(params.row.currentPosition)}</div>
    </CustomTooltip>
  );
};

const RenderCellDifference: React.FC<RenderCellProps> = params => {
  const color = useMemo(
    () =>
      !params.row.difference
        ? '#101828'
        : params.row.positiveChanges
        ? '#007C65'
        : '#D1002F',
    [params.row.difference, params.row.positiveChanges]
  );

  return (
    <Box display='flex' alignItems='center'>
      <Grid container gap={'6px'} alignItems={'center'}>
        <Grid item display={'contents'}>
          {getDifferenceIcon(params.row.positiveChanges, params.row.difference)}
        </Grid>
        <Grid
          width={'20px'}
          textAlign={'center'}
          item
          sx={{
            color: color,
          }}
        >
          {getValue(params.row.difference)}
        </Grid>
      </Grid>
    </Box>
  );
};

const MemoRenderCellKeywordName = memo(RenderCellKeywordName);
const MemoRenderCellPreviousPosition = memo(RenderCellPreviousPosition);
const MemoRenderCellRenderCellNewRank = memo(RenderCellNewRank);
const MemoRenderCellDifference = memo(RenderCellDifference);

export {
  MemoRenderCellKeywordName,
  MemoRenderCellPreviousPosition,
  MemoRenderCellRenderCellNewRank,
  MemoRenderCellDifference,
};
