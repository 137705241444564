import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';

import FeaturedIcon from '../../../../../../../../shared/images/FeaturedIcon';
import PlusIcon from '../../../../../../../../shared/images/PlusIcon';
import { useProjects } from '../../../../../../../../hooks';
import { NewProjectModal } from '../../../ProjectRedactorModal';

import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../../../../shared/buttons/styles';
import { StyledCustomNoRowsOverlay, EmptyState, Content } from './styles';
import { useAppSelector } from '../../../../../../../../store';
import useAccountLimits from '../../../../../../../auth/hooks/useAccountLimits';

const CustomNoRowsOverlay = () => {
  const { onSetSearchProjects, onSetNewProjectModal } = useProjects();
  const { onCheckSubscriptionExpiration } = useAccountLimits();

  const search = useAppSelector(state => state.projects.tables.projects.search);
  const folderTree = useAppSelector(state => state.auth.myAccount.folderTree);
  const openNewProject = useAppSelector(
    state => state.projects.modals.newProject
  );

  const [isOpenNewProjectModal, setIsOpenNewProjectModal] =
    React.useState(false);

  const handleClose = () => {
    onSetSearchProjects('');
  };

  const handleOpenNewProjectModal = () => {
    const isExpiration = onCheckSubscriptionExpiration();
    if (!isExpiration) {
      onSetNewProjectModal(true);
      setIsOpenNewProjectModal(true);
    }
  };
  const handleCloseNewProjectModal = () => {
    onSetNewProjectModal(false);
    setIsOpenNewProjectModal(false);
  };

  useEffect(() => {
    if (!openNewProject && isOpenNewProjectModal) {
      setIsOpenNewProjectModal(false);
    }
  }, [openNewProject]);

  return (
    <StyledCustomNoRowsOverlay>
      <EmptyState>
        <Content>
          <FeaturedIcon />
          <Typography
            color='#101828'
            fontSize='16px'
            fontWeight='600'
            lineHeight='24px'
            paddingTop='16px'
            paddingBottom='4px'
          >
            No result found
          </Typography>
          <Typography
            color='#475467'
            fontSize='14px'
            fontWeight='400'
            lineHeight='20px'
            textAlign='center'
          >
            {`Your search “${search}” did not match any projects. Please try again or
            add a new projects`}
          </Typography>
        </Content>
        <Grid
          container
          xs={12}
          justifyContent='space-between'
          paddingTop='24px'
        >
          <Grid item xs={5.7}>
            <GrayButtonSmall
              fullWidth
              size='small'
              outline
              onClick={handleClose}
            >
              Clear Search
            </GrayButtonSmall>
          </Grid>
          <Grid item xs={5.7}>
            <PrimaryButtonSmall
              disabled={!folderTree}
              fullWidth
              variant='contained'
              size='small'
              type='submit'
              startIcon={<PlusIcon />}
              onClick={handleOpenNewProjectModal}
            >
              New Project
            </PrimaryButtonSmall>
          </Grid>
        </Grid>
      </EmptyState>
      {isOpenNewProjectModal && (
        <NewProjectModal
          open={openNewProject}
          handleClose={handleCloseNewProjectModal}
        />
      )}
    </StyledCustomNoRowsOverlay>
  );
};

export default CustomNoRowsOverlay;
