import React from 'react';
import { PaginationItem } from '@mui/material';

import PaginationChevronPrevIcon from '../../../../../../../../../../shared/images/PaginationChevronPrevIcon';
import PaginationChevronNextIcon from '../../../../../../../../../../shared/images/PaginationChevronNextIcon';

import { StyledPagination, PaginationContainer } from './styles';
import { PaginationProps } from '../../types';
import { useSharedLinks } from '../../../../../../../../../../hooks';
import { getSharedLinkToken } from '../../../../../../../../../../utils/localStorage';

const Pagination: React.FC<PaginationProps> = ({ paginationData, id }) => {
  const { onGetKeywordSearchResults } = useSharedLinks();

  const handleSetPaginationPage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    const sharedLinkToken = getSharedLinkToken();
    if (sharedLinkToken) {
      onGetKeywordSearchResults({
        link: sharedLinkToken,
        keywordId: id,
        page: value,
        limit: 10,
      });
    }
  };

  return (
    <PaginationContainer xs={12}>
      <StyledPagination
        count={Math.ceil(paginationData.totalItems / 10)}
        page={paginationData.page}
        onChange={handleSetPaginationPage}
        renderItem={item => (
          <PaginationItem
            slots={{
              previous: PaginationChevronPrevIcon,
              next: PaginationChevronNextIcon,
            }}
            {...item}
          />
        )}
      />
    </PaginationContainer>
  );
};

export default Pagination;
