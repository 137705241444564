import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';

const StyledOverview = styled(Grid)`
  margin-top: 16px;
  padding: 24px;
  padding-bottom: 34px;
  border-radius: 8px;
  border: 1px solid #dedede;
  background: #fff;
  box-shadow: 0 3px 2px -1px rgba(0, 0, 0, 0.02),
    0px 1px 1px -1px rgba(0, 0, 0, 0.04);

  @media (max-width:900px) {
    padding: 16px;
    padding-bottom: 28px;
  },
`;

const StyledHead = styled(Grid)`
  color: #020202;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (max-width:900px) {
    font-size: 16px;
  },
`;

const StyledLabel = styled(Grid)`
  color: #667085;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  @media (max-width:900px) {
    font-size: 16px;
  },
`;

const StyledItemLabel = styled(Grid)`
  color: #667085;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
`;

const StyledItemValue = styled(Grid)<{ $color?: string }>`
  color: ${props => (props.$color ? `${props.$color}` : '#101828')};
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const StyledLinearProgress = styled(LinearProgress)<{
  $backgroundColor?: string;
}>`
  background-color: ${props => `${props.$backgroundColor}`};
  border-radius: 8px;
  height: 6px;
`;

const StyledViewChartButton = styled(Grid)`
  width: 50%;
  position: absolute;
  top: 75px;
  color: #126fe9;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;

  > div {
    :first-child {
      margin-right: 8px;
    }
  }
`;

const StyledItemSubLabel = styled('span')`
  color: #84818a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const StyledItemSubValue = styled('span')<{ $color?: string }>`
  color: ${props => (props.$color ? `${props.$color}` : '#101828')};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: ruby;
`;

const StyledITooltipContainer = styled(Grid)`
  width: 260px;
  padding: 16px;
`;

const StyledStatus = styled(Grid)<{
  $backgroundColor?: string;
}>`
  background-color: ${props => `${props.$backgroundColor}`};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  align-items: center;
  margin-right: 8px;
`;

const StyledStatusLabel = styled(Grid)`
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const StyledStatusValue = styled(Grid)`
  color: #101828;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const StyledITooltipContainerHead = styled(Grid)`
  color: #667085;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 8px;
`;

export {
  StyledOverview,
  StyledHead,
  StyledLabel,
  StyledItemLabel,
  StyledItemValue,
  StyledLinearProgress,
  StyledViewChartButton,
  StyledItemSubLabel,
  StyledItemSubValue,
  StyledITooltipContainer,
  StyledStatus,
  StyledStatusLabel,
  StyledStatusValue,
  StyledITooltipContainerHead,
};
