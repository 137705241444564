import React from 'react';

interface Props {
  stroke?: string;
}

const PlusIcon: React.FC<Props> = ({ stroke }) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.99967 3.33398V12.6673M3.33301 8.00065H12.6663'
      stroke={stroke || 'white'}
      strokeWidth='1.67'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default PlusIcon;
