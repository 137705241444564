import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StyledLabel = styled(Grid)`
  color: #475467;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const StyledValue = styled(Grid)`
  color: #344054;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const StyledNoteText = styled(Grid)`
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export { StyledLabel, StyledValue, StyledNoteText };
