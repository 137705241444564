import { styled } from '@mui/system';
import { Tooltip } from '@mui/material';

const CustomTooltip = styled(Tooltip)`
  .MuiTooltip-arrow::before {
    padding: 12px !important;
    background-color: #101828 !important;
  }
`;

const DateCustomTooltip = styled(CustomTooltip)`
  border-bottom: dashed 1px #d0d5dd;
`;

const popperWhiteProps = {
  sx: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: '#fff',
      color: '#344054',
      boxShadow:
        '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
      border: '1px solid #EDEDED',
    },
    '.MuiTooltip-arrow::before': {
      backgroundColor: '#fff',
      border: '1px solid #EDEDED',
    },
  },
};

export { CustomTooltip, DateCustomTooltip, popperWhiteProps };
