import React from 'react';
import { Grid } from '@mui/material';
import FolderIcon from '../../../../../shared/images/FolderIcon';
import { StylesFoldersListItem, StylesSelectIcon } from '../styles';
import SelectIcon from '../../../../../shared/components/SelectIcon';
import { IFolderTree } from '../../../../auth/types';
import { StyledListItem } from '../../Projects/components/TableProjects/components/EllipsisMenu/styles';

interface FoldersListItemProps {
  folder: IFolderTree;
  handleSetCurrentFolder: (folder: IFolderTree) => void;
}

const FoldersListItem: React.FC<FoldersListItemProps> = ({
  handleSetCurrentFolder,
  folder,
}) => {
  return (
    <StyledListItem
      onClick={() => {
        handleSetCurrentFolder(folder);
      }}
    >
      <StylesFoldersListItem container justifyContent={'space-between'}>
        <Grid item>
          <Grid container alignItems={'center'} gap={1}>
            <FolderIcon />
            <Grid item>{folder.name}</Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems={'center'}>
            <Grid>
              <StylesSelectIcon>
                <SelectIcon />
              </StylesSelectIcon>
            </Grid>
          </Grid>
        </Grid>
      </StylesFoldersListItem>
    </StyledListItem>
  );
};

export default FoldersListItem;
