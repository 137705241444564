import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React from 'react';
import useAlerts from '../../../../../../hooks/useAlerts';
import { SelectProjectStyles } from './styles';
import { SimpleSelectIcon } from '../../../../../../../../shared/components';
import { useAppSelector } from '../../../../../../../../store';
import GlobeIcon from '../../../../../../../../shared/images/GlobeIcon';

const MenuProps = {
  sx: {
    '.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
      backgroundColor: 'red !important',
    },
    '.MuiInputBase-input-MuiOutlinedInput-input': {
      paddingTop: '4px !important',
      paddingBottom: '4px !important',
    },
    '.MuiOutlinedInput-root': {
      color: '#4D4D4D !important',
      fontSize: '14px !important',
      fontWeight: '500 !important',
      lineHeight: '24px !important',
    },
    '.MuiPaper-root': {
      borderRadius: '8px !important',
      boxShadow: 'none !important',
      border: '1px solid #d0d5dd !important',
      marginTop: '2px !important',
    },
    '.MuiMenuItem-root': {
      fontSize: '14px !important',
      fontWeight: '400 !important',
      lineHeight: '24px !important',
      border: '8px !important',
      margin: '0 8px !important',
      borderRadius: '8px !important',
    },
  },
};

interface SelectProjectProps {
  selectBy: 'by Project' | 'by Keywords';
}

/**
 * SelectProjectProps represents the properties passed to the SelectProject component.
 */
const SelectProject: React.FC<SelectProjectProps> = ({ selectBy }) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const {
    onGetProjectsByAlerts,
    onSetActiveProjectForFilterAlerts,
    projectsByAlerts,
    activeProjectForFilterAlerts,
  } = useAlerts();

  const handleChange = (e: SelectChangeEvent<number>) => {
    onSetActiveProjectForFilterAlerts(e.target.value.toString());
  };

  const handleOpen = () => {
    if (currentAccount?.accountId) {
      onGetProjectsByAlerts({
        id: currentAccount.accountId,
        selectBy,
      });
    }
  };

  return (
    <SelectProjectStyles>
      <FormControl>
        <Select
          sx={{ width: '200px' }}
          value={Number(activeProjectForFilterAlerts)}
          size='small'
          onChange={handleChange}
          MenuProps={MenuProps}
          onOpen={handleOpen}
          IconComponent={() => <SimpleSelectIcon />}
        >
          <MenuItem value={'0'}>{'All Projects'}</MenuItem>
          {projectsByAlerts.map(project => (
            <MenuItem key={project.id} value={project.id}>
              <Grid container gap={'8px'}>
                <Grid item display={'flex'} alignItems={'center'}>
                  {project?.favicon ? (
                    <img
                      src={project.favicon}
                      width={16}
                      height={16}
                      alt={'Company Logo URL'}
                    />
                  ) : (
                    <GlobeIcon />
                  )}
                </Grid>
                <Grid item>{project.projectName}</Grid>
              </Grid>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </SelectProjectStyles>
  );
};

export default SelectProject;
