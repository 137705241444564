import React from 'react';
import {
  StyledCompanyDescription,
  StyledCompanyName,
  StyledCompanyUrl,
  StyledSharedCompanyInfo,
  StyledSocialNetwork,
} from './styles';
import { Grid } from '@mui/material';
import { useAppSelector } from '../../store';
import FacebookBlackIcon from '../images/FacebookBlackIcon';
import TwitterxBlackIcon from '../images/TwitterxBlackIcon';
import LinkedinBlackIcon from '../images/LinkedinBlackIcon';

const openUrlBlank = (url: string) => {
  const fullURL = url.startsWith('http') ? url : `https://${url}`;
  window.open(fullURL, '_blank');
};

const SharedCompanyInfo = () => {
  const sharedLinkSettings = useAppSelector(
    state => state.sharedLinks.sharedLinkSettings
  );

  if (!sharedLinkSettings?.accountInfo?.sharedLinks) {
    return null;
  }

  if (
    !sharedLinkSettings?.companyLogoUrl &&
    !sharedLinkSettings?.companyName &&
    !sharedLinkSettings?.tagline &&
    !sharedLinkSettings?.companyUrl &&
    !sharedLinkSettings?.socialNetworks?.facebookLink &&
    !sharedLinkSettings?.socialNetworks?.twitterLink
  ) {
    return null;
  }

  const handleOpenCompanyUrl = () => {
    const url = sharedLinkSettings.companyUrl;
    openUrlBlank(url);
  };

  const handleOpenFacebookLink = () => {
    const url = `facebook.com/${sharedLinkSettings.socialNetworks.facebookLink}`;
    openUrlBlank(url);
  };
  const handleOpenTwitterLink = () => {
    const url = `twitter.com/${sharedLinkSettings.socialNetworks.twitterLink}`;
    openUrlBlank(url);
  };
  const handleOpenLinkedinLink = () => {
    const url = `linkedin.com/company/${sharedLinkSettings.socialNetworks.linkedinLink}`;
    openUrlBlank(url);
  };

  return (
    <StyledSharedCompanyInfo xs={12} display={'flex'} justifyContent={'center'}>
      <Grid xs={11} sm={10} md={10} lg={10} padding={'16px 0'}>
        {sharedLinkSettings?.companyLogoUrl ? (
          <Grid>
            <img
              src={sharedLinkSettings.companyLogoUrl}
              alt={'Company Logo URL'}
            />
          </Grid>
        ) : null}

        {sharedLinkSettings?.companyName ? (
          <StyledCompanyName>
            {sharedLinkSettings.companyName}
          </StyledCompanyName>
        ) : null}

        {sharedLinkSettings?.tagline ? (
          <StyledCompanyDescription>
            {sharedLinkSettings.tagline}
          </StyledCompanyDescription>
        ) : null}

        {sharedLinkSettings.companyUrl ? (
          <StyledCompanyUrl onClick={handleOpenCompanyUrl}>
            {sharedLinkSettings.companyUrl}
          </StyledCompanyUrl>
        ) : null}

        <Grid container>
          {sharedLinkSettings.socialNetworks.facebookLink ? (
            <StyledSocialNetwork item onClick={handleOpenFacebookLink}>
              <FacebookBlackIcon />
            </StyledSocialNetwork>
          ) : null}

          {sharedLinkSettings.socialNetworks.twitterLink ? (
            <StyledSocialNetwork item onClick={handleOpenTwitterLink}>
              <TwitterxBlackIcon />
            </StyledSocialNetwork>
          ) : null}

          {sharedLinkSettings.socialNetworks.linkedinLink ? (
            <StyledSocialNetwork item onClick={handleOpenLinkedinLink}>
              <LinkedinBlackIcon />
            </StyledSocialNetwork>
          ) : null}
        </Grid>
      </Grid>
    </StyledSharedCompanyInfo>
  );
};

export default SharedCompanyInfo;
