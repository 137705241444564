import React from 'react';
import { useAuth, useSharedLinks } from '../../../../../../../../hooks';
import { DEFAULT_GET_USERS_PARAMS } from '../../../../../../../auth/components/Settings/components/Team/constants';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import CustomToast from '../../../../../../../../shared/CustomToast/CustomToast';
import { closeAndUndoSnackbarAction } from '../../../../../../../../utils/snackbar';
import {
  ListItemTypography,
  StyledListItem,
} from '../../../../../../../projects/components/ProjectExpanded/components/KeywordRankings/components/KeywordRankingsTable/components/EllipsisMenu/styles';
import SmallTrashIcon from '../../../../../../../../shared/images/SmallTrashIcom';
import { DeleteSharedLinkActionProps } from './types';
import { StyledListItemButton } from '../../../../../../../../shared/buttons/styles';
import { useAppSelector } from '../../../../../../../../store';

const DeleteSharedLinkAction: React.FC<DeleteSharedLinkActionProps> = ({
  linkId,
  popupClose,
}) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const {
    onSetDeleteElementsSharedLinksPageTable,
    onBulkDeleteSharedLinks,
    onGetSharedLinks,
  } = useSharedLinks();

  const sortModel = useAppSelector(
    state => state.sharedLinks.tables.sharedLinksPage.sortModel
  );
  const search = useAppSelector(
    state => state.sharedLinks.tables.sharedLinksPage.search
  );
  const paginationModel = useAppSelector(
    state => state.sharedLinks.tables.sharedLinksPage.paginationModel
  );

  const handleHardDelete = () => {
    if (currentAccount?.accountId) {
      onBulkDeleteSharedLinks({
        accountId: currentAccount.accountId,
        ids: [linkId],
        successFn: () => {
          onGetSharedLinks({
            id: currentAccount.accountId,
            page: paginationModel.page,
            limit: paginationModel.pageSize,
            search: search || DEFAULT_GET_USERS_PARAMS.search,
            sortBy: sortModel[0]?.field,
            sortOrder: sortModel[0]?.sort,
          });
          onSetDeleteElementsSharedLinksPageTable([]);
        },
      });
    }
  };

  const handleDeleteOneKeyword = () => {
    onSetDeleteElementsSharedLinksPageTable([linkId]);

    popupClose();
    let undoDelete = false;
    const onUndo = () => {
      undoDelete = true;
      onSetDeleteElementsSharedLinksPageTable([]);
    };
    enqueueSnackbar(
      <CustomToast
        message='Shared Link Removed'
        submessage={`Shared Link has been successfully removed.`}
      />,
      {
        action: (snackbarId: SnackbarKey) =>
          closeAndUndoSnackbarAction(snackbarId, onUndo),
        variant: 'error',
      }
    );
    setTimeout(() => {
      if (!undoDelete) {
        handleHardDelete();
      }
    }, 5000);
  };

  return (
    <StyledListItem>
      <StyledListItemButton onClick={handleDeleteOneKeyword}>
        <SmallTrashIcon />
        <ListItemTypography marginLeft='8px' sx={{ color: '#B42318' }}>
          Delete Shared Link
        </ListItemTypography>
      </StyledListItemButton>
    </StyledListItem>
  );
};

export default DeleteSharedLinkAction;
