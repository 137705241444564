import { AVATAR_COLORS } from './constant';

/**
 * Determines the index path based on the provided pathname.
 * @param pathname - The pathname string.
 * @returns The index corresponding to the pathname.
 */
function getPathIndex(pathname: string) {
  switch (pathname) {
    case `/settings`:
      return 0;
    default:
      return 0;
  }
}

/**
 * Generates avatar styles for a user, including background color and text settings.
 * @param firstName - The first name of the user.
 * @param lastName - The last name of the user.
 * @param userId - The user's ID, used to determine the avatar background color.
 * @param width - The width and height of the avatar.
 * @param fs - The font size of the avatar text.
 * @returns An object containing the style properties and avatar text.
 */
function avatarStyles(
  firstName: string,
  lastName: string,
  userId: number,
  width: string,
  fs: string
) {
  return {
    sx: {
      width: width,
      height: width,
      bgcolor: AVATAR_COLORS[userId % AVATAR_COLORS.length],
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: fs,
    },
    children: `${firstName.charAt(0) || lastName.charAt(0)}`,
  };
}

/**
 * Generates styles for the "My Account" avatar, including background color and text settings.
 * @param firstName - The first name of the user.
 * @param lastName - The last name of the user.
 * @param userId - The user's ID.
 * @param width - The width and height of the avatar.
 * @param fs - The font size of the avatar text.
 * @returns An object containing the style properties and avatar text.
 */
function myAccountAvatarStyles(
  firstName: string,
  lastName: string,
  userId: number,
  width: string,
  fs: string
) {
  return {
    sx: {
      width: width,
      height: width,
      bgcolor: '#01579B',
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: fs,
    },
    children: `${firstName.charAt(0) || lastName.charAt(0)}`,
  };
}

export { getPathIndex, avatarStyles, myAccountAvatarStyles };
