import React, { useEffect, useMemo, useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Box, Grid, Skeleton } from '@mui/material';

import { EllipsisMenu } from '../components/Projects/components/TableProjects/components';
import * as Tooltips from '../components/Projects/components/TableProjects/components/Header/components/Tooltips';
import InfoIcon from '../../../shared/images/InfoIcon';

import {
  CustomTooltip,
  DateCustomTooltip,
} from '../../../shared/tooltip/styles';
import { getDeviceTypeIcon, getSearchEngineIcon } from '../utils';
import { getFlagEmoji } from '../../../utils/getCountryCode';
import TextTooltip from '../../../shared/components/TextTooltip';
import DailyAverageGraph from '../components/DailyAverageGraph/DailyAverageGraph';
import { findLongestString, getColumnWidth } from '../../../utils/table';
import { customSortComparator } from '../../emailReports/hooks/useScheduledEmailReportsTable';
import { useAppSelector } from '../../../store';
import GlobeIcon from '../../../shared/images/GlobeIcon';
import { TableHeaderStyles } from '../../../shared/stylesTable';
import { convertToTimezone } from '../../../utils/date';
import { SERVER_UTS } from '../../../constants/config';
import Dash from '../components/Dash';

export default function useTeams() {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const projects = useAppSelector(state => state.projects.projects);

  const user = useAppSelector(state => state.auth.user);

  const [showCreatedFullFormat, setShowCreatedFullFormat] =
    useState<boolean>(false);
  const [showUpdatedFullFormat, setShowUpdatedFullFormat] =
    useState<boolean>(false);

  const [projectNameWidth, setProjectNameWidth] = useState<number>(100);
  const [urlWidth, setUrlWidth] = useState<number>(100);
  const [frequencyNameWidth, setFrequencyNameWidth] = useState<number>(120);
  const [createdAtWidth, setCreatedAtWidth] = useState<number>(120);
  const [updatedAtWidth, setUpdatedAtWidth] = useState<number>(120);

  useEffect(() => {
    if (projects?.items) {
      setProjectNameWidth(
        getColumnWidth(
          findLongestString(projects?.items?.map(item => item.projectName)) ||
            ''
        )
      );
      setUrlWidth(
        getColumnWidth(
          findLongestString(projects?.items?.map(item => item.url)) || ''
        )
      );
      setFrequencyNameWidth(
        getColumnWidth(
          findLongestString(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            projects?.items?.map(item => item.frequency.name)
          ) || ''
        )
      );
      setCreatedAtWidth(
        getColumnWidth(
          findLongestString(
            projects?.items?.map(item => item.createdAtFullFormat)
          ) || ''
        )
      );
      setUpdatedAtWidth(
        getColumnWidth(
          findLongestString(
            projects?.items?.map(item => item.updatedAtFullFormat || '-')
          ) || ''
        )
      );
    }
  }, [projects?.items]);

  const tableWidth = useMemo(
    () => ({
      projectName: projects.items
        ? getColumnWidth(
            findLongestString(projects?.items?.map(item => item.projectName)) ||
              ''
          )
        : projectNameWidth,
      url: projects.items
        ? getColumnWidth(
            findLongestString(projects?.items?.map(item => item.url)) || ''
          )
        : urlWidth,
      type: 90,
      totalKeywords: 160,
      improved: 120,
      declined: 120,
      noChange: 130,
      frequency: projects.items
        ? getColumnWidth(
            findLongestString(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              projects?.items?.map(item => item.frequency.name)
            ) || ''
          )
        : frequencyNameWidth,
      createdAt: projects.items
        ? getColumnWidth(
            findLongestString(
              projects?.items?.map(item => item.createdAtFullFormat)
            ) || ''
          )
        : createdAtWidth,
      updatedAt: projects.items
        ? getColumnWidth(
            findLongestString(
              projects?.items?.map(item => item.updatedAtFullFormat || '-')
            ) || ''
          )
        : updatedAtWidth,
    }),
    [
      projects.items,
      projectNameWidth,
      urlWidth,
      frequencyNameWidth,
      createdAtWidth,
      updatedAtWidth,
    ]
  );

  const popperProps = {
    sx: {
      '& .MuiTooltip-tooltip': {
        backgroundColor: '#fff',
        color: '#344054',
        boxShadow:
          '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
        border: '1px solid #EDEDED',
      },
      '.MuiTooltip-arrow::before': {
        backgroundColor: '#fff',
        border: '1px solid #EDEDED',
      },
    },
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        sortable: true,
        headerName: 'Project',
        maxWidth: 300,
        minWidth: 100,
        width: tableWidth.projectName + 20,
        sortComparator: customSortComparator,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.projectName} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              <Grid display={'flex'} alignItems={'center'}>
                <Box
                  gap={1}
                  display='flex'
                  alignItems='center'
                  sx={{ cursor: 'pointer' }}
                >
                  {params?.row?.favicon ? (
                    <img
                      src={params.row.favicon}
                      width={16}
                      height={16}
                      alt={'Company Logo URL'}
                    />
                  ) : (
                    <GlobeIcon />
                  )}
                  {params.row.projectName}
                </Box>
              </Grid>
            </CustomTooltip>
          );
        },
      },
      {
        field: 'url',
        headerName: 'URL',
        sortable: true,
        maxWidth: 300,
        minWidth: 100,
        width: tableWidth.url,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip title={<Tooltips.URL />} placement='top-start' arrow>
              <TableHeaderStyles>
                {'URL '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.url || <Dash />} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params.row.url || <Dash />}
            </CustomTooltip>
          );
        },
      },
      {
        field: 'deviceType',
        headerName: 'Type',
        headerAlign: 'left',
        align: 'left',
        sortable: true,
        width: tableWidth.type,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Type />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Type '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          const deviceType =
            params.row.deviceType.name === 'DesktopAndMobile'
              ? 'Desktop & Mobile'
              : params.row.deviceType.name;

          const searchEngine = ` / ${params.row.searchEngine.name}`;

          const region = `${
            params?.row?.region?.name ? ` / ${params.row.region.name}` : ''
          }`;

          const tooltipTitle = `${deviceType}${searchEngine}${region}`;

          return (
            <CustomTooltip
              title={tooltipTitle}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              <Box
                gap={1}
                display='flex'
                alignItems='center'
                sx={{ cursor: 'pointer' }}
              >
                {getDeviceTypeIcon(params.row.deviceType.name)}
                {getSearchEngineIcon(params.row.searchEngine.name)}
                {params.row.region.name
                  ? getFlagEmoji(params.row.region.name)
                  : null}
              </Box>
            </CustomTooltip>
          );
        },
      },
      {
        field: 'totalKeywords',
        headerName: 'Total Keywords',
        sortable: true,
        maxWidth: 164,
        width: tableWidth.totalKeywords,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.TotalKeywords />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Total Keywords '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.totalKeywords} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params.row.totalKeywords}
            </CustomTooltip>
          );
        },
      },
      {
        field: 'improved',
        headerName: 'Improved',
        sortable: true,
        maxWidth: 150,
        width: tableWidth.improved,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Improved />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Improved '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.improved} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              <span style={{ color: '#007C65' }}>{params.row.improved}</span>
            </CustomTooltip>
          );
        },
      },
      {
        field: 'declined',
        headerName: 'Declined',
        sortable: true,
        maxWidth: 150,
        width: tableWidth.declined,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Declined />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Declined '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.declined} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              <span style={{ color: '#DD1100' }}>{params.row.declined}</span>
            </CustomTooltip>
          );
        },
      },
      {
        field: 'noChange',
        headerName: 'No Change',
        sortable: true,
        maxWidth: 150,
        width: tableWidth.noChange,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.NoChange />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'No Change '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.noChange} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params.row.noChange}
            </CustomTooltip>
          );
        },
      },
      {
        field: 'dailyAverage',
        headerName: 'Daily Average',
        sortable: false,
        minWidth: 200,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.DailyAverage />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Daily Average '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return <DailyAverageGraph dailyAverage={params.row.dailyAverage} />;
        },
      },
      {
        field: 'frequency',
        headerName: 'Frequency',
        sortable: true,
        minWidth: 130,
        width: tableWidth.frequency,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Frequency />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Frequency '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.frequency.name} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params.row.frequency.name}
            </CustomTooltip>
          );
        },
      },
      {
        field: 'created',
        headerName: 'Created',
        sortable: true,
        maxWidth: 250,
        minWidth: 150,
        width: tableWidth.createdAt,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Created />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Created '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          const date = showCreatedFullFormat
            ? convertToTimezone(
                params.row.createdAtFullFormat,
                SERVER_UTS,
                user.timezone.utc
              )
            : params.row.createdAt;
          return (
            <DateCustomTooltip
              title={<TextTooltip text={date} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
              onClick={e => {
                e.stopPropagation();
                setShowCreatedFullFormat(!showCreatedFullFormat);
              }}
            >
              {date}
            </DateCustomTooltip>
          );
        },
      },
      {
        field: 'updated',
        headerName: 'Updated',
        sortable: true,
        maxWidth: 150,
        minWidth: 150,
        width: tableWidth.updatedAt,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Updated />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Updated '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          const date = params.row.updatedAt
            ? showUpdatedFullFormat
              ? convertToTimezone(
                  params.row.updatedAtFullFormat,
                  SERVER_UTS,
                  user.timezone.utc
                )
              : params.row.updatedAt
            : '-';
          return (
            <DateCustomTooltip
              title={<TextTooltip text={date} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
              onClick={e => {
                e.stopPropagation();
                setShowUpdatedFullFormat(!showUpdatedFullFormat);
              }}
            >
              <div>{date}</div>
            </DateCustomTooltip>
          );
        },
      },

      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 50,
        align: 'center',
        renderCell: (params: GridRenderCellParams) =>
          currentAccount?.role?.name !== 'ViewOnly' && (
            <div>
              <EllipsisMenu project={params.row} />
            </div>
          ),
      },
    ],
    [
      currentAccount?.role?.name,
      tableWidth,
      showCreatedFullFormat,
      showUpdatedFullFormat,
      user?.timezone,
    ]
  );

  const columnsSkeleton: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        sortable: true,
        headerName: 'Project',
        maxWidth: 300,
        minWidth: 100,
        width: tableWidth.projectName + 30,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'url',
        headerName: 'URL',
        sortable: true,
        maxWidth: 300,
        minWidth: 100,
        width: tableWidth.url,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'deviceType',
        headerName: 'Type',
        headerAlign: 'right',
        align: 'right',
        sortable: true,
        width: tableWidth.type,
        minWidth: 90,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'totalKeywords',
        headerName: 'Total Keywords',
        sortable: true,
        minWidth: 160,
        maxWidth: 180,
        width: tableWidth.totalKeywords,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'improved',
        headerName: 'Improved',
        sortable: true,
        maxWidth: 140,
        minWidth: 120,
        width: tableWidth.improved,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'declined',
        headerName: 'Declined',
        sortable: true,
        maxWidth: 140,
        minWidth: 120,
        width: tableWidth.declined,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'noChange',
        headerName: 'No Change',
        sortable: true,
        maxWidth: 140,
        minWidth: 130,
        width: tableWidth.noChange,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'dailyAverage',
        headerName: 'Daily Average',
        sortable: false,
        minWidth: 200,
        maxWidth: 220,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'frequency',
        headerName: 'Frequency',
        sortable: true,
        minWidth: 130,
        width: tableWidth.frequency,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'created',
        headerName: 'Created',
        sortable: true,
        maxWidth: 250,
        minWidth: 150,
        width: tableWidth.createdAt,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'updated',
        headerName: 'Updated',
        sortable: true,
        maxWidth: 150,
        minWidth: 150,
        width: tableWidth.updatedAt,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 50,
        align: 'center',
        renderCell: () => <Skeleton width='100%' />,
      },
    ],
    [tableWidth]
  );

  return {
    columns,
    columnsSkeleton,
  };
}
