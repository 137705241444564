import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useState } from 'react';
import { CustomTooltip } from '../../../shared/tooltip/styles';
import TextTooltip from '../../../shared/components/TextTooltip';
import EllipsisMenu from '../components/Triggers/components/TriggersModal/components/TriggersTable/components/EllipsisMenu/EllipsisMenu';
import * as Tooltips from '../components/Alerts/components/Tooltips';
import InfoIcon from '../../../shared/images/InfoIcon';
import { KeywordTriggerView } from '../components/Triggers/components/TriggersModal/components/TriggersTable/components';
import useAlerts from './useAlerts';
import { findLongestString, getColumnWidth } from '../../../utils/table';
import { customSortComparator } from '../../emailReports/hooks/useScheduledEmailReportsTable';
import { Skeleton } from '@mui/material';
import { useAppSelector } from '../../../store';
import { TableHeaderStyles } from '../../../shared/stylesTable';

export default function useTriggersTable() {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const isAuth = useAppSelector(state => state.auth.isAuth);

  const {
    triggers: { byProjects, byKeywords },
  } = useAlerts();

  const popperProps = {
    sx: {
      '& .MuiTooltip-tooltip': {
        backgroundColor: '#fff',
        color: '#344054',
        boxShadow:
          '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
        border: '1px solid #EDEDED',
      },
      '.MuiTooltip-arrow::before': {
        backgroundColor: '#fff',
        border: '1px solid #EDEDED',
      },
    },
  };

  const [projectNameWidth, setProjectNameWidth] = useState<number>(130);

  useEffect(() => {
    if (byProjects?.items) {
      setProjectNameWidth(
        getColumnWidth(
          findLongestString(byProjects?.items?.map(item => item.projectName)) ||
            ''
        )
      );
    }
  }, [byProjects?.items]);

  const isViewOnly = useMemo(
    () => !isAuth || (isAuth && currentAccount?.role?.name === 'ViewOnly'),
    [currentAccount?.role?.name, isAuth]
  );

  const triggersByProjectTableWidth = useMemo(
    () => ({
      keywordName: byProjects?.items
        ? getColumnWidth(
            findLongestString(
              byProjects?.items?.map(item => item.projectName)
            ) || ''
          )
        : projectNameWidth,
    }),
    [byProjects?.items, projectNameWidth]
  );

  const triggersByKeywordsTableWidth = useMemo(
    () => ({
      keywordName: byKeywords?.items
        ? getColumnWidth(
            findLongestString(
              byKeywords?.items?.map(item => item.projectName)
            ) || ''
          )
        : 130,
    }),
    [byKeywords?.items]
  );

  const triggersByProjectColumns: GridColDef[] = useMemo(
    () => [
      {
        field: 'projectName',
        sortable: true,
        headerName: 'Project',
        maxWidth: 300,
        minWidth: 130,
        width: triggersByProjectTableWidth.keywordName,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Project />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Project '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.projectName} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params.row.projectName}
            </CustomTooltip>
          );
        },
      },
      {
        field: 'rule',
        sortable: true,
        headerName: 'Rule',
        maxWidth: 180,
        minWidth: 180,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Rule />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Rule '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params?.row?.rule?.name} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params?.row?.rule?.name}
            </CustomTooltip>
          );
        },
      },
      {
        field: 'threshold',
        sortable: true,
        headerName: 'Threshold',
        maxWidth: 130,
        minWidth: 130,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Project />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Threshold '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.threshold} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params.row.threshold}
            </CustomTooltip>
          );
        },
      },
      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 50,
        align: 'center',
        renderCell: (params: GridRenderCellParams) =>
          !isViewOnly ? (
            <div>
              <EllipsisMenu id={params.row.id} selectBy={'by Project'} />
            </div>
          ) : null,
      },
    ],
    [triggersByProjectTableWidth, isViewOnly]
  );

  const triggersByProjectColumnsSkeleton: GridColDef[] = useMemo(
    () => [
      {
        field: 'projectName',
        sortable: true,
        headerName: 'Project',
        maxWidth: 300,
        minWidth: 130,
        width: triggersByProjectTableWidth.keywordName,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'rule',
        sortable: true,
        headerName: 'Rule',
        maxWidth: 180,
        minWidth: 180,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'threshold',
        sortable: true,
        headerName: 'Threshold',
        maxWidth: 130,
        minWidth: 130,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 50,
        align: 'center',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
    ],
    [triggersByProjectTableWidth]
  );

  const triggersByKeywordsColumns: GridColDef[] = useMemo(
    () => [
      {
        field: 'projectName',
        sortable: true,
        headerName: 'Project',
        maxWidth: 300,
        minWidth: 130,
        width: triggersByKeywordsTableWidth.keywordName,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Project />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Project '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.projectName} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params.row.projectName}
            </CustomTooltip>
          );
        },
      },
      {
        field: 'keywordCount',
        sortable: false,
        headerName: 'No. of Keywords',
        maxWidth: 130,
        minWidth: 130,
        align: 'right',
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.NumberOfKeywords />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'No. of Keywords '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <KeywordTriggerView
              keywordCount={params.row.keywordCount}
              triggerId={params.row.id}
            />
          );
        },
      },
      {
        field: 'rule',
        sortable: true,
        headerName: 'Rule',
        maxWidth: 180,
        minWidth: 180,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Rule />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Rule '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params?.row?.rule?.name} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params?.row?.rule?.name}
            </CustomTooltip>
          );
        },
      },
      {
        field: 'threshold',
        sortable: true,
        headerName: 'Threshold',
        maxWidth: 130,
        minWidth: 130,
        sortComparator: customSortComparator,
        renderHeader: () => {
          return (
            <CustomTooltip
              title={<Tooltips.Project />}
              placement='top-start'
              arrow
            >
              <TableHeaderStyles>
                {'Threshold '}
                <span role='img' aria-label='enjoy'>
                  <InfoIcon />
                </span>
              </TableHeaderStyles>
            </CustomTooltip>
          );
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CustomTooltip
              title={<TextTooltip text={params.row.threshold} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
            >
              {params.row.threshold}
            </CustomTooltip>
          );
        },
      },
      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 50,
        align: 'center',
        renderCell: (params: GridRenderCellParams) =>
          !isViewOnly ? (
            <div>
              <EllipsisMenu id={params.row.id} selectBy={'by Keywords'} />
            </div>
          ) : null,
      },
    ],
    [triggersByKeywordsTableWidth, isViewOnly]
  );

  const triggersByKeywordsColumnsSkeleton: GridColDef[] = useMemo(
    () => [
      {
        field: 'projectName',
        sortable: true,
        headerName: 'Project',
        maxWidth: 300,
        minWidth: 130,
        width: triggersByKeywordsTableWidth.keywordName,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'keywordCount',
        sortable: false,
        headerName: 'No. of Keywords',
        maxWidth: 130,
        minWidth: 130,
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'rule',
        sortable: true,
        headerName: 'Rule',
        maxWidth: 180,
        minWidth: 180,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'threshold',
        sortable: true,
        headerName: 'Threshold',
        maxWidth: 130,
        minWidth: 130,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 50,
        align: 'center',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
    ],
    [triggersByKeywordsTableWidth]
  );

  return {
    triggersByProjectColumns,
    triggersByKeywordsColumns,
    triggersByProjectColumnsSkeleton,
    triggersByKeywordsColumnsSkeleton,
  };
}
