import React from 'react';
import { IAlertByKeywords } from '../../../types/types';
import { Grid } from '@mui/material';
import {
  LabelStyles,
  LineStyles,
  ValueStyles,
} from '../../../../../shared/components/PositionInfoTooltip/styles';
import { getKeywordRankingTableValue } from '../../../../projects/utils';
import UpGreenIcon from '../../../../../shared/images/UpGreenIcon';
import DownRedIcon from '../../../../../shared/images/DownRedIcon';
import Dash from '../../../../projects/components/Dash';

/**
 * React functional component that renders a tooltip displaying information about keyword ranking changes.
 */
const AlertByKeywordNewRankTooltip: React.FC<
  IAlertByKeywords
> = alertKeyword => {
  if (!alertKeyword.difference) {
    return <Grid>No changes</Grid>;
  }

  return (
    <Grid>
      <LineStyles container justifyContent={'space-between'} gap={'8px'}>
        <LabelStyles item>{`Position on ${
          alertKeyword.previousDate || ''
        }:`}</LabelStyles>
        <ValueStyles item>
          {getKeywordRankingTableValue(alertKeyword.previousRank)}
        </ValueStyles>
      </LineStyles>
      <LineStyles container justifyContent={'space-between'} gap={'8px'}>
        <LabelStyles item>{`Position on ${
          alertKeyword.newDate || ''
        }:`}</LabelStyles>
        <ValueStyles item>
          {getKeywordRankingTableValue(alertKeyword.newRank)}
        </ValueStyles>
      </LineStyles>
      <LineStyles container justifyContent={'space-between'} gap={'8px'}>
        <LabelStyles item>Difference:</LabelStyles>
        <Grid item>
          <Grid container gap={'4px'} alignItems={'center'}>
            <Grid item display={'flex'} alignItems={'center'}>
              {alertKeyword.positiveChanges ? <UpGreenIcon /> : <DownRedIcon />}
            </Grid>

            <ValueStyles
              $color={
                alertKeyword.difference
                  ? alertKeyword.positiveChanges
                    ? '#007C65'
                    : '#D1002F'
                  : undefined
              }
              item
            >
              {!alertKeyword.difference || alertKeyword.difference > 100 ? (
                <Dash />
              ) : (
                getKeywordRankingTableValue(alertKeyword.difference)
              )}
            </ValueStyles>
          </Grid>
        </Grid>
      </LineStyles>
    </Grid>
  );
};

export { AlertByKeywordNewRankTooltip };
