import React from 'react';

const FeaturedIcon = () => {
  return (
    <svg
      width='52'
      height='52'
      viewBox='0 0 52 52'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_3401_117834)'>
        <rect x='2' y='1' width='48' height='48' rx='10' fill='white' />
        <path
          d='M35 34L31.5001 30.5M34 24.5C34 29.1944 30.1944 33 25.5 33C20.8056 33 17 29.1944 17 24.5C17 19.8056 20.8056 16 25.5 16C30.1944 16 34 19.8056 34 24.5Z'
          stroke='#344054'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <rect
          x='2.5'
          y='1.5'
          width='47'
          height='47'
          rx='9.5'
          stroke='#EAECF0'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_3401_117834'
          x='0'
          y='0'
          width='52'
          height='52'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_3401_117834'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_3401_117834'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};

export default FeaturedIcon;
