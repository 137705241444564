import { Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import pageNotFoundImage from '../../images/page-not-found-image.webp';
import { StylesHeading, StylesSupportingText } from './styles';
import { PrimaryButton } from '../../buttons/styles';
import { useNavigate } from 'react-router';
import ROUTES from '../../../routes/constants';

const NotFoundBlock = () => {
  const navigate = useNavigate();
  const mediumScreen = useMediaQuery('(max-width:900px)');

  const handleGoToHomepage = () => {
    navigate(ROUTES.projects);
  };

  return (
    <Grid
      xs={12}
      container
      align-items='center'
      height='calc(100vh - 380px)'
      margin='120px 24px'
    >
      <Grid
        xs={12}
        container
        item
        gap='48px'
        justifyContent='center'
        height='fit-content'
      >
        <Grid item width={mediumScreen ? '100%' : undefined}>
          <img
            width={mediumScreen ? '152px' : '350px'}
            src={pageNotFoundImage}
            alt={'NotFoundImage'}
          />
        </Grid>
        <Grid
          display={'flex'}
          item
          justifyContent={'center'}
          flexDirection={'column'}
        >
          <StylesHeading item>We can’t find this page</StylesHeading>
          <StylesSupportingText item paddingTop='16px' paddingBottom='48px'>
            The page you are looking for does&apos;t exist or has been moved.
          </StylesSupportingText>
          <Grid item>
            <PrimaryButton
              onClick={handleGoToHomepage}
              variant='contained'
              color='primary'
            >
              Go to homepage
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NotFoundBlock;
