import { styled } from '@mui/system';
import { Box } from '@mui/material';

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`;

export { Wrapper };
