import React, { useEffect, useMemo } from 'react';
import {
  clearStorage,
  getSharedLinkToken,
  STORAGE_KEYS,
} from '../../../../../../utils/localStorage';
import ROUTES from '../../../../../../routes/constants';
import { getUpdatedSharedLinkPasswordToServer } from '../../../../socket';
import { useNavigate, useParams } from 'react-router';
import { useSharedLinks } from '../../../../../../hooks';
import { Paper } from '../../../../../../shared/paper/styles';
import { Grid } from '@mui/material';
import { Wrapper } from '../../../sharedLinkContents/styles';
import ReportValidated from '../../../../../../shared/ReportValidated/ReportValidated';
import SharedCompanyInfo from '../../../../../../shared/SharedCompanyInfo/SharedCompanyInfo';
import Footer from '../../../../../../shared/layout/Footer/Footer';
import KeywordInfo from './components/KeywordInfo/KeywordInfo';
import {
  StyledBreadcrumbsAdditional,
  WrapContainer,
} from '../../../../../projects/components/KeywordRankHistory/styles';
import { useAppSelector } from '../../../../../../store';
import { decryptData } from '../../../../../../utils/cryptoJs';
import useSharedKeywordRankHistory from '../../../../hooks/useKeywordRankHistory';
import Graph from './components/Graph/Graph';
import BreadcrumbsSharedAdditional from './components/BreadcrumbsSharedAdditional/BreadcrumbsSharedAdditional';
import GraphLabels from './components/GraphLabels/GraphLabels';
import KeywordPositionsInfo from './components/KeywordPositionsInfo/KeywordPositionsInfo';

const SharedKeywordRankHistory = () => {
  const navigate = useNavigate();
  const { projectId, keywordId } = useParams();

  const {
    onSetIsPasswordUpdated,
    onGetSharedProject,
    ongGetSharedLinkSettings,
  } = useSharedLinks();

  const {
    onGetSharedKeywordInfo,
    onGetSharedSingleKeywordPositionHistory,
    onGetSharedKeywordPositionsInfo,
  } = useSharedKeywordRankHistory();

  const projectSharedDeviceType = useAppSelector(
    state => state.sharedLinks.projectSharedDeviceType
  );
  const isPasswordUpdated = useAppSelector(
    state => state.sharedLinks.isPasswordUpdated
  );
  const keywordRankHistory = useAppSelector(
    state => state.sharedLinks.keywordRankHistory
  );

  const decryptProjectId = useMemo(() => {
    if (projectId) {
      return decryptData(projectId);
    }
  }, [projectId]);

  const decryptKeywordId = useMemo(() => {
    if (keywordId) {
      return decryptData(keywordId);
    }
  }, [keywordId]);

  useEffect(() => {
    const sharedLinkToken = getSharedLinkToken();
    if (decryptProjectId && sharedLinkToken) {
      onGetSharedProject({
        link: sharedLinkToken,
        projectId: Number(decryptProjectId),
        deviceType: projectSharedDeviceType,
      });
    }
  }, [
    onGetSharedProject,
    decryptProjectId,
    projectSharedDeviceType,
    isPasswordUpdated,
  ]);

  useEffect(() => {
    const sharedLinkToken = getSharedLinkToken();
    if (decryptProjectId && decryptKeywordId && sharedLinkToken) {
      onGetSharedKeywordInfo({
        link: sharedLinkToken,
        keywordId: Number(decryptKeywordId),
      });
    }
  }, [decryptProjectId, decryptKeywordId, onGetSharedKeywordInfo]);

  useEffect(() => {
    const sharedLinkToken = getSharedLinkToken();
    if (!projectId || !keywordId || !sharedLinkToken) {
      navigate(ROUTES.login);
    }

    getUpdatedSharedLinkPasswordToServer(data => {
      if (sharedLinkToken === data.sharedLink) {
        clearStorage(STORAGE_KEYS.sharedLinkAccessToken);
        onSetIsPasswordUpdated(true);
      }
    });
  }, []);

  useEffect(() => {
    const sharedLinkToken = getSharedLinkToken();
    if (
      decryptProjectId &&
      decryptKeywordId &&
      sharedLinkToken &&
      keywordRankHistory?.keywordInfo?.id
    ) {
      onGetSharedSingleKeywordPositionHistory({
        link: sharedLinkToken,
        keywordId: Number(decryptKeywordId),
        projectId: Number(decryptProjectId),
        period: keywordRankHistory?.activePeriod?.value,
        competitorIds: keywordRankHistory?.showCompetitors,
      });
    }
  }, [
    onGetSharedSingleKeywordPositionHistory,
    decryptProjectId,
    decryptKeywordId,
    keywordRankHistory?.keywordInfo?.id,
    keywordRankHistory?.activePeriod?.value,
    keywordRankHistory?.showCompetitors,
  ]);

  useEffect(() => {
    const sharedLinkToken = getSharedLinkToken();
    if (sharedLinkToken && decryptKeywordId) {
      onGetSharedKeywordPositionsInfo({
        link: sharedLinkToken,
        keywordId: Number(decryptKeywordId),
        page: keywordRankHistory.tableState.paginationModel.page + 1,
        limit: keywordRankHistory.tableState.paginationModel.pageSize,
        sortBy: keywordRankHistory.tableState?.sortModel[0]?.field,
        sortOrder: keywordRankHistory.tableState?.sortModel[0]?.sort,
      });
    }
  }, [
    decryptKeywordId,
    onGetSharedKeywordPositionsInfo,
    keywordRankHistory.tableState.paginationModel.page,
    keywordRankHistory.tableState.paginationModel.pageSize,
    keywordRankHistory.tableState?.sortModel,
  ]);

  useEffect(() => {
    const sharedLinkToken = getSharedLinkToken();
    if (sharedLinkToken) {
      ongGetSharedLinkSettings({
        link: sharedLinkToken,
      });
    }
  }, []);

  return (
    <Wrapper>
      <Grid
        container
        xs={12}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'center'}
      >
        <ReportValidated />
        <SharedCompanyInfo />
        <Grid
          xs={11}
          sm={10}
          md={10}
          lg={10}
          overflow={'clip'}
          marginTop={'24px'}
        >
          <Paper>
            <StyledBreadcrumbsAdditional item xs={12}>
              <BreadcrumbsSharedAdditional />
            </StyledBreadcrumbsAdditional>
            <WrapContainer item xs={12}>
              <KeywordInfo />
            </WrapContainer>
            <WrapContainer item xs={12}>
              <Graph />
              <GraphLabels />
            </WrapContainer>
            <KeywordPositionsInfo />
          </Paper>
        </Grid>
        <Footer />
      </Grid>
    </Wrapper>
  );
};

export default SharedKeywordRankHistory;
