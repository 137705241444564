import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce, Grid, InputAdornment, Typography } from '@mui/material';
import { AlertsSwitch, PopupTableSettings } from './components';
import SearchIcon from '../../../../../../shared/images/SearchIcon';
import {
  GrayButtonCustom,
  PrimaryButtonCustom,
} from '../../../../../../shared/buttons/styles';
import TriggersModal from '../../../Triggers/components/TriggersModal/TriggersModal';
import AddNewAlert from './components/AddNewAlert/AddNewAlert';
import PlusIcon from '../../../../../../shared/images/PlusIcon';
import useAlerts from '../../../../hooks/useAlerts';
import SelectProject from './components/SelectProject/SelectProject';
import { OutlinedInputCustom } from '../../../../../../shared/inputs/styled';
import { useAppSelector } from '../../../../../../store';
import useAccountLimits from '../../../../../auth/hooks/useAccountLimits';

interface HeaderProps {
  selectBy: 'by Project' | 'by Keywords';
  setSelectBy: React.Dispatch<
    React.SetStateAction<'by Project' | 'by Keywords'>
  >;
}

/**
 * Header component for managing alert views, filters, and actions.
 */
const Header: React.FC<HeaderProps> = ({ setSelectBy, selectBy }) => {
  const { onCheckSubscriptionExpiration } = useAccountLimits();

  const openSiteBar = useAppSelector(state => state.auth.openSiteBar);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const selectByAlerts = useAppSelector(
    state => state.alerts.tables.alerts.selectByAlerts
  );
  const isAuth = useAppSelector(state => state.auth.isAuth);

  const isViewOnly = useMemo(
    () => !isAuth || (isAuth && currentAccount?.role?.name === 'ViewOnly'),
    [currentAccount?.role?.name, isAuth]
  );

  const refHeader = React.useRef<HTMLDivElement>(null);

  const [isDesktopHeader, setIsDesktopHeader] = useState(true);

  useEffect(() => {
    if (refHeader?.current?.clientWidth) {
      if (refHeader?.current?.clientWidth < 1159) {
        setIsDesktopHeader(true);
      } else {
        setIsDesktopHeader(false);
      }
    }
  }, [openSiteBar]);

  const {
    onSetSearchAlerts,
    onGetAlertsByProject,
    onGetAlertsByKeywords,
    activeProjectForFilterAlerts,
    tables: { alerts },
  } = useAlerts();

  const [localSearch, setLocalSearch] = useState('');
  const [isOpenEditTriggersModal, setOpenEditTriggersModal] = useState(false);
  const [isOpenAddAlertModal, setOpenAddAlertModal] = useState(false);

  const debounceSearch = useCallback(
    debounce(value => {
      onSetSearchAlerts(value);
    }, 500),
    []
  );

  const handleSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLocalSearch(event.target.value);
    debounceSearch(event.target.value);
  };

  const onOpenEditTriggersModal = () => {
    setOpenEditTriggersModal(true);
  };

  const onCloseEditTriggersModal = () => {
    setOpenEditTriggersModal(false);
    if (selectByAlerts === 'by Project') {
      onGetAlertsByProject({
        id: currentAccount.accountId,
        projectIds: activeProjectForFilterAlerts
          ? [activeProjectForFilterAlerts]
          : [],
        page: alerts.paginationModel.page,
        limit: alerts.paginationModel.pageSize,
        search: alerts.search,
        sortBy: alerts.sortModel[0]?.field,
        sortOrder: alerts.sortModel[0]?.sort,
        isChangeType: false,
      });
    } else {
      onGetAlertsByKeywords({
        id: currentAccount.accountId,
        projectIds: activeProjectForFilterAlerts
          ? [activeProjectForFilterAlerts]
          : [],
        page: alerts.paginationModel.page,
        limit: alerts.paginationModel.pageSize,
        search: alerts.search,
        sortBy: alerts.sortModel[0]?.field,
        sortOrder: alerts.sortModel[0]?.sort,
      });
    }
  };

  const onOpenAddAlertModal = () => {
    const isExpiration = onCheckSubscriptionExpiration();
    if (!isExpiration) {
      setOpenAddAlertModal(true);
    }
  };

  const onCloseAddAlertModal = () => {
    setOpenAddAlertModal(false);
  };

  return (
    <>
      <Grid xs={12} ref={refHeader}>
        <Typography
          sx={{ pt: 1, pb: '32px' }}
          fontWeight='600'
          color='#101828'
          fontSize='28px'
        >
          Alerts
        </Typography>
        <Grid
          container
          xs={12}
          gap={!isDesktopHeader ? 2 : 4}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Grid item>
            <AlertsSwitch selectBy={selectBy} setSelectBy={setSelectBy} />
          </Grid>

          <Grid item xs={!isDesktopHeader && 12} sx={{ width: '100%' }}>
            <Grid container gap={1}>
              <Grid
                item
                style={!isDesktopHeader ? { flex: '1' } : undefined}
                sx={{ flex: '1' }}
              >
                <OutlinedInputCustom
                  fullWidth
                  $padding={'7.94px 14px 7.94px 0px'}
                  size='small'
                  type='text'
                  id='search'
                  name='search'
                  placeholder='Search'
                  value={localSearch}
                  onChange={handleSearch}
                  startAdornment={
                    <InputAdornment position='start' disablePointerEvents>
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item>
                <SelectProject selectBy={selectBy} />
              </Grid>
              <Grid item>
                <PopupTableSettings selectBy={selectBy} />
              </Grid>
              <Grid item>
                <GrayButtonCustom
                  $padding='7px 12px'
                  size='small'
                  outline
                  onClick={onOpenEditTriggersModal}
                >
                  Edit Triggers
                </GrayButtonCustom>
              </Grid>
              {!isViewOnly && (
                <Grid item>
                  <PrimaryButtonCustom
                    variant='contained'
                    size='small'
                    $padding='8px 12px'
                    startIcon={<PlusIcon />}
                    onClick={onOpenAddAlertModal}
                  >
                    Add New Trigger
                  </PrimaryButtonCustom>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <TriggersModal
        handleClose={onCloseEditTriggersModal}
        open={isOpenEditTriggersModal}
      />
      <AddNewAlert
        type={selectBy}
        handleClose={onCloseAddAlertModal}
        open={isOpenAddAlertModal}
      />
    </>
  );
};

export default Header;
