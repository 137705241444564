import React, { useEffect } from 'react';

import ClockIcon from '../../../../../../../../../shared/images/ClockIcon';
import { EditFrequencyActionProps } from './types';

import { ListItemTypography, StyledListItem } from '../styles';
import { useFolders } from '../../../../../../../../../hooks';
import useProjects from '../../../../../../../hooks/useProjects';
import EditFrequencyModal from '../../../../ProjectRedactorModal/EditFrequencyModal/EditFrequencyModal';
import { useFormik } from 'formik';
import { CheckFrequency } from '../../../../../../../types';
import OverrideFrequencyForMultipleProjects from '../../../../ProjectRedactorModal/EditFrequencyModal/OverrideFrequencyForMultipleProjects';
import { StyledListItemButton } from '../../EllipsisMenu/styles';
import { useAppSelector } from '../../../../../../../../../store';

const EditFrequencyAction: React.FC<EditFrequencyActionProps> = ({
  projectIds,
  popupClose,
}) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const {
    onChangeFrequencyForProjects,
    onGetProjects,
    onGetCheckFrequency,
    onSetRowSelectionModel,
  } = useProjects();

  const { onSetRowSelectionModel: onSetRowSelectionModelFolders } =
    useFolders();

  const columnVisibilityModel = useAppSelector(
    state => state.projects.tables.projects.columnVisibilityModel
  );
  const frequencyFilter = useAppSelector(
    state => state.projects.tables.projects.frequencyFilter
  );
  const rowSelectionModel = useAppSelector(
    state => state.projects.tables.projects.rowSelectionModel
  );
  const sortModel = useAppSelector(
    state => state.projects.tables.projects.sortModel
  );
  const search = useAppSelector(state => state.projects.tables.projects.search);
  const paginationModel = useAppSelector(
    state => state.projects.tables.projects.paginationModel
  );
  const checkFrequency = useAppSelector(state => state.projects.checkFrequency);
  const selectedTags = useAppSelector(state => state.projects.selectedTags);

  const [isOpenEditFrequencyModal, setIsOpenEditFrequencyModal] =
    React.useState(false);
  const [
    isOverrideFrequencyForMultipleProjects,
    setIsOverrideFrequencyForMultipleProjects,
  ] = React.useState(false);

  const handleClose = () => {
    onSetRowSelectionModel([]);
    onSetRowSelectionModelFolders([]);
    popupClose();
    setIsOpenEditFrequencyModal(false);
    formik.resetForm();
  };

  const handleCloseWarningModal = () => {
    setIsOverrideFrequencyForMultipleProjects(false);
  };

  const formik = useFormik({
    initialValues: {
      checkFrequency: checkFrequency[0] as CheckFrequency,
    },
    enableReinitialize: true,
    onSubmit: values => {
      onChangeFrequencyForProjects({
        accountId: currentAccount.accountId,
        projectIds: projectIds ? projectIds : rowSelectionModel,
        frequency: values.checkFrequency.name,
        successFn: () => {
          handleCloseWarningModal();
          handleClose();
          onGetProjects({
            accountId: currentAccount.accountId,
            params: {
              frequencyName: frequencyFilter,
              page: paginationModel.page,
              limit: paginationModel.pageSize,
              tagIds: selectedTags.map(item => item.id).join(),
              search: search,
              sortBy: sortModel[0].field,
              sortOrder: sortModel[0].sort,
              dailyAverage: columnVisibilityModel.dailyAverage,
            },
          });
        },
      });
    },
  });

  const onClick = () => {
    setIsOpenEditFrequencyModal(true);
  };

  const handleSubmitEditFrequencyModal = () => {
    setIsOverrideFrequencyForMultipleProjects(true);
  };

  const handleOpenWarningModal = () => {
    formik.handleSubmit();
  };

  useEffect(() => {
    if (!checkFrequency.length) {
      onGetCheckFrequency();
    }
  }, []);

  return (
    <>
      <StyledListItem onClick={onClick}>
        <StyledListItemButton>
          <ClockIcon />
          <ListItemTypography marginLeft='8px'>
            Edit Frequency
          </ListItemTypography>
        </StyledListItemButton>
      </StyledListItem>
      <EditFrequencyModal
        open={isOpenEditFrequencyModal}
        handleClose={handleClose}
        handleSubmit={handleSubmitEditFrequencyModal}
        formik={formik}
      />
      <OverrideFrequencyForMultipleProjects
        open={isOverrideFrequencyForMultipleProjects}
        handleClose={handleCloseWarningModal}
        handleSubmit={handleOpenWarningModal}
      />
    </>
  );
};

export default EditFrequencyAction;
