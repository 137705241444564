import { Grid } from '@mui/material';
import React from 'react';

import { Folders } from '../features/folders/components';

const FoldersPage = () => {
  return (
    <Grid container xs={12}>
      <Grid item xs={12}>
        <Folders />
      </Grid>
    </Grid>
  );
};

export default FoldersPage;
