import React from 'react';
import { ListActionsProps } from './types';
import { MainContainer } from '../../../../../../../projects/components/ProjectExpanded/components/KeywordRankings/components/KeywordRankingsTable/components/EllipsisMenu/styles';
import { Divider, List } from '@mui/material';
import DeleteSharedLinkAction from './DeleteSharedLinkAction';
import CopySharedLinkAction from './CopySharedLinkAction';
import OpenInNewTabAction from './OpenInNewTabAction';
import EditSharedLinkAction from './EditSharedLinkAction';

const ListActions: React.FC<ListActionsProps> = ({
  linkId,
  link,
  popupClose,
}) => {
  return (
    <MainContainer>
      <List>
        <OpenInNewTabAction link={link} popupClose={popupClose} />
        <CopySharedLinkAction link={link} popupClose={popupClose} />
        <EditSharedLinkAction linkId={linkId} popupClose={popupClose} />

        <Divider
          orientation='horizontal'
          flexItem
          sx={{
            margin: '8px 0',
            color: '#EDEDED',
          }}
        />

        <DeleteSharedLinkAction linkId={linkId} popupClose={popupClose} />
      </List>
    </MainContainer>
  );
};

export default ListActions;
