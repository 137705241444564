import { useCallback, useMemo } from 'react';

import { useAppSelector, useAppDispatch } from '../../../store';

import {
  createFolderThunk,
  deletionOfFolderContentsThunk,
  getAvailableFoldersThunk,
  getFoldersByIdThunk,
  getFoldersThunk,
  getRootFoldersThunk,
  renameFolderThunk,
} from '../redux/thunk';
import { setDefaultCurrentFolder, setCurrentFolder } from '../redux/slice';

import {
  setRootColumnVisibilityModel,
  setColumnVisibilityModel,
  resetColumnVisibilityModel,
  setPaginationPage,
  setPaginationPageSize,
  setSearchFolders,
  setRowSelectionModel,
  setDeleteElementsRootFoldersTable,
  setSortModelRootFolder,
  setSortModelNestedElements,
  updateProjectsInFolder,
} from '../redux/actions';

import {
  GetFoldersByIdParams,
  IFolderFromList,
  ColumnRootVisibilityKey,
  ColumnVisibilityKey,
  GetRootFoldersParams,
  CreateFolderParams,
  RenameFolderParams,
  SetCurrentFolderParams,
  IFolder,
  deletionOfFolderContentsParams,
} from '../types';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { Project } from '../../projects/types';

/**
 * Custom hook for managing folders.
 * @returns {Object} Methods and state for managing folders.
 */
export default function useFolders() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(state => state.folders);

  const memoizedState = useMemo(() => ({ ...state }), [state]);

  /**
   * Fetches available folders.
   * @param {{ accountId: number }} params - Parameters for fetching available folders.
   */
  const onGetAvailableFolders = useCallback(
    (params: { accountId: number }) => {
      dispatch(getAvailableFoldersThunk(params));
    },
    [dispatch]
  );

  /**
   * Fetches folders for a specific account.
   * @param {{ accountId: number }} params - Parameters for fetching folders.
   */
  const onGetFolders = useCallback(
    (params: { accountId: number }) => {
      dispatch(getFoldersThunk(params));
    },
    [dispatch]
  );

  /**
   * Fetches folders by their ID.
   * @param {GetFoldersByIdParams} params - Parameters for fetching folders by ID.
   */
  const onGetFoldersById = useCallback(
    (params: GetFoldersByIdParams) => {
      dispatch(getFoldersByIdThunk(params));
    },
    [dispatch]
  );

  /**
   * Sets the default current folder.
   * @param {IFolderFromList} params - The default current folder.
   */
  const onSetDefaultCurrentFolder = useCallback(
    (params: IFolderFromList) => {
      dispatch(setDefaultCurrentFolder(params));
    },
    [dispatch]
  );

  /**
   * Sets the root column visibility model for folders.
   * @param {ColumnRootVisibilityKey} key - The column key.
   * @param {boolean} value - Whether the column is visible.
   */
  const onSetRootColumnVisibilityModel = useCallback(
    (key: ColumnRootVisibilityKey, value: boolean) => {
      dispatch(setRootColumnVisibilityModel(key, value));
    },
    [dispatch]
  );

  /**
   * Sets the column visibility model for folders.
   * @param {ColumnVisibilityKey} key - The column key.
   * @param {boolean} value - Whether the column is visible.
   */
  const onSetColumnVisibilityModel = useCallback(
    (key: ColumnVisibilityKey, value: boolean) => {
      dispatch(setColumnVisibilityModel(key, value));
    },
    [dispatch]
  );

  /**
   * Resets the column visibility model for folders.
   */
  const onResetColumnVisibilityModel = useCallback(() => {
    dispatch(resetColumnVisibilityModel());
  }, [dispatch]);

  /**
   * Sets the page size for pagination of folders.
   * @param {number} pageSize - The page size.
   */
  const onSetPaginationPageSize = useCallback(
    (pageSize: number) => {
      dispatch(setPaginationPageSize(pageSize));
    },
    [dispatch]
  );

  /**
   * Sets the page number for pagination of folders.
   * @param {number} page - The page number.
   */
  const onSetPaginationPage = useCallback(
    (page: number) => {
      dispatch(setPaginationPage(page));
    },
    [dispatch]
  );

  /**
   * Sets the search term for folders.
   * @param {string} value - The search term.
   */
  const onSetSearchFolders = useCallback(
    (value: string) => {
      dispatch(setSearchFolders(value));
    },
    [dispatch]
  );

  /**
   * Fetches root folders for a specific account.
   * @param {GetRootFoldersParams} params - Parameters for fetching root folders.
   */
  const onGetRootFolders = useCallback(
    (params: GetRootFoldersParams) => {
      dispatch(getRootFoldersThunk(params));
    },
    [dispatch]
  );

  /**
   * Creates a new folder.
   * @param {CreateFolderParams} params - Parameters for creating a folder.
   */
  const onCreateFolder = useCallback(
    (params: CreateFolderParams) => {
      dispatch(createFolderThunk(params));
    },
    [dispatch]
  );

  /**
   * Sets the row selection model for folders.
   * @param {IFolder[]} rowSelectionModel - The row selection model.
   */
  const onSetRowSelectionModel = useCallback(
    (rowSelectionModel: IFolder[]) => {
      dispatch(setRowSelectionModel(rowSelectionModel));
    },
    [dispatch]
  );

  /**
   * Renames an existing folder.
   * @param {RenameFolderParams} params - Parameters for renaming a folder.
   */
  const onRenameFolder = useCallback(
    (params: RenameFolderParams) => {
      dispatch(renameFolderThunk(params));
    },
    [dispatch]
  );

  /**
   * Sets the current folder.
   * @param {SetCurrentFolderParams} params - The current folder to be set.
   */
  const onSetCurrentFolder = useCallback(
    (params: SetCurrentFolderParams) => {
      dispatch(setCurrentFolder(params));
    },
    [dispatch]
  );

  /**
   * Deletes the contents of a folder.
   * @param {deletionOfFolderContentsParams} params - Parameters for deleting folder contents.
   */
  const onDeletionOfFolderContents = useCallback(
    (params: deletionOfFolderContentsParams) => {
      dispatch(deletionOfFolderContentsThunk(params));
    },
    [dispatch]
  );

  /**
   * Sets the delete elements for the root folders table.
   * @param {IFolder[]} rowSelectionModel - Elements to be deleted.
   */
  const onSetDeleteElementsRootFoldersTable = useCallback(
    (rowSelectionModel: IFolder[]) => {
      dispatch(setDeleteElementsRootFoldersTable(rowSelectionModel));
    },
    [dispatch]
  );

  /**
   * Sets the sort model for the root folder.
   * @param {GridSortModel} sortModel - The sort model.
   */
  const onSetSortModelRootFolder = useCallback(
    (sortModel: GridSortModel) => {
      dispatch(setSortModelRootFolder(sortModel));
    },
    [dispatch]
  );

  /**
   * Sets the sort model for nested elements.
   * @param {GridSortModel} sortModel - The sort model.
   */
  const onSetSortModelNestedElements = useCallback(
    (sortModel: GridSortModel) => {
      dispatch(setSortModelNestedElements(sortModel));
    },
    [dispatch]
  );

  /**
   * Updates the projects in a folder.
   * @param {Project[]} projects - The projects to update.
   */
  const onUpdateProjectsInFolder = useCallback(
    (projects: Project[]) => {
      dispatch(updateProjectsInFolder(projects));
    },
    [dispatch]
  );

  return {
    ...memoizedState,
    onGetAvailableFolders,
    onGetFolders,
    onSetDefaultCurrentFolder,
    onGetFoldersById,
    onSetRootColumnVisibilityModel,
    onSetColumnVisibilityModel,
    onResetColumnVisibilityModel,
    onSetPaginationPageSize,
    onSetPaginationPage,
    onSetSearchFolders,
    onGetRootFolders,
    onCreateFolder,
    onSetRowSelectionModel,
    onRenameFolder,
    onSetCurrentFolder,
    onDeletionOfFolderContents,
    onSetDeleteElementsRootFoldersTable,
    onSetSortModelRootFolder,
    onSetSortModelNestedElements,
    onUpdateProjectsInFolder,
  };
}
