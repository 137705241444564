import React from 'react';

interface Props {
  width?: string;
  height?: string;
}

const GoogleIconIcon: React.FC<Props> = ({ width = '14', height = '14' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='google 1' clipPath='url(#clip0_2228_171381)'>
        <path
          id='Vector'
          d='M3.51031 6.50969C4.13204 4.62575 5.90248 3.27273 7.99951 3.27273C9.12678 3.27273 10.145 3.67273 10.945 4.32727L13.2722 2C11.8541 0.763636 10.0359 0 7.99951 0C4.84622 0 2.13134 1.79887 0.826172 4.43335L3.51031 6.50969Z'
          fill='#EA4335'
        />
        <path
          id='Vector_2'
          d='M10.6931 12.0082C9.96652 12.4773 9.0433 12.7271 7.99924 12.7271C5.91023 12.7271 4.14532 11.3844 3.51723 9.51172L0.824219 11.5565C2.12777 14.1956 4.84258 15.9998 7.99924 15.9998C9.95447 15.9998 11.8228 15.3047 13.222 13.9995L10.6931 12.0082Z'
          fill='#34A853'
        />
        <path
          id='Vector_3'
          d='M13.2228 14.0002C14.6861 12.6352 15.6364 10.6029 15.6364 8.00044C15.6364 7.52772 15.5636 7.01863 15.4545 6.5459H8V9.63681H12.2909C12.0792 10.6762 11.5109 11.4813 10.6938 12.0088L13.2228 14.0002Z'
          fill='#4A90E2'
        />
        <path
          id='Vector_4'
          d='M3.51799 9.51216C3.35888 9.03779 3.27273 8.52942 3.27273 8.00024C3.27273 7.4791 3.35629 6.97812 3.5108 6.50993L0.82666 4.43359C0.291058 5.5072 0 6.71717 0 8.00024C0 9.27994 0.296521 10.487 0.824975 11.5569L3.51799 9.51216Z'
          fill='#FBBC05'
        />
      </g>
      <defs>
        <clipPath id='clip0_2228_171381'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoogleIconIcon;
