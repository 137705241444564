import React from 'react';

const MobileDeviceIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.00016 11.6667H8.00683M5.46683 14.6667H10.5335C11.2802 14.6667 11.6536 14.6667 11.9388 14.5214C12.1897 14.3935 12.3937 14.1895 12.5215 13.9387C12.6668 13.6534 12.6668 13.2801 12.6668 12.5333V3.46668C12.6668 2.71994 12.6668 2.34657 12.5215 2.06136C12.3937 1.81047 12.1897 1.6065 11.9388 1.47867C11.6536 1.33334 11.2802 1.33334 10.5335 1.33334H5.46683C4.72009 1.33334 4.34672 1.33334 4.06151 1.47867C3.81063 1.6065 3.60665 1.81047 3.47882 2.06136C3.3335 2.34657 3.3335 2.71994 3.3335 3.46668V12.5333C3.3335 13.2801 3.3335 13.6534 3.47882 13.9387C3.60665 14.1895 3.81063 14.3935 4.06151 14.5214C4.34672 14.6667 4.72009 14.6667 5.46683 14.6667ZM8.3335 11.6667C8.3335 11.8508 8.18426 12 8.00016 12C7.81607 12 7.66683 11.8508 7.66683 11.6667C7.66683 11.4826 7.81607 11.3333 8.00016 11.3333C8.18426 11.3333 8.3335 11.4826 8.3335 11.6667Z'
        stroke='#101828'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default MobileDeviceIcon;
