import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetCurrentProjectParams, Project } from '../../projects/types';
import { getCurrentProjectAPI } from '../../projects/api';
import {
  bulkDeleteKeywordsFromTrigger,
  bulkDeleteTriggersByKeywordsAPI,
  bulkDeleteTriggersByProjectsAPI,
  createTriggerAPI,
  getAlertInfoAPI,
  getAlertKeywordsAPI,
  getAlertsByKeywordsAPI,
  getAlertsByProjectAPI,
  getAlertsTriggersTypesAPI,
  getKeywordsByProjectAPI,
  getProjectsByAlertsAPI,
  getTriggerInfoAPI,
  getTriggerKeywordsAPI,
  getTriggersByKeywordsAPI,
  getTriggersByProjectsAPI,
  setViewAlertByKeywordsAPI,
  setViewAlertByProjectAPI,
  updateKeywordTriggerAPI,
  updateProjectTriggerAPI,
} from '../api';
import {
  IAlertInfo,
  BulkDeleteKeywordsFromTriggerParams,
  BulkDeleteTriggersParams,
  GetAlertInfoParams,
  GetAlertsParams,
  GetTriggerInfoParams,
  GetTriggerKeywordsParams,
  GetTriggersParams,
  IAlertByKeywordsResponse,
  IAlertByProjectResponse,
  IAlertsTriggersResponse,
  ICreateTriggerParams,
  IGetKeywordsByProjectParams,
  IGetTriggerKeywordsResponse,
  IGetTriggersByKeywordsResponse,
  IGetTriggersByProjectsResponse,
  IKeywordsByProject,
  ITriggerInfo,
  UpdateKeywordTriggerAPIParams,
  UpdateProjectTriggerAPIParams,
  GetAlertKeywordsParams,
  IGetAlertKeywordsResponse,
  ISetViewAlertParams,
  IGetProjectsByAlertsParams,
  IGetProjectsByAlertsResponse,
} from '../types/types';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import CustomToast from '../../../shared/CustomToast/CustomToast';
import { closeSnackbarAction } from '../../../utils/snackbar';
import React from 'react';
import {
  checkAndRedirectToMaintenancePage,
  convertErrorsForEmails,
  removeEmailKeys,
} from '../../../utils';

/**
 * Thunk to get project keywords for a trigger.
 * @param {GetCurrentProjectParams} params - Parameters for getting the current project.
 * @returns {Promise<Project>} - The current project data.
 */
export const onGetProjectKeywordsForTriggerThunk = createAsyncThunk<
  Project,
  GetCurrentProjectParams,
  { rejectValue: string }
>('onGetProjectKeywordsForTriggerThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getCurrentProjectAPI(params);
    params.successFn();
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to get keywords by project.
 * @param {IGetKeywordsByProjectParams} params - Parameters for getting keywords by project.
 * @returns {Promise<IKeywordsByProject>} - Keywords data for the specified project.
 */
export const getKeywordsByProjectThunk = createAsyncThunk<
  IKeywordsByProject,
  IGetKeywordsByProjectParams,
  { rejectValue: string }
>('getKeywordsByProjectThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getKeywordsByProjectAPI(params);
    params.successFn(data.items);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to get alert trigger types.
 * @returns {Promise<IAlertsTriggersResponse>} - Alert trigger types response.
 */
export const getAlertsTriggersTypesThunk = createAsyncThunk<
  IAlertsTriggersResponse,
  undefined,
  { rejectValue: string }
>('getAlertsTriggersTypesThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getAlertsTriggersTypesAPI();
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to create a new trigger.
 * @param {ICreateTriggerParams} params - Parameters for creating a trigger.
 * @returns {Promise<void>} - No response data.
 */
export const createTriggerThunk = createAsyncThunk<
  undefined,
  ICreateTriggerParams,
  { rejectValue: string }
>('createTriggerThunk', async (params, thunkAPI) => {
  try {
    const { data } = await createTriggerAPI(params);

    if (data.duplicateTriggersForKeywordsHaveBeenOmitted) {
      enqueueSnackbar(
        <CustomToast
          message='Info'
          submessage='Duplicate trigger(s) are skipped.'
        />,
        { action: closeSnackbarAction, variant: 'warning' }
      );
    }
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        const emailErrors = convertErrorsForEmails(errors);
        if (emailErrors.length) {
          const errorObj = { ...removeEmailKeys(errors), emails: emailErrors };
          params.errorFn(errorObj);
        } else {
          params.errorFn(errors);
        }
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to get triggers by project.
 * @param {GetTriggersParams} params - Parameters for getting triggers by projects.
 * @returns {Promise<IGetTriggersByProjectsResponse>} - Response containing triggers by projects.
 */
export const getTriggersByProjectsThunk = createAsyncThunk<
  IGetTriggersByProjectsResponse,
  GetTriggersParams
>('getTriggersByProjectsThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getTriggersByProjectsAPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to get triggers by keywords.
 * @param {GetTriggersParams} params - Parameters for getting triggers by keywords.
 * @returns {Promise<IGetTriggersByKeywordsResponse>} - Response containing triggers by keywords.
 */
export const getTriggersByKeywordsThunk = createAsyncThunk<
  IGetTriggersByKeywordsResponse,
  GetTriggersParams
>('getTriggersByKeywordsThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getTriggersByKeywordsAPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to bulk delete triggers by projects.
 * @param {BulkDeleteTriggersParams} params - Parameters for bulk deleting triggers by projects.
 * @returns {Promise<void>} - No response data.
 */
export const bulkDeleteTriggersByProjectsThunk = createAsyncThunk<
  undefined,
  BulkDeleteTriggersParams
>('bulkDeleteTriggersByProjectsThunk', async (params, thunkAPI) => {
  try {
    await bulkDeleteTriggersByProjectsAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to bulk delete triggers by keywords.
 * @param {BulkDeleteTriggersParams} params - Parameters for bulk deleting triggers by keywords.
 * @returns {Promise<void>} - No response data.
 */
export const bulkDeleteTriggersByKeywordsThunk = createAsyncThunk<
  undefined,
  BulkDeleteTriggersParams
>('bulkDeleteTriggersByKeywordsThunk', async (params, thunkAPI) => {
  try {
    await bulkDeleteTriggersByKeywordsAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to get trigger keywords.
 * @param {GetTriggerKeywordsParams} params - Parameters for getting keywords associated with a trigger.
 * @returns {Promise<IGetTriggerKeywordsResponse>} - Response containing trigger keywords.
 */
export const getTriggerKeywordThunk = createAsyncThunk<
  IGetTriggerKeywordsResponse,
  GetTriggerKeywordsParams
>('getTriggerKeywordThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getTriggerKeywordsAPI(params);
    if (params?.successFn)
      params.successFn(
        data.items.map(item => ({
          id: item.keywordId,
          name: item.name,
          deviceType: item.deviceType,
        }))
      );
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to get information about a specific trigger.
 *
 * @param {GetTriggerInfoParams} params - Parameters to identify the trigger.
 * @returns {Promise<ITriggerInfo>} Trigger information.
 */
export const getTriggerInfoThunk = createAsyncThunk<
  ITriggerInfo,
  GetTriggerInfoParams
>('getTriggerInfoThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getTriggerInfoAPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to delete multiple keywords from a specific trigger.
 *
 * @param {BulkDeleteKeywordsFromTriggerParams} params - Parameters for the keywords to be deleted.
 * @returns {Promise<void>} No data is returned.
 */
export const bulkDeleteKeywordsFromTriggerThunk = createAsyncThunk<
  undefined,
  BulkDeleteKeywordsFromTriggerParams
>('bulkDeleteKeywordsFromTriggerThunk', async (params, thunkAPI) => {
  try {
    await bulkDeleteKeywordsFromTrigger(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to update a project trigger.
 *
 * @param {UpdateProjectTriggerAPIParams} params - Parameters to update the project trigger.
 * @returns {Promise<void>} No data is returned.
 */
export const updateProjectTriggerThunk = createAsyncThunk<
  undefined,
  UpdateProjectTriggerAPIParams
>('updateProjectTriggerThunk', async (params, thunkAPI) => {
  try {
    await updateProjectTriggerAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        const emailErrors = convertErrorsForEmails(errors);
        if (emailErrors.length) {
          const errorObj = { ...removeEmailKeys(errors), emails: emailErrors };
          params.errorFn(errorObj);
        } else {
          params.errorFn(errors);
        }
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to update a keyword trigger.
 *
 * @param {UpdateKeywordTriggerAPIParams} params - Parameters to update the keyword trigger.
 * @returns {Promise<void>} No data is returned.
 */
export const updateKeywordTriggerThunk = createAsyncThunk<
  undefined,
  UpdateKeywordTriggerAPIParams
>('updateKeywordTriggerThunk', async (params, thunkAPI) => {
  try {
    await updateKeywordTriggerAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        params.errorFn(errors);
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to get alerts by project.
 *
 * @param {GetAlertsParams} params - Parameters to identify the alerts by project.
 * @returns {Promise<IAlertByProjectResponse>} Alerts by project.
 */
export const getAlertsByProjectThunk = createAsyncThunk<
  IAlertByProjectResponse,
  GetAlertsParams
>('getAlertsByProjectThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getAlertsByProjectAPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to get alerts by keywords.
 *
 * @param {GetAlertsParams} params - Parameters to identify the alerts by keywords.
 * @returns {Promise<IAlertByKeywordsResponse>} Alerts by keywords.
 */
export const getAlertsByKeywordsThunk = createAsyncThunk<
  IAlertByKeywordsResponse,
  GetAlertsParams
>('getAlertsByKeywordsThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getAlertsByKeywordsAPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to get detailed information about a specific alert.
 *
 * @param {GetAlertInfoParams} params - Parameters to identify the alert.
 * @returns {Promise<IAlertInfo>} Alert information.
 */
export const getAlertInfoThunk = createAsyncThunk<
  IAlertInfo,
  GetAlertInfoParams
>('getAlertInfoThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getAlertInfoAPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to get keywords related to a specific alert.
 *
 * @param {GetAlertKeywordsParams} params - Parameters to identify the alert keywords.
 * @returns {Promise<IGetAlertKeywordsResponse>} Alert keywords.
 */
export const getAlertKeywordsThunk = createAsyncThunk<
  IGetAlertKeywordsResponse,
  GetAlertKeywordsParams
>('getAlertKeywordsThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getAlertKeywordsAPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to mark an alert as viewed by project.
 *
 * @param {ISetViewAlertParams} params - Parameters to set the alert as viewed.
 * @returns {Promise<void>} No data is returned.
 */
export const setViewAlertByProjectThunk = createAsyncThunk<
  undefined,
  ISetViewAlertParams
>('setViewAlertByProjectThunk', async (params, thunkAPI) => {
  try {
    await setViewAlertByProjectAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to mark an alert as viewed by keywords.
 *
 * @param {ISetViewAlertParams} params - Parameters to set the alert as viewed.
 * @returns {Promise<void>} No data is returned.
 */
export const setViewAlertByKeywordsThunk = createAsyncThunk<
  undefined,
  ISetViewAlertParams
>('setViewAlertByKeywordsThunk', async (params, thunkAPI) => {
  try {
    await setViewAlertByKeywordsAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

/**
 * Thunk to get projects associated with alerts.
 *
 * @param {IGetProjectsByAlertsParams} params - Parameters to get projects by alerts.
 * @returns {Promise<IGetProjectsByAlertsResponse>} Projects associated with alerts.
 */
export const getProjectsByAlertsThunk = createAsyncThunk<
  IGetProjectsByAlertsResponse,
  IGetProjectsByAlertsParams
>('getProjectsByAlertsThunk', async (params, thunkAPI) => {
  try {
    const { data } = await getProjectsByAlertsAPI(params);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});
