import { createAction } from '@reduxjs/toolkit';
import { ColumnVisibilityKey } from '../types';
import {
  ColumnVisibilityKey as ColumnVisibilityKeyForProject,
  ColumnVisibilityKeyKeywordRankings,
  ImprovedVsDeclinedVisibilityKey,
  NotRankedVisibilityKey,
} from '../../projects/types';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import { Range } from 'react-date-range';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';

const setSearchAllSharedLinks = createAction(
  'setSearchAllSharedLinks',
  function prepare(value: string) {
    return {
      payload: value,
    };
  }
);

const setColumnVisibilityModel = createAction(
  'setColumnVisibilityModel',
  function prepare(key: ColumnVisibilityKey, value: boolean) {
    return {
      payload: {
        key,
        value,
      },
    };
  }
);

const resetColumnVisibilityModel = createAction('resetColumnVisibilityModel');

const setPaginationPageSize = createAction(
  'setPaginationPageSize',
  function prepare(pageSize: number) {
    return {
      payload: pageSize,
    };
  }
);

const setPaginationPage = createAction(
  'setPaginationPage',
  function prepare(page: number) {
    return {
      payload: page,
    };
  }
);

const setRowSelectionModelSharedLinks = createAction(
  'setRowSelectionModelSharedLinks',
  function prepare(rowSelectionModel: GridRowSelectionModel) {
    return {
      payload: rowSelectionModel,
    };
  }
);

const setPaginationPageSizeForSharedLinkContentsListTable = createAction(
  'setPaginationPageSizeForSharedLinkContentsListTable',
  function prepare(pageSize: number) {
    return {
      payload: pageSize,
    };
  }
);

const setPaginationPageForSharedLinkContentsListTable = createAction(
  'setPaginationPageForSharedLinkContentsListTable',
  function prepare(page: number) {
    return {
      payload: page,
    };
  }
);

const setColumnVisibilityModelForSharedLinkContentsListTable = createAction(
  'setColumnVisibilityModelForSharedLinkContentsListTable',
  function prepare(key: ColumnVisibilityKeyForProject, value: boolean) {
    return {
      payload: {
        key,
        value,
      },
    };
  }
);

const resetColumnVisibilityModelForSharedLinkContentsListTable = createAction(
  'resetColumnVisibilityModelForSharedLinkContentsListTable'
);

const setSearchSharedLinkContentsList = createAction(
  'setSearchSharedLinkContentsList',
  function prepare(value: string) {
    return {
      payload: value,
    };
  }
);

const setDeviceTypeForProject = createAction(
  'setDeviceTypeForProject',
  function prepare(deviceType: string) {
    return {
      payload: deviceType,
    };
  }
);

const setRangeDateForProject = createAction(
  'setRangeDateForProject',
  function prepare(rangeDate: Range) {
    return {
      payload: rangeDate,
    };
  }
);

const setIsShowImprovedVsDeclined = createAction(
  'setIsShowImprovedVsDeclined',
  function prepare(isShow: boolean) {
    return {
      payload: isShow,
    };
  }
);

const setImprovedVsDeclinedVisibilityModel = createAction(
  'setImprovedVsDeclinedVisibilityModel',
  function prepare(key: ImprovedVsDeclinedVisibilityKey, value: boolean) {
    return {
      payload: {
        key,
        value,
      },
    };
  }
);

const setActivePeriodImprovedVsDeclined = createAction(
  'setActivePeriodImprovedVsDeclined',
  function prepare(period: { label: string; value: string }) {
    return {
      payload: period,
    };
  }
);

const setKeywordTrendsVisibilityModel = createAction(
  'setKeywordTrendsVisibilityModel',
  function prepare(key: NotRankedVisibilityKey, value: boolean) {
    return {
      payload: {
        key,
        value,
      },
    };
  }
);

const setActivePeriodKeywordTrends = createAction(
  'setActivePeriodKeywordTrends',
  function prepare(period: { label: string; value: string }) {
    return {
      payload: period,
    };
  }
);

const setColumnVisibilityModelKeywordRankings = createAction(
  'setColumnVisibilityModelKeywordRankings',
  function prepare(key: ColumnVisibilityKeyKeywordRankings, value: boolean) {
    return {
      payload: {
        key,
        value,
      },
    };
  }
);

const resetColumnVisibilityModelKeywordRankings = createAction(
  'resetColumnVisibilityModelKeywordRankings'
);

const setRowKeywordsSelectionModel = createAction(
  'setRowKeywordsSelectionModel',
  function prepare(rowSelectionModel: GridRowSelectionModel) {
    return {
      payload: rowSelectionModel,
    };
  }
);

const setSearchKeywordRankings = createAction(
  'setSearchKeywordRankings',
  function prepare(value: string) {
    return {
      payload: value,
    };
  }
);

const setSortModelKeywordRankings = createAction(
  'setSortModelKeywordRankings',
  function prepare(sortModel: GridSortModel) {
    return {
      payload: sortModel,
    };
  }
);

const setKeywordRankingsPaginationPageSize = createAction(
  'setKeywordRankingsPaginationPageSize',
  function prepare(pageSize: number) {
    return {
      payload: pageSize,
    };
  }
);

const setKeywordRankingsPaginationPage = createAction(
  'setKeywordRankingsPaginationPage',
  function prepare(page: number) {
    return {
      payload: page,
    };
  }
);

const setKeywordAnalyticPeriod = createAction(
  'setSharedKeywordAnalyticPeriod',
  function prepare(id: number, period: { label: string; value: string }) {
    return {
      payload: {
        id,
        period,
      },
    };
  }
);

const setCloseKeywordPositionHistory = createAction(
  'setCloseKeywordPositionHistory',
  function prepare(id: number) {
    return {
      payload: id,
    };
  }
);

const setActivePeriodSharedProjectPerformance = createAction(
  'setActivePeriodSharedProjectPerformance',
  function prepare(period: { label: string; value: string }) {
    return {
      payload: period,
    };
  }
);

const setSharedProjectPerformanceIsShowNotes = createAction(
  'setSharedProjectPerformanceIsShowNotes',
  function prepare(value: boolean) {
    return {
      payload: value,
    };
  }
);

const setSharedProjectPerformanceIsLinearGraph = createAction(
  'setSharedProjectPerformanceIsLinearGraph',
  function prepare(value: boolean) {
    return {
      payload: value,
    };
  }
);

const setSharedProjectShowCompetitorsForGraph = createAction(
  'setSharedProjectShowCompetitorsForGraph',
  function prepare(values: number[]) {
    return {
      payload: { values },
    };
  }
);

const setSortModelSharedProjects = createAction(
  'setSortModelSharedProjects',
  function prepare(sortModel: GridSortModel) {
    return {
      payload: sortModel,
    };
  }
);

const resetSharedLinksState = createAction('resetSharedLinksState');

const setSharedLinksColumnKeywordPositionModel = createAction(
  'setSharedLinksColumnKeywordPositionModel',
  function prepare(items: string[]) {
    return {
      payload: {
        items,
      },
    };
  }
);

const resetSharedLinksKeywordPositionModel = createAction(
  'resetSharedLinksKeywordPositionModel'
);

const setSharedShowCompetitorsForPositionHistory = createAction(
  'setSharedShowCompetitorsForPositionHistory',
  function prepare(showCompetitors: number[], keywordAnalyticId: number) {
    return {
      payload: { showCompetitors, keywordAnalyticId },
    };
  }
);

const setDeleteElementsSharedLinksPageTable = createAction(
  'setDeleteElementsSharedLinksPageTable',
  function prepare(deleteElements: GridRowSelectionModel) {
    return {
      payload: { deleteElements },
    };
  }
);

const setIsPasswordUpdated = createAction(
  'setIsPasswordUpdated',
  function prepare(value: boolean) {
    return {
      payload: { value },
    };
  }
);

const setSharedActivePeriodKeywordRankHistory = createAction(
  'setSharedActivePeriodKeywordRankHistory',
  function prepare(period: { label: string; value: string }) {
    return {
      payload: period,
    };
  }
);

const setSharedKeywordRankHistoryIsLinearGraph = createAction(
  'setSharedKeywordRankHistoryIsLinearGraph',
  function prepare(value: boolean) {
    return {
      payload: value,
    };
  }
);

const setSharedShowCompetitorsForKeywordRankHistory = createAction(
  'setSharedShowCompetitorsForKeywordRankHistory',
  function prepare(showCompetitors: number[]) {
    return {
      payload: { showCompetitors },
    };
  }
);

const setSharedSortModelKeywordRankHistory = createAction(
  'setSharedSortModelKeywordRankHistory',
  function prepare(sortModel: GridSortModel) {
    return {
      payload: sortModel,
    };
  }
);

const setSharedKeywordRankHistoryPaginationPageSize = createAction(
  'setSharedKeywordRankHistoryPaginationPageSize',
  function prepare(pageSize: number) {
    return {
      payload: pageSize,
    };
  }
);

const setSharedKeywordRankHistoryPaginationPage = createAction(
  'setSharedKeywordRankHistoryPaginationPage',
  function prepare(page: number) {
    return {
      payload: page,
    };
  }
);

export {
  setSearchAllSharedLinks,
  setColumnVisibilityModel,
  resetColumnVisibilityModel,
  setPaginationPageSize,
  setPaginationPage,
  setRowSelectionModelSharedLinks,
  setPaginationPageSizeForSharedLinkContentsListTable,
  setPaginationPageForSharedLinkContentsListTable,
  setColumnVisibilityModelForSharedLinkContentsListTable,
  resetColumnVisibilityModelForSharedLinkContentsListTable,
  setSearchSharedLinkContentsList,
  setDeviceTypeForProject,
  setRangeDateForProject,
  setIsShowImprovedVsDeclined,
  setImprovedVsDeclinedVisibilityModel,
  setActivePeriodImprovedVsDeclined,
  setKeywordTrendsVisibilityModel,
  setActivePeriodKeywordTrends,
  setColumnVisibilityModelKeywordRankings,
  resetColumnVisibilityModelKeywordRankings,
  setRowKeywordsSelectionModel,
  setSearchKeywordRankings,
  setSortModelKeywordRankings,
  setKeywordRankingsPaginationPageSize,
  setKeywordRankingsPaginationPage,
  setKeywordAnalyticPeriod,
  setCloseKeywordPositionHistory,
  setActivePeriodSharedProjectPerformance,
  setSharedProjectPerformanceIsShowNotes,
  setSharedProjectPerformanceIsLinearGraph,
  setSharedProjectShowCompetitorsForGraph,
  resetSharedLinksState,
  setSortModelSharedProjects,
  setSharedLinksColumnKeywordPositionModel,
  resetSharedLinksKeywordPositionModel,
  setSharedShowCompetitorsForPositionHistory,
  setDeleteElementsSharedLinksPageTable,
  setIsPasswordUpdated,
  setSharedActivePeriodKeywordRankHistory,
  setSharedKeywordRankHistoryIsLinearGraph,
  setSharedShowCompetitorsForKeywordRankHistory,
  setSharedSortModelKeywordRankHistory,
  setSharedKeywordRankHistoryPaginationPageSize,
  setSharedKeywordRankHistoryPaginationPage,
};
