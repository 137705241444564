import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React, { useMemo, useState } from 'react';
import {
  MainContainer,
  StyledExportButton,
  StyledLinearLogSwitch,
} from './styled';
import { Divider, Grid, Popover } from '@mui/material';
import { useProjects } from '../../../../../../../../../../hooks';
import { GrayButtonCustom } from '../../../../../../../../../../shared/buttons/styles';
import DownIcon from '../../../../../../../../../../shared/images/DownIcon';
import { CustomSwitch } from '../../../../../../../../../../shared/Switch/styles';
import { ExportGraphProps } from '../../../../../../../KeywordRankHistory/components/Graph/components/types';
import DownloadIcon from '../../../../../../../../../../shared/images/DownloadIcon';
import ExportGraphModal from '../../../../../../../modals/ExportGraphModal/ExportGraphModal';
import { useAppSelector } from '../../../../../../../../../../store';

interface GraphOptionsProps extends ExportGraphProps {
  id: number;
}

const GraphOptions: React.FC<GraphOptionsProps> = ({ id, refGraph }) => {
  const { onSetPositionHistoryLinearGraph } = useProjects();

  const keywordRankings = useAppSelector(
    state => state.projects.projectExpandedGraphs.keywordRankings
  );

  const [isOpenExportGraphModal, setOpenExportGraphModal] = useState(false);

  const onClose = () => {
    setOpenExportGraphModal(false);
  };

  const keywordAnalytic = useMemo(() => {
    return keywordRankings.keywordsAnalytics.find(item => item.id === id);
  }, [id, keywordRankings.keywordsAnalytics]);

  const handleSetProjectPerformanceIsLinearGraph = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    onSetPositionHistoryLinearGraph({ value: checked, id: id });
  };

  if (!keywordAnalytic) return null;

  return (
    <>
      <PopupState variant='popover'>
        {popupState => (
          <>
            <GrayButtonCustom
              $padding='6px 12px'
              size='small'
              outline
              endIcon={<DownIcon stroke='#344054' />}
              {...bindTrigger(popupState)}
            >
              Graph Options
            </GrayButtonCustom>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MainContainer>
                <Grid>
                  <StyledExportButton
                    color={'inherit'}
                    size='small'
                    startIcon={<DownloadIcon />}
                    onClick={() => {
                      popupState.close();
                      setOpenExportGraphModal(true);
                    }}
                  >
                    Export
                  </StyledExportButton>
                </Grid>
                <Grid>
                  <Divider
                    orientation='horizontal'
                    flexItem
                    sx={{
                      margin: '8px 0',
                      color: '#EDEDED',
                    }}
                  />
                </Grid>
                <StyledLinearLogSwitch>
                  <Grid>Log</Grid>
                  <Grid>
                    <CustomSwitch
                      checked={keywordAnalytic.isLinearGraph}
                      onChange={handleSetProjectPerformanceIsLinearGraph}
                    />
                  </Grid>
                  <Grid>Linear</Grid>
                </StyledLinearLogSwitch>
              </MainContainer>
            </Popover>
          </>
        )}
      </PopupState>
      <ExportGraphModal
        refGraph={refGraph}
        open={isOpenExportGraphModal}
        onClose={onClose}
      />
    </>
  );
};

export default GraphOptions;
