import Grid from '@mui/material/Grid';
import React from 'react';
import { Invoice, PaymentAndPlan } from './components';
import UpgradePlan from '../../../UpgradePlan/UpgradePlan';
import { useAppSelector } from '../../../../../../store';

/**
 * Billing component displays the payment plan and invoice sections. If billing information is not available, prompts the user to upgrade the plan.
 *
 * @component
 * @returns {JSX.Element | null} Rendered Billing component or null if billing data is loading.
 */
const Billing = () => {
  const billing = useAppSelector(state => state.auth.payment.billing);
  const isLoadingBilling = useAppSelector(
    state => state.auth.payment.isLoadingBilling
  );

  if (isLoadingBilling) {
    return null;
  }

  if (!isLoadingBilling && !billing) {
    return <UpgradePlan />;
  }

  return (
    <Grid xs={12}>
      <PaymentAndPlan />
      <Invoice />
    </Grid>
  );
};

export default Billing;
