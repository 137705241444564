import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StylesLabelModal = styled(Grid)`
  color: #475467;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const StylesAddAnotherRecipientButton = styled(Grid)`
  color: #1a73e9;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export { StylesLabelModal, StylesAddAnotherRecipientButton };
