import { styled } from '@mui/system';
import { Grid } from '@mui/material';

const StyledKeywordLimit = styled(Grid)`
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  padding: 6px 0;
`;

export { StyledKeywordLimit };
