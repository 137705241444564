import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';

interface StyledTabProps {
  label: string;
  icon?: string | React.ReactElement;
  iconPosition?: 'top' | 'bottom' | 'start' | 'end';
  value: string;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(() => ({
  minHeight: '36px',
  textTransform: 'none',
  fontWeight: 600,
  fontSize: '16px',
  padding: '0',
  color: '#667085',
  paddingBottom: '11px',
}));

const PersonalInfoContainer = styled(Grid)`
  ${props => props.theme.breakpoints.down('sm')} {
    > div {
      :last-child {
        padding-top: 16px;
        justify-content: flex-start;
      }
    }
  }
`;

const FormItem = styled(Grid)`
  border-bottom: 1px solid #eaecf0;
  ${props => props.theme.breakpoints.down('sm')} {
    > div {
      :last-child {
        padding-top: 16px;
      }
    }
  }
`;

const TeamCountIcon = styled(Grid)`
  padding: 2px 8px;
  border-radius: 16px;
  background: #eff8ff;
  font-size: 14px;
  color: #126fe9;
`;

export { StyledTab, PersonalInfoContainer, FormItem, TeamCountIcon };
