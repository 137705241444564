import React from 'react';

const TagIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <path
      d='M13.7266 8.93992L8.94658 13.7199C8.82275 13.8439 8.6757 13.9422 8.51384 14.0093C8.35197 14.0764 8.17847 14.111 8.00325 14.111C7.82803 14.111 7.65453 14.0764 7.49266 14.0093C7.3308 13.9422 7.18375 13.8439 7.05992 13.7199L1.33325 7.99992V1.33325H7.99992L13.7266 7.05992C13.9749 7.30973 14.1143 7.64767 14.1143 7.99992C14.1143 8.35217 13.9749 8.6901 13.7266 8.93992V8.93992Z'
      stroke='#667085'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.66675 4.66675H4.67341'
      stroke='#667085'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default TagIcon;
