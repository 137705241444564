import React from 'react';
import { Button, Grid } from '@mui/material';

import { StyledTimeGrout } from './styles';
import { TimesGroupProps } from './types';
import { useAppSelector } from '../../../../store';

const TimesGroup: React.FC<TimesGroupProps> = ({ items, disabled }) => {
  const isLoadingProjectExpanded = useAppSelector(
    state => state.projects.isLoadingProjectExpanded
  );
  const isLoadingSharedProjectInfo = useAppSelector(
    state => state.sharedLinks.isLoadingSharedProjectInfo
  );

  return (
    <Grid item>
      <StyledTimeGrout variant='outlined' aria-label='outlined button group'>
        {items.map(item => (
          <Button
            key={item.value}
            onClick={item.onChange}
            disabled={
              item.isActive ||
              isLoadingProjectExpanded ||
              isLoadingSharedProjectInfo ||
              disabled
            }
            startIcon={item.icon}
          >
            {item.label}
          </Button>
        ))}
      </StyledTimeGrout>
    </Grid>
  );
};

export default TimesGroup;
