import { styled } from '@mui/system';
import { Box } from '@mui/material';

import { Paper } from '../../../../shared/paper/styles';

const Wrapper = styled(Box)`
  width: 100%;
  min-height: calc(88vh - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;

  ${props => props.theme.breakpoints.down('sm')} {
    min-height: calc(80vh - 40px);
  }
`;

const PaperContainer = styled(Paper)`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
`;

export { Wrapper, LogoContainer, PaperContainer };
