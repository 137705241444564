import React from 'react';

import useFolders from '../../../../../../hooks/useFolders';

import { StyledTablePagination, PaginationContainer } from './styles';

const Pagination = () => {
  const {
    onSetPaginationPageSize,
    onSetPaginationPage,
    folders,
    rootFolder,
    isRootView,
    tables: {
      rootFolder: { paginationModel },
    },
  } = useFolders();

  const handleSetPaginationPageSize = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onSetPaginationPageSize(Number(e.target.value));
  };

  const handleSetPaginationPage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    onSetPaginationPage(Number(page));
  };

  return (
    <PaginationContainer xs={12}>
      <StyledTablePagination
        labelRowsPerPage='Show rows:'
        count={
          isRootView
            ? rootFolder?.meta?.totalItems || 0
            : folders?.meta?.totalItems || 0
        }
        page={paginationModel.page}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onPageChange={handleSetPaginationPage}
        rowsPerPage={paginationModel.pageSize}
        onRowsPerPageChange={handleSetPaginationPageSize}
      />
    </PaginationContainer>
  );
};

export default Pagination;
