import { useParams } from 'react-router';
import { useAppSelector } from '../../../../../../../../store';
import ROUTES from '../../../../../../../../routes/constants';
import { BreadcrumbLink } from '../../../../../../../projects/components/ProjectExpanded/components/Header/Breadcrumbs/styled';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React, { useMemo } from 'react';
import { getSharedLinkToken } from '../../../../../../../../utils/localStorage';

const BreadcrumbsSharedAdditional = () => {
  const { projectId } = useParams();

  const projectShared = useAppSelector(
    state => state.sharedLinks.projectShared
  );
  const keywordRankHistory = useAppSelector(
    state => state.sharedLinks.keywordRankHistory
  );

  const breadcrumbItemProjects = useMemo(
    () =>
      projectShared?.projectName && keywordRankHistory?.keywordInfo?.keyword
        ? [
            <BreadcrumbLink
              key='Projects'
              to={`${ROUTES.dynamic.sharedLinkContents(
                getSharedLinkToken() || undefined
              )}`}
            >
              Shared With You
            </BreadcrumbLink>,
            <BreadcrumbLink
              key='project'
              to={`${ROUTES.dynamic.sharedProject(projectId)}`}
            >
              {projectShared?.projectName}
            </BreadcrumbLink>,
            <BreadcrumbLink key='project' to={'#'}>
              {keywordRankHistory?.keywordInfo?.keyword}
            </BreadcrumbLink>,
          ]
        : [],
    [
      keywordRankHistory?.keywordInfo?.keyword,
      projectId,
      projectShared?.projectName,
    ]
  );

  return (
    <MuiBreadcrumbs
      separator={<NavigateNextIcon fontSize='small' />}
      aria-label='breadcrumb'
    >
      {breadcrumbItemProjects.filter(item => item !== null) as JSX.Element[]}
    </MuiBreadcrumbs>
  );
};

export default BreadcrumbsSharedAdditional;
