import React from 'react';
import { ProjectActionProps } from './types';

import EditIcon from '../../../../../../../../../shared/images/EditIcon';
import { EditProjectModal } from '../../../../ProjectRedactorModal';

import {
  ListItemTypography,
  StyledListItem,
  StyledListItemButton,
} from '../styles';
import useProjects from '../../../../../../../hooks/useProjects';
import { useAppSelector } from '../../../../../../../../../store';
import useAccountLimits from '../../../../../../../../auth/hooks/useAccountLimits';

const EditProjectAction: React.FC<ProjectActionProps> = ({
  projectId,
  popupClose,
}) => {
  const [isOpenEditProjectModal, setIsOpenEditProjectModal] =
    React.useState(false);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const { onGetCurrentProject, onSetRowSelectionModel } = useProjects();
  const { onCheckSubscriptionExpiration } = useAccountLimits();

  const handleOpenEditProjectModal = () => {
    const isExpiration = onCheckSubscriptionExpiration();
    if (!isExpiration) {
      onGetCurrentProject({
        accountId: currentAccount.accountId,
        projectId: projectId,
        tags: true,
        notes: true,
        successFn: () => {
          setIsOpenEditProjectModal(true);
        },
      });
    }
  };

  const handleCloseEditProjectModal = () => {
    onSetRowSelectionModel([]);
    popupClose();
    setIsOpenEditProjectModal(false);
  };

  return (
    <>
      <StyledListItem onClick={handleOpenEditProjectModal}>
        <StyledListItemButton>
          <EditIcon />
          <ListItemTypography marginLeft='8px'>Edit Project</ListItemTypography>
        </StyledListItemButton>
      </StyledListItem>
      {isOpenEditProjectModal ? (
        <EditProjectModal
          open={isOpenEditProjectModal}
          handleClose={handleCloseEditProjectModal}
        />
      ) : null}
    </>
  );
};

export default EditProjectAction;
