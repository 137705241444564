import { BEACON_ID } from '../constants/config';

/**
 * Configures the Beacon display and messaging settings.
 *
 * This function sets the zIndex for the Beacon display and enables the subject field in the contact form.
 *
 * @returns {void}
 *
 * @example
 * onSetConfig(); // Configures Beacon settings.
 */
const onSetConfig = () => {
  window.Beacon('config', {
    display: {
      zIndex: '99999',
    },
    messaging: {
      contactForm: { showSubject: true },
    },
  });
};

/**
 * Initializes the Beacon with the provided BEACON_ID.
 *
 * This function sets up the Beacon service for usage with the specified ID.
 *
 * @returns {void}
 *
 * @example
 * onInit(); // Initializes the Beacon service.
 */
const onInit = () => {
  window.Beacon('init', BEACON_ID);
};

/**
 * Logs the user out of the Beacon service.
 *
 * This function ends the active chat if one is ongoing.
 *
 * @returns {void}
 *
 * @example
 * onLogout(); // Logs the user out of Beacon and ends any active chat.
 */
const onLogout = () => {
  window.Beacon('logout', { endActiveChat: true });
};

/**
 * Identifies the user in the Beacon system.
 *
 * This function sends user identification details to Beacon, including name, email, and signature.
 *
 * @param {string} name - The name of the user.
 * @param {string} email - The email address of the user.
 * @param {string} signature - The user's signature or identifier.
 * @returns {void}
 *
 * @example
 * onIdentify('John Doe', 'john.doe@example.com', '12345'); // Identifies the user in Beacon.
 */
const onIdentify = (name: string, email: string, signature: string) => {
  window.Beacon('identify', {
    name,
    email,
    signature,
  });
};

/**
 * Prefills the contact form in Beacon with a default subject.
 *
 * This function sets the subject of the contact form to a predefined message.
 *
 * @returns {void}
 *
 * @example
 * onPrefill(); // Prefills the Beacon contact form subject.
 */
const onPrefill = () => {
  window.Beacon('prefill', {
    subject: 'Your Serpnest Support Request',
  });
};

/**
 * Toggles the visibility of the Help Scout Beacon.
 *
 * This function opens or closes the Help Scout widget based on its current state.
 *
 * @returns {void}
 *
 * @example
 * onToggleHelpScout(); // Toggles the Help Scout widget visibility.
 */
const onToggleHelpScout = () => {
  window.Beacon('toggle');
};

/**
 * Retrieves information about the current state of Help Scout.
 *
 * This function returns information from the Beacon regarding its current state or user info.
 *
 * @returns {any} The information returned by the Beacon.
 *
 * @example
 * const info = onGetInfoHelpScout(); // Retrieves info from Beacon.
 */
const onGetInfoHelpScout = () => {
  return window.Beacon('info');
};

export {
  onSetConfig,
  onInit,
  onLogout,
  onIdentify,
  onPrefill,
  onToggleHelpScout,
  onGetInfoHelpScout,
};
