import React, { useCallback, useEffect, useState } from 'react';
import {
  debounce,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useProjects, useFolders } from '../../../../../../../../hooks';

import { PopupTableSettings } from './components';
import PopupTags from './components/PopupTags/PopupTags';
import PlusIcon from '../../../../../../../../shared/images/PlusIcon';
import SearchIcon from '../../../../../../../../shared/images/SearchIcon';
import ActionsMenu from '../ActionsMenu/ActionsMenu';
import { NewFolderModal, NewProjectModal } from '../Modals';
import FilterIcon from '../../../../../../../../shared/images/FilterIcon';

import { CountLabel } from './styles';
import {
  GrayButtonSmall,
  GrayButtonSmallForSelect,
  PrimaryButtonSmall,
} from '../../../../../../../../shared/buttons/styles';
import { OutlinedInputCustom } from '../../../../../../../../shared/inputs/styled';
import { useAppSelector } from '../../../../../../../../store';
import useAccountLimits from '../../../../../../../auth/hooks/useAccountLimits';

const Header = () => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');
  const extraTooSmallScreen = useMediaQuery('(max-width:550px)');
  const seScreen = useMediaQuery('(max-width:400px)');

  const { onSetShowTableFilters } = useProjects();
  const { onCheckSubscriptionExpiration } = useAccountLimits();

  const isShowTableFilters = useAppSelector(
    state => state.projects.isShowTableFilters
  );

  const {
    currentFolder,
    onSetRowSelectionModel,
    onSetSearchFolders,
    folders,
    rootFolder,
    isRootView,
    tables: {
      rootFolder: { rowSelectionModel, search },
    },
  } = useFolders();

  const folderTree = useAppSelector(state => state.auth.myAccount.folderTree);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const [isOpenNewFolderModal, setIsOpenNewFolderModal] =
    React.useState<boolean>(false);

  const [isOpenNewProjectModal, setIsOpenNewProjectModal] =
    React.useState<boolean>(false);

  const handleOpenNewFolderModal = () => setIsOpenNewFolderModal(true);
  const handleCloseNewFolderModal = () => setIsOpenNewFolderModal(false);

  const handleOpenNewProjectModal = () => {
    const isExpiration = onCheckSubscriptionExpiration();
    if (!isExpiration) {
      setIsOpenNewProjectModal(true);
    }
  };
  const handleCloseNewProjectModal = () => setIsOpenNewProjectModal(false);

  const [localSearch, setLocalSearch] = useState(search);

  const [showSearchMobile, setShowSearchMobile] = useState(false);

  const onClearSelected = () => {
    onSetRowSelectionModel([]);
  };

  const debounceSearch = useCallback(
    debounce(value => {
      onSetSearchFolders(value);
    }, 500),
    [onSetSearchFolders]
  );

  const handleSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLocalSearch(event.target.value);
    debounceSearch(event.target.value);
  };

  const handleClearSearch = () => {
    setLocalSearch('');
    debounceSearch('');
  };

  const handleShowFilters = () => onSetShowTableFilters(!isShowTableFilters);

  useEffect(() => {
    if (!search) {
      setLocalSearch('');
    }
  }, [search]);

  return (
    <Grid
      container
      item
      justifyContent='space-between'
      alignItems='center'
      flexDirection='column'
      sx={{
        padding: !extraSmallScreen
          ? '32px 24px 24px 24px'
          : '8px 24px 24px 24px',
      }}
    >
      {extraSmallScreen ? (
        <Grid item container marginBottom='24px' justifyContent='flex-end'>
          <Grid
            item
            sx={{
              marginRight: '12px',
            }}
          >
            <GrayButtonSmallForSelect
              isSelected={isShowTableFilters}
              sx={{
                padding: `${
                  seScreen ? '7px 12px !important' : '5px 12px !important'
                }`,
                minWidth: 'fit-content',
              }}
              size='small'
              outline
              startIcon={
                !seScreen ? (
                  <FilterIcon
                    color={isShowTableFilters ? '#0E59BA' : '#344054'}
                  />
                ) : null
              }
              onClick={handleShowFilters}
            >
              {!seScreen ? (
                'Filters'
              ) : (
                <FilterIcon
                  color={isShowTableFilters ? '#0E59BA' : '#344054'}
                />
              )}
            </GrayButtonSmallForSelect>
          </Grid>

          {currentAccount?.role?.name !== 'ViewOnly' && (
            <PrimaryButtonSmall
              sx={{
                height: '36px',
                minWidth: '40px !important',
              }}
              variant='contained'
              size='small'
              onClick={handleOpenNewFolderModal}
            >
              <PlusIcon />
            </PrimaryButtonSmall>
          )}

          {!isRootView && currentAccount?.role?.name !== 'ViewOnly' && (
            <GrayButtonSmall
              disabled={!folderTree}
              sx={{
                minWidth: '40px !important',
                ml: '8px',
                padding: '5px 15px !important',
              }}
              outline
              size='small'
              onClick={handleOpenNewProjectModal}
            >
              <PlusIcon stroke={'#475467'} />
              Add new Project
            </GrayButtonSmall>
          )}
        </Grid>
      ) : null}

      {isShowTableFilters && extraSmallScreen ? (
        <Grid
          item
          container
          alignItems='center'
          sx={{ marginBottom: '16px', gap: '6px' }}
        >
          <Grid item>
            <PopupTags />
          </Grid>
          <Grid item>
            <PopupTableSettings />
          </Grid>
        </Grid>
      ) : null}

      <Grid
        container
        item
        justifyContent='space-between'
        sx={{
          gap:
            extraTooSmallScreen && rowSelectionModel.length && showSearchMobile
              ? '8px'
              : !extraSmallScreen
              ? '8px'
              : !seScreen
              ? '0'
              : '8px',
          flexDirection:
            extraTooSmallScreen && rowSelectionModel.length && showSearchMobile
              ? 'column-reverse'
              : !seScreen
              ? 'row'
              : !showSearchMobile
              ? 'row'
              : 'column-reverse',
          alignItems:
            extraTooSmallScreen && rowSelectionModel.length && showSearchMobile
              ? 'flex-start'
              : !seScreen
              ? 'center'
              : !showSearchMobile
              ? 'center'
              : 'flex-start',
        }}
      >
        <Grid item>
          {rowSelectionModel.length ? (
            <Grid container item>
              <Grid container item alignItems='center'>
                <Grid item>
                  <IconButton
                    onClick={onClearSelected}
                    sx={{ padding: '0 !important', marginRight: '8px' }}
                  >
                    <CloseIcon fontSize='small' />
                  </IconButton>
                </Grid>
                <Grid item sx={{ paddingRight: '16px' }}>
                  <Typography
                    fontSize='14px'
                    lineHeight='14px'
                    color='#101828'
                    fontWeight='500'
                    alignItems='center'
                  >
                    {`${rowSelectionModel.length} Selected`}
                  </Typography>
                </Grid>
                <Grid item>
                  <ActionsMenu />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              item
              alignItems='center'
              display={
                !seScreen &&
                extraSmallScreen &&
                showSearchMobile &&
                !rowSelectionModel.length
                  ? 'none'
                  : 'flex'
              }
            >
              <Grid item marginRight='8px'>
                <Typography
                  variant='h6'
                  fontWeight='600'
                  color='#101828'
                  fontSize='18px'
                  lineHeight='28px'
                >
                  All Folders
                </Typography>
              </Grid>
              <Grid item>
                <CountLabel>
                  {isRootView
                    ? rootFolder?.items?.length || 0
                    : folders?.items?.length || 0}
                </CountLabel>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid
          sx={{
            gap: '8px',
            width:
              extraTooSmallScreen &&
              rowSelectionModel.length &&
              showSearchMobile
                ? '100%'
                : extraSmallScreen &&
                  showSearchMobile &&
                  !rowSelectionModel.length
                ? '100%'
                : 'initial',
            maxWidth:
              extraTooSmallScreen &&
              rowSelectionModel.length &&
              showSearchMobile
                ? 'initial'
                : seScreen
                ? 'initial'
                : extraSmallScreen &&
                  showSearchMobile &&
                  rowSelectionModel.length
                ? '50%'
                : 'initial',
          }}
          item
        >
          <Grid
            item
            container
            sx={{
              gap: '8px',
            }}
          >
            <Grid
              item
              xs={extraSmallScreen && showSearchMobile ? 12 : undefined}
            >
              <OutlinedInputCustom
                sx={{
                  width: extraSmallScreen && !showSearchMobile ? '44px' : null,
                }}
                $padding={'7.94px 14px 7.94px 0'}
                fullWidth
                size='small'
                type='text'
                id='searchProject'
                name='searchProject'
                placeholder='Search keyword'
                value={localSearch}
                onChange={handleSearch}
                onFocus={() => {
                  extraSmallScreen && setShowSearchMobile(true);
                }}
                startAdornment={
                  <InputAdornment position='start' disablePointerEvents>
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  extraSmallScreen && showSearchMobile ? (
                    <>
                      <Typography
                        fontSize='14px'
                        fontWeight='600'
                        lineHeight='20px'
                        color='#126FE9'
                        sx={{ cursor: 'pointer', paddingRight: '4px' }}
                        onClick={handleClearSearch}
                      >
                        Clear
                      </Typography>
                      <CloseIcon
                        fontSize='small'
                        sx={{ color: '#101828', cursor: 'pointer' }}
                        onClick={() =>
                          extraSmallScreen && setShowSearchMobile(false)
                        }
                      />
                    </>
                  ) : null
                }
              />
            </Grid>

            {!extraSmallScreen ? (
              <>
                <Grid item sx={{ height: '100%' }}>
                  <PopupTags />
                </Grid>
                <Grid item>
                  <PopupTableSettings />
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <NewFolderModal
        open={isOpenNewFolderModal}
        handleClose={handleCloseNewFolderModal}
      />
      <NewProjectModal
        handleClose={handleCloseNewProjectModal}
        open={isOpenNewProjectModal}
        folderId={currentFolder.id}
      />
    </Grid>
  );
};

export default Header;
