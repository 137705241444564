import Autocomplete from '@mui/material/Autocomplete';
import Fade from '@mui/material/Fade';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';

import { InviteUserEditProps } from '../types';
import { CustomFormHelperText } from '../../../../../../../shared/components';
import * as Tooltips from './Tooltips';
import HelperIcon from '../../../../../../../shared/images/HelperIcon';
import { useAuth, useFolders, useProjects } from '../../../../../../../hooks';
import CheckboxWrapper from '../components/CheckboxWrapper';
import CustomToast from '../../../../../../../shared/CustomToast/CustomToast';
import { closeSnackbarAction } from '../../../../../../../utils/snackbar';

import { CustomTooltip } from '../../../../../../../shared/tooltip/styles';
import {
  ModalContainer,
  ModalWrapper,
  ButtonsWrapper,
} from './customTableStyles';
import { CloseDeletePopoverOne } from '../../Account/styles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../../../shared/buttons/styles';
import SelectIcon from '../../../../../../../shared/components/SelectIcon';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from '../../../../../../../store';
import {
  ModalAutocompleteStylesProps,
  ModalMultipleAutocompleteStylesProps,
} from '../../../../../../../shared/modal/styled';
import { filter, filterFolder } from '../utils';
import { StyledAutocompleteLi, StyledChip } from './styles';
import AutocompletePaper from '../../../../../../../shared/AutocompletePaper';
import FolderIcon from '../../../../../../../shared/images/FolderIcon';
import GlobeIcon from '../../../../../../../shared/images/GlobeIcon';
import { Role } from '../../../../../types';

const EditUserModal: React.FC<InviteUserEditProps> = ({
  registered,
  open,
  handleClose,
  popupStateClose,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');
  const { onEditUserTeam, onGetUsers } = useAuth();
  const { onGetProjectsListForInvite } = useProjects();
  const { onGetAvailableFolders, listAvailableFolders } = useFolders();

  const sortModelTeam = useAppSelector(
    state => state.auth.sorting.sortModelTeam
  );
  const searchKeywordUsersTeam = useAppSelector(
    state => state.auth.search.searchKeywordUsersTeam
  );
  const meta = useAppSelector(state => state.auth.users.meta);
  const roles = useAppSelector(state => state.auth.roles);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const projectsListForInvite = useAppSelector(
    state => state.projects.projectsListForInvite
  );

  const userTeam = useAppSelector(state => state.auth.activeUserTeam.userTeam);
  const invitationTeam = useAppSelector(
    state => state.auth.activeUserTeam.invitationTeam
  );

  const [showTooltip, setShowTooltip] = useState(false);
  const [openTooltip, setOpenTooltip] = useState<number | undefined>();

  const onOpenTooltip = (tooltipIndex?: number) => {
    setShowTooltip(true);
    setOpenTooltip(tooltipIndex);
  };

  const onClickTooltip = (tooltipIndex?: number) => {
    if (showTooltip) {
      setShowTooltip(false);
      setOpenTooltip(undefined);
    } else {
      setShowTooltip(true);
      setOpenTooltip(tooltipIndex);
    }
  };

  const onClose = () => {
    handleClose();
    popupStateClose();
    formik.resetForm();
  };

  const editedUser = useMemo(
    () => (registered ? userTeam : invitationTeam),
    [invitationTeam, registered, userTeam]
  );

  const defaultInitialValues = useMemo(() => {
    const role = editedUser ? editedUser.role : null;
    const projects = editedUser ? editedUser.projects : [];
    const folders = editedUser ? editedUser.folders : [];

    return { role, projects, folders, message: '' };
  }, [editedUser]);

  const formik = useFormik({
    initialValues: defaultInitialValues,
    onSubmit: (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
      if (editedUser) {
        const requestData = {
          roleName: values?.role?.name || '',
          projectIds: values.projects.map(item => item.id),
          folderIds: values.folders.map(item => item.id),
        };
        onEditUserTeam({
          data: requestData,
          accountId: currentAccount.accountId,
          userId: editedUser.id,
          successFn: () => {
            resetForm();
            handleClose();
            onGetUsers({
              accountId: currentAccount.accountId,
              page: meta.currentPage,
              limit: meta.itemsPerPage,
              search: searchKeywordUsersTeam,
              sortBy: sortModelTeam[0]?.field,
              sortOrder: sortModelTeam[0]?.sort,
            });
            enqueueSnackbar(
              <CustomToast
                message='Success'
                submessage='User details have been successfully updated.'
              />,
              { action: closeSnackbarAction }
            );
          },
          errorFn: (error: any) => {
            setStatus({ success: false });
            setSubmitting(false);
            setErrors(error);
          },
        });
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue('role', editedUser?.role || null);
    formik.setFieldValue('projects', editedUser?.projects || []);
    formik.setFieldValue('folders', editedUser?.folders || []);
  }, [editedUser]);

  useEffect(() => {
    if (open && currentAccount?.accountId) {
      onGetProjectsListForInvite({
        accountId: currentAccount.accountId,
        search: '',
      });
      onGetAvailableFolders({ accountId: currentAccount.accountId });
    }
  }, [
    currentAccount?.accountId,
    onGetAvailableFolders,
    onGetProjectsListForInvite,
    open,
  ]);

  if (!editedUser) {
    return null;
  }

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open}
      onClose={onClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <form onSubmit={formik.handleSubmit}>
          <ModalWrapper>
            <ModalContainer container xs={12}>
              <Grid item xs={12} sx={{ position: 'relative' }}>
                <CloseDeletePopoverOne onClick={onClose}>
                  <CloseIcon fontSize='small' />
                </CloseDeletePopoverOne>
                <Typography
                  fontSize='18px'
                  fontWeight='600'
                  lineHeight='28px'
                  color='#101828'
                >
                  Edit user
                </Typography>
                <Typography fontSize='14px' lineHeight='20px' color='#475467'>
                  Modify user permissions for this account
                </Typography>
              </Grid>
              <Grid item xs={12} paddingTop='8px'>
                <Grid
                  container
                  fontWeight='500'
                  alignItems='center'
                  fontSize='14px'
                >
                  <Grid item>Role</Grid>
                  <Grid item>
                    <CustomTooltip
                      title={<Tooltips.RoleForNewUser />}
                      placement='top-start'
                      arrow
                      open={showTooltip && openTooltip === 1}
                      onOpen={() => onOpenTooltip(1)}
                      onClose={() => onClickTooltip(1)}
                      onClick={() => onClickTooltip(1)}
                    >
                      <IconButton onClick={() => onClickTooltip(1)}>
                        <HelperIcon />
                      </IconButton>
                    </CustomTooltip>
                  </Grid>
                </Grid>
                <Autocomplete
                  sx={ModalAutocompleteStylesProps}
                  fullWidth
                  size={'small'}
                  id='role'
                  options={roles}
                  getOptionLabel={option => option.name}
                  value={formik?.values?.role?.name ? formik.values.role : null}
                  onChange={(_, value) =>
                    formik.setFieldValue('role', value || {})
                  }
                  onBlur={formik.handleBlur}
                  popupIcon={<SelectIcon />}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder='Select role'
                      name='role'
                      error={!!(formik.touched.role && formik.errors.role)}
                    />
                  )}
                />

                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  formik.touched.role && formik.errors.role?.name && (
                    <FormHelperText error id='role'>
                      <CustomFormHelperText
                        error={
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          formik.errors.role.name
                        }
                      />
                    </FormHelperText>
                  )
                }
              </Grid>
              <Grid item xs={12} paddingTop='8px'>
                <Grid
                  container
                  fontWeight='500'
                  alignItems='center'
                  fontSize='14px'
                >
                  <Grid item>Access</Grid>
                  <Grid item>
                    <CustomTooltip
                      title={<Tooltips.AccessForNewUser />}
                      placement='top-start'
                      arrow
                      open={showTooltip && openTooltip === 2}
                      onOpen={() => onOpenTooltip(2)}
                      onClose={() => onClickTooltip(2)}
                      onClick={() => onClickTooltip(2)}
                    >
                      <IconButton onClick={() => onClickTooltip(2)}>
                        <HelperIcon />
                      </IconButton>
                    </CustomTooltip>
                  </Grid>
                </Grid>
                <Autocomplete
                  sx={
                    formik.values.folders.length
                      ? ModalMultipleAutocompleteStylesProps
                      : ModalAutocompleteStylesProps
                  }
                  disabled={!listAvailableFolders.length}
                  style={{ marginBottom: '6px' }}
                  multiple
                  disableCloseOnSelect
                  fullWidth
                  size={'small'}
                  id='folders'
                  limitTags={2}
                  options={listAvailableFolders}
                  filterOptions={(options, params) => {
                    const filtered = filterFolder(options, params);
                    return [{ id: 0, name: 'Select all' }, ...filtered];
                  }}
                  getOptionLabel={option => option.name}
                  renderOption={(props, option, { selected }) => {
                    const findItem = formik.values.folders.find(
                      item => item.id === option.id
                    );
                    const isSelected =
                      option.id === 0
                        ? listAvailableFolders.length ===
                          formik.values.folders.length
                        : selected;
                    return (
                      <StyledAutocompleteLi {...props} key={option.id}>
                        <CheckboxWrapper checked={!!findItem || isSelected} />
                        <FolderIcon />
                        <Grid marginLeft={'8px'}>{option.name}</Grid>
                      </StyledAutocompleteLi>
                    );
                  }}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <StyledChip
                          label={option.name}
                          deleteIcon={
                            <IconButton>
                              <CloseIcon
                                width='12'
                                height='12'
                                {...getTagProps({ index })}
                              />
                            </IconButton>
                          }
                          {...getTagProps({ index })}
                        />
                      );
                    })
                  }
                  value={formik.values.folders}
                  onChange={(e, value) => {
                    const count: { [key: number]: number } = {};
                    value.forEach(item => {
                      count[item.id] = (count[item.id] || 0) + 1;
                    });
                    const filterList = value.filter(
                      item => count[item.id] === 1
                    );

                    if (value.find(option => option.name === 'Select all')) {
                      if (
                        listAvailableFolders.length !==
                        formik.values.folders.length
                      ) {
                        formik.setFieldValue('folders', listAvailableFolders);
                      } else {
                        formik.setFieldValue('folders', []);
                      }
                    } else {
                      formik.setFieldValue('folders', filterList || []);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  popupIcon={<SelectIcon />}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder='Select folder to give access'
                      name='folders'
                      error={
                        !!(formik.touched.folders && formik.errors.folders)
                      }
                    />
                  )}
                  PaperComponent={AutocompletePaper}
                />
                {formik.touched.folders && formik.errors?.folders && (
                  <FormHelperText error id='folders'>
                    <CustomFormHelperText
                      error={formik.errors.folders.toString()}
                    />
                  </FormHelperText>
                )}
                {
                  <FormHelperText error={!!formik.errors.message} id='message'>
                    {formik.errors.message && (
                      <CustomFormHelperText error={formik.errors.message} />
                    )}
                  </FormHelperText>
                }
                <Autocomplete
                  sx={
                    formik.values.projects.length
                      ? ModalMultipleAutocompleteStylesProps
                      : ModalAutocompleteStylesProps
                  }
                  disabled={!projectsListForInvite.items.length}
                  style={{ marginBottom: '6px' }}
                  multiple
                  disablePortal
                  disableCloseOnSelect
                  fullWidth
                  size={'small'}
                  id='projects'
                  limitTags={2}
                  options={projectsListForInvite.items}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    return [{ id: 0, projectName: 'Select all' }, ...filtered];
                  }}
                  getOptionLabel={option => option.projectName}
                  renderOption={(props, option, { selected }) => {
                    const findItem = formik.values.projects.find(
                      item => item.id === option.id
                    );
                    const isSelected =
                      option.id === 0
                        ? projectsListForInvite.items.length ===
                          formik.values.projects.length
                        : selected;
                    return (
                      <StyledAutocompleteLi {...props} key={option.id}>
                        <Grid container>
                          <CheckboxWrapper checked={!!findItem || isSelected} />
                          <Grid
                            item
                            display={'flex'}
                            alignItems={'center'}
                            marginRight={'8px'}
                          >
                            {option?.favicon ? (
                              <img
                                src={option.favicon}
                                width={16}
                                height={16}
                                alt={'Company Logo URL'}
                              />
                            ) : (
                              <GlobeIcon />
                            )}
                          </Grid>
                          <Grid item>{option.projectName}</Grid>
                        </Grid>
                      </StyledAutocompleteLi>
                    );
                  }}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <StyledChip
                          label={option.projectName}
                          deleteIcon={
                            <IconButton>
                              <CloseIcon
                                width='12'
                                height='12'
                                {...getTagProps({ index })}
                              />
                            </IconButton>
                          }
                          {...getTagProps({ index })}
                        />
                      );
                    })
                  }
                  value={formik.values.projects}
                  onChange={(_, value) => {
                    const count: { [key: number]: number } = {};
                    value.forEach(item => {
                      count[item.id] = (count[item.id] || 0) + 1;
                    });
                    const filterList = value.filter(
                      item => count[item.id] === 1
                    );

                    if (
                      value.find(option => option.projectName === 'Select all')
                    ) {
                      if (
                        projectsListForInvite.items.length !==
                        formik.values.projects.length
                      ) {
                        formik.setFieldValue(
                          'projects',
                          projectsListForInvite.items
                        );
                      } else {
                        formik.setFieldValue('projects', []);
                      }
                    } else {
                      formik.setFieldValue('projects', filterList || []);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  popupIcon={<SelectIcon />}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder='Select project to give access'
                      name='projects'
                      error={
                        !!(formik.touched.projects && formik.errors.projects)
                      }
                    />
                  )}
                  PaperComponent={AutocompletePaper}
                />
                {formik.touched.projects && formik.errors?.projects && (
                  <FormHelperText error id='projects'>
                    <CustomFormHelperText
                      error={formik.errors.projects.toString()}
                    />
                  </FormHelperText>
                )}
              </Grid>

              <ButtonsWrapper container xs={12}>
                <Grid item xs={extraSmallScreen && 12}>
                  <GrayButtonSmall
                    fullWidth={extraSmallScreen}
                    size='small'
                    outline
                    onClick={handleClose}
                  >
                    Cancel
                  </GrayButtonSmall>
                </Grid>
                <Grid item xs={extraSmallScreen && 12}>
                  <PrimaryButtonSmall
                    fullWidth={extraSmallScreen}
                    variant='contained'
                    size='small'
                    type='submit'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    Edit User
                  </PrimaryButtonSmall>
                </Grid>
              </ButtonsWrapper>
            </ModalContainer>
          </ModalWrapper>
        </form>
      </Fade>
    </Modal>
  );
};

export default EditUserModal;
