import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StylesAlertsSwitch = styled(Grid)`
  border-radius: 8px;
  border: 1px solid #ededed;
  background: #f6f6f6;
  width: fit-content;
`;

const StylesNoActive = styled(Grid)`
  color: #667085;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 1px 16px;
  margin: 3px;
  cursor: pointer;
  border: 1px solid transparent;
`;

const StylesActive = styled(Grid)`
  border-radius: 8px;
  border: 1px solid #ededed;
  background: #fff;
  box-shadow: 0 5px 3px -2px rgba(0, 0, 0, 0.02),
    0px 3px 1px -2px rgba(0, 0, 0, 0.06);
  padding: 1px 16px;
  margin: 3px;
  cursor: pointer;

  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

export { StylesAlertsSwitch, StylesNoActive, StylesActive };
