import React from 'react';
import { StyledReportValidated, StyledReportValidatedText } from './styles';
import { Grid } from '@mui/material';
import CheckVerifiedIcon from '../images/CheckVerifiedIcon';
import SerpNestLogo from '../images/SerpNestLogo';
import { useAppSelector } from '../../store';

const ReportValidated = () => {
  const sharedLinkSettings = useAppSelector(
    state => state.sharedLinks.sharedLinkSettings
  );

  if (sharedLinkSettings?.accountInfo?.validatedBySerpneest) {
    return null;
  }

  return (
    <StyledReportValidated xs={12} display={'flex'} justifyContent={'center'}>
      <Grid
        xs={11}
        sm={10}
        md={10}
        lg={10}
        gap={'8px'}
        display={'flex'}
        padding={'16px 0'}
        alignItems={'center'}
      >
        <CheckVerifiedIcon />
        <StyledReportValidatedText>
          Report validated by
        </StyledReportValidatedText>
        <SerpNestLogo />
      </Grid>
    </StyledReportValidated>
  );
};

export default ReportValidated;
