import React from 'react';
import { Grid } from '@mui/material';

interface DashProps {
  text?: string;
}

const Dash: React.FC<DashProps> = ({ text = '-' }) => (
  <Grid display={'flex'} justifyContent={'center'} color={'#667085'}>
    {text}
  </Grid>
);

export default Dash;
