import React from 'react';

const DownRedIcon = () => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      id='Vector'
      d='M6.61719 13.5L13.1172 7L9.32552 7L9.32552 0.5L3.90886 0.499999L3.90886 7L0.117188 7L6.61719 13.5Z'
      fill='#EC5962'
    />
  </svg>
);

export default DownRedIcon;
