import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useMemo, useState } from 'react';
import {
  CustomTooltip,
  DateCustomTooltip,
} from '../../../shared/tooltip/styles';
import TextTooltip from '../../../shared/components/TextTooltip';
import ViewInvoice from '../components/Settings/components/Billing/components/Invoice/components/InvoiceTable/components/ViewInvoice/ViewInvoice';
import { customSortComparator } from '../../emailReports/hooks/useScheduledEmailReportsTable';
import { Skeleton } from '@mui/material';
import { convertToTimezone } from '../../../utils/date';
import { SERVER_UTS } from '../../../constants/config';
import { useAppSelector } from '../../../store';

/**
 * Custom hook to manage the columns and column skeletons for the Invoice table.
 * @returns {{ columns: GridColDef[], columnsSkeleton: GridColDef[] }} The columns and their skeletons for the Invoice table.
 */
export default function useInvoiceTable() {
  const user = useAppSelector(state => state.auth.user);

  const [showBillingDateFullFormat, setShowBillingDateFullFormat] =
    useState<boolean>(false);

  const popperProps = {
    sx: {
      '& .MuiTooltip-tooltip': {
        backgroundColor: '#fff',
        color: '#344054',
        boxShadow:
          '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
        border: '1px solid #EDEDED',
      },
      '.MuiTooltip-arrow::before': {
        backgroundColor: '#fff',
        border: '1px solid #EDEDED',
      },
    },
  };

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'id',
        sortable: true,
        headerName: 'Invoice ID',
        minWidth: 150,
        headerAlign: 'left',
        sortComparator: customSortComparator,
        renderCell: (params: GridRenderCellParams) => (
          <CustomTooltip
            title={<TextTooltip text={params.row.transactionId || '-'} />}
            placement='top-start'
            arrow
            PopperProps={popperProps}
          >
            {params.row.transactionId || '-'}
          </CustomTooltip>
        ),
      },
      {
        field: 'billingDate',
        sortable: true,
        headerName: 'Billing Date',
        maxWidth: 250,
        minWidth: 150,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: (params: GridRenderCellParams) => {
          const date = showBillingDateFullFormat
            ? convertToTimezone(
                params.row.billingDateFullFormat,
                SERVER_UTS,
                user.timezone.utc
              )
            : params.row.billingDate;
          return (
            <DateCustomTooltip
              title={<TextTooltip text={date} />}
              placement='top-start'
              arrow
              PopperProps={popperProps}
              onClick={e => {
                e.stopPropagation();
                setShowBillingDateFullFormat(!showBillingDateFullFormat);
              }}
            >
              {date}
            </DateCustomTooltip>
          );
        },
      },
      {
        field: 'plan',
        sortable: true,
        headerName: 'Plan',
        minWidth: 200,
        sortComparator: customSortComparator,
        renderCell: (params: GridRenderCellParams) => (
          <CustomTooltip
            title={<TextTooltip text={params.row.plan.name} />}
            placement='top-start'
            arrow
            PopperProps={popperProps}
          >
            {params.row.plan.name}
          </CustomTooltip>
        ),
      },
      {
        field: 'amount',
        sortable: true,
        headerName: 'Amount',
        minWidth: 120,
        maxWidth: 300,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: (params: GridRenderCellParams) => (
          <CustomTooltip
            title={<TextTooltip text={`${params.row.amount}$`} />}
            placement='top-start'
            arrow
            PopperProps={popperProps}
          >
            <div>${params.row.amount}</div>
          </CustomTooltip>
        ),
      },
      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 60,
        align: 'left',
        renderCell: (params: GridRenderCellParams) => (
          <ViewInvoice transactionId={params.row.transactionId} />
        ),
      },
    ];
  }, [showBillingDateFullFormat, user?.timezone]);

  const columnsSkeleton: GridColDef[] = useMemo(
    () => [
      {
        field: 'id',
        sortable: true,
        headerName: 'Invoice ID',
        minWidth: 150,
        headerAlign: 'center',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'billingDate',
        sortable: true,
        headerName: 'Billing Date',
        maxWidth: 140,
        flex: 1,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'plan',
        sortable: true,
        headerName: 'Plan',
        minWidth: 200,
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'amount',
        sortable: true,
        headerName: 'Amount',
        minWidth: 120,
        headerAlign: 'right',
        align: 'right',
        sortComparator: customSortComparator,
        renderCell: () => <Skeleton width='100%' />,
      },
      {
        field: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        width: 60,
        align: 'left',
        renderCell: () => <Skeleton width='100%' />,
      },
    ],
    []
  );

  return {
    columns,
    columnsSkeleton,
  };
}
