import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StyledReportValidated = styled(Grid)`
  width: 100%;
  background: #f5faff;
  border-bottom: 1px solid #71a9f2;
`;

const StyledReportValidatedText = styled('span')`
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export { StyledReportValidated, StyledReportValidatedText };
