import React from 'react';
import {
  Fade,
  FormControlLabel,
  FormHelperText,
  Grid,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  ButtonsWrapper,
  CloseDeletePopoverOne,
  CustomModal,
  HeaderContainer,
  ModalContainer,
} from '../../../Account/styles';
import CloseIcon from '@mui/icons-material/Close';
import { CustomFormHelperText } from '../../../../../../../../shared/components';
import {
  ErrorButton,
  GrayButton,
} from '../../../../../../../../shared/buttons/styles';
import { useFormik } from 'formik';
import { SadToSeeYouGoModalProps } from './types';
import SadFaceIcon from '../../../../../../../../shared/images/SadFaceIcon';
import { StyledDescriptions } from './styles';
import { StyledExportGraphLabel } from '../../../../../../../projects/components/modals/ExportGraphModal/styles';
import usePayment from '../../../../../../../payment/hooks/usePayment';
import { useAuth } from '../../../../../../../../hooks';
import { useAppSelector } from '../../../../../../../../store';
import * as Yup from 'yup';

const SadToSeeYouGoModal: React.FC<SadToSeeYouGoModalProps> = ({
  handleClose,
  open,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const { onGetMyAccount, onGetBilling } = useAuth();
  const { onUnsubscription } = usePayment();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const formik = useFormik({
    initialValues: {
      reasonText: '',
      typeOfReason: '',
    },
    validationSchema: Yup.object().shape({
      typeOfReason: Yup.string().required('Please select one of the reasons.'),
    }),
    onSubmit: (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
      if (currentAccount?.accountId) {
        onUnsubscription({
          id: currentAccount.accountId,
          data: {
            reason: values.reasonText,
            typeOfReason: values.typeOfReason,
          },
          successFn: () => {
            resetForm();
            onGetMyAccount(currentAccount.accountId);
            onGetBilling({ id: currentAccount.accountId });
            handleClose();
          },
          errorFn: (error: any) => {
            setStatus({ success: false });
            setSubmitting(false);
            setErrors(error);
          },
        });
      }
    },
  });

  const handleCloseAndReset = () => {
    formik.resetForm();
    handleClose();
  };

  const onChangeReasonSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('typeOfReason', event.target.value);
  };

  return (
    <Modal
      disableAutoFocus
      open={open}
      onClose={handleCloseAndReset}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <CustomModal>
          <form onSubmit={formik.handleSubmit}>
            <ModalContainer>
              <HeaderContainer>
                <CloseDeletePopoverOne onClick={handleCloseAndReset}>
                  <CloseIcon fontSize='small' />
                </CloseDeletePopoverOne>
                <SadFaceIcon />
                <Typography variant='h6' fontWeight='600' paddingTop='29px'>
                  Sad to see you go
                </Typography>
                <StyledDescriptions>
                  Before you cancel, please let us know the reason you are
                  leaving.
                </StyledDescriptions>
              </HeaderContainer>

              <Grid xs={12} padding={'24px 0'}>
                <RadioGroup
                  aria-labelledby='reasonSelect'
                  value={formik.values.typeOfReason}
                  name='typeOfReason'
                  onChange={onChangeReasonSelect}
                >
                  <FormControlLabel
                    value='Technical issues'
                    control={<Radio size='small' />}
                    label={
                      <StyledExportGraphLabel>
                        <span>Technical issues</span>
                      </StyledExportGraphLabel>
                    }
                  />
                  <FormControlLabel
                    value='Too expensive'
                    control={<Radio size='small' />}
                    label={
                      <StyledExportGraphLabel>
                        <span>Too expensive</span>
                      </StyledExportGraphLabel>
                    }
                  />
                  <FormControlLabel
                    value='Features I need are missing'
                    control={<Radio size='small' />}
                    label={
                      <StyledExportGraphLabel>
                        <span>Features I need are missing</span>
                      </StyledExportGraphLabel>
                    }
                  />
                  <FormControlLabel
                    value='Switching to another product'
                    control={<Radio size='small' />}
                    label={
                      <StyledExportGraphLabel>
                        <span>Switching to another product</span>
                      </StyledExportGraphLabel>
                    }
                  />
                  <FormControlLabel
                    value='Other'
                    control={<Radio size='small' />}
                    label={
                      <StyledExportGraphLabel>
                        <span>Other (please specify below)</span>
                      </StyledExportGraphLabel>
                    }
                  />
                </RadioGroup>
                {formik.errors.typeOfReason && (
                  <FormHelperText error id='typeOfReason'>
                    <CustomFormHelperText error={formik.errors.typeOfReason} />
                  </FormHelperText>
                )}
              </Grid>

              <div>
                <OutlinedInput
                  size='small'
                  type={'text'}
                  id='reasonText'
                  name='reasonText'
                  placeholder='Anything you want to share?'
                  fullWidth
                  multiline
                  rows={4}
                  value={formik.values.reasonText}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!(formik.touched.reasonText && formik.errors.reasonText)
                  }
                />
                {formik.touched.reasonText && formik.errors.reasonText && (
                  <FormHelperText error id='reasonText'>
                    <CustomFormHelperText error={formik.errors.reasonText} />
                  </FormHelperText>
                )}
              </div>

              <ButtonsWrapper container xs={12}>
                <Grid item xs={extraSmallScreen && 12}>
                  <GrayButton
                    fullWidth={extraSmallScreen}
                    size='medium'
                    outline
                    onClick={handleCloseAndReset}
                  >
                    Keep Plan
                  </GrayButton>
                </Grid>
                <Grid item xs={extraSmallScreen && 12}>
                  <ErrorButton
                    fullWidth={extraSmallScreen}
                    disabled={
                      formik.isSubmitting ||
                      !formik.isValid ||
                      !formik.values.typeOfReason
                    }
                    type='submit'
                    variant='contained'
                    color='error'
                  >
                    Cancel Subscription
                  </ErrorButton>
                </Grid>
              </ButtonsWrapper>
            </ModalContainer>
          </form>
        </CustomModal>
      </Fade>
    </Modal>
  );
};

export default SadToSeeYouGoModal;
