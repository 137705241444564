import {
  DEFAULT_ALERTS_BY_PROJECT_COLUMN_VISIBILITY_MODEL,
  DEFAULT_ALERTS_BY_KEYWORDS_COLUMN_VISIBILITY_MODEL,
} from '../constants';
import { createSlice } from '@reduxjs/toolkit';
import {
  changeTriggersType,
  resetAlertsState,
  resetColumnVisibilityModelAlerts,
  resetColumnVisibilityModelAlertsByKeywords,
  resetTriggerKeywordsState,
  setActiveProjectForFilterAlerts,
  setAlertStatus,
  setColumnVisibilityModelAlerts,
  setColumnVisibilityModelAlertsByKeyword,
  setDeleteElementsTriggerKeywordsTable,
  setDeleteElementsTriggersTable,
  setIsOpenTriggerKeywordsModal,
  setPaginationPageAlerts,
  setPaginationPageAlertTriggered,
  setPaginationPageSizeAlerts,
  setPaginationPageSizeAlertTriggered,
  setPaginationPageSizeTriggerKeywords,
  setPaginationPageSizeTriggers,
  setPaginationPageTriggerKeywords,
  setPaginationPageTriggers,
  setRowSelectionModelTriggerKeywords,
  setRowSelectionModelTriggers,
  setSearchAlerts,
  setSelectByAlerts,
  setShouldRefreshTable,
  setSortModelAlerts,
  setSortModelAlertTriggered,
  setSortModelTriggerKeywords,
  setSortModelTriggers,
  setTriggersSelectBy,
} from './actions';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import {
  IAlertInfo,
  IAlertByKeywordsResponse,
  IAlertByProjectResponse,
  IAlertsTrigger,
  IGetTriggerKeywordsResponse,
  IGetTriggersByKeywordsResponse,
  IGetTriggersByProjectsResponse,
  ITriggerInfo,
  IGetAlertKeywordsResponse,
  IProjectByAlerts,
} from '../types/types';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import { IKeyword } from '../../projects/types';
import {
  getAlertInfoThunk,
  getAlertKeywordsThunk,
  getAlertsByKeywordsThunk,
  getAlertsByProjectThunk,
  getAlertsTriggersTypesThunk,
  getProjectsByAlertsThunk,
  getTriggerInfoThunk,
  getTriggerKeywordThunk,
  getTriggersByKeywordsThunk,
  getTriggersByProjectsThunk,
} from './thunk';
import { getPage } from '../../../utils';
import {
  initializeTableColumns,
  TABLES_STORAGE_KEYS,
} from '../../projects/tablesColumnStase';

const initialState = {
  isLoading: false,
  keywordsForTrigger: [] as IKeyword[],
  alertsTriggers: [] as IAlertsTrigger[],
  alerts: {
    byProjects: null as IAlertByProjectResponse | null,
    byKeywords: null as IAlertByKeywordsResponse | null,
  },
  triggers: {
    shouldRefreshTable: false,
    selectBy: 'by Project' as 'by Project' | 'by Keywords',
    byProjects: null as IGetTriggersByProjectsResponse | null,
    byKeywords: null as IGetTriggersByKeywordsResponse | null,
  },
  triggerKeywords: null as IGetTriggerKeywordsResponse | null,
  alertKeywords: null as IGetAlertKeywordsResponse | null,
  projectsByAlerts: [] as IProjectByAlerts[],
  activeProjectForFilterAlerts: '0' as string,
  triggerInfo: null as ITriggerInfo | null,
  alertInfo: null as IAlertInfo | null,
  tables: {
    alerts: {
      search: '',
      sortModel: [{ field: 'date', sort: 'desc' }] as GridSortModel,
      paginationModel: {
        pageSize: 10,
        page: 0,
      },
      selectByAlerts: 'by Project' as 'by Project' | 'by Keywords',
      columnVisibilityModelByProject: initializeTableColumns(
        TABLES_STORAGE_KEYS.alertsByProject,
        DEFAULT_ALERTS_BY_PROJECT_COLUMN_VISIBILITY_MODEL
      ),
      columnVisibilityModelByKeywords: initializeTableColumns(
        TABLES_STORAGE_KEYS.alertsByKeywords,
        DEFAULT_ALERTS_BY_KEYWORDS_COLUMN_VISIBILITY_MODEL
      ),
    },
    alertTriggered: {
      sortModel: [{ field: 'keywordName', sort: 'desc' }] as GridSortModel,
      paginationModel: {
        pageSize: 10,
        page: 0,
      },
    },
    triggers: {
      sortModel: [{ field: 'projectName', sort: 'desc' }] as GridSortModel,
      paginationModel: {
        pageSize: 10,
        page: 0,
      },
      rowSelectionModel: [] as GridRowSelectionModel,
      deleteElements: [] as GridRowSelectionModel,
    },
    triggerKeywords: {
      isOpenModal: false,
      sortModel: [{ field: 'keywordName', sort: 'desc' }] as GridSortModel,
      paginationModel: {
        pageSize: 10,
        page: 0,
      },
      rowSelectionModel: [] as GridRowSelectionModel,
      deleteElements: [] as GridRowSelectionModel,
    },
  },
};

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setColumnVisibilityModelAlerts, (state, { payload }) => {
      state.tables.alerts.columnVisibilityModelByProject[payload.key] =
        payload.value;
    });

    builder.addCase(
      setColumnVisibilityModelAlertsByKeyword,
      (state, { payload }) => {
        state.tables.alerts.columnVisibilityModelByKeywords[payload.key] =
          payload.value;
      }
    );

    builder.addCase(resetColumnVisibilityModelAlerts, state => {
      state.tables.alerts.columnVisibilityModelByProject =
        DEFAULT_ALERTS_BY_PROJECT_COLUMN_VISIBILITY_MODEL;
    });

    builder.addCase(resetColumnVisibilityModelAlertsByKeywords, state => {
      state.tables.alerts.columnVisibilityModelByKeywords =
        DEFAULT_ALERTS_BY_KEYWORDS_COLUMN_VISIBILITY_MODEL;
    });

    builder.addCase(setPaginationPageSizeAlerts, (state, { payload }) => {
      state.tables.alerts.paginationModel.pageSize = payload;
    });

    builder.addCase(setPaginationPageAlerts, (state, { payload }) => {
      state.tables.alerts.paginationModel.page = payload;
    });

    builder.addCase(setSortModelAlerts, (state, { payload }) => {
      state.tables.alerts.sortModel = payload;
    });

    builder.addCase(
      setPaginationPageSizeAlertTriggered,
      (state, { payload }) => {
        state.tables.alertTriggered.paginationModel.pageSize = payload;
      }
    );

    builder.addCase(setPaginationPageAlertTriggered, (state, { payload }) => {
      state.tables.alertTriggered.paginationModel.page = payload;
    });

    builder.addCase(setSortModelAlertTriggered, (state, { payload }) => {
      state.tables.alertTriggered.sortModel = payload;
    });

    builder.addCase(
      getAlertsTriggersTypesThunk.fulfilled,
      (state, { payload }) => {
        state.alertsTriggers = payload.items;
      }
    );

    builder.addCase(getTriggersByProjectsThunk.pending, state => {
      state.triggers.byProjects = null;
    });
    builder.addCase(
      getTriggersByProjectsThunk.fulfilled,
      (state, { payload }) => {
        state.triggers.byProjects = payload;
        state.tables.triggers.paginationModel.page = getPage(
          state.tables.triggers.paginationModel.page,
          payload.meta.currentPage,
          payload.meta.totalPages
        );
      }
    );

    builder.addCase(getTriggersByKeywordsThunk.pending, state => {
      state.triggers.byKeywords = null;
    });
    builder.addCase(
      getTriggersByKeywordsThunk.fulfilled,
      (state, { payload }) => {
        state.triggers.byKeywords = payload;
        state.tables.triggers.paginationModel.page = getPage(
          state.tables.triggers.paginationModel.page,
          payload.meta.currentPage,
          payload.meta.totalPages
        );
      }
    );

    builder.addCase(setRowSelectionModelTriggers, (state, { payload }) => {
      state.tables.triggers.rowSelectionModel = payload;
    });

    builder.addCase(setPaginationPageSizeTriggers, (state, { payload }) => {
      state.tables.triggers.paginationModel.pageSize = payload;
    });

    builder.addCase(setPaginationPageTriggers, (state, { payload }) => {
      state.tables.triggers.paginationModel.page = payload;
    });

    builder.addCase(setSortModelTriggers, (state, { payload }) => {
      state.tables.triggers.sortModel = payload;
    });

    builder.addCase(getTriggerKeywordThunk.pending, state => {
      state.triggerKeywords = null;
    });
    builder.addCase(getTriggerKeywordThunk.fulfilled, (state, { payload }) => {
      state.triggerKeywords = payload;
      state.tables.triggerKeywords.paginationModel.page = getPage(
        state.tables.triggerKeywords.paginationModel.page,
        payload.meta.currentPage,
        payload.meta.totalPages
      );
    });

    builder.addCase(
      setRowSelectionModelTriggerKeywords,
      (state, { payload }) => {
        state.tables.triggerKeywords.rowSelectionModel = payload;
      }
    );

    builder.addCase(
      setPaginationPageSizeTriggerKeywords,
      (state, { payload }) => {
        state.tables.triggerKeywords.paginationModel.pageSize = payload;
      }
    );

    builder.addCase(setPaginationPageTriggerKeywords, (state, { payload }) => {
      state.tables.triggerKeywords.paginationModel.page = payload;
    });

    builder.addCase(setSortModelTriggerKeywords, (state, { payload }) => {
      state.tables.triggerKeywords.sortModel = payload;
    });

    builder.addCase(getTriggerInfoThunk.pending, state => {
      state.triggerInfo = null;
    });

    builder.addCase(getTriggerInfoThunk.fulfilled, (state, { payload }) => {
      state.triggerInfo = payload;
    });

    builder.addCase(changeTriggersType, state => {
      state.tables.triggers = {
        sortModel: [{ field: 'projectName', sort: 'desc' }] as GridSortModel,
        paginationModel: {
          pageSize: 10,
          page: 0,
        },
        rowSelectionModel: [] as GridRowSelectionModel,
        deleteElements: state.tables.triggers.deleteElements,
      };
      state.triggers.byKeywords = null;
      state.triggers.byProjects = null;
    });

    builder.addCase(resetTriggerKeywordsState, state => {
      state.triggerKeywords = null;
      state.tables.triggerKeywords = {
        isOpenModal: state.tables.triggerKeywords.isOpenModal,
        sortModel: [{ field: 'keywordName', sort: 'desc' }] as GridSortModel,
        paginationModel: {
          pageSize: 10,
          page: 0,
        },
        rowSelectionModel: [] as GridRowSelectionModel,
        deleteElements: state.tables.triggerKeywords.deleteElements,
      };
    });

    builder.addCase(getAlertsByProjectThunk.pending, state => {
      state.alerts.byProjects = null;
    });
    builder.addCase(
      getAlertsByProjectThunk.fulfilled,
      (state, { payload, meta }) => {
        state.alerts.byProjects = payload;
        if (!meta?.arg?.isChangeType) {
          state.tables.alerts.paginationModel.page = getPage(
            state.tables.alerts.paginationModel.page,
            payload.meta.currentPage,
            payload.meta.totalPages
          );
        }
      }
    );

    builder.addCase(getAlertsByKeywordsThunk.pending, state => {
      state.alerts.byKeywords = null;
    });
    builder.addCase(
      getAlertsByKeywordsThunk.fulfilled,
      (state, { payload, meta }) => {
        state.alerts.byKeywords = payload;
        if (!meta?.arg?.isChangeType) {
          state.tables.alerts.paginationModel.page = getPage(
            state.tables.alerts.paginationModel.page,
            payload.meta.currentPage,
            payload.meta.totalPages
          );
        }
      }
    );

    builder.addCase(setSearchAlerts, (state, { payload }) => {
      state.tables.alerts.search = payload;
    });

    builder.addCase(resetAlertsState, state => {
      state.tables.alerts = {
        search: '',
        sortModel: [{ field: 'date', sort: 'desc' }] as GridSortModel,
        paginationModel: {
          pageSize: 10,
          page: 0,
        },
        selectByAlerts: state.tables.alerts.selectByAlerts,
        columnVisibilityModelByProject:
          state.tables.alerts.columnVisibilityModelByProject,
        columnVisibilityModelByKeywords:
          state.tables.alerts.columnVisibilityModelByKeywords,
      };
      state.alerts.byKeywords = null;
      state.alerts.byProjects = null;
    });

    builder.addCase(getAlertInfoThunk.fulfilled, (state, { payload }) => {
      state.alertInfo = payload;
    });

    builder.addCase(getAlertKeywordsThunk.pending, state => {
      state.alertKeywords = null;
    });
    builder.addCase(getAlertKeywordsThunk.fulfilled, (state, { payload }) => {
      state.alertKeywords = payload;
    });

    builder.addCase(
      getProjectsByAlertsThunk.fulfilled,
      (state, { payload }) => {
        state.projectsByAlerts = payload.items;
      }
    );

    builder.addCase(setActiveProjectForFilterAlerts, (state, { payload }) => {
      state.activeProjectForFilterAlerts = payload;
    });

    builder.addCase(setDeleteElementsTriggersTable, (state, { payload }) => {
      state.tables.triggers.deleteElements = payload.deleteElements;
    });

    builder.addCase(
      setDeleteElementsTriggerKeywordsTable,
      (state, { payload }) => {
        state.tables.triggerKeywords.deleteElements = payload.deleteElements;
      }
    );

    builder.addCase(setAlertStatus, (state, { payload }) => {
      if (state?.alerts?.byProjects?.items?.length) {
        state.alerts.byProjects.items.forEach(item => {
          if (item.id === payload.alertId) {
            item.viewed = 1;
          }
        });
      }
    });

    builder.addCase(setSelectByAlerts, (state, { payload }) => {
      state.tables.alerts.selectByAlerts = payload.selectByAlerts;
    });

    builder.addCase(setTriggersSelectBy, (state, { payload }) => {
      state.triggers.selectBy = payload.value;
    });

    builder.addCase(setIsOpenTriggerKeywordsModal, (state, { payload }) => {
      state.tables.triggerKeywords.isOpenModal = payload.value;
    });

    builder.addCase(setShouldRefreshTable, (state, { payload }) => {
      state.triggers.shouldRefreshTable = payload.value;
    });
  },
});

export default alertsSlice.reducer;
