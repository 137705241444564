export const DEFAULT_COLUMN_VISIBILITY_MODEL = {
  url: true,
  deviceType: true,
  totalKeywords: true,
  improved: true,
  declined: true,
  noChange: false,
  dailyAverage: false,
  frequency: false,
  created: false,
  updated: true,
};

export const CLEARED_COLUMN_VISIBILITY_MODEL = {
  name: false,
  url: false,
  deviceType: false,
  totalKeywords: false,
  improved: false,
  declined: false,
  noChange: false,
  dailyAverage: false,
  frequency: false,
  created: false,
  updated: false,
};

export const DEFAULT_KEYWORD_RANKINGS_VISIBILITY_MODEL = {
  name: true,
  position: true,
  d1: true,
  d7: true,
  d30: true,
  best: true,
  life: true,
  first: true,
  searchValue: true,
  cpc: true,
  url: true,
  updated: true,
};

export const DEFAULT_KEYWORD_POSITIONS_MODEL = {
  top3: false,
  top10: false,
  top30: false,
  top100: false,
  improved: false,
  declined: false,
  lost: false,
  notRanked: false,
  noChange: false,
};

export const DEFAULT_SHARED_LINKS_MODEL = {
  created: true,
  type: true,
  lastViewed: true,
  link: true,
};

export const DEVISE_TYPE = ['Desktop', 'Mobile', 'DesktopAndMobile'];

export const PERIOD = [
  { label: '1w', value: 'Week' },
  { label: '1m', value: 'Month' },
  { label: '6m', value: 'SixMonths' },
  { label: '1y', value: 'year' },
  { label: 'All', value: 'All' },
];

export const DEFAULT_KEYWORD_POSITION_MODEL = [] as string[];
