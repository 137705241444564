import React from 'react';
import {
  ListItemTypography,
  StyledListItem,
  StyledListItemButton,
} from '../styles';
import DownloadIcon from '../../../../../../../../../shared/images/DownloadIcon';
import { IDownloadProjectInfoToCSVAction } from './types';
import { useProjects } from '../../../../../../../../../hooks';
import { useAppSelector } from '../../../../../../../../../store';

const DownloadProjectInfoToCSVAction: React.FC<
  IDownloadProjectInfoToCSVAction
> = ({ projectId, disableExportCSV, popupClose }) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const { onDownloadProjectInfoToCSV } = useProjects();

  const handleClick = () => {
    if (currentAccount?.accountId && !disableExportCSV) {
      onDownloadProjectInfoToCSV({
        projectId: projectId,
        id: currentAccount.accountId,
      });
      popupClose();
    }
  };
  return (
    <StyledListItem onClick={handleClick}>
      <StyledListItemButton disabled={disableExportCSV}>
        <DownloadIcon />
        <ListItemTypography marginLeft='8px'>
          Export Data (CSV)
        </ListItemTypography>
      </StyledListItemButton>
    </StyledListItem>
  );
};

export default DownloadProjectInfoToCSVAction;
