import { createAction } from '@reduxjs/toolkit';

const setPaddle = createAction('setPaddle', function prepare(paddle: any) {
  return {
    payload: paddle,
  };
});

const resetPaddleState = createAction('resetPaddleState');

const setIsOpenSuccessPayModal = createAction(
  'setIsOpenSuccessPayModal',
  function prepare(isOpen: boolean) {
    return { payload: isOpen };
  }
);

export { setPaddle, resetPaddleState, setIsOpenSuccessPayModal };
