import {
  Box,
  Fade,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Typography,
  Modal,
  Grid,
  useMediaQuery,
  InputAdornment,
  IconButton,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React, { useState } from 'react';

import Trash from '../../../../../../shared/images/Trash';
import { CustomFormHelperText } from '../../../../../../shared/components';
import { DeleteAccountStep1Props } from './types';

import {
  HeaderContainer,
  WarningContainer,
  CloseDeletePopoverOne,
  ButtonsWrapper,
  CustomModal,
  ModalContainer,
} from './styles';
import {
  ErrorButtonSmall,
  GrayButtonSmall,
} from '../../../../../../shared/buttons/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { PasswordStateLabel } from '../../../RegisterForm/styles';

/**
 * DeleteAccountStep1 component displays the first step in the delete account process, asking the user for a reason for deletion and their password.
 */
const DeleteAccountStep1: React.FC<DeleteAccountStep1Props> = ({
  open,
  handleClose,
  deletionReason,
  password,
  handleBlur,
  handleChange,
  touched,
  errors,
  isLoadingDeleteAccount,
  isValid,
  handleSubmit,
  passwordExists,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const [showPassword, setShowPassword] = useState<boolean>(false);

  /**
   * Toggles the visibility of the password input field.
   */
  const handleClickShowPassword = () => setShowPassword(show => !show);

  /**
   * Prevents the default mouse down action on the password visibility toggle button.
   *
   * @param {React.MouseEvent<HTMLButtonElement>} event - The mouse event.
   */
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <CustomModal>
          <ModalContainer>
            <HeaderContainer>
              <CloseDeletePopoverOne onClick={handleClose}>
                <CloseIcon fontSize='small' />
              </CloseDeletePopoverOne>
              <Trash />
              <Typography variant='h6' fontWeight='600' paddingTop='29px'>
                Delete account?
              </Typography>
            </HeaderContainer>
            <WarningContainer sx={{ marginTop: '24px', marginBottom: '24px' }}>
              <ErrorOutlineIcon fontSize='medium' color='error' />
              <Typography variant='body2' lineHeight='24px' color='#475467'>
                Are you sure you want to delete your account? You won’t be able
                to recover once you delete it. All the data associated with it
                will be lost.
              </Typography>
            </WarningContainer>
            <div>
              <Box sx={{ mb: '24px' }}>
                <InputLabel shrink htmlFor='confirm_password'>
                  <Typography variant='h6' fontWeight='500' color='#475467'>
                    Deletion reason
                  </Typography>
                </InputLabel>
                <OutlinedInput
                  multiline
                  rows={4}
                  size='small'
                  fullWidth
                  type='tex'
                  id='deletionReason'
                  name='deletionReason'
                  placeholder='Tell us why do want to delete your account?'
                  value={deletionReason}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!(touched.deletionReason && errors.deletionReason)}
                />
                {touched.deletionReason && errors.deletionReason && (
                  <FormHelperText error id='password'>
                    <CustomFormHelperText error={errors.deletionReason} />
                  </FormHelperText>
                )}
              </Box>
              {passwordExists && (
                <div>
                  <InputLabel shrink htmlFor='confirm_password'>
                    <Typography variant='h6' fontWeight='500' color='#475467'>
                      Enter your password to continue
                    </Typography>
                  </InputLabel>
                  <OutlinedInput
                    size='small'
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    id='password'
                    name='password'
                    placeholder='Enter your account password'
                    value={password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!(touched.password && errors.password)}
                    endAdornment={
                      <InputAdornment
                        position='end'
                        onClick={handleClickShowPassword}
                        sx={{ cursor: 'pointer' }}
                      >
                        <IconButton
                          aria-label='toggle password visibility'
                          onMouseDown={handleMouseDownPassword}
                          edge='end'
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        <PasswordStateLabel>
                          {showPassword ? <b>Hide</b> : <b>Show</b>}
                        </PasswordStateLabel>
                      </InputAdornment>
                    }
                  />
                  {touched.password && errors.password && (
                    <FormHelperText error id='password'>
                      <CustomFormHelperText error={errors.password} />
                    </FormHelperText>
                  )}
                </div>
              )}
            </div>
            <ButtonsWrapper container xs={12}>
              <Grid item xs={extraSmallScreen && 12}>
                <GrayButtonSmall
                  fullWidth={extraSmallScreen}
                  size='medium'
                  outline
                  onClick={handleClose}
                >
                  Cancel
                </GrayButtonSmall>
              </Grid>
              <Grid item xs={extraSmallScreen && 12}>
                <ErrorButtonSmall
                  fullWidth={extraSmallScreen}
                  onClick={() => handleSubmit()}
                  disabled={
                    isLoadingDeleteAccount ||
                    !isValid ||
                    (passwordExists ? !password : false) ||
                    !deletionReason
                  }
                  type='submit'
                  variant='contained'
                  color='error'
                >
                  Continue
                </ErrorButtonSmall>
              </Grid>
            </ButtonsWrapper>
          </ModalContainer>
        </CustomModal>
      </Fade>
    </Modal>
  );
};

export default DeleteAccountStep1;
