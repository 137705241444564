import React, { useMemo, useState } from 'react';
import { EllipsisActionsProps } from '../types';
import { ListItemTypography, StyledListItem } from '../styles';
import { StyledListItemButton } from '../../../../../../../../Projects/components/TableProjects/components/EllipsisMenu/styles';
import RefreshIcon from '../../../../../../../../../../../shared/images/RefreshIcon';
import ManualRefreshKeywordsModal from '../../../../../../../../modals/ManualRefreshKeywordsModal/ManualRefreshKeywordsModal';
import { useProjects } from '../../../../../../../../../../../hooks';
import useAccountLimits from '../../../../../../../../../../auth/hooks/useAccountLimits';
import { useAppSelector } from '../../../../../../../../../../../store';

const RefreshKeywordAction: React.FC<EllipsisActionsProps> = ({
  keyword,
  popupClose,
}) => {
  const { onCheckSubscriptionExpiration } = useAccountLimits();
  const { onSetRowSelectionKeywordsModel, onSetRefreshKeywordsModal } =
    useProjects();

  const openRefreshKeywords = useAppSelector(
    state => state.projects.modals.refreshKeywords
  );

  const [open, setOpen] = useState(false);

  const disableRefreshKeyword = useMemo(
    () => !(keyword.updateAllowed && !keyword.positionUpdate),
    [keyword.positionUpdate, keyword.updateAllowed]
  );

  const handleOpenModal = () => {
    if (!disableRefreshKeyword) {
      const isExpiration = onCheckSubscriptionExpiration();
      if (!isExpiration) {
        setOpen(true);
        onSetRefreshKeywordsModal(true);
      }
    }
  };

  const handleCloseModal = () => {
    onSetRowSelectionKeywordsModel([]);
    setOpen(false);
    onSetRefreshKeywordsModal(false);
    popupClose();
  };

  return (
    <>
      <StyledListItem onClick={handleOpenModal}>
        <StyledListItemButton disabled={disableRefreshKeyword}>
          <RefreshIcon />
          <ListItemTypography marginLeft='8px'>
            Refresh Keyword
          </ListItemTypography>
        </StyledListItemButton>
      </StyledListItem>
      {open && (
        <ManualRefreshKeywordsModal
          onClose={handleCloseModal}
          open={openRefreshKeywords}
          keywordIds={[keyword.id]}
        />
      )}
    </>
  );
};

export default RefreshKeywordAction;
