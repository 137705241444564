import React, { useMemo } from 'react';
import { useAppSelector } from '../../../../../../../../store';
import { Grid } from '@mui/material';
import {
  Circle,
  CircleContainer,
  CircleLabel,
  CirclesContainer,
} from '../../../../../../../projects/components/ProjectExpanded/components/ProjectPerformance/components/GraphLabels/styles';
import { CustomFormControlLabel } from '../../../../../../../projects/components/ProjectExpanded/components/KeywordTrends/components/Header/styled';
import { CustomCheckbox } from '../../../../../../../../shared/checkbox/styles';
import CustomCheckboxImage from '../../../../../../../../shared/images/CustomCheckbox';
import CheckedIcon from '../../../../../../../../shared/images/CheckedIcon';
import { COMPETITORS_COLORS } from '../../../../../../../../shared/layout/PrivateLayout/constant';
import useSharedKeywordRankHistory from '../../../../../../hooks/useKeywordRankHistory';

const GraphLabels = () => {
  const {
    keywordInfo,
    showCompetitors,
    onSetSharedShowCompetitorsForKeywordRankHistory,
  } = useSharedKeywordRankHistory();

  const projectShared = useAppSelector(
    state => state.sharedLinks.projectShared
  );

  const isBaseSearchEngine = useMemo(() => {
    return (
      projectShared &&
      projectShared.searchEngine.name !== 'Google My Business' &&
      projectShared.searchEngine.name !== 'Google Maps'
    );
  }, [projectShared?.searchEngine?.name]);

  const handleSetShowCompetitors = (competitorId: number, checked: boolean) => {
    let newShowCompetitors: number[];
    if (checked) {
      newShowCompetitors = [...showCompetitors, competitorId];
    } else {
      newShowCompetitors = showCompetitors.filter(
        item => item !== competitorId
      );
    }
    onSetSharedShowCompetitorsForKeywordRankHistory(newShowCompetitors);
  };

  return (
    <Grid>
      <CirclesContainer>
        <CircleContainer>
          <Circle backgroundColor={'#1A73E9'} />
          <CircleLabel>Position</CircleLabel>
        </CircleContainer>
      </CirclesContainer>

      {keywordInfo?.competitors?.length ? (
        <Grid>
          {keywordInfo.competitors.map(competitor => {
            const label = isBaseSearchEngine
              ? competitor.domainName
              : `${competitor.businessName}${
                  competitor?.url ? ` (${competitor?.url})` : ''
                }`;
            return (
              <Grid key={competitor.id}>
                <CustomFormControlLabel
                  control={
                    <CustomCheckbox
                      checked={
                        !!showCompetitors.find(item => item === competitor.id)
                      }
                      onChange={(event, checked) => {
                        handleSetShowCompetitors(competitor.id, checked);
                      }}
                      icon={<CustomCheckboxImage />}
                      checkedIcon={
                        <CheckedIcon
                          checkedColor={
                            COMPETITORS_COLORS[
                              competitor.id % COMPETITORS_COLORS.length
                            ]
                          }
                        />
                      }
                    />
                  }
                  label={label}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : null}
    </Grid>
  );
};

export default GraphLabels;
