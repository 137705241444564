import React, { useCallback, useMemo, useState } from 'react';
import { Divider, Grid, useMediaQuery } from '@mui/material';
import {
  StylesLabel,
  StylesDescription,
  StylesSlider,
  StylesSliderContainer,
  StylesSliderLabel,
  StylesSliderActiveKeywordsCount,
  StylesTotalContainer,
  StylesTotalValue,
  StylesTotalLabel,
  StylesMonthlyYearly,
  StylesMonthsFree,
  CustomSwitch,
} from './styles';
import { CustomPlanProps } from '../../types';
import usePayment from '../../../../../payment/hooks/usePayment';
import { ENVIRONMENT } from '../../../../../../constants/config';
import ReviewUpdateSubscriptionModal from '../../../../../payment/components/ReviewUpdateSubscriptionModal/ReviewUpdateSubscriptionModal';
import { useAppSelector } from '../../../../../../store';
import { ITariffPlan } from '../../../../types';
import { UpgradePlanPrimaryButton } from '../../styles';

const CustomPlan: React.FC<CustomPlanProps> = ({
  customTariffPlans,
  planType,
  setPlanType,
}) => {
  const isMobile = useMediaQuery('(max-width:1200px)');

  const { paddle } = usePayment();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const billing = useAppSelector(state => state.auth.payment.billing);
  const checkoutSettings = useAppSelector(state => state.auth.checkoutSettings);

  const [activePlanIndex, setActivePlanIndex] = useState(0);
  const [isReviewUpdateSubscriptionModal, setIsReviewUpdateSubscriptionModal] =
    useState(false);
  const [activePaddleProductId, setActivePaddleProductId] = useState<
    null | string
  >(null);

  const formattingCustomPlan = useMemo(() => {
    if (customTariffPlans.length) {
      return customTariffPlans
        .filter(item => item.name !== 'Trial Period')
        .map((item, index) => {
          const label =
            item.settings.dailyWordCount >= 1000
              ? (item.settings.dailyWordCount / 1000).toFixed() + 'K'
              : item.settings.dailyWordCount;
          return {
            price: item.settings.price,
            keywords: item.settings.dailyWordCount,
            value: index,
            label: label,
            paddleProductId: item.settings.paddleProductId,
            id: item.id,
            type: item.settings.type,
          };
        });
    } else {
      return [];
    }
  }, [customTariffPlans]);

  const userPlanCanceled = useMemo(
    () => billing?.status?.name === 'canceled',
    [billing?.status?.name]
  );

  const userPlanDeactivated = useMemo(
    () => billing?.status?.name === 'deactivated',
    [billing?.status?.name]
  );

  const getDisabledPlan = useCallback(
    (plan?: ITariffPlan) => {
      return !billing
        ? true
        : userPlanDeactivated
        ? false
        : userPlanCanceled ||
          (billing?.tariffPlan?.id === plan?.id &&
            billing?.tariffPlan?.settings?.type?.name ===
              plan?.settings?.type?.name);
    },
    [billing, userPlanCanceled, userPlanDeactivated]
  );

  const openPaddle = () => {
    if (
      paddle &&
      currentAccount?.accountId &&
      formattingCustomPlan[activePlanIndex] &&
      checkoutSettings
    ) {
      paddle.Checkout.open({
        items: [
          {
            price_id: formattingCustomPlan[activePlanIndex].paddleProductId,
            quantity: 1,
          },
        ],
        customer: { email: currentAccount.owner.ownerEmail },
        settings: {
          allowLogout: false,
          showAddDiscounts: checkoutSettings.showAddDiscounts,
          showAddTaxId: false,
          allowedPaymentMethods: checkoutSettings.paymentMethods,
        },
        customData: { id: currentAccount.accountId, environment: ENVIRONMENT },
      });
    }
  };

  const handleOpen = () => {
    if (formattingCustomPlan[activePlanIndex]?.paddleProductId) {
      if (
        billing &&
        billing.tariffPlan.name !== 'Trial Period' &&
        !userPlanDeactivated
      ) {
        setActivePaddleProductId(
          formattingCustomPlan[activePlanIndex].paddleProductId
        );
        setIsReviewUpdateSubscriptionModal(true);
      } else {
        openPaddle();
      }
    }
  };

  const handleClose = () => {
    setActivePaddleProductId(null);
    setIsReviewUpdateSubscriptionModal(false);
  };

  const onChangeSlider = (event: Event, value: number | number[]) => {
    setActivePlanIndex(value as number);
  };

  const onSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setPlanType(checked ? 'yearly' : 'monthly');
  };

  if (!formattingCustomPlan.length) {
    return null;
  }

  return (
    <>
      <Grid xs={12} paddingTop={'80px'}>
        <Grid textAlign={'center'}>
          <StylesLabel>Need more?</StylesLabel>
          <StylesDescription>Create a custom plan</StylesDescription>
        </Grid>
        <StylesSliderContainer container xs={12}>
          <Grid item xs={isMobile ? 12 : 7}>
            <StylesSliderLabel>
              How many keywords do you need?
            </StylesSliderLabel>
            <StylesSlider
              track={false}
              marks={formattingCustomPlan}
              min={formattingCustomPlan[0].value}
              max={formattingCustomPlan[formattingCustomPlan.length - 1].value}
              onChange={onChangeSlider}
            />
            <Grid xs={isMobile && 12} textAlign={'center'}>
              <StylesSliderActiveKeywordsCount>
                <b>
                  {formattingCustomPlan[activePlanIndex]?.keywords || 0}{' '}
                  keywords
                </b>{' '}
                / day
              </StylesSliderActiveKeywordsCount>
            </Grid>
          </Grid>

          {isMobile ? (
            <Grid xs={12}>
              <Divider
                orientation={'horizontal'}
                flexItem
                sx={{
                  color: '#EDEDED',
                  marginBottom: '38px',
                }}
              />
            </Grid>
          ) : (
            <Divider
              orientation={'vertical'}
              flexItem
              sx={{
                color: '#EDEDED',
                marginLeft: '65px',
                marginRight: '45px',
              }}
            />
          )}

          <Grid item>
            <StylesMonthsFree>
              Get 2 months free in yearly plan
            </StylesMonthsFree>
            <Grid container alignItems={'center'} gap={1} paddingTop={'24px'}>
              <StylesMonthlyYearly item>Monthly</StylesMonthlyYearly>
              <Grid item>
                <CustomSwitch
                  checked={planType === 'yearly'}
                  onChange={onSwitch}
                />
              </Grid>
              <StylesMonthlyYearly item>Yearly</StylesMonthlyYearly>
            </Grid>
            <StylesTotalContainer>
              <StylesTotalValue>
                {formattingCustomPlan[activePlanIndex]?.keywords || 0}
              </StylesTotalValue>
              <StylesTotalLabel>Keywords tracked</StylesTotalLabel>
            </StylesTotalContainer>
            <StylesTotalContainer>
              <StylesTotalValue>{`$${
                formattingCustomPlan[activePlanIndex]?.price || 0
              }`}</StylesTotalValue>
              <StylesTotalLabel>
                {`per ${planType === 'monthly' ? 'month' : 'year'}`}
              </StylesTotalLabel>
            </StylesTotalContainer>
            <UpgradePlanPrimaryButton
              variant='contained'
              size='medium'
              type='submit'
              disabled={
                !billing
                  ? true
                  : userPlanDeactivated
                  ? false
                  : userPlanCanceled ||
                    (billing?.tariffPlan?.id ===
                      formattingCustomPlan[activePlanIndex]?.id &&
                      billing?.tariffPlan?.settings?.type?.name ===
                        formattingCustomPlan[activePlanIndex]?.type?.name)
              }
              onClick={handleOpen}
            >
              Get started
            </UpgradePlanPrimaryButton>
          </Grid>
        </StylesSliderContainer>
      </Grid>
      <ReviewUpdateSubscriptionModal
        handleClose={handleClose}
        open={isReviewUpdateSubscriptionModal}
        paddleProductId={activePaddleProductId}
      />
    </>
  );
};

export default CustomPlan;
