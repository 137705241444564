import { styled } from '@mui/material/styles';
import { ButtonGroup } from '@mui/material';

const StyledTimeGrout = styled(ButtonGroup)`
  background: white;
  .MuiButton-root {
    color: #344054;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-transform: none;
    padding: 7px 16px;
    border-color: #d0d5dd;

    :hover {
      border-color: #d0d5dd;
      background: white;
    }
  }
  .MuiButton-root.Mui-disabled {
    background: #f2f4f7;
    color: #344054;
  }
`;

export { StyledTimeGrout };
