import { closeSnackbar, SnackbarKey } from 'notistack';
import React from 'react';
import { Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

/**
 * Renders a close button for a snackbar notification.
 *
 * @param {SnackbarKey} snackbarId - The unique identifier for the snackbar to be closed.
 * @returns {JSX.Element} The close button component.
 */
export const closeSnackbarAction = (snackbarId: SnackbarKey) => (
  <>
    <IconButton
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
    >
      <CloseIcon sx={{ color: '#EBEBEB' }} />
    </IconButton>
  </>
);

/**
 * Renders an undo button along with a close button for a snackbar notification.
 *
 * @param {SnackbarKey} snackbarId - The unique identifier for the snackbar.
 * @param {function} onUndo - The function to call when the undo action is triggered.
 * @returns {JSX.Element} The undo and close button components.
 */
export const closeAndUndoSnackbarAction = (
  snackbarId: SnackbarKey,
  onUndo: () => void
) => {
  const handleUndo = () => {
    onUndo();
    closeSnackbar(snackbarId);
  };

  return (
    <>
      <Button
        color='primary'
        size='small'
        style={{ color: '#6CF' }}
        onClick={handleUndo}
      >
        Undo
      </Button>
      <IconButton
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        <CloseIcon sx={{ color: '#EBEBEB' }} />
      </IconButton>
    </>
  );
};
