import { styled } from '@mui/system';
import { Grid, List } from '@mui/material';
import { StyledListItem as ProjectStyledListItem } from '../../../../../../features/projects/components/Projects/components/TableProjects/components/Header/components/PopupTableSettings/styles';

const StyledItemName = styled(Grid)`
  color: #101828;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
`;

const StyledItemDomain = styled(Grid)`
  color: #808080;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`;

const StyledListItem = styled(ProjectStyledListItem)`
  .MuiListItemButton-root {
    padding: 8px;
  }
`;

const StyledList = styled(List)`
  padding: 0;
`;

const ClearButton = styled(Grid)`
  cursor: pointer;
  color: #126fe9;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export {
  StyledItemName,
  StyledItemDomain,
  StyledListItem,
  StyledList,
  ClearButton,
};
