export enum TABLES_STORAGE_KEYS {
  projectsPage = 'projectsPage',
  projectKeywordRankings = 'projectKeywordRankings',
  scheduledEmailReportsPage = 'scheduledEmailReportsPage',
  alertsByProject = 'alertsByProject',
  alertsByKeywords = 'alertsByKeywords',
  sharedLinksPage = 'sharedLinksPage',
  rootFoldersPage = 'rootFoldersPage',
  childrenFoldersPage = 'childrenFoldersPage',
  sharedWithYouPage = 'sharedWithYouPage',
  sharedKeywordRankings = 'sharedKeywordRankings',
}

const initializeTableColumns = (
  storageKey: string,
  columnVisibilityModel: any
) => {
  const savedModel = localStorage.getItem(storageKey);
  return savedModel ? JSON.parse(savedModel) : columnVisibilityModel;
};

const setTableColumns = (storageKey: string, columnVisibilityModel: any) => {
  localStorage.setItem(storageKey, JSON.stringify(columnVisibilityModel));
};

export { initializeTableColumns, setTableColumns };
