import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CustomDataGrid } from '../../../../../../../auth/components/Settings/components/Team/components/customTableStyles';
import CheckboxWrapper from '../../../../../../../auth/components/Settings/components/Team/components/CheckboxWrapper';
import { Grid } from '@mui/material';
import {
  useEmailReports,
  useScheduledEmailReportsTable,
} from '../../../../../../../../hooks';
import { arraysAreEqual } from '../../../../../../../../utils';
import { useAppSelector } from '../../../../../../../../store';
import EmptyTable from '../../../../../../../../shared/components/EmptyTable/EmptyTable';
import PlusIcon from '../../../../../../../../shared/images/PlusIcon';
import CreateProjectReport from '../../../../../modals/CreateProjectReport/CreateProjectReport';
import useAccountLimits from '../../../../../../../auth/hooks/useAccountLimits';
import {
  setTableColumns,
  TABLES_STORAGE_KEYS,
} from '../../../../../../../projects/tablesColumnStase';

const Table = () => {
  const { columns, columnsSkeleton } = useScheduledEmailReportsTable();

  const {
    onSetRowSelectionModelEmailReports,
    onSetSortModelEmailReports,
    emailReports,
    tables: { scheduledEmailReports },
  } = useEmailReports();

  const { onCheckSubscriptionExpiration } = useAccountLimits();

  const openTariffPlanBanner = useAppSelector(
    state => state.auth.openTariffPlanBanner
  );

  const [itemCount, setItemCount] = useState(
    emailReports?.meta?.itemCount ||
      scheduledEmailReports.paginationModel.pageSize
  );
  const [isOpen, setIsOpenModal] = useState(false);

  const onOpen = () => {
    const isExpiration = onCheckSubscriptionExpiration();
    if (!isExpiration) {
      setIsOpenModal(true);
    }
  };

  const onClose = () => {
    setIsOpenModal(false);
  };

  useEffect(() => {
    if (emailReports?.meta?.itemCount) {
      setItemCount(emailReports.meta.itemCount);
    }
  }, [emailReports?.meta?.itemCount]);

  const rows = useMemo(() => {
    const arr1 = [...(emailReports?.items?.length ? emailReports.items : [])];
    const arr2 = [...scheduledEmailReports.deleteElements];

    return arr1.filter(item => !arr2.includes(item.id));
  }, [scheduledEmailReports.deleteElements, emailReports?.items]);

  const arrayOfObjects = useMemo(
    () => Array.from({ length: itemCount }, (_, index) => ({ id: index + 1 })),
    [itemCount]
  );

  useEffect(() => {
    const filtered = emailReports?.items
      ?.filter(obj => scheduledEmailReports.rowSelectionModel.includes(obj.id))
      ?.map(item => item.id)
      .filter(item => !scheduledEmailReports.deleteElements.includes(item));

    if (
      filtered &&
      !arraysAreEqual(scheduledEmailReports.rowSelectionModel, filtered)
    ) {
      onSetRowSelectionModelEmailReports(filtered);
    }
  }, [
    scheduledEmailReports.deleteElements,
    onSetRowSelectionModelEmailReports,
    emailReports?.items,
    scheduledEmailReports.rowSelectionModel,
  ]);

  const renderRows = useMemo(() => {
    return !emailReports?.items ? arrayOfObjects : rows;
  }, [arrayOfObjects, emailReports?.items, rows]);

  const renderColumns = useMemo(() => {
    return !emailReports?.items ? columnsSkeleton : columns;
  }, [columns, columnsSkeleton, emailReports?.items]);

  const NoRowsOverlay = useCallback(
    () => (
      <EmptyTable
        buttonText={'Add Scheduled Report'}
        handleButton={onOpen}
        supportingText={'Start by clicking the ‘Add Scheduled Report’ button.'}
        text={'You don’t have any scheduled reports'}
        startIcon={<PlusIcon />}
      />
    ),
    []
  );

  useEffect(() => {
    setTableColumns(
      TABLES_STORAGE_KEYS.scheduledEmailReportsPage,
      scheduledEmailReports.columnVisibilityModel
    );
  }, [scheduledEmailReports.columnVisibilityModel]);

  return (
    <>
      <Grid xs={12}>
        <CustomDataGrid
          initialState={{
            pinnedColumns: {
              right: ['actions'],
            },
          }}
          columnHeaderHeight={40}
          rowHeight={40}
          rows={renderRows}
          autoHeight
          rowSelectionModel={scheduledEmailReports.rowSelectionModel}
          onRowSelectionModelChange={onSetRowSelectionModelEmailReports}
          columns={renderColumns}
          onSortModelChange={onSetSortModelEmailReports}
          components={{
            BaseCheckbox: CheckboxWrapper,
            NoRowsOverlay: NoRowsOverlay,
          }}
          columnVisibilityModel={scheduledEmailReports.columnVisibilityModel}
          keepNonExistentRowsSelected
          hideFooterPagination
          checkboxSelection
          disableRowSelectionOnClick
          disableColumnMenu
          columnBuffer={100}
          columnThreshold={100}
          $openTariffPlanBanner={openTariffPlanBanner}
        />
      </Grid>
      <CreateProjectReport open={isOpen} onClose={onClose} />
    </>
  );
};

export default Table;
