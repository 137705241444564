import { styled } from '@mui/system';
import { Grid } from '@mui/material';

const StylesErrorBanner = styled(Grid)`
  z-index: 1110;
  position: fixed;
  top: 0;
  width: 100%;
  background: #FECDCA;
  padding: 12px 16px;
  align-items: center;

  color: #475467;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  height: 60px;

  @media (max-width:600px) {
    padding: 12px 12px;
    height: 90px;
  },
`;

export { StylesErrorBanner };
