import React from 'react';

interface Props {
  width?: string;
  height?: string;
}

const Trash: React.FC<Props> = ({ width = '33', height = '34' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 33 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.5 2H21.5M1.5 7H31.5M28.1667 7L26.9979 24.5321C26.8225 27.1626 26.7348 28.4778 26.1667 29.475C25.6665 30.353 24.9121 31.0588 24.0028 31.4995C22.9699 32 21.6518 32 19.0156 32H13.9844C11.3482 32 10.0301 32 8.99723 31.4995C8.08793 31.0588 7.33348 30.353 6.83331 29.475C6.26518 28.4778 6.1775 27.1626 6.00214 24.5322L4.83333 7M13.1667 14.5V22.8333M19.8333 14.5V22.8333'
        stroke='#D92D20'
        strokeWidth='2.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Trash;
