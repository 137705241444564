import {
  Autocomplete,
  Fade,
  Grid,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect } from 'react';

import { ModalContainer, ModalWrapper } from '../styles';
import { CloseDeletePopoverOne } from '../../../../../../auth/components/Settings/components/Account/styles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../../../shared/buttons/styles';
import { ButtonsWrapper } from '../../../../../../auth/components/Settings/components/Team/components/customTableStyles';
import SelectIcon from '../../../../../../../shared/components/SelectIcon';
import useProjects from '../../../../../hooks/useProjects';
import { EditFrequencyModalProps } from './types';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from '../../../../../../../store';
import { ModalAutocompleteStylesProps } from '../../../../../../../shared/modal/styled';

const EditFrequencyModal: React.FC<EditFrequencyModalProps> = ({
  handleSubmit,
  formik,
  open,
  handleClose,
  isMultiple = true,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const { onGetCheckFrequency } = useProjects();

  const checkFrequency = useAppSelector(state => state.projects.checkFrequency);

  useEffect(() => {
    if (!checkFrequency.length) {
      onGetCheckFrequency();
    }
  }, []);

  return (
    <Modal
      aria-labelledby='add-keywords-modal-title'
      aria-describedby='add-keywords-description'
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <ModalWrapper>
          <ModalContainer container xs={12} sx={{ gap: '8px' }}>
            <Grid xs={12}>
              <Grid item xs={12} sx={{ position: 'relative' }}>
                <CloseDeletePopoverOne onClick={handleClose}>
                  <CloseIcon fontSize='small' />
                </CloseDeletePopoverOne>
                <Typography
                  fontSize='18px'
                  fontWeight='600'
                  lineHeight='28px'
                  color='#101828'
                >
                  {isMultiple
                    ? 'Override frequency for multiple projects?'
                    : 'Override frequency for this project?'}
                </Typography>
              </Grid>
              <Grid item xs={12} paddingTop='28px'>
                <Grid
                  container
                  fontWeight='500'
                  alignItems='center'
                  fontSize='14px'
                  gap='8px'
                >
                  <Autocomplete
                    sx={ModalAutocompleteStylesProps}
                    disableClearable
                    fullWidth
                    size={'small'}
                    id='checkFrequency'
                    options={checkFrequency}
                    getOptionLabel={option => option.name}
                    value={formik.values.checkFrequency}
                    onChange={(_, value) =>
                      formik.setFieldValue('checkFrequency', value || '')
                    }
                    onBlur={formik.handleBlur}
                    popupIcon={<SelectIcon />}
                    renderInput={params => (
                      <TextField {...params} name='checkFrequency' />
                    )}
                  />
                </Grid>
              </Grid>
              <ButtonsWrapper
                container
                xs={12}
                sx={{ paddingTop: '24px !important' }}
              >
                <Grid item xs={extraSmallScreen && 12}>
                  <GrayButtonSmall
                    fullWidth={extraSmallScreen}
                    size='small'
                    outline
                    onClick={handleClose}
                  >
                    Cancel
                  </GrayButtonSmall>
                </Grid>
                <Grid item xs={extraSmallScreen && 12}>
                  <PrimaryButtonSmall
                    fullWidth={extraSmallScreen}
                    variant='contained'
                    size='small'
                    type='submit'
                    onClick={handleSubmit}
                  >
                    Update Frequency
                  </PrimaryButtonSmall>
                </Grid>
              </ButtonsWrapper>
            </Grid>
          </ModalContainer>
        </ModalWrapper>
      </Fade>
    </Modal>
  );
};

export default EditFrequencyModal;
