import { useAppDispatch, useAppSelector } from '../../../store';
import { useCallback, useMemo } from 'react';
import {
  bulkDeleteEmailReportsThunk,
  createEmailReportThunk,
  editEmailReportThunk,
  getEmailReportFrequencyThunk,
  getEmailReportFullThunk,
  getEmailReportsThunk,
  getReportDeliveryTimesThunk,
  getReportTypesThunk,
} from '../redux/thunk';
import {
  BulkDeleteEmailReportsParams,
  CreateEmailReportParams,
  EditEmailReportParams,
  GetEmailReportFullParams,
  GetEmailReportsParams,
  IColumnVisibilityKeyEmailReports,
} from '../types';
import {
  resetColumnVisibilityModelEmailReports,
  setColumnVisibilityModelEmailReports,
  setDeleteElementsScheduledEmailReportsTable,
  setPaginationPageEmailReports,
  setPaginationPageSizeEmailReports,
  setRowSelectionModelEmailReports,
  setSearchEmailReports,
  setSortModelEmailReports,
} from '../redux/actions';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';

/**
 * Custom hook to manage email reports actions and state.
 * @returns {Object} Methods and state for managing email reports.
 */
export default function useEmailReports() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(state => state.emailReports);

  const memoizedState = useMemo(() => ({ ...state }), [state]);

  /**
   * Fetches email reports.
   * @param {GetEmailReportsParams} param - Parameters for fetching email reports.
   */
  const onGetEmailReports = useCallback(
    (param: GetEmailReportsParams) => {
      dispatch(getEmailReportsThunk(param));
    },
    [dispatch]
  );

  /**
   * Sets the search term for email reports.
   * @param {string} value - The search term.
   */
  const onSetSearchEmailReports = useCallback(
    (value: string) => {
      dispatch(setSearchEmailReports(value));
    },
    [dispatch]
  );

  /**
   * Sets the row selection model for email reports.
   * @param {GridRowSelectionModel} rowSelectionModel - The row selection model.
   */
  const onSetRowSelectionModelEmailReports = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => {
      dispatch(setRowSelectionModelEmailReports(rowSelectionModel));
    },
    [dispatch]
  );

  /**
   * Sets the column visibility model for email reports.
   * @param {IColumnVisibilityKeyEmailReports} key - The column key.
   * @param {boolean} value - Whether the column is visible.
   */
  const onSetColumnVisibilityModelEmailReports = useCallback(
    (key: IColumnVisibilityKeyEmailReports, value: boolean) => {
      dispatch(setColumnVisibilityModelEmailReports(key, value));
    },
    [dispatch]
  );

  /**
   * Resets the column visibility model for email reports to default.
   */
  const onResetColumnVisibilityModelEmailReports = useCallback(() => {
    dispatch(resetColumnVisibilityModelEmailReports());
  }, [dispatch]);

  /**
   * Sets the sort model for email reports.
   * @param {GridSortModel} sortModel - The sort model.
   */
  const onSetSortModelEmailReports = useCallback(
    (sortModel: GridSortModel) => {
      dispatch(setSortModelEmailReports(sortModel));
    },
    [dispatch]
  );

  /**
   * Sets the page size for pagination of email reports.
   * @param {number} pageSize - The page size.
   */
  const onSetPaginationPageSizeEmailReports = useCallback(
    (pageSize: number) => {
      dispatch(setPaginationPageSizeEmailReports(pageSize));
    },
    [dispatch]
  );

  /**
   * Sets the page for pagination of email reports.
   * @param {number} page - The page number.
   */
  const onSetPaginationPageEmailReports = useCallback(
    (page: number) => {
      dispatch(setPaginationPageEmailReports(page));
    },
    [dispatch]
  );

  /**
   * Fetches email report frequency options.
   */
  const onGetEmailReportFrequency = useCallback(() => {
    dispatch(getEmailReportFrequencyThunk());
  }, [dispatch]);

  /**
   * Fetches report delivery times.
   * @param {number} accountId - The account ID for fetching delivery times.
   */
  const onGetReportDeliveryTimes = useCallback(
    (accountId: number) => {
      dispatch(getReportDeliveryTimesThunk({ accountId }));
    },
    [dispatch]
  );

  /**
   * Fetches report types.
   */
  const onGetReportTypes = useCallback(() => {
    dispatch(getReportTypesThunk());
  }, [dispatch]);

  /**
   * Creates a new email report.
   * @param {CreateEmailReportParams} params - Parameters for creating an email report.
   */
  const onCreateEmailReport = useCallback(
    (params: CreateEmailReportParams) => {
      dispatch(createEmailReportThunk(params));
    },
    [dispatch]
  );

  /**
   * Deletes email reports in bulk.
   * @param {BulkDeleteEmailReportsParams} params - Parameters for bulk deleting email reports.
   */
  const onBulkDeleteEmailReports = useCallback(
    (params: BulkDeleteEmailReportsParams) => {
      dispatch(bulkDeleteEmailReportsThunk(params));
    },
    [dispatch]
  );

  /**
   * Fetches full details of an email report.
   * @param {GetEmailReportFullParams} param - Parameters for fetching full details of an email report.
   */
  const onGetEmailReportFull = useCallback(
    (param: GetEmailReportFullParams) => {
      dispatch(getEmailReportFullThunk(param));
    },
    [dispatch]
  );

  /**
   * Edits an existing email report.
   * @param {EditEmailReportParams} param - Parameters for editing an email report.
   */
  const onEditEmailReport = useCallback(
    (param: EditEmailReportParams) => {
      dispatch(editEmailReportThunk(param));
    },
    [dispatch]
  );

  /**
   * Sets the delete elements for the scheduled email reports table.
   * @param {GridRowSelectionModel} deleteElements - Elements to be deleted.
   */
  const onSetDeleteElementsScheduledEmailReportsTable = useCallback(
    (deleteElements: GridRowSelectionModel) => {
      dispatch(setDeleteElementsScheduledEmailReportsTable(deleteElements));
    },
    [dispatch]
  );

  return {
    ...memoizedState,
    onGetEmailReports,
    onSetSearchEmailReports,
    onSetRowSelectionModelEmailReports,
    onSetColumnVisibilityModelEmailReports,
    onResetColumnVisibilityModelEmailReports,
    onSetSortModelEmailReports,
    onSetPaginationPageSizeEmailReports,
    onSetPaginationPageEmailReports,
    onGetEmailReportFrequency,
    onGetReportDeliveryTimes,
    onGetReportTypes,
    onCreateEmailReport,
    onBulkDeleteEmailReports,
    onGetEmailReportFull,
    onEditEmailReport,
    onSetDeleteElementsScheduledEmailReportsTable,
  };
}
