import React from 'react';
import Grid from '@mui/material/Grid';
import {
  StyledITooltipContainerHead,
  StyledStatus,
  StyledStatusLabel,
  StyledStatusValue,
} from '../../../Overview/styles';
import { StyledITooltipContainer } from './styled';
import { TooltipProps } from 'recharts';
import dayjs from 'dayjs';

const ImprovedDeclined = ({
  active,
  label,
  payload,
}: TooltipProps<number, string>) => {
  if (active) {
    const improved = payload?.find(item => item.dataKey === 'improved');
    const declined = payload?.find(item => item.dataKey === 'declined');
    const lost = payload?.find(item => item.dataKey === 'lost');
    const noChange = payload?.find(item => item.dataKey === 'noChange');

    return (
      <StyledITooltipContainer item>
        <StyledITooltipContainerHead item>
          {dayjs(label).format('MMMM DD, YYYY')}
        </StyledITooltipContainerHead>
        {payload?.length && (
          <>
            {!!improved?.value && (
              <Grid container item xs={12}>
                <Grid container item xs={8} alignItems={'center'}>
                  <StyledStatus item $backgroundColor={'#81D32F'} />
                  <StyledStatusLabel item>Improved:</StyledStatusLabel>
                </Grid>
                <Grid xs={4} container item justifyContent={'flex-end'}>
                  <StyledStatusValue item>{improved.value}</StyledStatusValue>
                </Grid>
              </Grid>
            )}
            {!!declined?.value && (
              <Grid container item xs={12}>
                <Grid container item xs={8} alignItems={'center'}>
                  <StyledStatus item $backgroundColor={'#FF9F9F'} />
                  <StyledStatusLabel item>Declined:</StyledStatusLabel>
                </Grid>
                <Grid xs={4} container item justifyContent={'flex-end'}>
                  <StyledStatusValue item>
                    {-Number(declined.value)}
                  </StyledStatusValue>
                </Grid>
              </Grid>
            )}
            {!!lost?.value && (
              <Grid container item xs={12}>
                <Grid container item xs={8} alignItems={'center'}>
                  <StyledStatus item $backgroundColor={'#D86868'} />
                  <StyledStatusLabel item>Lost:</StyledStatusLabel>
                </Grid>
                <Grid xs={4} container item justifyContent={'flex-end'}>
                  <StyledStatusValue item>
                    {-Number(lost.value)}
                  </StyledStatusValue>
                </Grid>
              </Grid>
            )}
            {!!noChange?.value && (
              <Grid container item xs={12}>
                <Grid container item xs={8} alignItems={'center'}>
                  <StyledStatus item $backgroundColor={'#D0D5DD'} />
                  <StyledStatusLabel item>No change:</StyledStatusLabel>
                </Grid>
                <Grid xs={4} container item justifyContent={'flex-end'}>
                  <StyledStatusValue item>{noChange.value}</StyledStatusValue>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </StyledITooltipContainer>
    );
  }

  return null;
};

export { ImprovedDeclined };
