import React, { useState } from 'react';
import { Grid } from '@mui/material';
import AlertTriggeredModal from '../AlertsTable/components/AlertTriggeredModal/AlertTriggeredModal';
import useAlerts from '../../../../hooks/useAlerts';
import { useAppSelector } from '../../../../../../store';

interface AlertTriggeredViewProps {
  keywordCount: number;
  triggerId: number;
}

/**
 * A component that displays a view of an alert triggered by a keyword.
 * It shows the keyword count and provides an option to open a modal with more details about the alert.
 */
const AlertTriggeredView: React.FC<AlertTriggeredViewProps> = ({
  keywordCount,
  triggerId,
}) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const { onSetViewAlertByProject } = useAlerts();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleClose = () => {
    setIsOpenModal(false);
  };

  const handleOpen = () => {
    if (currentAccount?.accountId) setIsOpenModal(true);
    onSetViewAlertByProject({
      id: currentAccount.accountId,
      alertId: triggerId,
      successFn: () => {
        console.log('successFn');
      },
    });
  };

  return (
    <div>
      <Grid container gap={1}>
        <Grid item>{keywordCount}</Grid>
        <Grid item style={{ cursor: 'pointer' }} onClick={handleOpen}>
          <a>View</a>
        </Grid>
      </Grid>
      <AlertTriggeredModal
        handleClose={handleClose}
        open={isOpenModal}
        triggerId={triggerId}
      />
    </div>
  );
};

export default AlertTriggeredView;
