import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AllNotesData,
  BulkDeleteNotesParams,
  CreateNoteParams,
  EditNoteParams,
  GetAllNotesParams,
  GetProjectNotesParams,
  ProjectNotesData,
} from '../types';
import {
  bulkDeleteNotesAPI,
  createNoteAPI,
  editNoteAPI,
  getAllNotesAPI,
  getProjectNotesAPI,
} from '../api';
import { AxiosError } from 'axios';
import { checkAndRedirectToMaintenancePage } from '../../../utils';

export const getProjectNotesThunk = createAsyncThunk<
  ProjectNotesData,
  GetProjectNotesParams,
  { rejectValue: string }
>('getProjectNotes', async (params, thunkAPI) => {
  try {
    const { data } = await getProjectNotesAPI(params);

    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const createNoteThunk = createAsyncThunk<
  undefined,
  CreateNoteParams,
  { rejectValue: string }
>('createNote', async (params, thunkAPI) => {
  try {
    await createNoteAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);

      const errors = error?.response?.data?.errors;
      if (typeof errors === 'object') {
        params.errorFn(errors);
      } else {
        params.errorFn({ message: error?.response?.data.message });
      }
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const editNoteThunk = createAsyncThunk<
  undefined,
  EditNoteParams,
  { rejectValue: string }
>('editNote', async (params, thunkAPI) => {
  try {
    await editNoteAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }

    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const bulkDeleteNotesThunk = createAsyncThunk<
  undefined,
  BulkDeleteNotesParams,
  { rejectValue: string }
>('bulkDeleteNotes', async (params, thunkAPI) => {
  try {
    await bulkDeleteNotesAPI(params);
    params.successFn();
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }

    return thunkAPI.rejectWithValue('Server error!');
  }
});

export const getAllNotesThunk = createAsyncThunk<
  AllNotesData,
  GetAllNotesParams,
  { rejectValue: string }
>('getAllNotes', async (params, thunkAPI) => {
  try {
    const { data } = await getAllNotesAPI(params);

    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      checkAndRedirectToMaintenancePage(error);
    }
    return thunkAPI.rejectWithValue('Server error!');
  }
});
