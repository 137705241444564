import React, { useEffect, useMemo, useState } from 'react';
import { Divider, Grid, SwipeableDrawer } from '@mui/material';
import { ModalContainer } from '../Projects/components/ProjectRedactorModal/styles';
import { StylesFoldersList } from './styles';
import BackIcon from '../../../../shared/images/BackIcon';
import { IFolderTree } from '../../../auth/types';
import FoldersListItem from './FoldersListItem/FoldersListItem';
import {
  findFolderById,
  newFindParentFolders,
} from '../../../../utils/folders';
import BreadcrumbsFolders from './BreadcrumbsFolders/BreadcrumbsFolders';
import FolderPlusIcon from '../../../../shared/images/FolderPlusIcon';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../shared/buttons/styles';
import NewFolder from './NewFolder/NewFolder';
import NoFoldersFound from './NoFoldersFound/NoFoldersFound';
import ProjectsListItem from './ProjectsListItem/ProjectsListItem';
import { useAppSelector } from '../../../../store';
import { processFolderTree } from '../../utils';

interface MoveToFolderProps {
  isOpen: boolean;
  handleClose: () => void;
  setFolder: (id: number, name: string) => void;
}

const MoveToFolder: React.FC<MoveToFolderProps> = ({
  isOpen,
  handleClose,
  setFolder,
}) => {
  const folderTree = useAppSelector(state => state.auth.myAccount.folderTree);

  const [currentFolder, setCurrentFolder] = useState(folderTree);
  const [isOpenNewFolder, setIsOpenNewFolder] = useState(false);

  const parentFolders = useMemo(() => {
    const data = processFolderTree(folderTree);
    return newFindParentFolders(data, currentFolder.id);
  }, [currentFolder.id, folderTree]);

  const parentFolder = useMemo(
    () => parentFolders[parentFolders.length - 2],
    [parentFolders]
  );

  const foldersList = useMemo(() => {
    const data = processFolderTree(currentFolder);
    if (data.length === 1 && data.find(item => item.id === currentFolder.id)) {
      return data[0].children;
    }

    return data;
  }, [currentFolder]);

  const disabledFolder = useMemo(
    () => currentFolder.name === 'My Folders',
    [currentFolder.name]
  );

  const isRenderBackIcon = useMemo(() => {
    if (!parentFolder && currentFolder?.name !== 'My Folders') {
      return true;
    }
    if (parentFolder) {
      return true;
    }
    return false;
  }, [currentFolder?.name, parentFolder]);

  const backToFolder = () => {
    if (parentFolder) {
      handleSetCurrentFolder(parentFolder);
    } else {
      handleSetCurrentFolder(folderTree);
    }
  };

  const isRenderNewFolderIcon = useMemo(
    () => !(currentFolder?.name === 'My Folders' && !currentFolder?.available),
    [currentFolder?.name, currentFolder?.available]
  );

  const handleSetCurrentFolder = (folder: IFolderTree) => {
    setCurrentFolder(folder);
  };

  const handleOpenNewFolder = () => setIsOpenNewFolder(true);
  const handleCloseNewFolder = () => setIsOpenNewFolder(false);

  const handleConfirmFolder = () => {
    setFolder(currentFolder.id, currentFolder.name);
    handleClose();
  };

  useEffect(() => {
    if (isOpen) {
      handleSetCurrentFolder(folderTree);
    }
  }, [isOpen]);

  useEffect(() => {
    if (folderTree) {
      const folder = findFolderById(folderTree, currentFolder.id);
      if (folder) {
        setCurrentFolder(folder);
      }
    }
  }, [folderTree]);

  if (!isOpen) {
    return null;
  }

  return (
    <ModalContainer container xs={12} sx={{ gap: '8px' }}>
      <Grid xs={12}>
        <Grid>
          <Grid container gap={1}>
            {isRenderBackIcon && (
              <Grid style={{ cursor: 'pointer' }} onClick={backToFolder}>
                <BackIcon />
              </Grid>
            )}
            <Grid item>{currentFolder.name}</Grid>
          </Grid>
          <Grid>
            <Divider
              orientation='horizontal'
              flexItem
              sx={{
                margin: '16px 0',
                color: '#EDEDED',
              }}
            />
          </Grid>
        </Grid>
        <StylesFoldersList>
          {foldersList.map(folder => (
            <FoldersListItem
              key={folder.id}
              handleSetCurrentFolder={handleSetCurrentFolder}
              folder={folder}
            />
          ))}
          {currentFolder.projects.map(project =>
            project.available ? (
              <ProjectsListItem key={project.id} project={project} />
            ) : null
          )}
          {!foldersList.length && (
            <NoFoldersFound currentFolder={currentFolder} />
          )}
        </StylesFoldersList>
        <Grid>
          <Divider
            orientation='horizontal'
            flexItem
            sx={{
              margin: '16px 0',
              color: '#EDEDED',
            }}
          />
        </Grid>
        <Grid>
          <BreadcrumbsFolders
            folders={parentFolders}
            handleSetCurrentFolder={handleSetCurrentFolder}
          />
        </Grid>
        <Grid
          container
          justifyContent={'space-between'}
          alignItems={'center'}
          paddingTop={'24px'}
          paddingBottom={'16px'}
        >
          <Grid item>
            {isRenderNewFolderIcon && (
              <div style={{ cursor: 'pointer' }} onClick={handleOpenNewFolder}>
                <FolderPlusIcon />
              </div>
            )}
          </Grid>
          <Grid item>
            <Grid container gap={2}>
              <Grid item>
                <GrayButtonSmall size='small' outline onClick={handleClose}>
                  Cancel
                </GrayButtonSmall>
              </Grid>
              {!disabledFolder && (
                <Grid item>
                  <PrimaryButtonSmall
                    variant='contained'
                    size='small'
                    onClick={handleConfirmFolder}
                  >
                    Confirm Folder
                  </PrimaryButtonSmall>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SwipeableDrawer
        container={() => document.querySelector('.moveToFolder')}
        anchor={'bottom'}
        disableSwipeToOpen
        open={isOpenNewFolder}
        onClose={handleCloseNewFolder}
        onOpen={handleOpenNewFolder}
      >
        <NewFolder
          handleClose={handleCloseNewFolder}
          currentFolder={currentFolder}
          open={isOpenNewFolder}
        />
      </SwipeableDrawer>
    </ModalContainer>
  );
};

export default MoveToFolder;
