/**
 * Returns the display title for a list item based on the given parameter.
 *
 * @param {string} param - The key representing the list item.
 * @returns {string} The display title for the given list item.
 */
function renderListItemTitle(param: string) {
  switch (param) {
    case 'date':
      return 'Date';
    case 'numberOfKeywords':
      return 'Number of keywords';
    case 'rule':
      return 'Rule';
    case 'threshold':
      return 'Threshold';
    default:
      return '';
  }
}

/**
 * Returns the display title for a keyword-related list item based on the given parameter.
 *
 * @param {string} param - The key representing the keyword list item.
 * @returns {string} The display title for the given keyword list item.
 */
function renderListItemTitleByKeywords(param: string) {
  switch (param) {
    case 'date':
      return 'Date';
    case 'keywordName':
      return 'Keyword';
    case 'previousRank':
      return 'Previous Rank';
    case 'newRank':
      return 'New Rank';
    case 'difference':
      return 'Difference';
    case 'rule':
      return 'Rule';
    case 'threshold':
      return 'Threshold';
    default:
      return '';
  }
}

export { renderListItemTitle, renderListItemTitleByKeywords };
