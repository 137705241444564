import { Fade, Modal } from '@mui/material';
import React from 'react';
import { KeywordsAnalyticsModalModalProps } from '../../../../projects/components/modals/KeywordsAnalyticsModal/types';
import PositionHistory from '../../sharedProject/components/KeywordRankings/components/PositionHistory/PositionHistory';
import SearchResult from '../../sharedProject/components/KeywordRankings/components/SearchResult/SearchResult';
import { StyledKeywordsAnalyticsModal } from '../../../../projects/components/modals/KeywordsAnalyticsModal/styles';
import { ModalContainer } from '../../../../../shared/modal/styled';

const KeywordsAnalyticsSharedModal: React.FC<
  KeywordsAnalyticsModalModalProps
> = ({ onClose, open, id, name }) => {
  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <Fade in={open}>
        <StyledKeywordsAnalyticsModal>
          <ModalContainer container xs={12}>
            <PositionHistory onClose={onClose} id={id} name={name} />
            <SearchResult id={id} />
          </ModalContainer>
        </StyledKeywordsAnalyticsModal>
      </Fade>
    </Modal>
  );
};

export default KeywordsAnalyticsSharedModal;
