import React, { useEffect, useMemo, useState } from 'react';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  ModalContainer,
  ModalWrapper,
} from '../../Projects/components/ProjectRedactorModal/styles';
import { CloseDeletePopoverOne } from '../../../../auth/components/Settings/components/Account/styles';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonsWrapper } from '../../../../auth/components/Settings/components/Team/components/customTableStyles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../shared/buttons/styles';
import { ManualRefreshKeywordsModalProps } from './types';
import { useProjects } from '../../../../../hooks';
import { useNavigate, useParams } from 'react-router';
import { decryptData } from '../../../../../utils/cryptoJs';
import { enqueueSnackbar } from 'notistack';
import CustomToast from '../../../../../shared/CustomToast/CustomToast';
import { closeSnackbarAction } from '../../../../../utils/snackbar';
import { useAppSelector } from '../../../../../store';
import DailyRefreshesLimitExceededModal from '../../../../../shared/components/DailyRefreshesLimitExceededModal/DailyRefreshesLimitExceededModal';
import ROUTES from '../../../../../routes/constants';
import useAccountLimits from '../../../../auth/hooks/useAccountLimits';
import { roundNegativeToZero } from '../../../../../utils';

const ManualRefreshKeywordsModal: React.FC<ManualRefreshKeywordsModalProps> = ({
  keywordIds,
  open,
  onClose,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');
  const { id } = useParams();

  const navigate = useNavigate();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const dailyRefreshesLimitExceededOpen = useAppSelector(
    state => state.auth.accountLimitErrors.dailyRefreshesLimitExceeded.open
  );

  const { onRefreshKeywords, onGetNumberOfAvailableKeywordsToUpdate } =
    useProjects();
  const {
    onCloseRefreshesLimitLimitExceededModal,
    onCheckCannotRefreshExcessKeywordsModal,
    onCheckYouveReachedYourRefreshLimitForTodayModal,
  } = useAccountLimits();

  const [keywordsSkipped, setKeywordsSkipped] = useState(0);

  const decryptId = useMemo(() => {
    if (id) {
      return decryptData(id);
    }
  }, [id]);

  const handleCloseRefreshesLimitExceededModal = () => {
    onCloseRefreshesLimitLimitExceededModal();
  };

  const handleDailyRefreshesUpgradeAccount = () => {
    onCloseRefreshesLimitLimitExceededModal();
    navigate(ROUTES.upgradePlan);
  };

  const successFn = () => {
    enqueueSnackbar(
      <CustomToast
        message='Success'
        submessage='Keywords are being updated.'
      />,
      { action: closeSnackbarAction }
    );
    onClose();
  };

  const onRefresh = () => {
    if (decryptId) {
      let checkCannotRefreshExcessKeywordsModal;
      const checkYouveReachedYourRefreshLimitForTodayModal =
        onCheckYouveReachedYourRefreshLimitForTodayModal();

      if (!checkYouveReachedYourRefreshLimitForTodayModal) {
        checkCannotRefreshExcessKeywordsModal =
          onCheckCannotRefreshExcessKeywordsModal(
            roundNegativeToZero(keywordIds.length - keywordsSkipped)
          );
      }

      if (
        !checkCannotRefreshExcessKeywordsModal &&
        !checkYouveReachedYourRefreshLimitForTodayModal
      ) {
        onRefreshKeywords({
          accountId: currentAccount.accountId,
          projectId: Number(decryptId),
          data: {
            keywordIds: keywordIds as number[],
          },
          successFn,
        });
        successFn();
      }
    }
  };

  useEffect(() => {
    if (currentAccount?.accountId && decryptId && open) {
      onGetNumberOfAvailableKeywordsToUpdate({
        accountId: currentAccount.accountId,
        projectId: Number(decryptId),
        keywordIds: keywordIds.map(item => Number(item)),
        successFn: keywordCount => setKeywordsSkipped(keywordCount),
      });
    }
  }, [open]);

  if (!open) return null;

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <ModalWrapper>
            <ModalContainer container xs={12}>
              <Grid item xs={12} sx={{ position: 'relative' }}>
                <CloseDeletePopoverOne onClick={onClose}>
                  <CloseIcon fontSize='small' />
                </CloseDeletePopoverOne>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  fontSize='18px'
                  fontWeight='600'
                  lineHeight='28px'
                  color='#101828'
                  textAlign='center'
                >
                  Refresh Keywords?
                </Typography>
                <Typography
                  fontSize='14px'
                  fontWeight='400'
                  lineHeight='24px'
                  color='#475467'
                  marginTop='24px'
                  textAlign={'center'}
                >
                  Are you sure you want to refresh the selected keywords? This
                  action will count towards your daily keyword refresh credit.
                  {keywordsSkipped ? (
                    <>
                      <br />
                      <br />
                      {keywordsSkipped
                        ? `Out of the selected keywords, ${keywordsSkipped} will be skipped as they have already been manually updated today.`
                        : null}
                    </>
                  ) : null}
                </Typography>
              </Grid>
              <ButtonsWrapper container xs={12}>
                <Grid item xs={extraSmallScreen && 12}>
                  <GrayButtonSmall
                    fullWidth={extraSmallScreen}
                    size='small'
                    outline
                    onClick={onClose}
                  >
                    Cancel
                  </GrayButtonSmall>
                </Grid>
                <Grid item xs={extraSmallScreen && 12}>
                  <PrimaryButtonSmall
                    fullWidth={extraSmallScreen}
                    variant='contained'
                    size='small'
                    type='submit'
                    onClick={onRefresh}
                  >
                    Refresh Keywords
                  </PrimaryButtonSmall>
                </Grid>
              </ButtonsWrapper>
            </ModalContainer>
          </ModalWrapper>
        </Fade>
      </Modal>
      <DailyRefreshesLimitExceededModal
        onClose={handleCloseRefreshesLimitExceededModal}
        onUpgradeAccount={handleDailyRefreshesUpgradeAccount}
        open={dailyRefreshesLimitExceededOpen}
      />
    </>
  );
};

export default ManualRefreshKeywordsModal;
