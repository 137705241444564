import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import errorllustration from '../shared/images/Errorllustration.png';
import {
  StylesHeading,
  StylesSupportingText,
} from '../shared/components/NotFoundBlock/styles';

const MaintenancePage = () => {
  const mediumScreen = useMediaQuery('(max-width:900px)');

  return (
    <Grid
      xs={12}
      container
      align-items='center'
      height='calc(100vh - 380px)'
      padding='120px 24px'
    >
      <Grid
        xs={12}
        container
        item
        gap='48px'
        justifyContent='center'
        height='fit-content'
      >
        <Grid item width={mediumScreen ? '100%' : undefined}>
          <img
            width={mediumScreen ? '152px' : '350px'}
            src={errorllustration}
            alt={'errorllustration'}
          />
        </Grid>
        <Grid
          display={'flex'}
          item
          justifyContent={'center'}
          flexDirection={'column'}
        >
          <StylesHeading item>Maintenance in Progress</StylesHeading>
          <StylesSupportingText item paddingTop='16px' paddingBottom='48px'>
            The page you are trying to access is currently undergoing
            maintenance. Please try again later. We appreciate your patience!
          </StylesSupportingText>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MaintenancePage;
