import React, { useMemo } from 'react';
import { Divider, List } from '@mui/material';

import { EllipsisListActionsProps } from '../../../../../../../../../features/projects/components/Projects/components/TableProjects/components/EllipsisMenu/types';
import DeleteProjectAction from './DeleteProjectAction';
import EditProjectAction from './EditProjectAction';
import OpenInNewTabAction from './OpenInNewTabAction';
import AddKeywordsAction from './AddKeywordsAction';
import EditProjectTagsAction from './EditProjectTagsAction';
import RefreshAllKeywordsAction from './RefreshAllKeywordsAction';

import { MainContainer } from '../styles';
import EditFrequencyAction from './EditFrequencyAction';
import MoveToFolderAction from './MoveToFolderAction';
import { useAppSelector } from '../../../../../../../../../store';
import DownloadProjectInfoToCSVAction from './DownloadProjectInfoToCSVAction';

const ListActions: React.FC<EllipsisListActionsProps> = ({
  projectId,
  keywordCount,
  disableExportCSV,
  popupClose,
}) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const isAuth = useAppSelector(state => state.auth.isAuth);

  const isAddon = useMemo(
    () => !isAuth || (isAuth && currentAccount?.role?.name === 'Addon'),
    [currentAccount?.role?.name, isAuth]
  );

  return (
    <MainContainer>
      <List>
        <OpenInNewTabAction projectId={projectId} popupClose={popupClose} />
        <AddKeywordsAction projectId={projectId} popupClose={popupClose} />
        <RefreshAllKeywordsAction
          projectId={projectId}
          keywordCount={keywordCount}
          popupClose={popupClose}
        />
        <EditProjectAction projectId={projectId} popupClose={popupClose} />

        <EditProjectTagsAction projectId={projectId} popupClose={popupClose} />
        <EditFrequencyAction projectId={projectId} popupClose={popupClose} />
        <MoveToFolderAction projectIds={[projectId]} popupClose={popupClose} />
        <DownloadProjectInfoToCSVAction
          projectId={projectId}
          popupClose={popupClose}
          disableExportCSV={disableExportCSV}
        />
        {!isAddon && (
          <>
            <Divider
              orientation='horizontal'
              flexItem
              sx={{
                margin: '8px 0',
                color: '#EDEDED',
              }}
            />
            <DeleteProjectAction
              projectId={projectId}
              popupClose={popupClose}
            />
          </>
        )}
      </List>
    </MainContainer>
  );
};

export default ListActions;
