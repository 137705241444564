import { Grid } from '@mui/material';
import React from 'react';
import { Table, Pagination } from './components';

/**
 * Invoice component that displays the header and invoice table, and handles fetching invoices for the current account.
 *
 * @component
 * @returns {JSX.Element} Rendered Invoice component.
 */
const InvoiceTable = () => {
  return (
    <Grid xs={12}>
      <Table />
      <Pagination />
    </Grid>
  );
};

export default InvoiceTable;
