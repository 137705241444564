import { useCallback } from 'react';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import { Range } from 'react-date-range';

import { useAppDispatch } from '../../../store';
import {
  ColumnVisibilityKey,
  GetAccountTagsParams,
  GetProjectsParams,
  NewProjectParams,
  DeleteOneProjectParams,
  BulkDeleteProjectsParams,
  EditProjectParams,
  AddKeywordsParams,
  AccountTag,
  GetProjectExpandedParams,
  GetRefreshAllKeywordsParams,
  ChangeFrequencyForProjectsParams,
  AddTagsParams,
  GetListAvailableProjectsParams,
  ImprovedVsDeclinedVisibilityKey,
  GetProjectsOverviewParams,
  NotRankedVisibilityKey,
  ColumnVisibilityKeyKeywordRankings,
  GetProjectsImprovedVsDeclinedParams,
  GetKeywordPositionHistoryParams,
  GetKeywordRankingParams,
  DeleteKeywordsParams,
  RefreshKeywordsParams,
  GetProjectsKeywordTrendsParams,
  GetKeywordSearchResultsParams,
  MoveProjectsToFolderParams,
  MoveFoldersToFolderParams,
  UpdateKeywordPositionParams,
  KeywordRanking,
  SearchLocationsParams,
  SearchLocationsStateMeta,
  GetNumberOfAvailableKeywordsToUpdateParams,
  Project,
  GetProjectsNumberOfAvailableKeywordsToUpdateParams,
  IDownloadProjectInfoToCSVParams,
} from '../types';
import {
  getProjectsThunk,
  getCheckFrequencyThunk,
  getLanguagesThunk,
  getGoogleDomainsThunk,
  getDeviceTypesThunk,
  getAccountTagsThunk,
  newProjectThunk,
  deleteOneProjectThunk,
  bulkDeleteProjectsThunk,
  onGetCurrentProjectThunk,
  editProjectThunk,
  addKeywordsThunk,
  getProjectExpandedThunk,
  refreshAllKeywordsThunk,
  changeFrequencyForProjectsThunk,
  addTagsThunk,
  getListAvailableProjectsThunk,
  getProjectsOverviewThunk,
  getProjectsImprovedVsDeclinedThunk,
  getKeywordPositionHistoryThunk,
  getKeywordRankingsThunk,
  deleteKeywordsThunk,
  getProjectsKeywordTrendsThunk,
  getKeywordSearchResultsThunk,
  getProjectPerformanceThunk,
  moveProjectsToFolderThunk,
  moveFoldersToFolderThunk,
  updateKeywordPositionThunk,
  searchLocationsThunk,
  refreshKeywordsThunk,
  getNumberOfAvailableKeywordsToUpdateThunk,
  getKeywordTagsThunk,
  getProjectsNumberOfAvailableKeywordsToUpdateThunk,
  geLocationsOfChinaThunk,
  newProjectForGoogleLocalThunk,
  newProjectForGoogleMapsThunk,
  newProjectForYoutubeThunk,
  newProjectForBingThunk,
  newProjectForYahooThunk,
  newProjectForBaiduThunk,
  getLanguagesBingThunk,
  getLanguagesYoutubeThunk,
  getLanguagesYahooThunk,
  getLanguagesBaiduThunk,
  geLocationsBingThunk,
  geLocationsYoutubeThunk,
  geLocationsYahooThunk,
  geLocationsBaiduThunk,
  downloadProjectInfoToCSVThunk,
} from '../redux/thunk';
import {
  resetColumnVisibilityModel,
  clearColumnVisibilityModel,
  setColumnVisibilityModel,
  setPaginationPage,
  setPaginationPageSize,
  setRowSelectionModel,
  setSearchProjects,
  setSortModelProjects,
  setIsShowImprovedVsDeclined,
  setIsShowPositionHistory,
  setImprovedVsDeclinedVisibilityModel,
  setProjectExpandedGraphsRangeDate,
  setKeywordTrendsVisibilityModel,
  setDeviceTypeForProjectExpanded,
  resetColumnVisibilityModelKeywordRankings,
  setColumnVisibilityModelKeywordRankings,
  setColumnKeywordPositionModel,
  setSearchKeywordRankings,
  setActivePeriodImprovedVsDeclined,
  setCloseKeywordPositionHistory,
  setKeywordPaginationPage,
  setKeywordPaginationPageSize,
  setRowKeywordsSelectionModel,
  setSortModelKeywords,
  setActivePeriodKeywordTrends,
  setActivePeriodProjectPerformance,
  setProjectPerformanceRangeDate,
  setProjectPerformanceIsShowNotes,
  setProjectPerformanceIsLinearGraph,
  setKeywordAnalyticPeriod,
  setPositionHistoryLinearGraph,
  setShowCompetitorsForGraph,
  setFrequencyFilterForProjectsTable,
  resetKeywordPositionModel,
  closeProjectExpandedPage,
  setUpdatedKeywords,
  setShowCompetitorsForPositionHistory,
  setSearchLocationsMeta,
  setDeleteElementsProjectsTable,
  setStartOfKeywordUpdate,
  setDeleteElementsKeywordRankingsTable,
  updateProjects,
  resetKeywordRankingsState,
  setLocationsOfChinaMeta,
  setRefreshAllKeywordsModal,
  setRefreshKeywordsModal,
  setNewProjectModal,
  setLocationsBingMeta,
  setLocationsYoutubeMeta,
  setLocationsYahooMeta,
  setLocationsBaiduMeta,
} from '../redux/actions';
import {
  selectKeywordTags,
  selectTags,
  showTableFilters,
} from '../redux/slice';
import { GetProjectPerformanceParams } from '../types/projectPerformance';

export default function useProjects() {
  const dispatch = useAppDispatch();

  const onGetProjects = useCallback(
    (params: GetProjectsParams) => {
      dispatch(getProjectsThunk(params));
    },
    [dispatch]
  );

  const onSetSortModelProjects = useCallback(
    (sortModel: GridSortModel) => {
      dispatch(setSortModelProjects(sortModel));
    },
    [dispatch]
  );

  const onSetSortModelKeywords = useCallback(
    (sortModel: GridSortModel) => {
      dispatch(setSortModelKeywords(sortModel));
    },
    [dispatch]
  );

  const onSetSearchProjects = useCallback(
    (value: string) => {
      dispatch(setSearchProjects(value));
    },
    [dispatch]
  );

  const onSetColumnVisibilityModel = useCallback(
    (key: ColumnVisibilityKey, value: boolean) => {
      dispatch(setColumnVisibilityModel(key, value));
    },
    [dispatch]
  );

  const onSetColumnVisibilityModelKeywordRankings = useCallback(
    (key: ColumnVisibilityKeyKeywordRankings, value: boolean) => {
      dispatch(setColumnVisibilityModelKeywordRankings(key, value));
    },
    [dispatch]
  );

  const onResetColumnVisibilityModel = useCallback(() => {
    dispatch(resetColumnVisibilityModel());
  }, [dispatch]);

  const onClearColumnVisibilityModel = useCallback(() => {
    dispatch(clearColumnVisibilityModel());
  }, [dispatch]);

  const onSetPaginationPageSize = useCallback(
    (pageSize: number) => {
      dispatch(setPaginationPageSize(pageSize));
    },
    [dispatch]
  );

  const onSetKeywordPaginationPageSize = useCallback(
    (pageSize: number) => {
      dispatch(setKeywordPaginationPageSize(pageSize));
    },
    [dispatch]
  );

  const onSetPaginationPage = useCallback(
    (page: number) => {
      dispatch(setPaginationPage(page));
    },
    [dispatch]
  );

  const onSetKeywordPaginationPage = useCallback(
    (page: number) => {
      dispatch(setKeywordPaginationPage(page));
    },
    [dispatch]
  );

  const onGetCheckFrequency = useCallback(() => {
    dispatch(getCheckFrequencyThunk());
  }, [dispatch]);

  const onGetLanguages = useCallback(() => {
    dispatch(getLanguagesThunk());
  }, [dispatch]);

  const onGetLanguagesBing = useCallback(() => {
    dispatch(getLanguagesBingThunk());
  }, [dispatch]);

  const onGetLanguagesYoutube = useCallback(() => {
    dispatch(getLanguagesYoutubeThunk());
  }, [dispatch]);

  const onGetLanguagesYahoo = useCallback(() => {
    dispatch(getLanguagesYahooThunk());
  }, [dispatch]);

  const onGetLanguagesBaidu = useCallback(() => {
    dispatch(getLanguagesBaiduThunk());
  }, [dispatch]);

  const onGetGoogleDomains = useCallback(() => {
    dispatch(getGoogleDomainsThunk());
  }, [dispatch]);

  const onGetDeviceTypes = useCallback(() => {
    dispatch(getDeviceTypesThunk());
  }, [dispatch]);

  const onGetAccountTags = useCallback(
    (params: GetAccountTagsParams) => {
      dispatch(getAccountTagsThunk(params));
    },
    [dispatch]
  );

  const onNewProject = useCallback(
    (params: NewProjectParams) => {
      dispatch(newProjectThunk(params));
    },
    [dispatch]
  );

  const onNewProjectForGoogleLocal = useCallback(
    (params: NewProjectParams) => {
      dispatch(newProjectForGoogleLocalThunk(params));
    },
    [dispatch]
  );

  const onNewProjectForGoogleMaps = useCallback(
    (params: NewProjectParams) => {
      dispatch(newProjectForGoogleMapsThunk(params));
    },
    [dispatch]
  );

  const onNewProjectForYoutube = useCallback(
    (params: NewProjectParams) => {
      dispatch(newProjectForYoutubeThunk(params));
    },
    [dispatch]
  );

  const onNewProjectForBing = useCallback(
    (params: NewProjectParams) => {
      dispatch(newProjectForBingThunk(params));
    },
    [dispatch]
  );

  const onNewProjectForYahoo = useCallback(
    (params: NewProjectParams) => {
      dispatch(newProjectForYahooThunk(params));
    },
    [dispatch]
  );

  const onNewProjectForBaidu = useCallback(
    (params: NewProjectParams) => {
      dispatch(newProjectForBaiduThunk(params));
    },
    [dispatch]
  );

  const onSetRowSelectionModel = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => {
      dispatch(setRowSelectionModel(rowSelectionModel));
    },
    [dispatch]
  );

  const onSetRowSelectionKeywordsModel = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => {
      dispatch(setRowKeywordsSelectionModel(rowSelectionModel));
    },
    [dispatch]
  );

  const onDeleteOneProject = useCallback(
    (prams: DeleteOneProjectParams) => {
      dispatch(deleteOneProjectThunk(prams));
    },
    [dispatch]
  );

  const onBulkDeleteProjects = useCallback(
    (prams: BulkDeleteProjectsParams) => {
      dispatch(bulkDeleteProjectsThunk(prams));
    },
    [dispatch]
  );

  const onGetCurrentProject = useCallback(
    (prams: GetProjectExpandedParams) => {
      dispatch(onGetCurrentProjectThunk(prams));
    },
    [dispatch]
  );

  const onEditProject = useCallback(
    (params: EditProjectParams) => {
      dispatch(editProjectThunk(params));
    },
    [dispatch]
  );

  const onAddKeywords = useCallback(
    (params: AddKeywordsParams) => {
      dispatch(addKeywordsThunk(params));
    },
    [dispatch]
  );

  const onSelectTags = useCallback(
    (tags: AccountTag[]) => {
      dispatch(selectTags(tags));
    },
    [dispatch]
  );

  const onSelectKeywordTags = useCallback(
    (tags: AccountTag[]) => {
      dispatch(selectKeywordTags(tags));
    },
    [dispatch]
  );

  const onSetShowTableFilters = useCallback(
    (isShow: boolean) => {
      dispatch(showTableFilters(isShow));
    },
    [dispatch]
  );

  const onGetProjectExtended = useCallback(
    (prams: GetProjectExpandedParams) => {
      dispatch(getProjectExpandedThunk(prams));
    },
    [dispatch]
  );

  const onRefreshAllKeywords = useCallback(
    (prams: GetRefreshAllKeywordsParams) => {
      dispatch(refreshAllKeywordsThunk(prams));
    },
    [dispatch]
  );

  const onChangeFrequencyForProjects = useCallback(
    (prams: ChangeFrequencyForProjectsParams) => {
      dispatch(changeFrequencyForProjectsThunk(prams));
    },
    [dispatch]
  );

  const onAddTags = useCallback(
    (params: AddTagsParams) => {
      dispatch(addTagsThunk(params));
    },
    [dispatch]
  );

  const onGetProjectsListForInvite = useCallback(
    (prams: GetListAvailableProjectsParams) => {
      dispatch(getListAvailableProjectsThunk(prams));
    },
    [dispatch]
  );

  const onSetIsShowImprovedVsDeclined = useCallback(
    (prams: boolean) => {
      dispatch(setIsShowImprovedVsDeclined(prams));
    },
    [dispatch]
  );

  const onSetIsShowPositionHistory = useCallback(
    (prams: boolean) => {
      dispatch(setIsShowPositionHistory(prams));
    },
    [dispatch]
  );

  const onImprovedVsDeclinedVisibilityModel = useCallback(
    (key: ImprovedVsDeclinedVisibilityKey, value: boolean) => {
      dispatch(setImprovedVsDeclinedVisibilityModel(key, value));
    },
    [dispatch]
  );

  const onGetProjectsOverview = useCallback(
    (prams: GetProjectsOverviewParams) => {
      dispatch(getProjectsOverviewThunk(prams));
    },
    [dispatch]
  );

  const onSetProjectExpandedGraphsRangeDate = useCallback(
    (prams: Range) => {
      dispatch(setProjectExpandedGraphsRangeDate(prams));
    },
    [dispatch]
  );

  const onKeywordTrendsVisibilityModel = useCallback(
    (key: NotRankedVisibilityKey, value: boolean) => {
      dispatch(setKeywordTrendsVisibilityModel(key, value));
    },
    [dispatch]
  );

  const onSetDeviceTypeForProjectExpanded = useCallback(
    (deviceType: string) => {
      dispatch(setDeviceTypeForProjectExpanded(deviceType));
    },
    [dispatch]
  );

  const onResetColumnVisibilityModelKeywordRankings = useCallback(() => {
    dispatch(resetColumnVisibilityModelKeywordRankings());
  }, [dispatch]);

  const onGetProjectsImprovedVsDeclined = useCallback(
    (prams: GetProjectsImprovedVsDeclinedParams) => {
      dispatch(getProjectsImprovedVsDeclinedThunk(prams));
    },
    [dispatch]
  );

  const onSetColumnKeywordPositionModel = useCallback(
    (items: string[]) => {
      dispatch(setColumnKeywordPositionModel(items));
    },
    [dispatch]
  );

  const onSetSearchKeywordRankings = useCallback(
    (value: string) => {
      dispatch(setSearchKeywordRankings(value));
    },
    [dispatch]
  );

  const onSetActivePeriodImprovedVsDeclined = useCallback(
    (prams: { label: string; value: string }) => {
      dispatch(setActivePeriodImprovedVsDeclined(prams));
    },
    [dispatch]
  );

  const onGetKeywordPositionHistory = useCallback(
    (prams: GetKeywordPositionHistoryParams) => {
      dispatch(getKeywordPositionHistoryThunk(prams));
    },
    [dispatch]
  );

  const onCloseKeywordPositionHistory = useCallback(
    (prams: { id: number }) => {
      dispatch(setCloseKeywordPositionHistory(prams.id));
    },
    [dispatch]
  );

  const onSetKeywordAnalyticPeriod = useCallback(
    (prams: { id: number; period: { label: string; value: string } }) => {
      dispatch(setKeywordAnalyticPeriod(prams.id, prams.period));
    },
    [dispatch]
  );

  const onGetKeywordRankings = useCallback(
    (prams: GetKeywordRankingParams) => {
      dispatch(getKeywordRankingsThunk(prams));
    },
    [dispatch]
  );

  const onRefreshKeywords = useCallback(
    (prams: RefreshKeywordsParams) => {
      dispatch(refreshKeywordsThunk(prams));
    },
    [dispatch]
  );

  const onDeleteKeywords = useCallback(
    (prams: DeleteKeywordsParams) => {
      dispatch(deleteKeywordsThunk(prams));
    },
    [dispatch]
  );

  const onGetProjectsKeywordTrends = useCallback(
    (prams: GetProjectsKeywordTrendsParams) => {
      dispatch(getProjectsKeywordTrendsThunk(prams));
    },
    [dispatch]
  );

  const onSetActivePeriodKeywordTrends = useCallback(
    (prams: { label: string; value: string }) => {
      dispatch(setActivePeriodKeywordTrends(prams));
    },
    [dispatch]
  );

  const onSetActivePeriodProjectPerformance = useCallback(
    (prams: { label: string; value: string }) => {
      dispatch(setActivePeriodProjectPerformance(prams));
    },
    [dispatch]
  );

  const onSetProjectPerformanceRangeDate = useCallback(
    (prams: Range) => {
      dispatch(setProjectPerformanceRangeDate(prams));
    },
    [dispatch]
  );

  const onSetProjectPerformanceIsShowNotes = useCallback(
    (prams: boolean) => {
      dispatch(setProjectPerformanceIsShowNotes(prams));
    },
    [dispatch]
  );

  const onSetProjectPerformanceIsLinearGraph = useCallback(
    (prams: boolean) => {
      dispatch(setProjectPerformanceIsLinearGraph(prams));
    },
    [dispatch]
  );

  const onGetKeywordSearchResults = useCallback(
    (prams: GetKeywordSearchResultsParams) => {
      dispatch(getKeywordSearchResultsThunk(prams));
    },
    [dispatch]
  );

  const onSetPositionHistoryLinearGraph = useCallback(
    (prams: { value: boolean; id: number }) => {
      dispatch(setPositionHistoryLinearGraph(prams.value, prams.id));
    },
    [dispatch]
  );

  const onGetProjectPerformance = useCallback(
    (prams: GetProjectPerformanceParams) => {
      dispatch(getProjectPerformanceThunk(prams));
    },
    [dispatch]
  );

  const onSetShowCompetitorsForGraph = useCallback(
    (values: number[]) => {
      dispatch(setShowCompetitorsForGraph(values));
    },
    [dispatch]
  );

  const onSetFrequencyFilterForProjectsTable = useCallback(
    (frequency: string) => {
      dispatch(setFrequencyFilterForProjectsTable(frequency));
    },
    [dispatch]
  );

  const onMoveProjectsToFolder = useCallback(
    (prams: MoveProjectsToFolderParams) => {
      dispatch(moveProjectsToFolderThunk(prams));
    },
    [dispatch]
  );

  const onMoveFoldersToFolder = useCallback(
    (prams: MoveFoldersToFolderParams) => {
      dispatch(moveFoldersToFolderThunk(prams));
    },
    [dispatch]
  );

  const onUpdateKeywordPosition = useCallback(
    (prams: UpdateKeywordPositionParams) => {
      dispatch(updateKeywordPositionThunk(prams));
    },
    [dispatch]
  );

  const onResetKeywordPositionModel = useCallback(() => {
    dispatch(resetKeywordPositionModel());
  }, [dispatch]);

  const onCloseProjectExpandedPage = useCallback(() => {
    dispatch(closeProjectExpandedPage());
  }, [dispatch]);

  const onSetUpdatedKeywords = useCallback(
    (keywords: KeywordRanking[]) => {
      dispatch(setUpdatedKeywords(keywords));
    },
    [dispatch]
  );

  const onSetShowCompetitorsForPositionHistory = useCallback(
    (showCompetitors: number[], keywordAnalyticId: number) => {
      dispatch(
        setShowCompetitorsForPositionHistory(showCompetitors, keywordAnalyticId)
      );
    },
    [dispatch]
  );

  const onGetSearchLocations = useCallback(
    (params: SearchLocationsParams) => {
      dispatch(searchLocationsThunk(params));
    },
    [dispatch]
  );

  const onSetSearchLocationsMeta = useCallback(
    (params: SearchLocationsStateMeta) => {
      dispatch(setSearchLocationsMeta(params));
    },
    [dispatch]
  );

  const onSetDeleteElementsProjectsTable = useCallback(
    (deleteElements: GridRowSelectionModel) => {
      dispatch(setDeleteElementsProjectsTable(deleteElements));
    },
    [dispatch]
  );

  const onSetStartOfKeywordUpdate = useCallback(
    (keywordIds: number[]) => {
      dispatch(setStartOfKeywordUpdate(keywordIds));
    },
    [dispatch]
  );

  const onGetNumberOfAvailableKeywordsToUpdate = useCallback(
    (params: GetNumberOfAvailableKeywordsToUpdateParams) => {
      dispatch(getNumberOfAvailableKeywordsToUpdateThunk(params));
    },
    [dispatch]
  );

  const onSetDeleteElementsKeywordRankingsTable = useCallback(
    (deleteElements: GridRowSelectionModel) => {
      dispatch(setDeleteElementsKeywordRankingsTable(deleteElements));
    },
    [dispatch]
  );

  const onUpdateProjects = useCallback(
    (projects: Project[]) => {
      dispatch(updateProjects(projects));
    },
    [dispatch]
  );

  const onGetKeywordTags = useCallback(
    (params: GetAccountTagsParams) => {
      dispatch(getKeywordTagsThunk(params));
    },
    [dispatch]
  );

  const onGetProjectsNumberOfAvailableKeywordsToUpdate = useCallback(
    (params: GetProjectsNumberOfAvailableKeywordsToUpdateParams) => {
      dispatch(getProjectsNumberOfAvailableKeywordsToUpdateThunk(params));
    },
    [dispatch]
  );

  const onResetKeywordRankingsState = useCallback(() => {
    dispatch(resetKeywordRankingsState());
  }, [dispatch]);

  const onGetLocationsOfChina = useCallback(
    (params: SearchLocationsParams) => {
      dispatch(geLocationsOfChinaThunk(params));
    },
    [dispatch]
  );

  const onSetLocationsOfChinaMeta = useCallback(
    (params: SearchLocationsStateMeta) => {
      dispatch(setLocationsOfChinaMeta(params));
    },
    [dispatch]
  );

  const onSetRefreshAllKeywordsModal = useCallback(
    (value: boolean) => {
      dispatch(setRefreshAllKeywordsModal(value));
    },
    [dispatch]
  );

  const onSetRefreshKeywordsModal = useCallback(
    (value: boolean) => {
      dispatch(setRefreshKeywordsModal(value));
    },
    [dispatch]
  );

  const onSetNewProjectModal = useCallback(
    (value: boolean) => {
      dispatch(setNewProjectModal(value));
    },
    [dispatch]
  );

  const onGetLocationsBing = useCallback(
    (params: SearchLocationsParams) => {
      dispatch(geLocationsBingThunk(params));
    },
    [dispatch]
  );

  const onSetLocationsBingMeta = useCallback(
    (params: SearchLocationsStateMeta) => {
      dispatch(setLocationsBingMeta(params));
    },
    [dispatch]
  );

  const onGetLocationsYoutube = useCallback(
    (params: SearchLocationsParams) => {
      dispatch(geLocationsYoutubeThunk(params));
    },
    [dispatch]
  );

  const onSetLocationsYoutubeMeta = useCallback(
    (params: SearchLocationsStateMeta) => {
      dispatch(setLocationsYoutubeMeta(params));
    },
    [dispatch]
  );

  const onGetLocationsYahoo = useCallback(
    (params: SearchLocationsParams) => {
      dispatch(geLocationsYahooThunk(params));
    },
    [dispatch]
  );

  const onSetLocationsYahooMeta = useCallback(
    (params: SearchLocationsStateMeta) => {
      dispatch(setLocationsYahooMeta(params));
    },
    [dispatch]
  );

  const onGetLocationsBaidu = useCallback(
    (params: SearchLocationsParams) => {
      dispatch(geLocationsBaiduThunk(params));
    },
    [dispatch]
  );

  const onSetLocationsBaiduMeta = useCallback(
    (params: SearchLocationsStateMeta) => {
      dispatch(setLocationsBaiduMeta(params));
    },
    [dispatch]
  );

  const onDownloadProjectInfoToCSV = useCallback(
    (params: IDownloadProjectInfoToCSVParams) => {
      dispatch(downloadProjectInfoToCSVThunk(params));
    },
    [dispatch]
  );

  return {
    onGetProjects,
    onSetSortModelProjects,
    onSetSortModelKeywords,
    onSetSearchProjects,
    onSetColumnVisibilityModel,
    onResetColumnVisibilityModel,
    onClearColumnVisibilityModel,
    onSetPaginationPageSize,
    onSetPaginationPage,
    onSetKeywordPaginationPage,
    onGetCheckFrequency,
    onGetLanguages,
    onGetLanguagesBing,
    onGetGoogleDomains,
    onGetDeviceTypes,
    onGetAccountTags,
    onNewProject,
    onNewProjectForBaidu,
    onNewProjectForGoogleMaps,
    onNewProjectForBing,
    onNewProjectForYahoo,
    onNewProjectForGoogleLocal,
    onNewProjectForYoutube,
    onSetRowSelectionModel,
    onSetRowSelectionKeywordsModel,
    onDeleteOneProject,
    onBulkDeleteProjects,
    onGetCurrentProject,
    onEditProject,
    onAddKeywords,
    onSelectTags,
    onSetShowTableFilters,
    onGetProjectExtended,
    onRefreshAllKeywords,
    onChangeFrequencyForProjects,
    onAddTags,
    onGetProjectsListForInvite,
    onSetIsShowImprovedVsDeclined,
    onSetIsShowPositionHistory,
    onImprovedVsDeclinedVisibilityModel,
    onGetProjectsOverview,
    onSetProjectExpandedGraphsRangeDate,
    onKeywordTrendsVisibilityModel,
    onSetDeviceTypeForProjectExpanded,
    onResetColumnVisibilityModelKeywordRankings,
    onSetColumnVisibilityModelKeywordRankings,
    onGetProjectsImprovedVsDeclined,
    onSetColumnKeywordPositionModel,
    onSetSearchKeywordRankings,
    onSetActivePeriodImprovedVsDeclined,
    onGetKeywordPositionHistory,
    onCloseKeywordPositionHistory,
    onSetKeywordAnalyticPeriod,
    onGetKeywordRankings,
    onSetKeywordPaginationPageSize,
    onRefreshKeywords,
    onDeleteKeywords,
    onGetProjectsKeywordTrends,
    onSetActivePeriodKeywordTrends,
    onSetActivePeriodProjectPerformance,
    onSetProjectPerformanceRangeDate,
    onSetProjectPerformanceIsShowNotes,
    onSetProjectPerformanceIsLinearGraph,
    onGetKeywordSearchResults,
    onSetPositionHistoryLinearGraph,
    onGetProjectPerformance,
    onSetShowCompetitorsForGraph,
    onSetFrequencyFilterForProjectsTable,
    onMoveProjectsToFolder,
    onMoveFoldersToFolder,
    onUpdateKeywordPosition,
    onResetKeywordPositionModel,
    onCloseProjectExpandedPage,
    onSetUpdatedKeywords,
    onSetShowCompetitorsForPositionHistory,
    onGetSearchLocations,
    onSetSearchLocationsMeta,
    onSetDeleteElementsProjectsTable,
    onSetStartOfKeywordUpdate,
    onGetNumberOfAvailableKeywordsToUpdate,
    onSetDeleteElementsKeywordRankingsTable,
    onUpdateProjects,
    onGetKeywordTags,
    onSelectKeywordTags,
    onGetProjectsNumberOfAvailableKeywordsToUpdate,
    onResetKeywordRankingsState,
    onGetLocationsOfChina,
    onSetLocationsOfChinaMeta,
    onSetRefreshAllKeywordsModal,
    onSetRefreshKeywordsModal,
    onSetNewProjectModal,
    onGetLanguagesYoutube,
    onGetLanguagesYahoo,
    onGetLanguagesBaidu,
    onGetLocationsBing,
    onSetLocationsBingMeta,
    onGetLocationsYoutube,
    onSetLocationsYoutubeMeta,
    onGetLocationsYahoo,
    onSetLocationsYahooMeta,
    onGetLocationsBaidu,
    onSetLocationsBaiduMeta,
    onDownloadProjectInfoToCSV,
  };
}
