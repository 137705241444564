import { createAction } from '@reduxjs/toolkit';
import {
  ColumnRootVisibilityKey,
  ColumnVisibilityKey,
  IFolder,
} from '../types';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { Project } from '../../projects/types';

/**
 * Action to set the column visibility model for root folders.
 * @param {ColumnRootVisibilityKey} key - The column key.
 * @param {boolean} value - Whether the column is visible.
 * @returns {Object} The action to set the column visibility for root folders.
 */
const setRootColumnVisibilityModel = createAction(
  'set-column-visibility-model-root-folder',
  function prepare(key: ColumnRootVisibilityKey, value: boolean) {
    return {
      payload: {
        key,
        value,
      },
    };
  }
);

/**
 * Action to set the column visibility model for folders.
 * @param {ColumnVisibilityKey} key - The column key.
 * @param {boolean} value - Whether the column is visible.
 * @returns {Object} The action to set the column visibility for folders.
 */
const setColumnVisibilityModel = createAction(
  'set-column-visibility-model-folders',
  function prepare(key: ColumnVisibilityKey, value: boolean) {
    return {
      payload: {
        key,
        value,
      },
    };
  }
);

/**
 * Action to reset the column visibility model for folders.
 * @returns {Object} The action to reset the column visibility model for folders.
 */
const resetColumnVisibilityModel = createAction(
  'reset-column-visibility-model-folders'
);

/**
 * Action to set the page size for pagination of folders.
 * @param {number} pageSize - The page size.
 * @returns {Object} The action to set the pagination page size.
 */
const setPaginationPageSize = createAction(
  'set-pagination-page-size-folders',
  function prepare(pageSize: number) {
    return {
      payload: pageSize,
    };
  }
);

/**
 * Action to set the page number for pagination of folders.
 * @param {number} page - The page number.
 * @returns {Object} The action to set the pagination page.
 */
const setPaginationPage = createAction(
  'set-pagination-page-folders',
  function prepare(page: number) {
    return {
      payload: page,
    };
  }
);

/**
 * Action to set the search term for folders.
 * @param {string} value - The search term.
 * @returns {Object} The action to set the search term for folders.
 */
const setSearchFolders = createAction(
  'set-search-folders',
  function prepare(value: string) {
    return {
      payload: value,
    };
  }
);

/**
 * Action to set the row selection model for folders.
 * @param {IFolder[]} rowSelectionModel - The row selection model.
 * @returns {Object} The action to set the row selection model.
 */
const setRowSelectionModel = createAction(
  'set-row-selection-model-folders',
  function prepare(rowSelectionModel: IFolder[]) {
    return {
      payload: rowSelectionModel,
    };
  }
);

/**
 * Action to reset the state of folders.
 * @returns {Object} The action to reset the folders state.
 */
const resetFoldersState = createAction('resetFoldersState');

/**
 * Action to set the delete elements for the root folders table.
 * @param {IFolder[]} deleteElements - Elements to be deleted.
 * @returns {Object} The action to set the delete elements.
 */
const setDeleteElementsRootFoldersTable = createAction(
  'setDeleteElementsRootFoldersTable',
  function prepare(deleteElements: IFolder[]) {
    return {
      payload: { deleteElements },
    };
  }
);

/**
 * Action to set the sort model for the root folder.
 * @param {GridSortModel} sortModel - The sort model.
 * @returns {Object} The action to set the sort model for the root folder.
 */
const setSortModelRootFolder = createAction(
  'setSortModelRootFolder',
  function prepare(sortModel: GridSortModel) {
    return {
      payload: sortModel,
    };
  }
);

/**
 * Action to set the sort model for nested elements.
 * @param {GridSortModel} sortModel - The sort model.
 * @returns {Object} The action to set the sort model for nested elements.
 */
const setSortModelNestedElements = createAction(
  'setSortModelNestedElements',
  function prepare(sortModel: GridSortModel) {
    return {
      payload: sortModel,
    };
  }
);

/**
 * Action to update projects in a folder.
 * @param {Project[]} projects - The projects to update in the folder.
 * @returns {Object} The action to update projects in the folder.
 */
const updateProjectsInFolder = createAction(
  'updateProjectsInFolder',
  function prepare(projects: Project[]) {
    return {
      payload: { projects },
    };
  }
);

export {
  setRootColumnVisibilityModel,
  setColumnVisibilityModel,
  resetColumnVisibilityModel,
  setPaginationPageSize,
  setPaginationPage,
  setSearchFolders,
  setRowSelectionModel,
  resetFoldersState,
  setDeleteElementsRootFoldersTable,
  setSortModelRootFolder,
  setSortModelNestedElements,
  updateProjectsInFolder,
};
