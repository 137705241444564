import { ISharedLinkSettings } from '../../../../../../../../types';

function renderListItemTitle(param: string) {
  switch (param) {
    case 'name':
      return 'Name';
    case 'position':
      return 'Position';
    case 'd1':
      return '1d';
    case 'd7':
      return '7d';
    case 'd30':
      return '30d';
    case 'best':
      return 'Best';
    case 'life':
      return 'Life';
    case 'first':
      return 'Start';
    case 'searchValue':
      return 'Volume';
    case 'cpc':
      return 'CPC';
    case 'url':
      return 'URL';
    case 'updated':
      return 'Updated';
    default:
      return '';
  }
}

function filterColumnVisibilityModel(
  columnVisibilityModel: {
    name: boolean;
    position: boolean;
    d1: boolean;
    d7: boolean;
    d30: boolean;
    best: boolean;
    life: boolean;
    first: boolean;
    searchValue: boolean;
    cpc: boolean;
    url: boolean;
    updated: boolean;
  },
  sharedLinkSettings: ISharedLinkSettings
) {
  return Object.keys(columnVisibilityModel).filter(item => {
    if (sharedLinkSettings) {
      return !!Object.keys(sharedLinkSettings.settings).find(setting => {
        return (
          item === 'name' ||
          (item === 'position' &&
            setting === 'position' &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sharedLinkSettings.settings[setting]) ||
          (item === 'd1' &&
            setting === 'oneDayChange' &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sharedLinkSettings.settings[setting]) ||
          (item === 'd7' &&
            setting === 'sevenDayChange' &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sharedLinkSettings.settings[setting]) ||
          (item === 'd30' &&
            setting === 'thirtyDayChange' &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sharedLinkSettings.settings[setting]) ||
          (item === 'best' &&
            setting === 'bestRank' &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sharedLinkSettings.settings[setting]) ||
          (item === 'life' &&
            setting === 'lifeTimeChange' &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sharedLinkSettings.settings[setting]) ||
          (item === 'first' &&
            setting === 'startingRank' &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sharedLinkSettings.settings[setting]) ||
          (item === 'searchValue' &&
            setting === 'volume' &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sharedLinkSettings.settings[setting]) ||
          (item === 'cpc' &&
            setting === 'cpc' &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sharedLinkSettings.settings[setting]) ||
          (item === 'url' &&
            setting === 'url' &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sharedLinkSettings.settings[setting]) ||
          (item === 'updated' &&
            setting === 'updated' &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sharedLinkSettings.settings[setting])
        );
      });
    }
    return false;
  });
}

export { renderListItemTitle, filterColumnVisibilityModel };
