import { io } from 'socket.io-client';
import { API_URL } from './config';

/**
 * Initializes a WebSocket connection to the server using Socket.IO.
 *
 * @constant
 * @type {SocketIOClient.Socket}
 *
 * @see {@link https://socket.io/docs/client-api/} for more details on the Socket.IO client API.
 *
 * @example
 * // You can use the socket instance to emit events or listen for messages
 * socket.emit('event_name', data);
 * socket.on('event_name', (response) => {
 *   console.log(response);
 * });
 */
const socket = io(API_URL, {
  path: '/socket.io',
  transports: ['websocket'], // Specify the transport mechanism (WebSocket in this case)
});

export { socket };
