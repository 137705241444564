import React, { useEffect, useState } from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { PricingTable, PricingTableMobile, CustomPlan } from './components';
import { useAuth } from '../../../../hooks';
import { ITariffPlan } from '../../types';
import { MAIN_TARIFF_PLANS } from './constants';
import useURLSearchParams from '../../../../hooks/useURLSearchParams';
import usePayment from '../../../payment/hooks/usePayment';
import { ENVIRONMENT } from '../../../../constants/config';
import { useAppSelector } from '../../../../store';

const UpgradePlan = () => {
  const isLargeScreen = useMediaQuery('(max-width:1200px)');
  const searchQuery = useURLSearchParams();
  const paddleProductId = searchQuery.get('paddleProductId');

  const { onGetTariffPlans } = useAuth();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const tariffPlansList = useAppSelector(
    state => state.auth.payment.tariffPlansList
  );
  const billing = useAppSelector(state => state.auth.payment.billing);
  const checkoutSettings = useAppSelector(state => state.auth.checkoutSettings);

  const { paddle } = usePayment();

  const [mainTariffPlans, setMainTariffPlans] = useState<ITariffPlan[]>([]);
  const [customTariffPlans, setCustomTariffPlans] = useState<ITariffPlan[]>([]);

  const [planType, setPlanType] = useState<string>('monthly');

  useEffect(() => {
    if (billing?.tariffPlan?.settings?.type?.name) {
      setPlanType(billing.tariffPlan.settings.type.name);
    }
  }, [billing?.tariffPlan?.settings?.type?.name]);

  const openPaddle = () => {
    if (
      paddle &&
      currentAccount?.accountId &&
      paddleProductId &&
      checkoutSettings
    ) {
      paddle.Checkout.open({
        items: [{ price_id: paddleProductId, quantity: 1 }],
        customer: { email: currentAccount.owner.ownerEmail },
        settings: {
          allowLogout: false,
          showAddDiscounts: checkoutSettings.showAddDiscounts,
          showAddTaxId: false,
          allowedPaymentMethods: checkoutSettings.paymentMethods,
        },
        customData: { id: currentAccount.accountId, environment: ENVIRONMENT },
      });
    }
  };

  useEffect(() => {
    onGetTariffPlans({ type: planType });
  }, [onGetTariffPlans, planType]);

  useEffect(() => {
    if (tariffPlansList.length) {
      const getMainTariffPlans = tariffPlansList.filter(tariffPlan =>
        MAIN_TARIFF_PLANS.find(
          mainTariffPlan => mainTariffPlan === tariffPlan.name
        )
      );
      const getCustomTariffPlans = tariffPlansList.filter(
        tariffPlan =>
          !MAIN_TARIFF_PLANS.find(
            mainTariffPlan => mainTariffPlan === tariffPlan.name
          )
      );
      setCustomTariffPlans(getCustomTariffPlans);
      setMainTariffPlans(getMainTariffPlans);
    }
  }, [tariffPlansList, planType]);

  useEffect(() => {
    if (paddleProductId) {
      openPaddle();
    }
  }, [openPaddle, paddleProductId]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Grid>
      <Typography
        sx={{ pt: 1, pb: '32px' }}
        fontWeight='600'
        color='#101828'
        fontSize='28px'
      >
        Upgrade Plan
      </Typography>
      {isLargeScreen ? (
        <PricingTableMobile
          planType={planType}
          mainTariffPlans={mainTariffPlans}
          setPlanType={setPlanType}
        />
      ) : (
        <PricingTable
          planType={planType}
          mainTariffPlans={mainTariffPlans}
          setPlanType={setPlanType}
        />
      )}
      <CustomPlan
        planType={planType}
        customTariffPlans={customTariffPlans}
        setPlanType={setPlanType}
      />
    </Grid>
  );
};

export default UpgradePlan;
