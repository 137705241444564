import React from 'react';
import {
  Description,
  Head,
  SharedLinkUnavailableContainer,
  SharedLinkUnavailableWrapper,
} from './styles';
import LinkBrokenIcon from '../../../../shared/images/LinkBrokenIcon';
import { PrimaryButtonSmall } from '../../../../shared/buttons/styles';
import { useNavigate } from 'react-router';
import ROUTES from '../../../../routes/constants';

const SharedLinkUnavailable = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(ROUTES.main);
  };

  return (
    <SharedLinkUnavailableWrapper>
      <SharedLinkUnavailableContainer container xs={8}>
        <div>
          <LinkBrokenIcon />
        </div>
        <Head>Shared Link Unavailable</Head>
        <Description>
          The link you&apos;re trying to access has been disabled by the
          administrator. If you believe this is an error, please contact the
          owner of the link or try again later.
        </Description>
        <div>
          <PrimaryButtonSmall
            variant='contained'
            type='submit'
            onClick={handleClick}
          >
            Return to Homepage
          </PrimaryButtonSmall>
        </div>
      </SharedLinkUnavailableContainer>
    </SharedLinkUnavailableWrapper>
  );
};

export default SharedLinkUnavailable;
