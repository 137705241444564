import { styled } from '@mui/system';
import { Button, ListItemButton } from '@mui/material';

import { buttonColors } from '../../themes/constants';
import { GrayButtonProps } from './types';

const PrimaryButton = styled(Button)`
  text-transform: none;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.06),
    0 5px 3px -2px rgba(0, 0, 0, 0.02);

  &.Mui-disabled {
    background: ${buttonColors.primary.solidDisabledBg};
    color: ${buttonColors.primary.solidDisabledColor};
  }
`;

const PrimaryButtonSmall = styled(PrimaryButton)`
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 8px 16px !important;
`;

const ErrorButton = styled(Button)`
  text-transform: none;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.06),
    0 5px 3px -2px rgba(0, 0, 0, 0.02);

  &.Mui-disabled {
    background: #fecdca;
    color: white;
  }
`;

const ErrorButtonSmall = styled(ErrorButton)`
  padding: 8px 16px !important;
`;

const GrayButton = styled(Button)<GrayButtonProps>(
  ({ outline }) => `
    text-transform: none;
    border: ${outline && '1px solid #D0D5DD'};
    font-weight: 600;
    border-radius: 8px;
    &.MuiButton-root {
      color: #344054;

      :hover {
        color: #344054;
        background: #f9fafb;
      }
    }
`
);

const GrayButtonSmall = styled(GrayButton)`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 7px 15px !important;
`;

const GrayButtonSmallForSelect = styled(GrayButtonSmall)(
  ({ isSelected }: { isSelected: boolean }) => ({
    borderColor: isSelected ? '#A0C5F6' : '#D0D5DD',
    background: isSelected ? '#EFF8FF' : 'initial',

    '&.MuiButtonBase-root.MuiButton-root': {
      color: isSelected ? '#0E59BA' : '#475467',
    },
  })
);

const NoBorderButton = styled(Button)`
  text-transform: none;
  box-shadow: none;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const GrayButtonCustom = styled(GrayButtonSmall)<{ $padding?: string }>`
  background: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: ${props =>
    props.$padding ? `${props.$padding} !important` : '8px 12px !important'};
`;

const PrimaryButtonCustom = styled(PrimaryButtonSmall)<{ $padding?: string }>`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: ${props =>
    props.$padding ? `${props.$padding} !important` : '8px 12px !important'};
`;

const StyledListItemButton = styled(ListItemButton)`
  padding-left: 8px;
  padding-right: 8px;
`;

const GrayButton36Small = styled(GrayButtonSmall)`
  padding: 5px 15px !important;
`;

const PrimaryButton36Small = styled(PrimaryButtonSmall)`
  padding: 6px 16px !important;
`;

const GoogleAuthButton = styled(GrayButton)`
  color: #344054;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  padding: 9px 18px !important;

  .MuiButton-startIcon {
    margin-right: 12px;
  }

  &.Mui-disabled {
    color: #d0d5dd;
  }
`;

export {
  PrimaryButton,
  GrayButton,
  NoBorderButton,
  ErrorButton,
  PrimaryButtonSmall,
  GrayButtonSmall,
  GrayButtonSmallForSelect,
  PrimaryButtonCustom,
  GrayButtonCustom,
  StyledListItemButton,
  GrayButton36Small,
  PrimaryButton36Small,
  ErrorButtonSmall,
  GoogleAuthButton,
};
