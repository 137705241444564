import React, { useState } from 'react';
import { EllipsisActionsProps } from '../types';
import { useSharedLinks } from '../../../../../../../../../../../hooks';

import { ListItemTypography, StyledListItem } from '../styles';
import GraphIcon from '../../../../../../../../../../../shared/images/GraphIcon';
import { StyledListItemButton } from '../../../../../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/styles';
import { getSharedLinkToken } from '../../../../../../../../../../../utils/localStorage';
import KeywordsAnalyticsSharedModal from '../../../../../../../../modals/KeywordsAnalyticsSharedModal/KeywordsAnalyticsSharedModal';
import { useAppSelector } from '../../../../../../../../../../../store';

const ShowOnGraphAction: React.FC<EllipsisActionsProps> = ({
  keywordName,
  keywordId,
  popupClose,
}) => {
  const { onCloseKeywordPositionHistory, onGetKeywordPositionHistory } =
    useSharedLinks();

  const activePeriodKeywordTrends = useAppSelector(
    state =>
      state.sharedLinks.projectGraphs.keywordRankings.activePeriodKeywordTrends
  );
  const projectShared = useAppSelector(
    state => state.sharedLinks.projectShared
  );

  const [openModal, setOpenModal] = useState(false);
  const [activeKeywordId, setActiveKeywordId] = useState<number | null>(null);
  const [activeKeywordName, setActiveKeywordName] = useState<string | null>(
    null
  );

  const onClose = () => {
    if (activeKeywordId) {
      onCloseKeywordPositionHistory({ id: activeKeywordId });
      setOpenModal(false);
      setActiveKeywordId(null);
      setActiveKeywordName(null);
    }
    popupClose();
  };

  const handleClick = () => {
    const sharedLinkToken = getSharedLinkToken();
    if (sharedLinkToken && keywordId && keywordName && projectShared) {
      setOpenModal(true);
      setActiveKeywordId(keywordId);
      setActiveKeywordName(keywordName);
      onGetKeywordPositionHistory({
        keywordId: keywordId,
        link: sharedLinkToken,
        period: activePeriodKeywordTrends.value,
        competitorIds: projectShared.competitors.map(item => item.id),
      });
    }
  };

  return (
    <>
      <StyledListItem onClick={handleClick}>
        <StyledListItemButton>
          <GraphIcon />
          <ListItemTypography marginLeft='8px' sx={{ color: '#101828' }}>
            Show on Graph
          </ListItemTypography>
        </StyledListItemButton>
      </StyledListItem>
      {activeKeywordId && activeKeywordName && (
        <KeywordsAnalyticsSharedModal
          id={activeKeywordId}
          name={activeKeywordName}
          onClose={onClose}
          open={openModal}
        />
      )}
    </>
  );
};

export default React.memo(ShowOnGraphAction);
