import React from 'react';

const DesktopDeviceIcon = () => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.8335 14H11.1668M8.50016 11.3333V14M5.0335 11.3333H11.9668C13.0869 11.3333 13.647 11.3333 14.0748 11.1153C14.4511 10.9236 14.7571 10.6176 14.9488 10.2413C15.1668 9.81349 15.1668 9.25344 15.1668 8.13333V5.2C15.1668 4.0799 15.1668 3.51984 14.9488 3.09202C14.7571 2.71569 14.4511 2.40973 14.0748 2.21799C13.647 2 13.0869 2 11.9668 2H5.0335C3.91339 2 3.35334 2 2.92552 2.21799C2.54919 2.40973 2.24323 2.71569 2.05148 3.09202C1.8335 3.51984 1.8335 4.0799 1.8335 5.2V8.13333C1.8335 9.25344 1.8335 9.81349 2.05148 10.2413C2.24323 10.6176 2.54919 10.9236 2.92552 11.1153C3.35334 11.3333 3.91339 11.3333 5.0335 11.3333Z'
        stroke='#101828'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default DesktopDeviceIcon;
