import React, { useMemo } from 'react';
import { Grid, Paper, useMediaQuery } from '@mui/material';
import PlusIcon from '../../../../images/PlusIcon';
import { StyledNoBorderButton } from './styles';
import { useSelectProjectProvider } from '../context/SelectProjectProvider';
import { useAppSelector } from '../../../../../store';
import useAccountLimits from '../../../../../features/auth/hooks/useAccountLimits';
import { useProjects } from '../../../../../hooks';

const AutocompletePaper: React.FC = (
  paperProps: React.HTMLAttributes<HTMLElement>
) => {
  const extraSmallScreen = useMediaQuery('(min-height:550px)');

  const folderTree = useAppSelector(state => state.auth.myAccount.folderTree);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const isAuth = useAppSelector(state => state.auth.isAuth);

  const isAddon = useMemo(
    () => !isAuth || (isAuth && currentAccount?.role?.name === 'Addon'),
    [currentAccount?.role?.name, isAuth]
  );
  const isViewOnly = useMemo(
    () => !isAuth || (isAuth && currentAccount?.role?.name === 'ViewOnly'),
    [currentAccount?.role?.name, isAuth]
  );

  const { onCheckSubscriptionExpiration } = useAccountLimits();
  const { handleOpenNewProjectModal } = useSelectProjectProvider();
  const { onSetNewProjectModal } = useProjects();

  const onOpenNewProjectModal = () => {
    const isExpiration = onCheckSubscriptionExpiration();
    if (!isExpiration) {
      onSetNewProjectModal(true);
      handleOpenNewProjectModal(true);
    }
  };

  const { children, ...restPaperProps } = paperProps;
  return (
    <Paper
      {...restPaperProps}
      elevation={0}
      data-prevent-dismiss
      sx={{
        boxShadow:
          '0px 12px 16px -4px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.06) !important',
        borderRadius: '8px  !important',
        background: '#ffffff  !important',
        border: '1px solid #ededed  !important',
        '& .MuiAutocomplete-listbox': {
          maxHeight: `${!extraSmallScreen ? '40vh' : '400px'}`,
        },
      }}
    >
      {children}
      {!isAddon && !isViewOnly && (
        <Grid container item sx={{ borderTop: '1px solid #ededed' }}>
          <StyledNoBorderButton
            fullWidth
            disabled={!folderTree}
            size='small'
            startIcon={<PlusIcon stroke={'#101828'} />}
            onMouseDown={onOpenNewProjectModal}
          >
            Add New Project
          </StyledNoBorderButton>
        </Grid>
      )}
    </Paper>
  );
};

export default AutocompletePaper;
