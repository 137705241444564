import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import ROUTES from '../routes/constants';
import { AxiosError } from 'axios';

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function arraysAreEqual(
  arr1: GridRowSelectionModel,
  arr2: number[] | string[]
) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
}

function getPage(
  paginationModelPage: number,
  metaCurrentPage?: number,
  metaTotalPages?: number
) {
  if (metaCurrentPage && metaTotalPages) {
    if (metaCurrentPage > metaTotalPages) {
      return metaTotalPages - 1;
    }
    return metaCurrentPage - 1;
  }
  return paginationModelPage;
}

function getPositionTableHeader(
  openTariffPlanBanner: boolean,
  isSharedTable?: boolean
) {
  if (isSharedTable) {
    return '0px';
  } else {
    if (openTariffPlanBanner) {
      return '125px';
    } else return '65px';
  }
}

const roundNegativeToZero = (num: number) => (num < 0 ? 0 : num);

function haveSameElements(arr1: any[], arr2: any[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1
    .slice()
    .sort()
    .map(item => JSON.stringify(item));
  const sortedArr2 = arr2
    .slice()
    .sort()
    .map(item => JSON.stringify(item));

  return JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2);
}

function convertErrorsForEmails(objectErrors: any) {
  const emails: never[] = [];
  for (const key in objectErrors) {
    if (key.startsWith('emails.') && key.endsWith('.email')) {
      const index = key.split('.')[1];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      emails[index] = { email: objectErrors[key] };
    }
  }
  return emails;
}

function removeEmailKeys(objectErrors: any) {
  Object.keys(objectErrors).forEach(key => {
    if (key.startsWith('emails.') && key.endsWith('.email')) {
      delete objectErrors[key];
    }
  });
  return objectErrors;
}

function convertErrorsForCompetitorsUrl(objectErrors: any) {
  const competitors: never[] = [];
  for (const key in objectErrors) {
    if (key.startsWith('competitors.') && key.endsWith('.competitorUrl')) {
      const index = key.split('.')[1];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      competitors[index] = {
        competitorUrl: objectErrors[key],
      };
    }
  }
  return competitors;
}

function removeCompetitorUrlKeys(objectErrors: any) {
  Object.keys(objectErrors).forEach(key => {
    if (key.startsWith('competitors.') && key.endsWith('.competitorUrl')) {
      delete objectErrors[key];
    }
  });
  return objectErrors;
}

function checkAndRedirectToMaintenancePage(error: AxiosError<any, any>) {
  if (error.code === 'ERR_NETWORK' || error?.response?.status === 502) {
    window.history.pushState(
      {},
      '',
      `${window.location.origin}${ROUTES.errNetwork}`
    );
    window.dispatchEvent(new PopStateEvent('popstate'));
  }
}

export {
  capitalizeFirstLetter,
  arraysAreEqual,
  getPage,
  getPositionTableHeader,
  roundNegativeToZero,
  haveSameElements,
  convertErrorsForEmails,
  removeEmailKeys,
  convertErrorsForCompetitorsUrl,
  removeCompetitorUrlKeys,
  checkAndRedirectToMaintenancePage,
};
