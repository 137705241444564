import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const ContentContainer = styled(Grid)`
  margin-top: 16px;
  border-radius: 8px;
  border: 1px solid #dedede;
  background: #fff;
  box-shadow: 3px 2px -1px rgba(0, 0, 0, 0.02),
    0px 1px 1px -1px rgba(0, 0, 0, 0.04);
`;

export { ContentContainer };
