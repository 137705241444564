import { Grid } from '@mui/material';
import { Pagination, Table } from './components';
import React from 'react';

interface TriggersTableProps {
  selectBy: 'by Project' | 'by Keywords';
}

const TriggersTable: React.FC<TriggersTableProps> = ({ selectBy }) => {
  return (
    <Grid xs={12} paddingTop={'24px'}>
      <Table selectBy={selectBy} />
      <Pagination selectBy={selectBy} />
    </Grid>
  );
};

export default TriggersTable;
