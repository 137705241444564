import React, { useState } from 'react';
import {
  ListItemTypography,
  StyledListItem,
} from '../../../../../../../projects/components/ProjectExpanded/components/KeywordRankings/components/KeywordRankingsTable/components/EllipsisMenu/styles';
import { ListActionsProps } from './types';
import EditPencilIcon from '../../../../../../../../shared/images/EditPencilIcon';
import EditNoteModal from '../../../../../../modals/EditNoteModal/EditNoteModal';
import { StyledListItemButton } from '../../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/styles';
import { useNotes, useProjects } from '../../../../../../../../hooks';
import { useAppSelector } from '../../../../../../../../store';

const EditProjectNoteAction: React.FC<ListActionsProps> = ({
  projectNoteId,
  popupClose,
}) => {
  const { onSetRowSelectionModelProjectNotes } = useNotes();
  const { onGetProjectPerformance } = useProjects();

  const [isOpenEditNoteModal, setOpenEditNoteModal] = useState(false);

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );
  const activePeriodProjectPerformance = useAppSelector(
    state => state.projects.projectExpandedGraphs.activePeriodProjectPerformance
  );
  const deviceType = useAppSelector(
    state => state.projects.projectExpandedGraphs.deviceType
  );

  const onOpen = () => {
    setOpenEditNoteModal(true);
  };

  const onClose = () => {
    onSetRowSelectionModelProjectNotes([]);
    setOpenEditNoteModal(false);
    popupClose();
  };

  const onDone = () => {
    if (projectExpanded?.id && currentAccount?.accountId && deviceType) {
      onGetProjectPerformance({
        id: currentAccount.accountId,
        projectId: projectExpanded.id,
        period: activePeriodProjectPerformance.value,
        competitorIds: projectExpanded.competitors.map(item =>
          item.id.toString()
        ),
        deviceType: deviceType,
      });
    }
  };

  return (
    <StyledListItem onClick={onOpen}>
      <StyledListItemButton>
        <EditPencilIcon />
        <ListItemTypography marginLeft='8px'>Edit Note</ListItemTypography>
      </StyledListItemButton>
      <EditNoteModal
        noteId={projectNoteId}
        onClose={onClose}
        onDone={onDone}
        open={isOpenEditNoteModal}
      />
    </StyledListItem>
  );
};

export default EditProjectNoteAction;
