import {
  Box,
  FormHelperText,
  Grid,
  OutlinedInput,
  Skeleton,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import { CustomFormHelperText } from '../../../../../../shared/components';
import { useAuth } from '../../../../../../hooks';
import { CompanyLogoUpload } from '../../components';
import { CustomCheckbox } from '../../../../../../shared/checkbox/styles';
import CustomCheckboxImage from '../../../../../../shared/images/CustomCheckbox';
import CustomToast from '../../../../../../shared/CustomToast/CustomToast';
import { closeSnackbarAction } from '../../../../../../utils/snackbar';

import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../../shared/buttons/styles';
import {
  BrandingFormControlLabel,
  CompanyProfileContainer,
  FormItem,
  LinkStartAdornment,
  ShowBrandingItem,
} from './styles';
import CheckedIcon from '../../../../../../shared/images/CheckedIcon';
import { useAppSelector } from '../../../../../../store';

const Branding = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { onChangeAccountBranding } = useAuth();

  const isAuth = useAppSelector(state => state.auth.isAuth);
  const isLoading = useAppSelector(state => state.auth.isLoading);
  const myAccount = useAppSelector(state => state.auth.myAccount);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const [companyLogoFile, setCompanyLogoFile] = useState<File | null>(null);

  const formik = useFormik({
    initialValues: {
      companyName: myAccount?.companyName || '',
      companyUrl: myAccount?.companyUrl || '',
      tagline: myAccount?.tagline || '',
      companyLogoUrl: myAccount?.companyLogoUrl || '',
      twitterLink: myAccount?.socialProfiles?.twitterLink || '',
      facebookLink: myAccount?.socialProfiles?.facebookLink || '',
      linkedinLink: myAccount?.socialProfiles?.linkedinLink || '',
      emailReports: myAccount?.options.emailReports,
      sharedLinks: myAccount?.options.sharedLinks,
      validatedBySerpnest: myAccount?.options.validatedBySerpnest,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      tagline: Yup.string().max(156, 'Tagline must be at most 156 characters'),
    }),
    onSubmit: (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
      const formData = new FormData();
      formData.append('companyName', values.companyName);
      formData.append('companyUrl', values.companyUrl);
      formData.append('tagline', values.tagline);
      formData.append('twitterLink', values.twitterLink);
      formData.append('facebookLink', values.facebookLink);
      formData.append('linkedinLink', values.linkedinLink);
      formData.append('emailReports', values.emailReports ? 'true' : 'false');
      formData.append('sharedLinks', values.sharedLinks ? 'true' : 'false');
      formData.append(
        'validatedBySerpnest',
        values.validatedBySerpnest ? 'true' : 'false'
      );
      companyLogoFile && formData.append('companyLogo', companyLogoFile);

      const params = {
        formData,
        id: currentAccount.accountId,
        successFn: () => {
          resetForm({ values: { ...values } });
          enqueueSnackbar(
            <CustomToast
              message='Successfully updated profile'
              submessage='All changes have been saved.'
            />,
            { action: closeSnackbarAction }
          );
        },
        errorFn: (error: any) => {
          setStatus({ success: false });
          setSubmitting(false);
          setErrors(error);
        },
      };
      onChangeAccountBranding({ ...params });
    },
  });

  const handleCancelCompanyProfile = () => {
    formik.resetForm();
  };

  const taglineCharactersLeft = 156 - formik.values.tagline.length;

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <CompanyProfileContainer container item sx={{ pt: 4, pb: 4 }}>
            <Grid item sm={4} xs={12}>
              <Box>
                <Typography
                  variant='h6'
                  fontWeight='600'
                  color='#101828'
                  fontSize='18px'
                  lineHeight='28px'
                >
                  Company profile
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant='body2'
                  color='#475467'
                  fontSize='14px'
                  lineHeight='20px'
                >
                  Update your company details here.
                </Typography>
              </Box>
            </Grid>
            <Grid
              container
              item
              sm={5}
              xs={12}
              spacing={2}
              sx={{ justifyContent: 'flex-end' }}
            >
              <Grid item>
                {!isAuth || isLoading ? (
                  <Skeleton width='88px' height='43px' />
                ) : (
                  <GrayButtonSmall
                    size='medium'
                    outline
                    onClick={handleCancelCompanyProfile}
                  >
                    Cancel
                  </GrayButtonSmall>
                )}
              </Grid>
              <Grid item>
                {!isAuth || isLoading ? (
                  <Skeleton width='130px' height='43px' />
                ) : (
                  <PrimaryButtonSmall
                    variant='contained'
                    size='medium'
                    type='submit'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    Save All Changes
                  </PrimaryButtonSmall>
                )}
              </Grid>
            </Grid>
          </CompanyProfileContainer>
          <FormItem container item sx={{ pt: 4, pb: 4 }}>
            <Grid item lg={2} sm={4} xs={12}>
              <Typography variant='body2' fontWeight='500' color='#344054'>
                Company Name
              </Typography>
            </Grid>
            <Grid container item lg={7.5} sm={8} xs={12}>
              <>
                <OutlinedInput
                  fullWidth
                  type={'companyName'}
                  size={'small'}
                  id='companyName'
                  placeholder='My Company LLC'
                  name='companyName'
                  value={formik.values.companyName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!(formik.touched.companyName && formik.errors.companyName)
                  }
                />
                {formik.touched.companyName && formik.errors.companyName && (
                  <FormHelperText error id='companyName'>
                    <CustomFormHelperText error={formik.errors.companyName} />
                  </FormHelperText>
                )}
              </>
            </Grid>
          </FormItem>
          <FormItem container item sx={{ pt: 4, pb: 4 }}>
            <Grid item lg={2} sm={4} xs={12}>
              <Typography variant='body2' fontWeight='500' color='#344054'>
                Company URL
              </Typography>
              <Typography variant='body2' fontWeight='400' color='#475467'>
                URL of your website
              </Typography>
            </Grid>
            <Grid container item lg={7.5} sm={8} xs={12}>
              <>
                <OutlinedInput
                  fullWidth
                  type={'companyUrl'}
                  size={'small'}
                  id='companyUrl'
                  placeholder='https://www.mywebsite.com'
                  name='companyUrl'
                  value={formik.values.companyUrl}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!(formik.touched.companyUrl && formik.errors.companyUrl)
                  }
                />
                {formik.touched.companyUrl && formik.errors.companyUrl && (
                  <FormHelperText error id='companyUrl'>
                    <CustomFormHelperText error={formik.errors.companyUrl} />
                  </FormHelperText>
                )}
              </>
            </Grid>
          </FormItem>
          <FormItem container item sx={{ pt: 4, pb: 4 }}>
            <Grid item lg={2} sm={4} xs={12}>
              <Typography variant='body2' fontWeight='500' color='#344054'>
                Tagline
              </Typography>
              <Typography variant='body2' fontWeight='400' color='#475467'>
                A quick snapshot of your company.
              </Typography>
            </Grid>
            <Grid container item lg={7.5} sm={8} xs={12}>
              <>
                <OutlinedInput
                  multiline
                  rows={4}
                  fullWidth
                  type={'tagline'}
                  size={'small'}
                  id='tagline'
                  placeholder='My company description.'
                  name='tagline'
                  value={formik.values.tagline}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={!!formik.errors.tagline}
                />
                {taglineCharactersLeft > 0 && (
                  <Typography
                    paddingTop='5px'
                    variant='body2'
                    fontSize='14px'
                    margin='0'
                    color='#475467'
                  >
                    {taglineCharactersLeft} characters left
                  </Typography>
                )}
                {formik.errors.tagline && (
                  <FormHelperText error id='companyUrl'>
                    <CustomFormHelperText error={formik.errors.tagline} />
                  </FormHelperText>
                )}
              </>
            </Grid>
          </FormItem>
          <FormItem container item sx={{ pt: 4, pb: 4 }}>
            <Grid item lg={2} sm={4} xs={12}>
              <Typography variant='body2' fontWeight='500' color='#344054'>
                Company logo
              </Typography>
              <Typography variant='body2' fontWeight='400' color='#475467'>
                Update your company logo and then choose where you want it to
                display.
              </Typography>
            </Grid>
            <Grid container item lg={7.5} sm={8} xs={12}>
              <CompanyLogoUpload
                errors={formik.errors}
                handleBlur={formik.handleBlur}
                setFieldValue={formik.setFieldValue}
                value={formik.values.companyLogoUrl}
                companyLogoFile={companyLogoFile}
                setCompanyLogoFile={setCompanyLogoFile}
                setErrors={formik.setErrors}
              />
            </Grid>
          </FormItem>

          <FormItem container item sx={{ pt: 4, pb: 4 }}>
            <Grid item lg={2} sm={4} xs={12}>
              <Typography variant='body2' fontWeight='500' color='#344054'>
                Social profiles
              </Typography>
            </Grid>
            <Grid container item lg={7.5} sm={8} xs={12} spacing={2}>
              <Grid container item>
                <OutlinedInput
                  fullWidth
                  type={'twitterLink'}
                  size={'small'}
                  id='twitterLink'
                  placeholder='twitterhandle'
                  name='twitterLink'
                  value={formik.values.twitterLink}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={!!formik.errors.twitterLink}
                  startAdornment={
                    <LinkStartAdornment>
                      twitter.com/ <div />
                    </LinkStartAdornment>
                  }
                />
                {formik.errors.twitterLink && (
                  <FormHelperText error id='twitterLink'>
                    <CustomFormHelperText error={formik.errors.twitterLink} />
                  </FormHelperText>
                )}
              </Grid>
              <Grid container item>
                <OutlinedInput
                  fullWidth
                  type={'facebookLink'}
                  size={'small'}
                  id='facebookLink'
                  placeholder='facebookhandle'
                  name='facebookLink'
                  value={formik.values.facebookLink}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={!!formik.errors.facebookLink}
                  startAdornment={
                    <LinkStartAdornment>
                      facebook.com/ <div />
                    </LinkStartAdornment>
                  }
                />
                {formik.errors.facebookLink && (
                  <FormHelperText error id='facebookLink'>
                    <CustomFormHelperText error={formik.errors.facebookLink} />
                  </FormHelperText>
                )}
              </Grid>
              <Grid container item>
                <OutlinedInput
                  fullWidth
                  type={'linkedinLink'}
                  size={'small'}
                  id='linkedinLink'
                  placeholder='linkedinhandle'
                  name='linkedinLink'
                  value={formik.values.linkedinLink}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={!!formik.errors.linkedinLink}
                  startAdornment={
                    <LinkStartAdornment>
                      linkedin.com/company/ <div />
                    </LinkStartAdornment>
                  }
                />
                {formik.errors.linkedinLink && (
                  <FormHelperText error id='linkedinLink'>
                    <CustomFormHelperText error={formik.errors.linkedinLink} />
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </FormItem>

          <FormItem container item sx={{ pt: 4, pb: 4 }}>
            <Grid item lg={2} sm={4} xs={12}>
              <Typography variant='body2' fontWeight='500' color='#344054'>
                Show Branding
              </Typography>
              <Typography variant='body2' fontWeight='400' color='#475467'>
                Show or hide branding.
              </Typography>
            </Grid>
            <Grid container item lg={7.5} sm={8} xs={12} spacing={2}>
              <ShowBrandingItem container item xs={12} wrap='nowrap'>
                <Grid item>
                  <BrandingFormControlLabel
                    name='emailReports'
                    control={
                      <CustomCheckbox
                        checked={formik.values.emailReports}
                        onChange={formik.handleChange}
                        icon={<CustomCheckboxImage />}
                        checkedIcon={<CheckedIcon />}
                      />
                    }
                    label
                  />
                </Grid>
                <Grid item>
                  <Grid container item xs={12}>
                    <Grid item>
                      <Typography
                        variant='body2'
                        fontWeight='500'
                        color='#344054'
                      >
                        Email Reports
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant='body2'
                      fontWeight='400'
                      color='#475467'
                    >
                      Include my company information in PDF email reports.
                    </Typography>
                  </Grid>
                </Grid>
              </ShowBrandingItem>
              <ShowBrandingItem container item xs={12} wrap='nowrap'>
                <Grid item>
                  <BrandingFormControlLabel
                    name='sharedLinks'
                    control={
                      <CustomCheckbox
                        checked={formik.values.sharedLinks}
                        onChange={formik.handleChange}
                        icon={<CustomCheckboxImage />}
                        checkedIcon={<CheckedIcon />}
                      />
                    }
                    label
                  />
                </Grid>
                <Grid item>
                  <Grid container item xs={12} sx={{ alignItems: 'center' }}>
                    <Grid item>
                      <Typography
                        variant='body2'
                        fontWeight='500'
                        color='#344054'
                      >
                        Shared Links
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant='body2'
                      fontWeight='400'
                      color='#475467'
                    >
                      Include my company information in Shared Link pages.
                    </Typography>
                  </Grid>
                </Grid>
              </ShowBrandingItem>
              <ShowBrandingItem container item xs={12} wrap='nowrap'>
                <Grid item>
                  <BrandingFormControlLabel
                    name='validatedBySerpnest'
                    control={
                      <CustomCheckbox
                        checked={formik.values.validatedBySerpnest}
                        onChange={formik.handleChange}
                        icon={<CustomCheckboxImage />}
                        checkedIcon={<CheckedIcon />}
                      />
                    }
                    label
                  />
                </Grid>
                <Grid item>
                  <Grid container item xs={12} sx={{ alignItems: 'center' }}>
                    <Grid item>
                      <Typography
                        variant='body2'
                        fontWeight='500'
                        color='#344054'
                      >
                        Hide “Validated by Serpnest”
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant='body2'
                      fontWeight='400'
                      color='#475467'
                    >
                      This applies to PDF reports and shared links.
                    </Typography>
                  </Grid>
                </Grid>
              </ShowBrandingItem>
            </Grid>
          </FormItem>

          <Grid
            container
            item
            sx={{
              justifyContent: 'flex-end',
              pt: 4,
            }}
          >
            <Grid
              container
              item
              sm={12}
              spacing={2}
              sx={{ justifyContent: 'flex-end' }}
            >
              <Grid item>
                {!isAuth || isLoading ? (
                  <Skeleton height='40px' width='88px' />
                ) : (
                  <GrayButtonSmall
                    size='medium'
                    outline
                    onClick={handleCancelCompanyProfile}
                  >
                    Cancel
                  </GrayButtonSmall>
                )}
              </Grid>
              <Grid item>
                {!isAuth || isLoading ? (
                  <Skeleton height='40px' width='136px' />
                ) : (
                  <PrimaryButtonSmall
                    variant='contained'
                    size='medium'
                    type='submit'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    Save All Changes
                  </PrimaryButtonSmall>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Branding;
