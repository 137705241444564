import {
  Autocomplete,
  Fade,
  FormHelperText,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { enqueueSnackbar } from 'notistack';

import { InviteUserModalProps } from '../types';
import { CustomFormHelperText } from '../../../../../../../shared/components';
import * as Tooltips from './Tooltips';
import HelperIcon from '../../../../../../../shared/images/HelperIcon';
import { useAuth, useFolders, useProjects } from '../../../../../../../hooks';
import { Role } from '../../../../../types';
import CheckboxWrapper from '../components/CheckboxWrapper';
import CustomToast from '../../../../../../../shared/CustomToast/CustomToast';
import { closeSnackbarAction } from '../../../../../../../utils/snackbar';

import { CustomTooltip } from '../../../../../../../shared/tooltip/styles';
import {
  ModalContainer,
  ModalWrapper,
  ButtonsWrapper,
} from './customTableStyles';
import { CloseDeletePopoverOne } from '../../Account/styles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../../../shared/buttons/styles';
import SelectIcon from '../../../../../../../shared/components/SelectIcon';
import { StyledAutocompleteLi, StyledChip } from './styles';
import CloseIcon from '@mui/icons-material/Close';
import { filter, filterFolder } from '../utils';
import FolderIcon from '../../../../../../../shared/images/FolderIcon';
import AutocompletePaper from '../../../../../../../shared/AutocompletePaper';
import { useAppSelector } from '../../../../../../../store';
import {
  ModalAutocompleteStylesProps,
  ModalMultipleAutocompleteStylesProps,
  ModalOutlinedInput,
} from '../../../../../../../shared/modal/styled';
import { useNavigate } from 'react-router';
import useAccountLimits from '../../../../../hooks/useAccountLimits';
import ROUTES from '../../../../../../../routes/constants';
import UsersLimitExceededModal from '../../../../../../../shared/components/usersLimitExceededModal/usersLimitExceededModal';
import GlobeIcon from '../../../../../../../shared/images/GlobeIcon';

const InviteUserModal: React.FC<InviteUserModalProps> = ({
  open,
  handleClose,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const navigate = useNavigate();

  const { onSendInvitation, onGetUsers, onGetMyAccount } = useAuth();

  const sortModelTeam = useAppSelector(
    state => state.auth.sorting.sortModelTeam
  );
  const searchKeywordUsersTeam = useAppSelector(
    state => state.auth.search.searchKeywordUsersTeam
  );
  const meta = useAppSelector(state => state.auth.users.meta);
  const roles = useAppSelector(state => state.auth.roles);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const { onGetProjectsListForInvite } = useProjects();
  const { onGetAvailableFolders, listAvailableFolders } = useFolders();
  const { onCheckUsersLimit, onCloseUsersLimitExceededModal } =
    useAccountLimits();

  const projectsListForInvite = useAppSelector(
    state => state.projects.projectsListForInvite
  );

  const userLimitExceededOpen = useAppSelector(
    state => state.auth.accountLimitErrors.usersLimitExceeded
  );

  const [showTooltip, setShowTooltip] = useState(false);
  const [openTooltip, setOpenTooltip] = useState<number | undefined>();

  const onOpenTooltip = (tooltipIndex?: number) => {
    setShowTooltip(true);
    setOpenTooltip(tooltipIndex);
  };

  const onClickTooltip = (tooltipIndex?: number) => {
    if (showTooltip) {
      setShowTooltip(false);
      setOpenTooltip(undefined);
    } else {
      setShowTooltip(true);
      setOpenTooltip(tooltipIndex);
    }
  };

  const onClose = () => {
    handleClose();
    formik.resetForm();
  };

  const handleCloseUsersLimitExceededModal = () => {
    onCloseUsersLimitExceededModal();
    formik.resetForm();
  };

  const handleUpgradeAccount = () => {
    onCloseUsersLimitExceededModal();
    navigate(ROUTES.upgradePlan);
  };

  useEffect(() => {
    if (open && currentAccount?.accountId) {
      onGetProjectsListForInvite({
        accountId: currentAccount.accountId,
        search: '',
      });
      onGetAvailableFolders({ accountId: currentAccount.accountId });
    }
  }, [
    currentAccount?.accountId,
    onGetAvailableFolders,
    onGetProjectsListForInvite,
    open,
  ]);

  const formik = useFormik({
    initialValues: {
      email: '',
      role: {} as Role,
      projects: [] as { id: number; projectName: string; favicon?: string }[],
      folders: [] as { id: number; name: string }[],
      message: '',
    },
    validationSchema: Yup.object().shape(
      {
        email: Yup.string()
          .email('Invalid email address.')
          .max(255)
          .required('Email is required.'),
        role: Yup.object().shape({
          name: Yup.string().required('Role is required.'),
        }),
        folders: Yup.array().when('projects', ([projects], schema) => {
          return !projects || projects?.length === 0
            ? schema.min(1, 'At least 1 folder or 1 project is required.')
            : schema
                .min(0)
                .required('At least 1 folder or 1 project is required.');
        }),
        projects: Yup.array().when('folders', ([folders], schema) => {
          return !folders || folders?.length === 0
            ? schema.min(1, 'At least 1 folder or 1 project is required.')
            : schema
                .min(0)
                .required('At least 1 folder or 1 project is required.');
        }),
      },
      [['folders', 'projects']]
    ),
    onSubmit: (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
      const error = onCheckUsersLimit();
      if (!error) {
        const requestData = {
          email: values.email,
          roleName: values.role.name,
          projectIds: values.projects.length
            ? values.projects.map(project => project.id)
            : undefined,
          folderIds: values.folders.length
            ? values.folders.map(folder => folder.id)
            : undefined,
        };
        onSendInvitation({
          data: requestData,
          accountId: currentAccount.accountId,
          successFn: () => {
            resetForm();
            handleClose();
            onGetUsers({
              accountId: currentAccount.accountId,
              page: meta.currentPage,
              limit: meta.itemsPerPage,
              search: searchKeywordUsersTeam,
              sortBy: sortModelTeam[0]?.field,
              sortOrder: sortModelTeam[0]?.sort,
            });
            onGetMyAccount(currentAccount.accountId);
            enqueueSnackbar(
              <CustomToast
                message='Success'
                submessage='Invitation sent successfully.'
              />,
              { action: closeSnackbarAction }
            );
          },
          errorFn: (error: any) => {
            setStatus({ success: false });
            setSubmitting(false);
            setErrors(error);
          },
        });
      }
    },
  });

  return (
    <>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={onClose}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <form onSubmit={formik.handleSubmit}>
            <ModalWrapper>
              <ModalContainer container xs={12}>
                <Grid item xs={12} sx={{ position: 'relative' }}>
                  <CloseDeletePopoverOne onClick={onClose}>
                    <CloseIcon fontSize='small' />
                  </CloseDeletePopoverOne>
                  <Typography
                    fontSize='18px'
                    fontWeight='600'
                    lineHeight='28px'
                    color='#101828'
                  >
                    Invite user
                  </Typography>
                  <Typography fontSize='14px' lineHeight='20px' color='#475467'>
                    Invite users to collaborate on this account.
                  </Typography>
                </Grid>
                <Grid item xs={12} paddingTop='8px'>
                  <Grid
                    container
                    fontWeight='500'
                    alignItems='center'
                    fontSize='14px'
                  >
                    <Grid item>Email address</Grid>
                    <Grid item>
                      <CustomTooltip
                        title={<Tooltips.EmailForNewUser />}
                        placement='top-start'
                        arrow
                        open={showTooltip && openTooltip === 0}
                        onOpen={() => onOpenTooltip(0)}
                        onClose={() => onClickTooltip(0)}
                        onClick={() => onClickTooltip(0)}
                      >
                        <IconButton onClick={() => onClickTooltip(0)}>
                          <HelperIcon />
                        </IconButton>
                      </CustomTooltip>
                    </Grid>
                  </Grid>

                  <ModalOutlinedInput
                    size='small'
                    fullWidth
                    type='text'
                    id='email'
                    name='email'
                    placeholder='Enter user’s email'
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={!!(formik.touched.email && formik.errors.email)}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <FormHelperText error id='email'>
                      <CustomFormHelperText error={formik.errors.email} />
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} paddingTop='8px'>
                  <Grid
                    container
                    fontWeight='500'
                    alignItems='center'
                    fontSize='14px'
                  >
                    <Grid item>Role</Grid>
                    <Grid item>
                      <CustomTooltip
                        title={<Tooltips.RoleForNewUser />}
                        placement='top-start'
                        arrow
                        open={showTooltip && openTooltip === 1}
                        onOpen={() => onOpenTooltip(1)}
                        onClose={() => onClickTooltip(1)}
                        onClick={() => onClickTooltip(1)}
                      >
                        <IconButton onClick={() => onClickTooltip(1)}>
                          <HelperIcon />
                        </IconButton>
                      </CustomTooltip>
                    </Grid>
                  </Grid>
                  <Autocomplete
                    sx={ModalAutocompleteStylesProps}
                    fullWidth
                    size={'small'}
                    id='role'
                    options={roles}
                    getOptionLabel={option => option.name}
                    value={formik.values.role.name ? formik.values.role : null}
                    onChange={(_, value) =>
                      formik.setFieldValue('role', value || {})
                    }
                    onBlur={formik.handleBlur}
                    popupIcon={<SelectIcon />}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder='Select role'
                        name='role'
                        error={!!(formik.touched.role && formik.errors.role)}
                      />
                    )}
                  />
                  {formik.touched.role && formik.errors.role?.name && (
                    <FormHelperText error id='role'>
                      <CustomFormHelperText error={formik.errors.role.name} />
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} paddingTop='8px'>
                  <Grid
                    container
                    fontWeight='500'
                    alignItems='center'
                    fontSize='14px'
                  >
                    <Grid item>Access</Grid>
                    <Grid item>
                      <CustomTooltip
                        title={<Tooltips.AccessForNewUser />}
                        placement='top-start'
                        arrow
                        open={showTooltip && openTooltip === 2}
                        onOpen={() => onOpenTooltip(2)}
                        onClose={() => onClickTooltip(2)}
                        onClick={() => onClickTooltip(2)}
                      >
                        <IconButton onClick={() => onClickTooltip(2)}>
                          <HelperIcon />
                        </IconButton>
                      </CustomTooltip>
                    </Grid>
                  </Grid>
                  <Autocomplete
                    sx={
                      formik.values.folders.length
                        ? ModalMultipleAutocompleteStylesProps
                        : ModalAutocompleteStylesProps
                    }
                    disabled={!listAvailableFolders.length}
                    style={{ marginBottom: '6px' }}
                    multiple
                    disableCloseOnSelect
                    fullWidth
                    size={'small'}
                    id='folders'
                    limitTags={2}
                    options={listAvailableFolders}
                    filterOptions={(options, params) => {
                      const filtered = filterFolder(options, params);
                      return [{ id: 0, name: 'Select all' }, ...filtered];
                    }}
                    getOptionLabel={option => option.name}
                    renderOption={(props, option, { selected }) => {
                      const isSelected =
                        option.id === 0
                          ? listAvailableFolders.length ===
                            formik.values.folders.length
                          : selected;
                      return (
                        <StyledAutocompleteLi {...props} key={option.id}>
                          <CheckboxWrapper checked={isSelected} />
                          <FolderIcon />
                          <Grid marginLeft={'8px'}>{option.name}</Grid>
                        </StyledAutocompleteLi>
                      );
                    }}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <StyledChip
                            label={option.name}
                            deleteIcon={
                              <IconButton>
                                <CloseIcon
                                  width='12'
                                  height='12'
                                  {...getTagProps({ index })}
                                />
                              </IconButton>
                            }
                            {...getTagProps({ index })}
                          />
                        );
                      })
                    }
                    value={formik.values.folders}
                    onChange={(_, value) => {
                      if (value.find(option => option.name === 'Select all')) {
                        if (
                          listAvailableFolders.length !==
                          formik.values.folders.length
                        ) {
                          formik.setFieldValue('folders', listAvailableFolders);
                        } else {
                          formik.setFieldValue('folders', []);
                        }
                      } else {
                        formik.setFieldValue('folders', value || []);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    popupIcon={<SelectIcon />}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder='Select folder to give access'
                        name='folders'
                        error={
                          !!(formik.touched.folders && formik.errors.folders)
                        }
                      />
                    )}
                    PaperComponent={AutocompletePaper}
                  />
                  {formik.touched.folders && formik.errors?.folders && (
                    <FormHelperText error id='folders'>
                      <CustomFormHelperText
                        error={formik.errors.folders.toString()}
                      />
                    </FormHelperText>
                  )}

                  <Autocomplete
                    sx={
                      formik.values.projects.length
                        ? ModalMultipleAutocompleteStylesProps
                        : ModalAutocompleteStylesProps
                    }
                    disabled={!projectsListForInvite.items.length}
                    style={{ marginBottom: '6px' }}
                    multiple
                    disablePortal
                    disableCloseOnSelect
                    fullWidth
                    size={'small'}
                    id='projects'
                    limitTags={2}
                    options={projectsListForInvite.items}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      return [
                        { id: 0, projectName: 'Select all' },
                        ...filtered,
                      ];
                    }}
                    getOptionLabel={option => option.projectName}
                    renderOption={(props, option, { selected }) => {
                      const isSelected =
                        option.id === 0
                          ? projectsListForInvite.items.length ===
                            formik.values.projects.length
                          : selected;
                      return (
                        <StyledAutocompleteLi {...props} key={option.id}>
                          <Grid container>
                            <CheckboxWrapper checked={isSelected} />
                            <Grid
                              item
                              display={'flex'}
                              alignItems={'center'}
                              marginRight={'8px'}
                            >
                              {option?.favicon ? (
                                <img
                                  src={option.favicon}
                                  width={16}
                                  height={16}
                                  alt={'Company Logo URL'}
                                />
                              ) : (
                                <GlobeIcon />
                              )}
                            </Grid>
                            <Grid item>{option.projectName}</Grid>
                          </Grid>
                        </StyledAutocompleteLi>
                      );
                    }}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <StyledChip
                            label={option.projectName}
                            deleteIcon={
                              <IconButton>
                                <CloseIcon
                                  width='12'
                                  height='12'
                                  {...getTagProps({ index })}
                                />
                              </IconButton>
                            }
                            {...getTagProps({ index })}
                          />
                        );
                      })
                    }
                    value={formik.values.projects}
                    onChange={(_, value) => {
                      if (
                        value.find(
                          option => option.projectName === 'Select all'
                        )
                      ) {
                        if (
                          projectsListForInvite.items.length !==
                          formik.values.projects.length
                        ) {
                          formik.setFieldValue(
                            'projects',
                            projectsListForInvite.items
                          );
                        } else {
                          formik.setFieldValue('projects', []);
                        }
                      } else {
                        formik.setFieldValue('projects', value || []);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    popupIcon={<SelectIcon />}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder='Select project to give access'
                        name='projects'
                        error={
                          !!(formik.touched.projects && formik.errors.projects)
                        }
                      />
                    )}
                    PaperComponent={AutocompletePaper}
                  />
                  {formik.touched.projects && formik.errors?.projects && (
                    <FormHelperText error id='projects'>
                      <CustomFormHelperText
                        error={formik.errors.projects.toString()}
                      />
                    </FormHelperText>
                  )}

                  {
                    <FormHelperText
                      error={!!formik.errors.message}
                      id='message'
                    >
                      {formik.errors.message && (
                        <CustomFormHelperText error={formik.errors.message} />
                      )}
                    </FormHelperText>
                  }

                  {
                    <FormHelperText
                      error={
                        !listAvailableFolders.length &&
                        !projectsListForInvite.items.length
                      }
                    >
                      {!listAvailableFolders.length &&
                        !projectsListForInvite.items.length && (
                          <CustomFormHelperText
                            error={"You don't have folders and projects yet"}
                          />
                        )}
                    </FormHelperText>
                  }
                </Grid>

                <ButtonsWrapper container xs={12}>
                  <Grid item xs={extraSmallScreen && 12}>
                    <GrayButtonSmall
                      fullWidth={extraSmallScreen}
                      size='small'
                      outline
                      onClick={handleClose}
                    >
                      Cancel
                    </GrayButtonSmall>
                  </Grid>
                  <Grid item xs={extraSmallScreen && 12}>
                    <PrimaryButtonSmall
                      fullWidth={extraSmallScreen}
                      variant='contained'
                      size='small'
                      type='submit'
                      disabled={
                        formik.isSubmitting ||
                        !formik.isValid ||
                        (!listAvailableFolders.length &&
                          !projectsListForInvite.items.length)
                      }
                    >
                      Add User
                    </PrimaryButtonSmall>
                  </Grid>
                </ButtonsWrapper>
              </ModalContainer>
            </ModalWrapper>
          </form>
        </Fade>
      </Modal>
      <UsersLimitExceededModal
        onClose={handleUpgradeAccount}
        onUpgradeAccount={handleCloseUsersLimitExceededModal}
        open={userLimitExceededOpen.open}
      />
    </>
  );
};

export default InviteUserModal;
