import React, { useMemo } from 'react';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItem,
  Divider,
  Typography,
  IconButton,
  Grid,
} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import KeyIcon from '../../../../images/KeyIcon';
import UserIcon from '../../../../images/UserIcon';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useAuth } from '../../../../../hooks';
import { avatarStyles, myAccountAvatarStyles } from '../../utils';
import { AccountMenuProps } from '../../types';
import { PopoverSx } from '../../constant';

import {
  PrimaryAccountContainer,
  AccessStatus,
  Popover,
  AccountInfo,
  StyledList,
  StyledIListItemButton,
} from './styles';
import { GrayButtonCustom } from '../../../../buttons/styles';
import TabMenu from './TabMenu/TabMenu';
import RemoveAccessModal from '../../../../../features/auth/modals/RemoveAccessModal/RemoveAccessModal';
import SetAsDefaultModal from '../../../../../features/auth/modals/SetAsDefaultModal/SetAsDefaultModal';
import { useAppSelector } from '../../../../../store';

const AccountMenu = ({
  onClickLogout,
  openSwitchAccess,
  myAccountClosePopup,
}: AccountMenuProps) => {
  const [openRemoveAccessModal, setOpenRemoveAccessModal] =
    React.useState(false);

  const handleOpenRemoveAccessModal = () => {
    setOpenRemoveAccessModal(previousOpen => !previousOpen);
  };

  const handleCloseRemoveAccessModal = () => {
    setOpenRemoveAccessModal(false);
  };

  const { onRemoveAccessAccount, onSetAsDefaultAccount } = useAuth();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const availableAccounts = useAppSelector(
    state => state.auth.availableAccounts
  );

  const isViewTabMenu = useMemo(
    () =>
      !currentAccount?.accountId ||
      currentAccount?.role?.name === 'ViewOnly' ||
      currentAccount?.role?.name === 'Addon',
    [currentAccount?.accountId, currentAccount?.role?.name]
  );

  const [openSetAsDefaultModal, setOpenSetAsDefaultModal] =
    React.useState(false);

  const handleOpenSetAsDefaultModal = () => {
    setOpenSetAsDefaultModal(previousOpen => !previousOpen);
  };

  const handleCloseSetAsDefaultModal = () => {
    setOpenSetAsDefaultModal(false);
  };

  const onSetAsDefault = (closePopup: () => void) => {
    onSetAsDefaultAccount({
      accountId: currentAccount.accountId,
      successFn: closePopup,
    });
  };

  const handleRemoveAccessAccount = (closePopup: () => void) => {
    onRemoveAccessAccount({
      accountId: currentAccount.accountId,
      successFn: closePopup,
    });
  };

  const stylesAccountAvatar = currentAccount.isMyAccount
    ? {
        ...myAccountAvatarStyles(
          `${currentAccount.owner.ownerUserFirstName}`,
          `${currentAccount.owner.ownerUserLastName}`,
          currentAccount.accountId,
          '40px',
          '20px'
        ),
      }
    : {
        ...avatarStyles(
          `${currentAccount.owner.ownerUserFirstName}`,
          `${currentAccount.owner.ownerUserLastName}`,
          currentAccount.accountId,
          '40px',
          '20px'
        ),
      };

  return (
    <Box
      sx={{
        padding: '8px 0',
        minWidth: '254px',
        maxWidth: '275px',
        position: 'relative',
      }}
    >
      {availableAccounts.length ? (
        <PopupState variant='popover' popupId='account-setting'>
          {popupState => (
            <>
              <Box sx={{ position: 'absolute', top: '11px', right: '20px' }}>
                <IconButton edge='end' {...bindTrigger(popupState)}>
                  <MoreVertIcon
                    sx={{
                      fontSize: '24px',
                      color: '#667085',
                      cursor: 'pointer',
                    }}
                  />
                </IconButton>
              </Box>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={PopoverSx}
              >
                <Box sx={{ padding: '2px 0' }}>
                  <List>
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{
                          padding: '9px 16px',
                          margin: '0 8px',
                          color: '#101828',
                          cursor: 'pointer',
                          fontSize: '14px',
                        }}
                        onClick={handleOpenSetAsDefaultModal}
                      >
                        Set as Default
                      </ListItemButton>
                      <SetAsDefaultModal
                        openSetAsDefaultModal={openSetAsDefaultModal}
                        onCloseSetAsDefaultModal={handleCloseSetAsDefaultModal}
                        onSetAsDefault={() => onSetAsDefault(popupState.close)}
                      />
                    </ListItem>
                    {currentAccount.isMyAccount ? null : (
                      <ListItem disablePadding>
                        <ListItemButton
                          sx={{
                            padding: '9px 16px',
                            margin: '0 8px',
                            color: '#B42318',
                            cursor: 'pointer',
                            fontSize: '14px',
                          }}
                          onClick={handleOpenRemoveAccessModal}
                        >
                          Remove My Access
                        </ListItemButton>
                        <RemoveAccessModal
                          openRemoveAccessModal={openRemoveAccessModal}
                          onCloseRemoveAccessModal={
                            handleCloseRemoveAccessModal
                          }
                          onRemoveAccesses={() => {
                            handleRemoveAccessAccount(popupState.close);
                          }}
                        />
                      </ListItem>
                    )}
                  </List>
                </Box>
              </Popover>
            </>
          )}
        </PopupState>
      ) : null}

      <Box sx={{ padding: '4px 16px' }}>
        <PrimaryAccountContainer>
          <Avatar {...stylesAccountAvatar} />
          <AccountInfo>
            {availableAccounts.length ? (
              <AccessStatus
                borderColor={
                  (currentAccount.isMyAccount && '#ABEFC6') || '#F9DBAF'
                }
                backgroundColor={
                  (currentAccount.isMyAccount && '#ECFDF3') || '#FEF6EE'
                }
                color={(currentAccount.isMyAccount && '#067647') || '#B93815'}
              >
                {currentAccount.isMyAccount
                  ? 'Primary Account'
                  : `${currentAccount.role.name} Access`}
              </AccessStatus>
            ) : null}
            <Typography sx={{ color: '#344054', fontWeight: 600 }}>
              {`${currentAccount.owner.ownerUserFirstName} ${currentAccount.owner.ownerUserLastName}`}
            </Typography>

            <Grid
              sx={{
                display: 'flex',
                gap: '6px',
                alignItems: 'center',
              }}
            >
              {availableAccounts.length ? <UserIcon /> : null}
              <Typography
                sx={{
                  color: '#475467',
                }}
              >
                {currentAccount.owner.ownerEmail}
              </Typography>
            </Grid>
            {!currentAccount.isMyAccount && (
              <Typography
                sx={{
                  display: 'flex',
                  gap: '6px',
                  alignItems: 'center',
                  color: '#475467',
                }}
              >
                <KeyIcon />
                <span>{currentAccount.invited.invitedEmail}</span>
              </Typography>
            )}
          </AccountInfo>
        </PrimaryAccountContainer>
        {availableAccounts.length ? (
          <GrayButtonCustom
            outline={true}
            sx={{ gap: '8px', width: '100%', marginTop: '20px' }}
            onClick={openSwitchAccess}
            $padding='5.5px 16px'
          >
            <span>Switch Access</span>
            <ArrowForwardIosOutlinedIcon sx={{ fontSize: '14px' }} />
          </GrayButtonCustom>
        ) : null}
      </Box>
      <Divider
        orientation='horizontal'
        flexItem
        sx={{
          margin: '16px 0 8px',
          color: '#EDEDED',
        }}
      />
      <StyledList>
        {!isViewTabMenu ? (
          <TabMenu myAccountClosePopup={myAccountClosePopup} />
        ) : null}
        <ListItem disablePadding>
          <StyledIListItemButton sx={{ gap: '8px' }} onClick={onClickLogout}>
            <ListItemIcon sx={{ minWidth: '16px' }}>
              <LogoutIcon
                sx={{ width: '16px', height: '16px' }}
                color={'error'}
              />
            </ListItemIcon>
            <ListItemText sx={{ color: '#B42318' }}>Log out</ListItemText>
          </StyledIListItemButton>
        </ListItem>
      </StyledList>
    </Box>
  );
};

export default AccountMenu;
