import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React, { useMemo } from 'react';
import { List, ListItemButton, useMediaQuery } from '@mui/material';
import { renderListItemTitle } from './utils';

import SettingsIcon from '../../../../../../../../../../shared/images/SettingsIcon';
import DownIcon from '../../../../../../../../../../shared/images/DownIcon';
import CustomCheckboxImage from '../../../../../../../../../../shared/images/CustomCheckbox';
import CheckedIcon from '../../../../../../../../../../shared/images/CheckedIcon';

import { CustomCheckbox } from '../../../../../../../../../../shared/checkbox/styles';
import { Popover } from '../../../../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import { GrayButtonCustom } from '../../../../../../../../../../shared/buttons/styles';
import {
  ListItemTypography,
  MainContainer,
  PopupLabel,
  ResetDefaultButton,
  StyledListItem,
} from './styles';
import { useProjects } from '../../../../../../../../../../hooks';
import { ColumnVisibilityKeyKeywordRankings } from '../../../../../../../../types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAppSelector } from '../../../../../../../../../../store';

const PopupTableSettings = () => {
  const extraSmallScreen = useMediaQuery('(max-width:680px)');

  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );

  const {
    onSetColumnVisibilityModelKeywordRankings,
    onResetColumnVisibilityModelKeywordRankings,
  } = useProjects();

  const columnVisibilityModel = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table
        .columnVisibilityModel
  );

  const handleResetColumnVisibilityModel = () => {
    onResetColumnVisibilityModelKeywordRankings();
  };

  const columnVisibilityList = useMemo(() => {
    if (
      projectExpanded?.searchEngine?.name !== 'Google' &&
      projectExpanded?.searchEngine?.name !== 'Bing'
    ) {
      return Object.keys(columnVisibilityModel).filter(
        item => item !== 'cpc' && item !== 'searchValue'
      );
    } else {
      return Object.keys(columnVisibilityModel);
    }
  }, [columnVisibilityModel, projectExpanded?.searchEngine?.name]);

  return (
    <>
      <PopupState variant='popover'>
        {popupState => (
          <>
            {!extraSmallScreen ? (
              <GrayButtonCustom
                $padding='7px 12px'
                size='small'
                outline
                startIcon={<SettingsIcon stroke='#344054' />}
                endIcon={<DownIcon stroke='#344054' />}
                {...bindTrigger(popupState)}
              >
                Table Settings
              </GrayButtonCustom>
            ) : (
              <MoreVertIcon {...bindTrigger(popupState)} />
            )}

            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MainContainer>
                <PopupLabel>Show or Hide Columns</PopupLabel>
                <ResetDefaultButton onClick={handleResetColumnVisibilityModel}>
                  Reset to default
                </ResetDefaultButton>
                <List>
                  {columnVisibilityList.map(item => {
                    const columnKey =
                      item as ColumnVisibilityKeyKeywordRankings;
                    return (
                      <StyledListItem
                        key={item}
                        disablePadding
                        checkBackgroundColor={
                          columnVisibilityModel[columnKey]
                            ? '#EEF3FF'
                            : undefined
                        }
                      >
                        <ListItemButton
                          onClick={() => {
                            onSetColumnVisibilityModelKeywordRankings(
                              columnKey,
                              !columnVisibilityModel[columnKey]
                            );
                          }}
                        >
                          <CustomCheckbox
                            checked={columnVisibilityModel[columnKey]}
                            icon={<CustomCheckboxImage />}
                            checkedIcon={<CheckedIcon />}
                            disableFocusRipple
                          />

                          <ListItemTypography>
                            {renderListItemTitle(columnKey)}
                          </ListItemTypography>
                        </ListItemButton>
                      </StyledListItem>
                    );
                  })}
                </List>
              </MainContainer>
            </Popover>
          </>
        )}
      </PopupState>
    </>
  );
};

export default PopupTableSettings;
