import React from 'react';

const SadFaceIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='44'
    height='43'
    viewBox='0 0 44 43'
    fill='none'
  >
    <path
      d='M21.8496 42.55C11.3871 42.55 0.0996094 35.9875 0.0996094 21.5875C0.0996094 7.1875 11.3871 0.625 21.8496 0.625C27.6621 0.625 33.0246 2.5375 36.9996 6.025C41.3121 9.85 43.5996 15.25 43.5996 21.5875C43.5996 27.925 41.3121 33.2875 36.9996 37.1125C33.0246 40.6 27.6246 42.55 21.8496 42.55Z'
      fill='url(#paint0_radial_213_30190)'
    />
    <path
      d='M39.8095 9.12622C41.8082 12.3512 42.8507 16.1912 42.8507 20.4625C42.8507 26.8 40.5632 32.1625 36.2507 35.9875C32.2757 39.475 26.8757 41.425 21.1007 41.425C14.3282 41.425 7.2257 38.6687 3.0332 32.8225C7.0682 39.445 14.6582 42.55 21.8507 42.55C27.6257 42.55 33.0257 40.6 37.0007 37.1125C41.3132 33.2875 43.6007 27.925 43.6007 21.5875C43.6007 16.81 42.2995 12.565 39.8095 9.12622Z'
      fill='#EB8F00'
    />
    <path
      d='M22.0006 27.7749C26.0506 27.7749 28.9006 29.8749 29.7631 31.1874C29.9248 31.3909 30.0277 31.6347 30.0608 31.8925C30.0938 32.1503 30.0557 32.4122 29.9506 32.6499C29.7856 32.9537 29.4706 33.1412 29.1256 33.1374C28.9981 33.1299 28.8706 33.1037 28.7506 33.0624C26.5555 32.4363 24.2834 32.1207 22.0006 32.1249C19.7281 32.1099 17.4631 32.4399 15.2881 33.0999C15.1681 33.1412 15.0406 33.1674 14.9131 33.1749C14.5681 33.1862 14.2456 32.9987 14.0881 32.6874C13.8706 32.2037 13.9456 31.6374 14.2756 31.2249C15.1006 29.8749 17.9881 27.7749 22.0006 27.7749Z'
      fill='#422B0D'
    />
    <path
      d='M11.875 37.1874C9.4375 37.1874 7.5625 34.8249 7.5625 32.7999C7.5625 31.3749 8.2 29.7249 9.1 27.4374C9.2125 27.0999 9.3625 26.7624 9.5125 26.3874C10.0375 25.0299 10.6375 23.7024 11.3125 22.4124C11.4475 22.1537 11.755 22.0412 12.025 22.1499C12.1375 22.2062 12.2312 22.2999 12.2875 22.4124C12.85 23.5749 13.4875 24.9999 14.125 26.4624C15.7 29.9874 16.15 31.4124 16.15 32.8374C16.1875 34.8249 14.275 37.1874 11.875 37.1874Z'
      fill='url(#paint1_radial_213_30190)'
    />
    <path
      d='M14.4992 34.4837C13.948 35.335 12.6992 35.1737 12.6992 33.76C12.6992 32.8562 12.883 28.2137 13.6592 28.8625C14.9342 29.92 15.2942 33.28 14.4992 34.4837Z'
      fill='#81D4FA'
    />
    <path
      d='M9.25071 15.5125C8.78196 16.2062 7.66821 15.7599 7.99446 14.92C8.76321 13.0937 10.1995 11.6312 12.0145 10.8362C12.6782 10.5212 13.3945 10.3225 14.1257 10.2437C14.497 10.1687 14.8607 10.4087 14.9357 10.78C15.0107 11.1512 14.7707 11.515 14.3957 11.59H14.347C12.2541 12.2846 10.4579 13.6671 9.25071 15.5125Z'
      fill='#422B0D'
    />
    <path
      d='M34.4836 15.5125C34.9523 16.2062 36.0661 15.7599 35.7398 14.92C34.9687 13.09 33.5223 11.6275 31.7011 10.8362C31.0373 10.5212 30.3286 10.3225 29.5973 10.2437C29.2261 10.1687 28.8623 10.4087 28.7911 10.78C28.7161 11.1512 28.9561 11.515 29.3273 11.59H29.3761C31.4723 12.28 33.2761 13.6637 34.4836 15.5125Z'
      fill='#422B0D'
    />
    <path
      d='M14.752 15.6025C13.1807 15.6025 11.752 16.93 11.752 19.135C11.752 21.34 13.1807 22.6638 14.752 22.6638C16.3232 22.6638 17.752 21.3363 17.752 19.135C17.752 16.9338 16.3232 15.6025 14.752 15.6025Z'
      fill='#422B0D'
    />
    <path
      d='M14.6054 17.0762C14.3486 16.954 14.054 16.9383 13.7856 17.0324C13.5173 17.1266 13.2971 17.3229 13.1729 17.5787C12.9741 17.9949 13.0641 18.4937 13.3979 18.8162C13.9304 19.0712 14.5716 18.8462 14.8304 18.3137C15.0291 17.8974 14.9391 17.3987 14.6054 17.0762Z'
      fill='#896024'
    />
    <path
      d='M29.125 15.6025C27.5538 15.6025 26.125 16.93 26.125 19.135C26.125 21.34 27.5538 22.6638 29.125 22.6638C30.6962 22.6638 32.125 21.3363 32.125 19.135C32.125 16.9338 30.7038 15.6025 29.125 15.6025Z'
      fill='#422B0D'
    />
    <path
      d='M28.9862 17.0762C28.7295 16.954 28.4348 16.9383 28.1665 17.0324C27.8982 17.1266 27.6779 17.3229 27.5537 17.5787C27.355 17.9949 27.445 18.4937 27.7787 18.8162C28.3112 19.0712 28.9525 18.8462 29.2112 18.3137C29.41 17.8974 29.32 17.3987 28.9862 17.0762Z'
      fill='#896024'
    />
    <defs>
      <radialGradient
        id='paint0_radial_213_30190'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(21.8496 21.5875) scale(21.36 21.36)'
      >
        <stop offset='0.5' stopColor='#FDE030' />
        <stop offset='0.92' stopColor='#F7C02B' />
        <stop offset='1' stopColor='#F4A223' />
      </radialGradient>
      <radialGradient
        id='paint1_radial_213_30190'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(12.1825 24.0223) scale(9.9075 15.2575)'
      >
        <stop offset='0.46' stopColor='#29B6F6' />
        <stop offset='1' stopColor='#1E88E5' />
      </radialGradient>
    </defs>
  </svg>
);

export default SadFaceIcon;
