import { NewProject, NewProjectValues } from './types';

/**
 * Converts the project edit values into a request data object based on the search engine type.
 * @param searchEngine - The name of the search engine.
 * @param values - The values used to edit the project.
 * @returns An object representing the edited project request data, or null if the search engine is not supported.
 */
function convertRequestEditDataByProjectType(
  searchEngine: string,
  values: NewProjectValues
) {
  switch (searchEngine) {
    case 'Google': {
      const requestData: NewProject = {
        projectName: values.projectName,
        url: values.url,
        keywords: values.keywords.filter(Boolean),
        checkFrequency: values.checkFrequency.name,
        deviceType: values.deviceType.name,
      };
      if (values.competitors.length)
        requestData.competitors = values.competitors;
      if (values.competitorIds.length)
        requestData.competitorIds = values.competitorIds.map(item =>
          item.id.toString()
        );
      if (values.tags.length) requestData.tags = values.tags;
      if (values.tagIds.length)
        requestData.tagIds = values.tagIds.map(item => item.id);
      if (values.note) requestData.note = values.note;

      return requestData;
    }
    case 'Google My Business': {
      const requestData: NewProject = {
        projectName: values.projectName,
        url: values.businessUrl,
        checkFrequency: values.checkFrequency.name,
        keywords: values.keywords.filter(Boolean),
        deviceType: values.deviceType.name,
      };

      if (values.businessCompetitors.length)
        requestData.businessCompetitors = values.businessCompetitors;
      if (values.competitorIds.length)
        requestData.competitorIds = values.competitorIds.map(item =>
          item.id.toString()
        );
      if (values.tags.length) requestData.tags = values.tags;
      if (values.tagIds.length)
        requestData.tagIds = values.tagIds.map(item => item.id);
      if (values.note) requestData.note = values.note;

      return requestData;
    }
    case 'Google Maps': {
      const requestData: NewProject = {
        projectName: values.projectName,
        url: values.businessUrl,
        checkFrequency: values.checkFrequency.name,
        keywords: values.keywords.filter(Boolean),
        deviceType: values.deviceType.name,
      };

      if (values.businessCompetitors.length)
        requestData.businessCompetitors = values.businessCompetitors;
      if (values.competitorIds.length)
        requestData.competitorIds = values.competitorIds.map(item =>
          item.id.toString()
        );
      if (values.tags.length) requestData.tags = values.tags;
      if (values.tagIds.length)
        requestData.tagIds = values.tagIds.map(item => item.id);
      if (values.note) requestData.note = values.note;

      return requestData;
    }
    case 'Bing': {
      const requestData: NewProject = {
        projectName: values.projectName,
        url: values.url,
        keywords: values.keywords.filter(Boolean),
        checkFrequency: values.checkFrequency.name,
        deviceType: values.deviceType.name,
      };
      if (values.competitors.length)
        requestData.competitors = values.competitors;
      if (values.competitorIds.length)
        requestData.competitorIds = values.competitorIds.map(item =>
          item.id.toString()
        );
      if (values.tags.length) requestData.tags = values.tags;
      if (values.tagIds.length)
        requestData.tagIds = values.tagIds.map(item => item.id);
      if (values.note) requestData.note = values.note;

      return requestData;
    }
    case 'Yahoo': {
      const requestData: NewProject = {
        projectName: values.projectName,
        url: values.url,
        keywords: values.keywords.filter(Boolean),
        checkFrequency: values.checkFrequency.name,
        deviceType: values.deviceType.name,
      };
      if (values.competitors.length)
        requestData.competitors = values.competitors;
      if (values.competitorIds.length)
        requestData.competitorIds = values.competitorIds.map(item =>
          item.id.toString()
        );
      if (values.tags.length) requestData.tags = values.tags;
      if (values.tagIds.length)
        requestData.tagIds = values.tagIds.map(item => item.id);
      if (values.note) requestData.note = values.note;

      return requestData;
    }
    case 'Baidu': {
      const requestData: NewProject = {
        projectName: values.projectName,
        url: values.url,
        keywords: values.keywords.filter(Boolean),
        checkFrequency: values.checkFrequency.name,
        deviceType: values.deviceType.name,
      };
      if (values.competitors.length)
        requestData.competitors = values.competitors;
      if (values.competitorIds.length)
        requestData.competitorIds = values.competitorIds.map(item =>
          item.id.toString()
        );
      if (values.tags.length) requestData.tags = values.tags;
      if (values.tagIds.length)
        requestData.tagIds = values.tagIds.map(item => item.id);
      if (values.note) requestData.note = values.note;

      return requestData;
    }
    case 'YouTube': {
      const requestData: NewProject = {
        projectName: values.projectName,
        url: values.url,
        keywords: values.keywords.filter(Boolean),
        checkFrequency: values.checkFrequency.name,
        deviceType: values.deviceType.name,
      };
      if (values.competitorsVideoUrl.length)
        requestData.competitors = values.competitorsVideoUrl;
      if (values.competitorIds.length)
        requestData.competitorIds = values.competitorIds.map(item =>
          item.id.toString()
        );
      if (values.tags.length) requestData.tags = values.tags;
      if (values.tagIds.length)
        requestData.tagIds = values.tagIds.map(item => item.id);
      if (values.note) requestData.note = values.note;

      return requestData;
    }
    default:
      return null;
  }
}

export { convertRequestEditDataByProjectType };
