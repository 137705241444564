import React, { useState } from 'react';
import {
  ListItemTypography,
  StyledListItem,
} from '../../../../../../../projects/components/ProjectExpanded/components/KeywordRankings/components/KeywordRankingsTable/components/EllipsisMenu/styles';
import { DeleteSharedLinkActionProps } from './types';
import EditIcon from '../../../../../../../../shared/images/EditIcon';
import EditSharedLinkModal from '../../../../../modals/EditSharedLinkModal/EditSharedLinkModal';
import { StyledListItemButton } from '../../../../../../../../shared/buttons/styles';
import { useSharedLinks } from '../../../../../../../../hooks';

const EditSharedLinkAction: React.FC<DeleteSharedLinkActionProps> = ({
  linkId,
  popupClose,
}) => {
  const { onSetRowSelectionModelSharedLinks } = useSharedLinks();

  const [isOpenModal, setOpenModal] = useState(false);

  const onOpen = () => {
    setOpenModal(true);
  };

  const onClose = () => {
    onSetRowSelectionModelSharedLinks([]);
    setOpenModal(false);
    popupClose();
  };

  return (
    <StyledListItem>
      <StyledListItemButton onClick={onOpen}>
        <EditIcon />
        <ListItemTypography marginLeft='8px'>
          Edit Shared Link
        </ListItemTypography>
      </StyledListItemButton>
      <EditSharedLinkModal
        linkId={linkId}
        onClose={onClose}
        open={isOpenModal}
      />
    </StyledListItem>
  );
};

export default EditSharedLinkAction;
