import { AxiosResponse } from 'axios';

import api from '../../api';
import {
  GetProjectsParams,
  ProjectsState,
  LanguagesResponse,
  CheckFrequencyResponse,
  GoogleDomainsResponse,
  DeviceTypeResponse,
  AccountTagsResponse,
  GetAccountTagsParams,
  NewProjectParams,
  DeleteOneProjectParams,
  BulkDeleteProjectsParams,
  GetCurrentProjectParams,
  Project,
  EditProjectParams,
  AddKeywordsParams,
  GetProjectExpandedParams,
  ProjectExpanded,
  GetRefreshAllKeywordsParams,
  ChangeFrequencyForProjectsParams,
  AddTagsParams,
  GetListAvailableProjectsParams,
  GetListAvailableProjectsResponse,
  GetProjectsOverviewParams,
  ProjectOverview,
  GetProjectsImprovedVsDeclinedParams,
  ProjectImprovedVsDeclined,
  GetKeywordPositionHistoryParams,
  GetKeywordPositionHistoryResponse,
  GetKeywordRankingParams,
  KeywordRankingState,
  DeleteKeywordsParams,
  RefreshKeywordsParams,
  GetProjectsKeywordTrendsParams,
  ProjectKeywordTrends,
  KeywordSearchResultsState,
  GetKeywordSearchResultsParams,
  MoveProjectsToFolderParams,
  MoveFoldersToFolderParams,
  UpdateKeywordPositionParams,
  ExportToCsvKeywordPositionsInfoParams,
  KeywordRanking,
  SearchLocationsParams,
  SearchLocationsResponse,
  GetNumberOfAvailableKeywordsToUpdateParams,
  GetNumberOfAvailableKeywordsToUpdateResponse,
  IAddKeywordsParamsResponse,
  GetProjectsNumberOfAvailableKeywordsToUpdateParams,
  INewProjectResponse,
  IDownloadProjectInfoToCSVParams,
} from './types';
import {
  GetKeywordInfoParams,
  GetKeywordInfoResponse,
  GetKeywordPositionsInfoParams,
  GetKeywordPositionsInfoResponse,
} from './types/keywordInfo';
import {
  GetProjectPerformanceParams,
  GetProjectPerformanceResponse,
} from './types/projectPerformance';
import { convertRequestDataByProjectType } from './createProjectUtils';

export const getProjectsAPI = async (
  params: GetProjectsParams
): Promise<AxiosResponse<ProjectsState>> => {
  return api.get<ProjectsState>(`/accounts/${params.accountId}/projects`, {
    params: {
      ...params.params,
      page: params.params.page + 1,
      sortOrder: params.params.sortOrder
        ? params.params.sortOrder.toUpperCase()
        : null,
      tagIds: params.params.tagIds ? params.params.tagIds : null,
      frequencyName: undefined,
      dailyAverage: params.params.dailyAverage,
    },
  });
};

export const deleteOneProjectAPI = async (
  params: DeleteOneProjectParams
): Promise<AxiosResponse<null>> => {
  return api.delete<null>(
    `/accounts/${params.accountId}/projects/${params.projectId}`
  );
};

export const bulkDeleteProjectsAPI = async (
  params: BulkDeleteProjectsParams
): Promise<AxiosResponse<null>> => {
  return api.post<null>(`/accounts/${params.accountId}/projects/bulk-delete`, {
    projectIds: params.projectIds,
  });
};

export const getCheckFrequencyAPI = async (): Promise<
  AxiosResponse<CheckFrequencyResponse>
> => {
  return api.get<CheckFrequencyResponse>('/check-frequency');
};

export const getLanguagesAPI = async (): Promise<
  AxiosResponse<LanguagesResponse>
> => {
  return api.get<LanguagesResponse>('/languages');
};

export const getLanguagesBingAPI = async (): Promise<
  AxiosResponse<LanguagesResponse>
> => {
  return api.get<LanguagesResponse>('/languages/bing');
};

export const getLanguagesYoutubeAPI = async (): Promise<
  AxiosResponse<LanguagesResponse>
> => {
  return api.get<LanguagesResponse>('/languages/youtube');
};

export const getLanguagesYahooAPI = async (): Promise<
  AxiosResponse<LanguagesResponse>
> => {
  return api.get<LanguagesResponse>('/languages/yahoo');
};

export const getLanguagesBaiduAPI = async (): Promise<
  AxiosResponse<LanguagesResponse>
> => {
  return api.get<LanguagesResponse>('/languages/baidu');
};

export const getGoogleDomainsAPI = async (): Promise<
  AxiosResponse<GoogleDomainsResponse>
> => {
  return api.get<GoogleDomainsResponse>('/google-domains');
};

export const getDeviceTypesAPI = async (): Promise<
  AxiosResponse<DeviceTypeResponse>
> => {
  return api.get<DeviceTypeResponse>('/device-types');
};

export const getAccountTagsAPI = async (
  params: GetAccountTagsParams
): Promise<AxiosResponse<AccountTagsResponse>> => {
  return api.get<AccountTagsResponse>(
    `/accounts/${params.accountId}/project-tags${
      params.search ? `?search=${params.search}` : ''
    }`
  );
};

export const newProjectAPI = async (
  params: NewProjectParams
): Promise<AxiosResponse<INewProjectResponse>> => {
  return api.post(
    `/accounts/${params.accountId}/folders/${params.folderId}/projects-for-google`,
    {
      ...convertRequestDataByProjectType(params.data.searchEngine, params.data),
      keywords: params.data.keywords.filter(item => /\S/.test(item)),
    }
  );
};

export const newProjectForGoogleLocalAPI = async (
  params: NewProjectParams
): Promise<AxiosResponse<INewProjectResponse>> => {
  return api.post(
    `/accounts/${params.accountId}/folders/${params.folderId}/projects-for-google-local`,
    {
      ...convertRequestDataByProjectType(params.data.searchEngine, params.data),
      keywords: params.data.keywords.filter(item => /\S/.test(item)),
    }
  );
};

export const newProjectForGoogleMapsAPI = async (
  params: NewProjectParams
): Promise<AxiosResponse<INewProjectResponse>> => {
  return api.post(
    `/accounts/${params.accountId}/folders/${params.folderId}/projects-for-google-maps`,
    {
      ...convertRequestDataByProjectType(params.data.searchEngine, params.data),
      keywords: params.data.keywords.filter(item => /\S/.test(item)),
    }
  );
};

export const newProjectForYoutubeAPI = async (
  params: NewProjectParams
): Promise<AxiosResponse<INewProjectResponse>> => {
  return api.post(
    `/accounts/${params.accountId}/folders/${params.folderId}/projects-for-youtube`,
    {
      ...convertRequestDataByProjectType(params.data.searchEngine, params.data),
      keywords: params.data.keywords.filter(item => /\S/.test(item)),
    }
  );
};

export const newProjectForBingAPI = async (
  params: NewProjectParams
): Promise<AxiosResponse<INewProjectResponse>> => {
  return api.post(
    `/accounts/${params.accountId}/folders/${params.folderId}/projects-for-bing`,
    {
      ...convertRequestDataByProjectType(params.data.searchEngine, params.data),
      keywords: params.data.keywords.filter(item => /\S/.test(item)),
    }
  );
};

export const newProjectForYahooAPI = async (
  params: NewProjectParams
): Promise<AxiosResponse<INewProjectResponse>> => {
  return api.post(
    `/accounts/${params.accountId}/folders/${params.folderId}/projects-for-yahoo`,
    {
      ...convertRequestDataByProjectType(params.data.searchEngine, params.data),
      keywords: params.data.keywords.filter(item => /\S/.test(item)),
    }
  );
};

export const newProjectForBaiduAPI = async (
  params: NewProjectParams
): Promise<AxiosResponse<INewProjectResponse>> => {
  return api.post(
    `/accounts/${params.accountId}/folders/${params.folderId}/projects-for-baidu`,
    {
      ...convertRequestDataByProjectType(params.data.searchEngine, params.data),
      keywords: params.data.keywords.filter(item => /\S/.test(item)),
    }
  );
};

export const getCurrentProjectAPI = async (
  params: GetCurrentProjectParams
): Promise<AxiosResponse<Project>> => {
  return api.get<Project>(
    `/accounts/${params.accountId}/projects/${params.projectId}`,
    {
      params: {
        deviceType: true,
        tags: true,
        frequency: true,
        region: true,
        language: true,
        country: true,
        searchEngine: true,
        competitors: true,
        notes: true,
      },
    }
  );
};

export const editProjectAPI = async (
  params: EditProjectParams
): Promise<AxiosResponse<IAddKeywordsParamsResponse>> => {
  return api.patch(
    `/accounts/${params.accountId}/projects/${params.projectId}`,
    {
      ...params.data,
      keywords: params.data.keywords.filter(item => /\S/.test(item)),
      competitors: params.data.competitors?.length
        ? params.data.competitors
        : null,
    }
  );
};

export const addKeywordsAPI = async (
  params: AddKeywordsParams
): Promise<AxiosResponse<IAddKeywordsParamsResponse>> => {
  return api.post(
    `/accounts/${params.accountId}/projects/${params.projectId}/add-keywords`,
    {
      ...params.data,
      keywords: params.data.keywords.filter(item => /\S/.test(item)),
    }
  );
};

export const getProjectExpandedAPI = async (
  params: GetProjectExpandedParams
): Promise<AxiosResponse<ProjectExpanded>> => {
  return api.get<ProjectExpanded>(
    `/accounts/${params.accountId}/projects/${params.projectId}/project-info`,
    {
      params: {
        keywordDeviceType: params.keywordDeviceType,
        tags: true,
        keywordTags: true,
        notes: !!params.notes,
      },
    }
  );
};

export const getRefreshAllKeywordsAPI = async (
  params: GetRefreshAllKeywordsParams
): Promise<AxiosResponse<null>> => {
  return api.patch<null>(
    `/accounts/${params.accountId}/projects/refresh-all-keywords`,
    {
      projectIds: params.projectIds ? params.projectIds : [],
      folderIds: params.folderIds ? params.folderIds : [],
    }
  );
};

export const changeFrequencyForProjectsAPI = async (
  params: ChangeFrequencyForProjectsParams
): Promise<AxiosResponse<null>> => {
  return api.patch<null>(
    `/accounts/${params.accountId}/projects/change-frequency-for-projects`,
    { projectIds: params.projectIds, frequency: params.frequency }
  );
};

export const addTagsAPI = async (params: AddTagsParams) => {
  return api.post(`/accounts/${params.accountId}/projects/add-tags`, {
    ...params.data,
  });
};

export const getListAvailableProjectsAPI = async (
  params: GetListAvailableProjectsParams
): Promise<AxiosResponse<GetListAvailableProjectsResponse>> => {
  return api.get<GetListAvailableProjectsResponse>(
    `/accounts/${params.accountId}/projects/list-available-projects?search=${params.search}`
  );
};

export const getProjectsOverviewAPI = async (
  params: GetProjectsOverviewParams
): Promise<AxiosResponse<ProjectOverview>> => {
  const paramsObj =
    params.fromDate && params.toDate
      ? {
          fromDate: params.fromDate,
          toDate: params.toDate,
          deviceType: params.deviceType,
        }
      : {
          deviceType: params.deviceType,
        };
  return api.get<ProjectOverview>(
    `/accounts/${params.accountId}/projects/${params.projectId}/overview`,
    {
      params: {
        ...paramsObj,
      },
    }
  );
};

export const getProjectsImprovedVsDeclinedAPI = async (
  params: GetProjectsImprovedVsDeclinedParams
): Promise<AxiosResponse<ProjectImprovedVsDeclined>> => {
  return api.get<ProjectImprovedVsDeclined>(
    `/accounts/${params.accountId}/projects/${params.projectId}/improved-vs-declined`,
    {
      params: {
        period: params.period,
        improvedFilter: params.improvedFilter,
        declinedFilter: params.declinedFilter,
        lostFilter: params.lostFilter,
        noChange: params.noChange,
        deviceType: params.deviceType,
      },
    }
  );
};

export const getKeywordRankingsPI = async (
  params: GetKeywordRankingParams
): Promise<AxiosResponse<KeywordRankingState>> => {
  return api.get<KeywordRankingState>(
    `/accounts/${params.accountId}/projects/${params.projectId}/keyword-rankings`,
    {
      params: {
        ...params.params,
        ...params.filters,
        tagIds: params.tagIds,
        deviceType: params.deviceType,
        page: params.params.page + 1,
        sortOrder: params.params.sortOrder
          ? params.params.sortOrder.toUpperCase()
          : null,
      },
    }
  );
};

export const getKeywordPositionHistoryAPI = async (
  params: GetKeywordPositionHistoryParams
): Promise<AxiosResponse<GetKeywordPositionHistoryResponse>> => {
  return api.get<GetKeywordPositionHistoryResponse>(
    `/accounts/${params.accountId}/keywords/${params.keywordId}`,
    {
      params: {
        period: params.period,
        competitorIds: params.competitorIds,
      },
    }
  );
};

export const refreshKeywordsAPI = async (params: RefreshKeywordsParams) => {
  return api.post(
    `/accounts/${params.accountId}/projects/${params.projectId}/update-keyword-positions`,
    {
      ...params.data,
    }
  );
};

export const deleteKeywordsAPI = async (params: DeleteKeywordsParams) => {
  return api.post(
    `/accounts/${params.accountId}/projects/${params.projectId}/delete-keywords`,
    {
      ...params.data,
    }
  );
};

export const getProjectsKeywordTrendsAPI = async (
  params: GetProjectsKeywordTrendsParams
): Promise<AxiosResponse<ProjectKeywordTrends>> => {
  return api.get<ProjectKeywordTrends>(
    `/accounts/${params.accountId}/projects/${params.projectId}/keyword-trends`,
    {
      params: {
        period: params.period,
        top3Filter: params.top3Filter,
        fromFourToTen: params.fromFourToTen,
        fromElevenToTwenty: params.fromElevenToTwenty,
        fromTwentyOneToFifty: params.fromTwentyOneToFifty,
        fiftyOneToOneHundred: params.fiftyOneToOneHundred,
        notRanked: params.notRanked,
        deviceType: params.deviceType,
      },
    }
  );
};

export const getKeywordSearchResultsAPI = async (
  params: GetKeywordSearchResultsParams
): Promise<AxiosResponse<KeywordSearchResultsState>> => {
  return api.get<KeywordSearchResultsState>(
    `/accounts/${params.accountId}/keywords/${params.keywordId}/search-results`,
    {
      params: {
        page: params.page,
        limit: params.limit,
      },
    }
  );
};

export const getKeywordInfoAPI = async (
  params: GetKeywordInfoParams
): Promise<AxiosResponse<GetKeywordInfoResponse>> => {
  return api.get<GetKeywordInfoResponse>(
    `/accounts/${params.id}/keywords/${params.keywordId}/keyword-info`
  );
};

export const getKeywordPositionsInfoAPI = async (
  params: GetKeywordPositionsInfoParams
): Promise<AxiosResponse<GetKeywordPositionsInfoResponse>> => {
  return api.get<GetKeywordPositionsInfoResponse>(
    `/accounts/${params.id}/keywords/${params.keywordId}/keyword-positions-info`,
    {
      params: {
        page: params.page,
        limit: params.limit,
        sortBy: params.sortBy,
        sortOrder: params?.sortOrder ? params.sortOrder.toUpperCase() : null,
      },
    }
  );
};

export const getProjectPerformanceAPI = async (
  params: GetProjectPerformanceParams
): Promise<AxiosResponse<GetProjectPerformanceResponse>> => {
  return api.get<GetProjectPerformanceResponse>(
    `/accounts/${params.id}/projects/${params.projectId}/project-performance`,
    {
      params: {
        period: params.period,
        fromDate: params.fromDate,
        toDate: params.toDate,
        competitorIds: params.competitorIds,
        deviceType: params.deviceType,
      },
    }
  );
};

export const moveProjectsToFolderAPI = async (
  params: MoveProjectsToFolderParams
): Promise<AxiosResponse<null>> => {
  return api.patch<null>(
    `/accounts/${params.id}/folders/${params.folderId}/projects`,
    { projectIds: params.projectIds }
  );
};

export const moveFoldersToFolderAPI = async (
  params: MoveFoldersToFolderParams
): Promise<AxiosResponse<null>> => {
  return api.post<null>(
    `/accounts/${params.id}/folders/${params.folderId}/move-folders`,
    {
      destinationFolderId: params.destinationFolderId,
    }
  );
};

export const updateKeywordPositionAPI = async (
  params: UpdateKeywordPositionParams
): Promise<AxiosResponse<KeywordRanking>> => {
  return api.post<KeywordRanking>(
    `/accounts/${params.id}/projects/${params.projectId}/update-keyword-position/${params.keywordId}`
  );
};

export const exportToCsvKeywordPositionsInfoAPI = async (
  params: ExportToCsvKeywordPositionsInfoParams
): Promise<AxiosResponse<string>> => {
  return api.get<string>(
    `/accounts/${params.id}/keywords/${params.keywordId}/keyword-positions-info/export-to-csv`
  );
};

export const searchLocationsAPI = async (
  params: SearchLocationsParams
): Promise<AxiosResponse<SearchLocationsResponse>> => {
  return api.get<SearchLocationsResponse>(`/countries/locations`, {
    params: {
      search: params.search || undefined,
      page: params.page,
      limit: params.limit,
    },
  });
};

export const getNumberOfAvailableKeywordsToUpdateAPI = async (
  params: GetNumberOfAvailableKeywordsToUpdateParams
): Promise<AxiosResponse<GetNumberOfAvailableKeywordsToUpdateResponse>> => {
  return api.patch<GetNumberOfAvailableKeywordsToUpdateResponse>(
    `/accounts/${params.accountId}/projects/${params.projectId}/number-of-available-keywords-to-update`,
    { keywordIds: params.keywordIds }
  );
};

export const getKeywordTagsAPI = async (
  params: GetAccountTagsParams
): Promise<AxiosResponse<AccountTagsResponse>> => {
  return api.get<AccountTagsResponse>(
    `/accounts/${params.accountId}/keyword-tags${
      params.search ? `?search=${params.search}` : ''
    }`
  );
};

export const getProjectsNumberOfAvailableKeywordsToUpdateAPI = async (
  params: GetProjectsNumberOfAvailableKeywordsToUpdateParams
): Promise<AxiosResponse<GetNumberOfAvailableKeywordsToUpdateResponse>> => {
  return api.patch<GetNumberOfAvailableKeywordsToUpdateResponse>(
    `/accounts/${params.accountId}/projects/number-of-keywords-to-update`,
    { projectIds: params.projectIds, folderIds: params.folderIds }
  );
};

export const geLocationsOfChinaAPI = async (
  params: SearchLocationsParams
): Promise<AxiosResponse<SearchLocationsResponse>> => {
  return api.get<SearchLocationsResponse>(`/countries/locations-of-china`, {
    params: { search: params.search, page: params.page, limit: params.limit },
  });
};

export const searchLocationsBingAPI = async (
  params: SearchLocationsParams
): Promise<AxiosResponse<SearchLocationsResponse>> => {
  return api.get<SearchLocationsResponse>(`/countries/locations/bing`, {
    params: {
      search: params.search || undefined,
      page: params.page,
      limit: params.limit,
    },
  });
};

export const searchLocationsYoutubeAPI = async (
  params: SearchLocationsParams
): Promise<AxiosResponse<SearchLocationsResponse>> => {
  return api.get<SearchLocationsResponse>(`/countries/locations/youtube`, {
    params: {
      search: params.search || undefined,
      page: params.page,
      limit: params.limit,
    },
  });
};

export const searchLocationsYahooAPI = async (
  params: SearchLocationsParams
): Promise<AxiosResponse<SearchLocationsResponse>> => {
  return api.get<SearchLocationsResponse>(`/countries/locations/yahoo`, {
    params: {
      search: params.search || undefined,
      page: params.page,
      limit: params.limit,
    },
  });
};

export const searchLocationsBaiduAPI = async (
  params: SearchLocationsParams
): Promise<AxiosResponse<SearchLocationsResponse>> => {
  return api.get<SearchLocationsResponse>(`/countries/locations/baidu`, {
    params: {
      search: params.search || undefined,
      page: params.page,
      limit: params.limit,
    },
  });
};

export const downloadProjectInfoToCSVAPI = async (
  params: IDownloadProjectInfoToCSVParams
): Promise<AxiosResponse<string>> => {
  return api.get<string>(
    `/accounts/${params.id}/projects/${params.projectId}/project-info-to-csv`
  );
};
