import React, { useEffect, useMemo, useState } from 'react';
import { Fade, Grid, IconButton, Modal, Typography } from '@mui/material';
import {
  ModalContainer,
  ModalDynamicWidthWrapper,
} from '../../../../../projects/components/Projects/components/ProjectRedactorModal/styles';
import { CloseDeletePopoverOne } from '../../../../../auth/components/Settings/components/Account/styles';
import CloseIcon from '@mui/icons-material/Close';
import { StylesLabelModal } from '../../../Alerts/components/Header/components/AddNewAlert/styles';
import { TriggersModalProps } from './types';
import { AlertsSwitch } from '../../../Alerts/components/Header/components';
import { PrimaryButtonCustom } from '../../../../../../shared/buttons/styles';
import PlusIcon from '../../../../../../shared/images/PlusIcon';
import TriggersTable from './components/TriggersTable/TriggersTable';
import AddNewAlert from '../../../Alerts/components/Header/components/AddNewAlert/AddNewAlert';
import useAlerts from '../../../../hooks/useAlerts';
import ActionsMenu from './components/TriggersTable/components/ActionsMenu/ActionsMenu';
import { useAppSelector } from '../../../../../../store';
import useAccountLimits from '../../../../../auth/hooks/useAccountLimits';

const TriggersModal: React.FC<TriggersModalProps> = ({ handleClose, open }) => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const isAuth = useAppSelector(state => state.auth.isAuth);
  const selectBy = useAppSelector(state => state.alerts.triggers.selectBy);

  const {
    triggers: { shouldRefreshTable },
    tables: {
      triggers: { paginationModel, sortModel, rowSelectionModel },
      triggerKeywords: { isOpenModal },
    },
    onGetTriggersByProjects,
    onGetTriggersByKeywords,
    onSetRowSelectionModelTriggers,
    onChangeTriggersType,
    onSetTriggersSelectBy,
    onSetShouldRefreshTable,
  } = useAlerts();

  const { onCheckSubscriptionExpiration } = useAccountLimits();

  const [isOpenAddAlertModal, setOpenAddAlertModal] = useState(false);

  const isViewOnly = useMemo(
    () => !isAuth || (isAuth && currentAccount?.role?.name === 'ViewOnly'),
    [currentAccount?.role?.name, isAuth]
  );

  const onOpen = () => {
    const isExpiration = onCheckSubscriptionExpiration();
    if (!isExpiration) {
      setOpenAddAlertModal(true);
    }
  };

  const onClose = () => {
    setOpenAddAlertModal(false);
  };

  const onClearSelected = () => {
    onSetRowSelectionModelTriggers([]);
  };

  useEffect(() => {
    if (currentAccount?.accountId && open) {
      if (selectBy === 'by Project') {
        onGetTriggersByProjects({
          id: currentAccount.accountId,
          page: paginationModel.page,
          limit: paginationModel.pageSize,
          sortBy: sortModel[0]?.field,
          sortOrder: sortModel[0]?.sort,
        });
      }
      if (selectBy === 'by Keywords') {
        onGetTriggersByKeywords({
          id: currentAccount.accountId,
          page: paginationModel.page,
          limit: paginationModel.pageSize,
          sortBy: sortModel[0]?.field,
          sortOrder: sortModel[0]?.sort,
        });
      }
    }
  }, [
    currentAccount?.accountId,
    paginationModel.page,
    paginationModel.pageSize,
    sortModel,
    open,
  ]);

  useEffect(() => {
    if (shouldRefreshTable && !isOpenModal) {
      onGetTriggersByKeywords({
        id: currentAccount.accountId,
        page: paginationModel.page,
        limit: paginationModel.pageSize,
        sortBy: sortModel[0]?.field,
        sortOrder: sortModel[0]?.sort,
      });
      onSetShouldRefreshTable(false);
    }
  }, [isOpenModal, shouldRefreshTable]);

  useEffect(() => {
    if (open) {
      onChangeTriggersType();
    }
  }, [onChangeTriggersType, selectBy, open]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <ModalDynamicWidthWrapper>
            <ModalContainer container xs={12} sx={{ gap: '8px' }}>
              <Grid xs={12}>
                <Grid item xs={12} sx={{ position: 'relative' }}>
                  <CloseDeletePopoverOne onClick={handleClose}>
                    <CloseIcon fontSize='small' />
                  </CloseDeletePopoverOne>
                  <Typography
                    fontSize='18px'
                    fontWeight='600'
                    lineHeight='28px'
                    color='#101828'
                  >
                    Triggers
                  </Typography>
                </Grid>
                <StylesLabelModal>Manage your alerts.</StylesLabelModal>
                <Grid
                  container
                  gap={2}
                  justifyContent={'space-between'}
                  alignItems={'flex-end'}
                  paddingTop={'32px'}
                  paddingBottom={'16px'}
                >
                  <Grid item>
                    <AlertsSwitch
                      selectBy={selectBy}
                      setSelectBy={onSetTriggersSelectBy}
                    />
                  </Grid>
                  {!isViewOnly && (
                    <Grid item>
                      <PrimaryButtonCustom
                        variant='contained'
                        size='small'
                        $padding='8px 12px'
                        startIcon={<PlusIcon />}
                        onClick={onOpen}
                      >
                        Add New Trigger
                      </PrimaryButtonCustom>
                    </Grid>
                  )}
                </Grid>
                <Grid container item alignItems='center' xs={12}>
                  {rowSelectionModel.length ? (
                    <>
                      <Grid item>
                        <IconButton
                          onClick={onClearSelected}
                          sx={{ padding: '0 !important', marginRight: '8px' }}
                        >
                          <CloseIcon fontSize='small' />
                        </IconButton>
                      </Grid>
                      <Grid item sx={{ paddingRight: '16px' }}>
                        <Typography
                          fontSize='14px'
                          lineHeight='14px'
                          color='#101828'
                          fontWeight='500'
                          alignItems='center'
                        >
                          {`${rowSelectionModel.length} Selected`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <ActionsMenu selectBy={selectBy} />
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid item>
                  <TriggersTable selectBy={selectBy} />
                </Grid>
              </Grid>
            </ModalContainer>
          </ModalDynamicWidthWrapper>
        </Fade>
      </Modal>
      <AddNewAlert
        type={selectBy}
        handleClose={onClose}
        open={isOpenAddAlertModal}
      />
    </>
  );
};

export default TriggersModal;
