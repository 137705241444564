import { createAction } from '@reduxjs/toolkit';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import {
  GetBillingResponse,
  IBillingCard,
  IFolderTree,
  IGetAddressGeolocationActionParams,
  IUpdateBalanceCount,
  NecessaryRemovalResponse,
  User,
} from '../types';
import {
  ICannotAddExcessKeywords,
  ICannotRefreshExcessKeywords,
  ICompetitorsLimitExceeded,
  IDailyRefreshesLimitExceeded,
  IEmailReportsLimitExceeded,
  IKeywordsLimitExceeded,
  IKeywordUpdateScheduled,
  INotesLimitExceeded,
  ISharedLinksLimitExceeded,
  ITagsPerKeywordLimitExceeded,
  ITagsPerProjectLimitExceeded,
  ITriggersLimitExceeded,
  IUsersLimitExceeded,
  IYouveReachedYourRefreshLimitForToday,
} from '../types/errors';

/**
 * Creates an action to set search keywords for team users.
 * @param {string} text - The search keyword.
 * @returns {object} - The action object with payload.
 */
const setSearchKeywordUsersTeam = createAction(
  'set-search-keyword-users',
  function prepare(text: string) {
    return {
      payload: text,
    };
  }
);

/**
 * Creates an action to set the sort model for the team.
 * @param {GridSortModel} sortModel - The sort model.
 * @returns {object} - The action object with payload.
 */
const setSortModelTeam = createAction(
  'sort-model-team',
  function prepare(sortModel: GridSortModel) {
    return {
      payload: sortModel,
    };
  }
);

/**
 * Creates an action to reset authentication state.
 * @returns {object} - The action object.
 */
const resetAuthState = createAction('resetAuthState');

/**
 * Creates an action to set the page size for invoices.
 * @param {number} pageSize - The page size for invoices.
 * @returns {object} - The action object with payload.
 */
const setInvoicesPageSize = createAction(
  'setInvoicesPageSize',
  function prepare(pageSize: number) {
    return {
      payload: pageSize,
    };
  }
);

/**
 * Creates an action to set the pagination page for invoices.
 * @param {number} page - The pagination page.
 * @returns {object} - The action object with payload.
 */
const setInvoicesPaginationPage = createAction(
  'setInvoicesPaginationPage',
  function prepare(page: number) {
    return {
      payload: page,
    };
  }
);

/**
 * Creates an action to set the sort model for invoices.
 * @param {GridSortModel} sortModel - The sort model for invoices.
 * @returns {object} - The action object with payload.
 */
const setSortModelInvoices = createAction(
  'setSortModelInvoices',
  function prepare(sortModel: GridSortModel) {
    return {
      payload: sortModel,
    };
  }
);

/**
 * Creates an action to open or close the site bar.
 * @param {boolean} isOpen - Indicates if the site bar is open.
 * @returns {object} - The action object with payload.
 */
const seOpenSiteBar = createAction(
  'seOpenSiteBar',
  function prepare(isOpen: boolean) {
    return {
      payload: isOpen,
    };
  }
);

/**
 * Creates an action to set the elements to be deleted from the teams table.
 * @param {GridRowSelectionModel} deleteElements - The elements to be deleted.
 * @returns {object} - The action object with payload.
 */
const setDeleteElementsTeamsTable = createAction(
  'setDeleteElementsTeamsTable',
  function prepare(deleteElements: GridRowSelectionModel) {
    return {
      payload: { deleteElements },
    };
  }
);

/**
 * Creates an action to update the email reports balance count.
 * @param {IUpdateBalanceCount} value - The value to update the balance count.
 * @returns {object} - The action object with payload.
 */
const updateEmailReportsBalanceCount = createAction(
  'updateEmailReportsBalanceCount',
  function prepare(value: IUpdateBalanceCount) {
    return {
      payload: { value },
    };
  }
);

/**
 * Creates an action to update the triggers balance count.
 * @param {IUpdateBalanceCount} value - The value to update the balance count.
 * @returns {object} - The action object with payload.
 */
const updateTriggersBalanceCount = createAction(
  'updateTriggersBalanceCount',
  function prepare(value: IUpdateBalanceCount) {
    return {
      payload: { value },
    };
  }
);

/**
 * Creates an action to update the users balance count.
 * @param {IUpdateBalanceCount} value - The value to update the balance count.
 * @returns {object} - The action object with payload.
 */
const updateUsersBalanceCount = createAction(
  'updateUsersBalanceCount',
  function prepare(value: IUpdateBalanceCount) {
    return {
      payload: { value },
    };
  }
);

/**
 * Creates an action to update the invitations balance count.
 * @param {IUpdateBalanceCount} value - The value to update the balance count.
 * @returns {object} - The action object with payload.
 */
const updateInvitationsBalanceCount = createAction(
  'updateInvitationsBalanceCount',
  function prepare(value: IUpdateBalanceCount) {
    return {
      payload: { value },
    };
  }
);

/**
 * Creates an action to update the competitors balance count.
 * @param {IUpdateBalanceCount} value - The value to update the balance count.
 * @returns {object} - The action object with payload.
 */
const updateCompetitorsBalanceCount = createAction(
  'updateCompetitorsBalanceCount',
  function prepare(value: IUpdateBalanceCount) {
    return {
      payload: { value },
    };
  }
);

/**
 * Creates an action to update the notes balance count.
 * @param {IUpdateBalanceCount} value - The value to update the balance count.
 * @returns {object} - The action object with payload.
 */
const updateNotesBalanceCount = createAction(
  'updateNotesBalanceCount',
  function prepare(value: IUpdateBalanceCount) {
    return {
      payload: { value },
    };
  }
);

/**
 * Creates an action to update the daily keywords balance count.
 * @param {IUpdateBalanceCount} value - The value to update the balance count.
 * @returns {object} - The action object with payload.
 */
const updateDailyKeywordsBalanceCount = createAction(
  'updateDailyKeywordsBalanceCount',
  function prepare(value: IUpdateBalanceCount) {
    return {
      payload: { value },
    };
  }
);

/**
 * Creates an action to update the monthly keywords balance count.
 * @param {IUpdateBalanceCount} value - The value to update the balance count.
 * @returns {object} - The action object with payload.
 */
const updateMonthlyKeywordsBalanceCount = createAction(
  'updateMonthlyKeywordsBalanceCount',
  function prepare(value: IUpdateBalanceCount) {
    return {
      payload: { value },
    };
  }
);

/**
 * Creates an action to update the shared links balance count.
 * @param {IUpdateBalanceCount} value - The value to update the balance count.
 * @returns {object} - The action object with payload.
 */
const updateSharedLinksBalanceCount = createAction(
  'updateSharedLinksBalanceCount',
  function prepare(value: IUpdateBalanceCount) {
    return {
      payload: { value },
    };
  }
);

/**
 * Creates an action to update the projects balance count.
 * @param {IUpdateBalanceCount} value - The value to update the balance count.
 * @returns {object} - The action object with payload.
 */
const updateProjectsBalanceCount = createAction(
  'updateProjectsBalanceCount',
  function prepare(value: IUpdateBalanceCount) {
    return {
      payload: { value },
    };
  }
);

/**
 * Creates an action to open the keywords limit exceeded modal.
 * @param {IKeywordsLimitExceeded} params - The parameters for the modal.
 * @returns {object} - The action object with payload.
 */
const openKeywordsLimitExceededModal = createAction(
  'openKeywordsLimitExceededModal',
  function prepare(params: IKeywordsLimitExceeded) {
    return {
      payload: params,
    };
  }
);

/**
 * Creates an action to close the keywords limit exceeded modal.
 * @returns {object} - The action object.
 */
const closeKeywordsLimitExceededModal = createAction(
  'closeKeywordsLimitExceededModal'
);

/**
 * Creates an action to open the email reports limit exceeded modal.
 * @param {IEmailReportsLimitExceeded} params - The parameters for the modal.
 * @returns {object} - The action object with payload.
 */
const openEmailReportsLimitExceededModal = createAction(
  'openEmailReportsLimitExceededModal',
  function prepare(params: IEmailReportsLimitExceeded) {
    return {
      payload: params,
    };
  }
);

/**
 * Creates an action to close the email reports limit exceeded modal.
 * @returns {object} - The action object.
 */
const closeEmailReportsLimitExceededModal = createAction(
  'closeEmailReportsLimitExceededModal'
);

/**
 * Creates an action to open the shared links limit exceeded modal.
 * @param {ISharedLinksLimitExceeded} params - The parameters for the modal.
 * @returns {object} - The action object with payload.
 */
const openSharedLinksLimitExceededModal = createAction(
  'openSharedLinksLimitExceededModal',
  function prepare(params: ISharedLinksLimitExceeded) {
    return {
      payload: params,
    };
  }
);

/**
 * Creates an action to close the shared links limit exceeded modal.
 * @returns {object} - The action object.
 */
const closeSharedLinksLimitExceededModal = createAction(
  'closeSharedLinksLimitExceededModal'
);

/**
 * Creates an action to open the users limit exceeded modal.
 * @param {IUsersLimitExceeded} params - The parameters for the modal.
 * @returns {object} - The action object with payload.
 */
const openUsersLimitExceededModal = createAction(
  'openUsersLimitExceededModal',
  function prepare(params: IUsersLimitExceeded) {
    return {
      payload: params,
    };
  }
);

/**
 * Creates an action to close the users limit exceeded modal.
 * @returns {object} - The action object.
 */
const closeUsersLimitExceededModal = createAction(
  'closeUsersLimitExceededModal'
);

/**
 * Creates an action to open the notes limit exceeded modal.
 * @param {INotesLimitExceeded} params - The parameters for the modal.
 * @returns {object} - The action object with payload.
 */
const openNotesLimitExceededModal = createAction(
  'openNotesLimitExceededModal',
  function prepare(params: INotesLimitExceeded) {
    return {
      payload: params,
    };
  }
);

/**
 * Creates an action to close the notes limit exceeded modal.
 * @returns {object} - The action object.
 */
const closeNotesLimitExceededModal = createAction(
  'closeNotesLimitExceededModal'
);

/**
 * Creates an action to open the triggers limit exceeded modal.
 * @param {ITriggersLimitExceeded} params - The parameters for the modal.
 * @returns {object} - The action object with payload.
 */
const openTriggersLimitExceededModal = createAction(
  'openTriggersLimitExceededModal',
  function prepare(params: ITriggersLimitExceeded) {
    return {
      payload: params,
    };
  }
);

/**
 * Creates an action to close the triggers limit exceeded modal.
 * @returns {object} - The action object.
 */
const closeTriggersLimitExceededModal = createAction(
  'closeTriggersLimitExceededModal'
);

/**
 * Creates an action to open the competitors limit exceeded modal.
 * @param {ICompetitorsLimitExceeded} params - The parameters for the modal.
 * @returns {object} - The action object with payload.
 */
const openCompetitorsLimitExceededModal = createAction(
  'openCompetitorsLimitExceededModal',
  function prepare(params: ICompetitorsLimitExceeded) {
    return {
      payload: params,
    };
  }
);

/**
 * Creates an action to close the competitors limit exceeded modal.
 * @returns {object} - The action object.
 */
const closeCompetitorsLimitExceededModal = createAction(
  'closeCompetitorsLimitExceededModal'
);

/**
 * Creates an action to open the tags per keyword limit exceeded modal.
 * @param {ITagsPerKeywordLimitExceeded} params - The parameters for the modal.
 * @returns {object} - The action object with payload.
 */
const openTagsPerKeywordLimitExceededModal = createAction(
  'openTagsPerKeywordLimitExceededModal',
  function prepare(params: ITagsPerKeywordLimitExceeded) {
    return {
      payload: params,
    };
  }
);

/**
 * Creates an action to close the tags per keyword limit exceeded modal.
 * @returns {object} - The action object.
 */
const closeTagsPerKeywordLimitExceededModal = createAction(
  'closeTagsPerKeywordLimitExceededModal'
);

/**
 * Creates an action to open the tags per project limit exceeded modal.
 * @param {ITagsPerProjectLimitExceeded} params - The parameters for the modal.
 * @returns {object} - The action object with payload.
 */
const openTagsPerProjectLimitExceededModal = createAction(
  'openTagsPerProjectLimitExceededModal',
  function prepare(params: ITagsPerProjectLimitExceeded) {
    return {
      payload: params,
    };
  }
);

/**
 * Creates an action to close the tags per project limit exceeded modal.
 * @returns {object} - The action object.
 */
const closeTagsPerProjectLimitExceededModal = createAction(
  'closeTagsPerProjectLimitExceededModal'
);

/**
 * Creates an action to open the refreshes limit exceeded modal.
 * @param {IDailyRefreshesLimitExceeded} params - The parameters for the modal.
 * @returns {object} - The action object with payload.
 */
const openRefreshesLimitLimitExceededModal = createAction(
  'openRefreshesLimitLimitExceededModal',
  function prepare(params: IDailyRefreshesLimitExceeded) {
    return {
      payload: params,
    };
  }
);

/**
 * Creates an action to close the refreshes limit exceeded modal.
 * @returns {object} - The action object.
 */
const closeRefreshesLimitExceededModal = createAction(
  'closeRefreshesLimitExceededModal'
);

/**
 * Creates an action to open or close the tariff plan banner.
 * @param {boolean} open - Indicates if the banner is open.
 * @returns {object} - The action object with payload.
 */
const setOpenTariffPlanBanner = createAction(
  'setOpenTariffPlanBanner',
  function prepare(open: boolean) {
    return {
      payload: open,
    };
  }
);

/**
 * Creates an action to set actions required before downgrading.
 * @param {NecessaryRemovalResponse} params - The necessary removal response.
 * @returns {object} - The action object with payload.
 */
const setActionRequiredBeforeDowngrading = createAction(
  'setActionRequiredBeforeDowngrading',
  function prepare(params: NecessaryRemovalResponse) {
    return {
      payload: { ...params },
    };
  }
);

/**
 * Creates an action to set the account billing card.
 * @param {IBillingCard | null} card - The billing card details.
 * @returns {object} - The action object with payload.
 */
const setAccountBillingCard = createAction(
  'setAccountBillingCard',
  function prepare(card: IBillingCard | null) {
    return {
      payload: card,
    };
  }
);

/**
 * Creates an action to reset the billing information.
 * @returns {object} - The action object.
 */
const resetPayment = createAction('onResetBilling');

/**
 * Creates an action to open the cannot add excess keywords modal.
 * @param {ICannotAddExcessKeywords} params - The parameters for the modal.
 * @returns {object} - The action object with payload.
 */
const openCannotAddExcessKeywordsModal = createAction(
  'openCannotAddExcessKeywordsModal',
  function prepare(params: ICannotAddExcessKeywords) {
    return {
      payload: params,
    };
  }
);

/**
 * Creates an action to close the cannot add excess keywords modal.
 * @returns {object} - The action object.
 */
const closeCannotAddExcessKeywordsModal = createAction(
  'closeCannotAddExcessKeywordsModal'
);

/**
 * Creates an action to open the keyword update scheduled modal.
 * @param {IKeywordUpdateScheduled} params - The parameters for the modal.
 * @returns {object} - The action object with payload.
 */
const openKeywordUpdateScheduledModal = createAction(
  'openKeywordUpdateScheduledModal',
  function prepare(params: IKeywordUpdateScheduled) {
    return {
      payload: params,
    };
  }
);

/**
 * Creates an action to close the keyword update scheduled modal.
 * @returns {object} - The action object.
 */
const closeKeywordUpdateScheduledModal = createAction(
  'closeKeywordUpdateScheduledModal'
);

/**
 * Creates an action to open the cannot refresh excess keywords modal.
 * @param {ICannotRefreshExcessKeywords} params - The parameters for the modal.
 * @returns {object} - The action object with payload.
 */
const openCannotRefreshExcessKeywordsModal = createAction(
  'openCannotRefreshExcessKeywordsModal',
  function prepare(params: ICannotRefreshExcessKeywords) {
    return {
      payload: params,
    };
  }
);

/**
 * Creates an action to close the cannot refresh excess keywords modal.
 * @returns {object} - The action object.
 */
const closeCannotRefreshExcessKeywordsModal = createAction(
  'closeCannotRefreshExcessKeywordsModal'
);

/**
 * Creates an action to open the refresh limit reached modal.
 * @param {IYouveReachedYourRefreshLimitForToday} params - The parameters for the modal.
 * @returns {object} - The action object with payload.
 */
const openYouveReachedYourRefreshLimitForToday = createAction(
  'openYouveReachedYourRefreshLimitForToday',
  function prepare(params: IYouveReachedYourRefreshLimitForToday) {
    return {
      payload: params,
    };
  }
);

/**
 * Creates an action to close the refresh limit reached modal.
 * @returns {object} - The action object.
 */
const closeYouveReachedYourRefreshLimitForToday = createAction(
  'closeYouveReachedYourRefreshLimitForToday'
);

/**
 * Creates an action to update user information.
 * @param {User} user - The user information.
 * @returns {object} - The action object with payload.
 */
const updatedUserAction = createAction(
  'updatedUserAction',
  function prepare(user: User) {
    return {
      payload: user,
    };
  }
);

/**
 * Creates an action to get address geolocation.
 * @param {IGetAddressGeolocationActionParams} params - The parameters for address geolocation.
 * @returns {object} - The action object with payload.
 */
const getAddressGeolocationAction = createAction(
  'getAddressGeolocationAction',
  function prepare(params: IGetAddressGeolocationActionParams) {
    return {
      payload: params,
    };
  }
);

/**
 * Creates an action to set the free trial modal state.
 * @param {boolean} value - Indicates if the free trial modal is open.
 * @returns {object} - The action object with payload.
 */
const setFreeTrialModal = createAction(
  'setFreeTrialModal',
  function prepare(value: boolean) {
    return {
      payload: value,
    };
  }
);

/**
 * Creates an action to set the subscription modal state.
 * @param {boolean} value - Indicates if the subscription modal is open.
 * @returns {object} - The action object with payload.
 */
const setSubscriptionModal = createAction(
  'setSubscriptionModal',
  function prepare(value: boolean) {
    return {
      payload: value,
    };
  }
);

/**
 * Creates an action to set the action required before cancelling subscription modal state.
 * @param {boolean} value - Indicates if the action required modal is open.
 * @returns {object} - The action object with payload.
 */
const setActionRequiredCancelSubscriptionModal = createAction(
  'setActionRequiredCancelSubscriptionModal',
  function prepare(value: boolean) {
    return {
      payload: value,
    };
  }
);

/**
 * Creates an action to set the notice account deletion cancelled modal state.
 * @param {boolean} value - Indicates if the notice account deletion cancelled modal is open.
 * @returns {object} - The action object with payload.
 */
const setNoticeAccountDeletionCancelledModal = createAction(
  'setNoticeAccountDeletionCancelledModal',
  function prepare(value: boolean) {
    return {
      payload: value,
    };
  }
);

/**
 * Creates an action to check cancel subscription action.
 * @param {GetBillingResponse} value - The billing response.
 * @returns {object} - The action object with payload.
 */
const checkCancelSubscriptionAction = createAction(
  'checkCancelSubscriptionAction',
  function prepare(value: GetBillingResponse) {
    return {
      payload: value,
    };
  }
);

/**
 * Creates an action to set the folder tree structure.
 * @param {IFolderTree} value - The folder tree structure.
 * @returns {object} - The action object with payload.
 */
const setFolderTreeAction = createAction(
  'setFolderTreeAction',
  function prepare(value: IFolderTree) {
    return {
      payload: value,
    };
  }
);

export {
  setSearchKeywordUsersTeam,
  setSortModelTeam,
  resetAuthState,
  setInvoicesPageSize,
  setInvoicesPaginationPage,
  setSortModelInvoices,
  seOpenSiteBar,
  setDeleteElementsTeamsTable,
  updateEmailReportsBalanceCount,
  updateTriggersBalanceCount,
  updateUsersBalanceCount,
  updateInvitationsBalanceCount,
  updateCompetitorsBalanceCount,
  updateNotesBalanceCount,
  updateDailyKeywordsBalanceCount,
  updateMonthlyKeywordsBalanceCount,
  updateSharedLinksBalanceCount,
  updateProjectsBalanceCount,
  openKeywordsLimitExceededModal,
  closeKeywordsLimitExceededModal,
  openEmailReportsLimitExceededModal,
  closeEmailReportsLimitExceededModal,
  openSharedLinksLimitExceededModal,
  closeSharedLinksLimitExceededModal,
  openUsersLimitExceededModal,
  closeUsersLimitExceededModal,
  openNotesLimitExceededModal,
  closeNotesLimitExceededModal,
  openCompetitorsLimitExceededModal,
  closeCompetitorsLimitExceededModal,
  openTagsPerKeywordLimitExceededModal,
  closeTagsPerKeywordLimitExceededModal,
  openTagsPerProjectLimitExceededModal,
  closeTagsPerProjectLimitExceededModal,
  setOpenTariffPlanBanner,
  setActionRequiredBeforeDowngrading,
  setAccountBillingCard,
  resetPayment,
  openRefreshesLimitLimitExceededModal,
  closeRefreshesLimitExceededModal,
  openCannotAddExcessKeywordsModal,
  closeCannotAddExcessKeywordsModal,
  openKeywordUpdateScheduledModal,
  closeKeywordUpdateScheduledModal,
  openCannotRefreshExcessKeywordsModal,
  closeCannotRefreshExcessKeywordsModal,
  openYouveReachedYourRefreshLimitForToday,
  closeYouveReachedYourRefreshLimitForToday,
  updatedUserAction,
  getAddressGeolocationAction,
  setFreeTrialModal,
  setSubscriptionModal,
  setActionRequiredCancelSubscriptionModal,
  setNoticeAccountDeletionCancelledModal,
  checkCancelSubscriptionAction,
  openTriggersLimitExceededModal,
  closeTriggersLimitExceededModal,
  setFolderTreeAction,
};
