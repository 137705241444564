import React from 'react';
import { Fade, Grid, Modal, useMediaQuery } from '@mui/material';
import {
  ModalContainer,
  ModalWrapper,
} from '../../../features/projects/components/Projects/components/ProjectRedactorModal/styles';
import {
  CloseDeletePopoverOne,
  HeaderContainer,
} from '../../../features/auth/components/Settings/components/Account/styles';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonsWrapper } from '../../../features/auth/components/Settings/components/Team/components/customTableStyles';
import { GrayButtonSmall, PrimaryButtonSmall } from '../../buttons/styles';
import AlertCircleIcon from '../../images/AlertCircleIcon';
import {
  StyledLabel,
  StyledMainText,
} from '../KeywordsLimitExceededModal/styles';
import { StyledErrorList } from './styles';
import { useAppSelector } from '../../../store';
import { Link } from 'react-router-dom';
import ROUTES from '../../../routes/constants';

interface ImportantActionRequiredBeforeDowngradingModalProps {
  onClose: () => void;
  onGotIt: () => void;
  open: boolean;
  productName: string;
}

const ImportantActionRequiredBeforeDowngradingModal: React.FC<
  ImportantActionRequiredBeforeDowngradingModalProps
> = ({ onClose, onGotIt, open, productName }) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const actionRequiredBeforeDowngrading = useAppSelector(
    state => state.auth.actionRequiredBeforeDowngrading
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <ModalWrapper>
          <ModalContainer>
            <HeaderContainer>
              <CloseDeletePopoverOne color='error' onClick={onClose}>
                <CloseIcon fontSize='small' />
              </CloseDeletePopoverOne>
              <AlertCircleIcon />
              <StyledLabel padding='24px 0'>
                Important: Action Required Before Downgrading
              </StyledLabel>
            </HeaderContainer>

            <Grid item xs={12}>
              <StyledMainText>
                To proceed with downgrading your plan, you must first remove
                excess keywords from your projects.
              </StyledMainText>
            </Grid>
            <br />
            <Grid item xs={12}>
              <StyledMainText>
                To downgrade to the <b>{productName}</b>, please complete the
                following actions:
              </StyledMainText>
            </Grid>
            <br />
            <Grid item xs={12}>
              <StyledErrorList>
                {actionRequiredBeforeDowngrading.numberOfKeywords ? (
                  <li>
                    Remove
                    <b>{` ${actionRequiredBeforeDowngrading.numberOfKeywords} keywords.`}</b>
                  </li>
                ) : null}

                {actionRequiredBeforeDowngrading.numberOfTriggers ? (
                  <li>
                    Remove
                    <b>
                      <Link to={ROUTES.alerts}>
                        {` ${actionRequiredBeforeDowngrading.numberOfTriggers} triggers.`}
                      </Link>
                    </b>
                  </li>
                ) : null}

                {actionRequiredBeforeDowngrading.numberOfEmailReports ? (
                  <li>
                    Remove
                    <b>
                      <Link to={ROUTES.scheduledEmailReports}>
                        {` ${actionRequiredBeforeDowngrading.numberOfEmailReports} scheduled email reports.`}
                      </Link>
                    </b>
                  </li>
                ) : null}

                {actionRequiredBeforeDowngrading.numberOfNotes ? (
                  <li>
                    Remove
                    <b>
                      <Link to={ROUTES.notes}>
                        {` ${actionRequiredBeforeDowngrading.numberOfNotes} notes.`}
                      </Link>
                    </b>
                  </li>
                ) : null}
              </StyledErrorList>
            </Grid>
            <ButtonsWrapper container xs={12}>
              <Grid item xs={extraSmallScreen && 12}>
                <GrayButtonSmall
                  fullWidth={extraSmallScreen}
                  size='small'
                  outline
                  onClick={onClose}
                >
                  Cancel
                </GrayButtonSmall>
              </Grid>
              <Grid item xs={extraSmallScreen && 12}>
                <PrimaryButtonSmall
                  fullWidth={extraSmallScreen}
                  variant='contained'
                  size='small'
                  type='submit'
                  onClick={onGotIt}
                >
                  Got it
                </PrimaryButtonSmall>
              </Grid>
            </ButtonsWrapper>
          </ModalContainer>
        </ModalWrapper>
      </Fade>
    </Modal>
  );
};

export default ImportantActionRequiredBeforeDowngradingModal;
