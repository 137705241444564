import { styled } from '@mui/material/styles';
import {
  BreadcrumbsPadding as ProjectBreadcrumbsPadding,
  ContentContainer as ProjectContentContainer
} from "../../../projects/components/Projects/styles";

const ContentContainer = styled(ProjectContentContainer)``;

const BreadcrumbsPadding = styled(ProjectBreadcrumbsPadding)``;

export { ContentContainer, BreadcrumbsPadding };
