import { Box, FormHelperText, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import UploadIcon from '../../../../../../shared/images/UploadIcon';
import { CompanyLogoUploadProps } from './types';
import { CustomFormHelperText } from '../../../../../../shared/components';
import { checkSize } from '../../../../../../utils/imageTolls';

import { LogoUploadContainer } from './styles';

const CompanyLogoUpload: React.FC<CompanyLogoUploadProps> = ({
  companyLogoFile,
  setCompanyLogoFile,
  setErrors,
  value,
  errors,
}) => {
  const [logoImage, setLogoImage] = useState('');

  function handleSetCompanyLogoFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.currentTarget.files) {
      const file = e.currentTarget.files[0];
      if (e.currentTarget.files[0]) {
        const Img = new Image();
        Img.src = URL.createObjectURL(file);

        Img.onload = (e: any) => {
          const height = e?.currentTarget?.height;
          const width = e?.currentTarget?.width;

          if ((width <= 300 || height <= 250) && checkSize(file)) {
            const updateErrors = { ...errors };
            delete updateErrors['companyLogoUrl'];
            setErrors({ ...updateErrors });
          } else {
            setErrors({ companyLogoUrl: '300x250px max. 3MB max.' });
          }

          setCompanyLogoFile(file);
        };
      }
    }
  }

  useEffect(() => {
    if (companyLogoFile) {
      setLogoImage(URL.createObjectURL(companyLogoFile));
    } else {
      setLogoImage('');
    }
  }, [companyLogoFile, setCompanyLogoFile]);

  return (
    <Grid container item xs={12} spacing={2}>
      {(logoImage || value) && (
        <Grid item xs={12} sm={5}>
          <img
            src={logoImage || value}
            alt={'logo'}
            style={{ maxWidth: '100%' }}
          />
          {errors.companyLogoUrl && (
            <FormHelperText error>
              <CustomFormHelperText error={errors.companyLogoUrl || ''} />
            </FormHelperText>
          )}
        </Grid>
      )}
      <Grid item xs={12} sm={7}>
        <label htmlFor='companyLogoUrl'>
          <input
            style={{ display: 'none' }}
            id='companyLogoUrl'
            name='companyLogoUrl'
            type='file'
            accept='image/*'
            onChange={handleSetCompanyLogoFile}
          />
          <LogoUploadContainer
            sx={{
              p: 2,
            }}
          >
            <UploadIcon />
            <Box sx={{ display: 'flex', justifyContent: 'center', pt: '8px' }}>
              <Typography variant='body2' color='#475467'>
                <span style={{ color: '#126FE9' }}>Click to upload</span> or
                drag and drop
              </Typography>
            </Box>
            <Typography variant='body2' color='#475467'>
              SVG, PNG, JPG or GIF
            </Typography>
            <Typography variant='body2' color='#475467'>
              300x250px max. 3MB max.
            </Typography>
          </LogoUploadContainer>
        </label>
      </Grid>
    </Grid>
  );
};

export default CompanyLogoUpload;
