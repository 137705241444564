import React, { useEffect, useMemo, useState } from 'react';
import {
  Line,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Brush,
  Label,
  ComposedChart,
} from 'recharts';
import { ChartContainer } from '../../../../../ImprovedVsDeclined/components/Graph/styled';
import * as Tooltips from './CustomTooltipsForGraph';
import dayjs from 'dayjs';
import { PositionHistoryGraphProps } from '../../types';
import GraphNoData from '../../../../../../../../../../shared/components/graphNoData/GraphNoData';
import { COMPETITORS_COLORS } from '../../../../../../../../../../shared/layout/PrivateLayout/constant';
import { LineGraphSkeleton } from '../../../../../../../../../../shared/graphSkeletons';

const Graph: React.FC<PositionHistoryGraphProps> = ({ keywordAnalytic }) => {
  const [brushData, setBrushData] = useState<{
    endIndex?: number;
    startIndex?: number;
  }>({
    startIndex: 0,
    endIndex: 23,
  });

  const graphData = useMemo(
    () =>
      keywordAnalytic?.keywordPositionHistory
        ? keywordAnalytic.keywordPositionHistory.map(item => ({
            ...item,
            position: item.position,
          }))
        : [],
    [keywordAnalytic?.keywordPositionHistory]
  );

  const graphDataMemo = useMemo(
    () =>
      keywordAnalytic?.competitors
        ? graphData.map(item => {
            if (keywordAnalytic.competitors?.length) {
              keywordAnalytic.competitors.forEach(competitor => {
                const getCompetitorIndex = competitor?.items?.findIndex(
                  competitorItem => competitorItem.date === item.date
                );
                if (competitor?.items[getCompetitorIndex]?.position) {
                  item = {
                    ...item,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    [`competitor${competitor.id}`]:
                      competitor.items[getCompetitorIndex].position,
                  };
                }
              });
            }
            return item;
          })
        : [],
    [keywordAnalytic?.competitors, graphData]
  );

  const competitorsMemo = useMemo(() => {
    return keywordAnalytic?.competitors
      ? keywordAnalytic.competitors.filter(obj =>
          keywordAnalytic.showCompetitors.includes(obj.id)
        )
      : [];
  }, [keywordAnalytic?.competitors, keywordAnalytic?.showCompetitors]);

  const [brushTrigger, setBrushTrigger] = useState(true);

  useEffect(() => {
    if (graphData.length) {
      setBrushData({
        startIndex: Math.max(0, graphData.length - 24),
        endIndex: graphData.length - 1,
      });
      setBrushTrigger(false);
      const timeout = setTimeout(() => {
        setBrushTrigger(true);
      }, 100);

      return () => clearTimeout(timeout);
    }
  }, [graphData]);

  const maxValue = useMemo(
    () => Math.max(...graphData.map(d => d.position)),
    [graphData]
  );

  const maxValueForGraph = useMemo(() => {
    return maxValue > 100 ? 100 : maxValue;
  }, [maxValue]);

  const scale = useMemo(
    () =>
      keywordAnalytic && !keywordAnalytic.isLinearGraph
        ? 'log'
        : maxValue > 100
        ? 'linear'
        : 'auto',
    [keywordAnalytic, maxValue]
  );

  return (
    <ChartContainer>
      <ResponsiveContainer width={'100%'} height={311}>
        {keywordAnalytic ? (
          graphData.length ? (
            <ComposedChart data={brushTrigger ? graphDataMemo : []}>
              <CartesianGrid
                vertical={false}
                stroke='#EDEDED'
                strokeWidth={2}
              />
              <XAxis
                interval={'equidistantPreserveStart'}
                axisLine={false}
                tickLine={false}
                dataKey='date'
                stroke='#D8DAE5'
                dy={5}
                tickFormatter={value => {
                  return dayjs(value).format('D MMM');
                }}
              />
              <YAxis
                allowDecimals={false}
                axisLine={false}
                tickLine={false}
                stroke='#D8DAE5'
                orientation='right'
                scale={scale}
                domain={[1, maxValueForGraph]}
                reversed
                interval='preserveStartEnd'
              >
                <Label
                  value='SERP Position'
                  angle={90}
                  position={'insideRight'}
                  style={{
                    color: '#84818A',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '15px',
                  }}
                />
              </YAxis>

              <Tooltip
                content={<Tooltips.PositionHistory />}
                cursor={{
                  stroke: '#1A73E9',
                  strokeDasharray: '3 3',
                  strokeWidth: '2',
                }}
              />

              <Line
                dataKey='position'
                stroke='#1A73E9'
                isAnimationActive={false}
                strokeWidth={2}
              />

              {competitorsMemo?.length
                ? competitorsMemo.map(competitor => {
                    return (
                      <Line
                        type={'linear'}
                        key={`competitor${competitor.id}`}
                        dataKey={`competitor${competitor.id}`}
                        isAnimationActive={false}
                        stroke={
                          COMPETITORS_COLORS[
                            competitor.id % COMPETITORS_COLORS.length
                          ]
                        }
                        strokeWidth={2}
                      />
                    );
                  })
                : null}

              {keywordAnalytic.keywordPositionHistory.filter(
                item => item.position !== 0
              ).length > 24 ? (
                <Brush
                  dataKey='date'
                  height={40}
                  stroke='#126FE9'
                  startIndex={brushData?.startIndex}
                  endIndex={brushData?.endIndex}
                  travellerWidth={12}
                  tickFormatter={() => ''}
                />
              ) : null}
            </ComposedChart>
          ) : (
            <GraphNoData />
          )
        ) : (
          <LineGraphSkeleton />
        )}
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default React.memo(Graph);
