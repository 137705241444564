import React, { useCallback, useState } from 'react';
import {
  debounce,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ActionsMenu from '../ActionsMenu/ActionsMenu';
import { useNotes, useProjects } from '../../../../../../../../hooks';
import SearchIcon from '../../../../../../../../shared/images/SearchIcon';
import AddNoteModal from '../../../../../../modals/AddNoteModal/AddNoteModal';
import PlusIcon from '../../../../../../../../shared/images/PlusIcon';
import { PrimaryButtonCustom } from '../../../../../../../../shared/buttons/styles';
import { StyledRightTools } from './styles';
import { OutlinedInputCustom } from '../../../../../../../../shared/inputs/styled';
import { useAppSelector } from '../../../../../../../../store';
import useAccountLimits from '../../../../../../../auth/hooks/useAccountLimits';

const Header = () => {
  const {
    onSearchProjectNotes,
    onSetRowSelectionModelProjectNotes,
    tables: {
      projectNotes: { rowSelectionModel, search },
    },
  } = useNotes();
  const { onCheckSubscriptionExpiration } = useAccountLimits();
  const { onGetProjectPerformance } = useProjects();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );
  const activePeriodProjectPerformance = useAppSelector(
    state => state.projects.projectExpandedGraphs.activePeriodProjectPerformance
  );
  const deviceType = useAppSelector(
    state => state.projects.projectExpandedGraphs.deviceType
  );

  const [isOpenEditNoteModal, setEditAddNoteModal] = useState(false);

  const [localSearch, setLocalSearch] = useState(search);

  const isViewOnly =
    !currentAccount?.accountId || currentAccount?.role?.name === 'ViewOnly';

  const debounceSearch = useCallback(
    debounce(value => {
      onSearchProjectNotes(value);
    }, 500),
    [onSearchProjectNotes]
  );

  const handleSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLocalSearch(event.target.value);
    debounceSearch(event.target.value);
  };

  const onOpen = () => {
    const isExpiration = onCheckSubscriptionExpiration();
    if (!isExpiration) {
      setEditAddNoteModal(true);
    }
  };

  const onClose = () => {
    setEditAddNoteModal(false);
  };

  const onDone = () => {
    if (projectExpanded?.id && currentAccount?.accountId && deviceType) {
      onGetProjectPerformance({
        id: currentAccount.accountId,
        projectId: projectExpanded.id,
        period: activePeriodProjectPerformance.value,
        competitorIds: projectExpanded.competitors.map(item =>
          item.id.toString()
        ),
        deviceType: deviceType,
      });
    }
  };

  const onClearSelected = () => {
    onSetRowSelectionModelProjectNotes([]);
  };

  return (
    <Grid xs={12} padding={'24px'}>
      <Grid
        xs={12}
        style={{
          color: '#101828',
          fontSize: '18px',
          fontWeight: '700',
        }}
      >
        Notes
      </Grid>
      <Grid
        item
        container
        xs={12}
        flexDirection={'row'}
        justifyContent={'space-between'}
        paddingTop={'24px'}
      >
        <Grid container item alignItems='center' xs={12} sm={5}>
          {!!rowSelectionModel.length && (
            <>
              <Grid item>
                <IconButton
                  onClick={onClearSelected}
                  sx={{ padding: '0 !important', marginRight: '8px' }}
                >
                  <CloseIcon fontSize='small' />
                </IconButton>
              </Grid>
              <Grid item sx={{ paddingRight: '16px' }}>
                <Typography
                  fontSize='14px'
                  lineHeight='14px'
                  color='#101828'
                  fontWeight='500'
                  alignItems='center'
                >
                  {`${rowSelectionModel.length} Selected`}
                </Typography>
              </Grid>
              <Grid item>
                <ActionsMenu />
              </Grid>
            </>
          )}
        </Grid>
        <StyledRightTools
          container
          item
          alignItems='center'
          xs={12}
          sm={7}
          spacing={2}
        >
          <Grid item>
            <OutlinedInputCustom
              $padding={'7.94px 14px 7.94px 0'}
              size='small'
              type='text'
              id='searchNotes'
              name='searchNotes'
              placeholder='Search notes'
              value={localSearch}
              onChange={handleSearch}
              startAdornment={
                <InputAdornment position='start' disablePointerEvents>
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Grid>
          {!isViewOnly && (
            <Grid item>
              <PrimaryButtonCustom
                variant='contained'
                size='small'
                $padding='8px 12px'
                startIcon={<PlusIcon />}
                onClick={onOpen}
              >
                Add a Note
              </PrimaryButtonCustom>
            </Grid>
          )}
        </StyledRightTools>
      </Grid>
      <AddNoteModal
        open={isOpenEditNoteModal}
        onClose={onClose}
        onDone={onDone}
      />
    </Grid>
  );
};

export default Header;
