import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { useSharedLinks } from '../../../../../../../../hooks';

import CustomCheckboxImage from '../../../../../../../../shared/images/CustomCheckbox';
import CheckedIcon from '../../../../../../../../shared/images/CheckedIcon';

import { CustomFormControlLabel } from './styled';
import { CustomCheckbox } from '../../../../../../../../shared/checkbox/styles';
import * as Tooltips from '../../../Toltips';
import InfoIcon from '../../../../../../../../shared/images/InfoIcon';
import { CustomTooltip } from '../../../../../../../../shared/tooltip/styles';
import { PERIOD } from '../../../../../../../projects/constants';
import { TimesGroup } from '../../../../../../../projects/components';
import { useAppSelector } from '../../../../../../../../store';

const Header = () => {
  const { onSetActivePeriodKeywordTrends, onSetKeywordTrendsVisibilityModel } =
    useSharedLinks();

  const showKeywordTrendsVisibilityModel = useAppSelector(
    state => state.sharedLinks.projectGraphs.showKeywordTrendsVisibilityModel
  );
  const activePeriodKeywordTrends = useAppSelector(
    state => state.sharedLinks.projectGraphs.activePeriodKeywordTrends
  );

  const timesGroupItems = useMemo(() => {
    return PERIOD.map(period => ({
      label: period.label,
      value: period.value,
      isActive: period.value === activePeriodKeywordTrends.value,
      onChange: () => onSetActivePeriodKeywordTrends(period),
    }));
  }, [activePeriodKeywordTrends.value, onSetActivePeriodKeywordTrends]);

  return (
    <Grid
      item
      container
      sx={{ position: 'relative', marginBottom: '35px' }}
      xs={12}
    >
      <Grid xs={12}>
        <CustomTooltip
          title={<Tooltips.KeywordsTrends />}
          placement='top-start'
          arrow
        >
          <strong>
            <span
              style={{
                color: '#101828',
                fontSize: '18px',
                fontWeight: '700',
              }}
            >
              Keyword Trends <InfoIcon />
            </span>
          </strong>
        </CustomTooltip>

        <Grid container item xs={12} flexWrap={'wrap-reverse'}>
          <Grid container item xs={12} sm={8} sx={{ mt: '16px' }}>
            <Grid item>
              <CustomFormControlLabel
                control={
                  <CustomCheckbox
                    checked={showKeywordTrendsVisibilityModel.top3}
                    onChange={event => {
                      onSetKeywordTrendsVisibilityModel(
                        'top3',
                        event.target.checked
                      );
                    }}
                    icon={<CustomCheckboxImage />}
                    checkedIcon={<CheckedIcon checkedColor='#AAE247' />}
                  />
                }
                label='Top 3'
              />
            </Grid>
            <Grid item>
              <CustomFormControlLabel
                control={
                  <CustomCheckbox
                    checked={showKeywordTrendsVisibilityModel['top4-10']}
                    onChange={event => {
                      onSetKeywordTrendsVisibilityModel(
                        'top4-10',
                        event.target.checked
                      );
                    }}
                    icon={<CustomCheckboxImage />}
                    checkedIcon={<CheckedIcon checkedColor='#58B0F1' />}
                  />
                }
                label='Top 4-10'
              />
            </Grid>
            <Grid item>
              <CustomFormControlLabel
                control={
                  <CustomCheckbox
                    checked={showKeywordTrendsVisibilityModel['top11-20']}
                    onChange={event => {
                      onSetKeywordTrendsVisibilityModel(
                        'top11-20',
                        event.target.checked
                      );
                    }}
                    icon={<CustomCheckboxImage />}
                    checkedIcon={<CheckedIcon checkedColor='#FFAB44' />}
                  />
                }
                label='Top 11-20'
              />
            </Grid>
            <Grid item>
              <CustomFormControlLabel
                control={
                  <CustomCheckbox
                    checked={showKeywordTrendsVisibilityModel['top21-50']}
                    onChange={event => {
                      onSetKeywordTrendsVisibilityModel(
                        'top21-50',
                        event.target.checked
                      );
                    }}
                    icon={<CustomCheckboxImage />}
                    checkedIcon={<CheckedIcon checkedColor='#D160D2' />}
                  />
                }
                label='Top 21-50'
              />
            </Grid>
            <Grid item>
              <CustomFormControlLabel
                control={
                  <CustomCheckbox
                    checked={showKeywordTrendsVisibilityModel['top51-100']}
                    onChange={event => {
                      onSetKeywordTrendsVisibilityModel(
                        'top51-100',
                        event.target.checked
                      );
                    }}
                    icon={<CustomCheckboxImage />}
                    checkedIcon={<CheckedIcon checkedColor='#1E406B' />}
                  />
                }
                label='Top 51-100'
              />
            </Grid>
            <Grid item>
              <CustomFormControlLabel
                control={
                  <CustomCheckbox
                    checked={showKeywordTrendsVisibilityModel.notRanked}
                    onChange={event => {
                      onSetKeywordTrendsVisibilityModel(
                        'notRanked',
                        event.target.checked
                      );
                    }}
                    icon={<CustomCheckboxImage />}
                    checkedIcon={<CheckedIcon checkedColor='#AEA5E0' />}
                  />
                }
                label='Not ranked'
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={4}
            sx={{
              mt: '16px',
              alignItems: 'center',
              justifyContent: 'flex-end',
              '@media (max-width:600px)': {
                justifyContent: 'flex-start',
              },
            }}
          >
            <TimesGroup items={timesGroupItems} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
