import React, { useMemo, useState } from 'react';
import {
  Fade,
  Grid,
  Modal,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { IDeleteFolderModalProps } from './types';
import { closeAndUndoSnackbarAction } from '../../../../../../../../utils/snackbar';
import { useAuth, useFolders } from '../../../../../../../../hooks';
import { enqueueSnackbar, SnackbarKey } from 'notistack';

import Trash from '../../../../../../../../shared/images/Trash';
import CustomToast from '../../../../../../../../shared/CustomToast/CustomToast';

import { ButtonsWrapper } from '../../../../../../../auth/components/Settings/components/Team/components/customTableStyles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../../../../shared/buttons/styles';
import {
  ModalContainer,
  ModalWrapper,
} from '../../../../../../../../shared/modal/styled';
import { CloseDeletePopoverOne } from '../../../../../../../auth/components/Settings/components/Account/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from '../../../../../../../../store';

const DeleteFolderModal: React.FC<IDeleteFolderModalProps> = ({
  itemId,
  itemName,
  itemType,
  open,
  handleClose,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const [deleteText, setDeleteText] = useState('');

  const { onGetMyAccount, onGetAccountLimit } = useAuth();

  const folderTree = useAppSelector(state => state.auth.myAccount.folderTree);
  const myAccount = useAppSelector(state => state.auth.myAccount);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const selectedTags = useAppSelector(state => state.projects.selectedTags);

  const {
    onSetDeleteElementsRootFoldersTable,
    currentFolder,
    onDeletionOfFolderContents,
    onGetFoldersById,
    onGetRootFolders,
    onGetAvailableFolders,
    isRootView,
    tables: {
      rootFolder: {
        paginationModel,
        search,
        sortModel,
        rowSelectionModel,
        rootFolderColumnVisibilityModel,
        childrenFolderColumnVisibilityModel,
      },
    },
    folders,
  } = useFolders();

  const columnVisibilityModel = useMemo(
    () =>
      isRootView
        ? rootFolderColumnVisibilityModel
        : childrenFolderColumnVisibilityModel,
    [
      childrenFolderColumnVisibilityModel,
      isRootView,
      rootFolderColumnVisibilityModel,
    ]
  );

  const onClose = () => {
    handleClose();
  };

  const deleteFolder = useMemo(() => {
    if (itemId && folders?.items) {
      return folders.items.find(item => item.id === itemId);
    }
  }, [folders.items, itemId]);

  const currentFolderId = isRootView ? folderTree?.id : currentFolder?.id;

  const textContainer = useMemo(() => {
    if (itemType === 'project') {
      return (
        <Typography
          fontSize='14px'
          fontWeight='400'
          lineHeight='24px'
          color='#475467'
          marginTop='24px'
        >
          This action is irreversible, and all associated data will be
          permanently erased.
        </Typography>
      );
    } else {
      return (
        <>
          <Typography
            fontSize='14px'
            fontWeight='700'
            lineHeight='24px'
            color='#475467'
            marginTop='24px'
          >
            Deleting the selected folder(s) will permanently remove all
            projects, folders, and associated data contained within them from
            your account and cannot be recovered.
          </Typography>
          <Typography
            fontSize='14px'
            fontWeight='400'
            lineHeight='24px'
            color='#475467'
            marginTop='24px'
          >
            Before proceeding, ensure any projects and folders you want to
            retain are moved out of the selected folder(s).
          </Typography>
        </>
      );
    }
  }, [itemType]);

  const handleHardDelete = () => {
    if (currentFolderId) {
      let foldersIds = [] as number[];
      let projectsIds = [] as number[];
      if (rowSelectionModel.length > 0) {
        if (!('type' in rowSelectionModel[0])) {
          foldersIds = rowSelectionModel.map(item => item.id);
        } else {
          foldersIds = rowSelectionModel
            .filter(item => item.type === 'folder')
            .map(item => item.id);
          projectsIds = rowSelectionModel
            .filter(item => item.type === 'project')
            .map(item => item.id);
        }
      }
      onDeletionOfFolderContents({
        accountId: currentAccount.accountId,
        folderId: currentFolderId,
        data: {
          folderIds: itemType === 'folder' && itemId ? [itemId] : foldersIds,
          projectIds: itemType === 'project' && itemId ? [itemId] : projectsIds,
        },
        successFn: () => {
          isRootView
            ? onGetRootFolders({
                accountId: currentAccount.accountId,
                params: {
                  page: paginationModel.page,
                  limit: paginationModel.pageSize,
                  search: search,
                  tagIds: selectedTags.map(item => item.id).join(),
                  sortBy: sortModel[0].field,
                  sortOrder: sortModel[0].sort,
                },
              })
            : onGetFoldersById({
                accountId: currentAccount.accountId,
                folderId: currentFolderId,
                params: {
                  page: paginationModel.page,
                  limit: paginationModel.pageSize,
                  search: search,
                  tagIds: selectedTags.map(item => item.id).join(),
                  dailyAverage: columnVisibilityModel.dailyAverage,
                },
              });
          onGetMyAccount(currentAccount.accountId);
          onGetAvailableFolders({ accountId: myAccount.id });
          onGetAccountLimit({ id: currentAccount.accountId });
        },
      });
    }
  };

  const handleDeleteFolder = () => {
    onSetDeleteElementsRootFoldersTable(
      deleteFolder ? [deleteFolder] : rowSelectionModel
    );

    handleClose();
    let undoDelete = false;
    const onUndo = () => {
      undoDelete = true;
      onSetDeleteElementsRootFoldersTable([]);
    };
    enqueueSnackbar(
      <CustomToast
        message={`${rowSelectionModel.length > 1 ? 'Items' : 'Item'} Removed`}
        submessage={`${
          rowSelectionModel.length > 1
            ? 'Items'
            : `Item ${itemName || rowSelectionModel[0].name}`
        } has been successfully removed.`}
      />,
      {
        action: (snackbarId: SnackbarKey) =>
          closeAndUndoSnackbarAction(snackbarId, onUndo),
        variant: 'error',
      }
    );
    setTimeout(() => {
      !undoDelete && handleHardDelete();
    }, 5000);
  };

  return (
    <Modal
      aria-labelledby='folder-or-project-delete-modal-title'
      aria-describedby='folder-or-project-delete--modal-description'
      open={open}
      onClose={onClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <ModalWrapper sx={{ width: '386px' }}>
          <ModalContainer container xs={12} sx={{ gap: '24px' }}>
            <Grid item xs={12} sx={{ position: 'relative' }}>
              <CloseDeletePopoverOne onClick={onClose}>
                <CloseIcon fontSize='small' />
              </CloseDeletePopoverOne>
              <Grid display='flex' justifyContent='center'>
                <Trash width='40' height='40' />
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ gap: '16px' }}>
              <Typography
                fontSize='18px'
                fontWeight='600'
                lineHeight='28px'
                color='#101828'
                textAlign='center'
              >
                Delete items?
              </Typography>
              <Typography
                fontSize='14px'
                fontWeight='400'
                lineHeight='24px'
                color='#475467'
                marginTop='16px'
              >
                Are you sure you want to delete the selected item(s)?
              </Typography>
              {textContainer}
            </Grid>

            <Grid item xs={12} paddingTop='4px'>
              <Grid
                container
                fontWeight='500'
                alignItems='center'
                fontSize='14px'
                gap='6px'
              >
                <Grid item color='#475467'>
                  Please type &lsquo;DELETE&lsquo; in the box below to confirm.
                </Grid>
                <OutlinedInput
                  size='small'
                  fullWidth
                  type='text'
                  id='folderName'
                  placeholder=''
                  value={deleteText}
                  onChange={e => {
                    setDeleteText(e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <ButtonsWrapper
              container
              xs={12}
              sx={{ paddingTop: '8px !important' }}
            >
              <Grid item xs={extraSmallScreen && 12}>
                <GrayButtonSmall
                  fullWidth={extraSmallScreen}
                  size='small'
                  outline
                  onClick={handleClose}
                >
                  Cancel
                </GrayButtonSmall>
              </Grid>
              <Grid item xs={extraSmallScreen && 12}>
                <PrimaryButtonSmall
                  fullWidth={extraSmallScreen}
                  variant='contained'
                  size='small'
                  type='submit'
                  disabled={deleteText.toLowerCase() !== 'delete'}
                  onClick={handleDeleteFolder}
                  sx={{
                    background: '#D92D20',
                    '&.Mui-disabled': {
                      background: 'rgba(217,45,32,0.6)',
                    },
                    '&.MuiButton-root:hover': {
                      background: '#D92D20',
                    },
                  }}
                >
                  Delete
                </PrimaryButtonSmall>
              </Grid>
            </ButtonsWrapper>
          </ModalContainer>
        </ModalWrapper>
      </Fade>
    </Modal>
  );
};

export default DeleteFolderModal;
