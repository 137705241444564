import React, { useEffect } from 'react';

import { Header, TableProjects, WelcomeProject } from './components';
import { useProjects } from '../../../../hooks';
import Breadcrumbs from '../../../../shared/Breadcrumbs/Breadcrumbs';

import { ContentContainer, BreadcrumbsPadding } from './styles';
import { getUpdatedProjectsToServer } from '../../socket';
import { arraysAreEqual } from '../../../../utils';
import { useAppSelector } from '../../../../store';

const Projects = () => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const accountLimit = useAppSelector(state => state.auth.accountLimit);

  const { onUpdateProjects, onSetRowSelectionModel, onGetProjects } =
    useProjects();

  const columnVisibilityModel = useAppSelector(
    state => state.projects.tables.projects.columnVisibilityModel
  );
  const rowSelectionModel = useAppSelector(
    state => state.projects.tables.projects.rowSelectionModel
  );
  const frequencyFilter = useAppSelector(
    state => state.projects.tables.projects.frequencyFilter
  );
  const sortModel = useAppSelector(
    state => state.projects.tables.projects.sortModel
  );
  const search = useAppSelector(state => state.projects.tables.projects.search);
  const paginationModel = useAppSelector(
    state => state.projects.tables.projects.paginationModel
  );
  const projects = useAppSelector(state => state.projects.projects);
  const selectedTags = useAppSelector(state => state.projects.selectedTags);

  useEffect(() => {
    if (currentAccount.accountId) {
      onGetProjects({
        accountId: currentAccount.accountId,
        params: {
          frequencyName: frequencyFilter,
          page: paginationModel.page,
          limit: paginationModel.pageSize,
          search: search,
          sortBy: sortModel[0]?.field,
          sortOrder: sortModel[0]?.sort,
          tagIds: selectedTags.map(item => item.id).join(),
          dailyAverage: columnVisibilityModel.dailyAverage,
        },
      });
    }
  }, [
    frequencyFilter,
    currentAccount.accountId,
    onGetProjects,
    paginationModel.page,
    paginationModel.pageSize,
    search,
    sortModel,
    selectedTags,
    columnVisibilityModel.dailyAverage,
  ]);

  useEffect(() => {
    if (currentAccount?.accountId) {
      getUpdatedProjectsToServer(currentAccount.accountId, onUpdateProjects);
    }
  }, [currentAccount?.accountId, onUpdateProjects]);

  useEffect(() => {
    const filtered = projects?.items
      ?.filter(obj => rowSelectionModel.includes(obj.id))
      ?.map(item => item.id);
    if (filtered && !arraysAreEqual(rowSelectionModel, filtered)) {
      onSetRowSelectionModel(filtered);
    }
  }, [onSetRowSelectionModel, projects?.items, rowSelectionModel]);

  useEffect(() => {
    return () => onSetRowSelectionModel([]);
  }, [onSetRowSelectionModel]);

  if (
    accountLimit?.accountLimitsUsed &&
    !accountLimit.accountLimitsUsed.numberOfProjects
  ) {
    return (
      <ContentContainer container>
        <WelcomeProject />
      </ContentContainer>
    );
  }

  return (
    <ContentContainer container>
      <BreadcrumbsPadding>
        <Breadcrumbs />
      </BreadcrumbsPadding>
      <Header />
      <TableProjects />
    </ContentContainer>
  );
};

export default Projects;
