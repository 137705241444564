import React from 'react';

const SerpNestLogo: React.FC = () => {
  return (
    <svg
      width='124'
      height='24'
      viewBox='0 0 124 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2426_174435)'>
        <path
          d='M23.7406 16.9787C22.8379 16.5684 22.1198 16.0093 21.5915 15.3066C21.0631 14.6038 20.7759 13.8242 20.7246 12.9676H24.269C24.3356 13.5062 24.5972 13.9473 25.0589 14.3012C25.5205 14.6551 26.095 14.8295 26.7823 14.8295C27.4696 14.8295 27.9774 14.6962 28.3519 14.4294C28.7315 14.1627 28.9161 13.8191 28.9161 13.3985C28.9161 12.9471 28.6853 12.6085 28.2237 12.3829C27.762 12.1572 27.0285 11.911 26.0232 11.6391C24.9819 11.3878 24.1356 11.1262 23.4739 10.8595C22.8122 10.5927 22.2429 10.1824 21.7659 9.62842C21.2888 9.07446 21.0478 8.33071 21.0478 7.39205C21.0478 6.62265 21.2683 5.91994 21.7146 5.27878C22.1608 4.64274 22.7968 4.14007 23.6227 3.77076C24.4485 3.40145 25.4282 3.2168 26.5515 3.2168C28.2083 3.2168 29.5316 3.63227 30.5216 4.46322C31.5115 5.29416 32.0552 6.41235 32.1527 7.81778H28.7827C28.7315 7.26381 28.5006 6.8227 28.0903 6.49955C27.6799 6.17127 27.1311 6.00714 26.4438 6.00714C25.8078 6.00714 25.3153 6.12511 24.9717 6.36106C24.628 6.59701 24.4587 6.92015 24.4587 7.34076C24.4587 7.81265 24.6947 8.16657 25.1615 8.40765C25.6282 8.64873 26.3566 8.90006 27.3465 9.1514C28.3519 9.40273 29.1828 9.66433 29.8343 9.93105C30.4857 10.1978 31.055 10.6132 31.5321 11.1775C32.0091 11.7366 32.2553 12.4803 32.2758 13.4036C32.2758 14.2089 32.0552 14.927 31.609 15.563C31.1627 16.1991 30.5267 16.6966 29.7009 17.0608C28.8751 17.425 27.9056 17.5994 26.7977 17.5994C25.6898 17.5994 24.6383 17.3942 23.7304 16.9838L23.7406 16.9787Z'
          fill='#101828'
        />
        <path
          d='M47.0188 11.4584H36.8422C36.9243 12.4637 37.2782 13.2536 37.8988 13.823C38.5195 14.3923 39.2838 14.6796 40.1865 14.6796C41.4945 14.6796 42.4229 14.1205 42.9769 12.9972H46.7725C46.3725 14.3359 45.5979 15.4387 44.4592 16.3004C43.3205 17.1621 41.9202 17.593 40.2635 17.593C38.9247 17.593 37.7193 17.2955 36.6576 16.7005C35.5958 16.1055 34.7648 15.2643 34.1698 14.1769C33.5748 13.0895 33.2773 11.8328 33.2773 10.4069C33.2773 8.98091 33.5697 7.69858 34.1545 6.61117C34.7392 5.52376 35.5599 4.68255 36.6165 4.09781C37.6732 3.51307 38.8888 3.2207 40.2635 3.2207C41.6381 3.2207 42.7717 3.50794 43.8181 4.0773C44.8644 4.64665 45.68 5.45708 46.2545 6.50346C46.8341 7.54983 47.1213 8.75522 47.1213 10.1094C47.1213 10.612 47.0854 11.0634 47.0188 11.4686V11.4584ZM43.4744 9.09375C43.459 8.191 43.1307 7.46263 42.4947 6.91893C41.8587 6.37522 41.079 6.10337 40.1557 6.10337C39.2838 6.10337 38.5503 6.36496 37.9553 6.89328C37.3603 7.4216 36.9961 8.15509 36.8627 9.09375H43.4744Z'
          fill='#101828'
        />
        <path
          d='M53.9621 3.87187C54.6905 3.45127 55.5266 3.24609 56.4652 3.24609V6.93919H55.5368C54.4289 6.93919 53.598 7.20078 53.0337 7.71884C52.4746 8.2369 52.1925 9.14479 52.1925 10.4322V17.3671H48.6738V3.44101H52.1925V5.60044C52.6439 4.86182 53.2338 4.28734 53.9621 3.86674V3.87187Z'
          fill='#101828'
        />
        <path
          d='M63.2151 3.85796C64.0102 3.43223 64.918 3.2168 65.9439 3.2168C67.1339 3.2168 68.2111 3.50917 69.1754 4.09391C70.1397 4.67865 70.8988 5.51472 71.463 6.59701C72.0221 7.67929 72.3043 8.93084 72.3043 10.3568C72.3043 11.7827 72.0221 13.0445 71.463 14.1371C70.904 15.2347 70.1397 16.0862 69.1754 16.6863C68.2111 17.2916 67.1339 17.5891 65.9439 17.5891C64.9232 17.5891 64.0204 17.3788 63.2408 16.9582C62.4611 16.5376 61.8302 16.0144 61.3429 15.3732V23.9956H57.8242V3.44249H61.3429V5.45317C61.7943 4.81714 62.4201 4.28369 63.2151 3.85796ZM68.1905 8.18196C67.8469 7.57157 67.3955 7.10481 66.8313 6.78679C66.2722 6.46877 65.6618 6.30977 65.0104 6.30977C64.359 6.30977 63.7742 6.4739 63.2151 6.80218C62.656 7.13045 62.1995 7.60235 61.8558 8.22299C61.5122 8.84364 61.3429 9.572 61.3429 10.4081C61.3429 11.2442 61.5122 11.9725 61.8558 12.5932C62.1995 13.2138 62.6509 13.6857 63.2151 14.014C63.7742 14.3422 64.3743 14.5064 65.0104 14.5064C65.6464 14.5064 66.2722 14.3371 66.8313 14.0037C67.3904 13.6703 67.8469 13.1933 68.1905 12.5726C68.5342 11.952 68.7035 11.2134 68.7035 10.3619C68.7035 9.51045 68.5342 8.79748 68.1905 8.18709V8.18196Z'
          fill='#101828'
        />
        <path
          d='M85.5952 4.81175C86.616 5.85813 87.1289 7.31998 87.1289 9.19731V17.3632H83.6102V9.67433C83.6102 8.5664 83.3332 7.72007 82.7792 7.12507C82.2253 6.53007 81.4713 6.23257 80.5172 6.23257C79.5632 6.23257 78.7784 6.53007 78.2193 7.12507C77.6602 7.72007 77.3781 8.57153 77.3781 9.67433V17.3632H73.8594V3.44223H77.3781V5.17593C77.8448 4.57068 78.445 4.09878 79.1733 3.75512C79.9017 3.41145 80.7019 3.24219 81.5738 3.24219C83.2306 3.24219 84.5745 3.76538 85.5952 4.81175Z'
          fill='#101828'
        />
        <path
          d='M102.306 11.4584H92.1293C92.2114 12.4637 92.5653 13.2536 93.186 13.823C93.8066 14.3923 94.5709 14.6796 95.4736 14.6796C96.7816 14.6796 97.71 14.1205 98.264 12.9972H102.06C101.66 14.3359 100.885 15.4387 99.7463 16.3004C98.6076 17.1621 97.2073 17.593 95.5506 17.593C94.2118 17.593 93.0064 17.2955 91.9447 16.7005C90.8829 16.1055 90.0519 15.2643 89.4569 14.1769C88.8619 13.0895 88.5645 11.8328 88.5645 10.4069C88.5645 8.98091 88.8568 7.69858 89.4416 6.61117C90.0263 5.52376 90.847 4.68255 91.9036 4.09781C92.9603 3.51307 94.1759 3.2207 95.5506 3.2207C96.9252 3.2207 98.0588 3.50794 99.1052 4.0773C100.152 4.64665 100.967 5.45708 101.542 6.50346C102.121 7.54983 102.408 8.75522 102.408 10.1094C102.408 10.612 102.373 11.0634 102.306 11.4686V11.4584ZM98.7615 9.09375C98.7461 8.191 98.4179 7.46263 97.7818 6.91893C97.1458 6.37522 96.3661 6.10337 95.4429 6.10337C94.5709 6.10337 93.8374 6.36496 93.2424 6.89328C92.6474 7.4216 92.2832 8.15509 92.1498 9.09375H98.7615Z'
          fill='#101828'
        />
        <path
          d='M106.231 16.9787C105.328 16.5684 104.61 16.0093 104.082 15.3066C103.553 14.6038 103.266 13.8242 103.215 12.9676H106.759C106.826 13.5062 107.087 13.9473 107.549 14.3012C108.011 14.6551 108.585 14.8295 109.273 14.8295C109.96 14.8295 110.468 14.6962 110.842 14.4294C111.222 14.1627 111.406 13.8191 111.406 13.3985C111.406 12.9471 111.176 12.6085 110.714 12.3829C110.252 12.1572 109.519 11.911 108.513 11.6391C107.472 11.3878 106.626 11.1262 105.964 10.8595C105.302 10.5927 104.733 10.1824 104.256 9.62842C103.779 9.07446 103.538 8.33071 103.538 7.39205C103.538 6.62265 103.759 5.91994 104.205 5.27878C104.651 4.64274 105.287 4.14007 106.113 3.77076C106.939 3.40145 107.918 3.2168 109.042 3.2168C110.699 3.2168 112.022 3.63227 113.012 4.46322C114.002 5.29416 114.545 6.41235 114.643 7.81778H111.273C111.222 7.26381 110.991 6.8227 110.581 6.49955C110.17 6.17127 109.621 6.00714 108.934 6.00714C108.298 6.00714 107.806 6.12511 107.462 6.36106C107.118 6.59701 106.949 6.92015 106.949 7.34076C106.949 7.81265 107.185 8.16657 107.652 8.40765C108.118 8.64873 108.847 8.90006 109.837 9.1514C110.842 9.40273 111.673 9.66433 112.324 9.93105C112.976 10.1978 113.545 10.6132 114.022 11.1775C114.499 11.7366 114.746 12.4803 114.766 13.4036C114.766 14.2089 114.545 14.927 114.099 15.563C113.653 16.1991 113.017 16.6966 112.191 17.0608C111.365 17.425 110.396 17.5994 109.288 17.5994C108.18 17.5994 107.129 17.3942 106.221 16.9838L106.231 16.9787Z'
          fill='#101828'
        />
        <path
          d='M120.757 6.33469V13.0695C120.757 13.5414 120.869 13.8799 121.095 14.0851C121.321 14.2954 121.7 14.3979 122.239 14.3979H123.875V17.3627H121.664C118.7 17.3627 117.217 15.9214 117.217 13.0387V6.33469H115.561V3.44176H117.217V0H120.762V3.44176H123.88V6.33469H120.762H120.757Z'
          fill='#101828'
        />
        <path
          d='M12.9133 3.58486C11.8413 5.02106 11.1591 6.74451 10.995 8.62696H2.30078C2.38285 5.06723 5.2963 2.20508 8.87655 2.20508C10.4 2.20508 11.7951 2.72314 12.9133 3.58486Z'
          fill='#126FE9'
        />
        <path
          d='M7.77104 6.46278C9.32522 6.46278 9.33035 4.04688 7.77104 4.04688C6.21174 4.04688 6.21174 6.46278 7.77104 6.46278Z'
          fill='white'
        />
        <path
          d='M14.1024 12.3564C14.1024 16.5265 10.9428 19.9118 7.04962 19.9118C3.15648 19.9118 0.00195312 16.5265 0.00195312 12.3564C0.00195312 11.469 0.140444 10.6227 0.417427 9.83789H13.6921C13.9639 10.6227 14.1075 11.469 14.1075 12.3564H14.1024Z'
          fill='#126FE9'
        />
      </g>
      <defs>
        <clipPath id='clip0_2426_174435'>
          <rect width='123.873' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SerpNestLogo;
