import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { CustomFormHelperText } from '../../../../../../../shared/components';
import { FormItem } from '../../../styles';
import {
  GrayButtonSmall,
  NoBorderButton,
  PrimaryButtonSmall,
} from '../../../../../../../shared/buttons/styles';
import { AccountPasswordFormProps } from '../types';
import { useAuth } from '../../../../../../../hooks';
import DeleteAccount from '../DeleteAccount';
import ActionRequiredToast from './ActionRequiredToast/ActionRequiredToast';
import RemoveAccessModal from '../../../../../modals/RemoveAccessModal/RemoveAccessModal';
import { useAppSelector } from '../../../../../../../store';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { PasswordStateLabel } from '../../../../RegisterForm/styles';

const AccountChangeEmailForm: React.FC<AccountPasswordFormProps> = formik => {
  const { onRemoveAccessAccount } = useAuth();

  const isAuth = useAppSelector(state => state.auth.isAuth);
  const isLoading = useAppSelector(state => state.auth.isLoading);
  const isLoadingChangeAccount = useAppSelector(
    state => state.auth.isLoadingChangeAccount
  );
  const myAccount = useAppSelector(state => state.auth.myAccount);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const user = useAppSelector(state => state.auth.user);

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openRemoveAccessModal, setOpenRemoveAccessModal] =
    React.useState(false);

  const [showEnterYourPassword, setShowEnterYourPassword] =
    useState<boolean>(false);

  // const passwordExists = useMemo(() => !!user && user.passwordExists, [user]);
  const passwordExists = false;

  const handleClickShowEnterYourPassword = () =>
    setShowEnterYourPassword(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClick = () => {
    setOpenDeleteModal(previousOpen => !previousOpen);
  };

  const handleOpenRemoveAccessModal = () => {
    setOpenRemoveAccessModal(previousOpen => !previousOpen);
  };

  const handleRemoveAccessAccount = () => {
    onRemoveAccessAccount({
      accountId: currentAccount.accountId,
      successFn: () => {
        console.log('success delete Accesses');
      },
    });
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleCloseRemoveAccessModal = () => {
    setOpenRemoveAccessModal(false);
  };

  const [readOnlyNewEmail, setReadOnlyNewEmail] = useState(true);

  useEffect(() => {
    if (!passwordExists) {
      formik.setFieldValue('confirmationPassword', null);
    }
  }, [passwordExists]);

  return (
    <>
      <Grid container>
        <Grid
          container
          item
          sx={{ borderBottom: '1px solid #EAECF0', pt: 4, pb: 4 }}
        >
          <Grid item lg={12}>
            <Box>
              <Typography
                variant='h6'
                fontWeight='600'
                color='#101828'
                fontSize='18px'
                lineHeight='28px'
              >
                Change Email
              </Typography>
            </Box>
            <Box>
              <Typography
                variant='body2'
                color='#475467'
                fontSize='14px'
                lineHeight='20px'
              >
                Update your email address below.
              </Typography>
            </Box>
            {formik.touched.changeEmailMessage &&
              formik.errors.changeEmailMessage && (
                <FormHelperText error id='changeEmailMessage'>
                  <CustomFormHelperText
                    error={formik.errors.changeEmailMessage}
                  />
                </FormHelperText>
              )}
          </Grid>
        </Grid>
        <FormItem container item sx={{ pt: 4, pb: 4 }}>
          <Grid item lg={2} sm={4} xs={12}>
            <Typography variant='body2' fontWeight='500' color='#344054'>
              Current email
            </Typography>
          </Grid>
          <Grid container item lg={7.5} sm={8} xs={12}>
            <>
              <OutlinedInput
                fullWidth
                disabled
                type={'currentEmail'}
                size={'small'}
                id='currentPassword'
                placeholder='Current email'
                name='currentPassword'
                value={formik.values.currentEmail}
              />
              {formik.touched.currentEmail && formik.errors.currentEmail && (
                <FormHelperText error id='currentEmail'>
                  <CustomFormHelperText error={formik.errors.currentEmail} />
                </FormHelperText>
              )}
            </>
          </Grid>
        </FormItem>
        <Grid marginTop={'32px'} xs={12}>
          <ActionRequiredToast />
        </Grid>
        <FormItem container item sx={{ pt: 4, pb: 4 }}>
          <Grid item lg={2} sm={4} xs={12}>
            <Typography variant='body2' fontWeight='500' color='#344054'>
              New email
            </Typography>
          </Grid>
          <Grid container item lg={7.5} sm={8} xs={12} flexDirection={'column'}>
            <OutlinedInput
              fullWidth
              type={'none'}
              size={'small'}
              id='newEmail'
              placeholder='New email'
              name='newEmail'
              readOnly={readOnlyNewEmail}
              autoComplete='new-email'
              onFocus={() => setReadOnlyNewEmail(false)}
              onBlur={event => {
                setReadOnlyNewEmail(true);
                formik.handleBlur(event);
              }}
              value={formik.values.newEmail}
              onChange={formik.handleChange}
              error={!!(formik.touched.newEmail && formik.errors.newEmail)}
            />
            {formik.values.newEmail && !formik.errors.newEmail ? (
              <Grid>
                <FormHelperText>
                  <Typography variant='body2' color='#666666'>
                    A verification email will be sent to your current and new
                    email addresses.
                  </Typography>
                </FormHelperText>
              </Grid>
            ) : null}
            <Grid>
              {formik.touched.newEmail && formik.errors.newEmail && (
                <FormHelperText error id='newPassword'>
                  <CustomFormHelperText error={formik.errors.newEmail} />
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </FormItem>
        {passwordExists && (
          <FormItem container item sx={{ pt: 4, pb: 4 }}>
            <Grid item lg={2} sm={4} xs={12}>
              <Typography variant='body2' fontWeight='500' color='#344054'>
                Enter your password
              </Typography>
            </Grid>
            <Grid container item lg={7.5} sm={8} xs={12}>
              <OutlinedInput
                fullWidth
                type={showEnterYourPassword ? 'text' : 'password'}
                size={'small'}
                id='confirmationPassword'
                placeholder='Enter your password'
                name='confirmationPassword'
                value={formik.values.confirmationPassword}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  !!(
                    formik.touched.confirmationPassword &&
                    formik.errors.confirmationPassword
                  )
                }
                endAdornment={
                  <InputAdornment
                    position='end'
                    onClick={handleClickShowEnterYourPassword}
                    sx={{ cursor: 'pointer' }}
                  >
                    <IconButton
                      aria-label='toggle password visibility'
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showEnterYourPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                    <PasswordStateLabel>
                      {showEnterYourPassword ? <b>Hide</b> : <b>Show</b>}
                    </PasswordStateLabel>
                  </InputAdornment>
                }
              />
              {formik.touched.confirmationPassword &&
                formik.errors.confirmationPassword && (
                  <FormHelperText error id='confirmationPassword'>
                    <CustomFormHelperText
                      error={formik.errors.confirmationPassword}
                    />
                  </FormHelperText>
                )}
            </Grid>
          </FormItem>
        )}
        <Grid
          container
          item
          sx={{ borderBottom: '1px solid #EAECF0', pt: 4, pb: 4 }}
        >
          <Grid item lg={2}>
            {!isAuth || isLoading ? (
              <Skeleton height='40px' width='133px' />
            ) : currentAccount?.isMyAccount ? (
              <NoBorderButton
                color={myAccount?.deletedAt ? 'primary' : 'error'}
                size='medium'
                onClick={handleClick}
              >
                {myAccount?.deletedAt ? 'Cancel Deletion' : 'Delete My Account'}
              </NoBorderButton>
            ) : (
              <NoBorderButton
                color={myAccount?.deletedAt ? 'primary' : 'error'}
                size='medium'
                onClick={handleOpenRemoveAccessModal}
              >
                Remove My Access
              </NoBorderButton>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          item
          sx={{
            justifyContent: 'flex-end',
            pt: 4,
          }}
        >
          <Grid
            container
            item
            sm={12}
            spacing={2}
            sx={{ justifyContent: 'flex-end' }}
          >
            <Grid item>
              {!isAuth || isLoading ? (
                <Skeleton height='40px' width='88px' />
              ) : (
                <GrayButtonSmall
                  size='medium'
                  outline
                  onClick={formik.handleCloseAccountInfo}
                >
                  Cancel
                </GrayButtonSmall>
              )}
            </Grid>
            <Grid item>
              {!isAuth || isLoading ? (
                <Skeleton height='40px' width='136px' />
              ) : (
                <PrimaryButtonSmall
                  variant='contained'
                  size='medium'
                  type='submit'
                  disabled={
                    isLoadingChangeAccount ||
                    formik.isSubmitting ||
                    !formik.isValid
                  }
                >
                  Save All Changes
                </PrimaryButtonSmall>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DeleteAccount
        openDeleteModal={openDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
      />
      <RemoveAccessModal
        openRemoveAccessModal={openRemoveAccessModal}
        onCloseRemoveAccessModal={handleCloseRemoveAccessModal}
        onRemoveAccesses={handleRemoveAccessAccount}
      />
    </>
  );
};

export default AccountChangeEmailForm;
