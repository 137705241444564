import React from 'react';

type Props = {
  width?: string;
  height?: string;
};

const CloseIcon: React.FC<Props> = ({width = "10", height = "10"}) => (
  <svg width={width} height={height} viewBox={`0 0 10 10`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="x-close">
      <path id="Icon" d="M7.5 2.5L2.5 7.5M2.5 2.5L7.5 7.5" stroke="#98A2B3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  </svg>
);

export default CloseIcon;
