import React, { useMemo } from 'react';
import { KeywordsActionProps } from './types';
import { closeAndUndoSnackbarAction } from '../../../../../../../../../../../utils/snackbar';
import { useParams } from 'react-router';
import { useAuth, useProjects } from '../../../../../../../../../../../hooks';
import { enqueueSnackbar, SnackbarKey } from 'notistack';

import SmallTrashIcon from '../../../../../../../../../../../shared/images/SmallTrashIcom';
import CustomToast from '../../../../../../../../../../../shared/CustomToast/CustomToast';

import { ListItemTypography, StyledListItem } from '../styles';
import { StyledListItemButton } from '../../../../../../../../../../projects/components/Projects/components/TableProjects/components/EllipsisMenu/styles';
import { checkObjectProperties } from '../../../../../../../../../../projects/utils';
import { DEFAULT_KEYWORD_POSITIONS_MODEL } from '../../../../../../../../../../projects/constants';
import { useAppSelector } from '../../../../../../../../../../../store';

const DeleteProjectsAction: React.FC<KeywordsActionProps> = ({
  popupClose,
}) => {
  const { id } = useParams();

  const { onGetMyAccount, onGetAccountLimit } = useAuth();
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const { onDeleteKeywords, onGetKeywordRankings } = useProjects();

  const deviceType = useAppSelector(
    state => state.projects.projectExpandedGraphs.deviceType
  );
  const search = useAppSelector(
    state => state.projects.projectExpandedGraphs.keywordRankings.table.search
  );
  const sortModel = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table.sortModel
  );
  const paginationModel = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table.paginationModel
  );
  const keywordPositionModel = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table
        .keywordPositionModel
  );
  const rowSelectionModel = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table
        .rowSelectionModel
  );

  const keywordSelectedTags = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table.selectedTags
  );

  const memoKeywordSelectedTags = useMemo(
    () => keywordSelectedTags.map(item => item.id),
    [keywordSelectedTags]
  );

  const handleHardDelete = () => {
    if (id && deviceType) {
      onDeleteKeywords({
        accountId: currentAccount.accountId,
        projectId: Number(id),
        data: {
          keywordIds: rowSelectionModel as number[],
        },
        successFn: () => {
          onGetAccountLimit({ id: currentAccount.accountId });
          onGetKeywordRankings({
            accountId: currentAccount.accountId,
            projectId: Number(id),
            params: {
              page: paginationModel.page,
              limit: paginationModel.pageSize,
              search: search,
              sortBy: sortModel[0]?.field,
              sortOrder: sortModel[0]?.sort,
            },
            filters: checkObjectProperties(
              DEFAULT_KEYWORD_POSITIONS_MODEL,
              keywordPositionModel
            ),
            deviceType: deviceType,
            tagIds: memoKeywordSelectedTags,
          });
          onGetMyAccount(currentAccount.accountId);
        },
      });
    }
  };

  const handleDeleteOneKeywords = () => {
    popupClose();
    let undoDelete = false;
    const onUndo = () => {
      undoDelete = true;
    };
    enqueueSnackbar(
      <CustomToast
        message='Keywords Removed'
        submessage={`Keywords has been successfully removed.`}
      />,
      {
        action: (snackbarId: SnackbarKey) =>
          closeAndUndoSnackbarAction(snackbarId, onUndo),
        variant: 'error',
      }
    );
    setTimeout(() => {
      !undoDelete && handleHardDelete();
    }, 5000);
  };

  return (
    <StyledListItem>
      <StyledListItemButton onClick={handleDeleteOneKeywords}>
        <SmallTrashIcon />
        <ListItemTypography marginLeft='8px' sx={{ color: '#B42318' }}>
          Delete Keywords
        </ListItemTypography>
      </StyledListItemButton>
    </StyledListItem>
  );
};

export default DeleteProjectsAction;
