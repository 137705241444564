import React from 'react';
import { Divider, List } from '@mui/material';

import DeleteKeywordsAction from './DeleteKeywordsAction';
import { KeywordsActionProps } from './types';

import { MainContainer } from '../styles';
import RefreshKeywordsAction from './RefreshKeywordsAction';

const ListActions: React.FC<KeywordsActionProps> = ({ popupClose, open }) => {
  if (!open) return null;
  return (
    <MainContainer>
      <List>
        <RefreshKeywordsAction popupClose={popupClose} />
        <Divider
          orientation='horizontal'
          flexItem
          sx={{
            margin: '8px 0',
            color: '#EDEDED',
          }}
        />

        <DeleteKeywordsAction popupClose={popupClose} />
      </List>
    </MainContainer>
  );
};

export default ListActions;
