import { createAction } from '@reduxjs/toolkit';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import { Range } from 'react-date-range';

import {
  ColumnVisibilityKey,
  ColumnVisibilityKeyKeywordRankings,
  ImprovedVsDeclinedVisibilityKey,
  KeywordRanking,
  NotRankedVisibilityKey,
  Project,
  SearchLocationsStateMeta,
} from '../types';

const setSortModelProjects = createAction(
  'sort-model-projects',
  function prepare(sortModel: GridSortModel) {
    return {
      payload: sortModel,
    };
  }
);

const setSortModelKeywords = createAction(
  'sort-model-keywords',
  function prepare(sortModel: GridSortModel) {
    return {
      payload: sortModel,
    };
  }
);

const setSearchProjects = createAction(
  'set-search-projects',
  function prepare(value: string) {
    return {
      payload: value,
    };
  }
);

const setColumnVisibilityModel = createAction(
  'set-column-visibility-model',
  function prepare(key: ColumnVisibilityKey, value: boolean) {
    return {
      payload: {
        key,
        value,
      },
    };
  }
);

const setColumnVisibilityModelKeywordRankings = createAction(
  'set-column-visibility-model-keyword-rankings',
  function prepare(key: ColumnVisibilityKeyKeywordRankings, value: boolean) {
    return {
      payload: {
        key,
        value,
      },
    };
  }
);

const resetColumnVisibilityModel = createAction(
  'reset-column-visibility-model'
);

const resetColumnVisibilityModelKeywordRankings = createAction(
  'reset-column-visibility-model-keyword-rankings'
);

const clearColumnVisibilityModel = createAction(
  'clear-column-visibility-model'
);

const setPaginationPageSize = createAction(
  'set-pagination-page-size',
  function prepare(pageSize: number) {
    return {
      payload: pageSize,
    };
  }
);

const setKeywordPaginationPageSize = createAction(
  'set-keyword-pagination-page-size',
  function prepare(pageSize: number) {
    return {
      payload: pageSize,
    };
  }
);

const setPaginationPage = createAction(
  'set-pagination-page',
  function prepare(page: number) {
    return {
      payload: page,
    };
  }
);

const setKeywordPaginationPage = createAction(
  'set-keyword-pagination-page',
  function prepare(page: number) {
    return {
      payload: page,
    };
  }
);

const setRowSelectionModel = createAction(
  'set-row-selection-model',
  function prepare(rowSelectionModel: GridRowSelectionModel) {
    return {
      payload: rowSelectionModel,
    };
  }
);

const setRowKeywordsSelectionModel = createAction(
  'set-row-keywords-selection-model',
  function prepare(rowSelectionModel: GridRowSelectionModel) {
    return {
      payload: rowSelectionModel,
    };
  }
);

const setIsShowImprovedVsDeclined = createAction(
  'set-is-show-improved-vs-declined',
  function prepare(isShow: boolean) {
    return {
      payload: isShow,
    };
  }
);

const setIsShowPositionHistory = createAction(
  'set-is-show-position-history',
  function prepare(isShow: boolean) {
    return {
      payload: isShow,
    };
  }
);

const setImprovedVsDeclinedVisibilityModel = createAction(
  'set-improved-vs-declined-visibility-model',
  function prepare(key: ImprovedVsDeclinedVisibilityKey, value: boolean) {
    return {
      payload: {
        key,
        value,
      },
    };
  }
);

const setKeywordTrendsVisibilityModel = createAction(
  'set-keyword-trends-visibility-model',
  function prepare(key: NotRankedVisibilityKey, value: boolean) {
    return {
      payload: {
        key,
        value,
      },
    };
  }
);

const setProjectExpandedGraphsRangeDate = createAction(
  'set-project-expanded-graphs-range-date',
  function prepare(rangeDate: Range) {
    return {
      payload: rangeDate,
    };
  }
);

const setDeviceTypeForProjectExpanded = createAction(
  'set-device-type-for-project-expanded',
  function prepare(deviceType: string) {
    return {
      payload: deviceType,
    };
  }
);

const setColumnKeywordPositionModel = createAction(
  'set-keyword-position-model-keyword-rankings',
  function prepare(items: string[]) {
    return {
      payload: {
        items,
      },
    };
  }
);

const setSearchKeywordRankings = createAction(
  'set-search-keyword-rankings',
  function prepare(value: string) {
    return {
      payload: value,
    };
  }
);

const setActivePeriodImprovedVsDeclined = createAction(
  'setActivePeriodImprovedVsDeclined',
  function prepare(period: { label: string; value: string }) {
    return {
      payload: period,
    };
  }
);

const setCloseKeywordPositionHistory = createAction(
  'set-close-keyword-position-history',
  function prepare(id: number) {
    return {
      payload: id,
    };
  }
);

const setKeywordAnalyticPeriod = createAction(
  'setKeywordAnalyticPeriod',
  function prepare(id: number, period: { label: string; value: string }) {
    return {
      payload: {
        id,
        period,
      },
    };
  }
);

const setActivePeriodKeywordTrends = createAction(
  'setActivePeriodKeywordTrends',
  function prepare(period: { label: string; value: string }) {
    return {
      payload: period,
    };
  }
);

const setActivePeriodProjectPerformance = createAction(
  'setActivePeriodProjectPerformance',
  function prepare(period: { label: string; value: string }) {
    return {
      payload: period,
    };
  }
);

const setProjectPerformanceRangeDate = createAction(
  'setProjectPerformanceRangeDate',
  function prepare(rangeDate: Range) {
    return {
      payload: rangeDate,
    };
  }
);

const setProjectPerformanceIsShowNotes = createAction(
  'setProjectPerformanceIsShowNotes',
  function prepare(value: boolean) {
    return {
      payload: value,
    };
  }
);

const setProjectPerformanceIsLinearGraph = createAction(
  'setProjectPerformanceIsLinearGraph',
  function prepare(value: boolean) {
    return {
      payload: value,
    };
  }
);

const setActivePeriodKeywordRankHistory = createAction(
  'setActivePeriodKeywordRankHistory',
  function prepare(period: { label: string; value: string }) {
    return {
      payload: period,
    };
  }
);

const setSortModelKeywordRankHistory = createAction(
  'setSortModelKeywordRankHistory',
  function prepare(sortModel: GridSortModel) {
    return {
      payload: sortModel,
    };
  }
);

const setKeywordRankHistoryPaginationPageSize = createAction(
  'setKeywordRankHistoryPaginationPageSize',
  function prepare(pageSize: number) {
    return {
      payload: pageSize,
    };
  }
);

const setKeywordRankHistoryPaginationPage = createAction(
  'setKeywordRankHistoryPaginationPage',
  function prepare(page: number) {
    return {
      payload: page,
    };
  }
);

const setKeywordRankHistoryIsLinearGraph = createAction(
  'setKeywordRankHistoryIsLinearGraph',
  function prepare(value: boolean) {
    return {
      payload: value,
    };
  }
);

const setPositionHistoryLinearGraph = createAction(
  'setPositionHistoryLinearGraph',
  function prepare(value: boolean, id: number) {
    return {
      payload: { value, id },
    };
  }
);

const setShowCompetitorsForGraph = createAction(
  'setShowCompetitorsForGraph',
  function prepare(values: number[]) {
    return {
      payload: { values },
    };
  }
);

const setFrequencyFilterForProjectsTable = createAction(
  'setFrequencyFilterForProjectsTable',
  function prepare(frequency: string) {
    return {
      payload: { frequency },
    };
  }
);

const resetProjectsState = createAction('resetProjectsState');

const resetKeywordPositionModel = createAction('resetKeywordPositionModel');

const closeProjectExpandedPage = createAction('closeProjectExpandedPage');

const setUpdatedKeywords = createAction(
  'setUpdatedKeywords',
  function prepare(keywords: KeywordRanking[]) {
    return {
      payload: keywords,
    };
  }
);

const setShowCompetitorsForPositionHistory = createAction(
  'setShowCompetitorsForPositionHistory',
  function prepare(showCompetitors: number[], keywordAnalyticId: number) {
    return {
      payload: { showCompetitors, keywordAnalyticId },
    };
  }
);

const setSearchLocationsMeta = createAction(
  'setSearchLocationsMeta',
  function prepare(meta: SearchLocationsStateMeta) {
    return {
      payload: meta,
    };
  }
);

const setDeleteElementsProjectsTable = createAction(
  'setDeleteElementsProjectsTable',
  function prepare(deleteElements: GridRowSelectionModel) {
    return {
      payload: { deleteElements },
    };
  }
);

const setStartOfKeywordUpdate = createAction(
  'setStartOfKeywordUpdate',
  function prepare(keywordIds: number[]) {
    return {
      payload: { keywordIds },
    };
  }
);

const setDeleteElementsKeywordRankingsTable = createAction(
  'setDeleteElementsKeywordRankingsTable',
  function prepare(deleteElements: GridRowSelectionModel) {
    return {
      payload: { deleteElements },
    };
  }
);

const updateProjects = createAction(
  'updateProjects',
  function prepare(projects: Project[]) {
    return {
      payload: { projects },
    };
  }
);

const setShowCompetitorsForKeywordRankHistory = createAction(
  'setShowCompetitorsForKeywordRankHistory',
  function prepare(showCompetitors: number[]) {
    return {
      payload: { showCompetitors },
    };
  }
);

const setLocationsOfChinaMeta = createAction(
  'setLocationsOfChinaMeta',
  function prepare(meta: SearchLocationsStateMeta) {
    return {
      payload: meta,
    };
  }
);

const setLocationsBingMeta = createAction(
  'setLocationsBingMeta',
  function prepare(meta: SearchLocationsStateMeta) {
    return {
      payload: meta,
    };
  }
);

const setLocationsYoutubeMeta = createAction(
  'setLocationsYoutubeMeta',
  function prepare(meta: SearchLocationsStateMeta) {
    return {
      payload: meta,
    };
  }
);

const setLocationsYahooMeta = createAction(
  'setLocationsYahooMeta',
  function prepare(meta: SearchLocationsStateMeta) {
    return {
      payload: meta,
    };
  }
);

const setLocationsBaiduMeta = createAction(
  'setLocationsBaiduMeta',
  function prepare(meta: SearchLocationsStateMeta) {
    return {
      payload: meta,
    };
  }
);

const resetKeywordRankingsState = createAction('resetKeywordRankingsState');

const setRefreshAllKeywordsModal = createAction(
  'setRefreshAllKeywordsModal',
  function prepare(value: boolean) {
    return {
      payload: value,
    };
  }
);

const setRefreshKeywordsModal = createAction(
  'setRefreshKeywordsModal',
  function prepare(value: boolean) {
    return {
      payload: value,
    };
  }
);

const setNewProjectModal = createAction(
  'setNewProjectModal',
  function prepare(value: boolean) {
    return {
      payload: value,
    };
  }
);

export {
  setSortModelProjects,
  setSortModelKeywords,
  setSearchProjects,
  setColumnVisibilityModel,
  resetColumnVisibilityModel,
  clearColumnVisibilityModel,
  setPaginationPageSize,
  setPaginationPage,
  setKeywordPaginationPageSize,
  setKeywordPaginationPage,
  setRowSelectionModel,
  setRowKeywordsSelectionModel,
  setIsShowImprovedVsDeclined,
  setIsShowPositionHistory,
  setImprovedVsDeclinedVisibilityModel,
  setProjectExpandedGraphsRangeDate,
  setKeywordTrendsVisibilityModel,
  setDeviceTypeForProjectExpanded,
  resetColumnVisibilityModelKeywordRankings,
  setColumnVisibilityModelKeywordRankings,
  setColumnKeywordPositionModel,
  setSearchKeywordRankings,
  setActivePeriodImprovedVsDeclined,
  setCloseKeywordPositionHistory,
  setKeywordAnalyticPeriod,
  setActivePeriodKeywordTrends,
  setActivePeriodProjectPerformance,
  setProjectPerformanceRangeDate,
  setProjectPerformanceIsShowNotes,
  setProjectPerformanceIsLinearGraph,
  setActivePeriodKeywordRankHistory,
  setSortModelKeywordRankHistory,
  setKeywordRankHistoryPaginationPageSize,
  setKeywordRankHistoryPaginationPage,
  setKeywordRankHistoryIsLinearGraph,
  setPositionHistoryLinearGraph,
  setShowCompetitorsForGraph,
  setFrequencyFilterForProjectsTable,
  resetProjectsState,
  resetKeywordPositionModel,
  closeProjectExpandedPage,
  setUpdatedKeywords,
  setShowCompetitorsForPositionHistory,
  setSearchLocationsMeta,
  setDeleteElementsProjectsTable,
  setStartOfKeywordUpdate,
  setDeleteElementsKeywordRankingsTable,
  updateProjects,
  setShowCompetitorsForKeywordRankHistory,
  resetKeywordRankingsState,
  setLocationsOfChinaMeta,
  setRefreshAllKeywordsModal,
  setRefreshKeywordsModal,
  setNewProjectModal,
  setLocationsBingMeta,
  setLocationsYoutubeMeta,
  setLocationsYahooMeta,
  setLocationsBaiduMeta,
};
