import { styled } from '@mui/system';
import { Switch } from '@mui/material';

const CustomSwitch = styled(Switch)`
  width: 44px;
  height: 24px;
  padding: 0;
  border-radius: 12px;
  box-sizing: content-box;
  
  .MuiSwitch-switchBase {
      padding: 2px;
  };

  .MuiSwitch-track {
    background-color: #DEDEDE;
    opacity: 1 !important;
  }
  
  .MuiSwitch-thumb {
    color: #FFFFFF !important;
  }
  
  .Mui-checked+.MuiSwitch-track {
    background-color: #126FE9;
    opacity: 1 !important;
  }
`;

export { CustomSwitch };
