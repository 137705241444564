import React, { useState } from 'react';
import { Grid } from '@mui/material';
import KeywordsAssociatedWithTriggerModal from '../../../../../KeywordsAssociatedWithTriggerModal/KeywordsAssociatedWithTriggerModal';

interface KeywordTriggerViewProps {
  keywordCount: number;
  triggerId: number;
}

const KeywordTriggerView: React.FC<KeywordTriggerViewProps> = ({
  keywordCount,
  triggerId,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpenModal = () => {
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };
  return (
    <>
      <Grid container gap={1}>
        <Grid item>{keywordCount}</Grid>
        <Grid item style={{ cursor: 'pointer' }} onClick={onOpenModal}>
          <a>View</a>
        </Grid>
      </Grid>
      {isOpen && (
        <KeywordsAssociatedWithTriggerModal
          triggerId={triggerId}
          onClose={onCloseModal}
          isOpen={isOpen}
        />
      )}
    </>
  );
};

export default KeywordTriggerView;
