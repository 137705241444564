import React from 'react';

import { Graph, Header } from './components';

import { ImprovedVsDeclinedContainer } from './styled';

const ImprovedVsDeclined = () => {
  return (
    <ImprovedVsDeclinedContainer>
      <Header />
      <Graph />
    </ImprovedVsDeclinedContainer>
  );
};

export default ImprovedVsDeclined;
