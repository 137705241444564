import React from 'react';
import { Paper } from '@mui/material';

const AutocompletePaper: React.FC = (
  paperProps: React.HTMLAttributes<HTMLElement>
) => {
  const { children, ...restPaperProps } = paperProps;
  return <Paper {...restPaperProps}>{children}</Paper>;
};

export default AutocompletePaper;
