/**
 * Default column visibility model for alerts by project.
 * Indicates whether each column should be visible in the alerts by project table.
 */
const DEFAULT_ALERTS_BY_PROJECT_COLUMN_VISIBILITY_MODEL = {
  date: true,
  numberOfKeywords: true,
  rule: true,
  threshold: true,
};

/**
 * Default column visibility model for alerts by keywords.
 * Indicates whether each column should be visible in the alerts by keywords table.
 */
const DEFAULT_ALERTS_BY_KEYWORDS_COLUMN_VISIBILITY_MODEL = {
  date: true,
  previousRank: true,
  newRank: true,
  difference: true,
  rule: true,
  threshold: true,
};

export {
  DEFAULT_ALERTS_BY_PROJECT_COLUMN_VISIBILITY_MODEL,
  DEFAULT_ALERTS_BY_KEYWORDS_COLUMN_VISIBILITY_MODEL,
};
