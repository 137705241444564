import Grid from '@mui/material/Grid';
import React, { useEffect, useMemo, useState } from 'react';

import { CustomDataGrid } from '../../../../../Projects/components/TableProjects/components/Table/styles';
import CheckboxWrapper from '../../../../../../../auth/components/Settings/components/Team/components/CheckboxWrapper';
import {
  useKeywordPositionsInfoTable,
  useKeywordRankHistory,
} from '../../../../../../../../hooks';
import { TableNoData } from '../../../../../../../../shared/components';
import { useAppSelector } from '../../../../../../../../store';

const Table = () => {
  const { columns, columnsSkeleton } = useKeywordPositionsInfoTable();
  const {
    onSetSortModelKeywordRankHistory,
    keywordPositionsInfo,
    tableState: {
      paginationModel: { pageSize },
    },
  } = useKeywordRankHistory();

  const openTariffPlanBanner = useAppSelector(
    state => state.auth.openTariffPlanBanner
  );

  const [itemCount, setItemCount] = useState(
    keywordPositionsInfo?.meta?.itemCount || pageSize
  );

  const arrayOfObjects = useMemo(
    () => Array.from({ length: itemCount }, (_, index) => ({ id: index + 1 })),
    [itemCount]
  );

  const rows = useMemo(
    () =>
      keywordPositionsInfo?.items?.length ? keywordPositionsInfo.items : [],
    [keywordPositionsInfo?.items]
  );

  const renderRows = useMemo(() => {
    return !keywordPositionsInfo?.items ? arrayOfObjects : rows;
  }, [arrayOfObjects, keywordPositionsInfo?.items, rows]);

  const renderColumns = useMemo(() => {
    return !keywordPositionsInfo?.items ? columnsSkeleton : columns;
  }, [columns, columnsSkeleton, keywordPositionsInfo?.items]);

  useEffect(() => {
    if (keywordPositionsInfo?.meta?.itemCount) {
      setItemCount(keywordPositionsInfo.meta.itemCount);
    }
  }, [keywordPositionsInfo?.meta?.itemCount]);

  return (
    <Grid item xs={12}>
      <CustomDataGrid
        isSelectedRow={false}
        columnHeaderHeight={40}
        rowHeight={40}
        rows={renderRows}
        columns={renderColumns}
        keepNonExistentRowsSelected
        hideFooterPagination
        disableRowSelectionOnClick
        disableColumnMenu
        onSortModelChange={onSetSortModelKeywordRankHistory}
        components={{
          BaseCheckbox: CheckboxWrapper,
          NoRowsOverlay: TableNoData,
        }}
        $openTariffPlanBanner={openTariffPlanBanner}
      />
    </Grid>
  );
};

export default Table;
