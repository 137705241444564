import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import {
  ISharedLinkFullInfo,
  ISharedLinkSettings,
  SharedLinksResponse,
} from '../types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getAllSharedLinksThunk,
  getSharedImprovedVsDeclinedThunk,
  getSharedKeywordRankingsThunk,
  getSharedKeywordTrendsThunk,
  getSharedProjectOverviewThunk,
  getSharedProjectThunk,
  getSharedThunk,
  getKeywordPositionHistoryThunk,
  getKeywordSearchResultsThunk,
  getSharedProjectPerformanceThunk,
  getSharedLinkFullThunk,
  getSharedLinkSettingsThunk,
  getSharedKeywordInfoThunk,
  getSharedSingleKeywordPositionHistoryThunk,
  getSharedKeywordPositionsInfoThunk,
} from './thunk';
import {
  DEFAULT_COLUMN_VISIBILITY_MODEL,
  DEFAULT_KEYWORD_POSITION_MODEL,
  DEFAULT_KEYWORD_RANKINGS_VISIBILITY_MODEL,
  DEFAULT_SHARED_LINKS_MODEL,
} from '../../projects/constants';
import {
  setSearchAllSharedLinks,
  setColumnVisibilityModel,
  resetColumnVisibilityModel,
  setPaginationPage,
  setPaginationPageSize,
  setRowSelectionModelSharedLinks,
  setPaginationPageSizeForSharedLinkContentsListTable,
  setPaginationPageForSharedLinkContentsListTable,
  setColumnVisibilityModelForSharedLinkContentsListTable,
  resetColumnVisibilityModelForSharedLinkContentsListTable,
  setSearchSharedLinkContentsList,
  setDeviceTypeForProject,
  setRangeDateForProject,
  setImprovedVsDeclinedVisibilityModel,
  setIsShowImprovedVsDeclined,
  setActivePeriodImprovedVsDeclined,
  setKeywordTrendsVisibilityModel,
  setActivePeriodKeywordTrends,
  resetColumnVisibilityModelKeywordRankings,
  setColumnVisibilityModelKeywordRankings,
  setRowKeywordsSelectionModel,
  setSearchKeywordRankings,
  setSortModelKeywordRankings,
  setKeywordRankingsPaginationPageSize,
  setKeywordRankingsPaginationPage,
  setKeywordAnalyticPeriod,
  setCloseKeywordPositionHistory,
  setActivePeriodSharedProjectPerformance,
  setSharedProjectPerformanceIsShowNotes,
  setSharedProjectPerformanceIsLinearGraph,
  setSharedProjectShowCompetitorsForGraph,
  resetSharedLinksState,
  setSortModelSharedProjects,
  setSharedLinksColumnKeywordPositionModel,
  resetSharedLinksKeywordPositionModel,
  setSharedShowCompetitorsForPositionHistory,
  setDeleteElementsSharedLinksPageTable,
  setIsPasswordUpdated,
  setSharedActivePeriodKeywordRankHistory,
  setSharedKeywordRankHistoryIsLinearGraph,
  setSharedShowCompetitorsForKeywordRankHistory,
  setSharedSortModelKeywordRankHistory,
  setSharedKeywordRankHistoryPaginationPageSize,
  setSharedKeywordRankHistoryPaginationPage,
} from './actions';
import {
  AccountTag,
  GetKeywordPositionHistoryResponse,
  KeywordAnalytics,
  KeywordRankingState,
  ProjectExpanded,
  ProjectImprovedVsDeclined,
  ProjectKeywordTrends,
  ProjectOverview,
  ProjectsState,
} from '../../projects/types';
import { Range } from 'react-date-range';
import { GetProjectPerformanceResponse } from '../../projects/types/projectPerformance';
import dayjs from 'dayjs';
import { getPage } from '../../../utils';
import {
  initializeTableColumns,
  TABLES_STORAGE_KEYS,
} from '../../projects/tablesColumnStase';
import {
  GetKeywordInfoResponse,
  IKeywordPositionsInfoState,
} from '../../projects/types/keywordInfo';

const initialState = {
  isLoading: false,
  isLoadingSharedProjectInfo: false,
  sharedLinkFullInfo: null as ISharedLinkFullInfo | null,
  sharedLinkSettings: null as ISharedLinkSettings | null,
  sharedLinksList: null as SharedLinksResponse | null,
  sharedLinkContentsList: null as ProjectsState | null,
  tables: {
    sharedLinksPage: {
      search: '',
      sortModel: [{ field: '', sort: 'desc' }] as GridSortModel,
      paginationModel: {
        pageSize: 10,
        page: 0,
      },
      columnVisibilityModel: initializeTableColumns(
        TABLES_STORAGE_KEYS.sharedLinksPage,
        DEFAULT_SHARED_LINKS_MODEL
      ),
      rowSelectionModel: [] as GridRowSelectionModel,
      deleteElements: [] as GridRowSelectionModel,
    },
    sharedLinkContentsList: {
      search: '',
      sortModel: [{ field: 'projects', sort: 'desc' }] as GridSortModel,
      paginationModel: {
        pageSize: 100,
        page: 0,
      },
      columnVisibilityModel: initializeTableColumns(
        TABLES_STORAGE_KEYS.sharedWithYouPage,
        DEFAULT_COLUMN_VISIBILITY_MODEL
      ),
      rowSelectionModel: [] as GridRowSelectionModel,
    },
  },
  projectShared: null as ProjectExpanded | null,
  projectSharedDeviceType: undefined as string | undefined,
  projectSharedRangeDate: {
    startDate: undefined,
    endDate: undefined,
    key: 'selection',
  } as Range,
  projectGraphs: {
    isLoadingProjectImprovedVsDeclined: false,
    isLoadingProjectKeywordTrends: false,
    isLoadingProjectPerformance: false,
    isLoadingProjectKeywordRankings: false,
    overview: null as ProjectOverview | null,
    isShowImprovedVsDeclined: true,
    showImprovedVsDeclinedVisibilityModel: {
      improved: true,
      declined: true,
      lost: true,
      noChange: false,
    },
    activePeriodImprovedVsDeclined: {
      label: '1m',
      value: 'Month',
    },
    projectImprovedVsDeclined: null as ProjectImprovedVsDeclined | null,

    activePeriodKeywordTrends: {
      label: '1m',
      value: 'Month',
    },
    showKeywordTrendsVisibilityModel: {
      top3: true,
      'top4-10': true,
      'top11-20': true,
      'top21-50': true,
      'top51-100': true,
      notRanked: false,
    },
    projectKeywordTrends: null as ProjectKeywordTrends | null,

    keywordRankings: {
      activePeriodKeywordTrends: {
        label: '1m',
        value: 'Month',
      },
      keywordRanking: {} as KeywordRankingState,
      keywordsAnalytics: [] as KeywordAnalytics[],
      table: {
        search: '',
        sortModel: [] as GridSortModel,
        columnVisibilityModel: initializeTableColumns(
          TABLES_STORAGE_KEYS.sharedKeywordRankings,
          DEFAULT_KEYWORD_RANKINGS_VISIBILITY_MODEL
        ),
        keywordPositionModel: DEFAULT_KEYWORD_POSITION_MODEL,
        rowSelectionModel: [] as GridRowSelectionModel,
        selectedTags: [] as AccountTag[],
        paginationModel: {
          pageSize: 100,
          page: 0,
        },
      },
    },

    projectPerformance: {
      showCompetitors: [] as number[],
      rangeDate: {
        startDate: undefined,
        endDate: undefined,
        key: 'selection',
      } as Range,
      isShowNotes: true,
      isLinearGraph: true,
      graphData: null as GetProjectPerformanceResponse | null,
    },
    activePeriodProjectPerformance: {
      label: '1m',
      value: 'Month',
    },
  },
  isPasswordUpdated: false,
  keywordRankHistory: {
    keywordInfo: null as GetKeywordInfoResponse | null,
    activePeriod: {
      label: '1m',
      value: 'Month',
    },
    keywordPositionHistory: null as GetKeywordPositionHistoryResponse | null,
    keywordPositionsInfo: null as IKeywordPositionsInfoState | null,
    isLinearGraph: true as boolean,
    showCompetitors: [] as number[],
    tableState: {
      search: '',
      sortModel: [{ field: 'date', sort: 'desc' }] as GridSortModel,
      paginationModel: {
        pageSize: 10,
        page: 0,
      },
      rowSelectionModel: [] as GridRowSelectionModel,
    },
  },
};

export const sharedLinks = createSlice({
  name: 'sharedLinks',
  initialState,
  reducers: {
    selectSharedKeywordTags(state, action: PayloadAction<AccountTag[]>) {
      state.projectGraphs.keywordRankings.table.selectedTags = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllSharedLinksThunk.pending, state => {
      state.sharedLinksList = null;
    });
    builder.addCase(getAllSharedLinksThunk.fulfilled, (state, { payload }) => {
      state.sharedLinksList = payload;
    });

    builder.addCase(setSearchAllSharedLinks, (state, { payload }) => {
      state.tables.sharedLinksPage.search = payload;
    });

    builder.addCase(setColumnVisibilityModel, (state, { payload }) => {
      state.tables.sharedLinksPage.columnVisibilityModel[payload.key] =
        payload.value;
    });

    builder.addCase(resetColumnVisibilityModel, state => {
      state.tables.sharedLinksPage.columnVisibilityModel =
        DEFAULT_SHARED_LINKS_MODEL;
    });

    builder.addCase(setPaginationPageSize, (state, { payload }) => {
      state.tables.sharedLinksPage.paginationModel.pageSize = payload;
    });

    builder.addCase(setPaginationPage, (state, { payload }) => {
      state.tables.sharedLinksPage.paginationModel.page = payload;
    });

    builder.addCase(setRowSelectionModelSharedLinks, (state, { payload }) => {
      state.tables.sharedLinksPage.rowSelectionModel = payload;
    });

    builder.addCase(getSharedThunk.pending, state => {
      state.sharedLinkContentsList = null;
    });
    builder.addCase(getSharedThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.sharedLinkContentsList = payload;
    });

    builder.addCase(
      setPaginationPageSizeForSharedLinkContentsListTable,
      (state, { payload }) => {
        state.tables.sharedLinkContentsList.paginationModel.pageSize = payload;
      }
    );

    builder.addCase(
      setPaginationPageForSharedLinkContentsListTable,
      (state, { payload }) => {
        state.tables.sharedLinkContentsList.paginationModel.page = payload;
      }
    );

    builder.addCase(
      setColumnVisibilityModelForSharedLinkContentsListTable,
      (state, { payload }) => {
        state.tables.sharedLinkContentsList.columnVisibilityModel[payload.key] =
          payload.value;
      }
    );

    builder.addCase(
      resetColumnVisibilityModelForSharedLinkContentsListTable,
      state => {
        state.tables.sharedLinkContentsList.columnVisibilityModel =
          DEFAULT_COLUMN_VISIBILITY_MODEL;
      }
    );

    builder.addCase(setSearchSharedLinkContentsList, (state, { payload }) => {
      state.tables.sharedLinkContentsList.search = payload;
    });

    builder.addCase(getSharedProjectThunk.pending, (state, action) => {
      if (action?.meta?.arg?.deviceType) {
        state.projectSharedDeviceType = action.meta.arg.deviceType;
      }
      state.isLoadingSharedProjectInfo = true;
    });

    builder.addCase(getSharedProjectThunk.fulfilled, (state, { payload }) => {
      state.projectShared = payload.data;

      if (
        payload.deviceType !== 'Desktop' &&
        (payload.data.searchEngine.name === 'Google My Business' ||
          payload.data.searchEngine.name === 'Google Maps' ||
          payload.data.searchEngine.name === 'YouTube')
      ) {
        state.projectSharedDeviceType = 'Desktop';
        state.projectShared = null;
      } else {
        state.projectShared = payload.data;
        state.projectSharedDeviceType = payload.data.deviceType.name;
      }

      state.isLoadingSharedProjectInfo = false;
    });

    builder.addCase(getSharedProjectThunk.rejected, (state, action) => {
      state.isLoadingSharedProjectInfo = true;
    });

    builder.addCase(setDeviceTypeForProject, (state, { payload }) => {
      state.projectSharedDeviceType = payload;
    });

    builder.addCase(setRangeDateForProject, (state, { payload }) => {
      state.projectSharedRangeDate = payload;
    });

    builder.addCase(
      getSharedProjectOverviewThunk.fulfilled,
      (state, { payload }) => {
        state.projectGraphs.overview = payload;
        if (payload.fromDate && payload.toDate) {
          state.projectSharedRangeDate = {
            startDate: dayjs(payload.fromDate).toDate(),
            endDate: dayjs(payload.toDate).toDate(),
            key: 'selection',
          } as Range;
        }
      }
    );

    builder.addCase(setIsShowImprovedVsDeclined, (state, { payload }) => {
      state.projectGraphs.isShowImprovedVsDeclined = payload;
    });

    builder.addCase(
      setImprovedVsDeclinedVisibilityModel,
      (state, { payload }) => {
        state.projectGraphs.showImprovedVsDeclinedVisibilityModel[payload.key] =
          payload.value;
      }
    );

    builder.addCase(setActivePeriodImprovedVsDeclined, (state, { payload }) => {
      state.projectGraphs.activePeriodImprovedVsDeclined = payload;
    });

    builder.addCase(getSharedImprovedVsDeclinedThunk.pending, state => {
      state.projectGraphs.isLoadingProjectImprovedVsDeclined = true;
    });
    builder.addCase(
      getSharedImprovedVsDeclinedThunk.fulfilled,
      (state, { payload }) => {
        state.projectGraphs.projectImprovedVsDeclined = payload;
        state.projectGraphs.isLoadingProjectImprovedVsDeclined = false;
      }
    );
    builder.addCase(getSharedImprovedVsDeclinedThunk.rejected, state => {
      state.projectGraphs.isLoadingProjectImprovedVsDeclined = false;
    });

    builder.addCase(setKeywordTrendsVisibilityModel, (state, { payload }) => {
      state.projectGraphs.showKeywordTrendsVisibilityModel[payload.key] =
        payload.value;
    });

    builder.addCase(setActivePeriodKeywordTrends, (state, { payload }) => {
      state.projectGraphs.activePeriodKeywordTrends = payload;
    });

    builder.addCase(getSharedKeywordTrendsThunk.pending, state => {
      state.projectGraphs.isLoadingProjectKeywordTrends = true;
    });
    builder.addCase(
      getSharedKeywordTrendsThunk.fulfilled,
      (state, { payload }) => {
        state.projectGraphs.projectKeywordTrends = payload;
        state.projectGraphs.isLoadingProjectKeywordTrends = false;
      }
    );
    builder.addCase(getSharedKeywordTrendsThunk.rejected, state => {
      state.projectGraphs.isLoadingProjectKeywordTrends = true;
    });

    builder.addCase(getSharedKeywordRankingsThunk.pending, state => {
      state.projectGraphs.isLoadingProjectKeywordRankings = true;
      state.projectGraphs.keywordRankings.keywordRanking =
        {} as KeywordRankingState;
    });
    builder.addCase(
      getSharedKeywordRankingsThunk.fulfilled,
      (state, { payload }) => {
        state.projectGraphs.isLoadingProjectKeywordRankings = false;
        state.projectGraphs.keywordRankings.keywordRanking = payload;
        state.projectGraphs.keywordRankings.table.paginationModel.page =
          getPage(
            state.projectGraphs.keywordRankings.table.paginationModel.page,
            payload.meta.currentPage,
            payload.meta.totalPages
          );
      }
    );
    builder.addCase(getSharedKeywordRankingsThunk.rejected, state => {
      state.projectGraphs.isLoadingProjectKeywordRankings = true;
    });

    builder.addCase(resetColumnVisibilityModelKeywordRankings, state => {
      state.projectGraphs.keywordRankings.table.columnVisibilityModel =
        DEFAULT_KEYWORD_RANKINGS_VISIBILITY_MODEL;
    });

    builder.addCase(
      setColumnVisibilityModelKeywordRankings,
      (state, { payload }) => {
        state.projectGraphs.keywordRankings.table.columnVisibilityModel[
          payload.key
        ] = payload.value;
      }
    );

    builder.addCase(setRowKeywordsSelectionModel, (state, { payload }) => {
      state.projectGraphs.keywordRankings.table.rowSelectionModel = payload;
    });

    builder.addCase(setSearchKeywordRankings, (state, { payload }) => {
      state.projectGraphs.keywordRankings.table.search = payload;
    });

    builder.addCase(setSortModelKeywordRankings, (state, { payload }) => {
      state.projectGraphs.keywordRankings.table.sortModel = payload;
    });

    builder.addCase(
      setKeywordRankingsPaginationPageSize,
      (state, { payload }) => {
        state.projectGraphs.keywordRankings.table.paginationModel.pageSize =
          payload;
      }
    );

    builder.addCase(setKeywordRankingsPaginationPage, (state, { payload }) => {
      state.projectGraphs.keywordRankings.table.paginationModel.page = payload;
    });

    builder.addCase(
      getKeywordPositionHistoryThunk.fulfilled,
      (state, { payload }) => {
        const keywordAnalytics =
          state.projectGraphs.keywordRankings.keywordsAnalytics.find(
            item => item.id === payload.id
          );

        if (keywordAnalytics?.id) {
          state.projectGraphs.keywordRankings.keywordsAnalytics = [
            ...state.projectGraphs.keywordRankings.keywordsAnalytics,
          ].map(item => {
            if (item.id === payload.id) {
              return {
                ...keywordAnalytics,
                keywordPositionHistory: payload.keywordPositionHistory,
                competitors: payload.competitors,
                showCompetitors: payload.showCompetitors,
              };
            }
            return item;
          });
        } else {
          state.projectGraphs.keywordRankings.keywordsAnalytics = [
            ...state.projectGraphs.keywordRankings.keywordsAnalytics,
            { ...payload },
          ];
        }
      }
    );

    builder.addCase(setKeywordAnalyticPeriod, (state, { payload }) => {
      const {
        projectGraphs: {
          keywordRankings: { keywordsAnalytics },
        },
      } = state;
      keywordsAnalytics.map((item, index) => {
        if (payload.id === item.id) {
          state.projectGraphs.keywordRankings.keywordsAnalytics[
            index
          ].activePeriod.value = payload.period.value;
          state.projectGraphs.keywordRankings.keywordsAnalytics[
            index
          ].activePeriod.label = payload.period.label;

          return item;
        } else {
          return item;
        }
      });
    });

    builder.addCase(setCloseKeywordPositionHistory, (state, { payload }) => {
      state.projectGraphs.keywordRankings.keywordsAnalytics =
        state.projectGraphs.keywordRankings.keywordsAnalytics.filter(
          item => item.id !== payload
        );
    });

    builder.addCase(
      getKeywordSearchResultsThunk.fulfilled,
      (state, { payload }) => {
        const keywordAnalytics =
          state.projectGraphs.keywordRankings.keywordsAnalytics.find(
            item => item.id === payload.id
          );

        if (keywordAnalytics?.id) {
          state.projectGraphs.keywordRankings.keywordsAnalytics = [
            ...state.projectGraphs.keywordRankings.keywordsAnalytics,
          ].map(item => {
            if (item.id === payload.id) {
              return {
                ...keywordAnalytics,
                searchResult: payload.state,
              };
            }
            return item;
          });
        }
      }
    );

    builder.addCase(getSharedProjectPerformanceThunk.pending, state => {
      state.projectGraphs.isLoadingProjectPerformance = true;
      state.projectGraphs.projectPerformance.graphData = null;
    });
    builder.addCase(
      getSharedProjectPerformanceThunk.fulfilled,
      (state, { payload }) => {
        state.projectGraphs.isLoadingProjectPerformance = false;
        state.projectGraphs.projectPerformance.graphData = payload;
      }
    );
    builder.addCase(getSharedProjectPerformanceThunk.rejected, state => {
      state.projectGraphs.isLoadingProjectPerformance = false;
    });

    builder.addCase(
      setActivePeriodSharedProjectPerformance,
      (state, { payload }) => {
        state.projectGraphs.activePeriodProjectPerformance = payload;
      }
    );

    builder.addCase(
      setSharedProjectPerformanceIsShowNotes,
      (state, { payload }) => {
        state.projectGraphs.projectPerformance.isShowNotes = payload;
      }
    );

    builder.addCase(
      setSharedProjectPerformanceIsLinearGraph,
      (state, { payload }) => {
        state.projectGraphs.projectPerformance.isLinearGraph = payload;
      }
    );

    builder.addCase(
      setSharedProjectShowCompetitorsForGraph,
      (state, { payload }) => {
        state.projectGraphs.projectPerformance.showCompetitors = payload.values;
      }
    );

    builder.addCase(setSortModelSharedProjects, (state, { payload }) => {
      state.tables.sharedLinkContentsList.sortModel = payload;
    });

    builder.addCase(resetSharedLinksState, state => {
      return (state = initialState);
    });

    builder.addCase(getSharedLinkFullThunk.fulfilled, (state, { payload }) => {
      state.sharedLinkFullInfo = payload;
    });

    builder.addCase(
      getSharedLinkSettingsThunk.fulfilled,
      (state, { payload }) => {
        state.sharedLinkSettings = payload;
        state.projectGraphs.keywordRankings.table.columnVisibilityModel = {
          ...state.projectGraphs.keywordRankings.table.columnVisibilityModel,
          position: payload.settings.position,
          d1: payload.settings.oneDayChange,
          d7: payload.settings.sevenDayChange,
          d30: payload.settings.thirtyDayChange,
          life: payload.settings.lifeTimeChange,
          url: payload.settings.url,
          best: payload.settings.bestRank,
          searchValue: payload.settings.volume,
          cpc: payload.settings.cpc,
          first: payload.settings.startingRank,
          updated: payload.settings.updated,
        };
      }
    );

    builder.addCase(
      setSharedLinksColumnKeywordPositionModel,
      (state, { payload }) => {
        state.projectGraphs.keywordRankings.table.keywordPositionModel =
          payload.items;
      }
    );

    builder.addCase(
      resetSharedLinksKeywordPositionModel,
      (state, { payload }) => {
        state.projectGraphs.keywordRankings.table.keywordPositionModel =
          DEFAULT_KEYWORD_POSITION_MODEL;
      }
    );

    builder.addCase(
      setSharedShowCompetitorsForPositionHistory,
      (state, { payload }) => {
        const keywordsAnalyticIndex =
          state.projectGraphs.keywordRankings.keywordsAnalytics.findIndex(
            item => item.id === payload.keywordAnalyticId
          );

        state.projectGraphs.keywordRankings.keywordsAnalytics[
          keywordsAnalyticIndex
        ].showCompetitors = payload.showCompetitors;
      }
    );

    builder.addCase(
      setDeleteElementsSharedLinksPageTable,
      (state, { payload }) => {
        state.tables.sharedLinksPage.deleteElements = payload.deleteElements;
      }
    );

    builder.addCase(setIsPasswordUpdated, (state, { payload }) => {
      state.isPasswordUpdated = payload.value;
    });

    builder.addCase(
      getSharedKeywordInfoThunk.fulfilled,
      (state, { payload }) => {
        state.keywordRankHistory.keywordInfo = payload;
        state.keywordRankHistory.showCompetitors = payload.competitors.map(
          item => item.id
        );
      }
    );

    builder.addCase(
      getSharedSingleKeywordPositionHistoryThunk.fulfilled,
      (state, { payload }) => {
        state.keywordRankHistory.keywordPositionHistory = payload;
      }
    );

    builder.addCase(
      setSharedActivePeriodKeywordRankHistory,
      (state, { payload }) => {
        state.keywordRankHistory.activePeriod = payload;
      }
    );

    builder.addCase(
      setSharedKeywordRankHistoryIsLinearGraph,
      (state, { payload }) => {
        state.keywordRankHistory.isLinearGraph = payload;
      }
    );

    builder.addCase(
      setSharedShowCompetitorsForKeywordRankHistory,
      (state, { payload }) => {
        state.keywordRankHistory.showCompetitors = payload.showCompetitors;
      }
    );

    builder.addCase(getSharedKeywordPositionsInfoThunk.pending, state => {
      state.keywordRankHistory.keywordPositionsInfo = null;
    });

    builder.addCase(
      getSharedKeywordPositionsInfoThunk.fulfilled,
      (state, { payload }) => {
        state.keywordRankHistory.keywordPositionsInfo = payload;
      }
    );

    builder.addCase(
      setSharedSortModelKeywordRankHistory,
      (state, { payload }) => {
        state.keywordRankHistory.tableState.sortModel = payload;
      }
    );

    builder.addCase(
      setSharedKeywordRankHistoryPaginationPageSize,
      (state, { payload }) => {
        state.keywordRankHistory.tableState.paginationModel.pageSize = payload;
      }
    );

    builder.addCase(
      setSharedKeywordRankHistoryPaginationPage,
      (state, { payload }) => {
        state.keywordRankHistory.tableState.paginationModel.page = payload;
      }
    );
  },
});

export const { selectSharedKeywordTags } = sharedLinks.actions;

export default sharedLinks.reducer;
