import React, { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { DeleteAccountProps } from './types';
import { useAuth } from '../../../../../../hooks';
import DeleteAccountStep1 from './DeleteAccountStep1';
import DeleteAccountStep2 from './DeleteAccountStep2';
import DeleteAccountStep3 from './DeleteAccountStep3';
import CancelDeleteModal from './CancelDeleteModal';
import { useAppSelector } from '../../../../../../store';
import useAccountLimits from '../../../../hooks/useAccountLimits';

/**
 * DeleteAccount component manages the process of deleting a user's account through multiple steps, including password confirmation, reason submission, and final confirmation.
 *
 * @component
 * @param {DeleteAccountProps} props - The props for the DeleteAccount component.
 * @param {boolean} props.openDeleteModal - Boolean flag to control if the delete account modal is open.
 * @param {Function} props.handleCloseDeleteModal - Function to handle the closing of the delete account modal.
 * @returns {JSX.Element} Rendered DeleteAccount component.
 */
const DeleteAccount: React.FC<DeleteAccountProps> = ({
  openDeleteModal,
  handleCloseDeleteModal,
}) => {
  const { onPasswordConfirmation, onDeleteAccount, onGetMyAccount } = useAuth();
  const { onSetActionRequiredCancelSubscriptionModal } = useAccountLimits();

  const isLoadingDeleteAccount = useAppSelector(
    state => state.auth.isLoadingDeleteAccount
  );
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const myAccount = useAppSelector(state => state.auth.myAccount);
  const user = useAppSelector(state => state.auth.user);

  const [openStep, setOpenStep] = React.useState(myAccount?.deletedAt ? 3 : 0);

  const passwordExists = useMemo(() => !!user && user.passwordExists, [user]);

  const formik = useFormik({
    initialValues: {
      deletionReason: '',
      password: '',
      confirmText: '',
    },
    validationSchema: Yup.object().shape({
      deletionReason: Yup.string().required('Deletion reason is required.'),
      password: Yup.string(),
    }),
    onSubmit: (values, { setErrors, setStatus, setSubmitting }) => {
      if (openStep === 0) {
        if (passwordExists) {
          onPasswordConfirmation({
            password: formik.values.password,
            id: currentAccount.accountId,
            successFn: () => {
              setOpenStep(1);
            },
            errorFn: (error: any) => {
              setStatus({ success: false });
              setSubmitting(false);
              setErrors(error);
            },
          });
        } else {
          setOpenStep(1);
        }
      }
      if (openStep === 1) {
        onDeleteAccount({
          reason: formik.values.deletionReason,
          id: currentAccount.accountId,
          successFn: () => {
            currentAccount?.accountId &&
              onGetMyAccount(currentAccount.accountId);
            setOpenStep(2);
          },
          errorFn: error => {
            error && onSetActionRequiredCancelSubscriptionModal(true);
          },
        });
      }
    },
  });

  /**
   * Handles closing the delete account modal and resetting the form state.
   */
  const handleClose = () => {
    formik.resetForm();
    handleCloseDeleteModal();
    setOpenStep(myAccount?.deletedAt ? 3 : 0);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <DeleteAccountStep1
        deletionReason={formik.values.deletionReason}
        errors={formik.errors}
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
        handleClose={handleClose}
        isLoadingDeleteAccount={isLoadingDeleteAccount}
        isValid={formik.isValid}
        open={openDeleteModal && openStep === 0}
        password={formik.values.password}
        touched={formik.touched}
        handleSubmit={formik.handleSubmit}
        passwordExists={passwordExists}
      />
      <DeleteAccountStep2
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
        handleClose={handleClose}
        isLoadingDeleteAccount={isLoadingDeleteAccount}
        open={openDeleteModal && openStep === 1}
        handleSubmit={formik.handleSubmit}
        confirmText={formik.values.confirmText}
      />
      <DeleteAccountStep3
        handleClose={() => {
          formik.resetForm();
          setOpenStep(3);
          handleCloseDeleteModal();
        }}
        open={openDeleteModal && openStep === 2}
      />
      <CancelDeleteModal
        handleClose={() => {
          setOpenStep(3);
          handleCloseDeleteModal();
        }}
        handleSubmit={() => {
          setOpenStep(0);
          handleCloseDeleteModal();
        }}
        open={openDeleteModal && openStep === 3}
      />
    </form>
  );
};

export default DeleteAccount;
