import { styled } from '@mui/system';
import { Box, Grid } from '@mui/material';
import background from '../../../../shared/images/BackgroundPatternDecorativeSharedLinkUnavailable.svg';

const SharedLinkUnavailableWrapper = styled(Box)`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${background});
  background-position: top;
  background-repeat: no-repeat;

  justify-content: center;
`;

const SharedLinkUnavailableContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Head = styled('div')`
  color: #101828;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: -0.64px;
  margin-top: 24px;
`;

const Description = styled('div')`
  color: #475467;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 48px;
  margin-top: 24px;
`;

export {
  SharedLinkUnavailableWrapper,
  SharedLinkUnavailableContainer,
  Head,
  Description,
};
