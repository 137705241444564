import React, { useMemo } from 'react';
import { Grid } from '@mui/material';

import { GrayButtonCustom } from '../../../../../../../../shared/buttons/styles';
import DownloadIcon from '../../../../../../../../shared/images/DownloadIcon';
import { useAuth, useKeywordRankHistory } from '../../../../../../../../hooks';
import { useParams } from 'react-router';
import { decryptData } from '../../../../../../../../utils/cryptoJs';
import { useAppSelector } from '../../../../../../../../store';

const ExportCSV = () => {
  const { keywordId } = useParams();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const { onExportToCsvKeywordPositionsInfo } = useKeywordRankHistory();

  const decryptKeywordId = useMemo(() => {
    if (keywordId) {
      return decryptData(keywordId);
    }
  }, [keywordId]);

  const handleExportToCsvKeywordPositionsInfo = () => {
    if (currentAccount?.accountId) {
      onExportToCsvKeywordPositionsInfo({
        id: currentAccount.accountId,
        keywordId: Number(decryptKeywordId),
      });
    }
  };
  return (
    <Grid paddingBottom='24px' paddingLeft='16px' paddingRight='16px'>
      <GrayButtonCustom
        $padding='7px 12px'
        size='small'
        outline
        startIcon={<DownloadIcon />}
        onClick={handleExportToCsvKeywordPositionsInfo}
      >
        Export CSV
      </GrayButtonCustom>
    </Grid>
  );
};

export default ExportCSV;
