import { Grid } from '@mui/material';
import React, { useMemo } from 'react';
import { Progress } from './components';
import { StyledAccountContainer, Title } from './syles';
import { useAppSelector } from '../../../../store';
import { getUsed } from './components/Progress/Progress';

const AccountUsage = () => {
  const accountLimit = useAppSelector(state => state.auth.accountLimit);

  const numberOfDailyUpdatesOfKeywordPositions = useMemo(
    () =>
      accountLimit?.defaultAccountLimits
        ?.numberOfDailyUpdatesOfKeywordPositions || 0,
    [accountLimit?.defaultAccountLimits?.numberOfDailyUpdatesOfKeywordPositions]
  );
  const currentNumberOfDailyUpdatesOfKeywordPositions = useMemo(
    () =>
      accountLimit?.balanceAccountLimits
        ?.currentNumberOfDailyUpdatesOfKeywordPositions || 0,
    [
      accountLimit?.balanceAccountLimits
        ?.currentNumberOfDailyUpdatesOfKeywordPositions,
    ]
  );

  const numberOfKeywords = useMemo(
    () => accountLimit?.accountLimitsUsed?.numberOfKeywords || 0,
    [accountLimit?.accountLimitsUsed?.numberOfKeywords]
  );

  return (
    <Grid item xs={12} md={6} mt={{ xs: 2, md: 0 }}>
      <StyledAccountContainer container xs={12}>
        <Title item xs={12}>
          Account Usage
        </Title>
        <Progress
          title='Daily refresh used'
          value={getUsed(
            numberOfDailyUpdatesOfKeywordPositions,
            currentNumberOfDailyUpdatesOfKeywordPositions
          )}
          maxValue={numberOfDailyUpdatesOfKeywordPositions}
        />
        <Progress
          title='Keyword limit'
          value={numberOfKeywords}
          maxValue={numberOfDailyUpdatesOfKeywordPositions}
        />
      </StyledAccountContainer>
    </Grid>
  );
};

export default AccountUsage;
