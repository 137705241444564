import { AllNotesData, ProjectNotesData } from '../types';
import { createSlice } from '@reduxjs/toolkit';
import { getAllNotesThunk, getProjectNotesThunk } from './thunk';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import {
  resetNotesState,
  setAllNotesPageSize,
  setAllNotesPaginationPage,
  setDeleteElementsAllNotesTable,
  setDeleteElementsProjectNotesTable,
  setProjectNotesPageSize,
  setProjectNotesPaginationPage,
  setRowSelectionModelAllNotes,
  setRowSelectionModelProjectNotes,
  setSearchAllNotes,
  setSearchProjectNotes,
  setSortModelAllNotes,
  setSortModelProjectNotes,
} from './actions';
import { getPage } from '../../../utils';

const initialState = {
  isLoading: false,
  projectNotesData: null as ProjectNotesData | null,
  allNotesData: null as AllNotesData | null,
  tables: {
    projectNotes: {
      search: '',
      sortModel: [{ field: 'date', sort: 'desc' }] as GridSortModel,
      paginationModel: {
        pageSize: 10,
        page: 0,
      },
      rowSelectionModel: [] as GridRowSelectionModel,
      deleteElements: [] as GridRowSelectionModel,
    },
    allNotes: {
      search: '',
      sortModel: [{ field: 'date', sort: 'desc' }] as GridSortModel,
      paginationModel: {
        pageSize: 10,
        page: 0,
      },
      rowSelectionModel: [] as GridRowSelectionModel,
      deleteElements: [] as GridRowSelectionModel,
    },
  },
};

export const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getProjectNotesThunk.pending, state => {
      state.isLoading = true;
      state.projectNotesData = null;
    });
    builder.addCase(getProjectNotesThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.projectNotesData = payload;
      state.tables.projectNotes.paginationModel.page = getPage(
        state.tables.projectNotes.paginationModel.page,
        payload.meta.currentPage,
        payload.meta.totalPages
      );
    });
    builder.addCase(getProjectNotesThunk.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(setProjectNotesPageSize, (state, { payload }) => {
      state.tables.projectNotes.paginationModel.pageSize = payload;
    });

    builder.addCase(setProjectNotesPaginationPage, (state, { payload }) => {
      state.tables.projectNotes.paginationModel.page = payload;
    });

    builder.addCase(setSortModelProjectNotes, (state, { payload }) => {
      state.tables.projectNotes.sortModel = payload;
    });

    builder.addCase(setRowSelectionModelProjectNotes, (state, { payload }) => {
      state.tables.projectNotes.rowSelectionModel = payload;
    });

    builder.addCase(setSearchProjectNotes, (state, { payload }) => {
      state.tables.projectNotes.search = payload;
    });

    builder.addCase(getAllNotesThunk.pending, state => {
      state.allNotesData = null;
    });
    builder.addCase(getAllNotesThunk.fulfilled, (state, { payload }) => {
      state.allNotesData = payload;
      state.tables.allNotes.paginationModel.page = getPage(
        state.tables.allNotes.paginationModel.page,
        payload.meta.currentPage,
        payload.meta.totalPages
      );
    });

    builder.addCase(setAllNotesPaginationPage, (state, { payload }) => {
      state.tables.allNotes.paginationModel.page = payload;
    });

    builder.addCase(setAllNotesPageSize, (state, { payload }) => {
      state.tables.allNotes.paginationModel.pageSize = payload;
    });

    builder.addCase(setSortModelAllNotes, (state, { payload }) => {
      state.tables.allNotes.sortModel = payload;
    });

    builder.addCase(setRowSelectionModelAllNotes, (state, { payload }) => {
      state.tables.allNotes.rowSelectionModel = payload;
    });

    builder.addCase(setSearchAllNotes, (state, { payload }) => {
      state.tables.allNotes.search = payload;
    });

    builder.addCase(resetNotesState, state => {
      return (state = initialState);
    });

    builder.addCase(setDeleteElementsAllNotesTable, (state, { payload }) => {
      state.tables.allNotes.deleteElements = payload.deleteElements;
    });

    builder.addCase(
      setDeleteElementsProjectNotesTable,
      (state, { payload }) => {
        state.tables.projectNotes.deleteElements = payload.deleteElements;
      }
    );
  },
});

export default notesSlice.reducer;
