import React, { useEffect, useState } from 'react';
import {
  Fade,
  FormHelperText,
  Grid,
  Modal,
  useMediaQuery,
} from '@mui/material';
import { FormikProvider } from 'formik';
import { ProjectModalProps } from '../../../../types';

import { CustomFormHelperText } from '../../../../../../shared/components';
import { ButtonsWrapper } from '../../../../../auth/components/Settings/components/Team/components/customTableStyles';
import MainSettings from './MainSettings';
import AdvancedSettings from './AdvancedSettings';

import { ModalContainer, ModalWrapper } from './styles';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../../../shared/buttons/styles';

const ProjectModalContainer: React.FC<ProjectModalProps> = ({
  formik,
  open,
  handleClose,
  isOpenAdvancedSettings,
  onToggleAdvancedSettings,
  children,
  isNew,
  disableEditProject,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const onClose = () => {
    handleClose();
    formik.resetForm();
  };

  const [isBulkKeywords, setIsBulkKeywords] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);
  const [openTooltip, setOpenTooltip] = useState<number | undefined>();

  const onToggleKeywords = () => setIsBulkKeywords(!isBulkKeywords);

  const onOpenTooltip = (tooltipIndex?: number) => {
    setShowTooltip(true);
    setOpenTooltip(tooltipIndex);
  };

  const onClickTooltip = (tooltipIndex?: number) => {
    if (showTooltip) {
      setShowTooltip(false);
      setOpenTooltip(undefined);
    } else {
      setShowTooltip(true);
      setOpenTooltip(tooltipIndex);
    }
  };

  useEffect(() => {
    const clearedEmpty = formik.values.keywords.filter(item => /\S/.test(item));
    if (clearedEmpty.length) {
      formik.setFieldValue('keywords', clearedEmpty);
    } else {
      formik.setFieldValue('keywords', ['']);
    }
  }, [isBulkKeywords]);

  return (
    <Modal
      aria-labelledby='project-modal-title'
      aria-describedby='project-modal-description'
      open={open}
      onClose={onClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <form onSubmit={formik.handleSubmit}>
          <FormikProvider value={formik}>
            <ModalWrapper className={'moveToFolder'}>
              <ModalContainer container xs={12} sx={{ gap: '8px' }}>
                {children}

                <MainSettings
                  isBulkKeywords={isBulkKeywords}
                  onToggleKeywords={onToggleKeywords}
                  isOpenAdvancedSettings={isOpenAdvancedSettings}
                  onToggleAdvancedSettings={onToggleAdvancedSettings}
                  showTooltip={showTooltip}
                  openTooltip={openTooltip}
                  onClickTooltip={onClickTooltip}
                  onOpenTooltip={onOpenTooltip}
                  formik={formik}
                  isNew={isNew}
                />

                <AdvancedSettings
                  showTooltip={showTooltip}
                  openTooltip={openTooltip}
                  onClickTooltip={onClickTooltip}
                  onOpenTooltip={onOpenTooltip}
                  formik={formik}
                  isNew={isNew}
                  isOpenAdvancedSettings={isOpenAdvancedSettings}
                />

                {formik.errors.message && (
                  <Grid item xs={12}>
                    <FormHelperText
                      error={!!formik.errors.message}
                      id='message'
                    >
                      {formik.errors.message && (
                        <CustomFormHelperText error={formik.errors.message} />
                      )}
                    </FormHelperText>
                  </Grid>
                )}

                <ButtonsWrapper container xs={12} sx={{ paddingTop: '8px' }}>
                  <Grid item xs={extraSmallScreen && 12}>
                    <GrayButtonSmall
                      fullWidth={extraSmallScreen}
                      size='small'
                      outline
                      onClick={handleClose}
                    >
                      Cancel
                    </GrayButtonSmall>
                  </Grid>
                  <Grid item xs={extraSmallScreen && 12}>
                    <PrimaryButtonSmall
                      fullWidth={extraSmallScreen}
                      variant='contained'
                      size='small'
                      type='submit'
                      disabled={
                        formik.isSubmitting ||
                        !formik.isValid ||
                        disableEditProject
                      }
                    >
                      Save Project
                    </PrimaryButtonSmall>
                  </Grid>
                </ButtonsWrapper>
              </ModalContainer>
            </ModalWrapper>
          </FormikProvider>
        </form>
      </Fade>
    </Modal>
  );
};

export default ProjectModalContainer;
