import React, { useState } from 'react';
import {
  PasswordStateLabel,
  Wrapper,
} from '../../../auth/components/RegisterForm/styles';
import { Paper } from '../../../../shared/paper/styles';
import { PasswordTitleContainer } from '../../../auth/components/Login/styles';
import {
  Box,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { CustomFormHelperText } from '../../../../shared/components';
import { PrimaryButton } from '../../../../shared/buttons/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DescriptionTypography, HeadTypography } from './styles';
import { useNavigate, useParams } from 'react-router';
import { saveSharedLinkAccessToken } from '../../../../utils/localStorage';
import { useSharedLinks } from '../../../../hooks';
import ROUTES from '../../../../routes/constants';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const SharedLinkLogin = () => {
  const navigate = useNavigate();

  const { sharedLinkToken } = useParams();
  const { onLoginThunk } = useSharedLinks();

  const formik = useFormik({
    initialValues: {
      password: '',
      message: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().max(255).required('Password is required.'),
    }),
    onSubmit: (values, { setErrors, setStatus, setSubmitting }) => {
      const requestData = {
        link: sharedLinkToken as string,
        password: values.password,
        successFn: (accessToken: string) => {
          saveSharedLinkAccessToken(accessToken);
          navigate(ROUTES.dynamic.sharedLinkContents(sharedLinkToken));
        },
        errorFn: (error: any) => {
          setStatus({ success: false });
          setSubmitting(false);
          setErrors(error);
        },
      };
      onLoginThunk(requestData);
    },
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Wrapper>
      <Grid container xs={12} display={'flex'} justifyContent={'center'}>
        <Grid xs={10} lg={4} md={8}>
          <Paper
            sx={{
              p: 4,
            }}
          >
            <HeadTypography>Enter Password to Access Link</HeadTypography>
            <DescriptionTypography>
              This link is secured. Please enter the password provided to you by
              the link owner.
            </DescriptionTypography>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={2} sx={{ mb: '16px' }}>
                  <Grid item xs={12}>
                    <PasswordTitleContainer>
                      <div>
                        <InputLabel shrink htmlFor='password'>
                          Password
                        </InputLabel>
                      </div>
                    </PasswordTitleContainer>
                    <OutlinedInput
                      fullWidth
                      id='password'
                      type={showPassword ? 'text' : 'password'}
                      value={formik.values.password}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        !!(formik.touched.password && formik.errors.password)
                      }
                      placeholder='Password'
                      name='password'
                      endAdornment={
                        <InputAdornment
                          position='end'
                          onClick={handleClickShowPassword}
                          sx={{ cursor: 'pointer' }}
                        >
                          <IconButton
                            aria-label='toggle password visibility'
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                          <PasswordStateLabel>
                            {showPassword ? <b>Hide</b> : <b>Show</b>}
                          </PasswordStateLabel>
                        </InputAdornment>
                      }
                    />
                    {
                      <FormHelperText
                        error={
                          !!(formik.touched.password && formik.errors.password)
                        }
                        id='password'
                      >
                        {formik.touched.password && formik.errors.password && (
                          <CustomFormHelperText
                            error={formik.errors.password}
                          />
                        )}
                      </FormHelperText>
                    }
                  </Grid>
                </Grid>
                <PrimaryButton
                  size={'large'}
                  type='submit'
                  fullWidth
                  variant='contained'
                  color={'primary'}
                  disabled={
                    formik.isSubmitting || !(formik.isValid && formik.dirty)
                  }
                >
                  Access
                </PrimaryButton>
                {
                  <FormHelperText error={!!formik.errors.message} id='password'>
                    {formik.errors.message && (
                      <CustomFormHelperText error={formik.errors.message} />
                    )}
                  </FormHelperText>
                }
              </Box>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default SharedLinkLogin;
