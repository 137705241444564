import React, { useEffect, useMemo } from 'react';

import { StyledArrow, StyledKeywordRankingsTable } from './styles';
import { Header } from './components';
import Table from './components/Table/Table';
import { useProjects } from '../../../../../../../../hooks';
import { useParams } from 'react-router';
import KeywordRankingsTableArrowIcon from '../../../../../../../../shared/images/KeywordRankingsTableArrowIcon';
import { checkObjectProperties } from '../../../../../../utils';
import { DEFAULT_KEYWORD_POSITIONS_MODEL } from '../../../../../../constants';
import { decryptData } from '../../../../../../../../utils/cryptoJs';
import { useAppSelector } from '../../../../../../../../store';
import { KeywordRankingsTableProps } from '../types';

const KeywordRankingsTable: React.FC<KeywordRankingsTableProps> = ({
  apiRef,
  containerRef,
  updatedKeywordsTrigger,
  onSetUpdatedKeywordsTrigger,
}) => {
  const { id } = useParams();

  const decryptId = useMemo(() => {
    if (id) {
      return decryptData(id);
    }
  }, [id]);

  const { onGetKeywordRankings } = useProjects();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const deviceType = useAppSelector(
    state => state.projects.projectExpandedGraphs.deviceType
  );
  const keywordPositionModel = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table
        .keywordPositionModel
  );
  const paginationModel = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table.paginationModel
  );
  const sortModel = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table.sortModel
  );
  const search = useAppSelector(
    state => state.projects.projectExpandedGraphs.keywordRankings.table.search
  );
  const projectPositionHistory = useAppSelector(
    state => state.projects.projectExpandedGraphs.projectPositionHistory
  );
  const keywordSelectedTags = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table.selectedTags
  );
  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );

  const memoKeywordSelectedTags = useMemo(
    () => keywordSelectedTags.map(item => item.id),
    [keywordSelectedTags]
  );

  useEffect(() => {
    if (currentAccount.accountId && projectExpanded?.id && deviceType) {
      onGetKeywordRankings({
        accountId: currentAccount.accountId,
        projectId: projectExpanded.id,
        params: {
          page: paginationModel.page,
          limit: paginationModel.pageSize,
          search: search,
          sortBy: sortModel[0]?.field,
          sortOrder: sortModel[0]?.sort,
        },
        filters: checkObjectProperties(
          DEFAULT_KEYWORD_POSITIONS_MODEL,
          keywordPositionModel
        ),
        deviceType: deviceType,
        tagIds: memoKeywordSelectedTags,
      });
    }
  }, [
    currentAccount.accountId,
    onGetKeywordRankings,
    projectExpanded?.id,
    search,
    sortModel,
    paginationModel.page,
    paginationModel.pageSize,
    keywordPositionModel,
    deviceType,
    memoKeywordSelectedTags,
  ]);

  return (
    <StyledKeywordRankingsTable>
      <Header />
      <Table
        apiRef={apiRef}
        containerRef={containerRef}
        updatedKeywordsTrigger={updatedKeywordsTrigger}
        onSetUpdatedKeywordsTrigger={onSetUpdatedKeywordsTrigger}
      />
      {projectPositionHistory && (
        <StyledArrow>
          <KeywordRankingsTableArrowIcon />
        </StyledArrow>
      )}
    </StyledKeywordRankingsTable>
  );
};

export default KeywordRankingsTable;
