import React from 'react';

const TrialExpiredModalIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='200'
    height='188'
    viewBox='0 0 200 188'
    fill='none'
  >
    <g clipPath='url(#clip0_9186_77286)'>
      <path
        d='M184.881 143.357L193.853 144.762C196.89 141.078 198.983 136.707 199.952 132.029C193.391 137.409 183.414 134.55 175.459 137.487C172.985 138.422 170.752 139.903 168.927 141.82C167.102 143.737 165.731 146.041 164.915 148.561L160.531 153.286C164.5 154.86 168.762 155.554 173.025 155.319C177.287 155.084 181.448 153.926 185.22 151.925C187.326 150.781 189.279 149.376 191.034 147.744C187.895 145.764 184.881 143.357 184.881 143.357Z'
        fill='#F2F2F2'
      />
      <path
        d='M181.696 128.981L190.351 126.23C191.43 121.576 191.358 116.729 190.142 112.109C186.665 119.856 176.462 121.754 170.65 127.937C168.851 129.879 167.513 132.202 166.732 134.733C165.952 137.264 165.751 139.939 166.142 142.559L164.322 148.747C168.575 148.383 172.699 147.099 176.409 144.985C180.12 142.87 183.328 139.974 185.814 136.498C187.189 134.534 188.312 132.404 189.156 130.159C185.465 129.789 181.696 128.981 181.696 128.981Z'
        fill='#F2F2F2'
      />
      <path
        d='M200 156.148C200 156.194 199.991 156.24 199.973 156.283C199.956 156.326 199.93 156.365 199.897 156.398C199.864 156.43 199.826 156.456 199.783 156.474C199.74 156.492 199.694 156.501 199.648 156.501H0.352244C0.258823 156.501 0.169227 156.463 0.103169 156.397C0.0371107 156.331 0 156.241 0 156.148C0 156.054 0.0371107 155.964 0.103169 155.898C0.169227 155.832 0.258823 155.795 0.352244 155.795H199.648C199.694 155.795 199.74 155.804 199.783 155.822C199.826 155.839 199.864 155.865 199.897 155.898C199.93 155.931 199.956 155.97 199.973 156.013C199.991 156.055 200 156.101 200 156.148Z'
        fill='#CCCCCC'
      />
      <path
        d='M82.5841 141.105C117.732 141.105 146.225 112.562 146.225 77.3523C146.225 42.1426 117.732 13.5996 82.5841 13.5996C47.4364 13.5996 18.9436 42.1426 18.9436 77.3523C18.9436 112.562 47.4364 141.105 82.5841 141.105Z'
        fill='#E6E6E6'
      />
      <path
        d='M137.344 77.353C137.351 85.9127 135.355 94.3548 131.516 102.002C127.678 109.65 122.104 116.29 115.242 121.39C114.783 121.734 114.315 122.069 113.845 122.395C104.668 128.785 93.7598 132.21 82.5839 132.21C71.4081 132.21 60.4997 128.785 51.3231 122.395C50.8525 122.069 50.3848 121.734 49.926 121.39C43.0683 116.285 37.4979 109.644 33.6601 101.998C29.8223 94.3515 27.8235 85.9116 27.8235 77.353C27.8235 68.7945 29.8223 60.3546 33.6601 52.7081C37.4979 45.0617 43.0683 38.4208 49.926 33.3162C50.3848 32.9723 50.8525 32.6372 51.3231 32.311C60.4997 25.921 71.4081 22.4961 82.5839 22.4961C93.7598 22.4961 104.668 25.921 113.845 32.311C114.315 32.6372 114.783 32.9723 115.242 33.3162C122.104 38.4163 127.678 45.0563 131.516 52.7037C135.355 60.3512 137.351 68.7933 137.344 77.353Z'
        fill='white'
      />
      <path
        d='M82.584 127.465C110.212 127.465 132.608 105.029 132.608 77.3528C132.608 49.6764 110.212 27.2402 82.584 27.2402C54.9563 27.2402 32.5596 49.6764 32.5596 77.3528C32.5596 105.029 54.9563 127.465 82.584 127.465Z'
        fill='#A0C5F6'
      />
      <path
        opacity='0.2'
        d='M82.7144 27.3789C81.9561 27.3789 81.2024 27.3988 80.4523 27.4322C93.0957 28.5452 104.844 34.4311 113.317 43.8974C121.79 53.3637 126.354 65.7026 126.085 78.4142C125.816 91.1258 120.733 103.26 111.867 112.357C103 121.455 91.0131 126.836 78.334 127.411C79.7778 127.536 81.2381 127.604 82.7144 127.604C95.9817 127.604 108.706 122.324 118.087 112.926C127.468 103.528 132.739 90.7821 132.739 77.4915C132.739 64.2008 127.468 51.4545 118.087 42.0565C108.706 32.6586 95.9817 27.3789 82.7144 27.3789Z'
        fill='black'
      />
      <path
        d='M82.732 23.6816C82.3005 23.6824 81.8868 23.8545 81.5817 24.1602C81.2765 24.4658 81.1048 24.8802 81.104 25.3125V32.4291C81.104 32.8616 81.2755 33.2765 81.5808 33.5823C81.8861 33.8882 82.3002 34.06 82.732 34.06C83.1638 34.06 83.5779 33.8882 83.8832 33.5823C84.1885 33.2765 84.36 32.8616 84.36 32.4291V25.3125C84.3593 24.8802 84.1875 24.4658 83.8824 24.1602C83.5772 23.8545 83.1636 23.6824 82.732 23.6816Z'
        fill='white'
      />
      <path
        d='M115.242 33.3153C114.783 32.9713 114.315 32.6363 113.845 32.3101C113.562 32.2917 113.28 32.3473 113.026 32.4716C112.771 32.5959 112.554 32.7845 112.394 33.0188L108.395 38.8989C108.152 39.2563 108.061 39.6958 108.142 40.1206C108.222 40.5455 108.468 40.9209 108.825 41.1643C109.181 41.4077 109.62 41.4991 110.044 41.4185C110.468 41.3379 110.843 41.0918 111.086 40.7343L115.085 34.8543C115.237 34.6312 115.331 34.3735 115.359 34.1048C115.386 33.836 115.346 33.5646 115.242 33.3153Z'
        fill='white'
      />
      <path
        d='M133.411 60.127C133.33 59.9288 133.212 59.7483 133.061 59.596C132.911 59.4437 132.733 59.3225 132.536 59.2394C132.339 59.1562 132.128 59.1127 131.914 59.1114C131.701 59.11 131.489 59.1509 131.291 59.2315L124.708 61.9091C124.308 62.0722 123.99 62.3875 123.822 62.7858C123.655 63.1841 123.652 63.6327 123.814 64.0332C123.976 64.4336 124.291 64.7531 124.688 64.9214C125.086 65.0897 125.534 65.0931 125.934 64.9307L132.517 62.2531C132.916 62.0892 133.234 61.7735 133.402 61.375C133.569 60.9765 133.573 60.5278 133.411 60.127Z'
        fill='white'
      />
      <path
        d='M56.7727 38.8989L52.7737 33.0188C52.6143 32.7845 52.3967 32.5959 52.1424 32.4716C51.888 32.3473 51.6057 32.2917 51.3233 32.3101C50.8526 32.6363 50.3849 32.9713 49.9261 33.3153C49.8221 33.5646 49.782 33.836 49.8094 34.1048C49.8368 34.3735 49.9309 34.6312 50.083 34.8543L54.082 40.7343C54.325 41.0918 54.6997 41.3379 55.1238 41.4185C55.5479 41.4991 55.9867 41.4077 56.3435 41.1643C56.7003 40.9209 56.9459 40.5455 57.0264 40.1206C57.1069 39.6958 57.0156 39.2563 56.7727 38.8989Z'
        fill='white'
      />
      <path
        d='M40.4599 61.9088L33.8768 59.2311C33.4768 59.0684 33.0287 59.0716 32.631 59.2398C32.2334 59.408 31.9187 59.7276 31.7562 60.1282C31.5937 60.5289 31.5967 60.9778 31.7646 61.3762C31.9325 61.7746 32.2514 62.0899 32.6513 62.2527L39.2344 64.9303C39.6343 65.0926 40.0822 65.0893 40.4796 64.921C40.877 64.7527 41.1915 64.4332 41.3539 64.0328C41.5163 63.6323 41.5134 63.1837 41.3457 62.7854C41.1781 62.3871 40.8595 62.0718 40.4599 61.9088Z'
        fill='white'
      />
      <path
        d='M82.732 120.645C82.3005 120.645 81.8868 120.817 81.5817 121.123C81.2765 121.429 81.1048 121.843 81.104 122.275V129.392C81.104 129.825 81.2755 130.239 81.5808 130.545C81.8861 130.851 82.3002 131.023 82.732 131.023C83.1638 131.023 83.5779 130.851 83.8832 130.545C84.1885 130.239 84.36 129.825 84.36 129.392V122.275C84.3593 121.843 84.1875 121.429 83.8824 121.123C83.5772 120.817 83.1636 120.645 82.732 120.645Z'
        fill='white'
      />
      <path
        d='M115.085 119.851L111.086 113.971C110.966 113.794 110.812 113.642 110.633 113.525C110.454 113.407 110.254 113.326 110.044 113.286C109.834 113.246 109.619 113.248 109.409 113.292C109.2 113.336 109.001 113.42 108.825 113.541C108.648 113.661 108.497 113.815 108.38 113.994C108.263 114.173 108.182 114.374 108.142 114.584C108.102 114.795 108.104 115.011 108.147 115.22C108.191 115.43 108.275 115.629 108.396 115.806L112.395 121.686C112.554 121.92 112.772 122.109 113.026 122.233C113.28 122.358 113.563 122.413 113.845 122.395C114.316 122.069 114.783 121.734 115.242 121.39C115.346 121.14 115.386 120.869 115.359 120.6C115.331 120.331 115.237 120.074 115.085 119.851Z'
        fill='white'
      />
      <path
        d='M132.517 92.4498L125.934 89.7722C125.534 89.6095 125.086 89.6126 124.688 89.7808C124.29 89.949 123.976 90.2686 123.813 90.6692C123.651 91.0699 123.654 91.5188 123.821 91.9172C123.989 92.3156 124.308 92.6309 124.708 92.7937L131.291 95.4713C131.691 95.6337 132.139 95.6303 132.536 95.462C132.934 95.2937 133.248 94.9742 133.411 94.5738C133.573 94.1733 133.57 93.7247 133.403 93.3264C133.235 92.9281 132.916 92.6128 132.517 92.4498Z'
        fill='white'
      />
      <path
        d='M56.3434 113.54C55.9866 113.297 55.5479 113.206 55.1239 113.286C54.6998 113.367 54.325 113.613 54.082 113.97L50.083 119.85C49.9309 120.074 49.8368 120.331 49.8094 120.6C49.782 120.869 49.8221 121.14 49.9261 121.389C50.3849 121.733 50.8526 122.068 51.3233 122.395C51.6057 122.413 51.888 122.357 52.1424 122.233C52.3967 122.109 52.6143 121.92 52.7737 121.686L56.7727 115.806C56.893 115.629 56.9773 115.43 57.0209 115.22C57.0644 115.011 57.0663 114.795 57.0265 114.584C56.9866 114.374 56.9058 114.173 56.7886 113.994C56.6714 113.815 56.5201 113.661 56.3434 113.54Z'
        fill='white'
      />
      <path
        d='M41.3537 90.6681C41.2733 90.4698 41.1547 90.2894 41.0046 90.1371C40.8546 89.9847 40.676 89.8635 40.4792 89.7804C40.2824 89.6972 40.0711 89.6537 39.8575 89.6524C39.6439 89.651 39.4321 89.6919 39.2343 89.7725L32.6512 92.4502C32.2516 92.6132 31.933 92.9285 31.7653 93.3268C31.5977 93.7251 31.5948 94.1737 31.7572 94.5742C31.9196 94.9746 32.234 95.2941 32.6315 95.4624C33.0289 95.6307 33.4768 95.6341 33.8766 95.4717L40.4597 92.7941C40.8591 92.6302 41.1775 92.3145 41.345 91.916C41.5126 91.5176 41.5157 91.0688 41.3537 90.6681Z'
        fill='white'
      />
      <path
        d='M84.8445 76.5427C84.9948 77.4342 84.786 78.3489 84.2639 79.0864C83.7418 79.8238 82.949 80.3236 82.0594 80.4763L53.1702 85.3989C52.7295 85.474 52.2783 85.4614 51.8425 85.3619C51.4067 85.2623 50.9947 85.0777 50.63 84.8187C50.2654 84.5596 49.9553 84.2311 49.7174 83.852C49.4795 83.4729 49.3185 83.0505 49.2435 82.609C49.1686 82.1676 49.1811 81.7156 49.2805 81.279C49.3799 80.8424 49.5642 80.4297 49.8228 80.0644C50.0814 79.6992 50.4093 79.3885 50.7878 79.1502C51.1662 78.9119 51.5878 78.7506 52.0285 78.6755L80.9178 73.7528C81.8077 73.6022 82.7209 73.8114 83.457 74.3344C84.1931 74.8574 84.6921 75.6516 84.8445 76.5427Z'
        fill='#3F3D56'
      />
      <path
        d='M115.25 108.606C114.835 109.025 114.271 109.261 113.682 109.263C113.094 109.265 112.528 109.034 112.11 108.619L81.0112 77.7094C80.5938 77.2939 80.3583 76.7293 80.3562 76.1399C80.3542 75.5504 80.5858 74.9842 81.0003 74.5658C81.4147 74.1473 81.978 73.9108 82.5665 73.9082C83.1549 73.9056 83.7203 74.1372 84.1384 74.552L115.237 105.461C115.655 105.877 115.89 106.442 115.893 107.031C115.895 107.621 115.664 108.187 115.25 108.606Z'
        fill='#3F3D56'
      />
      <path
        d='M82.5841 81.2057C84.7093 81.2057 86.4321 79.4799 86.4321 77.3509C86.4321 75.222 84.7093 73.4961 82.5841 73.4961C80.4589 73.4961 78.7361 75.222 78.7361 77.3509C78.7361 79.4799 80.4589 81.2057 82.5841 81.2057Z'
        fill='#CCCCCC'
      />
      <path
        d='M56.0789 10.5107C50.1722 19.8134 41.1599 25.9871 30.0455 30.0429C29.8259 30.2077 29.576 30.3275 29.3102 30.3955C29.0443 30.4636 28.7677 30.4785 28.4961 30.4394C28.2245 30.4004 27.9632 30.3081 27.7272 30.1679C27.4912 30.0277 27.2851 29.8423 27.1206 29.6223C21.0833 21.5472 22.4465 10.0063 30.4144 3.83602C32.3408 2.34685 34.5432 1.25583 36.8939 0.626321C39.2446 -0.00319275 41.6968 -0.158668 44.1079 0.168925C46.5191 0.496518 48.8413 1.30065 50.9397 2.53464C53.0381 3.76863 54.8709 5.4079 56.3318 7.35727L56.4988 7.58062C56.6632 7.80063 56.7829 8.05094 56.8508 8.31726C56.9187 8.58358 56.9336 8.8607 56.8946 9.13278C56.8556 9.40487 56.7635 9.6666 56.6235 9.90303C56.4836 10.1395 56.2985 10.346 56.0789 10.5107Z'
        fill='#E6E6E6'
      />
      <path
        d='M108.836 7.35727C110.297 5.4079 112.13 3.76863 114.229 2.53464C116.327 1.30065 118.649 0.496519 121.06 0.168926C123.471 -0.158667 125.924 -0.00319601 128.274 0.626318C130.625 1.25583 132.827 2.34685 134.754 3.83602C142.722 10.0063 144.085 21.5472 138.048 29.6223C137.883 29.8423 137.677 30.0277 137.441 30.1679C137.205 30.3081 136.944 30.4004 136.672 30.4394C136.401 30.4785 136.124 30.4636 135.858 30.3955C135.592 30.3275 135.342 30.2077 135.123 30.0429C124.805 26.0857 116.344 19.2377 109.089 10.5107C108.646 10.1779 108.352 9.68227 108.274 9.13277C108.195 8.58327 108.337 8.02494 108.669 7.58061L108.836 7.35727Z'
        fill='#E6E6E6'
      />
      <path
        d='M106.247 132.586C105.871 132.797 105.594 133.15 105.477 133.566C105.36 133.982 105.412 134.428 105.622 134.805L115.017 151.625C115.228 152.002 115.58 152.28 115.996 152.398C116.411 152.515 116.856 152.462 117.233 152.251C117.61 152.04 117.887 151.688 118.005 151.271C118.122 150.855 118.07 150.409 117.859 150.032L108.463 133.212C108.252 132.835 107.9 132.558 107.485 132.44C107.069 132.323 106.624 132.375 106.247 132.586Z'
        fill='#E6E6E6'
      />
      <path
        d='M56.7049 133.212L47.3092 150.031C47.0985 150.409 47.046 150.855 47.1634 151.271C47.2807 151.687 47.5583 152.039 47.9351 152.251C48.3118 152.462 48.7568 152.514 49.1723 152.397C49.5878 152.28 49.9397 152.002 50.1506 151.624L59.5463 134.805C59.6507 134.618 59.7174 134.412 59.7425 134.199C59.7676 133.987 59.7506 133.771 59.6925 133.565C59.6345 133.359 59.5364 133.166 59.404 132.998C59.2716 132.83 59.1074 132.689 58.9208 132.585C58.7342 132.48 58.5288 132.413 58.3165 132.388C58.1041 132.363 57.8889 132.38 57.6831 132.438C57.4774 132.497 57.2851 132.595 57.1172 132.728C56.9494 132.86 56.8092 133.025 56.7049 133.212Z'
        fill='#E6E6E6'
      />
      <path
        d='M116.92 155.929C119.7 155.929 121.953 153.673 121.953 150.889C121.953 148.105 119.7 145.848 116.92 145.848C114.141 145.848 111.888 148.105 111.888 150.889C111.888 153.673 114.141 155.929 116.92 155.929Z'
        fill='#E6E6E6'
      />
      <path
        d='M120.472 26.9431C123.251 26.9431 125.504 24.6863 125.504 21.9022C125.504 19.1182 123.251 16.8613 120.472 16.8613C117.693 16.8613 115.44 19.1182 115.44 21.9022C115.44 24.6863 117.693 26.9431 120.472 26.9431Z'
        fill='#E6E6E6'
      />
      <path
        d='M82.2881 15.0818C85.0673 15.0818 87.3202 12.8249 87.3202 10.0409C87.3202 7.25689 85.0673 5 82.2881 5C79.509 5 77.2561 7.25689 77.2561 10.0409C77.2561 12.8249 79.509 15.0818 82.2881 15.0818Z'
        fill='#E6E6E6'
      />
      <path
        d='M45.8797 26.3494C48.6588 26.3494 50.9117 24.0925 50.9117 21.3085C50.9117 18.5245 48.6588 16.2676 45.8797 16.2676C43.1006 16.2676 40.8477 18.5245 40.8477 21.3085C40.8477 24.0925 43.1006 26.3494 45.8797 26.3494Z'
        fill='#E6E6E6'
      />
      <path
        d='M49.1358 155.929C51.9149 155.929 54.1678 153.673 54.1678 150.889C54.1678 148.105 51.9149 145.848 49.1358 145.848C46.3567 145.848 44.1038 148.105 44.1038 150.889C44.1038 153.673 46.3567 155.929 49.1358 155.929Z'
        fill='#E6E6E6'
      />
      <path
        d='M112.973 105.771C113.172 106.082 113.436 106.346 113.747 106.544C114.058 106.742 114.409 106.869 114.774 106.916C115.14 106.963 115.511 106.93 115.862 106.818C116.213 106.706 116.536 106.518 116.807 106.268L124.703 110.506L124.279 105.862L116.846 102.55C116.396 102.122 115.805 101.874 115.185 101.853C114.565 101.832 113.959 102.04 113.481 102.437C113.004 102.834 112.689 103.393 112.595 104.007C112.501 104.622 112.636 105.249 112.973 105.771Z'
        fill='#FFB7B7'
      />
      <path
        d='M118.727 107.392L121.821 103.113L136.727 110.172L155.277 106.686C156.194 106.514 157.137 106.546 158.041 106.78C158.944 107.014 159.784 107.444 160.503 108.04C161.221 108.636 161.8 109.384 162.198 110.229C162.596 111.074 162.803 111.997 162.804 112.931C162.806 114.619 162.139 116.239 160.949 117.435C159.76 118.631 158.146 119.305 156.461 119.31L135.203 119.349L118.727 107.392Z'
        fill='#3F3D56'
      />
      <path
        d='M113.961 181.294L110.83 179.794L115.13 166.686L119.751 168.899L113.961 181.294Z'
        fill='#FFB7B7'
      />
      <path
        d='M134.949 179.181C134.721 179.324 134.494 179.465 134.264 179.605C133.806 179.886 133.344 180.163 132.882 180.434C132.649 180.57 132.415 180.706 132.183 180.84V180.847L132.169 180.849L132.164 180.851L131.81 180.939L131.302 179.594L131.067 178.974L130.736 178.096L130.129 176.491L126.933 168.036L131.902 166.809L132.088 167.571H132.091L132.183 167.949L133.919 175.003L134.273 176.445L134.284 176.488L134.498 177.35L134.682 178.096L134.887 178.93L134.949 179.181Z'
        fill='#FFB7B7'
      />
      <path
        d='M164.593 149.492C164.278 149.999 163.956 150.503 163.632 151.003C163.03 151.934 162.411 152.851 161.776 153.754C161.776 153.756 161.774 153.756 161.772 153.758C161.401 154.102 160.992 154.401 160.553 154.65C160.535 154.664 160.514 154.673 160.495 154.684C160.172 154.864 159.827 155.002 159.47 155.097C159.341 155.129 159.21 155.161 159.081 155.193C158.617 155.304 158.157 155.398 157.699 155.481C156.885 155.627 156.08 155.73 155.28 155.795C155.216 155.802 155.151 155.806 155.087 155.811C151.796 156.053 148.593 155.663 145.249 155.025C143.598 154.712 141.913 154.337 140.167 153.947C139.597 153.818 139.024 153.691 138.445 153.565C138.401 153.556 138.355 153.544 138.309 153.535C137.481 153.353 136.64 153.173 135.777 152.996C134.692 152.772 133.572 152.556 132.409 152.351L132.491 153.993C132.56 155.257 132.822 156.502 133.269 157.686C133.765 159.142 134.159 160.294 133.328 162.692L132.928 163.853C133.136 164.429 133.207 165.046 133.136 165.655C133.066 166.264 132.855 166.848 132.521 167.361L132.441 167.483L135.777 175.94L135.994 176.488H134.285L132.183 176.49H128.621L123.482 166.161L123.236 165.668V165.643L123.767 145.792C123.774 145.617 123.788 145.44 123.81 145.265C123.904 144.469 124.155 143.7 124.549 143.002C124.942 142.304 125.471 141.692 126.103 141.201C126.709 140.727 127.403 140.378 128.145 140.175C128.887 139.971 129.661 139.918 130.424 140.017L132.183 140.245L135.777 140.711L138.309 141.04L138.36 141.047L145.827 142.014L146.362 139.826L146.461 139.789L149.605 138.635L160.477 134.643L160.505 134.634L161.22 134.371L161.305 134.509C161.33 134.551 161.355 134.592 161.378 134.634C162.085 135.903 162.661 137.242 163.096 138.628C163.828 140.893 164.431 143.198 164.901 145.532C164.922 145.633 164.94 145.726 164.957 145.809L164.963 145.834C164.97 145.873 164.975 145.898 164.977 145.905C165.228 146.951 165.053 148.227 164.593 149.492Z'
        fill='#2F2E41'
      />
      <path
        d='M165.052 142.176C165.018 142.029 164.974 141.879 164.928 141.734C164.563 140.635 164.138 139.557 163.654 138.504C163.111 137.306 162.461 136.023 161.686 134.634C161.311 133.962 160.908 133.262 160.476 132.536C160.26 132.172 160.035 131.801 159.802 131.423C159.793 131.406 159.783 131.389 159.772 131.372L159.706 131.262L148.948 132.704L144.942 133.241L144.703 135.24L144.646 135.735C144.644 135.735 144.644 135.735 144.646 135.738L144.485 137.088V137.09L144.271 138.873L144.253 138.871L138.308 138.099L132.182 137.304L128.891 136.878C128.129 136.778 127.354 136.832 126.612 137.035C125.871 137.238 125.176 137.587 124.571 138.062C123.938 138.553 123.41 139.165 123.016 139.863C122.623 140.561 122.372 141.33 122.278 142.126C122.257 142.287 122.243 142.453 122.237 142.616L119.132 149.986C117.274 154.393 116.091 159.056 115.623 163.816L113.346 171.107L113.671 171.137L118.495 171.587L119.769 171.706L122.575 167.604C122.894 167.136 123.198 166.654 123.481 166.162C123.946 165.356 124.36 164.522 124.72 163.664L130.789 149.198C131.258 149.278 131.722 149.355 132.182 149.428C134.309 149.774 136.351 150.055 138.308 150.273C144.671 150.985 150.181 151.041 155.086 150.437C156.463 150.269 157.795 150.048 159.08 149.774C159.552 149.675 160.017 149.568 160.476 149.453C160.584 149.428 160.69 149.403 160.796 149.375C161.746 149.13 162.62 148.649 163.337 147.978C164.054 147.307 164.592 146.466 164.9 145.533C165.269 144.451 165.322 143.287 165.052 142.176Z'
        fill='#2F2E41'
      />
      <path
        d='M114.961 179.154L111.574 177.527L111.042 177.271L110.194 179.359L104.27 180.665C103.977 180.729 103.713 180.886 103.515 181.111C103.317 181.337 103.196 181.62 103.171 181.919C103.145 182.219 103.216 182.518 103.372 182.775C103.528 183.031 103.763 183.23 104.04 183.344L109.828 185.71L111.666 184.053L111.215 186.276L113.395 187.168L115.784 179.55L114.961 179.154Z'
        fill='#2F2E41'
      />
      <path
        d='M135.777 175.939L135.993 176.488H135.777V175.939Z'
        fill='#2F2E41'
      />
      <path
        d='M134.598 176.603L131.048 177.835L130.491 178.028L131.354 180.109L128.063 185.213C127.9 185.465 127.821 185.763 127.839 186.062C127.857 186.362 127.971 186.648 128.163 186.879C128.355 187.11 128.615 187.273 128.906 187.345C129.198 187.417 129.504 187.393 129.781 187.278L135.555 184.88L135.698 182.408L136.939 184.305L139.114 183.402L135.461 176.305L134.598 176.603Z'
        fill='#2F2E41'
      />
      <path
        d='M164.167 145.513C163.956 145.513 163.749 145.461 163.562 145.363L144.833 135.559L144.616 134.672L144.208 131.863L144.214 131.837L145.995 123.61C145.963 123.54 145.894 123.424 145.817 123.293C145.022 121.943 142.602 117.835 147.221 112.179L155.33 102.708L162.29 102.408L162.369 102.508C164.23 104.848 165.485 107.613 166.023 110.555C166.56 113.498 166.364 116.529 165.45 119.377L164.726 132.489L165.503 135.136C165.957 136.692 165.882 138.356 165.289 139.864L165.468 144.152C165.475 144.327 165.446 144.503 165.384 144.667C165.322 144.832 165.227 144.982 165.106 145.109C164.984 145.236 164.839 145.337 164.677 145.407C164.516 145.476 164.342 145.513 164.167 145.513Z'
        fill='#3F3D56'
      />
      <path
        d='M147.921 156.326C148.238 156.138 148.51 155.883 148.718 155.579C148.927 155.274 149.066 154.927 149.126 154.563C149.186 154.199 149.165 153.826 149.066 153.47C148.966 153.115 148.79 152.786 148.55 152.506L153.053 144.748L148.405 145.01L144.842 152.337C144.399 152.773 144.131 153.356 144.089 153.976C144.047 154.596 144.233 155.21 144.612 155.702C144.992 156.193 145.538 156.529 146.148 156.644C146.758 156.759 147.388 156.646 147.921 156.326Z'
        fill='#FFB7B7'
      />
      <path
        d='M149.738 150.621L145.577 147.375L153.138 132.696L150.305 114.004C150.165 113.08 150.229 112.136 150.494 111.24C150.759 110.344 151.218 109.518 151.837 108.819C152.457 108.121 153.223 107.567 154.08 107.198C154.937 106.83 155.865 106.654 156.797 106.685C158.481 106.743 160.074 107.467 161.225 108.699C162.377 109.931 162.993 111.571 162.94 113.258L162.24 134.542L149.738 150.621Z'
        fill='#3F3D56'
      />
      <path
        d='M150.866 98.7729V91.8737C150.864 89.4219 151.835 87.0698 153.564 85.335C155.294 83.6001 157.641 82.6246 160.088 82.623C162.536 82.6215 164.884 83.594 166.616 85.3266C168.347 87.0592 169.321 89.41 169.323 91.8618V91.8737C170.949 95.2691 169.932 96.8834 169.323 98.7729C169.322 99.1019 169.191 99.4173 168.959 99.65C168.727 99.8827 168.412 100.014 168.084 100.014H152.105C151.777 100.014 151.462 99.8829 151.229 99.6502C150.997 99.4175 150.866 99.102 150.866 98.7729Z'
        fill='#2F2E41'
      />
      <path
        d='M157.068 99.5666C160.884 99.5666 163.976 96.4684 163.976 92.6466C163.976 88.8248 160.884 85.7266 157.068 85.7266C153.253 85.7266 150.161 88.8248 150.161 92.6466C150.161 96.4684 153.253 99.5666 157.068 99.5666Z'
        fill='#FFB7B7'
      />
      <path
        d='M164.298 94.4599C164.235 94.45 164.173 94.4368 164.11 94.4269C163.033 94.2354 161.955 94.0473 160.881 93.8525L160.782 91.4131L159.48 93.5983C156.511 94.5853 153.734 94.0275 151.121 92.7962C150.259 92.3863 149.422 91.9244 148.616 91.4131C148.836 90.064 149.354 88.781 150.132 87.6582C150.91 86.5353 151.929 85.6009 153.114 84.9234C153.213 84.8673 153.309 84.8178 153.407 84.7682L153.409 84.768L153.41 84.7673L153.41 84.7662L153.411 84.7649C153.579 84.6857 153.75 84.6098 153.921 84.5405C155.263 84.0153 156.726 83.8823 158.14 84.1569C159.554 84.4316 160.861 85.1026 161.909 86.0919C164.018 88.1089 164.967 91.4395 164.298 94.4599Z'
        fill='#2F2E41'
      />
    </g>
    <defs>
      <clipPath id='clip0_9186_77286'>
        <rect width='200' height='187.387' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default TrialExpiredModalIcon;
