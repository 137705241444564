import React from 'react';

const MicrosoftBingIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
  >
    <path
      d='M6.5895 13.9033L7.5 13.3569V3.97592C7.5 3.36392 7.2015 2.79054 6.70013 2.43992L4.635 0.994294C4.26225 0.733294 3.75 0.999919 3.75 1.45517V12.1873C3.75 12.2698 3.7575 12.3954 3.76237 12.4768C3.88837 13.8249 5.39325 14.621 6.5895 13.9033Z'
      fill='url(#paint0_linear_9233_77514)'
    />
    <path
      d='M12.2555 10.4639L7.49972 13.3124L6.58922 13.8587C5.39259 14.5769 3.88809 13.7804 3.76172 12.4322C3.88922 14.5956 5.67909 16.3124 7.87472 16.3124C8.61084 16.3124 9.33272 16.1114 9.96272 15.7307L12.5255 14.1835C12.8318 13.9986 13.0962 13.7804 13.3317 13.5434C14.2145 12.5305 13.7768 10.7507 12.2555 10.4639Z'
      fill='url(#paint1_linear_9233_77514)'
    />
    <path
      d='M12.6139 7.33811L9.76127 5.91199C9.29852 5.68061 8.79527 6.14374 8.98727 6.62411L9.64539 8.83136C9.83252 9.29936 10.2 9.67211 10.665 9.86561L12.1879 10.5001C13.7093 10.7874 14.1949 12.4692 13.3324 13.544C15.15 11.7151 15.0334 8.54786 12.6139 7.33811Z'
      fill='url(#paint2_linear_9233_77514)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_9233_77514'
        x1='4.46437'
        y1='0.731794'
        x2='6.72787'
        y2='15.1502'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#3DBFFC' />
        <stop offset='1' stopColor='#183EFB' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_9233_77514'
        x1='5.37797'
        y1='15.5541'
        x2='12.7951'
        y2='9.59049'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#33BEF0' />
        <stop offset='0.159' stopColor='#32B9F0' />
        <stop offset='0.341' stopColor='#2FACF2' />
        <stop offset='0.533' stopColor='#2A95F4' />
        <stop offset='0.733' stopColor='#2475F6' />
        <stop offset='0.936' stopColor='#1B4CFA' />
        <stop offset='1' stopColor='#183EFB' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_9233_77514'
        x1='9.08402'
        y1='6.41749'
        x2='17.1375'
        y2='14.4706'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#3DBFFD' />
        <stop offset='1' stopColor='#1DE9B6' />
      </linearGradient>
    </defs>
  </svg>
);

export default MicrosoftBingIcon;
