import React from 'react';
import YourFreeTrialEndsBanner from '../YourFreeTrialEndsBanner/YourFreeTrialEndsBanner';
import YourTrialHasEndedBanner from '../YourTrialHasEndedBanner/YourTrialHasEndedBanner';
import YourSubscriptionHasEndedBanner from '../YourSubscriptionHasEndedBanner/YourSubscriptionHasEndedBanner';
import YouHaveCanceledYourPlanBanner from '../YouHaveCanceledYourPlanBanner/YouHaveCanceledYourPlanBanner';
import YourLastPaymentFailedBanner from '../YourLastPaymentFailedBanner/YourLastPaymentFailedBanner';

interface TrialBannersProps {
  onCloseYourFreeTrialEndsBanner: () => void;
  daysRemaining: number;
  tariffPlanName: string;
  tariffPlanStatus: string;
  lastTransactionStatus?: string;
}

const TrialBanners: React.FC<TrialBannersProps> = ({
  onCloseYourFreeTrialEndsBanner,
  daysRemaining,
  tariffPlanName,
  tariffPlanStatus,
  lastTransactionStatus,
}) => {
  if (tariffPlanStatus === 'deactivated') {
    if (lastTransactionStatus === 'payment failed') {
      return <YourLastPaymentFailedBanner />;
    } else {
      if (tariffPlanName === 'Trial Period') {
        return <YourTrialHasEndedBanner />;
      } else {
        return <YourSubscriptionHasEndedBanner />;
      }
    }
  }

  if (tariffPlanStatus === 'canceled') {
    return <YouHaveCanceledYourPlanBanner />;
  }

  return (
    <YourFreeTrialEndsBanner
      onClose={onCloseYourFreeTrialEndsBanner}
      daysRemaining={daysRemaining}
      tariffPlanName={tariffPlanName}
      tariffPlanStatus={tariffPlanStatus}
    />
  );
};

export default TrialBanners;
