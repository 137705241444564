import React, { useEffect, useMemo, useState } from 'react';
import {
  CheckFrequency,
  Domains,
  GoogleDomain,
  IBusinessCompetitors,
  Language,
  NewProjectModalProps,
  SearchLocation,
} from '../../../../../../../../types';
import { useAuth } from '../../../../../../../../../../hooks';
import useProjects from '../../../../../../../../hooks/useProjects';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { enqueueSnackbar } from 'notistack';
import CustomToast from '../../../../../../../../../../shared/CustomToast/CustomToast';
import { closeSnackbarAction } from '../../../../../../../../../../utils/snackbar';
import ProjectModalContainer from '../../../../../../../Projects/components/ProjectRedactorModal/ProjectModalContainer';
import { Divider, Grid, Typography } from '@mui/material';
import { CloseDeletePopoverOne } from '../../../../../../../../../auth/components/Settings/components/Account/styles';
import CloseIcon from '@mui/icons-material/Close';
import {
  HeaderItem,
  ProjectNameContainer,
} from '../../../../../../../Projects/components/ProjectRedactorModal/styles';
import { checkObjectProperties } from '../../../../../../../../utils';
import { DEFAULT_KEYWORD_POSITIONS_MODEL } from '../../../../../../../../constants';
import {
  keywordsSymbolsLimit,
  validateKeywords,
} from '../../../../../../../../../../constants/serpKeywords';
import { useAppSelector } from '../../../../../../../../../../store';
import useAccountLimits from '../../../../../../../../../auth/hooks/useAccountLimits';
import { useNavigate } from 'react-router';
import ROUTES from '../../../../../../../../../../routes/constants';
import CompetitorsLimitExceededModal from '../../../../../../../../../../shared/components/CompetitorsLimitExceededModal/CompetitorsLimitExceededModal';
import TagsPreProjectExceededModal from '../../../../../../../../../../shared/components/TagsPreProjectExceededModal/TagsPreProjectExceededModal';
import DailyRefreshesLimitExceededModal from '../../../../../../../../../../shared/components/DailyRefreshesLimitExceededModal/DailyRefreshesLimitExceededModal';
import { haveSameElements } from '../../../../../../../../../../utils';
import { convertRequestEditDataByProjectType } from '../../../../../../../../editProjectUtils';
import { CustomTooltip } from '../../../../../../../../../../shared/tooltip/styles';

const EditProjectModal: React.FC<NewProjectModalProps> = ({
  open,
  handleClose,
}) => {
  const navigate = useNavigate();

  const { onGetMyAccount } = useAuth();

  const {
    onGetKeywordRankings,
    onGetProjectExtended,
    onGetCheckFrequency,
    onEditProject,
  } = useProjects();

  const {
    onCheckKeywordLimit,
    onCheckCompetitorsLimit,
    onCloseCompetitorsLimitExceededModal,
    onCloseTagsPerProjectLimitExceededModal,
    onCheckTagsPerProjectLimit,
    onCloseRefreshesLimitLimitExceededModal,
    onCheckCannotAddExcessKeywords,
    onOpenKeywordUpdateScheduledModal,
  } = useAccountLimits();

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const keywordPositionModel = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table
        .keywordPositionModel
  );
  const keywordRankings = useAppSelector(
    state => state.projects.projectExpandedGraphs.keywordRankings
  );
  const deviceType = useAppSelector(
    state => state.projects.projectExpandedGraphs.deviceType
  );
  const currentProject = useAppSelector(state => state.projects.currentProject);
  const checkFrequency = useAppSelector(state => state.projects.checkFrequency);
  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );
  const deviceTypes = useAppSelector(state => state.projects.deviceTypes);
  const dailyRefreshesLimitExceededOpen = useAppSelector(
    state => state.auth.accountLimitErrors.dailyRefreshesLimitExceeded.open
  );
  const keywordSelectedTags = useAppSelector(
    state =>
      state.projects.projectExpandedGraphs.keywordRankings.table.selectedTags
  );
  const competitorsLimitExceededOpen = useAppSelector(
    state => state.auth.accountLimitErrors.competitorsLimitExceeded.open
  );
  const tagsPreProjectLimitExceededOpen = useAppSelector(
    state => state.auth.accountLimitErrors.tagsPerProjectLimitExceeded.open
  );

  const memoKeywordSelectedTags = useMemo(
    () => keywordSelectedTags.map(item => item.id),
    [keywordSelectedTags]
  );

  const deviceTypeForForm = useMemo(
    () => deviceTypes.find(item => item.name === deviceType),
    [deviceType, deviceTypes]
  );

  const onClose = () => {
    handleClose();
    formik.resetForm();
  };

  useEffect(() => {
    if (!checkFrequency.length) {
      onGetCheckFrequency();
    }
  }, []);

  const [isOpenAdvancedSettings, setIsOpenAdvancedSettings] = useState(false);
  const onToggleAdvancedSettings = () =>
    setIsOpenAdvancedSettings(!isOpenAdvancedSettings);

  const initialValues = {
    projectName: currentProject?.projectName || '',
    keywords: [''] as string[],
    checkFrequency: currentProject?.checkFrequency || ({} as CheckFrequency),
    competitorIds: currentProject?.competitors || [],
    competitors: [] as string[],
    tagIds: currentProject?.tags || [],
    tags: [] as string[],
    note: '',
    message: '',
    url: '',
    projectUrlType: {} as Domains,
    searchEngine: currentProject?.searchEngine?.name || '',
    regionId: {} as GoogleDomain,
    location: null as SearchLocation | null,
    deviceType: deviceTypes[0],
    languageId: {} as Language,
    folder: null as { id: number; name: string } | null,
    businessName: '',
    businessUrl: '',
    businessCompetitors: [] as IBusinessCompetitors[],
    competitorsVideoUrl: [] as string[],
    videoUrl: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      projectName: Yup.string()
        .required('Project Name is required.')
        .trim()
        .matches(/\S/, 'Project Name should not contain only spaces.'),
      checkFrequency: Yup.object().shape({
        name: isOpenAdvancedSettings
          ? Yup.string().required('Check Frequency is required.')
          : Yup.string().notRequired(),
      }),
      keywords: Yup.array().of(
        Yup.string()
          .max(
            keywordsSymbolsLimit,
            `Keywords must not exceed ${keywordsSymbolsLimit} characters. Please reduce the length of keywords exceeding this limit.`
          )
          .test('forbidden-word', (value, { createError, path }) =>
            validateKeywords(value, { createError, path })
          )
      ),
      note: Yup.string()
        .max(2500, 'Note must be at most 2,500 characters')
        .matches(/\S/, 'Note should not contain only spaces.'),
    }),
    onSubmit: (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
      const filterKeywordsLength = values.keywords.filter(item =>
        /\S/.test(item)
      ).length;
      const isDesktopAndMobile = values.deviceType.name === 'DesktopAndMobile';
      const googleMyBusinessOrYouTube =
        values.searchEngine === 'Google My Business' ||
        values.searchEngine === 'YouTube';

      const checkKeywordLimit = onCheckKeywordLimit();
      let checkCannotAddExcessKeywords;
      if (!checkKeywordLimit) {
        checkCannotAddExcessKeywords = onCheckCannotAddExcessKeywords(
          filterKeywordsLength,
          isDesktopAndMobile,
          googleMyBusinessOrYouTube,
          values.searchEngine || ''
        );
      }
      const competitorsError = onCheckCompetitorsLimit(
        values.competitors.filter(item => /\S/.test(item)).length
      );
      const tagsPerProjectError = onCheckTagsPerProjectLimit(
        values.tags.filter(item => /\S/.test(item)).length +
          values.tagIds.length
      );
      if (
        currentProject &&
        !checkKeywordLimit &&
        !checkCannotAddExcessKeywords &&
        !competitorsError &&
        !tagsPerProjectError
      ) {
        const convertRequestEditData = convertRequestEditDataByProjectType(
          values.searchEngine,
          values
        );
        if (convertRequestEditData) {
          onEditProject({
            accountId: currentAccount.accountId,
            projectId: currentProject.id,
            data: convertRequestEditData,
            successFn: keywordUpdateWasSkipped => {
              if (projectExpanded?.id) {
                if (keywordUpdateWasSkipped) {
                  onOpenKeywordUpdateScheduledModal();
                }
                resetForm();
                handleClose();
                onGetProjectExtended({
                  accountId: currentAccount.accountId,
                  projectId: projectExpanded.id,
                  keywordDeviceType: deviceType,
                  notes: true,
                });
                deviceType &&
                  onGetKeywordRankings({
                    accountId: currentAccount.accountId,
                    projectId: projectExpanded.id,
                    params: {
                      page: keywordRankings.table.paginationModel.page,
                      limit: keywordRankings.table.paginationModel.pageSize,
                      search: keywordRankings.table.search,
                      sortBy: keywordRankings.table.sortModel[0]?.field,
                      sortOrder: keywordRankings.table.sortModel[0]?.sort,
                    },
                    filters: checkObjectProperties(
                      DEFAULT_KEYWORD_POSITIONS_MODEL,
                      keywordPositionModel
                    ),
                    deviceType: deviceType,
                    tagIds: memoKeywordSelectedTags,
                  });
                onGetMyAccount(currentAccount.accountId);
                enqueueSnackbar(
                  <CustomToast
                    message='Success'
                    submessage='Project details have been successfully updated.'
                  />,
                  { action: closeSnackbarAction }
                );
              }
            },
            errorFn: (error: any) => {
              setStatus({ success: false });
              setSubmitting(false);
              setErrors(error);
            },
          });
        }
      } else {
        setStatus({ success: false });
        setSubmitting(false);
      }
    },
  });

  const disableEditProject = useMemo(() => {
    return (
      formik.values.projectName === initialValues.projectName &&
      !formik.values.keywords.filter(item => /\S/.test(item)).length &&
      formik.values.checkFrequency.name === initialValues.checkFrequency.name &&
      !formik.values.competitors.filter(item => /\S/.test(item)).length &&
      !formik.values.businessCompetitors.length &&
      !formik.values.competitorsVideoUrl.filter(item => /\S/.test(item))
        .length &&
      haveSameElements(
        formik.values.competitorIds.map(item => item.id),
        initialValues.competitorIds.map(item => item.id)
      ) &&
      !formik.values.tags.filter(item => /\S/.test(item)).length &&
      haveSameElements(
        formik.values.tagIds.map(item => item.id),
        initialValues.tagIds.map(item => item.id)
      ) &&
      !formik.values.note &&
      formik.values.businessName === initialValues.businessName &&
      formik.values.businessUrl === initialValues.businessUrl
    );
  }, [
    formik.values.businessCompetitors.length,
    formik.values.businessName,
    formik.values.businessUrl,
    formik.values.checkFrequency.name,
    formik.values.competitorIds,
    formik.values.competitors,
    formik.values.competitorsVideoUrl,
    formik.values.keywords,
    formik.values.note,
    formik.values.projectName,
    formik.values.tagIds,
    formik.values.tags,
    initialValues.businessName,
    initialValues.businessUrl,
    initialValues.checkFrequency.name,
    initialValues.competitorIds,
    initialValues.projectName,
    initialValues.tagIds,
  ]);

  const isRenderRegion = useMemo(() => {
    return (
      formik.values.searchEngine !== 'YouTube' &&
      formik.values.searchEngine !== 'Yahoo' &&
      formik.values.searchEngine !== 'Google My Business' &&
      formik.values.searchEngine !== 'Bing' &&
      formik.values.searchEngine !== 'Baidu'
    );
  }, [formik.values.searchEngine]);

  const isRenderBusinessNameAndURL = useMemo(() => {
    return (
      formik.values.searchEngine === 'Google My Business' ||
      formik.values.searchEngine === 'Google Maps'
    );
  }, [formik.values?.searchEngine]);

  const projectDescription = useMemo(
    () =>
      currentProject?.id
        ? ` ${currentProject.projectName}${
            currentProject?.url ? ` (${currentProject?.url})` : ''
          }`
        : '',
    [currentProject?.id, currentProject?.projectName, currentProject?.url]
  );

  const handleCloseRefreshesLimitExceededModal = () => {
    onCloseRefreshesLimitLimitExceededModal();
    formik.resetForm();
  };

  const handleDailyRefreshesUpgradeAccount = () => {
    onCloseRefreshesLimitLimitExceededModal();
    navigate(ROUTES.upgradePlan);
  };

  const handleCloseCompetitorsLimitExceededModal = () => {
    onCloseCompetitorsLimitExceededModal();
    formik.resetForm();
  };

  const handleCompetitorsUpgradeAccount = () => {
    onCloseCompetitorsLimitExceededModal();
    navigate(ROUTES.upgradePlan);
  };

  const handleCloseTagsPerProjectLimitExceededModal = () => {
    onCloseTagsPerProjectLimitExceededModal();
    formik.resetForm();
  };

  const handleTagsPerProjectUpgradeAccount = () => {
    onCloseTagsPerProjectLimitExceededModal();
    navigate(ROUTES.upgradePlan);
  };

  if (!currentProject) {
    return null;
  }

  return (
    <>
      <ProjectModalContainer
        formik={formik}
        open={open}
        handleClose={handleClose}
        isOpenAdvancedSettings={isOpenAdvancedSettings}
        onToggleAdvancedSettings={onToggleAdvancedSettings}
        isNew={false}
        disableEditProject={disableEditProject}
      >
        <Grid xs={12}>
          <Grid item xs={12} sx={{ position: 'relative' }}>
            <CloseDeletePopoverOne onClick={onClose}>
              <CloseIcon fontSize='small' />
            </CloseDeletePopoverOne>
            <Typography
              fontSize='18px'
              fontWeight='600'
              lineHeight='28px'
              color='#101828'
            >
              Edit Project
            </Typography>
            <CustomTooltip
              title={projectDescription}
              placement='top-start'
              arrow
            >
              <ProjectNameContainer
                fontSize='14px'
                lineHeight='20px'
                color='#475467'
              >
                Project:
                {projectDescription}
              </ProjectNameContainer>
            </CustomTooltip>
            <Divider
              orientation='horizontal'
              flexItem
              sx={{
                margin: '16px 0',
                color: '#EDEDED',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                gap: '24px 12px',

                '@media (max-width:600px)': {
                  gridTemplateColumns: '1fr 1fr 1fr',
                },
              }}
            >
              {isRenderRegion ? (
                <HeaderItem>
                  <Typography component='span' color='#84818A'>
                    Region
                  </Typography>
                  <Typography component='span' color='#101828'>
                    {currentProject.region.name}
                  </Typography>
                </HeaderItem>
              ) : null}

              {isRenderBusinessNameAndURL && (
                <>
                  <HeaderItem>
                    <Typography component='span' color='#84818A'>
                      Business Name
                    </Typography>
                    <Typography component='span' color='#101828'>
                      {currentProject?.businessName || 'N/A'}
                    </Typography>
                  </HeaderItem>
                  <HeaderItem>
                    <Typography component='span' color='#84818A'>
                      Business URL
                    </Typography>
                    <Typography component='span' color='#101828'>
                      {currentProject?.url || 'N/A'}
                    </Typography>
                  </HeaderItem>
                </>
              )}

              <HeaderItem>
                <Typography component='span' color='#84818A'>
                  Language
                </Typography>
                <Typography component='span' color='#101828'>
                  {currentProject.language.code} ({currentProject.language.name}
                  )
                </Typography>
              </HeaderItem>

              <HeaderItem>
                <Typography component='span' color='#84818A'>
                  Location
                </Typography>
                <Typography component='span' color='#101828'>
                  {currentProject?.location || 'N/A'}
                </Typography>
              </HeaderItem>

              <HeaderItem>
                <Typography component='span' color='#84818A'>
                  Device
                </Typography>
                <Typography component='span' color='#101828'>
                  {currentProject?.deviceType?.name === 'DesktopAndMobile'
                    ? 'Desktop & Mobile'
                    : currentProject?.deviceType?.name}
                </Typography>
              </HeaderItem>

              <HeaderItem>
                <Typography component='span' color='#84818A'>
                  Search engine
                </Typography>
                <Typography component='span' color='#101828'>
                  {currentProject.searchEngine.name}
                </Typography>
              </HeaderItem>

              <HeaderItem sx={{ cursor: 'pointer' }}>
                <Typography component='span' color='#84818A'>
                  Email Reports
                </Typography>
                <Typography component='span' color='#126FE9'>
                  {currentProject.emailReportCount}
                </Typography>
              </HeaderItem>

              <HeaderItem sx={{ cursor: 'pointer' }}>
                <Typography component='span' color='#84818A'>
                  Triggers
                </Typography>
                <Typography component='span' color='#126FE9'>
                  {currentProject.triggerCount}
                </Typography>
              </HeaderItem>
            </Grid>
            <Divider
              orientation='horizontal'
              flexItem
              sx={{
                margin: '16px 0 8px',
                color: '#EDEDED',
              }}
            />
          </Grid>
        </Grid>
      </ProjectModalContainer>
      <DailyRefreshesLimitExceededModal
        onClose={handleCloseRefreshesLimitExceededModal}
        onUpgradeAccount={handleDailyRefreshesUpgradeAccount}
        open={dailyRefreshesLimitExceededOpen}
      />
      <CompetitorsLimitExceededModal
        onClose={handleCloseCompetitorsLimitExceededModal}
        onUpgradeAccount={handleCompetitorsUpgradeAccount}
        open={competitorsLimitExceededOpen}
      />
      <TagsPreProjectExceededModal
        onClose={handleCloseTagsPerProjectLimitExceededModal}
        onUpgradeAccount={handleTagsPerProjectUpgradeAccount}
        open={tagsPreProjectLimitExceededOpen}
      />
    </>
  );
};

export default EditProjectModal;
