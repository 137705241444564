import { useAppDispatch, useAppSelector } from '../../../store';
import { useCallback, useMemo } from 'react';
import {
  resetColumnVisibilityModelAlerts,
  setColumnVisibilityModelAlerts,
  setPaginationPageAlertTriggered,
  setPaginationPageAlerts,
  setPaginationPageSizeAlerts,
  setPaginationPageSizeAlertTriggered,
  setRowSelectionModelTriggers,
  setSortModelAlerts,
  setSortModelAlertTriggered,
  setSortModelTriggers,
  setPaginationPageTriggers,
  setPaginationPageSizeTriggers,
  setRowSelectionModelTriggerKeywords,
  setPaginationPageSizeTriggerKeywords,
  setPaginationPageTriggerKeywords,
  setSortModelTriggerKeywords,
  changeTriggersType,
  resetTriggerKeywordsState,
  setSearchAlerts,
  setColumnVisibilityModelAlertsByKeyword,
  resetColumnVisibilityModelAlertsByKeywords,
  setActiveProjectForFilterAlerts,
  resetAlertsState,
  setDeleteElementsTriggersTable,
  setDeleteElementsTriggerKeywordsTable,
  setAlertStatus,
  setSelectByAlerts,
  setTriggersSelectBy,
  setIsOpenTriggerKeywordsModal,
  setShouldRefreshTable,
} from '../redux/actions';
import {
  BulkDeleteKeywordsFromTriggerParams,
  BulkDeleteTriggersParams,
  GetAlertInfoParams,
  GetAlertKeywordsParams,
  GetAlertsParams,
  GetTriggerInfoParams,
  GetTriggerKeywordsParams,
  GetTriggersParams,
  IColumnVisibilityKeyAlertsByKeywords,
  IColumnVisibilityKeyAlertsByProject,
  ICreateTriggerParams,
  IGetKeywordsByProjectParams,
  IGetProjectsByAlertsParams,
  ISetViewAlertParams,
  UpdateKeywordTriggerAPIParams,
  UpdateProjectTriggerAPIParams,
} from '../types/types';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import {
  bulkDeleteKeywordsFromTriggerThunk,
  bulkDeleteTriggersByKeywordsThunk,
  bulkDeleteTriggersByProjectsThunk,
  createTriggerThunk,
  getAlertInfoThunk,
  getAlertKeywordsThunk,
  getAlertsByKeywordsThunk,
  getAlertsByProjectThunk,
  getAlertsTriggersTypesThunk,
  getKeywordsByProjectThunk,
  getProjectsByAlertsThunk,
  getTriggerInfoThunk,
  getTriggerKeywordThunk,
  getTriggersByKeywordsThunk,
  getTriggersByProjectsThunk,
  setViewAlertByKeywordsThunk,
  setViewAlertByProjectThunk,
  updateKeywordTriggerThunk,
  updateProjectTriggerThunk,
} from '../redux/thunk';

export default function useAlerts() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(state => state.alerts);

  const memoizedState = useMemo(() => ({ ...state }), [state]);

  const onSetColumnVisibilityModelEmailAlerts = useCallback(
    (key: IColumnVisibilityKeyAlertsByProject, value: boolean) => {
      dispatch(setColumnVisibilityModelAlerts(key, value));
    },
    [dispatch]
  );

  const onSetColumnVisibilityModelAlertsByKeyword = useCallback(
    (key: IColumnVisibilityKeyAlertsByKeywords, value: boolean) => {
      dispatch(setColumnVisibilityModelAlertsByKeyword(key, value));
    },
    [dispatch]
  );

  const onResetColumnVisibilityModelAlerts = useCallback(() => {
    dispatch(resetColumnVisibilityModelAlerts());
  }, [dispatch]);

  const onResetColumnVisibilityModelAlertsByKeywords = useCallback(() => {
    dispatch(resetColumnVisibilityModelAlertsByKeywords());
  }, [dispatch]);

  const onSetPaginationPageSizeAlerts = useCallback(
    (pageSize: number) => {
      dispatch(setPaginationPageSizeAlerts(pageSize));
    },
    [dispatch]
  );

  const onSetPaginationPageAlerts = useCallback(
    (page: number) => {
      dispatch(setPaginationPageAlerts(page));
    },
    [dispatch]
  );

  const onSetRowSelectionModelTriggers = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => {
      dispatch(setRowSelectionModelTriggers(rowSelectionModel));
    },
    [dispatch]
  );

  const onSetSortModelAlerts = useCallback(
    (sortModel: GridSortModel) => {
      dispatch(setSortModelAlerts(sortModel));
    },
    [dispatch]
  );

  const onSetPaginationPageSizeAlertTriggered = useCallback(
    (pageSize: number) => {
      dispatch(setPaginationPageSizeAlertTriggered(pageSize));
    },
    [dispatch]
  );

  const onSetPaginationPageAlertTriggered = useCallback(
    (page: number) => {
      dispatch(setPaginationPageAlertTriggered(page));
    },
    [dispatch]
  );

  const onSetSortModelAlertTriggered = useCallback(
    (sortModel: GridSortModel) => {
      dispatch(setSortModelAlertTriggered(sortModel));
    },
    [dispatch]
  );

  const onGetKeywordsByProject = useCallback(
    (params: IGetKeywordsByProjectParams) => {
      dispatch(getKeywordsByProjectThunk(params));
    },
    [dispatch]
  );

  const onGetAlertsTriggersTypes = useCallback(() => {
    dispatch(getAlertsTriggersTypesThunk());
  }, [dispatch]);

  const onCreateTrigger = useCallback(
    (params: ICreateTriggerParams) => {
      dispatch(createTriggerThunk(params));
    },
    [dispatch]
  );

  const onGetTriggersByProjects = useCallback(
    (params: GetTriggersParams) => {
      dispatch(getTriggersByProjectsThunk(params));
    },
    [dispatch]
  );

  const onGetTriggersByKeywords = useCallback(
    (params: GetTriggersParams) => {
      dispatch(getTriggersByKeywordsThunk(params));
    },
    [dispatch]
  );

  const onBulkDeleteTriggersByProjects = useCallback(
    (params: BulkDeleteTriggersParams) => {
      dispatch(bulkDeleteTriggersByProjectsThunk(params));
    },
    [dispatch]
  );

  const onBulkDeleteTriggersByKeywords = useCallback(
    (params: BulkDeleteTriggersParams) => {
      dispatch(bulkDeleteTriggersByKeywordsThunk(params));
    },
    [dispatch]
  );

  const onSetPaginationPageSizeTriggers = useCallback(
    (pageSize: number) => {
      dispatch(setPaginationPageSizeTriggers(pageSize));
    },
    [dispatch]
  );

  const onSetPaginationPageTriggers = useCallback(
    (page: number) => {
      dispatch(setPaginationPageTriggers(page));
    },
    [dispatch]
  );

  const onSetSortModelTriggers = useCallback(
    (sortModel: GridSortModel) => {
      dispatch(setSortModelTriggers(sortModel));
    },
    [dispatch]
  );

  const onGetTriggerKeywords = useCallback(
    (params: GetTriggerKeywordsParams) => {
      dispatch(getTriggerKeywordThunk(params));
    },
    [dispatch]
  );

  const onSetRowSelectionModelTriggerKeywords = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => {
      dispatch(setRowSelectionModelTriggerKeywords(rowSelectionModel));
    },
    [dispatch]
  );

  const onSetPaginationPageSizeTriggerKeywords = useCallback(
    (pageSize: number) => {
      dispatch(setPaginationPageSizeTriggerKeywords(pageSize));
    },
    [dispatch]
  );

  const onSetPaginationPageTriggerKeywords = useCallback(
    (page: number) => {
      dispatch(setPaginationPageTriggerKeywords(page));
    },
    [dispatch]
  );

  const onSetSortModelTriggerKeywords = useCallback(
    (sortModel: GridSortModel) => {
      dispatch(setSortModelTriggerKeywords(sortModel));
    },
    [dispatch]
  );

  const onGetTriggerInfo = useCallback(
    (params: GetTriggerInfoParams) => {
      dispatch(getTriggerInfoThunk(params));
    },
    [dispatch]
  );

  const onBulkDeleteKeywordsFromTrigger = useCallback(
    (params: BulkDeleteKeywordsFromTriggerParams) => {
      dispatch(bulkDeleteKeywordsFromTriggerThunk(params));
    },
    [dispatch]
  );

  const onUpdateProjectTrigger = useCallback(
    (params: UpdateProjectTriggerAPIParams) => {
      dispatch(updateProjectTriggerThunk(params));
    },
    [dispatch]
  );

  const onUpdateKeywordTrigger = useCallback(
    (params: UpdateKeywordTriggerAPIParams) => {
      dispatch(updateKeywordTriggerThunk(params));
    },
    [dispatch]
  );

  const onChangeTriggersType = useCallback(() => {
    dispatch(changeTriggersType());
  }, [dispatch]);

  const onResetTriggerKeywordsState = useCallback(() => {
    dispatch(resetTriggerKeywordsState());
  }, [dispatch]);

  const onGetAlertsByProject = useCallback(
    (params: GetAlertsParams) => {
      dispatch(getAlertsByProjectThunk(params));
    },
    [dispatch]
  );

  const onGetAlertsByKeywords = useCallback(
    (params: GetAlertsParams) => {
      dispatch(getAlertsByKeywordsThunk(params));
    },
    [dispatch]
  );

  const onSetSearchAlerts = useCallback(
    (value: string) => {
      dispatch(setSearchAlerts(value));
    },
    [dispatch]
  );

  const onGetAlertInfo = useCallback(
    (params: GetAlertInfoParams) => {
      dispatch(getAlertInfoThunk(params));
    },
    [dispatch]
  );

  const onGetAlertKeywords = useCallback(
    (params: GetAlertKeywordsParams) => {
      dispatch(getAlertKeywordsThunk(params));
    },
    [dispatch]
  );

  const onSetViewAlertByProject = useCallback(
    (params: ISetViewAlertParams) => {
      dispatch(setViewAlertByProjectThunk(params));
    },
    [dispatch]
  );

  const onSetViewAlertByKeywords = useCallback(
    (params: ISetViewAlertParams) => {
      dispatch(setViewAlertByKeywordsThunk(params));
    },
    [dispatch]
  );

  const onGetProjectsByAlerts = useCallback(
    (params: IGetProjectsByAlertsParams) => {
      dispatch(getProjectsByAlertsThunk(params));
    },
    [dispatch]
  );

  const onSetActiveProjectForFilterAlerts = useCallback(
    (id: string) => {
      dispatch(setActiveProjectForFilterAlerts(id));
    },
    [dispatch]
  );

  const onResetAlertsState = useCallback(() => {
    dispatch(resetAlertsState());
  }, [dispatch]);

  const onSetDeleteElementsTriggersTable = useCallback(
    (deleteElements: GridRowSelectionModel) => {
      dispatch(setDeleteElementsTriggersTable(deleteElements));
    },
    [dispatch]
  );

  const onSetDeleteElementsTriggerKeywordsTable = useCallback(
    (deleteElements: GridRowSelectionModel) => {
      dispatch(setDeleteElementsTriggerKeywordsTable(deleteElements));
    },
    [dispatch]
  );

  const onSetAlertStatus = useCallback(
    (alertId: number) => {
      dispatch(setAlertStatus(alertId));
    },
    [dispatch]
  );

  const onSetSelectByAlerts = useCallback(
    (selectByAlerts: 'by Project' | 'by Keywords') => {
      dispatch(setSelectByAlerts(selectByAlerts));
    },
    [dispatch]
  );

  const onSetTriggersSelectBy = useCallback(
    (selectBy: 'by Project' | 'by Keywords') => {
      dispatch(setTriggersSelectBy(selectBy));
    },
    [dispatch]
  );

  const onSetIsOpenTriggerKeywordsModal = useCallback(
    (value: boolean) => {
      dispatch(setIsOpenTriggerKeywordsModal(value));
    },
    [dispatch]
  );

  const onSetShouldRefreshTable = useCallback(
    (value: boolean) => {
      dispatch(setShouldRefreshTable(value));
    },
    [dispatch]
  );

  return {
    ...memoizedState,
    onSetColumnVisibilityModelEmailAlerts,
    onSetColumnVisibilityModelAlertsByKeyword,
    onResetColumnVisibilityModelAlerts,
    onResetColumnVisibilityModelAlertsByKeywords,
    onSetPaginationPageSizeAlerts,
    onSetPaginationPageAlerts,
    onSetRowSelectionModelTriggers,
    onSetSortModelAlerts,
    onSetPaginationPageSizeAlertTriggered,
    onSetPaginationPageAlertTriggered,
    onSetSortModelAlertTriggered,
    onGetKeywordsByProject,
    onGetAlertsTriggersTypes,
    onCreateTrigger,
    onGetTriggersByProjects,
    onGetTriggersByKeywords,
    onBulkDeleteTriggersByProjects,
    onBulkDeleteTriggersByKeywords,
    onSetPaginationPageSizeTriggers,
    onSetPaginationPageTriggers,
    onSetSortModelTriggers,
    onGetTriggerKeywords,
    onSetRowSelectionModelTriggerKeywords,
    onSetPaginationPageSizeTriggerKeywords,
    onSetPaginationPageTriggerKeywords,
    onSetSortModelTriggerKeywords,
    onGetTriggerInfo,
    onBulkDeleteKeywordsFromTrigger,
    onUpdateProjectTrigger,
    onUpdateKeywordTrigger,
    onChangeTriggersType,
    onResetTriggerKeywordsState,
    onGetAlertsByProject,
    onGetAlertsByKeywords,
    onSetSearchAlerts,
    onGetAlertInfo,
    onGetAlertKeywords,
    onSetViewAlertByProject,
    onSetViewAlertByKeywords,
    onGetProjectsByAlerts,
    onSetActiveProjectForFilterAlerts,
    onResetAlertsState,
    onSetDeleteElementsTriggersTable,
    onSetDeleteElementsTriggerKeywordsTable,
    onSetAlertStatus,
    onSetSelectByAlerts,
    onSetTriggersSelectBy,
    onSetIsOpenTriggerKeywordsModal,
    onSetShouldRefreshTable,
  };
}
