import React from 'react';

import { Header, Content } from './styles';

const Project = () => (
  <div>
    <Header>Project</Header>
    <Content>The specific project for which the report is generated.</Content>
  </div>
);

const Recipient = () => (
  <div>
    <Header>Recipient</Header>
    <Content>
      Email address of the individual set to receive the report.
    </Content>
  </div>
);

const Frequency = () => (
  <div>
    <Header>Frequency</Header>
    <Content>How often the report is sent out: daily, weekly, etc.</Content>
  </div>
);

const LastSent = () => (
  <div>
    <Header>Last Sent</Header>
    <Content>The most recent date when the report was emailed.</Content>
  </div>
);

const NextDelivery = () => (
  <div>
    <Header>Next Delivery</Header>
    <Content>Scheduled date for the upcoming report to be sent.</Content>
  </div>
);

const Format = () => (
  <div>
    <Header>Format</Header>
    <Content>
      The file format in which the report is delivered, either as a CSV or PDF.
    </Content>
  </div>
);

export { Project, Recipient, Frequency, LastSent, NextDelivery, Format };
