import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StyledExportGraphLabel = styled(Grid)`
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;
`;
export { StyledExportGraphLabel };
