/**
 * An object that defines application routes.
 *
 * This object contains static and dynamic routes for navigation throughout the application.
 * Static routes are direct paths, while dynamic routes allow for parameters to be passed in.
 **/
const ROUTES = {
  main: '/',
  signup: '/signup',
  login: '/login',
  forgotPassword: '/forgot-password',
  forgotPasswordContactSupport: '/forgot-password-contact-support',
  projects: '/projects',
  rootFolders: '/folders',
  notes: '/notes',
  sharedLinks: '/shared-links',
  scheduledEmailReports: '/scheduled-email-reports',
  sharedLinkUnavailable: '/shared-link-unavailable',
  upgradePlan: '/upgrade-plan',
  alerts: '/alerts',
  notFound: '/not-found',
  testPay: 'test-pay',
  verifyEmail: '/verify-email',
  errNetwork: '/err-network',
  dynamic: {
    settings: (tab = ':tab') => `/settings/${tab}`,
    confirmEmail: (emailConfirmationToken = ':emailConfirmationToken') =>
      `/confirm-email/${emailConfirmationToken}`,
    forgotPassword: (email = ':email') => `/forgot-password/${email}`,
    resetPassword: (
      passwordResetConfirmationToken = ':passwordResetConfirmationToken'
    ) => `/reset-password/${passwordResetConfirmationToken}`,
    changeEmail: (emailConfirmationToken = ':emailConfirmationToken') =>
      `/email-change-confirmation/${emailConfirmationToken}`,
    projectExpanded: (id = ':id') => `/projects/${id}`,
    folders: (id = ':id') => `/folders/${id}`,
    keywordRankHistory: (projectId = ':projectId', keywordId = ':keywordId') =>
      `/projects/${projectId}/keywords/${keywordId}`,
    sharedLinkContents: (sharedLinkToken = ':sharedLinkToken') =>
      `/shared/${sharedLinkToken}`,
    sharedLinkLogin: (sharedLinkToken = ':sharedLinkToken') =>
      `/shared/${sharedLinkToken}/login`,
    sharedProject: (projectId = ':projectId') => `/shared/project/${projectId}`,
    sharedKeywordRankHistory: (
      projectId = ':projectId',
      keywordId = ':keywordId'
    ) => `/shared/projects/${projectId}/keywords/${keywordId}`,
    authGoogle: () => `/auth-google`,
  },
};

export default ROUTES;
