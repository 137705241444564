import React from 'react';
import { Divider, List } from '@mui/material';
import { DeleteFoldersActionProps } from './types';
import { useFolders } from '../../../../../../../../../hooks';
import DeleteFoldersAction from './DeleteFoldersAction';
import EditFrequencyAction from '../../../../../../../../projects/components/Projects/components/TableProjects/components/ActionsMenu/components/EditFrequencyAction';
import RefreshAllKeywordsAction from '../../EllipsisMenu/components/RefreshAllKeywordsAction';

import { MainContainer } from '../styles';

const ListActions: React.FC<DeleteFoldersActionProps> = ({ popupClose }) => {
  const {
    tables: {
      rootFolder: { rowSelectionModel },
    },
  } = useFolders();

  return (
    <MainContainer>
      <List>
        {!rowSelectionModel.find(item => item.type === 'folder') &&
        'type' in rowSelectionModel[0] ? (
          <EditFrequencyAction
            projectIds={rowSelectionModel.map(item => item.id)}
            popupClose={popupClose}
          />
        ) : null}

        <RefreshAllKeywordsAction popupClose={popupClose} keywordCount={0} />
        <Divider
          orientation='horizontal'
          flexItem
          sx={{
            margin: '8px 0',
            color: '#EDEDED',
          }}
        />

        <DeleteFoldersAction popupClose={popupClose} />
      </List>
    </MainContainer>
  );
};

export default ListActions;
