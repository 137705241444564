import { styled } from '@mui/material/styles';

const StyledNotePopover = styled('div')`
  display: flex;
  width: 214px;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledNotePopoverDate = styled('div')`
  color: #475467;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const StyledNotePopoverText = styled('div')`
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const StyledNotePopoverButton = styled('div')`
  color: #0e59ba;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
`;

export {
  StyledNotePopover,
  StyledNotePopoverDate,
  StyledNotePopoverText,
  StyledNotePopoverButton,
};
