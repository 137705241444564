import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ROUTES from '../../../../../../../routes/constants';
import { useProjects } from '../../../../../../../hooks';

import { BreadcrumbLink } from './styled';
import { encryptData } from '../../../../../../../utils/cryptoJs';
import { useParams } from 'react-router';
import { useAppSelector } from '../../../../../../../store';

const Breadcrumbs = () => {
  const { id } = useParams();

  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );

  const pathSnippets = location.pathname.split('/').filter(i => i);

  const breadcrumbNameMap: Record<string, string> = {
    [ROUTES.projects]: 'Projects',
    [ROUTES.dynamic.projectExpanded(id)]: `${projectExpanded?.projectName}`,
  };

  const extraBreadcrumbItems = pathSnippets.map((i, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return (
      <BreadcrumbLink
        key={index}
        to={url}
        sx={{
          color: pathSnippets.length - 1 === index ? '#101828' : undefined,
        }}
      >
        {breadcrumbNameMap[url] !== 'undefined' ? breadcrumbNameMap[url] : ''}
      </BreadcrumbLink>
    );
  });

  return (
    <MuiBreadcrumbs
      separator={<NavigateNextIcon fontSize='small' />}
      aria-label='breadcrumb'
    >
      {extraBreadcrumbItems}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
