import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../hooks';
import { useNavigate } from 'react-router';
import ROUTES from '../../routes/constants';
import { useAppSelector } from '../../store';

const PreferredTariffPlanLayout = () => {
  const navigate = useNavigate();

  const { onVisitPreferredTariffPlan } = useAuth();

  const isLogin = useAppSelector(state => state.auth.isLogin);
  const isVisitPreferredTariffPlan = useAppSelector(
    state => state.auth.isVisitPreferredTariffPlan
  );
  const myAccount = useAppSelector(state => state.auth.myAccount);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const billing = useAppSelector(state => state.auth.payment.billing);

  useEffect(() => {
    if (
      !isVisitPreferredTariffPlan &&
      isLogin &&
      currentAccount?.isMyAccount &&
      myAccount.preferredTariffPlan &&
      myAccount.preferredTariffPlan?.name !== 'Trial Period' &&
      billing &&
      billing.tariffPlan.name === 'Trial Period'
    ) {
      onVisitPreferredTariffPlan();
      navigate(
        `${ROUTES.upgradePlan}?paddleProductId=${myAccount.preferredTariffPlan.settings.paddleProductId}`
      );
    }
  }, [
    currentAccount?.isMyAccount,
    isLogin,
    isVisitPreferredTariffPlan,
    myAccount?.preferredTariffPlan,
    navigate,
    onVisitPreferredTariffPlan,
    billing,
  ]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default PreferredTariffPlanLayout;
