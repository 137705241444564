import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const CustomSwitchContainer = styled(Grid)`
  display: flex;
  padding-top: 24px;
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

export { CustomSwitchContainer };
