import React from 'react';

const DesktopIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="monitor-01">
        <path id="Icon" d="M6.66663 17.5H13.3333M9.99996 14.1667V17.5M5.66663 14.1667H14.3333C15.7334 14.1667 16.4335 14.1667 16.9683 13.8942C17.4387 13.6545 17.8211 13.272 18.0608 12.8016C18.3333 12.2669 18.3333 11.5668 18.3333 10.1667V6.5C18.3333 5.09987 18.3333 4.3998 18.0608 3.86502C17.8211 3.39462 17.4387 3.01217 16.9683 2.77248C16.4335 2.5 15.7334 2.5 14.3333 2.5H5.66663C4.26649 2.5 3.56643 2.5 3.03165 2.77248C2.56124 3.01217 2.17879 3.39462 1.93911 3.86502C1.66663 4.3998 1.66663 5.09987 1.66663 6.5V10.1667C1.66663 11.5668 1.66663 12.2669 1.93911 12.8016C2.17879 13.272 2.56124 13.6545 3.03165 13.8942C3.56643 14.1667 4.26649 14.1667 5.66663 14.1667Z" stroke="#667085" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </svg>
  );
};

export default DesktopIcon;