import React, { useMemo } from 'react';
import { StyledCanceledPlanCard, StyledExpireDate, StyledHead } from './styles';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { PrimaryButtonCustom } from '../../../../../../../../../../shared/buttons/styles';
import { Progress } from '../../../../../../../../../projects/components/AccountUsage/components';
import { useAuth } from '../../../../../../../../../../hooks';
import usePayment from '../../../../../../../../../payment/hooks/usePayment';
import { useAppSelector } from '../../../../../../../../../../store';
import useAccountLimits from '../../../../../../../../hooks/useAccountLimits';

/**
 * CanceledPlanCard component to display details of a canceled subscription plan
 * and provide an option for the user to reactivate it.
 *
 * @returns {JSX.Element} A component displaying the canceled plan information and options to reactivate.
 */
const CanceledPlanCard = () => {
  const { onGetBilling, onGetMyAccount } = useAuth();
  const { onSetNoticeAccountDeletionCancelledModal } = useAccountLimits();

  const accountLimit = useAppSelector(state => state.auth.accountLimit);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const myAccount = useAppSelector(state => state.auth.myAccount);
  const billing = useAppSelector(state => state.auth.payment.billing);

  /**
   * Number of keywords used.
   * @type {number}
   */
  const numberOfKeywords = useMemo(
    () => accountLimit?.accountLimitsUsed?.numberOfKeywords || 0,
    [accountLimit?.accountLimitsUsed?.numberOfKeywords]
  );

  /**
   * Number of daily updates allowed for keyword positions.
   * @type {number}
   */
  const numberOfDailyUpdatesOfKeywordPositions = useMemo(
    () =>
      accountLimit?.defaultAccountLimits
        ?.numberOfDailyUpdatesOfKeywordPositions || 0,
    [accountLimit?.defaultAccountLimits?.numberOfDailyUpdatesOfKeywordPositions]
  );

  const { onReactivateSubscription } = usePayment();

  /**
   * Handle reactivation of the subscription.
   * Sends a request to reactivate the canceled subscription.
   */
  const handleReactivateSubscription = () => {
    if (currentAccount?.accountId && billing?.subscriptionId) {
      onReactivateSubscription({
        id: currentAccount.accountId,
        data: { subscriptionId: billing.subscriptionId },
        successFn: () => {
          onGetMyAccount(currentAccount.accountId);
          onGetBilling({ id: currentAccount.accountId });
          myAccount?.deletedAt &&
            onSetNoticeAccountDeletionCancelledModal(true);
        },
      });
    }
  };

  return (
    <StyledCanceledPlanCard xs={12}>
      <StyledHead>You have canceled your plan</StyledHead>

      <StyledExpireDate>
        Your subscription will expire on{' '}
        <b>{billing?.subscriptionExpirationDate}</b>.
      </StyledExpireDate>

      <Grid padding={'24px 0'}>
        <PrimaryButtonCustom
          variant='contained'
          size='small'
          $padding='12px 16px'
          onClick={handleReactivateSubscription}
        >
          Reactivate Subscription
        </PrimaryButtonCustom>
      </Grid>

      <Grid>
        <Divider
          orientation='horizontal'
          flexItem
          sx={{
            color: '#EDEDED',
          }}
        />
      </Grid>

      <Grid paddingTop={'24px'}>
        <Progress
          title='Keyword limit'
          value={numberOfKeywords}
          maxValue={numberOfDailyUpdatesOfKeywordPositions}
        />
      </Grid>
    </StyledCanceledPlanCard>
  );
};

export default CanceledPlanCard;
