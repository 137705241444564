import React from 'react';

const PayPalIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='58'
      height='41'
      viewBox='0 0 58 41'
      fill='none'
    >
      <path
        d='M0.630435 5.04349C0.630435 2.60623 2.60622 0.630435 5.04348 0.630435H52.9565C55.3938 0.630435 57.3696 2.60622 57.3696 5.04348V35.3043C57.3696 37.7416 55.3938 39.7174 52.9565 39.7174H5.04348C2.60622 39.7174 0.630435 37.7416 0.630435 35.3044V5.04349Z'
        fill='white'
      />
      <path
        d='M0.630435 5.04349C0.630435 2.60623 2.60622 0.630435 5.04348 0.630435H52.9565C55.3938 0.630435 57.3696 2.60622 57.3696 5.04348V35.3043C57.3696 37.7416 55.3938 39.7174 52.9565 39.7174H5.04348C2.60622 39.7174 0.630435 37.7416 0.630435 35.3044V5.04349Z'
        stroke='#EAECF0'
        strokeWidth='1.26087'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.5688 31.0152L24.9399 28.579L24.1133 28.559H20.166L22.9092 10.5814C22.9178 10.5269 22.9454 10.4764 22.9857 10.4404C23.0263 10.4045 23.078 10.3848 23.132 10.3848H29.7877C31.9974 10.3848 33.5223 10.8599 34.3183 11.7979C34.6916 12.2379 34.9293 12.6979 35.0443 13.2039C35.165 13.7349 35.167 14.3693 35.0493 15.1431L35.0408 15.1994V15.6953L35.414 15.9139C35.7283 16.0862 35.9781 16.2834 36.1697 16.5092C36.4889 16.8856 36.6954 17.3638 36.7826 17.9306C36.8729 18.5136 36.8431 19.2075 36.6954 19.9931C36.5251 20.8966 36.2498 21.6836 35.8779 22.3274C35.5361 22.9206 35.1004 23.4129 34.583 23.7941C34.0891 24.1565 33.5024 24.4316 32.839 24.6076C32.196 24.7806 31.463 24.8679 30.659 24.8679H30.1411C29.7708 24.8679 29.4111 25.0057 29.1286 25.2529C28.8454 25.5051 28.6582 25.8499 28.6007 26.2268L28.5616 26.4462L27.9059 30.7403L27.8763 30.8979C27.8683 30.9478 27.8548 30.9726 27.8349 30.9895C27.8172 31.0049 27.7918 31.0152 27.767 31.0152H24.5688Z'
        fill='#28356A'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M35.7677 15.2559C35.748 15.3871 35.7251 15.5213 35.6997 15.6591C34.822 20.317 31.819 21.9261 27.9839 21.9261H26.0311C25.5621 21.9261 25.1667 22.278 25.0938 22.7563L23.8108 31.1675C23.7633 31.4816 23.9975 31.7645 24.3039 31.7645H27.7674C28.1775 31.7645 28.5259 31.4566 28.5905 31.0385L28.6245 30.8567L29.2766 26.5795L29.3185 26.3449C29.3823 25.9254 29.7315 25.6173 30.1415 25.6173H30.6595C34.0151 25.6173 36.642 24.2093 37.4098 20.1344C37.7304 18.4322 37.5644 17.0108 36.7157 16.0112C36.4589 15.7099 36.1403 15.4596 35.7677 15.2559Z'
        fill='#298FC2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.8496 14.8764C34.7154 14.8359 34.5771 14.7993 34.4352 14.7663C34.2925 14.734 34.1464 14.7055 33.9959 14.6804C33.4694 14.5925 32.8925 14.5508 32.2745 14.5508H27.0578C26.9292 14.5508 26.8072 14.5808 26.6981 14.635C26.4574 14.7546 26.2787 14.99 26.2354 15.2782L25.1256 22.5434L25.0938 22.7552C25.1667 22.277 25.562 21.9251 26.0311 21.9251H27.9838C31.819 21.9251 34.822 20.3151 35.6997 15.658C35.7259 15.5202 35.748 15.3861 35.7676 15.2548C35.5456 15.133 35.3051 15.0288 35.0462 14.9401C34.9822 14.9181 34.9162 14.8969 34.8496 14.8764Z'
        fill='#22284F'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.2358 15.2792C26.2792 14.991 26.4578 14.7556 26.6985 14.6368C26.8084 14.5824 26.9297 14.5524 27.0583 14.5524H32.275C32.8929 14.5524 33.4699 14.5943 33.9964 14.6822C34.1468 14.7071 34.2929 14.7359 34.4356 14.7681C34.5775 14.801 34.7159 14.8378 34.85 14.878C34.9166 14.8986 34.9826 14.9199 35.0472 14.9411C35.3062 15.0298 35.5469 15.1348 35.7689 15.2558C36.03 13.5345 35.7667 12.3625 34.8663 11.3013C33.8736 10.1328 32.082 9.63281 29.7894 9.63281H23.1335C22.6652 9.63281 22.2657 9.98471 22.1934 10.4638L19.4212 28.6263C19.3665 28.9856 19.6346 29.3098 19.9852 29.3098H24.0943L26.2358 15.2792Z'
        fill='#28356A'
      />
    </svg>
  );
};

export default PayPalIcon;
