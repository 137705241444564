import {
  Fade,
  FormHelperText,
  Grid,
  Modal,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import {
  ModalContainer,
  ModalWrapper,
} from '../../../projects/components/Projects/components/ProjectRedactorModal/styles';
import { ViewNoteModalProps } from './types';
import { CloseDeletePopoverOne } from '../../../auth/components/Settings/components/Account/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  GrayButtonSmall,
  PrimaryButtonSmall,
} from '../../../../shared/buttons/styles';
import { ButtonsWrapper } from '../../../auth/components/Settings/components/Team/components/customTableStyles';
import { StyledLabel, StyledNoteText, StyledValue } from './styles';
import { CustomFormHelperText } from '../../../../shared/components';
import EditIcon from '../../../../shared/images/EditIcon';
import { DEFAULT_GET_USERS_PARAMS } from '../../../auth/components/Settings/components/Team/constants';
import { useNotes, useProjects } from '../../../../hooks';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from '../../../../store';

const ViewNoteModal: React.FC<ViewNoteModalProps> = ({
  onClose,
  open,
  note,
  isShared,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const [editMode, setEditMode] = useState(false);

  const isViewOnly =
    !currentAccount?.accountId || currentAccount?.role?.name === 'ViewOnly';

  const { onGetProjectPerformance } = useProjects();

  const projectExpanded = useAppSelector(
    state => state.projects.projectExpanded
  );
  const activePeriodProjectPerformance = useAppSelector(
    state => state.projects.projectExpandedGraphs.activePeriodProjectPerformance
  );
  const deviceType = useAppSelector(
    state => state.projects.projectExpandedGraphs.deviceType
  );

  const {
    onEditNote,
    onGetProjectNotes,
    tables: {
      projectNotes: { sortModel, search, paginationModel },
    },
  } = useNotes();

  const onChangeEditMode = () => {
    setEditMode(prev => !prev);
  };

  const formik = useFormik({
    initialValues: {
      note: note.text,
    },
    validationSchema: Yup.object().shape({
      note: Yup.string()
        .max(2500, 'Note must be at most 2,500 characters')
        .required('Note is required.')
        .trim()
        .matches(/\S/, 'Note should not contain only spaces.'),
    }),
    onSubmit: (values, { setStatus, setSubmitting, setErrors, resetForm }) => {
      if (currentAccount?.accountId && projectExpanded && deviceType) {
        const requestData = {
          id: currentAccount.accountId,
          projectId: note.projectId,
          noteId: note.id,
          text: values.note,
          successFn: () => {
            resetForm();
            handleClose();
            onGetProjectNotes({
              id: currentAccount.accountId,
              projectId: note.projectId,
              page: paginationModel.page,
              limit: paginationModel.pageSize,
              search: search || DEFAULT_GET_USERS_PARAMS.search,
              sortBy: sortModel[0]?.field,
              sortOrder: sortModel[0]?.sort,
            });
            onGetProjectPerformance({
              id: currentAccount.accountId,
              projectId: note.projectId,
              period: activePeriodProjectPerformance.value,
              competitorIds: projectExpanded.competitors.map(item =>
                item.id.toString()
              ),
              deviceType: deviceType,
            });
          },
          errorFn: (error: any) => {
            setStatus({ success: false });
            setSubmitting(false);
            setErrors(error);
          },
        };

        onEditNote({ ...requestData });
      }
    },
  });

  const handleClose = () => {
    onClose();
    setEditMode(false);
    formik.resetForm();
  };

  return (
    <Modal
      aria-labelledby='AddNoteModal'
      aria-describedby='EditNoteModal'
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <form onSubmit={formik.handleSubmit}>
          <ModalWrapper>
            <ModalContainer container xs={12} sx={{ gap: '8px' }}>
              <Grid xs={12}>
                <Grid item xs={12} sx={{ position: 'relative' }}>
                  <CloseDeletePopoverOne onClick={handleClose}>
                    <CloseIcon fontSize='small' />
                  </CloseDeletePopoverOne>
                  <Typography
                    textAlign={'center'}
                    fontSize='18px'
                    fontWeight='600'
                    lineHeight='28px'
                    color='#101828'
                  >
                    View Note
                  </Typography>
                </Grid>

                <Grid padding={'12px 0'}>
                  <StyledLabel>Created</StyledLabel>
                  <StyledValue>
                    {dayjs(note.date).format('MMMM D, YYYY')}
                  </StyledValue>
                </Grid>

                {!isShared && !isViewOnly && (
                  <Grid
                    padding={'12px 0'}
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Grid>
                      <StyledLabel>Author</StyledLabel>
                      <StyledValue>{note.author}</StyledValue>
                    </Grid>
                    <GrayButtonSmall
                      size='small'
                      outline
                      startIcon={<EditIcon />}
                      onClick={onChangeEditMode}
                    >
                      Edit Note
                    </GrayButtonSmall>
                  </Grid>
                )}

                <Grid padding={'12px 0'}>
                  {editMode ? (
                    <>
                      <OutlinedInput
                        multiline
                        rows={4}
                        fullWidth
                        size={'small'}
                        id='note'
                        placeholder='Enter a description...'
                        name='note'
                        value={formik.values.note}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={!!(formik.touched.note && formik.errors.note)}
                      />
                      {formik.touched.note && formik.errors.note && (
                        <FormHelperText error={!!formik.errors.note} id='note'>
                          {formik.errors.note && (
                            <CustomFormHelperText error={formik.errors.note} />
                          )}
                        </FormHelperText>
                      )}
                    </>
                  ) : (
                    <StyledNoteText>{note.text}</StyledNoteText>
                  )}
                </Grid>

                {!isShared && !isViewOnly && (
                  <ButtonsWrapper container xs={12} sx={{ paddingTop: '32px' }}>
                    <Grid item xs={extraSmallScreen && 12}>
                      <GrayButtonSmall
                        fullWidth={extraSmallScreen}
                        size='small'
                        outline
                        onClick={handleClose}
                      >
                        Cancel
                      </GrayButtonSmall>
                    </Grid>
                    <Grid item xs={extraSmallScreen && 12}>
                      <PrimaryButtonSmall
                        fullWidth={extraSmallScreen}
                        variant='contained'
                        size='small'
                        type='submit'
                        disabled={formik.isSubmitting || !formik.isValid}
                        onClick={() => formik.handleSubmit()}
                      >
                        Done
                      </PrimaryButtonSmall>
                    </Grid>
                  </ButtonsWrapper>
                )}
              </Grid>
            </ModalContainer>
          </ModalWrapper>
        </form>
      </Fade>
    </Modal>
  );
};

export default ViewNoteModal;
