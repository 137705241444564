import { Fade, Grid, Modal, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { RemoveAccessModalProps } from './types';

import CloseIcon from '@mui/icons-material/Close';
import Trash from '../../../../shared/images/Trash';
import { CloseDeletePopoverOne } from '../../components/Settings/components/Account/styles';
import { GrayButton, PrimaryButton } from '../../../../shared/buttons/styles';
import { ButtonsWrapper } from '../../components/Settings/components/Team/components/customTableStyles';
import {
  HeaderContainer,
  ModalContainer,
  ModalText,
  ModalWrapper,
} from './styles';

/**
 * Modal component for removing access from an account.
 * @param {RemoveAccessModalProps} props - Props for RemoveAccessModal.
 * @returns {React.ReactElement} The rendered modal component.
 */
const RemoveAccessModal: React.FC<RemoveAccessModalProps> = ({
  openRemoveAccessModal,
  onCloseRemoveAccessModal,
  onRemoveAccesses,
}) => {
  const extraSmallScreen = useMediaQuery('(max-width:600px)');

  const handleClose = () => {
    onCloseRemoveAccessModal();
  };

  const handleRemoveAccesses = () => {
    try {
      onRemoveAccesses();
      onCloseRemoveAccessModal();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      aria-labelledby='EditNoteModal'
      aria-describedby='EditNoteModal'
      open={openRemoveAccessModal}
      onClose={handleClose}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={openRemoveAccessModal}>
        <ModalWrapper>
          <ModalContainer container xs={12}>
            <Grid item xs={12} sx={{ position: 'relative' }}>
              <CloseDeletePopoverOne onClick={handleClose} sx={{ zIndex: 1 }}>
                <CloseIcon fontSize='small' />
              </CloseDeletePopoverOne>
              <HeaderContainer>
                <Trash />
                <Typography
                  fontSize='18px'
                  fontWeight='600'
                  lineHeight='28px'
                  color='#101828'
                >
                  Remove Access Confirmation
                </Typography>
              </HeaderContainer>
            </Grid>
            <ModalText>
              <p>
                Are you sure you want to remove your access to this account?
                Once you proceed with this action, you will no longer be able to
                view or manage this account unless you&apos;re invited again.
              </p>
              <p>
                Please note, this action is irreversible. Make sure you have
                saved any important information or completed any tasks before
                you proceed.
              </p>
              <p>
                Do you wish to continue with removing your access to this
                account?
              </p>
            </ModalText>
            <ButtonsWrapper
              container
              xs={12}
              sx={{
                paddingTop: '32px',
              }}
            >
              <Grid item xs={extraSmallScreen && 12}>
                <GrayButton
                  sx={{ padding: '8px 18px !important', maxHeight: '40px' }}
                  fullWidth={extraSmallScreen}
                  outline
                  onClick={handleClose}
                >
                  Cancel
                </GrayButton>
              </Grid>
              <Grid item xs={extraSmallScreen && 12}>
                <PrimaryButton
                  sx={{ padding: '8px 16px !important' }}
                  fullWidth={extraSmallScreen}
                  variant='contained'
                  color='error'
                  onClick={handleRemoveAccesses}
                >
                  Yes, Remove Access
                </PrimaryButton>
              </Grid>
            </ButtonsWrapper>
          </ModalContainer>
        </ModalWrapper>
      </Fade>
    </Modal>
  );
};

export default RemoveAccessModal;
