import React, { useCallback, useState } from 'react';
import {
  debounce,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { StyledHead, StyledHeader } from './styles';

import { useSharedLinks } from '../../../../../../../../../../hooks';
import { PopupTableSettings } from '../';
import CloseIcon from '@mui/icons-material/Close';
import ActionsMenu from '../ActionsMenu/ActionsMenu';
import { CountLabel } from '../../../../../../../../../projects/components/Projects/components/TableProjects/components/Header/styles';
import SearchIcon from '../../../../../../../../../../shared/images/SearchIcon';
import { GrayButtonSmallForSelect } from '../../../../../../../../../../shared/buttons/styles';
import FilterIcon from '../../../../../../../../../../shared/images/FilterIcon';
import PopupTablePosition from '../PopupPositions/PopupTablePosition';
import PopupTags from '../../../../../../../../../folders/components/Folders/components/TableFolders/components/Header/components/PopupTags/PopupTags';
import useProjects from '../../../../../../../../../projects/hooks/useProjects';
import { useAppSelector } from '../../../../../../../../../../store';
import PopupKeywordTags from '../../../PopupKeywordTags/PopupKeywordTags';

const Header = () => {
  const smallScreen = useMediaQuery('(max-width:1000px)');
  const extraTooSmallScreen = useMediaQuery('(max-width:550px)');
  const extraSmallScreen = useMediaQuery('(max-width:680px)');
  const seScreen = useMediaQuery('(max-width:400px)');

  const { onSetShowTableFilters } = useProjects();

  const isShowTableFilters = useAppSelector(
    state => state.projects.isShowTableFilters
  );

  const { onSetSearchKeywordRankings, onSetRowSelectionKeywordsModel } =
    useSharedLinks();

  const rowSelectionModel = useAppSelector(
    state =>
      state.sharedLinks.projectGraphs.keywordRankings.table.rowSelectionModel
  );
  const search = useAppSelector(
    state => state.sharedLinks.projectGraphs.keywordRankings.table.search
  );
  const keywordRanking = useAppSelector(
    state => state.sharedLinks.projectGraphs.keywordRankings.keywordRanking
  );

  const [showSearchMobile, setShowSearchMobile] = useState(false);
  const [localSearch, setLocalSearch] = useState(search);

  const debounceSearch = useCallback(
    debounce(value => {
      onSetSearchKeywordRankings(value);
    }, 500),
    [onSetSearchKeywordRankings]
  );

  const handleSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLocalSearch(event.target.value);
    debounceSearch(event.target.value);
  };

  const handleClearSearch = () => {
    setLocalSearch('');
    debounceSearch('');
  };

  const onClearSelected = () => {
    onSetRowSelectionKeywordsModel([]);
  };
  const handleShowFilters = () => onSetShowTableFilters(!isShowTableFilters);

  return (
    <>
      <StyledHeader container xs={12}>
        <Grid
          container
          item
          xs={12}
          justifyContent={'space-between'}
          alignItems={'center'}
          flexWrap={'wrap'}
          gap='8px'
          marginBottom={
            !extraSmallScreen ? '42px' : isShowTableFilters ? '13px' : '26px'
          }
        >
          <Grid
            container
            item
            xs={12}
            justifyContent={'space-between'}
            alignItems={'center'}
            flexWrap={'wrap'}
            gap='8px'
            marginBottom={
              !extraSmallScreen ? '42px' : isShowTableFilters ? '13px' : '26px'
            }
          >
            <Grid item lg={2}>
              <StyledHead>Keyword Rankings</StyledHead>
            </Grid>

            <Grid
              item
              spacing={2}
              lg={9}
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                gap: '8px',
              }}
            >
              {!extraSmallScreen ? (
                <Grid item>
                  <OutlinedInput
                    sx={{
                      width: smallScreen && !showSearchMobile ? '44px' : null,
                    }}
                    size='small'
                    type='text'
                    id='searchKeyword'
                    name='searchKeyword'
                    placeholder='Search keyword'
                    value={localSearch}
                    onChange={handleSearch}
                    startAdornment={
                      <InputAdornment position='start' disablePointerEvents>
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                </Grid>
              ) : (
                <Grid item>
                  <GrayButtonSmallForSelect
                    isSelected={isShowTableFilters}
                    sx={{
                      padding: '6px 12px !important',
                      minWidth: 'fit-content',
                    }}
                    size='small'
                    outline
                    startIcon={
                      !seScreen ? (
                        <FilterIcon
                          color={isShowTableFilters ? '#0E59BA' : '#344054'}
                        />
                      ) : null
                    }
                    onClick={handleShowFilters}
                  >
                    {!seScreen ? (
                      'Filters'
                    ) : (
                      <FilterIcon
                        color={isShowTableFilters ? '#0E59BA' : '#344054'}
                      />
                    )}
                  </GrayButtonSmallForSelect>
                </Grid>
              )}

              <Grid item>
                <PopupTableSettings />
              </Grid>
            </Grid>
          </Grid>

          {extraSmallScreen && isShowTableFilters ? (
            <Grid
              item
              sx={{
                display: 'flex',
                gap: '8px',
                flexDirection: 'row',
                mb: '8px',
              }}
            >
              <Grid>
                <PopupTablePosition />
              </Grid>
              <Grid>
                <PopupTags />
              </Grid>
            </Grid>
          ) : null}
        </Grid>

        <Grid
          container
          item
          xs={12}
          justifyContent={'space-between'}
          alignItems={'center'}
          flexWrap={'wrap'}
          gap='8px'
        >
          {rowSelectionModel.length ? (
            <Grid item>
              <Grid container item alignItems='center'>
                <Grid item>
                  <IconButton
                    onClick={onClearSelected}
                    sx={{ padding: '0 !important', marginRight: '8px' }}
                  >
                    <CloseIcon fontSize='small' />
                  </IconButton>
                </Grid>
                <Grid item sx={{ paddingRight: '16px' }}>
                  <Typography
                    fontSize='14px'
                    lineHeight='14px'
                    color='#101828'
                    fontWeight='500'
                    alignItems='center'
                  >
                    {`${rowSelectionModel.length} Selected`}
                  </Typography>
                </Grid>
                <Grid item>
                  <ActionsMenu />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item display={'flex'} alignItems={'center'} lg={2}>
              <Grid marginRight={'8px'}>
                <Typography
                  variant='h6'
                  fontWeight='600'
                  color='#101828'
                  fontSize='18px'
                  lineHeight='28px'
                >
                  All Keywords
                </Typography>
              </Grid>
              <Grid>
                <CountLabel>{keywordRanking?.meta?.totalItems || 0}</CountLabel>
              </Grid>
            </Grid>
          )}
          <Grid
            spacing={2}
            lg={9}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '8px',
              width:
                extraTooSmallScreen && showSearchMobile ? '100%' : 'initial',
            }}
          >
            {!extraSmallScreen ? (
              <>
                <Grid>
                  <PopupTablePosition />
                </Grid>
                <Grid>
                  <PopupKeywordTags />
                </Grid>
              </>
            ) : (
              <Grid
                item
                xs={extraSmallScreen && showSearchMobile ? 12 : undefined}
              >
                <OutlinedInput
                  sx={{
                    width:
                      extraSmallScreen && !showSearchMobile ? '44px' : null,
                  }}
                  fullWidth
                  size='small'
                  type='text'
                  id='searchKeyword'
                  name='searchKeyword'
                  placeholder='Search Keyword'
                  value={localSearch}
                  onChange={handleSearch}
                  onFocus={() => {
                    extraSmallScreen && setShowSearchMobile(true);
                  }}
                  startAdornment={
                    <InputAdornment position='start' disablePointerEvents>
                      <SearchIcon />
                    </InputAdornment>
                  }
                  endAdornment={
                    extraSmallScreen && showSearchMobile ? (
                      <>
                        <Typography
                          fontSize='14px'
                          fontWeight='600'
                          lineHeight='20px'
                          color='#126FE9'
                          sx={{ cursor: 'pointer', paddingRight: '4px' }}
                          onClick={handleClearSearch}
                        >
                          Clear
                        </Typography>
                        <CloseIcon
                          fontSize='small'
                          sx={{ color: '#101828', cursor: 'pointer' }}
                          onClick={() =>
                            extraSmallScreen && setShowSearchMobile(false)
                          }
                        />
                      </>
                    ) : null
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </StyledHeader>
    </>
  );
};

export default Header;
