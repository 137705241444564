import { styled } from '@mui/material/styles';
import {
  Header as TeamHeader,
  Content as TeamContent,
} from '../../../../../../auth/components/Settings/components/Team/components/Tooltips/styles';

const Header = styled(TeamHeader)``;

const Content = styled(TeamContent)``;

export { Header, Content };
