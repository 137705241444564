import React from 'react';
import { ProjectActionProps } from '../../../../../../../Projects/components/TableProjects/components/EllipsisMenu/components/types';
import useProjects from '../../../../../../../../hooks/useProjects';
import {
  ListItemTypography,
  StyledListItem,
  StyledListItemButton,
} from '../../../../../../../Projects/components/TableProjects/components/EllipsisMenu/styles';
import EditIcon from '../../../../../../../../../../shared/images/EditIcon';
import EditProjectModal from './EditProjectModal';
import { useAppSelector } from '../../../../../../../../../../store';
import useAccountLimits from '../../../../../../../../../auth/hooks/useAccountLimits';

const EditProjectAction: React.FC<ProjectActionProps> = ({
  projectId,
  popupClose,
}) => {
  const [isOpenEditProjectModal, setIsOpenEditProjectModal] =
    React.useState(false);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const { onGetCurrentProject } = useProjects();
  const { onCheckSubscriptionExpiration } = useAccountLimits();

  const handleOpenEditProjectModal = () => {
    const isExpiration = onCheckSubscriptionExpiration();
    if (!isExpiration) {
      onGetCurrentProject({
        accountId: currentAccount.accountId,
        projectId: projectId,
        tags: true,
        notes: true,
        successFn: () => {
          setIsOpenEditProjectModal(true);
        },
      });
    }
  };

  const handleCloseEditProjectModal = () => {
    popupClose();
    setIsOpenEditProjectModal(false);
  };

  return (
    <>
      <StyledListItem onClick={handleOpenEditProjectModal}>
        <StyledListItemButton>
          <EditIcon />
          <ListItemTypography marginLeft='8px'>Edit Project</ListItemTypography>
        </StyledListItemButton>
      </StyledListItem>
      {isOpenEditProjectModal ? (
        <EditProjectModal
          open={isOpenEditProjectModal}
          handleClose={handleCloseEditProjectModal}
        />
      ) : null}
    </>
  );
};

export default EditProjectAction;
