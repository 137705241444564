import React from 'react';

const GoogleBusinessIcon = () => {
  return (
    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="google-business 1" clipPath="url(#clip0_2228_171388)">
        <path id="Vector" d="M1.55565 4.00586H16.3238C16.6753 4.00586 16.9595 4.29013 16.9595 4.64159V15.3645C16.9595 15.716 16.6753 16.0003 16.3238 16.0003H1.55565C1.20419 16.0003 0.919922 15.716 0.919922 15.3645V4.64159C0.921645 4.29185 1.20591 4.00586 1.55565 4.00586Z" fill="#4989F5"/>
        <path id="Vector_2" d="M16.1389 3.91602H1.72392C1.27598 3.91602 0.929688 7.37203 0.929688 7.81824L8.82373 15.9983H16.1389C16.5868 15.9966 16.9504 15.6348 16.9504 15.1868V4.72747C16.9504 4.27953 16.5868 3.91774 16.1389 3.91602Z" fill="url(#paint0_linear_2228_171388)"/>
        <path id="Vector_3" d="M4.58203 6.01443H9.16478V0H5.26944L4.58203 6.01443Z" fill="#3C4BA6"/>
        <path id="Vector_4" d="M13.7488 6.01443H9.16602V0H13.0631L13.7488 6.01443Z" fill="#7BABF7"/>
        <path id="Vector_5" d="M16.959 1.14062L16.9642 1.1613C16.9642 1.15441 16.9607 1.14752 16.959 1.14062Z" fill="#7BABF7"/>
        <path id="Vector_6" d="M16.9665 1.16119L16.9613 1.14052C16.8148 0.475503 16.2256 0 15.5451 0H13.0625L13.7482 6.01443H18.3309L16.9665 1.16119Z" fill="#3F51B5"/>
        <path id="Vector_7" d="M1.3704 1.14062L1.36523 1.1613C1.36696 1.15441 1.36868 1.14752 1.3704 1.14062Z" fill="#7BABF7"/>
        <path id="Vector_8" d="M1.36621 1.16119L1.37138 1.14052C1.5161 0.475503 2.10531 0 2.78583 0H5.26844L4.58275 6.01443H0L1.36621 1.16119Z" fill="#7BABF7"/>
        <path id="Vector_9" fillRule="evenodd" clipRule="evenodd" d="M4.59079 6.0127C4.59079 7.27726 3.5657 8.30235 2.30114 8.30235C1.03658 8.30235 0.00976562 7.27898 0.00976562 6.0127H4.59079Z" fill="#709BE0"/>
        <path id="Vector_10" fillRule="evenodd" clipRule="evenodd" d="M9.1711 6.0127C9.1711 7.27726 8.14601 8.30235 6.88145 8.30235C5.61689 8.30235 4.5918 7.27898 4.5918 6.0127H9.1711Z" fill="#3C4BA6"/>
        <path id="Vector_11" fillRule="evenodd" clipRule="evenodd" d="M13.7509 6.0127C13.7509 7.27726 12.7259 8.30235 11.4613 8.30235C10.1967 8.30235 9.16992 7.27898 9.16992 6.0127H13.7509Z" fill="#709BE0"/>
        <path id="Vector_12" fillRule="evenodd" clipRule="evenodd" d="M18.3313 6.0127C18.3313 7.27726 17.3062 8.30235 16.0416 8.30235C14.777 8.30235 13.752 7.27726 13.752 6.0127H18.3313Z" fill="#3C4BA6"/>
        <path id="Vector_13" d="M16.041 12.2184C16.0324 12.1098 16.0204 12.0099 15.9997 11.8824H13.5016C13.5016 12.2322 13.5016 12.6129 13.4999 12.9626H14.947C14.885 13.2917 14.6938 13.5829 14.4164 13.7707C14.4164 13.7707 14.4164 13.7655 14.4147 13.7638C14.2372 13.8809 14.0374 13.9584 13.8289 13.9929C13.6187 14.0308 13.4051 14.0308 13.1949 13.9895C12.983 13.9447 12.7814 13.8585 12.604 13.7328C12.3421 13.5467 12.1405 13.2883 12.0234 12.9902C12.0148 12.9678 12.0062 12.9437 11.9975 12.9196V12.9161L11.9993 12.9144C11.8856 12.5802 11.8856 12.2184 11.9993 11.8842C12.0785 11.6498 12.2112 11.4362 12.3835 11.2588C12.7901 10.8384 13.3965 10.6799 13.9564 10.8487C14.1718 10.9142 14.3664 11.0296 14.5284 11.1864L15.016 10.6988C15.1021 10.6127 15.1917 10.5283 15.2744 10.437C15.0263 10.2061 14.7369 10.0269 14.4199 9.90806C13.8427 9.69959 13.2121 9.6927 12.6316 9.89255C12.6109 9.89944 12.5919 9.90633 12.5713 9.91322C11.9441 10.1493 11.429 10.611 11.1275 11.2071C11.0207 11.419 10.9432 11.643 10.8967 11.8755C10.6193 13.2504 11.4583 14.6028 12.8124 14.9663C13.2552 15.0852 13.7256 15.0817 14.1718 14.9801C14.5766 14.8888 14.9522 14.6941 15.2589 14.415C15.5793 14.1187 15.8102 13.7259 15.9308 13.3072C16.0342 12.954 16.0703 12.5853 16.041 12.2184Z" fill="white"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_2228_171388" x1="0.929171" y1="9.9581" x2="16.9511" y2="9.9581" gradientUnits="userSpaceOnUse">
          <stop offset="0.03" stopColor="#4079D8"/>
          <stop offset="1" stopColor="#4989F5"/>
        </linearGradient>
        <clipPath id="clip0_2228_171388">
          <rect width="18.331" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoogleBusinessIcon;