import React from 'react';
import Grid from '@mui/material/Grid';

import { Table, Pagination, ExportCSV } from './components';
import { PaginationContainer } from '../../../Projects/components/TableProjects/components/Pagination/styles';

const KeywordPositionsInfo = () => {
  return (
    <Grid xs={12} paddingTop={'27px'}>
      <ExportCSV />
      <Table />
      <PaginationContainer xs={12}>
        <Pagination />
      </PaginationContainer>
    </Grid>
  );
};

export default KeywordPositionsInfo;
