import React, { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';

import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { useProjects } from '../../../../../../../../../../hooks';
import { AccountTag } from '../../../../../../../../types';

import DownIcon from '../../../../../../../../../../shared/images/DownIcon';
import SearchIcon from '../../../../../../../../../../shared/images/SearchIcon';
import CloseIcon from '@mui/icons-material/Close';
import CustomCheckboxImage from '../../../../../../../../../../shared/images/CustomCheckbox';

import { PrimaryButtonSmall } from '../../../../../../../../../../shared/buttons/styles';
import { Popover } from '../../../../../../../../../../shared/layout/PrivateLayout/components/MyAccount/styles';
import { TagsContainer, TagsSearchContainer } from './styles';
import { SmallContainerCheckbox } from '../../../../../../../../../../shared/checkbox/styles';
import CheckedIcon from '../../../../../../../../../../shared/images/CheckedIcon';
import {
  PopupButtonContainer,
  PopupSelectButton,
  SelectedPopupItems,
} from '../../styles';
import { useAppSelector } from '../../../../../../../../../../store';

const PopupTags = () => {
  const { onGetAccountTags, onSelectTags } = useProjects();

  const accountTags = useAppSelector(state => state.projects.accountTags);

  const selectedTags = useAppSelector(state => state.projects.selectedTags);

  const currentAccount = useAppSelector(state => state.auth.currentAccount);
  const [searchString, setSearchString] = useState('');

  const [checkedTags, setCheckedTags] = useState<AccountTag[]>(selectedTags);

  const handleSelectTag = (tag: AccountTag, checked: boolean) => {
    setCheckedTags([...checkedTags, tag]);
    if (!checked) {
      setCheckedTags(checkedTags.filter(item => item.id !== tag.id));
    }
  };

  const handleConfirm = (onClose: () => void) => {
    onSelectTags(checkedTags);
    onClose();
  };

  const handleClearTags = () => {
    onSelectTags([]);
    setCheckedTags([]);
  };

  useEffect(() => {
    if (currentAccount?.accountId) {
      onGetAccountTags({
        accountId: currentAccount.accountId,
        search: searchString,
      });
    }
  }, [currentAccount.accountId, searchString, onGetAccountTags]);

  return (
    <PopupState variant='popover' popupId='add-tag-setting'>
      {popupState => (
        <>
          <PopupButtonContainer isSelected={!!selectedTags.length}>
            <PopupSelectButton
              {...bindTrigger(popupState)}
              isSelected={!!selectedTags.length}
            >
              <Grid>Tags</Grid>
              <DownIcon stroke={selectedTags.length ? '#0E59BA' : '#344054'} />
            </PopupSelectButton>
            {selectedTags.length ? (
              <SelectedPopupItems>
                <Grid>{selectedTags.length} Selected</Grid>
                <CloseIcon
                  onClick={handleClearTags}
                  sx={{
                    color: '#000000',
                    position: 'initial',
                    cursor: 'pointer',
                    width: '20px',
                    marginLeft: '4px',
                  }}
                />
              </SelectedPopupItems>
            ) : null}
          </PopupButtonContainer>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              boxShadow:
                ' 0px 4px 6px -2px rgba(0, 0, 0, 0.06), 0px 12px 16px -4px rgba(0, 0, 0, 0.10)',
              borderRadius: '8px',
              marginTop: '11px',
            }}
          >
            <TagsContainer>
              <TagsSearchContainer>
                <InputLabel htmlFor='searchTags'>
                  <SearchIcon />
                </InputLabel>
                <InputBase
                  fullWidth
                  id='searchTags'
                  placeholder='Search tags...'
                  value={searchString}
                  onChange={e => setSearchString(e.target.value as string)}
                />
              </TagsSearchContainer>

              <Divider
                orientation='horizontal'
                flexItem
                sx={{
                  margin: '11px 0 16px 0',
                  color: '#EDEDED',
                }}
              />

              {accountTags.length ? (
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0 8px',
                    cursor: 'pointer',
                  }}
                >
                  {accountTags.map(item => (
                    <FormControlLabel
                      key={item.id}
                      label={item.name}
                      sx={{
                        marginLeft: '8px',
                        marginRight: '8px',
                        gap: '8px',
                        color: '#101828',
                        fontSize: '14px',
                        padding: '8px 0 8px 8px',
                        backgroundColor: checkedTags.some(x => x.id === item.id)
                          ? '#EEF3FF'
                          : 'initial',
                        borderRadius: '8px',
                        ':hover': {
                          backgroundColor: '#F6F6F6',
                          borderRadius: '8px',
                        },
                      }}
                      control={
                        <SmallContainerCheckbox
                          onChange={() =>
                            handleSelectTag(
                              item,
                              !checkedTags.some(x => x.id === item.id)
                            )
                          }
                          checked={checkedTags.some(x => x.id === item.id)}
                          icon={<CustomCheckboxImage />}
                          checkedIcon={<CheckedIcon />}
                        />
                      }
                    />
                  ))}
                </Grid>
              ) : null}

              <Grid item xs={12} sx={{ m: '16px 16px 0 16px' }}>
                <PrimaryButtonSmall
                  fullWidth
                  variant='contained'
                  size='small'
                  onClick={() => handleConfirm(popupState.close)}
                >
                  Apply
                </PrimaryButtonSmall>
              </Grid>
            </TagsContainer>
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export default PopupTags;
