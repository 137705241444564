import React from 'react';

import { Header, Content } from './styles';

/**
 * Component representing the date of an alert.
 */
const Date = () => (
  <div>
    <Header>Date</Header>
    <Content>The date and time when the alert was triggered.</Content>
  </div>
);

/**
 * Component representing the project associated with an alert.
 */
const Project = () => (
  <div>
    <Header>Project</Header>
    <Content>The project associated with the alerted keyword.</Content>
  </div>
);

/**
 * Component representing the number of keywords meeting the trigger condition.
 */
const NumberOfKeywords = () => (
  <div>
    <Header>Number of Keywords</Header>
    <Content>
      Indicates the number of keywords in a project that have met the trigger
      condition.
    </Content>
  </div>
);

/**
 * Component representing the alert rule.
 */
const Rule = () => (
  <div>
    <Header>Rule</Header>
    <Content>
      The condition set to trigger the alert (e.g., rank increase, rank
      decrease).
    </Content>
  </div>
);

/**
 * Component representing the threshold for triggering an alert.
 */
const Threshold = () => (
  <div>
    <Header>Threshold</Header>
    <Content>The amount of rank change needed to trigger the alert.</Content>
  </div>
);
/**
 * Component representing the keyword that triggered the alert.
 */
const Keyword = () => (
  <div>
    <Header>Keyword</Header>
    <Content>The specific keyword for which the alert was triggered.</Content>
  </div>
);

/**
 * Component representing the difference in rank that triggered the alert.
 */
const Difference = () => (
  <div>
    <Header>Difference</Header>
    <Content>The change in rank that triggered the alert.</Content>
  </div>
);

/**
 * Component representing the previous position of a keyword.
 */
const PreviousPosition = () => (
  <div>
    <Content>
      Displays the keyword position on the date just before the alert was
      triggered.
    </Content>
  </div>
);

/**
 * Component representing the current position of a keyword.
 */
const CurrentPosition = () => (
  <div>
    <Content>
      Shows the keyword position on the date the alert was actually triggered.
    </Content>
  </div>
);

/**
 * Component representing the previous rank of a keyword.
 */
const PreviousRank = () => (
  <div>
    <Header>Previous Rank</Header>
    <Content>
      The keyword&apos;s rank before the change that triggered the alert.
    </Content>
  </div>
);

/**
 * Component representing the new rank of a keyword.
 */
const NewRank = () => (
  <div>
    <Header>New Rank</Header>
    <Content>The keyword&apos;s rank that triggered the alert.</Content>
  </div>
);

export {
  Date,
  Project,
  NumberOfKeywords,
  Rule,
  Threshold,
  Keyword,
  Difference,
  PreviousPosition,
  CurrentPosition,
  PreviousRank,
  NewRank,
};
