import { TooltipProps } from 'recharts';
import { StyledITooltipContainer } from '../../../../../../../../projects/components/ProjectExpanded/components/ImprovedVsDeclined/components/Graph/styled';
import {
  StyledITooltipContainerHead,
  StyledStatus,
  StyledStatusValue,
} from '../../../../../../../../projects/components/ProjectExpanded/components/Overview/styles';
import dayjs from 'dayjs';
import { Grid } from '@mui/material';
import React, { useMemo } from 'react';
import { COMPETITORS_COLORS } from '../../../../../../../../../shared/layout/PrivateLayout/constant';
import { useAppSelector } from '../../../../../../../../../store';
import { StyledStatusLabel } from '../../../../../../../../projects/components/ProjectExpanded/components/ProjectPerformance/components/Graph/components/styles';

const Competitor: React.FC<{ name: string; value: string }> = ({
  name,
  value,
}) => {
  const projectShared = useAppSelector(
    state => state.sharedLinks.projectShared
  );

  const competitor = useMemo(() => {
    return (
      projectShared &&
      projectShared?.competitors?.length &&
      projectShared.competitors.find(
        item => Number(name.replace(/^competitor/, '')) === item.id
      )
    );
  }, [name, projectShared]);

  const isBaseSearchEngine = useMemo(() => {
    return (
      projectShared &&
      projectShared.searchEngine.name !== 'Google My Business' &&
      projectShared.searchEngine.name !== 'Google Maps'
    );
  }, [projectShared?.searchEngine?.name]);

  const label = useMemo(() => {
    if (competitor) {
      return isBaseSearchEngine
        ? competitor.domainName
        : `${competitor.businessName}${
            competitor?.url ? ` (${competitor?.url})` : ''
          }`;
    }
    return '';
  }, [competitor, isBaseSearchEngine]);

  if (!competitor) {
    return null;
  }

  return (
    <Grid container item xs={12}>
      <Grid container item xs={9.8} alignItems={'center'} wrap={'nowrap'}>
        <StyledStatus
          item
          $backgroundColor={
            COMPETITORS_COLORS[competitor.id % COMPETITORS_COLORS.length]
          }
        />
        <StyledStatusLabel item>{`${label}:`}</StyledStatusLabel>
      </Grid>

      <Grid xs={2.2} container item justifyContent={'flex-end'}>
        <StyledStatusValue item>
          {Number(value) === 101
            ? '> 100'
            : Math.round(Number(value) * 10) / 10}
        </StyledStatusValue>
      </Grid>
    </Grid>
  );
};

const CustomTooltipProjectPerformanceGraph = ({
  active,
  label,
  payload,
}: TooltipProps<number, string>) => {
  const note = payload?.find(item => item.name === 'Note');
  const dailyAveragePosition = payload?.find(item => item.dataKey === 'avg');
  const competitors = payload?.filter(item => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return item?.dataKey?.slice(0, 10) === 'competitor';
  });

  if (note) {
    return null;
  }

  if (active) {
    return (
      <StyledITooltipContainer item>
        <StyledITooltipContainerHead item>
          {dayjs(label).format('MMMM DD, YYYY')}
        </StyledITooltipContainerHead>

        {dailyAveragePosition && (
          <Grid container item xs={12}>
            <Grid container item xs={9} alignItems={'center'} wrap={'nowrap'}>
              <StyledStatus item $backgroundColor={'#1A73E9'} />
              <StyledStatusLabel item>
                Daily Average Position:
              </StyledStatusLabel>
            </Grid>

            <Grid xs={3} container item justifyContent={'flex-end'}>
              <StyledStatusValue item>
                {dailyAveragePosition.value === 101
                  ? '> 100'
                  : Math.round((dailyAveragePosition.value as number) * 10) /
                    10}
              </StyledStatusValue>
            </Grid>
          </Grid>
        )}
        {competitors &&
          competitors.map(item => (
            <Competitor
              key={item.name}
              name={item.name || ''}
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              // @ts-ignore
              value={item.payload[item.name]}
            />
          ))}
      </StyledITooltipContainer>
    );
  }

  return null;
};

export { CustomTooltipProjectPerformanceGraph };
