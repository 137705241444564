import { Grid } from '@mui/material';
import React from 'react';

import {
  Projects,
  AccountUsage,
  AccountSummary,
} from '../features/projects/components';
import { useAppSelector } from '../store';

const ProjectsPage = () => {
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  return (
    <Grid container xs={12}>
      <Grid item xs={12}>
        <Projects />
      </Grid>

      <Grid
        container
        item
        xs={12}
        columnSpacing={{ xs: 2 }}
        mt={{ xs: 1, md: 7, lg: 2 }}
      >
        {currentAccount?.role?.name !== 'ViewOnly' ? <AccountUsage /> : null}
        <AccountSummary />
      </Grid>
    </Grid>
  );
};

export default ProjectsPage;
