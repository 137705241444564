import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StyledKeywordInfo = styled(Grid)`
  border-radius: 8px;
  border: 1px solid #dedede;
  background: #fff;
  overflow: auto;
  max-width: 475px;
  width: 100%;
`;

const StyledRow = styled(Grid)`
  flex-wrap: nowrap;
`;

const StyledLeftCell = styled(Grid)`
  padding: 16px 24px;
  background: #fafafa;
`;

const StyledLeftCellTypography = styled('div')`
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledRightCell = styled(Grid)`
  padding: 16px 24px;
  background: white;
`;

const StyledRightCellTypography = styled('div')`
  color: #101828;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export {
  StyledKeywordInfo,
  StyledRow,
  StyledLeftCell,
  StyledRightCell,
  StyledLeftCellTypography,
  StyledRightCellTypography,
};
