import * as React from 'react';
import {
  TreeItemProps,
  useTreeItem,
  TreeItemContentProps,
} from '@mui/lab/TreeItem';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';

import { IFolderTree } from '../../../auth/types';
import FolderIcon from '../../../../shared/images/FolderIcon';
import ExpandMoreFolderIcon from '../../../../shared/images/ExpandMoreFolderIcon';
import ChevronRightFolderIcon from '../../../../shared/images/ChevronRightFolderIcon';
import { useFolders } from '../../../../hooks';
import {
  StyledFoldersDrawer,
  StyledFoldersDrawerTitle,
  StyledTreeItemRoot,
  StyledTreeView,
} from './styles';
import { useLocation, useNavigate, useParams } from 'react-router';
import ROUTES from '../../../../routes/constants';
import { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '../../../../store';
import { processFolderTree } from '../../utils';

const CustomContent = React.forwardRef(function CustomContent(
  props: TreeItemContentProps,
  ref
) {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    preventSelection(event);
  };

  const handleExpansionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    event.preventDefault();
    handleExpansion(event);
  };

  return (
    <div
      onClick={handleSelection}
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography component='div' className={classes.label}>
        {label}
      </Typography>
    </div>
  );
});

const CustomTreeItem = (props: TreeItemProps) => {
  return <StyledTreeItemRoot ContentComponent={CustomContent} {...props} />;
};

const MemoizedCustomTreeItem = React.memo(CustomTreeItem);

const renderTree = (nodes: IFolderTree) => {
  return (
    <MemoizedCustomTreeItem
      key={nodes.id}
      nodeId={nodes.id.toString()}
      sx={{ p: 0 }}
      label={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box sx={{ position: 'relative', top: '3px' }}>
            <FolderIcon />
          </Box>
          <Box
            sx={{
              pl: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {nodes.name}
          </Box>
        </Box>
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map(node => renderTree(node))
        : null}
    </MemoizedCustomTreeItem>
  );
};

interface IIconExpansionTreeViewProps {
  handleSwipeableDrawerClose: () => void;
}

const IconExpansionTreeView: React.FC<IIconExpansionTreeViewProps> = ({
  handleSwipeableDrawerClose,
}) => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [selectedNode, setSelectedNode] = useState('');

  const { onSetDefaultCurrentFolder, onSetCurrentFolder, currentFolder } =
    useFolders();

  const myAccount = useAppSelector(state => state.auth.myAccount);
  const currentAccount = useAppSelector(state => state.auth.currentAccount);

  const pathSnippets = pathname.split('/');
  const isFolders = pathSnippets[1] === 'folders';

  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);

  function findElementById(
    node: IFolderTree | null,
    targetId: number
  ): IFolderTree | null {
    if (!node) {
      return null;
    }

    if (node.id === targetId) {
      return node;
    }

    for (const child of node.children) {
      const result = findElementById(child, targetId);
      if (result !== null) {
        return result;
      }
    }

    return null;
  }

  const findParentNodes = (
    targetNodeId: string,
    treeData: IFolderTree
  ): string[] => {
    const parentIds: string[] = [];
    const stack: IFolderTree[] = [];

    const dfs = (node: IFolderTree) => {
      stack.push(node);

      if (node.id.toString() === targetNodeId) {
        for (const item of stack) {
          parentIds.push(item.id.toString());
        }
        return;
      }

      if (node.children) {
        for (const childNode of node.children) {
          dfs(childNode);
        }
      }

      stack.pop();
    };

    dfs(treeData);
    return parentIds;
  };

  const handleNodeToggle = useCallback(
    (event: React.SyntheticEvent, nodeIds: string[]) => {
      setExpandedNodes(nodeIds);
    },
    []
  );

  useEffect(() => {
    if (id && myAccount?.folderTree) {
      const folder = findElementById(myAccount.folderTree, Number(id));
      if (folder && currentFolder?.id !== folder?.id) {
        onSetCurrentFolder({
          folder: { id: folder.id, name: folder.name },
          isRootView:
            myAccount.folderTree.id === folder.id && currentAccount.isMyAccount,
        });
      }
    }
  }, [
    id,
    currentFolder,
    findElementById,
    myAccount.folderTree,
    onSetCurrentFolder,
    currentAccount.isMyAccount,
  ]);

  useEffect(() => {
    if (isFolders && id && myAccount?.folderTree?.id) {
      setSelectedNode(id);
    } else {
      if (isFolders && myAccount?.folderTree?.id) {
        setSelectedNode(myAccount.folderTree.id.toString());
      } else {
        setSelectedNode('');
      }
    }
  }, [id, isFolders, myAccount?.folderTree?.id]);

  useEffect(() => {
    if (currentFolder?.id && id) {
      const parentIds = findParentNodes(id, myAccount.folderTree);
      setExpandedNodes(parentIds);
    }
  }, [currentFolder?.id]);

  const handleSelectionClick = (
    event: React.SyntheticEvent,
    nodeIds: string
  ) => {
    setSelectedNode(nodeIds);
    const folderTreeId = myAccount.folderTree.id;
    if (folderTreeId === Number(nodeIds) && currentAccount?.isMyAccount) {
      const mainFolder = { id: myAccount.folderTree.id, name: 'My Folders' };
      onSetDefaultCurrentFolder(mainFolder);
      navigate(ROUTES.rootFolders);
    } else {
      navigate(ROUTES.dynamic.folders(nodeIds));
    }
    handleSwipeableDrawerClose();
  };

  const FoldersDrawer = useCallback(() => {
    let data = [] as IFolderTree[];
    if (myAccount.folderTree) {
      data = processFolderTree(myAccount.folderTree);
    }
    return (
      <StyledFoldersDrawer container>
        <StyledFoldersDrawerTitle item>Folders</StyledFoldersDrawerTitle>
        <Grid container item>
          {myAccount?.folderTree ? (
            <StyledTreeView
              expanded={expandedNodes}
              onNodeToggle={handleNodeToggle}
              onNodeSelect={handleSelectionClick}
              selected={selectedNode}
              aria-label='rich object'
              defaultCollapseIcon={<ExpandMoreFolderIcon />}
              defaultExpandIcon={<ChevronRightFolderIcon />}
              sx={{
                flexGrow: 1,
                overflowY: 'auto',
              }}
            >
              {data.map(item => renderTree(item))}
            </StyledTreeView>
          ) : null}
        </Grid>
      </StyledFoldersDrawer>
    );
  }, [
    expandedNodes,
    handleNodeToggle,
    handleSelectionClick,
    myAccount.folderTree,
    selectedNode,
  ]);
  return <FoldersDrawer />;
};

export default React.memo(IconExpansionTreeView);
